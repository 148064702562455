import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./CatalogueUIHelpers";

const CatalogueUIContext = createContext();

export function useCatalogueUIContext() {
  return useContext(CatalogueUIContext);
}

export const CatalogueUIConsumer = CatalogueUIContext.Consumer;

export function CatalogueUIProvider({ catalogueUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initCatalogue = {
    id: undefined,
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    gender: "Female",
    status: 0,
    dateOfBbirth: "",
    ipAddress: "",
    type: 1,
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initCatalogue,
    newCatalogueButtonClick: catalogueUIEvents.newCatalogueButtonClick,
    viewPublishersButtonClick: catalogueUIEvents.viewPublishersButtonClick,
    viewNewEbooksButtonClick: catalogueUIEvents.viewNewEbooksButtonClick,
    viewEbooksButtonClick: catalogueUIEvents.viewEbooksButtonClick,
    viewCategoriesButtonClick: catalogueUIEvents.viewCategoriesButtonClick,
    openEditCatalogueDialog: catalogueUIEvents.openEditCatalogueDialog,
    openViewCatalogueEbookDialog:
      catalogueUIEvents.openViewCatalogueEbookDialog,
    openDeleteCatalogueDialog: catalogueUIEvents.openDeleteCatalogueDialog,
    openBlockCatalogueDialog: catalogueUIEvents.openBlockCatalogueDialog,
    openUnblockCatalogueDialog: catalogueUIEvents.openUnblockCatalogueDialog,
    openFetchCatalogueDialog: catalogueUIEvents.openFetchCatalogueDialog,
    openUpdateCatalogueStatusDialog:
      catalogueUIEvents.openUpdateCatalogueStatusDialog,
    newPublisherButtonClick: catalogueUIEvents.newPublisherButtonClick,
    newAuthorButtonClick: catalogueUIEvents.newAuthorButtonClick,
    newCategoryButtonClick: catalogueUIEvents.newCategoryButtonClick,
    newSubCategoryButtonClick: catalogueUIEvents.newSubCategoryButtonClick,
    viewCatalogueDetailClick: catalogueUIEvents.viewCatalogueDetailClick,
  };

  return (
    <CatalogueUIContext.Provider value={value}>
      {children}
    </CatalogueUIContext.Provider>
  );
}
