/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from 'yup';
import { Modal, Input, Button, Divider, message, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { uuid } from 'uuidv4';

import {
    DeleteOutlined,
    SaveOutlined,
    LoadingOutlined
} from '@ant-design/icons';

import {
    EmailPublisherType,
    getAllEmailPublisher,
    SellerEmailAction,
    SellerEmailType,
    sendAllLicenseToAllVendors,
    SendEmailAllVendor,
    sendReportToAllVendors,
    updateEmailSeller,
    VendorsEmail
} from '../../../../../../redux/axios/email';

//? css
import './index.scss';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { AlertSendQuoteModal } from './AlertSendQuoteModal';

interface ConfirmModalProps {
    visible: boolean;
    isQuotation: boolean;
    catalogUID: string;
    clientUID: string;
    startDate: number;
    payDate: number;
    onClose: () => void;
    onOpenSuccess: () => void;
}

export const QuotationModal: React.FC<ConfirmModalProps> = ({
    visible,
    clientUID,
    catalogUID,
    startDate,
    payDate,
    onClose,
    onOpenSuccess,
    isQuotation
}) => {
    const [alertQuoteModal, setAlertQuoteModal] = useState<boolean>(false);
    const [componentLoading, setComponentLoading] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [unsentPublisher, setUnsentPublisher] = useState<string[]>([]);
    const [publisherList, setPublisherList] = useState<EmailPublisherType[]>();
    const [vendorEmails, setVendorsEmails] = useState<VendorsEmail[]>([]);
    const newEmail: SellerEmailType = {
        uniqueUID: uuid(),
        email: '',
        action: SellerEmailAction.add,
        newEmail: true
    };

    useEffect(() => {
        if (visible) fetchAllEmailPublisher();
    }, [visible]);

    const fetchAllEmailPublisher = async () => {
        try {
            setComponentLoading(true);
            const response = await getAllEmailPublisher(catalogUID, clientUID);
            if (response) {
                setPublisherList(response.publishers);
                setComponentLoading(false);
            }
        } catch (error) {
            setComponentLoading(false);
        }
    };

    const updateVendorsData = (
        buttonAction: SellerEmailAction,
        publisherUID: string,
        newSellerEmail: SellerEmailType[],
        currentEmail?: string
    ) => {
        switch (buttonAction) {
            case SellerEmailAction.add:
            case SellerEmailAction.update: {
                const publishers = vendorEmails;
                const currentPublisherIndex = publishers.findIndex(
                    el => el.publisherUID === publisherUID
                );
                publishers[currentPublisherIndex].sellerEmail = newSellerEmail;
                setVendorsEmails([...publishers]);
                break;
            }
            case SellerEmailAction.delete: {
                const publishers = vendorEmails;
                const currentPublisherIndex = publishers.findIndex(
                    el => el.publisherUID === publisherUID
                );
                const newPublisherEmail = publishers[
                    currentPublisherIndex
                ].sellerEmail.filter(el => el.email !== currentEmail);
                publishers[
                    currentPublisherIndex
                ].sellerEmail = newPublisherEmail;
                setVendorsEmails([...publishers]);
                break;
            }
        }
    };

    const updatePublisherData = (
        buttonAction: SellerEmailAction,
        publisherUID: string,
        newSellerEmail: SellerEmailType[],
        currentEmail?: string
    ) => {
        switch (buttonAction) {
            case SellerEmailAction.add:
            case SellerEmailAction.update: {
                if (publisherList) {
                    const publishers = publisherList;
                    const currentPublisherIndex = publishers.findIndex(
                        el => el.publisherUID === publisherUID
                    );
                    publishers[
                        currentPublisherIndex
                    ].sellerEmail = newSellerEmail;
                    setVendorsEmails([...publishers]);
                }
                break;
            }
            case SellerEmailAction.delete: {
                if (publisherList) {
                    const publishers = publisherList;
                    const currentPublisherIndex = publishers.findIndex(
                        el => el.publisherUID === publisherUID
                    );
                    const newPublisherEmail = publishers[
                        currentPublisherIndex
                    ].sellerEmail.filter(el => el.email !== currentEmail);
                    publishers[
                        currentPublisherIndex
                    ].sellerEmail = newPublisherEmail;
                    setVendorsEmails([...publishers]);
                }
                break;
            }
        }
    };

    const updateEmail = async (
        publisherUID: string,
        email: string,
        uniqueUID: string,
        actionType: SellerEmailAction
    ) => {
        try {
            switch (actionType) {
                case SellerEmailAction.add: {
                    const emailObj: SellerEmailType = {
                        uniqueUID: uniqueUID,
                        email: email,
                        action: SellerEmailAction.add
                    };
                    const response = await updateEmailSeller(
                        publisherUID,
                        emailObj
                    );
                    message.success('เพิ่มอีเมลสำเร็จ');
                    break;
                }

                case SellerEmailAction.delete: {
                    const emailObj: SellerEmailType = {
                        uniqueUID: uniqueUID,
                        email: email,
                        action: SellerEmailAction.delete
                    };
                    const response = await updateEmailSeller(
                        publisherUID,
                        emailObj
                    );
                    message.success('ลบอีเมลสำเร็จ');
                    break;
                }

                case SellerEmailAction.update: {
                    const emailObj: SellerEmailType = {
                        uniqueUID: uniqueUID,
                        email: email,
                        action: SellerEmailAction.update
                    };
                    const response = await updateEmailSeller(
                        publisherUID,
                        emailObj
                    );
                    message.success('อัพเดทอีเมลสำเร็จ');
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const sendLicenseToAllVendors = async (
        catalogUID: string,
        clientUID: string,
        startDate: number,
        publisher: VendorsEmail[]
    ) => {
        try {
            const data: SendEmailAllVendor = {
                clientUID: clientUID,
                startDate: startDate,
                publishers: publisher
            };
            setLoading(true);
            const response = await sendAllLicenseToAllVendors(catalogUID, data);
            setLoading(false);
            onClose();
            onOpenSuccess();
        } catch (error) {
            setLoading(false);
        }
    };

    const sendEmailToAllVendors = async () => {
        try {
            const payload = publisherList!.filter(
                e => !unsentPublisher.includes(e.publisherUID)
            );
            const data: SendEmailAllVendor = {
                clientUID: clientUID,
                startDate: startDate,
                payDeadLine: payDate,
                publishers: payload
            };
            setLoading(true);
            const response = await sendReportToAllVendors(catalogUID, data);
            setLoading(false);
            setAlertQuoteModal(false);
            setUnsentPublisher([]);
            onClose();
            onOpenSuccess();
        } catch (error) {
            setLoading(false);
        }
    };

    const RenderForm = (
        publisherIndex: number,
        sellerEmail: SellerEmailType[],
        publisherUID: string,
        publisherName: string
    ) => {
        const saveData: VendorsEmail = {
            publisherUID: publisherUID,
            sellerEmail: sellerEmail
        };

        if (vendorEmails.length < publisherList!.length) {
            vendorEmails.push(saveData);
        }

        const checkValidateButton = (
            newEmail: string,
            defaultEmail: string
        ) => {
            const emailReg = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                newEmail
            );
            if (newEmail === '' || newEmail === undefined) {
                return true;
            } else if (emailReg) {
                return true;
            } else if (newEmail === defaultEmail) {
                return true;
            } else {
                return false;
            }
        };

        const validate = Yup.object().shape({
            publisher: Yup.array().of(
                Yup.object().shape({
                    email: Yup.string()
                        .required('กรุณากรอกอีเมล')
                        .email('รูปแบบอีเมลไม่ถูกต้อง')
                })
            )
        });

        const onCheckBox = (e: CheckboxChangeEvent, uid: string) => {
            console.log(e.target.checked);

            if (e.target.checked) {
                const currentList = unsentPublisher?.filter(el => el !== uid);
                setUnsentPublisher(currentList);
            } else {
                setUnsentPublisher([...unsentPublisher, uid]);
            }
        };

        return (
            <Formik
                initialValues={{ publisher: sellerEmail }}
                validationSchema={validate}
                onSubmit={values => alert(JSON.stringify(values, null, 2))}
                render={({
                    values,
                    handleChange,
                    setFieldValue,
                    touched,
                    errors
                }) => (
                    <Form>
                        <FieldArray
                            name="publisher"
                            render={arrayObj => (
                                <div>
                                    <Divider
                                        orientation="left"
                                        orientationMargin="0"
                                    >
                                        <Checkbox
                                            checked={
                                                !unsentPublisher.includes(
                                                    publisherUID
                                                )
                                            }
                                            onChange={e =>
                                                onCheckBox(e, publisherUID)
                                            }
                                            style={{
                                                marginRight: '0.5rem'
                                            }}
                                        />
                                        <span>
                                            สำนักพิมพ์​ : {publisherName}
                                        </span>
                                    </Divider>
                                    {values.publisher.map((el, index) => (
                                        <>
                                            <div
                                                className="label-flex"
                                                key={index}
                                            >
                                                <Input
                                                    name={`publisher.${index}.email`}
                                                    value={
                                                        values.publisher[index]
                                                            .email
                                                    }
                                                    onChange={handleChange}
                                                    type="email"
                                                />

                                                <Button
                                                    shape="round"
                                                    icon={<SaveOutlined />}
                                                    onClick={() => {
                                                        setFieldValue(
                                                            `publisher.${index}.newEmail`,
                                                            false
                                                        );
                                                        updateEmail(
                                                            publisherUID,
                                                            values.publisher[
                                                                index
                                                            ].email,
                                                            values.publisher[
                                                                index
                                                            ].uniqueUID!,
                                                            values.publisher[
                                                                index
                                                            ].action
                                                                ? SellerEmailAction.add
                                                                : SellerEmailAction.update
                                                        );
                                                        updateVendorsData(
                                                            values.publisher[
                                                                index
                                                            ].action
                                                                ? SellerEmailAction.add
                                                                : SellerEmailAction.update,
                                                            publisherUID,
                                                            values.publisher
                                                        );
                                                        updatePublisherData(
                                                            values.publisher[
                                                                index
                                                            ].action
                                                                ? SellerEmailAction.add
                                                                : SellerEmailAction.update,
                                                            publisherUID,
                                                            values.publisher
                                                        );
                                                    }}
                                                    disabled={
                                                        values.publisher[
                                                            index
                                                        ] &&
                                                        values.publisher[index]
                                                            .email &&
                                                        vendorEmails[
                                                            publisherIndex
                                                        ].sellerEmail[index] &&
                                                        vendorEmails[
                                                            publisherIndex
                                                        ].sellerEmail[index]
                                                            .email
                                                            ? checkValidateButton(
                                                                  values
                                                                      .publisher[
                                                                      index
                                                                  ].email,
                                                                  vendorEmails![
                                                                      publisherIndex
                                                                  ].sellerEmail[
                                                                      index
                                                                  ].email
                                                              )
                                                            : false
                                                    }
                                                />

                                                <Button
                                                    shape="round"
                                                    onClick={() => {
                                                        arrayObj.remove(index);
                                                        updateVendorsData(
                                                            SellerEmailAction.delete,
                                                            publisherUID,
                                                            values.publisher,
                                                            values.publisher[
                                                                index
                                                            ].email
                                                        );
                                                        updatePublisherData(
                                                            SellerEmailAction.delete,
                                                            publisherUID,
                                                            values.publisher,
                                                            values.publisher[
                                                                index
                                                            ].email
                                                        );
                                                        if (
                                                            !values.publisher[
                                                                index
                                                            ].newEmail
                                                        ) {
                                                            updateEmail(
                                                                publisherUID,
                                                                values
                                                                    .publisher[
                                                                    index
                                                                ].email,
                                                                values
                                                                    .publisher[
                                                                    index
                                                                ].uniqueUID!,
                                                                SellerEmailAction.delete
                                                            );
                                                        }
                                                    }}
                                                    icon={<DeleteOutlined />}
                                                />
                                            </div>
                                            {errors &&
                                                errors.publisher &&
                                                errors.publisher[index] &&
                                                touched &&
                                                touched.publisher &&
                                                touched.publisher[index] && (
                                                    <div className="field-error">
                                                        {
                                                            errors.publisher[
                                                                index
                                                            ]
                                                        }
                                                    </div>
                                                )}
                                        </>
                                    ))}
                                    <Button
                                        onClick={() => arrayObj.push(newEmail)}
                                    >
                                        + เพิ่มอีเมล
                                    </Button>
                                </div>
                            )}
                        />
                    </Form>
                )}
            />
        );
    };

    return (
        <Modal
            className="confirm-modal"
            style={{ top: 20 }}
            visible={visible}
            onCancel={onClose}
            title={null}
            footer={null}
            key={catalogUID}
        >
            <div className="content-container">
                <div className="text-text-red">
                    {isQuotation
                        ? 'ยืนยันการส่งใบเสนอราคา ?'
                        : 'ยืนยันการตรวจลิขสิทธิ์ ?'}
                </div>
                <div className="text" style={{ marginBottom: '1rem' }}>
                    ต้องการส่งใบเสนอราคาหรือไม่ ?
                </div>
                <p>จำนวนสำนักพิมพ์ : {publisherList?.length ?? 0} </p>

                {!componentLoading && publisherList ? (
                    <>
                        {publisherList.map((el, index) => (
                            <div
                                key={el.publisherUID}
                                style={{ width: '100%' }}
                            >
                                {RenderForm(
                                    index,
                                    el.sellerEmail,
                                    el.publisherUID,
                                    el.publisherName
                                )}
                            </div>
                        ))}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Button
                                htmlType="submit"
                                shape="round"
                                size="large"
                                loading={loading}
                                onClick={() => {
                                    if (isQuotation) {
                                        if (
                                            unsentPublisher.length > 0 &&
                                            unsentPublisher.length !==
                                                publisherList.length
                                        ) {
                                            setAlertQuoteModal(true);
                                        } else if (
                                            unsentPublisher.length <
                                            publisherList.length
                                        ) {
                                            sendEmailToAllVendors();
                                        } else {
                                            message.warning(
                                                'กรุณาเลือกสำนักพิมพ์ที่จะส่งใบเสนอราคาอย่างน้อย 1 สำนักพิมพ์'
                                            );
                                        }
                                    } else {
                                        sendLicenseToAllVendors(
                                            catalogUID,
                                            clientUID,
                                            startDate,
                                            vendorEmails
                                        );
                                    }
                                }}
                            >
                                ส่งใบเสนอราคา
                            </Button>
                        </div>
                    </>
                ) : (
                    <LoadingOutlined style={{ fontSize: '48px' }} />
                )}
            </div>
            <AlertSendQuoteModal
                visible={alertQuoteModal}
                onClose={() => setAlertQuoteModal(false)}
                sendAllEmail={sendEmailToAllVendors}
                publisherList={publisherList ?? []}
                unsentPublisher={unsentPublisher}
            />
        </Modal>
    );
};
