import React from "react";
import { Card, CardBody } from "../../../../../_metronic/_partials/controls";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Progress } from "antd";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { sum } from "lodash";
const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: "#BDBDBD",
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "#F2C94C",
  },
})(LinearProgress);
const BorderLinearProgressOrange = withStyles({
  root: {
    height: 6,
    backgroundColor: "#BDBDBD",
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "#D7042B",
  },
})(LinearProgress);
const useStyles1 = makeStyles((theme) => ({
  bigDiv: {
    borderRadius: "0px 12px 12px 12px",
    backgroundColor: "white",
    padding: "2rem 150px",
  },
  stat: { background: "#F2F2F2", borderRadius: "20px", padding: "10px 0 20px" },
  denyButton: {
    margin: "8px",
    width: "150px",
    height: "48px",
    borderRadius: "100px",
    border: "solid 1px #012875",
    backgroundColor: "#ffffff",
    fontWeight: "600",
    color: "#012875",
    fontSize: "15px",
  },
  acceptButton: {
    margin: "8px",
    width: "150px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "#012875",
    border: "none",
    color: "white",
    fontWeight: "600",
    fontSize: "16px",
    "&:disabled": {
      backgroundColor: "#e0e0e0",
      color: "#828282",
      "&:hover": {
        cursor: "not-allowed",
      },
    },
  },
  invertButton: {
    margin: "8px",
    width: "150px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "white",
    border: "1px solid #012875",
    color: "#012875",
    fontWeight: "600",
    fontSize: "16px",
  },
  divButton: { marginTop: "32px", textAlign: "right" },
  blue_p: {
    fontSize: 18,
    fontWeight: 600,
    color: "#012875",
    marginBottom: "6px",
  },
  blue_p_mini: {
    fontSize: 15,
    fontWeight: 500,
    color: "#012875",
    marginBottom: "6px",
  },
  blue_p_small: {
    fontSize: 14,
    fontWeight: 400,
    color: "#012875",
    display: "inline-block",
  },

  dropzoneTitle: { marginBottom: "0px", fontSize: "16px" },
  dropzoneSubTitle: { marginBottom: "36px", fontSize: "14px" },

  progress_mini: {
    margin: "4px 0px 8px",
    borderRadius: "5px",
  },
  progressUpload: {
    margin: "3px 13px 47px",
    borderRadius: "5px",
  },
  information: {
    backgroundColor: "#012875",
    width: 16,
    height: 16,
    borderRadius: 8,
    textAlign: "center",
    margin: "0 0 0 6px",
    display: "inline-block",
    "&:hover": { cursor: "pointer" },
  },
  subProgressLeft: {
    margin: 0,
    fontFamily: "Prompt",
    fontSize: "12px",
    fontWeight: "500",
    float: "left",
    color: "#FF5337",
  },
  subProgressRight: {
    margin: 0,
    fontFamily: "Prompt",
    fontSize: "12px",
    fontWeight: "500",
    float: "right",
    color: "#FF5337",
  },
  uploadedImg: {
    padding: "16px",
    height: "240px",
    maxWidth: "100%",
  },
}));
const MultiColorProgressBar = (props) => {
    console.log(props);
  let bars =
    props.readings &&
    props.readings.length &&
    props.readings.map(function(item, i) {
      if (item.value > 0 && props.readings[0] !== null) {
        return (
          <div
            className="bar"
            style={{
              backgroundColor: item.color,
              width: item.value + "%",
              height: "16px",
            }}
            key={i}
          ></div>
        );
      } else if (item.value > 0 && props.readings[0] === null) {
        return (
          <div
            className="bars-nth2"
            style={{
              backgroundColor: item.color,
              width: item.value + "%",
              height: "16px",
            }}
            key={i}
          ></div>
        );
      }
    }, this);
  return (
    <div className="multicolor-bar">
      <div className="bars">{bars == "" ? "" : bars}</div>
    </div>
  );
};
export const DashboardMini = ({
  storageUsedNow,
  storageData,
  titleData,
  onlyStorage,
  titleUsedNow,
  usedStorage,
}) => {
  // console.log(storageData.usedStorage, "storageData");

  let storage = (storageData.totalStorage * storageData.percentage) / 100;

  let percent = storageData.percentage / 100;
  let storageLeft = storageData.totalStorage - storage;
  let percentLeft = (storageLeft / storageData.totalStorage) * 100 - 4;
  // let title = (titleData.totalTitle * titleData.percentagex) / 100;
  //-------------------- usedStorage------------------
  let totalMutimedia = storageData?.usedStorage;
  let total = totalMutimedia?.total;
  let ebook = (totalMutimedia?.ebook / total) * 100;
  let video = (totalMutimedia?.video / total) * 100;
  let podcast = (totalMutimedia?.podcast / total) * 100;
  let audioBook = (totalMutimedia?.audioBook / total) * 100;
  let ebookpercantage = ebook * percent + 1;
  
  let videopercantage = video * percent + 1;
  let podcastpercantage = podcast * percent + 1;
  let audioBookpercantage = audioBook * percent + 1;
  // console.log(ebookpercantage + 1);
  //-------------------- usedusedTitle------------------
  //-------------------- usedusedTitle------------------
  //-------------------- usedusedTitle------------------
  let title = (titleData.totalTitle * titleData.percentage) / 100;
  let percentTitle = titleData?.percentage / 100;
  let titleLeft = titleData.totalTitle - title;
  let percentTitleLeft = (titleLeft / titleData.totalTitle) * 100 - 4;
  let totalMutimediausedTitle = titleData?.usedTitle;
  let totalusedTitle = totalMutimediausedTitle?.total;
  let ebookusedTitle = (totalMutimediausedTitle?.ebook / totalusedTitle) * 100;
  let videousedTitle = (totalMutimediausedTitle?.video / totalusedTitle) * 100;
  let podcastusedTitle =
    (totalMutimediausedTitle?.podcast / totalusedTitle) * 100;
  let audioBookusedTitle =
    (totalMutimediausedTitle?.audioBook / totalusedTitle) * 100;
  let ebookusedTitlePercent = ebookusedTitle * percentTitle + 1;
  let videousedTitlePercent = videousedTitle * percentTitle + 1;
  let podcastusedTitlePercent = podcastusedTitle * percentTitle + 1;
  let audioBookusedTitlePercent = audioBookusedTitle * percentTitle + 1;
  //   console.log(
  //     ebookusedTitlePercent,
  //     videousedTitlePercent,
  //     podcastusedTitlePercent,
  //     audioBookusedTitlePercent
  //   );
  const getStoragePercent = storageUsedNow
    ? (
        (storageData.usedStorage + storageUsedNow / 1024 / 1024) /
        storageData.totalStorage
      ).toFixed(2) * 100
    : storageData.totalStorage
    ? ((storageData.usedStorage * 100) / storageData.totalStorage).toFixed(2)
    : 0;
  const getTitlePercent =
    titleData.percentage !== undefined
      ? titleData.percentage.toFixed(2) +
        ((titleUsedNow ? titleUsedNow : 0) / titleData.totalTitle) * 100
      : 0;

  let AllusedStorage = [
    {
      name: "อีบุ๊ก",
      value: ebookpercantage,
      color: "#f2c94c",
    },
    {
      name: "วิดีโอ",
      value: videopercantage,
      color: "#27ae60",
    },
    {
      name: "พ็อดคาสท์",
      value: podcastpercantage,
      color: "#bb6bd9",
    },
    {
      name: "หนังสือเสียง",
      value: audioBookpercantage,
      color: "#2f80ed",
    },
    // { name: "", value: percentLeft, color: "#cccc" },
  ];
  let AllusedTitle = [
    {
      name: "อีบุ๊ก",
      value: ebookusedTitlePercent,
      color: "#f2c94c",
    },
    {
      name: "วิดีโอ",
      value: videousedTitlePercent,
      color: "#27ae60",
    },
    {
      name: "พ็อดคาสท์",
      value: podcastusedTitlePercent,
      color: "#bb6bd9",
    },
    {
      name: "หนังสือเสียง",
      value: audioBookusedTitlePercent,
      color: "#2f80ed",
    },
    // { name: "", value: percentTitleLeft, color: "#cccc" },
  ];
  const storageZone = (col) => {
    return (
      <div className={`col-${col}`}>
        <div className="head-progress">
          <div className="text-ebook-gray">พื้นที่จัดเก็บข้อมูลสื่อ</div>
          <div className="text-ebook-gray-small ">
            ใช้ไป{"  "}
            {storageData && storage !== undefined
              ? storage < 1024
                ? `${(storage + storageUsedNow / 1024 / 1024).toFixed(2)} MB`
                : `${(
                    storage / 1024 +
                    storageUsedNow / 1024 / 1024 / 1024
                  ).toFixed(2)} GB`
              : "0"}
            {"  "}จาก{" "}
            {storageData && storageData.totalStorage !== undefined
              ? storageData.totalStorage < 1024
                ? `${(storageData.totalStorage ).toFixed(2)} MB`
                : `${(storageData.totalStorage / 1024).toFixed(2)} GB`
              : "0"}
          </div>
        </div>
        
        <MultiColorProgressBar
            readings={AllusedStorage}
            className="progress-upload"
          />
        {/* {getStoragePercent > 80 ? (
       
        ) : (
          // <BorderLinearProgressOrange
          //      className="progress-upload"
          //      variant="determinate"
          //      color="secondary"
          //      value={getStoragePercent}
          // />
          <MultiColorProgressBar
            readings={AllusedStorage}
            className="progress-upload"
          />
          // <BorderLinearProgress

          //      variant="determinate"
          //      color="secondary"
          //      value={getStoragePercent}
          // />
        )} */}
        <div className="dot-text-div">
          <div>
            <div className=" dot-text yellow "></div>
            <span>อีบุ๊ก</span>
          </div>
          <div>
            <div className=" dot-text green "></div>
            <span>วิดีโอ</span>
          </div>
          <div>
            <div className=" dot-text purple "></div>
            <span>พ็อดคาสท์</span>
          </div>
          <div>
            <div className=" dot-text blue "></div>
            <span>หนังสือเสียง</span>
          </div>
        </div>
      </div>
    );
  };
  const classes = useStyles1();
  return onlyStorage ? (
    storageZone(4)
  ) : (
    <Card className="storageCard">
      <CardBody>{console.log(storageData)}
        <div className={`row ${classes.stat}`}>
          {storageZone(6)}
          <div className="col-6">
            <div className="head-progress">
              <div className="text-ebook-gray">จำนวนรายการสื่อ</div>
              <div className="text-ebook-gray-small ">
                ใช้ไป{" "}
                {titleData && titleData?.usedTitle?.total !== undefined
                  ? titleData.usedTitle?.total +
                    (titleUsedNow ? titleUsedNow : 0)
                  : "0"}{" "}
                title จาก{" "}
                {titleData && titleData?.totalTitle !== undefined
                  ? titleData?.totalTitle
                  : "0"}{" "}
                title
              </div>
            </div>

            {getTitlePercent > 80 ? (
              <MultiColorProgressBar
                readings={AllusedTitle}
                className="progress-upload"
              />
            ) : (
              <MultiColorProgressBar
                readings={AllusedTitle}
                className="progress-upload"
              />
            )}
            <div className="dot-text-div">
              <div>
                <div className=" dot-text yellow "></div>
                <span>อีบุ๊ก</span>
              </div>
              <div>
                <div className=" dot-text green "></div>
                <span>วิดีโอ</span>
              </div>
              <div>
                <div className=" dot-text purple "></div>
                <span>พ็อดคาสท์</span>
              </div>
              <div>
                <div className=" dot-text blue "></div>
                <span>หนังสือเสียง</span>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
