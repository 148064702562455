import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { takeLatest } from 'redux-saga/effects';
import userTableMock from '../__mocks__/userTableMock';
import jwt_decode from 'jwt-decode';
import { UserTokenInfo, Role } from '../../../../interface';

export enum AuthenActionTypes {
    Login = '[Login] Action',
    Logout = '[Logout] Action',
    Register = '[Register] Action',
    ViewAs = '[ViewAs] Action',
    StopViewAs = '[StopViewAs] Action',
    UserRequested = '[Request User] Action',
    UserLoaded = '[Load User] Auth API',
    SaveNewPasswordToken = '[SaveNewPasswordToken] Action',
    DeleteNewPasswordToken = '[DeleteNewPasswordToken] Action',
    SetIsCuDomain = '[SetIsCuDomain] Action'
}
interface AuthenAction {
    type: AuthenActionTypes;
    payload: AuthenPayload;
}
interface AuthenPayload {
    authToken?: AuthenToken;
    userToken?: string;
    newPasswordToken?: string;
}
interface AuthenToken {
    userToken: string;
    client?: any;
}

export interface UserAuthentication {
    authToken?: string;
    role?: string;
    viewAsToken?: string;
    newPasswordToken?: string;
    email?: string;
    userInfo?: UserTokenInfo;
    isCuDomain?: boolean;
}

const getUserInfo = (userToken: string): UserTokenInfo => {
    const jwtObject: UserTokenInfo = jwt_decode(userToken);
    // console.log("jwtObject ", jwtObject);
    return jwtObject;
};

const initialAuthState: UserAuthentication = {
    authToken: undefined,
    role: undefined,
    viewAsToken: undefined,
    newPasswordToken: undefined,
    email: undefined,
    isCuDomain: undefined
};

export const reducer = persistReducer(
    {
        storage,
        key: 'hibrary-admin-auth',
        whitelist: [
            'userInfo',
            'authToken',
            'role',
            'email',
            'viewAsToken',
            'isCuDomain'
        ]
    },
    (
        state: UserAuthentication = initialAuthState,
        action: AuthenAction
    ): UserAuthentication => {
        // console.log("action.type ", action.type, action.payload);
        switch (action.type) {
            case AuthenActionTypes.Login: {
                const { authToken } = action.payload;
                if (authToken) {
                    const userToken = authToken?.userToken;
                    const themes = authToken?.client
                        ? authToken?.client.themes
                        : {};
                    return {
                        authToken: userToken,
                        role: getUserInfo(userToken).role, //=== "super" ? "superadmin" : "",
                        email: getUserInfo(userToken).email,
                        userInfo: getUserInfo(userToken),
                        isCuDomain: state.isCuDomain
                    };
                }
                return initialAuthState;
            }

            case AuthenActionTypes.ViewAs: {
                const { userToken } = action.payload;
                // const userToken = authToken.userToken;
                // const userToken = action.payload.userToken;
                return { ...state, viewAsToken: userToken };
            }
            case AuthenActionTypes.StopViewAs: {
                return { ...state, viewAsToken: undefined };
            }

            case AuthenActionTypes.SaveNewPasswordToken: {
                const token = action.payload.newPasswordToken;
                return { ...state, newPasswordToken: token };
            }

            case AuthenActionTypes.DeleteNewPasswordToken: {
                return { ...state, newPasswordToken: undefined };
            }
            case AuthenActionTypes.SetIsCuDomain: {
                return { ...state, isCuDomain: action.payload as boolean };
            }

            // case AuthenActionTypes.Register: {
            //   const { authToken } = action.payload;
            //   return { authToken, user: undefined };
            // }

            case AuthenActionTypes.Logout: {
                // TODO: Change this code. Actions in reducer aren't allowed.
                console.log(state.isCuDomain);
                return Object.assign(initialAuthState, {
                    isCuDomain: state.isCuDomain
                });

                // if(state.isCuDomain){
                //   return Object.assign(initialAuthState,{isCuDomain : state.isCuDomain});
                // }else{
                //   return initialAuthState;
                // }
            }

            // case AuthenActionTypes.UserLoaded: {
            //   const { user } = action.payload;
            //   return { ...state, user };
            // }

            default:
                return state;
        }
    }
);

export const actions = {
    login: (authToken: string) => ({
        type: AuthenActionTypes.Login,
        payload: { authToken: authToken }
    }),
    viewAs: (userToken: string) => ({
        type: AuthenActionTypes.ViewAs,
        payload: { userToken: userToken }
    }),
    saveNewPasswordToken: (token: string) => ({
        type: AuthenActionTypes.SaveNewPasswordToken,
        payload: { newPasswordToken: token }
    }),
    stopViewAs: () => ({
        type: AuthenActionTypes.StopViewAs
    }),
    deleteNewPasswordToken: () => ({
        type: AuthenActionTypes.DeleteNewPasswordToken
    }),
    register: (authToken: string) => ({
        type: AuthenActionTypes.Register,
        payload: { authToken }
    }),
    logout: () => ({ type: AuthenActionTypes.Logout }),
    // requestUser: (user) => ({ type: actionTypes.UserRequested, payload: { user } }),
    fulfillUser: (user: string) => ({
        type: AuthenActionTypes.UserLoaded,
        payload: { user }
    }),
    setIsCuDomain: (isCuDomain: boolean) => ({
        type: AuthenActionTypes.SetIsCuDomain,
        payload: isCuDomain
    })
};

export function* saga() {
    yield takeLatest(AuthenActionTypes.Login, function* loginSaga() {
        // yield put(actions.requestUser());
    });

    yield takeLatest(AuthenActionTypes.Register, function* registerSaga() {
        // yield put(actions.requestUser());
    });

    // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    //   const { data: user } = yield getUserByToken();
    //   console.log(user);

    //   yield put(actions.fulfillUser(user));
    // });
}
