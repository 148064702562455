/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
   Card,
   CardBody,
   CardHeader,
   CardHeaderToolbar
} from '../../../../../_metronic/_partials/controls';
import { useHistory } from 'react-router-dom';
import { Empty } from 'antd';
import { SubscriptionCard } from './SubscriptionCard';
import { CatalogueUIProvider } from '../catalogue/CatalogueUIContext';
import { ClipLoader } from 'react-spinners';
import axios from 'axios';
const loaderHeight = 50;
export function SubscriptionPage(props) {
   const history = useHistory();
   const [subscript, setSubscript] = useState(false);
   useEffect(() => {}, []);
   const useStyles1 = makeStyles(theme => ({
      div: {
         marginTop: 0
      },
      p1: {
         fontFamily: 'Prompt',
         fontSize: '20px',
         fontWeight: 600,
         color: '#012875',
         marginBottom: 0
      }
   }));
   const classes = useStyles1();
   const [loading, setLoading] = useState(true);
   const [subscriptionList, setSubscriptionList] = useState([]);
   const fetchSubscriptionList = async () => {
      try {
         const req = await axios.get('/superuser/subscriptions');
         if (req.data.status && req.data.data) {
            setSubscriptionList(req.data.data.subscriptions);
         }
         setLoading(false);
      } catch (err) {
         setLoading(false);
      }
   };
   useEffect(() => {
      fetchSubscriptionList();
   }, []);
   return (
      <Card className="cardStyle">
         {/* <CatalogueUIProvider catalogueUIEvents={catalogueUIEvents}> */}
         <CardHeader
            title={
               <div className={classes.div}>
                  <p className={classes.p1}>Subscription</p>
               </div>
            }
         >
            <CardHeaderToolbar>
               <button
                  type="button"
                  className="button "
                  // style={{ lineHeight: "44px" }}
                  onClick={() => {
                     history.push({
                        pathname: '/upload-subscription',
                        state: { subscript: subscript }
                     });
                  }}
               >
                  <span>สร้าง Subscription</span>
               </button>
            </CardHeaderToolbar>
         </CardHeader>
         <CardBody>
            {loading ? (
               <div className="cliploader-center">
                  <ClipLoader
                     size={loaderHeight}
                     color={'#123abc'}
                     loading={true}
                  />
               </div>
            ) : subscriptionList && subscriptionList.length > 0 ? (
               <SubscriptionCard
                  subscriptionList={subscriptionList}
                  fetchSubscriptionList={fetchSubscriptionList}
               />
            ) : (
               <div
                  style={{
                     //  margin: "50px auto",
                     textAlign: 'center',
                     width: '100%',
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     height: 'calc(100% - 100px)',
                     flexFlow: 'column'
                  }}
               >
                  <Empty description={false} />
                  <p
                     style={{
                        color: '#012875',
                        marginBottom: '0',
                        fontSize: '20px',
                        fontWeight: '600'
                     }}
                  >
                     ไม่มีข้อมูล
                  </p>
               </div>
            )}
         </CardBody>
         {/* </CatalogueUIProvider> */}
      </Card>
   );
}
