import { makeStyles } from "@material-ui/core";
import { Col, Empty, Row } from "antd";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  LastEvaluatedKeyContentJob,
  ListJob,
  ListJobStatus,
} from "../../../../../interface/ebook";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { LoaderView } from "../../../CustomView";
import {
  getInsertHibraryContentJob,
  getUploadFileAddToStore,
} from "../../_redux/ebooks/ebooksCrud";

function ListUploadedContentPage() {
  const history = useHistory();
  const [listJob, setListJob] = useState<ListJob[]>();
  const [lastEvaluateKey, setLastEvaluateKey] = useState<
    LastEvaluatedKeyContentJob
  >();
  const [loadUIDList, setLoadUIDList] = useState<string[]>();
  const [loadingPage, setLoadingPage] = useState(true);
  useEffect(() => {
    getInsertHibraryContentJob().then((res) => {
      setListJob(res.data?.listJob);
      setLastEvaluateKey(res.data?.lastEvaluatedKey);
      setLoadingPage(false);
    });
  }, []);
  useEffect(() => {
    console.log(loadUIDList);
  }, [loadUIDList]);

  const useStyles1 = makeStyles((theme) => ({
    div: {
      marginTop: 16,
    },
    list_item_header: {
      margin: "10px 0px",
      paddingInline: 30,
    },
    item_card: {
      padding: 30,
      backgroundColor: "white",
      marginBottom: 10,
      borderRadius: 12,
    },
    button: {
      borderRadius: 100,
      backgroundColor: "#012875",

      // padding: "10px 22px",
      width: "110px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 40,
      color: "white",
      fontSize: 13,
      fontWeight: 600,
      fontFamily: "Prompt",
      border: "none",
    },
    header: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // backgroundColor:'white',
      // padding:20,
      // borderRadius:10,
      marginBottom: 20,
    },
    textTitle: {
      margin: 0,
    },
    elementCenter: {
      display: "flex",
      justifyContent: "center",
    },
    tableContainer: {
      position: "relative",
      height: "100%",
    },
    absoluteCenter: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  }));
  const classes = useStyles1();

  const downloadFile = async (reportUID: string) => {
    const { data } = await getUploadFileAddToStore(reportUID);
    let newLoadUIDList = loadUIDList?.filter((el) => el !== reportUID);
    setLoadUIDList(newLoadUIDList);
    if (data?.uploadFile) {
      const link = document.createElement("a");
      link.href = data.uploadFile;
      link.click();
    }
  };

  const getListJobStatus = (status: number) => {
    switch (status) {
      case ListJobStatus.Process:
        return "กำลังดำเนินการ";
      case ListJobStatus.Success:
        return "สำเร็จ";
      case ListJobStatus.SomethingWrong:
        return "พบข้อผิดพลาด";
      default:
        return "-";
    }
  };

  const requestedReportTemplate = (
    param1: string,
    param2: string,
    param3: string,
    param4: string,
    param5: any
  ) => {
    return (
      <Row style={{ alignItems: "center" }}>
        <Col span={4}>{param1}</Col>
        <Col span={5}>{param2}</Col>
        <Col span={5}>{param3}</Col>
        <Col span={5}>{param4}</Col>
        <Col span={5} className={classes.elementCenter}>
          {param5}
        </Col>
      </Row>
    );
  };
  return (
    <>
      <div className={classes.header}>
        <p className={`text-text-head ${classes.textTitle}`}>
          ประวัติการเพิ่มสื่อ
        </p>

        <button
          type="button"
          className="button-border"
          onClick={() => {
            history.push("/view-as-upload-content");
          }}
        >
          ย้อนกลับ
        </button>
      </div>
      <div className={classes.tableContainer}>
        <div className={classes.list_item_header}>
          {requestedReportTemplate(
            "วันที่สร้าง",
            "ชื่อผู้เพิ่ม",
            "สถานะ",
            "เลขที่สร้าง",
            "ดาวน์โหลด "
          )}
        </div>

        {listJob?.map((el) => {
          return (
            <div className={classes.item_card}>
              {requestedReportTemplate(
                el.registerDate
                  ? moment.unix(el.registerDate).format("DD/MM/YYYY")
                  : "-",
                el.memberName,
                getListJobStatus(el.status),
                el.reportUID,
                <button
                  type="button"
                  className={classes.button}
                  onClick={() => {
                    let newLoadUIDList = loadUIDList;
                    if (newLoadUIDList) {
                      newLoadUIDList.push(el.reportUID);
                    } else {
                      newLoadUIDList = [el.reportUID];
                    }
                    console.log(newLoadUIDList);

                    setLoadUIDList(newLoadUIDList);
                    downloadFile(el.reportUID);
                  }}
                >
                  <span>
                    {loadUIDList?.includes(el.reportUID)
                      ? "กำลังดาวน์โหลด..."
                      : "ดาวน์โหลด"}
                  </span>
                </button>
              )}
            </div>
          );
        })}
        {listJob === undefined || listJob.length === 0 ? (
          loadingPage ? (
            <LoaderView />
          ) : (
            <Empty className={classes.absoluteCenter} />
          )
        ) : null}
      </div>
    </>
  );
}

export default ListUploadedContentPage;
