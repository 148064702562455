/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Grid from '@material-ui/core/Grid';
import Spinner from '../../../_helpers/spinner';
import { Row, Col } from 'antd';

const useStyles1 = makeStyles(theme => ({
   root: {
      flexShrink: 0,
      color: theme.palette.text.secondary
   }
}));

const StyledPaper = withStyles(theme => ({
   root: {
      flexShrink: 0,
      color: theme.palette.text.secondary
      // marginLeft: theme.spacing(2.5),
   },
   elevation1: {
      boxShadow: 'none !important'
   }
}))(Paper);

function TablePaginationActions(props) {
   const classes = useStyles1();
   const theme = useTheme();
   const { count, page, rowsPerPage, onChangePage } = props;

   // function handleFirstPageButtonClick(event) {
   //   onChangePage(event, 0);
   // }

   function handleBackButtonClick(event) {
      onChangePage(event, page - 1);
   }

   function handleNextButtonClick(event) {
      onChangePage(event, page + 1);
   }

   // function handleLastPageButtonClick(event) {
   //   onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
   // }

   return (
      <div className={classes.root}>
         {/* <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton> */}
         <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="Previous Page"
         >
            {theme.direction === 'rtl' ? (
               <KeyboardArrowRight />
            ) : (
               <KeyboardArrowLeft />
            )}
         </IconButton>
         <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="Next Page"
         >
            {theme.direction === 'rtl' ? (
               <KeyboardArrowLeft />
            ) : (
               <KeyboardArrowRight />
            )}
         </IconButton>
         {/* <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton> */}
      </div>
   );
}

TablePaginationActions.propTypes = {
   count: PropTypes.number.isRequired,
   onChangePage: PropTypes.func.isRequired,
   page: PropTypes.number.isRequired,
   rowsPerPage: PropTypes.number.isRequired
};

const useStyles2 = makeStyles(theme => ({
   root: {
      width: '100%',
      padding: '6px 30px 0',
      height: 'calc(100% - 70px)'
      // minHeight: '400px'
   },
   table: {
      width: '100%'
      // height: "calc(100vh - 453px)"
   },
   tableWrapper: {
      // overflowX: "auto",
      // transform: "translateY(1px)",
      height: '100%'
   },

   borrowedName: {
      fontFamily: 'Prompt',
      fontSize: '14px',
      fontWeight: 500,
      color: '#333333',
      marginBottom: 2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      display: '-webkit-box',
      maxWidth: '100%',
      boxOrient: 'vertical'
   },
   ebookCount: {
      fontFamily: 'Prompt',
      fontWeight: 600,
      margin: 'auto',
      color: '#828282',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      fontSize: '17px',
      width: 40,
      height: 25,
      borderRadius: 4
   },
   queueCount: {
      fontFamily: 'Prompt',
      fontWeight: 600,
      margin: 'auto',
      color: '#ff5337',
      backgroundColor: 'rgba(255, 83, 55, 0.2)',
      // fontSize: "18px",
      // width: 74,
      // height: 26,
      // borderRadius: 6,
      fontSize: '17px',
      width: 40,
      height: 25,
      borderRadius: 4
   },
   queueCountBlue: {
      fontFamily: 'Prompt',
      fontWeight: 600,
      margin: 'auto',
      color: '#012875',
      backgroundColor: 'rgba(1, 40, 117, 0.2)',
      // fontSize: "18px",
      // width: 74,
      // height: 26,
      // borderRadius: 6,
      fontSize: '17px',
      width: 40,
      height: 25,
      borderRadius: 4
   },
   borrowedNameSub: {
      fontFamily: 'Prompt',
      fontSize: '12px',
      fontWeight: 500,
      color: '#b5b5c3',
      marginBottom: -8,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      display: '-webkit-box',
      maxWidth: '100%',
      boxOrient: 'vertical'
   },
   coverImage: {
      width: '66px',
      height: '100px',
      backgroundColor: '#000000',
      margin: '12px 0',
      imageRendering: '-webkit-optimize-contrast',
      '-webkit-filter': 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))',
      filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))'
   },
   divScroll: {
      height: 'calc(100vh - 500px)',
      overflow: 'auto'
   },
   limitLine: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 1,
      maxWidth: '100%'
   }
}));

const StyledTableCell = withStyles(theme => ({
   head: {
      backgroundColor: '#f3f6f9',
      color: '#828282',
      fontFamily: 'Prompt',
      fontSize: 13,
      fontWeight: 600
   }
}))(TableCell);

const StyledTableCell100px = withStyles(theme => ({
   head: {
      backgroundColor: '#f3f6f9',
      color: '#828282',
      fontFamily: 'Prompt',
      fontSize: 13,
      fontWeight: 600,
      width: '100px'
   }
}))(TableCell);

export function BorrowingQueue({ className, data, loaded }) {
   const classes = useStyles2();
   const [rows, setRows] = useState([]);
   const [page, setPage] = React.useState(0);
   // const rowsPerPage = 8;
   const rowHeight = 115.25;
   const rowsPerPage = 4;
   // const rowHeight = 96;

   const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

   function handleChangePage(event, newPage) {
      setPage(newPage);
   }

   useEffect(() => {
      setRows(data);
      // setRows([
      //   {
      //     contentUID: "d90f6cde-74c8-49af-b7c1-ebe14114f5b7",
      //     activeLicense: 1,
      //     concurrent: 1,
      //     waitingAmount: 1,
      //     title: "basic python coding เรียนง่ายเป็นเร็ว",
      //     authorName: "สุชาติ คุ้มมะณี",
      //     coverImage:
      //       "https://s3-ap-southeast-1.amazonaws.com/htiebook.content/images/coverImages/medium/d90f6cde-74c8-49af-b7c1-ebe14114f5b7.jpg",
      //     fileType: "pdf"
      //   },
      //   {
      //     contentUID: "d90f6cde-74c8-49af-b7c1-ebe14114f5b7",
      //     activeLicense: 1,
      //     concurrent: 1,
      //     waitingAmount: 1,
      //     title: "basic python coding เรียนง่ายเป็นเร็ว",
      //     authorName: "สุชาติ คุ้มมะณี",
      //     coverImage:
      //       "https://s3-ap-southeast-1.amazonaws.com/htiebook.content/images/coverImages/medium/d90f6cde-74c8-49af-b7c1-ebe14114f5b7.jpg",
      //     fileType: "pdf"
      //   },
      //   {
      //     contentUID: "d90f6cde-74c8-49af-b7c1-ebe14114f5b7",
      //     activeLicense: 1,
      //     concurrent: 1,
      //     waitingAmount: 1,
      //     title: "basic python coding เรียนง่ายเป็นเร็ว",
      //     authorName: "สุชาติ คุ้มมะณี",
      //     coverImage:
      //       "https://s3-ap-southeast-1.amazonaws.com/htiebook.content/images/coverImages/medium/d90f6cde-74c8-49af-b7c1-ebe14114f5b7.jpg",
      //     fileType: "pdf"
      //   },
      //   {
      //     contentUID: "d90f6cde-74c8-49af-b7c1-ebe14114f5b7",
      //     activeLicense: 1,
      //     concurrent: 1,
      //     waitingAmount: 1,
      //     title: "basic python coding เรียนง่ายเป็นเร็ว",
      //     authorName: "สุชาติ คุ้มมะณี",
      //     coverImage:
      //       "https://s3-ap-southeast-1.amazonaws.com/htiebook.content/images/coverImages/medium/d90f6cde-74c8-49af-b7c1-ebe14114f5b7.jpg",
      //     fileType: "pdf"
      //   }
      // ]);
   }, [data]);

   return (
      <div className={`card card-custom ${className}`}>
         {/* Head */}
         <div
            className="card-header border-0 pt-5"
            style={{ marginBottom: '-14px', paddingTop: '16px !important' }}
         >
            <h3 className="card-title align-items-start flex-column">
               <span className="title">จองคิว</span>
            </h3>
            {/* <div className="card-toolbar">
          <Tab.Container defaultActiveKey={key}>
            <Nav
              as="ul"
              onSelect={(_key) => setKey(_key)}
              className="nav nav-pills nav-pills-sm nav-dark-75"
            >
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Month"
                  className={`nav-link py-2 px-4 ${key === "Month" ? "active" : ""}`}
                >
                  Month
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Week"
                  className={`nav-link py-2 px-4 ${key === "Week" ? "active" : ""}`}
                >
                  Week
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Day"
                  className={`nav-link py-2 px-4 ${key === "Day" ? "active" : ""}`}
                >
                  Day
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div> */}
         </div>
         {/* Body */}
         <StyledPaper className={classes.root}>
            <div className={classes.tableWrapper}>
               <Table className={classes.table}>
                  <TableHead>
                     <TableRow>
                        <StyledTableCell
                           style={{ fontSize: '18px', color: '#333333' }}
                        >
                           อีบุ๊ก
                        </StyledTableCell>
                        <StyledTableCell100px align="center">
                           จำนวนอีบุ๊ก
                        </StyledTableCell100px>
                        <StyledTableCell100px align="center">
                           คงเหลือ
                        </StyledTableCell100px>
                        <StyledTableCell100px align="center">
                           จองคิวทั้งหมด
                        </StyledTableCell100px>
                     </TableRow>
                  </TableHead>
               </Table>
               {/*<TableBody>*/}
               {rows && rows.length === 0 ? (
                  loaded ? (
                     <div
                        style={{
                           // margin: "calc(88% - 140px) auto",
                           // position: "absolute",
                           textAlign: 'center',
                           width: '100%',
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           height: '42vh',
                           flexFlow: 'column'
                        }}
                     >
                        <p
                           style={{
                              color: '#012875',
                              marginBottom: '0',
                              fontSize: '20px',
                              fontWeight: '600'
                           }}
                        >
                           ไม่มีการจองคิว
                        </p>
                        <img src="/media/members/no-queue.png" alt="" />
                     </div>
                  ) : (
                     <Spinner forDashboard={true} />
                  )
               ) : (
                  <div className={`${classes.divScroll} noMarginRow`}>
                     {rows
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                           <Row
                              key={index}
                              style={{
                                 height: `${rowHeight}px`,
                                 alignItems: 'center'
                              }}
                           >
                              <Col
                                 align="left"
                                 style={{
                                    padding: '0',
                                    width: 'calc(100% - 300px)'
                                 }}
                              >
                                 {/* component="th" scope="row" */}
                                 <Grid
                                    container
                                    spacing={3}
                                    style={{ margin: '0' }}
                                 >
                                    <img
                                       src={
                                          row && row.coverImage
                                             ? row.coverImage
                                             : '/media/members/placeholder-image.png'
                                       }
                                       className={classes.coverImage}
                                       alt=""
                                    />
                                    <Grid
                                       item
                                       xs={7}
                                       style={{ padding: '12px' }}
                                    >
                                       <p className={classes.borrowedName}>
                                          {row.title}
                                       </p>
                                       <p className={classes.borrowedNameSub}>
                                          {row.authorName}
                                       </p>
                                    </Grid>
                                 </Grid>
                              </Col>
                              <Col
                                 align="center"
                                 style={{ padding: '0', width: '100px' }}
                              >
                                 <p className={classes.ebookCount}>
                                    {row.concurrent}
                                 </p>
                              </Col>
                              <Col
                                 align="center"
                                 style={{ padding: '16px 0px', width: '100px' }}
                              >
                                 <p className={classes.queueCount}>
                                    {row.concurrent - row.activeLicense}
                                 </p>
                              </Col>
                              <Col
                                 align="center"
                                 style={{ padding: '0', width: '100px' }}
                              >
                                 <p className={classes.queueCountBlue}>
                                    {row.waitingAmount
                                       ? row.waitingAmount
                                       : '-'}
                                 </p>
                              </Col>
                           </Row>
                        ))}
                  </div>
               )}

               {/*emptyRows > 0 && (
                <TableRow style={{ height: rowHeight * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )
            </TableBody>*/}
               {/*<TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={4}
                  count={rows.length}
                  rowsPerPage={4}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "Rows per page" },
                    native: true
                  }}
                  onChangePage={handleChangePage}
                  // onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
                </TableFooter>*/}
            </div>
         </StyledPaper>
         {/* <div className="card-body pt-3 pb-0">
        <div className="table-responsive">
          <table className="table table-borderless table-vertical-center">
            <thead>
              <tr>
                <th className="p-0" style={{ width: "50px" }} />
                <th className="p-0" style={{ minWidth: "200px" }} />
                <th className="p-0" style={{ minWidth: "100px" }} />
                <th className="p-0" style={{ minWidth: "125px" }} />
                <th className="p-0" style={{ minWidth: "110px" }} />
                <th className="p-0" style={{ minWidth: "150px" }} />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="pl-0 py-4">
                  <div className="symbol symbol-50 symbol-light mr-1">
                    <span className="symbol-label">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/misc/006-plurk.svg")}
                        className="h-50 align-self-center"
                      ></SVG>
                    </span>
                  </div>
                </td>
                <td className="pl-0">
                  <a
                    href="#"
                    className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >
                    Sant Outstanding
                  </a>
                  <div>
                    <span className="font-weight-bolder">Email:</span>{" "}
                    <a className="text-muted font-weight-bold text-hover-primary" href="#">
                      bprow@bnc.cc
                    </a>
                  </div>
                </td>
                <td className="text-right">
                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                    $2,000,000
                  </span>
                  <span className="text-muted font-weight-bold">Paid</span>
                </td>
                <td className="text-right">
                  <span className="text-muted font-weight-500">ReactJs, HTML</span>
                </td>
                <td className="text-right">
                  <span className="label label-lg label-light-primary label-inline">Approved</span>
                </td>
                <td className="text-right pr-0">
                  <a href="#" className="btn btn-icon btn-light btn-sm">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}></SVG>
                    </span>
                  </a>
                  <a href="#" className="btn btn-icon btn-light btn-sm mx-3">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>
                    </span>
                  </a>
                  <a href="#" className="btn btn-icon btn-light btn-sm">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}></SVG>
                    </span>
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pl-0 py-4">
                  <div className="symbol symbol-50 symbol-light">
                    <span className="symbol-label">
                      <SVG
                        className="h-50 align-self-center"
                        src={toAbsoluteUrl("/media/svg/misc/015-telegram.svg")}
                      ></SVG>
                    </span>
                  </div>
                </td>
                <td className="pl-0">
                  <a
                    href="#"
                    className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >
                    Application Development
                  </a>
                  <div>
                    <span className="font-weight-bolder">Email:</span>{" "}
                    <a className="text-muted font-weight-bold text-hover-primary" href="#">
                      app@dev.com
                    </a>
                  </div>
                </td>
                <td className="text-right">
                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                    $4,600,000
                  </span>
                  <span className="text-muted font-weight-bold">Paid</span>
                </td>
                <td className="text-right">
                  <span className="text-muted font-weight-500">Python, MySQL</span>
                </td>
                <td className="text-right">
                  <span className="label label-lg label-light-warning label-inline">
                    In Progress
                  </span>
                </td>
                <td className="text-right pr-0">
                  <a href="#" className="btn btn-icon btn-light btn-sm">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}></SVG>
                    </span>
                  </a>
                  <a href="#" className="btn btn-icon btn-light btn-sm mx-3">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>
                    </span>
                  </a>
                  <a href="#" className="btn btn-icon btn-light btn-sm">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}></SVG>
                    </span>
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pl-0 py-4">
                  <div className="symbol symbol-50 symbol-light">
                    <span className="symbol-label">
                      <SVG
                        className="h-50 align-self-center"
                        src={toAbsoluteUrl("/media/svg/misc/003-puzzle.svg")}
                      ></SVG>
                    </span>
                  </div>
                </td>
                <td className="pl-0">
                  <a
                    href="#"
                    className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >
                    Payrol Application
                  </a>
                  <div>
                    <span className="font-weight-bolder">Email:</span>{" "}
                    <a className="text-muted font-weight-bold text-hover-primary" href="#">
                      company@dev.com
                    </a>
                  </div>
                </td>
                <td className="text-right">
                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                    $560,000
                  </span>
                  <span className="text-muted font-weight-bold">Paid</span>
                </td>
                <td className="text-right">
                  <span className="text-muted font-weight-500">Laravel, Metronic</span>
                </td>
                <td className="text-right">
                  <span className="label label-lg label-light-success label-inline">Success</span>
                </td>
                <td className="text-right pr-0">
                  <a href="#" className="btn btn-icon btn-light btn-sm">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}></SVG>
                    </span>
                  </a>
                  <a href="#" className="btn btn-icon btn-light btn-sm mx-3">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>
                    </span>
                  </a>
                  <a href="#" className="btn btn-icon btn-light btn-sm">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}></SVG>
                    </span>
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pl-0 py-4">
                  <div className="symbol symbol-50 symbol-light">
                    <span className="symbol-label">
                      <SVG
                        className="h-50 align-self-center"
                        src={toAbsoluteUrl("/media/svg/misc/005-bebo.svg")}
                      ></SVG>
                    </span>
                  </div>
                </td>
                <td className="pl-0">
                  <a
                    href="#"
                    className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >
                    HR Management System
                  </a>
                  <div>
                    <span className="font-weight-bolder">Email:</span>{" "}
                    <a className="text-muted font-weight-bold text-hover-primary" href="#">
                      hr@demo.com
                    </a>
                  </div>
                </td>
                <td className="text-right">
                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                    $57,000
                  </span>
                  <span className="text-muted font-weight-bold">Paid</span>
                </td>
                <td className="text-right">
                  <span className="text-muted font-weight-bold">AngularJS, C#</span>
                </td>
                <td className="text-right">
                  <span className="label label-lg label-light-danger label-inline">Rejected</span>
                </td>
                <td className="text-right pr-0">
                  <a href="#" className="btn btn-icon btn-light btn-sm">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}></SVG>
                    </span>
                  </a>
                  <a href="#" className="btn btn-icon btn-light btn-sm mx-3">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>
                    </span>
                  </a>
                  <a href="#" className="btn btn-icon btn-light btn-sm">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}></SVG>
                    </span>
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pl-0 py-4">
                  <div className="symbol symbol-50 symbol-light">
                    <span className="symbol-label">
                      <SVG
                        className="h-50 align-self-center"
                        src={toAbsoluteUrl("/media/svg/misc/014-kickstarter.svg")}
                      ></SVG>
                    </span>
                  </div>
                </td>
                <td className="pl-0">
                  <a
                    href="#"
                    className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >
                    KTR Mobile Application
                  </a>
                  <div>
                    <span className="font-weight-bolder">Email:</span>{" "}
                    <a className="text-muted font-weight-bold text-hover-primary" href="#">
                      ktr@demo.com
                    </a>
                  </div>
                </td>
                <td className="text-right">
                  <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                    $45,200,000
                  </span>
                  <span className="text-muted font-weight-bold">Paid</span>
                </td>
                <td className="text-right">
                  <span className="text-muted font-weight-500">ReactJS, Ruby</span>
                </td>
                <td className="text-right">
                  <span className="label label-lg label-light-warning label-inline">
                    In Progress
                  </span>
                </td>
                <td className="text-right pr-0">
                  <a href="#" className="btn btn-icon btn-light btn-sm">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}></SVG>
                    </span>
                  </a>
                  <a href="#" className="btn btn-icon btn-light btn-sm mx-3">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}></SVG>
                    </span>
                  </a>
                  <a href="#" className="btn btn-icon btn-light btn-sm">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}></SVG>
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
      </div>
   );
}
