import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const initialFilter = {
  filter: {
    firstName: "",
    lastName: "",
    email: "",
    ipAddress: ""
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 50,
  limit: 50, //10
  totalSize: 9 //จำนวนสมาชิก (ไม่ได้ใช้แล้ว)
};

const PodcastsUIContext = createContext();

export function usePodcastsUIContext() {
  return useContext(PodcastsUIContext);
}

export const PodcastsUIConsumer = PodcastsUIContext.Consumer;

export function PodcastsUIProvider({ podcastsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initPodcast = {
    id: undefined,
    name: ""
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initPodcast,
    openFileInformationDialog: podcastsUIEvents.openFileInformationDialog,
    newPodButtonClick: podcastsUIEvents.newPodButtonClick,
    newPodcastButtonClick: podcastsUIEvents.newPodcastButtonClick,
    openEditPodDialog: podcastsUIEvents.openEditPodDialog,
    openEditPodcastDialog: podcastsUIEvents.openEditPodcastDialog,
    openBlockPodDialog: podcastsUIEvents.openBlockPodDialog,
    openBlockPodcastDialog: podcastsUIEvents.openBlockPodcastDialog,
    openUnblockPodDialog: podcastsUIEvents.openUnblockPodDialog,
    openUnblockPodcastDialog: podcastsUIEvents.openUnblockPodcastDialog,
    openDeletePodDialog: podcastsUIEvents.openDeletePodDialog,
    openDeletePodcastDialog: podcastsUIEvents.openDeletePodcastDialog,
    newChannelButtonClick: podcastsUIEvents.newChannelButtonClick,
    newCreatorButtonClick: podcastsUIEvents.newCreatorButtonClick,
    newCategoryButtonClick: podcastsUIEvents.newCategoryButtonClick,
    newSubCategoryButtonClick: podcastsUIEvents.newSubCategoryButtonClick,
    openViewPodcastDialog: podcastsUIEvents.openViewPodcastDialog
  };

  return (
    <PodcastsUIContext.Provider value={value}>
      {children}
    </PodcastsUIContext.Provider>
  );
}
