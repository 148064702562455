import React, { useState, useEffect } from "react";
import { CategoriesLoadingDialog } from "./categories-loading-dialog/CategoriesLoadingDialog";
import { CategoryNewDialog } from "./category-new-dialog/CategoryNewDialog";
import { CategoryEditDialog } from "./category-edit-dialog/CategoryEditDialog";
import { CategoryDeleteDialog } from "./category-delete-dialog/CategoryDeleteDialog";
import { CategoryBlockDialog } from "./category-block-dialog/CategoryBlockDialog";
import { CategoryUnblockDialog } from "./category-unblock-dialog/CategoryUnblockDialog";
import { CategoriesUIProvider } from "./CategoriesUIContext";
import { CategoriesCard } from "./CategoriesCard";
import queryString from "query-string";

export function SubCategoriesPrivatePage(props) {
  const { history, location, contentType } = props;
  const [isNewCat, setNewCat] = useState(0);
  const [isNewSubcat, setNewSubcat] = useState(0);
  const [isEditSubcat, setEditSubcat] = useState(0);
  const [isDeleteSubcat, setDeleteSubcat] = useState(0);
  const [isBlockSubcat, setBlockSubcat] = useState(0);
  const [isUnblockSubcat, setUnblockSubcat] = useState(0);

  const [catName, setCatName] = useState(null);
  const [catId, setCatId] = useState(null);
  const [subcatName, setSubcatName] = useState(null);
  const [subcatId, setSubcatId] = useState(null);

  const [isPrivate, setIsPrivate] = useState(0);
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.newCat) {
      setNewCat(1);
    }
    if (parsed.newSubcat) {
      setNewSubcat(1);
    }
    if (parsed.editSubcat) {
      setEditSubcat(1);
    }
    if (parsed.deleteSubcat) {
      setDeleteSubcat(1);
    }
    if (parsed.blockSubcat) {
      setBlockSubcat(1);
    }
    if (parsed.unblockSubcat) {
      setUnblockSubcat(1);
    }
    //------------------------------
    if (parsed.catName) {
      setCatName(parsed.catName);
    } else {
      setCatName(null);
    }
    if (parsed.catId) {
      setCatId(parsed.catId);
    } else {
      setCatId(null);
    }
    if (parsed.subcatName) {
      setSubcatName(parsed.subcatName);
    } else {
      setSubcatName(null);
    }
    if (parsed.subcatId) {
      setSubcatId(parsed.subcatId);
    } else {
      setSubcatId(null);
    }
    //------------------------------
    if (parsed.isPrivate) {
      setIsPrivate(parseInt(parsed.isPrivate, 10));
    }
  }, [location.search]);
  const categoriesUIEvents = {
    newCategoryButtonClick: (isPrivate, catId, catName) => {
      history.push(
        `/subcategories-private-${contentType}?isPrivate=${isPrivate}&catId=${catId}&catName=${catName}&newCat=true`
      );
    },
    newSubCategoryButtonClick: (isPrivate, catId, catName) => {
      history.push(
        `/subcategories-private-${contentType}?isPrivate=${isPrivate}&catId=${catId}&catName=${catName}&newSubcat=true`
      );
    },
    openEditSubCategoryDialog: (name, catId, subcatId, isPrivate, catName) => {
      history.push(
        `/subcategories-private-${contentType}?catId=${catId}&catName=${catName}&subcatId=${subcatId}&subcatName=${name}&isPrivate=${isPrivate}&editSubcat=true`
      );
    },
    openDeleteSubCategoryDialog: (name, catId, subcatId, isPrivate, catName) => {
      history.push(
        `/subcategories-private-${contentType}?catId=${catId}&catName=${catName}&subcatId=${subcatId}&subcatName=${name}&isPrivate=${isPrivate}&deleteSubcat=true`
      );
    },
    //--------------------------------------------------------------------------------
    openDeleteCategoryDialog: (type, name, id, isPrivate) => {
      history.push(`/categories/${type}/${id}/${encodeURIComponent(name)}/${isPrivate}/delete`);
    },
    openViewCategoryDialog: (id) => {
      history.push(`/categories/${id}/view`);
    },
    openEditCategoryDialog: (type, name, catId, isPrivate) => {
      history.push(`/categories/${type}/${catId}/${encodeURIComponent(name)}/${isPrivate}/edit`);
    },
    openBlockCategoryDialog: (name, id, isPrivate, isSubCat, catName, catId) => {
      history.push(
        `/subcategories-private-${contentType}?catId=${catId}&catName=${catName}&subcatId=${id}&subcatName=${name}&isPrivate=${isPrivate}&blockSubcat=true`
      );
    },
    openUnblockCategoryDialog: (name, id, isPrivate, isSubCat, catName, catId) => {
      history.push(
        `/subcategories-private-${contentType}?catId=${catId}&catName=${catName}&subcatId=${id}&subcatName=${name}&isPrivate=${isPrivate}&unblockSubcat=true`
      );
    },
    openDeleteCategoriesDialog: () => {
      history.push(`/categories/deleteCategories`);
    },
    openFetchCategoriesDialog: () => {
      history.push(`/categories/fetch`);
    },
    openUpdateCategoriesStatusDialog: () => {
      history.push("/categories/updateStatus");
    },
    openSubCategoriesPage: (id, catName) => {
      history.push(`/categories/${id}/${catName}`);
    },
    openSubCategoryBooksPage: (type) => {
      history.push(`/categories/${type}/sub/books`);
    },
  };

  return (
    <CategoriesUIProvider categoriesUIEvents={categoriesUIEvents} contentType={contentType}>
      <CategoriesLoadingDialog />
      {/* <Route path="/subcategories/:type/:id/:catName/sub/books">
          {({ match }) => (
            <CategoriesCard
              isSubCat={true}
              isSubCatBooks={true}
              name={match && match.params.catName}
              type={match && match.params.type}
            />
          )}
        </Route> */}
      <CategoriesCard
        isSubCat={true}
        name={catName}
        type="ebooks"
        id={catId}
        onHide={() => {
          history.push(`/categories-private-${contentType}`);
        }}
        isPrivate={1}
      />
      {isNewCat ? (
        <CategoryNewDialog
          show={true}
          onHide={() => {
            history.push(`/subcategories-private-${contentType}?catId=${catId}&catName=${catName}`);
            setNewCat(0);
          }}
          onSuccess={() => {
            history.push(`/categories-private-${contentType}`);
            setNewCat(0);
          }}
          isPrivate={isPrivate}
          name={catName}
          history={history}
          contentType={contentType}
        />
      ) : (
        ""
      )}
      {isNewSubcat ? (
        <CategoryNewDialog
          show={true}
          onHide={() => {
            history.push(`/subcategories-private-${contentType}?catId=${catId}&catName=${catName}`);
            setNewSubcat(0);
          }}
          onSuccess={() => {
            setNewSubcat(0);
          }}
          isPrivate={isPrivate}
          name={catName}
          isSubCat={true}
          history={history}
          contentType={contentType}
        />
      ) : (
        ""
      )}
      {isEditSubcat ? (
        <CategoryEditDialog
          show={true}
          catId={catId}
          name={catName}
          subcatId={subcatId}
          isPrivate={isPrivate}
          onHide={() => {
            history.push(`/subcategories-private-${contentType}?catId=${catId}&catName=${catName}`);
            setEditSubcat(0);
          }}
        />
      ) : (
        ""
      )}
      {isDeleteSubcat ? (
        <CategoryDeleteDialog
          show={true}
          catId={catId}
          catName={catName}
          subcatId={subcatId}
          name={subcatName}
          isPrivate={isPrivate}
          onHide={() => {
            history.push(`/subcategories-private-${contentType}?catId=${catId}&catName=${catName}`);
            setDeleteSubcat(0);
          }}
        />
      ) : (
        ""
      )}
      {isBlockSubcat ? (
        <CategoryBlockDialog
          show={true}
          id={subcatId}
          catId={catId}
          name={subcatName}
          isPrivate={isPrivate}
          onHide={() => {
            history.push(`/subcategories-private-${contentType}?catId=${catId}&catName=${catName}`);
            setBlockSubcat(0);
          }}
        />
      ) : (
        ""
      )}
      {isUnblockSubcat ? (
        <CategoryUnblockDialog
          show={true}
          id={subcatId}
          catId={catId}
          name={subcatName}
          isPrivate={isPrivate}
          onHide={() => {
            history.push(`/subcategories-private-${contentType}?catId=${catId}&catName=${catName}`);
            setUnblockSubcat(0);
          }}
        />
      ) : (
        ""
      )}
      {/* 
      <Route path="/subcategories/:type/:name/:id/:isPrivate/block">
        {({ history, match }) => (
          <CategoryBlockDialog
            show={match != null}
            id={match && match.params.id}
            name={match && decodeURIComponent(match.params.name)}
            type={match && match.params.type}
            isPrivate={match && parseInt(match.params.isPrivate, 10)}
            onHide={() => {
              history.push(`/categories-private-${contentType}`);
            }}
          />
        )}
      </Route>
      <Route path="/subcategories/:type/:name/:id/:isPrivate/unblock">
        {({ history, match }) => (
          <CategoryUnblockDialog
            show={match != null}
            id={match && match.params.id}
            name={match && decodeURIComponent(match.params.name)}
            type={match && match.params.type}
            isPrivate={match && parseInt(match.params.isPrivate, 10)}
            onHide={() => {
              history.push(`/categories-private-${contentType}`);
            }}
          />
        )}
      </Route> */}
    </CategoriesUIProvider>
  );
}
