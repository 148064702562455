import React, { useEffect, useState, useRef, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { makeStyles } from "@material-ui/core/styles";
import { usePodcastsUIContext } from "../PodcastsUIContext";
import * as actions from "../../../_redux/podcasts/podcastsActions";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { message, Divider, Row, Col, Button, Slider } from "antd";
import jwt_decode from "jwt-decode";
import ReactPlayer from "react-player";
import parse from "html-react-parser";
import Hls from "hls.js";
import "./podcast.scss";
import { dateStr, timeStr } from "../../../partials/timeStampToDate";
import { Role } from "../../../../../../interface"

const dateConvert = (second) => {
     return new Date(second * 1000)
          .toISOString()
          .substr(11, 8)
          .replace(/^00:/, "");
};

export const PodCastPlayer = (props) => {
     const audioPlayerRef = useRef(null);
     const [audioPlayer, setAudioPlayer] = useState({});
     const [currentTime, setCurrentTime] = useState(0);
     const [duration, setDuration] = useState(0);
     const [isPlay, setIsPlay] = useState(false);

     useEffect(() => {
          setAudioPlayer(audioPlayerRef.current);
     }, [audioPlayerRef.current]);
     useEffect(() => {
          if (audioPlayer.paused !== undefined) {
               // console.log({ audioPlayer });
               audioPlayer.onloadeddata = () => {
                    setDuration(audioPlayer.duration);
               };
               audioPlayer.ontimeupdate = (data) => {
                    // console.log(data);
                    setCurrentTime(audioPlayer.currentTime);
               };
               audioPlayer.onprogress = (data) => {
                    // console.log("progress", { data });
               };
               audioPlayer.onwaiting = (data) => {
                    // console.log(data);
               };
          }
     }, [audioPlayer]);

     useEffect(() => {
          audioPlayer.playbackRate = 1;
     }, []);

     const playBtnAction = () => {
          if (audioPlayer.paused) {
               audioPlayer.play();
               setIsPlay(true);
          } else {
               audioPlayer.pause();
               setIsPlay(false);
          }
     };

     const sliderOnChange = (value) => {
          setCurrentTime(value);
          audioPlayer.currentTime = value;
     };
     return (
          <div className="podcast-player">
               <Row>
                    <h2>{props.title}</h2>
               </Row>
               <Row style={{ marginTop: 16 }}>
                    <Col className="list-button-left" span={12} offset={0}>
                         <Button className="player-button" onClick={playBtnAction}>
                              <img
                                   src={isPlay ? "/media/members/pause-player.svg" : "/media/members/play-player.svg"}
                              />
                         </Button>
                    </Col>
                    <Col className="list-button-right" span={12} offset={0}></Col>
               </Row>
               <Row style={{ marginTop: 8 }}>
                    <Slider
                         className="player-slider"
                         defaultValue={0}
                         value={currentTime}
                         tooltipVisible={false}
                         max={duration}
                         min={0}
                         onChange={sliderOnChange}
                    />
               </Row>
               <Row>
                    <Col className="list-button-left" span={12} offset={0}>
                         <p className="time">{dateConvert(currentTime)}</p>
                    </Col>
                    <Col className="list-button-right" span={12} offset={0}>
                         <p className="time">-{dateConvert(duration - currentTime)}</p>
                    </Col>
               </Row>
               <audio ref={audioPlayerRef}>
                    {/* {playList !== undefined || "" ? ( */}
                    <HLSSource type="audio/mpeg" source={audioPlayer} src={props.playlist} />
               </audio>
          </div>
     );
};

const HLSSource = ({ src, source, type }) => {
     useEffect(() => {
          const hls = new Hls();
          if (Hls.isSupported()) {
               // console.log({ source });
               hls.loadSource(src);
               hls.attachMedia(source);
          }
     }, [source]);
     return <source src={src} type={type || "application/x-mpegURL"} />;
};

const useStyles = makeStyles(() => ({
     body: {
          textAlign: "center",
     },
     divButton: {
          marginTop: "24px",
          marginBottom: "12px",
     },
     col7: { textAlign: "left", maxWidth: "440px" },
     coverImage: { width: "260px" },
     title: {
          fontSize: "24px",
          color: "#1C1C1E",
          fontWeight: "600",
          textAlign: "left",
     },
     titleDes: {
          fontSize: "24px",
          color: "#012875",
          fontWeight: "600",
          marginTop: "20px",
          marginBottom: "10px",
          textAlign: "left",
     },
     text: { color: "#4f4f4f", fontSize: "18px" },
     textGrey: { color: "#828282", fontSize: "16px", marginBottom: "8px" },
     textOrange: { color: "#fd6b53", fontSize: "18px" },
     text14: { color: "#4f4f4f", fontSize: "14px" },
     textOrange14: { color: "#fd6b53", fontSize: "14px" },
     textOrangeBig: { color: "#fd6b53", fontSize: "17px", fontWeight: "600" },
     textOrange22: { color: "#fd6b53", fontSize: "22px", fontWeight: "600" },
     textBlue13: { color: "#012875", fontSize: "13px" },
     textBlue17: { color: "#012875", fontSize: "17px" },
     UID: {
          fontSize: "16px",
          width: "326px",
          height: "32px",
          border: "none",
          color: "#828282",
          backgroundColor: "#f2f2f2",
          cursor: "text",
          borderRadius: "6px",
     },
     copyButton: {
          marginLeft: "14px",
          borderRadius: "6px",
          backgroundColor: "#f2f2f2",
          transform: "translateY(-3px)",
          border: "none",
     },
     catsubcat: {
          borderRadius: "100px",
          backgroundColor: "#f1ebe6",
          fontSize: "16px",
          color: "#4f4f4f",
          height: "28px",
          padding: "4px 16px",
     },
     description: {
          fontFamily: "Bai Jamjuree",
          fontSize: "16px",
          color: "#4f4f4f",
          marginBottom: "20px",
          textAlign: "left",
          "& p": {
               marginBottom: "4px",
          },
     },
     closeButton: {
          width: "30px",
          height: "30px",
          border: "none",
          backgroundColor: "#e0e0e0",
          borderRadius: "50%",
          padding: 0,
     },
     slot: {
          width: "100%",
          border: "1px solid #E5EAEE",
          display: "block",
          height: "calc(1.5em + 1.3rem + 2px)",
          padding: "0.65rem 1rem",
          fontSize: "1rem",
          fontWeight: "400",
          lineHeight: "1.5",
          color: "#464E5F",
          backgroundColor: "#ffffff",
          backgroundClip: "padding-box",
          borderRadius: "0.42rem",
          boxShadow: "none",
          transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
     },
     p: { marginTop: "4px", marginBottom: "8px", fontSize: "14px" },
     bigp: {
          color: "#012875",
          fontSize: "20px",
          marginBottom: "0",
          fontWeight: "600",
     },
     title_confirm: {
          fontSize: "20px",
          fontWeight: 600,
          color: "#d7042b",
     },
   
}));

export function PodcastViewDialog({ id, isPrivate, show, onHide, isFree }) {
     // Podcasts UI Context
     const history = useHistory();
     const loaderHeight = 50;
     const [podcastDetail, setPodcastDetail] = useState(null);
     const [modalManageLicense, setModalManageLicense] = useState(false);
     const textAreaRef = useRef(null);
     const [checkingError, setCheckingError] = useState(false);
     const [concurrentValue, setConcurrentValue] = useState(undefined);
     const podcastsUIContext = usePodcastsUIContext();
     const podcastsUIProps = useMemo(() => {
          return {
               setIds: podcastsUIContext.setIds,
               queryParams: podcastsUIContext.queryParams,
          };
     }, [podcastsUIContext]);

     // Podcasts Redux state
     const dispatch = useDispatch();
     const { role, isLoading, authToken } = useSelector(
          (state) => ({
               role: state.auth.role,
               isLoading: state.podcasts.actionsLoading,
               authToken: state.auth.authToken,
          }),
          shallowEqual
     );
     const [durationTypeList, setDurationTypeList] = useState([<option value={1800}>30 นาที</option>]);
     const [anonymousDurationTypeList, setAnonymousDurationTypeList] = useState([
          <option value={1800}>30 นาที</option>,
     ]);
     const [rentalPeriodValue, setRentalPeriodValue] = useState(1800);
     const [anonymousRentalPeriodValue, setAnonymousRentalPeriodValue] = useState(1800);
     const [confirmModal, setConfirmModal] = useState(false);

     const reqDetailFunc = async (val) => {
          try {
               const req = await axios.get(
                    `${`/admin${isPrivate ? "/private/contents" : isFree ? "/free/contents" : "/contents"}`}/${id}`
               );
               const { data } = req;
               if (data.status && data.data) {
                    setPodcastDetail(data.data);
                    if (data.data.concurrent) {
                         setConcurrentValue(data.data.concurrent);
                    } else {
                         setConcurrentValue(undefined);
                    }
                    if (val === 2) {
                         if (data.data.rentalPeriod) {
                              setRentalPeriodValue(data.data.rentalPeriod);
                         }
                         if (data.data.anonymousRentalPeriod) {
                              setAnonymousRentalPeriodValue(data.data.anonymousRentalPeriod);
                         } else if (data.data.rentalPeriod) {
                              setAnonymousRentalPeriodValue(data.data.rentalPeriod);
                         }
                    } else {
                         if (!isFree || (isPrivate && data.data.concurrent)) {
                              // reqDropdownFunc(data.data.contentUID);
                         }
                    }
               }
          } catch (err) {
               setPodcastDetail(0);
          }
     };

     const reqDropdownFunc = async (UID) => {
          const reqDropdown = await axios.get(`/admin/contents/${UID}/edit`);
          if (reqDropdown.data.data) {
               let res = reqDropdown.data.data;
               setDurationTypeList(res.rentalPeriodPreset.map((e) => <option value={e.value}>{e.title}</option>));
               setAnonymousDurationTypeList(
                    res.anonymousRentalPeriodPreset.map((e) => <option value={e.value}>{e.title}</option>)
               );
          }
     };

     // if !id we should close modal
     useEffect(() => {
          if (!id) {
               onHide();
               setPodcastDetail(null);
          } else {
               reqDetailFunc();
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [id]);

     const copyToClipboard = () => {
          textAreaRef.current.select();
          textAreaRef.current.setSelectionRange(0, textAreaRef.current.value.length);
          document.execCommand("copy");
          message.success(
               <p
                    style={{
                         display: "inline-block",
                         transform: "translateY(4px)",
                         marginBottom: "5px",
                    }}
               >
                    คัดลอก UID แล้ว
               </p>,
               [2]
          );
     };

     // looking for loading/dispatch
     useEffect(() => {}, [isLoading, dispatch]);

     //   const blockPodcast = () => {
     //     dispatch(actions.blockPodcast(id)).then(() => {
     //       // refresh list after deletion
     //       dispatch(actions.fetchPodcasts(podcastsUIProps.queryParams));
     //       // clear selections list
     //       podcastsUIProps.setIds([]);
     //       // closing block modal
     //       onHide();
     //     });
     //   };

     const classes = useStyles();

     const openModalManageLicense = () => {
          setModalManageLicense(true);
          reqDetailFunc(2);
     };
     const submitModalManageLicense = async (private_) => {
          const obj = {
               rentalPeriod: parseInt(rentalPeriodValue, 10),
               anonymousRentalPeriod: parseInt(anonymousRentalPeriodValue, 10),
               concurrent: role === Role.SuperAdmin ? parseInt(concurrentValue, 10) : undefined,
          };
          const submitFunc = await axios.patch(
               `/admin/${private_ ? `private/` : ``}contents/${podcastDetail.contentUID}/${
                    private_ ? `period` : `edit`
               }`,
               obj
          );
          if (submitFunc.data.status) {
               reqDetailFunc();
               dispatch(actions.fetchPodcasts(podcastsUIProps.queryParams, isPrivate, isFree));
               setConfirmModal(false);
               setModalManageLicense(false);
          }
     };
     const handleRentalPeriodChange = (e) => {
          setRentalPeriodValue(e.target.value);
     };
     const handleAnonymousRentalPeriodChange = (e) => {
          setAnonymousRentalPeriodValue(e.target.value);
     };
     const errorNumberFormat = (e) => !e || parseInt(e, 10) < 1 || isNaN(parseInt(e, 10));

     const handleConcurrentChange = (e) => {
          const regex = RegExp(/^[0-9]+$/);
          if (e && e.target) {
               if (e.target.value && e.target.value.length <= 3 && regex.test(e.target.value)) {
                    setConcurrentValue(parseInt(e.target.value, 10));
               } else if (e.target.value === "") {
                    setConcurrentValue(e.target.value);
               }
          }
          if (checkingError) {
               setCheckingError(false);
          }
     };

     const [checkCantManage, setCheckCantManage] = useState(true);

     useEffect(() => {
          if (isFree || (authToken && jwt_decode(authToken).poolUID)) {
               setCheckCantManage(true);
          } else {
               setCheckCantManage(false);
          }
     }, [isPrivate, isFree, authToken]);

     const status_text = (s_) => {
          switch (s_) {
               case 0:
                    return "ปิดการใช้งาน";
               case 1:
                    return "เปิดการใช้งาน";
               case 2:
                    return "บล็อก";
               default:
                    return "-";
          }
     };
     const language_text = (l_) => {
          switch (l_) {
               case "th":
                    return "ไทย";
               case "en":
                    return "อังกฤษ";
               case "zh":
                    return "จีน";
               case "ja":
                    return "ญี่ปุ่น";
               case "ko":
                    return "เกาหลี";
               default:
                    return "-";
          }
     };

     return (
          <>
               <Modal
                    backdrop="static"
                    show={confirmModal}
                    onHide={() => setConfirmModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    style={{ margin: "3% auto" }}
               >
                    <Modal.Body className={classes.body}>
                         <img src="/media/members/image-modal.svg" alt="" />
                         <div className={classes.title_confirm}>ยืนยันการจำกัดสิทธิ์</div>
                         <div className={classes.divButton}>
                              <button type="button" onClick={() => setConfirmModal(false)} className="denyButton">
                                   ยกเลิก
                              </button>
                              <button
                                   type="button"
                                   onClick={() => submitModalManageLicense(isPrivate)}
                                   className=" acceptButton"
                              >
                                   ยืนยัน
                              </button>
                         </div>
                    </Modal.Body>
               </Modal>
               <Modal
                    backdrop="static"
                    size="sm"
                    show={modalManageLicense}
                    onHide={() => setModalManageLicense(false)}
                    style={{ margin: "3% auto" }}
               >
                    <Modal.Body>
                         <div
                              style={{
                                   position: "absolute",
                                   zIndex: "5",
                                   top: "12px",
                                   right: "12px",
                                   textAlign: "right",
                              }}
                         >
                              <button
                                   className={classes.closeButton}
                                   onClick={() => {
                                        setModalManageLicense(false);
                                   }}
                              >
                                   <img src="/media/books/close-button.svg" alt="" />
                              </button>
                         </div>
                         {(role !== Role.SuperAdmin && durationTypeList && durationTypeList.length > 1) ||
                         (role === Role.SuperAdmin && concurrentValue !== undefined) ? (
                              <>
                                   {role === Role.SuperAdmin ? (
                                        <div className={`col-12`} style={{ margin: "0 0 58px 0" }}>
                                             <p className={classes.bigp}>จำนวนสิทธิ์</p>
                                             <p className={classes.p}>จำนวนสิทธิ์ในการยืม</p>
                                             <input
                                                  className={classes.slot}
                                                  onChange={handleConcurrentChange}
                                                  value={concurrentValue}
                                             />
                                             {errorNumberFormat(concurrentValue) && checkingError && (
                                                  <>
                                                       <div className="is-invalid"></div>
                                                       <div className="invalid-feedback">
                                                            กรุณากรอกจำนวนสิทธิ์ให้ถูกต้อง
                                                       </div>
                                                  </>
                                             )}
                                        </div>
                                   ) : (
                                        ""
                                   )}
                                   <div className={`col-12`}>
                                        <p className={classes.bigp}>สมาชิก</p>
                                        <p className={classes.p}>เวลาสูงสุดในการยืม</p>
                                        <select
                                             className={classes.slot}
                                             onChange={handleRentalPeriodChange}
                                             value={rentalPeriodValue}
                                        >
                                             {durationTypeList}
                                        </select>
                                   </div>
                                   <div className={`col-12`} style={{ margin: "58px 0" }}>
                                        <p className={classes.bigp}>สมาชิกไม่ระบุตัวตน</p>
                                        <p className={classes.p}>เวลาสูงสุดในการยืม</p>
                                        <select
                                             className={classes.slot}
                                             onChange={handleAnonymousRentalPeriodChange}
                                             value={anonymousRentalPeriodValue}
                                        >
                                             {anonymousDurationTypeList}
                                        </select>
                                   </div>
                                   <div className={`col-12`} style={{ textAlign: "center" }}>
                                        <button
                                             type="button"
                                             onClick={() => {
                                                  setCheckingError(true);
                                                  if (
                                                       (role === Role.SuperAdmin && concurrentValue) ||
                                                       role !== Role.SuperAdmin
                                                  ) {
                                                       setConfirmModal(true);
                                                  }
                                             }}
                                             className="acceptButton"
                                        >
                                             ตกลง
                                        </button>
                                   </div>
                              </>
                         ) : (
                              <div
                                   style={{
                                        minHeight: `${loaderHeight}px`,
                                        textAlign: "center",
                                        padding: "20px 0 0 0",
                                        width: "100%",
                                   }}
                              >
                                   <ClipLoader size={loaderHeight} color={"#123abc"} loading={true} />
                              </div>
                         )}
                    </Modal.Body>
               </Modal>
               <Modal
                    backdrop="static"
                    size={podcastDetail === 0 ? "md" : "lg"}
                    show={show}
                    onHide={onHide}
                    aria-labelledby="example-modal-sizes-title-lg"
                    onShow={() => {
                         // console.log(window.scrollY);
                    }}
               >
                    {/*begin::Loading*/}
                    {isLoading && <ModalProgressBar />}
                    {/*end::Loading*/}
                    {/* <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Podcast Block</Modal.Title>
      </Modal.Header> */}
                    <Modal.Body className={classes.body}>
                         {podcastDetail === 0 ? (
                              ""
                         ) : (
                              <div style={{ textAlign: "right" }}>
                                   <button
                                        className={classes.closeButton}
                                        onClick={() => {
                                             onHide();
                                             setPodcastDetail(null);
                                        }}
                                   >
                                        <img src="/media/books/close-button.svg" alt="" />
                                   </button>
                              </div>
                         )}
                         {podcastDetail ? (
                              <>
                                   <div className="row" style={{ marginLeft: "12.5px", marginRight: "12.5px" }}>
                                        <div className="col-lg-12" style={{ padding: "0" }}>
                                             {/*<div className={classes.title}>{podcastDetail.title}</div>*/}
                                             {1 === 1 ? (
                                                  <div className="podcast-detail">
                                                       <PodCastPlayer
                                                            title={podcastDetail.title}
                                                            playlist={
                                                                 podcastDetail.playlist ||
                                                                 "https://s3.ap-southeast-1.amazonaws.com/dev.elibrary-private-contents/a32b5f03-2931-40a7-99a6-4a2e0af4a839/encoded-medias/b9ce55e1-7512-4c24-aefe-5396ece6a78b/760eea88-1648-40a4-949f-7204a474c79a.m3u8"
                                                            }
                                                       />
                                                  </div>
                                             ) : (
                                                  ""
                                             )}
                                             {isFree ? (
                                                  ""
                                             ) : (
                                                  <div className="row" style={{ margin: "0" }}>
                                                       {/* <div className="col-8">
                        <div
                          className="row"
                          style={{
                            marginTop: "8px",
                            justifyContent: "flex-end"
                          }}
                        >
                          <div
                            className={`col-lg-${
                              Number.isInteger(podcastDetail.concurrent) &&
                              Number.isInteger(podcastDetail.activeLicense)
                                ? 2
                                : 3
                            } text-right ${classes.textBlue13}`}
                            style={{ padding: "0" }}
                          >
                            คงเหลือ :{" "}
                            <span className={classes.textOrangeBig}>
                              {Number.isInteger(podcastDetail.concurrent) &&
                              Number.isInteger(podcastDetail.activeLicense)
                                ? podcastDetail.concurrent -
                                  podcastDetail.activeLicense
                                : "ไม่จำกัด"}
                            </span>
                          </div>
                          <div
                            className={`col-lg-1 text-right ${classes.textBlue17}`}
                          >
                            |
                          </div>
                          <div
                            className={`col-lg-${
                              podcastDetail.concurrent ? 2 : 3
                            } text-right ${classes.textBlue13}`}
                            style={{ padding: "0" }}
                          >
                            ทั้งหมด :{" "}
                            <span className={classes.textOrangeBig}>
                              {Number.isInteger(podcastDetail.concurrent)
                                ? podcastDetail.concurrent
                                : "ไม่จำกัด"}
                            </span>
                          </div>
                          <div
                            className={`col-lg-1 text-right ${classes.textBlue17}`}
                          >
                            |
                          </div>
                          <div
                            className={`col-lg-2 text-right ${classes.textBlue13}`}
                            style={{ padding: "0" }}
                          >
                            ยืมแล้ว :{" "}
                            <span className={classes.textOrangeBig}>
                              {podcastDetail.rentAmount}
                            </span>
                          </div>
                        </div>
                              </div>*/}
                                                  </div>
                                             )}
                                             <Divider style={{ margin: "20px 0" }} />
                                             <div className="text-left">
                                                  {podcastDetail.channelName ? (
                                                       <div className={classes.textGrey}>
                                                            ช่อง : {podcastDetail.channelName}
                                                       </div>
                                                  ) : (
                                                       ""
                                                  )}
                                                  {podcastDetail.status ? (
                                                       <div className={classes.textGrey}>
                                                            สถานะ : {status_text(podcastDetail.status)}
                                                       </div>
                                                  ) : (
                                                       ""
                                                  )}
                                                  <div style={{ margin: "4px 0" }}>
                                                       <span className={classes.textGrey}>UID :</span>{" "}
                                                       {document.queryCommandSupported("copy") && (
                                                            <input
                                                                 ref={textAreaRef}
                                                                 className={classes.UID}
                                                                 value={podcastDetail.contentUID}
                                                            />
                                                       )}
                                                       <button onClick={copyToClipboard} className={classes.copyButton}>
                                                            <img src="/media/books/copy-button.svg" alt="" />
                                                       </button>
                                                  </div>
                                                  {podcastDetail.language ? (
                                                       <div className={classes.textGrey}>
                                                            ภาษา : {language_text(podcastDetail.language)}
                                                       </div>
                                                  ) : (
                                                       ""
                                                  )}
                                                  {isFree ? (
                                                       ""
                                                  ) : (
                                                       <>
                                                            <div
                                                                 className={classes.text14}
                                                                 style={{ marginTop: "12px" }}
                                                            >
                                                                 วันที่เพิ่ม :{" "}
                                                                 {podcastDetail.registerDate ? (
                                                                      <p
                                                                           style={{
                                                                                marginBottom: "4px",
                                                                                display: "inline-block",
                                                                           }}
                                                                      >
                                                                           {dateStr(podcastDetail.registerDate)}{" "}
                                                                           {timeStr(podcastDetail.registerDate)}
                                                                      </p>
                                                                 ) : (
                                                                      "-"
                                                                 )}
                                                            </div>
                                                            <div className={classes.text14}>
                                                                 วันที่เริ่มใช้งาน :{" "}
                                                                 {podcastDetail.startDate ? (
                                                                      <p
                                                                           style={{
                                                                                marginBottom: "4px",
                                                                                display: "inline-block",
                                                                           }}
                                                                      >
                                                                           {dateStr(podcastDetail.startDate)}{" "}
                                                                           {timeStr(podcastDetail.startDate)}
                                                                      </p>
                                                                 ) : (
                                                                      "-"
                                                                 )}
                                                                 <span
                                                                      className={classes.textOrange14}
                                                                      style={{ marginLeft: "20px" }}
                                                                 >
                                                                      วันที่ยุติการอ่าน :{" "}
                                                                      {podcastDetail.expireDate ? (
                                                                           <p
                                                                                style={{
                                                                                     marginBottom: "4px",
                                                                                     display: "inline-block",
                                                                                }}
                                                                           >
                                                                                {dateStr(podcastDetail.expireDate)}{" "}
                                                                                {timeStr(podcastDetail.expireDate)}
                                                                           </p>
                                                                      ) : (
                                                                           "-"
                                                                      )}
                                                                 </span>
                                                            </div>
                                                       </>
                                                  )}
                                             </div>
                                        </div>
                                   </div>
                                   <Divider style={{ margin: "20px 0" }} />
                                   <div style={{ textAlign: "left" }}>
                                        {podcastDetail.catName ? (
                                             <div style={{ display: "inline-block", marginRight: "8px" }}>
                                                  <span className={classes.catsubcat}>{podcastDetail.catName}</span>
                                             </div>
                                        ) : (
                                             ""
                                        )}
                                        {podcastDetail.subcatName ? (
                                             <div style={{ display: "inline-block" }}>
                                                  <span className={classes.catsubcat}>{podcastDetail.subcatName}</span>
                                             </div>
                                        ) : (
                                             ""
                                        )}
                                   </div>
                                   <div className={classes.titleDes}>รายละเอียด</div>
                                   <div className={classes.description}>
                                        {podcastDetail.description === "" || !podcastDetail.description
                                             ? "-"
                                             : podcastDetail.description
                                             ? parse(podcastDetail.description)
                                             : ""}
                                   </div>
                                   <Divider style={{ margin: "20px 0" }} />
                                   <div style={{ margin: "16px 0 24px", textAlign: "left" }}>
                                        {podcastDetail.tags &&
                                             podcastDetail.tags.map((el, index) => (
                                                  <div
                                                       key={index}
                                                       style={{ display: "inline-block", marginRight: "8px" }}
                                                  >
                                                       <span className={classes.catsubcat}>{el}</span>
                                                  </div>
                                             ))}
                                   </div>
                                   <div className={`row ${checkCantManage ? "justify-content-center" : ""}`}>
                                        <div
                                             className={`col-lg-${checkCantManage ? "3" : "2"} text-center ${
                                                  classes.textBlue17
                                             }`}
                                        >
                                             การดู
                                             <p className={classes.textOrange22}>
                                                  {podcastDetail.view ? podcastDetail.view : 0}
                                             </p>
                                        </div>
                                        <div
                                             className={`col-lg-${checkCantManage ? "3" : "2"} text-center ${
                                                  classes.textBlue17
                                             }`}
                                        >
                                             ความยาว
                                             <p className={classes.textOrange22}>
                                                  {podcastDetail.duration
                                                       ? podcastDetail.duration.includes(":")
                                                            ? podcastDetail.duration
                                                            : podcastDetail.duration.length === 1
                                                            ? "00:0" + podcastDetail.duration
                                                            : "00:" + podcastDetail.duration
                                                       : "-"}
                                             </p>
                                        </div>
                                        <div
                                             className={`col-lg-${checkCantManage ? "3" : "2"} text-center ${
                                                  classes.textBlue16
                                             }`}
                                        ></div>
                                        {checkCantManage || (isPrivate && !concurrentValue) ? (
                                             ""
                                        ) : (
                                             <div
                                                  className={`col-lg-${isPrivate ? `4` : `6`} text-right`}
                                                  style={{ alignSelf: "flex-end", paddingRight: "0px" }}
                                             >
                                                  <button
                                                       type="button"
                                                       onClick={() => openModalManageLicense()}
                                                       className="acceptButton_mini"
                                                       style={{ marginBottom: "24px" }}
                                                  >
                                                       จัดการสิทธิ์
                                                  </button>
                                             </div>
                                        )}
                                        {/*isPrivate ? (
                  <div
                    className={`col-lg-${
                      checkCantManage || (isPrivate && !concurrentValue) ? 6 : 2
                    } text-right`}
                    style={{
                      alignSelf: "flex-end",
                      paddingLeft:
                        checkCantManage || (isPrivate && !concurrentValue) ? "12.5px" : "0px",
                    }}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        const id_ = podcastDetail.contentUID;
                        history.push(`/podcasts-private?id=${id_}&edit=true`);
                      }}
                      className={classes.acceptButton_mini}
                      style={{ marginBottom: "24px" }}
                    >
                      แก้ไข
                    </button>
                  </div>
                ) : (
                  ""
                )*/}
                                   </div>
                              </>
                         ) : podcastDetail === 0 ? (
                              <div>
                                   <img src="/media/members/image-modal.svg" alt="" />
                                   <div
                                        style={{
                                             fontSize: "20px",
                                             fontWeight: 600,
                                             color: "#d7042b",
                                             marginBottom: "12px",
                                        }}
                                   >
                                        <p>กำลังดำเนินการ...</p>
                                   </div>
                                   <div className={classes.divButton}>
                                        <button
                                             type="button"
                                             onClick={() => {
                                                  onHide();
                                                  setPodcastDetail(null);
                                             }}
                                             style={{
                                                  margin: "8px",
                                                  color: "#ffffff",
                                                  fontSize: "18px",
                                                  fontWeight: 600,
                                                  width: "160px",
                                                  height: "48px",
                                                  borderRadius: "100px",
                                                  backgroundColor: "#012875",
                                                  border: "none",
                                             }}
                                        >
                                             ตกลง
                                        </button>
                                   </div>
                              </div>
                         ) : (
                              <div
                                   style={{
                                        minHeight: `${loaderHeight}px`,
                                        textAlign: "center",
                                        padding: "20px 0 0 0",
                                        width: "100%",
                                   }}
                              >
                                   <ClipLoader size={loaderHeight} color={"#123abc"} loading={true} />
                              </div>
                         )}
                    </Modal.Body>
                    {/* <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            Cancel
          </button>
          <> </>
          <button type="button" onClick={blockPodcast} className="btn btn-primary btn-elevate">
            Block
          </button>
        </div>
      </Modal.Footer> */}
               </Modal>
          </>
     );
}
