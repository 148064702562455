//@ts-nocheck
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import moment from 'moment';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
    Tabs,
    Dropdown,
    Menu,
    DatePicker,
    Button,
    Divider,
    Skeleton,
    Tag,
    Input
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
    Card,
    CardHeader,
    CardHeaderToolbar,
    CardBody
} from '../../../../../_metronic/_partials/controls/Card';
import BootstrapTable from 'react-bootstrap-table-next';
import SVG from 'react-inlinesvg';

import { makeStyles } from '@material-ui/core/styles';
import {
    LoaderView,
    NodataView,
    Modals,
    ModalsSuccess,
    ModalsWithBody
} from '../../../CustomView';
import * as util from './CheckEbookFunc';
import './checkebook.scss';

const { TextArea } = Input;

const { TabPane } = Tabs;
export function CheckEbookSubDetail({ history, location }) {
    const parsed = queryString.parse(location.search);
    const [activeTab, setActiveTab] = useState('1');
    const [firstLoad, setFirstLoad] = useState(true);
    const [search, setSearch] = useState('');
    const [sortBy, setSortBy] = useState('sort');

    const [loadingApprove, setLoadingApprove] = useState(true);
    const [loadingAwait, setLoadingAwait] = useState(true);
    const [isSearch, setIsSearch] = useState(false);

    const [catalog_details, setcatalog_details] = useState();
    const [publisherSummary, setpublisherSummary] = useState();
    const [catalogAwait, setCatalogAwait] = useState([]);
    const [catalogApprove, setCatalogApprove] = useState([]);
    const [filterCatalogAwait, setFilterCatalogAwait] = useState([]);
    const [filterCatalogApprove, setFilterCatalogApprove] = useState([]);

    const [confirmExcel, setConfirmModalExcel] = useState();
    const [loadingButtonExcel, setLoadingButtonExcel] = useState(false);

    const useStyles1 = makeStyles(theme => ({
        div: {
            marginBottom: -22
        }
    }));
    const classes = useStyles1();
    const [successModal, setSuccessModal] = useState(false);
    const [modalQuotation, setModalQuotation] = useState(false);
    const [modalLicense, setModalLicense] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [quotationConfirmModal, setQuotationConfirmModal] = useState(false);
    const [loadingConfirmModal, setLoadingConfirmModal] = useState(false);
    const [enableConfirmModal, setEnableConfirmModal] = useState(true);

    const [startDate, setStartDate] = useState(moment().unix());
    const [payDeadLine, setpayDeadLineDate] = useState(moment().unix());
    const [expireDate, setexpireDate] = useState(moment().unix());

    const [contentStatus, setContentStatus] = useState();
    const [confirmStatus, setConfirmStatus] = useState();
    const [rejectMessages, setrejectMessages] = useState();
    const [contentUID, setcontentUID] = useState();
    const [contentvalue, setContentvalue] = useState();

    const [startDateLicenses, setstartDateLicenses] = useState();

    const [emails, setEmails] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const saveInputRef = useRef(null);
    const [loadingBottom, setLoadingBottom] = useState(false);

    // const [loading, setLoading] = useState(false);
    // const [subcat, setSubcat_detail] = useState();
    // const loaderHeight = 50;
    // const [loadingBottom, setLoadingBottom] = useState(false);

    useEffect(() => {
        //  window.addEventListener('resize', resize);
        if (
            document.getElementById('custom-table-scroll') &&
            document
                .getElementById('custom-table-scroll')
                .getElementsByTagName('tbody')[0]
        ) {
            document
                .getElementById('custom-table-scroll')
                .getElementsByTagName('tbody')[0]
                .addEventListener('scroll', trackScrolling);
        }
        // eslint-disable-next-line
    }, []);

    const trackScrolling = () => {
        if (isBottom()) {
            setLoadingBottom(true);
        }
    };
    const onChangeSearch = e => {
        setSearch(e.target.value);
    };

    const getContentStatus = async () => {
        try {
            const req = await axios.get(
                `/superuser/catalogs/${parsed.id}/contents/status?clientUID=${parsed.clientUID}`
            );
            const { data } = req;
            if (data.status && data.data) {
                let status = data.data;
                setContentStatus(status.status);
            }
        } catch (error) {}
    };

    const isBottom = () => {
        const getScroll = document
            .getElementById('custom-table-scroll')
            .getElementsByTagName('tbody')[0];
        const offsetHeight = Math.ceil(getScroll.offsetHeight);
        const scrollTop = Math.ceil(getScroll.scrollTop);
        if (getScroll.scrollHeight - (scrollTop + offsetHeight) <= 1) {
            return true;
        } else {
            return false;
        }
    };

    /* Dropdown for ebooks in tbl */
    const dropdownStatusBtn = row => (
        <Menu>
            {contentStatus?.map((e, i) => {
                return (
                    <Menu.Item
                        key={i}
                        onClick={() => handleDropdownStatusClick(row, e)}
                    >
                        {e.title}
                    </Menu.Item>
                );
            })}
        </Menu>
    );

    const handleDropdownStatusClick = (row, e) => {
        if (row !== null) {
            setConfirmStatus(true);
            setcontentUID(row.uid);
            setContentvalue(e.value);
            setrejectMessages(e.rejectMessages);
        }
    };

    const postStatus = async () => {
        const req = await axios.post(
            `/superuser/catalogs/${parsed.id}/contents/approved`,
            {
                clientUID: parsed.clientUID,
                payload: [
                    {
                        contentUID: contentUID,
                        status: contentvalue,
                        rejectStatus: rejectMessages
                    }
                ]
            }
        );
        if (req) {
            getpublisherSummary();
            setConfirmStatus(false);
            setSuccessModal(true);
            setcontentUID();
            setContentvalue();
            setrejectMessages();
            forceReload(1);
            forceReload(2);
        }
    };

    const handleInputChange = e => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && emails.indexOf(inputValue) === -1) {
            setEmails([...emails, inputValue]);
        }
        setInputVisible(false);
        setInputValue('');
    };

    const showTagsInput = () => {
        setInputVisible(true);
    };

    const handleTagsClose = removedTag => {
        setEmails(emails.filter(tag => tag !== removedTag));
    };

    const setDefaultEmail = () => {
        if (publisherSummary && publisherSummary.publisherEmail)
            setEmails([publisherSummary.publisherEmail]);
    };

    useEffect(() => {
        if (emails.length > 0) {
            setEnableConfirmModal(true);
        } else {
            setEnableConfirmModal(false);
        }
    }, [emails]);

    /* End emails */

    const onStartDateChange = date => {
        if (date) {
            setStartDate(date.unix());
        }
    };
    const onexpireDateChange = date => {
        if (date) {
            setexpireDate(date.unix());
        }
    };
    const onpayDeadLineDateChange = date => {
        if (date) {
            setpayDeadLineDate(date.unix());
        }
    };
    const onStartDateLicensesChange = date => {
        if (date) {
            setstartDateLicenses(date.unix());
        }
    };

    const chkValidateQuotation = () => {
        setModalQuotation(false);
        setQuotationConfirmModal(true);
        setConfirmModal(true);
    };
    const didClickConfirm = () => {
        // setLoading(true);
        // setConfirmModal(false);
        setLoadingConfirmModal(true);
        if (quotationConfirmModal) {
            sentReport();
        } else {
            sentReportlicenses();
        }
    };
    const sentReport = async () => {
        try {
            const req = await axios.post(
                `/superuser/catalogs/${parsed.id}/vendors`,
                {
                    clientUID: parsed.clientUID,
                    payDeadLine: payDeadLine,
                    startDate: startDate,
                    publisherUID: parsed.uid,
                    publisherEmail: emails
                }
            );

            if (req) {
                // setLoading(false);
                setLoadingConfirmModal(false);
                setConfirmModal(false);
                setSuccessModal(true);
                setDefaultEmail();
            }
        } catch (error) {
            setLoadingConfirmModal(false);
            setConfirmModal(false);
            setDefaultEmail();
        }
    };
    const sentReportlicenses = async () => {
        try {
            const req = await axios.post(
                `/superuser/catalogs/${parsed.id}/licenses`,
                {
                    clientUID: parsed.clientUID,
                    startDate: startDateLicenses,
                    publisherUID: parsed.uid,
                    publisherEmail: emails
                }
            );

            if (req) {
                // setLoading(false);
                setLoadingConfirmModal(false);
                setConfirmModal(false);
                setSuccessModal(true);
                setDefaultEmail();
            }
        } catch (error) {
            setLoadingConfirmModal(false);
            setConfirmModal(false);
            setDefaultEmail();
        }
    };
    const getPublisherContents = async status => {
        try {
            setLoadingAwait(true);
            setLoadingApprove(true);
            var qString = { clientUID: parsed.clientUID, status: status };
            var path = `/superuser/catalogs/${parsed.id}/publishers/${parsed.uid}`;
            const req = await axios.get(
                queryString.stringifyUrl({
                    url: path,
                    query: qString
                })
            );

            const { data } = req;
            if (data.status && data.data) {
                const { contents } = data.data;
                if (status === 0) {
                    setLoadingAwait(false);
                    setCatalogAwait(contents);
                } else if (status === 1) {
                    setLoadingApprove(false);
                    setCatalogApprove(contents);
                }
            }
        } catch (error) {
            if (status === 0) {
                setLoadingAwait(false);
            } else if (status === 1) {
                setLoadingApprove(false);
            }
        } finally {
            setLoadingAwait(false);
            setLoadingApprove(false);
        }
    };
    const getpublisherSummary = async () => {
        try {
            const req = await axios.get(
                `/superuser/catalogs/${parsed.id}/publishers/${parsed.uid}/summary?clientUID=${parsed.clientUID}`
            );
            const { data } = req;
            if (data.status && data.data) {
                setpublisherSummary(data.data);
            }
        } catch (error) {}
    };
    const forceReload = tab => {
        if (tab === 1) {
            setLoadingAwait(true);
            getPublisherContents(0);
        } else if (tab === 2) {
            setLoadingApprove(true);
            getPublisherContents(1);
        }
    };
    const columns = [
        {
            dataField: '',
            text: 'ลำดับ',
            formatter: util.IndexFormatter,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'coverImage',
            text: 'ปก',
            formatter: util.CoverImageFormatter,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'none',
            text: 'รายละเอียด',

            formatter: util.DetailContentFormatter,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'lifttimePurchase',
            text: 'ขายต่อเนื่อง',
            formatter: util.IsLifttimePurchaseFormatter,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'concurrent',
            text: 'จำนวนสิทธิ์',
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'priceTHB',
            text: 'ราคาขาย',
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'totalPrice',
            text: 'ราคารวม',
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'status',
            text: 'ตรวจสอบสิทธิ์',
            formatter: util.ActionContentFormatter,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            },
            formatExtraData: {
                isHistory:
                    catalog_details && catalog_details.status !== 3
                        ? false
                        : true,
                dropdownStatusBtn: dropdownStatusBtn,
                contentStatus
            }
        }
    ];

    const Summaryebook = () => {
        return (
            <div>
                <span className="mr-5">
                    <span className="text text-gray-drak">จำนวนอีบุ๊ก : </span>
                    <span className="text text-blue bold">
                        {publisherSummary
                            ? publisherSummary?.contentsAmount
                            : 0}
                    </span>
                </span>
                <span className="mr-5">
                    <span className="text text-gray-drak ">จำนวนสิทธิ์ : </span>
                    <span className="text text-blue bold">
                        {publisherSummary ? publisherSummary?.concurrent : 0}
                    </span>
                </span>
                <span className="mr-5">
                    <span className="text text-sky-blue">
                        ราคาทั้งหมด :{' '}
                        {publisherSummary
                            ? publisherSummary?.totalPrice
                            : '0.00'}
                    </span>
                </span>
                <span className="mr-5">
                    <span className="text text-orange ">
                        ส่วนลด{' '}
                        {publisherSummary
                            ? `${publisherSummary?.discount}% `
                            : ''}
                    </span>
                    <span className="text text-orange">
                        :{' '}
                        {publisherSummary
                            ? publisherSummary?.totalDiscount
                            : '0.00'}
                    </span>
                </span>
                <span className="">
                    <span className="text text-green ">ราคาสุทธิ : </span>
                    <span className="text text-green">
                        {publisherSummary
                            ? publisherSummary?.grandTotal
                            : '0.00'}
                    </span>
                </span>
            </div>
        );
    };

    const onHide = () => {
        setSuccessModal(false);
    };

    const downloadExcel = async () => {
        setLoadingButtonExcel(true);
        const req = await axios
            .get(
                `/superuser/catalogs/${parsed.id}/export?clientUID=${parsed.clientUID}`
            )
            .then(res => {
                if (res.data && res.data.data && res.data.data.fulfillmentUrl) {
                    let link = document.createElement('a');
                    link.setAttribute('download', 'template');
                    link.href = res.data.data.fulfillmentUrl;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
                setLoadingButtonExcel(false);
            });
    };

    const dropdownBtn = () => (
        <Menu>
            {util.sortWordingValue?.map((e, i) => {
                return (
                    <>
                        {e.value === 4 ? (
                            <Divider style={{ margin: '2px 0' }} />
                        ) : (
                            ''
                        )}
                        <Menu.Item
                            key={e.value}
                            onClick={() => {
                                setSortBy(e.value);
                            }}
                        >
                            <span className="dropdown-text no-pic">
                                {e.title}
                            </span>
                        </Menu.Item>
                    </>
                );
            })}
        </Menu>
    );

    const getdatacatalog_details = async () => {
        try {
            const req = await axios.get(
                `/superuser/catalogs/${parsed.id}?clientUID=${parsed.clientUID}`
            );

            const { data } = req;

            if (data.status && data.data) {
                let catalogs = data.data;
                // setLoadingApprove(false);
                setcatalog_details(catalogs);
            }
        } catch (error) {}
    };

    const sortBtn = () => (
        <Menu>
            {util.sortWordingValue?.map((e, i) => {
                return (
                    <Menu.Item key={e.value} onClick={() => setSortBy(e.value)}>
                        <span className="dropdown-text no-pic">{e.title}</span>
                    </Menu.Item>
                );
            })}
        </Menu>
    );

    const ButtonExcel = () => {
        return (
            <Button
                type="button "
                className="button-green"
                onClick={() => {
                    setConfirmModalExcel(true);
                }}
                loading={loadingButtonExcel}
            >
                <span>Export Excel</span>
            </Button>
        );
    };

    const handleTab = tab => {
        let chk = parseInt(tab);
        if (chk === 1) {
            if (catalogAwait.length === 0) {
                forceReload(chk);
            }
        } else if (chk === 2) {
            if (catalogApprove.length === 0) {
                forceReload(chk);
            }
        }
    };

    useEffect(() => {
        getContentStatus();
        getdatacatalog_details();
        getpublisherSummary();
    }, []);

    useEffect(() => {
        setFilterCatalogAwait(
            Object.values(catalogAwait)
                .filter(el =>
                    el.title.toLowerCase().includes(search.toLowerCase())
                )
                .sort(util.sortByFunc(sortBy))
        );
    }, [catalogAwait]);

    useEffect(() => {
        setFilterCatalogApprove(
            Object.values(catalogApprove)
                .filter(el =>
                    el.title.toLowerCase().includes(search.toLowerCase())
                )
                .sort(util.sortByFunc(sortBy))
        );
    }, [catalogApprove]);

    useEffect(() => {
        if (Array.isArray(catalogAwait)) {
            setFilterCatalogAwait(
                Object.values(catalogAwait)
                    .filter(el =>
                        el.title.toLowerCase().includes(search.toLowerCase())
                    )
                    .sort(util.sortByFunc(sortBy))
            );
        }
        if (Array.isArray(catalogApprove)) {
            setFilterCatalogApprove(
                Object.values(catalogApprove)
                    .filter(el =>
                        el.title.toLowerCase().includes(search.toLowerCase())
                    )
                    .sort(util.sortByFunc(sortBy))
            );
        }
    }, [sortBy]);

    useEffect(() => {
        if (firstLoad && publisherSummary) {
            if (
                publisherSummary.processingAmount === 0 &&
                publisherSummary.passedAmount > 0
            ) {
                setActiveTab(2);
                handleTab(2);
            } else {
                handleTab(1);
            }
            setFirstLoad(false);
            setDefaultEmail();
        }
    }, [publisherSummary]);

    const onSearch = () => {
        setIsSearch(true);
        if (Array.isArray(catalogAwait)) {
            setFilterCatalogAwait(
                Object.values(catalogAwait)
                    .filter(el =>
                        el.title.toLowerCase().includes(search.toLowerCase())
                    )
                    .sort(util.sortByFunc(sortBy))
            );
        }
        if (Array.isArray(catalogApprove)) {
            setFilterCatalogApprove(
                Object.values(catalogApprove)
                    .filter(el =>
                        el.title.toLowerCase().includes(search.toLowerCase())
                    )
                    .sort(util.sortByFunc(sortBy))
            );
        }
    };

    const summarydetail = <Summaryebook />;

    return (
        <div id="CheckEbookDetailPage" className="checkebook">
            <Card className="cardStyle">
                <CardHeader
                    title={
                        publisherSummary ? (
                            <div className="card-label">
                                <div>{publisherSummary?.publisherName}</div>
                            </div>
                        ) : (
                            <Skeleton paragraph={{ rows: 1 }} active />
                        )
                    }
                >
                    <div className="tool-bar">
                        <CardHeaderToolbar>
                            <input
                                type="text"
                                className="form-control"
                                name="searchText"
                                placeholder="ค้นหา"
                                // onFocus={true}
                                value={search}
                                onChange={e => onChangeSearch(e)}
                                onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                        onSearch();
                                    }
                                }}
                            />
                            <SVG
                                src={
                                    isSearch
                                        ? '/media/members/reset-search.svg'
                                        : '/media/members/search-icon.svg'
                                }
                                alt=""
                                className="icon-search"
                                onClick={() => {
                                    if (isSearch) {
                                        setIsSearch(false);
                                        setSearch('');
                                        getPublisherContents(
                                            activeTab === '1' ? 0 : 1
                                        );
                                    } else {
                                        setIsSearch(true);
                                        onSearch();
                                    }
                                }}
                            />
                            <Dropdown
                                overlay={() => sortBtn()}
                                trigger={['click']}
                                placement="bottomLeft"
                            >
                                <button
                                    type="button"
                                    className="button-border -icon"
                                >
                                    <span>{`${util.sortWording(sortBy)}`}</span>
                                </button>
                            </Dropdown>

                            {catalog_details &&
                            catalog_details.status !== 3 &&
                            catalog_details.status !== 4 ? (
                                <>
                                    <ButtonExcel
                                        loading={loadingButtonExcel}
                                    ></ButtonExcel>
                                    <button
                                        type="button "
                                        className="button-dark"
                                        onClick={() => {
                                            setModalQuotation(true);
                                        }}
                                    >
                                        <span>ส่งใบเสนอราคา</span>
                                    </button>
                                    <button
                                        type="button"
                                        className="button"
                                        onClick={() => {
                                            setModalLicense(true);
                                        }}
                                    >
                                        <span>ตรวจสอบลิขสิทธิ์</span>
                                    </button>
                                </>
                            ) : (
                                ''
                            )}
                        </CardHeaderToolbar>
                    </div>
                    <div className="tool-barsmall ">
                        <CardHeaderToolbar>
                            <input
                                type="text"
                                className="form-control"
                                name="searchText"
                                placeholder="ค้นหา"
                                // onFocus={true}
                                value={search}
                                onChange={e => onChangeSearch(e)}
                            />
                            <SVG
                                src={
                                    isSearch
                                        ? '/media/members/reset-search.svg'
                                        : '/media/members/search-icon.svg'
                                }
                                alt=""
                                className="icon-search"
                                onClick={() => {
                                    if (isSearch) {
                                        setIsSearch(false);
                                        setSearch('');
                                        getPublisherContents(
                                            activeTab === '1' ? 0 : 1
                                        );
                                    } else {
                                        setIsSearch(true);
                                        onSearch();
                                    }
                                }}
                            />
                            <Dropdown
                                overlay={() => sortBtn()}
                                trigger={['click']}
                                placement="bottomLeft"
                            >
                                <button
                                    type="button"
                                    className="button-border-small -icon"
                                >
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip>{`${util.sortWording(
                                                sortBy
                                            )}`}</Tooltip>
                                        }
                                    >
                                        <img
                                            src="/media/members/sort.svg"
                                            alt=""
                                        />
                                    </OverlayTrigger>
                                </button>
                            </Dropdown>
                            <Button
                                type="button"
                                className="button-green -icon"
                                onClick={() => {
                                    downloadExcel();
                                }}
                                loading={loadingButtonExcel}
                            >
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip>{'Export Excel'}</Tooltip>
                                    }
                                >
                                    <img
                                        src="/media/members/excel.svg"
                                        alt=""
                                    />
                                </OverlayTrigger>
                            </Button>
                            <button
                                type="button "
                                className="button-dark -icon"
                                onClick={() => {
                                    setModalQuotation(true);
                                }}
                            >
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip>{'ส่งใบเสนอราคา'}</Tooltip>
                                    }
                                >
                                    <img
                                        src="/media/members/upload_mail.svg"
                                        alt=""
                                    />
                                </OverlayTrigger>
                            </button>
                            <button
                                type="button"
                                className="button -icon"
                                onClick={() => {
                                    setModalLicense(true);
                                }}
                            >
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip>{'ตรวจสอบลิขสิทธิ์'}</Tooltip>
                                    }
                                >
                                    <img
                                        src="/media/members/open_envelope.svg"
                                        alt=""
                                    />
                                </OverlayTrigger>
                            </button>
                        </CardHeaderToolbar>
                    </div>
                </CardHeader>

                <CardBody className="cardBodyStyle">
                    <Tabs
                        //   defaultActiveKey={`${activeTab}`}
                        activeKey={`${activeTab}`}
                        className="tab-custom"
                        tabBarExtraContent={summarydetail}
                        onChange={e => {
                            setActiveTab(e);
                            handleTab(e);
                        }}
                    >
                        <TabPane
                            tab={`รอตรวจสอบ (${
                                publisherSummary?.processingAmount
                                    ? publisherSummary?.processingAmount
                                    : 0
                            })`}
                            key="1"
                        >
                            {loadingAwait ? (
                                <LoaderView></LoaderView>
                            ) : filterCatalogAwait &&
                              filterCatalogAwait.length === 0 ? (
                                <NodataView></NodataView>
                            ) : (
                                <div className="CheckEbookDetailTable">
                                    <BootstrapTable
                                        wrapperClasses="table-responsive"
                                        bordered={false}
                                        classes="table table-head-custom table-vertical-center custom-table-scroll"
                                        id="custom-table-scroll"
                                        bootstrap4
                                        remote
                                        keyField="contentUID"
                                        data={filterCatalogAwait}
                                        columns={columns}
                                    ></BootstrapTable>
                                </div>
                            )}
                        </TabPane>
                        <TabPane
                            tab={`ตรวจสอบแล้ว (${
                                publisherSummary?.passedAmount
                                    ? publisherSummary?.passedAmount
                                    : 0
                            })`}
                            key="2"
                        >
                            {loadingApprove ? (
                                <LoaderView></LoaderView>
                            ) : filterCatalogApprove &&
                              filterCatalogApprove.length === 0 ? (
                                <NodataView></NodataView>
                            ) : (
                                <div className="CheckEbookDetailTable">
                                    <BootstrapTable
                                        wrapperClasses="table-responsive"
                                        bordered={false}
                                        classes="table table-head-custom table-vertical-center custom-table-scroll"
                                        id="custom-table-scroll"
                                        bootstrap4
                                        remote
                                        keyField="contentUID"
                                        data={filterCatalogApprove}
                                        columns={columns}
                                    ></BootstrapTable>
                                </div>
                            )}
                        </TabPane>
                    </Tabs>
                </CardBody>
            </Card>
            <ModalsSuccess
                show={successModal}
                onHide={() => onHide()}
                onClickAccept={() => onHide()}
            />
            <Modals
                title="ยืนยันการเปลี่ยนแปลง ?"
                show={confirmStatus}
                onHide={() => setConfirmStatus(false)}
                onClickAccept={e => postStatus(e)}
                onClickDeny={() => setConfirmStatus(false)}
            />
            <Modals
                title="ยืนยันการดาวน์โหลดไฟล์ ?"
                show={confirmExcel}
                onHide={() => setConfirmModalExcel(false)}
                onClickAccept={e => {
                    setConfirmModalExcel(false);
                    downloadExcel();
                }}
                onClickDeny={() => setConfirmModalExcel(false)}
            />
            <ModalsWithBody
                show={modalQuotation}
                title={'ตั้งค่าวันที่'}
                onClickAccept={() => {
                    chkValidateQuotation();
                }}
                onClickDeny={() => setModalQuotation(false)}
                el={
                    <>
                        <div className="mt">
                            <div className="text">วันที่เริ่ม</div>
                            <DatePicker
                                placeholder="วันที่เริ่มใช้งาน"
                                className="slecter_date_input"
                                onChange={onStartDateChange}
                                allowClear={false}
                                format={'DD/MM/YY'}
                                value={
                                    startDate
                                        ? moment.unix(startDate)
                                        : moment()
                                }
                            />
                        </div>
                        <div className="mt">
                            <div className="text">วันหมดอายุ</div>
                            <DatePicker
                                placeholder="วันหมดอายุ"
                                className="slecter_date_input"
                                onChange={onexpireDateChange}
                                allowClear={false}
                                format={'DD/MM/YY'}
                                value={
                                    expireDate
                                        ? moment.unix(expireDate)
                                        : moment()
                                }
                            />
                        </div>
                        <div className="mt">
                            <div className="text">รอบจ่ายเงิน</div>
                            <DatePicker
                                placeholder="รอบจ่ายเงิน"
                                className="slecter_date_input"
                                onChange={onpayDeadLineDateChange}
                                allowClear={false}
                                format={'DD/MM/YY'}
                                value={
                                    payDeadLine
                                        ? moment.unix(payDeadLine)
                                        : moment()
                                }
                            />
                        </div>
                    </>
                }
            />

            <ModalsWithBody
                show={modalLicense}
                title={'เลือกวันที่'}
                onClickAccept={() => {
                    setModalLicense(false);
                    setQuotationConfirmModal(false);
                    setConfirmModal(true);
                }}
                onClickDeny={() => setModalLicense(false)}
                el={
                    <div className="mt">
                        <DatePicker
                            placeholder="เลือกวันที่"
                            className="slecter_date_input"
                            onChange={onStartDateLicensesChange}
                            allowClear={false}
                            format={'DD/MM/YYYY'}
                            value={
                                startDateLicenses
                                    ? moment.unix(startDateLicenses)
                                    : moment()
                            }
                        />
                    </div>
                }
            />

            <Modal
                backdrop="static"
                size="md"
                show={confirmModal}
                onHide={onHide}
                aria-labelledby="example-modal-sizes-title-lg"
                style={{ marginTop: '27px' }}
                dialogClassName="divconf"
            >
                <Modal.Body className={classes.body}>
                    <div>
                        <img src="/media/members/image-modal.svg" alt="" />
                    </div>
                    <div className="text-text-red mb">
                        {quotationConfirmModal
                            ? 'ยืนยันการส่งใบเสนอราคา ?'
                            : 'ยืนยันการตรวจลิขสิทธิ์ ?'}
                    </div>
                    <div className="text">
                        ต้องการส่งใบเสนอราคาถึง{' '}
                        {publisherSummary?.publisherName}
                    </div>
                    <div className="text">
                        อีเมล
                        {/* {publisherSummary?.publisherEmail} หรือไม่ */}
                    </div>

                    <div className="row">
                        <div className={`col-lg-12 input`}>
                            {emails.map(tag => {
                                const tagElem = (
                                    <div className="email-publisher">
                                        <Tag
                                            className="edit-tag"
                                            key={tag}
                                            closable={true}
                                            onClose={() => handleTagsClose(tag)}
                                            // style={{
                                            //   display: "inline-flex",
                                            //   alignItems: "center",
                                            //   fontSize: "13px",
                                            //   height: "32px",
                                            // }}
                                        >
                                            <span>{tag}</span>
                                        </Tag>
                                    </div>
                                );
                                return tagElem;
                            })}
                            <div>
                                {inputVisible && (
                                    <Input
                                        ref={saveInputRef}
                                        type="text"
                                        size="small"
                                        className="input-publisher"
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        onBlur={handleInputConfirm}
                                        onPressEnter={handleInputConfirm}
                                    />
                                )}
                                {!inputVisible && (
                                    <Tag
                                        className={`site-tag-plus dashedBox `}
                                        onClick={showTagsInput}
                                    >
                                        <PlusOutlined
                                            style={{ marginRight: '2px' }}
                                        />{' '}
                                        เพิ่มอีเมล
                                    </Tag>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="divButton">
                        <Button
                            className="denyButton"
                            disabled={loadingConfirmModal}
                            onClick={() => {
                                setConfirmModal(false);
                                setDefaultEmail();
                            }}
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            className="acceptButton"
                            loading={loadingConfirmModal}
                            onClick={() => {
                                didClickConfirm();
                            }}
                            disabled={!enableConfirmModal}
                        >
                            ยืนยัน
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
