/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../_redux/upload/uploadActions';
import * as actions2 from '../../../_redux/channels-podcast/channelsActions';
import * as actions3 from '../../../_redux/categories/categoriesActions';
import * as actions4 from '../../../_redux/podcasts/podcastsActions';
import { podcastsSlice } from '../../../_redux/podcasts/podcastsSlice';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DashboardMini } from '../../upload/DashboardMini';
import {
   Input,
   Select,
   Card,
   CardHeader,
   CardHeaderToolbar,
   CardBody
} from '../../../../../../_metronic/_partials/controls';
import { usePodcastsUIContext } from '../PodcastsUIContext';
import { PlusOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import { DatePicker, Switch, Input as Input2, Divider, Upload } from 'antd';
import moment from 'moment';
import ImgCrop from 'antd-img-crop';
import 'antd/dist/antd.css';
import axios from 'axios';
import Spinner from '../../../../../../_metronic/_helpers/spinner';
import { useDropzone } from 'react-dropzone';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as vid from '../../../../CustomView/UploadFunc';
import { createFFmpeg } from '@ffmpeg/ffmpeg';
import { UploadInformationDialog } from '../../upload/upload-information-dialog/UploadInformationDialog';

const { TextArea } = Input2;
const CancelToken = axios.CancelToken;
const { actions: actions5 } = podcastsSlice;

const useStyles = makeStyles(theme => ({
   bigDiv: {
      borderRadius: '0px 12px 12px 12px'
   },
   input: { margin: '12px 0' },
   stat: { background: '#F2F2F2', borderRadius: '20px', padding: '20px 0' },

   blue_p: {
      fontSize: 20,
      fontWeight: 600,
      color: '#012875',
      marginBottom: '6px'
   },
   blue_p_small: {
      fontSize: 14,
      fontWeight: 400,
      color: '#012875',
      display: 'inline-block'
   },

   dropzoneTitle: { marginBottom: '0px', fontSize: '16px' },
   dropzoneSubTitle: {
      marginBottom: '2px',
      fontSize: '14px',
      color: '#828282'
   },
   dropzoneSubTitleBlue: {
      marginBottom: '0px',
      fontSize: '14px',
      color: '#012875'
   },
   dropzoneSubTitleOrange: {
      marginBottom: '24px',
      fontSize: '14px',
      color: '#FF5337'
   },
   progress: {
      margin: '13px 0px',
      borderRadius: '5px'
   },
   information: {
      backgroundColor: '#012875',
      width: 16,
      height: 16,
      borderRadius: 8,
      textAlign: 'center',
      margin: '0 0 0 6px',
      display: 'inline-block',
      '&:hover': { cursor: 'pointer' }
   },
   subProgressLeft: {
      margin: 0,
      fontFamily: 'Prompt',
      fontSize: '12px',
      fontWeight: '500',
      float: 'left',
      color: '#FF5337'
   },
   subProgressRight: {
      margin: 0,
      fontFamily: 'Prompt',
      fontSize: '12px',
      fontWeight: '500',
      float: 'right',
      color: '#FF5337'
   },
   uploadedImg: {
      border: '1px solid rgba(0, 0, 0, 0.3)',
      width: '100%',
      maxHeight: '400px'
   },
   uploadedImg_edit_loading: {
      width: '100%',
      maxHeight: '400px',
      filter: 'brightness(0.7) saturate(0.5)'
   },
   uploadedImg_edit: {
      border: '1px solid rgba(0, 0, 0, 0.3)',
      width: '100%',
      maxHeight: '400px',
      filter: 'brightness(0.7) saturate(0.5)'
   },
   row: { display: 'flex' },
   col: { margin: '2rem auto 0', display: 'inline-block' },
   col_result: { display: 'inline-block' },
   p: { marginBottom: '8px', fontSize: '14px' },
   title: {
      color: '#012875',
      fontWeight: 500,
      fontSize: '20px',
      textAlign: 'center'
   },
   subtitle: {
      color: '#012875',
      fontWeight: 400,
      fontSize: 12,
      marginBottom: 0
   },
   title_red: {
      fontSize: '20px',
      fontWeight: 600,
      color: '#d7042b',
      textAlign: 'center'
   },
   slot: {
      height: '32px',
      width: '100%',
      borderRadius: '4px',
      border: '1px solid black'
   },
   divButton: { marginTop: '2px', textAlign: 'right' },
   progressUpload: {
      margin: '3px 13px 47px',
      borderRadius: '5px'
   },
   dropDivImg: { margin: 'auto', '&:hover': { cursor: 'pointer' } },
   dropDivImg_noHover: { margin: 'auto' },

   contentBox: { '&:hover': { cursor: 'pointer' } },
   closeButton: { border: 'none', backgroundColor: 'transparent' },
   uploadFileImg: { marginTop: '36px' },
   dashedBox: {
      border: '1px dashed #BDBDBD',
      backgroundColor: '#ffffff',
      height: '32px',
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: '13px',
      '&:hover': { cursor: 'pointer' }
   },
   uploadingLabel: {
      fontSize: 14,
      alignSelf: 'center'
   },
   body: { margin: 'auto' },

   divText: { fontSize: '24px', fontWeight: '600', color: '#012875' },
   button: {
      marginTop: '32px',
      fontSize: '17px',
      fontWeight: '600',
      width: '200px',
      height: '48px',
      borderRadius: '100px',
      backgroundColor: '#012875',
      border: 'none',
      color: 'white'
   }
}));

export function PodcastUploadDialog({
   id,
   show,
   onHide,
   isPrivate,
   fixStatus
}) {
   // Podcasts Redux state
   const dispatch = useDispatch();
   const {
      uploadState,
      currentState,
      currentState2,
      actionsLoading,
      entities
   } = useSelector(
      state => ({
         uploadState: state.upload,
         currentState: state.categories,
         currentState2: state.channelsPodcast,
         actionsLoading: state.podcasts.actionsLoading,
         entities: state.podcasts.entities
      }),
      shallowEqual
   );
   const podcastsUIContext = usePodcastsUIContext();
   const podcastsUIProps = useMemo(() => {
      return {
         queryParams: podcastsUIContext.queryParams,
         openFileInformationDialog: podcastsUIContext.openFileInformationDialog,
         newChannelButtonClick: podcastsUIContext.newChannelButtonClick,
         newCreatorButtonClick: podcastsUIContext.newCreatorButtonClick,
         newCategoryButtonClick: podcastsUIContext.newCategoryButtonClick,
         newSubCategoryButtonClick: podcastsUIContext.newSubCategoryButtonClick
      };
   }, [podcastsUIContext]);
   const saveInputRef = useRef(null);
   const loaderHeight = 50;
   const [fixFileModal, setFixFileModal] = useState(false);
   const [modalType, setModalType] = useState(null);
   const [storageUsedNow, setStorageUsedNow] = useState(0);
   const [channelValue, setChannelValue] = useState(0);
   const [creatorValue, setCreatorValue] = useState(0);
   const [channelName, setChannelName] = useState('');
   const [creatorName, setCreatorName] = useState('');
   const [categoryValue, setCategoryValue] = useState(0);
   const [subCategoryValue, setSubCategoryValue] = useState(0);
   const [languageValue, setLanguageValue] = useState('th');
   const [startDate, setStartDate] = useState(moment().unix());
   const [endDate, setEndDate] = useState(undefined);
   const [isManageLicense, setIsManageLicense] = useState(false);
   const [tags, setTags] = useState([]);
   const [inputVisible, setInputVisible] = useState(false);
   const [inputValue, setInputValue] = useState('');
   const [rentalPeriodValue, setRentalPeriodValue] = useState(undefined);
   const [anonymousRentalPeriodValue, setAnonymousRentalPeriodValue] = useState(
      undefined
   );
   const [description, setDescription] = useState('');
   const [successModal, setSuccessModal] = useState(false);
   const [contentUIDToUpload, setContentUIDToUpload] = useState(undefined);
   const [coverName, setCoverName] = useState(null);
   const [source, setSource] = useState(
      Object.assign({}, CancelToken.source(), { key: 0 })
   );
   const [token, setToken] = useState(source.token);
   const [uploadingFile, setUploadingFile] = useState(false);
   const [filePost, setFilePost] = useState(null);
   // const [uploadingFile, setUploadingFile] = useState(true);
   // const [filePost, setFilePost] = useState(true);
   const [progressBarObj, setProgressBarObj] = useState({ percentage: 0 });
   const [storageLeft, setStorageLeft] = useState(0);
   const [canUpload, setCanUpload] = useState(false);
   const [fileName, setFileName] = useState(null);

   const [ffmpeg, setFFMPEG] = useState(undefined);
   const [readyUpload, setReadyUpload] = useState(false);
   const [width, setWidth] = useState(0);
   const [height, setHeight] = useState(0);
   const [duration, setDuration] = useState('00:00:00');
   const [millisecs, setMillisecs] = useState(0);

   useEffect(() => {
      const cf = createFFmpeg({
         log: true,
         corePath: vid.corePath,
         logger: ({ type, message }) => {
            const logger = vid.Loggers(type, message);
            if (logger.width > 0) setWidth(logger.width);
            if (logger.height > 0) setHeight(logger.height);
            if (logger.duration !== vid.defDuration)
               setDuration(logger.duration);
            if (logger.millisecs > 0) setMillisecs(logger.millisecs);
         }
      });
      setFFMPEG(cf);
   }, []);
   useEffect(() => {
      if (ffmpeg !== undefined) {
         if (!ffmpeg.isLoaded()) {
            ffmpeg.load();
            // setReadyUpload(true);
         }
      }
   }, [ffmpeg]);
   useEffect(() => {
      console.log('logger ', width, height, duration, millisecs);
   }, [width, height, duration, millisecs]);

   useEffect(() => {
      setToken(source.token);
   }, [source]);

   useEffect(() => {
      if (inputVisible) saveInputRef.current.focus();
   }, [inputVisible]);

   useEffect(() => {
      return () => {
         if (uploadingFile) {
            source.cancel('Operation canceled by the user.');
         }
      };
   }, [uploadingFile]);

   const [channelList, setChannelList] = useState([
      <option value={0} disabled selected>
         เลือก Channel
      </option>
   ]);
   const [categoryList, setCategoryList] = useState([
      <option value={0} disabled selected>
         เลือกหมวดหมู่หลัก
      </option>
   ]);
   const [subCategoryList, setSubCategoryList] = useState([
      <option value={0} disabled selected>
         เลือกหมวดหมู่ย่อย
      </option>
   ]);

   const [rentalPeriodList, setRentalPeriodList] = useState([
      <option value={3600}>1 ชั่วโมง</option>,
      <option value={7200}>2 ชั่วโมง</option>
   ]);

   const [languageList, setLanguageList] = useState([
      <option value="th">th-ไทย</option>,
      <option value="en">en-อังกฤษ</option>
   ]);

   const [checkingError, setCheckingError] = useState(false);

   const classes = useStyles();

   const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
      accept: '.mp3',
      noClick: true,
      noKeyboard: true,
      maxSize: canUpload
         ? storageLeft > 4000000000
            ? 4000000000
            : storageLeft
         : 0,
      onDropRejected: e => onDropRejected(e)
   });

   useEffect(() => {
      if (uploadState.storageData && uploadState.titleData) {
         var storage =
            (uploadState.storageData?.totalStorage *
               uploadState.storageData?.percentage) /
            100;
         let storageLeft_ = uploadState.storageData.totalStorage - storage;
         //  let titleLeft = uploadState.titleData.totalTitle - uploadState.titleData.usedTitle;
         if (!isNaN(storageLeft_) && storageLeft_ > 0) {
            // && titleLeft > 0) {
            setStorageLeft(storageLeft_ * 1000000000);
            setCanUpload(true);
         }
      }
      setSource(
         Object.assign({}, CancelToken.source(), { key: source.key + 1 })
      );
   }, [uploadState.storageData, uploadState.titleData]);

   const onDropRejected = e => {
      setSource(
         Object.assign({}, CancelToken.source(), { key: source.key + 1 })
      );
      if (e && Array.isArray(e) && e.length > 0) {
         if (
            e[0].errors &&
            Array.isArray(e[0].errors) &&
            e[0].errors.length > 0
         ) {
            if (e[0].errors[0] && e[0].errors[0].code === 'file-too-large') {
               podcastsUIProps.openFileInformationDialog(
                  'file-too-large',
                  'larger'
               );
            } else if (
               e[0].errors[0] &&
               e[0].errors[0].code === 'file-invalid-type'
            ) {
               console.log(e[0].errors[0].message);
               podcastsUIProps.openFileInformationDialog(
                  'file-invalid-type',
                  e[0].errors[0].message
               );
            }
         }
      }
   };

   const reqDeleteFileFunc = async () => {
      const req = await axios.delete(`/admin/private/contents/file`, {
         data: { fileName: fileName, contentUID: contentUIDToUpload }
      });
      if (req.data.status) {
         setFileName(null);
      }
   };
   const reqDeleteCoverFunc = async () => {
      const req = await axios.delete(`/admin/private/contents/file`, {
         data: { fileName: coverName, contentUID: contentUIDToUpload }
      });
      if (req.data.status) {
         setCoverName(null);
      }
   };

   useEffect(() => {
      const reqFunc = async () => {
         if (acceptedFiles && acceptedFiles.length) {
            setUploadingFile(true);
            const fileToUpload = acceptedFiles[0];
            if (fileToUpload) {
               vid.getMediaInfo(ffmpeg, fileToUpload);

               setFileName(fileToUpload.name);
               const req = await axios.post(`/admin/private/contents/file`, {
                  fileName: fileToUpload.name
               });
               if (req.data.status && req.data.data) {
                  const urlToUpload = req.data.data.url;
                  const fieldsToUpload = req.data.data.fields;
                  const UID = req.data.data.contentUID;
                  setContentUIDToUpload(UID);

                  var formData = new FormData();
                  const entries = Object.entries(fieldsToUpload);
                  for (const [key, val] of entries) {
                     formData.append(key, val);
                  }
                  formData.append('file', fileToUpload);
                  axios({
                     method: 'post',
                     url: urlToUpload,
                     data: formData,
                     onUploadProgress: progressEvent => {
                        if (progressEvent) {
                           setProgressBarObj({
                              loaded: (progressEvent.loaded / 1000).toFixed(2),
                              total: (progressEvent.total / 1000).toFixed(2),
                              percentage: (
                                 (100 * progressEvent.loaded) /
                                 progressEvent.total
                              ).toFixed()
                           });
                        }
                     },
                     cancelToken: token
                  })
                     .then(() => {
                        setFilePost(fileToUpload);
                        setUploadingFile(false);
                     })
                     .catch(() => {
                        setUploadingFile(false);
                     });
               }
            }
         }
      };
      reqFunc();
   }, [acceptedFiles]);

   useEffect(() => {
      if (currentState && currentState.entities) {
         setCategoryList([
            <option value={0} disabled selected>
               เลือกหมวดหมู่หลัก
            </option>,
            ...currentState.entities.map((el, index) => {
               return (
                  <option key={index} value={el.id}>
                     {el.catName}
                  </option>
               );
            })
         ]);
         if (currentState.entities.length === 0) {
            setCategoryList([
               <option value={0}>กรุณาเพิ่มหมวดหมู่หลัก</option>
            ]);
         }
      }
      if (categoryValue) {
         if (currentState && currentState.entities) {
            const currentCat = currentState.entities.filter(
               el => el.id === categoryValue
            );
            if (currentCat.length > 0) {
               const currentSubCat = currentCat[0].subcats;
               if (currentSubCat) {
                  setSubCategoryList([
                     <option value={0} disabled selected>
                        เลือกหมวดหมู่ย่อย
                     </option>,
                     ...currentSubCat.map((el, index) => {
                        return (
                           <option key={index} value={el.id}>
                              {el.subcatName}
                           </option>
                        );
                     })
                  ]);
                  if (currentSubCat.length === 0) {
                     setSubCategoryList([
                        <option value={0}>กรุณาเพิ่มหมวดหมู่ย่อย</option>
                     ]);
                  }
               } else {
                  setSubCategoryList([
                     <option value={0}>กรุณาเพิ่มหมวดหมู่ย่อย</option>
                  ]);
               }
            }
         }
      }
   }, [currentState.entities, categoryValue]);

   useEffect(() => {
      if (entities) {
         setChannelList([
            ...entities
               .filter(el => el.contentUID === id)
               .map((el, index) => {
                  // if (index === 0 && channelValue === 0) {
                  setChannelValue(el.contentUID);
                  setChannelName(el.title);
                  dispatch(
                     actions5.podcastNameFetched({
                        podcastName: el.title
                     })
                  );
                  // }
                  return (
                     <option key={index} value={el.contentUID} disabled>
                        {el.title}
                     </option>
                  );
               })
         ]);
         if (entities && entities.length === 0) {
            setChannelList([<option value={0}>กรุณาเพิ่ม Channel</option>]);
         }
      }
   }, [entities]);

   useEffect(() => {
      if (
         currentState2 &&
         currentState2.creatorsForEdit &&
         currentState2.creatorsForEdit.length > 0
      ) {
         setCreatorValue(currentState2.creatorsForEdit[0].uid);
         // setCreatorList([
         //   <option value={0} disabled selected>
         //     เลือก Creator
         //   </option>,
         //   ...currentState2.creatorsForEdit
         //     .filter((el) => el.status === 1)
         //     .map((el, index) => {
         //       // if (index === 0 && creatorValue === 0) {
         //       //   setCreatorValue(el.uid);
         //       // }
         //       return (
         //         <option key={index} value={el.uid}>
         //           {el.name}
         //         </option>
         //       );
         //     }),
         // ]);
         // if (currentState2.creatorsForEdit.length === 0) {
         //   setCreatorList([<option value={0}>เลือก Creator</option>]);
         // }
      }
   }, [currentState2.creatorsForEdit]);

   useEffect(() => {
      if (channelValue) {
         dispatch(actions2.fetchCreators('none', channelValue, 1));
      }
   }, [channelValue]);

   useEffect(() => {
      dispatch(actions.fetchNewPrivateContentData());
      const fetchLangFunc = async () => {
         const req = await axios.get('/admin/contents/languages');
         if (req.data.status && req.data.data) {
            setLanguageList(
               req.data.data.languages.map((el, index) => (
                  <option key={index} value={el.value}>
                     {el.label}
                  </option>
               ))
            );
         }
      };
      fetchLangFunc();
   }, []);

   useEffect(() => {
      if (uploadingFile) {
         dispatch(actions4.fetchPodcasts(podcastsUIProps.queryParams, 2));
         dispatch(
            actions3.fetchCategories(
               { contentType: 'podcast' },
               1,
               undefined,
               true
            )
         );
      }
   }, [uploadingFile]);

   useEffect(() => {
      if (fixStatus === 4) {
         setFixFileModal(true);
         setModalType('both');
      } else if (fixStatus === 6) {
         setFixFileModal(true);
         setModalType('content');
      } else if (fixStatus === 5) {
         setFixFileModal(true);
         setModalType('cover');
      }
   }, [fixStatus]);

   useEffect(() => {
      if (uploadState.rentalPeriod) {
         setRentalPeriodList(
            uploadState.rentalPeriod.map((el, index) => (
               <option key={index} value={el.value}>
                  {el.label}
               </option>
            ))
         );
      }
   }, [uploadState.rentalPeriod]);

   const onStartDateChange = date => {
      if (date) {
         setStartDate(date.unix());
         if (endDate && date.unix() > endDate) {
            setEndDate(null);
         }
      }
   };

   const onEndDateChange = date => {
      if (date) {
         setEndDate(date.unix());
      } else {
         setEndDate(null);
      }
   };

   const onStartDateOk = date => {
      if (date) {
         setStartDate(date.unix());
      }
   };

   const onEndDateOk = date => {
      if (date) {
         setEndDate(date.unix());
      }
   };

   const disabledEndDate = date => {
      return date && date < moment().endOf('day');
   };

   const errorNumberFormat = e =>
      !e || parseInt(e, 10) < 1 || isNaN(parseInt(e, 10));

   const onHideSuccessModal = () => {
      setSuccessModal(false);
   };

   const uploadSchema = Yup.object().shape({
      name: Yup.string()
         .min(1, 'อย่างน้อย 1 ตัวอักษร')
         .max(200, 'ไม่เกิน 200 ตัวอักษร')
         .required('กรุณากรอกชื่อ'),
      isbn: Yup.string(),
      eisbn: Yup.string(),
      numPages: Yup.number()
         .min(1, 'อย่างน้อย 1 หน้า')
         .typeError('กรุณาใช้ตัวเลข')
   });

   const handleChannelChange = e => {
      setChannelValue(e.target.value);
      var index = e.nativeEvent.target.selectedIndex;
      setChannelName(e.nativeEvent.target[index].text);
      setCreatorValue(0);
   };
   // const handleCreatorChange = (e) => {
   //   setCreatorValue(e.target.value);
   //   var index = e.nativeEvent.target.selectedIndex;
   //   setCreatorName(e.nativeEvent.target[index].text);
   // };
   const handleCategoryChange = e => {
      setCategoryValue(e.target.value);
      setSubCategoryValue(0);
   };
   const handleSubCategoryChange = e => {
      setSubCategoryValue(e.target.value);
   };
   const handleLanguageChange = e => {
      setLanguageValue(e.target.value);
   };
   const onManageLicenseChange = e => {
      if (e) {
         setRentalPeriodValue(3600);
         setAnonymousRentalPeriodValue(3600);
      } else {
         setRentalPeriodValue(null);
         setAnonymousRentalPeriodValue(null);
      }
      setIsManageLicense(e);
   };
   const handleRentalPeriodChange = e => {
      setRentalPeriodValue(e.target.value);
   };
   const handleAnonymousRentalPeriodChange = e => {
      setAnonymousRentalPeriodValue(e.target.value);
   };
   const handleClose = removedTag => {
      setTags(tags.filter(tag => tag !== removedTag));
   };
   const handleInputChange = e => {
      setInputValue(e.target.value);
   };

   const handleInputConfirm = () => {
      if (inputValue && tags.indexOf(inputValue) === -1) {
         let commaSplit = inputValue.split(',');
         setTags(tags.concat(commaSplit));
      }
      setInputVisible(false);
      setInputValue('');
   };

   const showInput = () => {
      setInputVisible(true);
   };

   const onDescriptionChange = e => {
      setDescription(e.target.value);
   };

   //  const chageFormatDuration = duration => {
   //     let durationInteger = parseInt(duration);
   //     let hour = parseInt(durationInteger / 3600);
   //     let min = parseInt(hour / 60);
   //     let sec = parseInt(min % 60);

   //     if (hour < 9) {
   //        hour = `0${hour}`;
   //     } else {
   //        hour = `` + hour;
   //     }
   //     if (min < 9) {
   //        min = `0${min}`;
   //     } else {
   //        min = `` + min;
   //     }
   //     if (sec < 9) {
   //        sec = `0${sec}`;
   //     } else {
   //        sec = `` + sec;
   //     }

   //     return `${hour}:${min}:${sec}`;
   //  };

   const changeFormatDuration = duration => {
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration % 3600) / 60);
      const remainingSeconds = Math.floor(duration % 60);

      return `${hours
         .toString()
         .padStart(2, '0')}:${minutes
         .toString()
         .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
   };

   return (
      <>
         <>
            {/* {podcastDetail ? ( */}
            <>
               {acceptedFiles && acceptedFiles.length ? (
                  <audio
                     style={{ display: 'none' }}
                     controls={true}
                     onLoadedMetadata={e => {
                        console.log(e.target);
                        setMillisecs(parseInt(e.target.duration * 1000));
                        setDuration(changeFormatDuration(e.target.duration));
                     }}
                  >
                     <source
                        src={URL.createObjectURL(acceptedFiles[0])}
                        type="audio/mpeg"
                     />
                  </audio>
               ) : (
                  ''
               )}
               <h2 className="center">อัปโหลดพ็อดคาสท์</h2>
               {filePost || uploadingFile ? (
                  <>
                     {actionsLoading && (
                        <Spinner forNewEbook={true} fullScreen={true} />
                     )}
                     <Formik
                        enableReinitialize={true}
                        initialValues={{
                           id: undefined
                        }}
                        validationSchema={uploadSchema}
                        onSubmit={values => {
                           const newValues = {
                              contentUID: contentUIDToUpload,
                              contentType: 'podcast_episode',
                              language: languageValue,
                              title: values.name,

                              tags: tags,
                              description: description,
                              duration: duration,
                              length: millisecs
                           };
                           if (
                              channelValue === id &&
                              newValues.title &&
                              newValues.contentUID
                           ) {
                              dispatch(
                                 actions4.createPodcast(
                                    newValues,
                                    channelValue === id ? id : '',
                                    setSuccessModal
                                 )
                              );
                           }
                        }}
                     >
                        {({ handleSubmit, values, setFieldValue }) => {
                           return (
                              <Card>
                                 <CardBody>
                                    <div
                                       style={{
                                          maxWidth: '736px',
                                          margin: 'auto'
                                       }}
                                    >
                                       <Form className="form form-label-right">
                                          <div className="row">
                                             <div
                                                className={`col-lg-12 ${classes.input}`}
                                             >
                                                <Field
                                                   name="name"
                                                   component={Input}
                                                   placeholder="กรุณากรอกชื่อ"
                                                   label="ชื่อ*"
                                                />
                                             </div>
                                          </div>
                                          <div className="row">
                                             <div
                                                className={`col-lg-8 ${classes.input}`}
                                             >
                                                <Field
                                                   name="channel"
                                                   component={Input}
                                                   label="รายการ*"
                                                   value={channelName}
                                                   disabled
                                                />
                                             </div>
                                             <div
                                                className={`col-lg-4 ${classes.input}`}
                                             >
                                                <Select
                                                   name="language"
                                                   withFeedbackLabel={false}
                                                   label="ภาษา"
                                                   onChange={
                                                      handleLanguageChange
                                                   }
                                                   value={languageValue}
                                                >
                                                   {languageList}
                                                </Select>
                                             </div>
                                          </div>
                                          <div className="row">
                                             <div
                                                className={`col-lg-12 ${classes.input}`}
                                             >
                                                <p
                                                   style={{
                                                      marginBottom: '0.5rem'
                                                   }}
                                                >
                                                   รายละเอียดพ็อดคาสท์
                                                </p>
                                                <CKEditor
                                                   editor={ClassicEditor}
                                                   config={{
                                                      toolbar: [
                                                         'bold',
                                                         'italic',
                                                         'bulletedList',
                                                         'numberedList'
                                                      ]
                                                   }}
                                                   data={
                                                      description
                                                         ? description
                                                         : ''
                                                   }
                                                   onReady={editor => {
                                                      // You can store the "editor" and use when it is needed.
                                                      // console.log("Editor is ready to use!", editor);
                                                   }}
                                                   onChange={(
                                                      event,
                                                      editor
                                                   ) => {
                                                      const data = editor.getData();
                                                      setDescription(data);
                                                   }}
                                                   onBlur={(event, editor) => {
                                                      // console.log("Blur.", editor);
                                                   }}
                                                   onFocus={(event, editor) => {
                                                      // console.log("Focus.", editor);
                                                   }}
                                                />
                                             </div>
                                          </div>
                                          <div className="row">
                                             <div
                                                className={`col-lg-12 ${classes.input}`}
                                             >
                                                <p
                                                   style={{
                                                      marginBottom: '0.5rem'
                                                   }}
                                                >
                                                   Tags
                                                </p>
                                                {tags.map(tag => {
                                                   const isLongTag =
                                                      tag.length > 20;

                                                   const tagElem = (
                                                      <Tag
                                                         className="edit-tag"
                                                         key={tag}
                                                         closable={true}
                                                         onClose={() =>
                                                            handleClose(tag)
                                                         }
                                                         style={{
                                                            display:
                                                               'inline-flex',
                                                            alignItems:
                                                               'center',
                                                            fontSize: '13px',
                                                            height: '32px'
                                                         }}
                                                      >
                                                         <span>
                                                            {isLongTag
                                                               ? `${tag.slice(
                                                                    0,
                                                                    20
                                                                 )}...`
                                                               : tag}
                                                         </span>
                                                      </Tag>
                                                   );
                                                   return isLongTag ? (
                                                      <Tooltip
                                                         title={tag}
                                                         key={tag}
                                                      >
                                                         {tagElem}
                                                      </Tooltip>
                                                   ) : (
                                                      tagElem
                                                   );
                                                })}
                                                {inputVisible && (
                                                   <Input2
                                                      ref={saveInputRef}
                                                      type="text"
                                                      size="small"
                                                      className="tag-input"
                                                      value={inputValue}
                                                      onChange={
                                                         handleInputChange
                                                      }
                                                      onBlur={
                                                         handleInputConfirm
                                                      }
                                                      onPressEnter={
                                                         handleInputConfirm
                                                      }
                                                      style={{
                                                         width: '100px',
                                                         height: '32px'
                                                      }}
                                                   />
                                                )}
                                                {!inputVisible && (
                                                   <Tag
                                                      className={`site-tag-plus ${classes.dashedBox}`}
                                                      onClick={showInput}
                                                   >
                                                      <PlusOutlined
                                                         style={{
                                                            marginRight: '2px'
                                                         }}
                                                      />{' '}
                                                      เพิ่ม Tag
                                                   </Tag>
                                                )}
                                             </div>
                                          </div>
                                          <Divider
                                             style={{
                                                width: 'calc(100% + 4.5rem)',
                                                margin: '16px -2.25rem'
                                             }}
                                          />
                                          <div className="row">
                                             <div
                                                className={`col-6 ${classes.uploadingLabel}`}
                                             >
                                                <div>
                                                   <img
                                                      src="/media/members/uploading-image.png"
                                                      alt=""
                                                      style={{
                                                         transform:
                                                            'translateY(-3px)'
                                                      }}
                                                   />{' '}
                                                   {parseInt(
                                                      progressBarObj.percentage,
                                                      10
                                                   ) === 100
                                                      ? 'อัปโหลดสำเร็จ'
                                                      : `กำลังอัปโหลด ${progressBarObj.percentage}% ...`}
                                                </div>
                                             </div>
                                             <div className="col-6">
                                                <div className="divButton">
                                                   <button
                                                      className="acceptButton"
                                                      type="submit"
                                                      disabled={
                                                         parseInt(
                                                            progressBarObj.percentage,
                                                            10
                                                         ) !== 100
                                                      }
                                                      onClick={() => {
                                                         setCheckingError(true);
                                                      }}
                                                   >
                                                      บันทึก
                                                   </button>
                                                </div>
                                             </div>
                                          </div>
                                       </Form>
                                    </div>
                                 </CardBody>
                              </Card>
                           );
                        }}
                     </Formik>
                  </>
               ) : (
                  <>
                     {/* <div style={{ margin: "0 20px -20px" }}> */}
                     <DashboardMini
                        storageUsedNow={storageUsedNow}
                        storageData={uploadState.storageData}
                        titleData={uploadState.titleData}
                     />

                     <div
                        {...getRootProps({ className: 'dropzone' })}
                        style={{
                           border: '1px dashed',
                           textAlign: 'center',
                           borderRadius: '10px',
                           margin: '16px 32px 32px 32px'
                        }}
                     >
                        <input {...getInputProps()} />
                        <img
                           src="/media/members/upload-file.png"
                           alt=""
                           className={classes.uploadFileImg}
                        />
                        <div style={{ height: '260px' }}>
                           <p className={classes.dropzoneTitle}>
                              ลากและวางไฟล์เสียงเพื่ออัปโหลด
                           </p>
                           <p className={classes.dropzoneSubTitleOrange}>
                              รองรับไฟล์ mp3 เท่านั้น
                           </p>
                           <button
                              type="button"
                              className="browseButtonPrimary acceptButton"
                              onClick={open}
                           >
                              เลือกไฟล์
                           </button>
                        </div>
                     </div>
                  </>
               )}
            </>
         </>
         <Modal
            backdrop="static"
            size="md"
            show={successModal}
            onHide={onHideSuccessModal}
            aria-labelledby="example-modal-sizes-title-lg"
            style={{ marginTop: '27px' }}
            dialogClassName="divSuccess"
         >
            <Modal.Body className={classes.body}>
               <div>
                  <img src="/media/bg/success.svg" alt="" />
               </div>
               <div className="divText-modal">เรียบร้อยแล้ว</div>
               <div>
                  <button
                     className="acceptButton "
                     onClick={() => {
                        onHideSuccessModal();
                        onHide();
                        setTimeout(() => {
                           setFilePost(false);
                        }, 200);
                     }}
                  >
                     ตกลง
                  </button>
               </div>
            </Modal.Body>
         </Modal>
      </>
   );
}
