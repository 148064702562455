import * as requestFromServer from './reportsCrud';
import { reportsSlice, callTypes } from './reportsSlice';
const { actions } = reportsSlice;

export const fetchReports = queryParams => dispatch => {
   dispatch(actions.startCall({ callType: callTypes.list }));
   return requestFromServer
      .findReports(queryParams)
      .then(response => {
         const { LastEvaluatedKey, contents, totalAmount } = response.data.data;
         const entities = contents;
         dispatch(
            actions.reportsFetched({
               totalCount: totalAmount,
               entities,
               LastEvaluatedKey
            })
         );
      })
      .catch(error => {
         if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errorCode === '012'
         ) {
            alert('กรุณาเข้าสู่ระบบอีกครั้ง เนื่องจากระยะเวลาการใช้งานหมดอายุ');
            window.location.href = '/logout';
         }
         error.clientMessage = "Can't find reports";
         dispatch(actions.catchError({ error, callType: callTypes.list }));
      });
};

export const fetchReport = id => dispatch => {
   if (!id) {
      return dispatch(actions.reportFetched({ reportForEdit: undefined }));
   }

   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .getReportById(id)
      .then(response => {
         const report = response.data;
         dispatch(actions.reportFetched({ reportForEdit: report }));
      })
      .catch(error => {
         error.clientMessage = "Can't find report";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const deleteReport = id => dispatch => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .deleteReport(id)
      .then(response => {
         dispatch(actions.reportDeleted({ id }));
      })
      .catch(error => {
         error.clientMessage = "Can't delete report";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const blockReport = id => dispatch => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .blockReport(id)
      .then(response => {
         dispatch(actions.reportDeleted({ id }));
      })
      .catch(error => {
         error.clientMessage = "Can't block report";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const createReport = reportForCreation => dispatch => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .createReport(reportForCreation)
      .then(response => {
         const { report } = response.data;
         dispatch(actions.reportCreated({ report }));
      })
      .catch(error => {
         error.clientMessage = "Can't create report";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const updateReport = report => dispatch => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .updateReport(report)
      .then(() => {
         dispatch(actions.reportUpdated({ report }));
      })
      .catch(error => {
         error.clientMessage = "Can't update report";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const updateReportsStatus = (ids, status) => dispatch => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .updateStatusForReports(ids, status)
      .then(() => {
         dispatch(actions.reportsStatusUpdated({ ids, status }));
      })
      .catch(error => {
         error.clientMessage = "Can't update reports status";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};
