import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/companies/companiesActions";
import { useCompaniesUIContext } from "../CompaniesUIContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
     body: {
          textAlign: "center",
     },
}));

export function CompanyBlockDialog({ name, id, isPrivate, show, onHide }) {
     // Companies UI Context
     const companiesUIContext = useCompaniesUIContext();
     const companiesUIProps = useMemo(() => {
          return {
               setIds: companiesUIContext.setIds,
               queryParams: companiesUIContext.queryParams,
          };
     }, [companiesUIContext]);

     // Companies Redux state
     const dispatch = useDispatch();
     const { isLoading } = useSelector((state) => ({ isLoading: state.companies.actionsLoading }), shallowEqual);

     // if !id we should close modal
     useEffect(() => {
          if (!id) {
               onHide();
          }

          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [id]);

     // looking for loading/dispatch
     useEffect(() => {}, [isLoading, dispatch]);

     const blockCompany = () => {
          // server request for deleting company by id
          dispatch(actions.blockCompany(id, isPrivate)).then(() => {
               // refresh list after deletion
               dispatch(actions.fetchCompanies(companiesUIProps.queryParams, isPrivate));
               // clear selections list
               companiesUIProps.setIds([]);
               // closing block modal
               onHide();
          });
     };

     const classes = useStyles();

     return (
          <Modal
               backdrop="static"
               show={show}
               onHide={onHide}
               aria-labelledby="example-modal-sizes-title-lg"
               style={{ margin: "3% auto" }}
          >
               {isLoading && <ModalProgressBar />}
               <Modal.Body className={classes.body}>
                    {name ? (
                         <>
                              <img src="/media/members/image-modal.svg" alt="" />
                              {!isLoading ? (
                                   <>
                                        <div className="text-text-red">ยืนยันการบล็อก</div>
                                        <div className="text-text-gray">ต้องการบล็อก {name} หรือไม่?</div>

                                        <div className="mt-3">
                                             <button type="button" onClick={onHide} className="denyButton">
                                                  ยกเลิก
                                             </button>
                                             <button type="button" onClick={blockCompany} className="acceptButton">
                                                  ยืนยัน
                                             </button>
                                        </div>
                                   </>
                              ) : (
                                   <div className="text-text-gray" style={{ marginBottom: "36px" }}>
                                        กำลังบล็อกสื่อ...
                                   </div>
                              )}
                         </>
                    ) : (
                         ""
                    )}
               </Modal.Body>
          </Modal>
     );
}
