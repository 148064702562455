/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { QRCodeGeneratorForm } from "./QRCodeGeneratorForm";
import { useCustomersUIContext } from "../CustomersUIContext";
import { makeStyles } from "@material-ui/core";
import LoadScriptOnlyIfNeeded, { libraries } from "../LoadScriptOnlyIfNeeded";
import { googleMapsApiKey } from "../../../../../../_metronic/_helpers/GoogleMapsApiKey";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles(() => ({
  body: { textAlign: "center" },
  divSuccess: {
    textAlign: "center",
    width: "340px",
    height: "360px",
    borderRadius: "10px",
    backgroundColor: "#fff",
  },
  divText: { fontSize: "24px", fontWeight: "600", color: "#012875" },
  button: {
    marginBottom: "30px",
    marginTop: "40px",
    fontSize: "17px",
    fontWeight: "600",
    width: "180px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "#012875",
    border: "none",
    color: "white",
  },
}));

const initQRCodeData = {
  name: "",
  latitude: "",
  longitude: "",
};

export function QRCodeGeneratorDialog({ id, show, onHide }) {
  // Customers UI Context
  const [successModal, setSuccessModal] = useState(false);
  const customersUIContext = useCustomersUIContext();
  const styles = useStyles();
  const [clientStartDate, setClientStartDate] = useState(null);
  const [clientExpireDate, setClientExpireDate] = useState(null);

  const { actionsLoading, authToken, viewAsToken } = useSelector(
    (state) => ({
      actionsLoading: state.customers.actionsLoading,
      authToken: state.auth.authToken,
      viewAsToken: state.auth.viewAsToken,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (viewAsToken) {
      if (jwt_decode(viewAsToken).startDate) {
        setClientStartDate(jwt_decode(viewAsToken).startDate);
      } else {
        setClientStartDate(null);
      }
      if (jwt_decode(viewAsToken).expireDate) {
        setClientExpireDate(jwt_decode(viewAsToken).expireDate);
      } else {
        setClientExpireDate(null);
      }
    } else if (authToken) {
      if (jwt_decode(authToken).startDate) {
        setClientStartDate(jwt_decode(authToken).startDate);
      } else {
        setClientStartDate(null);
      }
      if (jwt_decode(authToken).expireDate) {
        setClientExpireDate(jwt_decode(authToken).expireDate);
      } else {
        setClientExpireDate(null);
      }
    }
  }, [authToken, viewAsToken]);

  // server request for saving customer
  const saveQRCodeData = (data) => {
    // console.log(data);
  };
  const libraries = ["geometry", "drawing", "places"];

  return (
    <Modal
      backdrop="static"
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      dialogClassName={successModal ? styles.divSuccess : ""}
    >
      <LoadScriptOnlyIfNeeded googleMapsApiKey={googleMapsApiKey} libraries={libraries}>
        <QRCodeGeneratorForm
          saveQRCodeData={saveQRCodeData}
          actionsLoading={actionsLoading}
          qrCodeData={initQRCodeData}
          onHide={onHide}
          clientStartDate={clientStartDate}
          clientExpireDate={clientExpireDate}
        />
      </LoadScriptOnlyIfNeeded>
    </Modal>
  );
}
