import { createSlice } from "@reduxjs/toolkit";

const initialBannersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  entities_hti: [],
  bannerForEdit: undefined,
  lastError: null,
  LastEvaluatedKey: {},
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const bannersSlice = createSlice({
  name: "banners",
  initialState: initialBannersState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.error && action.payload.error.response && action.payload.error.response.data) {
        state.error = action.payload.error.response.data.errorCode;
      }
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getBannerById
    bannerFetched: (state, action) => {
      state.actionsLoading = false;
      state.bannerForEdit = action.payload.bannerForEdit;
      state.error = null;
    },
    // findBanners
    bannersFetched: (state, action) => {
      const { totalCount, entities, LastEvaluatedKey, lastEvaluate, isPrivate } = action.payload;
      state.listLoading = false;
      state.error = null;
      if (isPrivate) {
        if (lastEvaluate === undefined) {
          state.entities = entities;
        } else {
          entities.forEach((item) => state.entities.push(item));
        }
      } else {
        if (lastEvaluate === undefined) {
          state.entities_hti = entities;
        } else {
          entities.forEach((item) => state.entities_hti.push(item));
        }
      }
      state.totalCount = totalCount;
      state.LastEvaluatedKey = LastEvaluatedKey;
      // state.entities = mockUsers;
      // state.totalCount = 17;
    },
    // createBanner
    bannerCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.banner);
    },
    // updateBanner
    bannerUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.uid === action.payload.banner.uid) {
          return action.payload.banner;
        }
        return entity;
      });
    },
    // deleteBanner
    bannerDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => el.uid !== action.payload.id);
    },
    // blockBanner
    bannerBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.uid === action.payload.id) {
          return Object.assign({}, entity, { status: 2 });
        }
        return entity;
      });
    },
    // deleteBanners
    bannersDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => !action.payload.ids.includes(el.id));
    },
    // bannersUpdateState
    bannersStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    elementUpdated: (state, action) => {
      state.actionsLoading = false;
    },
  },
});
