// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

export function ImageColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { isSubCat, openViewCategoryDialog, colorObj, totalCount }
) {
  // const colorOfBG = () => {
  //   if (colorObj.length === totalCount) {
  //     if (colorObj.filter((el) => el.index === rowIndex).length) {
  //       if (colorObj.filter((el) => el.index === rowIndex)[0].color === "error") {
  //         return "#dedede";
  //       } else {
  //         // console.log(colorObj.filter((el) => el.index === rowIndex)[0].color);
  //         return colorObj.filter((el) => el.index === rowIndex)[0].color.hex;
  //       }
  //     }
  //   }
  ////   return "#dedede";
  // };
  return (
    <div className="row">
      {/*{!isSubCat ? (
        <div
          style={{
            width: "60px",
            height: "60px",
            display: "grid",
            alignContent: "center",
            justifyContent: "center",
            borderRadius: "6px",
            backgroundColor: colorOfBG(),
          }}
        >
          <img
            alt=""
            id={rowIndex}
            src={row && row.coverImage ? row.coverImage : "/media/members/placeholder-image.png"}
            style={{ maxHeight: "50px", maxWidth: "32px" }}
          />
        </div>
      ) : (
        ""
      )}*/}
      <div
        style={{
          display: "flex",
          // textAlign: "center",
          alignSelf: "center",
          marginLeft: "12.5px"
        }}
      >
        {row && (row.catName || row.subcatName)}
      </div>
    </div>
  );
}
