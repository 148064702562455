/* eslint-disable array-callback-return */
import React, { useMemo, useState } from "react";
import { Card, CardBody, CardHeaderToolbar, CardHeader } from "../../../../../_metronic/_partials/controls";
import { BannersTable } from "./banners-table/BannersTable";
import { BannersGrouping } from "./banners-grouping/BannersGrouping";
import { useBannersUIContext } from "./BannersUIContext";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";

// const AntTabs = withStyles({
//   root: {
//     borderBottom: "none",
//   },
//   indicator: {
//     backgroundColor: "#fd6b53",
//   },
// })(Tabs);

// const AntTab = withStyles((theme) => ({
//   root: {
//     textTransform: "none",
//     minWidth: 72,
//     fontWeight: theme.typography.fontWeightRegular,
//     marginRight: theme.spacing(4),
//     fontFamily: "Prompt",
//     fontSize: "20px",
//     "&:hover": {
//       color: "#012875",
//       opacity: 1,
//     },
//     "&$selected": {
//       color: "#012875",
//       fontWeight: theme.typography.fontWeightMedium,
//     },
//     "&:focus": {
//       color: "#012875",
//     },
//   },
//   selected: {},
// }))((props) => <Tab disableRipple {...props} />);

export function BannersCard() {
     const bannersUIContext = useBannersUIContext();
     // const [open, setOpen] = React.useState(false);
     const [isPrivate, setIsPrivate] = React.useState(1);
     const { viewAsToken } = useSelector((state) => state.auth);
     // const anchorRef = React.useRef(null);
     const handleChange = (newValue) => {
          setIsPrivate(newValue);
     };
     // const handleToggle = () => {
     //   setOpen((prevOpen) => !prevOpen);
     // };

     // const handleClose = (event) => {
     //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
     //     return;
     //   }

     //   setOpen(false);
     // };
     // function handleListKeyDown(event) {
     //   if (event.key === "Tab") {
     //     event.preventDefault();
     //     setOpen(false);
     //   }
     // }
     const bannersUIProps = useMemo(() => {
          return {
               ids: bannersUIContext.ids,
               newBannerButtonClick: bannersUIContext.newBannerButtonClick,
          };
     }, [bannersUIContext]);
     const useStyles1 = makeStyles((theme) => ({
          div: {
               marginBottom: -22,
          },
          p1: {
               fontFamily: "Prompt",
               fontSize: "20px",
               fontWeight: 600,
               color: "#012875",
          },
     }));
     const stepLabel = ["Hibrary", "แบนเนอร์"];
     const [listLoading, setListLoading] = useState(false);
     const classes = useStyles1();

     return (
          <>
               {/*stepLabel.map((el, index) => {
        if (index === isPrivate) {
          return (
            <div
              key={index}
              className={`col-2 ${
                index === isPrivate
                  ? classes.stepDiv
                  : listLoading
                  ? classes.stepDivBlack_wait
                  : classes.stepDivBlack
              }`}
              onClick={() => {
                if (!listLoading) handleChange(index);
              }}
            >
              <p
                className={
                  index === isPrivate
                    ? classes.stepDiv_p
                    : classes.stepDivBlack_p
                }
              >
                {el}
              </p>
            </div>
          );
        }
      }) */}
               <Card className="cardStyle">
                    <CardHeader
                         title={
                              <div className={classes.div}>
                                   <p className={classes.p1}>แบนเนอร์</p>
                              </div>
                         }
                    >
                         <CardHeaderToolbar>
                              <button
                                   type="button"
                                   className="acceptButton "
                                   onClick={() => bannersUIProps.newBannerButtonClick(isPrivate)}
                              >
                                   <span>อัปโหลดแบนเนอร์</span>
                              </button>
                         </CardHeaderToolbar>
                    </CardHeader>
                    <CardBody className="cardBodyStyle">
                         {bannersUIProps.ids.length > 0 && <BannersGrouping />}
                         <BannersTable isPrivate={isPrivate} setListLoading={setListLoading} />
                    </CardBody>
               </Card>
          </>
     );
}
