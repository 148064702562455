import React, { useState, useEffect } from "react";
import { UploadUIProvider } from "./UploadUIContext";
import { UploadCard } from "./UploadCard";
import { UploadInformationDialog } from "./upload-information-dialog/UploadInformationDialog";
import { PublisherNewDialog } from "../publishers/publisher-new-dialog/PublisherNewDialog";
import { CategoryNewDialog } from "../categories/category-new-dialog/CategoryNewDialog";
import queryString from "query-string";

export function UploadEbookPage({ history, onHide, location }) {
  const [information, setInformation] = useState(null);
  const [newPublisher, setNewPublisher] = useState(null);
  const [newAuthor, setNewAuthor] = useState(null);
  const [newCategory, setNewCategory] = useState(null);
  const [newSubCategory, setNewSubCategory] = useState(null);
  const [errorMessage, setErrorMessage] = useState(undefined);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.information) {
      setInformation(parsed.information);
    }
    if (parsed.newPublisher) {
      setNewPublisher(parsed.newPublisher);
    } else {
      setNewPublisher(false);
    }
    if (parsed.newAuthor) {
      setNewAuthor(parsed.newAuthor);
    } else {
      setNewAuthor(false);
    }
    if (parsed.newCategory) {
      setNewCategory(parsed.newCategory);
    }
    if (parsed.newSubCategory) {
      setNewSubCategory(parsed.newSubCategory);
    }
  }, [location.search]);

  const uploadUIEvents = {
    openFileInformationDialog: (error, message) => {
      if (error) {
        setErrorMessage(message);
        history.push("/ebooks-private?newEbook=true&information=error");
      } else {
        history.push("/ebooks-private?newEbook=true&information=left");
      }
    },
    openCoverInformationDialog: () => {
      history.push("/ebooks-private?newEbook=true&information=right");
    },
    newPublisherButtonClick: () => {
      history.push("/ebooks-private?newEbook=true&newPublisher=true");
    },
    newAuthorButtonClick: () => {
      history.push("/ebooks-private?newEbook=true&newAuthor=true");
    },
    newCategoryButtonClick: () => {
      history.push("/ebooks-private?newEbook=true&newCategory=true");
    },
    newSubCategoryButtonClick: () => {
      history.push("/ebooks-private?newEbook=true&newSubCategory=true");
    },
  };
  return (
    <UploadUIProvider uploadUIEvents={uploadUIEvents}>
      {information === "error" ? (
        <UploadInformationDialog
          show={true}
          onHide={() => {
            history.push(`/ebooks-private?newEbook=true`);
            setInformation(null);
            setErrorMessage(undefined);
          }}
          isError={true}
          errorMessage={errorMessage}
        />
      ) : (
        ""
      )}
      {information === "left" ? (
        <UploadInformationDialog
          show={true}
          onHide={() => {
            history.push(`/ebooks-private?newEbook=true`);
            setInformation(null);
          }}
          isLeft={true}
        />
      ) : (
        ""
      )}
      {information === "right" ? (
        <UploadInformationDialog
          show={true}
          onHide={() => {
            history.push(`/ebooks-private?newEbook=true`);
            setInformation(null);
          }}
          isLeft={false}
        />
      ) : (
        ""
      )}
      {/*newPublisher ? (
        <PublisherNewDialog
          show={true}
          onHide={() => {
            history.push(`/ebooks-private?newEbook=true`);
            setNewPublisher(false);
          }}
          isPrivate={1}
          notOrigin={true}
        />
      ) : (
        ""
      )*/}
      {/*newAuthor ? (
        <PublisherNewDialog
          show={true}
          onHide={() => {
            history.push(`/ebooks-private?newEbook=true`);
            setNewAuthor(false);
          }}
          isPrivate={1}
          isAuthor={true}
          notOrigin={true}
        />
      ) : (
        ""
      )*/}
      {/*newCategory ? (
        <CategoryNewDialog
          show={true}
          onHide={() => {
            history.push(`/ebooks-private?newEbook=true`);
            setNewCategory(false);
          }}
          isPrivate={1}
          notOrigin={true}
          contentType="ebook"
        />
      ) : (
        ""
      )*/}
      {/*newSubCategory ? (
        <CategoryNewDialog
          show={true}
          onHide={() => {
            history.push(`/ebooks-private?newEbook=true`);
            setNewSubCategory(false);
          }}
          isPrivate={1}
          isSubCat={true}
          notOrigin={true}
          contentType="ebook"
        />
      ) : (
        ""
      )*/}
      <UploadCard fromEbookPage={true} onHide={onHide} />
    </UploadUIProvider>
  );
}
