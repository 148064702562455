/* eslint-disable react-hooks/exhaustive-deps */
//@ts-nocheck

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import moment from 'moment';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import {
    Tabs,
    Dropdown,
    Menu,
    Table,
    Button,
    Skeleton,
    DatePicker
} from 'antd';
import {
    Card,
    CardHeader,
    CardHeaderToolbar,
    CardBody
} from '../../../../../_metronic/_partials/controls/Card';

import './checkebook.scss';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import BootstrapTable from 'react-bootstrap-table-next';
import * as util from './CheckEbookFunc';
import {
    chkLastEvaluateKey,
    LoaderView,
    NodataView,
    Modals,
    ModalsSuccess,
    ModalsWithBody,
    LoaderAbsolute
} from '../../../CustomView';
import { QuotationModal } from './modal/QuotationModal';
import { QuoteDatePickerModal } from './modal/QuoteDatePickerModal';
import { CheckLicenseModal } from './modal/check-license';

const { TabPane } = Tabs;

export function CheckEbookDetailPage({ history, location }) {
    const scrollRef = useRef();
    const parsed = queryString.parse(location.search);
    const [sortBy, setSortBy] = useState('sort');
    const [filterStatus, setFilterStatus] = useState('');
    const [filterReject, setFilterReject] = useState('');

    // const minWidthOfAll = 900;
    // const [scroll, setScroll] = useState();
    const [tab, setTab] = useState('1');
    const [searchAll, setSearchAll] = useState('');
    const [searchCategory, setSearchCategory] = useState('');
    const [searchPublisher, setSearchPublisher] = useState('');
    const [filteredcatalogs, setFilterCatalogs] = useState([]);
    const [FilterPublishers, setFilterPublishers] = useState([]);

    const [loading, setLoading] = useState(true);
    const [loadingcat, setLoadingcat] = useState(true);
    const [loadingPublishers, setLoadingPublishers] = useState(true);
    const [progressLast, setProgressLast] = useState(false);

    const [contents, setContents] = useState([]);
    const [filterContents, setFilterContents] = useState([]);
    const [contentStatus, setContentstatus] = useState();
    const [confirm, setConfirm] = useState();
    const [confirmSent, setConfirmModalSent] = useState();

    const [startDateApprove, setStartDateApprove] = useState(moment().unix());
    const [endDateApprove, setEndDateApprove] = useState(moment().unix());
    const [modalApprove, setModalApprove] = useState(false);
    const [confirmApprove, setConfirmModalApprove] = useState();

    const [confirmExcel, setConfirmModalExcel] = useState();
    const [loadingButtonExcel, setLoadingButtonExcel] = useState(false);

    const [loadingModalSent, setLoadingModalSent] = useState(false);
    const [loadingModalApprove, setLoadingModalApprove] = useState(false);

    const [contentUID, setcontentUID] = useState();
    const [contentvalue, setContentvalue] = useState();
    const [rejectMessages, setrejectMessages] = useState();
    const [successModal, setSuccessModal] = useState();
    const [catalog_details, setcatalog_details] = useState();
    const [catalogLastEvaluatedKey, setCatalogLastEvaluatedKey] = useState(
        undefined
    );
    const [publishers, setpublishers] = useState([]);
    const [subcategories, setsubcategories] = useState([]);

    //? quotation modal state
    const [quotationModal, setQuotationModal] = useState(false);
    const [isQuotation, setIsQuotation] = useState(false);
    const [startDate, setStartDate] = useState(moment().unix());
    const [payDeadLine, setPayDeadLineDate] = useState(moment().unix());
    const [licenseDate, setLicenseDate] = useState(moment().unix());
    const [isSearch, setIsSearch] = useState(false);

    //? confirm modal state
    const [confirmModal, setConfirmModal] = useState(false);

    //?  check license modal state
    const [checkLicenseModal, setCheckLicenseModal] = useState(false);

    const onChangesearchAll = e => {
        setSearchAll(e.target.value);
    };
    const onChangesearchCatagory = e => {
        setSearchCategory(e.target.value);
    };
    const onChangesearchPublisher = e => {
        setSearchPublisher(e.target.value);
    };

    const dropdownStatusBtn = row => (
        <Menu>
            {contentStatus?.map((e, i) => {
                // if (row.status == 1 && e.value == 2) {
                // } else {
                return (
                    <Menu.Item
                        key={i}
                        onClick={() => handleDropdownStatusClick(row, e)}
                    >
                        {e.title}
                    </Menu.Item>
                );
                // }
            })}
        </Menu>
    );

    const handleDropdownStatusClick = (row, e) => {
        if (row !== null) {
            setConfirm(true);
            setcontentUID(row.uid);
            setContentvalue(e.value);
            setrejectMessages(e.rejectMessages);
        }
    };

    const setFilter = (status, reject) => {
        setFilterStatus(status);
        setFilterReject(reject);
    };

    const onStartDateApproveChange = date => {
        if (date) {
            setStartDateApprove(date.unix());
        }
    };

    const onEndDateApproveChange = date => {
        if (date) {
            setEndDateApprove(date.unix());
        }
    };

    const columns = [
        {
            dataField: '',
            text: 'ลำดับ',
            formatter: util.IndexFormatter,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'coverImage',
            text: 'ปก',
            formatter: util.CoverImageFormatter,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'none',
            text: 'รายละเอียด',

            formatter: util.DetailContentFormatter,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'lifttimePurchase',
            text: 'ขายต่อเนื่อง',
            formatter: util.IsLifttimePurchaseFormatter,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'concurrent',
            text: 'จำนวนสิทธิ์',
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'priceTHB',
            text: 'ราคาขาย',
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'totalPrice',
            text: 'ราคารวม',
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'status',
            text: 'ตรวจสอบสิทธิ์',
            formatter: util.ActionContentFormatter,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            },
            formatExtraData: {
                isHistory:
                    catalog_details && catalog_details.status !== 3
                        ? false
                        : true,
                dropdownStatusBtn,
                contentStatus
            }
        }
    ];

    const columns2 = [
        {
            dataIndex: 'title',
            title: 'หมวดหมู่',
            key: 'title'
        },
        {
            key: 'totalPrice',
            dataIndex: 'totalPrice',
            title: 'ราคารวม'
        },
        {
            key: 'contentAmount',
            dataIndex: 'contentAmount',
            title: 'จำนวนอีบุ๊ก'
        },
        {
            key: 'concurrentAmount',
            dataIndex: 'concurrentAmount',
            title: 'จำนวนสิทธิ์ '
        },
        {
            key: 'title',
            dataIndex: '',
            title: 'จัดการ',
            render: (value, row) => (
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip id="quick-user-tooltip">รายละเอียด</Tooltip>
                    }
                >
                    <a
                        className="btn btn-icon btn-light btn-hover-primary btn-sm"
                        onClick={() => {
                            history.push(
                                `/checkebook-catagory?id=${parsed.id}&title=${parsed.title}&categoryId=${row.key}&subtitle=${row.title}&clientUID=${parsed.clientUID}`
                            );
                        }}
                    >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                                src={toAbsoluteUrl(
                                    '/media/members/view-qrcode-register.svg'
                                )}
                            />
                        </span>
                    </a>
                </OverlayTrigger>
            )
        }
    ];

    const columnspublishers = [
        {
            dataField: 'profileImage',
            text: 'สำนักพิมพ์/นักเขียน',
            formatter: (value, row) => (
                <div>
                    <img src={row.profileImage} />
                    <div>
                        <div className="text text-title">
                            {row?.publisherName}
                        </div>
                    </div>
                </div>
            ),
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'totalPrice',
            text: 'ราคารวม',
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },

        {
            dataField: 'discount',
            text: 'ส่วนลด',
            formatter: value => <span>{value}%</span>,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'grandTotal',
            text: 'ราคาหลังหักส่วนลด',
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'contentAmount',
            text: 'จำนวนอีบุ๊ก',
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'concurrentAmount',
            text: 'จำนวนสิทธิ์',
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'vandorReported',
            text: 'Q',
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            },
            formatter: (value, row) => (
                <div>
                    {row.vendorReported ? (
                        <img src="/media/members/check-true.svg" alt="" />
                    ) : (
                        '-'
                    )}
                </div>
            )
        },
        {
            dataField: 'licenseReported',
            text: 'L',
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            },
            formatter: (value, row) => (
                <div>
                    {row.licenseReported ? (
                        <img src="/media/members/check-true.svg" alt="" />
                    ) : (
                        '-'
                    )}
                </div>
            )
        },
        {
            dataField: 'uid',
            text: 'จัดการ',
            formatter: (value, row) => (
                <>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="quick-user-tooltip">
                                รายละเอียด
                            </Tooltip>
                        }
                    >
                        <a
                            className="btn btn-icon btn-light btn-hover-primary btn-sm"
                            onClick={() => {
                                history.push(
                                    `/checkebook-subdetail?id=${parsed.id}&title=${parsed.title}&publisherName=${row.publisherName}&clientUID=${parsed.clientUID}&uid=${row.uid}`
                                );
                            }}
                        >
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                    src={toAbsoluteUrl(
                                        '/media/members/view-qrcode-register.svg'
                                    )}
                                />
                            </span>
                        </a>
                    </OverlayTrigger>
                </>

                // <button
                //   className="button-description"
                //   onClick={() => {
                //     history.push(
                //       `/checkebook-subdetail?id=${parsed.id}&title=${parsed.title}&publisherName=${row.publisherName}&clientUID=${parsed.clientUID}&uid=${row.uid}`
                //     );
                //   }}
                // >
                //   รายละเอียด
                // </button>
            ),
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        }
    ];

    const postStatus = async () => {
        try {
            const req = await axios.post(
                `/superuser/catalogs/${parsed.id}/contents/approved`,
                {
                    clientUID: parsed.clientUID,
                    payload: [
                        {
                            contentUID: contentUID,
                            status: contentvalue,
                            rejectStatus: rejectMessages
                        }
                    ]
                }
            );
            if (req) {
                setConfirm(false);
                setSuccessModal(true);
                setcontentUID();
                setContentvalue();
                setrejectMessages();

                // reload
                setLoading(true);
                setCatalogLastEvaluatedKey(undefined);
                setContents([]);
                getdata();
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const sentApproveCatalog = async () => {
        setLoadingModalApprove(true);
        try {
            const req = await axios.post(
                `/superuser/catalogs/${parsed.id}/approved`,
                {
                    clientUID: parsed.clientUID,
                    // period: 0,
                    startDate: startDateApprove,
                    expireDate: endDateApprove
                }
            );
            if (req.status) {
                setConfirmModalApprove(false);
                setLoadingModalApprove(false);
                setSuccessModal(true);
            }
        } catch (error) {
            setConfirmModalApprove(false);
            setLoadingModalApprove(false);
        }
    };

    const sentData = async () => {
        setLoadingModalSent(true);
        try {
            const req = await axios.post(
                `/superuser/catalogs/${parsed.id}/submit`,
                {
                    clientUID: parsed.clientUID
                }
            );
            if (req.status) {
                setConfirmModalSent(false);
                setLoadingModalSent(false);
                setSuccessModal(true);
            }
        } catch (error) {
            setConfirmModalSent(false);
            setLoadingModalSent(false);
        }
    };

    const getdata = async (isClearSearch = false) => {
        try {
            setLoading(true);
            var path = `/superuser/catalogs/${parsed.id}/contents`;

            const req = await axios.get(
                queryString.stringifyUrl({
                    url: path,
                    query: { clientUID: parsed.clientUID }
                })
            );

            const { data } = req;
            if (data.status && data.data) {
                const { contents, LastEvaluatedKey } = data.data;
                setCatalogLastEvaluatedKey(LastEvaluatedKey);
                setLoading(false);
                if (isClearSearch) {
                    setContents(contents);
                } else {
                    setContents(val => val.concat(contents));
                }
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const getNextData = async () => {
        var qString = {};
        var path = `/superuser/catalogs/${parsed.id}/contents`;
        if (catalogLastEvaluatedKey !== undefined) {
            qString = chkLastEvaluateKey(catalogLastEvaluatedKey);
            qString.clientUID = parsed.clientUID;
            const req = await axios.get(
                queryString.stringifyUrl({
                    url: path,
                    query: qString
                })
            );
            const { data } = req;
            if (data.status && data.data) {
                const { contents, LastEvaluatedKey } = data.data;
                setCatalogLastEvaluatedKey(LastEvaluatedKey);
                setLoading(false);
                setContents(val => val.concat(contents));
            }
        }
    };

    const getdatacatalog_details = async () => {
        try {
            const req = await axios.get(
                `/superuser/catalogs/${parsed.id}?clientUID=${parsed.clientUID}`
            );
            const { data } = req;
            if (data.status && data.data) {
                let catalogs = data.data;
                setcatalog_details(catalogs);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const getContentStatus = async () => {
        try {
            const req = await axios.get(
                `/superuser/catalogs/${parsed.id}/contents/status?clientUID=${parsed.clientUID}`
            );
            const { data } = req;
            console.log(data);
            if (data.data) {
                let status = data.data;
                setContentstatus(status.status);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const getcategories = async () => {
        try {
            setLoadingcat(true);
            const req = await axios.get(
                `/superuser/catalogs/${parsed.id}/categories?clientUID=${parsed.clientUID}`
            );
            const { data } = req;
            if (data.status) {
                setLoadingcat(false);
            }
            if (data.status && data.data) {
                let categories = data.data.categories;
                console.log(categories);
                setcategoriesdata(categories);
                // setFilterCatalogs(categories);
            } else {
                setLoadingcat(true);
            }
        } catch (error) {
            setLoadingcat(false);
        }
    };

    const searchContentInCatalog = async () => {
        try {
            setIsSearch(true);
            setLoading(true);
            const { data } = await axios.get(
                `/superuser/catalogs/${parsed.id}/search`,
                {
                    params: {
                        keyword: searchAll,
                        clientUID: parsed.clientUID,
                        status: filterStatus !== '' ? filterStatus : undefined
                    }
                }
            );
            if (data.status) {
                console.log(data.data);
                setFilterContents(data.data.contents);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const setcategoriesdata = categories => {
        let cat = categories;
        const subcatdata = [];
        for (let i = 0; i < cat.length; i++) {
            let dataArr = cat[i];
            subcatdata.push({
                key: dataArr.categoryId,
                title: dataArr.title,
                totalPrice: dataArr.totalPrice,
                contentAmount: dataArr.contentAmount,
                subcats: dataArr.subcategories,
                concurrentAmount: dataArr.concurrentAmount
            });
        }
        setsubcategories(subcatdata);
    };

    const getdatapublishers = async () => {
        try {
            setLoadingPublishers(true);
            const req = await axios.get(
                `/superuser/catalogs/${parsed.id}/publishers?clientUID=${parsed.clientUID}`
            );
            const { data } = req.data;

            if (req) {
                setLoadingPublishers(false);
                setpublishers(data.publishers);
            } else {
                setLoadingPublishers(true);
            }
        } catch (error) {
            setLoadingPublishers(false);
        } finally {
            setLoadingPublishers(false);
        }
    };

    const Summaryebook = () => {
        return (
            <div>
                <span className="mr-5">
                    <span className="text text-gray-drak">จำนวนอีบุ๊ก : </span>
                    <span className="text text-blue bold">
                        {catalog_details?.contentsAmount
                            ? catalog_details?.contentsAmount
                            : 0}
                    </span>
                </span>
                <span className="mr-5">
                    <span className="text text-gray-drak ">จำนวนสิทธิ์ : </span>
                    <span className="text text-blue bold">
                        {catalog_details?.concurrent
                            ? catalog_details?.concurrent
                            : 0}
                    </span>
                </span>

                <span className="text text-sky-blue">
                    ราคาทั้งหมด :{' '}
                    {catalog_details?.totalPrice
                        ? catalog_details?.totalPrice
                        : 0}
                </span>
            </div>
        );
    };

    const downloadExcel = async () => {
        setLoadingButtonExcel(true);
        const req = await axios
            .get(
                `/superuser/catalogs/${parsed.id}/export?clientUID=${parsed.clientUID}`
            )
            .then(res => {
                if (res.data && res.data.data && res.data.data.fulfillmentUrl) {
                    let link = document.createElement('a');
                    link.setAttribute('download', 'template');
                    link.href = res.data.data.fulfillmentUrl;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
                setLoadingButtonExcel(false);
            });
    };

    const onClickSearch = () => {
        switch (tab) {
            case '1': {
                if (isSearch) {
                    setSearchAll('');
                    setIsSearch(false);
                    getdata(true);
                } else {
                    searchContentInCatalog();
                }
                break;
            }
            case '2': {
                if (isSearch) {
                    setSearchCategory('');
                    setIsSearch(false);
                    getcategories();
                } else {
                    onSearchCategory();
                }
                break;
            }
            case '3': {
                if (isSearch) {
                    setSearchPublisher('');
                    setIsSearch(false);
                    getdatapublishers();
                } else {
                    onSearchPublisher();
                }
                break;
            }
            default:
                break;
        }
    };

    const expandedRowRender = record => {
        let subcat = record?.subcats;
        const data = [];
        for (let i = 0; i < subcat.length; i++) {
            let dataArr = subcat[i];
            data.push({
                type: 'subcat',
                key: dataArr.subcategoryId,
                catId: record.key,
                subcatId: dataArr.subcategoryId,
                title: dataArr.title,
                totalPrice: dataArr.totalPrice,
                contentAmount: dataArr.contentAmount,
                concurrentAmount: dataArr.concurrentAmount
            });
        }
        const subcatcolumns = [
            {
                dataIndex: 'title',
                title: '',
                key: 'title'
            },
            {
                key: 'totalPrice',
                dataIndex: 'totalPrice',
                title: ''
            },
            {
                key: 'contentAmount',
                dataIndex: 'contentAmount',
                title: ''
            },
            {
                key: 'concurrentAmount',
                dataIndex: 'concurrentAmount',
                title: ''
            },
            {
                key: '',
                dataIndex: '',
                title: '',
                align: 'center',
                render: (value, row) => {
                    return (
                        <button
                            className="button-description"
                            onClick={() => {
                                history.push(
                                    `/checkebook-catagory?id=${parsed.id}&title=${parsed.title}&categoryId=${row.catId}&subcategoryId=${row.subcatId}&subtitle=${row.title}&clientUID=${parsed.clientUID}`
                                );
                            }}
                        >
                            รายละเอียด
                        </button>
                    );
                }
            },
            {
                dataIndex: '',
                title: '',
                key: 'type'
            }
        ];

        return (
            <Table
                columns={subcatcolumns}
                dataSource={data}
                pagination={false}
                expandIconColumnIndex={5}
                className="components-table-nested-checkebook"
            />
        );
    };

    const ButtonExcel = () => {
        return (
            <Button
                className="button-green icon"
                onClick={() => {
                    setConfirmModalExcel(true);
                }}
                loading={loadingButtonExcel}
            >
                <span>Export Excel</span>
            </Button>
        );
    };

    const TitleBar = () => {
        return (
            <div className="card-label">
                {catalog_details ? (
                    <>
                        <div style={{ marginBottom: '0.5rem' }}>
                            {catalog_details?.title}
                        </div>
                        <div className="detail">
                            {catalog_details?.clientName}
                        </div>
                    </>
                ) : (
                    <Skeleton paragraph={{ rows: 1 }} active />
                )}
            </div>
        );
    };

    const ToolBar = props => {
        let tab = parseInt(props.tab);
        let sent = 'ส่งข้อมูล';
        return (
            <>
                {tab === 1 ? (
                    <input
                        type="text"
                        className="form-control"
                        name="searchText"
                        placeholder="ค้นหา"
                        value={searchAll}
                        autoFocus={true}
                        onChange={e => onChangesearchAll(e)}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                searchContentInCatalog();
                            }
                        }}
                        //search
                    />
                ) : tab === 2 ? (
                    <input
                        type="text"
                        className="form-control"
                        name="searchText"
                        placeholder="ค้นหา"
                        value={searchCategory}
                        autoFocus={true}
                        onChange={e => onChangesearchCatagory(e)}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                onSearchCategory();
                            }
                        }}
                    />
                ) : (
                    <input
                        type="text"
                        className="form-control"
                        name="searchText"
                        placeholder="ค้นหา"
                        value={searchPublisher}
                        autoFocus={true}
                        onChange={e => onChangesearchPublisher(e)}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                onSearchPublisher();
                            }
                        }}
                    />
                )}
                <SVG
                    src={
                        isSearch
                            ? '/media/members/reset-search.svg'
                            : '/media/members/search-icon.svg'
                    }
                    alt=""
                    className="icon-search"
                    onClick={() => onClickSearch()}
                />
                {tab === 1 ? (
                    <Dropdown
                        overlay={() => (
                            <util.sortBtn
                                contentStatus={contentStatus}
                                setFilter={(status, reject) => {
                                    setFilter(status, reject);
                                }}
                            />
                        )}
                        trigger={['click']}
                        placement="bottomLeft"
                    >
                        <button
                            type="button"
                            className="button-border -icon button-filter"
                        >
                            <span>
                                {contentStatus && (
                                    <util.filterStatus
                                        contentStatus={contentStatus}
                                        status={filterStatus}
                                        reject={filterReject}
                                    />
                                )}
                            </span>
                        </button>
                    </Dropdown>
                ) : null}

                {catalog_details && catalog_details.status !== 3 ? (
                    <>
                        <ButtonExcel loading={loadingButtonExcel}></ButtonExcel>
                        <button
                            type="button"
                            className="button "
                            onClick={() => {
                                setConfirmModalSent(true);
                            }}
                        >
                            <span>ส่งข้อมูล</span>
                        </button>
                        <button
                            type="button"
                            className="button-dark "
                            onClick={() => {
                                setModalApprove(true);
                            }}
                        >
                            <span>เพิ่มรายการอีบุ๊กให้องค์กร</span>
                        </button>

                        <button
                            type="button"
                            className="button-dark"
                            onClick={() => {
                                setQuotationModal(true);
                                setIsQuotation(true);
                            }}
                        >
                            <span>ส่งใบเสนอราคา</span>
                        </button>

                        <button
                            type="button"
                            className="button-dark"
                            onClick={() => setCheckLicenseModal(true)}
                        >
                            <span>ตรวจสอบลิขสิทธิ์</span>
                        </button>
                    </>
                ) : null}
            </>
        );
    };

    const ToolBarSmall = props => {
        let tab = parseInt(props.tab);
        let sent = 'ส่งข้อมูล';
        return (
            <>
                {tab === 1 ? (
                    <input
                        type="text"
                        className="form-control"
                        name="searchText"
                        placeholder="ค้นหา"
                        value={searchAll}
                        autoFocus={true}
                        onChange={e => onChangesearchAll(e)}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                searchContentInCatalog();
                            }
                        }}
                    />
                ) : tab === 2 ? (
                    <input
                        type="text"
                        className="form-control"
                        name="searchText"
                        placeholder="ค้นหา"
                        value={searchCategory}
                        autoFocus={true}
                        onChange={e => onChangesearchCatagory(e)}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                onSearchCategory();
                            }
                        }}
                    />
                ) : (
                    <input
                        type="text"
                        className="form-control"
                        name="searchText"
                        placeholder="ค้นหา"
                        value={searchPublisher}
                        autoFocus={true}
                        onChange={e => onChangesearchPublisher(e)}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                onSearchPublisher();
                            }
                        }}
                    />
                )}
                <SVG
                    src={
                        isSearch
                            ? '/media/members/reset-search.svg'
                            : '/media/members/search-icon.svg'
                    }
                    alt=""
                    className="icon-search"
                    onClick={() => onClickSearch()}
                />
                {tab === 1 ? (
                    <Dropdown
                        overlay={() => (
                            <util.sortBtn
                                contentStatus={contentStatus}
                                setFilter={(status, reject) => {
                                    setFilter(status, reject);
                                }}
                            />
                        )}
                        trigger={['click']}
                        placement="bottomLeft"
                    >
                        <button
                            type="button"
                            className="button-border-small -icon"
                        >
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip>
                                        <util.filterStatus
                                            contentStatus={contentStatus}
                                            status={filterStatus}
                                            reject={rejectMessages}
                                        />
                                    </Tooltip>
                                }
                            >
                                <img src="/media/members/sort.svg" alt="" />
                            </OverlayTrigger>
                        </button>
                    </Dropdown>
                ) : null}

                {catalog_details && catalog_details.status !== 3 ? (
                    <>
                        <Button
                            type="button"
                            className="button-green -icon"
                            onClick={() => {
                                downloadExcel();
                            }}
                            loading={loadingButtonExcel}
                        >
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip>{'Export Excel'}</Tooltip>}
                            >
                                <img src="/media/members/excel.svg" alt="" />
                            </OverlayTrigger>
                        </Button>

                        <button
                            type="button"
                            className="button -icon"
                            onClick={() => {
                                setConfirmModalSent(true);
                            }}
                        >
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip>{sent}</Tooltip>}
                            >
                                <img
                                    src="/media/members/email_send.svg"
                                    alt=""
                                />
                            </OverlayTrigger>
                        </button>

                        <button
                            type="button"
                            className="button-dark -icon"
                            onClick={() => {
                                setModalApprove(true);
                            }}
                        >
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip>
                                        เพิ่มรายการอีบุ๊กให้องค์กร
                                    </Tooltip>
                                }
                            >
                                <img src="/media/members/add.svg" alt="" />
                            </OverlayTrigger>
                        </button>
                    </>
                ) : null}
            </>
        );
    };

    const handleTab = tab => {
        let chk = parseInt(tab);
        if (chk === 1) {
            if (contents.length === 0) getdata();
        } else if (chk === 2) {
            // if (subcategories.length === 0) getcategories();
        } else if (chk === 3) {
            if (publishers.length === 0) getdatapublishers();
        }
    };

    const compareStatus = (a, b) => {
        if (a.status < b.status) {
            return -1;
        }
        if (a.status > b.status) {
            return 1;
        }
        return 0;
    };

    useEffect(() => {
        if (filterContents && filterContents.length > 0) {
            document
                .getElementById('custom-table-scroll')
                ?.getElementsByTagName('tbody')[0]
                ?.addEventListener('scroll', e => {
                    // const scroll = e.path[0];
                    // e.path[0].scrollTop + e.path[0].offsetHeight ===
                    //   e.path[0].scrollHeight &&
                    // !progressLast
                    console.log(e.target.offsetHeight);
                    const offsetHeight = Math.ceil(e.target.offsetHeight);
                    const scrollTop = Math.ceil(e.target.scrollTop);
                    console.log(offsetHeight, scrollTop, e.path);
                    if (
                        e.target.scrollHeight - (offsetHeight + scrollTop) <=
                            1 &&
                        !progressLast
                    ) {
                        setProgressLast(true);
                        // setFilterContents(contents);
                    }
                });
        }
    }, [filterContents]);

    useEffect(() => {
        if (progressLast) {
            getNextData().then(() => {
                console.log('end page');
                setProgressLast(false);
            });
        }
    }, [progressLast]);

    useEffect(() => {
        getdatacatalog_details();
        getContentStatus();

        if (parsed.id) {
            getcategories();
        }
    }, []);

    useEffect(() => {
        if (contentStatus !== undefined) {
            handleTab(1);
        }
    }, [contentStatus]);
    //
    useEffect(() => {
        console.log(filterStatus);
        if (Array.isArray(contents)) {
            setFilterContents(
                Object.values(contents)
                    .filter(el =>
                        filterStatus === '' && filterReject === ''
                            ? el
                            : filterStatus === 1
                            ? filterReject === 0
                                ? el.status === filterStatus &&
                                  (el.rejectMessages === undefined ||
                                      el.rejectMessages === 0)
                                : el.status === filterStatus &&
                                  el.rejectMessages === filterReject
                            : el.status === filterStatus
                    )
                    // .filter(el =>
                    //    el.title.toLowerCase().includes(searchAll.toLowerCase())
                    // )
                    .sort(compareStatus)
            );
        }
    }, [
        // searchAll,
        contents,
        sortBy,
        filterStatus,
        filterReject
    ]);

    useEffect(() => {
        if (Array.isArray(subcategories)) {
            setFilterCatalogs(
                Object.values(subcategories).filter(el =>
                    el.title
                        .toLowerCase()
                        .includes(searchCategory.toLowerCase())
                )
            );
        }
    }, [subcategories]);

    useEffect(() => {
        if (Array.isArray(publishers)) {
            setFilterPublishers(
                Object.values(publishers).filter(el =>
                    el.publisherName
                        .toLowerCase()
                        .includes(searchPublisher.toLowerCase())
                )
            );
        }
    }, [publishers]);

    const onSearchPublisher = () => {
        setLoading(true);
        setIsSearch(true);
        if (Array.isArray(publishers)) {
            setFilterPublishers(
                Object.values(publishers).filter(el =>
                    el.publisherName
                        .toLowerCase()
                        .includes(searchPublisher.toLowerCase())
                )
            );
            setLoading(false);
        }
    };

    const onSearchCategory = () => {
        setLoading(true);
        setLoadingcat(true);
        setIsSearch(true);
        if (Array.isArray(subcategories)) {
            setFilterCatalogs(
                Object.values(subcategories).filter(el =>
                    el.title
                        .toLowerCase()
                        .includes(searchCategory.toLowerCase())
                )
            );
            setLoading(false);
            setLoadingcat(false);
        }
    };

    const onStartDateChange = date => {
        if (date) {
            setStartDate(date.unix());
        }
    };

    const onPayDeadLineDateChange = date => {
        if (date) {
            setPayDeadLineDate(date.unix());
        }
    };

    const onLicneseDate = date => {
        if (date) {
            setLicenseDate(date.unix());
        }
    };

    return (
        <div id="CheckEbookDetailPage" className="checkebook">
            <Card className="cardStyle">
                <CardHeader title={<TitleBar />}>
                    <div className="tool-bar">
                        <CardHeaderToolbar>
                            {<ToolBar tab={tab} />}
                        </CardHeaderToolbar>
                    </div>
                    <div className="tool-barsmall ">
                        <CardHeaderToolbar>
                            {<ToolBarSmall tab={tab} />}
                        </CardHeaderToolbar>
                    </div>
                </CardHeader>
                <CardBody className="cardBodyStyle">
                    {progressLast ? (
                        <>
                            <LoaderAbsolute />
                        </>
                    ) : null}
                    <Tabs
                        // defaultActiveKey="1"
                        activeKey={tab}
                        className="tab-custom"
                        tabBarExtraContent={<Summaryebook />}
                        onChange={e => {
                            setTab(e);
                            handleTab(e);
                        }}
                    >
                        <TabPane tab=" ทั้งหมด" key="1">
                            {loading ? (
                                <LoaderView></LoaderView>
                            ) : filterContents &&
                              filterContents.length === 0 ? (
                                <NodataView></NodataView>
                            ) : (
                                <div className="CheckEbookDetailTable">
                                    <BootstrapTable
                                        ref={scrollRef}
                                        id="custom-table-scroll"
                                        wrapperClasses="table-responsive"
                                        classes="table table-head-custom table-vertical-center custom-table-scroll"
                                        bordered={false}
                                        bootstrap4
                                        remote
                                        keyField="contentUID"
                                        data={filterContents}
                                        columns={columns}
                                        onL
                                    ></BootstrapTable>
                                </div>
                            )}
                        </TabPane>
                        <TabPane tab="หมวดหมู่" key="2">
                            {loadingcat ? (
                                <LoaderView></LoaderView>
                            ) : filteredcatalogs &&
                              filteredcatalogs.length === 0 ? (
                                <NodataView></NodataView>
                            ) : (
                                <div id="CheckEbookDetailCategoriesTable">
                                    <Table
                                        id="custom-table-scroll"
                                        wrapperClasses="table-responsive"
                                        className="components-table-checkebook table table-head-custom" // table-vertical-center"
                                        expandedRowRender={record =>
                                            expandedRowRender(record)
                                        }
                                        size="small"
                                        columns={columns2}
                                        dataSource={filteredcatalogs}
                                        expandIconColumnIndex={5}
                                        pagination={false}
                                        expandable={{
                                            expandIcon: ({
                                                expanded,
                                                onExpand,
                                                record
                                            }) =>
                                                expanded ? (
                                                    <DownOutlined
                                                        onClick={e =>
                                                            onExpand(record)
                                                        }
                                                    />
                                                ) : (
                                                    <RightOutlined
                                                        onClick={e =>
                                                            onExpand(record)
                                                        }
                                                    />
                                                )
                                        }}
                                    />
                                </div>
                            )}
                        </TabPane>
                        <TabPane
                            tab={`สำนักพิมพ์/นักเขียน ${
                                FilterPublishers?.length
                                    ? `(${FilterPublishers?.length})`
                                    : ''
                            }`}
                            key="3"
                        >
                            {loadingPublishers ? (
                                <LoaderView></LoaderView>
                            ) : FilterPublishers &&
                              FilterPublishers.length === 0 ? (
                                <NodataView></NodataView>
                            ) : (
                                <div className="CheckEbookDetailPublisherTable">
                                    <BootstrapTable
                                        wrapperClasses="table-responsive"
                                        bordered={false}
                                        classes="table table-head-custom table-vertical-center custom-table-scroll"
                                        id="custom-table-scroll"
                                        bootstrap4
                                        remote
                                        keyField="id"
                                        data={FilterPublishers}
                                        columns={columnspublishers}
                                    ></BootstrapTable>
                                </div>
                            )}
                        </TabPane>
                    </Tabs>
                </CardBody>
            </Card>
            <Modals
                title="ยืนยันการเปลี่ยนแปลง ?"
                show={confirm}
                onHide={() => setConfirm(false)}
                onClickAccept={e => postStatus(e)}
                onClickDeny={() => setConfirm(false)}
            />
            <ModalsSuccess
                show={successModal}
                onHide={() => setSuccessModal(false)}
                onClickAccept={() => setSuccessModal(false)}
            />
            <Modals
                title="ยืนยันการดาวน์โหลดไฟล์ ?"
                show={confirmExcel}
                onHide={() => setConfirmModalExcel(false)}
                onClickAccept={e => {
                    setConfirmModalExcel(false);
                    downloadExcel();
                }}
                onClickDeny={() => setConfirmModalExcel(false)}
            />
            <Modals
                title="ยืนยันการส่งข้อมูล ?"
                show={confirmSent}
                onHide={() => setConfirmModalSent(false)}
                loading={loadingModalSent}
                onClickAccept={e => {
                    // setConfirmModalSent(false);
                    // setLoading(true);
                    sentData();
                }}
                onClickDeny={() => setConfirmModalSent(false)}
            />
            <Modals
                title="ยืนยันการเพิ่มรายการอีบุ๊กให้องค์กร ?"
                show={confirmApprove}
                onHide={() => {
                    setConfirmModalApprove(false);
                }}
                loading={loadingModalApprove}
                onClickAccept={e => {
                    sentApproveCatalog();
                }}
                onClickDeny={() => {
                    setConfirmModalApprove(false);
                }}
            />
            <ModalsWithBody
                show={modalApprove}
                title={'เลือกวันที่'}
                onClickAccept={() => {
                    setModalApprove(false);
                    setConfirmModalApprove(true);
                }}
                onClickDeny={() => setModalApprove(false)}
                el={
                    <>
                        <div className="mt">
                            <div className="text">วันที่เริ่มใช้งาน</div>
                            <DatePicker
                                format={'DD-MM-YYYY HH:mm:ss'}
                                placeholder="วันที่เริ่มใช้งาน"
                                className="slecter_date_input"
                                onChange={onStartDateApproveChange}
                                allowClear={false}
                                format={'DD/MM/YYYY'}
                                value={
                                    startDateApprove
                                        ? moment.unix(startDateApprove)
                                        : moment()
                                }
                            />
                        </div>
                        <div className="mt">
                            <div className="text">วันที่สิ้นสุด</div>
                            <DatePicker
                                placeholder="วันที่สิ้นสุด"
                                className="slecter_date_input"
                                onChange={onEndDateApproveChange}
                                allowClear={false}
                                format={'DD/MM/YYYY'}
                                value={
                                    endDateApprove
                                        ? moment.unix(endDateApprove)
                                        : moment()
                                }
                            />
                        </div>
                    </>
                }
            />

            {/* //? quotation modal */}
            <QuoteDatePickerModal
                visible={quotationModal}
                onClose={() => setQuotationModal(false)}
                onConfirm={() => setConfirmModal(true)}
                onIsQuotation={() => setIsQuotation(false)}
                startDate={startDate}
                payDate={payDeadLine}
                onChangeStartDate={onStartDateChange}
                onChangePayDate={onPayDeadLineDateChange}
            />

            {/*  //? check license modal  */}
            {checkLicenseModal && (
                <CheckLicenseModal
                    visible={checkLicenseModal}
                    onClose={() => setCheckLicenseModal(false)}
                    onConfirm={() => {
                        setCheckLicenseModal(false);
                        setConfirmModal(true);
                    }}
                    licenseDate={licenseDate}
                    onLicenseDate={onLicneseDate}
                />
            )}

            <QuotationModal
                visible={confirmModal}
                onClose={() => {
                    setConfirmModal(false);
                    setQuotationModal(false);
                    setCheckLicenseModal(false);
                    setIsQuotation(false);
                }}
                isQuotation={isQuotation}
                catalogUID={parsed.id}
                clientUID={parsed.clientUID}
                startDate={startDate}
                payDate={payDeadLine}
                onOpenSuccess={() => setSuccessModal(true)}
            />
        </div>
    );
}
