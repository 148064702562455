/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

export function CardOnTop(props) {
    const [title, setTitle] = useState('');
    const [data, setData] = useState(props.data);

    useEffect(() => {
        if (props.data) {
            setData(props.data);
            if (props.type === 'member') {
                setTitle('จำนวนสมาชิก');
            } else if (props.type === 'ebook') {
                setTitle('สิทธิ์การยืมอีบุ๊ก');
            } else if (props.type === 'borrowing') {
                setTitle('จำนวนการยืม');
            } else if (props.type === 'sms-otp') {
                setData(prev =>
                    Object.assign(prev, {
                        percentage:
                            100 - (((data.remainSMS ?? data.totalSMS) / data.totalSMS) * 100)
                    })
                );
                setTitle('จำนวนข้อความ OTP');
            }
        }
    }, [props.type, props.data]);

    const BorderLinearProgress = withStyles({
        root: {
            height: 10,
            backgroundColor: lighten('#ff6c5c', 0.5)
        },
        bar: {
            borderRadius: 20,
            backgroundColor: '#ff6c5c'
        }
    })(LinearProgress);

    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1
        },
        progress: {
            margin: `${theme.spacing(1)}px 0px`,
            borderRadius: '5px'
        },
        lastMonth: {
            fontFamily: 'Prompt',
            fontSize: '28px',
            fontWeight: '600',
            color: '#828282',
            textAlign: 'right',
            marginTop: '9px'
        },
        subBorrowing: {
            fontFamily: 'Prompt',
            fontSize: '12px',
            fontWeight: '500',
            color: '#b5b5c3',
            marginTop: '-32px'
        }
    }));
    const classes = useStyles();

    // useEffect(() => {
    //   const element = document.getElementById("kt_stats_widget_7_chart");

    //   if (!element) {
    //     return;
    //   }

    //   const options = getChartOption(layoutProps);
    //   const chart = new ApexCharts(element, options);
    //   chart.render();
    //   return function cleanUp() {
    //     chart.destroy();
    //   };
    // }, [layoutProps]);

    return data ? (
        <div
            className={`card card-custom ${props.className}`}
            style={{ height: '180px', margin: '0' }}
        >
            <div className="card-body d-flex flex-column p-0">
                <div className="align-items-center justify-content-between card-spacer flex-grow-1">
                    <p
                        className="title oneLine"
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            lineClamp: 1,
                            display: '-webkit-box',
                            maxWidth: '100%',
                            boxOrient: 'vertical'
                        }}
                    >
                        {title}
                    </p>
                    <div>
                        {props.type === 'borrowing' ? (
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <p className="subTitle">
                                        {data.licenseAmountInTime?.toLocaleString()}{' '}
                                        <span
                                            className={classes.subBorrowing}
                                            style={{
                                                color: '#828282',
                                                textAlign: 'right',
                                                marginTop: '-44px'
                                            }}
                                        >
                                            {data.percentage !== 0 &&
                                            data.percentage !== undefined
                                                ? (data.percentage > 0
                                                      ? '+'
                                                      : '') +
                                                  data.percentage.toFixed(2) +
                                                  '%'
                                                : ''}
                                        </span>
                                    </p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p className={classes.lastMonth}>
                                        {data.licenseAmountInTimeAgo?.toLocaleString()}
                                    </p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p
                                        className={classes.subBorrowing}
                                        style={{ color: '#fd6b53' }}
                                    >
                                        เดือนนี้
                                    </p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p
                                        className={classes.subBorrowing}
                                        style={{
                                            color: '#828282',
                                            textAlign: 'right'
                                        }}
                                    >
                                        เดือนที่แล้ว
                                    </p>
                                </Grid>
                            </Grid>
                        ) : props.type === 'member' ? (
                            <span
                                className="subTitle "
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    lineClamp: 1,
                                    display: '-webkit-box',
                                    maxWidth: '100%',
                                    boxOrient: 'vertical'
                                }}
                            >
                                {data.currentMember?.toLocaleString()}/
                                {data.maximumMember?.toLocaleString()}
                            </span>
                        ) : props.type === 'sms-otp' ? (
                            <span
                                className="subTitle"
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    lineClamp: 1,
                                    display: '-webkit-box',
                                    maxWidth: '100%',
                                    boxOrient: 'vertical'
                                }}
                            >
                                {(
                                    data.totalSMS - (data.remainSMS ?? data.totalSMS)
                                ).toLocaleString()}
                                /{data.totalSMS?.toLocaleString() ?? 0}
                            </span>
                        ) : (
                            <span className="subTitle">
                                {data.activeLicense
                                    ? data.activeLicense?.toLocaleString()
                                    : 0}
                                /{data.concurrent?.toLocaleString()}
                            </span>
                        )}
                    </div>
                    {/* <span className="symbol symbol-light-success symbol-45">
            <span className="symbol-label font-weight-bolder font-size-h6">+57</span>
          </span> */}
                </div>
                <div
                    id="kt_stats_widget_7_chart"
                    className="card-rounded-bottom"
                    style={{
                        height: '150px',
                        padding: '0 2.25rem',
                        marginTop: '-8px'
                    }}
                >
                    {props.type === 'member' ||
                    props.type === 'ebook' ||
                    props.type === 'sms-otp' ? (
                        <>
                            <BorderLinearProgress
                                className={classes.progress}
                                variant="determinate"
                                color="secondary"
                                value={data.percentage ? data.percentage : 0}
                            />
                            <p className="subProgressLeft">Progress</p>
                            <p className="subProgressRight">
                                {data.percentage
                                    ? data.percentage.toFixed(2)
                                    : 0}
                                %
                            </p>
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    ) : (
        <div />
    );
}
