import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { CustomersLoadingDialog } from "./customers-loading-dialog/CustomersLoadingDialog";
import { CustomerEditDialog } from "./customer-edit-dialog/CustomerEditDialog";
import { QrcodeDeleteDialog } from "./qrcode-delete-dialog/QrcodeDeleteDialog";
import { CustomerBlockDialog } from "./customer-block-dialog/CustomerBlockDialog";
import { CustomerUnblockDialog } from "./customer-unblock-dialog/CustomerUnblockDialog";
import { CustomerBannedDialog } from "./customer-banned-dialog/CustomerBannedDialog.js";
import { CustomerResetPwDialog } from "./customer-resetPw-dialog/CustomerResetPwDialog";
import { CustomersDeleteDialog } from "./customers-delete-dialog/CustomersDeleteDialog";
import { CustomersFetchDialog } from "./customers-fetch-dialog/CustomersFetchDialog";
import { CustomersUpdateStateDialog } from "./customers-update-status-dialog/CustomersUpdateStateDialog";
import { CustomerDevicesDialog } from "./customer-devices-dialog/CustomerDevicesDialog";
import { CustomersUIProvider } from "./CustomersUIContext";
import { QrcodesCard } from "./QrcodesCard";
import { QRCodeGeneratorDialog } from "./qrcode-generator-dialog/QRCodeGeneratorDialog";
import { QrcodeViewDialog } from "./qrcode-view-dialog/QrcodeViewDialog";
import { QrcodeEditDialog } from "./qrcode-edit-dialog/QrcodeEditDialog";
import queryString from "query-string";

export function QrcodesPage({ history, location }) {
  const [devices, setDevices] = useState([]);
  const [UID, setUID] = useState("");
  const [email, setEmail] = useState("");
  const [isNew, setNew] = useState(false);
  const [isNewQrcode, setNewQrcode] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isBlock, setBlock] = useState(false);
  const [isUnblock, setUnblock] = useState(false);
  const [isBanned, setBanned] = useState(false);
  const [isResetPw, setResetPw] = useState(false);
  const [isDevices, setIsDevices] = useState(false);
  const [isView, setView] = useState(false);
  const [isViewFull, setViewFull] = useState(false);
  const [id, setId] = useState(null);
  const [staffID, setStaffID] = useState(null);
  const [name, setName] = useState(null);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.isNew) {
      setNew(true);
    }
    if (parsed.isNewQrcode) {
      setNewQrcode(true);
    }
    if (parsed.edit) {
      setEdit(true);
    }
    if (parsed.id) {
      setId(parsed.id);
    }
    if (parsed.staffID) {
      setStaffID(parsed.staffID);
    }
    if (parsed.name) {
      setName(parsed.name);
    }
    if (parsed.email) {
      setEmail(parsed.email);
    }
    if (parsed.delete) {
      setDelete(true);
    }
    if (parsed.block) {
      setBlock(true);
    }
    if (parsed.unblock) {
      setUnblock(true);
    }
    if (parsed.banned) {
      setBanned(true);
    }
    if (parsed.resetpw) {
      setResetPw(true);
    }
    if (parsed.isDevices) {
      setIsDevices(true);
    }
    if (parsed.view) {
      setView(true);
    }
    if (parsed.viewFull) {
      setViewFull(true);
    }
  }, [location.search]);

  const customersUIEvents = {
    newCustomerButtonClick: () => {
      history.push("/qrcodes?isNew=true");
    },
    newQRCodeButtonClick: () => {
      history.push("/qrcodes?isNewQrcode=true");
    },
    openEditCustomerDialog: (id) => {
      history.push(`/qrcodes?id=${id}&edit=true`);
    },
    openDeleteQrcodeDialog: (UID, name) => {
      setUID(UID);
      history.push(`/qrcodes?name=${encodeURIComponent(name)}&delete=true`);
    },
    openBlockCustomerDialog: (id, email) => {
      history.push(`/qrcodes?id=${id}&email=${email}&block=true`);
    },
    openUnblockCustomerDialog: (id, email) => {
      history.push(`/qrcodes?id=${id}&email=${email}&unblock=true`);
    },
    openBannedCustomerDialog: (email) => {
      history.push(`/qrcodes?email=${email}&banned=true`);
    },
    openResetPwCustomerDialog: (id) => {
      history.push(`/qrcodes?id=${id}&resetpw=true`);
    },
    openCustomerDevicesDialog: (devices, UID, staffID, name) => {
      setDevices(devices);
      setUID(UID);
      history.push(`/qrcodes?staffID=${staffID}&name=${encodeURIComponent(name)}&isDevices=true`);
    },
    openViewQrcodeDialog: (id, isFull) => {
      if (isFull) {
        history.push(`/qrcodes?id=${id}&viewFull=true`);
      } else {
        history.push(`/qrcodes?id=${id}&view=true`);
      }
    },
    openEditQrcodeDialog: (id) => {
      history.push(`/qrcodes?id=${id}&edit=true`);
    },
    openViewQrcodeMembersPage: (id) => {
      history.push(`/qrcode-members?id=${id}`);
    },
    openDeleteCustomersDialog: () => {
      history.push(`/qrcodes/deleteCustomers`);
    },
    openFetchCustomersDialog: () => {
      history.push(`/qrcodes/fetch`);
    },
    openUpdateCustomersStatusDialog: () => {
      history.push("/qrcodes/updateStatus");
    },
  };

  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <CustomersLoadingDialog />
      <Switch>
        {isNew ? (
          <CustomerEditDialog
            show={true}
            onHide={() => {
              history.push("/qrcodes");
              setNew(false);
            }}
          />
        ) : (
          ""
        )}
        {isNewQrcode ? (
          <QRCodeGeneratorDialog
            show={true}
            onHide={() => {
              history.push("/qrcodes");
              setNewQrcode(false);
            }}
          />
        ) : (
          ""
        )}
        {isDelete ? (
          <QrcodeDeleteDialog
            show={true}
            id={name}
            UID={UID}
            onHide={() => {
              history.push("/qrcodes");
              setDelete(false);
            }}
          />
        ) : (
          ""
        )}
        {isBlock ? (
          <CustomerBlockDialog
            show={true}
            id={id}
            email={email}
            onHide={() => {
              history.push("/qrcodes");
              setBlock(false);
            }}
          />
        ) : (
          ""
        )}
        {isUnblock ? (
          <CustomerUnblockDialog
            show={true}
            id={id}
            email={email}
            onHide={() => {
              history.push("/qrcodes");
              setUnblock(false);
            }}
          />
        ) : (
          ""
        )}
        {isBanned ? (
          <CustomerBannedDialog
            show={true}
            email={email}
            onHide={() => {
              history.push("/qrcodes");
              setBanned(false);
            }}
          />
        ) : (
          ""
        )}
        {isResetPw ? (
          <CustomerResetPwDialog
            show={true}
            id={id}
            onHide={() => {
              history.push("/qrcodes");
              setResetPw(false);
            }}
          />
        ) : (
          ""
        )}
        {isDevices ? (
          <CustomerDevicesDialog
            show={true}
            UID={UID}
            id={staffID}
            name={decodeURIComponent(name)}
            devices={devices}
            onHide={() => {
              history.push("/qrcodes");
              setIsDevices(false);
            }}
          />
        ) : (
          ""
        )}
        {isViewFull ? (
          <QrcodeViewDialog
            show={true}
            id={id}
            onHide={() => {
              history.push("/qrcodes");
              setViewFull(false);
            }}
            isFull={true}
          />
        ) : (
          ""
        )}
        {isView ? (
          <QrcodeViewDialog
            show={true}
            id={id}
            onHide={() => {
              history.push("/qrcodes");
              setView(false);
            }}
          />
        ) : (
          ""
        )}
        {isEdit ? (
          <QrcodeEditDialog
            isEditing={true}
            show={true}
            id={id}
            onHide={() => {
              history.push("/qrcodes");
              setEdit(false);
            }}
          />
        ) : (
          ""
        )}
        <Route path="/qrcodes/deleteCustomers">
          {({ history, match }) => (
            <CustomersDeleteDialog
              show={match != null}
              onHide={() => {
                history.push("/qrcodes");
              }}
            />
          )}
        </Route>
        <Route path="/qrcodes/fetch">
          {({ history, match }) => (
            <CustomersFetchDialog
              show={match != null}
              onHide={() => {
                history.push("/qrcodes");
              }}
            />
          )}
        </Route>
        <Route path="/qrcodes/updateStatus">
          {({ history, match }) => (
            <CustomersUpdateStateDialog
              show={match != null}
              onHide={() => {
                history.push("/qrcodes");
              }}
            />
          )}
        </Route>
      </Switch>
      <QrcodesCard />
    </CustomersUIProvider>
  );
}
