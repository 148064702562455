// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../../../_metronic/_partials/controls";
import { makeStyles } from "@material-ui/core";
import Spinner from "../../../../../../_metronic/_helpers/spinner";

const useStyles = makeStyles(() => ({
  head: {
    fontSize: "20px",
    fontWeight: "600",
    margin: "16px auto 0",
    color: "#012875",
  },
  input: { margin: "12px 0" },
  denyButton: {
    margin: "8px",
    width: "150px",
    height: "48px",
    borderRadius: "100px",
    border: "solid 1px #012875",
    backgroundColor: "#ffffff",
    fontWeight: "600",
    color: "#012875",
    fontSize: "15px",
  },
  acceptButton: {
    margin: "8px",
    width: "150px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "#012875",
    border: "none",
    color: "white",
    fontWeight: "600",
    fontSize: "16px",
    "&:disabled": {
      backgroundColor: "#e0e0e0",
      color: "#828282",
      "&:hover": {
        cursor: "not-allowed",
      },
    },
  },
  divButton: { marginTop: "64px", textAlign: "center" },
}));

// Validation schema
const PublisherNewSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "อย่างน้อย 2 ตัวอักษร")
    .max(200, "ไม่เกิน 200 ตัวอักษร")
    .matches(/^[^#%&?/]*$/g,'ไม่สามารถกรอก #, &, %, ? หรือ /')
    .required("กรุณากรอกชื่อ"),
});

export function PublisherNewForm({
  createPublisher,
  createAuthor,
  publisher,
  actionsLoading,
  onHide,
  isAuthor,
  publisherList,
  isDuplicate,
  setDuplicate,
}) {
  const styles = useStyles();
  const [publisherIn, setPublisherIn] = useState("");
  const [publisherInList, setPublisherInList] = useState([<option key="none" value=""></option>]);
  const [publisherName, setPublisherName] = useState("");
  const [checkingError, setCheckingError] = useState(false);

  useEffect(() => {
    setCheckingError(false);
  }, [publisherIn]);

  useEffect(() => {
    if (publisherList) {
      setPublisherInList([
        <option value={""} disabled selected>
          เลือกสำนักพิมพ์
        </option>,
        ...publisherList.map((x, index) => {
          // if (index === 0) {
          //   setPublisherIn(x.uid);
          //   setPublisherName(x.name);
          // }
          return (
            <option key={index} value={x.uid}>
              {x.name}
            </option>
          );
        }),
      ]);
    }
  }, [publisherList]);

  const handlePublisherInChange = (e) => {
    setPublisherIn(e.target.value);
    var index = e.nativeEvent.target.selectedIndex;
    setPublisherName(e.nativeEvent.target[index].text);
  };
  return (
    <>
      <p className={styles.head}>{isAuthor ? "เพิ่มนักเขียน" : "เพิ่มสำนักพิมพ์"}</p>
      <Formik
        enableReinitialize={true}
        initialValues={{ name: publisher.name, status: publisher.status }}
        validationSchema={PublisherNewSchema}
        onSubmit={(values) => {
          setDuplicate(false);
          if (isAuthor) {
            createAuthor(values, publisherIn, publisherName);
          } else {
            createPublisher(values);
          }
        }}
      >
        {({ handleSubmit, values, setFieldValue, errors }) => {
          return (
            <>
              <Modal.Body>
                {actionsLoading && <Spinner forNewCat={true} isSubCat={isAuthor} />}
                <Form className="form form-label-right">
                  {isAuthor ? (
                    <div className={`col-lg-12 ${styles.input}`}>
                      <Select
                        name="publisher"
                        withFeedbackLabel={false}
                        label="เลือกสำนักพิมพ์"
                        onChange={handlePublisherInChange}
                        value={publisherIn}
                      >
                        {publisherInList}
                      </Select>
                      {publisherIn === "" && checkingError && (
                        <>
                          <div className="is-invalid"></div>
                          <div className="invalid-feedback">กรุณาเลือกสำนักพิมพ์</div>
                        </>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={`col-lg-12 ${styles.input}`}>
                    <Field
                      name="name"
                      component={Input}
                      placeholder={isAuthor ? "กรอกชื่อนักเขียน" : "กรอกชื่อสำนักพิมพ์"}
                      label={isAuthor ? "ชื่อนักเขียน" : "ชื่อสำนักพิมพ์"}
                    />
                    {isDuplicate && checkingError && (
                      <>
                        <div className="is-invalid"></div>
                        <div className="invalid-feedback">
                          ไม่สามารถเพิ่ม{isAuthor ? "นักเขียน" : "สำนักพิมพ์"}ได้ เนื่องจากชื่อซ้ำ
                        </div>
                      </>
                    )}
                  </div>
                </Form>
                <div className={styles.divButton}>
                  <button className={styles.denyButton} type="button" onClick={onHide}>
                    ยกเลิก
                  </button>
                  <button
                    className={styles.acceptButton}
                    type="submit"
                    onClick={() => {
                      setCheckingError(true);
                      handleSubmit();
                    }}
                    disabled={
                      actionsLoading || !values["name"] || (isAuthor && publisherIn === "")
                        ? true
                        : false
                    }
                  >
                    ตกลง
                  </button>
                </div>
              </Modal.Body>
            </>
          );
        }}
      </Formik>
    </>
  );
}
