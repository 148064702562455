import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { CatalogueEditDialog } from "./catalogue-edit-dialog/CatalogueEditDialog";
import { CatalogueViewDialog } from "./catalogue-view-dialog/CatalogueViewDialog";
import { CatalogueDeleteDialog } from "./catalogue-delete-dialog/CatalogueDeleteDialog";
import { CatalogueBlockDialog } from "./catalogue-block-dialog/CatalogueBlockDialog";
import { CatalogueUnblockDialog } from "./catalogue-unblock-dialog/CatalogueUnblockDialog";
import { CatalogueUIProvider } from "./CatalogueUIContext";
import { CatalogueCard } from "./CatalogueCard";
import { CatalogueDetailCard } from "./CatalogueDetailCard";
import queryString from "query-string";
import { EbookViewDialog } from "../ebooks/ebook-view-dialog/EbookViewDialog";
import { enableRequireDomain } from "../../../RequestServer/setting";
// import "../../../../../_metronic/_assets/sass/components/checkebook.scss";
export function CatalogueDetailPage({ history, location }) { 
  const parsed_ = queryString.parse(location.search);
  // console.log(parsed_);
  const [id, setId] = useState(null);
  const [ebookId, setEbookId] = useState(null);
  const [name, setName] = useState(null);
  const [isDelete, setDelete] = useState(false);
  const [isBlock, setBlock] = useState(false);
  const [isUnblock, setUnblock] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isView, setView] = useState(false);
  const [viewEbookModal, setViewEbookModal] = useState(false);

  useEffect(() => {
    const parsed = queryString.parse(location.search);

    if (parsed.id) {
      setId(parsed.id);
    }
    if (parsed.name) {
      setName(parsed.name);
    }
    if (parsed.view) {
      setView(true);
    }
    if (parsed.viewEbook) {
      setViewEbookModal(true);
    }
    if (parsed.ebookId) {
      setEbookId(parsed.ebookId);
    }
    if (parsed.edit) {
      setEdit(true);
    }
    if (parsed.delete) {
      setDelete(true);
    }
    if (parsed.block) {
      setBlock(true);
    }
    if (parsed.unblock) {
      setUnblock(true);
    }
  }, [location.search]);

  const catalogueUIEvents = {
    newCatalogueButtonClick: () => {
      history.push(`/catalogue-detail?id=${parsed_.id}&newEbook=true`);
    },
    viewPublishersButtonClick: () => {
      history.push(
        `/catalogue-detail?id=${parsed_.id}&newEbook=true&publishers=true`
      );
    },
    viewNewEbooksButtonClick: () => {
      history.push(
        `/catalogue-detail?id=${parsed_.id}&newEbook=true&newEbooks=true`
      );
    },
    viewEbooksButtonClick: (uid, type, subcatId) => {
      if (type === "publisher") {
        history.push(
          `/catalogue-detail?id=${parsed_.id}&newEbook=true&ebooks=true&publisherId=${uid}`
        );
      } else if (type === "subcategory") {
        history.push(
          `/catalogue-detail?id=${parsed_.id}&newEbook=true&ebooks=true&catId=${uid}&subcatId=${subcatId}`
        );
      }
    },
    viewCategoriesButtonClick: (catId, isSubcats) => {
      if (isSubcats) {
        history.push(
          `/catalogue-detail?id=${parsed_.id}&newEbook=true&catId=${catId}&subcategories=true`
        );
      } else {
        history.push(
          `/catalogue-detail?id=${parsed_.id}&newEbook=true&categories=true`
        );
      }
    },
    openEditCatalogueDialog: (id) => {
      history.push(`/catalogue-detail?id=${id}&edit=true`);
    },
    //http://localhost:3000/catalogue-detail?id=2963a8e0-dda0-40c6-9c19-328d131f009c&search=true&newEbook=true
    //http://localhost:3000/catalogue-detail?id=2963a8e0-dda0-40c6-9c19-328d131f009c&ebookId=0bc06908-05c0-4fc9-95a8-175db365fcc4&viewEbook=true&search=true&newEbooks=true
    openViewCatalogueEbookDialog: (ebookId) => {
      console.log(parsed_);
      if (parsed_.newEbook && parsed_.ebooks) {
        if (parsed_.publisherId) {
          history.push(
            `/catalogue-detail?id=${id}&newEbook=true&ebooks=true&publisherId=${parsed_.publisherId}&ebookId=${ebookId}&viewEbook=true`
          );
        } else if (parsed_.catId && parsed_.subcatId) {
          history.push(
            `/catalogue-detail?id=${id}&newEbook=true&ebooks=true&catId=${parsed_.catId}&subcatId=${parsed_.subcatId}&ebookId=${ebookId}&viewEbook=true`
          );
        }
      } else {
        console.log(parsed_);
        if(parsed_.search){
          history.push(
            `/catalogue-detail?id=${id}&ebookId=${ebookId}&viewEbook=true&search=true&newEbook=true`
          );
        }else{
          if (parsed_.newEbooks) {
            history.push(
              `/catalogue-detail?id=${id}&ebookId=${ebookId}&newEbook=true&newEbooks=true&viewEbook=true`
            );
          } else {
            history.push(
              `/catalogue-detail?id=${id}&ebookId=${ebookId}&viewEbook=true`
            );
          
           
          }
        }
    
      }
    },
    openDeleteCatalogueDialog: (name, id) => {
      history.push(
        `/catalogue-detail?name=${encodeURIComponent(
          name
        )}&id=${id}&delete=true`
      );
    },
    openBlockCatalogueDialog: (name, id) => {
      history.push(
        `/catalogue-detail?name=${encodeURIComponent(name)}&id=${id}&block=true`
      );
    },
    openUnblockCatalogueDialog: (name, id) => {
      history.push(
        `/catalogue-detail?name=${encodeURIComponent(
          name
        )}&id=${id}&unblock=true`
      );
    },
    openFetchCatalogueDialog: () => {
      history.push(`/catalogue-detail/fetch`);
    },
    openUpdateCatalogueStatusDialog: () => {
      history.push("/catalogue-detail/updateStatus");
    },
  };

  return (
    <CatalogueUIProvider catalogueUIEvents={catalogueUIEvents}>
      {/* {parsed.newEbook ? (
        <CatalogueEditDialog
          show={true}
          onHide={() => {
            history.push(`/catalogue-detail?id=${parsed.id}`);
          }}
        />
      ) : (
        ""
      )} */}
      {isEdit ? (
        <CatalogueEditDialog
          show={true}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push(`/catalogue-detail?id=${parsed_.id}`);
            setEdit(false);
          }}
        />
      ) : (
        ""
      )}
      {isView ? (
        <CatalogueViewDialog
          show={true}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push(`/catalogue-detail?id=${parsed_.id}`);
            setView(false);
          }}
        />
      ) : (
        ""
      )}
      {isDelete ? (
        <CatalogueDeleteDialog
          show={true}
          name={decodeURIComponent(name)}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push(`/catalogue-detail?id=${parsed_.id}`);
            setDelete(false);
          }}
        />
      ) : (
        ""
      )}
      {isUnblock ? (
        <CatalogueUnblockDialog
          show={true}
          name={decodeURIComponent(name)}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push(`/catalogue-detail?id=${parsed_.id}`);
            setUnblock(false);
          }}
        />
      ) : (
        ""
      )}
      {isBlock ? (
        <CatalogueBlockDialog
          show={true}
          name={decodeURIComponent(name)}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push(`/catalogue-detail?id=${parsed_.id}`);
          }}
        />
      ) : (
        ""
      )}
      {viewEbookModal ? (
        <EbookViewDialog
          show={true}
          id={ebookId}
          isPrivate={0}
          isFree={0}
          onHide={() => {
            if (parsed_.newEbook && parsed_.ebooks) {
              if (parsed_.publisherId) {
                history.push(
                  `/catalogue-detail?id=${parsed_.id}&newEbook=true&ebooks=true&publisherId=${parsed_.publisherId}`
                );
              } else if (parsed_.catId && parsed_.subcatId) {
                history.push(
                  `/catalogue-detail?id=${parsed_.id}&newEbook=true&ebooks=true&catId=${parsed_.catId}&subcatId=${parsed_.subcatId}`
                );
              }
            } else {
              if(parsed_.search){
                history.push(
                  `/catalogue-detail?id=${id}&search=true&newEbook=true`
                );
              }else{
                if (parsed_.newEbooks) {
                  history.push(
                    `/catalogue-detail?id=${parsed_.id}&newEbook=true&newEbooks=true`
                  );
                } else {
                  history.push(`/catalogue-detail?id=${parsed_.id}`);
                }
              }
              
            }
            setViewEbookModal(false);
            setEbookId(null);
          }}
          catalogueId={id}
        />
      ) : (
        ""
      )}
      <CatalogueCard
        isPrivate={1}
        isFree={0}
        isNewCatalogue={0}
        isCatalogueDetail={!parsed_.newEbook}
        id={parsed_.id}
        publisherId={parsed_.publisherId}
        catId={parsed_.catId}
        subcatId={parsed_.subcatId}
        isNewEbook={parsed_.newEbook}
        isPublishers={parsed_.publishers}
        isNewEbooks={parsed_.newEbooks}
        isEbooks={parsed_.ebooks}
        isCategories={parsed_.categories}
        isSubcategories={parsed_.subcategories}
        onHide={() => {
          history.push("/summarycatalogs");
          setView(false);
        }}
      />
    </CatalogueUIProvider>
  );
}
