import * as requestFromServer from "./podcastsCrud";
import { podcastsSlice, callTypes } from "./podcastsSlice";
import {
  customersUIPropsQueryParams,
  LastEvaluatedKey_Content,
  LastEvaluatedKey_FreeContent,
  UpdateContentPayload,
  HtiResponse,
  ContentData,
  FreeContentData,
  PodcastByIdData,
  CreatePodcastPayload,
  CreatePodPayload,
  contentForEdit,
  deletePayload
} from "../../../../../interface";
const { actions } = podcastsSlice;
export const fetchPodcasts = (
  queryParams: customersUIPropsQueryParams,
  isPrivate: string,
  isFree: string,
  lastEvaluate: LastEvaluatedKey_Content | LastEvaluatedKey_FreeContent,
  setLoadingBottom?: (loading: boolean) => void,
  setSearching?: (searching: boolean) => void
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPodcasts(
      queryParams,
      parseInt(isPrivate, 10),
      lastEvaluate,
      parseInt(isFree, 10)
    )
    .then((response) => {
      const {
        data,
      }: HtiResponse<ContentData | FreeContentData> = response.data;

      const entities = data?.contents;
      dispatch(
        actions.podcastsFetched({
          totalCount: data?.totalAmount,
          entities,
          LastEvaluatedKey: data?.LastEvaluatedKey,
          lastEvaluate,
          isPrivate,
          isFree,
          memberMode: queryParams.memberMode,
        })
      );
      if (setSearching) {
        setSearching(false);
      }
      if (setLoadingBottom) {
        setLoadingBottom(false);
      }
    })
    .catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorCode === "012"
      ) {
        alert("กรุณาเข้าสู่ระบบอีกครั้ง เนื่องจากระยะเวลาการใช้งานหมดอายุ");
        window.location.href = "/logout";
      }
      dispatch(
        actions.podcastsFetched({
          totalCount: 0,
          entities: [],
          LastEvaluatedKey: {},
          isPrivate,
          isFree,
        })
      );
      if (setSearching) {
        setSearching(false);
      }
      error.clientMessage = "Can't find podcasts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchPodcast = (id: string) => (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  if (!id) {
    return dispatch(actions.podcastFetched({ podcastForEdit: undefined }));
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getPodcastById(id)
    .then((response) => {
      const podcast = response.data;
      dispatch(actions.podcastFetched({ podcastForEdit: podcast }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find podcast";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchPodcastEpisodes = (
  id: string,
  setSearching?: (searching: boolean) => void,
  setLoadingBottom?: (loading: boolean) => void,
  setPodcastDetail?: (podcast: PodcastByIdData | number) => void
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getPodcastEpisodes(id)
    .then((response) => {
      if (response.data.data && response.data.status) {
        const podcast = response.data.data;
        dispatch(actions.podcastFetched({ podcastForEdit: podcast }));
        if (setPodcastDetail) setPodcastDetail(podcast);
        dispatch(
          actions.podcastEpisodesFetched({
            podcastEpisodes: podcast.episodes,
          })
        );
        dispatch(
          actions.podcastNameFetched({
            podcastName: podcast.title,
          })
        );
        if (setSearching) setSearching(false);
        if (setLoadingBottom) setLoadingBottom(false);
      }
    })
    .catch((error) => {
      if (setPodcastDetail) setPodcastDetail(0);
    });
};

export const deletePodcast = (id: string, isPrivate: boolean,forceReturn :deletePayload) => (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deletePodcast(id, isPrivate,forceReturn)
    .then((response) => {
      if (response.data.status) {
        dispatch(actions.podcastDeleted({ id }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't delete podcast";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const blockPodcast = (id: string, isPrivate: number, isFree: number) => (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .blockPodcast(id, isPrivate, isFree)
    .then((response) => {
      if (response.data.status) {
        dispatch(actions.podcastBlocked({ id }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't block podcast";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const unblockPodcast = (
  id: string,
  isPrivate: number,
  isFree: number
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .unblockPodcast(id, isPrivate, isFree)
    .then((response) => {
      if (response.data.status) {
        dispatch(actions.podcastUnblocked({ id }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't unblock podcast";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createPod = (
  newValues: CreatePodPayload,
  setSuccessModal?: (isSuccess: boolean) => void
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.createPod(newValues).then((res) => {
    if (res.data.status) {
      if (setSuccessModal) {
        dispatch(actions.elementUpdated());
        setSuccessModal(true);
      }
    }
  });
};

export const createPodcast = (
  newValues: CreatePodcastPayload,
  creatorUID: string,
  setSuccessModal?: (isSuccess: boolean) => void
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.createPodcast(newValues, creatorUID).then((res) => {
    if (res.data.status) {
      if (setSuccessModal) {
        dispatch(actions.elementUpdated());
        setSuccessModal(true);
      }
    }
  });
};

export const updatePodcast = (
  newValues: UpdateContentPayload,
  contentUID: string,
  isNew: boolean,
  setSuccessModal?: (isSuccess: boolean) => void,
  originValues?: contentForEdit
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updatePodcast(newValues, contentUID, isNew)
    .then((res) => {
      if (res.data.status && res.data.data) {
        dispatch(
          actions.podcastUpdated({
            id: contentUID,
            podcast: Object.assign(
              {},
              originValues,
              newValues,
              res.data.data,
              {
                channelName:
                  res.data.data.publisher || res.data.data.channelName,
              },
              {
                concurrent:
                  newValues.concurrent === 0
                    ? "unlimited"
                    : newValues.concurrent,
              }
            ),
          })
        );
        if (setSuccessModal) {
          setSuccessModal(true);
        }
      }
    });
};

// export const updatePodcastsStatus = (ids, status) => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateStatusForPodcasts(ids, status)
//     .then(() => {
//       dispatch(actions.podcastsStatusUpdated({ ids, status }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't update podcasts status";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const deletePodcasts = (ids) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deletePodcasts(ids)
//     .then(() => {
//       dispatch(actions.podcastsDeleted({ ids }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't delete podcasts";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };
