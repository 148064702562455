import axios from 'axios';
import {
   CreateContentPayload,
   customersUIPropsQueryParams,
   LastEvaluatedKey_Content,
   LastEvaluatedKey_FreeContent,
   UpdateContentPayload,
   HtiResponse,
   ContentData,
   ContentById,
   FreeContentData,
   deletePayload,
   HtiContentJobResponse,
   getUploadedFileContentToStore
} from '../../../../../interface';

// export const EBOOKS_URL = "api/ebooks";
export const EBOOKS_URL = `/admin/contents`;
export const PRIVATE_EBOOKS_URL = `/admin/private/contents`;
export const FREE_EBOOKS_URL = '/admin/free/contents';
export const SUPER_USER_URL = `/superuser/hibrary/contents`;
// CREATE =>  POST: add a new ebook to the server
export function createEbook(newValues: CreateContentPayload) {
   return axios.post(PRIVATE_EBOOKS_URL, newValues);
}

// READ
export function getAllEbooks(isExpired?: boolean, lastEvaluatedKey?: string) {
   return axios.get<HtiResponse<ContentData>>(EBOOKS_URL, {
      params: { isExpired: isExpired ?? false, lastEvaluatedKey }
   });
}

export function getEbookById(ebookId: string) {
   return axios.get<HtiResponse<ContentById>>(`${EBOOKS_URL}/${ebookId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findEbooks(
   queryParams: customersUIPropsQueryParams,
   isPrivate: number,
   lastEvaluate:
      | LastEvaluatedKey_Content
      | LastEvaluatedKey_FreeContent
      | string,
   isFree: number
) {
   console.log('ebook get all');
   if (!queryParams.keyword) {
      // return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
      let limit = '';
      let lastKeyQuery = '';
      let status = '';
      let ebookType = '';

      if (queryParams.memberMode) {
         status = `&status=${
            queryParams.memberMode === '0' && isPrivate
               ? '2'
               : queryParams.memberMode
         }`;
      }
      if (queryParams && queryParams.ebookType) {
         ebookType = `&ebookType=${queryParams.ebookType}`;
      }
      if (queryParams && queryParams.pageSize) {
         limit = `?limit=${queryParams.pageSize}`;
      }
      if (
         lastEvaluate !== undefined &&
         JSON.stringify(lastEvaluate) !== JSON.stringify({}) &&
         typeof lastEvaluate !== 'string'
      ) {
         // lastKeyQuery = "&lastEvaluatedKey=" + btoa(JSON.stringify(lastEvaluate));
         lastKeyQuery =
            '&lastEvaluatedKey=' + btoa(JSON.stringify(lastEvaluate));
      } else if (typeof lastEvaluate === 'string') {
         lastKeyQuery = `&lastEvaluatedKey=${lastEvaluate}`;
      }
      return axios.get<HtiResponse<ContentData | FreeContentData>>(
         `${
            isPrivate
               ? PRIVATE_EBOOKS_URL
               : isFree
               ? FREE_EBOOKS_URL
               : EBOOKS_URL
         }${limit}${lastKeyQuery}${status}${ebookType}`
      );
   } else {
      let status = '';
      if (
         queryParams.memberMode !== undefined &&
         queryParams.memberMode !== ''
      ) {
         console.log(encodeURIComponent(queryParams.memberMode));
         status = `&status=${queryParams.memberMode}`;
      }

      return axios.get<HtiResponse<ContentData | FreeContentData>>(
         `${
            isPrivate
               ? PRIVATE_EBOOKS_URL
               : isFree
               ? FREE_EBOOKS_URL
               : EBOOKS_URL
         }/search?keyword=${encodeURIComponent(queryParams.keyword)}${status}`
      );
   }
   // return axios.post(`${EBOOKS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the ebook on the server
export function updateEbook(
   newValues: UpdateContentPayload,
   contentUID: string,
   isNew: boolean
) {
   let isNewQuery = '';
   if (isNew) {
      isNewQuery = '?isNew=true';
   }
   let values = newValues;
   delete values.authorName;
   delete values.publisherName;
   delete values.activeLicense;
   // console.log(values);
   return axios.put(
      `${PRIVATE_EBOOKS_URL}/${contentUID}/edit${isNewQuery}`,
      values
   );
}

// UPDATE Status
// export function updateStatusForEbooks(ids, status) {
//   return axios.post(`${EBOOKS_URL}/updateStatusForEbooks`, {
//     ids,
//     status,
//   });
// }

// DELETE => delete the ebook from the server
export function deleteEbook(
   ebookId: string,
   isPrivate: boolean,
   forceReturn: deletePayload
) {
   return axios.delete(
      `${isPrivate ? PRIVATE_EBOOKS_URL : EBOOKS_URL}/${ebookId}`,
      { data: forceReturn }
   );
}

export async function deleteExpiredContent(
   isAll?: boolean,
   contentUID?: string
) {
   try {
      const { data } = await axios.delete(`/superuser/hibrary/deleteContent`, {
         data: { contentUID, isAll: isAll ? true : false }
      });
      return data;
   } catch (error) {
      console.log(error);
   }
}

export function blockEbook(
   ebookId: string,
   isPrivate: boolean,
   isFree: boolean
) {
   if (isPrivate) {
      return axios.patch(`${PRIVATE_EBOOKS_URL}/${ebookId}/status`, {
         status: 2
      });
   } else {
      if (isFree) {
         return axios.patch(`${FREE_EBOOKS_URL}/${ebookId}/status`);
      } else {
         return axios.patch(`${EBOOKS_URL}/${ebookId}/status`);
      }
   }
}

export function unblockEbook(
   ebookId: string,
   isPrivate: boolean,
   isFree: boolean
) {
   if (isPrivate) {
      return axios.patch(`${PRIVATE_EBOOKS_URL}/${ebookId}/status`, {
         status: 1
      });
   } else {
      if (isFree) {
         return axios.patch(`${FREE_EBOOKS_URL}/${ebookId}/status`);
      } else {
         return axios.patch(`${EBOOKS_URL}/${ebookId}/status`);
      }
   }
}

export async function getInsertHibraryContentJob() {
   try {
      const { data } = await axios.get<HtiResponse<HtiContentJobResponse>>(
         `${SUPER_USER_URL}`
      );
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
}

export async function getUploadFileAddToStore(reportUID: string) {
   try {
      const { data } = await axios.get<
         HtiResponse<getUploadedFileContentToStore>
      >(`${SUPER_USER_URL}/${reportUID}/upload`);
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
}

// DELETE Ebooks by ids
// export function deleteEbooks(ids) {
//   return axios.post(`${EBOOKS_URL}/deleteEbooks`, { ids });
// }
