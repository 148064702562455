import axios from "axios";
import {
  QrcodeData,
  HtiResponse,
  QRCodeById,
  UpdateQRCodepayload,
  LastEvaluatedKey_QRCode,
  customersUIPropsQueryParams,
  createQrcodePayload,
} from "../../../../../interface";

// export const QRCODES_URL = "api/qrcodes";
export const QRCODES_URL = `/admin/qrcode`;
export const REGISTER_QRCODES_URL = `/admin/register`;

// CREATE =>  POST: add a new qrcode to the server
export function createQrcode(qrcode: createQrcodePayload) {
  return axios.post(REGISTER_QRCODES_URL, { ...qrcode });
}

// READ
export function getAllQrcodes() {
  return axios.get<HtiResponse<QrcodeData>>(QRCODES_URL);
}

export function getQrcodeById(qrcodeId: string) {
  return axios.get<HtiResponse<QRCodeById>>(`${QRCODES_URL}/${qrcodeId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findQrcodes(
  queryParams: customersUIPropsQueryParams,
  lastEvaluate: LastEvaluatedKey_QRCode
) {
  if (!queryParams.keyword) {
    // return axios.post(`${QRCODES_URL}/find`, { queryParams });
    let limit = "";
    let lastKeyQuery = "";
    let status = "";

    if (queryParams && queryParams.pageSize) {
      limit = `?limit=${queryParams.pageSize}`;
    }
    if (
      lastEvaluate !== undefined &&
      JSON.stringify(lastEvaluate) !== JSON.stringify({})
    ) {
      lastKeyQuery = "&lastEvaluatedKey=" + btoa(JSON.stringify(lastEvaluate));
    }
    // if (memberMode) {
    //   status = `&status=${memberMode}`;
    // }
    return axios.get(`${QRCODES_URL}${limit}${lastKeyQuery}${status}`);
  } else {
    return axios.get(
      `${QRCODES_URL}/search?keyword=${encodeURIComponent(queryParams.keyword)}`
    );
  }
}

export function findQrcodeMembers(qrcodeId: string) {
  // if (!queryParams.keyword) {
  //   // if (memberMode) {
  //   //   status = `&status=${memberMode}`;
  //   // }
  return axios.get(`${QRCODES_URL}/${qrcodeId}/user`);
  // } else {
  //   return axios.post(`${QRCODES_URL}/${qrcodeId}/user/search`, { keyword: queryParams.keyword });
  // }
}

// UPDATE => PUT: update the qrcode on the server
export function updateQrcode(qrcode: UpdateQRCodepayload) {
  return axios.put(`${QRCODES_URL}/${qrcode.id}`, { qrcode });
}

// UPDATE Status
// export function updateStatusForQrcodes(ids, status) {
//   return axios.post(`${QRCODES_URL}/updateStatusForQrcodes`, {
//     ids,
//     status,
//   });
// }

// DELETE => delete the qrcode from the server
export function deleteQrcode(qrcodeId: string) {
  return axios.delete(`${QRCODES_URL}/${qrcodeId}`);
}

// export function blockQrcode(qrcodeId:string) {
//   return axios.patch(`${QRCODES_URL}/${qrcodeId}/block`);
// }

// export function resetPwQrcode(qrcodeId:string) {
//   return axios.patch(`${QRCODES_URL}/${qrcodeId}/block`);
// }

// // DELETE Qrcodes by ids
// export function deleteQrcodes(ids) {
//   return axios.post(`${QRCODES_URL}/deleteQrcodes`, { ids });
// }
