import * as requestFromServer from "./uploadCrud";
import * as auth from "../../../Auth/_redux/authRedux";
import { uploadSlice, callTypes } from "./uploadSlice";
const { actions } = uploadSlice;

export const fetchNewPrivateContentData = () => (dispatch) => {
  return requestFromServer
    .getNewPrivateContentData()
    .then((response) => {
      if (response.data.status && response.data.data) {
        dispatch(
          actions.contentDataFetched({ contentData: response.data.data })
        );
      }
    })
    .catch((error) => {
      auth.actions.logout();
      error.clientMessage = "Can't fetch data";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
