/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import {
   Select,
   Card,
   CardBody
} from '../../../../../_metronic/_partials/controls';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(() => ({
   input: { margin: '12px 0' }
}));
const ColorForm = ({
   isEditing,
   uploadValue,
   handleSubmitOutside,
   colorThemeData,
   // colorObj,
   // setColorObj,
   lightColorObj,
   setLightColorObj,
   darkColorObj,
   setDarkColorObj,
   themeValue,
   setThemeValue,
   defaultLightColorObj,
   defaultDarkColorObj
}) => {
   const [themeList, setThemeList] = useState([
      <option value={'light'}>Light</option>,
      <option value={'dark'}>Dark</option>
   ]);

   const [primaryInput, setPrimaryInput] = useState('');
   const [primaryVariantInput, setPrimaryVariantInput] = useState('');
   const [secondaryInput, setSecondaryInput] = useState('');
   const [secondaryVariantInput, setSecondaryVariantInput] = useState('');
   const [onPrimaryInput, setOnPrimaryInput] = useState('');
   const [onSecondaryInput, setOnSecondaryInput] = useState('');

   const [onPrimaryDarkDefault, setOnPrimaryDarkDefault] = useState('');
   const [onSecondaryDarkDefault, setOnSecondaryDarkDefault] = useState('');

   const [onPrimaryLightDefault, setOnPrimaryLightDefault] = useState('');
   const [onSecondaryLightDefault, setOnSecondaryLightDefault] = useState('');
   const [firstLoad, setFirstLoad] = useState(0);
   const [checkingError, setCheckingError] = useState({
      Primary: false,
      Secondary: false,
      PrimaryVariant: false,
      SecondaryVariant: false,
      OnPrimary: false,
      OnSecondary: false
   });

   // const [colorObj, setColorObj] = useState({});
   const checkPattern = () => {
      console.log(
         checkingError.Primary,
         checkingError.Secondary,
         checkingError.PrimaryVariant,
         checkingError.SecondaryVariant,
         checkingError.OnPrimary,
         checkingError.OnSecondary
      );
      return (
         checkingError.Primary &&
         checkingError.Secondary &&
         checkingError.PrimaryVariant &&
         checkingError.SecondaryVariant &&
         checkingError.OnPrimary &&
         checkingError.OnSecondary
      );
   };

   useEffect(() => {
      console.log('themeValue ', themeValue, isEditing);
      if (isEditing) {
         if (defaultLightColorObj) {
            setInputValue('Primary', defaultLightColorObj['Primary']);
            setInputValue('Secondary', defaultLightColorObj['Secondary']);
            setInputValue(
               'PrimaryVariant',
               defaultLightColorObj['PrimaryVariant']
            );
            setInputValue(
               'SecondaryVariant',
               defaultLightColorObj['SecondaryVariant']
            );
            setInputValue('OnPrimary', defaultLightColorObj['OnPrimary']);
            setInputValue('OnSecondary', defaultLightColorObj['OnSecondary']);
            setOnPrimaryLightDefault(lightColorObj['OnPrimary']);
            setOnSecondaryLightDefault(lightColorObj['OnSecondary']);
         } else if (defaultDarkColorObj) {
            setInputValue('Primary', defaultDarkColorObj['Primary']);
            setInputValue('Secondary', defaultDarkColorObj['Secondary']);
            setInputValue(
               'PrimaryVariant',
               defaultDarkColorObj['PrimaryVariant']
            );
            setInputValue(
               'SecondaryVariant',
               defaultDarkColorObj['SecondaryVariant']
            );
            setInputValue('OnPrimary', defaultDarkColorObj['OnPrimary']);
            setInputValue('OnSecondary', defaultDarkColorObj['OnSecondary']);
            setOnPrimaryDarkDefault(darkColorObj['OnPrimary']);
            setOnSecondaryDarkDefault(darkColorObj['OnSecondary']);
         }
      }
   }, [isEditing, defaultLightColorObj, defaultDarkColorObj]);
   useEffect(() => {
      console.log('dark ', darkColorObj);
      console.log('light ', lightColorObj);
   }, [darkColorObj, lightColorObj]);
   useEffect(() => {
      console.log(
         'colorThemeData ',
         themeValue,
         primaryInput,
         secondaryInput,
         primaryVariantInput,
         secondaryVariantInput,
         onPrimaryInput,
         onSecondaryInput,
         onPrimaryLightDefault,
         onSecondaryLightDefault
      );
      if (
         Array.isArray(colorThemeData) &&
         colorThemeData.length === 2 &&
         !isEditing
      ) {
         setThemeList(
            colorThemeData.map(e => {
               if (e.value === 'light') {
                  const newLightColorObj = Object.assign({}, e.color, {
                     Primary: lightColorObj.Primary,
                     PrimaryVariant: lightColorObj.PrimaryVariant,
                     Secondary: lightColorObj.Secondary,
                     SecondaryVariant: lightColorObj.SecondaryVariant
                  });
                  setLightColorObj(newLightColorObj);
                  setOnPrimaryLightDefault(e.color.OnPrimary);
                  setOnSecondaryLightDefault(e.color.OnSecondary);
               }
               if (e.value === 'dark') {
                  const newDarkColorObj = Object.assign({}, e.color, {
                     Primary: lightColorObj.Primary,
                     PrimaryVariant: lightColorObj.PrimaryVariant,
                     Secondary: lightColorObj.Secondary,
                     SecondaryVariant: lightColorObj.SecondaryVariant
                  });
                  console.log(newDarkColorObj);
                  setDarkColorObj(newDarkColorObj);
                  setOnPrimaryDarkDefault(e.color.OnPrimary);
                  setOnSecondaryDarkDefault(e.color.OnSecondary);
               }
               return <option value={e.value}>{e.label}</option>;
            })
         );
      }
   }, [colorThemeData]);

   const onHide = () => {
      handleSubmitOutside();
   };

   const handleThemeChange = e => {
      setThemeValue(e.target.value);
   };

   useEffect(() => {
      if (themeValue === 'light' && isEditing) {
         const newLightColorObj = Object.assign({}, lightColorObj, {
            Primary: primaryInput,
            Secondary: secondaryInput,
            PrimaryVariant: primaryVariantInput,
            SecondaryVariant: secondaryVariantInput
         });
         setLightColorObj(newLightColorObj);
      } else if (themeValue === 'dark' && isEditing) {
         const newDarkColorObj = Object.assign({}, darkColorObj, {
            Primary: primaryInput,
            Secondary: secondaryInput,
            PrimaryVariant: primaryVariantInput,
            SecondaryVariant: secondaryVariantInput
         });
         setDarkColorObj(newDarkColorObj);
      }
      if (isEditing)
         console.log(
            'themeInput ',
            themeValue,
            primaryInput,
            secondaryInput,
            primaryVariantInput,
            secondaryVariantInput,
            onPrimaryInput,
            onSecondaryInput,
            onPrimaryLightDefault,
            onSecondaryLightDefault
         );
   }, [
      themeValue,
      primaryInput,
      secondaryInput,
      primaryVariantInput,
      secondaryVariantInput
   ]);

   const setInputValue = (mode, value) => {
      // console.log("setInputValue ", mode, value);
      switch (mode) {
         case 'Primary':
            setPrimaryInput(value);
            break;
         case 'Secondary':
            setSecondaryInput(value);
            break;
         case 'PrimaryVariant':
            setPrimaryVariantInput(value);
            break;
         case 'SecondaryVariant':
            setSecondaryVariantInput(value);
            break;
         case 'OnPrimary':
            setOnPrimaryInput(value);
            break;
         case 'OnSecondary':
            setOnSecondaryInput(value);
            break;
         default:
            break;
      }
   };
   const getInputValue = mode => {
      // console.log("setInputValue ", mode, value);
      switch (mode) {
         case 'Primary':
            return primaryInput;
         case 'Secondary':
            return secondaryInput;

         case 'PrimaryVariant':
            return primaryVariantInput;

         case 'SecondaryVariant':
            return secondaryVariantInput;

         case 'OnPrimary':
            return onPrimaryInput;

         case 'OnSecondary':
            return onSecondaryInput;

         default:
            break;
      }
   };

   const setLightDefaultUndefined = mode => {
      switch (mode) {
         case 'OnPrimary':
            setOnPrimaryLightDefault(undefined);
            break;
         case 'OnSecondary':
            setOnSecondaryLightDefault(undefined);
            break;
         default:
            break;
      }
   };

   const setDarkDefaultUndefined = mode => {
      switch (mode) {
         case 'OnPrimary':
            setOnPrimaryDarkDefault(undefined);
            break;
         case 'OnSecondary':
            setOnSecondaryDarkDefault(undefined);
            break;
         default:
            break;
      }
   };
   const displayColor = colorName => {
      if (themeValue === 'light') {
         if (lightColorObj[colorName]) {
            return lightColorObj[colorName];
         } else {
            return getInputValue(colorName);
         }
      }
      if (themeValue === 'dark') {
         if (darkColorObj[colorName]) {
            return darkColorObj[colorName];
         } else {
            return getInputValue(colorName);
         }
      }
   };
   useEffect(() => {
      console.log(
         'useEffect1 ',
         themeValue,
         primaryInput,
         secondaryInput,
         primaryVariantInput,
         secondaryVariantInput,
         onPrimaryInput,
         onSecondaryInput
      );
      if (
         !isEditing &&
         themeValue === 'light' &&
         lightColorObj &&
         firstLoad < 2
      ) {
         setInputValue('OnPrimary', lightColorObj['OnPrimary']);
         setInputValue('OnSecondary', lightColorObj['OnSecondary']);
         setFirstLoad(firstLoad + 1);
      } else if (
         !isEditing &&
         themeValue === 'dark' &&
         darkColorObj &&
         firstLoad < 2
      ) {
         setInputValue('OnPrimary', darkColorObj['OnPrimary']);
         setInputValue('OnSecondary', darkColorObj['OnSecondary']);
         setFirstLoad(firstLoad + 1);
      }
   }, [themeValue, lightColorObj, darkColorObj]);

   useEffect(() => {
      console.log('useEffect2 ', lightColorObj, darkColorObj, firstLoad);
      if (themeValue === 'light') {
         setInputValue('OnPrimary', lightColorObj['OnPrimary']);
         setInputValue('OnSecondary', lightColorObj['OnSecondary']);
      } else {
         setInputValue('OnPrimary', darkColorObj['OnPrimary']);
         setInputValue('OnSecondary', darkColorObj['OnSecondary']);
         // if (!isEditing && firstLoad < 2) {
         //   setInputValue("Primary", darkColorObj["Primary"]);
         //   setInputValue("Secondary", darkColorObj["Secondary"]);
         //   setInputValue("PrimaryVariant", darkColorObj["PrimaryVariant"]);
         //   setInputValue("SecondaryVariant", darkColorObj["SecondaryVariant"]);
         //   setFirstLoad(firstLoad + 1);
         // }
      }
      if (!isEditing && firstLoad < 2) {
         setInputValue('Primary', lightColorObj['Primary']);
         setInputValue('Secondary', lightColorObj['Secondary']);
         setInputValue('PrimaryVariant', lightColorObj['PrimaryVariant']);
         setInputValue('SecondaryVariant', lightColorObj['SecondaryVariant']);
         setInputValue('Primary', darkColorObj['Primary']);
         setInputValue('Secondary', darkColorObj['Secondary']);
         setInputValue('PrimaryVariant', darkColorObj['PrimaryVariant']);
         setInputValue('SecondaryVariant', darkColorObj['SecondaryVariant']);
         setFirstLoad(firstLoad + 1);
      }
   }, [themeValue]);

   const onChangeColorText = (e, mode) => {
      if (e && e.target) {
         const value = e.target.value;

         if (value && value.length <= 7 && regexInput.test(value)) {
            setInputValue(mode, value);
         } else if (value === '') {
            setInputValue(mode, value);
         }
         if (themeValue === 'light') {
            setLightDefaultUndefined(mode);
         } else {
            setDarkDefaultUndefined(mode);
         }
         if (
            value
            // (value && value.length === 7) || (value && value.length === 6)
         ) {
            console.log(value);
            let newError = Object.assign({}, checkingError);
            newError[mode] = false;
            setCheckingError(newError);
            let newValue = value;
            // if (value.length === 6) {
            //    newValue = '#' + value;
            // }
            if (themeValue === 'light') {
               let newObj = Object.assign({}, lightColorObj);
               newObj[mode] = newValue;
               setLightColorObj(newObj);
               // setColorObj(newObj);
            } else {
               let newObj = Object.assign({}, darkColorObj);
               newObj[mode] = newValue;
               setDarkColorObj(newObj);
               // setColorObj(newObj);
            }
         }
      }
   };

   const onBlurColorText = (e, mode) => {
      if (e && e.target) {
         if (
            (e.target.value &&
               e.target.value.length === 7 &&
               regex.test(e.target.value)) ||
            (e.target.value &&
               e.target.value.length === 6 &&
               regex.test('#' + e.target.value))
         ) {
         } else {
            let newError = Object.assign({}, checkingError);
            newError[mode] = true;
            setCheckingError(newError);
         }
      }
   };

   const styles = useStyles();
   const regexInput = RegExp(/^[#0-9A-F]*$/i);
   const regex = RegExp(/^#[0-9A-F]{6}$/i);
   const regex_6 = RegExp(/^[0-9A-F]{6}$/i);
   const isInputRegex = input => {
      if (input && regex.test(input)) {
         //for color
         return input;
      } else if (input && regex_6.test(input)) {
         // for validating 6 characters
         return '#' + input;
      } else {
         return undefined;
      }
   };
   const onChangeColorInput = (e, mode) => {
      console.log(e);
      if (themeValue === 'light') {
         setLightDefaultUndefined(mode); //OnPrimary and OnSecondary
         let newObj = Object.assign({}, lightColorObj);
         newObj[mode] = e.target ? e.target.value : '#ffffff';
         console.log(newObj);
         setLightColorObj(newObj);
      } else {
         setDarkDefaultUndefined(mode); //OnPrimary and OnSecondary
         let newObj = Object.assign({}, darkColorObj);
         newObj[mode] = e.target ? e.target.value : '#000000';
         setDarkColorObj(newObj);
      }
      setInputValue(mode, e.target.value);
   };
   return (
      <>
         <Formik
            enableReinitialize={true}
            initialValues={uploadValue}
            onSubmit={() => {}}
         >
            {({ handleSubmit, values, setFieldValue }) => {
               return (
                  <Card classNam="cardStyle">
                     <CardBody
                        style={isEditing ? { padding: '2rem 0 0 0' } : {}}
                     >
                        <div style={{ maxWidth: '736px', margin: 'auto' }}>
                           <Form className="form form-label-right">
                              <div
                                 className="row"
                                 style={{ justifyContent: 'center' }}
                              >
                                 <div
                                    className={`col-lg-8 ${styles.input}`}
                                    style={{ padding: '0 20px' }}
                                 >
                                    <Select
                                       name="theme"
                                       withFeedbackLabel={false}
                                       label="Theme"
                                       onChange={handleThemeChange}
                                       value={themeValue}
                                    >
                                       {themeList}
                                    </Select>
                                 </div>
                              </div>
                              <div
                                 className="row"
                                 style={{
                                    justifyContent: 'center',
                                    margin: '12px 0'
                                 }}
                              >
                                 <div className={`col-lg-4`}>
                                    <label for="Primary">Primary</label>
                                    <br />
                                    <div className="row">
                                       <div className={`col-lg-3`}>
                                          <input
                                             type="color"
                                             id="Primary"
                                             name="Primary"
                                             value={displayColor('Primary')}
                                             onChange={e =>
                                                onChangeColorInput(e, 'Primary')
                                             }
                                             className="inputColor"
                                          />
                                       </div>
                                       <div
                                          className={`col-lg-9`}
                                          style={{ paddingLeft: '0' }}
                                       >
                                          <input
                                             name="Primary"
                                             value={displayColor('Primary')}
                                             placeholder="#"
                                             onChange={e =>
                                                onChangeColorText(e, 'Primary')
                                             }
                                             onBlur={e =>
                                                onBlurColorText(e, 'Primary')
                                             }
                                             className="form-control"
                                          />
                                          {checkingError['Primary'] && (
                                             <>
                                                <div className="is-invalid"></div>
                                                <div className="invalid-feedback">
                                                   กรุณากรอกค่าสีให้ถูกต้อง
                                                </div>
                                             </>
                                          )}
                                       </div>
                                    </div>
                                 </div>
                                 <div className={`col-lg-4`}>
                                    <label for="PrimaryVariant">
                                       PrimaryVariant
                                    </label>
                                    <br />
                                    <div className="row">
                                       <div className={`col-lg-3`}>
                                          <input
                                             type="color"
                                             id="PrimaryVariant"
                                             name="PrimaryVariant"
                                             value={displayColor(
                                                'PrimaryVariant'
                                             )}
                                             onChange={e =>
                                                onChangeColorInput(
                                                   e,
                                                   'PrimaryVariant'
                                                )
                                             }
                                             className="inputColor"
                                          />
                                       </div>
                                       <div
                                          className={`col-lg-9`}
                                          style={{ paddingLeft: '0' }}
                                       >
                                          <input
                                             name="PrimaryVariant"
                                             value={displayColor(
                                                'PrimaryVariant'
                                             )}
                                             placeholder="#"
                                             onChange={e =>
                                                onChangeColorText(
                                                   e,
                                                   'PrimaryVariant'
                                                )
                                             }
                                             onBlur={e =>
                                                onBlurColorText(
                                                   e,
                                                   'PrimaryVariant'
                                                )
                                             }
                                             className="form-control"
                                          />
                                          {checkingError['PrimaryVariant'] && (
                                             <>
                                                <div className="is-invalid"></div>
                                                <div className="invalid-feedback">
                                                   กรุณากรอกค่าสีให้ถูกต้อง
                                                </div>
                                             </>
                                          )}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div
                                 className="row"
                                 style={{
                                    justifyContent: 'center',
                                    margin: '12px 0'
                                 }}
                              >
                                 <div className={`col-lg-4`}>
                                    <label for="Secondary">Secondary</label>
                                    <br />
                                    <div className="row">
                                       <div className={`col-lg-3`}>
                                          <input
                                             type="color"
                                             id="Secondary"
                                             name="Secondary"
                                             value={displayColor('Secondary')}
                                             onChange={e =>
                                                onChangeColorInput(
                                                   e,
                                                   'Secondary'
                                                )
                                             }
                                             className="inputColor"
                                          />
                                       </div>
                                       <div
                                          className={`col-lg-9`}
                                          style={{ paddingLeft: '0' }}
                                       >
                                          {console.log(
                                             darkColorObj['Secondary']
                                          )}
                                          <input
                                             name="Secondary"
                                             // value={secondaryInput}
                                             value={displayColor('Secondary')}
                                             //ryou
                                             placeholder="#"
                                             onChange={e =>
                                                onChangeColorText(
                                                   e,
                                                   'Secondary'
                                                )
                                             }
                                             onBlur={e =>
                                                onBlurColorText(e, 'Secondary')
                                             }
                                             className="form-control"
                                          />
                                          {checkingError['Secondary'] && (
                                             <>
                                                <div className="is-invalid"></div>
                                                <div className="invalid-feedback">
                                                   กรุณากรอกค่าสีให้ถูกต้อง
                                                </div>
                                             </>
                                          )}
                                       </div>
                                    </div>
                                 </div>
                                 <div className={`col-lg-4`}>
                                    <label for="SecondaryVariant">
                                       SecondaryVariant
                                    </label>
                                    <br />
                                    <div className="row">
                                       <div className={`col-lg-3`}>
                                          <input
                                             type="color"
                                             id="SecondaryVariant"
                                             name="SecondaryVariant"
                                             value={displayColor(
                                                'SecondaryVariant'
                                             )}
                                             onChange={e =>
                                                onChangeColorInput(
                                                   e,
                                                   'SecondaryVariant'
                                                )
                                             }
                                             className="inputColor"
                                          />
                                       </div>
                                       <div
                                          className={`col-lg-9`}
                                          style={{ paddingLeft: '0' }}
                                       >
                                          <input
                                             name="SecondaryVariant"
                                             value={displayColor(
                                                'SecondaryVariant'
                                             )}
                                             placeholder="#"
                                             onChange={e =>
                                                onChangeColorText(
                                                   e,
                                                   'SecondaryVariant'
                                                )
                                             }
                                             onBlur={e =>
                                                onBlurColorText(
                                                   e,
                                                   'SecondaryVariant'
                                                )
                                             }
                                             className="form-control"
                                          />
                                          {checkingError[
                                             'SecondaryVariant'
                                          ] && (
                                             <>
                                                <div className="is-invalid"></div>
                                                <div className="invalid-feedback">
                                                   กรุณากรอกค่าสีให้ถูกต้อง
                                                </div>
                                             </>
                                          )}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div
                                 className="row"
                                 style={{
                                    justifyContent: 'center',
                                    margin: '12px 0'
                                 }}
                              >
                                 <div className={`col-lg-4`}>
                                    <label for="OnPrimary">OnPrimary</label>
                                    <br />
                                    <div className="row">
                                       <div className={`col-lg-3`}>
                                          <input
                                             type="color"
                                             id="OnPrimary"
                                             name="OnPrimary"
                                             value={
                                                themeValue === 'light'
                                                   ? onPrimaryLightDefault ||
                                                     isInputRegex(
                                                        onPrimaryInput
                                                     ) ||
                                                     lightColorObj['OnPrimary']
                                                   : onPrimaryDarkDefault ||
                                                     isInputRegex(
                                                        onPrimaryInput
                                                     ) ||
                                                     darkColorObj['OnPrimary']
                                             }
                                             onChange={e =>
                                                onChangeColorInput(
                                                   e,
                                                   'OnPrimary'
                                                )
                                             }
                                             className="inputColor"
                                          />
                                       </div>
                                       <div
                                          className={`col-lg-9`}
                                          style={{ paddingLeft: '0' }}
                                       >
                                          <input
                                             name="OnPrimary"
                                             value={
                                                themeValue === 'light'
                                                   ? onPrimaryLightDefault ||
                                                     onPrimaryInput
                                                   : onPrimaryDarkDefault ||
                                                     onPrimaryInput
                                             }
                                             placeholder="#"
                                             onChange={e =>
                                                onChangeColorText(
                                                   e,
                                                   'OnPrimary'
                                                )
                                             }
                                             onBlur={e =>
                                                onBlurColorText(e, 'OnPrimary')
                                             }
                                             className="form-control"
                                          />
                                          {checkingError['OnPrimary'] && (
                                             <>
                                                <div className="is-invalid"></div>
                                                <div className="invalid-feedback">
                                                   กรุณากรอกค่าสีให้ถูกต้อง
                                                </div>
                                             </>
                                          )}
                                       </div>
                                    </div>
                                 </div>
                                 <div className={`col-lg-4`}>
                                    <label for="OnSecondary">OnSecondary</label>
                                    <br />
                                    <div className="row">
                                       <div className={`col-lg-3`}>
                                          <input
                                             type="color"
                                             id="OnSecondary"
                                             name="OnSecondary"
                                             value={
                                                themeValue === 'light'
                                                   ? onSecondaryLightDefault ||
                                                     lightColorObj[
                                                        'OnSecondary'
                                                     ] ||
                                                     isInputRegex(
                                                        onSecondaryInput
                                                     )
                                                   : onSecondaryDarkDefault ||
                                                     darkColorObj[
                                                        'OnSecondary'
                                                     ] ||
                                                     isInputRegex(
                                                        onSecondaryInput
                                                     )
                                             }
                                             onChange={e =>
                                                onChangeColorInput(
                                                   e,
                                                   'OnSecondary'
                                                )
                                             }
                                             className="inputColor"
                                          />
                                       </div>
                                       <div
                                          className={`col-lg-9`}
                                          style={{ paddingLeft: '0' }}
                                       >
                                          <input
                                             name="OnSecondary"
                                             value={
                                                themeValue === 'light'
                                                   ? onSecondaryLightDefault ||
                                                     onSecondaryInput
                                                   : onSecondaryDarkDefault ||
                                                     onSecondaryInput
                                             }
                                             placeholder="#"
                                             onChange={e =>
                                                onChangeColorText(
                                                   e,
                                                   'OnSecondary'
                                                )
                                             }
                                             onBlur={e =>
                                                onBlurColorText(
                                                   e,
                                                   'OnSecondary'
                                                )
                                             }
                                             className="form-control"
                                          />
                                          {checkingError['OnSecondary'] && (
                                             <>
                                                <div className="is-invalid"></div>
                                                <div className="invalid-feedback">
                                                   กรุณากรอกค่าสีให้ถูกต้อง
                                                </div>
                                             </>
                                          )}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="row">
                                 <div
                                    className="col-lg-8"
                                    style={{ margin: 'auto' }}
                                 >
                                    <div className="divButton ">
                                       <button
                                          className="denyButton "
                                          type="button"
                                          onClick={() => {
                                             handleSubmitOutside('prev');
                                          }}
                                       >
                                          {isEditing ? 'ยกเลิก' : 'ย้อนกลับ'}
                                       </button>
                                       <button
                                          className="acceptButton "
                                          type="submit"
                                          onClick={() => {
                                             onHide();
                                          }}
                                          disabled={
                                             !isInputRegex(primaryInput) ||
                                             !isInputRegex(secondaryInput) ||
                                             !isInputRegex(
                                                primaryVariantInput
                                             ) ||
                                             !isInputRegex(
                                                secondaryVariantInput
                                             ) ||
                                             (!isInputRegex(onPrimaryInput) &&
                                                !onPrimaryLightDefault &&
                                                !onPrimaryDarkDefault) ||
                                             (!isInputRegex(onSecondaryInput) &&
                                                !onSecondaryLightDefault &&
                                                !onSecondaryDarkDefault) ||
                                             checkPattern()
                                          }
                                       >
                                          {isEditing ? 'บันทึก' : 'ถัดไป'}
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </Form>
                        </div>
                     </CardBody>
                  </Card>
               );
            }}
         </Formik>
      </>
   );
};

export default ColorForm;
