/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import {
    Select,
    Card,
    CardBody
} from '../../../../../_metronic/_partials/controls';
import { DatePicker } from 'antd';
import * as Yup from 'yup';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { LoaderViewSmall, ModalsSuccess } from '../../../CustomView';
import {
    disabledDateAfterExpire,
    disabledDateBeforeStart,
    todayDatePickerStart,
    todayDatePickerEnd
} from '../../../../utility';
import axios from 'axios';
const { RangePicker } = DatePicker;

export function UploadSubscriptionPage({
    modalEditData,
    setModalEditData,
    editSubscriptionFunc,
    setModalEditUID,
    isEditing,
    isNew,
    successModalOutside,
    setSuccessModalOutside
}) {
    const { authState } = useSelector(
        ({ auth }) => ({
            authState: auth
        }),
        shallowEqual
    );
    const { viewAsToken } = authState;
    const history = useHistory();

    const [setData, setSetData] = useState([]);
    const [magazineData, setMagazineData] = useState([]);
    const [setValue, setSetValue] = useState(1);
    const [newspaperValue, setNewspaperValue] = useState('');
    const [magazineValue, setMagazineValue] = useState('');
    const [concurrentValue, setConcurrentValue] = useState(0);
    const [rentalValue, setRentalValue] = useState(0);
    const [paymentDate, setPaymentDate] = useState(null);

    const [startDate, setStartDate] = useState(
        moment()
            .startOf('date')
            .unix()
    );
    const [endDate, setEndDate] = useState(null);
    const [nameMini, setNameMini] = useState(null);
    const [disabledStartDate, setDisabledStartDate] = useState(null);
    const [disabledExpireDate, setDisabledExpireDate] = useState(null);
    const [newspaperToSubmit, setNewspaperToSubmit] = useState(0);
    const [magazineToSubmit, setMagazineToSubmit] = useState(0);

    const [successModal, setSuccessModal] = useState(false);

    const [checkingError, setCheckingError] = useState({
        newspaper: false,
        magazine: false,
        datePicker: false,
        rental: false,
        concurrent: false
    });

    const setList = [
        <option key="1" value={1}>
            หนังสือพิมพ์
        </option>,
        <option key="2" value={2}>
            นิตยสาร
        </option>
    ];
    const [newspaperList, setNewspaperList] = useState([
        <option key="0" value="" disabled>
            กรุณาเลือกหนังสือพิมพ์
        </option>
    ]);
    const [magazineList, setMagazineList] = useState([
        <option key="0" value="" disabled>
            กรุณาเลือกนิตยสาร
        </option>
    ]);
    const [concurrentList, setConcurrentList] = useState([
        <option key="" value={0} disabled>
            กรุณาเลือกจำนวน
        </option>
    ]);
    const [concurrentLimitData, setConcurrentLimitData] = useState([]);
    const [rentalList, setRentalList] = useState([
        <option key="" value={0} disabled>
            กรุณาเลือกจำนวน
        </option>
    ]);
    const [rentalLimitData, setRentalLimitData] = useState([]);

    const fetchDropdownFunc = async () => {
        if (viewAsToken && jwt_decode(viewAsToken).clientUID) {
            const req = await axios.get(
                `/superuser/subscriptions/create?clientUID=${
                    jwt_decode(viewAsToken).clientUID
                }`
            );
            if (req.data.status && req.data.data) {
                if (req.data.data.startDate) {
                    setDisabledStartDate(req.data.data.startDate);
                }
                if (req.data.data.expireDate) {
                    setDisabledExpireDate(req.data.data.expireDate);
                }
                if (req.data.data.presets) {
                    req.data.data.presets.map(e => {
                        if (e.title === 'หนังสือพิมพ์') {
                            setSetData(e.contents ? e.contents : []);
                        }
                        if (e.title === 'นิตยสาร') {
                            setMagazineData(e.contents ? e.contents : []);
                        }
                    });
                }
                if (req.data.data.concurrent) {
                    setConcurrentLimitData(req.data.data.concurrent);
                }
                if (req.data.data.rentalPeriod) {
                    setRentalLimitData(req.data.data.rentalPeriod);
                }
            }
        }
    };
    useEffect(() => {
        if (Array.isArray(setData)) {
            setNewspaperList([
                <option key="0" value="" disabled>
                    กรุณาเลือกหนังสือพิมพ์
                </option>,
                ...setData.map((e, index) => {
                    return (
                        <option key={e.value} value={e.value}>
                            {e.title}
                        </option>
                    );
                })
            ]);
        }
    }, [setData]);

    useEffect(() => {
        fetchDropdownFunc();
    }, []);

    useEffect(() => {
        if (Array.isArray(magazineData)) {
            setMagazineList([
                <option key="0" value="" disabled>
                    กรุณาเลือกนิตยสาร
                </option>,
                ...magazineData.map((e, index) => {
                    return (
                        <option key={e.value} value={e.value}>
                            {e.title}
                        </option>
                    );
                })
            ]);
        }
    }, [magazineData]);

    useEffect(() => {
        if (Array.isArray(concurrentLimitData)) {
            setConcurrentList([
                <option key="" value={0} disabled>
                    กรุณาเลือกจำนวน
                </option>,
                ...concurrentLimitData.map((el, index) => {
                    return (
                        <option key={index} value={el.value}>
                            {el.title}
                        </option>
                    );
                })
            ]);
        }
    }, [concurrentLimitData]);

    useEffect(() => {
        if (successModalOutside) {
            setSuccessModal(true);
        }
    }, [successModalOutside]);

    useEffect(() => {
        if (Array.isArray(rentalLimitData)) {
            setRentalList([
                <option key="" value={0} disabled>
                    กรุณาเลือกจำนวน
                </option>,
                ...rentalLimitData.map((el, index) => {
                    return (
                        <option key={index} value={el.value}>
                            {el.title}
                        </option>
                    );
                })
            ]);
        }
    }, [rentalLimitData]);

    const onHide = () => {
        setSuccessModal(false);
        if (setSuccessModalOutside) {
            setSuccessModalOutside(false);
        }
    };

    const getdata = async () => {
        const get = await axios.get(`/superuser/subscriptions/create`);
    };
    useEffect(() => {
        getdata();
    }, []);
    // Validation schema
    const uploadSchema = Yup.object().shape({
        nameLib: Yup.string()
            .min(1, 'อย่างน้อย 1 ตัวอักษร')
            .max(200, 'ไม่เกิน 200 ตัวอักษร'),
        email: Yup.string()
            .email('อีเมลไม่ถูกต้อง')
            .min(6, 'อย่างน้อย 6 ตัวอักษร')
            .max(200, 'ไม่เกิน 200 ตัวอักษร')
    });

    const handleSetChange = e => {
        const value = parseInt(e.target.value, 10);
        setSetValue(value);
    };

    const handleNewspaperChange = e => {
        setNewspaperValue(e.target.value);
        setCheckingError(
            Object.assign({}, checkingError, { newspaper: false })
        );
    };

    const handleMagazineChange = e => {
        setMagazineValue(e.target.value);
        setCheckingError(Object.assign({}, checkingError, { magazine: false }));
    };
    const handleRentalChange = e => {
        const value = parseInt(e.target.value, 10);
        setRentalValue(value);
        setCheckingError(Object.assign({}, checkingError, { rental: false }));
    };
    const handleConcurrentChange = e => {
        const value = parseInt(e.target.value, 10);
        setConcurrentValue(value);
        setCheckingError(
            Object.assign({}, checkingError, { concurrent: false })
        );
    };
    const onDurationChange = (dates, dateStrings) => {
        if (dates) {
            setStartDate(dates[0].unix());
            setEndDate(dates[1].unix());
            setCheckingError(
                Object.assign({}, checkingError, { datePicker: false })
            );
        } else {
            setStartDate(null);
            setEndDate(null);
        }
    };
    useEffect(() => {
        if (modalEditData && modalEditData.startDate) {
            setStartDate(modalEditData.startDate);
        }
        if (modalEditData && modalEditData.expireDate) {
            setEndDate(modalEditData.expireDate);
        }
        if (modalEditData && modalEditData.rentalPeriod) {
            setRentalValue(modalEditData.rentalPeriod);
        }
        if (modalEditData && modalEditData.concurrent) {
            setConcurrentValue(modalEditData.concurrent);
        }
        if (modalEditData && modalEditData.paymentDate) {
            setPaymentDate(modalEditData.paymentDate);
        }
        if (modalEditData && modalEditData.setType && modalEditData.title) {
            if (modalEditData.setType === 'Newspaper') {
                setSetValue(1);
                setNewspaperValue(modalEditData.title);
            } else if (modalEditData.setType === 'Magazine') {
                setSetValue(2);
                setMagazineValue(modalEditData.title);
            }
        }
    }, [modalEditData]);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    email: '',
                    password: '',
                    concurrent: concurrentValue,
                    subscriptionUID:
                        setValue === 1 ? newspaperValue : magazineValue,
                    rentalPeriod: rentalValue,
                    startDate: startDate,
                    expireDate: endDate
                }}
                onSubmit={values => {
                    const newValues = {
                        concurrent: concurrentValue,
                        subscriptionUID:
                            setValue === 1 ? newspaperValue : magazineValue,
                        rentalPeriod: rentalValue,
                        startDate: startDate,
                        expireDate: endDate,
                        paymentDate:
                            paymentDate !== null ? paymentDate : undefined
                    };

                    //d
                    if (
                        concurrentValue &&
                        rentalValue &&
                        startDate &&
                        endDate &&
                        (newspaperValue || magazineValue)
                    ) {
                        const reqFunc = async () => {
                            const req = await axios.post(
                                `/superuser/subscriptions/create`,
                                newValues
                            );
                            if (req.data.status) {
                                setSuccessModal(true);
                            }
                        };
                        console.log(isNew);
                        if (isNew) {
                            reqFunc();
                        } else if (modalEditData) {
                            editSubscriptionFunc(
                                Object.assign({}, newValues, {
                                    status: modalEditData.status,
                                    subscriptionUID: undefined,
                                    // period: rentalValue,
                                    rentalPeriod: rentalValue
                                })
                            );
                        }
                    }
                }}
            >
                {({ handleSubmit, values, setFieldValue }) => {
                    return (
                        <Card>
                            {isEditing &&
                            !modalEditData ? null : modalEditData ? (
                                <h2 className="center">แก้ไข Subscription</h2>
                            ) : null}
                            <CardBody>
                                {isEditing &&
                                !modalEditData ? null : modalEditData ? null : (
                                    <h2 className="center">
                                        สร้าง Subscription
                                    </h2>
                                )}
                                {isEditing && !modalEditData ? (
                                    <LoaderViewSmall />
                                ) : (
                                    <div
                                        style={{
                                            maxWidth: '736px',
                                            margin: 'auto'
                                        }}
                                    >
                                        <Form className="form form-label-right">
                                            <h3>เลือกเซ็ท</h3>
                                            <div className="row">
                                                <div className={`col-12 input`}>
                                                    {modalEditData ? (
                                                        <input
                                                            autoComplete="new-password"
                                                            className={`form-control`}
                                                            value={
                                                                setValue === 1
                                                                    ? 'หนังสือพิมพ์'
                                                                    : 'นิตยสาร'
                                                            }
                                                            disabled
                                                        />
                                                    ) : (
                                                        <Select
                                                            name="sets"
                                                            withFeedbackLabel={
                                                                false
                                                            }
                                                            onChange={
                                                                handleSetChange
                                                            }
                                                            value={setValue}
                                                            style={{
                                                                backgroundImage: `url(
                                                                                     "data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"
                                                                                )`
                                                            }}
                                                            className={`form-control selectDropdown`}
                                                        >
                                                            {setList}
                                                        </Select>
                                                    )}
                                                    {!setValue &&
                                                    checkingError['set'] ? (
                                                        <>
                                                            <div className="is-invalid"></div>
                                                            <div className="invalid-feedback">
                                                                กรุณาเลือกเซ็ท
                                                            </div>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <h3 className="mt">
                                                {setValue === 1
                                                    ? 'เลือกหนังสือพิมพ์'
                                                    : 'เลือกนิตยสาร'}
                                            </h3>
                                            <div className="row">
                                                <div
                                                    className={`col-lg-12 input`}
                                                >
                                                    {modalEditData ? (
                                                        <input
                                                            autoComplete="new-password"
                                                            className={`form-control`}
                                                            value={
                                                                setValue === 1
                                                                    ? newspaperValue
                                                                    : magazineValue
                                                            }
                                                            disabled
                                                        />
                                                    ) : setValue === 1 ? (
                                                        <Select
                                                            name="newspaper"
                                                            withFeedbackLabel={
                                                                false
                                                            }
                                                            // label="กรุณาเลือกหนังสือพิมพ์"
                                                            onChange={
                                                                handleNewspaperChange
                                                            }
                                                            value={
                                                                newspaperValue
                                                            }
                                                            className={`form-control selectDropdown`}
                                                            style={{
                                                                backgroundImage: `url(
                                  "data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"
                                )`
                                                            }}
                                                        >
                                                            {newspaperList}
                                                        </Select>
                                                    ) : (
                                                        <Select
                                                            name="magazine"
                                                            withFeedbackLabel={
                                                                false
                                                            }
                                                            // label="กรุณาเลือกนิตยสาร"
                                                            onChange={
                                                                handleMagazineChange
                                                            }
                                                            value={
                                                                magazineValue
                                                            }
                                                            className={`form-control selectDropdown`}
                                                            style={{
                                                                backgroundImage: `url(
                                      "data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"
                                )`
                                                            }}
                                                        >
                                                            {magazineList}
                                                        </Select>
                                                    )}
                                                    {setValue === 1 ? (
                                                        !newspaperValue &&
                                                        checkingError[
                                                            'newspaper'
                                                        ] ? (
                                                            <>
                                                                <div className="is-invalid"></div>
                                                                <div className="invalid-feedback">
                                                                    กรุณาเลือกหนังสือพิมพ์
                                                                </div>
                                                            </>
                                                        ) : (
                                                            ''
                                                        )
                                                    ) : !magazineValue &&
                                                      checkingError[
                                                          'magazine'
                                                      ] ? (
                                                        <>
                                                            <div className="is-invalid"></div>
                                                            <div className="invalid-feedback">
                                                                กรุณาเลือกนิตยสาร
                                                            </div>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                            <h3 className="mt">
                                                วันที่เริ่ม - วันที่สิ้นสุด
                                            </h3>
                                            <div className="row">
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        gap: '1rem',
                                                        width: '100%'
                                                    }}
                                                >
                                                    <DatePicker
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                        format={'DD-MM-YYYY'}
                                                        renderExtraFooter={() =>
                                                            todayDatePickerStart(
                                                                setStartDate
                                                            )
                                                        }
                                                        onChange={dates => {
                                                            if (dates !== null)
                                                                setStartDate(
                                                                    dates
                                                                        .startOf(
                                                                            'day'
                                                                        )
                                                                        .unix()
                                                                );
                                                            else
                                                                setStartDate(
                                                                    null
                                                                );
                                                        }}
                                                        value={
                                                            startDate !== null
                                                                ? moment.unix(
                                                                      startDate
                                                                  )
                                                                : null
                                                        }
                                                        placeholder="วันที่เริ่ม"
                                                        disabledDate={current => {
                                                            if (endDate) {
                                                                return disabledDateAfterExpire(
                                                                    current,
                                                                    endDate
                                                                );
                                                            } else {
                                                                return false;
                                                            }
                                                        }}
                                                    />
                                                    <DatePicker
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                        format={'DD-MM-YYYY'}
                                                        renderExtraFooter={() =>
                                                            todayDatePickerEnd(
                                                                setEndDate
                                                            )
                                                        }
                                                        onChange={dates => {
                                                            if (dates !== null)
                                                                setEndDate(
                                                                    dates
                                                                        .endOf(
                                                                            'day'
                                                                        )
                                                                        .unix()
                                                                );
                                                            else
                                                                setEndDate(
                                                                    null
                                                                );
                                                        }}
                                                        placeholder="วันที่สิ้นสุด"
                                                        value={
                                                            endDate !== null
                                                                ? moment.unix(
                                                                      endDate
                                                                  )
                                                                : null
                                                        }
                                                        disabledDate={current => {
                                                            if (startDate) {
                                                                return disabledDateBeforeStart(
                                                                    current,
                                                                    startDate
                                                                );
                                                            } else {
                                                                return false;
                                                            }
                                                        }}
                                                    />
                                                    {checkingError[
                                                        'datePicker'
                                                    ] && (
                                                        // || soLittleTime
                                                        <div className="invalid-feedback">
                                                            {
                                                                // soLittleTime
                                                                // ? "กรุณากรอกช่วงเวลา 30 วันขึ้นไป"
                                                                // :
                                                                'กรุณากรอกวันที่เริ่มใช้งานและวันที่หมดอายุ'
                                                            }
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <h3 className="mt">
                                                จัดการสิทธิ์การยืมรายเล่ม
                                                (concurrent)
                                            </h3>
                                            <div className="row">
                                                <div className={`col-12 input`}>
                                                    <Select
                                                        name="concurrent"
                                                        withFeedbackLabel={
                                                            false
                                                        }
                                                        label="จำนวนสูงสุดในการยืม"
                                                        type="number"
                                                        onChange={
                                                            handleConcurrentChange
                                                        }
                                                        value={concurrentValue}
                                                        className={`form-control selectDropdown`}
                                                        style={{
                                                            backgroundImage: `url(
                                                                                "data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"
                                                                           )`
                                                        }}
                                                    >
                                                        {concurrentList}
                                                    </Select>
                                                    {checkingError[
                                                        'concurrent'
                                                    ] && !concurrentValue ? (
                                                        <>
                                                            <div className="is-invalid"></div>
                                                            <div className="invalid-feedback">
                                                                กรุณาเลือกจำนวน
                                                            </div>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                            <h3 className="mt">
                                                จัดการเวลาสูงสุดในการยืมรายเล่ม
                                            </h3>
                                            <div className="row">
                                                <div className={`col-12 input`}>
                                                    <Select
                                                        name="rentalLimit"
                                                        withFeedbackLabel={
                                                            false
                                                        }
                                                        label="เวลาสูงสุดในการยืม"
                                                        onChange={
                                                            handleRentalChange
                                                        }
                                                        value={rentalValue}
                                                        className={`form-control selectDropdown`}
                                                        style={{
                                                            backgroundImage: `url(
                                                                                "data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"
                                                                           )`
                                                        }}
                                                    >
                                                        {rentalList}
                                                    </Select>
                                                    {checkingError['rental'] &&
                                                    !rentalValue ? (
                                                        <>
                                                            <div className="is-invalid"></div>
                                                            <div className="invalid-feedback">
                                                                กรุณาเลือกจำนวน
                                                            </div>
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>

                                            <h3 className="mt">รอบจ่ายบิล</h3>

                                            <DatePicker
                                                format={'DD-MM-YYYY '}
                                                renderExtraFooter={() =>
                                                    todayDatePickerStart(
                                                        setPaymentDate
                                                    )
                                                }
                                                onChange={dates => {
                                                    if (dates !== null)
                                                        setPaymentDate(
                                                            dates
                                                                .tz(
                                                                    'Asia/Bangkok'
                                                                )
                                                                .unix()
                                                        );
                                                    else setPaymentDate(null);
                                                }}
                                                value={
                                                    paymentDate !== null
                                                        ? moment.unix(
                                                              paymentDate
                                                          )
                                                        : null
                                                }
                                                placeholder="รอบจ่ายบิล"
                                            />

                                            {modalEditData ? (
                                                <div className="divButton ">
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setModalEditData(
                                                                false
                                                            );
                                                            setModalEditUID(
                                                                null
                                                            );
                                                        }}
                                                        className="denyButton"
                                                    >
                                                        ยกเลิก
                                                    </button>
                                                    <button
                                                        className="acceptButton"
                                                        type="submit"
                                                        onClick={() => {
                                                            setCheckingError({
                                                                newspaper: true,
                                                                magazine: true,
                                                                datePicker: true,
                                                                rental: true,
                                                                concurrent: true
                                                            });
                                                        }}
                                                    >
                                                        ยืนยัน
                                                    </button>
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    <button
                                                        className="acceptButton"
                                                        type="submit"
                                                        onClick={() => {
                                                            setCheckingError({
                                                                newspaper: true,
                                                                magazine: true,
                                                                datePicker: true,
                                                                rental: true,
                                                                concurrent: true
                                                            });
                                                        }}
                                                    >
                                                        ยืนยัน
                                                    </button>
                                                </div>
                                            )}
                                        </Form>
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    );
                }}
            </Formik>
            <ModalsSuccess
                show={successModal}
                onHide={() => onHide()}
                onClickAccept={() => {
                    onHide();
                    history.push({
                        pathname: '/subscription'
                    });
                    if (setModalEditData && setModalEditUID) {
                        setModalEditData(false);
                        setModalEditUID(false);
                    }
                }}
            />
        </>
    );
}
