import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./BannersUIHelpers";

const BannersUIContext = createContext();

export function useBannersUIContext() {
  return useContext(BannersUIContext);
}

export const BannersUIConsumer = BannersUIContext.Consumer;

export function BannersUIProvider({ bannersUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initBanner = {
    id: undefined,
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    gender: "Female",
    status: 0,
    dateOfBbirth: "",
    ipAddress: "",
    type: 1,
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initBanner,
    newBannerButtonClick: bannersUIEvents.newBannerButtonClick,
    openEditBannerDialog: bannersUIEvents.openEditBannerDialog,
    openDeleteBannerDialog: bannersUIEvents.openDeleteBannerDialog,
    openBlockBannerDialog: bannersUIEvents.openBlockBannerDialog,
    openUnblockBannerDialog: bannersUIEvents.openUnblockBannerDialog,
    openDeleteBannersDialog: bannersUIEvents.openDeleteBannersDialog,
    openFetchBannersDialog: bannersUIEvents.openFetchBannersDialog,
    openUpdateBannersStatusDialog: bannersUIEvents.openUpdateBannersStatusDialog,
  };

  return <BannersUIContext.Provider value={value}>{children}</BannersUIContext.Provider>;
}
