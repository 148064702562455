import { createSlice } from '@reduxjs/toolkit';

const initialCataloguesState = {
   listLoading: false,
   actionsLoading: false,
   actionsLoading_catalogueForEdit: false,
   totalCount: 0,
   entities: [],
   entities_hti: [],
   entities_publishers: undefined,
   entities_newEbooks: undefined,
   entities_categories: undefined,
   entities_search: undefined,
   catalogueForEdit: undefined,
   publisherContents: undefined,
   categoryContents: undefined,
   LastEvaluatedKey_publisherContents: undefined,
   LastEvaluatedKey_categoryContents: undefined,
   LastEvaluatedKey_newEbooksContents: undefined,
   lastError: null,
   LastEvaluatedKey: {},
   LastEvaluatedKey_hti: {},
   LastEvaluatedKey_free: {},
   LastEvaluatedKey_search: {},
   remainConcurrent: undefined,
   memberMode: 0,
   LastEvaluatedKey_catalogueForEdit: undefined,
   catName: undefined,
   publisherName: undefined,
   subcats: undefined,
   subcatName: undefined,
   contentAmount: undefined,
   catalogueName: undefined,
   catalogue_contentAmount: undefined,
   voteStatus: undefined,
   voteStartDate: undefined,
   voteEndDate: undefined
};
export const callTypes = {
   list: 'list',
   action: 'action',
   action_catalogueForEdit: 'action_catalogueForEdit'
};

export const catalogueSlice = createSlice({
   name: 'catalogues',
   initialState: initialCataloguesState,
   reducers: {
      catchError: (state, action) => {
         if (
            action.payload.error &&
            action.payload.error.response &&
            action.payload.error.response.data
         ) {
            state.error = action.payload.error.response.data.errorCode;
         }
         if (action.payload.callType === callTypes.list) {
            state.listLoading = false;
         } else if (
            action.payload.callType === callTypes.action_catalogueForEdit
         ) {
            state.actionsLoading_catalogueForEdit = false;
         } else {
            state.actionsLoading = false;
         }
      },
      startCall: (state, action) => {
         state.error = null;
         if (action.payload.callType === callTypes.list) {
            state.listLoading = true;
         } else if (
            action.payload.callType === callTypes.action_catalogueForEdit
         ) {
            state.actionsLoading_catalogueForEdit = true;
         } else {
            state.actionsLoading = true;
         }
      },
      // getCatalogueById
      catalogueFetched: (state, action) => {
         state.memberMode = action.payload.memberMode;
         if (action.payload.lastEvaluated_catalogueForEdit === undefined) {
            state.catalogueForEdit = action.payload.catalogueForEdit;
            state.LastEvaluatedKey_catalogueForEdit =
               action.payload.LastEvaluatedKey_catalogueForEdit;
            state.catalogueName = state.catalogueName
               ? state.catalogueName
               : action.payload.catalogueForEdit
               ? action.payload.catalogueForEdit.title
               : undefined;
            state.voteStatus = action.payload.catalogueForEdit.voteStatus;
            state.voteStartDate = action.payload.catalogueForEdit.voteStartDate;
            state.voteEndDate = action.payload.catalogueForEdit.voteEndDate;
         } else {
            if (
               action.payload.catalogueForEdit &&
               state.catalogueForEdit &&
               Array.isArray(state.catalogueForEdit.contents) &&
               JSON.stringify(
                  action.payload.LastEvaluatedKey_catalogueForEdit
               ) !== JSON.stringify(state.LastEvaluatedKey_catalogueForEdit)
            ) {
               action.payload.catalogueForEdit.contents.forEach(item =>
                  state.catalogueForEdit.contents.push(item)
               );
               state.LastEvaluatedKey_catalogueForEdit =
                  action.payload.LastEvaluatedKey_catalogueForEdit;
            }
         }
         state.error = null;
         state.actionsLoading_catalogueForEdit = false;
      },
      contentsFetched: (state, action) => {
         if (action.payload.type == 'publisher') {
            if (action.payload.lastEvaluated_publisherContents === undefined) {
               state.categoryContents = undefined;
               state.newEbooksContents = undefined;
               state.catName = undefined;
               state.subcats = undefined;
               state.subcatName = undefined;
               state.contentAmount = action.payload.contentAmount;
               state.publisherName = action.payload.publisherName;
               state.publisherContents = action.payload.publisherContents;
            } else {
               action.payload.publisherContents.forEach(item =>
                  state.publisherContents.push(item)
               );
            }
            state.LastEvaluatedKey_publisherContents =
               action.payload.LastEvaluatedKey_publisherContents;
         }
         if (action.payload.type == 'newEbooks') {
            if (action.payload.lastEvaluated_newEbooksContents === undefined) {
               state.publisherName = undefined;
               state.publisherContents = undefined;
               state.categoryContents = undefined;
               state.catName = undefined;
               state.subcats = undefined;
               state.subcatName = undefined;
               state.contentAmount = undefined;
               state.newEbooksContents = action.payload.newEbooksContents;
            } else {
               action.payload.newEbooksContents.forEach(item =>
                  state.newEbooksContents.push(item)
               );
            }
            state.LastEvaluatedKey_newEbooksContents =
               action.payload.LastEvaluatedKey_newEbooksContents;
         }
         if (action.payload.type == 'category') {
            if (action.payload.lastEvaluated_categoryContents === undefined) {
               state.categoryContents = action.payload.categoryContents;
               state.catName = action.payload.catName;
               state.subcats = action.payload.subcats ?? state.subcats;
               state.subcatName = action.payload.subcatName;
               state.contentAmount = action.payload.contentAmount;
               state.publisherName = undefined;
               state.publisherContents = undefined;
               state.newEbooksContents = undefined;
            } else {
               action.payload.categoryContents.forEach(item =>
                  state.categoryContents.push(item)
               );
            }
            state.LastEvaluatedKey_categoryContents =
               action.payload.LastEvaluatedKey_categoryContents;
         }
         state.error = null;
         state.actionsLoading = false;
      },
      // findCatalogues
      setVoteStatus: (state, action) => {
         console.log(action.payload);
         const { voteStatus } = action.payload;
         state.voteStatus = voteStatus;
      },
      cataloguesFetched: (state, action) => {
         const {
            totalCount,
            entities,
            LastEvaluatedKey,
            lastEvaluate,
            type,
            memberMode,
            remainConcurrent
         } = action.payload;
         const blockedOrNormal_Private = e_status => (e_status === 1 ? 1 : 0);
         state.listLoading = false;
         state.error = null;
         state.entities_categories = undefined;
         state.entities_publishers = undefined;
         state.entities_newEbooks = undefined;
         state.subcats = undefined;
         state.remainConcurrent = remainConcurrent;
         if (type === 'catalogues') {
            state.catalogueForEdit = undefined;
            state.catalogueName = undefined;
            if (lastEvaluate === undefined) {
               if (memberMode) {
                  state.entities = entities.filter(
                     e => e.status === parseInt(memberMode, 10) - 1
                  );
               } else {
                  state.entities = entities;
               }
            } else {
               if (memberMode) {
                  entities
                     .filter(e => e.status === parseInt(memberMode, 10) - 1)
                     .forEach(item => state.entities.push(item));
               } else {
                  entities.forEach(item => state.entities.push(item));
               }
            }
         } else {
            if (type === 'publishers') {
               state.publisherContents = undefined;
               state.LastEvaluatedKey_publisherContents = undefined;
               if (lastEvaluate === undefined) {
                  state.entities_publishers = entities;
               } else {
                  entities.forEach(item =>
                     state.entities_publishers.push(item)
                  );
               }
            } else if (type === 'categories') {
               state.categoryContents = undefined;
               state.LastEvaluatedKey_categoryContents = undefined;
               if (lastEvaluate === undefined) {
                  state.entities_categories = entities;
               } else {
                  entities.forEach(item =>
                     state.entities_categories.push(item)
                  );
               }
            } else {
               if (lastEvaluate === undefined) {
                  if (memberMode) {
                     state.entities_hti = entities.filter(
                        e => e.status === parseInt(memberMode, 10)
                     );
                  } else {
                     state.entities_hti = entities;
                  }
               } else {
                  if (memberMode) {
                     entities
                        .filter(e => e.status === parseInt(memberMode, 10))
                        .forEach(item => state.entities_hti.push(item));
                  } else {
                     entities.forEach(item => state.entities_hti.push(item));
                  }
               }
            }
         }
         state.totalCount = totalCount;
         if (type === 'isFree') {
            state.LastEvaluatedKey_free = LastEvaluatedKey;
         } else if (type === 'catalogues') {
            state.LastEvaluatedKey = LastEvaluatedKey;
         } else {
            state.LastEvaluatedKey_hti = LastEvaluatedKey;
         }
         // state.entities = mockUsers;
         // state.totalCount = 17;
      },
      // createCatalogue
      catalogueCreated: (state, action) => {
         state.actionsLoading = false;
         state.error = null;
         state.entities.push(action.payload.catalogue);
      },
      // updateCatalogue
      catalogueUpdated: (state, action) => {
         // console.log(action.payload.id, action.payload.catalogue);
         state.error = null;
         state.actionsLoading = false;
         state.entities = state.entities.map(entity => {
            if (entity.contentUID === action.payload.id) {
               // console.log(action.payload.catalogue);
               // delete action.payload.catalogue.coverImageeee;
               return Object.assign({}, entity, action.payload.catalogue);
            }
            return entity;
         });
      },
      // deleteCatalogue
      catalogueDeleted: (state, action) => {
         state.error = null;
         state.actionsLoading = false;
         state.entities = state.entities.filter(
            el => el.uid !== action.payload.id
         );
      },
      // blockCatalogue
      catalogueBlocked: (state, action) => {
         state.error = null;
         state.actionsLoading = false;
         state.entities = state.entities.map(entity => {
            if (entity.contentUID === action.payload.id) {
               return Object.assign({}, entity, { status: 2 });
            }
            return entity;
         });
      },
      // unblockCatalogue
      catalogueUnblocked: (state, action) => {
         state.error = null;
         state.actionsLoading = false;
         state.entities = state.entities.map(entity => {
            if (entity.contentUID === action.payload.id) {
               return Object.assign({}, entity, { status: 1 });
            }
            return entity;
         });
      },
      // catalogueUpdateState
      catalogueStatusUpdated: (state, action) => {
         state.actionsLoading = false;
         state.error = null;
         const { ids, status } = action.payload;
         state.entities = state.entities.map(entity => {
            if (ids.findIndex(id => id === entity.id) > -1) {
               entity.status = status;
            }
            return entity;
         });
      },
      elementUpdated: (state, action) => {
         state.actionsLoading = false;
      },
      clearContents: (state, action) => {
         state.actionsLoading = false;
         state.publisherContents = undefined;
         state.newEbooksContents = undefined;
         state.categoryContents = undefined;
         state.publisherName = undefined;
         state.catName = undefined;
         state.subcatName = undefined;
         state.contentAmount = undefined;
         state.LastEvaluatedKey_publisherContents = undefined;
         state.LastEvaluatedKey_newEbooksContents = undefined;
         state.LastEvaluatedKey_categoryContents = undefined;
         state.entities_publishers = undefined;
         state.entities_categories = undefined;
      },
      allContentAmountFetched: (state, action) => {
         state.catalogue_contentAmount = action.payload.catalogue_contentAmount;
      },
      searched: (state, action) => {
         state.LastEvaluatedKey_search = action.payload.LastEvaluatedKey_search;

         if (state.entities_search !== undefined) {
            state.entities_search = state.entities_search.concat(
               action.payload.contents
            );
         } else {
            state.entities_search = action.payload.contents;
         }
         state.actionsLoading = false;
      },
      resetSearch: state => {
         state.entities_search = undefined;
         state.LastEvaluatedKey_search = {};
      }
   }
});
