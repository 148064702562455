import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { LoadingDialog } from '../../../../../../_metronic/_partials/controls';

export function EbooksLoadingDialog() {
   // Ebooks Redux state
   const { isLoading } = useSelector(
      state => ({ isLoading: state.ebooks.listLoading }),
      shallowEqual
   );
   // looking for loading/dispatch
   useEffect(() => {}, [isLoading]);
   useEffect(() => {
      console.log('loader');
   }, [isLoading]);
   return <LoadingDialog isLoading={isLoading} text="Loading ..." />;
}
