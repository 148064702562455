import axios from "axios";
import { unstable_renderSubtreeIntoContainer } from "react-dom";
import {
  customerForCreation,
  LastEvaluatedKey_membersData,
  customerForEdit,
  HtiResponse,
  Member,
  memberbyid,
  MemberBanned,
  UnBanPayload
} from "../../../../../interface";
import { customersUIPropsQueryParams } from "../../../../../interface/request";
// export const CUSTOMERS_URL = "api/customers";
export const CUSTOMERS_URL = `/admin/members`;
export const REGISTER_CUSTOMERS_URL = `/admin/register`;
export const REGISTER_ADMIN_URL = `/superuser/members/create`;
export const MEMVER_BANNED = `/superuser/members/baned`

// CREATE =>  POST: add a new customer to the server
export function createCustomer(customer: customerForCreation) {
 
    return axios.post(REGISTER_CUSTOMERS_URL, { ...customer });
  
}
export function getMemberBanned(){
   return axios.get<HtiResponse<MemberBanned>>(MEMVER_BANNED);
}
// READ
export function getAllCustomers() {
  return axios.get<HtiResponse<Member>>(CUSTOMERS_URL);
}

export function getCustomerById(customerId: string) {
  return axios.get<HtiResponse<memberbyid>>(`${CUSTOMERS_URL}/${customerId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCustomers(
  queryParams: customersUIPropsQueryParams,
  lastEvaluate: LastEvaluatedKey_membersData
) {
  let status = "";
  let limit = "";
  let lastKeyQuery = "";
  if (
    lastEvaluate !== undefined &&
    JSON.stringify(lastEvaluate) !== JSON.stringify({})
  ) {
    // lastKeyQuery = "&lastEvaluatedKey=" + btoa(JSON.stringify(lastEvaluate));
    lastKeyQuery = "&lastEvaluatedKey=" + btoa(JSON.stringify(lastEvaluate));
  }
  if (queryParams && queryParams.pageSize) {
    limit = `?limit=${queryParams.pageSize}`;
  }
  if (!queryParams.keyword) {
    if (queryParams.memberMode) {
      status = `&status=${queryParams.memberMode}`;
    }
    return axios.get<HtiResponse<Member>>(`${CUSTOMERS_URL}${limit}${lastKeyQuery}${status}`);
  } else {
    return axios.post(`${CUSTOMERS_URL}/search`, {
      keyword: queryParams.keyword,
      status: queryParams.memberMode ? queryParams.memberMode : undefined,
    });
  }
}

// UPDATE => PUT: update the customer on the server
export function updateCustomer(customer: customerForEdit, id: string) {
  if (customer.memberUID) delete customer.memberUID;
  console.log(JSON.stringify(customer));
  // console.log(customer, id);
  return axios.put(`${CUSTOMERS_URL}/${id}`, customer); //{ customer });
}

// DELETE => delete the customer from the server
export function deleteCustomer(customerId: string) {
  return axios.delete(`${CUSTOMERS_URL}/${customerId}`);
}

export function blockCustomer(customerId: string) {
  return axios.patch(`${CUSTOMERS_URL}/${customerId}/block`);
}

export function resetPwCustomer(customerId: string) {
  return axios.patch(`${CUSTOMERS_URL}/${customerId}/block`);
}
export function unBanMember(unBanList:UnBanPayload){
  return axios.patch(`${MEMVER_BANNED}`,unBanList)
}

// DELETE Customers by ids
// export function deleteCustomers(ids) {
//   return axios.post(`${CUSTOMERS_URL}/deleteCustomers`, { ids });
// }
