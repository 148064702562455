import React, { useEffect, useState } from "react";
import { PublishersLoadingDialog } from "./publishers-loading-dialog/PublishersLoadingDialog";
import { PublisherNewDialog } from "./publisher-new-dialog/PublisherNewDialog";
import { PublisherEditDialog } from "./publisher-edit-dialog/PublisherEditDialog";
import { PublisherDeleteDialog } from "./publisher-delete-dialog/PublisherDeleteDialog";
import { PublisherBlockDialog } from "./publisher-block-dialog/PublisherBlockDialog";
import { PublisherUnblockDialog } from "./publisher-unblock-dialog/PublisherUnblockDialog";
import { PublishersUIProvider } from "./PublishersUIContext";
import { PublishersCard } from "./PublishersCard";
import queryString from "query-string";

export function AuthorsPage(props) {
  const { history, location } = props;
  const [isNewPublisher, setNewPublisher] = useState(0);
  const [isEditAuthor, setEditAuthor] = useState(0);
  const [isDeleteAuthor, setDeleteAuthor] = useState(0);
  const [isBlockAuthor, setBlockAuthor] = useState(0);
  const [isUnblockAuthor, setUnblockAuthor] = useState(0);
  const [isNewAuthor, setNewAuthor] = useState(0);

  const [publisherName, setPublisherName] = useState(null);
  const [publisherId, setPublisherId] = useState(null);
  const [authorName, setAuthorName] = useState(null);
  const [authorId, setAuthorId] = useState(null);

  const [isPrivate, setIsPrivate] = useState(0);
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.newPublisher) {
      setNewPublisher(1);
    }
    if (parsed.newAuthor) {
      setNewAuthor(1);
    }
    if (parsed.editAuthor) {
      setEditAuthor(1);
    }
    if (parsed.deleteAuthor) {
      setDeleteAuthor(1);
    }
    if (parsed.blockAuthor) {
      setBlockAuthor(1);
    }
    if (parsed.unblockAuthor) {
      setUnblockAuthor(1);
    }
    //------------------------------
    if (parsed.publisherName) {
      setPublisherName(parsed.publisherName);
    } else {
      setPublisherName(null);
    }
    if (parsed.publisherId) {
      setPublisherId(parsed.publisherId);
    } else {
      setPublisherId(null);
    }
    if (parsed.authorName) {
      setAuthorName(parsed.authorName);
    } else {
      setAuthorName(null);
    }
    if (parsed.authorId) {
      setAuthorId(parsed.authorId);
    } else {
      setAuthorId(null);
    }
    //------------------------------
    if (parsed.isPrivate) {
      setIsPrivate(parseInt(parsed.isPrivate, 10));
    }
  }, [location.search]);
  const publishersUIEvents = {
    newPublisherButtonClick: (isPrivate, publisherId, publisherName) => {
      history.push(
        `/authors?isPrivate=${isPrivate}&publisherId=${publisherId}&publisherName=${publisherName}&newPublisher=true`
      );
    },
    newAuthorButtonClick: (isPrivate, publisherId, publisherName) => {
      history.push(
        `/authors?isPrivate=${isPrivate}&publisherId=${publisherId}&publisherName=${publisherName}&newAuthor=true`
      );
    },
    openEditAuthorDialog: (name, publisherId, authorId, isPrivate, publisherName) => {
      history.push(
        `/authors?isPrivate=${isPrivate}&publisherId=${publisherId}&publisherName=${publisherName}&authorId=${authorId}&authorName=${name}&editAuthor=true`
      );
    },
    openDeleteAuthorDialog: (name, publisherId, authorId, isPrivate, publisherName) => {
      history.push(
        `/authors?isPrivate=${isPrivate}&publisherId=${publisherId}&publisherName=${publisherName}&authorId=${authorId}&authorName=${name}&deleteAuthor=true`
      );
    },
    openBlockAuthorDialog: (name, publisherId, authorId, isPrivate, publisherName) => {
      history.push(
        `/authors?isPrivate=${isPrivate}&publisherId=${publisherId}&publisherName=${publisherName}&authorId=${authorId}&authorName=${name}&blockAuthor=true`
      );
    },
    openUnblockAuthorDialog: (name, publisherId, authorId, isPrivate, publisherName) => {
      history.push(
        `/authors?isPrivate=${isPrivate}&publisherId=${publisherId}&publisherName=${publisherName}&authorId=${authorId}&authorName=${name}&unblockAuthor=true`
      );
    },
    openAuthorsPage: (id, name) => {
      history.push(`/publishers/${id}/${encodeURIComponent(name)}/authors`);
    },
    openAuthorBooksPage: (type) => {
      history.push(`/publishers/${type}/sub/books`);
    },
  };

  return (
    <PublishersUIProvider publishersUIEvents={publishersUIEvents}>
      <PublishersLoadingDialog />
      <PublishersCard
        isAuthor={true}
        id={publisherId}
        name={publisherName}
        onHide={() => {
          history.push(`/publishers`);
        }}
      />
      {isNewPublisher ? (
        <PublisherNewDialog
          show={true}
          onHide={() => {
            history.push(`/authors?publisherId=${publisherId}&publisherName=${publisherName}`);
            setNewPublisher(0);
          }}
          onSuccess={() => {
            history.push(`/publishers`);
            setNewPublisher(0);
          }}
          isPrivate={isPrivate}
        />
      ) : (
        ""
      )}
      {isNewAuthor ? (
        <PublisherNewDialog
          show={true}
          onHide={() => {
            history.push(`/authors?publisherId=${publisherId}&publisherName=${publisherName}`);
            setNewAuthor(0);
          }}
          onSuccess={() => {
            setNewAuthor(0);
          }}
          isPrivate={isPrivate}
          isAuthor={true}
          history={history}
        />
      ) : (
        ""
      )}
      {isEditAuthor ? (
        <PublisherEditDialog
          show={true}
          publisherId={publisherId}
          authorId={authorId}
          name={authorName}
          isPrivate={isPrivate}
          onHide={() => {
            history.push(`/authors?publisherId=${publisherId}&publisherName=${publisherName}`);
            setEditAuthor(0);
          }}
        />
      ) : (
        ""
      )}
      {isDeleteAuthor ? (
        <PublisherDeleteDialog
          show={true}
          publisherId={publisherId}
          authorId={authorId}
          name={authorName}
          isPrivate={isPrivate}
          onHide={() => {
            history.push(`/authors?publisherId=${publisherId}&publisherName=${publisherName}`);
            setDeleteAuthor(0);
          }}
        />
      ) : (
        ""
      )}
      {isBlockAuthor ? (
        <PublisherBlockDialog
          show={true}
          publisherId={publisherId}
          authorId={authorId}
          name={authorName}
          isPrivate={isPrivate}
          onHide={() => {
            history.push(`/authors?publisherId=${publisherId}&publisherName=${publisherName}`);
            setBlockAuthor(0);
          }}
        />
      ) : (
        ""
      )}
      {isUnblockAuthor ? (
        <PublisherUnblockDialog
          show={true}
          publisherId={publisherId}
          authorId={authorId}
          name={authorName}
          isPrivate={isPrivate}
          onHide={() => {
            history.push(`/authors?publisherId=${publisherId}&publisherName=${publisherName}`);
            setUnblockAuthor(0);
          }}
        />
      ) : (
        ""
      )}
    </PublishersUIProvider>
  );
}
