import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Modal } from "react-bootstrap";
function SuccessModal({ show, onHide }) {
    const useStyles = makeStyles(() => ({
        body: { margin: "auto" },
        divSuccess: {
             textAlign: "center",
             width: "340px",
             height: "360px",
             borderRadius: "10px",
             backgroundColor: "#fff",
        },
        divText: { fontSize: "24px", fontWeight: "600", color: "#012875" },
        button: {
             marginTop: "32px",
             fontSize: "17px",
             fontWeight: "600",
             width: "200px",
             height: "48px",
             borderRadius: "100px",
             backgroundColor: "#012875",
             border: "none",
             color: "white",
        },
    }));
    const styles = useStyles();
    return (
        <div>
            <Modal
               backdrop="static"
               size="md"
               show={show}
               onHide={!show}
               style={{ marginTop: "27px" }}
               aria-labelledby="example-modal-sizes-title-md"
               dialogClassName={ styles.divSuccess}
          >
              
                    <Modal.Body className={styles.body}>
                         <div>
                              <img src="/media/bg/success.svg" alt="" />
                         </div>
                         <div className="divText-modal">เรียบร้อยแล้ว</div>
                         <div>
                              <button
                                   className="acceptButton "
                                   onClick={() => {
                                        onHide();
                                      
                                   }}
                              >
                                   ตกลง
                              </button>
                         </div>
                    </Modal.Body>
               
          </Modal>
        </div>
    )
}

export default SuccessModal
