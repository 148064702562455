import React, { useState, useEffect } from 'react';
import { Card, CardBody } from '../../../../../_metronic/_partials/controls';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { DatePicker, Empty } from 'antd';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { deleteReports } from '../../_redux/reports/reportsCrud';

import {
   // disabledDateAfterExpire,
   disabledDateOnDateNow,
   disabledDateOnStartDate
   // disabledDateBeforeStart,
   // todayDatePickerStart,
   // todayDatePickerEnd
} from '../../../../utility';
import SVG from 'react-inlinesvg';
import { ReportDeleteDialog } from './report-delete-dialog/ReportDeleteDialog';
import { useHistory } from 'react-router-dom';

export function ReportsCard() {
   const useStyles1 = makeStyles(theme => ({
      row: { display: 'block', justifyContent: 'center' },
      col: { margin: '1.5rem auto 0' },
      p: { marginBottom: '8px', fontSize: '14px' },
      select: {
         width: '100%',
         borderRadius: '4px',
         border: '1px solid #E5EAEE',
         display: 'block',
         height: 'calc(1.5em + 1.3rem + 2px)',
         padding: '0.65rem 1rem',
         fontSize: '1rem',
         fontWeight: '400',
         lineHeight: '1.5',
         color: '#464E5F',
         backgroundColor: '#ffffff',
         backgroundClip: 'padding-box',
         boxShadow: 'none',
         transition:
            'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
      },
      center: {
         textAlign: 'center'
      },
      body: { margin: 'auto' }
   }));
   const classes = useStyles1();
   const [period, setPeriod] = useState(undefined);
   const [report, setReport] = useState(undefined);
   const [clientActive, setClientActive] = useState();
   const [requestList, setRequestList] = useState([]);
   const [periodPresets, setPeriodPresets] = useState([]);
   const [reportPresets, setReportPresets] = useState([]);
   const [startDate, setStartDate] = useState(null);
   const [endDate, setEndDate] = useState(null);
   const [isClickedRangePicker, setIsClickedRangePicker] = useState(false);
   const [successModal, setSuccessModal] = useState(false);
   const [waitingButton, setWaitingButton] = useState(false);
   const [removeUidList, setRemoveUidList] = useState();
   const { viewAsToken } = useSelector(state => state.auth);

   const reportNotCustomPeriod = ['qrcodeLicense', 'qrcodeContent'];
   const reportNotCustomStartEndDate = ['qrcodeLicense', 'qrcodeContent'];
   const history = useHistory();
   const handlePeriodChange = e => {
      setIsClickedRangePicker(false);
      setPeriod(e.target.value);
   };
   const handleReportChange = e => {
      setReport(e.target.value);

      setStartDate(null);
      setEndDate(null);
      if (reportNotCustomPeriod.includes(e.target.value)) {
         setPeriod('Custom');
         setStartDate(clientActive.startDate);
         setEndDate(clientActive.expireDate);
      }
   };
   const loaderHeight = 50;

   const onDurationChange = (dates, dateStrings) => {
      if (dates) {
         setIsClickedRangePicker(true);
         setStartDate(dates[0].unix());
         setEndDate(dates[1].unix());
      } else {
         setStartDate(null);
         setEndDate(null);
      }
   };

   useEffect(() => {
      reqFunc();
   }, []);

   const reqFunc = async () => {
      const req = await axios.get('/admin/reports');
      if (req.data.status && req.data.data) {
         const obj = req.data.data;
         if (obj.period) {
            setPeriodPresets(
               obj.period.map((el, index) => {
                  if (index === 0) {
                     setPeriod(el.period);
                  }
                  return (
                     <option key={index} value={el.period}>
                        {el.title}
                     </option>
                  );
               })
            );
         }
         if (obj.reportRequest) {
            setReportPresets(
               obj.reportRequest.map((el, index) => {
                  if (index === 0) {
                     setReport(el.type);
                  }
                  return (
                     <option key={index} value={el.type}>
                        {el.title}
                     </option>
                  );
               })
            );
         }
         if (obj.requestedReport && obj.requestedReport.reports) {
            setRequestList(obj.requestedReport.reports);
         }
         if (obj.client) {
            setClientActive(obj.client);
         }
      }
   };

   const pushRequest = async () => {
      const momentNow = moment().unix();
      var reportPostObj = {};
      if (period === 'Custom') {
         if (startDate && endDate) {
            reportPostObj = {
               reportType: report,
               registerDate: momentNow,
               startDate: startDate,
               endDate: endDate,
               period: period,
               status: 1
            };
         }
      } else {
         reportPostObj = {
            reportType: report,
            registerDate: momentNow,
            period: period ? parseInt(period, 10) : undefined,
            status: 1
         };
      }
      if (reportPostObj.reportType) {
         setWaitingButton(true);
         try {
            const req = await axios.post('/admin/reports', reportPostObj);
            if (req.data.status) {
               setSuccessModal(true);
               setWaitingButton(false);
               reqFunc();
            }
         } catch (e) {
            setWaitingButton(false);
         }
      }
      // setRequestList([
      //   ...requestList,
      //   {
      //     date: "29/09/2020 11:39",
      //     email: "supattrasupattra@hytexts.com",
      //     type: "วิดีโอ",
      //     duration: "90 วัน",
      //     status: "wait",
      //   },
      // ]);
   };

   const downloadReport = async uid => {
      if (uid) {
         const res = await axios.post(`/admin/reports/${uid}/download`);
         if (res.data && res.data.data && res.data.data.fulfillmentUrl) {
            let link = document.createElement('a');
            link.setAttribute('download', 'template');
            link.href = res.data.data.fulfillmentUrl;
            document.body.appendChild(link);
            link.click();
            link.remove();
         }
      }
   };

   const onRemoveReport = async uidList => {
      const res = await deleteReports(uidList);
      if (res.data && res.status) {
         setRequestList(prev =>
            prev.filter(el => !uidList.includes(el.reportUID))
         );
      }
      setRemoveUidList(undefined);
   };

   const cardMini = (obj, index) => {
      if (obj) {
         return (
            <div key={index} className={`row cardMini`}>
               {obj.dateStr ? (
                  <div className={`col-2 ${classes.col}`}>
                     <p style={{ marginBottom: '0' }}>
                        {/* {obj.dateStr.split(' ')[0]} */}
                        {obj.dateStr}
                     </p>
                     {/* <p>{obj.dateStr.split(' ')[1]}</p> */}
                  </div>
               ) : null}
               <div
                  className={`col-2 ${classes.col}`}
                  style={{ margin: 'auto 0' }}
               >
                  {obj.email ? obj.email : '-'}
               </div>
               <div
                  className={`col-3 ${classes.col}`}
                  style={{ margin: 'auto 0' }}
               >
                  {obj.description}
               </div>
               {obj.period ? (
                  <div
                     className={`col-3 ${classes.col}`}
                     style={{ margin: 'auto 0' }}
                  >
                     <p style={{ marginBottom: '0' }}>
                        {obj.period.split(' - ')[0]} -{' '}
                        {obj.period.split(' - ')[1]}
                     </p>
                     {/* <p>{obj.period.split(" - ")[1]}</p> */}
                  </div>
               ) : (
                  ''
               )}
               <div
                  className={`col-2 ${classes.col} ${classes.center} action-column`}
                  // style={{
                  //    margin: '1rem auto',
                  //    display: 'flex',
                  //    gap: '16px',
                  //    alignItems: 'center'
                  // }}
               >
                  <button
                     className="miniButton"
                     disabled={obj.status === 0 || obj.status === 2}
                     style={{ margin: '0', width: '100%', maxWidth: '170px' }}
                     onClick={() => downloadReport(obj.reportUID)}
                  >
                     {obj.status === 0
                        ? 'กำลังดำเนินการ...'
                        : obj.status === 2
                        ? 'ไม่มีข้อมูล'
                        : obj.status === 1
                        ? 'ดาวน์โหลดรายงาน'
                        : ''}
                  </button>
                  <SVG
                     src="/media/members/delete-button.svg"
                     width={30}
                     height={25}
                     onClick={() => {
                        console.log('click');
                        history.replace(`?name=${obj.description}`);
                        setRemoveUidList([obj.reportUID]);
                     }}
                  />
               </div>
            </div>
         );
      }
   };

   const onHide = () => {
      setSuccessModal(false);
   };

   return (
      <>
         {viewAsToken ? null : (
            <Card className="cardStyle" style={{ height: 'auto' }}>
               <CardBody>
                  <p className="text-text-head ">การรายงานสถิติการใช้งาน</p>
                  {reportPresets &&
                  periodPresets &&
                  reportPresets.length &&
                  periodPresets.length ? (
                     <>
                        <div className={`row ${classes.row}`}>
                           <div className={`col-8 ${classes.col}`}>
                              <p className={classes.p}>เลือกรายงาน</p>
                              <select
                                 className={classes.select}
                                 onChange={handleReportChange}
                                 value={report}
                              >
                                 {reportPresets}
                              </select>
                           </div>
                           {!reportNotCustomPeriod.includes(report) && (
                              <div className={`col-8 ${classes.col}`}>
                                 <p className={classes.p}>เลือกช่วงเวลา</p>
                                 <select
                                    className={classes.select}
                                    onChange={handlePeriodChange}
                                    value={period}
                                 >
                                    {periodPresets}
                                 </select>
                              </div>
                           )}

                           {period === 'Custom' &&
                           !reportNotCustomStartEndDate.includes(report) ? (
                              <div className={`col-8 ${classes.col}`}>
                                 <p className={classes.p}>
                                    วันเริ่ม - วันสิ้นสุด
                                 </p>
                                 <div className="period-container">
                                    <DatePicker
                                       format={'DD-MM-YYYY'}
                                       onChange={dates => {
                                          if (dates !== null)
                                             setStartDate(
                                                dates.startOf('day').unix()
                                             );
                                          else setStartDate(null);
                                       }}
                                       value={
                                          startDate !== null
                                             ? moment.unix(startDate)
                                             : null
                                       }
                                       placeholder="วันที่เริ่ม"
                                       disabledDate={current =>
                                          disabledDateOnDateNow(
                                             current,
                                             endDate
                                          )
                                       }
                                       allowClear={() => setStartDate(null)}
                                    />
                                    <DatePicker
                                       format={'DD-MM-YYYY'}
                                       onChange={dates => {
                                          if (dates !== null)
                                             setEndDate(
                                                dates.endOf('day').unix()
                                             );
                                          else setEndDate(null);
                                       }}
                                       placeholder="วันที่สิ้นสุด"
                                       value={
                                          endDate !== null
                                             ? moment.unix(endDate)
                                             : null
                                       }
                                       disabledDate={current =>
                                          disabledDateOnStartDate(
                                             current,
                                             startDate
                                          )
                                       }
                                       allowClear={() => setEndDate(undefined)}
                                    />
                                 </div>
                                 {/* <RangePicker
                        defaultValue={
                          startDate && endDate
                            ? [moment.unix(startDate), moment.unix(endDate)]
                            : null
                        }
                        value={
                          startDate && endDate
                            ? [moment.unix(startDate), moment.unix(endDate)]
                            : null
                        }
                        ranges={{
                          วันนี้: [
                            moment().startOf("day"),
                            moment().endOf("day"),
                          ],
                          เดือนนี้: [
                            moment().startOf("month"),
                            moment().endOf("month"),
                          ],
                        }}
                        showTime={{
                          defaultValue: [
                            moment("00:00:00", "HH:mm:ss"),
                            moment("23:59:59", "HH:mm:ss"),
                          ],
                        }}
                        format="DD/MM/YYYY HH:mm:ss"
                        onChange={onDurationChange}
                        placeholder={["วันที่เริ่มใช้งาน", "วันที่หมดอายุ"]}
                        disabledDate={(current) => {
                          // return current.isBetween(moment(), moment().add(1, "hour"));
                          return moment().startOf("day") < current;
                        }}
                        className={
                          (startDate === null || endDate === null) &&
                          isClickedRangePicker
                            ? "form-control is-invalid"
                            : ""
                        }
                        style={{
                          display: "inline-flex",
                          width: "100%",
                          height: "32px",
                          border: "1px solid #767676",
                          borderRadius: "4px",
                        }}
                      /> */}
                                 {isClickedRangePicker && (
                                    <div className="invalid-feedback">
                                       กรุณากรอกวันที่เริ่มใช้งานและวันที่หมดอายุ
                                    </div>
                                 )}
                              </div>
                           ) : null}
                        </div>
                        <div className="divButton">
                           <button
                              className="miniButton"
                              onClick={() => {
                                 pushRequest();
                                 setIsClickedRangePicker(true);
                              }}
                              disabled={waitingButton}
                           >
                              {waitingButton
                                 ? 'โปรดรอสักครู่'
                                 : 'Request Report'}
                           </button>
                        </div>
                     </>
                  ) : (
                     <div
                        style={{
                           minHeight: `${loaderHeight}px`,
                           textAlign: 'center',
                           padding: '20px 0 0 0',
                           width: '100%'
                        }}
                     >
                        <ClipLoader
                           size={loaderHeight}
                           color={'#123abc'}
                           loading={true}
                        />
                     </div>
                  )}
               </CardBody>
            </Card>
         )}
         {requestList && requestList.length > 0 ? (
            <Card
               style={{
                  boxShadow: 'none',
                  background: 'none',
                  marginTop: '4rem'
               }}
            >
               <CardBody style={{ padding: '0' }}>
                  <p className="text-text-head ">
                     ดาวน์โหลดรายงานสถิติการใช้งาน
                  </p>
                  <div className="row" style={{ margin: 'auto 0 1rem' }}>
                     <p className={`col-2 ${classes.col}`}>วันที่สร้าง</p>
                     <p className={`col-2 ${classes.col}`}>Requested By</p>
                     <p className={`col-3 ${classes.col}`}>ประเภทรายงาน</p>
                     <p className={`col-3 ${classes.col}`}>ช่วงเวลา</p>
                     <p className={`col-2 ${classes.col} ${classes.center}`}>
                        สถานะ
                     </p>
                  </div>
                  {requestList.map((e, index) => cardMini(e, index))}
                  {removeUidList !== undefined ? (
                     <ReportDeleteDialog
                        show={removeUidList !== undefined}
                        onOK={() => {
                           onRemoveReport(removeUidList);
                        }}
                        onHide={() => {
                           setRemoveUidList(undefined);
                        }}
                     />
                  ) : null}
               </CardBody>
            </Card>
         ) : viewAsToken ? (
            <Card style={{ boxShadow: 'none', marginTop: '4rem' }}>
               <CardBody style={{ padding: '0' }}>
                  <div style={{ textAlign: 'center', margin: '50px auto' }}>
                     <Empty description={false} />
                     <p
                        style={{
                           color: '#012875',
                           marginBottom: '0',
                           fontSize: '20px',
                           fontWeight: '600'
                        }}
                     >
                        ไม่มีข้อมูลรายงาน
                     </p>
                  </div>
               </CardBody>
            </Card>
         ) : null}
         <Modal
            backdrop="static"
            size="md"
            show={successModal}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-md"
            style={{ marginTop: '27px' }}
            dialogClassName="divSuccess"
         >
            <Modal.Body className={classes.body}>
               <div>
                  <img src="/media/bg/success.svg" alt="" />
               </div>
               <div className="divText-modal">เรียบร้อยแล้ว</div>
               <div className="text-text-gray">
                  เมื่อประมวลผลรายงานเสร็จแล้ว
               </div>
               <div className="text-text-gray">
                  จะได้รับอีเมลเพื่อให้เข้ามาดาวน์โหลดรายงานอีกครั้ง
               </div>
               {/* <div className={classes.subtitle}>60 วินาที</div> */}
               <div>
                  <button
                     className="acceptButton "
                     onClick={() => {
                        onHide();
                     }}
                  >
                     ตกลง
                  </button>
               </div>
            </Modal.Body>
         </Modal>
      </>
   );
}
