// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../../_metronic/_partials/controls";
import Spinner from "../../../../../../_metronic/_helpers/spinner";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
     head: { fontSize: "20px", fontWeight: "600", margin: "16px auto 0", color: "#012875" },
     input: { margin: "12px 0" },
}));

// Validation schema
const PublisherEditSchema = Yup.object().shape({
     name: Yup.string()
          .min(2, "อย่างน้อย 2 ตัวอักษร")
          .max(200, "ไม่เกิน 200 ตัวอักษร")
          .required("กรุณากรอกชื่อ"),
});

export function PublisherEditForm({
     savePublisher,
     saveAuthor,
     publisher,
     actionsLoading,
     onHide,
     errorCode,
     setErrorCode,
     isAuthor,
     publisherList,
}) {
     const styles = useStyles();
     return (
          <>
               <p className={styles.head}>{isAuthor ? "แก้ไขชื่อนักเขียน" : "แก้ไขชื่อสำนักพิมพ์"}</p>
               <Formik
                    enableReinitialize={true}
                    initialValues={{ name: publisher.name, status: publisher.status }}
                    validationSchema={PublisherEditSchema}
                    onSubmit={(values) => {
                         if (isAuthor) {
                              saveAuthor(values);
                         } else {
                              savePublisher(values);
                         }
                    }}
               >
                    {({ handleSubmit, values, setFieldValue, errors }) => {
                         return (
                              <>
                                   <Modal.Body>
                                        {actionsLoading && <Spinner forNewCat={true} />}
                                        <Form className="form form-label-right">
                                             <div className={`col-lg-12 ${styles.input}`}>
                                                  <Field
                                                       name="name"
                                                       component={Input}
                                                       placeholder={
                                                            isAuthor ? "กรอกชื่อนักเขียน" : "กรอกชื่อสำนักพิมพ์"
                                                       }
                                                       label={isAuthor ? "ชื่อนักเขียน" : "ชื่อสำนักพิมพ์"}
                                                  />
                                             </div>
                                        </Form>
                                        <div className="divButton">
                                             <button className="denyButton" type="button" onClick={onHide}>
                                                  ยกเลิก
                                             </button>
                                             <button
                                                  className="acceptButton "
                                                  type="submit"
                                                  onClick={() => {
                                                       handleSubmit();
                                                  }}
                                             >
                                                  ตกลง
                                             </button>
                                        </div>
                                   </Modal.Body>
                              </>
                         );
                    }}
               </Formik>
          </>
     );
}
