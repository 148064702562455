import React, {
   useMemo,
   useState,
   useEffect,
   createContext,
   useContext
} from 'react';
import '../catalogue/CatalogueCard.scss';
import { useCatalogueUIContext } from '../catalogue/CatalogueUIContext';
import { makeStyles } from '@material-ui/core/styles';
import { Row, Col, Empty, Divider, Dropdown, Menu } from 'antd';
import axios from 'axios';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import { OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { stubFalse } from 'lodash';
import { useHistory } from 'react-router-dom';
import { dateStr } from '../../partials/timeStampToDate';
import { UploadSubscriptionPage } from './UploadSubscriptionPage';
import { SubscriptionBox } from './SubscriptionBox';
export const useStyles1 = makeStyles(theme => ({
   div: {
      marginBottom: -40,
      marginTop: 0,
      zIndex: 2
   },
   body: {
      textAlign: 'center'
   },

   dot: {
      position: 'absolute',
      right: '12px',
      bottom: '9px',
      '&:hover': {
         cursor: 'pointer'
      }
   }
}));
export function SubscriptionCard(props) {
   const catalogueUIContext = useCatalogueUIContext();
   const [modalEditUID, setModalEditUID] = useState(false);
   const [modalEditData, setModalEditData] = useState(null);
   const [modalDeleteUID, setModalDeleteUID] = useState(false);
   const [successModalOutside, setSuccessModalOutside] = useState(false);

   const classes = useStyles1();
   const history = useHistory();

   const dropdownBtn = uid => (
      <Menu>
         <Menu.Item
            key="1"
            onClick={() => {
               setModalEditUID(uid);
            }}
            style={{ padding: '0 8px' }}
         >
            <span
            // className="dropdown-text no-pic"
            >
               แก้ไข
            </span>
         </Menu.Item>
         <Menu.Item
            key="2"
            onClick={() => {
               setModalDeleteUID(uid);
            }}
            style={{ padding: '0 8px' }}
         >
            <span
            // className="dropdown-text no-pic"
            >
               ลบ
            </span>
         </Menu.Item>
      </Menu>
   );

   const deleteSubscription = async () => {
      const req = await axios.delete(
         `/superuser/subscriptions/${modalDeleteUID}`
      );
      if (req.data.status) {
         setModalDeleteUID(false);
         if (props.fetchSubscriptionList) {
            props.fetchSubscriptionList();
         }
      }
   };
   const getSubscriptionById = async () => {
      const req = await axios.get(`/superuser/subscriptions/${modalEditUID}`);
      if (req.data.status && req.data.data) {
         setModalEditData(req.data.data);
      }
   };
   useEffect(() => {
      if (modalEditUID) {
         getSubscriptionById();
      }
   }, [modalEditUID]);
   const editSubscriptionFunc = async obj => {
      const req = await axios.put(
         `/superuser/subscriptions/${modalEditUID}`,
         obj
      );
      if (req.data.status) {
         props.fetchSubscriptionList();
         setSuccessModalOutside(true);
      }
   };
   return (
      <>
         <Row gutter={[16, 16]} id="CatalogCard">
            {props.subscriptionList &&
               props.subscriptionList.map(e => (
                  //   <Col md={12} lg={8} style={{ maxHeight: "200px" }}>
                  <>
                     <SubscriptionBox
                        e={e}
                        classes={classes}
                        dropdownBtn={dropdownBtn}
                     />
                     {/* <Dropdown
                overlay={() => dropdownBtn(e.uid)}
                trigger={["click"]}
                placement="bottomLeft"
              >
                <img
                  alt=""
                  src="/media/members/dot-bookshelf.svg"
                  className={classes.dot}
                />
              </Dropdown> */}
                  </>
                  //   </Col>
               ))}
         </Row>
         <Modal
            backdrop="static"
            show={modalDeleteUID}
            onHide={() => setModalDeleteUID(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            style={{ margin: '3% auto' }}
         >
            <Modal.Body className={classes.body}>
               <img src="/media/members/image-modal.svg" alt="" />
               <div className="text-text-red">ยืนยันการลบ</div>
               <div className="text-text-gray">
                  ต้องการลบ Subscription นี้หรือไม่?
               </div>
               <div className="mt-3">
                  <button
                     type="button"
                     onClick={() => setModalDeleteUID(false)}
                     className="denyButton"
                  >
                     ยกเลิก
                  </button>
                  <button
                     type="button"
                     onClick={deleteSubscription}
                     className="acceptButton"
                  >
                     ยืนยัน
                  </button>
               </div>
            </Modal.Body>
         </Modal>
         <Modal
            backdrop="static"
            show={modalEditUID}
            onHide={() => setModalEditUID(false)}
            aria-labelledby="example-modal-sizes-title-lg"
         >
            <Modal.Body>
               <UploadSubscriptionPage
                  editSubscriptionFunc={editSubscriptionFunc}
                  modalEditData={modalEditData}
                  setModalEditData={setModalEditData}
                  setModalEditUID={setModalEditUID}
                  isEditing={true}
                  successModalOutside={successModalOutside}
                  setSuccessModalOutside={setSuccessModalOutside}
               />
            </Modal.Body>
         </Modal>
      </>
   );
}
