import React, { useMemo, useState, useEffect, useRef } from "react";
import { Formik, useFormik } from "formik";
import { isEqual } from "lodash";
import { useCompaniesUIContext } from "../CompaniesUIContext";
import { makeStyles } from "@material-ui/core";
import { Menu, Dropdown, Divider } from "antd";
import SVG from "react-inlinesvg";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { companiesSlice } from "../../../_redux/companies/companiesSlice";
import * as actions from "../../../_redux/companies/companiesActions";

export function CompaniesFilter(props) {
  // eslint-disable-next-line no-undef
  const dispatch = useDispatch();
  // eslint-disable-next-line no-undef
  const { companiesState, authState } = useSelector(
    ({ companies, auth }) => ({
      companiesState: companies,
      authState: auth,
    }),
    // eslint-disable-next-line no-undef
    shallowEqual
  );
  const formik = useFormik({
    initialValues: {
      status: "", // values => All=""/Suspended=0/Active=1/Pending=2
      type: "", // values => All=""/Business=0/Individual=1
      searchText: companiesState.searchKeyword ?? "",
    },
    onSubmit: (values) => {
      applyFilter(
        Object.assign({}, values, {
          memberMode: memberMode,
        })
      );
    },
  });

  // Companies UI Context
  const [memberMode, setMemberMode] = useState(0);
  // const memberMode = 0;
  const companiesUIContext = useCompaniesUIContext();
  const companiesUIProps = useMemo(() => {
    return {
      queryParams: companiesUIContext.queryParams,
      setQueryParams: companiesUIContext.setQueryParams,
    };
  }, [companiesUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    let queryParams = companiesUIProps.queryParams;

    queryParams = Object.assign(queryParams, {
      keyword: values.searchText,
      memberMode: values.memberMode,
    });
    console.log(queryParams);
    if (companiesUIProps.queryParams.keyword !== values.keyword) {
      dispatch(
        actions.fetchCompanies(
          queryParams,
          props.isPrivate,
          undefined,
          props.setLoadingBottom,
          props.currentTab
        )
      );
    }
  };

  useEffect(() => {
    formik.setFieldValue("searchText", companiesState.searchKeyword ?? "");
  }, [companiesState.searchKeyword]);

  const sortWording = () => {
    switch (memberMode) {
      case 0:
        return "ทั้งหมด";
      // case "1":
      //   return "รอใช้งาน";
      // case "2":
      //   return "ใช้งาน";
      // case "3":
      //   return "หมดอายุ";
      case "1":
        return "ใช้งานจริง";
      case "4":
        return "ทดลองใช้";
      default:
        break;
    }
  };

  const dropdownBtn = (values) => (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          setMemberMode(0);
          applyFilter(
            Object.assign({}, values, {
              memberMode: 0,
            })
          );
        }}
      >
        <span className="dropdown-text no-pic">ทั้งหมด</span>
      </Menu.Item>

      <Menu.Item
        key="1"
        onClick={() => {
          setMemberMode("1");
          applyFilter(
            Object.assign({}, values, {
              memberMode: "1",
            })
          );
        }}
      >
        <span className="dropdown-text no-pic">ใช้งานจริง</span>
      </Menu.Item>
      <Menu.Item
        key="4"
        onClick={() => {
          setMemberMode("4");
          applyFilter(
            Object.assign({}, values, {
              memberMode: "4",
            })
          );
        }}
      >
        <span className="dropdown-text no-pic">ทดลองใช้</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className="form form-label-right search-box"
      >
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="searchText"
            placeholder="ค้นหา"
            onBlur={formik.handleBlur}
            value={formik.values.searchText}
            onChange={(e) => {
              formik.setFieldValue("searchText", e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                formik.handleSubmit();
              }
            }}
          />
          <div
            className="icon-search"
            onClick={() => {
              if (
                !companiesUIProps.queryParams.keyword ||
                !formik.values.searchText
              ) {
                formik.handleSubmit();
              } else {
                dispatch(
                  companiesSlice.actions.saveKeyword({
                    searchKeyword: undefined,
                  })
                );

                applyFilter(
                  Object.assign({}, formik.values, {
                    searchText: "",
                    memberMode: memberMode,
                  })
                );
                formik.setFieldValue("searchText", "");
              }
            }}
          >
            {companiesUIProps.queryParams.keyword ||
            companiesState.searchKeyword ? (
              <SVG
                src="/media/members/reset-search.svg"
                alt=""
                style={{
                  transform: "scale(0.7)",
                }}
              />
            ) : (
              <SVG src="/media/members/search-icon.svg" alt="" />
            )}
          </div>
          <Dropdown
            overlay={() => dropdownBtn(formik.values)}
            trigger={["click"]}
            placement="bottomLeft"
          >
            <button type="button" className="button-border">
              <span>{`${sortWording()}`}</span>
            </button>
          </Dropdown>
        </div>
      </form>

      {/* </Formik> */}
    </>
  );
}
