// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Role } from "../../../../../../../interface";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  {
    ele,
    isPrivate,
    publisherName,
    isAuthor,
    publisherIdOfAuthors,
    openEditAuthorDialog,
    openEditPublisherDialog,
    openDeleteAuthorDialog,
    openDeletePublisherDialog,
    openBlockAuthorDialog,
    openBlockPublisherDialog,
    openUnblockAuthorDialog,
    openUnblockPublisherDialog,
    openAuthorsPage,
    openAuthorBooksPage,
    role,
  }
) {
  const barStatus = (row) => {
    if (row.status === 1) {
      return "ACTIVE";
    } else if (row.status === 0) {
      return "BLOCKED";
    }
  };
  return row && ele ? (
    <div style={{ position: "absolute", textAlign: "right" }}>
      {role === Role.SuperAdmin ? (
        ""
      ) : (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="quick-user-tooltip">
              แก้ไข{isAuthor ? "นักเขียน" : "สำนักพิมพ์"}
            </Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => {
              if (isAuthor) {
                openEditAuthorDialog(
                  row.name,
                  publisherIdOfAuthors,
                  row.uid,
                  isPrivate,
                  publisherName
                );
              } else {
                openEditPublisherDialog(row.name, row.uid, isPrivate);
              }
            }}
            style={{ marginBottom: "4px" }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/members/edit-button.svg")} />
            </span>
          </a>
        </OverlayTrigger>
      )}
      {role === Role.SuperAdmin ? (
        <a
          className="btn btn-icon btn-light btn-sm mx-3"
          style={{
            backgroundColor:
              barStatus(row) === "ACTIVE" ? "#F3F6F9" : "#ff5337",
            cursor: "default",
          }}
          onClick={() => {
            if (barStatus(row) === "ACTIVE") {
              if (isAuthor) {
                openBlockAuthorDialog(
                  row.name,
                  publisherIdOfAuthors,
                  row.uid,
                  isPrivate,
                  publisherName
                );
              } else {
                openBlockPublisherDialog(row.name, row.uid, isPrivate);
              }
            } else if (barStatus(row) === "BLOCKED") {
              if (isAuthor) {
                openUnblockAuthorDialog(
                  row.name,
                  publisherIdOfAuthors,
                  row.uid,
                  isPrivate,
                  publisherName
                );
              } else {
                openUnblockPublisherDialog(row.name, row.uid, isPrivate);
              }
            }
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl(
                `/media/members/${
                  barStatus(row) === "ACTIVE" ? "block" : "unblock"
                }-button.svg`
              )}
            />
          </span>
        </a>
      ) : (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="quick-user-tooltip">
              {barStatus(row) === "ACTIVE" ? "บล็อก" : "ปลดบล็อก"}
              {isAuthor ? "นักเขียน" : "สำนักพิมพ์"}
            </Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => {
              if (barStatus(row) === "ACTIVE") {
                if (isAuthor) {
                  openBlockAuthorDialog(
                    row.name,
                    publisherIdOfAuthors,
                    row.uid,
                    isPrivate,
                    publisherName
                  );
                } else {
                  openBlockPublisherDialog(row.name, row.uid, isPrivate);
                }
              } else if (barStatus(row) === "BLOCKED") {
                if (isAuthor) {
                  openUnblockAuthorDialog(
                    row.name,
                    publisherIdOfAuthors,
                    row.uid,
                    isPrivate,
                    publisherName
                  );
                } else {
                  openUnblockPublisherDialog(row.name, row.uid, isPrivate);
                }
              }
            }}
            style={{
              backgroundColor:
                barStatus(row) === "ACTIVE" ? "#F3F6F9" : "#ff5337",
              marginBottom: "4px",
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl(
                  `/media/members/${
                    barStatus(row) === "ACTIVE" ? "block" : "unblock"
                  }-button.svg`
                )}
              />
            </span>
          </a>
        </OverlayTrigger>
      )}
      {role === Role.SuperAdmin ? (
        ""
      ) : (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="quick-user-tooltip">
              ลบ{isAuthor ? "นักเขียน" : "สำนักพิมพ์"}
            </Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            style={{ marginLeft: "0.75rem", marginBottom: "4px" }}
            onClick={() => {
              if (isAuthor) {
                openDeleteAuthorDialog(
                  row.name,
                  publisherIdOfAuthors,
                  row.uid,
                  isPrivate,
                  publisherName
                );
              } else {
                openDeletePublisherDialog(row.name, row.uid, isPrivate);
              }
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/members/delete-button.svg")} />
            </span>
          </a>
        </OverlayTrigger>
      )}
      {isAuthor ? (
        ""
      ) : (
        <button
          title="View author"
          onClick={(e) => {
            e.preventDefault();
            openAuthorsPage(cellContent, row.name); //cellContent = id
          }}
          style={{
            padding: "3px",
            width: "100px",
            height: "28px",
            borderRadius: "6px",
            backgroundColor: "#f2f2f2",
            color: "#012875",
            border: "none",
            marginLeft: "0.75rem",
            marginTop: "4px",
          }}
        >
          ดูนักเขียน
        </button>
      )}
    </div>
  ) : (
    ""
  );
}
