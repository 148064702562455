/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/customers/customersActions";
import { useCustomersUIContext } from "../CustomersUIContext";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import SVG from "react-inlinesvg";
import axios from "axios";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { Empty } from "antd";
import { dateStr, timeStr } from "../../../partials/timeStampToDate";
import { CloseButon } from "../../../../CustomView";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#f3f6f9",
    color: "#828282",
    fontFamily: "Prompt",
    fontSize: 13,
    fontWeight: 600,
  },
}))(TableCell);

const useStyles = makeStyles(() => ({
  //   body: {
  //     textAlign: "center",
  //     width: "750px",
  //     backgroundColor: "white",
  //   },
  //   body_nodevices: {
  //     textAlign: "center",
  //     backgroundColor: "white",
  //   },
  head: {
    textAlign: "left",
    fontFamily: "Prompt",
    fontSize: "20px",
    fontWeight: "600",
    color: "#001b51",
  },
  subhead: {
    textAlign: "left",
    height: "20px",
    fontFamily: "Prompt",
    fontSize: "15px",
  },
  title: {
    fontFamily: "Prompt",
    fontSize: "15px",
    fontWeight: 500,
    color: "#4f4f4f",
    marginBottom: -8,
  },
  subtitle: {
    fontFamily: "Prompt",
    fontSize: "13px",
    fontWeight: 500,
    color: "#fd6b53",
    marginBottom: -8,
    marginTop: 8,
  },
}));

export function CustomerDevicesDialog({
  devices,
  UID,
  id,
  name,
  show,
  onHide,
}) {
  // Customers UI Context
  const [devicesList, setDevicesList] = useState(devices);
  const [isDeleteDialog, setDeleteDialog] = useState(false);
  const [deviceToken, setDeviceToken] = useState(null);
  const [deviceName, setDeviceName] = useState("");
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
    };
  }, [customersUIContext]);

  useEffect(() => {
    if (devices) {
      setDevicesList(devices);
    } else {
      setDevicesList([]);
    }
  }, [devices]);

  // Customers Redux state
  const dispatch = useDispatch();
  const { isLoading, role } = useSelector(
    (state) => ({
      isLoading: state.customers.actionsLoading,
      role: state.auth.role,
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteCustomerDevice = async (deviceToken) => {
    const req = await axios.delete(`/admin/members/${UID}/devices`, {
      data: { deviceToken: deviceToken },
    });
    const { data } = req;
    if (data.status) {
      const newDevicesList = devicesList.filter((e) => {
        return e.deviceToken !== deviceToken;
      });
      setDevicesList(newDevicesList);
      setDeleteDialog(false);
      dispatch(actions.fetchCustomers(customersUIProps.queryParams));
    }
  };

  const classes = useStyles();

  return (
    <Modal
      backdrop="static"
      size={isDeleteDialog ? "md" : "lg"}
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      dialogClassName={
        devicesList && devicesList.length === 0 ? "divdialog-small" : ""
      }
    >
      {isLoading && <ModalProgressBar />}
      {isDeleteDialog ? (
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <img src="/media/members/image-modal.svg" alt="" />
            <div className="text-text-red">ยืนยันการลบ</div>
            <div className="text">ต้องการลบอุปกรณ์ {deviceName} หรือไม่?</div>
            <div className="mt">
              <button
                type="button"
                onClick={() => setDeleteDialog(false)}
                className="denyButton "
              >
                ยกเลิก
              </button>
              <button
                type="button"
                onClick={() => {
                  deleteCustomerDevice(deviceToken);
                }}
                className="acceptButton "
              >
                ยืนยัน
              </button>
            </div>
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <CloseButon onClick={() => onHide()} />
          <h2>Devices</h2>
          <div className="text-title">
            {id && id !== "Anonymous" ? `รหัสพนักงาน ${id} ` : ""}ชื่อ {name}
          </div>
          {devicesList && devicesList.length === 0 ? (
            <div
              style={{
                textAlign: "center",
                margin: "50px auto",
                width: "100%",
              }}
            >
              <Empty description={false} />
              <p
                style={{
                  color: "#012875",
                  marginBottom: "0",
                  fontSize: "20px",
                  fontWeight: "600",
                }}
              >
                ไม่มีอุปกรณ์
              </p>
            </div>
          ) : (
            ""
          )}
          {devicesList &&
          Array.isArray(devicesList) &&
          devicesList.length > 0 ? (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Platform</StyledTableCell>
                  <StyledTableCell align="left">Device</StyledTableCell>
                  <StyledTableCell align="center">App version</StyledTableCell>
                  <StyledTableCell align="center">
                    Logged in date
                  </StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {devicesList.map((row, index) => (
                  <TableRow key={index} style={{ height: "90px" }}>
                    <TableCell align="left">
                      <div className="text">{row.os}</div>
                      <div className="text-red">{row.osversion}</div>
                    </TableCell>
                    <TableCell align="left">
                      <div className="text">{row.devicename}</div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="text">{row.appversion}</div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="text">
                        <div>{dateStr(row.loginDate)}</div>
                        <div>{timeStr(row.loginDate)}</div>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {row.deviceuid ===
                      "hibrary.admin." + localStorage.getItem("deviceuid") ? (
                        <div className={classes.title}>เครื่องนี้</div>
                      ) : (
                        <a
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                          style={{ marginLeft: "0.35rem" }}
                          onClick={() => {
                            setDeviceName(row.devicename);
                            setDeviceToken(row.deviceToken);
                            setDeleteDialog(true);
                          }}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/members/delete-button.svg"
                              )}
                            />
                          </span>
                        </a>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            ""
          )}
        </Modal.Body>
      )}
    </Modal>
  );
}
