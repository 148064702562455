/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
//@ts-nocheck
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/publishers/publishersActions";
import { ClipLoader } from "react-spinners";
import * as uiHelpers from "../PublishersUIHelpers";
import * as columnFormatters from "./column-formatters";
import { usePublishersUIContext } from "../PublishersUIContext";
import { publishersSlice } from "../../../_redux/publishers/publishersSlice";
import { Empty } from "antd";
const actions1 = publishersSlice.actions;

export function PublishersTable({
  publisherId,
  publisherName,
  isAuthor,
  isPrivate,
  setListLoading,
}) {
  // Publishers UI Context
  const publishersUIContext = usePublishersUIContext();
  const publishersUIProps = useMemo(() => {
    return {
      ids: publishersUIContext.ids,
      setIds: publishersUIContext.setIds,
      queryParams: publishersUIContext.queryParams,
      setQueryParams: publishersUIContext.setQueryParams,
      openEditAuthorDialog: publishersUIContext.openEditAuthorDialog,
      openEditPublisherDialog: publishersUIContext.openEditPublisherDialog,
      openViewPublisherDialog: publishersUIContext.openViewPublisherDialog,
      openDeleteAuthorDialog: publishersUIContext.openDeleteAuthorDialog,
      openDeletePublisherDialog: publishersUIContext.openDeletePublisherDialog,
      openBlockAuthorDialog: publishersUIContext.openBlockAuthorDialog,
      openBlockPublisherDialog: publishersUIContext.openBlockPublisherDialog,
      openUnblockAuthorDialog: publishersUIContext.openUnblockAuthorDialog,
      openUnblockPublisherDialog:
        publishersUIContext.openUnblockPublisherDialog,
      openAuthorsPage: publishersUIContext.openAuthorsPage,
      openAuthorBooksPage: publishersUIContext.openAuthorBooksPage,
    };
  }, [publishersUIContext]);

  // Getting current state of publishers list from store (Redux)
  const { currentState, role } = useSelector(
    (state) => ({ currentState: state.publishers, role: state.auth.role }),
    shallowEqual
  );
  const {
    totalCount,
    entities,
    listLoading,
    authorsForEdit,
    publisherIdOfAuthors,
    LastEvaluatedKey,
    error,
  } = currentState;
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [isError, setError] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [isEmpty, setEmpty] = useState(false);
  const loaderHeight = 50;

  useEffect(() => {
    if (error && error.errorCode === "011") {
      setError(true);
      setErrorType(error.from);
    }
  }, [error]);

  useEffect(() => {
    setListLoading(listLoading);
  }, [listLoading]);

  // useEffect(() => {
  //   if (!entities) {
  //     dispatch(actions.fetchPublishers(publishersUIProps.queryParams, isPrivate, LastEvaluatedKey));
  //   }
  // }, [entities]);

  useEffect(() => {
    if (
      LastEvaluatedKey === undefined ||
      JSON.stringify(LastEvaluatedKey) === JSON.stringify({})
    ) {
      setEmpty(true);
    } else {
      setEmpty(false);
      dispatch(
        actions.fetchPublishers(
          publishersUIProps.queryParams,
          isPrivate,
          LastEvaluatedKey
        )
      );
    }
  }, [LastEvaluatedKey]);

  // Publishers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    publishersUIProps.setIds([]);
    // server call by queryParams
    if (isAuthor) {
      dispatch(
        actions.fetchAuthors(
          publishersUIProps.queryParams,
          publisherId,
          isPrivate
        )
      );
      if (!firstLoaded) {
        dispatch(
          actions.fetchPublishers(publishersUIProps.queryParams, isPrivate)
        );
        setFirstLoaded(true);
      }
    } else {
      dispatch(
        actions.fetchPublishers(publishersUIProps.queryParams, isPrivate)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    publishersUIProps.queryParams,
    dispatch,
    isPrivate,
    publisherId,
    isAuthor,
  ]);

  useEffect(() => {
    return () => {
      dispatch(
        actions1.publishersFetched({
          totalCount: 0,
          entities: [],
          LastEvaluatedKey: {},
        })
      );
    };
  }, []);

  useEffect(() => {
    return () => {
      if (!isAuthor) {
        dispatch(actions1.authorsFetched({ authorsForEdit: undefined }));
      }
    };
  }, [isAuthor]);

  const errorTypeWording = (type) => {
    if (type) {
      switch (type) {
        case "delete":
          return "ลบ";
        case "block":
          return "บล็อก";
        case "unblock":
          return "ปลดบล็อก";
        case "setStatus":
          return "เปลี่ยนสถานะ";
        case "update":
          return "เปลี่ยนชื่อ";
        default:
          return "อัปเดท";
      }
    }
  };

  const cardOfEle = (arr) => {
    if (arr && Array.isArray(arr) && arr.length) {
      return (
        <div className="publisherTable">
          {arr.map((el, index) => {
            return (
              <div key={index}>
                <div
                  className="row"
                  style={{
                    margin: "12px 0px",
                    height: "95px",
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.3)",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    className="col-1"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "16px",
                    }}
                  >
                    <p style={{ margin: "auto" }}>{index + 1}.</p>
                  </div>
                  <div
                    className={`col-${isAuthor ? 6 : 4}`}
                    style={{
                      display: "block",
                      alignItems: "center",
                      fontSize: "16px",
                      margin: "auto",
                      padding: "0px",
                    }}
                  >
                    {el.name}
                  </div>
                  <div
                    className={`col-${isAuthor ? 5 : 7}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    {columnFormatters.ActionsColumnFormatter(
                      el.uid,
                      el,
                      index,
                      {
                        ele: entities,
                        publisherName,
                        isPrivate,
                        isAuthor,
                        publisherIdOfAuthors: publisherIdOfAuthors,
                        openEditAuthorDialog:
                          publishersUIProps.openEditAuthorDialog,
                        openEditPublisherDialog:
                          publishersUIProps.openEditPublisherDialog,
                        openBlockAuthorDialog:
                          publishersUIProps.openBlockAuthorDialog,
                        openBlockPublisherDialog:
                          publishersUIProps.openBlockPublisherDialog,
                        openUnblockAuthorDialog:
                          publishersUIProps.openUnblockAuthorDialog,
                        openUnblockPublisherDialog:
                          publishersUIProps.openUnblockPublisherDialog,
                        openDeleteAuthorDialog:
                          publishersUIProps.openDeleteAuthorDialog,
                        openDeletePublisherDialog:
                          publishersUIProps.openDeletePublisherDialog,
                        openAuthorsPage: publishersUIProps.openAuthorsPage,
                        openAuthorBooksPage:
                          publishersUIProps.openAuthorBooksPage,
                        role,
                      }
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="empty-container">
          {Array.isArray(arr) && arr.length === 0 && !listLoading ? (
            <div className="div-isNotFound">
              {publishersUIProps.queryParams.keyword ? (
                <img src="/media/members/no-search-result.png" alt="" />
              ) : (
                <Empty description={false} />
              )}
              <p
                style={{
                  color: "#012875",
                  marginBottom: "0",
                  fontSize: "20px",
                  fontWeight: "600",
                }}
              >
                {publishersUIProps.queryParams.keyword
                  ? "ไม่พบรายการที่ค้นหา"
                  : "ไม่มีข้อมูล"}
              </p>
            </div>
          ) : null}
        </div>
      );
    }
  };
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: publishersUIProps.queryParams.pageSize,
    page: publishersUIProps.queryParams.pageNumber,
    onPageChange: () => {
      window.scrollTo(0, 0);
    },
  };

  //entities

  return (
    <>
      <div>
        {isAuthor ? cardOfEle(authorsForEdit) : cardOfEle(entities)}
        {(isAuthor && !authorsForEdit) ||
        (!isAuthor && !entities) ||
        listLoading ||
        (LastEvaluatedKey !== undefined && !isEmpty) ? (
          <div className="cliploader-center">
            <ClipLoader size={loaderHeight} color={"#123abc"} loading={true} />
          </div>
        ) : !error && !isEmpty ? (
          <div style={{ minHeight: `${loaderHeight}px` }} />
        ) : null}
      </div>
      <Modal
        backdrop="static"
        show={isError}
        onHide={() => {
          setError(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
        style={{ margin: "3% auto" }}
        size="md"
      >
        <Modal.Body
          style={{
            textAlign: "center",
          }}
        >
          <img src="/media/members/image-modal.svg" alt="" />
          <div
            style={{
              fontSize: "20px",
              fontWeight: 600,
              color: "#d7042b",
              marginBottom: "12px",
            }}
          >
            {isAuthor ? (
              <>
                <p style={{ marginBottom: "0px" }}>
                  ไม่สามารถ{errorTypeWording(errorType)}นักเขียนได้
                </p>
                <p>เนื่องจากมีการใช้งานนักเขียนอยู่</p>
              </>
            ) : (
              <>
                <p style={{ marginBottom: "0px" }}>
                  ไม่สามารถ{errorTypeWording(errorType)}สำนักพิมพ์ได้
                </p>
                <p>เนื่องจากมีการใช้งานสำนักพิมพ์อยู่</p>
              </>
            )}
          </div>
          <div style={{ marginTop: "36px" }}>
            <button
              type="button"
              onClick={() => {
                setError(false);
              }}
              style={{
                margin: "8px",
                color: "#ffffff",
                fontSize: "18px",
                fontWeight: 600,
                width: "160px",
                height: "48px",
                borderRadius: "100px",
                backgroundColor: "#012875",
                border: "none",
              }}
            >
              ตกลง
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
