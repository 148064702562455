/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
// import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from '../../../../_metronic/layout';
import Login from './Login';
import Registration from './Registration';
import ForgotPassword from './ForgotPassword';
import VerifyOTP from './VerifyOTP';
import NewPassword from './NewPassword';
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';

export function AuthPage() {
   const [emailDone, setEmailDone] = useState(false);
   const [timeLeft, setTimeLeft] = useState(500);
   const [uidOfOTP, setUidOfOTP] = useState('');
   const [refCode, setRefCode] = useState('');
   const [OTP, setOTP] = useState('');
   const { authState } = useSelector(
      ({ auth }) => ({
         authState: auth
      }),
      shallowEqual
   );
   return (
      <>
         <div className="d-flex flex-column flex-root">
            {/*begin::Login*/}
            <div
               className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
               id="kt_login"
            >
               {/*begin::Aside*/}
               <div
                  className={`login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 ${
                     authState.isCuDomain ? 'cu-color' : ''
                  }`}
               >
                  {/*begin: Aside Container*/}
                  <div className="d-flex flex-row-fluid flex-column justify-content-between">
                     {/* start:: Aside header */}
                     {/* <Link to="/" className="flex-column-auto mt-5">
                <img
                  alt="Logo"
                  className="max-h-70px"
                  src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                />
              </Link> */}
                     {/* end:: Aside header */}

                     {/* start:: Aside content */}
                     <div
                        className="flex-column-fluid d-flex flex-column justify-content-center"
                        // style={{ textAlign: "center" }}
                     >
                        {authState.isCuDomain ? (
                           <h1
                              style={{
                                 fontSize: 40,
                                 fontWeight: 500,
                                 color: '#fff'
                              }}
                           >
                              CU - eLibrary
                           </h1>
                        ) : (
                           <img
                              src="/media/auth/hibrary.svg"
                              id="hibrary-text"
                              alt=""
                           />
                        )}

                        <h3 className="font-size-h1 text-white">
                           ห้องสมุดออนไลน์
                        </h3>
                        <h3 className="font-size-h1 text-white">
                           ที่เชื่อมต่อกับคุณทุกที่
                        </h3>
                        <p className="text-white opacity-80 mt">
                           มีอีบุ๊กหลากหลายสามารถเลือกอีบุ๊กเข้าห้องสมุดได้ตามต้องการ
                        </p>
                        <p className="text-white opacity-80">
                           ไม่ว่าจะเป็นธุรกิจอะไร
                           ก็สามารถมีห้องสมุดประจำองค์กรให้กับพนักงานหรือลูกค้าของคุณได้
                        </p>
                        {authState.isCuDomain ? (
                           <img
                              src={
                                 process.env.PUBLIC_URL +
                                 '/media/auth/cu-login-cover.png'
                              }
                              className="login-cover"
                              alt=""
                           />
                        ) : (
                           <img
                              src="/media/auth/login-cover.png"
                              className="login-cover"
                              alt=""
                           />
                        )}
                     </div>
                     {/* end:: Aside content */}

                     {/* start:: Aside footer for desktop */}
                     {/* <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">&copy; 2020 Metronic</div>
                <div className="d-flex">
                  <Link to="/terms" className="text-white">
                    Privacy
                  </Link>
                  <Link to="/terms" className="text-white ml-10">
                    Legal
                  </Link>
                  <Link to="/terms" className="text-white ml-10">
                    Contact
                  </Link>
                </div>
              </div> */}
                     {/* end:: Aside footer for desktop */}
                  </div>
                  {/*end: Aside Container*/}
               </div>
               {/*begin::Aside*/}

               {/*begin::Content*/}
               <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
                  {/*begin::Content header*/}
                  <div className="mt-5 mb-15 mb-lg-0 flex justify-content-center py-5 px-10">
                     {/* <span className="font-weight-bold text-dark-50">Don't have an account yet?</span>
              <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">
                Sign Up!
              </Link> */}
                     <div className="login-logo">
                        {authState.isCuDomain ? (
                           <img src="/media/auth/cu-logo.svg" alt="" />
                        ) : (
                           <img src="/media/auth/login-logo.svg" alt="" />
                        )}
                     </div>
                  </div>
                  {/*end::Content header*/}

                  {/* begin::Content body */}
                  <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                     <Switch>
                        {!emailDone && (
                           <Redirect exact from="/auth/verify-otp" to="/" />
                        )}
                        {!emailDone && (
                           <Redirect exact from="/auth/newpw" to="/" />
                        )}
                        <ContentRoute path="/" exact={true} component={Login} />
                        <ContentRoute
                           path="/auth/registration"
                           component={Registration}
                        />
                        <ContentRoute
                           path="/auth/forgot-password"
                           render={props => (
                              <ForgotPassword
                                 setEmailDone={setEmailDone}
                                 setRefCode={setRefCode}
                                 setUidOfOTP={setUidOfOTP}
                                 setTimeLeft={setTimeLeft}
                                 {...props}
                              />
                           )}
                        />
                        <ContentRoute
                           path="/auth/verify-otp"
                           render={props => (
                              <VerifyOTP
                                 timeLeft={timeLeft}
                                 setTimeLeft={setTimeLeft}
                                 uid={uidOfOTP}
                                 refCode={refCode}
                                 otp={OTP}
                                 setOTP={setOTP}
                                 {...props}
                              />
                           )}
                        />
                        <ContentRoute
                           path="/auth/newpw"
                           render={props => (
                              <NewPassword
                                 uid={uidOfOTP}
                                 refCode={refCode}
                                 otp={OTP}
                                 {...props}
                              />
                           )}
                        />
                        <Redirect from="/auth" exact={true} to="/" />
                        <Redirect from="/auth/login" exact={true} to="/" />
                        <Redirect from="/companies" exact={true} to="/" />
                        <Redirect from="/dashboard" exact={true} to="/" />
                        <Redirect from="/logout" exact={true} to="/" />
                     </Switch>
                  </div>
                  {/*end::Content body*/}

                  {/* begin::Mobile footer */}
                  <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                     <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                        &copy; 2020 Hibrary
                     </div>
                     {/* <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 text-hover-primary">
                  Privacy
                </Link>
                <Link to="/terms" className="text-dark-75 text-hover-primary ml-4">
                  Legal
                </Link>
                <Link to="/terms" className="text-dark-75 text-hover-primary ml-4">
                  Contact
                </Link>
              </div> */}
                  </div>
                  {/* end::Mobile footer */}
                  {authState.isCuDomain && (
                     <div style={{ textAlign: 'center' }}>
                        {' '}
                        <img src="/media/logos/byHibrary.png" />
                     </div>
                  )}
               </div>

               {/*end::Content*/}
            </div>
            {/*end::Login*/}
         </div>
      </>
   );
}
