import { Button, Select } from 'antd';

import './index.scss';

const ReportCardSuperAdmin = () => {
   const { Option } = Select;

   return (
      <div className="report-super-admin">
         <div className="form-container">
            <h1>การรายงานสถิติการใช้งาน</h1>
            <div>
               <div>
                  <label>เลือกรายงาน</label>
                  <Select defaultValue="lucy" size="large">
                     <Option value="jack">Jack</Option>
                     <Option value="lucy">Lucy</Option>
                     <Option value="disabled" disabled>
                        Disabled
                     </Option>
                     <Option value="Yiminghe">yiminghe</Option>
                  </Select>
               </div>
               <div>
                  <label>เลือกช่วงเวลา</label>
                  <Select defaultValue="lucy" size="large">
                     <Option value="jack">Jack</Option>
                     <Option value="lucy">Lucy</Option>
                     <Option value="disabled" disabled>
                        Disabled
                     </Option>
                     <Option value="Yiminghe">yiminghe</Option>
                  </Select>
               </div>
               <Button type="primary" size="large" shape="round">
                  Request Report
               </Button>
            </div>
         </div>
         <div className="card-container">
            <h1>ดาวน์โหลดรายงานสถิติการใช้งาน</h1>
            <div className="col-title">
               <div className="col-title-item">วันที่สร้าง</div>
               <div className="col-title-item">Requested By</div>
               <div className="col-title-item">ประเภทรายงาน</div>
               <div className="col-title-item">ช่วงเวลา</div>
               <div className="col-title-item">สถานะ</div>
            </div>
            <div className="card-item ">
               <div className="col-card">
                  <p>30-09-2022</p>
                  <p>16:53:05</p>
               </div>
               <div className="col-card">fsun@hytexts.com</div>
               <div className="col-card">
                  ข้อมูลการยืมและการอ่านของสมาชิกจากการสแกน QRcode
               </div>
               <div className="col-card">29-04-2020 - 22-08-2027</div>
               <div className="col-card">
                  <Button size="small" shape="round">
                     ดาวน์โหลดรายงาน
                  </Button>
               </div>
            </div>
            <>
               <div className="card-item ">
                  <div className="col-card">
                     <p>30-09-2022</p>
                     <p>16:53:05</p>
                  </div>
                  <div className="col-card">fsun@hytexts.com</div>
                  <div className="col-card">
                     ข้อมูลการยืมและการอ่านของสมาชิกจากการสแกน QRcode
                  </div>
                  <div className="col-card">29-04-2020 - 22-08-2027</div>
                  <div className="col-card">
                     <Button size="small" shape="round">
                        ดาวน์โหลดรายงาน
                     </Button>
                  </div>
               </div>
               <div className="card-item ">
                  <div className="col-card">
                     <p>30-09-2022</p>
                     <p>16:53:05</p>
                  </div>
                  <div className="col-card">fsun@hytexts.com</div>
                  <div className="col-card">
                     ข้อมูลการยืมและการอ่านของสมาชิกจากการสแกน QRcode
                  </div>
                  <div className="col-card">29-04-2020 - 22-08-2027</div>
                  <div className="col-card">
                     <Button size="small" shape="round">
                        ดาวน์โหลดรายงาน
                     </Button>
                  </div>
               </div>
               <div className="card-item ">
                  <div className="col-card">
                     <p>30-09-2022</p>
                     <p>16:53:05</p>
                  </div>
                  <div className="col-card">fsun@hytexts.com</div>
                  <div className="col-card">
                     ข้อมูลการยืมและการอ่านของสมาชิกจากการสแกน QRcode
                  </div>
                  <div className="col-card">29-04-2020 - 22-08-2027</div>
                  <div className="col-card">
                     <Button size="small" shape="round">
                        ดาวน์โหลดรายงาน
                     </Button>
                  </div>
               </div>
               <div className="card-item ">
                  <div className="col-card">
                     <p>30-09-2022</p>
                     <p>16:53:05</p>
                  </div>
                  <div className="col-card">fsun@hytexts.com</div>
                  <div className="col-card">
                     ข้อมูลการยืมและการอ่านของสมาชิกจากการสแกน QRcode
                  </div>
                  <div className="col-card">29-04-2020 - 22-08-2027</div>
                  <div className="col-card">
                     <Button size="small" shape="round">
                        ดาวน์โหลดรายงาน
                     </Button>
                  </div>
               </div>
               <div className="card-item ">
                  <div className="col-card">
                     <p>30-09-2022</p>
                     <p>16:53:05</p>
                  </div>
                  <div className="col-card">fsun@hytexts.com</div>
                  <div className="col-card">
                     ข้อมูลการยืมและการอ่านของสมาชิกจากการสแกน QRcode
                  </div>
                  <div className="col-card">29-04-2020 - 22-08-2027</div>
                  <div className="col-card">
                     <Button size="small" shape="round">
                        ดาวน์โหลดรายงาน
                     </Button>
                  </div>
               </div>
               <div className="card-item ">
                  <div className="col-card">
                     <p>30-09-2022</p>
                     <p>16:53:05</p>
                  </div>
                  <div className="col-card">fsun@hytexts.com</div>
                  <div className="col-card">
                     ข้อมูลการยืมและการอ่านของสมาชิกจากการสแกน QRcode
                  </div>
                  <div className="col-card">29-04-2020 - 22-08-2027</div>
                  <div className="col-card">
                     <Button size="small" shape="round">
                        ดาวน์โหลดรายงาน
                     </Button>
                  </div>
               </div>
            </>
         </div>
      </div>
   );
};

export default ReportCardSuperAdmin;
