import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";
import { logout } from "../_redux/authCrud";

class Logout extends Component {
  componentDidMount() {
    if (this.props.isViewingAs) {
      this.props.stopViewAs();
      this.props.history.push("/companies");
    } else {
      logout()
        .then(() => {
          this.props.logout();
        })
        .catch(() => {
          this.props.logout();
        });
    }
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? (
      <LayoutSplashScreen />
    ) : (
      <Redirect to="/auth/login" />
    );
  }
}

export default withRouter(
  connect(
    ({ auth }) => ({
      hasAuthToken: Boolean(auth.authToken),
      isViewingAs: Boolean(auth.viewAsToken)
    }),
    auth.actions
  )(Logout)
);
