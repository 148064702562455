/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FileAndCover from "./FileAndCover";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../../_metronic/_partials/controls";

export function ViewAsUploadContentPage() {
     const history = useHistory();
     const step = 1;
     const useStyles1 = makeStyles((theme) => ({
          div: {
               marginTop: 16,
          },
          button: {
               borderRadius: 100,
               backgroundColor: "#012875",
               marginLeft: 8,
               padding: "10px 22px",
               height: 40,
               color: "white",
               fontSize: 13,
               fontWeight: 600,
               fontFamily: "Prompt",
               border: "none",
               marginTop: 8,
          },
     }));
     const classes = useStyles1();
     const handleSubmit = (note) => {
          history.push("/companies");
     };
     const stepLabel = ["อัปโหลดไฟล์"];
     const downloadTemplate = () => {
          axios.get(`/superuser/hibrary/contents/template`).then((res) => {
               if (res.data && res.data.data && res.data.data.fulfillmentUrl) {
                    let link = document.createElement("a");
                    link.setAttribute("download", "template");
                    link.href = res.data.data.fulfillmentUrl;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
               }
          });
     };
     return (
          <Card className="cardStyle">
               {/*<div className="row">
        {stepLabel.map((el, index) => (
          <div
            key={index}
            className={`col-3 ${
              index + 1 === step ? classes.stepDiv : classes.stepDivBlack
            }`}
          >
            <p
              className={
                index + 1 === step ? classes.stepIndex : classes.stepIndexBlack
              }
            >
              {index + 1}
            </p>
            <p
              className={
                index + 1 === step ? classes.stepDiv_p : classes.stepDivBlack_p
              }
            >
              {el}
            </p>
          </div>
        ))}
            </div>*/}
               <CardHeader
                    title={
                         <div className={classes.div}>
                              <p className="text-text-head">เพิ่มสื่อ</p>
                         </div>
                    }
               >
                    <CardHeaderToolbar>
                         <button
                          type="button"
                          className={classes.button}
                          onClick={()=>{
                              history.push('/view-as-upload-content-history')
                          }}
                         >
                              <span>
                                   ดูประวัติการเพิ่ม
                              </span>
                         </button>
                         <button
                              type="button"
                              className={classes.button}
                              onClick={() => {
                                   downloadTemplate();
                              }}
                         >
                              <span>
                                   <img alt="qrcode" src="/media/members/excel-button.svg" />
                              </span>
                              <span>ดาวน์โหลด Template</span>
                         </button>
                    </CardHeaderToolbar>
               </CardHeader>
               <CardBody className="cardBodyStyle">
                    <FileAndCover handleSubmitOutside={handleSubmit} />
               </CardBody>
          </Card>
     );
}
