import { createSlice } from '@reduxjs/toolkit';

const initialCompaniesState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    entities_hti: [],
    companyForEdit: undefined,
    LastEvaluatedKey: {},
    updatedTime: 0,
    companyUpdate: undefined,
    companySearch: undefined,
    searchKeyword: undefined
};
export const callTypes = {
    list: 'list',
    action: 'action'
};

export const companiesSlice = createSlice({
    name: 'companies',
    initialState: initialCompaniesState,
    reducers: {
        catchError: (state, action) => {
            if (
                action.payload.error &&
                action.payload.error.response &&
                action.payload.error.response.data
            ) {
                state.error = action.payload.error.response.data.errorCode;
            }
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },

        setEntities: (state, action) => {
            state.entities = action.payload.entities;
        },

        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getCompanyById
        companyFetched: (state, action) => {
            state.actionsLoading = false;
            state.companyForEdit = action.payload.companyForEdit;
            state.error = null;
        },
        // findCompanies
        companiesFetched: (state, action) => {
            const {
                totalCount,
                entities,
                LastEvaluatedKey,
                lastEvaluate,
                isPrivate,
                memberMode
            } = action.payload;
            state.listLoading = false;
            state.error = null;
            if (isPrivate) {
                if (lastEvaluate === undefined) {
                    if (memberMode) {
                        if (memberMode === '4') {
                            state.companySearch = entities.filter(
                                e => e.status === 4
                            );
                        } else if (memberMode === '1') {
                            state.companySearch = entities.filter(
                                e => e.status === 1
                            );
                        } else {
                            state.companySearch = entities.filter(
                                e => e.timeStatus === parseInt(memberMode, 10)
                            );
                        }
                    } else {
                        state.companySearch = entities;
                    }
                } else {
                    if (memberMode) {
                        if (memberMode === '4') {
                            entities
                                .filter(e => e.status === 4)
                                .forEach(item =>
                                    state.companySearch.push(item)
                                );
                        } else if (memberMode === '1') {
                            entities
                                .filter(e => e.status === 1)
                                .forEach(item =>
                                    state.companySearch.push(item)
                                );
                        } else {
                            entities
                                .filter(
                                    e =>
                                        e.timeStatus ===
                                        parseInt(memberMode, 10)
                                )
                                .forEach(item =>
                                    state.companySearch.push(item)
                                );
                        }
                    } else {
                        entities.forEach(item =>
                            state.companySearch.push(item)
                        );
                    }
                }
            } else {
                if (lastEvaluate === undefined) {
                    if (memberMode) {
                        if (memberMode === '4') {
                            state.companySearch = entities.filter(
                                e => e.status === 4
                            );
                        } else if (memberMode === '1') {
                            state.companySearch = entities.filter(
                                e => e.status === 1
                            );
                        } else {
                            state.companySearch = entities.filter(
                                e => e.timeStatus === parseInt(memberMode, 10)
                            );
                        }
                    } else {
                        state.companySearch = entities;
                        // state.entities = entities;
                    }
                } else {
                    console.log('2', memberMode);
                    if (memberMode) {
                        if (memberMode === '4') {
                            entities
                                .filter(e => e.status === 4)
                                .forEach(item =>
                                    state.companySearch.push(item)
                                );
                        } else if (memberMode === '1') {
                            entities
                                .filter(e => e.status === 1)
                                .forEach(item =>
                                    state.companySearch.push(item)
                                );
                        } else {
                            entities
                                .filter(
                                    e =>
                                        e.timeStatus ===
                                        parseInt(memberMode, 10)
                                )
                                .forEach(item =>
                                    state.companySearch.push(item)
                                );
                        }
                    } else {
                        state.entities = state.entities.concat(entities);
                        //  entities.forEach(item =>
                        //      state.companySearch.push(item)
                        //  );
                    }
                }
            }
            state.totalCount = totalCount;
            state.LastEvaluatedKey = LastEvaluatedKey;
            // state.entities = entities;
            state.totalCount = 17;
        },
        // createCompany
        companyCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.company);
        },
        // updateCompany
        companyUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.updatedTime = Date.now();
            state.companyUpdate = Object.assign(
                { clientUID: action.payload.id },
                action.payload.company
            );
        },
        // deleteCompany
        companyDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => el.contentUID !== action.payload.id
            );
        },
        // blockCompany
        companyBlocked: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.contentUID === action.payload.id) {
                    return Object.assign({}, entity, { status: 2 });
                }
                return entity;
            });
        },
        // clear all data
        clearedAllData: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            console.log(state);

            const index = state.entities.findIndex(
                el => el.clientUID === action.payload.id
            );
            if (action.payload.table === 'member') {
                state.companyUpdate = Object.assign(state.entities[index], {
                    processStatus: action.payload.processStatus
                });
            }
        },
        // unblockCompany
        companyUnblocked: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.contentUID === action.payload.id) {
                    return Object.assign({}, entity, { status: 1 });
                }
                return entity;
            });
        },
        // deleteCompanies
        companiesDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => !action.payload.ids.includes(el.id)
            );
        },
        // companiesUpdateState
        companiesStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            const { ids, status } = action.payload;
            state.entities = state.entities.map(entity => {
                if (ids.findIndex(id => id === entity.id) > -1) {
                    entity.status = status;
                }
                return entity;
            });
        },
        elementUpdated: state => {
            state.actionsLoading = false;
        },
        updatedImage: (state, action) => {
            state.entities_hti = state.entities_hti.map(entity => {
                if (entity.clientUID === action.payload.id) {
                    return Object.assign({}, entity, {
                        logoImages: action.payload.logoImages
                    });
                }
                return entity;
            });
        },
        saveKeyword: (state, action) => {
            state.searchKeyword = action.payload.searchKeyword;
        }
    }
});
