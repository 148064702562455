import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/banners/bannersActions";
import { useBannersUIContext } from "../BannersUIContext";
import { makeStyles } from "@material-ui/core/styles";
import { useStyles } from "../../../partials/useStyles";

export function BannerBlockDialog({ id, isPrivate, show, onHide }) {
     // Banners UI Context
     const bannersUIContext = useBannersUIContext();
     const bannersUIProps = useMemo(() => {
          return {
               setIds: bannersUIContext.setIds,
               queryParams: bannersUIContext.queryParams,
          };
     }, [bannersUIContext]);

     // Banners Redux state
     const dispatch = useDispatch();
     const { isLoading } = useSelector(
          (state) => ({
               bannersState: state.banners,
               isLoading: state.banners.actionsLoading,
          }),
          shallowEqual
     );

     // looking for loading/dispatch
     useEffect(() => {}, [isLoading, dispatch]);

     const blockBanner = () => {
          dispatch(actions.updateBannerStatus(id, false, isPrivate)).then(() => {
               dispatch(actions.fetchBanners(bannersUIProps.queryParams, isPrivate));
               bannersUIProps.setIds([]);
               onHide();
          });
     };

     const classes = useStyles();

     return (
          <Modal
               backdrop="static"
               show={show}
               onHide={onHide}
               aria-labelledby="example-modal-sizes-title-lg"
               style={{ margin: "3% auto" }}
          >
               {isLoading && <ModalProgressBar />}
               <Modal.Body className={classes.body}>
                    <img src="/media/members/image-modal.svg" alt="" />
                    {!isLoading && (
                         <>
                              <div className="text-text-red">ยืนยันการบล็อก</div>
                              <div className="text-text-gray">ต้องการบล็อกแบนเนอร์นี้หรือไม่?</div>
                              <div className="divButton">
                                   <button type="button" onClick={onHide} className="denyButton">
                                        ยกเลิก
                                   </button>
                                   <button type="button" onClick={blockBanner} className="acceptButton">
                                        ยืนยัน
                                   </button>
                              </div>
                         </>
                    )}
                    {isLoading && (
                         <div className="text-text-gray" style={{ marginBottom: "36px" }}>
                              กำลังบล็อกแบนเนอร์...
                         </div>
                    )}
               </Modal.Body>
          </Modal>
     );
}
