import React, { useEffect, useState, useRef, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { makeStyles } from "@material-ui/core/styles";
import { useEbooksUIContext } from "../EbooksUIContext";
import * as actions from "../../../_redux/ebooks/ebooksActions";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { message } from "antd";
import jwt_decode from "jwt-decode";
import parse from "html-react-parser";
import { dateStr, timeStr } from "../../../partials/timeStampToDate";
import { Role } from "../../../../../../interface";

const useStyles = makeStyles(() => ({
  body: {
    textAlign: "center",
  },
  divButton: {
    marginTop: "24px",
    marginBottom: "12px",
  },

  acceptButton_sample: {
    margin: "8px",
    color: "#ffffff",
    fontSize: "16px",
    fontWeight: 600,
    width: "160px",
    height: "36px",
    borderRadius: "100px",
    backgroundColor: "#012875",
    border: "none",
  },
  acceptButton: {
    margin: "8px",
    width: "150px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "#012875",
    border: "none",
    color: "white",
    fontWeight: "600",
    fontSize: "16px",
  },
  denyButton: {
    margin: "8px",
    color: "#012875",
    fontSize: "18px",
    fontWeight: 600,
    width: "160px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "#ffffff",
    border: "solid 1px #012875",
  },
  col7: { textAlign: "left", maxWidth: "440px" },
  coverImage: {
    width: "260px",
    "-webkit-box-shadow": "0px 0px 4px rgba(0, 0, 0, 0.1)",
    "box-shadow": "0px 0px 4px rgba(0, 0, 0, 0.1)",
  },
  title: { fontSize: "24px", color: "#012875", fontWeight: "600" },
  titleDes: {
    fontSize: "24px",
    color: "#012875",
    fontWeight: "600",
    marginTop: "33px",
    marginBottom: "10px",
    textAlign: "left",
  },
  text: { color: "#4f4f4f", fontSize: "18px" },
  textGrey: { color: "#828282", fontSize: "18px" },
  textOrange: { color: "#fd6b53", fontSize: "18px" },
  text16: { color: "#4f4f4f", fontSize: "16px" },
  textOrange16: { color: "#fd6b53", fontSize: "16px" },
  textOrangeBig: { color: "#fd6b53", fontSize: "28px", fontWeight: "600" },
  textBlue16: { color: "#012875", fontSize: "16px" },

  catsubcat: {
    borderRadius: "100px",
    backgroundColor: "#f1ebe6",
    fontSize: "16px",
    color: "#4f4f4f",
    height: "28px",
    padding: "4px 16px",
  },
  description: {
    fontFamily: "Bai Jamjuree",
    fontSize: "16px",
    color: "#4f4f4f",
    marginBottom: "20px",
    textAlign: "left",
    "& p": {
      marginBottom: "4px",
    },
  },
  closeButton: {
    width: "30px",
    height: "30px",
    border: "none",
    backgroundColor: "#e0e0e0",
    borderRadius: "50%",
    padding: 0,
  },
  slot: {
    width: "100%",
    border: "1px solid #E5EAEE",
    display: "block",
    height: "calc(1.5em + 1.3rem + 2px)",
    padding: "0.65rem 1rem",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "#464E5F",
    backgroundColor: "#ffffff",
    backgroundClip: "padding-box",
    borderRadius: "0.42rem",
    boxShadow: "none",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  },
  p: { marginTop: "4px", marginBottom: "8px", fontSize: "14px" },
  bigp: {
    color: "#012875",
    fontSize: "20px",
    marginBottom: "0",
    fontWeight: "600",
  },
  title_confirm: {
    fontSize: "20px",
    fontWeight: 600,
    color: "#d7042b",
  },
}));

export function EbookViewDialog({
  id,
  isPrivate,
  show,
  onHide,
  isFree,
  catalogueId,
}) {
  // Ebooks UI Context
  const history = useHistory();
  const loaderHeight = 50;
  const [ebookDetail, setEbookDetail] = useState(null);
  const [modalManageLicense, setModalManageLicense] = useState(false);
  const textAreaRef = useRef(null);
  const [checkingError, setCheckingError] = useState(false);
  const [concurrentValue, setConcurrentValue] = useState(undefined);
  const ebooksUIContext = useEbooksUIContext();
  const ebooksUIProps = useMemo(() => {
    return {
      queryParams: ebooksUIContext ? ebooksUIContext.queryParams : undefined,
    };
  }, [ebooksUIContext]);

  // Ebooks Redux state
  const dispatch = useDispatch();
  const { role, isLoading, authToken } = useSelector(
    (state) => ({
      role: state.auth.role,
      isLoading: state.ebooks.actionsLoading,
      authToken: state.auth.authToken,
    }),
    shallowEqual
  );
  const [durationTypeList, setDurationTypeList] = useState([
    <option value={1800}>30 นาที</option>,
  ]);
  const [anonymousDurationTypeList, setAnonymousDurationTypeList] = useState([
    <option value={1800}>30 นาที</option>,
  ]);
  const [rentalPeriodValue, setRentalPeriodValue] = useState(1800);
  const [anonymousRentalPeriodValue, setAnonymousRentalPeriodValue] = useState(
    1800
  );
  const [confirmModal, setConfirmModal] = useState(false);

  const reqDetailFunc = async (val) => {
    try {
      const req = await axios.get(
        `${`/admin${
          catalogueId
            ? `/catalogs/${catalogueId}/contents`
            : isPrivate
            ? "/private/contents"
            : isFree
            ? "/free/contents"
            : "/contents"
        }`}/${id}`
      );
      const { data } = req;
      if (data.status && data.data) {
        setEbookDetail(data.data);
        if (data.data.concurrent !== undefined) {
          setConcurrentValue(data.data.concurrent);
        } else {
          setConcurrentValue(undefined);
        }
        if (val === 2) {
          if (data.data.rentalPeriod) {
            setRentalPeriodValue(data.data.rentalPeriod);
          }
          if (data.data.anonymousRentalPeriod) {
            setAnonymousRentalPeriodValue(data.data.anonymousRentalPeriod);
          } else if (data.data.rentalPeriod) {
            setAnonymousRentalPeriodValue(data.data.rentalPeriod);
          }
        } else {
          if (
            !catalogueId &&
            (!isFree || (isPrivate && data.data.concurrent !== undefined))
          ) {
            reqDropdownFunc(data.data.contentUID);
          }
        }
      }
    } catch (err) {
      setEbookDetail(0);
    }
  };

  const reqDropdownFunc = async (UID) => {
    const reqDropdown = await axios.get(`/admin/contents/${UID}/edit`);
    if (reqDropdown.data.data) {
      let res = reqDropdown.data.data;
      setDurationTypeList(
        res.rentalPeriodPreset.map((e) => (
          <option value={e.value}>{e.title}</option>
        ))
      );
      setAnonymousDurationTypeList(
        res.anonymousRentalPeriodPreset.map((e) => (
          <option value={e.value}>{e.title}</option>
        ))
      );
    }
  };

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
      setEbookDetail(null);
    } else {
      reqDetailFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const copyToClipboard = () => {
    textAreaRef.current.select();
    textAreaRef.current.setSelectionRange(0, textAreaRef.current.value.length);
    document.execCommand("copy");
    message.success(
      <p
        style={{
          display: "inline-block",
          transform: "translateY(4px)",
          marginBottom: "5px",
        }}
      >
        คัดลอก UID แล้ว
      </p>,
      [2]
    );
  };

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  //   const blockEbook = () => {
  //     dispatch(actions.blockEbook(id)).then(() => {
  //       // refresh list after deletion
  //       dispatch(actions.fetchEbooks(ebooksUIProps.queryParams));
  //       // clear selections list
  //       ebooksUIProps.setIds([]);
  //       // closing block modal
  //       onHide();
  //     });
  //   };

  const classes = useStyles();

  const timeStampToYear = (t) => {
    if (t) {
      const date = new Date(t * 1000);
      const dateStr = date.getFullYear();
      return (
        <p style={{ marginBottom: "0px", display: "inline-block" }}>
          {dateStr}
        </p>
      );
    }
  };

  const openModalManageLicense = () => {
    setModalManageLicense(true);
    reqDetailFunc(2);
  };
  const submitModalManageLicense = async (private_) => {
    const obj = {
      rentalPeriod: parseInt(rentalPeriodValue, 10),
      anonymousRentalPeriod: parseInt(anonymousRentalPeriodValue, 10),
      concurrent:
        role === Role.SuperAdmin ? parseInt(concurrentValue, 10) : undefined,
    };
    const submitFunc = await axios.patch(
      `/admin/${private_ ? `private/` : ``}contents/${ebookDetail.contentUID}/${
        private_ ? `period` : `edit`
      }`,
      obj
    );
    if (submitFunc.data.status) {
      reqDetailFunc();
      if (ebooksUIProps.queryParams) {
        dispatch(
          actions.fetchEbooks(ebooksUIProps.queryParams, isPrivate, isFree)
        );
      }
      setConfirmModal(false);
      setModalManageLicense(false);
    }
  };
  const handleRentalPeriodChange = (e) => {
    setRentalPeriodValue(e.target.value);
  };
  const handleAnonymousRentalPeriodChange = (e) => {
    setAnonymousRentalPeriodValue(e.target.value);
  };
  const errorNumberFormat = (e) =>
    !e || parseInt(e, 10) < 1 || isNaN(parseInt(e, 10));

  const handleConcurrentChange = (e) => {
    const regex = RegExp(/^[0-9]+$/);
    if (e && e.target) {
      if (
        e.target.value &&
        e.target.value.length <= 3 &&
        regex.test(e.target.value)
      ) {
        setConcurrentValue(parseInt(e.target.value, 10));
      } else if (e.target.value === "") {
        setConcurrentValue(e.target.value);
      }
    }
    if (checkingError) {
      setCheckingError(false);
    }
  };

  const [checkCantManage, setCheckCantManage] = useState(true);

  useEffect(() => {
    if (isFree) {
      setCheckCantManage(true);
    } else {
      setCheckCantManage(false);
    }
  }, [isPrivate, isFree, authToken]);

  return (
    <>
      <Modal
        backdrop="static"
        show={confirmModal}
        onHide={() => setConfirmModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        style={{ margin: "3% auto" }}
      >
        <Modal.Body className={classes.body}>
          <img src="/media/members/image-modal.svg" alt="" />
          <div className={classes.title_confirm}>ยืนยันการจำกัดสิทธิ์</div>
          <div className={classes.divButton}>
            <button
              type="button"
              onClick={() => setConfirmModal(false)}
              className={classes.denyButton}
            >
              ยกเลิก
            </button>
            <button
              type="button"
              onClick={() => submitModalManageLicense(isPrivate)}
              className={classes.acceptButton}
            >
              ยืนยัน
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        size="sm"
        show={modalManageLicense}
        onHide={() => setModalManageLicense(false)}
        style={{ margin: "3% auto" }}
      >
        {console.log(durationTypeList)}
        <Modal.Body>
          <div
            style={{
              position: "absolute",
              zIndex: "5",
              top: "12px",
              right: "12px",
              textAlign: "right",
            }}
          >
            <button
              className={classes.closeButton}
              onClick={() => {
                setModalManageLicense(false);
              }}
            >
              <img src="/media/books/close-button.svg" alt="" />
            </button>
          </div>
          {(role !== Role.SuperAdmin &&
            durationTypeList &&
            durationTypeList.length > 1) ||
          (role === Role.SuperAdmin && concurrentValue !== undefined) ? (
            <>
              {role === Role.SuperAdmin ? (
                <div className={`col-12`} style={{ margin: "0 0 58px 0" }}>
                  <p className={classes.bigp}>จำนวนสิทธิ์</p>
                  <p className={classes.p}>จำนวนสิทธิ์ในการยืม</p>
                  <input
                    className={classes.slot}
                    onChange={handleConcurrentChange}
                    value={concurrentValue}
                  />
                  {errorNumberFormat(concurrentValue) && checkingError && (
                    <>
                      <div className="is-invalid"></div>
                      <div className="invalid-feedback">
                        กรุณากรอกจำนวนสิทธิ์ให้ถูกต้อง
                      </div>
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
              <div className={`col-12`}>
                <p className={classes.bigp}>สมาชิก</p>
                <p className={classes.p}>เวลาสูงสุดในการยืม</p>
                <select
                  className={classes.slot}
                  onChange={handleRentalPeriodChange}
                  value={rentalPeriodValue}
                >
                  {durationTypeList}
                </select>
              </div>
              <div className={`col-12`} style={{ margin: "58px 0" }}>
                <p className={classes.bigp}>สมาชิกไม่ระบุตัวตน</p>
                <p className={classes.p}>เวลาสูงสุดในการยืม</p>
                <select
                  className={classes.slot}
                  onChange={handleAnonymousRentalPeriodChange}
                  value={anonymousRentalPeriodValue}
                >
                  {anonymousDurationTypeList}
                </select>
              </div>
              <div className={`col-12`} style={{ textAlign: "center" }}>
                <button
                  type="button"
                  onClick={() => {
                    setCheckingError(true);
                    if (
                      (role === Role.SuperAdmin && concurrentValue) ||
                      role !== Role.SuperAdmin
                    ) {
                      setConfirmModal(true);
                    }
                  }}
                  className={classes.acceptButton}
                >
                  ตกลง
                </button>
              </div>
            </>
          ) : (
            <div
              style={{
                minHeight: `${loaderHeight}px`,
                textAlign: "center",
                padding: "20px 0 0 0",
                width: "100%",
              }}
            >
              <ClipLoader
                size={loaderHeight}
                color={"#123abc"}
                loading={true}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        size={ebookDetail === 0 ? "md" : "lg"}
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        onShow={() => {
          // console.log(window.scrollY);
        }}
      >
        {/*begin::Loading*/}
        {isLoading && <ModalProgressBar />}
        {/*end::Loading*/}
        {/* <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Ebook Block</Modal.Title>
      </Modal.Header> */}
        <Modal.Body className={classes.body}>
          {ebookDetail === 0 ? (
            ""
          ) : (
            <div style={{ textAlign: "right" }}>
              <button
                className={classes.closeButton}
                onClick={() => {
                  onHide();
                  setEbookDetail(null);
                }}
              >
                <img src="/media/books/close-button.svg" alt="" />
              </button>
            </div>
          )}
          {ebookDetail ? (
            <div className="EbookDetail">
              <div>
                <div>
                  <img src={ebookDetail.coverImage} alt="" />
                </div>
                <div>
                  <h2>{ebookDetail.title}</h2>
                  <div className="text-title mb">
                    {ebookDetail.authorName
                      ? ebookDetail.authorName === "ไม่มีข้อมูล"
                        ? ""
                        : ebookDetail.authorName
                      : ""}
                  </div>
                  {ebookDetail.publisherName ? (
                    <div className="text-grey">
                      สำนักพิมพ์ : {ebookDetail.publisherName}
                    </div>
                  ) : (
                    ""
                  )}
                  {ebookDetail.catName ? (
                    <div className="text-grey">
                      หมวดหมู่ : {ebookDetail.catName}{" "}
                      {ebookDetail.subcatName ? (
                        <div
                          className="text-grey"
                          style={{ display: "inline-block" }}
                        >
                          {" > "}
                          {ebookDetail.subcatName}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt mb">
                    {ebookDetail.ISBN ? (
                      <div className="text-grey mt">
                        ISBN : {ebookDetail.ISBN}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text-grey">
                      UID :{" "}
                      {document.queryCommandSupported("copy") && (
                        <input
                          ref={textAreaRef}
                          className="UID"
                          value={ebookDetail.contentUID}
                        />
                      )}
                      <button onClick={copyToClipboard} className="copyButton">
                        <img src="/media/books/copy-button.svg" alt="" />
                      </button>
                    </div>
                  </div>
                  {isFree ? (
                    ""
                  ) : catalogueId ? (
                    <div className="text-grey">
                      ปีที่ตีพิมพ์ :{" "}
                      {ebookDetail.publicationDate
                        ? timeStampToYear(ebookDetail.publicationDate)
                        : "-"}
                    </div>
                  ) : (
                    <>
                      <div className="text-grey">
                        วันที่เริ่มใช้งาน :{" "}
                        {ebookDetail.startDate ? (
                          <>
                            {dateStr(ebookDetail.startDate)}{" "}
                            {timeStr(ebookDetail.startDate)}
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                      <div className="text-red">
                        วันที่ยุติการอ่าน :{" "}
                        {ebookDetail.expireDate ? (
                          <>
                            {dateStr(ebookDetail.expireDate)}{" "}
                            {timeStr(ebookDetail.expireDate)}
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                    </>
                  )}
                  {isFree ? (
                    ""
                  ) : catalogueId ? (
                    <>
                      <div className="row" style={{ marginTop: "36px" }}>
                        <EbookElementNumber
                          title={"จำนวนสิทธิ์ที่มี"}
                          detail={
                            Number.isInteger(ebookDetail.purchasedConcurrent)
                              ? ebookDetail.purchasedConcurrent
                              : "-"
                          }
                        />
                        <EbookElementNumber
                          title={"จำนวนสิทธิ์ที่เลือก"}
                          detail={
                            Number.isInteger(ebookDetail.orderAmount)
                              ? ebookDetail.orderAmount
                              : "-"
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <div className="row" style={{ marginTop: "36px" }}>
                      <EbookElementNumber
                        title={"คงเหลือ"}
                        detail={
                          Number.isInteger(ebookDetail.concurrent) &&
                          Number.isInteger(ebookDetail.activeLicense)
                            ? ebookDetail.concurrent - ebookDetail.activeLicense
                            : "ไม่จำกัด"
                        }
                      />
                      <EbookElementNumber
                        title={"ทั้งหมด"}
                        detail={
                          Number.isInteger(ebookDetail.concurrent)
                            ? ebookDetail.concurrent
                            : "ไม่จำกัด"
                        }
                      />
                      <EbookElementNumber
                        title={"ยืมแล้ว"}
                        detail={ebookDetail.rentAmount}
                      />
                    </div>
                  )}
                </div>
              </div>
              <h3 className="mt">เรื่องย่อ </h3>
              <div className="text-description">
                {ebookDetail.description === "" || !ebookDetail.description
                  ? "-"
                  : ebookDetail.description
                  ? parse(ebookDetail.description)
                  : ""}
              </div>
              <div style={{ margin: "16px 0 24px", textAlign: "left" }}>
                {ebookDetail.tags &&
                  ebookDetail.tags.map((el, index) => (
                    <div
                      key={index}
                      style={{ display: "inline-block", marginRight: "8px" }}
                    >
                      <span className={classes.catsubcat}>{el}</span>
                    </div>
                  ))}
              </div>
              <div className="row">
                <div className="boxElement row">
                  <EbookElementNumber
                    title={"ขนาดไฟล์"}
                    detail={`${
                      ebookDetail.fileSize
                        ? ebookDetail.fileSize.toFixed(2)
                        : "- "
                    } MB`}
                  />
                  <EbookElementNumber
                    title={"รูปแบบไฟล์"}
                    detail={
                      ebookDetail.fileType && ebookDetail.fileType.toUpperCase()
                    }
                  />
                  <EbookElementNumber
                    title={"จำนวนหน้า"}
                    detail={ebookDetail.pageCount ? ebookDetail.pageCount : "-"}
                  />
                </div>
                <div className="boxButtonManage">
                  {catalogueId ? (
                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          if (ebookDetail && ebookDetail.samplePath) {
                            let link = document.createElement("a");
                            link.setAttribute("download", "template");
                            link.target = "_blank";
                            link.href = ebookDetail.samplePath;
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                          }
                        }}
                        className="button"
                      >
                        ดาวน์โหลดตัวอย่าง
                      </button>
                    </div>
                  ) : checkCantManage || (isPrivate && !concurrentValue) ? (
                    ``
                  ) : (
                    <div>
                      <button
                        type="button"
                        onClick={() => openModalManageLicense()}
                        className="button"
                        // style={{ marginBottom: "24px" }}
                      >
                        จัดการสิทธิ์
                      </button>
                    </div>
                  )}
                  {isPrivate ? (
                    <div
                    // className={`col-lg-${
                    //   checkCantManage || (isPrivate && !concurrentValue) ? 6 : 2
                    // } text-right`}
                    // style={{
                    //   alignSelf: "flex-end",
                    //   paddingLeft:
                    //     checkCantManage || (isPrivate && !concurrentValue)
                    //       ? "12.5px"
                    //       : "0px",
                    // }}
                    >
                      <button
                        type="button"
                        onClick={() => {
                          const id_ = ebookDetail.contentUID;
                          history.push(`/ebooks-private?id=${id_}&edit=true`);
                        }}
                        className="button"
                        // style={{ marginBottom: "24px" }}
                      >
                        แก้ไข
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : ebookDetail === 0 ? (
            <div>
              <img src="/media/members/image-modal.svg" alt="" />
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  color: "#d7042b",
                  marginBottom: "12px",
                }}
              >
                <p>กำลังดำเนินการ...</p>
              </div>
              <div className={classes.divButton}>
                <button
                  type="button"
                  onClick={() => {
                    onHide();
                    setEbookDetail(null);
                  }}
                  style={{
                    margin: "8px",
                    color: "#ffffff",
                    fontSize: "18px",
                    fontWeight: 600,
                    width: "160px",
                    height: "48px",
                    borderRadius: "100px",
                    backgroundColor: "#012875",
                    border: "none",
                  }}
                >
                  ตกลง
                </button>
              </div>
            </div>
          ) : (
            <div
              style={{
                minHeight: `${loaderHeight}px`,
                textAlign: "center",
                padding: "20px 0 0 0",
                width: "100%",
              }}
            >
              <ClipLoader
                size={loaderHeight}
                color={"#123abc"}
                loading={true}
              />
            </div>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            Cancel
          </button>
          <> </>
          <button type="button" onClick={blockEbook} className="btn btn-primary btn-elevate">
            Block
          </button>
        </div>
      </Modal.Footer> */}
      </Modal>
    </>
  );
}

export const EbookElementNumber = (props) => {
  return (
    <div className="col center">
      <div className="text-primary-big">{props.title}</div>
      <div className="text-second-big">{props.detail}</div>
    </div>
    // <div className={`col-lg-${6} text-center ${classes.textBlue16}`}>
    //   จำนวนสิทธิ์ที่มี
    //   <p className={classes.textOrangeBig}>
    //     {Number.isInteger(ebookDetail.purchasedConcurrent)
    //       ? ebookDetail.purchasedConcurrent
    //       : "-"}
    //   </p>
    // </div>
  );
};
