import { createSlice } from '@reduxjs/toolkit';
import { Member, LastEvaluatedKey_membersData } from '../../../../../interface';
export interface CustomersStateRedux {
   listLoading: boolean;
   actionsLoading: boolean;
   totalCount: number;
   entities?: Member[];
   customerForEdit?: Member;
   lastError?: string | null;
   LastEvaluatedKey?: LastEvaluatedKey_membersData;
   rowSelected: string[];
   error?: object | null;
   errorMessage?: string;
}
const initialCustomersState: CustomersStateRedux = {
   listLoading: false,
   actionsLoading: false,
   totalCount: 0,
   entities: [],
   rowSelected: [],
   customerForEdit: undefined,
   lastError: null,
   LastEvaluatedKey: undefined
};
export const callTypes = {
   list: 'list',
   action: 'action'
};

export const customersSlice = createSlice({
   name: 'customers',
   initialState: initialCustomersState,
   reducers: {
      catchError: (state, action) => {
         if (
            action.payload.error &&
            action.payload.error.response &&
            action.payload.error.response.data
         ) {
            state.error = action.payload.error.response.data.errorCode;
            state.errorMessage = action.payload.error.response.data.message;
         }
         if (action.payload.callType === callTypes.list) {
            state.listLoading = false;
         } else {
            state.actionsLoading = false;
         }
      },
      startCall: (state, action) => {
         state.error = null;
         if (action.payload.callType === callTypes.list) {
            state.listLoading = true;
         } else {
            state.actionsLoading = true;
         }
      },
      checkedRow: (state, action) => {
         console.log('check row');

         if (action.payload.checked && action.payload.memberUID) {
            state.rowSelected = [
               ...state.rowSelected,
               action.payload.memberUID
            ];
         } else if (action.payload.memberUID) {
            let rowFilter = state.rowSelected.filter(
               el => el !== action.payload.memberUID
            );
            state.rowSelected = rowFilter;
         }
         console.log(state.rowSelected);
      },
      // getCustomerById
      customerFetched: (state, action) => {
         state.actionsLoading = false;
         state.customerForEdit = action.payload.customerForEdit;
         state.error = null;
      },
      // findCustomers
      customersFetched: (state, action) => {
         const {
            totalCount,
            entities,
            LastEvaluatedKey,
            lastEvaluate,
            keyword
         } = action.payload;
         state.listLoading = false;
         state.error = null;
         if (
            lastEvaluate === undefined ||
            (keyword !== '' &&
               JSON.stringify(lastEvaluate) ===
                  JSON.stringify({ lastPosition: 20 }))
         ) {
            state.entities = entities;
         } else {
            entities.forEach((item: Member) => state.entities?.push(item));
         }
         state.totalCount = totalCount;
         state.LastEvaluatedKey = LastEvaluatedKey;
         // state.entities = mockUsers;
         // state.totalCount = 17;
      },
      // createCustomer
      customerCreated: state => {
         state.actionsLoading = false;
         state.error = null;
         // state.entities.push(action.payload.customer);
      },
      // updateCustomer
      customerUpdated: state => {
         state.error = null;
         state.actionsLoading = false;
         // state.entities = state.entities.map((entity) => {
         //   if (entity.memberUID === action.payload.customer.memberUID) {
         //     return action.payload.customer;
         //   }
         //   return entity;
         // });
      },
      // blockCustomer
      customerBlocked: (state, action) => {
         state.error = null;
         state.actionsLoading = false;
      },
      // deleteCustomer
      customerDeleted: (state, action) => {
         state.error = null;
         state.actionsLoading = false;
         state.entities = state.entities?.filter(
            el => el.memberUID !== action.payload.id
         );
      }
   }
});
