import React, { useState, useEffect } from "react";
import { PodcastsCard } from "./PodcastsCard";
import { Route } from "react-router-dom";
import { PodcastsUIProvider } from "./PodcastsUIContext";
import { PodcastUploadDialog } from "./podcast-upload-dialog/PodcastUploadDialog";
import { PodUploadDialog } from "./podcast-upload-dialog/PodUploadDialog";
import { shallowEqual, useSelector } from "react-redux";
import axios from "axios";
import queryString from "query-string";
import { PodcastEditDialog } from "./podcast-edit-dialog/PodcastEditDialog";
import { PodcastViewDialog } from "./podcast-view-dialog/PodcastViewDialog";
import { PodcastDeleteDialog } from "./podcast-delete-dialog/PodcastDeleteDialog";
import { PodcastBlockDialog } from "./podcast-block-dialog/PodcastBlockDialog";
import { PodcastUnblockDialog } from "./podcast-unblock-dialog/PodcastUnblockDialog";
import { ChannelNewDialog } from "../channels-podcast/channel-new-dialog/ChannelNewDialog";
import { CategoryNewDialog } from "../categories/category-new-dialog/CategoryNewDialog";
import { UploadInformationDialog } from "../upload/upload-information-dialog/UploadInformationDialog";
import { Card, CardBody } from "../../../../../_metronic/_partials/controls";

export function PodcastsPrivatePage(props) {
  const { history, location } = props;
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [isEdit, setEdit] = useState(0);
  const [isDelete, setDelete] = useState(0);
  const [isNewPod, setNewPod] = useState(undefined);
  const [isNewPodcast, setNewPodcast] = useState(undefined);
  const [isNewChannel, setNewChannel] = useState(0);
  const [isNewCreator, setNewCreator] = useState(0);
  const [isBlock, setBlock] = useState(false);
  const [isUnblock, setUnblock] = useState(false);
  const [isNewCat, setNewCat] = useState(0);
  const [isNewSubcat, setNewSubcat] = useState(0);
  const [view, setView] = useState(false);
  const [episodeId, setEpisodeId] = useState(undefined);
  const [isViewEP, setViewEP] = useState(0);

  const [errorMessage, setErrorMessage] = useState(undefined);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.name) {
      setName(parsed.name);
    }
    if (parsed.edit) {
      setEdit(parsed.edit);
    }
    if (parsed.newPod) {
      setNewPod(1);
    } else {
      setNewPod(undefined);
    }
    if (parsed.upload) {
      setNewPodcast(1);
    } else {
      setNewPodcast(undefined);
    }
    if (parsed.newChannel) {
      setNewChannel(parsed.newChannel);
    } else {
      // setNewChannel(0);
    }
    if (parsed.newCreator) {
      // setNewCreator(parsed.newCreator);
    }
    if (parsed.newCat) {
      setNewCat(parsed.newCat);
    }
    if (parsed.newSubcat) {
      setNewSubcat(parsed.newSubcat);
    }
    if (parsed.id) {
      setId(parsed.id);
    } else {
      if(!parsed.newChannel)
      setId(null);
    }
    if (
      parsed.episodeIdDelete ||
      parsed.episodeIdEdit ||
      parsed.episodeIdBlock ||
      parsed.episodeIdUnblock ||
      parsed.episodeIdView
    ) {
      setEpisodeId(
        parsed.episodeIdDelete ||
          parsed.episodeIdEdit ||
          parsed.episodeIdBlock ||
          parsed.episodeIdUnblock ||
          parsed.episodeIdView
      );
    } else {
      setEpisodeId(undefined);
    }
    if (parsed.view) {
      setView(parsed.view);
    } else {
      setView(false);
    }
    if (parsed.delete) {
      setDelete(parsed.delete);
    }
    if (parsed.block) {
      setBlock(parsed.block);
    }
    if (parsed.unblock) {
      setUnblock(parsed.unblock);
    }
    if (parsed.viewEP) {
      setViewEP(parsed.viewEP);
    }
  }, [location.search]);

  const podcastsUIEvents = {
    openFileInformationDialog: (error, message) => {
      if (error) {
        setErrorMessage(message);
      }
    },
    newPodButtonClick: () => {
      history.push(`/podcasts-private?newPod=true`);
    },
    newPodcastButtonClick: () => {
      history.push(`/podcasts-private?id=${id}&upload=true`);
    },
    newChannelButtonClick: (mode) => {
      if (mode === "pod") {
        history.push("/podcasts-private?newPod=true&newChannel=true");
      } else if (mode === "podcast") {
        history.push("/podcasts-private?newPodcast=true&newChannel=true");
      } else {
        history.push(`/podcasts-private?id=${id}&newChannel=true`);
      }
    },
    newCreatorButtonClick: (mode) => {
      if (mode === "pod") {
        history.push("/podcasts-private?newPod=true&newCreator=true");
      } else if (mode === "podcast") {
        history.push("/podcasts-private?newPodcast=true&newCreator=true");
      } else {
        history.push("/podcasts-private?newCreator=true");
      }
    },
    newCategoryButtonClick: (mode) => {
      if (mode === "pod") {
        history.push("/podcasts-private?newPod=true&newCat=true");
      } else if (mode === "podcast") {
        history.push("/podcasts-private?newPodcast=true&newCat=true");
      } else {
        history.push(`/podcasts-private?id=${id}&edit=true&newCat=true`);
      }
    },
    newSubCategoryButtonClick: (mode) => {
      if (mode === "pod") {
        history.push("/podcasts-private?newPod=true&newSubcat=true");
      } else if (mode === "podcast") {
        history.push("/podcasts-private?newPodcast=true&newSubcat=true");
      } else {
        history.push(`/podcasts-private?id=${id}&edit=true&newSubcat=true`);
      }
    },
    openViewPodcastDialog: (id_, mode) => {
      console.log(mode);
      if (mode === "pod") {
        history.push(`/podcasts-private?id=${id_}&view=true`);
      } else if (mode === "podcast") {
        history.push(
          `/podcasts-private?id=${id}&view=true&viewEP=true&episodeIdView=${id_}`
        );
      }
    },
    openDeletePodDialog: (name, id) => {
      history.push(
        `/podcasts-private?name=${encodeURIComponent(
          name
        )}&id=${id}&delete=true`
      );
    },
    openDeletePodcastDialog: (name, id_) => {
      history.push(
        `/podcasts-private?name=${encodeURIComponent(
          name
        )}&id=${id}&view=true&delete=true&episodeIdDelete=${id_}`
      );
    },
    openBlockPodDialog: (name, id) => {
      history.push(
        `/podcasts-private?name=${encodeURIComponent(name)}&id=${id}&block=true`
      );
    },
    openBlockPodcastDialog: (name, id_) => {
      history.push(
        `/podcasts-private?name=${encodeURIComponent(
          name
        )}&id=${id}&view=true&block=true&episodeIdBlock=${id_}`
      );
    },
    openUnblockPodDialog: (name, id) => {
      history.push(
        `/podcasts-private?name=${encodeURIComponent(
          name
        )}&id=${id}&unblock=true`
      );
    },
    openUnblockPodcastDialog: (name, id_) => {
      history.push(
        `/podcasts-private?name=${encodeURIComponent(
          name
        )}&id=${id}&view=true&unblock=true&episodeIdUnblock=${id_}`
      );
    },
    openEditPodDialog: (id) => {
      history.push(`/podcasts-private?id=${id}&edit=true`);
    },
    openEditPodcastDialog: (id_) => {
      history.push(
        `/podcasts-private?id=${id}&view=true&edit=true&episodeIdEdit=${id_}`
      );
    },
  };

  return (
    <PodcastsUIProvider podcastsUIEvents={podcastsUIEvents}>
      {isViewEP ? (
        <PodcastViewDialog
          id={episodeId}
          isPrivate={1}
          show={true}
          onHide={() => {
            history.push(`/podcasts-private?id=${id}&view=true`);
            setViewEP(false);
          }}
        />
      ) : (
        ""
      )}
      {isNewChannel ? (
        <ChannelNewDialog
          show={true}
          onHide={() => {
            if (isNewPod) {
              history.push(`/podcasts-private?newPod=true`);
            } else {
              history.push(`/podcasts-private?id=${id}`);
            }
            setNewChannel(0);
          }}
          isPrivate={1}
          notOrigin={true}
        />
      ) : (
        ""
      )}
      {isEdit ? (
        <PodcastEditDialog
          show={true}
          id={id}
          episodeId={episodeId}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push(
              episodeId
                ? `/podcasts-private?id=${id}&view=true`
                : "/podcasts-private"
            );
            setEdit(0);
          }}
        />
      ) : (
        ""
      )}
      {errorMessage ? (
        <UploadInformationDialog
          show={true}
          onHide={() => {
            history.push(`/podcasts-private`);
            setErrorMessage(undefined);
          }}
          isError={true}
          errorMessage={errorMessage}
        />
      ) : (
        ""
      )}
      {/*isNewCreator ? (
        <ChannelNewDialog
          show={true}
          onHide={() => {
            if (isNewPodcast) {
              history.push(`/podcasts-private?newPodcast=true`);
            } else {
              history.push(`/podcasts-private`);
            }
            setNewCreator(0);
          }}
          isPrivate={1}
          isCreator={true}
          notOrigin={true}
        />
      ) : (
        ""
      )*/}
      {isNewCat ? (
        <CategoryNewDialog
          show={true}
          onHide={() => {
            if (isNewPod) {
              history.push(`/podcasts-private?newPod=true`);
            } else {
              history.push(`/podcasts-private?id=${id}&edit=true`);
            }
            setNewCat(0);
          }}
          isPrivate={1}
          history={history}
          notOrigin={true}
          contentType="podcast"
        />
      ) : (
        ""
      )}
      {isNewSubcat ? (
        <CategoryNewDialog
          show={true}
          onHide={() => {
            if (isNewPod) {
              history.push(`/podcasts-private?newPod=true`);
            } else {
              history.push(`/podcasts-private?id=${id}&edit=true`);
            }
            setNewSubcat(0);
          }}
          isPrivate={1}
          isSubCat={true}
          history={history}
          notOrigin={true}
          contentType="podcast"
        />
      ) : (
        ""
      )}
      {isDelete ? (
        <PodcastDeleteDialog
          show={true}
          name={name}
          id={id}
          episodeId={episodeId}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push(
              episodeId
                ? `/podcasts-private?id=${id}&view=true`
                : "/podcasts-private"
            );
            setDelete(false);
          }}
        />
      ) : (
        ""
      )}
      {isUnblock ? (
        <PodcastUnblockDialog
          show={true}
          name={name}
          id={id}
          episodeId={episodeId}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push(
              episodeId
                ? `/podcasts-private?id=${id}&view=true`
                : "/podcasts-private"
            );
            setUnblock(false);
          }}
        />
      ) : (
        ""
      )}
      {isBlock ? (
        <PodcastBlockDialog
          show={true}
          name={name}
          id={id}
          episodeId={episodeId}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push(
              episodeId
                ? `/podcasts-private?id=${id}&view=true`
                : "/podcasts-private"
            );
            setBlock(false);
          }}
        />
      ) : (
        ""
      )}
      {isNewPodcast ? (
        <Card
          style={{
            boxShadow: "none",
            borderRadius: "0.42rem",
          }}
        >
          <CardBody>
            <PodcastUploadDialog
              show={true}
              id={id}
              isPrivate={1}
              onHide={() => {
                history.push(`/podcasts-private?id=${id}&view=true`);
                setNewPodcast(0);
              }}
            />
          </CardBody>
        </Card>
      ) : isNewPod ? (
        <PodUploadDialog
          show={true}
          onHide={() => {
            history.push("/podcasts-private");
            setNewPod(0);
          }}
        />
      ) : (
        <PodcastsCard
          isPrivate={1}
          isPodcast={view && id ? { id: id } : { id: 0 }}
        />
      )}
    </PodcastsUIProvider>
  );
}
