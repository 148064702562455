import React, { useEffect, useMemo ,useState} from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/ebooks/ebooksActions";
import { useEbooksUIContext } from "../EbooksUIContext";
import { useStyles } from "../../../partials/useStyles";

export function EbookDeleteDialog({ name, id, isPrivate, show, onHide }) {
     // Ebooks UI Context
     const modalClasses = useStyles();
     const ebooksUIContext = useEbooksUIContext();
     const ebooksUIProps = useMemo(() => {
          return {
               setIds: ebooksUIContext.setIds,
               queryParams: ebooksUIContext.queryParams,
          };
     }, [ebooksUIContext]);

     // Ebooks Redux state
     const dispatch = useDispatch();
     const { isLoading } = useSelector((state) => ({ isLoading: state.ebooks.actionsLoading }), shallowEqual);

     // if !id we should close modal
     useEffect(() => {
          if (!id) {
               onHide();
          }

          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [id]);

     // looking for loading/dispatch
     useEffect(() => {}, [isLoading, dispatch]);

     const deleteEbook = () => {
          // server request for deleting ebook by id
          dispatch(actions.deleteEbook(id, isPrivate,{forceReturn:false})).then(() => {
               // refresh list after deletion
               dispatch(actions.fetchEbooks(ebooksUIProps.queryParams, isPrivate));
               // clear selections list
               ebooksUIProps.setIds([]);
               // closing block modal
               onHide();
          });
     };

     return (
          <Modal
               backdrop="static"
               show={show}
               onHide={onHide}
               aria-labelledby="example-modal-sizes-title-lg"
               style={{ margin: "3% auto" }}
          >
               {isLoading && <ModalProgressBar />}
               <Modal.Body className={modalClasses.body}>
                    {name ? (
                         <>
                              <img src="/media/members/image-modal.svg" alt="" />
                              <div className="text-text-red">ยืนยันการลบ</div>
                              <div className="text-text-gray">ต้องการลบ {name} หรือไม่?</div>

                              <div className="mt-3">
                                   <button type="button" onClick={onHide} className="denyButton ">
                                        ยกเลิก
                                   </button>
                                   <button type="button" onClick={deleteEbook} className="acceptButton ">
                                        ยืนยัน
                                   </button>
                              </div>
                         </>
                    ) : (
                         ""
                    )}
               </Modal.Body>
          </Modal>
     );
}
