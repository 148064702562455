/* eslint-disable no-dupe-keys */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { Input, Select } from "../../../../../../_metronic/_partials/controls";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import * as actions from "../../../_redux/customers/qrcodesActions";
import { useCustomersUIContext } from "../CustomersUIContext";
import { GoogleMap, LoadScript, Autocomplete, Marker, Circle } from "@react-google-maps/api";
import { DatePicker, Switch } from "antd";
import moment from "moment";
import axios from "axios";
import domtoimage from "dom-to-image";
import "antd/dist/antd.css";
const { RangePicker } = DatePicker;

const useStyles = makeStyles(() => ({
  head: {
    fontSize: "20px",
    fontWeight: "600",
    margin: "16px auto 0",
    color: "#012875",
  },
  input: { margin: "12px 0" },
  denyButton: {
    margin: "8px",
    width: "150px",
    height: "48px",
    borderRadius: "100px",
    border: "solid 1px #012875",
    backgroundColor: "#ffffff",
    fontWeight: "600",
    color: "#012875",
    fontSize: "15px",
  },
  acceptButton: {
    margin: "8px",
    width: "150px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "#012875",
    border: "none",
    color: "white",
    fontWeight: "600",
    fontSize: "16px",
  },
  divButton: { marginTop: "32px", textAlign: "center" },
  qrcodeImageDiv: {
    width: "700px",
    height: "700px",
    borderRadius: "31px",
    backgroundColor: "#012875",
    // margin: "auto",
    textAlign: "center",
  },
  acceptButtonLong: {
    margin: "8px",
    width: "200px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "#012875",
    border: "none",
    color: "white",
    fontWeight: "600",
    fontSize: "16px",
  },
  qrcodeTextBottom: {
    fontSize: "26px",
    color: "white",
    textAlign: "center",
    fontWeight: "500",
  },
  qrcodeTextBottom_18px: {
    fontSize: "18px",
    color: "white",
    textAlign: "center",
    fontWeight: "500",
  },
  hytextsLogoDiv: {
    width: "182px",
    height: "88px",
    backgroundColor: "#ffffff",
    margin: "auto",
    borderBottomLeftRadius: "31px",
    borderBottomRightRadius: "31px",
    textAlign: "center",
  },
  hytextsLogo: {
    width: "134.7px",
    height: "46.9px",
    marginTop: "16px",
  },
  qrcodeImage: {
    width: "451px",
    height: "451px",
    borderRadius: "10px",
    margin: "29px auto",
  },
  anonymousLabel: {
    border: "1px solid #000000",
    display: "inline-block",
    borderRadius: "12px",
    padding: "0 10px",
  },
}));

// Validation schema
const qrCodeSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "อย่างน้อย 1 ตัวอักษร")
    .max(200, "ไม่เกิน 200 ตัวอักษร")
    .required("กรุณากรอกชื่อ QR Code"),
  latitude: Yup.number()
    .typeError("กรุณาใช้ตัวเลข")
    .required("กรุณากรอกค่า Latitude หรือปักหมุด"),
  longitude: Yup.number()
    .typeError("กรุณาใช้ตัวเลข")
    .required("กรุณากรอกค่า Longitude หรือปักหมุด"),
});
const qrCodeEditingSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "อย่างน้อย 1 ตัวอักษร")
    .max(200, "ไม่เกิน 200 ตัวอักษร")
    .required("กรุณากรอกชื่อ QR Code"),
  latitude: Yup.number().typeError("กรุณาใช้ตัวเลข"),
  longitude: Yup.number().typeError("กรุณาใช้ตัวเลข"),
});

export function QrcodeMemberMapForm({ data, onHide }) {
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
    };
  }, [customersUIContext]);
  const dispatch = useDispatch();
  const [zoom, setZoom] = useState(16);
  const [center, setCenter] = useState({
    lat: 13.8118153,
    lng: 100.5638696,
  });
  const [latValue, setLatValue] = useState(null);
  const [lngValue, setLngValue] = useState(null);
  const [latLngArr, setLatLngArr] = useState([]);
  const [markerPositionArr, setMarkerPositionArr] = useState([]);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [isMarkerShown, setIsMarkerShown] = useState(false);

  const styles = useStyles();

  useEffect(() => {
    if (Array.isArray(data)) {
      let acc_lat = 0;
      let acc_lng = 0;
      setLatLngArr(
        data.map((el) => {
          if (
            el.registLocation &&
            Number(el.registLocation.latitude) === el.registLocation.latitude &&
            Number(el.registLocation.longitude) === el.registLocation.longitude
          ) {
            acc_lat += el.registLocation.latitude;
            acc_lng += el.registLocation.longitude;
            return el.registLocation;
          } else {
            return {
              latitude: 13.8118153,
              longitude: 100.5638696,
            };
          }
        })
      );
      setCenter({ lat: acc_lat / data.length, lng: acc_lng / data.length });
      setIsMarkerShown(true);
      setZoom(10);
    } else {
      if (data && data.registLocation) {
        const lat = data.registLocation.latitude;
        const lng = data.registLocation.longitude;
        setLatValue(lat);
        setLngValue(lng);
        setCenter({ lat: lat, lng: lng });
        setIsMarkerShown(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (latLngArr.length && window.google) {
      setMarkerPositionArr(
        latLngArr.map((e) => new window.google.maps.LatLng(e.latitude, e.longitude))
      );
    } else {
      if (latValue && lngValue && window.google) {
        const newPos = new window.google.maps.LatLng(latValue, lngValue);
        setMarkerPosition(newPos);
      }
    }
  }, [latValue, lngValue, latLngArr]);

  let iconMarker = new window.google.maps.MarkerImage(
    "/media/members/reddot.svg",
    null /* size is determined at runtime */,
    null /* origin is 0,0 */,
    null /* anchor is bottom center of the scaled image */,
    new window.google.maps.Size(16, 16)
  );
  return (
    <>
      <p className={styles.head}>{markerPositionArr.length ? "ภาพรวมแผนที่" : "พิกัดการสแกน"}</p>
      <Modal.Body>
        {/*<div style={{ width: "500px", margin: "auto" }}>
          <div className="row" style={{ minHeight: "108px" }}>
            <div className={`col-lg-6 ${styles.input}`}>
             <Field
              name="latitude"
              component={Input}
              placeholder="0.000000"
              label="Latitude"
              onKeyUp={handleLatitudeChange}
            />
            </div>
            <div className={`col-lg-6 ${styles.input}`}>
               <Field
              name="longitude"
              component={Input}
              placeholder="0.000000"
              label="Longitude"
              onKeyUp={handleLongitudeChange}
            />
            </div>
          </div>
        </div> */}
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "400px",
            borderRadius: "5px",
            marginTop: "10px",
          }}
          zoom={zoom}
          center={center}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
        >
          {isMarkerShown && markerPositionArr.length ? (
            markerPositionArr.map((e) => <Marker position={e} icon={iconMarker} />)
          ) : (
            <Marker position={markerPosition} icon={iconMarker} />
          )}
        </GoogleMap>
        {/* <MapWithASearchBox /> */}
        <div className={styles.divButton}>
          <button className={styles.acceptButton} type="button" onClick={onHide}>
            ตกลง
          </button>
        </div>
      </Modal.Body>
    </>
  );
}
