import axios from "axios";

// export const BANNERS_URL = "api/banners";
export const BANNERS_URL = `/admin/banners`;
export const PRIVATE_BANNERS_URL = `/admin/banners`;

// READ
export function getAllBanners() {
  return axios.get(BANNERS_URL);
}

export function getBannerById(bannerId) {
  return axios.get(`${BANNERS_URL}/${bannerId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findBanners(queryParams, isPrivate, lastEvaluate) {
  if (!queryParams.keyword) {
    // return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
    let limit = "";
    let lastKeyQuery = "";

    if (queryParams && queryParams.pageSize) {
      limit = `?limit=${queryParams.pageSize}`;
    }
    if (lastEvaluate !== undefined && JSON.stringify(lastEvaluate) !== JSON.stringify({})) {
      lastKeyQuery = "&lastEvaluatedKey=" + btoa(JSON.stringify(lastEvaluate));
    }
    return axios.get(`${isPrivate ? PRIVATE_BANNERS_URL : BANNERS_URL}${limit}${lastKeyQuery}`);
  } else {
    return axios.post(`${isPrivate ? PRIVATE_BANNERS_URL : BANNERS_URL}/search`, {
      keyword: queryParams.keyword,
    });
  }
  // return axios.post(`${BANNERS_URL}/find`, { queryParams });
}

// CREATE =>  POST: add a new banner to the server
export function createBanner(banner, isPrivate) {
  return axios.post(`${isPrivate ? PRIVATE_BANNERS_URL : BANNERS_URL}/create`, banner);
}

// UPDATE => PUT: update the banner on the server
export function updateBanner(banner, isPrivate) {
  return axios.put(`${isPrivate ? PRIVATE_BANNERS_URL : BANNERS_URL}/${banner.uid}`, banner);
}

export function updateBannerStatus(id, value, isPrivate) {
  return axios.patch(`${isPrivate ? PRIVATE_BANNERS_URL : BANNERS_URL}/${id}/status`, {
    status: !value ? 2 : 1,
  });
}

// UPDATE Status
export function updateStatusForBanners(ids, status) {
  return axios.post(`${BANNERS_URL}/updateStatusForBanners`, {
    ids,
    status,
  });
}

// DELETE => delete the banner from the server
export function deleteBanner(bannerId, isPrivate) {
  return axios.delete(`${isPrivate ? PRIVATE_BANNERS_URL : BANNERS_URL}/${bannerId}`);
}

export function blockBanner(bannerId, banner, isPrivate) {
  return axios.patch(
    `${isPrivate ? PRIVATE_BANNERS_URL : BANNERS_URL}/${bannerId}`,
    Object.assign({}, banner, { status: 0 })
  );
}

// DELETE Banners by ids
export function deleteBanners(ids) {
  return axios.post(`${BANNERS_URL}/deleteBanners`, { ids });
}
