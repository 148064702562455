import React, { createContext, useContext, useState, useCallback } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './CompaniesUIHelpers';

const CompaniesUIContext = createContext();

export function useCompaniesUIContext() {
   return useContext(CompaniesUIContext);
}

export const CompaniesUIConsumer = CompaniesUIContext.Consumer;

export function CompaniesUIProvider({ companiesUIEvents, children }) {
   const [queryParams, setQueryParamsBase] = useState(initialFilter);
   const [ids, setIds] = useState([]);
   const [currentTab,setCurrentTab] =useState('School')
   const setQueryParams = useCallback(nextQueryParams => {
      setQueryParamsBase(prevQueryParams => {
         if (isFunction(nextQueryParams)) {
            nextQueryParams = nextQueryParams(prevQueryParams);
         }

         if (isEqual(prevQueryParams, nextQueryParams)) {
            return prevQueryParams;
         }

         return nextQueryParams;
      });
   }, []);

   const initCompany = {
      id: undefined,
      firstName: '',
      lastName: '',
      email: '',
      userName: '',
      gender: 'Female',
      status: 0,
      dateOfBbirth: '',
      ipAddress: '',
      type: 1
   };

   const value = {
      queryParams,
      setQueryParamsBase,
      ids,
      setIds,
      setQueryParams,
      initCompany,
      newCustomerButtonClick: companiesUIEvents.newCustomerButtonClick,
      openEditCompanyDialog: companiesUIEvents.openEditCompanyDialog,
      openEditColorDialog: companiesUIEvents.openEditColorDialog,
      openViewAsPage: companiesUIEvents.openViewAsPage,
      openViewCompanyDialog: companiesUIEvents.openViewCompanyDialog,
      openDeleteCompanyDialog: companiesUIEvents.openDeleteCompanyDialog,
      openCompanyClearStoreDialog:
         companiesUIEvents.openCompanyClearStoreDialog,
      openCompanyClearMember: companiesUIEvents.openCompanyClearMember,
      openBlockCompanyDialog: companiesUIEvents.openBlockCompanyDialog,
      openUnblockCompanyDialog: companiesUIEvents.openUnblockCompanyDialog,
      openDeleteCompaniesDialog: companiesUIEvents.openDeleteCompaniesDialog,
      openFetchCompaniesDialog: companiesUIEvents.openFetchCompaniesDialog,
      openUpdateCompaniesStatusDialog:
         companiesUIEvents.openUpdateCompaniesStatusDialog,
      newPublisherButtonClick: companiesUIEvents.newPublisherButtonClick,
      newAuthorButtonClick: companiesUIEvents.newAuthorButtonClick,
      newCategoryButtonClick: companiesUIEvents.newCategoryButtonClick,
      newSubCategoryButtonClick: companiesUIEvents.newSubCategoryButtonClick,
      openExtendPeriodDialog: companiesUIEvents.openExtendPeriodDialog,
      currentTab,
      setCurrentTab
   };

   return (
      <CompaniesUIContext.Provider value={value}>
         {children}
      </CompaniesUIContext.Provider>
   );
}
