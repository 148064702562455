import { createSlice } from "@reduxjs/toolkit";
import {
  Publisher,
  AuthorSearch
  
} from "../../../../../interface";
interface publishersState{
  listLoading: boolean,
  actionsLoading: boolean,
  totalCount:number,
  entities: Publisher[],
  authorsForEdit: AuthorSearch[],
  lastError: null,
  LastEvaluatedKey: {},
  publisherIdOfAuthors: undefined,
  error?: object | null
}
const initialPublishersState :publishersState= {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  authorsForEdit: [],
  lastError: null,
  LastEvaluatedKey: {},
  publisherIdOfAuthors: undefined,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const publishersSlice = createSlice({
  name: "publishers",
  initialState: initialPublishersState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.error && action.payload.error.response && action.payload.error.response.data) {
        state.error = {
          from: action.payload.from,
          errorCode: action.payload.error.response.data.errorCode,
        };
      }
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getAuthorsById
    authorsFetched: (state, action) => {
      const { authors, publisherId, memberMode } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      if (memberMode) {
        state.authorsForEdit = authors.filter((e:AuthorSearch) => e.status === parseInt(memberMode, 10));
      } else {
        state.authorsForEdit = authors;
      }
      state.publisherIdOfAuthors = publisherId;
      if (authors) {
        state.totalCount = authors.length;
      } else {
        state.totalCount = 0;
      }
      state.error = null;
    },
    // findPublishers
    publishersFetched: (state, action) => {
      const { totalCount, entities, LastEvaluatedKey, lastEvaluate, memberMode } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      if (lastEvaluate === undefined) {
        if (memberMode) {
          state.entities = entities.filter((e:Publisher) => e.status === parseInt(memberMode, 10));
        } else {
          state.entities = entities;
        }
      } else {
        if (memberMode) {
          entities
            .filter((e:Publisher) => e.status === parseInt(memberMode, 10))
            .forEach((item:Publisher) => state.entities.push(item));
        } else {
          entities.forEach((item:Publisher) => state.entities.push(item));
        }
      }
      state.totalCount = totalCount;
      state.LastEvaluatedKey = LastEvaluatedKey;
      // state.entities = mockUsers;
      // state.totalCount = 17;
    },
    // createPublisher
    publisherCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.publisher);
      state.totalCount += 1;
    },
    authorCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      if (action.payload.publisherId === state.publisherIdOfAuthors) {
        state.authorsForEdit.push(action.payload.author);
      }
    },
    // updatePublisher
    publisherUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.uid === action.payload.publisher.uid) {
          return action.payload.publisher;
        }
        return entity;
      });
    },
    authorUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.authorsForEdit = state.authorsForEdit.map((el) => {
        if (el.uid === action.payload.authorId) {
          return Object.assign({}, el, action.payload.author);
        }
        return el;
      });

      Object.assign(
        {},
        ...state.authorsForEdit,
        ...state.authorsForEdit.filter((el) => el.uid === action.payload.author.authorId)
      );
    },
    // deletePublisher
    publisherDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => el.id !== action.payload.id);
    },
    authorDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.authorsForEdit = state.authorsForEdit.filter(
        (el) => el.uid !== action.payload.authorsId
      );
    },
 
  

    elementUpdated: (state) => {
      state.actionsLoading = false;
    },
  },
});
