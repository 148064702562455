// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import moment from "moment-timezone";
import Hls from "hls.js";
import { Row, Col, Button, Slider } from "antd";
import "./podcast.scss";

export function DetailColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openEditPodcastDialog, isPrivate, getRefreshContent }
) {
  // const refreshPodcast = (
  //   <span
  //     style={{ cursor: "pointer", fontSize: "14px", color: "#189AEB", marginBottom: "0px" }}
  //     onClick={() => {
  //       const reqFunc = async () => {
  //         const req = await axios.get(`/admin/private/contents/${row.contentUID}/verify`);
  //         if (req.data.status && req.data.data) {
  //           getRefreshContent(req.data.data);
  //         }
  //       };
  //       reqFunc();
  //     }}
  //   >
  //     ตรวจสอบไฟล์
  //   </span>
  // );
  const fixFile = status => {
    if (isPrivate && (status === 4 || status === 5 || status === 6)) {
      return (
        <p style={{ fontSize: "14px", color: "#D7042B", marginBottom: "0px" }}>
          <span>
            {status === 4
              ? "ไฟล์มีปัญหา : อัปโหลดไฟล์สื่อ/ปก  "
              : status === 6
              ? "ไฟล์มีปัญหา : อัปโหลดไฟล์สื่อ  "
              : status === 5
              ? "ไฟล์มีปัญหา : อัปโหลดไฟล์ปก  "
              : ""}
            <img
              src="/media/members/fix-file-button.png"
              alt=""
              style={{
                background: "#f3f6f9",
                width: "22px",
                imageRendering: "-webkit-optimize-contrast",
                padding: "2px",
                borderRadius: "4px",
                cursor: "pointer"
              }}
              onClick={() => {
                openEditPodcastDialog(row.contentUID, status);
              }}
            />
          </span>
        </p>
      );
    } else {
      return "";
    }
  };
  return (
    <div className="podcast-detail-view">
      <p style={{ fontSize: "16px", color: "#4f4f4f", marginBottom: "0" }}>
        {row ? row.title : ""}
      </p>
      <p style={{ fontSize: "14px", color: "#828282" }}>
        {row ? millisecsToDateTime(row.uploadDate) : ""}
      </p>
      {row.playlist ? <PodCastPlayer playlist={row.playlist} /> : ""}
      {row && isPrivate
        ? row.status === 4 || row.status === 5 || row.status === 6
          ? fixFile(row.status)
          : ""
        : ""}
      {/* {fixFile(5)} */}
    </div>
  );
}

const millisecsToDateTime = (secs, timeFormat, dateFormat) => {
  const pad = num => {
    return ("0" + num).slice(-2);
  };
  var momentBKK = moment.tz(secs * 1000, "Asia/Bangkok").format();
  var a = new Date(momentBKK);
  var months = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม"
  ];
  var year = a.getFullYear() || "";
  var month = months[a.getMonth()] || "";
  var date = a.getDate() || "";
  var hour = a.getHours() || "0";
  var minute = a.getMinutes() || "0";
  var time =
    timeFormat === "hr-min"
      ? dateFormat === "dd/mm/yyyy"
        ? (parseInt(date, 10) < 10 ? "0" : "") +
          date +
          (parseInt(a.getMonth() + 1, 10) < 10 ? "/0" : "/") +
          parseInt(a.getMonth() + 1, 10) +
          "/" +
          year +
          " " +
          pad(hour) +
          ":" +
          pad(minute)
        : date + " " + month + " " + year + " " + pad(hour) + ":" + pad(minute)
      : date + " " + month + " " + year;
  return time;
};

export const PodCastPlayer = props => {
  const audioPlayerRef = useRef(null);
  const [audioPlayer, setAudioPlayer] = useState({});
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlay, setIsPlay] = useState(false);
  const [duration, setDuration] = useState(0);
  const [src, setSrc] = useState(props.playlist);
  useEffect(() => {
    setAudioPlayer(audioPlayerRef.current);
    setSrc(props.playlist);
  }, [audioPlayerRef.current, props.playlist]);

  useEffect(() => {
    if (audioPlayer.paused !== undefined) {
      // console.log({ audioPlayer });
      audioPlayer.onloadeddata = () => {
        setDuration(audioPlayer.duration);
      };
      audioPlayer.ontimeupdate = data => {
        // console.log(data);
        setCurrentTime(audioPlayer.currentTime);
      };
      audioPlayer.onprogress = data => {
        // console.log("progress", { data });
      };
      audioPlayer.onwaiting = data => {
        // console.log(data);
      };
    }
  }, [audioPlayer]);

  useEffect(() => {
    audioPlayer.playbackRate = 1;
  }, []);

  const playBtnAction = () => {
    if (audioPlayer.paused) {
      audioPlayer.play();
      setIsPlay(true);
    } else {
      audioPlayer.pause();
      setIsPlay(false);
    }
  };

  const sliderOnChange = value => {
    setCurrentTime(value);
    audioPlayer.currentTime = value;
  };
  return (
    <div className="podcast-player">
      <Row style={{ marginTop: 8 }}>
        <Col>
          <Button
            className="player-button"
            style={{ padding: "0", width: "auto", border: "none" }}
            onClick={playBtnAction}
          >
            <img
              src={
                isPlay
                  ? "/media/members/pause-circle.svg"
                  : "/media/members/play-circle.svg"
              }
            />
          </Button>
        </Col>
        <Col className="player-slider">
          <Row>
            <Col className="list-button-left" span={12} offset={0}>
              <p className="time">{dateConvert(currentTime)}</p>
            </Col>
            <Col className="list-button-right" span={12} offset={0}>
              <p className="time">-{dateConvert(duration - currentTime)}</p>
            </Col>
          </Row>
          <Slider
            defaultValue={0}
            value={currentTime}
            tooltipVisible={false}
            max={duration}
            min={0}
            onChange={sliderOnChange}
          />
        </Col>
      </Row>
      <audio ref={audioPlayerRef}>
        <HLSSource
          type="audio/mpeg"
          source={audioPlayer}
          src={src}
          isPlay={isPlay}
        />
      </audio>
    </div>
  );
};

const HLSSource = ({ src, source, type }) => {
  useEffect(() => {
    const hls = new Hls();
    if (Hls.isSupported()) {
      // console.log({ source });
      hls.loadSource(src);
      hls.attachMedia(source);
    }
  }, [source]);
  return <source src={src} type={type || "application/x-mpegURL"} />;
};

const dateConvert = second => {
  return new Date(second * 1000)
    .toISOString()
    .substr(11, 8)
    .replace(/^00:/, "");
};
