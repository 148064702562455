import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Dropdown, Button, Menu } from 'antd';
import { isNil } from 'lodash';
import SVG from 'react-inlinesvg';

import { CaretDownOutlined } from '@ant-design/icons';

export const minimumTwoBooks = [
    // "350b7d19-633c-426a-bdde-8c80f38c4cdf", //matichon
    // 'e13b2db7-4652-4edf-a55a-cc6a4f851b6e', //tpabooks
    // '292da99f-931e-4598-8c6a-fa296b7eb5c1' //TPAPressbooks
];

// export const filterStatusValue = [
//   {
//     value: "sort",
//     title: "Sort by",
//   },
//   { value: 1, title: "ทั้งหมด" },
//   { value: 2, title: "รอใช้งาน" },
//   { value: 3, title: "ใช้งาน" },
//   { value: 4, title: "หมดอายุ" },
//   { value: 5, title: "ใช้งานจริง" },
//   { value: 6, title: "ทดลองใช้" },
// ];
export const sortWordingValue = [
    {
        value: 'sort',
        title: 'Sort by'
    },
    { value: 'old', title: 'วันที่เก่า-ใหม่' },
    { value: 'new', title: 'วันที่ใหม่-เก่า' }
];
export const sortWording = sortBy => {
    if (sortBy !== undefined)
        return sortWordingValue.find(e => e.value === sortBy).title;
};
export const sortByFunc = sortBy => {
    switch (sortBy) {
        case 'sort':
            return compareOldDate;
        case 'old':
            return compareOldDate;
        case 'new':
            return compareNewDate;

        default:
            break;
    }
};
export const compareNewDate = (a, b) => {
    if (a.registerDate < b.registerDate) {
        return 1;
    }
    if (a.registerDate > b.registerDate) {
        return -1;
    }
    return 0;
};

export const compareOldDate = (a, b) => {
    if (a.registerDate < b.registerDate) {
        return -1;
    }
    if (a.registerDate > b.registerDate) {
        return 1;
    }
    return 0;
};

export const timeStampToDate = t => {
    if (t) {
        const date = new Date(t * 1000);
        const dateStr = date.getFullYear();

        return (
            <>
                <div style={{ display: 'inline-block' }}>{dateStr}</div>
            </>
        );
    }
};
export const statusWording = (status, statusValue, rejectMessages) => {
    if (status) {
        if (statusValue == 0) {
            return (
                <div className="text-small wrap text-orange">
                    {status.find(e => e.value === statusValue).title}
                </div>
            );
        } else if (statusValue == 2) {
            return (
                <div className="text-small wrap text-green">
                    {status.find(e => e.value === statusValue).title}
                </div>
            );
        } else if (statusValue == 1) {
            if (rejectMessages == 1 || rejectMessages == 2) {
                return (
                    <div className="text-small wrap text-red">
                        {
                            status.find(
                                e => e.rejectMessages === rejectMessages
                            ).title
                        }
                    </div>
                );
            } else {
                return (
                    <div className="text-small wrap text-red">
                        {status.find(e => e.rejectMessages === 0).title}
                    </div>
                );
            }
        }
    }
};
export const IndexFormatter = (cellContent, row, rowIndex) => {
    return <div>{rowIndex + 1}</div>;
};
export const CoverImageFormatter = (cellContent, row) => {
    return (
        <div className="cover">
            <img
                src={row.coverImage}
                alt="book-cover-catalogue-verify"
                onError={e => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src =
                        'https://via.placeholder.com/120x170.png';
                }}
            />
        </div>
    );
};
export const IsLifttimePurchaseFormatter = (cellContent, row) => {
    const getDisplayLifttimePurchase = () => {
        if (row.lifetimePurchase) {
            return (
                <div>
                    <SVG src="/media/svg/icons/General/Correct.svg" />
                </div>
            );
        } else {
            return <div>-</div>;
        }
    };

    return <div>{getDisplayLifttimePurchase()}</div>;
};

export const DetailContentFormatter = (cellContent, row) => {
    return (
        <div>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{row?.title}</Tooltip>}
            >
                <div className="text-title wrap">{row?.title}</div>
            </OverlayTrigger>
            <div className="text-detail">ผู้แต่ง : {row?.authorName}</div>
            <div className="text-detail">
                สำนักพิมพ์ : {row?.publisherName ? row?.publisherName : '-'}
            </div>
            <div className="text-detail">
                ISBN :{' '}
                {row?.eisbn && row?.isbn
                    ? row.eisbn
                    : row?.isbn
                    ? row?.isbn
                    : '-'}
            </div>
            <div className="text-detail">
                ปีที่ตีพิมพ์ :{' '}
                {row?.publicationDate
                    ? timeStampToDate(row?.publicationDate)
                    : '-'}
            </div>
            <div className="text-detail">
                หมวดหมู่ : {` ${row?.subcatName} / ${row?.catName}`}
            </div>
            <div className="text-detail">
                จำนวนสิทธิ์ที่มี :{' '}
                {!isNil(row?.purchasedConcurrent)
                    ? row?.purchasedConcurrent
                    : null}{' '}
                สิทธิ์
            </div>
            <div className="text text-red">
                <span style={{ color: '#D7042B' }}>
                    {minimumTwoBooks.includes(row.creatorUID)
                        ? '(ต้องซื้ออย่างน้อยปกละ 2 สิทธิ์)'
                        : ''}
                </span>
            </div>
        </div>
    );
};

export const ActionContentFormatter = (
    cellContent,
    row,
    rowIndex,
    { isHistory, dropdownStatusBtn, contentStatus }
) => {
    return !isHistory ? (
        <div className="dropdown dropdown-custom">
            <Dropdown
                overlay={() => dropdownStatusBtn(row)}
                trigger={['click']}
            >
                <Button className="dropdawn-btn">
                    <div className="d-flex">
                        {statusWording(
                            contentStatus,
                            row?.status,
                            row?.rejectMessages
                        )}
                        <div className="icon">
                            <CaretDownOutlined />
                        </div>
                    </div>
                </Button>
            </Dropdown>
        </div>
    ) : (
        <div>
            {statusWording(contentStatus, row?.status, row?.rejectMessages)}
        </div>
    );
};

export const sortBtn = props => (
    <Menu>
        <Menu.Item key="" onClick={() => props.setFilter('', '')}>
            <span className="dropdown-text no-pic">ทั้งหมด</span>
        </Menu.Item>
        {props.contentStatus?.map((e, i) => {
            return (
                <Menu.Item
                    key={`${e.value}_${
                        e.rejectMessages !== undefined ? e.rejectMessages : ''
                    }`}
                    onClick={() =>
                        props.setFilter(
                            e.value,
                            e.rejectMessages !== undefined
                                ? e.rejectMessages
                                : ''
                        )
                    }
                >
                    <span className="dropdown-text no-pic">{e.title}</span>
                </Menu.Item>
            );
        })}
    </Menu>
);

const findTitleContent = props => {
    let title = props.contentStatus.find(
        e =>
            e.value === props.status &&
            (props.reject === '' ||
                (props.reject !== '' && e.rejectMessages === props.reject))
    )?.title;
    if (title) {
        return title;
    } else {
        return 'ทั้งหมด';
    }
};

export const filterStatus = props => {
    return (
        <>
            {/* {`${props.status}_${props.reject}`} */}
            {props.status === '' && props.reject === ''
                ? 'ทั้งหมด'
                : findTitleContent(props)}
        </>
    );
};
