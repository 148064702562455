/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar
} from '../../../../../_metronic/_partials/controls';
import { CategoriesTable } from './categories-table/CategoriesTable';
import { CategoriesGrouping } from './categories-grouping/CategoriesGrouping';
import { useCategoriesUIContext } from './CategoriesUIContext';
import { makeStyles, withStyles } from '@material-ui/core/';
import Tabs from '@material-ui/core/';
import Tab from '@material-ui/core/';
import Grow from '@material-ui/core/Grow/';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { CategoriesFilter } from './categories-filter/CategoriesFilter';
import { useSelector } from 'react-redux';
import { Role } from '../../../../../interface';

export function CategoriesCard({
    name,
    type,
    id,
    isSubCat,
    isSubCatBooks,
    onHide,
    isPrivate
}) {
    const categoriesUIContext = useCategoriesUIContext();
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(isPrivate);
    const { role } = useSelector(state => state.auth);
    const anchorRef = React.useRef(null);

    const handleChange = newValue => {
        setValue(newValue);
    };
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }
    const categoriesUIProps = useMemo(() => {
        return {
            ids: categoriesUIContext.ids,
            newCategoryButtonClick: categoriesUIContext.newCategoryButtonClick,
            newSubCategoryButtonClick:
                categoriesUIContext.newSubCategoryButtonClick,
            contentType: categoriesUIContext.contentType
        };
    }, [categoriesUIContext]);
    const useStyles1 = makeStyles(theme => ({
        div: {
            marginBottom: -22
        },
        p1: {
            fontFamily: 'Prompt',
            fontSize: '20px',
            fontWeight: 600,
            color: '#012875',
            marginBottom: 0
        },
        p2: {
            fontFamily: 'Prompt',
            fontSize: '13px',
            color: '#fd6b53'
        },
        button: {
            borderRadius: 100,
            backgroundColor: '#012875',
            marginLeft: 8,
            padding: '10px 22px',
            height: 40,
            color: 'white',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Prompt',
            border: 'none'
        },
        invertButton: {
            borderRadius: 100,
            color: '#012875',
            border: '1px solid #012875',
            marginLeft: 8,
            padding: '10px 22px',
            height: 40,
            backgroundColor: 'white',
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Prompt'
        },
        dropdown1: {
            fontFamily: 'Prompt',
            paddingLeft: 8,
            paddingRight: 6
        },
        dropdown2: {
            fontFamily: 'Prompt',
            paddingLeft: 8,
            paddingRight: 6,
            paddingBottom: 6
        },
        stepDiv: {
            width: '200px',
            height: '48px',
            borderRadius: '20px 20px 0px 0px',
            margin: '0 -8.5px 0 12.5px',
            backgroundColor: 'white',
            padding: '12.5px',
            textAlign: 'center'
        },
        stepDivBlack: {
            width: '200px',
            height: '48px',
            borderRadius: '20px 20px 0px 0px',
            margin: '0 -8.5px 0 12.5px',
            backgroundColor: '#e0e0e0',
            padding: '12.5px',
            textAlign: 'center',
            '&:hover': { cursor: 'pointer' }
        },
        stepDivBlack_wait: {
            width: '200px',
            height: '48px',
            borderRadius: '20px 20px 0px 0px',
            margin: '0 -8.5px 0 12.5px',
            backgroundColor: '#e0e0e0',
            padding: '12.5px',
            textAlign: 'center',
            '&:hover': { cursor: 'wait' }
        },
        stepDivBlack_notAllowed: {
            width: '200px',
            height: '48px',
            borderRadius: '20px 20px 0px 0px',
            margin: '0 -8.5px 0 12.5px',
            backgroundColor: '#e0e0e0',
            padding: '12.5px',
            textAlign: 'center',
            '&:hover': { cursor: 'not-allowed' }
        },
        stepDiv_p: {
            display: 'inline-block',
            fontSize: '20px',
            color: '#FD6B53',
            fontWeight: 600
        },
        stepDivBlack_p: {
            display: 'inline-block',
            fontSize: '20px',
            color: '#828282',
            fontWeight: 600
        },
        textHead: {
            color: '#012875',
            fontSize: '18px',
            marginBottom: '0',
            height: '40px',
            display: 'flex',
            alignItems: 'center'
        }
    }));
    const classes = useStyles1();

    const addButton = (
        <div style={{ display: 'inline-block' }}>
            <button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className={classes.button}
            >
                <span>เพิ่มหมวดหมู่</span>
            </button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                placement="bottom-start"
                // disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom'
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                    style={{ paddingBottom: '0px' }}
                                >
                                    <MenuItem
                                        className={classes.dropdown1}
                                        onClick={() =>
                                            categoriesUIContext.newCategoryButtonClick(
                                                value,
                                                id,
                                                name
                                            )
                                        }
                                    >
                                        <span>เพิ่มหมวดหมู่หลัก</span>
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.dropdown2}
                                        onClick={() =>
                                            categoriesUIContext.newSubCategoryButtonClick(
                                                value,
                                                id,
                                                name
                                            )
                                        }
                                    >
                                        <span>เพิ่มหมวดหมู่ย่อย</span>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
    const stepLabel =
        categoriesUIProps.contentType === 'ebook'
            ? ['Hibrary', 'อีบุ๊ก']
            : categoriesUIProps.contentType === 'video'
            ? ['Hibrary', 'วิดีโอ']
            : categoriesUIProps.contentType === 'podcast'
            ? ['Hibrary', 'พ็อดคาสท์']
            : ['Hibrary', 'องค์กร'];
    const [listLoading, setListLoading] = useState(false);

    return (
        <>
            <div className="row">
                {/*stepLabel.map((el, index) => {
          if (index === isPrivate) {
            return (
              <div
                key={index}
                className={`col-2 ${
                  // index === value
                  //   ?
                  classes.stepDiv
                  // : listLoading
                  // ? classes.stepDivBlack_wait
                  // : isSubCat
                  // ? classes.stepDivBlack_notAllowed
                  // : classes.stepDivBlack
                }`}
                onClick={() => {
                  if (!listLoading && !isSubCat) handleChange(index);
                }}
              >
                <p
                  className={
                    index === value ? classes.stepDiv_p : classes.stepDivBlack_p
                  }
                >
                  {el}
                </p>
              </div>
            );
          }
        })*/}
                {/* vv */}
            </div>
            <Card className="cardStyle">
                <CardHeader
                    title={
                        <p className={classes.textHead}>
                            {isSubCat ? name : 'หมวดหมู่ทั้งหมด'}
                        </p>
                    }
                >
                    <CardHeaderToolbar>
                        {/* <CategoriesFilter role={role} /> */}
                        {role === Role.SuperAdmin ? (
                            ''
                        ) : (
                            <div className="row">{value ? addButton : ''}</div>
                        )}
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody className="cardBodyStyle">
                    {categoriesUIProps.ids.length > 0 && <CategoriesGrouping />}
                    <CategoriesTable
                        catName={name}
                        type={type}
                        catId={id}
                        isSubCat={isSubCat}
                        isSubCatBooks={isSubCatBooks}
                        isPrivate={value}
                        setListLoading={setListLoading}
                    />
                </CardBody>
            </Card>
            {/* ddd */}
        </>
        // lll/
    );
}
