import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { useCompaniesUIContext } from "../CompaniesUIContext";
import * as actions from "../../../_redux/companies/companiesActions";
// import { deleteCompany } from "../../../_redux/companies/companiesCrud";
import { HtiResponse } from "../../../../../../interface";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  body: {
    textAlign: "center",
  },
}));

export function CompanyDeleteDialog({ name, id, isPrivate, show, onHide }) {
  // Companies UI Context
  const companiesUIContext = useCompaniesUIContext();
  const companiesUIProps = useMemo(() => {
    return {
      setIds: companiesUIContext.setIds,
      queryParams: companiesUIContext.queryParams,
      groupClientType: companiesUIContext.currentTab
    };
  }, [companiesUIContext]);

  // Companies Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.companies.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteCompany = () => {


    // server request for deleting customer by id
    dispatch(actions.deleteCompany(id)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchCompanies(companiesUIProps.queryParams, isPrivate,undefined,undefined,companiesUIProps.groupClientType));
      // clear selections list
      companiesUIProps.setIds([]);
      // closing delete modal
      onHide();
    });
  };

  // const deleteCompanyById = async () => {
  //   try {
  //     const req = await deleteCompany(id);
  //     const { status } = req;
  //     if (status) {
  //       companiesUIProps.setIds([]);
  //       dispatch(
  //         actions.fetchCompanies(companiesUIProps.queryParams, isPrivate)
  //       );
  //       onHide();
  //     } else {
  //       companiesUIProps.setIds([]);
  //       onHide();
  //     }
  //   } catch (err) {
  //     companiesUIProps.setIds([]);
  //     onHide();
  //   }
  // };

  const classes = useStyles();

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      style={{ margin: "3% auto" }}
    >
      {isLoading && <ModalProgressBar />}
      <Modal.Body className={classes.body}>
        {name ? (
          <>
            <img src="/media/members/image-modal.svg" alt="" />
            <h2 className="header-red">ยืนยันการลบ</h2>
            <div className="detail-text">
              ต้องการลบ <span class="bold">{name}</span> หรือไม่?
            </div>

            <div className="divButton">
              <button type="button" onClick={onHide} className="denyButton">
                ยกเลิก
              </button>
              <button
                type="button"
                onClick={deleteCompany}
                className="acceptButton"
              >
                ยืนยัน
              </button>
            </div>
          </>
        ) : (
          ""
        )}
      </Modal.Body>
    </Modal>
  );
}
 