import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/customers/customersActions";
import { CustomerEditForm } from "./CustomerEditForm";
import { useCustomersUIContext } from "../CustomersUIContext";
import jwt_decode from "jwt-decode";
import { ModalsSuccess } from "../../../../CustomView";

export function CustomerEditDialog({ id, show, onHide }) {
  // Customers UI Context
  const [successModal, setSuccessModal] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      initCustomer: {
        id: undefined,
        name: "",
        surname: "",
        email: "",
        rawPassword: "",
        staffID: "",
      },
      queryParams: customersUIContext
        ? customersUIContext.queryParams
        : undefined,
    };
  }, [customersUIContext]);

  // Customers Redux state
  const dispatch = useDispatch();
  const { actionsLoading, customerForEdit, error, errorMessage } = useSelector(
    (state) => ({
      actionsLoading: state.customers.actionsLoading,
      customerForEdit: state.customers.customerForEdit,
      error: state.customers.error,
      errorMessage: state.customers.errorMessage,
    }),
    shallowEqual
  );

  const [clientStartDate, setClientStartDate] = useState(null);
  const [clientExpireDate, setClientExpireDate] = useState(null);

  const { authToken, viewAsToken } = useSelector(
    (state) => ({
      actionsLoading: state.customers.actionsLoading,
      authToken: state.auth.authToken,
      viewAsToken: state.auth.viewAsToken,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (viewAsToken) {
      if (jwt_decode(viewAsToken).startDate) {
        setClientStartDate(jwt_decode(viewAsToken).startDate);
      } else {
        setClientStartDate(null);
      }
      if (jwt_decode(viewAsToken).expireDate) {
        setClientExpireDate(jwt_decode(viewAsToken).expireDate);
      } else {
        setClientExpireDate(null);
      }
    } else if (authToken) {
      if (jwt_decode(authToken).startDate) {
        setClientStartDate(jwt_decode(authToken).startDate);
      } else {
        setClientStartDate(null);
      }
      if (jwt_decode(authToken).expireDate) {
        setClientExpireDate(jwt_decode(authToken).expireDate);
      } else {
        setClientExpireDate(null);
      }
    }
  }, [authToken, viewAsToken]);

  useEffect(() => {
    // server call for getting Customer by id
    dispatch(actions.fetchCustomer(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (error) {
      setErrorCode(error);
    }
  }, [error]);

  // server request for saving customer
  const saveCustomer = (customer) => {
    if (!id) {
      // server request for creating customer
      dispatch(actions.createCustomer(customer, setSuccessModal)).then(() => {
        if (customersUIProps && customersUIProps.queryParams) {
          dispatch(actions.fetchCustomers(customersUIProps.queryParams));
        }
      });
    } else {
      // server request for updating customer
      dispatch(
        actions.updateCustomer(customer, customer.memberUID, setSuccessModal)
      ).then(() => {
        if (customersUIProps && customersUIProps.queryParams) {
          dispatch(actions.fetchCustomers(customersUIProps.queryParams));
        }
      });
    }
  };

  return successModal ? (
    <ModalsSuccess
      show={show}
      onHide={() => onHide()}
      onClickAccept={() => {
        onHide();
        setTimeout(() => {
          setSuccessModal(false);
        }, 50);
      }}
    />
  ) : (
    <Modal
      backdrop="static"
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      className="create-customer-dialog"
    >
      <Modal.Body>
        <CustomerEditForm
          saveCustomer={saveCustomer}
          actionsLoading={actionsLoading}
          customer={
            id && customerForEdit
              ? customerForEdit
              : customersUIProps.initCustomer
          }
          onHide={onHide}
          errorMessage={errorMessage}
          errorCode={errorCode}
          setErrorCode={setErrorCode}
          clientStartDate={clientStartDate}
          clientExpireDate={clientExpireDate}
        />
      </Modal.Body>
    </Modal>
  );
}
