import {
   Button,
   Divider,
   Input,
   InputRef,
   message,
   Radio,
   Space,
   Switch,
   Tag
} from 'antd';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { CreateTokenContainer } from './styled';
import { PlusOutlined } from '@ant-design/icons';
import {
   AllowType,
   createToken,
   getTokenByUID,
   TokenPayload,
   updateToken
} from '../../../redux/axios/tokenKey';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { isNull } from 'util';

const CreateToken = () => {
   const inputRef = useRef<InputRef>(null);
   const history = useHistory();
   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const uid = searchParams.get('uid');
   const [enableInput, setEnableInput] = useState<boolean>(false);
   const [inputValue, setInputValue] = useState<string>('');
   const [buttonLoading, setButtonLoading] = useState<boolean>(false);

   useEffect(() => {
      if (!isNull(uid)) {
         fetchTokenByUID(uid);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const schema = Yup.object().shape({
      name: Yup.string().required('กรุณากรอกชื่อ Token'),
      ip: Yup.array().when(['isEnable', 'allowType'], {
         is: (isEnable, allowType) =>
            isEnable && allowType === AllowType.IPAddress,
         then: Yup.array().min(1, 'กรุณาเพิ่ม IP Address')
      }),
      domain: Yup.array().when(['isEnable', 'allowType'], {
         is: (isEnable, allowType) =>
            isEnable && allowType === AllowType.Domain,
         then: Yup.array().min(1, 'กรุณาเพิ่ม Domain')
      })
   });

   const formik = useFormik({
      initialValues: {
         name: '',
         isEnable: false,
         allowType: AllowType.All,
         domain: [],
         ip: []
      } as TokenPayload,
      validationSchema: schema,
      onSubmit: value => {
         onCreateToken(value);
      }
   });

   const onCreateToken = async (token: TokenPayload) => {
      if (!isNull(uid)) {
         try {
            setButtonLoading(true);
            const response = await updateToken(token);
            if (response) {
               setButtonLoading(false);
               message.success('แก้ไขข้อมูล Token สำเร็จ!');
               history.push('/publicApi');
            }
         } catch (error) {
            setButtonLoading(false);
         }
      } else {
         try {
            setButtonLoading(true);
            const response = await createToken(token);
            if (response) {
               setButtonLoading(false);
               message.success('สร้าง Token สำเร็จ!');
               history.push('/publicApi');
            }
         } catch (error) {
            setButtonLoading(false);
         }
      }
   };

   const fetchTokenByUID = async (uid: string) => {
      try {
         const response = await getTokenByUID(uid);
         if (response) {
            formik.setValues(response);
         }
      } catch (error) {}
   };

   const validateDomain = (domain: string) => {
      var pattern = /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/;

      return pattern.test(domain);
   };

   const validateIP = (ip: string) => {
      var pattern = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      return pattern.test(ip);
   };

   const handleInputConfirm = () => {
      switch (formik.values.allowType) {
         case AllowType.IPAddress:
            if (inputValue && formik.values.ip.indexOf(inputValue) === -1) {
               if (validateIP(inputValue)) {
                  const tags = formik.values.ip;
                  tags.push(inputValue);
                  formik.setFieldValue('ip', tags);
               } else {
                  message.warning('กรอกรูปแบบ IP Address ไม่ถูกต้อง');
               }
            }
            break;
         case AllowType.Domain:
            if (inputValue && formik.values.domain.indexOf(inputValue) === -1) {
               if (validateDomain(inputValue)) {
                  const tags = formik.values.domain;
                  tags.push(inputValue);
                  formik.setFieldValue('domain', tags);
               } else {
                  message.warning('กรอกรูปแบบ Domain ไม่ถูกต้อง');
               }
            }
            break;
      }
      setEnableInput(false);
      setInputValue('');
   };

   const handleClose = (text: string) => {
      switch (formik.values.allowType) {
         case AllowType.IPAddress: {
            const tags = formik.values.ip.filter(e => e !== text);
            formik.setFieldValue('ip', tags);
            break;
         }
         case AllowType.Domain: {
            const tags = formik.values.domain.filter(e => e !== text);
            formik.setFieldValue('domain', tags);
            break;
         }
      }
   };
   return (
      <CreateTokenContainer>
         <div className="headerSection">
            <h1>{!isNull(uid) ? `แก้ไข Token Key` : `สร้าง Token Key`}</h1>
            <Space size={16}>
               <Button
                  shape="round"
                  onClick={() => history.push('/publicApi')}
                  size="large"
                  className="backButton"
               >
                  ยกเลิก
               </Button>
               <Button
                  className="saveButton"
                  shape="round"
                  size="large"
                  loading={buttonLoading}
                  onClick={() => formik.handleSubmit()}
               >
                  {!isNull(uid) ? `บันทึก` : `สร้าง`}
               </Button>
            </Space>
         </div>
         <div className="contentContainer">
            <div>
               <label>ชื่อ Token Key</label>
               <Input
                  placeholder="กรุณากรอกชื่อ Token Key"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  size="large"
               />
            </div>
            <div className="permissionContainer">
               <div style={{ width: '15%' }}>จำกัดสิทธิ์</div>
               <div>
                  <p className="description">
                     การจำกัดสิทธิ์นี้จะควบคุมว่า IP Addresses หรือ Domain
                     ใดบ้างที่สามารถใช้ Token Key ได้
                     สามารถตั้งค่าการจำกัดสิทธิ์ได้เพียงหนึ่งรายการต่อหนึ่ง
                     Token Key
                  </p>

                  <div className="switchContainer">
                     <p>จำกัดสิทธิ์การยืนยันด้วย Token Key</p>
                     <Space size={8} align="center">
                        <p>เปิดให้จำกัดสิทธิ์</p>
                        <Switch
                           checked={formik.values.isEnable}
                           checkedChildren="เปิด"
                           unCheckedChildren="ปิด"
                           onChange={e => formik.setFieldValue('isEnable', e)}
                        />
                     </Space>
                  </div>
                  <Divider />
                  {formik.values.isEnable && (
                     <div className="selectApiContainer">
                        <Radio.Group
                           defaultValue={formik.values.allowType}
                           onChange={e =>
                              formik.setFieldValue(
                                 'allowType',
                                 e.target.value as AllowType
                              )
                           }
                        >
                           <Space size={16} direction="vertical">
                              <Radio value={AllowType.IPAddress}>
                                 IP Addresses
                              </Radio>
                              {formik.values.allowType ===
                                 AllowType.IPAddress && (
                                 <div className="item">
                                    <p>
                                       กำหนด IP Addresses ที่อนุญาตให้ใช้งาน
                                       Token Key นี้
                                    </p>
                                    <div className="listContainer">
                                       {formik.values.ip.map(el => (
                                          <Tag
                                             key={el}
                                             className="customTag"
                                             onClose={e => {
                                                e.preventDefault();
                                                handleClose(el);
                                             }}
                                             closable
                                          >
                                             {el}
                                          </Tag>
                                       ))}
                                       {enableInput ? (
                                          <Input
                                             className="tagInput"
                                             ref={inputRef}
                                             type="text"
                                             size="small"
                                             style={{ width: 78 }}
                                             value={inputValue}
                                             onChange={e =>
                                                setInputValue(e.target.value)
                                             }
                                             onBlur={handleInputConfirm}
                                             onPressEnter={handleInputConfirm}
                                          />
                                       ) : (
                                          <Tag
                                             className="addTag"
                                             onClick={() =>
                                                setEnableInput(true)
                                             }
                                          >
                                             <PlusOutlined /> New IP Address
                                          </Tag>
                                       )}
                                    </div>
                                    {formik.errors.ip && formik.touched.ip ? (
                                       <p className="error">
                                          {formik.errors.ip}
                                       </p>
                                    ) : null}
                                 </div>
                              )}
                              <Radio value={AllowType.Domain}>Domain</Radio>
                              {formik.values.allowType === AllowType.Domain && (
                                 <div className="item">
                                    <p>
                                       กำหนดค่า Domain ที่อนุญาตให้ใช้งาน Token
                                       Key นี้
                                    </p>
                                    <p className="highlight">
                                       <b>หมายเหตุ</b> : ไม่สามารถใช้ Domain
                                       ดังต่อไปนี้ในการใช้ Token Key ได้
                                       gmail.com, hotmail.com, outlook.com,
                                       yahoo.com
                                    </p>
                                    <div className="listContainer">
                                       {formik.values.domain.map(el => (
                                          <Tag
                                             className="customTag"
                                             onClose={e => {
                                                e.preventDefault();
                                                handleClose(el);
                                             }}
                                             closable
                                          >
                                             {el}
                                          </Tag>
                                       ))}
                                       {enableInput ? (
                                          <Input
                                             className="tagInput"
                                             ref={inputRef}
                                             type="text"
                                             size="small"
                                             style={{ width: 78 }}
                                             value={inputValue}
                                             onChange={e =>
                                                setInputValue(e.target.value)
                                             }
                                             onBlur={handleInputConfirm}
                                             onPressEnter={handleInputConfirm}
                                          />
                                       ) : (
                                          <Tag
                                             className="addTag"
                                             onClick={() =>
                                                setEnableInput(true)
                                             }
                                          >
                                             <PlusOutlined /> New Domain
                                          </Tag>
                                       )}
                                    </div>
                                    {formik.errors.domain &&
                                    formik.touched.domain ? (
                                       <p className="error">
                                          {formik.errors.domain}
                                       </p>
                                    ) : null}
                                 </div>
                              )}
                           </Space>
                        </Radio.Group>
                     </div>
                  )}
               </div>
            </div>
         </div>
      </CreateTokenContainer>
   );
};

export default CreateToken;
