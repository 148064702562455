// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../../../_metronic/_partials/controls";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { DatePicker } from "antd";
import Spinner from "../../../../../../_metronic/_helpers/spinner";
import { dateStr, timeStr } from "../../../partials/timeStampToDate";
import { Role } from "../../../../../../interface";
import {
  disabledDateBeforeStart,
  disabledDateAfterExpire,
  todayDatePickerStart,
  todayDatePickerEnd,
} from "../../../../../utility";
const { RangePicker } = DatePicker;

const useStyles = makeStyles(() => ({
  alwaysDropdown: {
    background: "transparent",
    backgroundImage: `url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "100%",
    backgroundPositionY: "5px",
    "&::-webkit-calendar-picker-indicator": { opacity: 0 },
  },
}));

// Validation schema
const CustomerEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "อย่างน้อย 2 ตัวอักษร")
    .max(200, "ไม่เกิน 200 ตัวอักษร")
    .required("กรุณากรอกชื่อ"),
  surname: Yup.string()
    .min(2, "อย่างน้อย 2 ตัวอักษร")
    .max(200, "ไม่เกิน 200 ตัวอักษร")
    .required("กรุณากรอกนามสกุล"),
  staffID: Yup.string().min(
    7,
    "รหัสพนักงานต้องมากกว่า 7 หลักและสามารถเพิ่มเลข 0 ไว้ข้างหน้าได้"
  ),
  email: Yup.string()
    .email("อีเมลไม่ถูกต้อง")
    .required("กรุณากรอกอีเมล"),
  password: Yup.string()
    .min(6, "อย่างน้อย 6 ตัวอักษร")
    .max(20, "ไม่เกิน 20 ตัวอักษร"),
});

export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
  errorMessage,
  errorCode,
  setErrorCode,
  clientStartDate,
  clientExpireDate,
}) {
  const [loadedClientDate, setLoadedClientDate] = useState(false);
  const styles = useStyles();
  const makepw = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const { role, viewAsToken, authToken } = useSelector((state) => state.auth);
  const [companyDropdown, setCompanyDropdown] = useState([
    <option value="">กรุณาเลือกองค์กร</option>,
  ]);
  const [companyIn, setCompanyIn] = useState("");

  const [roleDropdown, setRoleDropdown] = useState([
    <option value={0}>กรุณาเลือกประเภท</option>,
  ]);
  const [checkingError, setCheckingError] = useState({
    datePicker: false,
    rental: false,
    rental_mag: false,
    rental_news: false,
    companyIn: false,
    roleIn: false,
    email: false,
    password: false,
  });
  const [roleIn, setRoleIn] = useState(0);
  const [startDate, setStartDate] = useState(moment().unix());
  const [endDate, setEndDate] = useState(null);
  const [companyToSubmit, setCompanyToSubmit] = useState(0);
  const [defaultExpire, setDefaultExpire] = useState(undefined);
  const [organization, setOrganization] = useState();
  const [organizationSelect, setOrganizationSelect] = useState(undefined);
  const [rentalLimitValue, setRentalLimitValue] = useState(0);
  const [rentalLimitValue_Magazine, setRentalLimitValue_Magazine] = useState(0);
  const [rentalLimitValue_Newspaper, setRentalLimitValue_Newspaper] = useState(
    0
  );
  const [concurrentList, setConcurrentList] = useState([
    <option key="" value={0} disabled>
      กรุณาเลือกจำนวน
    </option>,
  ]);
  const [concurrentList_small, setConcurrentList_small] = useState([
    <option key="" value={0} disabled>
      กรุณาเลือกจำนวน
    </option>,
  ]);
  const [editState, setEditState] = useState(false);

  const fetchFunc = async () => {
    const fetchDropdown = await axios.get(`/superuser/members/create`);
    setOrganization(fetchDropdown.data.data.clients);
    if (fetchDropdown.data.status && fetchDropdown.data.data) {
      setCompanyDropdown([
        <option disabled key="0" value="">
          กรุณาเลือกองค์กร
        </option>,
        ...fetchDropdown.data.data.clients.map((e) => (
          <option key={e.clientUID} value={e.clientName} />
        )),
      ]);
      // setCompanyDropdown(fetchDropdown.data.data.clients);
      setRoleDropdown([
        <option disabled key="0" value={0}>
          กรุณาเลือกประเภท
        </option>,
        ...fetchDropdown.data.data.role.map((e) => (
          <option key={e.value} value={e.value}>
            {e.label}
          </option>
        )),
      ]);
      // setRoleDropdown(fetchDropdown.data.data.role);
    }
  };
  const fetchPreset = async () => {
    const fetchPreset = await axios.get(`/admin/register`);

    if (fetchPreset.data.status && fetchPreset.data.data) {
      if (fetchPreset.data.data.preSet) {
        // setEndDate(fetchPreset.data.data.preSet.expireDate);
        setDefaultExpire(fetchPreset.data.data.preSet.expireDate);
        setRoleDropdown([
          <option disabled key="0" value={0}>
            กรุณาเลือกประเภท
          </option>,
          ...fetchPreset.data.data.preSet.memberType.map((e) => (
            <option key={e.value} value={e.value}>
              {e.label}
            </option>
          )),
        ]);
      }
    }
  };
  useEffect(() => {
    setConcurrentList([
      <option disabled key="0" value={0}>
        กรุณาเลือกจำนวน
      </option>,
      ...Array.from({ length: 10 }, (_, i) => i + 1).map((el) => (
        <option value={el}>{el} เล่ม</option>
      )),
    ]);
    setConcurrentList_small([
      <option disabled key="0" value={0}>
        กรุณาเลือกจำนวน
      </option>,
      ...Array.from({ length: 10 }, (_, i) => i + 1).map((el) => (
        <option value={el}>{el} เล่ม</option>
      )),
    ]);
  }, []);

  useEffect(() => {
    if (role === Role.SuperAdmin && !viewAsToken) {
      fetchFunc();
    } else {
      fetchPreset();
    }
  }, [role]);
  const handleRoleInChange = (e) => {
    // const result = roleDropdown.find(({ label }) => label === e.target.value);
    // if (result) {
    //   setRoleIn(result.value);
    // } else {
    //   setRoleIn("");
    // }
    setRoleIn(e.target.value);
  };
  const handleCompanyInChange = (e) => {
    // const result = companyDropdown.find(({ clientName }) => clientName === e.target.value);
    // if (result) {
    //   setCompanyIn(result.clientUID);
    // } else {
    //   setCompanyIn("");
    // }

    companyDropdown.forEach((el) => {
      if (el.props && el.props.value && e.target.value === el.props.value) {
        setCompanyToSubmit(el.key);
      }
    });
    if (e.target.value === "") {
      setCompanyToSubmit(0);
    }
    // let selectedIndex =  e.target.options.selectedIndex
    let organizationOBJ = organization.find(
      ({ clientName }) => clientName === e.target.value
    );
    console.log(organizationOBJ.clientName);
    setDefaultExpire(organizationOBJ.expireDate);
    setOrganizationSelect(organizationOBJ.clientUID);

    setCompanyIn(e.target.value);
  };

  const filterFieldEdit = (initObject, onSubmitObject) => {
    const keys1 = Object.keys(initObject);
    const keys2 = Object.keys(onSubmitObject);
    let newObject = {};

    // if (keys1.length !== keys2.length) {
    //   return false;
    // }

    for (let key of keys2) {
      if (initObject[key] !== onSubmitObject[key]) {
        newObject = Object.assign(newObject, { [key]: onSubmitObject[key] });
      }
    }
    newObject = Object.assign(newObject, {
      memberUID: onSubmitObject.memberUID,
    });
    return newObject;
  };
  const onDurationChange = (dates, dateStrings) => {
    console.log(dateStrings);
    if (dates) {
      console.log(dates);
      setStartDate(dates[0].unix());
      setEndDate(dates[1].unix());
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };
  const handleRentalLimitChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setRentalLimitValue(value);
    const newList = concurrentList.filter((el) => el.props.value <= value);
    setConcurrentList_small(newList);
    if (rentalLimitValue_Magazine > value) {
      setRentalLimitValue_Magazine(value);
    }
    if (rentalLimitValue_Newspaper > value) {
      setRentalLimitValue_Newspaper(value);
    }
    setCheckingError(Object.assign({}, checkingError, { rental: false }));
  };
  const handleRentalLimitChange_Magazine = (e) => {
    setRentalLimitValue_Magazine(parseInt(e.target.value, 10));
    setCheckingError(Object.assign({}, checkingError, { rental_mag: false }));
  };
  const handleRentalLimitChange_Newspaper = (e) => {
    setRentalLimitValue_Newspaper(parseInt(e.target.value, 10));
    setCheckingError(Object.assign({}, checkingError, { rental_news: false }));
  };

  useEffect(() => {
    if (clientStartDate && clientExpireDate && !loadedClientDate) {
      setStartDate(moment().unix());
      setEndDate(clientExpireDate);
    }
  }, [clientStartDate, clientExpireDate, loadedClientDate]);

  useEffect(() => {
    if (customer && customer.email !== "") {
      // console.log("Dialog ", customer, customer.email !== "");
      setEditState(customer.email !== "" ? true : false);
      setRoleIn(customer.memberType);
      setStartDate(customer.startDate);
      setEndDate(customer.expireDate);

      setLoadedClientDate(true);
      console.log(customer);
    }
  }, [customer]);
  return (
    <>
      <h2 className="center">{editState ? "แก้ไขสมาชิก" : "เพิ่มสมาชิก"}</h2>

      <Formik
        key={customer.memberUID}
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          console.log("submit", values);
          let newValues = Object.assign({}, values);
          newValues.startDate = startDate;
          newValues.expireDate = endDate;
          if (editState) {
            let memberUID = newValues.memberUID;
            console.log(customer, newValues);
            newValues = filterFieldEdit(customer, newValues);

            newValues = Object.assign(newValues, { memberUID });
          } else {
            if (viewAsToken !== undefined) {
              console.log("false", jwt_decode(viewAsToken).clientUID);
              newValues = Object.assign(newValues, {
                clientUID: jwt_decode(viewAsToken).clientUID,
              });
            } else {
              if (organizationSelect !== undefined) {
                newValues = Object.assign(newValues, {
                  clientUID: organizationSelect,
                });
              } else {
                newValues = Object.assign(newValues, {
                  clientUID: jwt_decode(authToken).clientUID,
                });
              }
            }
          }
          //

          if (
            role === Role.SuperAdmin &&
            roleIn &&
            startDate &&
            endDate &&
            (editState || (!editState && values["password"]))
          ) {
            if (viewAsToken || (!viewAsToken && companyToSubmit)) {
              saveCustomer(
                Object.assign({}, newValues, {
                  memberType: roleIn,
                  // company: viewAsToken
                  //   ? jwt_decode(viewAsToken).clientUID
                  //   : companyToSubmit,
                  // startDate: startDate,
                  // expireDate: endDate,
                })
              );
            }
          } else if (
            role === Role.Admin &&
            roleIn &&
            startDate &&
            endDate &&
            (editState || (!editState && values["password"]))
          ) {
            if (roleIn === "user_vip") {
              if (
                rentalLimitValue &&
                rentalLimitValue_Magazine &&
                rentalLimitValue_Newspaper
              ) {
                saveCustomer(
                  Object.assign({}, newValues, {
                    memberType: roleIn,
                    // startDate: startDate,
                    // expireDate: endDate,
                    rentalLimit: rentalLimitValue,
                    rentalLimitCategory: {
                      newspapers: { member: rentalLimitValue_Newspaper },
                      magazine: { member: rentalLimitValue_Magazine },
                    },
                  })
                );
              }
            } else {
              saveCustomer(
                Object.assign({}, newValues, {
                  memberType: roleIn,
                  // startDate: startDate,
                  // expireDate: endDate,
                })
              );
            }
          }
        }}
      >
        {({ handleSubmit, values, setFieldValue, errors }) => {
          return (
            <>
              <>
                {actionsLoading && <Spinner forEditCustomer={true} />}
                <Form className="form form-label-right">
                  <div className={`col-lg-12 input`}>
                    <Select
                      name="role"
                      withFeedbackLabel={false}
                      label="ประเภท*"
                      onChange={handleRoleInChange}
                      value={roleIn}
                      style={{
                        backgroundImage: `url(
                        "data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"
                                                            )`,
                      }}
                      className={`form-control selectDropdown`}
                    >
                      {roleDropdown}
                    </Select>
                    {!roleIn && checkingError["roleIn"] && (
                      <>
                        <div className="is-invalid"></div>
                        <div className="invalid-feedback">กรุณาเลือกประเภท</div>
                      </>
                    )}
                  </div>
                  {role === Role.SuperAdmin && !viewAsToken ? (
                    <div className={`col-lg-12 input`}>
                      <label for="company">องค์กร*</label>
                      <input
                        placeholder="กรุณาเลือกองค์กร"
                        list="companies"
                        name="company"
                        id="company"
                        className={`form-control ${styles.alwaysDropdown}`}
                        onChange={handleCompanyInChange}
                        value={companyIn}
                      />
                      <datalist autoComplete="new-password" id="companies">
                        {companyDropdown}
                      </datalist>
                      {!companyToSubmit && checkingError["companyIn"] && (
                        <>
                          <div className="is-invalid"></div>
                          <div className="invalid-feedback">
                            กรุณาเลือกองค์กร
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={`col-lg-12 input`}>
                    <p style={{ marginBottom: "0.5rem" }}>
                      วันที่เริ่มใช้งาน - วันที่หมดอายุ*
                    </p>
                    <div className="member-period">
                      <DatePicker
                        format={"DD-MM-YYYY HH:mm:ss"}
                        placeholder="วันที่เริ่มใช้งาน"
                        renderExtraFooter={() =>
                          todayDatePickerStart(setStartDate)
                        }
                        showTime={{
                          defaultValue: moment("00:00:00", "HH:mm:ss"),
                        }}
                        onChange={(dates) => {
                          if (dates !== null) setStartDate(dates.unix());
                          else setStartDate(null);
                        }}
                        defaultValue={
                          startDate !== null ? moment.unix(startDate) : null
                        }
                        disabledDate={(current) =>
                          disabledDateAfterExpire(current, endDate)
                        }
                        value={
                          startDate !== null ? moment.unix(startDate) : null
                        }
                      />
                      <DatePicker
                        format={"DD-MM-YYYY HH:mm:ss"}
                        placeholder="วันที่หมดอายุ"
                        renderExtraFooter={() => todayDatePickerEnd(setEndDate)}
                        showTime={{
                          defaultValue: moment("23:59:59", "HH:mm:ss"),
                        }}
                        onChange={(dates) => {
                          if (dates !== null) setEndDate(dates.unix());
                          else setEndDate(null);
                        }}
                        value={endDate !== null ? moment.unix(endDate) : null}
                        disabledDate={(current) =>
                          disabledDateBeforeStart(
                            current,
                            startDate,
                            defaultExpire
                          )
                        }
                      />
                    </div>

                    {/* <RangePicker
                      value={
                        startDate && endDate
                          ? [moment.unix(startDate), moment.unix(endDate)]
                          : null
                      }
                      ranges={{
                        วันนี้: [
                          moment().startOf("day"),
                          // moment().endOf("day"),
                           moment.unix(endDate)
                        ],
                        เดือนนี้: [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                      }}
                      showTime={{
                        defaultValue: [moment(), moment().add(1, "hour")],
                      }}
                      format="DD/MM/YYYY HH:mm:ss"
                      onChange={onDurationChange}
                      placeholder={["วันที่เริ่มใช้งาน", "วันที่หมดอายุ"]}
                      disabledDate={(current) => {
                        if (clientStartDate && clientExpireDate) {
                          return (
                            moment.unix(clientStartDate) > current ||
                            current > moment.unix(clientExpireDate)
                          );
                        } else {
                          return (
                            moment().startOf("day") > current ||
                            current > defaultExpire
                          );
                        }
                      }}
                      className={
                        (!startDate || !endDate) && checkingError["datePicker"]
                          ? "form-control is-invalid"
                          : ""
                      }
                      style={
                        (!startDate || !endDate) && checkingError["datePicker"]
                          ? {
                              display: "inline-flex",
                              width: "100%",
                              height: "38px",
                            }
                          : {
                              display: "inline-flex",
                              width: "100%",
                              height: "38px",
                              border: "1px solid #E5EAEE",
                            }
                      }
                    /> */}
                    {checkingError["datePicker"] && (
                      <div className="invalid-feedback">
                        กรุณากรอกวันที่เริ่มใช้งานและวันที่หมดอายุ
                      </div>
                    )}
                  </div>
                  {roleIn === "user_vip" ? (
                    <>
                      <div className={`col-lg-12 input`}>
                        <Select
                          name="rentalLimit"
                          withFeedbackLabel={false}
                          label="จำนวนสูงสุดในการยืม"
                          type="number"
                          onChange={handleRentalLimitChange}
                          value={rentalLimitValue}
                          className={`form-control selectDropdown`}
                          style={{
                            backgroundImage: `url(
                                                                           "data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"
                                                                      )`,
                          }}
                        >
                          {concurrentList}
                        </Select>
                        {checkingError["rental"] && !rentalLimitValue ? (
                          <>
                            <div className="is-invalid"></div>
                            <div className="invalid-feedback">
                              กรุณาเลือกจำนวน
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className={`col-lg-12 input`}>
                        <Select
                          name="rentalLimit_Magazine"
                          withFeedbackLabel={false}
                          label="จำนวนสูงสุดในการยืมนิตยสาร"
                          type="number"
                          onChange={handleRentalLimitChange_Magazine}
                          value={rentalLimitValue_Magazine}
                          className={`form-control selectDropdown`}
                          style={{
                            backgroundImage: `url(
                                                                           "data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"
                                                                      )`,
                          }}
                        >
                          {concurrentList_small}
                        </Select>
                        {checkingError["rental_mag"] &&
                        !rentalLimitValue_Magazine ? (
                          <>
                            <div className="is-invalid"></div>
                            <div className="invalid-feedback">
                              กรุณาเลือกจำนวน
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className={`col-lg-12 input`}>
                        <Select
                          name="rentalLimit_Newspaper"
                          withFeedbackLabel={false}
                          label="จำนวนสูงสุดในการยืมหนังสือพิมพ์"
                          type="number"
                          onChange={handleRentalLimitChange_Newspaper}
                          value={rentalLimitValue_Newspaper}
                          className={`form-control selectDropdown`}
                          style={{
                            backgroundImage: `url(
                                                                           "data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>"
                                                                      )`,
                          }}
                        >
                          {concurrentList_small}
                        </Select>
                        {checkingError["rental_news"] &&
                        !rentalLimitValue_Newspaper ? (
                          <>
                            <div className="is-invalid"></div>
                            <div className="invalid-feedback">
                              กรุณาเลือกจำนวน
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {/* First Name */}
                  <div className={`col-lg-12 input`}>
                    <Field
                      name="name"
                      component={Input}
                      placeholder="กรอกชื่อ"
                      label="ชื่อ*"
                    />
                  </div>
                  {/* Last Name */}
                  <div className={`col-lg-12 input`}>
                    <Field
                      name="surname"
                      component={Input}
                      placeholder="กรอกนามสกุล"
                      label="นามสกุล*"
                    />
                  </div>
                  {/* Login */}
                  <div className={`col-lg-12 input`}>
                    <Field
                      name="staffID"
                      component={Input}
                      placeholder="กรอกรหัสพนักงาน 7 ตัวขึ้นไป"
                      label="Staff ID"
                      value={values.staffID}
                      className={
                        errorCode === "079" ||
                        errorCode === "083" ||
                        errors.staffID
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={(e) => {
                        setErrorCode("");
                        setFieldValue("staffID", e.target.value);
                      }}
                      disabled={
                        customer.staffID && customer.staffID !== ""
                          ? true
                          : false
                      }
                    />
                    {errorCode === "079" && (
                      <div className="invalid-feedback">
                        {errors.staffID
                          ? errors.staffID
                          : "รหัสพนักงานซ้ำกับที่มีอยู่ในระบบ"}
                      </div>
                    )}
                    {errorCode === "083" && (
                      <div className="invalid-feedback">
                        {errors.staffID
                          ? errors.staffID
                          : "รหัสพนักงานไม่ตรงกับรหัสองค์กร"}
                      </div>
                    )}
                  </div>
                  {/* Email */}
                  <div className={`col-lg-12 input`}>
                    <Field
                      type="email"
                      name="email"
                      component={Input}
                      placeholder="กรอกที่อยู่อีเมล"
                      label="Email address*"
                      className={
                        errorCode === "063" ||
                        (checkingError["email"] && errors.email)
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={(e) => {
                        setErrorCode("");
                        setFieldValue("email", e.target.value);
                      }}
                      disabled={editState}
                    />
                    {errorCode === "063" && (
                      <div className="invalid-feedback">
                        {errors.email
                          ? errors.email
                          : "อีเมลซ้ำกับที่มีอยู่ในระบบ"}
                      </div>
                    )}
                  </div>
                  {/* Password */}
                  {!editState ? (
                    <div className={`col-lg-12 input`}>
                      <Field
                        // type="password"
                        name="password"
                        component={Input}
                        value={values["password"]}
                        onChange={(e) => {
                          setCheckingError(
                            Object.assign({}, checkingError, {
                              password: false,
                            })
                          );
                          setFieldValue("password", e.target.value);
                        }}
                        className={
                          (!values["password"] && checkingError["password"]) ||
                          errors.password
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="กรอกรหัสผ่าน"
                        label={
                          <div className="row" style={{ width: "426%" }}>
                            <div className={`col-lg-6`}>Password*</div>
                            <div className={`col-lg-6`}>
                              <div
                                onClick={(e) => {
                                  e.preventDefault();
                                  setFieldValue("password", makepw(6));
                                }}
                                className="randomButton"
                              >
                                สุ่มรหัสผ่าน
                              </div>
                            </div>
                          </div>
                        }
                        // customFeedbackLabel="We'll never share customer IP Address with anyone else"
                      />
                      {checkingError["password"] && (
                        <div className="invalid-feedback">
                          กรุณากรอกรหัสผ่าน
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className={`col-lg-12`} style={{ textAlign: "center" }}>
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errorCode &&
                      errorCode !== "063" &&
                      errorCode !== "079" &&
                      errorCode !== "083" &&
                      errorMessage
                        ? errorMessage
                        : ""}
                    </div>
                  </div>
                  <div className="divButton ">
                    <button
                      className="denyButton"
                      type="button"
                      onClick={() => {
                        setErrorCode("");
                        onHide();
                      }}
                    >
                      ยกเลิก
                    </button>
                    <button
                      className="acceptButton"
                      type="submit"
                      onClick={() => {
                        setCheckingError({
                          datePicker: true,
                          rental: true,
                          rental_mag: true,
                          rental_news: true,
                          companyIn: true,
                          roleIn: true,
                          email: true,
                          password: true,
                        });
                      }}
                    >
                      ตกลง
                    </button>
                  </div>
                </Form>
              </>
            </>
          );
        }}
      </Formik>
    </>
  );
}
