export const dateStr = (t) => {
    if (t) {
        const date = new Date(t * 1000);
        return (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
          "/" +
          date.getFullYear();
      }
}
export const timeStr = (t) => {
    if (t) {
      const date = new Date(t * 1000);
      return (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) +
      ":" +
      (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()) +
      ":" +
      (date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds())+
      " น."
    }
  };