/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/upload/uploadActions";
import { CatalogueEditForm } from "./CatalogueEditForm";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { CatalogueEditFileAndCover } from "./CatalogueEditFileAndCover";

export function CatalogueEditDialog({ id, show, onHide, isPrivate, fixStatus }) {
  // Catalogue Redux state
  const dispatch = useDispatch();
  const { uploadState } = useSelector(
    (state) => ({
      uploadState: state.upload,
    }),
    shallowEqual
  );
  const loaderHeight = 50;
  const [catalogueDetail, setCatalogueDetail] = useState(null);
  const [catalogueValue, setCatalogueValue] = useState(null);
  const [rentalPeriod, setRentalPeriod] = useState([]);
  const [fixFileModal, setFixFileModal] = useState(false);
  const [modalType, setModalType] = useState(null);

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
      setCatalogueDetail(null);
    } else {
      dispatch(actions.fetchNewPrivateContentData()); //rental period dropdown
      const reqFunc = async () => {
        try {
          const req = await axios.get(
            `/admin${isPrivate ? "/private/contents" : "/contents"}/${id}/edit`
          );
          const { data } = req;
          if (data.status && data.data) {
            setCatalogueDetail(data.data);
          }
        } catch (err) {
          onHide();
          //axios.patch(`/member/logout`);
        }
      };
      if (fixStatus === 0) {
        reqFunc();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, fixStatus]);

  useEffect(() => {
    if (fixStatus === 4) {
      setFixFileModal(true);
      setModalType("both");
    } else if (fixStatus === 6) {
      setFixFileModal(true);
      setModalType("content");
    } else if (fixStatus === 5) {
      setFixFileModal(true);
      setModalType("cover");
    }
  }, [fixStatus]);

  useEffect(() => {
    setRentalPeriod(uploadState.rentalPeriod);
  }, [uploadState.rentalPeriod]);

  useEffect(() => {
    if (catalogueDetail) {
      const values = catalogueDetail;
      const originValues = {
        name: values.title,
        publisherValue: values.creatorUID,
        authorValue: values.writerUID,
        isbn: values.ISBN,
        eisbn: values.eISBN,
        numPages: values.pageCount ? values.pageCount : undefined,
        categoryValue: values.catId,
        subCategoryValue: values.subcatId,
        startDate: values.startDate,
        endDate: values.expireDate,
        numLicenses: values.concurrent > 0 ? values.concurrent : undefined,
        tags: values.tags,
        rentalPeriodValue: values.rentalPeriod,
        anonymousRentalPeriodValue: values.anonymousRentalPeriod,
        description: values.description,
        concurrent: values.concurrent,
        activeLicense: values.activeLicense,
        file: values.file,
      };
      setCatalogueValue(originValues);
    }
  }, [catalogueDetail]);

  return fixFileModal ? (
    <CatalogueEditFileAndCover
      modalType={modalType}
      onHide={() => onHide()}
      contentUIDForUpdate={id}
      fixFileOnly={true}
    />
  ) : (
    <Modal
      backdrop="static"
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {catalogueDetail ? (
        <>
          <p
            style={{
              color: "#012875",
              fontWeight: "500",
              textAlign: "center",
              marginBottom: "0",
              marginTop: "20px",
              fontSize: "20px",
            }}
          >
            แก้ไขรายละเอียด
          </p>
          <CatalogueEditForm
            catalogueOrigin={catalogueDetail}
            catalogueValue={Object.assign(
              {},
              {
                id: undefined,
                name: "",
              },
              catalogueValue
            )}
            rentalPeriod={rentalPeriod}
            contentUIDForUpdate={id}
            onHideOutside={onHide}
            setCatalogueValue={setCatalogueValue}
            setCatalogueDetail={setCatalogueDetail}
            isPrivate={isPrivate}
          />
        </>
      ) : (
        <div
          style={{
            minHeight: `${loaderHeight}px`,
            textAlign: "center",
            padding: "20px 0 0 0",
            width: "100%",
          }}
        >
          <ClipLoader size={loaderHeight} color={"#123abc"} loading={true} />
        </div>
      )}
    </Modal>
  );
}
