import React, { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

const minimumTwoBooks = [
    // "350b7d19-633c-426a-bdde-8c80f38c4cdf", //matichon
    // 'e13b2db7-4652-4edf-a55a-cc6a4f851b6e', //tpabooks
    // '292da99f-931e-4598-8c6a-fa296b7eb5c1' //TPAPressbooks
];

export const CardOfCatalogueDetailCategory = props => {
    const history = useHistory();
    return (
        <>
            {'หมวดหมู่ : '}
            {props.catalogueForEdit &&
            (props.catalogueForEdit.status === 2 ||
                props.catalogueForEdit.status === 3) ? (
                <>
                    <span>{props.catalogue.catName}</span>
                    {' / '}
                    <span>{props.catalogue.subcatName}</span>
                </>
            ) : (
                <>
                    <span
                        className={props.catalogue.catId ? 'hoverLink' : ''}
                        onClick={() => {
                            if (props.catalogue.catId) {
                                props.setLoadingSaving(true);
                                props.updateCatalogueEbooks(
                                    'category',
                                    props.catalogue
                                );
                            }
                            // history.push(
                            //   `/catalogue-detail?id=${props.id}&newEbook=true&catId=${props.catalogue.catId}&subcategories=true`
                            // );
                        }}
                    >
                        {props.catalogue.catName}
                    </span>
                    {' / '}
                    <span
                        className={
                            props.catalogue.catId && props.catalogue.subcatId
                                ? 'hoverLink'
                                : ''
                        }
                        onClick={() => {
                            if (
                                props.catalogue.catId &&
                                props.catalogue.subcatId
                            ) {
                                props.setLoadingSaving(true);
                                props.updateCatalogueEbooks(
                                    'subcategory',
                                    props.catalogue
                                );
                            }
                            // history.push(
                            //   `/catalogue-detail?id=${props.id}&newEbook=true&ebooks=true&catId=${props.catalogue.catId}&subcatId=${props.catalogue.subcatId}`
                            // );
                        }}
                    >
                        {props.catalogue.subcatName}
                    </span>
                </>
            )}
        </>
    );
};
export const CardOfCatalogueDetailPublisher = props => {
    const history = useHistory();
    return (
        <>
            {'สำนักพิมพ์ : '}
            {props.catalogueForEdit &&
            (props.catalogueForEdit.status === 2 ||
                props.catalogueForEdit.status === 3) ? (
                <>
                    <span>
                        {props.catalogue.publisherName
                            ? props.catalogue.publisherName
                            : props.catalogue.authorName
                            ? props.catalogue.authorName
                            : '-'}
                    </span>
                </>
            ) : (
                <>
                    <span
                        className={
                            props.catalogue.creatorUID ? 'hoverLink' : ''
                        }
                        onClick={() => {
                            if (props.catalogue.creatorUID) {
                                props.setLoadingSaving(true);
                                props.updateCatalogueEbooks(
                                    'publisher',
                                    props.catalogue
                                );
                                // history.push(
                                //   `/catalogue-detail?id=${props.id}&newEbook=true&ebooks=true&publisherId=${props.catalogue.creatorUID}`
                                // );
                            }
                        }}
                    >
                        {props.catalogue.publisherName
                            ? props.catalogue.publisherName
                            : props.catalogue.authorName
                            ? props.catalogue.authorName
                            : '-'}
                    </span>
                </>
            )}
        </>
    );
};

const useStyles1 = makeStyles(theme => ({
    // div: {
    //   marginBottom: -29,
    //   marginTop: 0,
    //   zIndex: 2,
    // },
    // p1: {
    //   fontFamily: "Prompt",
    //   fontSize: "20px",
    //   fontWeight: 600,
    //   color: "#012875",
    //   margin: "32px 0 0 32px",
    // },
    // p2: {
    //   fontFamily: "Prompt",
    //   fontSize: "13px",
    //   color: "#fd6b53",
    // },
    // button: {
    //   borderRadius: 100,
    //   backgroundColor: "#012875",
    //   marginLeft: 8,
    //   padding: "0px 22px",
    //   height: 40,
    //   color: "white",
    //   fontSize: 13,
    //   fontWeight: 600,
    //   fontFamily: "Prompt",
    //   border: "none",
    //   "&:disabled": {
    //     cursor: "not-allowed",
    //     opacity: 1,
    //     borderColor: "transparent !important",
    //     backgroundColor: "#E0E0E0 !important",
    //     color: "#828282 !important",
    //   },
    // },
    // buttonskyblue: {
    //   borderRadius: 100,
    //   backgroundColor: "#0066FF",
    //   marginLeft: 8,
    //   padding: "0px 22px",
    //   height: 40,
    //   color: "white",
    //   fontSize: 13,
    //   fontWeight: 600,
    //   fontFamily: "Prompt",
    //   border: "none",
    //   "&:disabled": {
    //     cursor: "not-allowed",
    //     opacity: 1,
    //     borderColor: "transparent !important",
    //     backgroundColor: "#E0E0E0 !important",
    //     color: "#828282 !important",
    //   },
    // },
    // buttonMaxHeight: {
    //   borderRadius: 100,
    //   backgroundColor: "#012875",
    //   marginLeft: 8,
    //   padding: "0px 22px",
    //   color: "white",
    //   fontSize: 13,
    //   fontWeight: 600,
    //   fontFamily: "Prompt",
    //   border: "none",
    //   "&:disabled": {
    //     cursor: "not-allowed",
    //     opacity: 1,
    //     borderColor: "transparent !important",
    //     backgroundColor: "#E0E0E0 !important",
    //     color: "#828282 !important",
    //   },
    //   height: "40px",
    // },
    // dropdown1: {
    //   fontFamily: "Prompt",
    //   paddingLeft: 10,
    //   paddingRight: 0,
    // },
    // dropdown2: {
    //   fontFamily: "Prompt",
    //   paddingLeft: 6,
    //   paddingRight: 12,
    //   paddingBottom: 6,
    // },
    // stepDiv: {
    //   width: "200px",
    //   height: "48px",
    //   borderRadius: "20px 20px 0px 0px",
    //   margin: "0 -8.5px 0 12.5px",
    //   backgroundColor: "white",
    //   padding: "12.5px",
    //   textAlign: "center",
    // },
    // stepDivBlack: {
    //   width: "200px",
    //   height: "48px",
    //   borderRadius: "20px 20px 0px 0px",
    //   margin: "0 -8.5px 0 12.5px",
    //   backgroundColor: "#e0e0e0",
    //   padding: "12.5px",
    //   textAlign: "center",
    //   "&:hover": { cursor: "pointer" },
    // },
    // stepDivBlack_wait: {
    //   width: "200px",
    //   height: "48px",
    //   borderRadius: "20px 20px 0px 0px",
    //   margin: "0 -8.5px 0 12.5px",
    //   backgroundColor: "#e0e0e0",
    //   padding: "12.5px",
    //   textAlign: "center",
    //   "&:hover": { cursor: "wait" },
    // },
    // closeButton: {
    //   width: "30px",
    //   height: "30px",
    //   border: "none",
    //   backgroundColor: "#e0e0e0",
    //   borderRadius: "50%",
    //   padding: 0,
    //   marginBottom: 10,
    // },
    // stepDiv_p: {
    //   display: "inline-block",
    //   fontSize: "20px",
    //   color: "#FD6B53",
    //   fontWeight: 600,
    // },
    // stepDivBlack_p: {
    //   display: "inline-block",
    //   fontSize: "20px",
    //   color: "#828282",
    //   fontWeight: 600,
    // },
    // divLicense: {
    //   textAlign: "center",
    //   marginRight: "16px",
    //   display: "flex",
    //   alignItems: "center",
    // },
    // orangeText16: {
    //   fontSize: "16px",
    //   fontWeight: 600,
    //   color: "#FD6B53",
    // },
    // firstDigit: {
    //   fontSize: "20px",
    //   fontWeight: 600,
    //   color: "#012875",
    //   marginLeft: "8px",
    // },
    // summary: { minHeight: "100vh" },
    // secondDigit: { fontSize: "20px", fontWeight: 600, color: "#828282" },
    // colCatalogue: {
    //   padding: "8px",
    //   backgroundColor: "#3F3428",
    //   borderRadius: 4,
    //   "&:hover": {
    //     cursor: "pointer",
    //     "-webkit-filter": "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2))",
    //     filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2))",
    //     "-webkit-box-shadow": "0px 0px 4px rgba(0, 0, 0, 0.2)",
    //     "box-shadow": "0px 0px 4px rgba(0, 0, 0, 0.2)",
    //   },
    // },
    // colCatalogueType: {
    //   width: "230px",
    //   padding: "8px",
    //   backgroundColor: "#3F3428",
    //   borderRadius: 4,
    //   "&:hover": {
    //     cursor: "pointer",
    //     "-webkit-filter": "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2))",
    //     filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2))",
    //     "-webkit-box-shadow": "0px 0px 4px rgba(0, 0, 0, 0.2)",
    //     "box-shadow": "0px 0px 4px rgba(0, 0, 0, 0.2)",
    //   },
    //   marginTop: "4px",
    //   "-webkit-filter": "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))",
    //   filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))",
    //   "-webkit-box-shadow": "0px 0px 4px rgba(0, 0, 0, 0.1)",
    //   "box-shadow": "0px 0px 4px rgba(0, 0, 0, 0.1)",
    // },
    // colType: {
    //   padding: "8px !important",
    //   gridRowGap: "16px",
    //   rowGap: "16px",
    // },
    // colCatalogueDetail: { padding: "8px" },
    // catalogueDetail: {
    //   display: "inline-block",
    //   // transform: "translateY(10px)",
    //   fontSize: 12,
    //   marginBottom: 12,
    //   width: "65%",
    // },
    // catalogueDetailDetail: {
    //   display: "inline-block",
    //   // transform: "translateY(6px)",
    //   color: "#828282",
    //   maxWidth: "calc(100% - 123px - 8px)",
    // },
    // catalogueDetailLicense: {
    //   color: "#4F4F4F",
    //   fontSize: "15px",
    //   marginTop: "0px",
    //   marginBottom: "0px",
    // },
    // greyDigit: {
    //   display: "inline-flex",
    //   margin: "0 12px",
    //   width: 30,
    //   justifyContent: "center",
    //   fontSize: 20,
    //   fontWeight: 500,
    //   transform: "translateY(4px)",
    // },
    // blueDigit: {
    //   display: "inline-flex",
    //   margin: "0 12px",
    //   width: 30,
    //   justifyContent: "center",
    //   fontSize: 20,
    //   fontWeight: 500,
    //   transform: "translateY(4px)",
    //   color: "#012875",
    // },
    // catalogueTitle: {
    //   fontSize: "16px",
    //   fontWeight: 500,
    //   overflow: "hidden",
    //   textOverflow: "ellipsis",
    //   display: "-webkit-box",
    //   // lineHeight: 20px;
    //   "-webkit-line-clamp": "1",
    //   "-webkit-box-orient": "vertical",
    // },
    // catalogueTitle18: {
    //   fontSize: "18px",
    //   fontWeight: 500,
    //   overflow: "hidden",
    //   textOverflow: "ellipsis",
    //   display: "-webkit-box",
    //   // lineHeight: 20px;
    //   "-webkit-line-clamp": "1",
    //   "-webkit-box-orient": "vertical",
    // },
    // catalogueDetailTitle: {
    //   fontSize: "16px",
    //   fontWeight: 500,
    //   color: "#4f4f4f",
    //   "&:hover": { textDecoration: "underline", cursor: "pointer" },
    // },
    // catalogueDetailImage: {
    //   float: "left",
    //   "&:hover": { cursor: "pointer" },
    //   width: "123px",
    //   marginRight: "8px",
    //   "-webkit-filter": "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))",
    //   filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))",
    //   "-webkit-box-shadow": "0px 0px 4px rgba(0, 0, 0, 0.1)",
    //   "box-shadow": "0px 0px 4px rgba(0, 0, 0, 0.1)",
    // },
    // flex: {
    //   display: "flex",
    //   paddingLeft: "32px",
    // },
    // orangeDash: {
    //   height: "2px",
    //   width: "100%",
    //   backgroundColor: "#FD6B53",
    //   "&:hover": { cursor: "pointer" },
    // },
    // transparentDash: {
    //   height: "2px",
    //   width: "100%",
    //   backgroundColor: "transparent",
    // },
    // licenseAddRemove: {
    //   imageRendering: "-webkit-optimize-contrast",
    //   "&:hover": { cursor: "pointer" },
    // },
    // licenseAddRemoveDisabled: {
    //   imageRendering: "-webkit-optimize-contrast",
    //   "&:hover": { cursor: "not-allowed" },
    // },
    // marginBottom2px: {
    //   marginBottom: 2,
    // },
    // redLabel: {
    //   color: "#F64E60",
    // },
    // orangeLabel: {
    //   color: "#DAA520",
    // },
    // greenLabel: {
    //   color: "#219653",
    // },
    // hoverLink: {
    //   "&:hover": {
    //     textDecoration: "underline",
    //     cursor: "pointer",
    //   },
    // },
    // deleteCatalogButton: {
    //   right: "14px",
    //   marginLeft: "0.75rem",
    //   position: "absolute",
    //   bottom: "6px",
    // },
}));
export const setColorFunc = (image_, id_, setColor_, isLocal) => {
    // if (image_ && id_) {
    //   var loaded = false;
    //   var img = document.getElementById(id_.toString());
    //   if (typeof img !== "undefined" && img !== null) {
    //     let imageURL = image_;
    //     let googleProxyURL =
    //       "https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=";
    //     img.crossOrigin = "Anonymous";
    //     img.src = isLocal
    //       ? encodeURIComponent(imageURL)
    //       : googleProxyURL + encodeURIComponent(imageURL);
    //     // img.src = encodeURIComponent(imageURL);
    //     const loadHandler = () => {
    //       if (loaded) {
    //         return;
    //       }
    //       loaded = true;
    //       var fac = new FastAverageColor();
    //       if (img) {
    //         fac
    //           .getColorAsync(img)
    //           .then(function(c) {
    //             setColor_(c);
    //           })
    //           .catch(function() {});
    //       }
    //     };
    //     img.addEventListener("load", loadHandler, false);
    //     loadHandler();
    //   }
    // }
};
