/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from 'react';
import {
   Card,
   CardBody,
   CardHeader,
   CardHeaderToolbar
} from '../../../../../_metronic/_partials/controls';
import { useDispatch } from 'react-redux';
import { CustomersFilter } from './customers-filter/CustomersFilter';
import { CustomersTable } from './customers-table/CustomersTable';
import { CustomersGrouping } from './customers-grouping/CustomersGrouping';
import { useCustomersUIContext } from './CustomersUIContext';
import { makeStyles, withStyles, lighten } from '@material-ui/core/';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import * as actions from '../../_redux/customers/customersActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Role } from '../../../../../interface';

const BorderLinearProgress = withStyles({
   root: {
      height: 10,
      backgroundColor: lighten('#ff6c5c', 0.5)
   },
   bar: {
      borderRadius: 20,
      backgroundColor: '#ff6c5c'
   }
})(LinearProgress);
const useStyles = makeStyles(theme => ({
   div: {
      marginTop: 16
   },
   dropdown1: {
      fontFamily: 'Prompt',
      paddingLeft: 10,
      paddingRight: 6
   },
   dropdown2: {
      fontFamily: 'Prompt',
      paddingLeft: 6,
      paddingRight: 12,
      paddingBottom: 6
   },

   label: {
      marginBottom: 0
   },
   modalBody: {
      textAlign: 'center'
   },
   divText: { fontSize: '24px', fontWeight: '600', color: '#012875' },

   title: {
      fontSize: '20px',
      fontWeight: '600',
      textAlign: 'left',
      color: '#012875'
   },
   subtitle: {
      color: '#fd6b53',
      fontSize: '14px',
      textAlign: 'left',
      marginBottom: '10px'
   },
   dropzoneTitle: { marginBottom: '0px', fontSize: '16px' },
   dropzoneSubTitle: { marginBottom: '36px', fontSize: '14px' },
   leftLabel: { paddingLeft: '35px', textAlign: 'left' },
   rightLabel: { paddingRight: '35px', textAlign: 'right' },
   greenBox: {
      borderRadius: '12px',
      boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.2)',
      height: '85px',
      maxWidth: '450px',
      padding: '28px 0px',
      margin: 'auto'
   },
   greenLabel: { color: '#219653', fontWeight: '600', fontSize: '20px' },
   redBox: {
      borderRadius: '12px',
      boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.2)',
      height: '85px',
      maxWidth: '450px',
      padding: '28px 0px',
      margin: '24px auto 0'
   },
   redLabel: { color: '#d7042b', fontWeight: '600', fontSize: '20px' },
   orangeLabel: { color: '#ff5337', margin: 'auto', fontWeight: '500' },
   miniLabel: { fontWeight: '400', margin: '-8px 0', fontSize: '14px' },
   closeButton: { border: 'none', backgroundColor: 'transparent' },
   progressUpload: {
      margin: '3px 13px 47px',
      borderRadius: '5px'
   }
}));
const useStylesModal = makeStyles(() => ({
   body: {
      textAlign: 'center'
   },
   title: {
      fontSize: '20px',
      fontWeight: 600,
      color: '#d7042b'
   },
   subtitle: {
      fontFamily: 'Prompt',
      fontSize: '16px'
   }
}));

export function CustomersCard({ memberAmountData, isBanned }) {
   const dispatch = useDispatch();
   const customersUIContext = useCustomersUIContext();
   const [open1, setOpen1] = React.useState(false);
   const anchorRef = React.useRef(null);
   const [importModal, setImportModal] = useState(false);
   const [uploadingFile, setUploadingFile] = useState(false);
   const [successUpload, setSuccessUpload] = useState(false);
   const [failedUpload, setFailedUpload] = useState(false);
   const [numAdded, setNumAdded] = useState(0);
   const [numNotAdded, setNumNotAdded] = useState(0);
   const [linkXLSX, setLinkXLSX] = useState('');
   const [clickedDownload, setClickedDownload] = useState(false);
   const [loaded, setLoaded] = useState(0);
   const [total, setTotal] = useState(0);
   const [percentage, setPercentage] = useState(0);
   const [downloadTemplateModal, setDownloadTemplateModal] = useState(false);

   const handleToggle = () => {
      setOpen1(prevOpen => !prevOpen);
   };

   const handleClose = event => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
         return;
      }
      setOpen1(false);
   };
   function handleListKeyDown(event) {
      if (event.key === 'Tab') {
         event.preventDefault();
         setOpen1(false);
      }
   }
   const customersUIProps = useMemo(() => {
      return {
         ids: customersUIContext.ids,
         newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
         newQRCodeButtonClick: customersUIContext.newQRCodeButtonClick,
         queryParams: customersUIContext.queryParams,
         unblockAllBannedDialog: customersUIContext.unblockAllBannedDialog,
         openFileInformationDialog: customersUIContext.openFileInformationDialog
      };
   }, [customersUIContext]);
   const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
      // Disable click and keydown behavior
      accept: '.xlsx',
      noClick: true,
      noKeyboard: true,
      maxSize: 5 * Math.pow(10, 9),
      onDropRejected: e => onDropRejected(e)
   });
   const { authState, customerState } = useSelector(state => ({
      authState: state.auth,
      customerState: state.customers
   }));
   const { role, viewAsToken, isCuDomain } = authState;
   // const { authState } = useSelector(
   //   ({ auth }) => ({
   //     authState: auth,
   //   }),
   //   shallowEqual
   // );
   // const { role, viewAsToken } = authState;

   useEffect(() => {
      const reqFunc = async () => {
         console.log(acceptedFiles, acceptedFiles.length);

         if (acceptedFiles && acceptedFiles.length) {
            setUploadingFile(true);
            const req = await axios.get(`/admin/register/upload`);
            const fileToUpload = acceptedFiles[0];
            if (req.data.status) {
               const urlToUpload = req.data.data.url.url;
               const fieldsToUpload = req.data.data.url.fields;
               const fileName = req.data.data.fileName;

               var formData = new FormData();
               const entries = Object.entries(fieldsToUpload);
               for (const [key, val] of entries) {
                  formData.append(key, val);
               }
               formData.append('file', fileToUpload);
               axios({
                  method: 'post',
                  url: urlToUpload,
                  data: formData,
                  onUploadProgress: progressEvent => {
                     if (progressEvent) {
                        setLoaded((progressEvent.loaded / 1000).toFixed(2));
                        setTotal((progressEvent.total / 1000).toFixed(2));
                        setPercentage(
                           (
                              (100 * progressEvent.loaded) /
                              progressEvent.total
                           ).toFixed()
                        );
                     }
                  }
               })
                  .then(() => {
                     axios
                        .post(`/admin/register/csv`, { fileName: fileName })
                        .then(res => {
                           if (res.data.status) {
                              dispatch(
                                 actions.fetchCustomers(
                                    customersUIProps.queryParams
                                 )
                              );
                              setNumAdded(res.data.data.successful);
                              setNumNotAdded(res.data.data.unsuccessfully);
                              setLinkXLSX(res.data.data.fulfillmentUrl);
                           }
                        });
                     setSuccessUpload(true);
                     return true;
                  })
                  .catch(() => {
                     setFailedUpload(true);
                  });
            }
         }
      };
      reqFunc();
   }, [acceptedFiles]);

   // const files = acceptedFiles.map(file => (
   //   <li key={file.path}>
   //     {file.path} - {file.size} bytes
   //   </li>
   // ));
   const classes = useStyles();
   const classesModal = useStylesModal();

   const downloadTemplate = () => {
      axios.get(`/admin/register/csv`).then(res => {
         if (res.data && res.data.data && res.data.data.fulfillmentUrl) {
            let link = document.createElement('a');
            link.setAttribute('download', 'template');
            link.href = res.data.data.fulfillmentUrl;
            document.body.appendChild(link);
            link.click();
            link.remove();
            setDownloadTemplateModal(false);
         }
      });
   };

   const onDropRejected = e => {
      console.log(e);
      if (e && Array.isArray(e) && e.length > 0) {
         if (
            e[0].errors &&
            Array.isArray(e[0].errors) &&
            e[0].errors.length > 0
         ) {
            if (e[0].errors[0] && e[0].errors[0].code === 'file-invalid-type') {
               customersUIProps.openFileInformationDialog(
                  'file-invalid-type',
                  e[0].errors[0].message
               );
            }
         }
      }
   };

   return (
      <>
         <Modal
            backdrop="static"
            show={downloadTemplateModal}
            onHide={() => setDownloadTemplateModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            style={{ margin: '3% auto' }}
         >
            <Modal.Body className={classesModal.body}>
               <img src="/media/members/image-modal.svg" alt="" />
               <div className={classesModal.title}>ยืนยันการดาวน์โหลด</div>
               <div className={classesModal.subtitle}>
                  ต้องการดาวน์โหลด Template
               </div>
               <div className={classesModal.subtitle}>
                  สำหรับการเพิ่มสมาชิกหรือไม่?
               </div>
               <div className="divButton">
                  <button
                     type="button"
                     onClick={() => setDownloadTemplateModal(false)}
                     className="denyButton"
                  >
                     ยกเลิก
                  </button>
                  <button
                     type="button"
                     onClick={downloadTemplate}
                     className="acceptButton"
                  >
                     ยืนยัน
                  </button>
               </div>
            </Modal.Body>
         </Modal>
         <Card className="cardStyle">
            <CardHeader
               title={
                  //  className={classes.div}
                  <div
                     className="card-label"
                     style={role === Role.SuperAdmin ? { marginTop: '0' } : {}}
                  >
                     <div>{isBanned ? 'สมาชิกที่ถูกแบน' : 'สมาชิก'}</div>
                     {role === Role.SuperAdmin || isBanned ? (
                        ''
                     ) : (
                        <div>
                           จำนวนสมาชิก{' '}
                           {memberAmountData
                              ? memberAmountData.currentMember?.toLocaleString()
                              : 0}
                           /
                           {memberAmountData
                              ? memberAmountData.maximumMember?.toLocaleString()
                              : 0}
                        </div>
                     )}
                  </div>
               }
            >
               <CardHeaderToolbar>
                  <CustomersFilter isBanned={isBanned} />
                  {role === Role.SuperAdmin ? (
                     ''
                  ) : (
                     <>
                        <button
                           type="button"
                           className="button"
                           onClick={customersUIProps.newQRCodeButtonClick}
                        >
                           <span>
                              <img
                                 alt="qrcode"
                                 src="/media/members/qrcode-button.svg"
                                 style={{ marginRight: '8px' }}
                              />
                           </span>
                           <span>สร้าง QR Code</span>
                        </button>
                        <button
                           type="button"
                           className="button"
                           onClick={() => {
                              setDownloadTemplateModal(true);
                           }}
                        >
                           <span>
                              <img
                                 alt="excel"
                                 src="/media/members/excel-button.svg"
                                 style={{ marginRight: '8px' }}
                              />
                           </span>
                           <span>ดาวน์โหลด Template</span>
                        </button>
                     </>
                  )}
                  {isBanned ? (
                     <button
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        className="button"
                        onClick={customersUIProps.unblockAllBannedDialog}
                     >
                        <span>ปลดบล็อกทั้งหมด</span>
                     </button>
                  ) : (
                     <div>
                        {/* {(!isCuDomain || viewAsToken || role !== Role.SuperAdmin)? (
                   <button
                   ref={anchorRef}
                   aria-controls={open ? "menu-list-grow" : undefined}
                   aria-haspopup="true"
                   onClick={handleToggle}
                   className="button"
                 >
                   <span>
                     <img
                       alt="addmember"
                       src="/media/members/add-member-button.svg"
                     />
                   </span>
                   <span>เพิ่มสมาชิก</span>
                 </button>
                ):'' } */}
                        {isCuDomain &&
                        viewAsToken === undefined &&
                        role === Role.SuperAdmin ? null : (
                           <button
                              ref={anchorRef}
                              aria-controls={
                                 open ? 'menu-list-grow' : undefined
                              }
                              aria-haspopup="true"
                              onClick={handleToggle}
                              className="button"
                           >
                              <span>
                                 <img
                                    alt="addmember"
                                    src="/media/members/add-member-button.svg"
                                 />
                              </span>
                              <span>เพิ่มสมาชิก</span>
                           </button>
                        )}
                        <Popper
                           open={open1}
                           anchorEl={anchorRef.current}
                           role={undefined}
                           transition
                           placement="bottom-start"
                           // disablePortal
                        >
                           {({ TransitionProps, placement }) => (
                              <Grow
                                 {...TransitionProps}
                                 style={{
                                    transformOrigin:
                                       placement === 'bottom'
                                          ? 'center top'
                                          : 'center bottom'
                                 }}
                              >
                                 <Paper>
                                    <ClickAwayListener
                                       onClickAway={handleClose}
                                    >
                                       <MenuList
                                          autoFocusItem={open1}
                                          id="menu-list-grow"
                                          onKeyDown={handleListKeyDown}
                                          style={{ paddingBottom: '0px' }}
                                       >
                                          <MenuItem
                                             className={classes.dropdown1}
                                             onClick={
                                                customersUIProps.newCustomerButtonClick
                                             }
                                          >
                                             <span>
                                                <img
                                                   alt="addmember"
                                                   src="/media/members/add-member-button.svg"
                                                   style={{
                                                      marginRight: '8px'
                                                   }}
                                                />
                                             </span>
                                             <span>เพิ่มทีละรายการ</span>
                                          </MenuItem>
                                          <MenuItem
                                             className={classes.dropdown2}
                                             onClick={() =>
                                                setImportModal(true)
                                             }
                                          >
                                             <span>
                                                <img
                                                   alt="addmember"
                                                   src="/media/members/add-excel-button.svg"
                                                   style={{
                                                      marginRight: '4px'
                                                   }}
                                                />
                                             </span>
                                             <span>Import Excel</span>
                                          </MenuItem>
                                       </MenuList>
                                    </ClickAwayListener>
                                 </Paper>
                              </Grow>
                           )}
                        </Popper>

                        {customerState.rowSelected.length > 0 ? (
                           <button className="button">ลบที่เลือก</button>
                        ) : null}
                     </div>
                  )}
               </CardHeaderToolbar>
            </CardHeader>
            <CardBody className="cardBodyStyle">
               {customersUIProps.ids.length > 0 && <CustomersGrouping />}
               <CustomersTable
                  memberAmountData={memberAmountData}
                  isBanned={isBanned}
                  viewAsToken={viewAsToken}
               />
            </CardBody>
         </Card>
         <Modal
            backdrop="static"
            size="md"
            show={importModal}
            onHide={() => {
               if ((clickedDownload && successUpload) || !successUpload) {
                  setUploadingFile(false);
                  setImportModal(false);
                  setFailedUpload(false);
                  setSuccessUpload(false);
                  setClickedDownload(false);
               } else {
                  alert('กรุณาดาวน์โหลดไฟล์ .XLSX ก่อน');
               }
            }}
            aria-labelledby="example-modal-sizes-title-lg"
            style={{ marginTop: '27px' }}
         >
            <Modal.Body className={classes.modalBody}>
               <div
                  style={{
                     textAlign: 'right',
                     position: 'absolute',
                     right: '16px'
                  }}
               >
                  <button
                     className={classes.closeButton}
                     onClick={() => {
                        if (
                           (clickedDownload && successUpload) ||
                           !successUpload
                        ) {
                           setUploadingFile(false);
                           setImportModal(false);
                           setFailedUpload(false);
                           setSuccessUpload(false);
                           setClickedDownload(false);
                        } else {
                           alert('กรุณาดาวน์โหลดไฟล์ .XLSX ก่อน');
                        }
                     }}
                  >
                     <img src="/media/books/close-button.svg" alt="" />
                  </button>
               </div>
               {successUpload ? (
                  <>
                     <p className={classes.title}>สำเร็จ</p>
                     <div className={`row ${classes.greenBox}`}>
                        <div
                           className={`col-6 ${classes.greenLabel} ${classes.leftLabel}`}
                        >
                           สำเร็จ
                        </div>
                        <div
                           className={`col-6 ${classes.greenLabel} ${classes.rightLabel}`}
                           style={{ marginTop: '-8px' }}
                        >
                           {numAdded} <p className={classes.miniLabel}>users</p>
                        </div>
                     </div>
                     {numNotAdded ? (
                        <div className={`row ${classes.redBox}`}>
                           <div
                              className={`col-6 ${classes.redLabel} ${classes.leftLabel}`}
                           >
                              ไม่สำเร็จ
                           </div>
                           <div
                              className={`col-6 ${classes.redLabel} ${classes.rightLabel}`}
                              style={{ marginTop: '-8px' }}
                           >
                              {numNotAdded}{' '}
                              <p className={classes.miniLabel}>users</p>
                           </div>
                        </div>
                     ) : (
                        ''
                     )}
                     <button
                        type="button"
                        className="button"
                        onClick={() => {
                           let link = document.createElement('a');
                           link.setAttribute('download', 'template');
                           link.href = linkXLSX;
                           document.body.appendChild(link);
                           link.click();
                           link.remove();
                           setClickedDownload(true);
                        }}
                        style={{ margin: '24px auto 17px' }}
                     >
                        <span>
                           <img
                              alt="excel"
                              src="/media/members/excel-button.svg"
                              style={{ marginRight: '8px' }}
                           />
                        </span>
                        <span>ดาวน์โหลด .XLSX</span>
                     </button>
                     <div className={classes.orangeLabel}>
                        กรุณาดาวน์โหลดไฟล์ .XLSX
                     </div>
                  </>
               ) : (
                  <>
                     <div className={classes.title}>อัปโหลดไฟล์</div>
                     <div className={classes.subtitle}>
                        อัปโหลดไฟล์ .XLSX เท่านั้น{' '}
                     </div>
                     <div
                        {...getRootProps({ className: 'dropzone' })}
                        style={{ border: '1px dashed' }}
                     >
                        <input {...getInputProps()} />
                        <img src="/media/members/excel-modal.svg" alt="" />
                        <div style={{ height: '168px' }}>
                           {failedUpload ? (
                              <p className={classes.dropzoneTitle}>
                                 ล้มเหลว กรุณาลองใหม่อีกครั้ง
                              </p>
                           ) : uploadingFile ? (
                              <>
                                 <p className={classes.dropzoneTitle}>
                                    รอสักครู่ ระบบกำลังอัปโหลดไฟล์...
                                 </p>
                                 <div
                                    className="row"
                                    style={{
                                       margin: '40px 0.5px 0',
                                       color: '#012875'
                                    }}
                                 >
                                    <p
                                       className="col-6"
                                       style={{
                                          fontSize: '12px',
                                          textAlign: 'left',
                                          margin: '0'
                                       }}
                                    >
                                       {loaded}KB/{total}KB
                                    </p>
                                    <p
                                       className="col-6"
                                       style={{
                                          fontSize: '14px',
                                          textAlign: 'end',
                                          margin: '0'
                                       }}
                                    >
                                       {parseInt(percentage, 10)}%
                                    </p>
                                 </div>
                                 <BorderLinearProgress
                                    className={classes.progressUpload}
                                    variant="determinate"
                                    color="secondary"
                                    value={percentage}
                                 />
                              </>
                           ) : (
                              <>
                                 <h4>Drag a file here</h4>
                                 <div>or browse your computer</div>
                                 <button
                                    type="button"
                                    className="browseButtonPrimary "
                                    onClick={open}
                                 >
                                    Browse
                                 </button>
                              </>
                           )}
                        </div>
                     </div>
                  </>
               )}
            </Modal.Body>
         </Modal>
      </>
   );
}
