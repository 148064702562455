// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";

export function WaitingAmountFormatter(
  cellContent,
  row,
  rowIndex,
  { isPrivate }
) {
  const getLabelCssClasses = (c) => {
    return (Number.isInteger(cellContent) &&
      Number.isInteger(row.concurrent) &&
      Number.isInteger(row.activeLicense)) ||
      (Number.isInteger(row.concurrent) && !Number.isInteger(row.activeLicense))
      ? `label label-lg ${c > 0 ? "label-light-danger" : ""} label-inline`
      : "";
  };
  return Number.isInteger(cellContent) ? (
    <span className={getLabelCssClasses(cellContent)}>
      {Number.isInteger(row.concurrent) && Number.isInteger(row.activeLicense)
        ? cellContent
        : Number.isInteger(row.concurrent) &&
          !Number.isInteger(row.activeLicense)
        ? cellContent
        : "ไม่จำกัด"}
    </span>
  ) : (
    <span className={getLabelCssClasses(cellContent)}>-</span>
  );
}
