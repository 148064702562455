/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { DownloadOutlined } from '@ant-design/icons';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';
import { shallowEqual, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { Role } from '../../../../../interface';

export function AsideMenuList({ layoutProps }) {
   const location = useLocation();
   const getMenuItemActive = url => {
      if (
         (url === '/ebooks' &&
            location &&
            location.pathname === '/categories/ebooks') ||
         (url === '/ebooks-private' &&
            location &&
            location.pathname === '/ebooks') ||
         (url === '/ebooks' &&
            location &&
            location.pathname === '/ebooks-private') ||
         (url === '/ebooks-free' &&
            location &&
            location.pathname === '/ebooks') ||
         (url === '/ebooks' &&
            location &&
            (location.pathname === '/ebooks-free' ||
               (location.pathname.includes('-free') &&
                  location.pathname.includes('/view')))) ||
         (url === '/categories-private-ebook' &&
            location &&
            location.pathname === '/categories') ||
         (url === '/categories' &&
            location &&
            location.pathname === '/categories-private-ebook') ||
         (url === '/categories-private-video' &&
            location &&
            location.pathname === '/categories') ||
         (url === '/categories' &&
            location &&
            location.pathname === '/categories-private-video') ||
         (url === '/categories-private-podcast' &&
            location &&
            location.pathname === '/categories') ||
         (url === '/categories' &&
            location &&
            location.pathname === '/categories-private-podcast') ||
         (url === '/channels-podcast' &&
            location &&
            location.pathname === '/channels') ||
         (url === '/channels' &&
            location &&
            location.pathname === '/channels-podcast') ||
         (url === '/catalogue' &&
            location &&
            location.pathname === '/catalogue-detail') ||
         (url === '/catalogue-detail' &&
            location &&
            location.pathname === '/catalogue') ||
         (url === '/catalogue' &&
            location &&
            location.pathname === '/catalogue-example') ||
         (url === '/catalogue-example' &&
            location &&
            location.pathname === '/catalogue') ||
         (url === '/checkebook-detail' &&
            location &&
            location.pathname === '/checkebook')
      ) {
         return '';
      }
      return checkIsActive(location, url)
         ? ' menu-item-active menu-item-open '
         : '';
   };
   const [canUpload, setCanUpload] = useState(false);
   const { authState } = useSelector(
      ({ auth }) => ({
         authState: auth
      }),
      shallowEqual
   );
   const { authToken, role, viewAsToken } = authState;
   useEffect(() => {
      if (viewAsToken) {
         if (jwt_decode(viewAsToken).isPrivateLibrary) {
            setCanUpload(true);
         } else {
            setCanUpload(false);
         }
      } else {
         if (jwt_decode(authToken).isPrivateLibrary) {
            setCanUpload(true);
         } else {
            setCanUpload(false);
         }
      }
   }, [authToken, viewAsToken]);
   // dfdsdf
   return (
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
         {role === Role.SuperAdmin && !viewAsToken ? (
            <>
               <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                     '/google-material'
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
               >
                  <NavLink
                     className={`menu-link menu-toggle`}
                     to="/google-material"
                  >
                     <span className="svg-icon menu-icon">
                        <SVG
                           src={toAbsoluteUrl(
                              '/media/members/members-icon.svg'
                           )}
                        />
                     </span>
                     <span className="menu-text">จัดการองค์กรที่ใช้บริการ</span>
                     <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                     <ul className="menu-subnav">
                        {/*begin::2 Level*/}
                        <li
                           className={`menu-item ${getMenuItemActive(
                              '/companies'
                           )}`}
                           aria-haspopup="true"
                        >
                           <NavLink className="menu-link" to="/companies">
                              <i className="menu-bullet menu-bullet-dot">
                                 <span />
                              </i>
                              <span className="menu-text">
                                 องค์กรที่ใช้บริการ
                              </span>
                           </NavLink>
                        </li>
                        <li
                           className={`menu-item ${getMenuItemActive(
                              '/members'
                           )}`}
                           aria-haspopup="true"
                        >
                           <NavLink className="menu-link" to="/members">
                              <i className="menu-bullet menu-bullet-dot">
                                 <span />
                              </i>
                              <span className="menu-text">สมาชิก</span>
                           </NavLink>
                        </li>
                        <li
                           className={`menu-item ${getMenuItemActive(
                              '/banned-members'
                           )}`}
                           aria-haspopup="true"
                        >
                           <NavLink className="menu-link" to="/banned-members">
                              <i className="menu-bullet menu-bullet-dot">
                                 <span />
                              </i>
                              <span className="menu-text">
                                 จัดการสมาชิกที่ถูกแบน
                              </span>
                           </NavLink>
                        </li>
                     </ul>
                  </div>
               </li>
               <li
                  className={`menu-item ${getMenuItemActive('/checkebook')}`}
                  aria-haspopup="true"
               >
                  <NavLink className="menu-link" to="/checkebook">
                     <span className="svg-icon menu-icon">
                        <SVG
                           src={toAbsoluteUrl(
                              '/media/members/members-icon.svg'
                           )}
                        />
                     </span>
                     <span className="menu-text">ตรวจสอบรายการอีบุ๊ก</span>
                  </NavLink>
               </li>
               {/* <li
                  className={`menu-item ${getMenuItemActive('/report')}`}
                  aria-haspopup="true"
               >
                  <NavLink className="menu-link" to="/report-super-admin">
                     <span className="svg-icon menu-icon">
                        <SVG
                           src={toAbsoluteUrl(
                              '/media/members/reports-icon.svg'
                           )}
                        />
                     </span>
                     <span className="menu-text">รายงาน</span>
                  </NavLink>
               </li> */}
            </>
         ) : (
            <>
               <li
                  className={`menu-item ${getMenuItemActive('/dashboard')}`}
                  aria-haspopup="true"
               >
                  <NavLink className="menu-link" to="/dashboard">
                     <span className="svg-icon menu-icon">
                        {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} /> */}
                        <SVG
                           src={toAbsoluteUrl(
                              '/media/members/dashboard-icon.svg'
                           )}
                        />
                     </span>
                     <span className="menu-text">Dashboard</span>
                  </NavLink>
               </li>
               <li
                  className={`menu-item ${getMenuItemActive('/members')}`}
                  aria-haspopup="true"
               >
                  <NavLink className="menu-link" to="/members">
                     <span className="svg-icon menu-icon">
                        <SVG
                           src={toAbsoluteUrl(
                              '/media/members/members-icon.svg'
                           )}
                        />
                        {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-4-blocks.svg")} /> */}
                     </span>
                     <span className="menu-text">สมาชิก</span>
                  </NavLink>
               </li>
               <li
                  className={`menu-item ${getMenuItemActive('/qrcodes')}`}
                  aria-haspopup="true"
               >
                  <NavLink className="menu-link" to="/qrcodes">
                     <span className="svg-icon menu-icon">
                        <SVG
                           src={toAbsoluteUrl(
                              '/media/members/qrcodes-icon.svg'
                           )}
                        />
                     </span>
                     <span
                        className="menu-text"
                        style={{ transform: 'translateY(1px)' }}
                     >
                        QR Code
                     </span>
                  </NavLink>
               </li>
               <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                     '/google-material'
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
               >
                  <NavLink
                     className={`menu-link menu-toggle`}
                     to="/google-material"
                  >
                     <span className="svg-icon menu-icon">
                        <SVG
                           src={toAbsoluteUrl(
                              '/media/members/hibrary-icon.svg'
                           )}
                        />
                     </span>
                     <span className="menu-text">สื่อ Hibrary</span>
                     <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                     <ul className="menu-subnav">
                        <li
                           className={`menu-item menu-item-submenu ${getMenuItemActive(
                              '/google-material'
                           )}`}
                           aria-haspopup="true"
                           data-menu-toggle="hover"
                        >
                           <NavLink
                              className="menu-link menu-toggle"
                              to="/google-material"
                           >
                              <i className="menu-bullet menu-bullet-dot">
                                 <span />
                              </i>
                              <span className="menu-text">
                                 จัดการสื่อ Hibrary
                              </span>
                              <i className="menu-arrow" />
                           </NavLink>
                           <div className="menu-submenu">
                              <ul className="menu-subnav">
                                 <li
                                    className={`menu-item ${getMenuItemActive(
                                       '/ebooks/type/Ebook'
                                    )}`}
                                    aria-haspopup="true"
                                 >
                                    <NavLink
                                       className="menu-link"
                                       to="/ebooks/type/Ebook"
                                    >
                                       <i className="menu-bullet menu-bullet-dot">
                                          <span />
                                       </i>
                                       <span className="menu-text">อีบุ๊ก</span>
                                    </NavLink>
                                 </li>

                                 <li
                                    className={`menu-item ${getMenuItemActive(
                                       '/ebooks/type/Magazine'
                                    )}`}
                                    aria-haspopup="true"
                                 >
                                    <NavLink
                                       className="menu-link"
                                       to="/ebooks/type/Magazine"
                                    >
                                       <i className="menu-bullet menu-bullet-dot">
                                          <span />
                                       </i>
                                       <span className="menu-text">
                                          นิตยสาร
                                       </span>
                                    </NavLink>
                                 </li>

                                 <li
                                    className={`menu-item ${getMenuItemActive(
                                       '/ebooks/type/Newspaper'
                                    )}`}
                                    aria-haspopup="true"
                                 >
                                    <NavLink
                                       className="menu-link"
                                       to="/ebooks/type/Newspaper"
                                    >
                                       <i className="menu-bullet menu-bullet-dot">
                                          <span />
                                       </i>
                                       <span className="menu-text">
                                          หนังสือพิมพ์
                                       </span>
                                    </NavLink>
                                 </li>

                                 <li
                                    className={`menu-item ${getMenuItemActive(
                                       '/expire'
                                    )}`}
                                    aria-haspopup="true"
                                 >
                                    <NavLink className="menu-link" to="/expire">
                                       <i className="menu-bullet menu-bullet-dot">
                                          <span />
                                       </i>
                                       <span className="menu-text">
                                          อีบุ๊กหมดอายุ
                                       </span>
                                    </NavLink>
                                 </li>
                                 {role === Role.SuperAdmin && viewAsToken ? (
                                    jwt_decode(viewAsToken).storeStatus ===
                                    'trial' ? (
                                       <li
                                          className={`menu-item ${getMenuItemActive(
                                             '/catalogue-example'
                                          )}`}
                                          aria-haspopup="true"
                                       >
                                          <NavLink
                                             className="menu-link"
                                             to="/catalogue-example"
                                          >
                                             <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                             </i>
                                             <span className="menu-text">
                                                เพิ่มตัวอย่างอีบุ๊ก
                                             </span>
                                          </NavLink>
                                       </li>
                                    ) : (
                                       <li
                                          className={`menu-item ${getMenuItemActive(
                                             '/view-as-upload-content'
                                          )}`}
                                          aria-haspopup="true"
                                       >
                                          <NavLink
                                             className="menu-link"
                                             to="/view-as-upload-content"
                                          >
                                             <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                             </i>
                                             <span className="menu-text">
                                                เพิ่มสื่อ
                                             </span>
                                          </NavLink>
                                       </li>
                                    )
                                 ) : (
                                    ''
                                 )}
                                 <li
                                    className={`menu-item ${getMenuItemActive(
                                       '/catalogue'
                                    )}`}
                                    aria-haspopup="true"
                                 >
                                    <NavLink
                                       className="menu-link"
                                       to="/catalogue"
                                    >
                                       <i className="menu-bullet menu-bullet-dot">
                                          <span />
                                       </i>
                                       <span className="menu-text">
                                          เลือกรายการอีบุ๊ก
                                       </span>
                                    </NavLink>
                                 </li>
                              </ul>
                           </div>
                        </li>
                        <li
                           className={`menu-item ${getMenuItemActive(
                              '/ebooks-free'
                           )}`}
                           aria-haspopup="true"
                        >
                           <NavLink className="menu-link" to="/ebooks-free">
                              <i className="menu-bullet menu-bullet-dot">
                                 <span />
                              </i>
                              <span className="menu-text">เผยแพร่ฟรี</span>
                           </NavLink>
                        </li>
                        {/*end::2 Level*/}
                        <li
                           className={`menu-item menu-item-submenu ${getMenuItemActive(
                              '/google-material'
                           )}`}
                           aria-haspopup="true"
                           data-menu-toggle="hover"
                        >
                           <NavLink
                              className="menu-link menu-toggle"
                              to="/google-material"
                           >
                              <i className="menu-bullet menu-bullet-dot">
                                 <span />
                              </i>
                              <span className="menu-text">หมวดหมู่</span>
                              <i className="menu-arrow" />
                           </NavLink>
                           <div className="menu-submenu">
                              <ul className="menu-subnav">
                                 <li
                                    className={`menu-item ${getMenuItemActive(
                                       '/categories'
                                    )}`}
                                    aria-haspopup="true"
                                 >
                                    <NavLink
                                       className="menu-link"
                                       to="/categories"
                                    >
                                       <i className="menu-bullet menu-bullet-dot">
                                          <span />
                                       </i>
                                       <span className="menu-text">อีบุ๊ก</span>
                                    </NavLink>
                                 </li>
                              </ul>
                           </div>
                        </li>
                        {role === Role.SuperAdmin && viewAsToken ? (
                           <li
                              className={`menu-item ${getMenuItemActive(
                                 '/subscription'
                              )}`}
                              aria-haspopup="true"
                           >
                              <NavLink className="menu-link" to="/subscription">
                                 <i className="menu-bullet menu-bullet-dot">
                                    <span />
                                 </i>
                                 <span className="menu-text">Subscription</span>
                              </NavLink>
                           </li>
                        ) : (
                           ''
                        )}
                     </ul>
                  </div>
               </li>
               {/* // */}
               {canUpload ? (
                  <>
                     <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                           '/google-material'
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                     >
                        <NavLink
                           className={`menu-link menu-toggle`}
                           to="/google-material"
                        >
                           <span className="svg-icon menu-icon">
                              <SVG
                                 src={toAbsoluteUrl(
                                    '/media/members/hibrary-private-icon.svg'
                                 )}
                              />
                           </span>
                           <span className="menu-text">จัดการสื่อองค์กร</span>
                           <i className="menu-arrow" />
                        </NavLink>
                        <div className="menu-submenu">
                           <ul className="menu-subnav">
                              <li
                                 className={`menu-item ${getMenuItemActive(
                                    '/ebooks-private'
                                 )}`}
                                 aria-haspopup="true"
                              >
                                 <NavLink
                                    className="menu-link"
                                    to="/ebooks-private"
                                 >
                                    <i className="menu-bullet menu-bullet-dot">
                                       <span />
                                    </i>
                                    <span className="menu-text">อีบุ๊ก</span>
                                 </NavLink>
                              </li>

                              <li
                                 className={`menu-item ${getMenuItemActive(
                                    '/videos-private'
                                 )}`}
                                 aria-haspopup="true"
                              >
                                 <NavLink
                                    className="menu-link"
                                    to="/videos-private"
                                 >
                                    <i className="menu-bullet menu-bullet-dot">
                                       <span />
                                    </i>
                                    <span className="menu-text">วิดีโอ</span>
                                 </NavLink>
                              </li>
                              <li
                                 className={`menu-item ${getMenuItemActive(
                                    '/podcasts-private'
                                 )}`}
                                 aria-haspopup="true"
                              >
                                 <NavLink
                                    className="menu-link"
                                    to="/podcasts-private"
                                 >
                                    <i className="menu-bullet menu-bullet-dot">
                                       <span />
                                    </i>
                                    <span className="menu-text">พ็อดคาสท์</span>
                                 </NavLink>
                              </li>
                              {/* {role === Role.superadmin ? (
                      ""
                    ) : (
                      <li
                        className={`menu-item ${getMenuItemActive("/upload")}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/upload">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">อัปโหลดสื่อ</span>
                        </NavLink>
                      </li>
                    )} */}
                              <li
                                 className={`menu-item menu-item-submenu ${getMenuItemActive(
                                    '/google-material'
                                 )}`}
                                 aria-haspopup="true"
                                 data-menu-toggle="hover"
                              >
                                 <NavLink
                                    className="menu-link menu-toggle"
                                    to="/google-material"
                                 >
                                    <i className="menu-bullet menu-bullet-dot">
                                       <span />
                                    </i>
                                    <span className="menu-text">
                                       จัดการสำนักพิมพ์/Channel
                                    </span>
                                    <i className="menu-arrow" />
                                 </NavLink>
                                 <div className="menu-submenu">
                                    <ul className="menu-subnav">
                                       <li
                                          className={`menu-item ${getMenuItemActive(
                                             '/publishers'
                                          )}`}
                                          aria-haspopup="true"
                                       >
                                          <NavLink
                                             className="menu-link"
                                             to="/publishers"
                                          >
                                             <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                             </i>
                                             <span className="menu-text">
                                                อีบุ๊ก
                                             </span>
                                          </NavLink>
                                       </li>
                                       <li
                                          className={`menu-item ${getMenuItemActive(
                                             '/channels'
                                          )}`}
                                          aria-haspopup="true"
                                       >
                                          <NavLink
                                             className="menu-link"
                                             to="/channels"
                                          >
                                             <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                             </i>
                                             <span className="menu-text">
                                                วิดีโอ
                                             </span>
                                          </NavLink>
                                       </li>
                                       <li
                                          className={`menu-item ${getMenuItemActive(
                                             '/channels-podcast'
                                          )}`}
                                          aria-haspopup="true"
                                       >
                                          <NavLink
                                             className="menu-link"
                                             to="/channels-podcast"
                                          >
                                             <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                             </i>
                                             <span className="menu-text">
                                                พ็อดคาสท์
                                             </span>
                                          </NavLink>
                                       </li>
                                    </ul>
                                 </div>
                              </li>
                              <li
                                 className={`menu-item menu-item-submenu ${getMenuItemActive(
                                    '/google-material'
                                 )}`}
                                 aria-haspopup="true"
                                 data-menu-toggle="hover"
                              >
                                 <NavLink
                                    className="menu-link menu-toggle"
                                    to="/google-material"
                                 >
                                    <i className="menu-bullet menu-bullet-dot">
                                       <span />
                                    </i>
                                    <span className="menu-text">หมวดหมู่</span>
                                    <i className="menu-arrow" />
                                 </NavLink>
                                 <div className="menu-submenu">
                                    <ul className="menu-subnav">
                                       <li
                                          className={`menu-item ${getMenuItemActive(
                                             '/categories-private-ebook'
                                          )}`}
                                          aria-haspopup="true"
                                       >
                                          <NavLink
                                             className="menu-link"
                                             to="/categories-private-ebook"
                                          >
                                             <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                             </i>
                                             <span className="menu-text">
                                                อีบุ๊ก
                                             </span>
                                          </NavLink>
                                       </li>
                                       <li
                                          className={`menu-item ${getMenuItemActive(
                                             '/categories-private-video'
                                          )}`}
                                          aria-haspopup="true"
                                       >
                                          <NavLink
                                             className="menu-link"
                                             to="/categories-private-video"
                                          >
                                             <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                             </i>
                                             <span className="menu-text">
                                                วิดีโอ
                                             </span>
                                          </NavLink>
                                       </li>
                                       <li
                                          className={`menu-item ${getMenuItemActive(
                                             '/categories-private-podcast'
                                          )}`}
                                          aria-haspopup="true"
                                       >
                                          <NavLink
                                             className="menu-link"
                                             to="/categories-private-podcast"
                                          >
                                             <i className="menu-bullet menu-bullet-dot">
                                                <span />
                                             </i>
                                             <span className="menu-text">
                                                พ็อดคาสท์
                                             </span>
                                          </NavLink>
                                       </li>
                                    </ul>
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </li>
                  </>
               ) : (
                  ''
               )}
               <li
                  className={`menu-item ${getMenuItemActive('/banners')}`}
                  aria-haspopup="true"
               >
                  <NavLink className="menu-link" to="/banners">
                     <span className="svg-icon menu-icon">
                        <SVG
                           src={toAbsoluteUrl('/media/members/banner-icon.svg')}
                        />
                     </span>
                     <span className="menu-text">แบนเนอร์</span>
                  </NavLink>
               </li>
               {(role === Role.SuperAdmin &&
                  jwt_decode(viewAsToken).clientPrefix === 'ssru') ||
               jwt_decode(authToken).clientPrefix === 'ssru' ? (
                  <li
                     className={`menu-item ${getMenuItemActive('/publicApi')}`}
                     aria-haspopup="true"
                  >
                     {console.log()}
                     <NavLink className="menu-link" to="/publicApi">
                        <span className="svg-icon menu-icon">
                           <SVG
                              src={toAbsoluteUrl('/media/members/key-icon.svg')}
                           />
                        </span>
                        <span className="menu-text">Token Key</span>
                     </NavLink>
                  </li>
               ) : null}
               {role === Role.SuperAdmin && viewAsToken ? null : (
                  <li
                     className={`menu-item ${getMenuItemActive('/report')}`}
                     aria-haspopup="true"
                  >
                     <NavLink className="menu-link" to="/report">
                        <span className="svg-icon menu-icon">
                           <SVG
                              src={toAbsoluteUrl(
                                 '/media/members/reports-icon.svg'
                              )}
                           />
                        </span>
                        <span className="menu-text">รายงาน</span>
                     </NavLink>
                  </li>
               )}
               <li
                  className={`menu-item ${getMenuItemActive('/setting')}`}
                  aria-haspopup="true"
               >
                  <NavLink className="menu-link" to="/setting">
                     <span className="svg-icon menu-icon">
                        <SVG
                           src={toAbsoluteUrl(
                              '/media/members/members-icon.svg'
                           )}
                        />
                     </span>
                     <span className="menu-text">ตั้งค่า</span>
                  </NavLink>
               </li>
            </>
         )}

         {authState.userInfo && authState.userInfo.contentDistributor ? (
            <>
               <li
                  className={`menu-item ${getMenuItemActive('/setting')}`}
                  aria-haspopup="true"
               >
                  <NavLink className="menu-link" to="/download-file">
                     <span className="svg-icon menu-icon">
                        <DownloadOutlined />
                     </span>
                     <span className="menu-text">ดาวน์โหลด</span>
                  </NavLink>
               </li>
            </>
         ) : (
            ''
         )}
      </ul>
   );
}
