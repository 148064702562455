import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/publishers/publishersActions";
import { usePublishersUIContext } from "../PublishersUIContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
     body: {
          textAlign: "center",
     },
}));

export function PublisherDeleteDialog({ name, id, publisherId, authorId, isPrivate, show, onHide }) {
     // Publishers UI Context
     const publishersUIContext = usePublishersUIContext();
     const publishersUIProps = useMemo(() => {
          return {
               setIds: publishersUIContext.setIds,
               queryParams: publishersUIContext.queryParams,
          };
     }, [publishersUIContext]);
     const [isAuthor, setIsAuthor] = useState(false);

     useEffect(() => {
          if (publisherId && authorId) {
               setIsAuthor(true);
          }
     }, [publisherId, authorId]);

     // Publishers Redux state
     const dispatch = useDispatch();
     const { isLoading } = useSelector((state) => ({ isLoading: state.publishers.actionsLoading }), shallowEqual);

     // if !id we should close modal
     useEffect(() => {
          if (!id) {
               // onHide();
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [id]);

     // looking for loading/dispatch
     useEffect(() => {}, [isLoading, dispatch]);

     const deletePublisher = () => {
          // server request for deleting publisher by id
          dispatch(actions.deletePublisher(id, isPrivate)).then(() => {
               // refresh list after deletion
               dispatch(actions.fetchPublishers(publishersUIProps.queryParams, isPrivate));
               // clear selections list
               publishersUIProps.setIds([]);
               // closing delete modal
               onHide();
          });
     };

     const deleteAuthor = () => {
          // server request for deleting category by id
          dispatch(actions.deleteAuthor(publisherId, authorId, isPrivate)).then(() => {
               // refresh list after deletion
               dispatch(actions.fetchAuthors(publishersUIProps.queryParams, publisherId, isPrivate));
               // closing delete modal
               onHide();
          });
     };

     const classes = useStyles();

     return (
          <Modal
               backdrop="static"
               show={show}
               onHide={onHide}
               aria-labelledby="example-modal-sizes-title-lg"
               style={{ margin: "3% auto" }}
          >
               {isLoading && <ModalProgressBar />}
               <Modal.Body className={classes.body}>
                    {name ? (
                         <>
                              <img src="/media/members/image-modal.svg" alt="" />
                              <div className="text-text-red">ยืนยันการลบ</div>
                              <div className="text-text-gray">ต้องการลบ {name} หรือไม่?</div>

                              <div className="mt-3">
                                   <button type="button" onClick={onHide} className="denyButton ">
                                        ยกเลิก
                                   </button>
                                   <button
                                        type="button"
                                        onClick={() => {
                                             if (isAuthor) {
                                                  deleteAuthor();
                                             } else {
                                                  deletePublisher();
                                             }
                                        }}
                                        className="acceptButton "
                                   >
                                        ยืนยัน
                                   </button>
                              </div>
                         </>
                    ) : (
                         ""
                    )}
               </Modal.Body>
          </Modal>
     );
}
