import React, {
   createContext,
   useContext,
   useState,
   useCallback,
   useEffect
} from 'react';
import { useParams } from 'react-router-dom';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './EbooksUIHelpers';

const EbooksUIContext = createContext();

export function useEbooksUIContext() {
   return useContext(EbooksUIContext);
}

export const EbooksUIConsumer = EbooksUIContext.Consumer;

export function EbooksUIProvider({ ebooksUIEvents, children }) {
   let { ebookType } = useParams();
   const [queryParams, setQueryParamsBase] = useState(
      Object.assign(initialFilter, { ebookType })
   );
   const [ids, setIds] = useState([]);
   const setQueryParams = useCallback(nextQueryParams => {
      setQueryParamsBase(prevQueryParams => {
         if (isFunction(nextQueryParams)) {
            nextQueryParams = nextQueryParams(prevQueryParams);
         }

         if (isEqual(prevQueryParams, nextQueryParams)) {
            return prevQueryParams;
         }

         return nextQueryParams;
      });
   }, []);

   useEffect(() => {
      setQueryParamsBase(Object.assign(initialFilter, { ebookType }));
   }, [ebookType]);

   useEffect(() => {
      console.log(queryParams);
   }, [queryParams]);

   const initEbook = {
      id: undefined,
      firstName: 'ddd',
      lastName: '',
      email: '',
      userName: '',
      gender: 'Female',
      status: 0,
      dateOfBbirth: '',
      ipAddress: '',
      type: 1
   };

   const value = {
      queryParams,
      setQueryParamsBase,
      ids,
      setIds,
      setQueryParams,
      initEbook,
      newEbookButtonClick: ebooksUIEvents.newEbookButtonClick,
      openEditEbookDialog: ebooksUIEvents.openEditEbookDialog,
      openViewEbookDialog: ebooksUIEvents.openViewEbookDialog,
      openDeleteEbookDialog: ebooksUIEvents.openDeleteEbookDialog,
      openBlockEbookDialog: ebooksUIEvents.openBlockEbookDialog,
      openUnblockEbookDialog: ebooksUIEvents.openUnblockEbookDialog,
      openDeleteEbooksDialog: ebooksUIEvents.openDeleteEbooksDialog,
      openFetchEbooksDialog: ebooksUIEvents.openFetchEbooksDialog,
      openUpdateEbooksStatusDialog: ebooksUIEvents.openUpdateEbooksStatusDialog,
      newPublisherButtonClick: ebooksUIEvents.newPublisherButtonClick,
      newAuthorButtonClick: ebooksUIEvents.newAuthorButtonClick,
      newCategoryButtonClick: ebooksUIEvents.newCategoryButtonClick,
      newSubCategoryButtonClick: ebooksUIEvents.newSubCategoryButtonClick
   };

   return (
      <EbooksUIContext.Provider value={value}>
         {children}
      </EbooksUIContext.Provider>
   );
}
