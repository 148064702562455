import React, { useMemo } from "react";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../../_metronic/_partials/controls";
import { CustomersFilter } from "./customers-filter/CustomersFilter";
import { QrcodesTable } from "./qrcodes-table/QrcodesTable";
import { QrcodeMembersTable } from "./qrcode-members-table/QrcodeMembersTable";
import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import { useCustomersUIContext } from "./CustomersUIContext";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, shallowEqual } from "react-redux";
const useStyles = makeStyles((theme) => ({
     p1: {
          fontFamily: "Prompt",
          fontSize: "20px",
          fontWeight: 600,
          color: "#012875",
          marginBottom: 0,
     },

     button: {
          borderRadius: 100,
          backgroundColor: "#012875",
          marginLeft: 8,
          padding: "10px 22px",
          height: 40,
          color: "white",
          fontSize: 13,
          fontWeight: 600,
          fontFamily: "Prompt",
          border: "none",
     },
     dropdown1: {
          fontFamily: "Prompt",
          paddingLeft: 10,
          paddingRight: 6,
     },
     dropdown2: {
          fontFamily: "Prompt",
          paddingLeft: 6,
          paddingRight: 12,
          paddingBottom: 6,
     },
}));

export function QrcodesCard({ isMemberTable, qrcodeId, onHide }) {
     const customersUIContext = useCustomersUIContext();
     // const [open1, setOpen1] = React.useState(false);
     // const anchorRef = React.useRef(null);
     const { role } = useSelector((state) => state.auth);
     const { currentState } = useSelector((state) => ({ currentState: state.qrcodes }), shallowEqual);
     const { entities_members } = currentState;
     // const handleToggle = () => {
     //   setOpen1((prevOpen) => !prevOpen);
     // };

     // const handleClose = (event) => {
     //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
     //     return;
     //   }

     //   setOpen1(false);
     // };
     // function handleListKeyDown(event) {
     //   if (event.key === "Tab") {
     //     event.preventDefault();
     //     setOpen1(false);
     //   }
     // }
     const customersUIProps = useMemo(() => {
          return {
               ids: customersUIContext.ids,
               newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
               newQRCodeButtonClick: customersUIContext.newQRCodeButtonClick,
               openQrcodeMembersMapDialog: customersUIContext.openQrcodeMembersMapDialog,
          };
     }, [customersUIContext]);

     // const files = acceptedFiles.map((file) => (
     //   <li key={file.path}>
     //     {file.path} - {file.size} bytes
     //   </li>
     // ));
     const classes = useStyles();

     return (
          <Card className="cardStyle">
               <CardHeader
                    title={
                         <div>
                              <p className={classes.p1}>{isMemberTable ? "ผู้ลงทะเบียนใช้งาน" : "QR Code"}</p>
                         </div>
                    }
               >
                    <CardHeaderToolbar>
                         {isMemberTable ? "" : <CustomersFilter isQrcode={true} />}
                         {isMemberTable ? (
                              Array.isArray(entities_members) && entities_members.length > 0 ? (
                                   <button
                                        onClick={() => customersUIProps.openQrcodeMembersMapDialog("0", true)}
                                        className={classes.button}
                                   >
                                        <span>ภาพรวมแผนที่</span>
                                   </button>
                              ) : (
                                   ""
                              )
                         ) : (
                              <button type="button" className="button" onClick={customersUIProps.newQRCodeButtonClick}>
                                   <span>
                                        <img alt="qrcode" src="/media/members/qrcode-button.svg" />
                                   </span>
                                   <span>สร้าง QR Code</span>
                              </button>
                         )}
                    </CardHeaderToolbar>
               </CardHeader>
               <CardBody className="cardBodyStyle">
                    {customersUIProps.ids.length > 0 && <CustomersGrouping />}
                    {isMemberTable ? <QrcodeMembersTable qrcodeId={qrcodeId} /> : <QrcodesTable />}
               </CardBody>
          </Card>
     );
}
