import { createSlice } from '@reduxjs/toolkit';
import { Category, Subcat } from '../../../../../interface/request';
export interface categoriesStateRedux {
   listLoading: boolean;
   actionsLoading: boolean;
   totalCount?: number;
   entities: Category[];
   categoryForEdit?: Category;
   isSubCat: boolean;
   // LastEvaluatedKey?: object,
   contentType: string | undefined;
   error?: object | null;
}

const initialCategoriesState: categoriesStateRedux = {
   listLoading: false,
   actionsLoading: false,
   totalCount: 0,
   entities: [],
   categoryForEdit: undefined,
   isSubCat: false,
   // LastEvaluatedKey: {},
   contentType: undefined
};
export const callTypes = {
   list: 'list',
   action: 'action'
};

export const categoriesSlice = createSlice({
   name: 'categories',
   initialState: initialCategoriesState,
   reducers: {
      catchError: (state, action) => {
         if (
            action.payload.error &&
            action.payload.error.response &&
            action.payload.error.response.data
         ) {
            state.error = {
               from: action.payload.from,
               errorCode: action.payload.error.response.data.errorCode
            };
         }
         if (action.payload.callType === callTypes.list) {
            state.listLoading = false;
         } else {
            state.actionsLoading = false;
         }
      },
      startCall: (state, action) => {
         state.error = null;
         if (action.payload.callType === callTypes.list) {
            state.listLoading = true;
         } else {
            state.actionsLoading = true;
         }
      },
      // getCategoryById
      categoryFetched: (state, action) => {
         state.listLoading = false;
         state.actionsLoading = false;
         state.categoryForEdit = action.payload.categoryForEdit;
         if (action.payload.isSubCat) {
            state.totalCount =
               action.payload.categoryForEdit &&
               action.payload.categoryForEdit.subcats &&
               action.payload.categoryForEdit.subcats.length;
         }
         state.isSubCat = action.payload.isSubCat;
         state.error = null;
      },
      // findCategories
      categoriesFetched: (state, action) => {
         const {
            totalCount,
            entities,
            catId,
            memberMode,
            contentType
         } = action.payload;
         state.contentType = contentType;
         state.listLoading = false;
         state.error = null;
         entities.forEach((item: Category) => console.log(item));

         if (memberMode) {
            entities
               .filter((e: Category) => e.status === parseInt(memberMode, 10))
               .forEach((item: Category) => state.entities.push(item));
         } else {
            // entities.forEach((item:Category) => state.entities.push(item));
            state.entities = entities;
         }
         console.log(JSON.stringify(state.entities));
         // state.LastEvaluatedKey = LastEvaluatedKey;
         if (catId) {
            state.categoryForEdit = entities.find(
               (el: Category) => el.id === catId
            );
            state.totalCount =
               state.categoryForEdit &&
               state.categoryForEdit.subcats &&
               state.categoryForEdit.subcats.length;
         } else {
            state.categoryForEdit = undefined;
            state.totalCount = totalCount;
         }
         // state.entities = mockUsers;
         // state.totalCount = 17;
      },
      // createCategory
      categoryCreated: (state, action) => {
         state.actionsLoading = false;
         state.error = null;
         state.entities.push(action.payload.category);
         state.totalCount
            ? (state.totalCount += 1)
            : (state.totalCount = undefined);
      },
      // createSubCategory
      subCategoryCreated: (state, action) => {
         state.actionsLoading = false;
         state.error = null;
         state.entities = state.entities.map(entity => {
            if (entity.id === action.payload.catId) {
               if (entity.subcats) {
                  entity.subcats.push(action.payload.subcategory);
               } else {
                  entity.subcats = [action.payload.subcategory];
               }
               state.categoryForEdit = entity;
            }
            // console.log(JSON.parse(JSON.stringify(entity)));
            return entity;
         });
      },
      // updateCategory
      categoryUpdated: (state, action) => {
         state.error = null;
         state.actionsLoading = false;
         state.entities = state.entities.map((entity: Category) => {
            if (entity.id === action.payload.catId) {
               return Object.assign({}, entity, action.payload.category);
            }
            return entity;
         });
      },
      // updateSubCategory
      subCategoryUpdated: (state, action) => {
         state.error = null;
         state.actionsLoading = false;
         state.entities = state.entities.map((entity: Category) => {
            if (entity.id === action.payload.catId) {
               let newEntity = Object.assign({}, entity);
               let temp = entity.subcats?.map(subcat => {
                  if (subcat.id === action.payload.subcatId) {
                     return Object.assign(
                        {},
                        subcat,
                        action.payload.subcategory
                     );
                  }
                  return subcat;
               });
               newEntity.subcats = temp;
               return Object.assign({}, newEntity);
            }
            return entity;
         });
         state.categoryForEdit = Object.assign({}, state.categoryForEdit, {
            subcats: state.categoryForEdit?.subcats.map(subcat => {
               if (subcat.id === action.payload.subcatId) {
                  return Object.assign({}, subcat, action.payload.subcategory);
               }
               return subcat;
            })
         });
      },
      // deleteCategory
      categoryDeleted: (state, action) => {
         state.error = null;
         state.actionsLoading = false;
         state.entities = state.entities.filter(
            (el: Category) => el.id !== action.payload.id
         );
      },
      // deleteSubCategory
      subCategoryDeleted: (state, action) => {
         state.error = null;
         state.actionsLoading = false;
         state.categoryForEdit = Object.assign({}, state.categoryForEdit, {
            subcats: state.categoryForEdit?.subcats.filter(
               el => el.id !== action.payload.subcatId
            )
         });
      },
      // blockCategory
      categoryBlocked: (state, action) => {
         state.error = null;
         state.actionsLoading = false;
         state.entities = state.entities.filter(
            (el: Category) => el.id !== action.payload.id
         );
      },
      // deleteCategories
      categoriesDeleted: (state, action) => {
         state.error = null;
         state.actionsLoading = false;
         state.entities = state.entities.filter(
            (el: Category) => !action.payload.ids.includes(el.id)
         );
      },
      // categoriesUpdateState
      categoriesStatusUpdated: (state, action) => {
         state.actionsLoading = false;
         state.error = null;
         const { ids, status } = action.payload;
         state.entities = state.entities.map((entity: Category) => {
            if (ids.findIndex((id: number) => id === entity.id) > -1) {
               entity.status = status;
            }
            return entity;
         });
      },
      elementUpdated: state => {
         state.actionsLoading = false;
      },
      errorNull: (state, action) => {
         state.error = null;
      }
   }
});
