import React from "react";
import { ReportsLoadingDialog } from "./reports-loading-dialog/ReportsLoadingDialog";
import { ReportsUIProvider } from "./ReportsUIContext";
import { SettingCard } from "./SettingCard";

export function SettingPage() {
  const reportsUIEvents = {};
  return (
    <ReportsUIProvider reportsUIEvents={reportsUIEvents}>
      <ReportsLoadingDialog />
      <SettingCard />
    </ReportsUIProvider>
  );
}
