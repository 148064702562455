import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/reports/reportsActions";
import { useReportsUIContext } from "../ReportsUIContext";

export function ReportBlockDialog({ id, show, onHide }) {
  // Reports UI Context
  // const reportsUIContext = useReportsUIContext();
  // const reportsUIProps = useMemo(() => {
  //   return {
  //     setIds: reportsUIContext.setIds,
  //     queryParams: reportsUIContext.queryParams,
  //   };
  // }, [reportsUIContext]);

  // Reports Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.reports.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  // const blockReport = () => {
  //   // server request for deleting report by id
  //   dispatch(actions.blockReport(id)).then(() => {
  //     // refresh list after deletion
  //     dispatch(actions.fetchReports(reportsUIProps.queryParams));
  //     // clear selections list
  //     reportsUIProps.setIds([]);
  //     // closing block modal
  //     onHide();
  //   });
  // };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      style={{ margin: "3% auto" }}
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      {/* <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Report Block</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <img src="/media/reports/image-modal.svg" alt="" />
        {!isLoading && (
          <>
            <div>ยืนยันการบล็อก</div>
            <div>ต้องการบล็อกสมาชิก อีเมล {id} หรือไม่</div>
            <div>
              <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
                ยกเลิก
              </button>
              <> </>
              <button type="button" 
              // onClick={blockReport}
               className="btn btn-primary btn-elevate">
                ยืนยัน
              </button>
            </div>
          </>
        )}
        {isLoading && <span>กำลังบล็อกผู้ใช้...</span>}
      </Modal.Body>
      {/* <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            Cancel
          </button>
          <> </>
          <button type="button" onClick={blockReport} className="btn btn-primary btn-elevate">
            Block
          </button>
        </div>
      </Modal.Footer> */}
    </Modal>
  );
}
