/* eslint-disable react-hooks/exhaustive-deps */
// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useMemo, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../../_metronic/_partials/controls";
import { Input as Input2, Upload } from "antd";
import Spinner from "../../../../../../_metronic/_helpers/spinner";
import { makeStyles } from "@material-ui/core";
import ImgCrop from "antd-img-crop";
import "antd/dist/antd.css";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";
const { TextArea } = Input2;

const useStyles = makeStyles(() => ({
  head: {
    fontSize: "20px",
    fontWeight: "600",
    margin: "16px auto 0",
    color: "#012875"
  },
  input: { margin: "12px 0" },
  denyButton: {
    margin: "8px",
    width: "150px",
    height: "48px",
    borderRadius: "100px",
    border: "solid 1px #012875",
    backgroundColor: "#ffffff",
    fontWeight: "600",
    color: "#012875",
    fontSize: "15px"
  },
  acceptButton: {
    margin: "8px",
    width: "150px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "#012875",
    border: "none",
    color: "white",
    fontWeight: "600",
    fontSize: "16px"
  },
  divButton: { marginTop: "64px", textAlign: "center" },
  subtitle: {
    color: "#012875",
    fontWeight: 400,
    fontSize: 12,
    marginBottom: 0
  }
}));

// Validation schema
const ChannelEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "อย่างน้อย 2 ตัวอักษร")
    .max(200, "ไม่เกิน 200 ตัวอักษร")
    .required("กรุณากรอกชื่อ")
});

export function ChannelEditForm({
  saveChannel,
  saveCreator,
  channel,
  actionsLoading,
  onHide,
  errorCode,
  setErrorCode,
  isCreator,
  channelList,
  id
}) {
  const styles = useStyles();
  const [description, setDescription] = useState("");
  const [coverName, setCoverName] = useState(undefined);
  const [fileList, setFileList] = useState([]);
  const onDescriptionChange = e => {
    setDescription(e.target.value);
  };
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const onUploadCoverImage = e => {
    const reqFunc = async () => {
      if (e) {
        const fileToUpload = e.file;
        if (fileToUpload) {
          setCoverName(fileToUpload.name);
          const req = await axios.post(`/admin/private/channels/file`, {
            fileName: fileToUpload.name,
            uid: id,
            publisherType: "podcast_channel"
          });
          if (req.data.status && req.data.data) {
            const urlToUpload = req.data.data.url;
            const fieldsToUpload = req.data.data.fields;
            var formData = new FormData();
            const entries = Object.entries(fieldsToUpload);
            for (const [key, val] of entries) {
              formData.append(key, val);
            }
            formData.append("file", fileToUpload);
            axios({
              method: "post",
              url: urlToUpload,
              data: formData,
              onUploadProgress: progressEvent => {
                if (progressEvent) {
                  e.onProgress(
                    {
                      percent: (
                        (100 * progressEvent.loaded) /
                        progressEvent.total
                      ).toFixed()
                    },
                    e.file
                  );
                }
              }
            })
              .then(response => {
                e.onSuccess(response, e.file);
              })
              .catch(() => {});
          }
        }
      }
    };
    reqFunc();
  };

  const reqDeleteCoverFunc = async () => {
    const req = await axios.delete(`/admin/private/channels/file`, {
      data: { fileName: coverName, uid: id, publisherType: "podcast_channel" }
    });
    if (req.data.status) {
      setCoverName(undefined);
      return true;
    } else {
      return false;
    }
  };

  const uploadImageMemo = useMemo(() => {
    return (
      <div className="channel-cover">
        <ImgCrop
          aspect={1 / 1}
          modalTitle="ตัดรูปภาพด้วยอัตราส่วน 1 : 1"
          modalOk="บันทึก"
          modalCancel="ยกเลิก"
          accept="image/png, image/jpeg, image/jpg"
        >
          <Upload
            customRequest={onUploadCoverImage}
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
            onRemove={reqDeleteCoverFunc}
          >
            {fileList.length < 1 && <PlusOutlined />}
          </Upload>
        </ImgCrop>
      </div>
    );
  }, [onUploadCoverImage, onChange, fileList, onPreview]);

  useEffect(() => {
    if (channel.description) {
      setDescription(channel.description);
    }
  }, [channel]);

  return (
    <>
      <p className={styles.head}>
        {isCreator ? "แก้ไข Creator" : "แก้ไข Channel"}
      </p>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: channel.name,
          status: channel.status,
          image: channel.image
        }}
        validationSchema={ChannelEditSchema}
        onSubmit={values => {
          if (isCreator) {
            saveCreator(
              Object.assign({}, values, {
                description: description,
                image: fileList.length ? coverName : values.image,
                uid: id
              })
            );
          } else {
            saveChannel(
              Object.assign({}, values, {
                description: description,
                image: fileList.length ? coverName : values.image,
                uid: id
              })
            );
          }
        }}
      >
        {({ handleSubmit, values, setFieldValue, errors }) => {
          return (
            <>
              <Modal.Body>
                {actionsLoading && <Spinner forNewChannel={true} />}
                <Form className="form form-label-right">
                  <div className={`col-lg-12 ${styles.input}`}>
                    <Field
                      name="name"
                      component={Input}
                      placeholder={
                        isCreator ? "กรอกชื่อ Creator" : "กรอกชื่อ Channel"
                      }
                      label={isCreator ? "ชื่อ Creator*" : "ชื่อ Channel*"}
                    />
                  </div>
                  <div className={`col-lg-12 ${styles.input}`}>
                    <p style={{ marginBottom: "0.5rem" }}>
                      คำอธิบายเกี่ยวกับ Channel
                    </p>
                    <TextArea
                      name="description"
                      placeholder="กรอกคำอธิบายเกี่ยวกับ Channel"
                      value={description}
                      onChange={onDescriptionChange}
                      rows={4}
                    />
                  </div>
                  <div className={`col-lg-12 ${styles.input}`}>
                    <p style={{ marginTop: "12px", marginBottom: "0.5rem" }}>
                      แก้ไขไฟล์หน้าปก Channel
                    </p>
                    <p className={styles.subtitle}>
                      *รองรับไฟล์ jpg, jpeg หรือ png เท่านั้น
                    </p>
                    <p
                      className={styles.subtitle}
                      style={{ marginBottom: "8px" }}
                    >
                      กรุณาใช้อัตราส่วน 1:1
                      เนื่องจากมีการใช้สัดส่วนนี้มากที่สุดในโปรแกรมเล่นพ็อดคาสต์และหน้าตัวอย่างของพ็อดคาสต์
                    </p>
                    {uploadImageMemo}
                  </div>
                </Form>
                <div className={styles.divButton}>
                  <button
                    className={styles.denyButton}
                    type="button"
                    onClick={() => {
                      onHide();
                      if (fileList.length) {
                        reqDeleteCoverFunc();
                      }
                    }}
                  >
                    ยกเลิก
                  </button>
                  <button
                    className={styles.acceptButton}
                    type="submit"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    ตกลง
                  </button>
                </div>
              </Modal.Body>
            </>
          );
        }}
      </Formik>
    </>
  );
}
