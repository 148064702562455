/* eslint-disable array-callback-return */
import React, { useMemo, useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    Input
} from '../../../../../_metronic/_partials/controls';
import './CatalogueCard.scss';
import { useCatalogueUIContext } from './CatalogueUIContext';
import { makeStyles } from '@material-ui/core/styles';
import { Row, Col, Empty, Divider } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import FastAverageColor from 'fast-average-color';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import * as actions from '../../_redux/catalogue/catalogueActions';
import { Modal } from 'react-bootstrap';
import { isEqual, isNil } from 'lodash';
import { useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CatalogueSummary } from './CatalogueSummary';
import jwt_decode from 'jwt-decode';
import { dateStr, timeStr } from '../../partials/timeStampToDate';
import { useStyles } from '../../partials/useStyles';
import { Modals, ModalsSuccess } from '../../../CustomView';
import { Role } from '../../../../../interface';

const minimumTwoBooks = [
    // '350b7d19-633c-426a-bdde-8c80f38c4cdf', //matichon
    // 'e13b2db7-4652-4edf-a55a-cc6a4f851b6e', //tpabooks
    // '292da99f-931e-4598-8c6a-fa296b7eb5c1' //TPAPressbooks
];

const ebookBox_list = [
    {
        catId: 1,
        catName: 'สำนักพิมพ์/นักเขียน',
        date: '04/04/2564',
        status: 1,
        licenses: 10,
        email: 'nan@email.com',
        coverImage: '/media/members/catalogue-publisher.png',
        clickFor: 'publishers',
        bgColor: '#2D9CDB'
    },
    {
        catId: 3,
        catName: 'หมวดหมู่',
        date: '04/04/2564',
        status: 1,
        licenses: 10,
        email: 'nan@email.com',
        coverImage: '/media/members/catalogue-category.png',
        clickFor: 'categories',
        bgColor: '#FBC020'
    },
    {
        catId: 4,
        catName: 'มาใหม่',
        date: '04/04/2564',
        status: 1,
        licenses: 10,
        email: 'nan@email.com',
        coverImage: '/media/members/catalogue-new-ebook.png',
        clickFor: 'newEbooks',
        bgColor: '#219653'
    }
];

const exampleBox_listxxx = [
    {
        catId: 4,
        catName: 'มาใหม่',
        date: '04/04/2564',
        status: 1,
        licenses: 10,
        email: 'nan@email.com',
        coverImage: '/media/members/catalogue-new-ebook.png',
        clickFor: 'newEbooks',
        bgColor: '#219653'
    },
    {
        catId: 1,
        catName: 'สำนักพิมพ์/นักเขียน',
        date: '04/04/2564',
        status: 1,
        licenses: 10,
        email: 'nan@email.com',
        coverImage: '/media/members/catalogue-publisher.png',
        clickFor: 'publishers',
        bgColor: '#2D9CDB'
    },
    {
        catId: 3,
        catName: 'หมวดหมู่',
        date: '04/04/2564',
        status: 1,
        licenses: 10,
        email: 'nan@email.com',
        coverImage: '/media/members/catalogue-category.png',
        clickFor: 'categories',
        bgColor: '#FBC020'
    }
];

const catalogueDetail_list = [
    {
        id: 1,
        title: 'สัญญารักพันปี',
        publisherName: 'หยกขาว',
        authorName: 'หยกขาว',
        date: '04/04/2564',
        catName: 'การ์ตูน',
        subcatName: 'มังงะ',
        status: 1,
        licenses: 10,
        startDate: '10/01/2564',
        endDate: '10/01/2565',
        timeLeft: 365,
        image: '/media/books/cover-catalogue.png'
    },
    {
        id: 2,
        title: 'สัญญารักพันปี',
        publisherName: 'หยกขาว',
        authorName: 'หยกขาว',
        date: '04/04/2564',
        catName: 'การ์ตูน',
        subcatName: 'มังงะ',
        status: 1,
        licenses: 10,
        startDate: '10/01/2564',
        endDate: '10/01/2565',
        timeLeft: 365,
        image: '/media/books/cover-catalogue.png'
    },
    {
        id: 3,
        title: 'สัญญารักพันปี',
        publisherName: 'หยกขาว',
        authorName: 'หยกขาว',
        date: '04/04/2564',
        catName: 'การ์ตูน',
        subcatName: 'มังงะ',
        status: 1,
        licenses: 10,
        startDate: '10/01/2564',
        endDate: '10/01/2565',
        timeLeft: 365,
        image: '/media/books/cover-catalogue.png'
    },
    {
        id: 4,
        title: 'สัญญารักพันปี',
        publisherName: 'หยกขาว',
        authorName: 'หยกขาว',
        date: '04/04/2564',
        catName: 'การ์ตูน',
        subcatName: 'มังงะ',
        status: 1,
        licenses: 10,
        startDate: '10/01/2564',
        endDate: '10/01/2565',
        timeLeft: 365,
        image: '/media/books/cover-catalogue.png'
    },
    {
        id: 5,
        title: 'สัญญารักพันปี',
        publisherName: 'หยกขาว',
        authorName: 'หยกขาว',
        date: '04/04/2564',
        catName: 'การ์ตูน',
        subcatName: 'มังงะ',
        status: 1,
        licenses: 10,
        startDate: '10/01/2564',
        endDate: '10/01/2565',
        timeLeft: 365,
        image: '/media/books/cover-catalogue.png'
    }
];

const useStyles1 = makeStyles(theme => ({
    div: {
        marginBottom: -29,
        marginTop: 0,
        zIndex: 2
    },
    p1: {
        fontFamily: 'Prompt',
        fontSize: '20px',
        fontWeight: 600,
        color: '#012875',
        margin: '32px 0 0 32px'
    },
    p2: {
        fontFamily: 'Prompt',
        fontSize: '13px',
        color: '#fd6b53'
    },
    button: {
        borderRadius: 100,
        backgroundColor: '#012875',
        marginLeft: 8,
        padding: '0px 22px',
        height: 40,
        color: 'white',
        fontSize: 13,
        fontWeight: 600,
        fontFamily: 'Prompt',
        border: 'none',
        '&:disabled': {
            cursor: 'not-allowed',
            opacity: 1,
            borderColor: 'transparent !important',
            backgroundColor: '#E0E0E0 !important',
            color: '#828282 !important'
        }
    },
    buttonskyblue: {
        borderRadius: 100,
        backgroundColor: '#0066FF',
        marginLeft: 8,
        padding: '0px 22px',
        height: 40,
        color: 'white',
        fontSize: 13,
        fontWeight: 600,
        fontFamily: 'Prompt',
        border: 'none',
        '&:disabled': {
            cursor: 'not-allowed',
            opacity: 1,
            borderColor: 'transparent !important',
            backgroundColor: '#E0E0E0 !important',
            color: '#828282 !important'
        }
    },
    buttonMaxHeight: {
        borderRadius: 100,
        backgroundColor: '#012875',
        marginLeft: 8,
        padding: '0px 22px',
        color: 'white',
        fontSize: 13,
        fontWeight: 600,
        fontFamily: 'Prompt',
        border: 'none',
        '&:disabled': {
            cursor: 'not-allowed',
            opacity: 1,
            borderColor: 'transparent !important',
            backgroundColor: '#E0E0E0 !important',
            color: '#828282 !important'
        },
        height: '40px'
    },
    dropdown1: {
        fontFamily: 'Prompt',
        paddingLeft: 10,
        paddingRight: 0
    },
    dropdown2: {
        fontFamily: 'Prompt',
        paddingLeft: 6,
        paddingRight: 12,
        paddingBottom: 6
    },
    stepDiv: {
        width: '200px',
        height: '48px',
        borderRadius: '20px 20px 0px 0px',
        margin: '0 -8.5px 0 12.5px',
        backgroundColor: 'white',
        padding: '12.5px',
        textAlign: 'center'
    },
    stepDivBlack: {
        width: '200px',
        height: '48px',
        borderRadius: '20px 20px 0px 0px',
        margin: '0 -8.5px 0 12.5px',
        backgroundColor: '#e0e0e0',
        padding: '12.5px',
        textAlign: 'center',
        '&:hover': { cursor: 'pointer' }
    },
    stepDivBlack_wait: {
        width: '200px',
        height: '48px',
        borderRadius: '20px 20px 0px 0px',
        margin: '0 -8.5px 0 12.5px',
        backgroundColor: '#e0e0e0',
        padding: '12.5px',
        textAlign: 'center',
        '&:hover': { cursor: 'wait' }
    },
    closeButton: {
        width: '30px',
        height: '30px',
        border: 'none',
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: 0,
        marginBottom: 10
    },
    stepDiv_p: {
        display: 'inline-block',
        fontSize: '20px',
        color: '#FD6B53',
        fontWeight: 600
    },
    stepDivBlack_p: {
        display: 'inline-block',
        fontSize: '20px',
        color: '#828282',
        fontWeight: 600
    },
    divLicense: {
        textAlign: 'center',
        marginRight: '16px',
        display: 'flex',
        alignItems: 'center'
    },
    orangeText16: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#FD6B53'
    },
    firstDigit: {
        fontSize: '20px',
        fontWeight: 600,
        color: '#012875',
        marginLeft: '8px'
    },
    summary: { minHeight: '100vh' },
    secondDigit: { fontSize: '20px', fontWeight: 600, color: '#828282' },
    colCatalogue: {
        padding: '8px',
        backgroundColor: '#3F3428',
        borderRadius: 4,
        '&:hover': {
            cursor: 'pointer',
            '-webkit-filter': 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2))',
            filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2))',
            '-webkit-box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.2)',
            'box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.2)'
        }
    },
    colCatalogueType: {
        width: '230px',
        padding: '8px',
        backgroundColor: '#3F3428',
        borderRadius: 4,
        '&:hover': {
            cursor: 'pointer',
            '-webkit-filter': 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2))',
            filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2))',
            '-webkit-box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.2)',
            'box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.2)'
        },
        marginTop: '4px',
        '-webkit-filter': 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))',
        filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))',
        '-webkit-box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.1)',
        'box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.1)'
    },
    colType: {
        padding: '8px !important',
        gridRowGap: '16px',
        rowGap: '16px'
    },
    colCatalogueDetail: { padding: '8px' },
    catalogueDetail: {
        display: 'inline-block',
        // transform: "translateY(10px)",
        fontSize: 12,
        marginBottom: 12,
        width: '65%'
    },
    catalogueDetailDetail: {
        display: 'inline-block',
        // transform: "translateY(6px)",
        color: '#828282',
        maxWidth: 'calc(100% - 123px - 8px)'
    },
    catalogueDetailLicense: {
        color: '#4F4F4F',
        fontSize: '15px',
        marginTop: '0px',
        marginBottom: '0px'
    },
    greyDigit: {
        display: 'inline-flex',
        margin: '0 12px',
        width: 30,
        justifyContent: 'center',
        fontSize: 20,
        fontWeight: 500,
        transform: 'translateY(4px)'
    },
    blueDigit: {
        display: 'inline-flex',
        margin: '0 12px',
        width: 30,
        justifyContent: 'center',
        fontSize: 20,
        fontWeight: 500,
        transform: 'translateY(4px)',
        color: '#012875'
    },
    catalogueTitle: {
        fontSize: '16px',
        fontWeight: 500,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        // lineHeight: 20px;
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical'
    },
    catalogueTitle18: {
        fontSize: '18px',
        fontWeight: 500,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        // lineHeight: 20px;
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical'
    },
    catalogueDetailTitle: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#4f4f4f',
        '&:hover': { textDecoration: 'underline', cursor: 'pointer' }
    },
    catalogueDetailImage: {
        float: 'left',
        '&:hover': { cursor: 'pointer' },
        width: '123px',
        marginRight: '8px',
        '-webkit-filter': 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))',
        filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))',
        '-webkit-box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.1)',
        'box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.1)'
    },
    flex: {
        display: 'flex',
        paddingLeft: '32px'
    },
    orangeDash: {
        height: '2px',
        width: '100%',
        backgroundColor: '#FD6B53',
        '&:hover': { cursor: 'pointer' }
    },
    transparentDash: {
        height: '2px',
        width: '100%',
        backgroundColor: 'transparent'
    },
    licenseAddRemove: {
        imageRendering: '-webkit-optimize-contrast',
        '&:hover': { cursor: 'pointer' }
    },
    licenseAddRemoveDisabled: {
        imageRendering: '-webkit-optimize-contrast',
        '&:hover': { cursor: 'not-allowed' }
    },
    marginBottom2px: {
        marginBottom: 2
    },
    redLabel: {
        color: '#F64E60'
    },
    orangeLabel: {
        color: '#DAA520'
    },
    greenLabel: {
        color: '#219653'
    },
    hoverLink: {
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },
    deleteCatalogButton: {
        right: '14px',
        marginLeft: '0.75rem',
        position: 'absolute',
        bottom: '6px'
    }
}));

const useStyles2 = makeStyles(theme => ({
    input: { margin: '12px 0' },
    body: { margin: '0' },
    divSuccess: {
        textAlign: 'center',
        width: '340px',
        height: '360px',
        borderRadius: '10px',
        backgroundColor: '#fff'
    },
    divInitial: {
        textAlign: 'center',
        width: '340px',
        borderRadius: '10px',
        backgroundColor: '#fff'
    },
    divText: { fontSize: '24px', fontWeight: '600', color: '#012875' },
    button: {
        marginTop: '32px',
        fontSize: '17px',
        fontWeight: '600',
        width: '200px',
        height: '48px',
        borderRadius: '100px',
        backgroundColor: '#012875',
        border: 'none',
        color: 'white',
        '&:disabled': {
            cursor: 'not-allowed',
            opacity: 1,
            borderColor: 'transparent !important',
            backgroundColor: '#E0E0E0 !important',
            color: '#828282 !important'
        }
    },
    acceptButton: {
        margin: '8px',
        width: '150px',
        height: '48px',
        borderRadius: '100px',
        backgroundColor: '#012875',
        border: 'none',
        color: 'white',
        fontWeight: '600',
        fontSize: '16px',
        cursor: 'pointer',
        '&:disabled': {
            backgroundColor: '#e0e0e0',
            color: '#828282',
            '&:hover': {
                cursor: 'not-allowed'
            }
        }
    },
    divButton: { marginTop: '0', textAlign: 'center' }
}));

export function CatalogueDetailCard({
    isPrivate,
    isCatalogueDetail,
    id,
    isNewEbook,
    isPublishers,
    isNewEbooks,
    isEbooks,
    isExample,
    isCategories,
    isSubcategories,
    publisherId,
    catId,
    subcatId,
    isPureCatalogue,
    onHide
}) {
    const catalogueUIContext = useCatalogueUIContext();
    const catalogueUIProps = useMemo(() => {
        return {
            ids: catalogueUIContext.ids,
            newCatalogueButtonClick: catalogueUIContext.newCatalogueButtonClick,
            queryParams: catalogueUIContext.queryParams,
            setQueryParams: catalogueUIContext.setQueryParams
        };
    }, [catalogueUIContext]);

    const modalStyles = useStyles();
    const classes = useStyles1();
    const styles = useStyles2();
    const dispatch = useDispatch();
    const stepLabel = ['สื่อ Hibrary', 'สื่อองค์กร'];
    const [activeTab, setActiveTab] = useState(0);
    const loaderHeight = 50;
    const [totalConcurrent, setTotalConcurrent] = useState(0);
    const [originConcurrent, setOriginConcurrent] = useState(0);
    const [originConcurrentIon, setOriginConcurrentIon] = useState(0);
    const [saved, setSaved] = useState(false);
    const [catalogueEbooks, setCatalogueEbooks] = useState({});
    const [catalogueEbooksIon, setCatalogueEbooksIon] = useState({});
    const [loadingBottom, setLoadingBottom] = useState(false);
    const [loadingBottomRow, setLoadingBottomRow] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [summaryModal, setsummaryModal] = useState(false);
    const [exampleBox_list, setExampleBox_list] = useState(null);
    const [selectedPreset, setSelectedPreset] = useState(-1);
    const [initialCreditModal, setInitialConcurrentModal] = useState(false);
    const [submitModal, setSubmitModal] = useState(false);
    // const [exam]
    const footerHeight = 300;
    const history = useHistory();

    const { authState } = useSelector(
        ({ auth }) => ({
            authState: auth
        }),
        shallowEqual
    );
    const { role, viewAsToken } = authState;

    const {
        listLoading,
        actionsLoading,
        actionsLoading_catalogueForEdit,
        entities: catalogue_list,
        entities_publishers: publishers_list,
        entities_categories: categories_list,
        catalogueForEdit,
        publisherContents,
        newEbooksContents,
        categoryContents,
        LastEvaluatedKey_publisherContents,
        LastEvaluatedKey_newEbooksContents,
        LastEvaluatedKey_categoryContents,
        error,
        remainConcurrent,
        memberMode,
        LastEvaluatedKey_catalogueForEdit,
        publisherName,
        catName,
        subcats: subcats_list,
        subcatName,
        contentAmount,
        catalogue_contentAmount
    } = useSelector(
        state => ({
            listLoading: state.catalogue.listLoading,
            actionsLoading: state.catalogue.actionsLoading,
            actionsLoading_catalogueForEdit:
                state.catalogue.actionsLoading_catalogueForEdit,
            entities: state.catalogue.entities,
            entities_publishers: state.catalogue.entities_publishers,
            entities_categories: state.catalogue.entities_categories,
            catalogueForEdit: state.catalogue.catalogueForEdit,
            publisherContents: state.catalogue.publisherContents,
            newEbooksContents: state.catalogue.newEbooksContents,
            categoryContents: state.catalogue.categoryContents,
            LastEvaluatedKey_publisherContents:
                state.catalogue.LastEvaluatedKey_publisherContents,
            LastEvaluatedKey_newEbooksContents:
                state.catalogue.LastEvaluatedKey_newEbooksContents,
            LastEvaluatedKey_categoryContents:
                state.catalogue.LastEvaluatedKey_categoryContents,
            error: state.catalogue.error,
            remainConcurrent: state.catalogue.remainConcurrent,
            memberMode: state.catalogue.memberMode,
            LastEvaluatedKey_catalogueForEdit:
                state.catalogue.LastEvaluatedKey_catalogueForEdit,
            publisherName: state.catalogue.publisherName,
            catName: state.catalogue.catName,
            subcats: state.catalogue.subcats,
            subcatName: state.catalogue.subcatName,
            contentAmount: state.catalogue.contentAmount,
            catalogue_contentAmount: state.catalogue.catalogue_contentAmount
        }),
        shallowEqual
    );

    const fetchExampleSet = () => {
        const reqFunc = async () => {
            const presetReq = await axios.get(
                `/superuser/hibrary/contents/presets`
            );
            if (presetReq.data.status && presetReq.data.data) {
                setExampleBox_list(presetReq.data.data.presets);
            }
        };
        reqFunc();
    };

    useEffect(() => {}, []);

    useEffect(() => {
        const reqFunc = () => {
            return isExample
                ? fetchExampleSet()
                : isNewEbook
                ? isPublishers
                    ? publishers_list
                        ? ''
                        : dispatch(
                              actions.fetchCatalogues(
                                  catalogueUIProps.queryParams,
                                  'publishers'
                              )
                          )
                    : isNewEbooks
                    ? newEbooksContents
                        ? ''
                        : dispatch(
                              actions.fetchNewEbooksContents(
                                  id,
                                  undefined,
                                  setLoadingBottomRow
                              )
                          )
                    : isCategories
                    ? categories_list
                        ? ''
                        : dispatch(
                              actions.fetchCatalogues(
                                  catalogueUIProps.queryParams,
                                  'categories'
                              )
                          )
                    : isSubcategories && catId
                    ? dispatch(actions.fetchCategoryContents(id, catId))
                    : isEbooks
                    ? catId && subcatId
                        ? dispatch(
                              actions.fetchCategoryContents(
                                  id,
                                  catId,
                                  undefined,
                                  undefined,
                                  subcatId
                              )
                          )
                        : publisherId
                        ? dispatch(
                              actions.fetchPublisherContents(id, publisherId)
                          )
                        : ''
                    : dispatch(actions.clearContents())
                : isCatalogueDetail
                ? ''
                : ''; //dispatch(actions.fetchCatalogues(catalogueUIProps.queryParams, "catalogues"));
        };
        reqFunc();
        if (document.getElementById('scrollingRow')) {
            document.getElementById('scrollingRow').scrollTop = 0;
        }
    }, [
        isNewEbook,
        isNewEbooks,
        isPublishers,
        isEbooks,
        isCategories,
        isCatalogueDetail,
        publisherId,
        catId,
        subcatId,
        isExample
    ]);

    const isBottom = () => {
        let scrollHeight = Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight
        );
        return (
            window.pageYOffset + window.innerHeight >=
            scrollHeight - 3 * loaderHeight - footerHeight
        );
    };

    const isBottomRow = () => {
        const getScroll = document.getElementById('scrollingRow');
        const offsetHeight = Math.ceil(getScroll.offsetHeight);
        const scrollTop = Math.ceil(getScroll.scrollTop);
        if (
            !actionsLoading &&
            !actionsLoading_catalogueForEdit &&
            getScroll.scrollHeight - (scrollTop + offsetHeight) <= 1
        ) {
            return true;
        } else {
            return false;
        }
    };
    const trackScrolling = () => {
        if (isBottom()) {
            setLoadingBottom(true);
        }
    };

    const trackScrollingRow = () => {
        if (isBottomRow()) {
            setLoadingBottomRow(true);
        }
    };

    useEffect(() => {
        document.addEventListener('scroll', trackScrolling);
        if (document.getElementById('scrollingRow')) {
            document
                .getElementById('scrollingRow')
                .addEventListener('scroll', trackScrollingRow);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        return () => {
            document.removeEventListener('scroll', trackScrolling);
            if (document.getElementById('scrollingRow')) {
                document
                    .getElementById('scrollingRow')
                    .removeEventListener('scroll', trackScrollingRow);
            }
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (publisherId) {
            if (
                LastEvaluatedKey_publisherContents === undefined ||
                JSON.stringify(LastEvaluatedKey_publisherContents) ===
                    JSON.stringify({})
            ) {
                setLoadingBottomRow(false);
            } else if (loadingBottomRow && !actionsLoading) {
                dispatch(
                    actions.fetchPublisherContents(
                        id,
                        publisherId,
                        LastEvaluatedKey_publisherContents,
                        setLoadingBottomRow
                    )
                );
            }
        }
    }, [LastEvaluatedKey_publisherContents, loadingBottomRow, actionsLoading]);

    useEffect(() => {
        if (catId) {
            if (
                LastEvaluatedKey_categoryContents === undefined ||
                JSON.stringify(LastEvaluatedKey_categoryContents) ===
                    JSON.stringify({})
            ) {
                setLoadingBottomRow(false);
            } else if (loadingBottomRow && !actionsLoading) {
                dispatch(
                    actions.fetchCategoryContents(
                        id,
                        catId,
                        LastEvaluatedKey_categoryContents,
                        setLoadingBottomRow,
                        subcatId
                    )
                );
            }
        }
    }, [LastEvaluatedKey_categoryContents, loadingBottomRow, actionsLoading]);

    useEffect(() => {
        if (id) {
            if (
                LastEvaluatedKey_newEbooksContents === undefined ||
                JSON.stringify(LastEvaluatedKey_newEbooksContents) ===
                    JSON.stringify({})
            ) {
                setLoadingBottomRow(false);
            } else if (loadingBottomRow && !actionsLoading) {
                dispatch(
                    actions.fetchNewEbooksContents(
                        id,
                        LastEvaluatedKey_newEbooksContents,
                        setLoadingBottomRow
                    )
                );
            }
        }
    }, [LastEvaluatedKey_newEbooksContents, loadingBottomRow, actionsLoading]);

    useEffect(() => {
        if (isCatalogueDetail && id) {
            if (
                LastEvaluatedKey_catalogueForEdit === undefined ||
                JSON.stringify(LastEvaluatedKey_catalogueForEdit) ===
                    JSON.stringify({})
            ) {
                setLoadingBottomRow(false);
            } else if (loadingBottomRow && !actionsLoading_catalogueForEdit) {
                dispatch(
                    actions.fetchCatalogue(
                        catalogueUIProps.queryParams,
                        id,
                        LastEvaluatedKey_catalogueForEdit,
                        setLoadingBottomRow,
                        true
                    )
                );
                dispatch(actions.clearContents());
            }
        }
    }, [
        LastEvaluatedKey_catalogueForEdit,
        loadingBottomRow,
        isCatalogueDetail,
        actionsLoading_catalogueForEdit
    ]);

    useEffect(() => {
        console.log(
            'isPureCatalogue ',
            isPureCatalogue,
            catalogueUIProps.queryParams
        );
        if (isPureCatalogue) {
            dispatch(
                actions.fetchCatalogues(
                    catalogueUIProps.queryParams,
                    'catalogues'
                )
            );
        }
    }, [catalogueUIProps.queryParams, isPureCatalogue]);

    useEffect(() => {
        console.log('catalogueForEdit ', catalogueForEdit);
        if (
            catalogueForEdit &&
            catalogueForEdit.uid === id &&
            catalogueUIProps.queryParams &&
            memberMode === catalogueUIProps.queryParams.memberMode
        ) {
            if (
                Number.isInteger(catalogueForEdit.credit) &&
                Number.isInteger(catalogueForEdit.concurrent)
            ) {
                if (
                    !isCatalogueDetail &&
                    localStorage.getItem('catalogueEbooks') &&
                    localStorage.getItem('catalogueEbooksIon')
                ) {
                    let stored = JSON.parse(
                        localStorage.getItem('catalogueEbooks')
                    );
                    let storedIon = JSON.parse(
                        localStorage.getItem('catalogueEbooksIon')
                    );
                    let storageIon = Object.values(storedIon).reduce(
                        (t, n) => t + n
                    );
                    setCatalogueEbooks(stored);
                    setCatalogueEbooksIon(storedIon);
                    // setSaved(true);
                    console.log(
                        catalogueForEdit.credit,
                        catalogueForEdit.concurrent,
                        storageIon
                    );
                    setTotalConcurrent(
                        catalogueForEdit.credit -
                            catalogueForEdit.concurrent -
                            storageIon
                    );
                    setOriginConcurrentIon(
                        catalogueForEdit.credit -
                            catalogueForEdit.concurrent -
                            storageIon
                    );
                } else {
                    setTotalConcurrent(
                        catalogueForEdit.credit - catalogueForEdit.concurrent
                    );
                }
                setOriginConcurrent(
                    catalogueForEdit.credit - catalogueForEdit.concurrent
                );
            }
            if (
                isCatalogueDetail &&
                catalogueForEdit &&
                !catalogueForEdit.contents
            ) {
                dispatch(
                    actions.fetchCatalogue(
                        catalogueUIProps.queryParams,
                        id,
                        undefined,
                        undefined,
                        true
                    )
                );
            }
        } else {
            if (id && !error) {
                dispatch(
                    actions.fetchCatalogue(
                        catalogueUIProps.queryParams,
                        id,
                        undefined,
                        undefined,
                        isCatalogueDetail
                    )
                );
            }
        }
    }, [catalogueForEdit, catalogueUIProps.queryParams, isCatalogueDetail]);

    useEffect(() => {
        if (!catalogue_contentAmount) {
            dispatch(actions.fetchAllContentAmount());
        }
    }, [catalogue_contentAmount]);

    useEffect(() => {
        if (!isEbooks && catalogueForEdit) {
            if (localStorage.getItem('catalogueEbooksIon')) {
                let storedIon = JSON.parse(
                    localStorage.getItem('catalogueEbooksIon')
                );
                let storageIon = Object.values(storedIon).reduce(
                    (t, n) => t + n
                );
                setTotalConcurrent(
                    catalogueForEdit.credit -
                        catalogueForEdit.concurrent -
                        storageIon
                );
            } else {
                setTotalConcurrent(
                    catalogueForEdit.credit - catalogueForEdit.concurrent
                );
            }
            if (saved) {
                setSaved(false);
            }
        }
    }, [isEbooks, catalogueForEdit]);

    useEffect(() => {
        if (isCatalogueDetail && catalogueForEdit) {
            setTotalConcurrent(originConcurrent);
            if (saved) {
                setSaved(false);
            }
        }
    }, [isCatalogueDetail, catalogueForEdit, originConcurrent]);

    const NoDataDiv = arr =>
        arr && arr.length === 0 ? (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    position: 'absolute'
                }}
            >
                <div style={{ textAlign: 'center', margin: '50px auto' }}>
                    <Empty description={false} />
                    <p
                        style={{
                            color: '#012875',
                            marginBottom: '0',
                            fontSize: '20px',
                            fontWeight: '600'
                        }}
                    >
                        ไม่มีข้อมูล
                    </p>
                </div>
            </div>
        ) : (
            undefined
        );
    const uploadSchema = Yup.object().shape({
        initialCredit: Yup.number()
            .min(1, 'กรุณาใช้จำนวน 1 ขึ้นไป')
            .max(10000, 'กรุณาใช้จำนวนไม่เกิน 10,000')
            .typeError('กรุณาใช้ตัวเลข')
    });
    const SpinnerDiv = forEndless => (
        <div
            style={
                forEndless
                    ? { width: '100%' }
                    : {
                          margin: '-4px',
                          display: 'flex',
                          alignItems: 'center',
                          width: '98%',
                          height: '100%',
                          position: 'absolute'
                      }
            }
        >
            <div
                style={{
                    minHeight: `${loaderHeight}px`,
                    textAlign: 'center',
                    padding: '20px 0 0 0',
                    width: '100%'
                }}
            >
                <ClipLoader
                    size={loaderHeight}
                    color={'#123abc'}
                    loading={true}
                />
            </div>
        </div>
    );
    const updateCatalogueEbooks = () => {
        const updateReqFunc = async () => {
            const updateReq = await axios.post(
                `/admin/catalogs/${id}/contents`,
                catalogueEbooks
            );
            if (updateReq.data.status) {
                setCatalogueEbooks({});
                setCatalogueEbooksIon({});
                localStorage.removeItem('catalogueEbooks');
                localStorage.removeItem('catalogueEbooksIon');
                dispatch(
                    actions.fetchCatalogue(
                        catalogueUIProps.queryParams,
                        id,
                        undefined,
                        undefined,
                        false,
                        catalogueEbooks
                    )
                );
                if (isNewEbooks) {
                    dispatch(
                        actions.fetchNewEbooksContents(
                            id,
                            undefined,
                            setLoadingBottomRow
                        )
                    );
                }
                if (isEbooks) {
                    if (catId && subcatId) {
                        dispatch(
                            actions.fetchCategoryContents(
                                id,
                                catId,
                                undefined,
                                undefined,
                                subcatId
                            )
                        );
                    }
                    if (publisherId) {
                        dispatch(
                            actions.fetchPublisherContents(id, publisherId)
                        );
                    }
                }
                setSuccessModal(true);
                setSaved(false);
            }
        };
        if (catalogueEbooks) {
            updateReqFunc();
        }
    };

    const submitCatalogueEbooks = () => {
        const submitReqFunc = async () => {
            const updateReq = await axios.post(`/admin/catalogs/${id}`);
            if (updateReq.data.status) {
                setCatalogueEbooks({});
                setCatalogueEbooksIon({});
                localStorage.removeItem('catalogueEbooks');
                localStorage.removeItem('catalogueEbooksIon');
                if (isCatalogueDetail) {
                    dispatch(
                        actions.fetchCatalogue(
                            catalogueUIProps.queryParams,
                            id,
                            undefined,
                            undefined,
                            false
                        )
                    );
                }
                setSuccessModal(true);
                setSubmitModal(false);
                setSaved(false);
            }
        };
        if (
            catalogueForEdit &&
            Number.isInteger(catalogueForEdit.allContentAmount) &&
            catalogueForEdit.allContentAmount > 0
        ) {
            submitReqFunc();
        }
    };

    const prepareFilter = (queryParams, values) => {
        const { memberMode } = values;
        const newQueryParams = { ...queryParams };
        newQueryParams.memberMode = memberMode;
        return newQueryParams;
    };

    const applyFilter = values => {
        const newQueryParams = prepareFilter(
            catalogueUIProps.queryParams,
            values
        );
        if (!isEqual(newQueryParams, catalogueUIProps.queryParams)) {
            // update list by queryParams
            catalogueUIProps.setQueryParams(newQueryParams);
        }
    };
    const onHidesummary = () => {
        setsummaryModal(false);
    };
    const openmodalsummary = () => {
        setsummaryModal(true);
    };

    const [loadingSavePreset, setLoadingSavePreset] = useState(false);
    const postPresetReq = () => {
        setLoadingSavePreset(true);
        axios
            .post(`/superuser/hibrary/contents`, {
                type: 'template',
                clientUID: jwt_decode(viewAsToken).clientUID,
                value: exampleBox_list[selectedPreset]
            })
            .then(res => {
                if (res.data && res.data.status) {
                    setLoadingSavePreset(false);
                    setSuccessModal(true);
                    setSelectedPreset(-1);
                }
            });
    };

    const downloadTemplate = () => {
        axios.get(`/admin/catalogs/${id}/export`).then(res => {
            if (res.data && res.data.data && res.data.data.link) {
                let link = document.createElement('a');
                link.setAttribute('download', 'template');
                link.href = res.data.data.link;
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        });
    };

    const downloadExampleTemplate = () => {
        axios.get(`/superuser/hibrary/contents/template`).then(res => {
            if (res.data && res.data.data && res.data.data.fulfillmentUrl) {
                let link = document.createElement('a');
                link.setAttribute('download', 'template');
                link.href = res.data.data.fulfillmentUrl;
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        });
    };

    const tabDiv = isPureCatalogue => {
        return (
            <div
                className={classes.flex}
                style={{
                    justifyContent: 'space-between',
                    paddingLeft: '0px',
                    alignItems: 'end',
                    height: '100%'
                }}
            >
                {filterDiv(isPureCatalogue)}
                {isPureCatalogue ? '' : licenseNumDiv()}
            </div>
        );
    };

    const filterDiv = isPureCatalogue => {
        return (
            <div className={classes.flex}>
                <div
                    onClick={() => {
                        setActiveTab(0);
                        applyFilter({ memberMode: 0 });
                    }}
                    className={`catalogueDetailTab ${
                        activeTab === 0 ? 'active' : ''
                    }`}
                >
                    <div>
                        {isPureCatalogue ? 'รอดำเนินการ ' : 'ทั้งหมด '}
                        {isPureCatalogue
                            ? '()'
                            : catalogueForEdit &&
                              Number.isInteger(
                                  catalogueForEdit.allContentAmount
                              )
                            ? `(${catalogueForEdit.allContentAmount})`
                            : ''}
                    </div>
                    <div className={`dash`}></div>
                </div>
                {isPureCatalogue ? (
                    ''
                ) : (
                    <div
                        onClick={() => {
                            setActiveTab(1);
                            applyFilter({ memberMode: 1 });
                        }}
                        className={`catalogueDetailTab ${
                            activeTab === 1 ? 'active' : ''
                        }`}
                    >
                        <div>
                            {'รอตรวจสอบ'}{' '}
                            {isPureCatalogue
                                ? ''
                                : catalogueForEdit &&
                                  Number.isInteger(
                                      catalogueForEdit.processingAmount
                                  )
                                ? `(${catalogueForEdit.processingAmount})`
                                : ''}
                        </div>
                        <div className={`dash`}></div>
                    </div>
                )}
                <div
                    onClick={() => {
                        setActiveTab(2);
                        applyFilter({ memberMode: isPureCatalogue ? 3 : 2 });
                    }}
                    className={`catalogueDetailTab ${
                        activeTab === 2 ? 'active' : ''
                    }`}
                >
                    <div>
                        {isPureCatalogue ? 'รอตรวจสอบ' : 'ไม่ผ่านการตรวจสอบ'}{' '}
                        {isPureCatalogue
                            ? ''
                            : catalogueForEdit &&
                              Number.isInteger(catalogueForEdit.rejectAmount)
                            ? `(${catalogueForEdit.rejectAmount})`
                            : ''}
                    </div>
                    <div className={`dash`}></div>
                </div>
                <div
                    onClick={() => {
                        setActiveTab(3);
                        applyFilter({ memberMode: isPureCatalogue ? 5 : 3 });
                    }}
                    className={`catalogueDetailTab ${
                        activeTab === 3 ? 'active' : ''
                    }`}
                >
                    <div>
                        {isPureCatalogue ? 'ผลการตรวจสอบ' : 'ผ่านการตรวจสอบ'}{' '}
                        {isPureCatalogue
                            ? ''
                            : catalogueForEdit &&
                              Number.isInteger(catalogueForEdit.submitAmount)
                            ? `(${catalogueForEdit.submitAmount})`
                            : ''}
                    </div>
                    <div className={`dash`}></div>
                </div>
                {isPureCatalogue ? (
                    <div
                        onClick={() => {
                            setActiveTab(4);
                            applyFilter({ memberMode: 4 });
                        }}
                        className={`catalogueDetailTab ${
                            activeTab === 4 ? 'active' : ''
                        }`}
                    >
                        <div>ประวัติการสั่งซื้อ</div>
                        <div className={`dash`}></div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    };

    const licenseNumDiv = () => {
        return (
            <div
                className={classes.divLicense}
                style={{ marginRight: '0px', paddingRight: '32px' }}
            >
                <div
                    className={classes.orangeText16}
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    จำนวนสิทธิ์อีบุ๊กคงเหลือ :{' '}
                    <span className={classes.firstDigit}>
                        {isPureCatalogue
                            ? Number.isInteger(remainConcurrent)
                                ? remainConcurrent
                                : ''
                            : Number.isInteger(totalConcurrent)
                            ? totalConcurrent
                            : '-'}
                    </span>
                </div>
            </div>
        );
    };

    const StepBar = () => {
        return isPrivate ? (
            ''
        ) : (
            <div className="row">
                {stepLabel.map((el, index) => {
                    if (index === isPrivate) {
                        return (
                            <div
                                key={index}
                                className={`col-2 ${
                                    index === isPrivate
                                        ? classes.stepDiv
                                        : listLoading
                                        ? classes.stepDivBlack_wait
                                        : classes.stepDivBlack
                                }`}
                                // onClick={() => {
                                //   if (!listLoading) handleChange(index);
                                // }}
                            >
                                <p
                                    className={
                                        index === isPrivate
                                            ? classes.stepDiv_p
                                            : classes.stepDivBlack_p
                                    }
                                >
                                    {el}
                                </p>
                            </div>
                        );
                    }
                })}
            </div>
        );
    };
    const ToolBar = () => {
        return (
            //  <div
            //    style={{
            //      display: "flex",
            //      justifyContent: "flex-end",
            //      margin: "32px 32px 0 0",
            //    }}
            //  >
            <>
                {isExample ? (
                    <div>
                        <button
                            type="button"
                            className="button"
                            onClick={() => {
                                downloadExampleTemplate();
                            }}
                        >
                            <span>
                                <img
                                    alt="excel"
                                    src="/media/members/excel-button.svg"
                                    style={{ marginRight: '8px' }}
                                />
                            </span>
                            <span>ดาวน์โหลด Template</span>
                        </button>
                        <button
                            type="button"
                            className="button"
                            onClick={catalogueUIProps.newCatalogueButtonClick}
                        >
                            <span>อัพโหลดไฟล์</span>
                        </button>
                    </div>
                ) : !isCatalogueDetail ? (
                    <div className={classes.divLicense}>
                        <div
                            className={classes.orangeText16}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            จำนวนสิทธิ์อีบุ๊กคงเหลือ :{' '}
                            <span className={classes.firstDigit}>
                                {isPureCatalogue
                                    ? Number.isInteger(remainConcurrent)
                                        ? remainConcurrent
                                        : '-'
                                    : Number.isInteger(totalConcurrent)
                                    ? totalConcurrent
                                    : '-'}
                            </span>
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {isCatalogueDetail ? (
                    <button
                        type="button"
                        className="button"
                        onClick={() => {
                            downloadTemplate();
                        }}
                    >
                        <span>
                            <img
                                alt="excel"
                                src="/media/members/excel-button.svg"
                                style={{ marginRight: '8px' }}
                            />
                        </span>
                        <span>ดาวน์โหลด Report</span>
                    </button>
                ) : (
                    ''
                )}
                {isCatalogueDetail ? (
                    <button
                        type="button"
                        className={classes.buttonskyblue}
                        onClick={openmodalsummary}
                    >
                        <span>สรุปข้อมูล</span>
                    </button>
                ) : (
                    ''
                )}
                {isNewEbook ? (
                    ''
                ) : (
                    <>
                        {role === Role.SuperAdmin &&
                        viewAsToken &&
                        !isCatalogueDetail ? (
                            <button
                                type="button"
                                className="button"
                                onClick={() => setInitialConcurrentModal(true)}
                            >
                                <span>เพิ่มสิทธิ์</span>
                            </button>
                        ) : (
                            ''
                        )}
                        <button
                            type="button"
                            className="button"
                            onClick={catalogueUIProps.newCatalogueButtonClick}
                        >
                            <span>
                                {isCatalogueDetail
                                    ? 'เพิ่มรายการอีบุ๊ก'
                                    : 'สร้างแคตตาล็อก'}
                            </span>
                        </button>
                    </>
                )}
                {isCatalogueDetail ? (
                    <button
                        type="button"
                        className={classes.button}
                        onClick={() => setSubmitModal(true)}
                        disabled={
                            (catalogueForEdit &&
                                Number.isInteger(
                                    catalogueForEdit.allContentAmount
                                ) &&
                                catalogueForEdit.allContentAmount === 0) ||
                            !catalogueForEdit ||
                            !Number.isInteger(catalogueForEdit.allContentAmount)
                        }
                    >
                        <span>ส่งข้อมูลการสั่งซื้อ</span>
                    </button>
                ) : (
                    ''
                )}

                {isCatalogueDetail || publisherId || catId || isNewEbooks ? (
                    <button
                        type="button"
                        className={classes.button}
                        onClick={() => {
                            updateCatalogueEbooks();
                        }}
                        disabled={!saved}
                    >
                        <span>บันทึก</span>
                    </button>
                ) : (
                    ''
                )}
                {isExample ? (
                    <button
                        className={classes.button}
                        disabled={selectedPreset === -1}
                        onClick={() => {
                            postPresetReq();
                        }}
                    >
                        บันทึก
                    </button>
                ) : (
                    ''
                )}
            </>
        );
    };
    const TitleBarss = () => {
        return (
            <div className="card-label">
                <>
                    <div>
                        {isExample
                            ? 'รายการอีบุ๊ก'
                            : isCatalogueDetail
                            ? catalogueForEdit
                                ? catalogueForEdit.title
                                : ''
                            : isNewEbook
                            ? isPublishers
                                ? 'สำนักพิมพ์/นักเขียน'
                                : isNewEbooks
                                ? newEbooksContents
                                    ? 'มาใหม่'
                                    : ''
                                : isCategories
                                ? 'หมวดหมู่'
                                : isSubcategories
                                ? catName && subcats_list
                                    ? catName
                                    : ''
                                : isEbooks
                                ? subcatId
                                    ? subcatName && categoryContents
                                        ? `${subcatName}${
                                              contentAmount
                                                  ? ' (' +
                                                    contentAmount +
                                                    ' เล่ม)'
                                                  : ''
                                          }`
                                        : ''
                                    : publisherId
                                    ? publisherName && publisherContents
                                        ? `${publisherName}${
                                              contentAmount
                                                  ? ' (' +
                                                    contentAmount +
                                                    ' เล่ม)'
                                                  : ''
                                          }`
                                        : ''
                                    : ''
                                : `${
                                      catalogueForEdit
                                          ? `${catalogueForEdit.title}${
                                                catalogue_contentAmount
                                                    ? ' (' +
                                                      catalogue_contentAmount.toLocaleString() +
                                                      ' เล่ม)'
                                                    : ''
                                            }`
                                          : ''
                                  }`
                            : 'รายการแคตตาล็อก'}
                    </div>
                </>
            </div>
        );
    };
    const TitleBar = () => {
        return (
            <>
                {isPrivate ? (
                    <Row gutter={[16, 16]}>
                        <Col
                            span={isCatalogueDetail ? 8 : 12}
                            style={
                                !isCatalogueDetail
                                    ? { marginBottom: '-32px' }
                                    : {
                                          display: 'flex',
                                          justifyContent: 'flex-start'
                                      }
                            }
                        >
                            <p className={classes.p1}>
                                {isExample
                                    ? 'รายการอีบุ๊ก'
                                    : isCatalogueDetail
                                    ? catalogueForEdit
                                        ? catalogueForEdit.title
                                        : ''
                                    : isNewEbook
                                    ? isPublishers
                                        ? 'สำนักพิมพ์/นักเขียน'
                                        : isNewEbooks
                                        ? newEbooksContents
                                            ? 'มาใหม่'
                                            : ''
                                        : isCategories
                                        ? 'หมวดหมู่'
                                        : isSubcategories
                                        ? catName && subcats_list
                                            ? catName
                                            : ''
                                        : isEbooks
                                        ? subcatId
                                            ? subcatName && categoryContents
                                                ? `${subcatName}${
                                                      contentAmount
                                                          ? ' (' +
                                                            contentAmount +
                                                            ' เล่ม)'
                                                          : ''
                                                  }`
                                                : ''
                                            : publisherId
                                            ? publisherName && publisherContents
                                                ? `${publisherName}${
                                                      contentAmount
                                                          ? ' (' +
                                                            contentAmount +
                                                            ' เล่ม)'
                                                          : ''
                                                  }`
                                                : ''
                                            : ''
                                        : `${
                                              catalogueForEdit
                                                  ? `${catalogueForEdit.title}${
                                                        catalogue_contentAmount
                                                            ? ' (' +
                                                              catalogue_contentAmount.toLocaleString() +
                                                              ' เล่ม)'
                                                            : ''
                                                    }`
                                                  : ''
                                          }`
                                    : 'รายการแคตตาล็อก'}
                            </p>
                        </Col>
                        <Col
                            span={isCatalogueDetail ? 16 : 12}
                            // style={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    margin: '32px 32px 0 0'
                                }}
                            >
                                {isExample ? (
                                    <div>
                                        <button
                                            type="button"
                                            className={classes.buttonMaxHeight}
                                            onClick={() => {
                                                downloadExampleTemplate();
                                            }}
                                        >
                                            <span>
                                                <img
                                                    alt="excel"
                                                    src="/media/members/excel-button.svg"
                                                    style={{
                                                        marginRight: '8px'
                                                    }}
                                                />
                                            </span>
                                            <span>ดาวน์โหลด Template</span>
                                        </button>
                                        <button
                                            type="button"
                                            className={classes.buttonMaxHeight}
                                            onClick={
                                                catalogueUIProps.newCatalogueButtonClick
                                            }
                                        >
                                            <span>อัพโหลดไฟล์</span>
                                        </button>
                                    </div>
                                ) : !isCatalogueDetail ? (
                                    <div className={classes.divLicense}>
                                        <div
                                            className={classes.orangeText16}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            จำนวนสิทธิ์อีบุ๊กคงเหลือ :{' '}
                                            <span
                                                className={classes.firstDigit}
                                            >
                                                {isPureCatalogue
                                                    ? Number.isInteger(
                                                          remainConcurrent
                                                      )
                                                        ? remainConcurrent
                                                        : '-'
                                                    : Number.isInteger(
                                                          totalConcurrent
                                                      )
                                                    ? totalConcurrent
                                                    : '-'}
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}

                                {isCatalogueDetail ? (
                                    <button
                                        type="button"
                                        className={classes.buttonMaxHeight}
                                        onClick={() => {
                                            downloadTemplate();
                                        }}
                                    >
                                        <span>
                                            <img
                                                alt="excel"
                                                src="/media/members/excel-button.svg"
                                                style={{ marginRight: '8px' }}
                                            />
                                        </span>
                                        <span>ดาวน์โหลด Report</span>
                                    </button>
                                ) : (
                                    ''
                                )}
                                {isCatalogueDetail ? (
                                    <button
                                        type="button"
                                        className={classes.buttonskyblue}
                                        onClick={openmodalsummary}
                                    >
                                        <span>สรุปข้อมูล</span>
                                    </button>
                                ) : (
                                    ''
                                )}
                                {isNewEbook ? (
                                    ''
                                ) : (
                                    <>
                                        {role === Role.SuperAdmin &&
                                        viewAsToken &&
                                        !isCatalogueDetail ? (
                                            <button
                                                type="button"
                                                className={
                                                    classes.buttonMaxHeight
                                                }
                                                onClick={() =>
                                                    setInitialConcurrentModal(
                                                        true
                                                    )
                                                }
                                            >
                                                <span>เพิ่มสิทธิ์</span>
                                            </button>
                                        ) : (
                                            ''
                                        )}
                                        <button
                                            type="button"
                                            className={classes.buttonMaxHeight}
                                            onClick={
                                                catalogueUIProps.newCatalogueButtonClick
                                            }
                                        >
                                            <span>
                                                {isCatalogueDetail
                                                    ? 'เพิ่มรายการอีบุ๊ก'
                                                    : 'สร้างแคตตาล็อก'}
                                            </span>
                                        </button>
                                    </>
                                )}
                                {isCatalogueDetail ? (
                                    <button
                                        type="button"
                                        className={classes.button}
                                        onClick={() => setSubmitModal(true)}
                                        disabled={
                                            (catalogueForEdit &&
                                                Number.isInteger(
                                                    catalogueForEdit.allContentAmount
                                                ) &&
                                                catalogueForEdit.allContentAmount ===
                                                    0) ||
                                            !catalogueForEdit ||
                                            !Number.isInteger(
                                                catalogueForEdit.allContentAmount
                                            )
                                        }
                                    >
                                        <span>ส่งข้อมูลการสั่งซื้อ</span>
                                    </button>
                                ) : (
                                    ''
                                )}

                                {isCatalogueDetail ||
                                publisherId ||
                                catId ||
                                isNewEbooks ? (
                                    <button
                                        type="button"
                                        className={classes.button}
                                        onClick={() => {
                                            updateCatalogueEbooks();
                                        }}
                                        disabled={!saved}
                                    >
                                        <span>บันทึก</span>
                                    </button>
                                ) : (
                                    ''
                                )}
                                {isExample ? (
                                    <button
                                        className={classes.button}
                                        disabled={selectedPreset === -1}
                                        onClick={() => {
                                            postPresetReq();
                                        }}
                                    >
                                        บันทึก
                                    </button>
                                ) : (
                                    ''
                                )}
                            </div>
                            {isCatalogueDetail ? (
                                <Modal
                                    backdrop="static"
                                    size="xl"
                                    show={summaryModal}
                                    onHide={onHide}
                                    ClassName={classes.summary}
                                >
                                    <Modal.Body>
                                        <div
                                            style={{
                                                zIndex: '5',
                                                top: '12px',
                                                right: '12px',
                                                textAlign: 'right'
                                            }}
                                        >
                                            <button
                                                className={classes.closeButton}
                                                onClick={() => {
                                                    setsummaryModal(false);
                                                }}
                                            >
                                                <img
                                                    src="/media/books/close-button.svg"
                                                    alt=""
                                                />
                                            </button>
                                        </div>
                                        <CatalogueSummary
                                            id={id}
                                            catalogueForEdit={
                                                catalogueForEdit?.title
                                            }
                                        />
                                    </Modal.Body>
                                </Modal>
                            ) : (
                                ''
                            )}
                        </Col>
                        {isPureCatalogue ? (
                            <Col span={24} className={classes.div}>
                                {tabDiv(isPureCatalogue)}
                            </Col>
                        ) : (
                            ''
                        )}
                        {isCatalogueDetail ? (
                            <Col span={24} className={classes.div}>
                                {tabDiv()}
                            </Col>
                        ) : (
                            ''
                        )}
                        <Col span={24}>
                            <Divider style={{ margin: '10px 0 24px' }} />
                        </Col>
                    </Row>
                ) : (
                    ''
                )}
            </>
        );
    };

    return (
        <>
            <div className="catalogueCard">
                <StepBar />
                <Card className="cardStyle">
                    {/* <CardHeader title={<TitleBar />}>
            <CardHeaderToolbar>
              <ToolBar />
            </CardHeaderToolbar>
          </CardHeader> */}
                    <TitleBar />
                    <CardBody className="cardBodyStyle">
                        <Row
                            gutter={[16, 16]}
                            id="scrollingRow"
                            className={
                                isCatalogueDetail ||
                                isPureCatalogue ||
                                isSubcategories ||
                                isEbooks
                                    ? `scrollingRow${
                                          isCatalogueDetail
                                              ? NoDataDiv(
                                                    catalogueForEdit &&
                                                        catalogueForEdit.contents
                                                )
                                                  ? ' isCatalogueDetail-NoData'
                                                  : ' isCatalogueDetail'
                                              : isPureCatalogue ||
                                                isSubcategories ||
                                                isEbooks
                                              ? isPureCatalogue
                                                  ? NoDataDiv(catalogue_list)
                                                      ? ' isPureCatalogue-NoData'
                                                      : ' isPureCatalogue'
                                                  : isSubcategories
                                                  ? NoDataDiv(subcats_list)
                                                      ? ' isPureCatalogue-NoData'
                                                      : ' isPureCatalogue'
                                                  : isEbooks
                                                  ? NoDataDiv(
                                                        catId
                                                            ? categoryContents
                                                            : publisherId
                                                            ? publisherContents
                                                            : ebookBox_list
                                                    )
                                                      ? ' isPureCatalogue-NoData'
                                                      : ' isPureCatalogue'
                                                  : ''
                                              : ''
                                      }`
                                    : 'scrollingRow normal'
                            }
                        >
                            {isExample
                                ? Array.isArray(exampleBox_list) &&
                                  exampleBox_list.map((e, index) => (
                                      <CardOfEbookBox
                                          catalogue={{
                                              catId: index,
                                              catName: e,
                                              date: '04/04/2564',
                                              status: 1,
                                              licenses: 10,
                                              email: 'nan@email.com',
                                              coverImage:
                                                  '/media/members/catalogue-publisher.png',
                                              clickFor: 'selectPreset',
                                              bgColor: '#2D9CDB'
                                          }}
                                          type="type"
                                          selectedPreset={selectedPreset}
                                          setSelectedPreset={setSelectedPreset}
                                      />
                                  ))
                                : isNewEbook
                                ? isPublishers
                                    ? publishers_list && !listLoading
                                        ? publishers_list.map(e => (
                                              <CardOfPublisher publisher={e} />
                                          ))
                                        : ''
                                    : isNewEbooks
                                    ? newEbooksContents
                                        ? newEbooksContents.map(e => (
                                              <CardOfEbook
                                                  ebook={e}
                                                  originConcurrent={
                                                      originConcurrent
                                                  }
                                                  totalConcurrent={
                                                      totalConcurrent
                                                  }
                                                  setTotalConcurrent={
                                                      setTotalConcurrent
                                                  }
                                                  saved={saved}
                                                  setSaved={setSaved}
                                                  setCatalogueEbooks={
                                                      setCatalogueEbooks
                                                  }
                                                  setCatalogueEbooksIon={
                                                      setCatalogueEbooksIon
                                                  }
                                                  catalogueForEdit={
                                                      catalogueForEdit
                                                  }
                                                  id={id}
                                                  subcatName={subcatName}
                                                  publisherName={publisherName}
                                              />
                                          ))
                                        : ''
                                    : isCategories
                                    ? categories_list && !listLoading
                                        ? categories_list.map(e => (
                                              <CardOfEbookBox
                                                  catalogue={e}
                                                  type="categories"
                                              />
                                          ))
                                        : ''
                                    : isSubcategories
                                    ? subcats_list && !actionsLoading
                                        ? subcats_list.map(e => (
                                              <CardOfEbookBox
                                                  catalogue={e}
                                                  type="subcategories"
                                                  catId={catId}
                                              />
                                          ))
                                        : ''
                                    : isEbooks
                                    ? catId && categoryContents
                                        ? categoryContents.map(e => (
                                              <CardOfEbook
                                                  ebook={e}
                                                  originConcurrent={
                                                      originConcurrent
                                                  }
                                                  totalConcurrent={
                                                      totalConcurrent
                                                  }
                                                  setTotalConcurrent={
                                                      setTotalConcurrent
                                                  }
                                                  setSaved={setSaved}
                                                  setCatalogueEbooks={
                                                      setCatalogueEbooks
                                                  }
                                                  setCatalogueEbooksIon={
                                                      setCatalogueEbooksIon
                                                  }
                                                  catalogueForEdit={
                                                      catalogueForEdit
                                                  }
                                                  id={id}
                                                  subcatName={subcatName}
                                                  publisherName={publisherName}
                                              />
                                          ))
                                        : publisherId && publisherContents
                                        ? publisherContents.map(e => (
                                              <CardOfEbook
                                                  ebook={e}
                                                  originConcurrent={
                                                      originConcurrent
                                                  }
                                                  totalConcurrent={
                                                      totalConcurrent
                                                  }
                                                  setTotalConcurrent={
                                                      setTotalConcurrent
                                                  }
                                                  setSaved={setSaved}
                                                  setCatalogueEbooks={
                                                      setCatalogueEbooks
                                                  }
                                                  setCatalogueEbooksIon={
                                                      setCatalogueEbooksIon
                                                  }
                                                  catalogueForEdit={
                                                      catalogueForEdit
                                                  }
                                                  id={id}
                                                  subcatName={subcatName}
                                                  publisherName={publisherName}
                                              />
                                          ))
                                        : ''
                                    : ebookBox_list
                                    ? ebookBox_list.map(e => (
                                          <CardOfEbookBox
                                              catalogue={e}
                                              type="type"
                                          />
                                      ))
                                    : ''
                                : isCatalogueDetail
                                ? catalogueForEdit && catalogueForEdit.contents
                                    ? catalogueForEdit.contents.map(e => (
                                          <CardOfCatalogueDetail
                                              catalogue={e}
                                              totalConcurrent={totalConcurrent}
                                              setTotalConcurrent={
                                                  setTotalConcurrent
                                              }
                                              setSaved={setSaved}
                                              setCatalogueEbooks={
                                                  setCatalogueEbooks
                                              }
                                              catalogueForEdit={
                                                  catalogueForEdit
                                              }
                                              id={id}
                                          />
                                      ))
                                    : ''
                                : catalogue_list
                                ? catalogue_list.map(e => (
                                      <CardOfCatalogue catalogue={e} />
                                  ))
                                : ''}

                            {isExample
                                ? Array.isArray(exampleBox_list)
                                    ? NoDataDiv(exampleBox_list)
                                    : SpinnerDiv()
                                : isNewEbook
                                ? isPublishers
                                    ? Array.isArray(publishers_list) &&
                                      !listLoading
                                        ? NoDataDiv(publishers_list)
                                        : SpinnerDiv()
                                    : isNewEbooks
                                    ? Array.isArray(newEbooksContents) &&
                                      !actionsLoading
                                        ? NoDataDiv(newEbooksContents)
                                        : SpinnerDiv(
                                              LastEvaluatedKey_newEbooksContents
                                          )
                                    : isCategories
                                    ? Array.isArray(categories_list) &&
                                      !listLoading
                                        ? NoDataDiv(categories_list)
                                        : SpinnerDiv()
                                    : isSubcategories
                                    ? Array.isArray(subcats_list) &&
                                      !actionsLoading
                                        ? NoDataDiv(subcats_list)
                                        : SpinnerDiv()
                                    : isEbooks
                                    ? catId
                                        ? Array.isArray(categoryContents) &&
                                          !actionsLoading
                                            ? NoDataDiv(categoryContents)
                                            : SpinnerDiv(
                                                  LastEvaluatedKey_categoryContents
                                              )
                                        : publisherId
                                        ? Array.isArray(publisherContents) &&
                                          !actionsLoading
                                            ? NoDataDiv(publisherContents)
                                            : SpinnerDiv(
                                                  LastEvaluatedKey_publisherContents
                                              )
                                        : ''
                                    : Array.isArray(ebookBox_list)
                                    ? NoDataDiv(ebookBox_list)
                                    : SpinnerDiv()
                                : isCatalogueDetail
                                ? catalogueForEdit &&
                                  Array.isArray(catalogueForEdit.contents) &&
                                  !actionsLoading_catalogueForEdit
                                    ? NoDataDiv(catalogueForEdit.contents)
                                    : ''
                                : Array.isArray(catalogue_list) && !listLoading
                                ? NoDataDiv(catalogue_list)
                                : SpinnerDiv()}
                            {isCatalogueDetail &&
                            actionsLoading_catalogueForEdit
                                ? SpinnerDiv(LastEvaluatedKey_catalogueForEdit)
                                : ''}
                            {isExample && loadingSavePreset ? SpinnerDiv() : ''}
                        </Row>
                    </CardBody>
                </Card>
            </div>

            <ModalsSuccess
                show={successModal}
                onHide={() => setSuccessModal(false)}
                onClickAccept={() => setSuccessModal(false)}
            />
            <Modal
                backdrop="static"
                show={submitModal}
                onHide={onHide}
                aria-labelledby="example-modal-sizes-title-lg"
                style={{ margin: '3% auto' }}
            >
                <Modal.Body className={modalStyles.body}>
                    <img src="/media/members/image-modal.svg" alt="" />
                    <div className={modalStyles.title}>
                        ยืนยันการส่งข้อมูลการสั่งซื้อ
                    </div>
                    <div className={modalStyles.divButton}>
                        <button
                            type="button"
                            onClick={() => setSubmitModal(false)}
                            className="denyButton"
                        >
                            ยกเลิก
                        </button>
                        <button
                            type="button"
                            onClick={() => submitCatalogueEbooks()}
                            className="acceptButton "
                        >
                            ยืนยัน
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                backdrop="static"
                size="md"
                show={initialCreditModal}
                onHide={() => {
                    setInitialConcurrentModal(false);
                    setSuccessModal(true);
                }}
                aria-labelledby="example-modal-sizes-title-lg"
                dialogClassName={styles.divInitial}
            >
                <Modal.Body className={styles.body}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            initialCredit: 0
                        }}
                        validationSchema={uploadSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            const newValues = {
                                credit: parseInt(values.initialCredit, 10)
                            };
                            const reqFunc = async () => {
                                const req = await axios.post(
                                    `/superuser/catalogs/credits`,
                                    newValues
                                );
                                if (req.data.status) {
                                    setSuccessModal(true);
                                    setInitialConcurrentModal(false);
                                    dispatch(
                                        actions.fetchCatalogues(
                                            catalogueUIProps.queryParams,
                                            'catalogues'
                                        )
                                    );
                                }
                            };
                            reqFunc();
                            setSubmitting(false);
                        }}
                    >
                        {({ isSubmitting }) => {
                            return (
                                <Card className="cardStyle">
                                    <div className={styles.divText}>
                                        เพิ่มสิทธิ์
                                    </div>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            zIndex: '5',
                                            right: '0',
                                            textAlign: 'right'
                                        }}
                                    >
                                        <button
                                            className={classes.closeButton}
                                            onClick={() => {
                                                setInitialConcurrentModal(
                                                    false
                                                );
                                            }}
                                        >
                                            <img
                                                src="/media/books/close-button.svg"
                                                alt=""
                                            />
                                        </button>
                                    </div>
                                    <CardBody className="cardBodyStyle">
                                        <div
                                            style={{
                                                maxWidth: '736px',
                                                margin: 'auto'
                                            }}
                                        >
                                            <Form className="form form-label-right">
                                                <div
                                                    className="row"
                                                    style={{
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <div
                                                        className={`col-12 ${styles.input}`}
                                                    >
                                                        <Field
                                                            name="initialCredit"
                                                            component={Input}
                                                            placeholder="กรุณากรอกสิทธิ์"
                                                            label="จำนวนสิทธิ์"
                                                            type="number"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="divButton">
                                                    <button
                                                        className="acceptButton"
                                                        disabled={isSubmitting}
                                                        type="submit"
                                                        style={{
                                                            marginTop: '60px'
                                                        }}
                                                    >
                                                        ตกลง
                                                    </button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                            );
                        }}
                    </Formik>
                </Modal.Body>
            </Modal>
            {isCatalogueDetail ? (
                <Modal
                    backdrop="static"
                    size="xl"
                    show={summaryModal}
                    onHide={onHide}
                    ClassName={classes.summary}
                >
                    <Modal.Body>
                        <div
                            style={{
                                zIndex: '5',
                                top: '12px',
                                right: '12px',
                                textAlign: 'right'
                            }}
                        >
                            <button
                                className={classes.closeButton}
                                onClick={() => {
                                    setsummaryModal(false);
                                }}
                            >
                                <img
                                    src="/media/books/close-button.svg"
                                    alt=""
                                />
                            </button>
                        </div>
                        <CatalogueSummary
                            id={id}
                            catalogueForEdit={catalogueForEdit?.title}
                        />
                    </Modal.Body>
                </Modal>
            ) : (
                ''
            )}
        </>
    );
}

const setColorFunc = (image_, id_, setColor_, isLocal) => {
    if (image_ && id_) {
        var loaded = false;
        var img = document.getElementById(id_.toString());
        if (typeof img !== 'undefined' && img !== null) {
            let imageURL = image_;
            let googleProxyURL =
                'https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=';
            img.crossOrigin = 'Anonymous';
            img.src = isLocal
                ? encodeURIComponent(imageURL)
                : googleProxyURL + encodeURIComponent(imageURL);
            // img.src = encodeURIComponent(imageURL);
            const loadHandler = () => {
                if (loaded) {
                    return;
                }
                loaded = true;
                var fac = new FastAverageColor();
                if (img) {
                    fac.getColorAsync(img)
                        .then(function(c) {
                            setColor_(c);
                        })
                        .catch(function() {});
                }
            };
            img.addEventListener('load', loadHandler, false);
            loadHandler();
        }
    }
};

const setBG_FontColorFunc = (color_, setBG_, setFontColor_) => {
    if (color_) {
        let bgEle = color_;
        let bg =
            bgEle !== undefined
                ? bgEle &&
                  bgEle.value &&
                  bgEle.value[0] > 230 &&
                  bgEle.value[1] > 230 &&
                  bgEle.value[2] > 230
                    ? 'rgb(230, 230, 230)'
                    : bgEle && bgEle.hex
                : 'rgb(230, 230, 230)';
        setBG_(bg);
        setFontColor_(
            bgEle
                ? !bgEle || (bgEle && bgEle.isLight)
                    ? '#000000'
                    : '#ffffff'
                : '#000000'
        );
    }
};

const CardOfCatalogue = props => {
    const [color, setColor] = useState({ hex: '#ffffff' });
    const [bg, setBG] = useState('');
    const [fontColor, setFontColor] = useState('');

    const catalogueUIContext = useCatalogueUIContext();
    const catalogueUIProps = useMemo(() => {
        return {
            ids: catalogueUIContext.ids,
            newCatalogueButtonClick: catalogueUIContext.newCatalogueButtonClick,
            viewCatalogueDetailClick:
                catalogueUIContext.viewCatalogueDetailClick,
            openDeleteCatalogueDialog:
                catalogueUIContext.openDeleteCatalogueDialog
        };
    }, [catalogueUIContext]);

    useEffect(() => {
        if (props.catalogue) {
            setColorFunc(
                props.catalogue.coverImage,
                'cardOfCatalogue' + props.catalogue.uid,
                setColor
            );
        }
    }, [props.catalogue]);
    useEffect(() => {
        setBG_FontColorFunc(color, setBG, setFontColor);
    }, [color]);
    const classes = useStyles1();
    const statusWording = s => {
        switch (s) {
            case 0:
                return 'รอดำเนินการ';
            case 2:
                return 'รอตรวจสอบ';
            case 4:
                return 'ผลการตรวจสอบ';
            default:
                return '';
        }
    };
    return props.catalogue ? (
        <Col span={24} md={12} lg={8}>
            <div
                onClick={() =>
                    catalogueUIProps.viewCatalogueDetailClick(
                        props.catalogue.uid
                    )
                }
                className={classes.colCatalogue}
                style={{
                    backgroundColor: bg,
                    color: fontColor,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'flex-start'
                }}
                // style={{ backgroundColor: "#000000", color: fontColor }}
            >
                <img
                    id={'cardOfCatalogue' + props.catalogue.uid}
                    src={props.catalogue.coverImage}
                    alt=""
                    style={{
                        maxWidth: '123px',
                        marginRight: '8px',
                        width: '35%'
                    }}
                />
                <div className={classes.catalogueDetail}>
                    <div className={classes.catalogueTitle}>
                        {props.catalogue.title}
                    </div>
                    <div>
                        วันที่สั่งซื้อ :{' '}
                        {
                            <p
                                style={{
                                    marginBottom: '0px',
                                    display: 'inline-block'
                                }}
                            >
                                {dateStr(props.catalogue.registerDate)}{' '}
                                {timeStr(props.catalogue.registerDate)}
                            </p>
                        }
                    </div>
                    <div>สถานะ : {statusWording(props.catalogue.status)}</div>
                    <div>จำนวนสิทธิ์ : {props.catalogue.concurrent} สิทธิ์</div>
                    <div>แอดมิน : {props.catalogue.creatorEmail}</div>
                </div>
            </div>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="quick-user-tooltip">ลบแคตตาล็อก</Tooltip>}
            >
                <a
                    className={`btn btn-icon btn-light btn-hover-danger btn-sm ${classes.deleteCatalogButton}`}
                    onClick={() =>
                        catalogueUIProps.openDeleteCatalogueDialog(
                            props.catalogue.title,
                            props.catalogue.uid
                        )
                    }
                >
                    <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                            src={toAbsoluteUrl(
                                '/media/members/delete-button.svg'
                            )}
                        />
                    </span>
                </a>
            </OverlayTrigger>
        </Col>
    ) : (
        <div></div>
    );
};

const CardOfPublisher = props => {
    const catalogueUIContext = useCatalogueUIContext();
    const catalogueUIProps = useMemo(() => {
        return {
            viewEbooksButtonClick: catalogueUIContext.viewEbooksButtonClick
        };
    }, [catalogueUIContext]);
    return props.publisher ? (
        <Col sm={24} md={12} lg={8}>
            <div
                className="row"
                style={{
                    margin: '12px 0px',
                    height: '95px',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.3)',
                    borderRadius: '10px',
                    cursor: 'pointer'
                }}
                onClick={() =>
                    catalogueUIProps.viewEbooksButtonClick(
                        props.publisher.uid,
                        'publisher'
                    )
                }
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '16px'
                    }}
                >
                    <img
                        src={props.publisher.profileImage}
                        alt=""
                        style={{
                            width: '62px',
                            imageRendering: '-webkit-optimize-contrast',
                            margin: 'auto 0 auto 8px',
                            borderRadius: '50%'
                        }}
                    />
                    <span
                        style={{
                            alignItems: 'center',
                            margin: 'auto 0 auto 8px',
                            padding: '0px'
                        }}
                    >
                        {props.publisher.name}
                    </span>
                </div>
            </div>
        </Col>
    ) : (
        <div></div>
    );
};

const CardOfEbookBox = props => {
    const [color, setColor] = useState({ hex: '#ffffff' });
    const [bg, setBG] = useState('');
    const [fontColor, setFontColor] = useState('');

    const catalogueUIContext = useCatalogueUIContext();
    const catalogueUIProps = useMemo(() => {
        return {
            ids: catalogueUIContext.ids,
            newCatalogueButtonClick: catalogueUIContext.newCatalogueButtonClick,
            viewCatalogueDetailClick:
                catalogueUIContext.viewCatalogueDetailClick,
            viewPublishersButtonClick:
                catalogueUIContext.viewPublishersButtonClick,
            viewNewEbooksButtonClick:
                catalogueUIContext.viewNewEbooksButtonClick,
            viewEbooksButtonClick: catalogueUIContext.viewEbooksButtonClick,
            viewCategoriesButtonClick:
                catalogueUIContext.viewCategoriesButtonClick
        };
    }, [catalogueUIContext]);

    useEffect(() => {
        if (props.catalogue) {
            setColorFunc(
                props.catalogue.coverImage,
                'cardOfEbookBox' +
                    (props.type === 'subcategories'
                        ? props.catalogue.subcatId
                        : props.catalogue.catId),
                setColor,
                props.type !== 'categories' && props.type !== 'subcategories'
            );
        }
    }, [props.catalogue]);
    useEffect(() => {
        setBG_FontColorFunc(color, setBG, setFontColor);
    }, [color]);
    const classes = useStyles1();
    const insideDiv = (
        <div
            onClick={() => {
                if (props.catalogue.clickFor === 'publishers') {
                    catalogueUIProps.viewPublishersButtonClick();
                } else if (props.catalogue.clickFor === 'categories') {
                    catalogueUIProps.viewCategoriesButtonClick();
                } else if (props.catalogue.clickFor === 'newEbooks') {
                    catalogueUIProps.viewNewEbooksButtonClick();
                } else if (props.catalogue.clickFor === 'selectPreset') {
                    if (props.setSelectedPreset) {
                        props.setSelectedPreset(props.catalogue.catId);
                    }
                } else if (props.type === 'categories') {
                    catalogueUIProps.viewCategoriesButtonClick(
                        props.catalogue.catId,
                        true
                    );
                } else {
                    catalogueUIProps.viewEbooksButtonClick(
                        props.type === 'subcategories'
                            ? props.catId
                            : props.catalogue.catId,
                        'subcategory',
                        props.type === 'subcategories'
                            ? props.catalogue.subcatId
                            : props.catalogue.catId
                    );
                }
                // catalogueUIProps.viewCatalogueDetailClick(props.catalogue.id)
            }}
            className={`${
                props.type === 'type'
                    ? classes.colCatalogueType
                    : classes.colCatalogue
            }`}
            style={
                props.type === 'type'
                    ? {
                          backgroundColor:
                              props.catalogue && props.catalogue.bgColor,
                          color: '#ffffff',
                          textAlign: 'center',
                          margin:
                              props.catalogue &&
                              props.catalogue.clickFor === 'selectPreset' &&
                              props.catalogue.catId === props.selectedPreset
                                  ? '-5px'
                                  : 'auto',
                          border:
                              props.catalogue &&
                              props.catalogue.clickFor === 'selectPreset' &&
                              props.catalogue.catId === props.selectedPreset
                                  ? '5px solid green'
                                  : 'none'
                      }
                    : {
                          backgroundColor: bg,
                          color: fontColor,
                          textAlign: 'center'
                      }
            }
            // style={{
            //   backgroundColor: "#000000",
            //   color: fontColor,
            //   textAlign: "center"
            // }}
        >
            {props.type === 'type' ? (
                ''
            ) : (
                <div className={classes.catalogueTitle}>
                    {props.type === 'subcategories'
                        ? props.catalogue.subcatName
                        : props.catalogue.catName}
                </div>
            )}
            <img
                id={
                    'cardOfEbookBox' +
                    (props.type === 'subcategories'
                        ? props.catalogue.subcatId
                        : props.catalogue.catId)
                }
                src={props.catalogue.coverImage}
                alt=""
                style={{
                    margin: '20px auto',
                    height: '150px',
                    imageRendering: '-webkit-optimize-contrast'
                }}
            />
            {props.type === 'type' ? (
                <div className={classes.catalogueTitle18}>
                    {props.type === 'subcategories'
                        ? props.catalogue.subcatName
                        : props.catalogue.catName}
                </div>
            ) : (
                ''
            )}
        </div>
    );
    return props.catalogue ? (
        props.type === 'type' ? (
            <div className={classes.colType}>{insideDiv}</div>
        ) : (
            <Col md={8} lg={6} xxl={4}>
                {insideDiv}
            </Col>
        )
    ) : (
        <div></div>
    );
};

const CardOfCatalogueDetail = props => {
    const history = useHistory();
    const [licenseNum, setLicenseNum] = useState(0);
    const [loadedOrderAmount, setLoadedOrderAmount] = useState(false);
    const catalogueUIContext = useCatalogueUIContext();
    const catalogueUIProps = useMemo(() => {
        return {
            ids: catalogueUIContext.ids,
            newCatalogueButtonClick: catalogueUIContext.newCatalogueButtonClick,
            viewCatalogueDetailClick:
                catalogueUIContext.viewCatalogueDetailClick,
            openViewCatalogueEbookDialog:
                catalogueUIContext.openViewCatalogueEbookDialog
        };
    }, [catalogueUIContext]);

    useEffect(() => {
        if (props.catalogue) {
            setLicenseNum(props.catalogue.concurrent);
            setLoadedOrderAmount(true);
        }
    }, [props.catalogue]);

    const classes = useStyles1();
    return props.catalogue ? (
        <Col span={12}>
            <div className={classes.colCatalogueDetail}>
                <div className={`filetype ${props.catalogue.fileType}`}>
                    {props.catalogue.fileType}
                </div>
                <img
                    onClick={() =>
                        catalogueUIProps.openViewCatalogueEbookDialog(
                            props.catalogue.contentUID
                        )
                    }
                    id={props.catalogue.contentUID}
                    src={props.catalogue.coverImage}
                    alt=""
                    className={classes.catalogueDetailImage}
                />
                <div className={classes.catalogueDetailDetail}>
                    <div>
                        <span
                            onClick={() =>
                                catalogueUIProps.openViewCatalogueEbookDialog(
                                    props.catalogue.contentUID
                                )
                            }
                            className={classes.catalogueDetailTitle}
                        >
                            {props.catalogue.title}
                        </span>
                    </div>
                    <div className={classes.marginBottom2px}>
                        ผู้แต่ง :{' '}
                        {props.catalogue.authorName
                            ? props.catalogue.authorName
                            : '-'}
                    </div>
                    <div className={classes.marginBottom2px}>
                        สำนักพิมพ์ :{' '}
                        <span
                            className={
                                props.catalogue.creatorUID
                                    ? classes.hoverLink
                                    : ''
                            }
                            onClick={() => {
                                if (props.catalogue.creatorUID)
                                    history.push(
                                        `/catalogue-detail?id=${props.id}&newEbook=true&ebooks=true&publisherId=${props.catalogue.creatorUID}`
                                    );
                            }}
                        >
                            {props.catalogue.publisherName
                                ? props.catalogue.publisherName
                                : props.catalogue.authorName
                                ? props.catalogue.authorName
                                : '-'}
                        </span>
                    </div>
                    <div className={classes.marginBottom2px}>
                        ปีที่ตีพิมพ์ :{' '}
                        {props.catalogue.publicationDate
                            ? timeStampToYear(props.catalogue.publicationDate)
                            : '-'}
                    </div>
                    <div className={classes.marginBottom2px}>
                        หมวดหมู่ :{' '}
                        <span
                            className={
                                props.catalogue.catId ? classes.hoverLink : ''
                            }
                            onClick={() => {
                                if (props.catalogue.catId)
                                    history.push(
                                        `/catalogue-detail?id=${props.id}&newEbook=true&catId=${props.catalogue.catId}&subcategories=true`
                                    );
                            }}
                        >
                            {props.catalogue.catName}
                        </span>{' '}
                        /{' '}
                        <span
                            className={
                                props.catalogue.catId &&
                                props.catalogue.subcatId
                                    ? classes.hoverLink
                                    : ''
                            }
                            onClick={() => {
                                if (
                                    props.catalogue.catId &&
                                    props.catalogue.subcatId
                                )
                                    history.push(
                                        `/catalogue-detail?id=${props.id}&newEbook=true&ebooks=true&catId=${props.catalogue.catId}&subcatId=${props.catalogue.subcatId}`
                                    );
                            }}
                        >
                            {props.catalogue.subcatName}
                        </span>
                    </div>
                    <div>
                        จำนวนสิทธิ์ที่มี :{' '}
                        {!isNil(props.catalogue?.purchasedConcurrent)
                            ? props.catalogue.purchasedConcurrent
                            : null}
                        {!isNil(props.ebook?.purchasedConcurrent)
                            ? props.ebook.purchasedConcurrent
                            : null}{' '}
                        สิทธิ์{' '}
                        <span style={{ color: '#D7042B' }}>
                            {minimumTwoBooks.includes(
                                props.catalogue.creatorUID
                            )
                                ? '(อย่างน้อยปกละ 2 สิทธิ์)'
                                : ''}
                        </span>
                    </div>
                    {/*<div>
            วันที่เริ่มเผยแพร่ - วันหมดอายุ : {props.catalogue.startDate} -{" "}
            {props.catalogue.endDate}
          </div>
          <div>
            คงเหลือ :{" "}
            {props.catalogue.timeLeft ? props.catalogue.timeLeft : "-"} วัน
          </div>*/}
                    <div className={classes.marginBottom2px}>
                        <span style={{ marginRight: '8px' }}>
                            จำนวนสิทธิ์ 555555555555
                        </span>
                        {minimumTwoBooks.includes(
                            props.catalogue.creatorUID
                        ) ? (
                            <>
                                <img
                                    src={`/media/members/${
                                        licenseNum > 0 &&
                                        props.catalogueForEdit &&
                                        loadedOrderAmount
                                            ? 'blue'
                                            : 'grey'
                                    }-minus.png`}
                                    alt=""
                                    onClick={() => {
                                        if (
                                            licenseNum > 0 &&
                                            props.catalogueForEdit &&
                                            loadedOrderAmount
                                        ) {
                                            setLicenseNum(num => {
                                                let dict = {};
                                                if (num === 2) {
                                                    props.setTotalConcurrent(
                                                        old => {
                                                            if (
                                                                licenseNum > 0
                                                            ) {
                                                                props.setSaved(
                                                                    true
                                                                );
                                                                return old + 2;
                                                            } else
                                                                return props.totalConcurrent;
                                                        }
                                                    );
                                                    //  dict[
                                                    //      props.catalogue.contentUID
                                                    //  ] = num - 2;
                                                    dict[
                                                        props.catalogue.contentUID
                                                    ] = {
                                                        concurrent: num - 2,
                                                        lifetimePurchase: false
                                                    };

                                                    props.setCatalogueEbooks(
                                                        old =>
                                                            Object.assign(
                                                                {},
                                                                old,
                                                                dict
                                                            )
                                                    );
                                                    return num - 2;
                                                } else {
                                                    if (num > 0) {
                                                        props.setTotalConcurrent(
                                                            old => {
                                                                if (
                                                                    licenseNum >
                                                                    0
                                                                ) {
                                                                    props.setSaved(
                                                                        true
                                                                    );
                                                                    return (
                                                                        old + 1
                                                                    );
                                                                } else
                                                                    return props.totalConcurrent;
                                                            }
                                                        );
                                                        dict[
                                                            props.catalogue.contentUID
                                                        ] = {
                                                            concurrent: num - 1,
                                                            lifetimePurchase: false
                                                        };
                                                        props.setCatalogueEbooks(
                                                            old =>
                                                                Object.assign(
                                                                    {},
                                                                    old,
                                                                    dict
                                                                )
                                                        );
                                                        return num - 1;
                                                    } else {
                                                        dict[
                                                            props.catalogue.contentUID
                                                        ] = {
                                                            concurrent: num,
                                                            lifetimePurchase: false
                                                        };
                                                        props.setCatalogueEbooks(
                                                            old =>
                                                                Object.assign(
                                                                    {},
                                                                    old,
                                                                    dict
                                                                )
                                                        );
                                                        return num;
                                                    }
                                                }
                                            });
                                        }
                                    }}
                                    className={
                                        licenseNum > 0 &&
                                        props.catalogueForEdit &&
                                        loadedOrderAmount
                                            ? classes.licenseAddRemove
                                            : classes.licenseAddRemoveDisabled
                                    }
                                />
                                <p
                                    className={
                                        licenseNum
                                            ? classes.blueDigit
                                            : classes.greyDigit
                                    }
                                >
                                    {licenseNum}
                                </p>
                                {/* <PlusCircleOutlined style={{ fontSize: "30px", color: "#012875" }} /> */}
                                <img
                                    src={`/media/members/${
                                        props.totalConcurrent === 1 &&
                                        licenseNum === 0
                                            ? 'grey'
                                            : Number.isInteger(
                                                  props.totalConcurrent
                                              ) &&
                                              props.totalConcurrent > 0 &&
                                              props.catalogueForEdit &&
                                              loadedOrderAmount
                                            ? 'blue'
                                            : 'grey'
                                    }-plus.png`}
                                    onClick={() => {
                                        if (
                                            props.totalConcurrent === 1 &&
                                            licenseNum === 0
                                        ) {
                                        } else {
                                            if (
                                                Number.isInteger(
                                                    props.totalConcurrent
                                                ) &&
                                                props.totalConcurrent > 0 &&
                                                props.catalogueForEdit &&
                                                loadedOrderAmount
                                            ) {
                                                if (licenseNum === 0) {
                                                    setLicenseNum(num => {
                                                        let dict = {};
                                                        dict[
                                                            props.catalogue.contentUID
                                                        ] = {
                                                            concurrent: num + 2,
                                                            lifetimePurchase: false
                                                        };

                                                        props.setCatalogueEbooks(
                                                            old =>
                                                                Object.assign(
                                                                    {},
                                                                    old,
                                                                    dict
                                                                )
                                                        );
                                                        return num + 2;
                                                    });
                                                    props.setTotalConcurrent(
                                                        old => old - 2
                                                    );
                                                    props.setSaved(true);
                                                } else {
                                                    setLicenseNum(num => {
                                                        let dict = {};
                                                        dict[
                                                            props.catalogue.contentUID
                                                        ] = {
                                                            concurrent: num + 1,
                                                            lifetimePurchase: false
                                                        };
                                                        props.setCatalogueEbooks(
                                                            old =>
                                                                Object.assign(
                                                                    {},
                                                                    old,
                                                                    dict
                                                                )
                                                        );
                                                        return num + 1;
                                                    });
                                                    props.setTotalConcurrent(
                                                        old => old - 1
                                                    );
                                                    props.setSaved(true);
                                                }
                                            }
                                        }
                                    }}
                                    alt=""
                                    className={
                                        props.totalConcurrent === 1 &&
                                        licenseNum === 0
                                            ? classes.licenseAddRemoveDisabled
                                            : Number.isInteger(
                                                  props.totalConcurrent
                                              ) &&
                                              props.totalConcurrent > 0 &&
                                              props.catalogueForEdit &&
                                              loadedOrderAmount
                                            ? classes.licenseAddRemove
                                            : classes.licenseAddRemoveDisabled
                                    }
                                />
                            </>
                        ) : (
                            <>
                                <img
                                    src={`/media/members/${
                                        licenseNum > 0 &&
                                        props.catalogueForEdit &&
                                        loadedOrderAmount
                                            ? 'blue'
                                            : 'grey'
                                    }-minus.png`}
                                    alt=""
                                    style={{ width: '20px' }}
                                    onClick={() => {
                                        if (
                                            licenseNum > 0 &&
                                            props.catalogueForEdit &&
                                            loadedOrderAmount
                                        ) {
                                            setLicenseNum(num => {
                                                let dict = {};
                                                if (num > 0) {
                                                    props.setTotalConcurrent(
                                                        old => {
                                                            if (
                                                                licenseNum > 0
                                                            ) {
                                                                props.setSaved(
                                                                    true
                                                                );
                                                                return old + 1;
                                                            } else
                                                                return props.totalConcurrent;
                                                        }
                                                    );
                                                    dict[
                                                        props.catalogue.contentUID
                                                    ] = {
                                                        concurrent: num - 1,
                                                        lifetimePurchase: false
                                                    };

                                                    props.setCatalogueEbooks(
                                                        old =>
                                                            Object.assign(
                                                                {},
                                                                old,
                                                                dict
                                                            )
                                                    );
                                                    return num - 1;
                                                } else {
                                                    dict[
                                                        props.catalogue.contentUID
                                                    ] = {
                                                        concurrent: num - 1,
                                                        lifetimePurchase: false
                                                    };
                                                    props.setCatalogueEbooks(
                                                        old =>
                                                            Object.assign(
                                                                {},
                                                                old,
                                                                dict
                                                            )
                                                    );
                                                    return num;
                                                }
                                            });
                                        }
                                    }}
                                    className={
                                        licenseNum > 0 &&
                                        props.catalogueForEdit &&
                                        loadedOrderAmount
                                            ? classes.licenseAddRemove
                                            : classes.licenseAddRemoveDisabled
                                    }
                                />
                                <p
                                    className={
                                        licenseNum
                                            ? classes.blueDigit
                                            : classes.greyDigit
                                    }
                                    style={{
                                        fontSize: '18px',
                                        margin: '0px 8px'
                                    }}
                                >
                                    {licenseNum}
                                </p>
                                {/* <PlusCircleOutlined style={{ fontSize: "30px", color: "#012875" }} /> */}
                                <img
                                    src={`/media/members/${
                                        Number.isInteger(
                                            props.totalConcurrent
                                        ) &&
                                        props.totalConcurrent > 0 &&
                                        props.catalogueForEdit &&
                                        loadedOrderAmount
                                            ? 'blue'
                                            : 'grey'
                                    }-plus.png`}
                                    style={{ width: '20px' }}
                                    onClick={() => {
                                        if (
                                            Number.isInteger(
                                                props.totalConcurrent
                                            ) &&
                                            props.totalConcurrent > 0 &&
                                            props.catalogueForEdit &&
                                            loadedOrderAmount
                                        ) {
                                            setLicenseNum(num => {
                                                let dict = {};
                                                dict[
                                                    props.catalogue.contentUID
                                                ] = {
                                                    concurrent: num + 1,
                                                    lifetimePurchase: false
                                                };
                                                props.setCatalogueEbooks(old =>
                                                    Object.assign({}, old, dict)
                                                );
                                                return num + 1;
                                            });
                                            props.setTotalConcurrent(
                                                old => old - 1
                                            );
                                            props.setSaved(true);
                                        }
                                    }}
                                    alt=""
                                    className={
                                        Number.isInteger(
                                            props.totalConcurrent
                                        ) &&
                                        props.totalConcurrent > 0 &&
                                        props.catalogueForEdit &&
                                        loadedOrderAmount
                                            ? classes.licenseAddRemove
                                            : classes.licenseAddRemoveDisabled
                                    }
                                />
                            </>
                        )}
                    </div>
                    <div>
                        สถานะ :{' '}
                        {props.catalogue.status === 0 ? (
                            <span className={classes.orangeLabel}>
                                รอการตรวจสอบ
                            </span>
                        ) : props.catalogue.status === 1 ? (
                            <span className={classes.redLabel}>
                                {props.catalogue.rejectMessages}
                            </span>
                        ) : props.catalogue.status === 2 ? (
                            <span className={classes.greenLabel}>
                                ผ่านการตรวจสอบ
                            </span>
                        ) : (
                            '-'
                        )}
                    </div>
                </div>
            </div>
        </Col>
    ) : (
        <div></div>
    );
};

const CardOfEbook = props => {
    const history = useHistory();
    const [licenseNum, setLicenseNum] = useState(0);
    const [loadedOrderAmount, setLoadedOrderAmount] = useState(false);
    const catalogueUIContext = useCatalogueUIContext();
    const catalogueUIProps = useMemo(() => {
        return {
            ids: catalogueUIContext.ids,
            newCatalogueButtonClick: catalogueUIContext.newCatalogueButtonClick,
            viewCatalogueDetailClick:
                catalogueUIContext.viewCatalogueDetailClick,
            openViewCatalogueEbookDialog:
                catalogueUIContext.openViewCatalogueEbookDialog
        };
    }, [catalogueUIContext]);

    useEffect(() => {
        if (props.ebook) {
            let stored = JSON.parse(localStorage.getItem('catalogueEbooks'));
            if (localStorage.getItem('catalogueEbooks')) {
                props.setSaved(true);
                if (Number.isInteger(stored[props.ebook.contentUID])) {
                    setLicenseNum(stored[props.ebook.contentUID]);
                    setLoadedOrderAmount(true);
                } else {
                    setLicenseNum(props.ebook.orderAmount);
                    setLoadedOrderAmount(true);
                }
            } else {
                setLicenseNum(props.ebook.orderAmount);
                setLoadedOrderAmount(true);
            }
        }
    }, [props.ebook, props.originConcurrent, props.setTotalConcurrent]);

    const classes = useStyles1();
    return props.ebook ? (
        <Col span={12}>
            <div className={classes.colCatalogueDetail}>
                <div className={`filetype ${props.ebook.fileType}`}>
                    {props.ebook.fileType}
                </div>
                <img
                    onClick={() =>
                        catalogueUIProps.openViewCatalogueEbookDialog(
                            props.ebook.contentUID
                        )
                    }
                    id={props.ebook.contentUID}
                    src={props.ebook.coverImage}
                    alt=""
                    className={classes.catalogueDetailImage}
                />
                <div className={classes.catalogueDetailDetail}>
                    <div>
                        <span
                            onClick={() =>
                                catalogueUIProps.openViewCatalogueEbookDialog(
                                    props.ebook.contentUID
                                )
                            }
                            className={classes.catalogueDetailTitle}
                        >
                            {props.ebook.title}
                        </span>
                    </div>
                    <div>
                        ผู้แต่ง :{' '}
                        {props.ebook.authorName ? props.ebook.authorName : '-'}
                    </div>
                    <div>
                        สำนักพิมพ์ :{' '}
                        <span
                            className={
                                props.ebook.creatorUID &&
                                props.ebook.publisherName !==
                                    props.publisherName
                                    ? classes.hoverLink
                                    : ''
                            }
                            onClick={() => {
                                if (
                                    props.ebook.creatorUID &&
                                    props.ebook.publisherName !==
                                        props.publisherName
                                )
                                    history.push(
                                        `/catalogue-detail?id=${props.id}&newEbook=true&ebooks=true&publisherId=${props.ebook.creatorUID}`
                                    );
                            }}
                        >
                            {props.ebook.publisherName
                                ? props.ebook.publisherName
                                : props.ebook.authorName
                                ? props.ebook.authorName
                                : '-'}
                        </span>
                    </div>
                    <div style={{ float: 'left' }}>
                        <div>
                            ปีที่ตีพิมพ์ :{' '}
                            {props.ebook.publicationDate
                                ? timeStampToYear(props.ebook.publicationDate)
                                : '-'}
                        </div>
                        <div>
                            หมวดหมู่ :{' '}
                            <span
                                className={
                                    props.ebook.catId ? classes.hoverLink : ''
                                }
                                onClick={() => {
                                    if (props.ebook.catId)
                                        history.push(
                                            `/catalogue-detail?id=${props.id}&newEbook=true&catId=${props.ebook.catId}&subcategories=true`
                                        );
                                }}
                            >
                                {props.ebook.catName}
                            </span>{' '}
                            /{' '}
                            <span
                                className={
                                    props.ebook.catId &&
                                    props.ebook.subcatId &&
                                    props.ebook.subcatName !== props.subcatName
                                        ? classes.hoverLink
                                        : ''
                                }
                                onClick={() => {
                                    if (
                                        props.ebook.catId &&
                                        props.ebook.subcatId &&
                                        props.ebook.subcatName !==
                                            props.subcatName
                                    )
                                        history.push(
                                            `/catalogue-detail?id=${props.id}&newEbook=true&ebooks=true&catId=${props.ebook.catId}&subcatId=${props.ebook.subcatId}`
                                        );
                                }}
                            >
                                {props.ebook.subcatName}
                            </span>
                        </div>
                        <div>
                            จำนวนสิทธิ์ที่มี :{' '}
                            {!isNil(props.catalogue?.purchasedConcurrent)
                                ? props.catalogue.purchasedConcurrent
                                : null}
                            {!isNil(props.ebook?.purchasedConcurrent)
                                ? props.ebook.purchasedConcurrent
                                : null}{' '}
                            สิทธิ์
                        </div>
                        <div className={classes.catalogueDetailLicense}>
                            เลือกจำนวนสิทธิ์{' '}
                            <span style={{ color: '#D7042B' }}>
                                {minimumTwoBooks.includes(
                                    props.ebook.creatorUID
                                )
                                    ? '(ต้องซื้ออย่างน้อยปกละ 2 สิทธิ์)'
                                    : ''}
                            </span>
                        </div>
                        {/* <MinusCircleOutlined style={{ fontSize: "30px", color: "#BDBDBD" }} /> */}
                        {minimumTwoBooks.includes(props.ebook.creatorUID) ? (
                            <>
                                <img
                                    src={`/media/members/${
                                        licenseNum > 0 &&
                                        props.catalogueForEdit &&
                                        loadedOrderAmount
                                            ? 'blue'
                                            : 'grey'
                                    }-minus.png`}
                                    alt=""
                                    onClick={() => {
                                        if (
                                            licenseNum > 0 &&
                                            props.catalogueForEdit &&
                                            loadedOrderAmount
                                        ) {
                                            setLicenseNum(num => {
                                                let dict = {};
                                                let dictIon = {};
                                                if (num === 2) {
                                                    props.setTotalConcurrent(
                                                        old => {
                                                            if (
                                                                licenseNum > 0
                                                            ) {
                                                                props.setSaved(
                                                                    true
                                                                );
                                                                return old + 2;
                                                            } else
                                                                return props.totalConcurrent;
                                                        }
                                                    );
                                                    dict[
                                                        props.ebook.contentUID
                                                    ] = {
                                                        concurrent: num - 2,
                                                        lifetimePurchase: false
                                                    };
                                                    dictIon[
                                                        props.ebook.contentUID
                                                    ] = {
                                                        concurrent:
                                                            num -
                                                            props.ebook
                                                                .orderAmount -
                                                            2,
                                                        lifetimePurchase: false
                                                    };

                                                    props.setCatalogueEbooks(
                                                        old => {
                                                            let newItems = Object.assign(
                                                                {},
                                                                old,
                                                                dict
                                                            );
                                                            localStorage.setItem(
                                                                'catalogueEbooks',
                                                                JSON.stringify(
                                                                    newItems
                                                                )
                                                            );
                                                            return newItems;
                                                        }
                                                    );
                                                    props.setCatalogueEbooksIon(
                                                        old => {
                                                            let newItemsIon = Object.assign(
                                                                {},
                                                                old,
                                                                dictIon
                                                            );
                                                            localStorage.setItem(
                                                                'catalogueEbooksIon',
                                                                JSON.stringify(
                                                                    newItemsIon
                                                                )
                                                            );
                                                            return newItemsIon;
                                                        }
                                                    );
                                                    return num - 2;
                                                } else {
                                                    if (num > 0) {
                                                        props.setTotalConcurrent(
                                                            old => {
                                                                if (
                                                                    licenseNum >
                                                                    0
                                                                ) {
                                                                    props.setSaved(
                                                                        true
                                                                    );
                                                                    return (
                                                                        old + 1
                                                                    );
                                                                } else
                                                                    return props.totalConcurrent;
                                                            }
                                                        );
                                                        dict[
                                                            props.ebook.contentUID
                                                        ] = {
                                                            concurrent: num - 1,
                                                            lifetimePurchase: false
                                                        };
                                                        dictIon[
                                                            props.ebook.contentUID
                                                        ] = {
                                                            concurrent:
                                                                num -
                                                                props.ebook
                                                                    .orderAmount -
                                                                1,
                                                            lifetimePurchase: false
                                                        };

                                                        props.setCatalogueEbooks(
                                                            old => {
                                                                let newItems = Object.assign(
                                                                    {},
                                                                    old,
                                                                    dict
                                                                );
                                                                localStorage.setItem(
                                                                    'catalogueEbooks',
                                                                    JSON.stringify(
                                                                        newItems
                                                                    )
                                                                );
                                                                return newItems;
                                                            }
                                                        );
                                                        props.setCatalogueEbooksIon(
                                                            old => {
                                                                let newItemsIon = Object.assign(
                                                                    {},
                                                                    old,
                                                                    dictIon
                                                                );
                                                                localStorage.setItem(
                                                                    'catalogueEbooksIon',
                                                                    JSON.stringify(
                                                                        newItemsIon
                                                                    )
                                                                );
                                                                return newItemsIon;
                                                            }
                                                        );
                                                        return num - 1;
                                                    } else {
                                                        dict[
                                                            props.ebook.contentUID
                                                        ] = {
                                                            concurrent: num,
                                                            lifetimePurchase: false
                                                        };
                                                        dictIon[
                                                            props.ebook.contentUID
                                                        ] = {
                                                            concurrent:
                                                                num -
                                                                props.ebook
                                                                    .orderAmount,
                                                            lifetimePurchase: false
                                                        };

                                                        props.setCatalogueEbooks(
                                                            old => {
                                                                let newItems = Object.assign(
                                                                    {},
                                                                    old,
                                                                    dict
                                                                );
                                                                localStorage.setItem(
                                                                    'catalogueEbooks',
                                                                    JSON.stringify(
                                                                        newItems
                                                                    )
                                                                );
                                                                return newItems;
                                                            }
                                                        );
                                                        props.setCatalogueEbooksIon(
                                                            old => {
                                                                let newItemsIon = Object.assign(
                                                                    {},
                                                                    old,
                                                                    dictIon
                                                                );
                                                                localStorage.setItem(
                                                                    'catalogueEbooksIon',
                                                                    JSON.stringify(
                                                                        newItemsIon
                                                                    )
                                                                );
                                                                return newItemsIon;
                                                            }
                                                        );
                                                        return num;
                                                    }
                                                }
                                            });
                                        }
                                    }}
                                    className={
                                        licenseNum > 0 &&
                                        props.catalogueForEdit &&
                                        loadedOrderAmount
                                            ? classes.licenseAddRemove
                                            : classes.licenseAddRemoveDisabled
                                    }
                                />
                                <p
                                    className={
                                        licenseNum
                                            ? classes.blueDigit
                                            : classes.greyDigit
                                    }
                                >
                                    {licenseNum}
                                </p>
                                {/* <PlusCircleOutlined style={{ fontSize: "30px", color: "#012875" }} /> */}
                                <img
                                    src={`/media/members/${
                                        props.totalConcurrent === 1 &&
                                        licenseNum === 0
                                            ? 'grey'
                                            : Number.isInteger(
                                                  props.totalConcurrent
                                              ) &&
                                              props.totalConcurrent > 0 &&
                                              props.catalogueForEdit &&
                                              loadedOrderAmount
                                            ? 'blue'
                                            : 'grey'
                                    }-plus.png`}
                                    onClick={() => {
                                        if (
                                            props.totalConcurrent === 1 &&
                                            licenseNum === 0
                                        ) {
                                        } else {
                                            if (
                                                Number.isInteger(
                                                    props.totalConcurrent
                                                ) &&
                                                props.totalConcurrent > 0 &&
                                                props.catalogueForEdit &&
                                                loadedOrderAmount
                                            ) {
                                                if (licenseNum === 0) {
                                                    setLicenseNum(num => {
                                                        let dict = {};
                                                        let dictIon = {};
                                                        dict[
                                                            props.ebook.contentUID
                                                        ] = {
                                                            concurrent: num + 2,
                                                            lifetimePurchase: false
                                                        };

                                                        dictIon[
                                                            props.ebook.contentUID
                                                        ] = {
                                                            concurrent:
                                                                num -
                                                                props.ebook
                                                                    .orderAmount +
                                                                2,
                                                            lifetimePurchase: false
                                                        };

                                                        props.setCatalogueEbooks(
                                                            old => {
                                                                let newItems = Object.assign(
                                                                    {},
                                                                    old,
                                                                    dict
                                                                );
                                                                localStorage.setItem(
                                                                    'catalogueEbooks',
                                                                    JSON.stringify(
                                                                        newItems
                                                                    )
                                                                );
                                                                return newItems;
                                                            }
                                                        );
                                                        props.setCatalogueEbooksIon(
                                                            old => {
                                                                let newItemsIon = Object.assign(
                                                                    {},
                                                                    old,
                                                                    dictIon
                                                                );
                                                                localStorage.setItem(
                                                                    'catalogueEbooksIon',
                                                                    JSON.stringify(
                                                                        newItemsIon
                                                                    )
                                                                );
                                                                return newItemsIon;
                                                            }
                                                        );
                                                        return num + 2;
                                                    });
                                                    props.setTotalConcurrent(
                                                        old => old - 2
                                                    );
                                                    props.setSaved(true);
                                                } else {
                                                    setLicenseNum(num => {
                                                        let dict = {};
                                                        let dictIon = {};
                                                        dict[
                                                            props.ebook.contentUID
                                                        ] = {
                                                            concurrent: num + 1,
                                                            lifetimePurchase: false
                                                        };

                                                        dictIon[
                                                            props.ebook.contentUID
                                                        ] = {
                                                            concurrent:
                                                                num -
                                                                props.ebook
                                                                    .orderAmount +
                                                                1,
                                                            lifetimePurchase: false
                                                        };

                                                        props.setCatalogueEbooks(
                                                            old => {
                                                                let newItems = Object.assign(
                                                                    {},
                                                                    old,
                                                                    dict
                                                                );
                                                                localStorage.setItem(
                                                                    'catalogueEbooks',
                                                                    JSON.stringify(
                                                                        newItems
                                                                    )
                                                                );
                                                                return newItems;
                                                            }
                                                        );
                                                        props.setCatalogueEbooksIon(
                                                            old => {
                                                                let newItemsIon = Object.assign(
                                                                    {},
                                                                    old,
                                                                    dictIon
                                                                );
                                                                localStorage.setItem(
                                                                    'catalogueEbooksIon',
                                                                    JSON.stringify(
                                                                        newItemsIon
                                                                    )
                                                                );
                                                                return newItemsIon;
                                                            }
                                                        );
                                                        return num + 1;
                                                    });
                                                    props.setTotalConcurrent(
                                                        old => old - 1
                                                    );
                                                    props.setSaved(true);
                                                }
                                            }
                                        }
                                    }}
                                    alt=""
                                    className={
                                        props.totalConcurrent === 1 &&
                                        licenseNum === 0
                                            ? classes.licenseAddRemoveDisabled
                                            : Number.isInteger(
                                                  props.totalConcurrent
                                              ) &&
                                              props.totalConcurrent > 0 &&
                                              props.catalogueForEdit &&
                                              loadedOrderAmount
                                            ? classes.licenseAddRemove
                                            : classes.licenseAddRemoveDisabled
                                    }
                                />
                            </>
                        ) : (
                            <>
                                <img
                                    src={`/media/members/${
                                        licenseNum > 0 &&
                                        props.catalogueForEdit &&
                                        loadedOrderAmount
                                            ? 'blue'
                                            : 'grey'
                                    }-minus.png`}
                                    alt=""
                                    onClick={() => {
                                        if (
                                            licenseNum > 0 &&
                                            props.catalogueForEdit &&
                                            loadedOrderAmount
                                        ) {
                                            setLicenseNum(num => {
                                                let dict = {};
                                                let dictIon = {};
                                                if (num > 0) {
                                                    props.setTotalConcurrent(
                                                        old => {
                                                            if (
                                                                licenseNum > 0
                                                            ) {
                                                                props.setSaved(
                                                                    true
                                                                );
                                                                return old + 1;
                                                            } else
                                                                return props.totalConcurrent;
                                                        }
                                                    );
                                                    dict[
                                                        props.ebook.contentUID
                                                    ] = {
                                                        concurrent: num - 1,
                                                        lifetimePurchase: false
                                                    };

                                                    dictIon[
                                                        props.ebook.contentUID
                                                    ] = {
                                                        concurrent:
                                                            num -
                                                            props.ebook
                                                                .orderAmount -
                                                            1,
                                                        lifetimePurchase: false
                                                    };

                                                    props.setCatalogueEbooks(
                                                        old => {
                                                            let newItems = Object.assign(
                                                                {},
                                                                old,
                                                                dict
                                                            );
                                                            localStorage.setItem(
                                                                'catalogueEbooks',
                                                                JSON.stringify(
                                                                    newItems
                                                                )
                                                            );
                                                            return newItems;
                                                        }
                                                    );
                                                    props.setCatalogueEbooksIon(
                                                        old => {
                                                            let newItemsIon = Object.assign(
                                                                {},
                                                                old,
                                                                dictIon
                                                            );
                                                            localStorage.setItem(
                                                                'catalogueEbooksIon',
                                                                JSON.stringify(
                                                                    newItemsIon
                                                                )
                                                            );
                                                            return newItemsIon;
                                                        }
                                                    );
                                                    return num - 1;
                                                } else {
                                                    dict[
                                                        props.ebook.contentUID
                                                    ] = {
                                                        concurrent: num,
                                                        lifetimePurchase: false
                                                    };
                                                    dictIon[
                                                        props.ebook.contentUID
                                                    ] = {
                                                        concurrent:
                                                            num -
                                                            props.ebook
                                                                .orderAmount,
                                                        lifetimePurchase: false
                                                    };

                                                    props.setCatalogueEbooks(
                                                        old => {
                                                            let newItems = Object.assign(
                                                                {},
                                                                old,
                                                                dict
                                                            );
                                                            localStorage.setItem(
                                                                'catalogueEbooks',
                                                                JSON.stringify(
                                                                    newItems
                                                                )
                                                            );
                                                            return newItems;
                                                        }
                                                    );
                                                    props.setCatalogueEbooksIon(
                                                        old => {
                                                            let newItemsIon = Object.assign(
                                                                {},
                                                                old,
                                                                dictIon
                                                            );
                                                            localStorage.setItem(
                                                                'catalogueEbooksIon',
                                                                JSON.stringify(
                                                                    newItemsIon
                                                                )
                                                            );
                                                            return newItemsIon;
                                                        }
                                                    );
                                                    return num;
                                                }
                                            });
                                        }
                                    }}
                                    className={
                                        licenseNum > 0 &&
                                        props.catalogueForEdit &&
                                        loadedOrderAmount
                                            ? classes.licenseAddRemove
                                            : classes.licenseAddRemoveDisabled
                                    }
                                />
                                <p
                                    className={
                                        licenseNum
                                            ? classes.blueDigit
                                            : classes.greyDigit
                                    }
                                >
                                    {licenseNum}
                                </p>
                                {/* <PlusCircleOutlined style={{ fontSize: "30px", color: "#012875" }} /> */}
                                <img
                                    src={`/media/members/${
                                        Number.isInteger(
                                            props.totalConcurrent
                                        ) &&
                                        props.totalConcurrent > 0 &&
                                        props.catalogueForEdit &&
                                        loadedOrderAmount
                                            ? 'blue'
                                            : 'grey'
                                    }-plus.png`}
                                    onClick={() => {
                                        if (
                                            Number.isInteger(
                                                props.totalConcurrent
                                            ) &&
                                            props.totalConcurrent > 0 &&
                                            props.catalogueForEdit &&
                                            loadedOrderAmount
                                        ) {
                                            setLicenseNum(num => {
                                                let dict = {};
                                                let dictIon = {};
                                                dict[props.ebook.contentUID] = {
                                                    concurrent: num + 1,
                                                    lifetimePurchase: false
                                                };
                                                dictIon[
                                                    props.ebook.contentUID
                                                ] = {
                                                    concurrent:
                                                        num -
                                                        props.ebook
                                                            .orderAmount +
                                                        1,
                                                    lifetimePurchase: false
                                                };

                                                props.setCatalogueEbooks(
                                                    old => {
                                                        let newItems = Object.assign(
                                                            {},
                                                            old,
                                                            dict
                                                        );
                                                        localStorage.setItem(
                                                            'catalogueEbooks',
                                                            JSON.stringify(
                                                                newItems
                                                            )
                                                        );
                                                        return newItems;
                                                    }
                                                );
                                                props.setCatalogueEbooksIon(
                                                    old => {
                                                        let newItemsIon = Object.assign(
                                                            {},
                                                            old,
                                                            dictIon
                                                        );
                                                        localStorage.setItem(
                                                            'catalogueEbooksIon',
                                                            JSON.stringify(
                                                                newItemsIon
                                                            )
                                                        );
                                                        return newItemsIon;
                                                    }
                                                );
                                                return num + 1;
                                            });
                                            props.setTotalConcurrent(
                                                old => old - 1
                                            );
                                            props.setSaved(true);
                                        }
                                    }}
                                    alt=""
                                    className={
                                        Number.isInteger(
                                            props.totalConcurrent
                                        ) &&
                                        props.totalConcurrent > 0 &&
                                        props.catalogueForEdit &&
                                        loadedOrderAmount
                                            ? classes.licenseAddRemove
                                            : classes.licenseAddRemoveDisabled
                                    }
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Col>
    ) : (
        <div></div>
    );
};

const timeStampToYear = t => {
    if (t) {
        const date = new Date(t * 1000);
        const dateStr = date.getFullYear();
        return (
            <>
                <p style={{ marginBottom: '0px', display: 'inline-block' }}>
                    {dateStr}
                </p>
            </>
        );
    }
};
