/* eslint-disable array-callback-return */
import React, { useMemo, useState, useEffect } from 'react';
import {
   Card,
   CardBody,
   CardHeader,
   CardHeaderToolbar
} from '../../../../../_metronic/_partials/controls';
import { EbooksTable } from './ebooks-table/EbooksTable';
import { EbooksGrouping } from './ebooks-grouping/EbooksGrouping';
import { useEbooksUIContext } from './EbooksUIContext';
import { EbooksFilter } from './ebooks-filter/EbooksFilter';
import { useParams } from 'react-router-dom';

export function EbooksCard({ isPrivate, isFree, isNewEbook }) {
   const { ebookType } = useParams();
   const ebooksUIContext = useEbooksUIContext();
   const ebooksUIProps = useMemo(() => {
      return {
         ids: ebooksUIContext.ids,
         newEbookButtonClick: ebooksUIContext.newEbookButtonClick
      };
   }, [ebooksUIContext, ebookType]);

   const getTitleCard = () => {
      switch (ebookType) {
         case 'Newspaper':
            return 'หนังสือพิมพ์';
         case 'Magazine':
            return 'นิตยสาร';
         default:
            return 'อีบุ๊ก';
      }
   };

   const [listLoading, setListLoading] = useState(false);

   return (
      <>
         <Card className="cardStyle" key={ebookType ?? ''}>
            <CardHeader
               title={
                  <div className="card-label">
                     <div>{getTitleCard()}</div>
                  </div>
               }
            >
               <CardHeaderToolbar>
                  <EbooksFilter isPrivate={isPrivate} isFree={isFree} />
                  {isPrivate ? (
                     <button
                        type="button"
                        className="button"
                        onClick={ebooksUIProps.newEbookButtonClick}
                     >
                        <span>อัปโหลดอีบุ๊ก</span>
                     </button>
                  ) : (
                     ''
                  )}
               </CardHeaderToolbar>
            </CardHeader>

            <CardBody className="cardBodyStyle">
               {ebooksUIProps.ids.length > 0 && <EbooksGrouping />}
               <EbooksTable
                  isPrivate={isPrivate}
                  isFree={isFree}
                  setListLoading={setListLoading}
                  isNewEbook={isNewEbook}
                  ebookType={ebookType}
               />
            </CardBody>
         </Card>
      </>
   );
}
