import axios from "axios";
import {
  CreateCategoryBodyRequest,
  CreateSubCategoryBodyRequest,
  UpdateCategoryBodyRequest,
  updateSubCategoryBodyRequest,
  customersUIPropsQueryParams
} from "../../../../../interface/request";


// export const ADMIN_CATEGORIES_URL = "api/categories";
// export const ADMIN_CATEGORIES_URL = `/categories`;
export const ADMIN_CATEGORIES_URL = `/admin/categories`;
export const PRIVATE_CATEGORIES_URL = `/admin/private/categories`;

// CREATE =>  POST: add a new category to the server
export function createCategory(
  category: CreateCategoryBodyRequest,
  isPrivate: number
) {
  return axios.post(
    isPrivate ? PRIVATE_CATEGORIES_URL : ADMIN_CATEGORIES_URL,
    category
  );
}

export function createSubCategory(
  subCategory: CreateSubCategoryBodyRequest,
  isPrivate: number,
  catId: string
) {
  return axios.post(
    `${
      isPrivate ? PRIVATE_CATEGORIES_URL : ADMIN_CATEGORIES_URL
    }/${catId}/subcategory`,
    subCategory
  );
}

// READ
export function getAllCategories() {
  return axios.get(ADMIN_CATEGORIES_URL);
}

export function getCategoryById(categoryId: string, isPrivate: number) {
  return axios.get(
    `${isPrivate ? PRIVATE_CATEGORIES_URL : ADMIN_CATEGORIES_URL}/${categoryId}`
  );
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCategories(
  queryParams: customersUIPropsQueryParams,
  isPrivate: number,
  isAll: boolean,
  catId: string
) {
  let contentTypeQuery = "";
  if (queryParams && queryParams.contentType) {
    contentTypeQuery = `&contentType=${queryParams.contentType}`;
  }
  if (queryParams === "none" || !queryParams.keyword || catId) {
    // return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
    let limit = "";

    let isAllQuery = "";

    if (queryParams && queryParams.pageSize) {
      limit = `?limit=${queryParams.pageSize}`;
    }

    if (isAll) {
      isAllQuery = "?isAll=true";
    }
    return axios.get(
      `${
        isPrivate ? PRIVATE_CATEGORIES_URL : ADMIN_CATEGORIES_URL
      }${limit}${isAllQuery}${contentTypeQuery}`
    );
  } else {
    return axios.get(
      `${
        isPrivate ? PRIVATE_CATEGORIES_URL : ADMIN_CATEGORIES_URL
      }/search?keyword=${encodeURIComponent(
        queryParams.keyword
      )}${contentTypeQuery}`
    );
  }
  // return axios.post(`${ADMIN_CATEGORIES_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the category on the server
export function updateCategory(
  category: UpdateCategoryBodyRequest,
  isPrivate: number,
  catId: string
) {
  return axios.patch(
    `${isPrivate ? PRIVATE_CATEGORIES_URL : ADMIN_CATEGORIES_URL}/${catId}`,
    category
  );
}

export function updateSubCategory(
  subcategory: updateSubCategoryBodyRequest,
  isPrivate: number,
  catId: string,
  subcatId: string
) {
  return axios.patch(
    `${
      isPrivate ? PRIVATE_CATEGORIES_URL : ADMIN_CATEGORIES_URL
    }/${catId}/subcategories/${subcatId}`,
    subcategory
  );
}

// UPDATE Status
// export function updateStatusForCategories(ids, status) {
//   return axios.post(`${ADMIN_CATEGORIES_URL}/updateStatusForCategories`, {
//     ids,
//     status,
//   });
// }

// DELETE => delete the category from the server
export function deleteCategory(categoryId: string, isPrivate: number) {
  return axios.delete(
    `${isPrivate ? PRIVATE_CATEGORIES_URL : ADMIN_CATEGORIES_URL}/${categoryId}`
  );
}

export function deleteSubCategory(
  categoryId: string,
  subcategoryId: string,
  isPrivate: number
) {
  return axios.delete(
    `${
      isPrivate ? PRIVATE_CATEGORIES_URL : ADMIN_CATEGORIES_URL
    }/${categoryId}/subcategory/${subcategoryId}`
  );
}

export function blockCategory(
  categoryName: string,
  categoryId: string,
  isPrivate: number
) {
  return axios.patch(
    `${
      isPrivate ? PRIVATE_CATEGORIES_URL : ADMIN_CATEGORIES_URL
    }/${categoryId}`,
    {
      catName: categoryName,
      status: 0,
    }
  );
}
export function unblockCategory(
  categoryName: string,
  categoryId: string,
  isPrivate: number
) {
  return axios.patch(
    `${
      isPrivate ? PRIVATE_CATEGORIES_URL : ADMIN_CATEGORIES_URL
    }/${categoryId}`,
    {
      catName: categoryName,
      status: 1,
    }
  );
}

export function setStatusCategory(
  id: string,
  value: boolean,
  isPrivate: number,
  catId: string
) {
  let subcatQuery = "";
  if (catId) {
    subcatQuery = `/${catId}/subcategories`;
  }
  return axios.patch(
    `${
      isPrivate ? PRIVATE_CATEGORIES_URL : ADMIN_CATEGORIES_URL
    }${subcatQuery}/${id}`,
    {
      status: value ? 1 : 0,
    }
  );
}
export function setPinCategory(
  categoryId: string,
  value: boolean,
  isPrivate: number
) {
  return axios.patch(
    `${
      isPrivate ? PRIVATE_CATEGORIES_URL : ADMIN_CATEGORIES_URL
    }/${categoryId}`,
    {
      pin: value,
    }
  );
}
