import * as requestFromServer from "./categoriesCrud";
import { categoriesSlice, callTypes } from "./categoriesSlice";
import {
  CreateCategoryBodyRequest,
  CreateSubCategoryBodyRequest,
  UpdateCategoryBodyRequest,
  updateSubCategoryBodyRequest,
  findCategoriesQueryParam,
  customersUIPropsQueryParams
} from "../../../../../interface/request";

const { actions } = categoriesSlice;

export const fetchCategories = (
  queryParams: customersUIPropsQueryParams,
  isPrivate: string,
  catId: string,
  isAll: boolean
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  //isAll is for dropdown
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCategories(queryParams, parseInt(isPrivate, 10), isAll, catId)
    .then((response) => {
      const { categories } = response.data.data;
      console.log(categories)
      const entities = categories;
      dispatch(
        actions.categoriesFetched({
          totalCount: categories.length,
          entities,
          catId: catId,
          memberMode: queryParams.memberMode,
          isPrivate,
          contentType: queryParams.contentType,
        })
      );
    })
    .catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorCode === "012"
      ) {
        alert("กรุณาเข้าสู่ระบบอีกครั้ง เนื่องจากระยะเวลาการใช้งานหมดอายุ");
        window.location.href = "/logout";
      }
      dispatch(
        actions.categoriesFetched({
          totalCount: 0,
          entities: [],
        })
      );
      error.clientMessage = "Can't find categories";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCategory = (
  id: string,
  isPrivate: string,
  isSubCat: boolean
) => ( dispatch: (arg0: { payload: any; type: string }) => void) => {
  if (!id) {
    return dispatch(actions.categoryFetched({ categoryForEdit: undefined }));
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCategoryById(id, parseInt(isPrivate, 10))
    .then((response) => {
      if (response.data.status) {
        const category = response.data.data.category || response.data.data;
        dispatch(
          actions.categoryFetched({
            categoryForEdit: category,
            isSubCat: isSubCat,
          })
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find category";
      dispatch(
        actions.categoriesFetched({
          totalCount: 0,
          entities: [],
        })
      );
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const deleteCategory = (id: string, isPrivate: number) => (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCategory(id, isPrivate)
    .then((response) => {
      dispatch(actions.categoryDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete category";
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          from: "delete",
        })
      );
    });
};

export const deleteSubCategory = (
  catId: string,
  subcatId: string,
  isPrivate: number
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSubCategory(catId, subcatId, isPrivate)
    .then((response) => {
      dispatch(
        actions.subCategoryDeleted({ catId: catId, subcatId: subcatId })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't delete subcategory";
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          from: "delete",
        })
      );
    });
};

export const blockCategory = (name: string, id: string, isPrivate: string) => (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .blockCategory(name, id, parseInt(isPrivate, 10))
    .then((response) => {
      dispatch(actions.elementUpdated());
    })
    .catch((error) => {
      error.clientMessage = "Can't block category";
      dispatch(
        actions.catchError({ error, callType: callTypes.action, from: "block" })
      );
    });
};

export const unblockCategory = (
  name: string,
  id: string,
  isPrivate: string
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .unblockCategory(name, id, parseInt(isPrivate, 10))
    .then((response) => {
      dispatch(actions.elementUpdated());
    })
    .catch((error) => {
      error.clientMessage = "Can't unblock category";
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          from: "unblock",
        })
      );
    });
};

export const setStatusCategory = (
  id: string,
  value: boolean,
  isPrivate: string,
  catId: string
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .setStatusCategory(id, value, parseInt(isPrivate, 10), catId)
    .then((response) => {
     
      dispatch(actions.elementUpdated());
    })
    .catch((error) => {
      error.clientMessage = "Can't set status category";
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          from: "setStatus",
        })
      );
    });
};

export const setPinCategory = (
  id: string,
  value: boolean,
  isPrivate: string
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .setPinCategory(id, value, parseInt(isPrivate, 10))
    .then((response) => {
    
      dispatch(actions.elementUpdated());
    })
    .catch((error) => {
      error.clientMessage = "Can't set pin category";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createCategory = (
  categoryForCreation: CreateCategoryBodyRequest,
  isPrivate: string,
  onSuccess:any
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCategory(categoryForCreation, parseInt(isPrivate, 10))
    .then((response) => {
      if (response.data.status) {
        const { category } = response.data.data;
        dispatch(actions.categoryCreated({ category }));
        if (onSuccess) {
          onSuccess();
        }
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't create category";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      if (error) {
        return "error";
      }
    });
};

export const createSubCategory = (
  subCategoryForCreation: CreateSubCategoryBodyRequest,
  isPrivate: string,
  catId: string,
  catName: string,
  history: any,
  onSuccess:any
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createSubCategory(subCategoryForCreation, parseInt(isPrivate, 10), catId)
    .then((response) => {
      if (response.data.status) {
        const { subcategory } = response.data.data;
        dispatch(
          actions.subCategoryCreated({ subcategory: subcategory, catId: catId })
        );
        if (history && onSuccess) {
          onSuccess();
          if (isPrivate) {
            history.push(
              `/subcategories-private-${subCategoryForCreation.contentType}?catId=${catId}&catName=${catName}`
            );
          } else {
            history.push(`/subcategories?catId=${catId}&catName=${catName}`);
          }
        }
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't create subcategory";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      if (error) {
        return "error";
      }
    });
};

export const updateCategory = (
  category: UpdateCategoryBodyRequest,
  isPrivate: string,
  catId: string,
  setSuccessModal: any
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCategory(category, parseInt(isPrivate, 10), catId)
    .then(() => {
      dispatch(actions.categoryUpdated({ category: category, catId: catId }));
      setSuccessModal(true);
    })
    .catch((error) => {
      error.clientMessage = "Can't update category";
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          from: "update",
        })
      );
    });
};

export const updateSubCategory = (
  subcategory: updateSubCategoryBodyRequest,
  isPrivate: string,
  catId: string,
  subcatId: string,
  setSuccessModal: any
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSubCategory(subcategory, parseInt(isPrivate, 10), catId, subcatId)
    .then(() => {
      dispatch(
        actions.subCategoryUpdated({
          subcategory: subcategory,
          catId: catId,
          subcatId: subcatId,
        })
      );
      setSuccessModal(true);
    })
    .catch((error) => {
      error.clientMessage = "Can't update subcategory";
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          from: "update",
        })
      );
    });
};
