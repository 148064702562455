import { Button, Empty, message, Popover, Tooltip } from 'antd';
import { CardContainer } from './styled';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getAllToken, TokenType } from '../../../redux/axios/tokenKey';
import SVG from 'react-inlinesvg';
import moment from 'moment-timezone';
import Spinner from '../../../_metronic/_helpers/spinner';
import ConfirmModal from './ConfirmModal';

const PublicApi = () => {
   const history = useHistory();
   const dateFormat = 'DD/MM/YYYY';
   const [tokenList, setTokenList] = useState<TokenType[]>([]);
   const [loading, setLoading] = useState<boolean>(false);
   const [confirmModal, setConfirmModal] = useState<boolean>(false);
   const [currentToken, setCurrentToken] = useState<TokenType>();

   useEffect(() => {
      fetchToken();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const fetchToken = async () => {
      try {
         setLoading(true);
         const response = await getAllToken();
         if (response) {
            setTokenList(response.tokenKeys);
            setLoading(false);
         }
      } catch (error) {
         setLoading(false);
      }
   };

   const copyClipboard = async (text: string) => {
      await navigator.clipboard.writeText(text);
      message.success('คัดลอง Token สำเร็จ!');
   };

   const onDelete = (uid: string) => {
      const newList = tokenList.filter(el => el.uid !== uid);
      setTokenList(newList);
   };

   const overlay = (uid: string) => {
      return (
         <div className="menuContainer">
            <div
               className="flex"
               onClick={() => history.push(`/createToken?uid=${uid}`)}
            >
               <SVG src="/media/members/edit-button.svg" />
               <p>แก้ไข</p>
            </div>
            <div
               className="flex"
               onClick={() => {
                  const index = tokenList.findIndex(el => el.uid === uid);
                  setCurrentToken(tokenList[index]);
                  setConfirmModal(true);
               }}
            >
               <SVG src="/media/members/delete-button.svg" />
               <p>ลบ</p>
            </div>
         </div>
      );
   };

   return (
      <CardContainer>
         <div className="headerSection">
            <h1>Token Key</h1>
            <Button
               shape="round"
               size="large"
               onClick={() => history.push('/createToken')}
            >
               สร้าง Token Key
            </Button>
         </div>
         <div className="itemContainer">
            {tokenList?.map((el, index) => (
               <div className="item" key={index}>
                  <div
                     className="flex"
                     style={{
                        justifyContent: 'space-between',
                        marginBottom: '1rem'
                     }}
                  >
                     <h4>{el.name}</h4>
                     <Popover
                        placement="bottom"
                        content={overlay(el.uid)}
                        trigger="hover"
                        overlayClassName="editTokenPopover"
                     >
                        <img
                           src="/media/members/dot-bookshelf.svg"
                           className="editButton"
                           alt="editButton"
                        />
                     </Popover>
                  </div>
                  <div style={{ marginBottom: '1rem' }}>
                     <p>
                        วันที่เริ่ม - วันที่หมดอายุ :{' '}
                        <span className="startDate">
                           {moment.unix(el.startDate).format(dateFormat)}
                        </span>
                        {' - '}
                        <span className="expireDate">
                           {moment.unix(el.expireDate).format(dateFormat)}
                        </span>
                     </p>
                     <p>แอดมิน : {el.createBy}</p>
                  </div>
                  <div className="flex">
                     <input defaultValue={el.token} type="password" disabled />
                     <Tooltip title="คัดลอก" placement="bottom">
                        <Button
                           className="copyTokenButton"
                           onClick={() => copyClipboard(el.token)}
                        >
                           <img
                              src="/media/members/copy-icon.svg"
                              alt="copyButton"
                           />
                        </Button>
                     </Tooltip>
                  </div>
               </div>
            ))}
            {confirmModal && currentToken && (
               <ConfirmModal
                  visible={confirmModal}
                  onCancel={() => setConfirmModal(false)}
                  onDelete={onDelete}
                  tokenData={currentToken}
               />
            )}
            {!loading && tokenList?.length <= 0 && <Empty />}
            {loading && (
               <div className="loading">
                  <Spinner
                     forEditCustomer={undefined}
                     forNewCat={undefined}
                     forQrCode={undefined}
                     isSubCat={undefined}
                     forNewEbook={undefined}
                     forDashboard={undefined}
                     fullScreen={undefined}
                     forNewChannel={undefined}
                     forNewCompany={undefined}
                  />
               </div>
            )}
         </div>
      </CardContainer>
   );
};

export default PublicApi;
