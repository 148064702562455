import { Button, Modal, ModalProps, Space } from 'antd';
import './index.scss';

interface VoteAlertModalProps {
   visible: boolean;
   onCancel: () => void;
   onOpenCreateVoteModal: () => void;
}

const VoteAlertModal = (props: VoteAlertModalProps) => {
   const modalProps: ModalProps = {
      visible: props.visible,
      onCancel: props.onCancel,
      footer: null,
      className: 'voteAlertModal'
   };

   return (
      <Modal {...modalProps}>
         <img src="/media/members/image-modal.svg" alt="" />
         <h2>คุณต้องการสร้างโหวตใหม่?</h2>
         <p>เมื่อกดสร้างโหวตใหม่ ระบบจะทำการรีเซ็ตข้อมูลการโหวต ทั้งหมด</p>
         <p>และไม่สามารถกลับไปดูข้อมูลการโหวตก่อนหน้าได้</p>
         <Space size={16} style={{ marginTop: '24px' }}>
            <Button
               shape="round"
               size="large"
               onClick={props.onCancel}
               className="cancel"
            >
               ยกเลิก
            </Button>
            <Button
               shape="round"
               size="large"
               className="accept"
               onClick={() => {
                  props.onOpenCreateVoteModal();
                  props.onCancel();
               }}
            >
               โหวต
            </Button>
         </Space>
      </Modal>
   );
};

export default VoteAlertModal;
