import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as expire from "../app/modules/ECommerce/_redux/expire/expireRedux";
import { categoriesSlice } from "../app/modules/ECommerce/_redux/categories/categoriesSlice";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { publishersSlice } from "../app/modules/ECommerce/_redux/publishers/publishersSlice";
import { channelsSlice } from "../app/modules/ECommerce/_redux/channels/channelsSlice";
import { channelsSlice as channelsPodcastSlice } from "../app/modules/ECommerce/_redux/channels-podcast/channelsSlice";
import { qrcodesSlice } from "../app/modules/ECommerce/_redux/customers/qrcodesSlice";
import { ebooksSlice } from "../app/modules/ECommerce/_redux/ebooks/ebooksSlice";
import { videosSlice } from "../app/modules/ECommerce/_redux/videos/videosSlice";
import { podcastsSlice } from "../app/modules/ECommerce/_redux/podcasts/podcastsSlice";
import { companiesSlice } from "../app/modules/ECommerce/_redux/companies/companiesSlice";
import { bannersSlice } from "../app/modules/ECommerce/_redux/banners/bannersSlice";
import { catalogueSlice } from "../app/modules/ECommerce/_redux/catalogue/catalogueSlice";
import { reportsSlice } from "../app/modules/ECommerce/_redux/reports/reportsSlice";
import { uploadSlice } from "../app/modules/ECommerce/_redux/upload/uploadSlice";

export interface HibraryRootState {
  auth: auth.UserAuthentication;
  expire: expire.ExpireState;
  categories: any;
  catalogue: any;
  channels: any;
  channelsPodcast: any;
  publishers: any;
  companies: any;
  customers: any;
  qrcodes: any;
  ebooks: any;
  podcasts: any;
  videos: any;
  banners: any;
  reports: any;
  upload: any;
}

export const rootReducer = combineReducers({
  auth: auth.reducer, //
  categories: categoriesSlice.reducer,
  catalogue: catalogueSlice.reducer,
  channels: channelsSlice.reducer,
  channelsPodcast: channelsPodcastSlice.reducer,
  publishers: publishersSlice.reducer,
  companies: companiesSlice.reducer,
  customers: customersSlice.reducer,
  qrcodes: qrcodesSlice.reducer,
  ebooks: ebooksSlice.reducer,
  podcasts: podcastsSlice.reducer,
  videos: videosSlice.reducer,
  banners: bannersSlice.reducer,
  reports: reportsSlice.reducer,
  upload: uploadSlice.reducer,
  expire: expire.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
