// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import { Switch } from "antd";
import { Role } from "../../../../../../../interface";

export function StatusColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { entities, onSwitchStatusChange, isPrivate, role }
) {
  const isChecked = () => {
    if (entities) {
      if (entities && Array.isArray(entities) && entities.length > 0) {
        if (entities.filter((el) => el.uid === row.uid).length > 0) {
          return entities.filter((el) => el.uid === row.uid)[0].status === 1
            ? true
            : false;
        }
      }
    } else {
      return cellContent === 1 ? true : false;
    }
  };
  const onSwitchChange = (e) => {
    if (entities) {
      if (entities && Array.isArray(entities) && entities.length > 0) {
        if (entities.filter((el) => el.uid === row.uid).length > 0) {
          onSwitchStatusChange(
            row.uid,
            entities.filter((el) => el.uid === row.uid)[0],
            e
          );
        }
      }
    }
  };
  const textOfStatus = () => {
    return cellContent === 1 ? "เปิดใช้งาน" : "บล็อก";
  };
  return row.status === 0 ||
    row.imageUrl ===
      "https://s3.ap-southeast-1.amazonaws.com/hti.elibrary/public/temporary-cover-image/temp_banner_cover.png" ? (
    "กำลังดำเนินการ"
  ) : role === Role.SuperAdmin && isPrivate ? (
    textOfStatus()
  ) : (
    <Switch
      onChange={onSwitchChange}
      checked={isChecked()}
      checkedChildren="เปิด"
      unCheckedChildren="ปิด"
    />
  );
}
