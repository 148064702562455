import axios, { AxiosResponse } from 'axios';
import {
   areaLimitPayload,
   CreateLimitArea,
   LimitArea,
   EditLimitAreaPayload
} from '../../../interface/request';
const LOCATION_URL = '/admin/location';
export const createLimitArea = async (areaLimitPayload: areaLimitPayload) => {
   let response = await axios.patch<AxiosResponse<CreateLimitArea>>(
      `${LOCATION_URL}/addlocation`,
      areaLimitPayload
   );
   return response.data;
};
export const getLimitArea = async () => {
   let response = await axios.get<AxiosResponse<LimitArea>>(`${LOCATION_URL}`);
   console.log(response.data.data);
   return response.data.data;
};
export const deleteLimitArea = async (name: string) => {
   let response = await axios.delete(`${LOCATION_URL}/deletelocation`, {
      data: { name }
   });
   return response.data;
};
export const editLimitArea = async (editPayload: EditLimitAreaPayload) => {
   let response = await axios.patch(
      `${LOCATION_URL}/editlocation`,
      editPayload
   );
   return response.data;
};
export const trunOn_Or_Off_limitArea = async () => {
   let response = await axios.post(`${LOCATION_URL}/triggerStatus`);
   return response.data;
};
export const enableStaticLocation = async () => {
   let response = await axios.post(`${LOCATION_URL}/addStaticLocation`);
   return response.data;
};
export const disableStaticLocation = async () => {
   let response = await axios.delete(`${LOCATION_URL}/deleteStaticLocation`);
   return response.data;
};
export const enableRequireEmployeeID = async () => {
   let response = await axios.post('/admin/register/require/employeeNumber');
   return response.data;
};
export const disableRequireEmployeeID = async () => {
   let response = await axios.delete('/admin/register/require/employeeNumber');
   return response.data;
};
export const enableRequireDomain = async () => {
   let response = await axios.post('/admin/register/domains/status');
   return response.data;
};
export const disableRequireDomain = async () => {
   let response = await axios.delete('/admin/register/domains/status');
   return response.data;
};

export const triggerButtonRegister = async () => {
   let response = await axios.patch(`/admin/register/public`);
   return response.data;
};

export const getAllSetting = async () => {
   let response = await axios.get(`/admin/setting`);
   return response.data;
};
