/* eslint-disable no-dupe-keys */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../../../_metronic/_partials/controls";
import Spinner from "../../../../../../_metronic/_helpers/spinner";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import * as actions from "../../../_redux/customers/qrcodesActions";
import { useCustomersUIContext } from "../CustomersUIContext";
import {
  GoogleMap,
  LoadScript,
  Autocomplete,
  Marker,
  Circle,
} from "@react-google-maps/api";
import { DatePicker, Switch } from "antd";
import moment from "moment";
import axios from "axios";
import domtoimage from "dom-to-image";
import "antd/dist/antd.css";
import { dateStr, timeStr } from "../../../partials/timeStampToDate";
import {
  disabledDateAfterExpire,
  disabledDateBeforeStart,
  todayDatePickerStart,
  todayDatePickerEnd,
} from "../../../../../utility";
const { RangePicker } = DatePicker;

export default class LoadScriptOnlyIfNeeded extends LoadScript {
  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
    const cleaningUp = true;
    const isBrowser = typeof document !== "undefined"; // require('@react-google-maps/api/src/utils/isbrowser')
    const isAlreadyLoaded =
      window.google &&
      window.google.maps &&
      document.querySelector("body.first-hit-completed"); // AJAX page loading system is adding this class the first time the app is loaded
    if (!isAlreadyLoaded && isBrowser) {
      // @ts-ignore
      if (window.google && !cleaningUp) {
        console.error("google api is already presented");
        return;
      }

      this.isCleaningUp().then(this.injectScript);
    }

    if (isAlreadyLoaded) {
      this.setState({ loaded: true });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
}

const useStyles = makeStyles(() => ({
  input: { margin: "12px 0" },
  qrCodePeriod: {
    width: "100%",
    display: "flex",
    gap: 8,
  },
}));

// Validation schema
const qrCodeSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "อย่างน้อย 1 ตัวอักษร")
    .max(200, "ไม่เกิน 200 ตัวอักษร")
    .required("กรุณากรอกชื่อ QR Code"),
  latitude: Yup.number()
    .typeError("กรุณาใช้ตัวเลข")
    .required("กรุณากรอกค่า Latitude หรือปักหมุด"),
  longitude: Yup.number()
    .typeError("กรุณาใช้ตัวเลข")
    .required("กรุณากรอกค่า Longitude หรือปักหมุด"),
});
const qrCodeEditingSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "อย่างน้อย 1 ตัวอักษร")
    .max(200, "ไม่เกิน 200 ตัวอักษร")
    .required("กรุณากรอกชื่อ QR Code"),
  latitude: Yup.number().typeError("กรุณาใช้ตัวเลข"),
  longitude: Yup.number().typeError("กรุณาใช้ตัวเลข"),
});

const libraries = ["geometry", "drawing", "places"];

export function QRCodeGeneratorForm({
  saveQRCodeData,
  qrCodeData,
  actionsLoading,
  onHide,
  id,
  queryParams,
  isForDownload,
  isFull,
  clientStartDate,
  clientExpireDate,
}) {
  const [loadedClientDate, setLoadedClientDate] = useState(false);
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
    };
  }, [customersUIContext]);
  const dispatch = useDispatch();
  const zoom = 16;
  const [center, setCenter] = useState({
    lat: 13.8118153,
    lng: 100.5638696,
  });

  const styles = useStyles();
  const [autocomplete, setAutocomplete] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [isMarkerShown, setIsMarkerShown] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [maxUsers, setMaxUsers] = useState("-");
  const [sessionTime, setSessionTime] = useState("-");
  const [licenses, setLicenses] = useState("-");
  const [radius, setRadius] = useState("-");

  const [latitude, setLatitude] = useState(undefined);
  const [longitude, setLongitude] = useState(undefined);
  const { authState } = useSelector(
    ({ auth }) => ({
      authState: auth,
    }),
    shallowEqual
  );
  const [maxUsersList, setMaxUsersList] = useState([
    <option key="none" value={"-"} disabled>
      กรุณาเลือกจำนวน
    </option>,
  ]);
  const [sessionTimeList, setSessionTimeList] = useState([
    <option key="none" value={"-"} disabled>
      กรุณาเลือกระยะเวลา
    </option>,
  ]);
  const [licensesList, setLicensesList] = useState([
    <option key="none" value={"-"} disabled>
      กรุณาเลือกจำนวน
    </option>,
  ]);
  const [radiusList, setRadiusList] = useState([
    <option key="none" value={"-"} disabled>
      กรุณาเลือกรัศมีที่ครอบคลุม
    </option>,
  ]);
  const [isClickedRangePicker, setIsClickedRangePicker] = useState(false);
  const [QRCodeImage, setQRCodeImage] = useState(null);
  const [QRCodeName, setQRCodeName] = useState("");
  const [isWaitingForQRCode, setIsWaitingForQRCode] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [rawMemberDurationList, setRawMemberDurationList] = useState([]);
  const [rawRadiusList, setRawRadiusList] = useState([]);
  const [isMapped, setIsMapped] = useState(true);

  const [sessionTimeList_anonymous, setSessionTimeList_anonymous] = useState([
    <option key="none" value={"-"} disabled>
      กรุณาเลือกระยะเวลา
    </option>,
  ]);

  useEffect(() => {
    const fetchDropdownData = () => {
      axios.get(`/admin/qrcode/create`).then((res) => {
        if (res.data && res.data.data) {
          const raw = res.data.data;
          if (raw.userLimit) {
            setMaxUsersList([
              <option key="none" value={"-"} disabled>
                กรุณาเลือกจำนวน
              </option>,
              ...raw.userLimit.map((x, index) => (
                <option key={index} value={x.value}>
                  {x.label}
                </option>
              )),
            ]);
          }
          if (raw.memberDuration) {
            if (isFull) {
              setRawMemberDurationList(raw.memberDuration);
            }
            setSessionTimeList([
              <option key="none" value={"-"} disabled>
                กรุณาเลือกระยะเวลา
              </option>,
              ...raw.memberDuration.map((x, index) => (
                <option key={index} value={x.value}>
                  {x.label}
                </option>
              )),
            ]);
            setSessionTimeList_anonymous([
              <option key="none" value={"-"} disabled>
                กรุณาเลือกระยะเวลา
              </option>,
              ...raw.memberDuration.map((x, index) => {
                if (x.value <= 604800) {
                  return (
                    <option key={index} value={x.value}>
                      {x.label}
                    </option>
                  );
                }
              }),
            ]);
          }
          if (raw.rentalLimit) {
            setLicensesList([
              <option key="none" value={"-"} disabled>
                กรุณาเลือกจำนวน
              </option>,
              ...raw.rentalLimit.map((x, index) => (
                <option key={index} value={x.value}>
                  {x.label}
                </option>
              )),
            ]);
          }
          if (raw.distance) {
            if (isFull) {
              setRawRadiusList(raw.distance);
            }
            setRadiusList([
              <option key="none" value={"-"} disabled>
                กรุณาเลือกรัศมีที่ครอบคลุม
              </option>,
              raw.distance.map((x, index) => (
                <option key={index} value={x.value}>
                  {x.label}
                </option>
              )),
            ]);
          }
        }
      });
    };
    if ((!isForDownload && !isFull) || isFull) {
      fetchDropdownData();
    }
  }, [isForDownload, isFull]);

  const onDurationChange = (dates, dateStrings) => {
    if (dates) {
      setIsClickedRangePicker(true);
      setStartDate(dates[0].unix());
      setEndDate(dates[1].unix());
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const onAnonymousChange = (e) => {
    setIsAnonymous(e);
  };

  const onMapChange = (e) => {
    setIsMapped(e);
  };

  const circleOptions = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1,
  };

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = (setFieldValue) => {
    if (autocomplete !== null && autocomplete.getPlace().geometry) {
      const latLng = autocomplete.getPlace().geometry.location;
      setCenter(latLng);
      setMarkerPosition(latLng);
      setIsMarkerShown(true);
      const latLngString = latLng
        .toString()
        .replace(/[()]/g, "")
        .split(", ");
      setFieldValue("latitude", parseFloat(latLngString[0]));
      setFieldValue("longitude", parseFloat(latLngString[1]));
      setLatitude(parseFloat(latLngString[0]));
      setLongitude(parseFloat(latLngString[1]));
    } else {
      // console.log("Autocomplete is not loaded yet!");
    }
  };

  const handleLatitudeChange = (e) => {
    if (e.target.value && window.google) {
      setLatitude(parseFloat(e.target.value));
      var newPos = new window.google.maps.LatLng(
        parseFloat(e.target.value),
        longitude
      );
      setCenter({ lat: parseFloat(e.target.value), lng: longitude });
      setMarkerPosition(newPos);
      setIsMarkerShown(true);
    } else {
      setLatitude(null);
      setMarkerPosition(null);
      setIsMarkerShown(false);
    }
  };

  const handleLongitudeChange = (e) => {
    if (e.target.value && window.google) {
      setLongitude(parseFloat(e.target.value));
      var newPos = new window.google.maps.LatLng(
        latitude,
        parseFloat(e.target.value)
      );
      setCenter({ lat: latitude, lng: parseFloat(e.target.value) });
      setMarkerPosition(newPos);
      setIsMarkerShown(true);
    } else {
      setLongitude(null);
      setMarkerPosition(null);
      setIsMarkerShown(false);
    }
  };

  const handleMaxUsersChange = (e) => {
    setMaxUsers(parseInt(e.target.value, 10));
  };

  const handleSessionTimeChange = (e) => {
    setSessionTime(parseInt(e.target.value, 10));
  };

  const handleLicensesChange = (e) => {
    setLicenses(parseInt(e.target.value, 10));
  };

  const handleRadiusChange = (e) => {
    setRadius(parseInt(e.target.value, 10));
  };

  useEffect(() => {
    if (
      qrCodeData.status &&
      window.google &&
      typeof isForDownload === "undefined"
    ) {
      const qr = qrCodeData.data;
      setIsAnonymous(qr.anonymousLogin);
      setQRCodeImage(qr.base64QRCode);
      setStartDate(qr.startDate);
      setEndDate(qr.expireDate);
      setMaxUsers(qr.userLimit);
      setSessionTime(qr.memberDuration);
      setLicenses(qr.rentalLimit);
      setRadius(qr.geoLocation.distance);
      let lat = qr.geoLocation.latitude;
      let lng = qr.geoLocation.longitude;
      setLatitude(lat);
      setLongitude(lng);
      let newPos = new window.google.maps.LatLng(lat, lng);
      setCenter({ lat: lat, lng: lng });
      setMarkerPosition(newPos);
      setIsMarkerShown(true);
      setQRCodeName(qr.name);
      if (!isForDownload) {
        setIsEditing(true);
      }
    } else if (qrCodeData.status && isForDownload) {
      const qr = qrCodeData.data;
      setQRCodeImage(qr.base64QRCode);
      setQRCodeName(qr.name);
      setStartDate(qr.startDate);
      setEndDate(qr.expireDate);
      setIsAnonymous(qr.anonymousLogin);
      setMaxUsers(qr.userLimit);
      if (isFull) {
        if (
          Array.isArray(
            rawMemberDurationList.filter((e) => e.value === qr.memberDuration)
          ) &&
          rawMemberDurationList.filter((e) => e.value === qr.memberDuration)
            .length
        ) {
          setSessionTime(
            rawMemberDurationList.filter(
              (e) => e.value === qr.memberDuration
            )[0].label
          );
        }
        // else {
        //   setSessionTime(qr.memberDuration + " วินาที");
        // }
        if (
          Array.isArray(
            rawRadiusList.filter((e) => e.value === qr.geoLocation.distance)
          ) &&
          rawRadiusList.filter((e) => e.value === qr.geoLocation.distance)
            .length
        ) {
          setRadius(
            rawRadiusList.filter((e) => e.value === qr.geoLocation.distance)[0]
              .label
          );
        }
        // else {
        //   setRadius(qr.geoLocation.distance + " เมตร");
        // }
      }
      setLicenses(qr.rentalLimit);
      let lat = qr.geoLocation.latitude;
      let lng = qr.geoLocation.longitude;
      setLatitude(lat);
      setLongitude(lng);
    }
  }, [qrCodeData, rawMemberDurationList, rawRadiusList]);

  const onMapClick = (e, setFieldValue) => {
    setMarkerPosition(e.latLng);
    setIsMarkerShown(true);
    const latLngString = e.latLng
      .toString()
      .replace(/[()]/g, "")
      .split(", ");
    setFieldValue("latitude", parseFloat(latLngString[0]));
    setFieldValue("longitude", parseFloat(latLngString[1]));
    setLatitude(parseFloat(latLngString[0]));
    setLongitude(parseFloat(latLngString[1]));
  };

  const downloadURI = (uri, name) => {
    var link = document.createElement("a");
    link.setAttribute("download", name);
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
    setIsWaitingForQRCode(false);
  };

  const printDiv = async (div) => {
    setIsWaitingForQRCode(true);
    domtoimage
      .toPng(div, {
        style: {
          marginTop: "0px",
          transform: "scale(1)",
          transformOrigin: "center",
        },
      })
      .then((dataUrl) => {
        downloadURI(dataUrl, QRCodeName + ".png");
      })
      .catch((error) => {
        console.error("oops, something went wrong!", error);
      });
  };

  useEffect(() => {
    if (clientStartDate && clientExpireDate && !loadedClientDate) {
      setStartDate(moment().unix());
      setEndDate(clientExpireDate);
      setLoadedClientDate(true);
    }
  }, [clientStartDate, clientExpireDate, loadedClientDate]);

  const makepw = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  return isForDownload || (QRCodeImage && !isEditing) ? (
    isFull ? (
      <>
        <div
          style={{
            textAlign: "right",
            position: "absolute",
            top: "16px",
            right: "16px",
          }}
        >
          <button
            className="closeButton"
            onClick={() => {
              onHide();
            }}
          >
            <img src="/media/books/close-button.svg" alt="" />
          </button>
        </div>
        <p className="text-text-head">รายละเอียด QR Code</p>
        <Modal.Body style={{ margin: "auto 27px" }}>
          <div style={{ width: "675px", margin: "auto" }}>
            <div className="row">
              <div className={`col-lg-6 ${styles.input}`}>
                <p style={{ marginBottom: "0px" }}>ชื่อ QR Code</p>
                <p className="text-text-dataLabel">{QRCodeName}</p>
              </div>
              <div className={`col-lg-6 ${styles.input}`}>
                <p style={{ marginBottom: "0px" }}>
                  วันที่เริ่มใช้งาน - วันที่หมดอายุ
                </p>
                <p className="text-text-dataLabel">
                  {`${dateStr(startDate)} ${timeStr(startDate)}` +
                    " - " +
                    `${dateStr(endDate)} ${timeStr(endDate)}`}
                </p>
              </div>
            </div>
            {isAnonymous ? (
              <div className="row">
                <div className={`col-lg-12 ${styles.input}`}>
                  <p style={{ margin: "12px 0 0" }}>
                    ลงชื่อเข้าใช้งานแบบต้องใส่ข้อมูล (anonymous)
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <div className={`col-lg-6 ${styles.input}`}>
                <p style={{ marginBottom: "0px" }}>จำนวนผู้ใช้งานสูงสุด</p>
                <p className="text-text-dataLabel">{maxUsers} คน</p>
              </div>
              <div className={`col-lg-6 ${styles.input}`}>
                <p style={{ marginBottom: "0px" }}>ระยะเวลาของบัญชีที่ใช้งาน</p>
                <p className="text-text-dataLabel">
                  {sessionTime === "-" ? (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-success" />
                    </div>
                  ) : (
                    sessionTime
                  )}
                </p>
              </div>
            </div>
            <div className="row">
              <div className={`col-lg-6 ${styles.input}`}>
                <p style={{ marginBottom: "0px" }}>จำนวนการยืมสูงสุดต่อบัญชี</p>
                <p className="text-text-dataLabel">{licenses}</p>
              </div>
              <div className={`col-lg-6 ${styles.input}`}>
                <p style={{ marginBottom: "0px" }}>
                  รัศมีที่ครอบคลุม (พื้นที่สแกน QR Code)
                </p>
                <p className="text-text-dataLabel">
                  {radius === "-" ? (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-success" />
                    </div>
                  ) : (
                    radius
                  )}
                </p>
              </div>
            </div>
            <div className="row">
              <div className={`col-lg-6 ${styles.input}`}>
                <p style={{ marginBottom: "0px" }}>Latitude</p>
                <p className="text-text-dataLabel">{latitude}</p>
              </div>
              <div className={`col-lg-6 ${styles.input}`}>
                <p style={{ marginBottom: "0px" }}>Longitude</p>
                <p className="text-text-dataLabel">{longitude}</p>
              </div>
            </div>
          </div>
          <div className="qrcodeImageDiv_mini" id="qrImageDiv">
            <div className="hytextsLogoDiv">
              123
              <img
                src={
                  authState.isCuDomain
                    ? "/media/auth/cu-logo.svg"
                    : "/media/auth/login-logo.svg"
                }
              />
            </div>
            <img src={QRCodeImage} className="qrcodeImage" />
            <p
              className="qrcodeTextBottom"
              style={{ marginBottom: "6px", marginTop: "-6px" }}
            >
              สแกน QR Code เพื่อเข้าใช้งาน E-Library
            </p>
            <p className="text-text-qrcodeText">{QRCodeName}</p>
          </div>
          <div className="divButton ">
            <button
              className="acceptButton "
              onClick={() => {
                printDiv(document.getElementById("qrImageDiv"));
              }}
            >
              {isWaitingForQRCode ? "กรุณารอสักครู่" : "ดาวน์โหลด QR Code"}
            </button>
          </div>
        </Modal.Body>
      </>
    ) : (
      <>
        <p className="text-text-head">ดาวน์โหลด QR Code</p>
        <Modal.Body style={{ margin: "auto 27px" }}>
          <div className="qrcodeImageDiv " id="qrImageDiv">
            <div className="hytextsLogoDiv">
              {authState.isCuDomain ? (
                <img src="/media/auth/cu-logo.svg" />
              ) : (
                <img src="/media/auth/login-logo.svg" />
              )}
            </div>
            <img className="qrcodeImage" src={QRCodeImage} />
            <p
              className="qrcodeTextBottom"
              style={{ marginBottom: "6px", marginTop: "-6px" }}
            >
              สแกน QR Code เพื่อเข้าใช้งาน E-Library
            </p>
            <p className="text-text-qrcodeText">{QRCodeName}</p>
          </div>
          <div className="divButton ">
            <button className="denyButton " onClick={onHide}>
              ยกเลิก
            </button>
            <button
              className="acceptButton "
              onClick={() => {
                printDiv(document.getElementById("qrImageDiv"));
              }}
            >
              {isWaitingForQRCode ? "กรุณารอสักครู่" : "ดาวน์โหลด QR Code"}
            </button>
          </div>
        </Modal.Body>
      </>
    )
  ) : (
    <>
      <p className="text-text-head">{isEditing ? "แก้ไข" : "สร้าง"} QR Code</p>
      <Formik
        enableReinitialize={true}
        initialValues={qrCodeData.status ? qrCodeData.data : qrCodeData}
        validationSchema={isEditing ? qrCodeEditingSchema : qrCodeSchema}
        onSubmit={(values) => {
          if (isEditing && startDate && endDate) {
            axios
              .patch(
                `/admin/qrcode/${id}`,
                Object.assign({}, values, {
                  startDate: startDate,
                  expireDate: endDate,
                  userLimit: maxUsers,
                  rentalLimit: licenses,
                  memberDuration: sessionTime,
                  geoLocation: {
                    latitude: latitude,
                    longitude: longitude,
                    distance: radius,
                  },
                  isAnonymous: undefined,
                  anomymousLogin: undefined,
                  base64QRCode: undefined,
                  latitude: undefined,
                  longitude: undefined,
                  qrcodeType: undefined,
                  qrcodeUID: undefined,
                  registerDate: undefined,
                })
              )
              .then((res) => {
                if (res.data && res.data.status) {
                  const c = customersUIProps.queryParams;
                  onHide();
                  dispatch(actions.fetchQrcodes(c));
                }
              });
          } else if (startDate && endDate) {
            axios
              .post(
                `/admin/qrcode`,
                Object.assign({}, values, {
                  startDate: startDate,
                  expireDate: endDate,
                  userLimit: maxUsers,
                  rentalLimit: licenses,
                  memberDuration: sessionTime,
                  location: {
                    latitude: latitude,
                    longitude: longitude,
                    distance: radius,
                  },
                  isAnonymous: isAnonymous,
                })
              )
              .then((res) => {
                if (res.data && res.data.data) {
                  setQRCodeImage(res.data.data.QRCode);
                  setQRCodeName(values.name);
                  const c = customersUIProps.queryParams;
                  dispatch(actions.fetchQrcodes(c));
                }
              });
          }
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => {
          return (
            <>
              <Modal.Body>
                {actionsLoading && <Spinner forQrCode={true} />}
                <Form className="form form-label-right">
                  <div style={{ width: "752px", margin: "auto" }}>
                    <div className="row">
                      <div className={`col-lg-6 ${styles.input}`}>
                        <Field
                          name="name"
                          component={Input}
                          placeholder="กรุณากรอกชื่อ QR Code"
                          label="ชื่อ QR Code*"
                          className="input-responsive"
                        />
                      </div>
                      <div className={`col-lg-6 ${styles.input}`}>
                        <p style={{ marginBottom: "0.5rem" }}>
                          วันที่เริ่มใช้งาน - วันที่หมดอายุ*
                        </p>
                        <div className={styles.qrCodePeriod}>
                          <DatePicker
                            format={"DD-MM-YYYY HH:mm:ss"}
                            renderExtraFooter={() =>
                              todayDatePickerStart(setStartDate)
                            }
                            showTime={{
                              defaultValue: moment("00:00:00", "HH:mm:ss"),
                            }}
                            onChange={(dates) => {
                              if (dates !== null) setStartDate(dates.unix());
                              else setStartDate(null);
                            }}
                            value={
                              startDate !== null ? moment.unix(startDate) : null
                            }
                            placeholder="วันที่เริ่มใช้งาน"
                            disabledDate={(current) => {
                              if (endDate) {
                                return disabledDateAfterExpire(
                                  current,
                                  endDate
                                );
                              } else {
                                return false;
                              }
                            }}
                          />
                          <DatePicker
                            format={"DD-MM-YYYY HH:mm:ss"}
                            renderExtraFooter={() =>
                              todayDatePickerEnd(setEndDate)
                            }
                            showTime={{
                              defaultValue: moment("23:59:59", "HH:mm:ss"),
                            }}
                            onChange={(dates) => {
                              if (dates !== null) setEndDate(dates.unix());
                              else setEndDate(null);
                            }}
                            placeholder="วันที่หมดอายุ"
                            value={
                              endDate !== null ? moment.unix(endDate) : null
                            }
                            disabledDate={(current) => {
                              if (endDate) {
                                return disabledDateBeforeStart(
                                  current,
                                  startDate
                                );
                              } else {
                                return false;
                              }
                            }}
                          />
                        </div>
                        {/* <RangePicker
                          defaultValue={
                            isEditing
                              ? [moment.unix(startDate), moment.unix(endDate)]
                              : null
                          }
                          defaultPickerValue={
                            isEditing
                              ? [moment.unix(startDate), moment.unix(endDate)]
                              : null
                          }
                          value={
                            startDate && endDate
                              ? [moment.unix(startDate), moment.unix(endDate)]
                              : null
                          }
                          ranges={{
                            วันนี้: [
                              moment().startOf("day"),
                              moment().endOf("day"),
                            ],
                            เดือนนี้: [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                          }}
                          showTime={{
                            defaultValue: [moment(), moment().add(1, "hour")],
                          }}
                          format="DD/MM/YYYY HH:mm:ss"
                          onChange={onDurationChange}
                          placeholder={["วันที่เริ่มใช้งาน", "วันที่หมดอายุ"]}
                          disabledDate={(current) => {
                            return (
                              moment.unix(clientStartDate) > current ||
                              current > moment.unix(clientExpireDate)
                            );
                          }}
                          className={
                            (!startDate || !endDate) && isClickedRangePicker
                              ? "form-control is-invalid"
                              : ""
                          }
                          style={
                            (!startDate || !endDate) && isClickedRangePicker
                              ? {
                                  display: "inline-flex",
                                  height: "38px",
                                }
                              : {
                                  display: "inline-flex",
                                  height: "38px",
                                  border: "1px solid #E5EAEE",
                                }
                          }
                        /> */}
                        {isClickedRangePicker && (
                          <div className="invalid-feedback">
                            กรุณากรอกวันที่เริ่มใช้งานและวันที่หมดอายุ
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`col-lg-1 ${styles.input}`}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Switch
                          onChange={onAnonymousChange}
                          checked={isAnonymous}
                          checkedChildren="เปิด"
                          unCheckedChildren="ปิด"
                          disabled={isEditing}
                        />
                      </div>
                      <div
                        className={`col-lg-11 ${styles.input}`}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span style={{ marginLeft: "0px" }}>
                          ลงชื่อเข้าใช้งานแบบไม่ต้องใส่ข้อมูล (anonymous)
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-lg-4 ${styles.input}`}>
                        <Select
                          name="maxUsers"
                          withFeedbackLabel={false}
                          label="จำนวนผู้ใช้งานสูงสุด"
                          onChange={handleMaxUsersChange}
                          value={maxUsers}
                          className="input-responsive"
                        >
                          {maxUsersList}
                        </Select>
                      </div>
                      <div className={`col-lg-4 ${styles.input}`}>
                        <Select
                          name="sessionTime"
                          withFeedbackLabel={false}
                          label="ระยะเวลาของบัญชีที่ใช้งาน"
                          onChange={handleSessionTimeChange}
                          value={sessionTime}
                          className="input-responsive"
                        >
                          {isAnonymous
                            ? sessionTimeList_anonymous
                            : sessionTimeList}
                        </Select>
                      </div>
                      <div className={`col-lg-4 ${styles.input}`}>
                        <Select
                          name="licenses"
                          withFeedbackLabel={false}
                          label="จำนวนการยืมสูงสุดต่อบัญชี"
                          onChange={handleLicensesChange}
                          value={licenses}
                          className="input-responsive"
                        >
                          {licensesList}
                        </Select>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div
                        className={`col-lg-1 ${styles.input}`}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Switch
                          onChange={onMapChange}
                          checked={isMapped}
                          checkedChildren="เปิด"
                          unCheckedChildren="ปิด"
                        />
                      </div>
                      <div
                        className={`col-lg-11 ${styles.input}`}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span style={{ marginLeft: "0px" }}>ปักหมุดพื้นที่การสแกน QR Code</span>
                      </div>
                    </div> */}
                    {isMapped ? (
                      <div className="row" style={{ minHeight: "108px" }}>
                        <div className={`col-lg-4 ${styles.input}`}>
                          <Select
                            name="radius"
                            withFeedbackLabel={false}
                            label="รัศมีที่ครอบคลุม (พื้นที่สแกน QR Code)"
                            onChange={handleRadiusChange}
                            value={radius}
                            className="input-responsive"
                          >
                            {radiusList}
                          </Select>
                        </div>
                        <div className={`col-lg-4 ${styles.input}`}>
                          <Field
                            name="latitude"
                            component={Input}
                            placeholder="0.000000"
                            label="Latitude"
                            onKeyUp={handleLatitudeChange}
                            type="number"
                            step="any"
                            className="input-responsive"
                          />
                        </div>
                        <div className={`col-lg-4 ${styles.input}`}>
                          <Field
                            name="longitude"
                            component={Input}
                            placeholder="0.000000"
                            label="Longitude"
                            onKeyUp={handleLongitudeChange}
                            type="number"
                            step="any"
                            className="input-responsive"
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <div className={`col-lg-12`} style={{ textAlign: "center" }}>
                </div> */}
                  {isMapped ? (
                    <LoadScriptOnlyIfNeeded
                      googleMapsApiKey="AIzaSyCFiRHTBRKXn9IQijX6sUcinAOT1ei-mlU"
                      libraries={libraries}
                    >
                      <GoogleMap
                        mapContainerStyle={{
                          width: "100%",
                          height: "400px",
                          borderRadius: "5px",
                          marginTop: "10px",
                        }}
                        zoom={zoom}
                        center={center}
                        options={{
                          streetViewControl: false,
                          mapTypeControl: false,
                          fullscreenControl: false,
                        }}
                        onClick={(e) => {
                          onMapClick(e, setFieldValue);
                        }}
                      >
                        {isMarkerShown && <Marker position={markerPosition} />}
                        <Autocomplete
                          onLoad={onLoad}
                          onPlaceChanged={() => {
                            onPlaceChanged(setFieldValue);
                          }}
                        >
                          <input
                            type="text"
                            placeholder="ค้นหาสถานที่หรือปักหมุด"
                            style={{
                              boxSizing: `border-box`,
                              border: `1px solid transparent`,
                              width: `300px`,
                              height: `40px`,
                              padding: `0 12px`,
                              borderRadius: `20px`,
                              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                              fontSize: `15px`,
                              outline: `none`,
                              textOverflow: `ellipses`,
                              position: "absolute",
                              margin: "8px",
                            }}
                          />
                        </Autocomplete>
                        <Circle
                          center={markerPosition}
                          radius={radius}
                          options={circleOptions}
                        />
                      </GoogleMap>
                    </LoadScriptOnlyIfNeeded>
                  ) : (
                    ""
                  )}
                  {/* <MapWithASearchBox /> */}
                </Form>
                <div className="divButton ">
                  <button className="denyButton" type="button" onClick={onHide}>
                    ยกเลิก
                  </button>
                  <button
                    className="acceptButton"
                    type="submit"
                    disabled={
                      maxUsers === "-" ||
                      sessionTime === "-" ||
                      licenses === "-" ||
                      radius === "-"
                    }
                    onClick={() => {
                      handleSubmit();
                      setIsClickedRangePicker(true);
                    }}
                  >
                    {isEditing ? "บันทึก" : "ตกลง"}
                  </button>
                </div>
              </Modal.Body>
            </>
          );
        }}
      </Formik>
    </>
  );
}
