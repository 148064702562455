import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./UploadUIHelpers";

const UploadUIContext = createContext();

export function useUploadUIContext() {
  return useContext(UploadUIContext);
}

export const UploadUIConsumer = UploadUIContext.Consumer;

export function UploadUIProvider({ uploadUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initUploadValue = {
    id: undefined,
    name: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initUploadValue,
    openFileInformationDialog: uploadUIEvents.openFileInformationDialog,
    openCoverInformationDialog: uploadUIEvents.openCoverInformationDialog,
    newPublisherButtonClick: uploadUIEvents.newPublisherButtonClick,
    newAuthorButtonClick: uploadUIEvents.newAuthorButtonClick,
    newCategoryButtonClick: uploadUIEvents.newCategoryButtonClick,
    newSubCategoryButtonClick: uploadUIEvents.newSubCategoryButtonClick,
  };

  return <UploadUIContext.Provider value={value}>{children}</UploadUIContext.Provider>;
}
