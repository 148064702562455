import { useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import ExpireTable from "./ExpireTable";
import { useSelector, shallowEqual } from "react-redux";
import { expireActions } from "../../_redux/expire/expireRedux";
import { Role } from "../../../../../interface";
import { confirmModal } from "../../../../utilities/utilities";

const ExpirePage = () => {
  const dispatch = useDispatch();
  const { authState } = useSelector(
    ({ auth }) => ({
      authState: auth,
    }),
    shallowEqual
  );
  return (
    <>
      <Card className="cardStyle expire-page">
        <CardHeader
          title={
            <div className="card-label">
              <div>อีบุ๊กหมดอายุ</div>
            </div>
          }
        >
          <CardHeaderToolbar>
            {authState.role === Role.SuperAdmin ? (
              <>
                <button
                  type="button"
                  className="button-border "
                  onClick={() => {
                    confirmModal(
                      () => expireActions.removeContentExpire(dispatch, true),
                      "คุณต้องการลบหนังสือหมดอายุทั้งหมดหรือไม่"
                    );
                  }}
                >
                  <span>ลบทั้งหมด</span>
                </button>
              </>
            ) : null}
          </CardHeaderToolbar>
        </CardHeader>

        <CardBody className="cardBodyStyle">
          <>
            <ExpireTable />
          </>
        </CardBody>
      </Card>
    </>
  );
};

export default ExpirePage;
