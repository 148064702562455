import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/customers/customersActions";
import { useCustomersUIContext } from "../CustomersUIContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
     body: {
          textAlign: "center",
     },
}));

export function UnblockAllBannedDialog({ id, show, onHide }) {
     // Customers UI Context
     const customersUIContext = useCustomersUIContext();
     const customersUIProps = useMemo(() => {
          return {
               setIds: customersUIContext.setIds,
               queryParams: customersUIContext.queryParams,
          };
     }, [customersUIContext]);

     // Customers Redux state
     const dispatch = useDispatch();
     const { isLoading } = useSelector((state) => ({ isLoading: state.customers.actionsLoading }), shallowEqual);

     useEffect(() => {}, [isLoading, dispatch]);

     const unblockAllBanned = () => {
          // dispatch(actions.unblockAllBanned()).then(() => {
          //   dispatch(actions.fetchCustomers(customersUIProps.queryParams));
          //   customersUIProps.setIds([]);
          onHide();
          // });
     };

     const classes = useStyles();

     return (
          <Modal
               backdrop="static"
               show={show}
               onHide={onHide}
               aria-labelledby="example-modal-sizes-title-lg"
               style={{ margin: "3% auto" }}
          >
               {isLoading && <ModalProgressBar />}
               <Modal.Body className={classes.body}>
                    <img src="/media/members/image-modal.svg" alt="" />
                    {!isLoading ? (
                         <>
                              <div className="text-text-red">ยืนยันการปลดบล็อกทั้งหมด</div>
                              <div className="text-text-gray">ต้องการปลดบล็อกสมาชิกทั้งหมดหรือไม่?</div>
                              <div className="mt-3">
                                   <button type="button" onClick={onHide} className="denyButton">
                                        ยกเลิก
                                   </button>
                                   <button type="button" onClick={unblockAllBanned} className="acceptButton">
                                        ยืนยัน
                                   </button>
                              </div>
                         </>
                    ) : (
                         <div className="text-text-gray" style={{ marginBottom: "36px" }}>
                              กำลังปลดบล็อกสมาชิกทั้งหมด...
                         </div>
                    )}
               </Modal.Body>
          </Modal>
     );
}
