import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { CompaniesLoadingDialog } from './companies-loading-dialog/CompaniesLoadingDialog';
import { CustomerEditDialog } from '../customers/customer-edit-dialog/CustomerEditDialog';
import { CompanyEditDialog } from './company-edit-dialog/CompanyEditDialog';
import { ColorEditDialog } from './color-edit-dialog/ColorEditDialog';
import { CompanyViewDialog } from './company-view-dialog/CompanyViewDialog';
import { CompanyDeleteDialog } from './company-delete-dialog/CompanyDeleteDialog';
import { CompanyBlockDialog } from './company-block-dialog/CompanyBlockDialog';
import { CompanyUnblockDialog } from './company-unblock-dialog/CompanyUnblockDialog';
import { CompaniesDeleteDialog } from './companies-delete-dialog/CompaniesDeleteDialog';
import { CompaniesFetchDialog } from './companies-fetch-dialog/CompaniesFetchDialog';
import { CompaniesUpdateStateDialog } from './companies-update-status-dialog/CompaniesUpdateStateDialog';
import { CompanyClearStoreDialog } from './companies-clear-store-dialog/CompaniesClearStoreDialog';
import CompaniesExtendPeriodDialog from './companiew-extend-period/CompaniesExtendPeriodDialog';
import { CompaniesUIProvider } from './CompaniesUIContext';
import { CompaniesCard } from './CompaniesCard';
import { useDispatch } from 'react-redux';
import * as auth from '../../../Auth/_redux/authRedux';
import { viewAs } from '../../../Auth/_redux/authCrud';
import queryString from 'query-string';
const { actions } = auth;

export function CompaniesPage({ history, location }) {
   const dispatch = useDispatch();
   const [id, setId] = useState(null);
   const [name, setName] = useState(null);
   const [isDelete, setDelete] = useState(false);
   const [isBlock, setBlock] = useState(false);
   const [isUnblock, setUnblock] = useState(false);
   const [isEdit, setEdit] = useState(false);
   const [isEditColor, setEditColor] = useState(false);
   const [isView, setView] = useState(false);
   const [isNewMember, setNewMember] = useState(false);
   const [isClear, setClear] = useState(false);
   const [isExtentPeriod, setIsExtentPeriod] = useState(false);
   const [clearType, setClearType] = useState(null);

   useEffect(() => {
      logoutViewAs();
   }, []);

   useEffect(() => {
      const parsed = queryString.parse(location.search);
      if (parsed.newMember) {
         setNewMember(true);
      }
      if (parsed.extentPeriod && parsed.extentPeriod === 'true') {
         setIsExtentPeriod(true);
      }
      if (parsed.id) {
         setId(parsed.id);
      }
      if (parsed.name) {
         setName(parsed.name);
      }
      if (parsed.clearType) {
         setClearType(parsed.clearType);
      }
      if (parsed.view) {
         setView(true);
      }
      if (parsed.edit) {
         setEdit(true);
      }
      if (parsed.editColor) {
         setEditColor(true);
      }
      if (parsed.delete) {
         setDelete(true);
      }
      if (parsed.block) {
         setBlock(true);
      }
      if (parsed.unblock) {
         setUnblock(true);
      }
      if (parsed.clear) {
         setClear(true);
      }
   }, [location.search]);

   const logoutViewAs = () => {
      dispatch(actions.stopViewAs());
   };

   const companiesUIEvents = {
      newCustomerButtonClick: () => {
         history.push('/companies?newMember=true');
      },
      openEditCompanyDialog: id => {
         history.push(`/companies?id=${id}&edit=true`);
      },
      openEditColorDialog: id => {
         history.push(`/companies?id=${id}&editColor=true`);
      },
      openViewAsPage: id => {
         viewAs(id)
            .then(res => {
               if (res.data.status && res.data.data) {
                  dispatch(actions.viewAs(res.data.data.userToken));
                  history.push(`/dashboard`);
               }
            })
            .catch(() => {});
      },
      openViewCompanyDialog: id => {
         history.push(`/companies?id=${id}&view=true`);
      },
      openDeleteCompanyDialog: (name, id) => {
         history.push(
            `/companies?name=${encodeURIComponent(name)}&id=${id}&delete=true`
         );
      },
      openCompanyClearStoreDialog: (name, id) => {
         history.push(
            `/companies?name=${encodeURIComponent(
               name
            )}&id=${id}&clear=true&clearType=store`
         );
      },
      openCompanyClearMember: (name, id) => {
         history.push(
            `/companies?name=${encodeURIComponent(
               name
            )}&id=${id}&clear=true&clearType=member`
         );
      },
      openBlockCompanyDialog: (name, id) => {
         history.push(
            `/companies?name=${encodeURIComponent(name)}&id=${id}&block=true`
         );
      },
      openUnblockCompanyDialog: (name, id) => {
         history.push(
            `/companies?name=${encodeURIComponent(name)}&id=${id}&unblock=true`
         );
      },
      openDeleteCompaniesDialog: () => {
         history.push(`/companies/deleteCompanies`);
      },
      openFetchCompaniesDialog: () => {
         history.push(`/companies/fetch`);
      },
      openUpdateCompaniesStatusDialog: () => {
         history.push('/companies/updateStatus');
      },
      openExtendPeriodDialog: (id, name) => {
         history.push(
            `/companies?name=${encodeURIComponent(
               name
            )}&id=${id}&extentPeriod=true`
         );
      }
   };

   return (
      <CompaniesUIProvider companiesUIEvents={companiesUIEvents}>
         <CompaniesLoadingDialog />
         {isNewMember ? (
            <CustomerEditDialog
               show={true}
               onHide={() => {
                  history.push('/companies');
                  setNewMember(false);
               }}
            />
         ) : (
            ''
         )}
         {isEditColor ? (
            <ColorEditDialog
               show={true}
               id={id}
               isPrivate={0}
               onHide={() => {
                  history.push('/companies');
                  setEditColor(false);
               }}
            />
         ) : (
            ''
         )}
         {isEdit ? (
            <CompanyEditDialog
               show={true}
               id={id}
               isPrivate={0}
               onHide={() => {
                  history.push('/companies');
                  setEdit(false);
               }}
            />
         ) : (
            ''
         )}
         {isView ? (
            <CompanyViewDialog
               show={true}
               id={id}
               isPrivate={0}
               onHide={() => {
                  history.push('/companies');
                  setView(false);
               }}
            />
         ) : (
            ''
         )}
         {isDelete ? (
            <CompanyDeleteDialog
               show={true}
               name={decodeURIComponent(name)}
               id={id}
               isPrivate={0}
               onHide={() => {
                  history.push('/companies');
                  setDelete(false);
               }}
            />
         ) : (
            ''
         )}
         {isClear ? (
            <CompanyClearStoreDialog
               show={true}
               name={decodeURIComponent(name)}
               clearType={decodeURIComponent(clearType)}
               id={id}
               isPrivate={0}
               onHide={() => {
                  history.push('/companies');
                  setClear(false);
               }}
            />
         ) : (
            ''
         )}
         {isUnblock ? (
            <CompanyUnblockDialog
               show={true}
               name={decodeURIComponent(name)}
               id={id}
               isPrivate={0}
               onHide={() => {
                  history.push('/companies');
                  setUnblock(false);
               }}
            />
         ) : (
            ''
         )}

         {isExtentPeriod ? (
            <CompaniesExtendPeriodDialog
               clientUID={id}
               name={decodeURIComponent(name)}
               show={true}
               onHide={() => {
                  history.push(`/companies`);
                  setIsExtentPeriod(false);
               }}
            />
         ) : null}

         {isBlock ? (
            <CompanyBlockDialog
               show={true}
               name={decodeURIComponent(name)}
               id={id}
               isPrivate={0}
               onHide={() => {
                  history.push('/companies');
                  setBlock(false);
               }}
            />
         ) : (
            ''
         )}
         <Route path="/companies/deleteCompanies">
            {({ history, match }) => (
               <CompaniesDeleteDialog
                  show={match != null}
                  onHide={() => {
                     history.push('/companies');
                  }}
               />
            )}
         </Route>
         <Route path="/companies/fetch">
            {({ history, match }) => (
               <CompaniesFetchDialog
                  show={match != null}
                  onHide={() => {
                     history.push('/companies');
                  }}
               />
            )}
         </Route>
         <Route path="/companies/updateStatus">
            {({ history, match }) => (
               <CompaniesUpdateStateDialog
                  show={match != null}
                  onHide={() => {
                     history.push('/companies');
                  }}
               />
            )}
         </Route>
         <CompaniesCard isPrivate={0} />
      </CompaniesUIProvider>
   );
}
