import * as requestFromServer from "./channelsCrud";
import { channelsSlice, callTypes } from "./channelsSlice";
const { actions } = channelsSlice;

export const fetchChannels = (queryParams, isPrivate, lastEvaluate, isAll) => (dispatch) => {
  //isAll is for dropdown
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findChannels(queryParams, parseInt(isPrivate, 10), lastEvaluate, isAll)
    .then((response) => {
      const { LastEvaluatedKey, channels } = response.data.data;
      const entities = channels;
      dispatch(
        actions.channelsFetched({
          totalCount: channels.length,
          entities,
          LastEvaluatedKey,
          lastEvaluate,
          memberMode: queryParams.memberMode,
        })
      );
    })
    .catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorCode === "012"
      ) {
        alert("กรุณาเข้าสู่ระบบอีกครั้ง เนื่องจากระยะเวลาการใช้งานหมดอายุ");
        window.location.href = "/logout";
      }
      dispatch(actions.channelsFetched({ totalCount: 0, entities: [], LastEvaluatedKey: {} }));
      error.clientMessage = "Can't find channels";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCreators = (queryParams, channelId, isPrivate) => (dispatch) => {
  if (!channelId) {
    return dispatch(actions.creatorsFetched({ creatorsForEdit: undefined }));
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getCreatorsById(queryParams, channelId, parseInt(isPrivate, 10))
    .then((response) => {
      if (response.data.status) {
        const { creators } = response.data.data;
        dispatch(
          actions.creatorsFetched({
            creators,
            channelId: channelId,
            memberMode: queryParams.memberMode,
          })
        );
      }
    })
    .catch((error) => {
      dispatch(actions.creatorsFetched({ creatorsForEdit: undefined }));
      error.clientMessage = "Can't find channel";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const deleteChannel = (id, isPrivate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteChannel(id, isPrivate)
    .then((response) => {
      dispatch(actions.channelDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete channel";
      dispatch(actions.catchError({ error, callType: callTypes.action, from: "delete" }));
    });
};

export const deleteCreator = (channelId, creatorId, isPrivate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCreator(channelId, creatorId, isPrivate)
    .then((response) => {
      dispatch(actions.creatorDeleted({ creatorId: creatorId }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete creator";
      dispatch(actions.catchError({ error, callType: callTypes.action, from: "delete" }));
    });
};

export const blockCreator = (name, channelId, creatorId, isPrivate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .blockCreator(name, channelId, creatorId, parseInt(isPrivate, 10))
    .then((response) => {
      dispatch(actions.elementUpdated());
    })
    .catch((error) => {
      error.clientMessage = "Can't block creator";
      dispatch(actions.catchError({ error, callType: callTypes.action, from: "block" }));
    });
};

export const blockChannel = (name, id, isPrivate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .blockChannel(name, id, parseInt(isPrivate, 10))
    .then((response) => {
      dispatch(actions.elementUpdated());
    })
    .catch((error) => {
      error.clientMessage = "Can't block channel";
      dispatch(actions.catchError({ error, callType: callTypes.action, from: "block" }));
    });
};

export const unblockCreator = (name, channelId, creatorId, isPrivate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .unblockCreator(name, channelId, creatorId, parseInt(isPrivate, 10))
    .then((response) => {
      dispatch(actions.elementUpdated());
    })
    .catch((error) => {
      error.clientMessage = "Can't unblock creator";
      dispatch(actions.catchError({ error, callType: callTypes.action, from: "unblock" }));
    });
};

export const unblockChannel = (name, id, isPrivate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .unblockChannel(name, id, parseInt(isPrivate, 10))
    .then((response) => {
      dispatch(actions.elementUpdated());
    })
    .catch((error) => {
      error.clientMessage = "Can't unblock channel";
      dispatch(actions.catchError({ error, callType: callTypes.action, from: "unblock" }));
    });
};

export const setStatusChannel = (id, value, isPrivate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .setStatusChannel(id, value, parseInt(isPrivate, 10))
    .then((response) => {
      dispatch(actions.elementUpdated());
    })
    .catch((error) => {
      error.clientMessage = "Can't set status channel";
      dispatch(actions.catchError({ error, callType: callTypes.action, from: "setStatus" }));
    });
};

export const setPinChannel = (id, value, isPrivate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .setPinChannel(id, value, parseInt(isPrivate, 10))
    .then((response) => {
      dispatch(actions.elementUpdated());
    })
    .catch((error) => {
      error.clientMessage = "Can't set pin channel";
      dispatch(actions.catchError({ error, callType: callTypes.action, from: "setPin" }));
    });
};

export const createChannel = (
  channelForCreation,
  isPrivate,
  setSuccessModal,
  onSuccess,
  setChName,
  setChId
) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createChannel(channelForCreation, parseInt(isPrivate, 10))
    .then((response) => {
      if (response.data.status) {
        const { channel } = response.data.data;
        if (channel && setChName && setChId) {
          setChName(channel.name);
          setChId(channel.uid);
        }
        dispatch(actions.channelCreated({ channel }));
        if (setSuccessModal) {
          setSuccessModal(true);
        }
        if (onSuccess) {
          onSuccess();
        }
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't create channel";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      // console.log(error);
      if (error) {
        return "error";
      }
    });
};

export const createCreator = (
  creatorForCreation,
  isPrivate,
  channelId,
  channelName,
  history,
  onSuccess
) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCreator(creatorForCreation, parseInt(isPrivate, 10), channelId)
    .then((response) => {
      if (response.data.status) {
        const { creator } = response.data.data;
        if (creator.status) {
          dispatch(actions.creatorCreated({ creator, channelId }));
        }
        if (history && onSuccess) {
          onSuccess();
          history.push(`/creators?channelId=${channelId}&channelName=${channelName}`);
        }
      }
    })
    .catch((error) => {
      console.log(error);
      error.clientMessage = "Can't create creator";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      if (error) {
        return "error";
      }
    });
};

export const updateChannel = (channel, isPrivate, setSuccessModal) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateChannel(channel, parseInt(isPrivate, 10))
    .then(() => {
      dispatch(actions.channelUpdated({ channel }));
      if (setSuccessModal) {
        setSuccessModal(true);
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't update channel";
      dispatch(actions.catchError({ error, callType: callTypes.action, from: "update" }));
    });
};

export const updateCreator = (creator, isPrivate, setSuccessModal) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCreator(creator, parseInt(isPrivate, 10))
    .then(() => {
      dispatch(
        actions.creatorUpdated({
          creator: { name: creator.name, status: creator.status },
          creatorId: creator.creatorId,
        })
      );
      if (setSuccessModal) {
        setSuccessModal(true);
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't update creator";
      dispatch(actions.catchError({ error, callType: callTypes.action, from: "update" }));
    });
};

export const updateChannelsStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForChannels(ids, status)
    .then(() => {
      dispatch(actions.channelsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update channels status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteChannels = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteChannels(ids)
    .then(() => {
      dispatch(actions.channelsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete channels";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
