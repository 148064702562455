// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Input, Select } from '../../../../../../_metronic/_partials/controls';
import Spinner from '../../../../../../_metronic/_helpers/spinner';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    head: {
        fontSize: '20px',
        fontWeight: '600',
        margin: '16px auto 0',
        color: '#012875'
    }
}));

// Validation schema
const CategoryNewSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'อย่างน้อย 2 ตัวอักษร')
        .max(200, 'ไม่เกิน 200 ตัวอักษร')
        .matches(/^[^#%&?/]*$/g,'ไม่สามารถกรอก #, &, %, ? หรือ /')

        .required('กรุณากรอกชื่อ')
});

export function CategoryNewForm({
    createCategory,
    createSubCategory,
    category,
    actionsLoading,
    onHide,
    isSubCat,
    categoryList,
    isDuplicate,
    setDuplicate
}) {
    const styles = useStyles();
    const [categoryIn, setCategoryIn] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [categoryInList, setCategoryInList] = useState([
        <option key="none" value=""></option>
    ]);
    const [checkingError, setCheckingError] = useState(false);

    useEffect(() => {
        setCheckingError(false);
    }, [categoryIn]);

    useEffect(() => {
        if (categoryList) {
            setCategoryInList([
                <option value={''} disabled selected>
                    เลือกหมวดหมู่หลัก
                </option>,
                ...categoryList.map((x, index) => {
                    // if (index === 0) {
                    //   setCategoryIn(x.id);
                    // }
                    return (
                        <option key={index} value={x.id}>
                            {x.catName}
                        </option>
                    );
                })
            ]);
        }
    }, [categoryList]);

    const handleCategoryInChange = e => {
        setCategoryIn(e.target.value);
        var index = e.nativeEvent.target.selectedIndex;
        setCategoryName(e.nativeEvent.target[index].text);
    };

    return (
        <>
            <p className={styles.head}>
                {isSubCat ? 'เพิ่มหมวดหมู่ย่อย' : 'เพิ่มหมวดหมู่หลัก'}
            </p>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name: category.catName,
                    status: category.status
                }}
                validationSchema={CategoryNewSchema}
                onSubmit={values => {
                    setDuplicate(false);
                    if (isSubCat) {
                        createSubCategory(values, categoryIn, categoryName);
                    } else {
                        createCategory(values);
                    }
                }}
            >
                {({ handleSubmit, values, setFieldValue, errors }) => {
                    return (
                        <>
                            <Modal.Body>
                                {actionsLoading && (
                                    <Spinner
                                        forNewCat={true}
                                        isSubCat={isSubCat}
                                    />
                                )}
                                <Form className="form form-label-right">
                                    {isSubCat ? (
                                        <div className={`col-lg-12 input`}>
                                            <Select
                                                name="category"
                                                withFeedbackLabel={false}
                                                label="เลือกหมวดหมู่หลัก"
                                                onChange={
                                                    handleCategoryInChange
                                                }
                                                value={categoryIn}
                                            >
                                                {categoryInList}
                                            </Select>
                                            {categoryIn === '' &&
                                                checkingError && (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกหมวดหมู่หลัก
                                                        </div>
                                                    </>
                                                )}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <div className={`col-lg-12 input`}>
                                        <Field
                                            name="name"
                                            component={Input}
                                            placeholder={
                                                isSubCat
                                                    ? 'กรอกชื่อหมวดหมู่ย่อย'
                                                    : 'กรอกชื่อหมวดหมู่หลัก'
                                            }
                                            label={
                                                isSubCat
                                                    ? 'ชื่อหมวดหมู่ย่อย'
                                                    : 'ชื่อหมวดหมู่หลัก'
                                            }
                                        />
                                        {isDuplicate && checkingError && (
                                            <>
                                                <div className="is-invalid"></div>
                                                <div className="invalid-feedback">
                                                    {isSubCat
                                                        ? 'มีชื่อหมวดหมู่ย่อยนี้แล้ว'
                                                        : 'มีชื่อหมวดหมู่หลักนี้แล้ว'}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Form>
                                <div className="divButton">
                                    <button
                                        className="denyButton"
                                        type="button"
                                        onClick={onHide}
                                    >
                                        ยกเลิก
                                    </button>
                                    <button
                                        className="acceptButton"
                                        type="submit"
                                        onClick={() => {
                                            handleSubmit();
                                            setCheckingError(true);
                                        }}
                                        disabled={
                                            actionsLoading ||
                                            !values['name'] ||
                                            (isSubCat && categoryIn === '')
                                                ? true
                                                : false
                                        }
                                    >
                                        ตกลง
                                    </button>
                                </div>
                            </Modal.Body>
                        </>
                    );
                }}
            </Formik>
        </>
    );
}
