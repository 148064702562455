// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../../../_metronic/_helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Role } from '../../../../../../../interface';

export function ActionsColumnFormatter(
   cellContent,
   row,
   rowIndex,
   {
      openViewQrcodeDialog,
      openViewQrcodeMembersPage,
      openEditQrcodeDialog,
      openDeleteQrcodeDialog,
      openBlockCustomerDialog,
      openUnblockCustomerDialog,
      openBannedCustomerDialog,
      openResetPwCustomerDialog,
      openCustomerDevicesDialog,
      role
   }
) {
   return (
      <>
         <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="quick-user-tooltip">ดูผู้ลงทะเบียน</Tooltip>}
         >
            <a
               className="btn btn-icon btn-light btn-hover-primary btn-sm"
               onClick={() => openViewQrcodeMembersPage(row.qrcodeUID)}
            >
               <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                     src={toAbsoluteUrl(
                        '/media/members/view-qrcode-register.svg'
                     )}
                  />
               </span>
            </a>
         </OverlayTrigger>
         {role === Role.SuperAdmin ? (
            ''
         ) : (
            <OverlayTrigger
               placement="bottom"
               overlay={
                  <Tooltip id="quick-user-tooltip">แก้ไข QR Code</Tooltip>
               }
            >
               <a
                  className="btn btn-icon btn-light btn-hover-primary btn-sm"
                  onClick={() => openEditQrcodeDialog(row.qrcodeUID)}
               >
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                     <SVG
                        src={toAbsoluteUrl('/media/members/edit-button.svg')}
                     />
                  </span>
               </a>
            </OverlayTrigger>
         )}
         <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="quick-user-tooltip">QR Code</Tooltip>}
         >
            <a
               className="btn btn-icon btn-light btn-hover-primary btn-sm"
               onClick={() => openViewQrcodeDialog(row.qrcodeUID)}
            >
               <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                     src={toAbsoluteUrl('/media/members/download-button.svg')}
                  />
               </span>
            </a>
         </OverlayTrigger>
         <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="quick-user-tooltip">ลบ QR Code</Tooltip>}
         >
            <a
               className="btn btn-icon btn-light btn-hover-danger btn-sm"
               onClick={() => openDeleteQrcodeDialog(row.qrcodeUID, row.name)}
            >
               <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                     src={toAbsoluteUrl('/media/members/delete-button.svg')}
                  />
               </span>
            </a>
         </OverlayTrigger>
         <button
            title="View detail"
            className="button-description"
            onClick={e => {
               e.preventDefault();
               openViewQrcodeDialog(row.qrcodeUID, true); //cellContent = id
            }}
         >
            รายละเอียด
         </button>
      </>
   );
}
