import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/customers/customersActions";
import { useCustomersUIContext } from "../CustomersUIContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
     body: {
          textAlign: "center",
     },
}));

export function CustomerUnblockDialog({ id, email, show, onHide }) {
     // Customers UI Context
     const customersUIContext = useCustomersUIContext();
     const customersUIProps = useMemo(() => {
          return {
               setIds: customersUIContext.setIds,
               queryParams: customersUIContext.queryParams,
          };
     }, [customersUIContext]);

     // Customers Redux state
     const dispatch = useDispatch();
     const { isLoading } = useSelector((state) => ({ isLoading: state.customers.actionsLoading }), shallowEqual);

     // if !id we should close modal
     useEffect(() => {
          if (!id) {
               onHide();
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [id]);

     // looking for loading/dispatch
     useEffect(() => {}, [isLoading, dispatch]);

     const unblockCustomer = () => {
          // server request for deleting customer by id
          dispatch(actions.blockCustomer(id)).then(() => {
               // refresh list after deletion
               dispatch(actions.fetchCustomers(customersUIProps.queryParams));
               // clear selections list
               customersUIProps.setIds([]);
               // closing block modal
               onHide();
          });
     };

     const classes = useStyles();

     return (
          <Modal
               backdrop="static"
               show={show}
               onHide={onHide}
               aria-labelledby="example-modal-sizes-title-lg"
               style={{ margin: "3% auto" }}
          >
               {/*begin::Loading*/}
               {isLoading && <ModalProgressBar />}
               {/*end::Loading*/}
               {/* <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Customer Block</Modal.Title>
      </Modal.Header> */}
               <Modal.Body className={classes.body}>
                    {email ? (
                         <>
                              <img src="/media/members/image-modal.svg" alt="" />
                              {!isLoading ? (
                                   <>
                                        <div className="text-text-red">ยืนยันการปลดบล็อก</div>
                                        <div className="text-text-gray">ต้องการปลดบล็อกสมาชิก</div>
                                        <div className="text-text-gray">อีเมล {email} หรือไม่?</div>
                                        <div className="mt-3">
                                             <button type="button" onClick={onHide} className="denyButton">
                                                  ยกเลิก
                                             </button>
                                             <button type="button" onClick={unblockCustomer} className="acceptButton">
                                                  ยืนยัน
                                             </button>
                                        </div>
                                   </>
                              ) : (
                                   <div className="text-text-gray" style={{ marginBottom: "36px" }}>
                                        กำลังปลดบล็อกสมาชิก...
                                   </div>
                              )}
                         </>
                    ) : (
                         ""
                    )}
               </Modal.Body>
               {/* <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            Cancel
          </button>
          <> </>
          <button type="button" onClick={blockCustomer} className="btn btn-primary btn-elevate">
            Block
          </button>
        </div>
      </Modal.Footer> */}
          </Modal>
     );
}
