import { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import {
    Input,
    Select,
    Card,
    CardBody
} from '../../../../../_metronic/_partials/controls';
import { useDispatch } from 'react-redux';
import * as actions from '../../_redux/companies/companiesActions';
import { DatePicker, Switch, Input as Inputantd, message, Divider } from 'antd';
import * as Yup from 'yup';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import Spinner from '../../../../../_metronic/_helpers/spinner';
import axios from 'axios';
import { ModalsSuccess } from '../../../CustomView/ModalView';
import { Button } from 'antd';
import { DownOutlined, RightOutlined, PlusOutlined } from '@ant-design/icons';
import {
    disabledDateAfterExpire,
    disabledDateBeforeStart,
    todayDatePickerStart,
    todayDatePickerEnd
} from '../../../../utility';
import './upload-company.scss';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

const FormForFile = ({
    isEditing,
    uploadValue,
    clientUIDForUpdate,
    handleSubmitOutside,
    FILENAMEToUpload,
    userLimitData,
    titleLimitData,
    storageLimitData,
    concurrentLimitData,
    organizationTypeData,
    smsRegisterPreset,
    storagePeriodData,
    themeValue,
    lightColorObj,
    darkColorObj,
    setCoverPost,
    setSuccessCoverUpload,
    registerByPhoneNumber
}) => {
    const dispatch = useDispatch();
    const limitCustomTitle = 30;
    const limitCustomTitleLine2 = 40;
    const limitCustomTitleLine3 = 50;
    const [userLimitValue, setUserLimitValue] = useState(1000);
    const [titleLimitValue, setTitleLimitValue] = useState(0);
    const [storageLimitValue, setStorageLimitValue] = useState(0);
    const [smsLimitValue, setSmsLimitValue] = useState(0);

    /* Rental Limit */
    const [rentalLimitValue, setRentalLimitValue] = useState(0);
    const [
        rentalLimitValue_Anonymous,
        setRentalLimitValue_Anonymous
    ] = useState(0);
    const [rentalLimitValue_Magazine, setRentalLimitValue_Magazine] = useState(
        0
    );
    const [
        rentalLimitValue_Anonymous_Magazine,
        setRentalLimitValue_Anonymous_Magazine
    ] = useState(0);
    const [
        rentalLimitValue_Newspaper,
        setRentalLimitValue_Newspaper
    ] = useState(0);
    const [
        rentalLimitValue_Anonymous_Newspaper,
        setRentalLimitValue_Anonymous_Newspaper
    ] = useState(0);

    /* Rental Period */
    const [rentalPeriodValue, setRentalPeriodValue] = useState(172800);
    const [
        rentalPeriodValue_Anonymous,
        setRentalPeriodValue_Anonymous
    ] = useState(172800);

    const [
        rentalPeriodValue_Magazine,
        setRentalPeriodValue_Magazine
    ] = useState(1800);
    const [
        rentalPeriodValue_Anonymous_Magazine,
        setRentalPeriodValue_Anonymous_Magazine
    ] = useState(1800);

    const [
        rentalPeriodValue_Newspaper,
        setRentalPeriodValue_Newspaper
    ] = useState(900);
    const [
        rentalPeriodValue_Anonymous_Newspaper,
        setRentalPeriodValue_Anonymous_Newspaper
    ] = useState(900);

    const [typeValue_store, setTypeValue_store] = useState(0);
    const [typeValue_company, setTypeValue_company] = useState(0);
    const [soLittleTime, setSoLittleTime] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isManageLicense, setIsManageLicense] = useState(false);
    const [registerByPhoneFirstTime, setRegisterByPhoneFirstTime] = useState(
        true
    );
    const [
        clientRegisterByPhoneNumberApi,
        setClientRegisterByPhoneNumberApi
    ] = useState();
    const [clientRegisterPhoneApi, setClientRegisterPhoneApi] = useState(
        isEditing ? true : false
    );
    const [addSmsCount, setAddSmsCount] = useState(0);
    const [nameMini, setNameMini] = useState(null);
    const [successModal, setSuccessModal] = useState(false);
    const [confirmModalIndex, setConfirmModalIndex] = useState(undefined);
    const [duplicateCompanyModal, setDuplicateCompanyModal] = useState(false);
    const [isSubmittingState, setSubmittingState] = useState(false);
    const [userLimitCustomValue, setUserLimitCustomValue] = useState(0);
    const [isCustomSms, setIsCustomSms] = useState(false);
    const [customStorageLimitValue, setCustomStorageLimitValue] = useState(
        undefined
    );
    const [customTitleLimitValue, setCustomTitleLimitValue] = useState(
        undefined
    );
    const [isCollapseAdminList, setIsCollapseAdminList] = useState(
        isEditing ? false : true
    );
    const [checkingError, setCheckingError] = useState({
        name_mini: false,
        type_store: false,
        type_company: false,
        user_limit: false,
        datePicker: false,
        rental: false,
        rental_ano: false,
        rental_mag: false,
        rental_ano_mag: false,
        rental_news: false,
        rental_ano_news: false
    });

    const [userLimitList, setUserLimitList] = useState([
        <option key="" value={0} disabled>
            กรุณาเลือกจำนวน
        </option>
    ]);
    const [titleLimitList, setTitleLimitList] = useState([
        <option key="" value={0} disabled>
            กรุณาเลือกจำนวนรายการ
        </option>
    ]);
    const [storageLimitList, setStorageLimitList] = useState([
        <option key="" value={0} disabled>
            กรุณาเลือกความจุ
        </option>
    ]);
    const [smsLimitList, setSmsLimitList] = useState([
        <option key="" value={0} disabled>
            กรุณาเลือกจำนวน sms
        </option>
    ]);
    const [concurrentList, setConcurrentList] = useState([
        <option key="" value={0} disabled>
            กรุณาเลือกจำนวน
        </option>
    ]);
    const [concurrentList_Anonymous, setConcurrentList_Anonymous] = useState([
        <option key="" value={0} disabled>
            กรุณาเลือกจำนวน
        </option>
    ]);
    const [concurrentList_small, setConcurrentList_small] = useState([
        <option key="" value={0} disabled>
            กรุณาเลือกจำนวน
        </option>
    ]);
    const [
        concurrentList_small_Anonymous,
        setConcurrentList_small_Anonymous
    ] = useState([
        <option key="" value={0} disabled>
            กรุณาเลือกจำนวน
        </option>
    ]);
    const typeList_store = [
        <option key="" value={0} disabled>
            กรุณาเลือกประเภท
        </option>,
        <option key="1" value={1}>
            ใช้งานจริง
        </option>,
        <option key="4" value={4}>
            ทดลองใช้งาน
        </option>
    ];

    const [typeList_company, setTypeList_company] = useState([
        <option key="" value={0} disabled>
            กรุณาเลือกประเภท
        </option>
    ]);

    const [periodList, setPeriodList] = useState([
        <option key="" value={0}>
            กรุณาเลือก
        </option>
    ]);

    var chkUserLimit = limit => {
        let chk = userLimitData.find(el => el.value === limit);
        if (chk === undefined) {
            setUserLimitValue(-1);
            setUserLimitCustomValue(limit);
        }
        return;
    };

    useEffect(() => {
        if (Array.isArray(userLimitData)) {
            setUserLimitList([
                <option key="" value={0} disabled>
                    กรุณาเลือกจำนวน
                </option>,
                ...userLimitData.map((e, index) => {
                    return (
                        <option key={e.value} value={e.value}>
                            {e.label}
                        </option>
                    );
                }),
                <option key="" value={-1}>
                    กำหนดเอง
                </option>
            ]);
            chkUserLimit(userLimitValue);
        }
    }, [userLimitData]);

    useEffect(() => {
        if (Array.isArray(titleLimitData)) {
            setTitleLimitList([
                <option key="" value={0} disabled>
                    กรุณาเลือกจำนวนรายการ
                </option>,
                ...titleLimitData.map((e, index) => {
                    return (
                        <option key={e.value} value={e.value}>
                            {e.label}
                        </option>
                    );
                })
            ]);
        }
    }, [titleLimitData]);

    useEffect(() => {
        if (Array.isArray(storageLimitData)) {
            setStorageLimitList([
                <option key="" value={0} disabled>
                    กรุณาเลือกความจุ
                </option>,
                ...storageLimitData.map((e, index) => {
                    return (
                        <option key={e.value} value={e.value}>
                            {e.label}
                        </option>
                    );
                })
            ]);
        }
    }, [storageLimitData]);

    useEffect(() => {
        if (Array.isArray(smsRegisterPreset)) {
            setSmsLimitList([
                <option key="" value={0} disabled>
                    กรุณาเลือกความจุ
                </option>,
                ...smsRegisterPreset.map((e, index) => {
                    return (
                        <option key={e.value} value={e.value}>
                            {e.label}
                        </option>
                    );
                })
            ]);
        }
    }, [smsRegisterPreset]);

    useEffect(() => {
        if (Array.isArray(storagePeriodData)) {
            setPeriodList([
                <option key="" value={0} disabled>
                    กรุณาเลือก
                </option>,
                ...storagePeriodData.map((e, index) => {
                    return (
                        <option key={e.value} value={e.value}>
                            {e.label}
                        </option>
                    );
                })
            ]);
        }
    }, [storagePeriodData]);

    useEffect(() => {
        if (Array.isArray(concurrentLimitData)) {
            setConcurrentList([
                <option key="" value={0} disabled>
                    กรุณาเลือกจำนวน
                </option>,
                ...concurrentLimitData.map((el, index) => {
                    return (
                        <option key={index} value={el.value}>
                            {el.label}
                        </option>
                    );
                })
            ]);
            setConcurrentList_Anonymous([
                <option key="" value={0} disabled>
                    กรุณาเลือกจำนวน
                </option>,
                ...concurrentLimitData.map((el, index) => {
                    return (
                        <option key={index} value={el.value}>
                            {el.label}
                        </option>
                    );
                })
            ]);
            setConcurrentList_small([
                <option key="" value={0} disabled>
                    กรุณาเลือกจำนวน
                </option>,
                ...concurrentLimitData.map((el, index) => {
                    return (
                        <option key={index} value={el.value}>
                            {el.label}
                        </option>
                    );
                })
            ]);
            setConcurrentList_small_Anonymous([
                <option key="" value={0} disabled>
                    กรุณาเลือกจำนวน
                </option>,
                ...concurrentLimitData.map((el, index) => {
                    return (
                        <option key={index} value={el.value}>
                            {el.label}
                        </option>
                    );
                })
            ]);
        }
    }, [concurrentLimitData]);

    useEffect(() => {
        if (Array.isArray(organizationTypeData)) {
            setTypeList_company([
                <option key="" value={0} disabled>
                    กรุณาเลือกประเภท
                </option>,
                ...organizationTypeData.map((e, index) => {
                    return (
                        <option key={index} value={e.value}>
                            {e.label}
                        </option>
                    );
                })
            ]);
        }
    }, [organizationTypeData]);

    const onManageLicenseChange = e => {
        setCheckingError(
            Object.assign({}, checkingError, {
                title_limit: false,
                storage_limit: false
            })
        );
        setTitleLimitValue(0);
        setStorageLimitValue(0);
        setIsManageLicense(e);
    };

    const onHide = () => {
        setSuccessModal(false);
        handleSubmitOutside();
    };

    const onHideConfirm = () => {
        setConfirmModalIndex(undefined);
    };

    // Validation schema
    const uploadSchema = Yup.object().shape({
        name: Yup.string()
            .min(1, 'อย่างน้อย 1 ตัวอักษร')
            .max(150, 'ไม่เกิน 150 ตัวอักษร')
            .required('กรุณากรอกชื่อ'),
        nameLib: Yup.string()
            .min(1, 'อย่างน้อย 1 ตัวอักษร')
            .max(150, 'ไม่เกิน 150 ตัวอักษร')
            .required('กรุณากรอกชื่อ'),
        // initialConcurrent: Yup.number()
        //   .min(0, "กรุณาใช้จำนวนที่เป็น 0 ขึ้นไป")
        //   .typeError("กรุณาใช้ตัวเลข"),
        monthlyLicense: Yup.number()
            .min(0, 'กรุณาใช้จำนวนที่เป็น 0 ขึ้นไป')
            .typeError('กรุณาใช้ตัวเลข'),
        email: Yup.string()
            .email('อีเมลไม่ถูกต้อง')
            .min(6, 'อย่างน้อย 6 ตัวอักษร')
            .max(150, 'ไม่เกิน 150 ตัวอักษร'),
        facebook: Yup.string().url('กรุณากรอก URL ที่ถูกต้อง'),
        instagram: Yup.string().url('กรุณากรอก URL ที่ถูกต้อง'),
        twitter: Yup.string().url('กรุณากรอก URL ที่ถูกต้อง'),
        youtube: Yup.string().url('กรุณากรอก URL ที่ถูกต้อง'),
        tiktok: Yup.string().url('กรุณากรอก URL ที่ถูกต้อง'),
        website: Yup.string().url('กรุณากรอก URL ที่ถูกต้อง'),
        registerByPhoneDetail: Yup.object().when('registerByPhoneNumber', {
            is: true,
            then: Yup.object().shape({
                totalSMS: Yup.number().required('กรุณาเลือกจำนวนข้อความ OTP'),
                email: Yup.array()
                    .of(
                        Yup.string()
                            .required('กรุณากรอกอีเมล')
                            .matches(
                                /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                'อีเมลไม่ถูกต้อง'
                            )
                    )
                    .required('กรุณาเพิ่มผู้ดูแล')
            })
        })
    });

    const handleUserLimitChange = e => {
        setUserLimitValue(parseInt(e.target.value));
        setUserLimitCustomValue(parseInt(e.target.value));
        if (parseInt(e.target.value) === -1) {
            setUserLimitCustomValue(1000);
        }
        setCheckingError(
            Object.assign({}, checkingError, { user_limit: false })
        );
    };
    const handleUserLimitCustomChange = e => {
        if (e.target.value && !isNaN(e.target.value)) {
            setUserLimitCustomValue(parseInt(e.target.value, 10));
        } else {
            setUserLimitValue(0);
            setUserLimitCustomValue(0);
        }
        setCheckingError(
            Object.assign({}, checkingError, { user_limit: false })
        );
    };
    const handleTypeCompanyChange = e => {
        setTypeValue_company(e.target.value);
        setCheckingError(
            Object.assign({}, checkingError, { type_company: false })
        );
    };
    const handleTypeStoreChange = e => {
        setTypeValue_store(e.target.value);
        setCheckingError(
            Object.assign({}, checkingError, { type_store: false })
        );
    };
    useEffect(() => {
        const newList = concurrentList.filter(
            el => el.props.value <= rentalLimitValue
        );
        setConcurrentList_Anonymous(newList);
        setConcurrentList_small(newList);
        setConcurrentList_small_Anonymous(newList);
    }, [rentalLimitValue]);

    const handleRentalLimitChange = e => {
        const value = parseInt(e.target.value, 10);
        setRentalLimitValue(value);
        if (
            rentalLimitValue_Anonymous === 0 ||
            rentalLimitValue_Anonymous > value
        ) {
            setRentalLimitValue_Anonymous(value);
        }
        if (
            rentalLimitValue_Magazine === 0 ||
            rentalLimitValue_Magazine > value
        ) {
            setRentalLimitValue_Magazine(value);
        }
        if (
            rentalLimitValue_Newspaper === 0 ||
            rentalLimitValue_Newspaper > value
        ) {
            setRentalLimitValue_Newspaper(value);
        }
        if (
            rentalLimitValue_Anonymous_Magazine === 0 ||
            rentalLimitValue_Anonymous_Magazine > value
        ) {
            setRentalLimitValue_Anonymous_Magazine(value);
        }
        if (
            rentalLimitValue_Anonymous_Newspaper === 0 ||
            rentalLimitValue_Anonymous_Newspaper > value
        ) {
            setRentalLimitValue_Anonymous_Newspaper(value);
        }
        setCheckingError(Object.assign({}, checkingError, { rental: false }));
    };
    const handleRentalLimitChange_Anonymous = e => {
        const value = parseInt(e.target.value, 10);
        setRentalLimitValue_Anonymous(value);
        setConcurrentList_small_Anonymous(
            concurrentList.filter(el => el.props.value <= value)
        );
        if (rentalLimitValue_Anonymous_Magazine > value) {
            setRentalLimitValue_Anonymous_Magazine(value);
        }
        if (rentalLimitValue_Anonymous_Newspaper > value) {
            setRentalLimitValue_Anonymous_Newspaper(value);
        }
        setCheckingError(
            Object.assign({}, checkingError, { rental_ano: false })
        );
    };
    const handleRentalLimitChange_Magazine = e => {
        setRentalLimitValue_Magazine(e.target.value);
        setCheckingError(
            Object.assign({}, checkingError, { rental_mag: false })
        );
    };
    const handleRentalLimitChange_Anonymous_Magazine = e => {
        setRentalLimitValue_Anonymous_Magazine(e.target.value);
        setCheckingError(
            Object.assign({}, checkingError, { rental_ano_mag: false })
        );
    };
    const handleRentalLimitChange_Newspaper = e => {
        setRentalLimitValue_Newspaper(e.target.value);
        setCheckingError(
            Object.assign({}, checkingError, { rental_news: false })
        );
    };
    const handleRentalLimitChange_Anonymous_Newspaper = e => {
        setRentalLimitValue_Anonymous_Newspaper(e.target.value);
        setCheckingError(
            Object.assign({}, checkingError, { rental_ano_news: false })
        );
    };
    const handleRentalPeriodChange = e => {
        setRentalPeriodValue(e.target.value);
        setCheckingError(Object.assign({}, checkingError, { period: false }));
    };
    const handleRentalPeriodChange_Anonymous = e => {
        setRentalPeriodValue_Anonymous(e.target.value);
        setCheckingError(
            Object.assign({}, checkingError, { period_ano: false })
        );
    };
    const handleRentalPeriodChange_Magazine = e => {
        setRentalPeriodValue_Magazine(e.target.value);
        setCheckingError(Object.assign({}, checkingError, { period: false }));
    };
    const handleRentalPeriodChange_Anonymous_Magazine = e => {
        setRentalPeriodValue_Anonymous_Magazine(e.target.value);
        setCheckingError(
            Object.assign({}, checkingError, { period_ano: false })
        );
    };
    const handleRentalPeriodChange_Newspaper = e => {
        setRentalPeriodValue_Newspaper(e.target.value);
        setCheckingError(Object.assign({}, checkingError, { period: false }));
    };
    const handleRentalPeriodChange_Anonymous_Newspaper = e => {
        setRentalPeriodValue_Anonymous_Newspaper(e.target.value);
        setCheckingError(
            Object.assign({}, checkingError, { period_ano: false })
        );
    };
    const handleTitleLimitChange = e => {
        setTitleLimitValue(e.target.value);
        setCheckingError(
            Object.assign({}, checkingError, { title_limit: false })
        );
    };
    const handleStorageLimitChange = e => {
        setStorageLimitValue(e.target.value);
        setCheckingError(
            Object.assign({}, checkingError, { storage_limit: false })
        );
    };

    const switchRegisterByPhone = (e, setFieldValue) => {
        setFieldValue('registerByPhoneNumber', e);
        if (e) {
            setIsCollapseAdminList(true);
            setFieldValue('registerByPhoneDetail.email', [undefined]);
        } else if (clientRegisterPhoneApi.email === undefined) {
            setFieldValue('registerByPhoneDetail.email', undefined);
        }
    };

    const handleSmsLimitChange = (e, setFieldValue) => {
        const value = parseInt(e.target.value);
        if (value === -1) {
            setIsCustomSms(true);
            setSmsLimitValue(0);
        } else {
            setFieldValue('registerByPhoneDetail.totalSMS', e.target.value);
            setIsCustomSms(false);
            setSmsLimitValue(value);
            setCheckingError(
                Object.assign({}, checkingError, { sms_limt: false })
            );
        }
    };

    const handleSmsLimitUpdateChange = (e, setFieldValue) => {
        const value = parseInt(e.target.value);
        if (value === -1) {
            setAddSmsCount(0);
            setIsCustomSms(true);
            setSmsLimitValue(0);
        } else {
            setFieldValue(
                'registerByPhoneDetail.totalSMS',
                (clientRegisterPhoneApi?.totalSMS ?? 0) + value
            );
            setAddSmsCount(value);
            setIsCustomSms(false);

            setSmsLimitValue(value);
            setCheckingError(
                Object.assign({}, checkingError, { sms_limt: false })
            );
        }
    };

    const handleSmsValueChange = (e, setFieldValue) => {
        const value = parseInt(e.target.value);
        if (value >= 0) {
            setSmsLimitValue(value);
            setFieldValue('registerByPhoneDetail.totalSMS', value);
        } else {
            message.warning('ไม่สามารถกรอกค่าน้อยกว่า 0 ได้');
        }
    };

    const onChangeEmailSms = (e, index, smsList, setFieldValue) => {
        const value = e.target.value;
        if (smsList) {
            let newAdminList = [...smsList];
            newAdminList[index] = value;
            console.log(newAdminList);
            setFieldValue('registerByPhoneDetail.email', newAdminList);
        }
    };

    const onAddAdminSms = (values, setFieldValue) => {
        if (values.registerByPhoneDetail?.email) {
            const newAdminList = [...values.registerByPhoneDetail.email];
            newAdminList.push('');
            setFieldValue('registerByPhoneDetail.email', newAdminList);
        } else {
            setFieldValue('registerByPhoneDetail.email', ['']);
        }
        setIsCollapseAdminList(true);
    };

    const removeEmailSms = (index, emailParam, setFieldValue) => {
        let emailList = [...emailParam];
        emailList.splice(index, 1);
        console.log(emailList);
        setFieldValue('registerByPhoneDetail.email', emailList);
    };

    const onChangeNameMini = e => {
        const regex = RegExp(/^[A-Za-z1-9]+$/);
        if (e && e.target) {
            if (
                e.target.value &&
                e.target.value.length <= 10 &&
                regex.test(e.target.value)
            ) {
                setNameMini(e.target.value.toLowerCase());
                setCheckingError(
                    Object.assign({}, checkingError, { sms_limit: false })
                );
            } else if (e.target.value === '') {
                setNameMini(e.target.value.toLowerCase());
            }
        }
    };

    useEffect(() => {
        if (startDate && endDate && endDate < startDate + 2592000) {
            setSoLittleTime(true);
        } else {
            setSoLittleTime(false);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        setClientRegisterByPhoneNumberApi(uploadValue.registerByPhoneNumber);
        if (isEditing) {
            setClientRegisterPhoneApi(
                Object.assign({}, uploadValue.registerByPhoneDetail)
            );
            setIsCollapseAdminList(false);

            if (
                uploadValue.registerByPhoneDetail?.totalSMS === undefined &&
                uploadValue.registerByPhoneDetail?.email === undefined &&
                uploadValue.registerByPhoneDetail?.remainSMS === undefined
            ) {
                setRegisterByPhoneFirstTime(true);
            } else {
                setRegisterByPhoneFirstTime(false);
            }
        }

        if (uploadValue && uploadValue.startDate) {
            setStartDate(uploadValue.startDate);
        }
        if (uploadValue && uploadValue.endDate) {
            setEndDate(uploadValue.endDate);
        }
        if (uploadValue && uploadValue.rentalLimit) {
            setRentalLimitValue(uploadValue.rentalLimit);
        }
        if (uploadValue && uploadValue.anonymousRentalLimit) {
            setRentalLimitValue_Anonymous(uploadValue.anonymousRentalLimit);
        }
        if (uploadValue && uploadValue.rentalPeriod) {
            setRentalPeriodValue(uploadValue.rentalPeriod);
        }
        if (uploadValue && uploadValue.anonymousPeriod) {
            setRentalPeriodValue_Anonymous(uploadValue.anonymousPeriod);
        }
        if (uploadValue && uploadValue.nameMini) {
            setNameMini(uploadValue.nameMini);
        }
        if (uploadValue && uploadValue.organizationType) {
            setTypeValue_company(uploadValue.organizationType);
        }
        if (uploadValue && uploadValue.status) {
            setTypeValue_store(uploadValue.status);
        }
        if (uploadValue && uploadValue.userLimit) {
            setUserLimitValue(uploadValue.userLimit);
            chkUserLimit(uploadValue.userLimit);
        }
        if (uploadValue && uploadValue.totalTitle && uploadValue.totalStorage) {
            setIsManageLicense(true);
            if (uploadValue.isCustomTotalStorage) {
                setStorageLimitValue(-1);
                setCustomStorageLimitValue(uploadValue.totalStorage);
            } else {
                setStorageLimitValue(uploadValue.totalStorage);
            }

            if (uploadValue.isCustomTotalTitle) {
                setTitleLimitValue(-1);
                setCustomTitleLimitValue(uploadValue.totalTitle);
            } else {
                setTitleLimitValue(uploadValue.totalTitle);
            }
        }
        if (
            uploadValue &&
            !uploadValue.totalTitle &&
            !uploadValue.totalStorage
        ) {
            setIsManageLicense(false);
        }
        if (uploadValue && uploadValue.rentalLimit_magazine) {
            setRentalLimitValue_Magazine(uploadValue.rentalLimit_magazine);
        }
        if (uploadValue && uploadValue.rentalLimit_anonymous_magazine) {
            setRentalLimitValue_Anonymous_Magazine(
                uploadValue.rentalLimit_anonymous_magazine
            );
        }
        if (uploadValue && uploadValue.rentalLimit_newspaper) {
            setRentalLimitValue_Newspaper(uploadValue.rentalLimit_newspaper);
        }
        if (uploadValue && uploadValue.rentalLimit_anonymous_newspaper) {
            setRentalLimitValue_Anonymous_Newspaper(
                uploadValue.rentalLimit_anonymous_newspaper
            );
        }
        if (uploadValue && uploadValue.period_magazine) {
            setRentalPeriodValue_Magazine(uploadValue.period_magazine);
        }
        if (uploadValue && uploadValue.period_anonymous_magazine) {
            setRentalPeriodValue_Anonymous_Magazine(
                uploadValue.period_anonymous_magazine
            );
        }
        if (uploadValue && uploadValue.period_newspaper) {
            setRentalPeriodValue_Newspaper(uploadValue.period_newspaper);
        }
        if (uploadValue && uploadValue.period_anonymous_newspaper) {
            setRentalPeriodValue_Anonymous_Newspaper(
                uploadValue.period_anonymous_newspaper
            );
        }
    }, [uploadValue]);

    const onUpdateClientRegisterPhone = values => {
        let updateEmail = false;
        let updateRegisterPhonePayload;

        if (
            clientRegisterByPhoneNumberApi === false &&
            values.registerByPhoneNumber
        ) {
            if (registerByPhoneFirstTime) {
                updateRegisterPhonePayload = {
                    emailList: values.registerByPhoneDetail?.email,
                    addSMSCount: addSmsCount
                };
            } else {
                updateRegisterPhonePayload = {
                    emailList: values.registerByPhoneDetail?.email,
                    addSMSCount:
                        values.registerByPhoneDetail?.totalSMS + addSmsCount
                };
            }
        } else {
            // if (clientRegisterPhoneApi && clientRegisterPhoneApi.email) {
            let submitEmail = values.registerByPhoneDetail?.email?.sort();
            let apiEmail = clientRegisterPhoneApi?.email?.sort();
            if (JSON.stringify(submitEmail) === JSON.stringify(apiEmail)) {
                updateEmail = false;
            } else {
                updateEmail = true;
            }
            // }
            console.log(JSON.stringify(submitEmail), JSON.stringify(apiEmail));
            console.log('updateEmail', updateEmail);

            updateRegisterPhonePayload = {
                emailList: updateEmail
                    ? values.registerByPhoneDetail?.email
                    : undefined,
                freeOTPVerify:
                    values.registerByPhoneDetail?.freeOTPVerify ===
                    clientRegisterPhoneApi?.freeOTPVerify
                        ? undefined
                        : values.registerByPhoneDetail?.freeOTPVerify,
                addSMSCount: addSmsCount > 0 ? addSmsCount : undefined
            };
        }
        if (
            updateRegisterPhonePayload.emailList !== undefined ||
            updateRegisterPhonePayload.freeOTPVerify !== undefined ||
            updateRegisterPhonePayload.addSMSCount !== undefined
        ) {
            return updateRegisterPhonePayload;
        } else {
            return undefined;
        }
    };

    const canSend = value => {
        return value !== undefined && value !== '';
    };

    const isCustomStoreValue = () => {
        return (
            parseInt(storageLimitValue + '') === -1 ||
            parseInt(titleLimitValue + '') === -1
        );
    };

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={uploadValue}
                validationSchema={uploadSchema}
                onSubmit={values => {
                    setSubmittingState(true);
                    let titleLimit =
                        parseInt(titleLimitValue + '') === -1
                            ? customTitleLimitValue
                            : titleLimitValue;
                    let storageLimit =
                        parseInt(storageLimitValue + '') === -1
                            ? customStorageLimitValue
                            : storageLimitValue;
                    const newValues = {
                        clientName: values.name,
                        companyName: values.name,
                        displayName: values.nameLib,
                        customTitle: values.customTitle,
                        description: values.description,
                        companyPrefix: nameMini,
                        startDate: startDate,
                        expireDate: endDate,

                        monthlyLicense: values.monthlyLicense
                            ? parseInt(values.monthlyLicense, 10)
                            : 0,
                        userLimit:
                            userLimitCustomValue ||
                            parseInt(userLimitValue, 10),
                        totalStorage: isManageLicense
                            ? storageLimit
                                ? parseFloat(storageLimit)
                                : undefined
                            : 0,
                        totalTitle: isManageLicense
                            ? titleLimit
                                ? parseInt(titleLimit, 10)
                                : undefined
                            : 0,
                        rentalLimit: parseInt(rentalLimitValue, 10),
                        anonymousRentalLimit: parseInt(
                            rentalLimitValue_Anonymous,
                            10
                        ),
                        rentalLimitCategory: {
                            newspapers: {
                                user: parseInt(rentalLimitValue_Newspaper, 10),
                                anonymous: parseInt(
                                    rentalLimitValue_Anonymous_Newspaper,
                                    10
                                )
                            },
                            magazine: {
                                user: parseInt(rentalLimitValue_Magazine, 10),
                                anonymous: parseInt(
                                    rentalLimitValue_Anonymous_Magazine,
                                    10
                                )
                            }
                        },
                        rentalPeriod: parseInt(rentalPeriodValue, 10),
                        anonymousPeriod: parseInt(
                            rentalPeriodValue_Anonymous,
                            10
                        ),
                        periodCategory: {
                            newspapers: {
                                user: parseInt(rentalPeriodValue_Newspaper, 10),
                                anonymous: parseInt(
                                    rentalPeriodValue_Anonymous_Newspaper,
                                    10
                                )
                            },
                            magazine: {
                                user: parseInt(rentalPeriodValue_Magazine, 10),
                                anonymous: parseInt(
                                    rentalPeriodValue_Anonymous_Magazine,
                                    10
                                )
                            }
                        },
                        status: parseInt(typeValue_store, 10),
                        organizationType: typeValue_company,
                        logoImages: FILENAMEToUpload,
                        showClientLogo: true,
                        theme: themeValue,
                        colorTheme:
                            themeValue === 'light'
                                ? lightColorObj
                                : darkColorObj,
                        contactList: {
                            address: canSend(values.address)
                                ? values.address
                                : undefined,
                            email: canSend(values.email)
                                ? values.email
                                : undefined,
                            Instagram: canSend(values.instagram)
                                ? values.instagram
                                : undefined,
                            LineID: canSend(values.lineID)
                                ? values.lineID
                                : undefined,
                            mobilePhone: canSend(values.telephone)
                                ? values.telephone
                                : undefined,
                            phoneNumber: canSend(values.phone)
                                ? values.phone
                                : undefined,
                            Twitter: canSend(values.twitter)
                                ? values.twitter
                                : undefined,
                            website: canSend(values.website)
                                ? values.website
                                : undefined,
                            Facebook: canSend(values.facebook)
                                ? values.facebook
                                : undefined,
                            YouTube: canSend(values.youtube)
                                ? values.youtube
                                : undefined,
                            Tiktok: canSend(values.tiktok)
                                ? values.tiktok
                                : undefined
                        },
                        registerByPhoneNumber: values.registerByPhoneNumber,
                        registerByPhoneDetail: values.registerByPhoneDetail
                    };

                    if (
                        nameMini &&
                        ((userLimitValue && userLimitValue > -1) ||
                            (userLimitValue === -1 &&
                                userLimitCustomValue > 0)) &&
                        typeValue_store &&
                        typeValue_company &&
                        !soLittleTime &&
                        endDate &&
                        rentalLimitValue &&
                        rentalLimitValue_Anonymous &&
                        rentalLimitValue_Magazine &&
                        rentalLimitValue_Anonymous_Magazine &&
                        rentalLimitValue_Newspaper &&
                        rentalLimitValue_Anonymous_Newspaper &&
                        rentalPeriodValue &&
                        rentalPeriodValue_Anonymous &&
                        rentalPeriodValue_Magazine &&
                        rentalPeriodValue_Anonymous_Magazine &&
                        rentalPeriodValue_Newspaper &&
                        rentalPeriodValue_Anonymous_Newspaper &&
                        ((isManageLicense && storageLimit && titleLimit) ||
                            !isManageLicense)
                    ) {
                        console.log('new values', newValues);
                        const reqFunc = async () => {
                            try {
                                const req = await axios.post(
                                    `/superuser/clients/create`,
                                    newValues
                                );
                                if (req.data.status) {
                                    setSuccessCoverUpload(false);
                                    setCoverPost(null);
                                    setSubmittingState(false);
                                    setSuccessModal(true);
                                }
                            } catch (err) {
                                setSubmittingState(false);
                                if (
                                    err &&
                                    err.response &&
                                    err.response.data &&
                                    err.response.data.errorCode &&
                                    err.response.data.errorCode.includes('800')
                                ) {
                                    setDuplicateCompanyModal(true);
                                }
                            }
                        };
                        if (!isEditing) {
                            reqFunc();
                        } else {
                            const phonePayload = onUpdateClientRegisterPhone(
                                newValues
                            );
                            if (isEditing)
                                delete newValues.registerByPhoneDetail;
                            console.log(phonePayload);
                            dispatch(
                                actions.updateCompany(
                                    clientUIDForUpdate,
                                    newValues,
                                    setSuccessModal,
                                    phonePayload,
                                    setSubmittingState
                                )
                            );
                            // reqEditFunc();
                        }
                    } else {
                        setSubmittingState(false);
                    }
                }}
            >
                {({
                    handleSubmit,
                    values,
                    handleChange,
                    setFieldValue,
                    errors,
                    touched
                }) => {
                    return (
                        <Card className="cardStyle upload-company">
                            <CardBody
                                style={
                                    isEditing ? { padding: '2rem 0 0 0' } : {}
                                }
                            >
                                <div
                                    style={{
                                        maxWidth: '736px',
                                        margin: 'auto'
                                    }}
                                >
                                    {isSubmittingState && (
                                        <Spinner forNewCompany={true} />
                                    )}
                                    <Form className="form form-label-right ">
                                        {isEditing ? '' : <h3>ข้อมูลองค์กร</h3>}
                                        <div className="row">
                                            <div className={`col-4 input`}>
                                                <Field
                                                    name="name"
                                                    component={Input}
                                                    placeholder="กรุณากรอกชื่อองค์กร"
                                                    label="ชื่อองค์กร*"
                                                />
                                            </div>
                                            <div className="col-4 input">
                                                <Field
                                                    name="nameLib"
                                                    component={Input}
                                                    placeholder="กรุณากรอกชื่อห้องสมุดองค์กร"
                                                    label="ชื่อห้องสมุดองค์กร*"
                                                />
                                            </div>
                                            <div className={`col-4 input`}>
                                                <label>
                                                    ชื่อย่อองค์กร (ภาษาอังกฤษ)*
                                                </label>
                                                <input
                                                    value={nameMini}
                                                    onChange={onChangeNameMini}
                                                    placeholder="กรุณากรอกชื่อย่อองค์กร"
                                                    className={
                                                        (checkingError[
                                                            'name_mini'
                                                        ] &&
                                                            !nameMini) ||
                                                        (nameMini &&
                                                            nameMini.length < 3)
                                                            ? 'form-control is-invalid'
                                                            : 'form-control'
                                                    }
                                                />
                                                {(checkingError['name_mini'] &&
                                                    !nameMini) ||
                                                (nameMini &&
                                                    nameMini.length < 3) ? (
                                                    <>
                                                        <div className="invalid-feedback">
                                                            {nameMini &&
                                                            nameMini.length < 3
                                                                ? 'กรุณาใช้ 3 ตัวอักษรขึ้นไป'
                                                                : 'กรุณากรอกชื่อย่อ'}
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4 input">
                                                <label>
                                                    ปรับแต่งหัวข้อ บรรทัดที่ 1
                                                </label>
                                                <Inputantd
                                                    name="customTitle.line1"
                                                    className="input-antd-suffix"
                                                    value={
                                                        values?.customTitle
                                                            ?.line1
                                                    }
                                                    component={Input}
                                                    placeholder="ปรับแต่งหัวข้อ บรรทัดที่ 1"
                                                    autocomplete="off"
                                                    suffix={
                                                        <span className="count-input">
                                                            {values?.customTitle
                                                                ?.line1
                                                                ?.length ??
                                                                0}{' '}
                                                            / {limitCustomTitle}
                                                        </span>
                                                    }
                                                    onChange={e => {
                                                        if (
                                                            e.target.value
                                                                .length <=
                                                            limitCustomTitle
                                                        ) {
                                                            setFieldValue(
                                                                'customTitle.line1',
                                                                e.target.value
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="col-4 input">
                                                <label>
                                                    ปรับแต่งหัวข้อ บรรทัดที่ 2
                                                </label>
                                                <Inputantd
                                                    name="customTitle.line2"
                                                    className="input-antd-suffix"
                                                    value={
                                                        values?.customTitle
                                                            ?.line2
                                                    }
                                                    component={Input}
                                                    placeholder="ปรับแต่งหัวข้อ บรรทัดที่ 2"
                                                    autocomplete="off"
                                                    suffix={
                                                        <span className="count-input">
                                                            {values?.customTitle
                                                                ?.line2
                                                                ?.length ??
                                                                0}{' '}
                                                            /{' '}
                                                            {
                                                                limitCustomTitleLine2
                                                            }
                                                        </span>
                                                    }
                                                    onChange={e => {
                                                        if (
                                                            e.target.value
                                                                .length <=
                                                            limitCustomTitleLine2
                                                        ) {
                                                            setFieldValue(
                                                                'customTitle.line2',
                                                                e.target.value
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="col-4 input">
                                                <label>
                                                    ปรับแต่งหัวข้อ บรรทัดที่ 3
                                                </label>
                                                <Inputantd
                                                    name="customTitle.line3"
                                                    className="input-antd-suffix"
                                                    value={
                                                        values?.customTitle
                                                            ?.line3
                                                    }
                                                    component={Input}
                                                    placeholder="ปรับแต่งหัวข้อ บรรทัดที่ 3"
                                                    autocomplete="off"
                                                    suffix={
                                                        <span className="count-input">
                                                            {values?.customTitle
                                                                ?.line3
                                                                ?.length ??
                                                                0}{' '}
                                                            /{' '}
                                                            {
                                                                limitCustomTitleLine3
                                                            }
                                                        </span>
                                                    }
                                                    onChange={e => {
                                                        if (
                                                            e.target.value
                                                                .length <=
                                                            limitCustomTitleLine3
                                                        ) {
                                                            setFieldValue(
                                                                'customTitle.line3',
                                                                e.target.value
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className={`col-3 input`}>
                                                {userLimitValue === -1 ? (
                                                    <>
                                                        <p
                                                            style={{
                                                                marginBottom:
                                                                    '0.5rem'
                                                            }}
                                                        >
                                                            จำนวนสมาชิก*
                                                        </p>
                                                        <Inputantd
                                                            placeholder="กรุณากรอกจำนวน"
                                                            type="number"
                                                            allowClear
                                                            onChange={
                                                                handleUserLimitCustomChange
                                                            }
                                                            value={
                                                                userLimitCustomValue
                                                            }
                                                            style={{
                                                                display:
                                                                    'inline-flex',
                                                                height: '38px'
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <Select
                                                        name="userLimit"
                                                        withFeedbackLabel={
                                                            false
                                                        }
                                                        label="จำนวนสมาชิก*"
                                                        onChange={
                                                            handleUserLimitChange
                                                        }
                                                        value={userLimitValue}
                                                    >
                                                        {userLimitList}
                                                    </Select>
                                                )}
                                                {checkingError['user_limit'] &&
                                                !userLimitValue ? (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกจำนวน
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                                {userLimitValue === -1 &&
                                                userLimitCustomValue < 1 ? (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาใช้จำนวนมากกว่า
                                                            0
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div className={`col-6 input`}>
                                                <p
                                                    style={{
                                                        marginBottom: '0.5rem'
                                                    }}
                                                >
                                                    วันที่เริ่มใช้งาน -
                                                    วันที่หมดอายุ*{' '}
                                                </p>
                                                <div className="period-container">
                                                    <DatePicker
                                                        format={
                                                            'DD-MM-YYYY HH:mm:ss'
                                                        }
                                                        renderExtraFooter={() =>
                                                            todayDatePickerStart(
                                                                setStartDate
                                                            )
                                                        }
                                                        showTime={{
                                                            defaultValue: moment(
                                                                '00:00:00',
                                                                'HH:mm:ss'
                                                            )
                                                        }}
                                                        onChange={dates => {
                                                            if (dates !== null)
                                                                setStartDate(
                                                                    dates.unix()
                                                                );
                                                            else
                                                                setStartDate(
                                                                    null
                                                                );
                                                        }}
                                                        value={
                                                            startDate !== null
                                                                ? moment.unix(
                                                                      startDate
                                                                  )
                                                                : null
                                                        }
                                                        placeholder="วันที่เริ่ม"
                                                        disabledDate={current => {
                                                            if (endDate) {
                                                                return disabledDateAfterExpire(
                                                                    current,
                                                                    endDate
                                                                );
                                                            } else {
                                                                return false;
                                                            }
                                                        }}
                                                    />
                                                    <DatePicker
                                                        format={
                                                            'DD-MM-YYYY HH:mm:ss'
                                                        }
                                                        renderExtraFooter={() =>
                                                            todayDatePickerEnd(
                                                                setEndDate
                                                            )
                                                        }
                                                        showTime={{
                                                            defaultValue: moment(
                                                                '23:59:59',
                                                                'HH:mm:ss'
                                                            )
                                                        }}
                                                        onChange={dates => {
                                                            if (dates !== null)
                                                                setEndDate(
                                                                    dates.unix()
                                                                );
                                                            else
                                                                setEndDate(
                                                                    null
                                                                );
                                                        }}
                                                        placeholder="วันที่สิ้นสุด"
                                                        value={
                                                            endDate !== null
                                                                ? moment.unix(
                                                                      endDate
                                                                  )
                                                                : null
                                                        }
                                                        disabledDate={current => {
                                                            if (startDate) {
                                                                return disabledDateBeforeStart(
                                                                    current,
                                                                    startDate
                                                                );
                                                            } else {
                                                                return false;
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                {checkingError['datePicker'] &&
                                                (startDate === null ||
                                                    endDate === null) ? (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณากรอกวันที่เริ่มใช้งานและวันที่หมดอายุ
                                                        </div>
                                                    </>
                                                ) : null}

                                                {checkingError['datePicker'] &&
                                                soLittleTime ? (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณากรอกช่วงเวลา 30
                                                            วันขึ้นไป
                                                        </div>
                                                    </>
                                                ) : null}
                                            </div>
                                            <div className={`col-3 input`}>
                                                <Select
                                                    name="type_company"
                                                    withFeedbackLabel={false}
                                                    label="ประเภทองค์กร*"
                                                    onChange={
                                                        handleTypeCompanyChange
                                                    }
                                                    value={typeValue_company}
                                                >
                                                    {typeList_company}
                                                </Select>
                                                {checkingError[
                                                    'type_company'
                                                ] && !typeValue_company ? (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกประเภท
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <Field
                                                    name="description"
                                                    component={Input}
                                                    placeholder="กรุณากรอกรายละเอียด"
                                                    values={values.description}
                                                    label="รายละเอียด"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <Divider />
                                        <h3 className="mt">Store</h3>
                                        <div className="row">
                                            <div className={`col-6 input`}>
                                                <Select
                                                    name="type_store"
                                                    withFeedbackLabel={false}
                                                    label="ประเภท Store*"
                                                    onChange={
                                                        handleTypeStoreChange
                                                    }
                                                    value={typeValue_store}
                                                >
                                                    {typeList_store}
                                                </Select>
                                                {checkingError['type_store'] &&
                                                !typeValue_store ? (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกประเภท
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            {/* <div className={`col-6 input`}>
                                                <Field
                                                    name="monthlyLicense"
                                                    component={Input}
                                                    placeholder="กรุณากรอกจำนวน"
                                                    label="จำนวนสิทธิ์ที่เพิ่มต่อเดือน"
                                                    type="number"
                                                />
                                            </div> */}
                                        </div>
                                        <Divider />
                                        <h3 className="mt">
                                            จัดการสิทธิ์การยืมรายเล่ม
                                            (concurrent)
                                        </h3>
                                        <div className="row">
                                            <div className={`col-4 input`}>
                                                <Select
                                                    name="rentalLimit"
                                                    withFeedbackLabel={false}
                                                    label="จำนวนในการยืมแบบสมาชิก*"
                                                    onChange={
                                                        handleRentalLimitChange
                                                    }
                                                    value={rentalLimitValue}
                                                >
                                                    {concurrentList}
                                                </Select>
                                                {checkingError['rental'] &&
                                                !rentalLimitValue ? (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกจำนวน
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div className={`col-2 input`}>
                                                <Select
                                                    name="rentalPeriod"
                                                    withFeedbackLabel={false}
                                                    label="เวลาในการยืม*"
                                                    onChange={
                                                        handleRentalPeriodChange
                                                    }
                                                    value={rentalPeriodValue}
                                                >
                                                    {periodList}
                                                </Select>
                                                {checkingError['period'] &&
                                                !rentalPeriodValue ? (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกจำนวน
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div className={`col-4 input`}>
                                                <Select
                                                    name="rentalLimit_Anonymous"
                                                    withFeedbackLabel={false}
                                                    label="จำนวนในการยืมแบบไม่ระบุตัวตน*"
                                                    onChange={
                                                        handleRentalLimitChange_Anonymous
                                                    }
                                                    value={
                                                        rentalLimitValue_Anonymous
                                                    }
                                                >
                                                    {concurrentList_Anonymous}
                                                </Select>
                                                {checkingError['rental_ano'] &&
                                                !rentalLimitValue_Anonymous ? (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกจำนวน
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div className={`col-2 input`}>
                                                <Select
                                                    name="rentalPeriod_Anonymous"
                                                    withFeedbackLabel={false}
                                                    label="เวลาในการยืม*"
                                                    onChange={
                                                        handleRentalPeriodChange_Anonymous
                                                    }
                                                    value={
                                                        rentalPeriodValue_Anonymous
                                                    }
                                                >
                                                    {periodList}
                                                </Select>
                                                {checkingError['period_ano'] &&
                                                !rentalPeriodValue_Anonymous ? (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกจำนวน
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                        <h3 className="mt">นิตยสาร </h3>
                                        <div className="row">
                                            <div className={`col-4 input`}>
                                                <Select
                                                    name="rentalLimit_Magazine"
                                                    withFeedbackLabel={false}
                                                    label="จำนวนสูงสุดในการยืมแบบสมาชิก*"
                                                    onChange={
                                                        handleRentalLimitChange_Magazine
                                                    }
                                                    value={
                                                        rentalLimitValue_Magazine
                                                    }
                                                >
                                                    {concurrentList_small}
                                                </Select>
                                                {checkingError['rental_mag'] &&
                                                !rentalLimitValue_Magazine ? (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกจำนวน
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div className={`col-2 input`}>
                                                <Select
                                                    name="rentalPeriod_Magazine"
                                                    withFeedbackLabel={false}
                                                    label="เวลาในการยืม*"
                                                    onChange={
                                                        handleRentalPeriodChange_Magazine
                                                    }
                                                    value={
                                                        rentalPeriodValue_Magazine
                                                    }
                                                >
                                                    {periodList}
                                                </Select>
                                                {checkingError['period_mag'] &&
                                                !rentalPeriodValue_Magazine ? (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกจำนวน
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div className={`col-4 input`}>
                                                <Select
                                                    name="rentalLimit_Anonymous_Magazine"
                                                    withFeedbackLabel={false}
                                                    label="จำนวนสูงสุดในการยืมไม่ระบุตัวตน*"
                                                    onChange={
                                                        handleRentalLimitChange_Anonymous_Magazine
                                                    }
                                                    value={
                                                        rentalLimitValue_Anonymous_Magazine
                                                    }
                                                >
                                                    {
                                                        concurrentList_small_Anonymous
                                                    }
                                                </Select>
                                                {checkingError[
                                                    'rental_ano_mag'
                                                ] &&
                                                !rentalLimitValue_Anonymous_Magazine ? (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกจำนวน
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div className={`col-2 input`}>
                                                <Select
                                                    name="rentalPeriod_Anonymous_Magazine"
                                                    withFeedbackLabel={false}
                                                    label="เวลาในการยืม*"
                                                    onChange={
                                                        handleRentalPeriodChange_Anonymous_Magazine
                                                    }
                                                    value={
                                                        rentalPeriodValue_Anonymous_Magazine
                                                    }
                                                >
                                                    {periodList}
                                                </Select>
                                                {checkingError[
                                                    'period_ano_mag'
                                                ] &&
                                                !rentalPeriodValue_Anonymous_Magazine ? (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกจำนวน
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                        <h3 className="mt">หนังสือพิมพ์</h3>
                                        <div className="row">
                                            <div className={`col-4 input`}>
                                                <Select
                                                    name="rentalLimit_Newspaper"
                                                    withFeedbackLabel={false}
                                                    label="จำนวนสูงสุดในการยืมแบบสมาชิก*"
                                                    onChange={
                                                        handleRentalLimitChange_Newspaper
                                                    }
                                                    value={
                                                        rentalLimitValue_Newspaper
                                                    }
                                                >
                                                    {concurrentList_small}
                                                </Select>
                                                {checkingError['rental_news'] &&
                                                !rentalLimitValue_Newspaper ? (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกจำนวน
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div className={`col-2 input`}>
                                                <Select
                                                    name="rentalPeriod_Newspaper"
                                                    withFeedbackLabel={false}
                                                    label="เวลาในการยืม*"
                                                    onChange={
                                                        handleRentalPeriodChange_Newspaper
                                                    }
                                                    value={
                                                        rentalPeriodValue_Newspaper
                                                    }
                                                >
                                                    {periodList}
                                                </Select>
                                                {checkingError['period_new'] &&
                                                !rentalPeriodValue_Newspaper ? (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกจำนวน
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div className={`col-4 input`}>
                                                <Select
                                                    name="rentalLimit_Anonymous_Newspaper"
                                                    withFeedbackLabel={false}
                                                    label="จำนวนสูงสุดในการยืมไม่ระบุตัวตน*"
                                                    onChange={
                                                        handleRentalLimitChange_Anonymous_Newspaper
                                                    }
                                                    value={
                                                        rentalLimitValue_Anonymous_Newspaper
                                                    }
                                                >
                                                    {
                                                        concurrentList_small_Anonymous
                                                    }
                                                </Select>
                                                {checkingError[
                                                    'rental_ano_news'
                                                ] &&
                                                !rentalLimitValue_Anonymous_Newspaper ? (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกจำนวน
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div className={`col-2 input`}>
                                                <Select
                                                    name="rentalPeriod_Anonymous_Newspaper"
                                                    withFeedbackLabel={false}
                                                    label="เวลาในการยืม*"
                                                    onChange={
                                                        handleRentalPeriodChange_Anonymous_Newspaper
                                                    }
                                                    value={
                                                        rentalPeriodValue_Anonymous_Newspaper
                                                    }
                                                >
                                                    {periodList}
                                                </Select>
                                                {checkingError[
                                                    'period_ano_new'
                                                ] &&
                                                !rentalPeriodValue_Anonymous_Newspaper ? (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกจำนวน
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>

                                        <Divider />
                                        <h3 className="mt">
                                            พื้นที่จัดเก็บสื่อองค์กร
                                            <span>
                                                <Switch
                                                    onChange={
                                                        onManageLicenseChange
                                                    }
                                                    checked={isManageLicense}
                                                    checkedChildren="เปิด"
                                                    unCheckedChildren="ปิด"
                                                    style={{
                                                        margin: '0 0 3px 12px'
                                                    }}
                                                />
                                            </span>
                                        </h3>
                                        {isManageLicense ? (
                                            <>
                                                <div className="row">
                                                    <>
                                                        <div
                                                            className={`col-6 input`}
                                                        >
                                                            <Select
                                                                name="storageLimit"
                                                                withFeedbackLabel={
                                                                    false
                                                                }
                                                                label="พื้นที่จัดเก็บข้อมูลสื่อ (GB)"
                                                                onChange={
                                                                    handleStorageLimitChange
                                                                }
                                                                value={
                                                                    storageLimitValue
                                                                }
                                                            >
                                                                {
                                                                    storageLimitList
                                                                }
                                                            </Select>
                                                            {checkingError[
                                                                'storage_limit'
                                                            ] &&
                                                            !storageLimitValue ? (
                                                                <>
                                                                    <div className="is-invalid"></div>
                                                                    <div className="invalid-feedback">
                                                                        กรุณาเลือกความจุ
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {/* {errorNumberFormat(values["numLicenses"]) && checkingError && (
                                                            <>
                                                                <div className="is-invalid"></div>
                                                                <div className="invalid-feedback">
                                                                กรุณากรอกจำนวนสิทธิ์ให้ถูกต้อง
                                                                </div>
                                                            </>
                                                            )} */}
                                                        </div>
                                                        <div
                                                            className={`col-6 input`}
                                                        >
                                                            <Select
                                                                name="titleLimit"
                                                                withFeedbackLabel={
                                                                    false
                                                                }
                                                                label="จำนวนรายการสื่อ"
                                                                onChange={
                                                                    handleTitleLimitChange
                                                                }
                                                                value={
                                                                    titleLimitValue
                                                                }
                                                            >
                                                                {titleLimitList}
                                                            </Select>
                                                            {checkingError[
                                                                'title_limit'
                                                            ] &&
                                                            !titleLimitValue &&
                                                            titleLimitValue !==
                                                                -1 ? (
                                                                <>
                                                                    <div className="is-invalid"></div>
                                                                    <div className="invalid-feedback">
                                                                        กรุณาเลือกจำนวนรายการ
                                                                    </div>
                                                                </>
                                                            ) : null}
                                                        </div>
                                                    </>
                                                </div>
                                                {isCustomStoreValue() ? (
                                                    <div className="row">
                                                        <div className="col-6 input">
                                                            {parseInt(
                                                                storageLimitValue +
                                                                    ''
                                                            ) === -1 ? (
                                                                <>
                                                                    <Field
                                                                        type="number"
                                                                        onChange={e =>
                                                                            setCustomStorageLimitValue(
                                                                                parseInt(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            )
                                                                        }
                                                                        value={
                                                                            customStorageLimitValue
                                                                        }
                                                                        component={
                                                                            Input
                                                                        }
                                                                        placeholder="กรุณากรอกพื้นที่จัดเก็บข้อมูลสื่อ"
                                                                        label="กรอกพื้นที่จัดเก็บข้อมูลสื่อ (GB)"
                                                                    />
                                                                    {checkingError[
                                                                        'storage_limit'
                                                                    ] &&
                                                                    !customStorageLimitValue &&
                                                                    parseInt(
                                                                        storageLimitValue +
                                                                            ''
                                                                    ) === -1 ? (
                                                                        <>
                                                                            <div className="is-invalid"></div>
                                                                            <div className="invalid-feedback">
                                                                                กรุณากรอกพื้นที่จัดเก็บข้อมูลสื่อ
                                                                            </div>
                                                                        </>
                                                                    ) : null}
                                                                </>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-6 input">
                                                            {parseInt(
                                                                titleLimitValue +
                                                                    ''
                                                            ) === -1 ? (
                                                                <>
                                                                    <Field
                                                                        type="number"
                                                                        onChange={e =>
                                                                            setCustomTitleLimitValue(
                                                                                parseInt(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            )
                                                                        }
                                                                        value={
                                                                            customTitleLimitValue
                                                                        }
                                                                        component={
                                                                            Input
                                                                        }
                                                                        placeholder="กรุณากรอกจำนวนรายการสื่อ"
                                                                        label="กรอกจำนวนรายการสื่อ "
                                                                    />
                                                                    {checkingError[
                                                                        'title_limit'
                                                                    ] &&
                                                                    !customTitleLimitValue &&
                                                                    parseInt(
                                                                        titleLimitValue +
                                                                            ''
                                                                    ) === -1 ? (
                                                                        <>
                                                                            <div className="is-invalid"></div>
                                                                            <div className="invalid-feedback">
                                                                                กรุณากรอกจำนวนรายการสื่อ
                                                                            </div>
                                                                        </>
                                                                    ) : null}
                                                                </>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </>
                                        ) : null}

                                        <Divider />
                                        {/* RYOU*/}
                                        <h3 className="mt">
                                            ลงทะเบียนผ่านเบอร์โทรศัพท์
                                            <span>
                                                <Switch
                                                    onChange={e => {
                                                        switchRegisterByPhone(
                                                            e,
                                                            setFieldValue
                                                        );
                                                    }}
                                                    checked={
                                                        values.registerByPhoneNumber
                                                    }
                                                    name="registerByPhone"
                                                    checkedChildren="เปิด"
                                                    unCheckedChildren="ปิด"
                                                    style={{
                                                        margin: '0 0 3px 12px'
                                                    }}
                                                />
                                            </span>
                                        </h3>

                                        {/* <div className="registerByPhoneContainer"> */}
                                        {values.registerByPhoneNumber &&
                                        !clientRegisterPhoneApi ? (
                                            <div>
                                                <div className="row">
                                                    <div
                                                        className={`col-6 input`}
                                                    >
                                                        <Select
                                                            // name="registerByPhoneDetail.totalSMS"
                                                            withFeedbackLabel={
                                                                false
                                                            }
                                                            label="จำนวนข้อความ OTP"
                                                            onChange={e =>
                                                                handleSmsLimitChange(
                                                                    e,
                                                                    setFieldValue
                                                                )
                                                            }
                                                            value={
                                                                isCustomSms
                                                                    ? -1
                                                                    : smsLimitValue
                                                            }
                                                            option={
                                                                smsRegisterPreset
                                                            }
                                                        >
                                                            {smsLimitList}
                                                        </Select>
                                                        {errors
                                                            ?.registerByPhoneDetail
                                                            ?.totalSMS &&
                                                        touched?.registerByPhoneDetail ? (
                                                            <>
                                                                <div className="is-invalid"></div>
                                                                <div className="invalid-feedback">
                                                                    กรุณาเลือกจำนวนข้อความ
                                                                </div>
                                                            </>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                    {isCustomSms ? (
                                                        <div
                                                            className={`col-6 input`}
                                                        >
                                                            <Field
                                                                value={
                                                                    values
                                                                        .registerByPhoneDetail
                                                                        ?.totalSMS
                                                                }
                                                                type="number"
                                                                onChange={e =>
                                                                    handleSmsValueChange(
                                                                        e,
                                                                        setFieldValue
                                                                    )
                                                                }
                                                                component={
                                                                    Input
                                                                }
                                                                placeholder="กรุณากรอกจำนวนข้อความ OTP"
                                                                label="กรอกจำนวนข้อความ OTP "
                                                            />
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        ) : values.registerByPhoneNumber &&
                                          clientRegisterPhoneApi ? (
                                            <div>
                                                <div className="phoneRegisterDataContainer">
                                                    <div className="phoneRegisterDataItem">
                                                        <label>
                                                            จำนวนทั้งหมด OTP
                                                        </label>
                                                        <h4 className="totalOtpText">
                                                            {clientRegisterPhoneApi?.totalSMS?.toLocaleString() ??
                                                                '-'}
                                                        </h4>
                                                    </div>
                                                    <div className="phoneRegisterDataItem">
                                                        <label>
                                                            จำนวนที่ใช้ไป
                                                        </label>{' '}
                                                        <h4 className="usedOtpText">
                                                            {clientRegisterPhoneApi?.totalSMS !==
                                                                undefined &&
                                                            values
                                                                .registerByPhoneDetail
                                                                ?.remainSMS !==
                                                                undefined ? (
                                                                <>
                                                                    {(
                                                                        clientRegisterPhoneApi?.totalSMS -
                                                                        values
                                                                            .registerByPhoneDetail
                                                                            ?.remainSMS
                                                                    ).toLocaleString()}
                                                                </>
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </h4>
                                                    </div>
                                                    <div className="phoneRegisterDataItem">
                                                        <label>
                                                            จำนวนที่เหลือ
                                                        </label>{' '}
                                                        <h4 className="remainOtpText">
                                                            {values.registerByPhoneDetail?.remainSMS?.toLocaleString() ??
                                                                '-'}
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="row">
                                                        <div
                                                            className={`col-6 input`}
                                                        >
                                                            <Select
                                                                // name="registerByPhoneDetail.totalSMS"
                                                                withFeedbackLabel={
                                                                    false
                                                                }
                                                                label={`เพิ่มจำนวนข้อความ OTP`}
                                                                onChange={e => {
                                                                    handleSmsLimitUpdateChange(
                                                                        e,
                                                                        setFieldValue
                                                                    );
                                                                }}
                                                                name
                                                                value={
                                                                    isCustomSms
                                                                        ? -1
                                                                        : smsLimitValue
                                                                }
                                                                option={
                                                                    smsRegisterPreset
                                                                }
                                                            >
                                                                {smsLimitList}
                                                            </Select>
                                                            {errors
                                                                ?.registerByPhoneDetail
                                                                ?.totalSMS &&
                                                            touched?.registerByPhoneDetail ? (
                                                                <>
                                                                    <div className="is-invalid"></div>
                                                                    <div className="invalid-feedback">
                                                                        กรุณาเลือกจำนวนข้อความ
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                        {isCustomSms ? (
                                                            <div
                                                                className={`col-6 input`}
                                                            >
                                                                <Field
                                                                    value={
                                                                        // smsLimitValue
                                                                        addSmsCount
                                                                    }
                                                                    type="number"
                                                                    onChange={e => {
                                                                        setAddSmsCount(
                                                                            parseInt(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        );
                                                                    }}
                                                                    component={
                                                                        Input
                                                                    }
                                                                    placeholder="กรุณากรอกจำนวนข้อความ OTP"
                                                                    label="กรอกจำนวนข้อความ OTP"
                                                                />
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}

                                        {values.registerByPhoneNumber ? (
                                            <div>
                                                <p className="mt adminTitleCollapse">
                                                    ผู้ดูแลการซื้อข้อความ OTP{' '}
                                                    <span className="highlight">
                                                        (*อย่างน้อย 1 คน)
                                                    </span>{' '}
                                                    {isEditing ? (
                                                        <div className="emailList-btn--collapse">
                                                            กดดูรายการ
                                                            {isCollapseAdminList ? (
                                                                <DownOutlined
                                                                    onClick={() =>
                                                                        setIsCollapseAdminList(
                                                                            false
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                <RightOutlined
                                                                    onClick={() =>
                                                                        setIsCollapseAdminList(
                                                                            true
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    ) : null}
                                                </p>
                                                <div className="emailListContainer">
                                                    {isCollapseAdminList ? (
                                                        <>
                                                            {values.registerByPhoneDetail?.email?.map(
                                                                (el, index) => (
                                                                    <div>
                                                                        <div className="row ">
                                                                            <div
                                                                                className={`col-11`}
                                                                            >
                                                                                <Field
                                                                                    value={
                                                                                        el
                                                                                    }
                                                                                    onChange={e =>
                                                                                        onChangeEmailSms(
                                                                                            e,
                                                                                            index,
                                                                                            values
                                                                                                .registerByPhoneDetail
                                                                                                ?.email,
                                                                                            setFieldValue
                                                                                        )
                                                                                    }
                                                                                    component={
                                                                                        Input
                                                                                    }
                                                                                    placeholder="กรุณากรอกอีเมล"
                                                                                    label="อีเมล* "
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                className="col-1"
                                                                                style={{
                                                                                    alignItems:
                                                                                        'end',
                                                                                    display:
                                                                                        'flex'
                                                                                }}
                                                                            >
                                                                                <Button
                                                                                    className="removeButton"
                                                                                    onClick={() => {
                                                                                        // confirmRemove(
                                                                                        //     index,
                                                                                        //     values
                                                                                        //         .registerByPhoneDetail
                                                                                        //         ?.email,
                                                                                        //     setFieldValue
                                                                                        // );
                                                                                        setConfirmModalIndex(
                                                                                            index
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <SVG
                                                                                        src={toAbsoluteUrl(
                                                                                            '/media/members/delete-button.svg'
                                                                                        )}
                                                                                    />
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                        {errors
                                                                            ?.registerByPhoneDetail
                                                                            ?.email &&
                                                                        errors
                                                                            ?.registerByPhoneDetail
                                                                            ?.email[
                                                                            index
                                                                        ] &&
                                                                        touched
                                                                            ?.registerByPhoneDetail
                                                                            ?.email ? (
                                                                            <>
                                                                                <div className="invalid-feedback">
                                                                                    {
                                                                                        errors
                                                                                            ?.registerByPhoneDetail
                                                                                            ?.email[
                                                                                            index
                                                                                        ]
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        ) : null}
                                                                    </div>
                                                                )
                                                            )}
                                                            <div>
                                                                <Button
                                                                    className="addNewAdminButton"
                                                                    onClick={() => {
                                                                        onAddAdminSms(
                                                                            values,
                                                                            setFieldValue
                                                                        );
                                                                    }}
                                                                >
                                                                    <PlusOutlined />
                                                                    เพิ่มผู้ดูแล
                                                                </Button>
                                                                {errors
                                                                    ?.registerByPhoneDetail
                                                                    ?.email &&
                                                                touched?.registerByPhoneDetail &&
                                                                (values
                                                                    .registerByPhoneDetail
                                                                    ?.email ===
                                                                    undefined ||
                                                                    values
                                                                        .registerByPhoneDetail
                                                                        ?.email
                                                                        .length ===
                                                                        0) ? (
                                                                    <>
                                                                        <div className="invalid-feedback">
                                                                            {
                                                                                errors
                                                                                    ?.registerByPhoneDetail
                                                                                    ?.email
                                                                            }
                                                                        </div>
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                        ) : null}

                                        {values.registerByPhoneNumber &&
                                        clientRegisterPhoneApi ? (
                                            <div className="freeOTPVerifyContainer">
                                                <div>
                                                    <h4 className="freeOTPVerifyTitle">
                                                        ระบบลงทะเบียนผ่านเบอร์โทรศัพท์
                                                        โดย{' '}
                                                        <span>
                                                            ไม่ต้องยืนยันด้วย
                                                            รหัส OTP ชั่วคราว
                                                        </span>
                                                    </h4>
                                                    <text>
                                                        เมื่อเปิดแล้ว จำนวน OTP
                                                        ที่เหลือจะไม่ถูกนำไปใช้
                                                        เนื่องจากผู้ใช้งานไม่จำเป็นต้องยืนยันตัวตนด้วย{' '}
                                                        <br />
                                                        รหัส OTP ชั่วคราว{' '}
                                                        และจะถูกคิดอีกครั้งเมื่อปิดระบบ
                                                        Free Mode
                                                    </text>
                                                </div>
                                                <div className="freeOtpSwitch">
                                                    <Switch
                                                        checked={
                                                            // freeOtpSwitch
                                                            values
                                                                .registerByPhoneDetail
                                                                ?.freeOTPVerify
                                                        }
                                                        checkedChildren="เปิด"
                                                        unCheckedChildren="ปิด"
                                                        onChange={e => {
                                                            setFieldValue(
                                                                'registerByPhoneDetail.freeOTPVerify',
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ) : null}

                                        <Divider />

                                        <h3 className="mt">ข้อมูลติดต่อ</h3>
                                        <div className="row">
                                            <div className={`col-12 input`}>
                                                <Field
                                                    name="address"
                                                    component={Input}
                                                    placeholder="กรุณากรอกที่อยู่องค์กร"
                                                    label="ที่อยู่"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className={`col-6 input`}>
                                                <Field
                                                    name="phone"
                                                    component={Input}
                                                    placeholder="กรุณากรอกเบอร์โทรศัพท์"
                                                    label="เบอร์โทรศัพท์"
                                                />
                                            </div>
                                            <div className={`col-6 input`}>
                                                <Field
                                                    name="telephone"
                                                    component={Input}
                                                    placeholder="กรุณากรอกเบอร์โทรศัพท์มือถือ"
                                                    label="เบอร์โทรศัพท์มือถือ"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className={`col-3 input`}>
                                                <Field
                                                    name="facebook"
                                                    component={Input}
                                                    placeholder="กรุณากรอก Facebook"
                                                    label="Facebook"
                                                />
                                            </div>
                                            <div className={`col-3 input`}>
                                                <Field
                                                    name="lineID"
                                                    component={Input}
                                                    placeholder="กรุณากรอก Line ID"
                                                    label="Line ID"
                                                />
                                            </div>
                                            <div className={`col-3 input`}>
                                                <Field
                                                    name="twitter"
                                                    component={Input}
                                                    placeholder="กรุณากรอก X (Twitter)"
                                                    label="X (Twitter)"
                                                />
                                            </div>
                                            <div className={`col-3 input`}>
                                                <Field
                                                    name="instagram"
                                                    component={Input}
                                                    placeholder="กรุณากรอก Instagram"
                                                    label="Instagram"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className={`col-3 input`}>
                                                <Field
                                                    name="youtube"
                                                    component={Input}
                                                    placeholder="กรุณากรอก YouTube"
                                                    label="YouTube"
                                                />
                                            </div>
                                            <div className={`col-3 input`}>
                                                <Field
                                                    name="tiktok"
                                                    component={Input}
                                                    placeholder="กรุณากรอก Tiktok"
                                                    label="Tiktok"
                                                />
                                            </div>
                                            <div className={`col-3 input`}>
                                                <Field
                                                    name="email"
                                                    component={Input}
                                                    placeholder="กรุณากรอกอีเมล"
                                                    label="อีเมล"
                                                />
                                            </div>
                                            <div className={`col-3 input`}>
                                                <Field
                                                    name="website"
                                                    component={Input}
                                                    placeholder="กรุณากรอกเว็บไซต์"
                                                    label="เว็บไซต์"
                                                />
                                            </div>
                                        </div>
                                        <div className="divButton ">
                                            <button
                                                className="denyButton"
                                                type="button"
                                                onClick={() => {
                                                    handleSubmitOutside('prev');
                                                }}
                                            >
                                                {isEditing
                                                    ? 'ยกเลิก'
                                                    : 'ย้อนกลับ'}
                                            </button>
                                            <button
                                                className="acceptButton button-load"
                                                disabled={isSubmittingState}
                                                onClick={() => {
                                                    console.log(
                                                        errors,
                                                        touched
                                                    );
                                                    setCheckingError({
                                                        name_mini: true,
                                                        type_store: true,
                                                        type_company: true,
                                                        user_limit: true,
                                                        datePicker: true,
                                                        rental: true,
                                                        rental_ano: true,
                                                        rental_mag: true,
                                                        rental_ano_mag: true,
                                                        rental_news: true,
                                                        rental_ano_news: true,
                                                        storage_limit: true,
                                                        title_limit: true
                                                    });
                                                    handleSubmit();
                                                }}
                                            >
                                                {isEditing
                                                    ? 'บันทึก'
                                                    : 'เสร็จสิ้น'}
                                            </button>
                                        </div>
                                    </Form>
                                    {/* {isSubmittingState ? <Spin indicator={antIcon} /> : ''}  */}
                                </div>

                                <Modal
                                    backdrop="static"
                                    show={confirmModalIndex !== undefined}
                                    onHide={onHideConfirm}
                                    aria-labelledby="example-modal-sizes-title-lg"
                                    style={{ margin: '3% auto' }}
                                >
                                    <Modal.Body style={{ textAlign: 'center' }}>
                                        {' '}
                                        <img
                                            src="/media/members/image-modal.svg"
                                            alt=""
                                        />
                                        <div className="text-text-red">
                                            ยืนยันการลบ
                                        </div>
                                        <div className="text-text-gray">
                                            ต้องการลบอีเมลล์นี้หรือไม่?
                                        </div>
                                        <div className="mt-3">
                                            <button
                                                type="button"
                                                onClick={onHideConfirm}
                                                className="denyButton"
                                            >
                                                ยกเลิก
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    removeEmailSms(
                                                        confirmModalIndex,
                                                        values
                                                            .registerByPhoneDetail
                                                            .email,
                                                        setFieldValue
                                                    );
                                                    setConfirmModalIndex(
                                                        undefined
                                                    );
                                                }}
                                                className="acceptButton"
                                            >
                                                ยืนยัน
                                            </button>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </CardBody>
                        </Card>
                    );
                }}
            </Formik>
            <ModalsSuccess
                show={successModal}
                onHide={() => onHide()}
                onClickAccept={() => {
                    onHide();
                }}
            />
            <Modal
                backdrop="static"
                size="md"
                show={duplicateCompanyModal}
                onHide={() => setDuplicateCompanyModal(false)}
                style={{ marginTop: '27px' }}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Body
                    className=" body-modal"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                        <img
                            src="/media/bg/close.svg"
                            alt=""
                            style={{ width: '50%' }}
                        />
                    </div>
                    <div className="divText-modal">
                        ชื่อย่อองค์กร(ภาษาอังกฤษ)ซ้ำกับที่มีในระบบ
                    </div>
                    <div>
                        <button
                            className="acceptButton "
                            onClick={() => {
                                setDuplicateCompanyModal(false);
                            }}
                            type="submit"
                        >
                            ตกลง
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default FormForFile;
