/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../_redux/podcasts/podcastsActions';
import { message, Empty } from 'antd';
import {
   getSelectRow,
   getHandlerTableChange,
   NoRecordsFoundMessage,
   PleaseWaitMessage,
   sortCaret,
   headerSortingClasses
} from '../../../../../../_metronic/_helpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../../../../../_metronic/_partials/controls';
import { usePodcastsUIContext } from '../PodcastsUIContext';
import { ClipLoader } from 'react-spinners';

import { NodataView } from '../../../../CustomView/NodataView';
import { LoaderView } from '../../../../CustomView/LoaderView';

export function PodsTable({ isPrivate, isFree, isPodcast }) {
   // Podcasts UI Context
   const podcastsUIContext = usePodcastsUIContext();
   const podcastsUIProps = useMemo(() => {
      return {
         queryParams: podcastsUIContext.queryParams,
         setQueryParams: podcastsUIContext.setQueryParams,
         openEditPodDialog: podcastsUIContext.openEditPodDialog,
         openViewPodcastDialog: podcastsUIContext.openViewPodcastDialog,
         openDeletePodDialog: podcastsUIContext.openDeletePodDialog,
         openBlockPodDialog: podcastsUIContext.openBlockPodDialog,
         openUnblockPodDialog: podcastsUIContext.openUnblockPodDialog
      };
   }, [podcastsUIContext]);

   // Getting current state of podcasts list from store (Redux)
   const { currentState, role } = useSelector(
      state => ({ currentState: state.podcasts, role: state.auth.role }),
      shallowEqual
   );
   const {
      totalCount,
      entities,
      entities_free,
      entities_hti,
      listLoading,
      LastEvaluatedKey,
      LastEvaluatedKey_free,
      LastEvaluatedKey_hti,
      error
   } = currentState;
   const loaderHeight = 50;
   const [loadingCount, setLoadingCount] = useState(0);
   const [isError, setIsError] = useState(false);
   const [isEmpty, setEmpty] = useState(false);
   const footerHeight = 300;
   const [loadingBottom, setLoadingBottom] = useState(false);
   const [isSearching, setSearching] = useState(false);
   const [deleteUIDTemp, setDeleteUIDTemp] = useState('');

   const dispatch = useDispatch();
   const history = useHistory();

   useEffect(() => {
      if (error === '5003') {
         setIsError('5003');
      }
   }, [error]);

   // const isBottom = () => {
   //   let scrollHeight = Math.max(
   //     document.body.scrollHeight,
   //     document.documentElement.scrollHeight,
   //     document.body.offsetHeight,
   //     document.documentElement.offsetHeight,
   //     document.body.clientHeight,
   //     document.documentElement.clientHeight
   //   );
   //   return (
   //     window.pageYOffset + window.innerHeight >=
   //     scrollHeight - 3 * loaderHeight - footerHeight
   //   );
   // };

   const trackScrolling = () => {
      if (isBottom()) {
         setLoadingBottom(true);
      }
   };

   // useEffect(() => {
   //   document.addEventListener("scroll", trackScrolling);
   //   // eslint-disable-next-line
   // }, []);

   // useEffect(() => {
   //   return () => {
   //     setLoadingCount(0);
   //     document.removeEventListener("scroll", trackScrolling);
   //   };
   //   // eslint-disable-next-line
   // }, []);

   useEffect(() => {
      if (isPrivate) {
         if (
            LastEvaluatedKey === undefined ||
            JSON.stringify(LastEvaluatedKey) === JSON.stringify({})
         ) {
            setEmpty(true);
         } else if (loadingBottom && !listLoading) {
            setEmpty(false);
            dispatch(
               actions.fetchPodcasts(
                  podcastsUIProps.queryParams,
                  isPrivate,
                  isFree,
                  LastEvaluatedKey,
                  setLoadingBottom
               )
            );
         }
      }
   }, [LastEvaluatedKey, loadingBottom, listLoading]);

   useEffect(() => {
      // server call by queryParams
      if (isPodcast === 0) {
         dispatch(
            actions.fetchPodcasts(
               podcastsUIProps.queryParams,
               isPrivate,
               isFree,
               undefined,
               setLoadingBottom,
               setSearching
            )
         );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [podcastsUIProps.queryParams, dispatch, isPrivate, isFree, isPodcast]);

   // useEffect(() => {
   //   if (isFree) {
   //     if (
   //       LastEvaluatedKey_free === undefined ||
   //       JSON.stringify(LastEvaluatedKey_free) === JSON.stringify({})
   //     ) {
   //       setEmpty(true);
   //     } else if (loadingBottom) {
   //       setEmpty(false);
   //       dispatch(
   //         actions.fetchPodcasts(
   //           podcastsUIProps.queryParams,
   //           isPrivate,
   //           isFree,
   //           LastEvaluatedKey_free,
   //           setLoadingBottom
   //         )
   //       );
   //     }
   //   }
   // }, [LastEvaluatedKey_free, loadingBottom]);

   // useEffect(() => {
   //   if (!isFree && !isPrivate) {
   //     if (
   //       LastEvaluatedKey_hti === undefined ||
   //       JSON.stringify(LastEvaluatedKey_hti) === JSON.stringify({})
   //     ) {
   //       setEmpty(true);
   //     } else if (loadingBottom) {
   //       setEmpty(false);
   //       dispatch(
   //         actions.fetchPodcasts(
   //           podcastsUIProps.queryParams,
   //           isPrivate,
   //           isFree,
   //           LastEvaluatedKey_hti,
   //           setLoadingBottom
   //         )
   //       );
   //     }
   //   }
   // }, [LastEvaluatedKey_hti, loadingBottom]);
   // Podcasts Redux state

   const getRefreshContent = content => {
      // dispatch(
      //   actions1.podcastUpdated({
      //     id: content.contentUID,
      //     podcast: content,
      //   })
      // );
      message.success(`"${content.title}" มีสถานะล่าสุดแล้ว`);
   };

   useEffect(() => {
      if (entities && entities.length > -1 && loadingCount < 2) {
         setLoadingCount(loadingCount + 1);
      }
   }, [entities]);

   useEffect(() => {
      if (entities_hti && entities_hti.length > -1 && loadingCount < 2) {
         setLoadingCount(loadingCount + 1);
      }
   }, [entities_hti]);

   useEffect(() => {
      if (entities_free && entities_free.length > -1 && loadingCount < 2) {
         setLoadingCount(loadingCount + 1);
      }
   }, [entities_free]);

   useEffect(() => {
      setSearching(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [podcastsUIProps.queryParams]);

   const minWidthOfAll = 1090;
   const minusOfAll = 380;
   const [tableWidth, setTableWidth] = useState(
      Math.max(
         document.body.scrollWidth,
         document.documentElement.scrollWidth,
         document.body.offsetWidth,
         document.documentElement.offsetWidth,
         document.documentElement.clientWidth
      ) <
         minWidthOfAll + minusOfAll
         ? minWidthOfAll
         : Math.max(
              document.body.scrollWidth,
              document.documentElement.scrollWidth,
              document.body.offsetWidth,
              document.documentElement.offsetWidth,
              document.documentElement.clientWidth
           ) - minusOfAll
   );

   const isBottom = () => {
      const getScroll = document
         .getElementById('custom-table-scroll')
         .getElementsByTagName('tbody')[0];
      const offsetHeight = Math.ceil(getScroll.offsetHeight);
      const scrollTop = Math.ceil(getScroll.scrollTop);
      if (
         !listLoading &&
         getScroll.scrollHeight - (scrollTop + offsetHeight) <= 1
      ) {
         return true;
      } else {
         return false;
      }
   };

   const resize = () => {
      if (
         document.getElementById('custom-table-scroll') &&
         loadingCount === 2
      ) {
         setTableWidth(
            document
               .getElementById('custom-table-scroll')
               .getBoundingClientRect().width < minWidthOfAll
               ? minWidthOfAll
               : document
                    .getElementById('custom-table-scroll')
                    .getBoundingClientRect().width
         );
      }
   };
   const forceDeletePodcast = () => {
      dispatch(
         actions.deletePodcast(deleteUIDTemp, isPrivate, { forceReturn: true })
      ).then(() => {
         // refresh list after deletion
         setIsError(false);
         dispatch(actions.fetchPodcast(podcastsUIProps.queryParams, isPrivate));
         // clear selections list

         // closing block modal
      });
   };

   useEffect(() => {
      window.addEventListener('resize', resize);
      if (
         document.getElementById('custom-table-scroll') &&
         document
            .getElementById('custom-table-scroll')
            .getElementsByTagName('tbody')[0] &&
         loadingCount === 2
      ) {
         document
            .getElementById('custom-table-scroll')
            .getElementsByTagName('tbody')[0]
            .addEventListener('scroll', trackScrolling);
      }
      // eslint-disable-next-line
   }, [loadingCount]);

   useEffect(() => {
      return () => {
         window.removeEventListener('resize', resize);
         setLoadingCount(0);
         if (
            document.getElementById('custom-table-scroll') &&
            document
               .getElementById('custom-table-scroll')
               .getElementsByTagName('tbody')[0]
         ) {
            document
               .getElementById('custom-table-scroll')
               .getElementsByTagName('tbody')[0]
               .removeEventListener('scroll', trackScrolling);
         }
      };
      // eslint-disable-next-line
   }, []);

   const widthOfAll = 60 + 100 + 250 + 105 + 80 + 80 + 80 + 100 + 240;

   // Table columns
   const columns = [
      {
         dataField: 'none',
         text: 'ลำดับ',
         sortCaret: sortCaret,
         headerSortingClasses,
         formatter: columnFormatters.IndexColumnFormatter,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return <div>{column.text}</div>;
         },
         formatExtraData: {
            pageNumber: podcastsUIProps.queryParams.pageNumber,
            pageSize: podcastsUIProps.queryParams.pageSize
         }
      },
      {
         dataField: 'coverImage',
         text: 'ปก',
         sortCaret: sortCaret,
         headerSortingClasses,
         formatter: columnFormatters.ImageColumnFormatter,
         formatExtraData: {
            openViewPodcastDialog: podcastsUIProps.openViewPodcastDialog,
            isPrivate
         },
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return <div>{column.text}</div>;
         }
      },
      {
         dataField: 'title',
         text: 'รายละเอียด',
         // sort: true,
         sortCaret: sortCaret,
         headerSortingClasses,
         formatter: columnFormatters.DetailColumnFormatter,
         formatExtraData: {
            openEditPodDialog: podcastsUIProps.openEditPodDialog,
            isPrivate,
            getRefreshContent
         },
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return <div>{column.text}</div>;
         }
      },
      {
         dataField: 'episodeAmount',
         text: 'ตอนทั้งหมด',
         sortCaret: sortCaret,
         formatter: columnFormatters.TextColumnFormatter,
         formatExtraData: {
            isPrivate
         },
         headerSortingClasses,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return <div>{column.text}</div>;
         }
      },
      {
         dataField: 'none1',
         text: 'คงเหลือ',
         sortCaret: sortCaret,
         formatter: columnFormatters.RemainAmountColumnFormatter,
         formatExtraData: {
            isPrivate
         },
         headerSortingClasses,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return <div>{column.text}</div>;
         }
      },
      {
         dataField: 'waitingAmount',
         text: 'จองคิว',
         sortCaret: sortCaret,
         formatter: columnFormatters.WaitingAmountFormatter,
         formatExtraData: {
            isPrivate
         },
         headerSortingClasses,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return <div>{column.text}</div>;
         }
      },
      {
         dataField: 'rentAmount',
         text: 'ยืมแล้ว',
         sortCaret: sortCaret,
         formatter: columnFormatters.TextAmountColumnFormatter,
         headerSortingClasses,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return <div>{column.text}</div>;
         }
      },
      {
         dataField: 'status',
         text: 'สถานะ',
         formatter: columnFormatters.StatusColumnFormatter,
         formatExtraData: {
            isPrivate,
            openBlockPodDialog: podcastsUIProps.openBlockPodDialog,
            openUnblockPodDialog: podcastsUIProps.openUnblockPodDialog,
            role,
            setIsError
         },
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return <div>{column.text}</div>;
         }
      },
      {
         dataField: 'contentUID',
         text: 'การจัดการ',
         formatter: columnFormatters.ActionsColumnFormatter,
         formatExtraData: {
            setDeleteUIDTemp,
            isPrivate,
            openEditPodDialog: podcastsUIProps.openEditPodDialog,
            openViewPodcastDialog: podcastsUIProps.openViewPodcastDialog,
            openDeletePodDialog: podcastsUIProps.openDeletePodDialog,
            role
         },
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return <div>{column.text}</div>;
         }
      }
   ];
   const isNotFound = () => {
      return (
         (isPrivate &&
            entities &&
            Array.isArray(entities) &&
            entities.length === 0 &&
            !listLoading) ||
         (!isFree &&
            (!isPrivate || isPrivate === undefined) &&
            entities_hti &&
            Array.isArray(entities_hti) &&
            entities_hti.length === 0 &&
            !listLoading) ||
         (isFree &&
            (!isPrivate || isPrivate === undefined) &&
            entities_free &&
            Array.isArray(entities_free) &&
            entities_free.length === 0 &&
            !listLoading)
      );
   };
   return loadingCount === 2 ? (
      // <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      //   {({ paginationProps, paginationTableProps }) => {
      //     return (
      //       <Pagination isLoading={listLoading} paginationProps={paginationProps}>
      <>
         <div className="PodcastTable">
            <BootstrapTable
               wrapperClasses="table-responsive"
               bordered={false}
               classes={`table table-head-custom table-vertical-center custom-table-scroll ${
                  isNotFound() ? 'isNotFound' : ''
               }`}
               id="custom-table-scroll"
               bootstrap4
               remote
               keyField="contentUID"
               data={
                  isSearching
                     ? []
                     : isPrivate
                     ? !Array.isArray(entities)
                        ? []
                        : entities
                     : isFree
                     ? !Array.isArray(entities_free)
                        ? []
                        : entities_free
                     : !Array.isArray(entities_hti)
                     ? []
                     : entities_hti
               }
               columns={
                  isFree
                     ? columns.filter(el => {
                          return (
                             el.text !== 'ทั้งหมด' &&
                             el.text !== 'คงเหลือ' &&
                             el.text !== 'จองคิว'
                          );
                       })
                     : columns
               }
               onTableChange={getHandlerTableChange(
                  podcastsUIProps.setQueryParams
               )}
               // selectRow={getSelectRow({
               //   entities,
               //   ids: podcastsUIProps.ids,
               //   setIds: podcastsUIProps.setIds,
               // })}
               // {...paginationTableProps}
            >
               <PleaseWaitMessage
                  entities={
                     isPrivate
                        ? entities
                        : isFree
                        ? entities_free
                        : entities_hti
                  }
               />
               <NoRecordsFoundMessage
                  entities={
                     isPrivate
                        ? entities
                        : isFree
                        ? entities_free
                        : entities_hti
                  }
               />
            </BootstrapTable>
         </div>{' '}
         {isNotFound() ? (
            podcastsUIProps.queryParams.keyword ? (
               <NodataView isSearch={true} />
            ) : (
               <NodataView title="ไม่มีข้อมูลสื่อ" />
            )
         ) : (
            ''
         )}
         {!error &&
         listLoading &&
         LastEvaluatedKey !== undefined &&
         (!isEmpty || loadingBottom) ? (
            <div className="cliploader-center">
               <ClipLoader
                  size={loaderHeight}
                  color={'#123abc'}
                  loading={true}
               />
            </div>
         ) : !error && !isEmpty ? (
            // <div style={{ minHeight: `${loaderHeight}px` }} />
            ''
         ) : (
            ''
         )}
         <Modal
            backdrop="static"
            show={isError}
            onHide={() => {
               setIsError(false);
            }}
            aria-labelledby="example-modal-sizes-title-lg"
            style={{ margin: '3% auto' }}
            size="md"
         >
            <Modal.Body
               style={{
                  textAlign: 'center'
               }}
            >
               <img src="/media/members/image-modal.svg" alt="" />
               <div
                  style={{
                     fontSize: '20px',
                     fontWeight: 600,
                     color: '#d7042b',
                     marginBottom: '12px'
                  }}
               >
                  {isError === '5003' ? (
                     <>
                        <p style={{ marginBottom: '0px' }}>
                           มีการใช้งานสื่อนี้อยู่
                        </p>
                        <p>ต้องการที่จะลบหรือไม่</p>
                     </>
                  ) : isError === 'fixFileFirst' ? (
                     <>
                        <p style={{ marginBottom: '0px' }}>
                           กรุณาซ่อมไฟล์ก่อนเปิดใช้งานสื่อนี้
                        </p>
                     </>
                  ) : (
                     ''
                  )}
               </div>
               <div style={{ marginTop: '36px' }}>
                  {isError === '5003' ? (
                     <>
                        <button
                           type="button"
                           onClick={() => {
                              setIsError(false);
                           }}
                           className="denyButton"
                        >
                           ยกเลิก
                        </button>
                        <button
                           type="button"
                           onClick={() => {
                              forceDeletePodcast();
                           }}
                           className="acceptButton"
                        >
                           ตกลง
                        </button>
                     </>
                  ) : (
                     <button
                        type="button"
                        onClick={() => {
                           setIsError(false);
                        }}
                        className="acceptButton"
                     >
                        ตกลง
                     </button>
                  )}
               </div>
            </Modal.Body>
         </Modal>
      </>
   ) : (
      //       </Pagination>
      //     );
      //   }}
      // </PaginationProvider>
      <LoaderView />
   );
}
