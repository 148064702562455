import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { CustomersLoadingDialog } from "./customers-loading-dialog/CustomersLoadingDialog";
import { CustomerEditDialog } from "./customer-edit-dialog/CustomerEditDialog";
import { CustomerDeleteDialog } from "./customer-delete-dialog/CustomerDeleteDialog";
import { CustomerBlockDialog } from "./customer-block-dialog/CustomerBlockDialog";
import { CustomerUnblockDialog } from "./customer-unblock-dialog/CustomerUnblockDialog";
import { CustomerBannedDialog } from "./customer-banned-dialog/CustomerBannedDialog.js";
import { CustomerResetPwDialog } from "./customer-resetPw-dialog/CustomerResetPwDialog";
import { CustomersDeleteDialog } from "./customers-delete-dialog/CustomersDeleteDialog";
import { CustomersFetchDialog } from "./customers-fetch-dialog/CustomersFetchDialog";
import { CustomersUpdateStateDialog } from "./customers-update-status-dialog/CustomersUpdateStateDialog";
import { CustomerDevicesDialog } from "./customer-devices-dialog/CustomerDevicesDialog";
import { UploadInformationDialog } from "../upload/upload-information-dialog/UploadInformationDialog";
import { CustomersUIProvider } from "./CustomersUIContext";
import { CustomersCard } from "./CustomersCard";
import queryString from "query-string";
import { QRCodeGeneratorDialog } from "./qrcode-generator-dialog/QRCodeGeneratorDialog";
// import "./customer.scss";

export function CustomersPage({ history, memberAmountData, location }) {
  const [devicesList, setDevicesList] = useState([]);
  const [isError, setError] = useState(false);
  const [isNewMember, setNewMember] = useState(false);
  const [isNewQrcode, setNewQrcode] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isBlock, setBlock] = useState(false);
  const [isUnblock, setUnblock] = useState(false);
  const [isBanned, setBanned] = useState(false);
  const [isResetpw, setResetpw] = useState(false);
  const [isDevices, setDevices] = useState(false);
  const [id, setId] = useState(null);
  const [staffId, setStaffId] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [errorMessage, setErrorMessage] = useState(undefined);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.error) {
      setError(true);
    }
    if (parsed.newMember) {
      setNewMember(1);
    }
    if (parsed.newQrcode) {
      setNewQrcode(1);
    }
    if (parsed.id) {
      setId(parsed.id);
    }
    if (parsed.staffId) {
      setStaffId(parsed.staffId);
    }
    if (parsed.name) {
      setName(parsed.name);
    }
    if (parsed.email) {
      setEmail(parsed.email);
    }
    if (parsed.edit) {
      setEdit(true);
    }
    if (parsed.delete) {
      setDelete(true);
    }
    if (parsed.block) {
      setBlock(true);
    }
    if (parsed.unblock) {
      setUnblock(true);
    }
    if (parsed.banned) {
      setBanned(true);
    }
    if (parsed.resetpw) {
      setResetpw(true);
    }
    if (parsed.devices) {
      setDevices(true);
    }
  }, [location.search]);

  const customersUIEvents = {
    openFileInformationDialog: (error, message) => {
      if (error) {
        history.push("/members?error=true");
        setErrorMessage(message);
      }
    },
    newCustomerButtonClick: () => {
      history.push("/members?newMember=true");
    },
    newQRCodeButtonClick: () => {
      history.push("/members?newQrcode=true");
    },
    openEditCustomerDialog: (id) => {
      history.push(`/members?id=${id}&edit=true`);
    },
    openDeleteCustomerDialog: (id, email) => {
      history.push(
        `/members?id=${id}&email=${email ? email : "Anonymous"}&delete=true`
      );
    },
    openBlockCustomerDialog: (id, email) => {
      history.push(
        `/members?id=${id}&email=${email ? email : "Anonymous"}&block=true`
      );
    },
    openUnblockCustomerDialog: (id, email) => {
      history.push(`/members?id=${id}&email=${email}&unblock=true`);
    },
    openBannedCustomerDialog: (email) => {
      history.push(`/members?email=${email}&banned=true`);
    },
    openResetPwCustomerDialog: (id) => {
      history.push(`/members?id=${id}&resetpw=true`);
    },
    openDeleteCustomersDialog: () => {
      history.push(`/members/deleteCustomers`);
    },
    openFetchCustomersDialog: () => {
      history.push(`/members/fetch`);
    },
    openUpdateCustomersStatusDialog: () => {
      history.push("/members/updateStatus");
    },
    openCustomerDevicesDialog: (devices, id, staffId, name) => {
      setDevicesList(devices);
      history.push(
        `/members?id=${id}&staffId=${staffId ? staffId : "Anonymous"}&name=${
          name ? name : "Anonymous"
        }&devices=true`
      );
    },
  };

  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <CustomersLoadingDialog />
      {isNewMember ? (
        <CustomerEditDialog
          show={true}
          onHide={() => {
            history.push("/members");
            setNewMember(false);
          }}
        />
        
      ) : (
        ""
      )}
      {isNewQrcode ? (
        <QRCodeGeneratorDialog
          show={true}
          onHide={() => {
            history.push("/members");
            setNewQrcode(false);
          }}
        />
      ) : (
        ""
      )}
      {isEdit ? (
        <CustomerEditDialog
          show={true}
          id={id}
          onHide={() => {
            history.push("/members");
            setEdit(false);
          }}
        />
      ) : (
        ""
      )}
      {isError ? (
        <UploadInformationDialog
          show={true}
          onHide={() => {
            history.push(`/members`);
            setError(false);
            setErrorMessage(undefined);
          }}
          isError={true}
          errorMessage={errorMessage}
        />
      ) : (
        ""
      )}
      {isDelete ? (
        <CustomerDeleteDialog
          show={true}
          email={email}
          UID={id}
          onHide={() => {
            history.push("/members");
            setDelete(false);
            setId("");
            setEmail("");
          }}
        />
      ) : (
        ""
      )}
      {isBlock ? (
        <CustomerBlockDialog
          show={true}
          id={id}
          email={email}
          onHide={() => {
            history.push("/members");
            setBlock(false);
            setId("");
            setEmail("");
          }}
        />
      ) : (
        ""
      )}
      {isUnblock ? (
        <CustomerUnblockDialog
          show={true}
          id={id}
          email={email}
          onHide={() => {
            history.push("/members");
            setUnblock(false);
            setId("");
            setEmail("");
          }}
        />
      ) : (
        ""
      )}
      {isBanned ? (
        <CustomerBannedDialog
          show={true}
          email={email}
          onHide={() => {
            history.push("/members");
            setEmail("");
            setBanned(false);
          }}
        />
      ) : (
        ""
      )}
      {isResetpw ? (
        <CustomerResetPwDialog
          show={true}
          id={id}
          onHide={() => {
            history.push("/members");
            setResetpw(false);
            setId("");
          }}
        />
      ) : (
        ""
      )}
      {isDevices ? (
        <CustomerDevicesDialog
          show={true}
          UID={id}
          id={staffId}
          name={decodeURIComponent(name)}
          devices={devicesList}
          onHide={() => {
            history.push("/members");
            setDevicesList([]);
            setDevices(false);
            setId("");
            setStaffId("");
          }}
        />
      ) : (
        ""
      )}
      <Route path="/members/deleteCustomers">
        {({ history, match }) => (
          <CustomersDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/members");
            }}
          />
        )}
      </Route>
      <Route path="/members/fetch">
        {({ history, match }) => (
          <CustomersFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/members");
            }}
          />
        )}
      </Route>
      <Route path="/members/updateStatus">
        {({ history, match }) => (
          <CustomersUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/members");
            }}
          />
        )}
      </Route>
      <CustomersCard memberAmountData={memberAmountData} />
    </CustomersUIProvider>
  );
}
