import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/ebooks/ebooksActions";
import { useEbooksUIContext } from "../EbooksUIContext";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";

export function EbooksDeleteDialog({ show, onHide }) {
  // Ebooks UI Context
  const ebooksUIContext = useEbooksUIContext();
  const ebooksUIProps = useMemo(() => {
    return {
      ids: ebooksUIContext.ids,
      setIds: ebooksUIContext.setIds,
      queryParams: ebooksUIContext.queryParams,
    };
  }, [ebooksUIContext]);

  // Ebooks Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.ebooks.actionsLoading }),
    shallowEqual
  );

  // if ebooks weren't selected we should close modal
  useEffect(() => {
    if (!ebooksUIProps.ids || ebooksUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ebooksUIProps.ids]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteEbooks = () => {
    // server request for deleting ebook by selected ids
    // dispatch(actions.deleteEbooks(ebooksUIProps.ids)).then(() => {
    //   // refresh list after deletion
    //   dispatch(actions.fetchEbooks(ebooksUIProps.queryParams)).then(
    //     () => {
    //       // clear selections list
    //       ebooksUIProps.setIds([]);
    //       // closing delete modal
    //       onHide();
    //     }
    //   );
    // });
  };

  return (
    <Modal
    backdrop="static"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Ebooks Delete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to permanently delete selected ebooks?</span>
        )}
        {isLoading && <span>Ebook are deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteEbooks}
            className="btn btn-primary btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
