import React from "react";
import { Modal } from "react-bootstrap";
import { Modal as ModalAntd } from "antd";

import { ModalProgressBar } from "../../_metronic/_partials/controls";

const { confirm } = ModalAntd;

interface ConfirmModalProps {
  show: boolean;
  isLoading: boolean;
  onHide: () => void;
  name: string;
}

// function confirmModal(props: ConfirmModalProps) {
//     const { show, isLoading ,onHide,name} = props;
//   return (
//     <Modal
//       backdrop="static"
//       show={show}
//       onHide={onHide}
//       aria-labelledby="example-modal-sizes-title-lg"
//       style={{ margin: "3% auto" }}
//     >
//       {isLoading && <ModalProgressBar />}
//       <Modal.Body >
//         {name ? (
//           <>
//             <img src="/media/members/image-modal.svg" alt="" />
//             <div className="text-text-red">ยืนยันการลบ</div>
//             <div className="text-text-gray">ต้องการลบ {name} หรือไม่?</div>

//             <div className="mt-3">
//               <button type="button" onClick={onHide} className="denyButton ">
//                 ยกเลิก
//               </button>
//               <button
//                 type="button"
//                 onClick={deleteEbook}
//                 className="acceptButton "
//               >
//                 ยืนยัน
//               </button>
//             </div>
//           </>
//         ) : (
//           ""
//         )}
//       </Modal.Body>
//     </Modal>
//   );
// }

// export default confirmModal;

export const confirmModal = (okAction?: () => Promise<void>, text?: string) => {
  confirm({
    icon: null,
    wrapClassName: "custom-confirm-modal",
    okText: "ตกลง",
    cancelText: "ยกเลิก",
    width: 500,
    content: (
      <>
        <div className="logo">
          <img src="/media/members/image-modal.svg" alt="" />
        </div>

        <div className="text-group">
          <div className="text-text-red">ยืนยันการลบ</div>
          <div className="text-text-gray">{text}</div>
        </div>
      </>
    ),
    async onOk() {
      if (okAction) {
        await okAction();
      }
    },
    onCancel() {
      console.log("Cancel");
    },
  });
};
