import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";

import { useStyles } from "../../partials/useStyles";

export default function SettingDeleteModal({ name, show, onHide ,onConfirm}) {
     // Banners UI Context
   

     
     const classes = useStyles();

     return (
          <Modal
               backdrop="static"
               show={show}
               onHide={onHide}
               aria-labelledby="example-modal-sizes-title-lg"
               style={{ margin: "3% auto" }}
          >
               {/*begin::Loading*/}
            
               {/*end::Loading*/}
               {/* <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Banner Delete
        </Modal.Title>
      </Modal.Header> */}
               <Modal.Body   className={classes.body}>
                    <img src="/media/members/image-modal.svg" alt="" />
                    <div className="text-text-red">ยืนยันการลบ</div>
                    <div className="text-text-gray">ต้องการลบ {name} หรือไม่?</div>
                    <div className="mt-3">
                         <button type="button" onClick={onHide} className="denyButton">
                              ยกเลิก
                         </button>
                         <button type="button" onClick={onConfirm} className="acceptButton">
                              ยืนยัน
                         </button>
                    </div>
               </Modal.Body>
          </Modal>
     );
}
