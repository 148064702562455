/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/customers/customersActions";
import { useCustomersUIContext } from "../CustomersUIContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  body: {
    textAlign: "center",
  },
  title: {
    fontSize: "20px",
    fontWeight: 600,
    color: "#d7042b",
  },
  subtitle: {
    fontFamily: "Prompt",
    fontSize: "16px",
  },
}));
interface CustomerBannedModalProps {
  email: string;
  isShow: boolean;
  setIsShowModal: (show: boolean) => void;
  onConfirm: () => void;
}

export default function CustomerBannedModal({
  email,
  isShow,
  setIsShowModal,
  onConfirm,
}: CustomerBannedModalProps) {
  // Customers UI Context

  // if !id we should close modal

  // looking for loading/dispatch

  const classes = useStyles();

  return (
    <Modal
      backdrop="static"
      show={isShow}
      aria-labelledby="example-modal-sizes-title-lg"
      style={{ margin: "3% auto" }}
    >
      {/* {isLoading && <ModalProgressBar />} */}
      <Modal.Body className={classes.body}>
        {email ? (
          <>
            <img src="/media/members/image-modal.svg" alt="" />
            {/* {!isLoading ? ( */}
            <>
              <div className={classes.title}>ยืนยันการปลดบล็อก</div>
              <div className={classes.subtitle}>ต้องการปลดบล็อกสมาชิก</div>
              <div className={classes.subtitle}>{`${
                email === "Anonymous" ? email : `อีเมล ${email}`
              } หรือไม่?`}</div>

              <div className="divButton">
                <button
                  type="button"
                  onClick={() => setIsShowModal(false)}
                  className="denyButton "
                >
                  ยกเลิก
                </button>
                <button
                  type="button"
                  onClick={() => onConfirm()}
                  className="acceptButton "
                >
                  ยืนยัน
                </button>
              </div>
            </>
            {/* ) : (
                         <div className={classes.subtitle} style={{ marginBottom: "36px" }}>
                               กำลังบล็อกสมาชิก...
                         </div>
                     )} */}
          </>
        ) : (
          ""
        )}
      </Modal.Body>
    </Modal>
  );
}
