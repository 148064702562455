import React, { useMemo, useEffect } from 'react';
import { AsideMenuList } from './AsideMenuList';
import { useHtmlClassService } from '../../../_core/MetronicLayout';
import J from '../../../../../../package.json';
import { makeStyles } from '@material-ui/core/';
import { shallowEqual, useSelector } from 'react-redux';
import axios from 'axios';

const useStyles = makeStyles(() => ({
   versionLabel: {
      bottom: '0',
      position: 'absolute',
      color: '#bdbdbd',
      width: '100%',
      margin: '0',
      paddingLeft: '5px'
   },
   downloadLabel: {
      // left: "108px",
      // bottom: "10px",
      // position: "absolute",
      textDecoration: 'underline',
      fontWeight: '500',
      fontSize: '1rem',
      color: 'rgb(1, 40, 117)',
      margin: 'auto',
      '&:hover': { cursor: 'pointer' },
      textAlign: 'end',
      paddingRight: '5px'
   }
}));

export function AsideMenu({ disableScroll }) {
   const uiService = useHtmlClassService();
   const classes = useStyles();
   const layoutProps = useMemo(() => {
      return {
         layoutConfig: uiService.config,
         asideMenuAttr: uiService.getAttributes('aside_menu'),
         ulClasses: uiService.getClasses('aside_menu_nav', true),
         asideClassesFromConfig: uiService.getClasses('aside_menu', true)
      };
   }, [uiService]);
   const { authState } = useSelector(
      ({ auth }) => ({
         authState: auth
      }),
      shallowEqual
   );
   const { role } = authState;
   return (
      <>
         {/* begin::Menu Container */}
         <div
            id="kt_aside_menu"
            data-menu-vertical="1"
            className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
            {...layoutProps.asideMenuAttr}
         >
            <AsideMenuList layoutProps={layoutProps} />
         </div>
         {/* end::Menu Container */}
         <div className={`row ${classes.versionLabel}`}>
            <div className="col-4" style={{ padding: '0' }}>
               <span>Version {J.version}</span>
            </div>
            {!authState.isCuDomain ? (
               <div className={`col-8 ${classes.downloadLabel}`}>
                  <span
                     onClick={() => {
                        axios.get(`/admin/handbooks/download`).then(res => {
                           if (
                              res.data &&
                              res.data.data &&
                              res.data.data.fulfillmentUrl
                           ) {
                              let link = document.createElement('a');
                              link.setAttribute('download', 'template');
                              link.target = '_blank';
                              link.href = res.data.data.fulfillmentUrl;
                              document.body.appendChild(link);
                              link.click();
                              link.remove();
                           }
                        });
                     }}
                  >
                     ดาวน์โหลดคู่มือการใช้งาน
                  </span>
               </div>
            ) : null}
         </div>
      </>
   );
}
