/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles, withStyles, lighten } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import moment from "moment";
import { OverlayTrigger, Tooltip as Tooltip1 } from "react-bootstrap";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { DashboardMini } from "../../upload/DashboardMini";
import axios from "axios";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { Document, Page, pdfjs } from "react-pdf";
import { catalogueSlice } from "../../../_redux/catalogue/catalogueSlice";
import {
  EpubView, // Underlaying epub-canvas (wrapper for epub.js iframe)
} from "react-reader";
import { UploadInformationDialog } from "../../upload/upload-information-dialog/UploadInformationDialog";
import { Modals, ModalsSuccess } from "../../../../CustomView/ModalView";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const CancelToken = axios.CancelToken;
const actions = catalogueSlice.actions;

const useModalStyles = makeStyles(() => ({
  body: {
    textAlign: "center",
  },
}));

const useStyles = makeStyles(() => ({
  head: {
    fontSize: "20px",
    fontWeight: "600",
    margin: "16px auto 0",
    color: "#012875",
  },
  input: { margin: "12px 0" },

  blue_p: {
    fontSize: 20,
    fontWeight: 600,
    color: "#012875",
    marginBottom: "6px",
  },
  blue_p_small: {
    fontSize: 14,
    fontWeight: 400,
    color: "#012875",
    display: "inline-block",
  },

  body: { margin: "auto" },

  divFileModal: {
    width: "800px",
    height: "700px",
    borderRadius: "10px",
    backgroundColor: "#fff",
  },
  divText: { fontSize: "24px", fontWeight: "600", color: "#012875" },

  col: { margin: "8px 0 3px 0" },
  center: {
    textAlign: "center",
  },
  dropzoneTitle: { marginBottom: "0px", fontSize: "16px" },
  dropzoneSubTitle: { marginBottom: "36px", fontSize: "14px" },

  information: {
    backgroundColor: "#012875",
    width: 16,
    height: 16,
    borderRadius: 8,
    textAlign: "center",
    margin: "0 0 0 6px",
    display: "inline-block",
    "&:hover": { cursor: "pointer" },
  },
  progressUpload: {
    margin: "3px 13px 47px",
    borderRadius: "5px",
  },
  uploadedImg: {
    padding: "16px",
    height: "240px",
    maxWidth: "100%",
  },
}));

export const CatalogueEditFileAndCover = ({
  catalogueOrigin,
  catalogueValue,
  setCatalogueValue,
  setCatalogueDetail,
  contentUIDForUpdate,
  rentalPeriod,
  isPrivate,
  fixStatus,
  modalType,
  saveDetail,
  onHide,
  contentDetail,
  coverDetail,
  outSideFilePost,
  outSideCoverPost,
  fixFileOnly,
}) => {
  const [filePost, setFilePost] = useState(null);
  const [coverPost, setCoverPost] = useState(null);

  const [source, setSource] = useState(
    Object.assign({}, CancelToken.source(), { key: 0 })
  );
  const [token, setToken] = useState(source.token);

  useEffect(() => {
    setToken(source.token);
  }, [source]);

  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(0);
  const [total, setTotal] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const [loaded1, setLoaded1] = useState(0);
  const [total1, setTotal1] = useState(0);
  const [percentage1, setPercentage1] = useState(0);

  const [informationDialogType, setInformationDialogType] = useState(null);
  const [informationDialogMessage, setInformationDialogMessage] = useState(
    undefined
  );
  const [storageUsedNow, setStorageUsedNow] = useState(0);

  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadingCover, setUploadingCover] = useState(false);

  const [canUpload, setCanUpload] = useState(false);
  const [storageLeft, setStorageLeft] = useState(0);
  const [fileUsed, setFileUsed] = useState(0);
  const [fileName, setFileName] = useState(null);

  const [successFileUpload, setSuccessFileUpload] = useState(false);
  const [failedFileUpload, setFailedFileUpload] = useState(false);

  const [successCoverUpload, setSuccessCoverUpload] = useState(false);
  const [failedCoverUpload, setFailedCoverUpload] = useState(false);

  const [numPagesForBeforeUpdate, setNumPagesForBeforeUpdate] = useState(0);
  const [coverName, setCoverName] = useState(null);
  const [coverImg, setCoverImg] = useState(null);
  const [fileType, setFileType] = useState("pdf");
  const [successModal, setSuccessModal] = useState(false);
  const { currentState3 } = useSelector(
    (state) => ({
      currentState3: state.upload,
    }),
    shallowEqual
  );
  const BorderLinearProgress = withStyles({
    root: {
      height: 10,
      backgroundColor: lighten("#ff6c5c", 0.5),
    },
    bar: {
      borderRadius: 20,
      backgroundColor: "#ff6c5c",
    },
  })(LinearProgress);

  useEffect(() => {
    setFilePost(outSideFilePost);
  }, [outSideFilePost]);

  useEffect(() => {
    setCoverPost(outSideCoverPost);
  }, [outSideCoverPost]);

  useEffect(() => {
    if (filePost) {
      if (modalType === "content" || modalType === "both") {
        if (filePost.type.includes("epub")) {
          setFileType("epub");
        } else if (filePost.type.includes("pdf")) {
          setFileType("pdf");
        }
        setSuccessFileUpload(true);
      }
    } else {
      setFilePost(null);
    }

    if (coverPost) {
      if (modalType === "cover" || modalType === "both") {
        setCoverImg(URL.createObjectURL(coverPost));
        setSuccessCoverUpload(true);
      }
    } else {
      setCoverImg(null);
    }
  }, [filePost, coverPost, modalType]);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    accept: ".pdf, .epub",
    noClick: true,
    noKeyboard: true,
    maxSize: canUpload ? storageLeft : 0,
    onDropRejected: (e) => onDropRejected(e),
    onDropAccepted: (e) => onDropAccepted(e),
  });

  const {
    getRootProps: getRootProps1,
    getInputProps: getInputProps1,
    open: open1,
    acceptedFiles: acceptedFiles1,
  } = useDropzone({
    accept: modalType === "content" ? ".pdf, .epub" : ".png, .jpg, .jpeg",
    noClick: true,
    noKeyboard: true,
    maxSize: canUpload ? storageLeft : 0,
    onDropRejected: (e) => onDropRejected(e),
    onDropAccepted: (e) => {
      if (modalType === "content") {
        onDropAccepted(e);
      }
    },
  });

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPagesForBeforeUpdate(parseInt(numPages, 10));
  };

  useEffect(() => {
    const reqFunc = async () => {
      if (acceptedFiles && acceptedFiles.length) {
        setUploadingFile(true);
        const fileToUpload = acceptedFiles[0];
        if (fileToUpload) {
          setFileName(fileToUpload.name);
          if (fileToUpload.type.includes("epub")) {
            setFileType("epub");
          } else if (fileToUpload.type.includes("pdf")) {
            setFileType("pdf");
          }
          const req = await axios.post(`/admin/private/contents/file`, {
            fileName: fileToUpload.name,
            contentUID: contentUIDForUpdate ? contentUIDForUpdate : undefined,
          });
          if (req.data.status && req.data.data) {
            const urlToUpload = req.data.data.url;
            const fieldsToUpload = req.data.data.fields;
            // const UID = req.data.data.contentUID;
            // setContentUIDForUpdate(UID);

            var formData = new FormData();
            const entries = Object.entries(fieldsToUpload);
            for (const [key, val] of entries) {
              formData.append(key, val);
            }
            formData.append("file", fileToUpload);
            axios({
              method: "post",
              url: urlToUpload,
              data: formData,
              onUploadProgress: (progressEvent) => {
                if (progressEvent) {
                  setLoaded((progressEvent.loaded / 1000).toFixed(2));
                  setTotal((progressEvent.total / 1000).toFixed(2));
                  setPercentage(
                    (
                      (100 * progressEvent.loaded) /
                      progressEvent.total
                    ).toFixed()
                  );
                }
              },
              cancelToken: token,
            })
              .then(() => {
                setFilePost(fileToUpload);
                setUploadingFile(false);
                setSuccessFileUpload(true);
                setLoaded(0);
                setTotal(0);
                setPercentage(0);
              })
              .catch(() => {
                setFilePost(fileToUpload);
                setUploadingFile(false);
                setFailedFileUpload(true);
                setLoaded(0);
                setTotal(0);
                setPercentage(0);
              });
          }
        }
      }
    };
    reqFunc();
  }, [acceptedFiles]);

  useEffect(() => {
    const reqFunc = async () => {
      if (acceptedFiles1 && acceptedFiles1.length) {
        setUploadingCover(true);
        const fileToUpload = acceptedFiles1[0];
        if (fileToUpload) {
          setCoverName(fileToUpload.name);
          const req = await axios.post(`/admin/private/contents/file`, {
            fileName: fileToUpload.name,
            contentUID: contentUIDForUpdate ? contentUIDForUpdate : undefined,
          });
          if (req.data.status && req.data.data) {
            const urlToUpload = req.data.data.url;
            const fieldsToUpload = req.data.data.fields;
            // const UID = req.data.data.contentUID;
            // setContentUIDForUpdate(UID);

            var formData = new FormData();
            const entries = Object.entries(fieldsToUpload);
            for (const [key, val] of entries) {
              formData.append(key, val);
            }
            formData.append("file", fileToUpload);
            axios({
              method: "post",
              url: urlToUpload,
              data: formData,
              onUploadProgress: (progressEvent) => {
                if (progressEvent) {
                  setLoaded1((progressEvent.loaded / 1000).toFixed(2));
                  setTotal1((progressEvent.total / 1000).toFixed(2));
                  setPercentage1(
                    (
                      (100 * progressEvent.loaded) /
                      progressEvent.total
                    ).toFixed()
                  );
                }
              },
              cancelToken: token,
            })
              .then(() => {
                setCoverPost(fileToUpload);
                setUploadingCover(false);
                setSuccessCoverUpload(true);
                setLoaded1(0);
                setTotal1(0);
                setPercentage1(0);
              })
              .catch(() => {
                setCoverPost(fileToUpload);
                setUploadingCover(false);
                setFailedCoverUpload(true);
                setLoaded1(0);
                setTotal1(0);
                setPercentage1(0);
              });
          }
        }
      }
    };
    reqFunc();
  }, [acceptedFiles1]);

  useEffect(() => {
    if (currentState3.storageData && currentState3.titleData) {
      let storageLeft_ =
        currentState3.storageData.totalStorage -
        currentState3.storageData.usedStorage;
      let titleLeft =
        currentState3.titleData.totalTitle - currentState3.titleData.usedTitle;
      if (!isNaN(storageLeft_) && storageLeft_ > 0 && titleLeft > 0) {
        setStorageLeft(storageLeft_ * 1000000000 - storageUsedNow);
        setCanUpload(true);
      }
    }
    setSource(Object.assign({}, CancelToken.source(), { key: source.key + 1 }));
  }, [currentState3.storageData, currentState3.titleData]);

  const onDropAccepted = (e) => {
    setSource(Object.assign({}, CancelToken.source(), { key: source.key + 1 }));
    if (e && Array.isArray(e) && e.length > 0) {
      if (
        currentState3.storageData.usedStorage + e[0].size / 1000000 >
        currentState3.storageData.totalStorage
      ) {
        openInformationDialog("error", "larger");
        setStorageUsedNow(currentState3.storageData.usedStorage);
        setSuccessFileUpload(false);
        setFailedFileUpload(false);
        source.cancel("Operation canceled by the user.");
      } else {
        setStorageLeft(storageLeft - e[0].size);
        setFileUsed(e[0].size);
        setStorageUsedNow(storageUsedNow + e[0].size);
      }
    }
  };

  const onDropRejected = (e) => {
    setSource(Object.assign({}, CancelToken.source(), { key: source.key + 1 }));
    if (e && Array.isArray(e) && e.length > 0) {
      if (e[0].errors && Array.isArray(e[0].errors) && e[0].errors.length > 0) {
        if (e[0].errors[0].code) {
          openInformationDialog("error", e[0].errors[0].message);
        }
      }
    }
  };

  useEffect(() => {
    if (modalType === "content" || modalType === "cover") {
      setSuccessFileUpload(true);
      if (modalType === "content" && contentDetail) {
        setFilePost(contentDetail.file);
      } else if (modalType === "cover" && coverDetail) {
        setFilePost(coverDetail.file);
      }
    }
    setSource(Object.assign({}, CancelToken.source(), { key: source.key + 1 }));
  }, [modalType]);

  const openInformationDialog = (params, message) => {
    if (params === "error") {
      setInformationDialogType("error");
      setInformationDialogMessage(message);
    } else if (params === "content") {
      setInformationDialogType("left");
    } else if (params === "cover") {
      setInformationDialogType("right");
    }
  };

  const reqDeleteFunc = async (name, uploading) => {
    const req = await axios.delete(`/admin/private/contents/file`, {
      data: { fileName: name, contentUID: contentUIDForUpdate },
    });
    if (req.data.status) {
      reqCancelFunc(uploading);
    }
  };

  const reqCancelFunc = (uploading) => {
    if (!uploading) {
      onHide();
    }
    setTimeout(() => {
      if (!uploading) {
        setStorageUsedNow(storageUsedNow - fileUsed);
      }
      setSuccessFileUpload(false);
      setFailedFileUpload(false);
      setCoverPost(null);
      setFilePost(null);
      if (modalType === "content") {
        setFileName(null);
      }
      if (modalType === "cover") {
        setCoverName(null);
      }
      setLoaded1(0);
      setTotal1(0);
      setPercentage1(0);
      setUploadingFile(false);
    }, 200);
  };

  const hideFixFileModal = (uploading) => {
    if (modalType === "content" && fileName) {
      reqDeleteFunc(fileName, uploading);
    } else if (modalType === "cover" && coverName) {
      reqDeleteFunc(coverName, uploading);
    } else if (modalType === "both") {
      if (fileName) {
        reqDeleteFunc(fileName, uploading);
      }
      if (coverName) {
        reqDeleteFunc(coverName, uploading);
      }
      onHide();
    } else {
      onHide();
    }
  };

  const styles = useStyles();
  const modalClasses = useModalStyles();

  const [isImgError, setIsImgError] = useState(0);
  const imgError = (image) => {
    if (isImgError === 0) {
      setIsImgError(1);
    }
    image.onError = "";
    return true;
  };
  const imgErrorSrc = "/media/members/placeholder-image.png";
  const [encryptedFileModal, setEncryptedFileModal] = useState(false);
  const onEncryptedFileModalHide = () => {
    setEncryptedFileModal(false);
    hideFixFileModal(true);
  };
  const onImgErrorModalHide = () => {
    setIsImgError(2);
    hideFixFileModal(true);
  };

  const modalFixFile = (modalType_) => (
    <div className="col-6" style={{ margin: "auto" }}>
      <h3>{modalType_ === "content" ? "อัปโหลดไฟล์" : "อัปโหลดไฟล์หน้าปก"}</h3>
      <div className="flex mb">
        <div className="text-blue">
          {modalType_ === "content"
            ? "รายละเอียดการอัปโหลดไฟล์และไฟล์ที่รองรับ"
            : "รายละเอียดขนาดไฟล์หน้าปก"}
        </div>
        <div
          className="information"
          onClick={() => openInformationDialog(modalType_)}
        >
          <img src="/media/members/information.svg" alt="" />
        </div>
      </div>
      {(modalType_ === "content" &&
        filePost &&
        (successFileUpload || failedFileUpload)) ||
      (modalType_ === "cover" &&
        coverImg &&
        (successCoverUpload || failedCoverUpload)) ? (
        <div
          style={{
            border: "1px dashed",
            textAlign: "center",
            borderRadius: "10px",
          }}
        >
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip1 id="quick-user-tooltip">
                {modalType_ === "content" ? "ลบไฟล์สื่อ" : "ลบไฟล์หน้าปก"}
              </Tooltip1>
            }
          >
            <a
              className="btn btn-icon btn-light btn-hover-danger btn-sm"
              style={{
                marginLeft: "0.75rem",
                position: "absolute",
                right: "22px",
              }}
              onClick={() => {
                const reqFunc = async () => {
                  const req = await axios.delete(
                    `/admin/private/contents/file`,
                    {
                      data: {
                        fileName:
                          modalType_ === "content" ? fileName : coverName,
                        contentUID: contentUIDForUpdate,
                      },
                    }
                  );
                  if (req.data.status) {
                    if (modalType_ === "content") {
                      setStorageUsedNow(storageUsedNow - fileUsed);
                      setSuccessFileUpload(false);
                      setFailedFileUpload(false);
                      setFileName(null);
                    } else {
                      setSuccessCoverUpload(false);
                      setFailedCoverUpload(false);
                      setCoverName(null);
                    }
                  }
                };
                reqFunc();
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG src={toAbsoluteUrl("/media/members/delete-button.svg")} />
              </span>
            </a>
          </OverlayTrigger>
          {failedFileUpload ? (
            <>
              <img src="/media/members/upload-file.png" alt="" />
              <div style={{ height: "160px" }}>
                <p className={styles.dropzoneTitle}>
                  ล้มเหลว กรุณาลองใหม่อีกครั้ง
                </p>
              </div>
            </>
          ) : modalType_ === "content" ? (
            <>
              <div
                style={{
                  height: "224px",
                  margin: "auto",
                  maxWidth: "180px",
                  padding: "16px",
                }}
              >
                {fileType === "pdf" ? (
                  <Document
                    file={filePost}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onPassword={() => {
                      setEncryptedFileModal(true);
                    }}
                  >
                    <Page
                      pageNumber={1}
                      width={145}
                      height={208}
                      style={{ display: "inline" }}
                    />
                  </Document>
                ) : fileType === "epub" ? (
                  <EpubView
                    url={filePost}
                    style={{ backgroundColor: "whitesmoke" }}
                  />
                ) : (
                  ""
                )}
              </div>
              <div style={{ height: "60px" }}>
                <p
                  className={styles.dropzoneTitle}
                  style={{ marginTop: "16px" }}
                >
                  อัปโหลดไฟล์สำเร็จ
                </p>
              </div>
            </>
          ) : (
            <>
              <img
                className={styles.uploadedImg}
                alt=""
                src={isImgError ? imgErrorSrc : coverImg}
                onError={imgError}
              />
              <div style={{ height: "60px" }}>
                <p className={styles.dropzoneTitle}>อัปโหลดไฟล์หน้าปกสำเร็จ</p>
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          {...(modalType_ === "content"
            ? getRootProps({ className: "dropzone" })
            : getRootProps1({ className: "dropzone" }))}
          style={{
            border: "1px dashed",
            textAlign: "center",
            borderRadius: "10px",
          }}
        >
          <input
            {...(modalType_ === "content" ? getInputProps() : getInputProps1())}
          />
          <img src="/media/members/upload-file.png" alt="" />
          {(modalType_ === "content" && uploadingFile) ||
          (modalType_ === "cover" && uploadingCover) ? (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip1 id="quick-user-tooltip">
                  {modalType_ === "content" ? "ลบไฟล์สื่อ" : "ลบไฟล์หน้าปก"}
                </Tooltip1>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                style={{
                  marginLeft: "0.75rem",
                  position: "absolute",
                  right: "22px",
                }}
                onClick={() => {
                  source.cancel("Operation canceled by the user.");
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                    src={toAbsoluteUrl("/media/members/delete-button.svg")}
                  />
                </span>
              </a>
            </OverlayTrigger>
          ) : (
            ""
          )}
          <div style={{ height: "160px" }}>
            {(modalType_ === "content" && uploadingFile) ||
            (modalType_ === "cover" && uploadingCover) ? (
              <>
                <p className={styles.dropzoneTitle}>
                  รอสักครู่ ระบบกำลังอัปโหลดไฟล์...
                </p>
                <div
                  className="row"
                  style={{ margin: "40px 0.5px 0", color: "#012875" }}
                >
                  <p
                    className="col-6"
                    style={{
                      fontSize: "12px",
                      textAlign: "left",
                      margin: "0",
                    }}
                  >
                    {modalType_ === "content" ? loaded : loaded1}KB/
                    {modalType_ === "content" ? total : total1}KB
                  </p>
                  <p
                    className="col-6"
                    style={{
                      fontSize: "14px",
                      textAlign: "end",
                      margin: "0",
                    }}
                  >
                    {modalType_ === "content"
                      ? parseInt(percentage, 10)
                      : parseInt(percentage1, 10)}
                    %
                  </p>
                </div>
                <BorderLinearProgress
                  className={styles.progressUpload}
                  variant="determinate"
                  color="secondary"
                  value={modalType_ === "content" ? percentage : percentage1}
                />
              </>
            ) : (
              <>
                <h4>Drag a file here</h4>
                <div>or browse your computer</div>
                <button
                  type="button"
                  className="browseButton"
                  onClick={modalType_ === "content" ? open : open1}
                >
                  Browse
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );

  const isButtonEnabled = (modalType_) => {
    switch (modalType_) {
      case "content":
        return filePost && successFileUpload && !failedFileUpload;
      case "cover":
        return coverImg && successCoverUpload && !failedCoverUpload;
      case "both":
        return (
          coverImg &&
          filePost &&
          successFileUpload &&
          !failedFileUpload &&
          successCoverUpload &&
          !failedCoverUpload
        );
      default:
        break;
    }
  };

  const fixFileOnlyReq = async () => {
    const req = await axios.put(
      `/admin/private/contents/${contentUIDForUpdate}/verify`,
      {
        rawContent: modalType === "content" || modalType === "both",
        coverImage: modalType === "cover" || modalType === "both",
      }
    );
    if (req.data.status && req.data.data) {
      setSuccessModal(true);
      dispatch(
        actions.catalogueUpdated({
          id: req.data.data.contentUID,
          catalogue: req.data.data,
        })
      );
    }
  };

  return (
    <>
      <Modal
        backdrop="static"
        size="lg"
        show={modalType}
        onHide={() => hideFixFileModal()}
        aria-labelledby="example-modal-sizes-title-lg"
        style={{ marginTop: "27px" }}
        dialogClassName={styles.divFileModal}
      >
        <Modal.Body>
          <DashboardMini
            storageUsedNow={storageUsedNow}
            storageData={currentState3.storageData}
            titleData={currentState3.titleData}
          />
          {modalType === "both" ? (
            <div className="row">
              {modalFixFile("content")}
              {modalFixFile("cover")}
            </div>
          ) : (
            modalFixFile(modalType)
          )}
          <div className={`col-6 divButton`}>
            <button
              className="denyButton"
              type="button"
              onClick={() => {
                if (uploadingFile) {
                  source.cancel("Operation canceled by the user.");
                  reqCancelFunc(uploadingFile);
                } else {
                  hideFixFileModal();
                }
              }}
              style={{ marginLeft: "0" }}
            >
              ยกเลิก
            </button>
            <button
              className="acceptButton"
              onClick={() => {
                if (saveDetail) {
                  saveDetail(
                    {
                      fileName: modalType === "content" ? fileName : coverName,
                      updatedDate: moment().unix(),
                      file: modalType === "content" ? filePost : coverPost,
                    },
                    modalType,
                    numPagesForBeforeUpdate
                  );
                } else if (fixFileOnly) {
                  fixFileOnlyReq();
                }
              }}
              style={{ marginRight: "0" }}
              disabled={!isButtonEnabled(modalType)}
            >
              บันทึก
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {informationDialogType === "error" ||
      informationDialogType === "left" ||
      informationDialogType === "right" ? (
        <UploadInformationDialog
          show={true}
          onHide={() => {
            setInformationDialogType(null);
          }}
          isError={informationDialogType === "error"}
          isLeft={informationDialogType === "left"}
          errorMessage={informationDialogMessage}
        />
      ) : (
        ""
      )}
      <ModalsSuccess
        show={successModal}
        onHide={() => onHide()}
        onClickAccept={() => onHide()}
      />
      <Modal
        backdrop="static"
        show={encryptedFileModal}
        onHide={onEncryptedFileModalHide}
        aria-labelledby="example-modal-sizes-title-lg"
        style={{ margin: "3% auto" }}
        size="md"
      >
        <Modal.Body className={modalClasses.body}>
          <>
            <img src="/media/members/image-modal.svg" alt="" />
            <div className="text-text-red">
              ไฟล์ที่มีการตั้งรหัสผ่านไม่สามารถอัปโหลดได้
            </div>
            <div className="mt-3">
              <button
                className="acceptButton "
                type="submit"
                onClick={() => {
                  onEncryptedFileModalHide();
                }}
              >
                ตกลง
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        show={isImgError === 1}
        onHide={onImgErrorModalHide}
        aria-labelledby="example-modal-sizes-title-lg"
        style={{ margin: "3% auto" }}
        size="md"
      >
        <Modal.Body className={modalClasses.body}>
          <>
            <img src="/media/members/image-modal.svg" alt="" />
            <div className="text-text-red">
              ไฟล์รูปมีปัญหาไม่สามารถอัปโหลดได้
            </div>
            <div className="mt-3">
              <button
                className="acceptButton"
                type="submit"
                onClick={() => {
                  onImgErrorModalHide();
                }}
              >
                ตกลง
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
