/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Modal } from 'react-bootstrap';
import {
    CardHeader,
    CardHeaderToolbar,
    CardBody
} from '../../../../../_metronic/_partials/controls';
import { Divider } from 'antd';
import { makeStyles, withStyles, lighten } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DashboardMini } from './DashboardMini';
import { useUploadUIContext } from './UploadUIContext';
import { Document, Page, pdfjs } from 'react-pdf';
import { withRouter } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import {
    EpubView // Underlaying epub-canvas (wrapper for epub.js iframe)
} from 'react-reader';
const CancelToken = axios.CancelToken;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useModalStyles = makeStyles(() => ({
    body: {
        textAlign: 'center'
    },
    title: {
        fontSize: '19px',
        color: '#012875',
        fontWeight: '600',
        textAlign: 'left'
    },
    titleError: {
        fontSize: '20px',
        fontWeight: 600,
        color: '#d7042b',
        marginBottom: '40px'
    },
    subtitle: {
        fontFamily: 'Prompt',
        fontSize: '16px'
    },
    divButton: {
        marginTop: '16px'
    },
    acceptButton: {
        margin: '8px',
        color: '#ffffff',
        fontSize: '18px',
        fontWeight: 600,
        width: '160px',
        height: '48px',
        borderRadius: '100px',
        backgroundColor: '#012875',
        border: 'none'
    },
    denyButton: {
        margin: '8px',
        color: '#012875',
        fontSize: '18px',
        fontWeight: 600,
        width: '160px',
        height: '48px',
        borderRadius: '100px',
        backgroundColor: '#ffffff',
        border: 'solid 1px #012875'
    },
    closeButton: { border: 'none', backgroundColor: 'transparent' },
    caution: { fontSize: '14px', color: '#D7042B' }
}));

const FileAndCover = ({
    handleSubmitOutside,
    storageData,
    titleData,
    setNumPagesForUpdate,
    history,
    storageUsedNow,
    setStorageUsedNow,
    titleUsedNow,
    filePost,
    coverPost,
    setFilePost,
    setCoverPost,
    contentUIDToUpload,
    setContentUIDToUpload,
    fileType,
    setFileType,
    fromEbookPage
}) => {
    const [uploadingFile, setUploadingFile] = useState(false);
    const [uploadingCover, setUploadingCover] = useState(false);

    const [successFileUpload, setSuccessFileUpload] = useState(false);
    const [successCoverUpload, setSuccessCoverUpload] = useState(false);
    const [failedFileUpload, setFailedFileUpload] = useState(false);
    const [failedCoverUpload, setFailedCoverUpload] = useState(false);

    const [coverImg, setCoverImg] = useState(null);
    const [storageLeft, setStorageLeft] = useState(0);
    const [canUpload, setCanUpload] = useState(false);

    const [fileUsed, setFileUsed] = useState(0);
    // const [coverUsed, setCoverUsed] = useState(0);

    const [fileName, setFileName] = useState(null);
    const [coverName, setCoverName] = useState(null);

    const [source, setSource] = useState(
        Object.assign({}, CancelToken.source(), { key: 0 })
    );
    const [token, setToken] = useState(source.token);

    useEffect(() => {
        setToken(source.token);
    }, [source]);

    const uploadUIContext = useUploadUIContext();
    const uploadUIProps = useMemo(() => {
        return {
            ids: uploadUIContext.ids,
            openFileInformationDialog:
                uploadUIContext.openFileInformationDialog,
            openCoverInformationDialog:
                uploadUIContext.openCoverInformationDialog
        };
    }, [uploadUIContext]);
    const BorderLinearProgress = withStyles({
        root: {
            height: 10,
            backgroundColor: lighten('#ff6c5c', 0.5)
        },
        bar: {
            borderRadius: 20,
            backgroundColor: '#ff6c5c'
        }
    })(LinearProgress);
    const useStyles1 = makeStyles(theme => ({
        bigDiv: {
            borderRadius: '0px 12px 12px 12px',
            backgroundColor: 'white',
            padding: fromEbookPage ? '1rem 0 0' : '2rem 150px'
        },
        stat: {
            background: '#F2F2F2',
            borderRadius: '20px',
            padding: '20px 0'
        },
        denyButton: {
            margin: '8px',
            width: '150px',
            height: '48px',
            borderRadius: '100px',
            border: 'solid 1px #012875',
            backgroundColor: '#ffffff',
            fontWeight: '600',
            color: '#012875',
            fontSize: '15px'
        },
        acceptButton: {
            margin: '8px',
            width: '150px',
            height: '48px',
            borderRadius: '100px',
            backgroundColor: '#012875',
            border: 'none',
            color: 'white',
            fontWeight: '600',
            fontSize: '16px',
            '&:disabled': {
                backgroundColor: '#e0e0e0',
                color: '#828282',
                '&:hover': {
                    cursor: 'not-allowed'
                }
            }
        },
        invertButton: {
            margin: '8px',
            width: '150px',
            height: '48px',
            borderRadius: '100px',
            backgroundColor: 'white',
            border: '1px solid #012875',
            color: '#012875',
            fontWeight: '600',
            fontSize: '16px'
        },
        divButton: { marginTop: '-47px', textAlign: 'right' },
        blue_p: {
            fontSize: 20,
            fontWeight: 600,
            color: '#012875',
            marginBottom: '6px'
        },
        blue_p_small: {
            fontSize: 14,
            fontWeight: 400,
            color: '#012875',
            display: 'inline-block'
        },

        dropzoneTitle: { marginBottom: '0px', fontSize: '16px' },
        dropzoneSubTitle: { marginBottom: '36px', fontSize: '14px' },
        progress: {
            margin: '13px 0px',
            borderRadius: '5px'
        },
        progressUpload: {
            margin: '3px 13px 47px',
            borderRadius: '5px'
        },
        information: {
            backgroundColor: '#012875',
            width: 16,
            height: 16,
            borderRadius: 8,
            textAlign: 'center',
            margin: '0 0 0 6px',
            display: 'inline-block',
            '&:hover': { cursor: 'pointer' }
        },
        subProgressLeft: {
            margin: 0,
            fontFamily: 'Prompt',
            fontSize: '12px',
            fontWeight: '500',
            float: 'left',
            color: '#FF5337'
        },
        subProgressRight: {
            margin: 0,
            fontFamily: 'Prompt',
            fontSize: '12px',
            fontWeight: '500',
            float: 'right',
            color: '#FF5337'
        },
        uploadedImg: {
            padding: '16px',
            height: '240px',
            maxWidth: '100%'
        }
    }));
    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        // Disable click and keydown behavior
        accept: '.pdf, .epub',
        noClick: true,
        noKeyboard: true,
        maxSize: canUpload ? storageLeft : 0,
        onDropRejected: e => onDropRejected(e),
        onDropAccepted: e => onDropFileAccepted(e)
    });

    const {
        getRootProps: getRootProps1,
        getInputProps: getInputProps1,
        open: openBrowseCover,
        acceptedFiles: acceptedFiles1
    } = useDropzone({
        accept: '.png, .jpg, .jpeg',
        noClick: true,
        noKeyboard: true,
        maxSize: canUpload ? storageLeft : 0,
        onDropRejected: e => onDropRejected(e),
        onDropAccepted: e => onDropCoverAccepted(e)
    });

    const onDropFileAccepted = e => {
        var storage =
            (storageData?.totalStorage * storageData?.percentage) / 100;
        console.log(storage, e);
        setSource(
            Object.assign({}, CancelToken.source(), { key: source.key + 1 })
        );
        if (e && Array.isArray(e) && e.length > 0) {
            if (
                storage + fileUsed + e[0].size / 1000000 >
                storageData.totalStorage
            ) {
                uploadUIProps.openFileInformationDialog(
                    'file-too-large',
                    'larger'
                );
                setStorageUsedNow(storage);
                setSuccessFileUpload(false);
                setFailedFileUpload(false);
                source.cancel('Operation canceled by the user. 3');
            } else {
                setStorageLeft(storageLeft - e[0].size);
                setFileUsed(e[0].size);
                setStorageUsedNow(storageUsedNow + e[0].size);
            }
        }
    };

    const onDropCoverAccepted = e => {
        setSource(
            Object.assign({}, CancelToken.source(), { key: source.key + 1 })
        );
        if (e && Array.isArray(e) && e.length > 0) {
            // setStorageLeft(storageLeft - e[0].size);
            // setCoverUsed(e[0].size);
            // setStorageUsedNow(storageUsedNow + e[0].size);
        }
    };

    const onDropRejected = e => {
        console.log('5555');
        setSource(
            Object.assign({}, CancelToken.source(), { key: source.key + 1 })
        );
        setFileUsed(0);
        if (e && Array.isArray(e) && e.length > 0) {
            if (
                e[0].errors &&
                Array.isArray(e[0].errors) &&
                e[0].errors.length > 0
            ) {
                if (
                    e[0].errors[0] &&
                    e[0].errors[0].code === 'file-too-large'
                ) {
                    uploadUIProps.openFileInformationDialog(
                        'file-too-large',
                        'larger'
                    );
                } else if (
                    e[0].errors[0] &&
                    e[0].errors[0].code === 'file-invalid-type'
                ) {
                    uploadUIProps.openFileInformationDialog(
                        'file-invalid-type',
                        e[0].errors[0].message
                    );
                }
            }
        }
    };

    useEffect(() => {
        if (storageData && titleData) {
            var storage =
                (storageData?.totalStorage * storageData?.percentage) / 100;
            console.log(storage, storageData.totalStorage, storageUsedNow);
            let storageLeft_ = storageData.totalStorage - storage;
            if (!isNaN(storageLeft_) && storageLeft_ > 0) {
                setStorageLeft(storageLeft_ * 1000000000 - storageUsedNow);
                setCanUpload(true);
            }
        }
        setSource(
            Object.assign({}, CancelToken.source(), { key: source.key + 1 })
        );
    }, [storageData, titleData]);

    const [loaded, setLoaded] = useState(0);
    const [total, setTotal] = useState(0);
    const [percentage, setPercentage] = useState(0);

    const [loaded1, setLoaded1] = useState(0);
    const [total1, setTotal1] = useState(0);
    const [percentage1, setPercentage1] = useState(0);

    useEffect(() => {
        const reqFunc = async () => {
            if (acceptedFiles && acceptedFiles.length) {
                setUploadingFile(true);
                const fileToUpload = acceptedFiles[0];
                if (fileToUpload) {
                    setFileName(fileToUpload.name);
                    if (fileToUpload.type.includes('epub')) {
                        setFileType('epub');
                    } else if (fileToUpload.type.includes('pdf')) {
                        setFileType('pdf');
                    }
                    const req = await axios.post(
                        `/admin/private/contents/file`,
                        {
                            fileName: fileToUpload.name,
                            contentUID: contentUIDToUpload
                                ? contentUIDToUpload
                                : undefined
                        }
                    );
                    if (req.data.status && req.data.data) {
                        const urlToUpload = req.data.data.url;
                        const fieldsToUpload = req.data.data.fields;
                        const UID = req.data.data.contentUID;
                        setContentUIDToUpload(UID);

                        var formData = new FormData();
                        const entries = Object.entries(fieldsToUpload);
                        for (const [key, val] of entries) {
                            formData.append(key, val);
                        }
                        formData.append('file', fileToUpload);
                        axios({
                            method: 'post',
                            url: urlToUpload,
                            data: formData,
                            onUploadProgress: progressEvent => {
                                if (progressEvent) {
                                    setLoaded(
                                        (progressEvent.loaded / 1000).toFixed(2)
                                    );
                                    setTotal(
                                        (progressEvent.total / 1000).toFixed(2)
                                    );
                                    setPercentage(
                                        (
                                            (100 * progressEvent.loaded) /
                                            progressEvent.total
                                        ).toFixed()
                                    );
                                }
                            },
                            cancelToken: token
                        })
                            .then(() => {
                                setFilePost(fileToUpload);
                                setUploadingFile(false);
                                setSuccessFileUpload(true);
                                setLoaded(0);
                                setTotal(0);
                                setPercentage(0);
                            })
                            .catch(err => {
                                console.log(err);
                                setUploadingFile(false);
                                setFailedFileUpload(true);
                                setLoaded(0);
                                setTotal(0);
                                setPercentage(0);
                            });
                    }
                }
            }
        };
        reqFunc();
    }, [acceptedFiles]);

    useEffect(() => {
        const reqFunc = async () => {
            if (acceptedFiles1 && acceptedFiles1.length) {
                setIsImgError(0);
                setUploadingCover(true);
                const fileToUpload = acceptedFiles1[0];
                if (fileToUpload) {
                    setCoverName(fileToUpload.name);
                    const req = await axios.post(
                        `/admin/private/contents/file`,
                        {
                            fileName: fileToUpload.name,
                            contentUID: contentUIDToUpload
                                ? contentUIDToUpload
                                : undefined
                        }
                    );
                    if (req.data.status && req.data.data) {
                        const urlToUpload = req.data.data.url;
                        const fieldsToUpload = req.data.data.fields;
                        const UID = req.data.data.contentUID;
                        setContentUIDToUpload(UID);

                        var formData = new FormData();
                        const entries = Object.entries(fieldsToUpload);
                        for (const [key, val] of entries) {
                            formData.append(key, val);
                        }
                        formData.append('file', fileToUpload);
                        axios({
                            method: 'post',
                            url: urlToUpload,
                            data: formData,
                            onUploadProgress: progressEvent => {
                                if (progressEvent) {
                                    setLoaded1(
                                        (progressEvent.loaded / 1000).toFixed(2)
                                    );
                                    setTotal1(
                                        (progressEvent.total / 1000).toFixed(2)
                                    );
                                    setPercentage1(
                                        (
                                            (100 * progressEvent.loaded) /
                                            progressEvent.total
                                        ).toFixed()
                                    );
                                }
                            },
                            cancelToken: token
                        })
                            .then(() => {
                                setCoverPost(fileToUpload);
                                setUploadingCover(false);
                                setSuccessCoverUpload(true);
                                setLoaded1(0);
                                setTotal1(0);
                                setPercentage1(0);
                            })
                            .catch(err => {
                                console.log(err);
                                setUploadingCover(false);
                                setFailedCoverUpload(true);
                                setLoaded1(0);
                                setTotal1(0);
                                setPercentage1(0);
                            });
                    }
                }
            }
        };
        reqFunc();
    }, [acceptedFiles1]);

    useEffect(() => {
        if (filePost) {
            if (filePost.type.includes('epub')) {
                setFileType('epub');
            } else if (filePost.type.includes('pdf')) {
                setFileType('pdf');
            }
            setSuccessFileUpload(true);
        }
        {
            console.log(55);
        }
    }, [filePost]);

    useEffect(() => {
        if (coverPost) {
            setCoverImg(URL.createObjectURL(coverPost));
            setSuccessCoverUpload(true);
        } else {
            setCoverImg(null);
        }
    }, [coverPost]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPagesForUpdate(parseInt(numPages, 10));
    };

    const reqDeleteFileFunc = async () => {
        const req = await axios.delete(`/admin/private/contents/file`, {
            data: { fileName: fileName, contentUID: contentUIDToUpload }
        });
        if (req.data.status) {
            setSource(
                Object.assign({}, CancelToken.source(), { key: source.key + 1 })
            );
            setStorageUsedNow(storageUsedNow - fileUsed);
            setStorageLeft(storageLeft + fileUsed);
            setFileUsed(0);
            setSuccessFileUpload(false);
            setFailedFileUpload(false);
            setUploadingFile(false);
        }
    };
    const reqDeleteCoverFunc = async () => {
        const req = await axios.delete(`/admin/private/contents/file`, {
            data: { fileName: coverName, contentUID: contentUIDToUpload }
        });
        if (req.data.status) {
            // setStorageUsedNow(storageUsedNow - coverUsed);
            setSource(
                Object.assign({}, CancelToken.source(), { key: source.key + 1 })
            );
            setSuccessCoverUpload(false);
            setFailedCoverUpload(false);
            setUploadingCover(false);
        }
    };

    const classes = useStyles1();
    const modalClasses = useModalStyles();

    const [isImgError, setIsImgError] = useState(0);
    const imgError = image => {
        if (isImgError === 0) {
            setIsImgError(1);
        }
        image.onError = '';
        return true;
    };
    const imgErrorSrc = '/media/members/placeholder-image.png';
    const [encryptedFileModal, setEncryptedFileModal] = useState(false);
    const onEncryptedFileModalHide = () => {
        setEncryptedFileModal(false);
        reqDeleteFileFunc();
    };
    const onImgErrorModalHide = () => {
        setIsImgError(2);
        reqDeleteCoverFunc();
    };

    const trashIcon = (type, uploading, s) => {
        return (
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id="quick-user-tooltip">
                        ลบไฟล์
                        {type === 'file'
                            ? 'สื่อ'
                            : type === 'cover'
                            ? 'หน้าปก'
                            : ''}
                    </Tooltip>
                }
            >
                <a
                    className="btn btn-icon btn-light btn-hover-danger btn-sm icon-delete"
                    onClick={() => {
                        if (uploading && s) {
                            s.cancel('Operation canceled by the user. 4');
                        }
                        if (type === 'file') {
                            reqDeleteFileFunc();
                        } else if (type === 'cover') {
                            reqDeleteCoverFunc();
                        }
                    }}
                >
                    <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                            src={toAbsoluteUrl(
                                '/media/members/delete-button.svg'
                            )}
                        />
                    </span>
                </a>
            </OverlayTrigger>
        );
    };

    return (
        <>
            <CardHeader
                title={
                    <div className="card-label">
                        <div>อัปโหลดอีบุ๊ก</div>
                    </div>
                }
            >
                <CardHeaderToolbar>
                    <button
                        className="denyButton"
                        type="submit"
                        onClick={() => {
                            history.push('/ebooks-private');
                        }}
                    >
                        ยกเลิก
                    </button>
                    <button
                        className="acceptButton"
                        type="submit"
                        disabled={
                            !(
                                filePost &&
                                coverImg &&
                                successFileUpload &&
                                successCoverUpload &&
                                !failedFileUpload &&
                                !failedCoverUpload
                            )
                        }
                        onClick={() => {
                            handleSubmitOutside();
                        }}
                    >
                        ถัดไป
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <DashboardMini
                storageUsedNow={storageUsedNow}
                titleUsedNow={titleUsedNow}
                storageData={storageData}
                titleData={titleData}
            />
            <div className="uploadFileCover">
                <div className="row">
                    <div className="col-6">
                        <h3>อัปโหลดไฟล์</h3>
                        <div className="flex mb">
                            <div className="text-blue">
                                รายละเอียดการอัปโหลดไฟล์และไฟล์ที่รองรับ
                            </div>
                            <div
                                className="information"
                                onClick={() =>
                                    uploadUIProps.openFileInformationDialog()
                                }
                            >
                                <img
                                    src="/media/members/information.svg"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="uploadDropzone">
                            {filePost &&
                            (successFileUpload || failedFileUpload) ? (
                                <>
                                    {trashIcon('file', false)}
                                    {failedFileUpload ? (
                                        <div>
                                            <img
                                                src="/media/members/upload-file.png"
                                                alt=""
                                            />
                                            <div>
                                                ล้มเหลว กรุณาลองใหม่อีกครั้ง
                                            </div>
                                        </div>
                                    ) : successFileUpload ? (
                                        <>
                                            <div className="sampleFile">
                                                {fileType === 'pdf' ? (
                                                    <Document
                                                        file={filePost}
                                                        onLoadSuccess={
                                                            onDocumentLoadSuccess
                                                        }
                                                        onPassword={() => {
                                                            setEncryptedFileModal(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        <Page
                                                            pageNumber={1}
                                                            width={145}
                                                            height={208}
                                                            style={{
                                                                display:
                                                                    'inline'
                                                            }}
                                                        />
                                                    </Document>
                                                ) : fileType === 'epub' ? (
                                                    <EpubView
                                                        url={filePost}
                                                        style={{
                                                            backgroundColor:
                                                                'whitesmoke'
                                                        }}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div>อัปโหลดไฟล์สำเร็จ</div>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </>
                            ) : (
                                <div
                                    {...getRootProps({ className: 'dropzone' })}
                                >
                                    <div>
                                        <input {...getInputProps()} />
                                        <img
                                            src="/media/members/upload-file.png"
                                            alt=""
                                        />
                                        {uploadingFile
                                            ? trashIcon('file', true, source)
                                            : ''}
                                        <div>
                                            {uploadingFile ? (
                                                <>
                                                    <div className="mt mb">
                                                        รอสักครู่
                                                        ระบบกำลังอัปโหลดไฟล์...
                                                    </div>
                                                    <div className="row text-blue">
                                                        <div className="col-6 uploadingText left">
                                                            {loaded}KB/{total}KB
                                                        </div>
                                                        <div className="col-6 uploadingText right">
                                                            {parseInt(
                                                                percentage,
                                                                10
                                                            )}
                                                            %
                                                        </div>
                                                    </div>
                                                    <BorderLinearProgress
                                                        className="uploadingProgress"
                                                        variant="determinate"
                                                        color="secondary"
                                                        value={percentage}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <h4>Drag a file here</h4>
                                                    <div>
                                                        or browse your computer
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="browseButton"
                                                        onClick={open}
                                                    >
                                                        Browse
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-6">
                        <h3>อัปโหลดไฟล์หน้าปก</h3>
                        <div className="flex mb">
                            <div className="text-blue">
                                รายละเอียดขนาดไฟล์หน้าปก
                            </div>
                            <div
                                className="information"
                                onClick={
                                    uploadUIProps.openCoverInformationDialog
                                }
                            >
                                <img
                                    src="/media/members/information.svg"
                                    alt=""
                                />
                            </div>
                        </div>
                        {coverImg &&
                        (successCoverUpload || failedCoverUpload) ? (
                            <div className="uploadDropzone">
                                {trashIcon('cover', false)}
                                {failedCoverUpload ? (
                                    <div>
                                        <img
                                            src="/media/members/upload-file.png"
                                            alt=""
                                        />
                                        <div>ล้มเหลว กรุณาลองใหม่อีกครั้ง</div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="sampleFile">
                                            <img
                                                className={classes.uploadedImg}
                                                alt=""
                                                src={
                                                    isImgError
                                                        ? imgErrorSrc
                                                        : coverImg
                                                }
                                                onError={imgError}
                                            />
                                        </div>
                                        <div>อัปโหลดไฟล์หน้าปกสำเร็จ</div>
                                    </>
                                )}
                            </div>
                        ) : (
                            <div
                                {...getRootProps1({ className: 'dropzone' })}
                                className="uploadDropzone"
                            >
                                <div>
                                    <input {...getInputProps1()} />
                                    <img
                                        src="/media/members/upload-file.png"
                                        alt=""
                                    />
                                    {uploadingCover
                                        ? trashIcon('cover', true, source)
                                        : ''}
                                    <div>
                                        {uploadingCover ? (
                                            <>
                                                <div className="mt mb">
                                                    รอสักครู่
                                                    ระบบกำลังอัปโหลดไฟล์...
                                                </div>
                                                <div className="row text-blue">
                                                    <div className="col-6 uploadingText left">
                                                        {loaded1}KB/{total1}KB
                                                    </div>
                                                    <div className="col-6 uploadingText right">
                                                        {parseInt(
                                                            percentage1,
                                                            10
                                                        )}
                                                        %
                                                    </div>
                                                </div>
                                                <BorderLinearProgress
                                                    className="uploadingProgress"
                                                    variant="determinate"
                                                    color="secondary"
                                                    value={percentage1}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <h4>Drag a file here</h4>
                                                <div>
                                                    or browse your computer
                                                </div>
                                                <button
                                                    type="button"
                                                    className="browseButton"
                                                    onClick={openBrowseCover}
                                                >
                                                    Browse
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {/* </div> */}
            </div>
            <Modal
                backdrop="static"
                show={encryptedFileModal}
                onHide={onEncryptedFileModalHide}
                aria-labelledby="example-modal-sizes-title-lg"
                style={{ margin: '3% auto' }}
                size="md"
            >
                <Modal.Body className={modalClasses.body}>
                    <>
                        <img src="/media/members/image-modal.svg" alt="" />
                        <div className={modalClasses.titleError}>
                            ไฟล์ที่มีการตั้งรหัสผ่านไม่สามารถอัปโหลดได้
                        </div>
                        <div className="divButton">
                            <button
                                className="acceptButton"
                                type="submit"
                                onClick={() => {
                                    onEncryptedFileModalHide();
                                }}
                            >
                                ตกลง
                            </button>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
            <Modal
                backdrop="static"
                show={isImgError === 1}
                onHide={onImgErrorModalHide}
                aria-labelledby="example-modal-sizes-title-lg"
                style={{ margin: '3% auto' }}
                size="md"
            >
                <Modal.Body className={modalClasses.body}>
                    <>
                        <img src="/media/members/image-modal.svg" alt="" />
                        <div className={modalClasses.titleError}>
                            ไฟล์รูปมีปัญหาไม่สามารถอัปโหลดได้
                        </div>
                        <div className="divButton">
                            <button
                                className="acceptButton"
                                type="submit"
                                onClick={() => {
                                    onImgErrorModalHide();
                                }}
                            >
                                ตกลง
                            </button>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default withRouter(FileAndCover);
