import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./PublishersUIHelpers";

const PublishersUIContext = createContext();

export function usePublishersUIContext() {
  return useContext(PublishersUIContext);
}

export const PublishersUIConsumer = PublishersUIContext.Consumer;

export function PublishersUIProvider({ publishersUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initPublisher = {
    uid: undefined,
    name: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initPublisher,
    newPublisherButtonClick: publishersUIEvents.newPublisherButtonClick,
    newAuthorButtonClick: publishersUIEvents.newAuthorButtonClick,
    openEditPublisherDialog: publishersUIEvents.openEditPublisherDialog,
    openEditAuthorDialog: publishersUIEvents.openEditAuthorDialog,
    openViewPublisherDialog: publishersUIEvents.openViewPublisherDialog,
    openDeleteAuthorDialog: publishersUIEvents.openDeleteAuthorDialog,
    openDeletePublisherDialog: publishersUIEvents.openDeletePublisherDialog,
    openBlockAuthorDialog: publishersUIEvents.openBlockAuthorDialog,
    openBlockPublisherDialog: publishersUIEvents.openBlockPublisherDialog,
    openUnblockAuthorDialog: publishersUIEvents.openUnblockAuthorDialog,
    openUnblockPublisherDialog: publishersUIEvents.openUnblockPublisherDialog,
    openFetchPublishersDialog: publishersUIEvents.openFetchPublishersDialog,
    openUpdatePublishersStatusDialog: publishersUIEvents.openUpdatePublishersStatusDialog,
    openAuthorsPage: publishersUIEvents.openAuthorsPage,
    openAuthorBooksPage: publishersUIEvents.openAuthorBooksPage,
  };

  return <PublishersUIContext.Provider value={value}>{children}</PublishersUIContext.Provider>;
}
