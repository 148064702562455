import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/catalogue/catalogueActions";
import { useCatalogueUIContext } from "../CatalogueUIContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
     body: {
          textAlign: "center",
     },
}));

export function CatalogueDeleteDialog({ name, id, isPrivate, show, onHide }) {
     // Catalogue UI Context
     const catalogueUIContext = useCatalogueUIContext();
     const catalogueUIProps = useMemo(() => {
          return {
               setIds: catalogueUIContext.setIds,
               queryParams: catalogueUIContext.queryParams,
          };
     }, [catalogueUIContext]);

     // Catalogue Redux state
     const dispatch = useDispatch();
     const { isLoading } = useSelector((state) => ({ isLoading: state.catalogue.actionsLoading }), shallowEqual);

     // if !id we should close modal
     useEffect(() => {
          if (!id) {
               onHide();
          }

          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [id]);

     // looking for loading/dispatch
     useEffect(() => {}, [isLoading, dispatch]);

     const deleteCatalogue = () => {
          dispatch(actions.deleteCatalogue(id)).then(() => {
               onHide();
          });
     };

     const classes = useStyles();

     return (
          <Modal
               backdrop="static"
               show={show}
               onHide={onHide}
               aria-labelledby="example-modal-sizes-title-lg"
               style={{ margin: "3% auto" }}
          >
               {isLoading && <ModalProgressBar />}
               <Modal.Body className={classes.body}>
                    {name ? (
                         <>
                              <img src="/media/members/image-modal.svg" alt="" />
                              <div className="text-text-red">ยืนยันการลบ</div>
                              <div className="text-text-gray">ต้องการลบ {name} หรือไม่?</div>
                              <div className="mt-3">
                                   <button type="button" onClick={onHide} className="denyButton ">
                                        ยกเลิก
                                   </button>
                                   <button type="button" onClick={deleteCatalogue} className="acceptButton ">
                                        ยืนยัน
                                   </button>
                              </div>
                         </>
                    ) : (
                         ""
                    )}
               </Modal.Body>
          </Modal>
     );
}
