import React, { useMemo, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { PodcastsFilter } from "./PodcastsFilter";
import { PodsTable } from "./pods-table/PodsTable";
import { PodcastsTable } from "./podcasts-table/PodcastsTable";
import { usePodcastsUIContext } from "./PodcastsUIContext";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  div: {
    marginTop: 16,
  },
  p1: {
    fontFamily: "Prompt",
    fontSize: "20px",
    fontWeight: 600,
    color: "#012875",
    marginBottom: 14,
  },
  p1_lessMargin: {
    fontFamily: "Prompt",
    fontSize: "20px",
    fontWeight: 600,
    color: "#012875",
    marginBottom: 0,
  },
  p2: {
    fontFamily: "Prompt",
    fontSize: "16px",
    fontWeight: 500,
    color: "#4F4F4F",
    marginBottom: 14,
  },
  button: {
    borderRadius: 100,
    backgroundColor: "#012875",
    marginLeft: 8,
    padding: "10px 22px",
    height: 40,
    color: "white",
    fontSize: 13,
    fontWeight: 600,
    fontFamily: "Prompt",
    border: "none",
    marginTop: 0,
  },
  dropdown1: {
    fontFamily: "Prompt",
    paddingLeft: 36,
    paddingRight: 40,
  },
  dropdown2: {
    fontFamily: "Prompt",
    paddingLeft: 36,
    paddingRight: 40,
    paddingBottom: 6,
  },
}));

export function PodcastsCard({ isPrivate, isPodcast }) {
  const podcastsUIContext = usePodcastsUIContext();
  // const [open1, setOpen1] = React.useState(false);
  // const anchorRef = React.useRef(null);
  const { role } = useSelector((state) => state.auth);
  const { currentState } = useSelector(
    (state) => ({ currentState: state.qrcodes }),
    shallowEqual
  );
  const [podcastDetail, setPodcastDetail] = useState(null);
  const dispatch = useDispatch();
  const { entities_members } = currentState;
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(1);
  const anchorRef = React.useRef(null);
  const podcastsUIProps = useMemo(() => {
    return {
      newPodButtonClick: podcastsUIContext.newPodButtonClick,
      newPodcastButtonClick: podcastsUIContext.newPodcastButtonClick,
      newChannelButtonClick: podcastsUIContext.newChannelButtonClick,
      newCreatorButtonClick: podcastsUIContext.newCreatorButtonClick,
    };
  }, [podcastsUIContext]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const classes = useStyles();

  return (
    <Card className="cardStyle">
      <CardHeader
        title={
          <div className="card-label">
            <div>
              {isPodcast.id && podcastDetail
                ? podcastDetail.title 
                : "พ็อดคาสท์"}
            </div>
            {isPodcast.id && podcastDetail ? (
              <div>{podcastDetail.channelName}</div>
            ) : (
              ""
            )}
          </div>
        }
      >
        <CardHeaderToolbar>
          <PodcastsFilter />
          {isPodcast.id ? (
            <button
              type="button"
              className="button"
              onClick={podcastsUIProps.newPodcastButtonClick}
            >
              <span>อัปโหลดพ็อดคาสท์</span>
            </button>
          ) : (
            <>
              <button
                type="button"
                className="button"
                onClick={podcastsUIProps.newChannelButtonClick}
              >
                <span>สร้าง Channel</span>
              </button>
              <button
                type="button"
                className="button"
                onClick={podcastsUIProps.newPodButtonClick}
              >
                <span>สร้างรายการพ็อดคาสท์</span>
              </button>
            </>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody className="cardBodyStyle">
        {isPodcast.id ? (
          <PodcastsTable
            isPrivate={2}
            setPodcastDetail={setPodcastDetail}
            id={isPodcast.id}
          />
         
        ) : isPodcast.id === 0 ? (
          <PodsTable isPrivate={2} isPodcast={0} />
        ) : (
          ""
        )}
      </CardBody>
    </Card>
  );
}
