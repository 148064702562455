/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/publishers/publishersActions";
import { PublisherNewForm } from "./PublisherNewForm";
import { usePublishersUIContext } from "../PublishersUIContext";

export function PublisherNewDialog({
  name,
  isPrivate,
  show,
  onHide,
  onSuccess,
  isAuthor,
  history,
  notOrigin,
}) {
  // Publishers UI Context
  const publishersUIContext = usePublishersUIContext();
  const publishersUIProps = useMemo(() => {
    return {
      initPublisher: {
        uid: undefined,
        name: "",
      },
    };
  }, [publishersUIContext]);

  // Publishers Redux state
  const dispatch = useDispatch();
  const { actionsLoading, entities, error } = useSelector(
    (state) => ({
      actionsLoading: state.publishers.actionsLoading,
      entities: state.publishers.entities,
      error: state.publishers.error,
    }),
    shallowEqual
  );

  const [isDuplicate, setDuplicate] = useState(false);

  useEffect(() => {
    if (error && error.errorCode === "014" && !isDuplicate) {
      setDuplicate(true);
    }
  }, [error]);

  const createPublisher = (publisher) => {
    const newOne = { name: publisher.name };
    dispatch(actions.createPublisher(newOne, isPrivate, undefined, onSuccess)).then((val) => {
      if (notOrigin && val !== "error") {
        onHide();
      }
    });
  };

  const createAuthor = (author, publisherId, publisherName) => {
    const newOne = { name: author.name };
    dispatch(
      actions.createAuthor(newOne, isPrivate, publisherId, publisherName, history, onSuccess)
    ).then((val) => {
      if (
        notOrigin &&
        publisherId &&
        val !== "error"
        // ||
        // (notOrigin === undefined && publisherId && val === undefined)
      ) {
        onHide(); //onHide of Upload
      }
    });
  };

  return (
    <Modal
      backdrop="static"
      size="md"
      show={show}
      onHide={onHide}
      style={{ marginTop: "27px" }}
      aria-labelledby="example-modal-sizes-title-md"
    >
      <PublisherNewForm
        createPublisher={createPublisher}
        createAuthor={createAuthor}
        actionsLoading={actionsLoading}
        publisher={publishersUIProps.initPublisher}
        onHide={onHide}
        isAuthor={isAuthor}
        publisherList={entities}
        isDuplicate={isDuplicate}
        setDuplicate={setDuplicate}
      />
    </Modal>
  );
}
