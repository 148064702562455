import React from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { QrcodeMemberMapForm } from "./QrcodeMemberMapForm";

export function QrcodeMemberMapDialog({ rowIndex, show, onHide, everyNode }) {
  const { members } = useSelector(
    (state) => ({
      members: state.qrcodes.entities_members,
    }),
    shallowEqual
  );

  return (
    <Modal
      backdrop="static"
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <QrcodeMemberMapForm
        onHide={onHide}
        data={members.length ? (everyNode ? members : members[rowIndex]) : undefined}
      />
    </Modal>
  );
}
