import { Tooltip } from "react-bootstrap";
import React, { useEffect } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { EbookViewDialog } from "../ebooks/ebook-view-dialog/EbookViewDialog";
import { useHistory } from "react-router-dom";
import { Content } from "../../../../../interface/ebook";
import { useDispatch, useSelector } from "react-redux";
import { HibraryRootState } from "../../../../../redux/rootReducer";
import { expireActions } from "../../_redux/expire/expireRedux";
import { LoaderAbsolute } from "../../../CustomView";
import moment from "moment-timezone";
import { confirmModal } from "../../../../utilities/utilities";
import { Empty } from "antd";

function ExpireTable() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let contentUID = params.get("contentUID");
  const dispatch = useDispatch();
  const history = useHistory();
  const { expire } = useSelector((state: HibraryRootState) => ({
    expire: state.expire,
  }));

  useEffect(() => {
    expireActions.getContentExpire(dispatch);

    return () => {
      console.log("destroy");

      dispatch(expireActions.reset());
    };
  }, []);

  const onEndScroll = (e: React.UIEvent<HTMLElement>) => {
    let scroll = e.currentTarget;
    let destScroll = scroll;
    let isEnd =
      destScroll.scrollHeight - destScroll.offsetHeight - destScroll.scrollTop <
      2;
    if (isEnd && expire.lastEvaluatedKey && !expire.loading) {
      expireActions.getContentExpire(dispatch, expire.lastEvaluatedKey);
    }
  };
  return (
    <div>
      <table className="expire-table">
        {expire.loading ? <LoaderAbsolute /> : null}
        {(expire.contentExpire?.length === 0 || !expire.contentExpire) &&
        !expire.loading ? (
          <Empty className="center-absolute " description="ไม่มีข้อมูล" />
        ) : null}
        <thead>
          <tr>
            <th>ลำดับ</th>
            <th>ปก</th>
            <th>รายละเอียด</th>
            <th>ทั้งหมด</th>
            <th>คงเหลือ</th>
            <th>จองคิว</th>
            <th>ยืมแล้ว</th>
            <th>วันที่หมดอายุ</th>
            <th>การจัดการ</th>
          </tr>
        </thead>
        <tbody onScroll={onEndScroll}>
          {expire.contentExpire?.map((el, index) => {
            return <ExpireRow content={el} index={index} />;
          })}
        </tbody>
      </table>
      <EbookViewDialog
        id={contentUID !== null ? contentUID : undefined}
        show={contentUID !== null ? true : false}
        isFree={false}
        catalogueId={undefined}
        onHide={() => {
          history.replace(`#`);
        }}
        isPrivate={undefined}
      />
    </div>
  );
}

export default ExpireTable;

interface ExpireRow {
  content: Content;
  index: number;
}

const ExpireRow = (props: ExpireRow) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const dateFormat = (unixTime?: number) => {
    if (unixTime) {
      return (
        <>
          <div>
            {moment.unix(props.content.expireDate).format("DD/MM/YYYY")}
          </div>
          <div className="error-text">
            {moment.unix(props.content.expireDate).format("HH:mm:ss")}
          </div>
        </>
      );
    } else {
      return "-";
    }
  };
  return (
    <>
      <tr>
        <td className="row-no">{props.index + 1}</td>
        <td className="cover-content">
          <img src={props.content.coverImage} className="cover" alt={`cover`} />
        </td>
        <td>
          <div className="text-title">{props.content.title}</div>
          <div className="text-detail">
            ผู้แต่ง : {props.content.authorName}{" "}
          </div>
        </td>
        <td>{props.content.concurrent}</td>
        <td>{props.content.concurrent - props.content.activeLicense}</td>
        <td>{props.content.rentAmount}</td>
        <td>{props.content.activeLicense}</td>
        <td>{dateFormat(props.content.expireDate)} </td>

        <td>
          <div className="action-column">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="quick-user-tooltip">ลบสื่อ</Tooltip>}
            >
              <a
                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                onClick={() => {
                  confirmModal(
                    () =>
                      expireActions.removeContentExpire(
                        dispatch,
                        false,
                        props.content.contentUID
                      ),
                    `คุณต้องการลบหนังสือ ${props.content.title} หรือไม่`
                  );
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                    src={toAbsoluteUrl("/media/members/delete-button.svg")}
                  />
                </span>
              </a>
            </OverlayTrigger>
            <button
              title="View books"
              className="button-description"
              onClick={(e) => {
                history.replace(`?contentUID=${props.content.contentUID}`);
              }}
            >
              รายละเอียด
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};
