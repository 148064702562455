/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles, withStyles, lighten } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useUploadUIContext } from "./UploadUIContext";
import { withRouter } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import axios from "axios";
import ReactCrop from "react-image-crop";
import { companiesSlice } from "../../_redux/companies/companiesSlice";
import { useDispatch } from "react-redux";
import "react-image-crop/dist/ReactCrop.css";
import { message } from "antd";
const { actions } = companiesSlice;

const pixelRatio = window.devicePixelRatio || 1;

const useStyles1 = makeStyles((theme) => ({
  bigDiv: {
    borderRadius: "0px 12px 12px 12px",
    backgroundColor: "white",
    padding: "4rem 150px",
    height: "calc(100% - 53px)",
  },
  modalDiv: {
    borderRadius: "0px 12px 12px 12px",
    backgroundColor: "white",
    padding: "1rem 0px",
  },
  stat: { background: "#F2F2F2", borderRadius: "20px", padding: "20px 0" },
  denyButton: {
    margin: "8px",
    width: "150px",
    height: "48px",
    borderRadius: "100px",
    border: "solid 1px #012875",
    backgroundColor: "#ffffff",
    fontWeight: "600",
    color: "#012875",
    fontSize: "15px",
  },
  acceptButton: {
    margin: "8px",
    width: "150px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "#012875",
    border: "none",
    color: "white",
    fontWeight: "600",
    fontSize: "16px",
    "&:disabled": {
      backgroundColor: "#e0e0e0",
      color: "#828282",
      "&:hover": {
        cursor: "not-allowed",
      },
    },
  },
  invertButton: {
    margin: "8px",
    width: "150px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "white",
    border: "1px solid #012875",
    color: "#012875",
    fontWeight: "600",
    fontSize: "16px",
  },
  divButton: { marginTop: "72px", textAlign: "center" },
  blue_p: {
    fontSize: 20,
    fontWeight: 600,
    color: "#012875",
    marginBottom: "6px",
  },
  blue_p_small: {
    fontSize: 14,
    fontWeight: 400,
    color: "#012875",
    display: "inline-block",
  },

  dropzoneTitle: { marginBottom: "0px", fontSize: "16px" },
  dropzoneSubTitle: { marginBottom: "72px", fontSize: "14px" },
  progress: {
    margin: "13px 0px",
    borderRadius: "5px",
  },
  progressUpload: {
    margin: "3px 13px 47px",
    borderRadius: "5px",
  },
  information: {
    backgroundColor: "#012875",
    width: 16,
    height: 16,
    borderRadius: 8,
    textAlign: "center",
    margin: "0 0 0 6px",
    display: "inline-block",
    "&:hover": { cursor: "pointer" },
  },
  subProgressLeft: {
    margin: 0,
    fontFamily: "Prompt",
    fontSize: "12px",
    fontWeight: "500",
    float: "left",
    color: "#FF5337",
  },
  subProgressRight: {
    margin: 0,
    fontFamily: "Prompt",
    fontSize: "12px",
    fontWeight: "500",
    float: "right",
    color: "#FF5337",
  },
  uploadedImg: {
    padding: "16px",
    height: "240px",
    maxWidth: "100%",
  },
  acceptButton2: {
    margin: "8px",
    width: "150px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "#012875",
    border: "none",
    color: "white",
    fontWeight: "600",
    fontSize: "16px",
    "&:disabled": {
      backgroundColor: "#e0e0e0",
      color: "#828282",
      "&:hover": {
        cursor: "not-allowed",
      },
    },
  },
  divButton2: { marginTop: "32px", textAlign: "center" },
  title_red2: {
    fontSize: "20px",
    fontWeight: 600,
    color: "#d7042b",
    textAlign: "center",
  },
}));

const FileAndCover = ({
  id,
  isModal,
  openFileInformationDialog,
  handleSubmitOutside,
  FILENAMEToUpload,
  setFILENAMEToUpload,
  completedCrop,
  setCompletedCrop,
  imgRef,
  successCoverUpload,
  setSuccessCoverUpload,
  coverPost,
  setCoverPost,
  setEditedImage,
}) => {
  const dispatch = useDispatch();
  const [uploadingCover, setUploadingCover] = useState(false);
  const [coverImg, setCoverImg] = useState(null);
  const [croppingImage, setCroppingImage] = useState(false);
  const [crop, setCrop] = useState({ unit: "%", width: 100, aspect: 1 / 1 });

  // const [failedCoverUpload, setFailedCoverUpload] = useState(false);
  const [storageLeft, setStorageLeft] = useState(1000000);
  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);
  const [loaded1, setLoaded1] = useState(0);
  const [total1, setTotal1] = useState(0);
  const [percentage1, setPercentage1] = useState(0);
  const previewCanvasRef = useRef(null);
  const [imgAreaInvalid, setImgAreaInvalid] = useState(false);

  const uploadUIContext = useUploadUIContext();
  const uploadUIProps = useMemo(() => {
    return {
      openFileInformationDialog: uploadUIContext
        ? uploadUIContext.openFileInformationDialog
        : null,
    };
  }, [uploadUIContext]);
  const BorderLinearProgress = withStyles({
    root: {
      height: 10,
      backgroundColor: lighten("#ff6c5c", 0.5),
    },
    bar: {
      borderRadius: 20,
      backgroundColor: "#ff6c5c",
    },
  })(LinearProgress);
  const {
    getRootProps: getRootProps1,
    getInputProps: getInputProps1,
    open: open1,
    acceptedFiles: acceptedFiles1,
  } = useDropzone({
    accept: ".png, .jpg, .jpeg",
    noClick: true,
    noKeyboard: true,
    maxSize: 5000000,
    onDropRejected: (e) => onDropRejected(e),
    onDropAccepted: (e) => onDropCoverAccepted(e),
  });

  const onDropCoverAccepted = (e) => {
    if (e && Array.isArray(e) && e.length > 0) {
      setStorageLeft(storageLeft - e[0].size);
    }
  };

  const onDropRejected = (e) => {
    if (e && Array.isArray(e) && e.length > 0) {
      if (e[0].errors && Array.isArray(e[0].errors) && e[0].errors.length > 0) {
        if (e[0].errors[0].code && e[0].errors[0].code === "file-too-large") {
          // console.log(e[0].errors[0]);
          if (isModal) {
            openFileInformationDialog();
          } else {
            uploadUIProps.openFileInformationDialog("file-too-large");
          }
        }
      }
    }
  };

  useEffect(() => {
    const prepareCrop = () => {
      console.log(acceptedFiles1);
      if (acceptedFiles1 &&
        acceptedFiles1.length &&
        acceptedFiles1[0].type === 'image/png') {
        const fileToUpload = acceptedFiles1[0];
        var img = new Image();
        var objectUrl = URL.createObjectURL(fileToUpload);
        img.onload = async function() {
          if (fileToUpload && this.width === 1024 && this.height === 1024) {
            // setCroppingImage(true);
            setCoverPost(fileToUpload);
            uploadCropImage(fileToUpload);
          } else {
            setImgAreaInvalid(true);
          }
          URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;
      } else {
        if (acceptedFiles1.length > 0 && acceptedFiles1[0].type !== 'image/png'){
          message.error('ไม่รองรับไฟล์นามสกุลนี้')
        }
      }
    };
    prepareCrop();
    // reqFunc();
  }, [acceptedFiles1]);

  useEffect(() => {}, []);

  const uploadCropImage = (file) => {
    setUploadingCover(true);
    setCroppingImage(false);
    const postImgFunc = async () => {
      const req = await axios.post(`/superuser/clients/file`, {
        fileName: file.name,
        // contentUID: contentUIDToUpload ? contentUIDToUpload : undefined,
      });
      if (req.data.status && req.data.data) {
        const urlToUpload = req.data.data.url;
        const fieldsToUpload = req.data.data.fields;
        const NEW_FILENAME = req.data.data.fileName;
        setFILENAMEToUpload(NEW_FILENAME);

        var formData = new FormData();
        const entries = Object.entries(fieldsToUpload);
        for (const [key, val] of entries) {
          formData.append(key, val);
        }
        formData.append("file", file);
        axios({
          method: "post",
          url: urlToUpload,
          data: formData,
          onUploadProgress: (progressEvent) => {
            if (progressEvent) {
              setLoaded1((progressEvent.loaded / 1000).toFixed(2));
              setTotal1((progressEvent.total / 1000).toFixed(2));
              setPercentage1(
                ((100 * progressEvent.loaded) / progressEvent.total).toFixed()
              );
            }
          },
        })
          .then(async () => {
            setUploadingCover(false);
            setSuccessCoverUpload(true);
            if (isModal) {
              uploadEditedImage(NEW_FILENAME);
            }
          })
          .catch(() => {
            // setFailedCoverUpload(true);
          });
      }
    };
    postImgFunc();
  };

  const uploadEditedImage = (filename) => {
    const postImgFunc = async () => {
      const req = await axios.patch(`/superuser/clients/${id}/profile`, {
        logoImages: filename,
        // contentUID: contentUIDToUpload ? contentUIDToUpload : undefined,
      });
      if (req.data.status && req.data.data) {
        dispatch(
          actions.updatedImage({ id: id, logoImages: req.data.data.logoImages })
        );
        setEditedImage(req.data.data.logoImages);
      }
    };
    postImgFunc();
  };

  useEffect(() => {
    if (coverPost) {
      setCoverImg(URL.createObjectURL(coverPost));
    } else {
      setCoverImg(null);
    }
  }, [coverPost]);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.save();
    ctx.beginPath();
    ctx.arc(
      crop.width / 2,
      crop.width / 2,
      crop.width / 2,
      0,
      Math.PI * 2,
      true
    );
    ctx.closePath();
    ctx.clip();

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    ctx.beginPath();
    ctx.arc(0, 0, crop.width / 2, 0, Math.PI * 2, true);
    ctx.clip();
    ctx.closePath();
    ctx.restore();
  }, [completedCrop]);

  const deleteButton = (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="quick-user-tooltip">ลบไฟล์</Tooltip>}
    >
      <a
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        style={{
          marginLeft: "0.75rem",
          position: "absolute",
          right: "22px",
          zIndex: "1",
        }}
        onClick={() => {
          const reqFunc = async () => {
            const req = await axios.delete(`/superuser/clients/file`, {
              data: { fileName: FILENAMEToUpload },
            });
            if (req.data.status) {
              setCrop({ unit: "%", width: 100, aspect: 1 / 1 });
              setSuccessCoverUpload(false);
              setCroppingImage(false);
              setCoverImg(null);
            }
          };
          if (FILENAMEToUpload) {
            reqFunc();
          } else {
            setCrop({ unit: "%", width: 100, aspect: 1 / 1 });
            setSuccessCoverUpload(false);
            setCroppingImage(false);
            setCoverImg(null);
          }
        }}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/members/delete-button.svg")} />
        </span>
      </a>
    </OverlayTrigger>
  );

  const classes = useStyles1();
  return (
    <>
      <div className={isModal ? classes.modalDiv : classes.bigDiv}>
        <div className="row" style={{ justifyContent: "center" }}>
          <div className={isModal ? "col-12" : "col-6"}>
            <p className={classes.blue_p}>
              {isModal ? "แก้ไขไฟล์โลโก้" : "อัปโหลดไฟล์โลโก้"}
            </p>
            <p className={classes.blue_p_small}>
              ไฟล์ที่รองรับ .png ขนาด 1024x1024px เท่านั้น
            </p>
            {/* <p className={classes.information} onClick={uploadUIProps.openCoverInformationDialog}>
            <img src="/media/members/information.svg" style={{ marginBottom: "5px" }} alt="" />
          </p> */}
            <div
              style={{
                padding: "10px 10px 0 10px",
                border: "1px dashed",
                textAlign: "center",
                borderRadius: "10px",
                display:
                  uploadingCover || successCoverUpload ? "block" : "none",
                borderBottom: uploadingCover ? "0px" : "1px dashed",
                borderBottomLeftRadius: uploadingCover ? "0" : "10px",
                borderBottomRightRadius: uploadingCover ? "0" : "10px",
              }}
            >
              <img
                alt=""
                src={coverImg}
                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                style={{
                  width: "200px",
                  height: "200px",
                  borderRadius: "50%",
                }}
              />
              {successCoverUpload ? (
                <>
                  {deleteButton}
                  <div style={{ height: "90px", paddingTop: "35px" }}>
                    <p className={classes.dropzoneTitle}>
                      อัปโหลดโลโก้สำเร็จแล้ว
                    </p>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            {coverImg && croppingImage ? (
              <div
                style={{
                  padding: "10px 10px 0 10px",
                  borderTop: "1px dashed",
                  borderTopRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                  border: "1px dashed",
                  textAlign: "center",
                  borderRadius: "10px",
                }}
              >
                {deleteButton}
                {/* <img src={coverImg} className={classes.uploadedImg} alt="" /> */}
                <ReactCrop
                  src={coverImg}
                  onImageLoaded={onLoad}
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                  circularCrop
                />
                <div style={{ height: "60px", paddingTop: "15px" }}>
                  <p className={classes.dropzoneTitle}>
                    ตัดรูปภาพตามที่ต้องการ
                  </p>
                </div>
              </div>
            ) : successCoverUpload ? (
              ""
            ) : (
              <div
                {...getRootProps1({ className: "dropzone" })}
                style={{
                  border: "1px dashed",
                  textAlign: "center",
                  borderRadius: "10px",
                  borderTop: uploadingCover ? "0px" : "1px dashed",
                  borderTopLeftRadius: uploadingCover ? "0" : "10px",
                  borderTopRightRadius: uploadingCover ? "0" : "10px",
                }}
              >
                {uploadingCover ? (
                  ""
                ) : (
                  <>
                    <input {...getInputProps1()} accept='image/png'/>
                    <img src="/media/members/upload-file.png" alt="" />
                  </>
                )}
                <div
                  style={{
                    height: "196px",
                    paddingTop: uploadingCover ? "50px" : undefined,
                  }}
                >
                  {uploadingCover ? (
                    <>
                      <p className={classes.dropzoneTitle}>
                        รอสักครู่ ระบบกำลังอัปโหลดไฟล์...
                      </p>
                      <div
                        className="row"
                        style={{ margin: "40px 0.5px 0", color: "#012875" }}
                      >
                        <p
                          className="col-6"
                          style={{
                            fontSize: "12px",
                            textAlign: "left",
                            margin: "0",
                          }}
                        >
                          {loaded1}KB/{total1}KB
                        </p>
                        <p
                          className="col-6"
                          style={{
                            fontSize: "14px",
                            textAlign: "end",
                            margin: "0",
                          }}
                        >
                          {parseInt(percentage1, 10)}%
                        </p>
                      </div>
                      <BorderLinearProgress
                        className={classes.progressUpload}
                        variant="determinate"
                        color="secondary"
                        value={percentage1}
                      />
                    </>
                  ) : (
                    <>
                      <h4>Drag a file here</h4>
                      <div>or browse your computer</div>
                      <button
                        type="button"
                        className="browseButton"
                        onClick={open1}
                      >
                        Browse
                      </button>
                    </>
                  )}
                  {/* {failedCoverUpload ? (
                <>
                  <img src="/media/members/upload-file.png" alt="" />
                  <div style={{ height: "196px" }}>
                    <p className={classes.dropzoneTitle}>ล้มเหลว กรุณาลองใหม่อีกครั้ง</p>
                  </div>
                </>
              ) : (

              )} */}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={classes.divButton}>
          <button
            className={classes.acceptButton}
            type="submit"
            disabled={
              !coverImg ||
              uploadingCover ||
              (completedCrop && completedCrop.width === 0)
            }
            onClick={() => {
              if (successCoverUpload) {
                handleSubmitOutside();
              }
            }}
          >
            {uploadingCover
              ? "กรุณารอสักครู่"
              : successCoverUpload
              ? isModal
                ? "บันทึก"
                : "ถัดไป"
              : "อัปโหลด"}
          </button>
        </div>
      </div>
      <Modal
        backdrop="static"
        show={imgAreaInvalid}
        onHide={() => setImgAreaInvalid(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body>
          <div className="center">
            <img src="/media/members/image-modal.svg" alt="" />
            <div className={classes.title_red2}>
              กรุณาใช้ไฟล์รูปที่มีขนาด 1024x1024px
            </div>
            <div className={classes.divButton2}>
              <button
                type="button"
                onClick={() => {
                  setImgAreaInvalid(false);
                }}
                className={classes.acceptButton2}
              >
                ยืนยัน
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withRouter(FileAndCover);
