import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";

const initialFilter = {
  filter: {
    firstName: "",
    lastName: "",
    email: "",
    ipAddress: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 50,
  limit: 50, //10
  totalSize: 9, //จำนวนสมาชิก (ไม่ได้ใช้แล้ว)
};

const VideosUIContext = createContext();

export function useVideosUIContext() {
  return useContext(VideosUIContext);
}

export const VideosUIConsumer = VideosUIContext.Consumer;

export function VideosUIProvider({ videosUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initVideo = {
    id: undefined,
    name: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initVideo,
    openFileInformationDialog: videosUIEvents.openFileInformationDialog,
    newVideoButtonClick: videosUIEvents.newVideoButtonClick,
    openEditVideoDialog: videosUIEvents.openEditVideoDialog,
    openViewVideoDialog: videosUIEvents.openViewVideoDialog,
    openDeleteVideoDialog: videosUIEvents.openDeleteVideoDialog,
    openBlockVideoDialog: videosUIEvents.openBlockVideoDialog,
    openUnblockVideoDialog: videosUIEvents.openUnblockVideoDialog,
    newChannelButtonClick: videosUIEvents.newChannelButtonClick,
    newCreatorButtonClick: videosUIEvents.newCreatorButtonClick,
    newCategoryButtonClick: videosUIEvents.newCategoryButtonClick,
    newSubCategoryButtonClick: videosUIEvents.newSubCategoryButtonClick,
  };

  return <VideosUIContext.Provider value={value}>{children}</VideosUIContext.Provider>;
}
