import React from "react";
import { ClipLoader } from "react-spinners";

export const LoaderView = () => {
  return (
    <div className="cliploader-center">
      <ClipLoader size={50} color={"#123abc"} loading={true} />
    </div>
  );
};

export const LoaderViewSmall = () => {
  return (
    <div className="cliploader-center small">
      <ClipLoader size={50} color={"#123abc"} loading={true} />
    </div>
  );
};

export const LoaderViewFull = () => {
  return (
    <div className="cliploader-center full">
      <ClipLoader size={50} color={"#123abc"} loading={true} />
    </div>
  );
};

export const LoaderAbsolute = () => {
  return (
    <div className="center-absolute">
      <ClipLoader size={50} color={"#123abc"} loading={true} />
    </div>
  );
};
