import React, { useState, useEffect } from "react";
import { CategoriesLoadingDialog } from "./categories-loading-dialog/CategoriesLoadingDialog";
import { CategoryNewDialog } from "./category-new-dialog/CategoryNewDialog";
import { CategoryBlockDialog } from "./category-block-dialog/CategoryBlockDialog";
import { CategoryUnblockDialog } from "./category-unblock-dialog/CategoryUnblockDialog";
import { CategoryPinDialog } from "./category-pin-dialog/CategoryPinDialog";
import { CategoryUnpinDialog } from "./category-unpin-dialog/CategoryUnpinDialog";
import { CategoryEditDialog } from "./category-edit-dialog/CategoryEditDialog";
import { CategoryDeleteDialog } from "./category-delete-dialog/CategoryDeleteDialog";
import { CategoriesUIProvider } from "./CategoriesUIContext";
import { CategoriesCard } from "./CategoriesCard";
import queryString from "query-string";

export function CategoriesPrivatePage(props) {
  const { history, location, contentType } = props;
  const [isNewCat, setNewCat] = useState(0);
  const [isNewSubcat, setNewSubcat] = useState(0);
  const [isEditCat, setEditCat] = useState(0);
  const [isDeleteCat, setDeleteCat] = useState(0);
  const [isBlockCat, setBlockCat] = useState(0);
  const [isUnblockCat, setUnblockCat] = useState(0);
  const [isPinCat, setPinCat] = useState(0);
  const [isUnpinCat, setUnpinCat] = useState(0);
  const [catName, setCatName] = useState(null);
  const [catId, setCatId] = useState(null);
  const [isPrivate, setIsPrivate] = useState(0);
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.newCat) {
      setNewCat(1);
    }
    if (parsed.newSubcat) {
      setNewSubcat(1);
    }
    if (parsed.editCat) {
      setEditCat(1);
    }
    if (parsed.deleteCat) {
      setDeleteCat(1);
    }
    if (parsed.blockCat) {
      setBlockCat(1);
    }
    if (parsed.unblockCat) {
      setUnblockCat(1);
    }
    if (parsed.pinCat) {
      setPinCat(1);
    }
    if (parsed.unpinCat) {
      setUnpinCat(1);
    }
    //------------------------------
    if (parsed.catName) {
      setCatName(parsed.catName);
    } else {
      setCatName(null);
    }
    if (parsed.catId) {
      setCatId(parsed.catId);
    } else {
      setCatId(null);
    }
    //------------------------------
    if (parsed.isPrivate) {
      setIsPrivate(parseInt(parsed.isPrivate, 10));
    }
  }, [location.search]);
  const categoriesUIEvents = {
    newCategoryButtonClick: (isPrivate) => {
      history.push(`/categories-private-${contentType}?isPrivate=${isPrivate}&newCat=true`);
    },
    newSubCategoryButtonClick: (isPrivate, catId, catName) => {
      history.push(`/categories-private-${contentType}?isPrivate=${isPrivate}&newSubcat=true`);
    },
    openSubCategoriesPage: (id, catName) => {
      history.push(`/subcategories-private-${contentType}?catId=${id}&catName=${catName}`);
    },
    openEditCategoryDialog: (name, catId, isPrivate) => {
      history.push(
        `/categories-private-${contentType}?isPrivate=${isPrivate}&catId=${catId}&catName=${name}&editCat=true`
      );
    },
    openDeleteCategoryDialog: (name, id, isPrivate) => {
      history.push(
        `/categories-private-${contentType}?isPrivate=${isPrivate}&catId=${id}&catName=${name}&deleteCat=true`
      );
    },
    openBlockCategoryDialog: (name, id, isPrivate) => {
      history.push(
        `/categories-private-${contentType}?isPrivate=${isPrivate}&catId=${id}&catName=${name}&blockCat=true`
      );
    },
    openUnblockCategoryDialog: (name, id, isPrivate) => {
      history.push(
        `/categories-private-${contentType}?isPrivate=${isPrivate}&catId=${id}&catName=${name}&unblockCat=true`
      );
    },
    openPinCategoryDialog: (name, id, isPrivate) => {
      history.push(
        `/categories-private-${contentType}?isPrivate=${isPrivate}&catId=${id}&catName=${name}&pinCat=true`
      );
    },
    openUnpinCategoryDialog: (name, id, isPrivate) => {
      history.push(
        `/categories-private-${contentType}?isPrivate=${isPrivate}&catId=${id}&catName=${name}&unpinCat=true`
      );
    },
    //----------------------------------------------------------------------------------
    openViewCategoryDialog: (id) => {
      history.push(`/categories/${id}/view`);
    },
    openEditSubCategoryDialog: (name, catId, subcatId, isPrivate, catName) => {
      history.push(
        `/categories/${catId}/sub/${catName}/${encodeURIComponent(
          name
        )}/${subcatId}/${isPrivate}/edit`
      );
    },
    openDeleteSubCategoryDialog: (name, catId, subcatId, isPrivate, catName) => {
      history.push(
        `/categories/${catId}/sub/${catName}/${encodeURIComponent(
          name
        )}/${subcatId}/${isPrivate}/delete`
      );
    },
    openDeleteCategoriesDialog: () => {
      history.push(`/categories/deleteCategories`);
    },
    openFetchCategoriesDialog: () => {
      history.push(`/categories/fetch`);
    },
    openUpdateCategoriesStatusDialog: () => {
      history.push("/categories/updateStatus");
    },
    openSubCategoryBooksPage: (type) => {
      history.push(`/categories/${type}/sub/books`);
    },
    contentType,
  };

  return (
    <CategoriesUIProvider categoriesUIEvents={categoriesUIEvents}>
      <CategoriesLoadingDialog />
      <CategoriesCard isPrivate={1} />
      {isNewCat ? (
        <CategoryNewDialog
          show={true}
          onHide={() => {
            history.push(`/categories-private-${contentType}`);
            setNewCat(0);
          }}
          onSuccess={() => {
            history.push(`/categories-private-${contentType}`);
            setNewCat(0);
          }}
          isPrivate={isPrivate}
          history={history}
          contentType={contentType}
        />
      ) : (
        ""
      )}
      {isNewSubcat ? (
        <CategoryNewDialog
          show={true}
          onHide={() => {
            history.push(`/categories-private-${contentType}`);
            setNewSubcat(0);
          }}
          onSuccess={() => {
            setNewSubcat(0);
          }}
          isPrivate={isPrivate}
          isSubCat={true}
          history={history}
          contentType={contentType}
        />
      ) : (
        ""
      )}
      {isEditCat ? (
        <CategoryEditDialog
          show={true}
          catId={catId}
          name={catName}
          isPrivate={isPrivate}
          onHide={() => {
            history.push(`/categories-private-${contentType}`);
            setEditCat(0);
          }}
        />
      ) : (
        ""
      )}
      {isDeleteCat ? (
        <CategoryDeleteDialog
          show={true}
          id={catId}
          name={catName}
          isPrivate={isPrivate}
          onHide={() => {
            history.push(`/categories-private-${contentType}`);
            setDeleteCat(0);
          }}
        />
      ) : (
        ""
      )}
      {isBlockCat ? (
        <CategoryBlockDialog
          show={true}
          id={catId}
          name={catName}
          isPrivate={isPrivate}
          onHide={() => {
            history.push(`/categories-private-${contentType}`);
            setBlockCat(0);
          }}
        />
      ) : (
        ""
      )}
      {isUnblockCat ? (
        <CategoryUnblockDialog
          show={true}
          id={catId}
          name={catName}
          isPrivate={isPrivate}
          onHide={() => {
            history.push(`/categories-private-${contentType}`);
            setUnblockCat(0);
          }}
        />
      ) : (
        ""
      )}
      {isPinCat ? (
        <CategoryPinDialog
          show={true}
          id={catId}
          name={catName}
          isPrivate={isPrivate}
          onHide={() => {
            history.push(`/categories-private-${contentType}`);
            setPinCat(0);
          }}
        />
      ) : (
        ""
      )}
      {isUnpinCat ? (
        <CategoryUnpinDialog
          show={true}
          id={catId}
          name={catName}
          isPrivate={isPrivate}
          onHide={() => {
            history.push(`/categories-private-${contentType}`);
            setUnpinCat(0);
          }}
        />
      ) : (
        ""
      )}
    </CategoriesUIProvider>
  );
}
