import React, { useMemo } from "react";
import { useBannersUIContext } from "../BannersUIContext";

export function BannersGrouping() {
  // Banners UI Context
  const bannersUIContext = useBannersUIContext();
  const bannersUIProps = useMemo(() => {
    return {
      ids: bannersUIContext.ids,
      setIds: bannersUIContext.setIds,
      openDeleteBannersDialog: bannersUIContext.openDeleteBannersDialog,
      openFetchBannersDialog: bannersUIContext.openFetchBannersDialog,
      openUpdateBannersStatusDialog:
        bannersUIContext.openUpdateBannersStatusDialog,
    };
  }, [bannersUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                  Selected records count: <b>{bannersUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                onClick={bannersUIProps.openDeleteBannersDialog}
              >
                <i className="fa fa-trash"></i> Delete All
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={bannersUIProps.openFetchBannersDialog}
              >
                <i className="fa fa-stream"></i> Fetch Selected
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={bannersUIProps.openUpdateBannersStatusDialog}
              >
                <i className="fa fa-sync-alt"></i> Update Status
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
