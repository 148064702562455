/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ColorForm from "../../upload-company/ColorForm";
import axios from "axios";
import {
  HtiResponse,
  CompanyTheme,
  PresetTheme,
  Themes,
  ThemeMode,
} from "../../../../../../interface";
import { LoaderViewSmall } from "../../../../CustomView";

interface ColorEditDialogProps {
  id: string;
  show: any;
  onHide: () => void;
}
export function ColorEditDialog({ id, show, onHide }: ColorEditDialogProps) {
  const [colorId, setColorId] = useState<string | null>(null);

  const [colorThemeData, setColorThemeData] = useState<PresetTheme[]>([]);
  const [themeValue, setThemeValue] = useState<ThemeMode>(ThemeMode.Light);
  const [lightColorObj, setLightColorObj] = useState({});
  const [darkColorObj, setDarkColorObj] = useState({});

  const [defaultLightColorObj, setDefaultLightColorObj] = useState<Themes>();
  const [defaultDarkColorObj, setDefaultDarkColorObj] = useState<Themes>();

  // if !id we should close modal
  useEffect(() => {
    console.log(id);
  });
  useEffect(() => {
    if (!id) {
      onHide();
      setColorId(null);
    } else {
      setColorId(id);
      const reqFunc = async () => {
        try {
          const req = await axios.get<HtiResponse<CompanyTheme>>(
            `/superuser/clients/${id}/theme`
          );
          // const { data } = req;
          const { data }: HtiResponse<CompanyTheme> = req.data;
          if (data) {
            // data.colorTheme;
            setColorThemeData(data?.colorTheme);
            setThemeValue(data?.mode ? data?.mode : ThemeMode.Light);
            setLightColorObj(data?.theme);
            setDefaultLightColorObj(data?.theme);
            data?.colorTheme.forEach((e) => {
              if (e.value === "dark") {
                setDarkColorObj(e.color);
                setDefaultDarkColorObj(
                  Object.assign({}, e.color, {
                    Primary: "#c4c4c4",
                    PrimaryVariant: "#c4c4c4",
                    Secondary: "#c4c4c4",
                    SecondaryVariant: "#c4c4c4",
                  })
                );
              }
            });
          }
        } catch (err) {
          //axios.patch(`/member/logout`);
        }
      };
      reqFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const submitTheme = async (isPrev: boolean) => {
    if (isPrev) {
      onHide();
    } else {
      const postTheme = await axios.put(
        `/superuser/clients/${colorId}/theme`,
        themeValue === ThemeMode.Light ? lightColorObj : darkColorObj
      );
      if (postTheme.status) {
        onHide();
      }
    }
  };

  return (
    <Modal
      backdrop="static"
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Body>
        {Array.isArray(colorThemeData) && colorThemeData.length ? (
          <>
            <h2 className="center">แก้ไขสีองค์กร</h2>
            <ColorForm
              isEditing={true}
              uploadValue={{
                id: undefined,
                name: id,
                nameLib: "",
                nameMini: "",
              }}
              // clientUIDForUpdate={id}
              handleSubmitOutside={submitTheme}
              colorThemeData={colorThemeData}
              // colorObj={undefined}
              // setColorObj={undefined}
              lightColorObj={lightColorObj}
              setLightColorObj={setLightColorObj}
              darkColorObj={darkColorObj}
              setDarkColorObj={setDarkColorObj}
              themeValue={themeValue}
              setThemeValue={setThemeValue}
              defaultLightColorObj={defaultLightColorObj}
              defaultDarkColorObj={defaultDarkColorObj}
            />
          </>
        ) : (
          <LoaderViewSmall />
        )}
      </Modal.Body>
    </Modal>
  );
}
