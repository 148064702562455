import * as requestFromServer from './customersCrud';
import { customersSlice, callTypes } from './customersSlice';
import { customersUIPropsQueryParams } from '../../../../../interface/request';
import { LastEvaluatedKey_membersData } from '../../../../../interface/member';
import { customerForCreation } from '../../../../../interface/member';

const { actions } = customersSlice;

interface customerForEdit {
   email?: string;

   password?: string;
   staffID?: string;
   role?: string;
   company?: string;
   startDate?: number;
   expireDate?: number;
   name?: string;
   surname?: string;
   memberUID: string;
}
export const fetchCustomers = (
   queryParams: customersUIPropsQueryParams,
   lastEvaluate: LastEvaluatedKey_membersData,
   setLoadingBottom?: (loading: boolean) => void
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
   dispatch(actions.startCall({ callType: callTypes.list }));
   return requestFromServer
      .findCustomers(queryParams, lastEvaluate)
      .then(response => {
         const { LastEvaluatedKey, members, totalAmount } = response.data.data;
         const entities = members;
         if (setLoadingBottom) {
            setLoadingBottom(false);
         }
         dispatch(
            actions.customersFetched({
               totalCount: totalAmount,
               entities,
               LastEvaluatedKey,
               lastEvaluate,
               keyword: queryParams.keyword
            })
         );
      })
      .catch(error => {
         console.log(error.response.data.errorCode);
         
         if (error && error.response && error.response.data) {
            if (error.response.data.errorCode === '012') {
               alert(
                  'กรุณาเข้าสู่ระบบอีกครั้ง เนื่องจากระยะเวลาการใช้งานหมดอายุ'
               );
               window.location.href = '/logout';
            } else if (error.response.data.errorCode === '009') {
               console.log('is error 009');
               
               dispatch(
                  actions.customersFetched({
                     totalCount: 0,
                     entities: [],
                     LastEvaluatedKey: undefined,
                     lastEvaluate,
                     keyword: queryParams.keyword
                  })
               );
            }
         }
         error.clientMessage = "Can't find customers";
         dispatch(actions.catchError({ error, callType: callTypes.list }));
      });
};

export const fetchCustomer = (id: string) => (
   dispatch: (arg0: { payload: any; type: string }) => void
) => {
   if (!id) {
      return dispatch(actions.customerFetched({ customerForEdit: undefined }));
   }

   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .getCustomerById(id)
      .then(response => {
         const customer = response.data.data;

         // if (!customer.role) customer.role = "user";

         dispatch(actions.customerFetched({ customerForEdit: customer }));
      })
      .catch(error => {
         error.clientMessage = "Can't find customer";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const unblockAllBanned = () => {
   console.log('unblockAll');
};

export const deleteCustomer = (id: string) => (
   dispatch: (arg0: { payload: any; type: string }) => void
) => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .deleteCustomer(id)
      .then(response => {
         dispatch(actions.customerDeleted({ id }));
      })
      .catch(error => {
         error.clientMessage = "Can't delete customer";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const blockCustomer = (id: string) => (
   dispatch: (arg0: { payload: any; type: string }) => void
) => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .blockCustomer(id)
      .then(response => {
         dispatch(actions.customerBlocked({ id }));
      })
      .catch(error => {
         error.clientMessage = "Can't block customer";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const resetPwCustomer = (id: string) => (
   dispatch: (arg0: { payload: any; type: string }) => void
) => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .blockCustomer(id)
      .then(response => {
         // dispatch(actions.customerDeleted({ id }));
      })
      .catch(error => {
         error.clientMessage = "Can't reset customer password";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const createCustomer = (
   customerForCreation: customerForCreation,
   setSuccessModal: any
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
   console.log(customerForCreation);

   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .createCustomer(
         Object.assign(customerForCreation, {
            rawPassword: customerForCreation.password
         })
      )
      .then(response => {
         if (response.data.status) {
            dispatch(actions.customerCreated());
            if (setSuccessModal) {
               setSuccessModal(true);
            }
         }
      })
      .catch(error => {
         // alert("กรุณาลองใหม่ อีเมลหรือรหัสพนักงานมีในระบบแล้ว");
         error.clientMessage = "Can't create customer";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const updateCustomer = (
   customer: customerForEdit,
   memberUID: string,
   setSuccessModal: any
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
   // if (customer.role !== undefined) {
   //   delete customer.role;
   // }

   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .updateCustomer(customer, memberUID)
      .then(() => {
         dispatch(actions.customerUpdated());
         if (setSuccessModal) {
            setSuccessModal(true);
         }
      })
      .catch(error => {
         error.clientMessage = "Can't update customer";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const memberSelectRow = (checked: boolean, memberUID: string) => (
   dispatch: (arg0: { payload: any; type: string }) => void
) => {
   dispatch(actions.checkedRow({ checked, memberUID }));
};

// export const deleteCustomers = (ids) => (
//   dispatch: (arg0: { payload: any; type: string }) => void
// ) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deleteCustomers(ids)
//     .then(() => {
//       dispatch(actions.customersDeleted({ ids }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't delete customers";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };
