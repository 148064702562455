import { createSlice } from "@reduxjs/toolkit";
import {
  
  LastEvaluatedKey_Content,
  LastEvaluatedKey_FreeContent,
  Content,
   ContentById,
  
  
} from "../../../../../interface";
interface videosStateRedux{
  listLoading: boolean,
  actionsLoading: boolean,
  totalCount: 0,
  entities: Content[],
  entities_hti: Content[],
  entities_free: Content[],
  videoForEdit?: ContentById ,
  lastError: null,
  LastEvaluatedKey: LastEvaluatedKey_Content  |{},
  LastEvaluatedKey_hti: LastEvaluatedKey_Content  |{},
  LastEvaluatedKey_free: LastEvaluatedKey_FreeContent | {},
  error?:string | null
}
const initialVideosState :videosStateRedux = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  entities_hti: [],
  entities_free: [],
  videoForEdit: undefined,
  lastError: null,
  LastEvaluatedKey: {},
  LastEvaluatedKey_hti: {},
  LastEvaluatedKey_free: {},
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const videosSlice = createSlice({
  name: "videos",
  initialState: initialVideosState,
  reducers: {
    catchError: (state, action) => {
      if (
        action.payload.error &&
        action.payload.error.response &&
        action.payload.error.response.data
      ) {
        state.error = action.payload.error.response.data.errorCode;
      }
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getVideoById
    videoFetched: (state, action) => {
      state.actionsLoading = false;
      state.videoForEdit = action.payload.videoForEdit;
      state.error = null;
    },
    // findVideos
    videosFetched: (state, action) => {
      const {
        totalCount,
        entities,
        LastEvaluatedKey,
        lastEvaluate,
        isPrivate,
        isFree,
        memberMode,
      } = action.payload;
      const blockedOrNormal_Private = (e_status : number) => (e_status === 1 ? 1 : 0);
      state.listLoading = false;
      state.error = null;
      if (isPrivate) {
        if (lastEvaluate === undefined) {
          if (memberMode) {
            state.entities = entities.filter(
              (e:Content) => blockedOrNormal_Private(e.status) === parseInt(memberMode, 10)
            );
          } else {
            state.entities = entities;
          }
        } else {
          if (memberMode) {
            entities
              .filter((e:Content) => blockedOrNormal_Private(e.status) === parseInt(memberMode, 10))
              .forEach((item:Content) => state.entities.push(item));
          } else {
            entities.forEach((item:Content) => state.entities.push(item));
          }
        }
      } else {
        if (isFree) {
          if (lastEvaluate === undefined) {
            if (memberMode) {
              state.entities_free = entities.filter((e:Content) => {
                return e.status === parseInt(memberMode, 10);
              });
            } else {
              state.entities_free = entities;
            }
          } else {
            if (memberMode) {
              entities
                .filter((e:Content) => e.status === parseInt(memberMode, 10))
                .forEach((item:Content) => state.entities_free.push(item));
            } else {
              entities.forEach((item:Content) => state.entities_free.push(item));
            }
          }
        } else {
          if (lastEvaluate === undefined) {
            if (memberMode) {
              state.entities_hti = entities.filter((e:Content) => e.status === parseInt(memberMode, 10));
            } else {
              state.entities_hti = entities;
            }
          } else {
            if (memberMode) {
              entities
                .filter((e:Content) => e.status === parseInt(memberMode, 10))
                .forEach((item:Content) => state.entities_hti.push(item));
            } else {
              entities.forEach((item:Content) => state.entities_hti.push(item));
            }
          }
        }
      }
      state.totalCount = totalCount;
      if (isFree) {
        state.LastEvaluatedKey_free = LastEvaluatedKey;
      } else if (isPrivate) {
        state.LastEvaluatedKey = LastEvaluatedKey;
      } else {
        state.LastEvaluatedKey_hti = LastEvaluatedKey;
      }
      // state.entities = mockUsers;
      // state.totalCount = 17;
    },
    // createVideo
    videoCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.video);
    },
    // updateVideo
    videoUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.contentUID === action.payload.id) {
          // console.log(action.payload.video);
          // delete action.payload.video.coverImage;
          return Object.assign({}, entity, action.payload.video);
        }
        return entity;
      });
    },
    // deleteVideo
    videoDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => el.contentUID !== action.payload.id);
    },
    // blockVideo
    videoBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.contentUID === action.payload.id) {
          return Object.assign({}, entity, { status: 2 });
        }
        return entity;
      });
    },
    // unblockVideo
    videoUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.contentUID === action.payload.id) {
          return Object.assign({}, entity, { status: 1 });
        }
        return entity;
      });
    },
    // deleteVideos
    // videosDeleted: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities = state.entities.filter((el) => !action.payload.ids.includes(el.id));
    // },
    // videosUpdateState
    // videosStatusUpdated: (state, action) => {
    //   state.actionsLoading = false;
    //   state.error = null;
    //   const { ids, status } = action.payload;
    //   state.entities = state.entities.map((entity) => {
    //     if (ids.findIndex((id) => id === entity.id) > -1) {
    //       entity.status = status;
    //     }
    //     return entity;
    //   });
    // },
    elementUpdated: (state,) => {
      state.actionsLoading = false;
    },
  },
});
