import React, { useState, useEffect } from "react";
import { UploadUIProvider } from "./UploadUIContext";
import { UploadCard } from "./UploadCard";
import { UploadInformationDialog } from "./upload-information-dialog/UploadInformationDialog";
import { PublisherNewDialog } from "../publishers/publisher-new-dialog/PublisherNewDialog";
import { CategoryNewDialog } from "../categories/category-new-dialog/CategoryNewDialog";
import queryString from "query-string";

export function UploadCompanyPage({ history, location }) {
  const [information, setInformation] = useState(null);
  const [newPublisher, setNewPublisher] = useState(null);
  const [newAuthor, setNewAuthor] = useState(null);
  const [newCategory, setNewCategory] = useState(null);
  const [newSubCategory, setNewSubCategory] = useState(null);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.information) {
      setInformation(parsed.information);
    }
    if (parsed.newPublisher) {
      setNewPublisher(parsed.newPublisher);
    }
    if (parsed.newAuthor) {
      setNewAuthor(parsed.newAuthor);
    }
    if (parsed.newCategory) {
      setNewCategory(parsed.newCategory);
    }
    if (parsed.newSubCategory) {
      setNewSubCategory(parsed.newSubCategory);
    }
  }, [location.search]);

  const uploadUIEvents = {
    openFileInformationDialog: (error) => {
      if (error) {
        history.push("/upload-company?information=error");
      } else {
        history.push("/upload-company?information=left");
      }
    },
    openCoverInformationDialog: () => {
      history.push("/upload-company?information=right");
    },
    newPublisherButtonClick: () => {
      history.push("/upload-company?newPublisher=true");
    },
    newAuthorButtonClick: () => {
      history.push("/upload-company?newAuthor=true");
    },
    newCategoryButtonClick: () => {
      history.push("/upload-company?newCategory=true");
    },
    newSubCategoryButtonClick: () => {
      history.push("/upload-company?newSubCategory=true");
    },
  };

  return (
    <UploadUIProvider uploadUIEvents={uploadUIEvents}>
      {information === "error" ? (
        <UploadInformationDialog
          show={true}
          onHide={() => {
            history.push(`/upload-company`);
            setInformation(null);
          }}
          isError={true}
        />
      ) : (
        ""
      )}
      {information === "left" ? (
        <UploadInformationDialog
          show={true}
          onHide={() => {
            history.push(`/upload-company`);
            setInformation(null);
          }}
          isLeft={true}
        />
      ) : (
        ""
      )}
      {information === "right" ? (
        <UploadInformationDialog
          show={true}
          onHide={() => {
            history.push(`/upload-company`);
            setInformation(null);
          }}
          isLeft={false}
        />
      ) : (
        ""
      )}
      {newPublisher ? (
        <PublisherNewDialog
          show={true}
          onHide={() => {
            history.push(`/upload-company`);
            setNewPublisher(false);
          }}
          isPrivate={1}
          notOrigin={true}
        />
      ) : (
        ""
      )}
      {newAuthor ? (
        <PublisherNewDialog
          show={true}
          onHide={() => {
            history.push(`/upload-company`);
            setNewAuthor(false);
          }}
          isPrivate={1}
          isAuthor={true}
          notOrigin={true}
        />
      ) : (
        ""
      )}
      {newCategory ? (
        <CategoryNewDialog
          show={true}
          onHide={() => {
            history.push(`/upload-company`);
            setNewCategory(false);
          }}
          isPrivate={1}
          notOrigin={true}
        />
      ) : (
        ""
      )}
      {newSubCategory ? (
        <CategoryNewDialog
          show={true}
          onHide={() => {
            history.push(`/upload-company`);
            setNewSubCategory(false);
          }}
          isPrivate={1}
          isSubCat={true}
          notOrigin={true}
        />
      ) : (
        ""
      )}
      <UploadCard />
    </UploadUIProvider>
  );
}
