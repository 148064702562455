import React, { useEffect, useState } from "react";
import { ChannelsLoadingDialog } from "./channels-loading-dialog/ChannelsLoadingDialog";
import { ChannelNewDialog } from "./channel-new-dialog/ChannelNewDialog";
import { ChannelEditDialog } from "./channel-edit-dialog/ChannelEditDialog";
import { ChannelDeleteDialog } from "./channel-delete-dialog/ChannelDeleteDialog";
import { ChannelBlockDialog } from "./channel-block-dialog/ChannelBlockDialog";
import { ChannelUnblockDialog } from "./channel-unblock-dialog/ChannelUnblockDialog";
import { ChannelsUIProvider } from "./ChannelsUIContext";
import { ChannelsCard } from "./ChannelsCard";
import queryString from "query-string";

export function CreatorsPodcastPage(props) {
  const { history, location } = props;
  const [isNewChannel, setNewChannel] = useState(0);
  const [isEditCreator, setEditCreator] = useState(0);
  const [isDeleteCreator, setDeleteCreator] = useState(0);
  const [isBlockCreator, setBlockCreator] = useState(0);
  const [isUnblockCreator, setUnblockCreator] = useState(0);
  const [isNewCreator, setNewCreator] = useState(0);

  const [channelName, setChannelName] = useState(null);
  const [channelId, setChannelId] = useState(null);
  const [creatorName, setCreatorName] = useState(null);
  const [creatorId, setCreatorId] = useState(null);

  const [isPrivate, setIsPrivate] = useState(0);
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.newChannel) {
      setNewChannel(1);
    }
    if (parsed.newCreator) {
      setNewCreator(1);
    }
    if (parsed.editCreator) {
      setEditCreator(1);
    }
    if (parsed.deleteCreator) {
      setDeleteCreator(1);
    }
    if (parsed.blockCreator) {
      setBlockCreator(1);
    }
    if (parsed.unblockCreator) {
      setUnblockCreator(1);
    }
    //------------------------------
    if (parsed.channelName) {
      setChannelName(parsed.channelName);
    } else {
      setChannelName(null);
    }
    if (parsed.channelId) {
      setChannelId(parsed.channelId);
    } else {
      setChannelId(null);
    }
    if (parsed.creatorName) {
      setCreatorName(parsed.creatorName);
    } else {
      setCreatorName(null);
    }
    if (parsed.creatorId) {
      setCreatorId(parsed.creatorId);
    } else {
      setCreatorId(null);
    }
    //------------------------------
    if (parsed.isPrivate) {
      setIsPrivate(parseInt(parsed.isPrivate, 10));
    }
  }, [location.search]);
  const channelsUIEvents = {
    newChannelButtonClick: (isPrivate, channelId, channelName) => {
      history.push(
        `/creators-podcast?isPrivate=${isPrivate}&channelId=${channelId}&channelName=${channelName}&newChannel=true`
      );
    },
    newCreatorButtonClick: (isPrivate, channelId, channelName) => {
      history.push(
        `/creators-podcast?isPrivate=${isPrivate}&channelId=${channelId}&channelName=${channelName}&newCreator=true`
      );
    },
    openEditCreatorDialog: (
      name,
      channelId,
      creatorId,
      isPrivate,
      channelName
    ) => {
      history.push(
        `/creators-podcast?isPrivate=${isPrivate}&channelId=${channelId}&channelName=${channelName}&creatorId=${creatorId}&creatorName=${name}&editCreator=true`
      );
    },
    openDeleteCreatorDialog: (
      name,
      channelId,
      creatorId,
      isPrivate,
      channelName
    ) => {
      history.push(
        `/creators-podcast?isPrivate=${isPrivate}&channelId=${channelId}&channelName=${channelName}&creatorId=${creatorId}&creatorName=${name}&deleteCreator=true`
      );
    },
    openBlockCreatorDialog: (
      name,
      channelId,
      creatorId,
      isPrivate,
      channelName
    ) => {
      history.push(
        `/creators-podcast?isPrivate=${isPrivate}&channelId=${channelId}&channelName=${channelName}&creatorId=${creatorId}&creatorName=${name}&blockCreator=true`
      );
    },
    openUnblockCreatorDialog: (
      name,
      channelId,
      creatorId,
      isPrivate,
      channelName
    ) => {
      history.push(
        `/creators-podcast?isPrivate=${isPrivate}&channelId=${channelId}&channelName=${channelName}&creatorId=${creatorId}&creatorName=${name}&unblockCreator=true`
      );
    },
    openCreatorsPage: (id, name) => {
      history.push(
        `/channels/${id}/${encodeURIComponent(name)}/creators-podcast`
      );
    },
    openCreatorBooksPage: type => {
      history.push(`/channels/${type}/sub/books`);
    }
  };

  return (
    <ChannelsUIProvider channelsUIEvents={channelsUIEvents}>
      <ChannelsLoadingDialog />
      <ChannelsCard
        isCreator={true}
        id={channelId}
        name={channelName}
        onHide={() => {
          history.push(`/channels-podcast`);
        }}
      />
      {isNewChannel ? (
        <ChannelNewDialog
          show={true}
          onHide={() => {
            history.push(
              `/creators-podcast?channelId=${channelId}&channelName=${channelName}`
            );
            setNewChannel(0);
          }}
          onSuccess={() => {
            history.push(`/channels`);
            setNewChannel(0);
          }}
          isPrivate={isPrivate}
        />
      ) : (
        ""
      )}
      {isNewCreator ? (
        <ChannelNewDialog
          show={true}
          onHide={() => {
            history.push(
              `/creators-podcast?channelId=${channelId}&channelName=${channelName}`
            );
            setNewCreator(0);
          }}
          onSuccess={() => {
            setNewCreator(0);
          }}
          isPrivate={isPrivate}
          isCreator={true}
          history={history}
        />
      ) : (
        ""
      )}
      {isEditCreator ? (
        <ChannelEditDialog
          show={true}
          channelId={channelId}
          creatorId={creatorId}
          name={creatorName}
          isPrivate={isPrivate}
          onHide={() => {
            history.push(
              `/creators-podcast?channelId=${channelId}&channelName=${channelName}`
            );
            setEditCreator(0);
          }}
        />
      ) : (
        ""
      )}
      {isDeleteCreator ? (
        <ChannelDeleteDialog
          show={true}
          channelId={channelId}
          creatorId={creatorId}
          name={creatorName}
          isPrivate={isPrivate}
          onHide={() => {
            history.push(
              `/creators-podcast?channelId=${channelId}&channelName=${channelName}`
            );
            setDeleteCreator(0);
          }}
        />
      ) : (
        ""
      )}
      {isBlockCreator ? (
        <ChannelBlockDialog
          show={true}
          channelId={channelId}
          creatorId={creatorId}
          name={creatorName}
          isPrivate={isPrivate}
          onHide={() => {
            history.push(
              `/creators-podcast?channelId=${channelId}&channelName=${channelName}`
            );
            setBlockCreator(0);
          }}
        />
      ) : (
        ""
      )}
      {isUnblockCreator ? (
        <ChannelUnblockDialog
          show={true}
          channelId={channelId}
          creatorId={creatorId}
          name={creatorName}
          isPrivate={isPrivate}
          onHide={() => {
            history.push(
              `/creators-podcast?channelId=${channelId}&channelName=${channelName}`
            );
            setUnblockCreator(0);
          }}
        />
      ) : (
        ""
      )}
    </ChannelsUIProvider>
  );
}
