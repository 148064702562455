/* eslint-disable react-hooks/exhaustive-deps */
// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../../../_metronic/_partials/controls";
import { Input as Input2, Upload } from "antd";
import { makeStyles } from "@material-ui/core";
import Spinner from "../../../../../../_metronic/_helpers/spinner";
import ImgCrop from "antd-img-crop";
import "antd/dist/antd.css";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";
const { TextArea } = Input2;

const useStyles = makeStyles(() => ({
 
  input: { margin: "12px 0" },
  subtitle: {
    color: "#012875",
    fontWeight: 400,
    fontSize: 12,
    marginBottom: 0
  }
}));

// Validation schema
const ChannelNewSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "อย่างน้อย 2 ตัวอักษร")
    .max(200, "ไม่เกิน 200 ตัวอักษร")
    .required("กรุณากรอกชื่อ")
});

export const ChannelNewForm = ({
  createChannel,
  createCreator,
  channel,
  actionsLoading,
  onHide,
  isCreator,
  channelList,
  isDuplicate,
  setDuplicate,
  contentUIDToUpload,
  setContentUIDToUpload,
  description,
  setDescription,
  coverName,
  setCoverName
}) => {
  const styles = useStyles();
  const [channelIn, setChannelIn] = useState("");
  const [channelInList, setChannelInList] = useState([
    <option key="none" value=""></option>
  ]);
  const [channelName, setChannelName] = useState("");
  const [checkingError, setCheckingError] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [coverImageSuccess, setCoverImageSuccess] = useState(false);

  const onDescriptionChange = e => {
    setDescription(e.target.value);
  };

  useEffect(() => {
    setCheckingError(false);
  }, [channelIn]);

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  useEffect(() => {
    if (channelList) {
      setChannelInList([
        <option value={""} disabled selected>
          เลือก Channel
        </option>,
        ...channelList.map((x, index) => {
          // if (index === 0) {
          //   setChannelIn(x.uid);
          //   setChannelName(x.name);
          // }
          return (
            <option key={index} value={x.uid}>
              {x.name}
            </option>
          );
        })
      ]);
    }
  }, [channelList]);

  const handleChannelInChange = e => {
    setChannelIn(e.target.value);
    var index = e.nativeEvent.target.selectedIndex;
    setChannelName(e.nativeEvent.target[index].text);
  };

  useEffect(() => {
    if (Array.isArray(fileList) && fileList.length === 0) {
      setCoverImageSuccess(false);
    }
  }, [fileList]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onUploadCoverImage = e => {
    const reqFunc = async () => {
      if (e) {
        const fileToUpload = e.file;
        if (fileToUpload) {
          setCoverName(fileToUpload.name);
          const req = await axios.post(`/admin/private/channels/file`, {
            fileName: fileToUpload.name,
            uid: contentUIDToUpload ? contentUIDToUpload : undefined
          });
          if (req.data.status && req.data.data) {
            const urlToUpload = req.data.data.url;
            const fieldsToUpload = req.data.data.fields;
            const UID = req.data.data.uid;
            setContentUIDToUpload(UID);
            var formData = new FormData();
            const entries = Object.entries(fieldsToUpload);
            for (const [key, val] of entries) {
              formData.append(key, val);
            }
            formData.append("file", fileToUpload);
            axios({
              method: "post",
              url: urlToUpload,
              data: formData,
              onUploadProgress: progressEvent => {
                if (progressEvent) {
                  e.onProgress(
                    {
                      percent: (
                        (100 * progressEvent.loaded) /
                        progressEvent.total
                      ).toFixed()
                    },
                    e.file
                  );
                }
              }
            })
              .then(response => {
                e.onSuccess(response, e.file);
                setCoverImageSuccess(true);
              })
              .catch(() => {});
          }
        }
      }
    };
    reqFunc();
  };

  const reqDeleteCoverFunc = async () => {
    const req = await axios.delete(`/admin/private/channels/file`, {
      data: { fileName: coverName, uid: contentUIDToUpload }
    });
    if (req.data.status) {
      setCoverName(undefined);
      setContentUIDToUpload(undefined);
      return true;
    } else {
      return false;
    }
  };

  const uploadImageMemo = useMemo(() => {
    return (
      <div className="channel-cover">
        <ImgCrop
          aspect={1 / 1}
          modalTitle="ตัดรูปภาพด้วยอัตราส่วน 1 : 1"
          modalOk="บันทึก"
          modalCancel="ยกเลิก"
          accept="image/png, image/jpeg, image/jpg"
        >
          <Upload
            customRequest={onUploadCoverImage}
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
            onRemove={reqDeleteCoverFunc}
          >
            {fileList.length < 1 && <PlusOutlined />}
          </Upload>
        </ImgCrop>
      </div>
    );
  }, [onUploadCoverImage, onChange, fileList, onPreview]);

  return (
    <>
      <p className="text-text-head">
        {isCreator ? "เพิ่ม Creator" : "เพิ่ม Channel"}
      </p>
      <Formik
        enableReinitialize={true}
        initialValues={{ name: channel.name, status: channel.status }}
        validationSchema={ChannelNewSchema}
        onSubmit={values => {
          setDuplicate(false);
          if (isCreator) {
            createCreator(values, channelIn, channelName);
          } else {
            createChannel(values);
          }
        }}
      >
        {({ handleSubmit, values, setFieldValue, errors }) => {
          return (
            <>
              <Modal.Body>
                {actionsLoading && <Spinner forNewChannel={true} />}
                <Form className="form form-label-right">
                  {isCreator ? (
                    <div className={`col-lg-12 ${styles.input}`}>
                      <Select
                        name="channel"
                        withFeedbackLabel={false}
                        label="เลือก Channel"
                        onChange={handleChannelInChange}
                        value={channelIn}
                      >
                        {channelInList}
                      </Select>
                      {channelIn === "" && checkingError && (
                        <>
                          <div className="is-invalid"></div>
                          <div className="invalid-feedback">
                            กรุณาเลือก Channel
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={`col-lg-12 ${styles.input}`}>
                    <Field
                      name="name"
                      component={Input}
                      placeholder={
                        isCreator ? "กรอกชื่อ Creator" : "กรอกชื่อ Channel"
                      }
                      label={isCreator ? "ชื่อ Creator*" : "ชื่อ Channel*"}
                    />
                    {isDuplicate && checkingError && (
                      <>
                        <div className="is-invalid"></div>
                        <div className="invalid-feedback">
                          ไม่สามารถเพิ่ม{isCreator ? " Creator " : " Channel "}
                          ได้ เนื่องจากชื่อซ้ำ
                        </div>
                      </>
                    )}
                  </div>
                  <div className={`col-lg-12 ${styles.input}`}>
                    <p style={{ marginBottom: "0.5rem" }}>
                      คำอธิบายเกี่ยวกับ Channel
                    </p>
                    <TextArea
                      name="description"
                      placeholder="กรอกคำอธิบายเกี่ยวกับ Channel"
                      value={description}
                      onChange={onDescriptionChange}
                      rows={4}
                    />
                  </div>
                  <div className={`col-lg-12 ${styles.input}`}>
                    <p style={{ marginTop: "12px", marginBottom: "0.5rem" }}>
                      อัปโหลดไฟล์หน้าปก Channel
                    </p>
                    <p className={styles.subtitle}>
                      รองรับไฟล์ jpg, jpeg หรือ png เท่านั้น
                    </p>
                    <p
                      className={styles.subtitle}
                      style={{ marginBottom: "8px" }}
                    >
                      กรุณาใช้อัตราส่วน 1:1
                      เนื่องจากมีการใช้สัดส่วนนี้มากที่สุดในโปรแกรมเล่นวิดีโอและหน้าตัวอย่างของวิดีโอ
                    </p>
                    {uploadImageMemo}
                  </div>
                </Form>
                <div className="divButton">
                  <button
                    className="denyButton"
                    type="button"
                    onClick={() => {
                      onHide();
                      if (fileList.length) {
                        reqDeleteCoverFunc();
                      }
                    }}
                  >
                    ยกเลิก
                  </button>
                  <button
                    className="acceptButton"
                    type="submit"
                    onClick={() => {
                      setCheckingError(true);
                      handleSubmit();
                    }}
                    disabled={
                      actionsLoading ||
                      !values["name"] ||
                      (isCreator && channelIn === "")
                        ? // || !coverImageSuccess
                          true
                        : false
                    }
                  >
                    ตกลง
                  </button>
                </div>
              </Modal.Body>
            </>
          );
        }}
      </Formik>
    </>
  );
};
