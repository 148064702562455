import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./CustomersUIHelpers";

const CustomersUIContext = createContext();

export function useCustomersUIContext() {
  return useContext(CustomersUIContext);
}

export const CustomersUIConsumer = CustomersUIContext.Consumer;

export function CustomersUIProvider({ customersUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initCustomer = {
    id: undefined,
    firstName: "",
    lastName: "",
    email: "",
    rawPassword: "",
    staffID: "",
    // userName: "",
    // gender: "Female",
    // status: 0,
    // dateOfBbirth: "",
    // ipAddress: "",
    // type: 1,
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initCustomer,
    newCustomerButtonClick: customersUIEvents.newCustomerButtonClick,
    newQRCodeButtonClick: customersUIEvents.newQRCodeButtonClick,
    openEditCustomerDialog: customersUIEvents.openEditCustomerDialog,
    openDeleteCustomerDialog: customersUIEvents.openDeleteCustomerDialog,
    openBlockCustomerDialog: customersUIEvents.openBlockCustomerDialog,
    openUnblockCustomerDialog: customersUIEvents.openUnblockCustomerDialog,
    openBannedCustomerDialog: customersUIEvents.openBannedCustomerDialog,
    openResetPwCustomerDialog: customersUIEvents.openResetPwCustomerDialog,
    openDeleteCustomersDialog: customersUIEvents.openDeleteCustomersDialog,
    openFetchCustomersDialog: customersUIEvents.openFetchCustomersDialog,
    openUpdateCustomersStatusDialog: customersUIEvents.openUpdateCustomersStatusDialog,
    openCustomerDevicesDialog: customersUIEvents.openCustomerDevicesDialog,
    openViewQrcodeDialog: customersUIEvents.openViewQrcodeDialog,
    openEditQrcodeDialog: customersUIEvents.openEditQrcodeDialog,
    openViewQrcodeMembersPage: customersUIEvents.openViewQrcodeMembersPage,
    openQrcodeMembersMapDialog: customersUIEvents.openQrcodeMembersMapDialog,
    openDeleteQrcodeDialog: customersUIEvents.openDeleteQrcodeDialog,
    unblockAllBannedDialog: customersUIEvents.unblockAllBannedDialog,
    openFileInformationDialog: customersUIEvents.openFileInformationDialog,
  };

  return <CustomersUIContext.Provider value={value}>{children}</CustomersUIContext.Provider>;
}
