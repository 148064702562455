/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles, withStyles, lighten } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useHistory, withRouter } from 'react-router-dom';
import axios from 'axios';
import 'react-image-crop/dist/ReactCrop.css';
import { ModalsSuccess } from '../../../CustomView/ModalView';
import jwt_decode from 'jwt-decode';
import { shallowEqual, useSelector } from 'react-redux';
import { LoaderView } from '../../../CustomView';

const useStyles1 = makeStyles(theme => ({
   button: {
      borderRadius: 100,
      backgroundColor: '#012875',
      marginLeft: 8,
      padding: '0px 22px',
      height: 40,
      color: 'white',
      fontSize: 13,
      fontWeight: 600,
      fontFamily: 'Prompt',
      border: 'none',
      marginTop: 8
   },
   title: {
      fontSize: '20px',
      fontWeight: 600,
      color: '#d7042b'
   },
   bigDiv: {
      borderRadius: '0px 12px 12px 12px',
      backgroundColor: 'white',
      padding: '2rem 150px'
   },
   modalDiv: {
      borderRadius: '0px 12px 12px 12px',
      backgroundColor: 'white',
      padding: '1rem 0px'
   },
   stat: { background: '#F2F2F2', borderRadius: '20px', padding: '20px 0' },

   divButton: { marginTop: '72px', textAlign: 'center' },
   blue_p: {
      fontSize: 20,
      fontWeight: 600,
      color: '#012875',
      marginBottom: '6px'
   },
   blue_p_small: {
      fontSize: 14,
      fontWeight: 400,
      color: '#012875',
      display: 'inline-block',
      marginBottom: '6px'
   },

   dropzoneTitle: { marginBottom: '0px', fontSize: '16px' },
   dropzoneSubTitle: { marginBottom: '72px', fontSize: '14px' },
   progress: {
      margin: '13px 0px',
      borderRadius: '5px'
   },
   information: {
      backgroundColor: '#012875',
      width: 16,
      height: 16,
      borderRadius: 8,
      textAlign: 'center',
      margin: '0 0 0 6px',
      display: 'inline-block',
      '&:hover': { cursor: 'pointer' }
   },

   uploadedImg: {
      padding: '16px',
      height: '240px',
      maxWidth: '100%'
   },
   leftLabel: { paddingLeft: '35px', textAlign: 'left' },
   rightLabel: { paddingRight: '35px', textAlign: 'right' },
   greenBox: {
      borderRadius: '12px',
      boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.2)',
      // height: "85px",
      maxWidth: '450px',
      padding: '28px 0px',
      margin: 'auto',
      alignItems: 'center'
   },
   greenLabel: { color: '#219653', fontWeight: '600', fontSize: '20px' },
   redBox: {
      borderRadius: '12px',
      boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.2)',
      maxWidth: '450px',
      padding: '28px 0px',
      margin: '24px auto 0',
      alignItems: 'center'
   },
   redLabel: { color: '#d7042b', fontWeight: '600', fontSize: '20px' },
   orangeLabel: { color: '#ff5337', margin: 'auto', fontWeight: '500' },
   miniLabel: { fontWeight: '400', margin: '-8px 0', fontSize: '14px' },

   progressUpload: {
      margin: '3px 13px 47px',
      borderRadius: '5px'
   },
   divCenter: {
      width: '100%',
      textAlign: 'center'
   }
}));

const FileAndCover = ({ handleSubmitOutside, isExample }) => {
   const [clickedDownload, setClickedDownload] = useState(false);
   const [uploadingFile, setUploadingFile] = useState(false);
   const [successUpload, setSuccessUpload] = useState(false);
   const [successUploadExample, setSuccessUploadExample] = useState(false);
   const [failedUpload, setFailedUpload] = useState(false);
   const [numAdded, setNumAdded] = useState(0);
   const [numNotAdded, setNumNotAdded] = useState(0);
   const [linkXLSX, setLinkXLSX] = useState('');
   const [loaded, setLoaded] = useState(0);
   const [total, setTotal] = useState(0);
   const [percentage, setPercentage] = useState(0);
   const [loadValue, setLoadValue] = useState(false);
   const [storageLeft, setStorageLeft] = useState(1000000);
   // eslint-disable-next-line no-undef
   const history = useHistory();
   const { authState } = useSelector(
      ({ auth }) => ({
         authState: auth
      }),
      // eslint-disable-next-line no-undef
      shallowEqual
   );
   const clientUID = authState.viewAsToken
      ? jwt_decode(authState.viewAsToken).clientUID
      : jwt_decode(authState.authToken).clientUID;
   const BorderLinearProgress = withStyles({
      root: {
         height: 10,
         backgroundColor: lighten('#ff6c5c', 0.5)
      },
      bar: {
         borderRadius: 20,
         backgroundColor: '#ff6c5c'
      }
   })(LinearProgress);
   const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
      accept: '.xlsx',
      noClick: true,
      noKeyboard: true,
      onDropAccepted: e => onDropCoverAccepted(e)
   });

   const onDropCoverAccepted = e => {
      if (e && Array.isArray(e) && e.length > 0) {
         setStorageLeft(storageLeft - e[0].size);
      }
   };

   useEffect(() => {
      const reqFunc = async () => {
         if (acceptedFiles && acceptedFiles.length) {
            setUploadingFile(true);
            const fileToUpload = acceptedFiles[0];
            if (isExample) {
               const fileNamePayload = {
                  fileName: fileToUpload.name
               };
               let test = 'test';
               const req = await axios.post(
                  '/superuser/hibrary/contents/template',
                  fileNamePayload
               );
               if (req.data.status) {
                  const urlToUpload = req.data.data.url;
                  const fieldsToUpload = req.data.data.fields;
                  console.log(req.data);
                  const sheetName = req.data.data.value;

                  var formData = new FormData();
                  const entries = Object.entries(fieldsToUpload);
                  // const fileName = fieldsToUpload['key'];
                  for (const [key, val] of entries) {
                     formData.append(key, val);
                  }
                  formData.append('file', fileToUpload);

                  axios({
                     method: 'post',
                     url: urlToUpload,
                     data: formData,
                     onUploadProgress: progressEvent => {
                        if (progressEvent) {
                           setLoaded((progressEvent.loaded / 1000).toFixed(2));
                           setTotal((progressEvent.total / 1000).toFixed(2));
                           setPercentage(
                              (
                                 (100 * progressEvent.loaded) /
                                 progressEvent.total
                              ).toFixed()
                           );
                        }
                     }
                  })
                     .then(async res => {
                        console.log();
                        setSuccessUploadExample(true);
                        // axios
                        //    .post(`/admin/register/csv`, {
                        //       fileName: fileName
                        //    })
                        //    .then(res => {
                        //       if (res.data.status) {
                        //          // dispatch(actions.fetchCustomers(customersUIProps.queryParams));
                        //          setNumAdded(res.data.data.successful || 0);
                        //          setNumNotAdded(
                        //             res.data.data.unsuccessfully || 0
                        //          );
                        //          setLinkXLSX(res.data.data.fulfillmentUrl);
                        //       }
                        //    });
                        // setSuccessUpload(true);
                        // return true;
                        axios
                           .post(`/superuser/hibrary/contents`, {
                              type: 'upload',
                              value: sheetName,
                              clientUID
                           })
                           .then(res => {
                              console.log(res);
                              if (res.status) {
                                 setSuccessUploadExample(true);
                                 history.push(
                                    '/view-as-upload-content-history'
                                 );
                              }
                           });
                        setSuccessUpload(true);
                        return true;
                     })
                     .catch(error => {
                        setFailedUpload(true);
                     });
               }
            } else {
               // setLoadValue(true)
               const req = await axios.post(
                  `/superuser/hibrary/contents/template`,
                  {
                     fileName: fileToUpload.name
                  }
               );
               if (req.data.status) {
                  const urlToUpload = req.data.data.url;
                  const fieldsToUpload = req.data.data.fields;
                  // const fileName = req.data.data.value;

                  var formData = new FormData();
                  const entries = Object.entries(fieldsToUpload);
                  const fileName = fieldsToUpload['key'];
                  for (const [key, val] of entries) {
                     formData.append(key, val);
                  }
                  formData.append('file', fileToUpload);

                  axios({
                     method: 'post',
                     url: urlToUpload,
                     data: formData,
                     onUploadProgress: progressEvent => {
                        if (progressEvent) {
                           setLoaded((progressEvent.loaded / 1000).toFixed(2));
                           setTotal((progressEvent.total / 1000).toFixed(2));
                           setPercentage(
                              (
                                 (100 * progressEvent.loaded) /
                                 progressEvent.total
                              ).toFixed()
                           );
                        }
                     }
                  })
                     .then(() => {
                        axios
                           .post(`/superuser/hibrary/contents/template`, {
                              fileName: fileName
                           })
                           .then(async res => {
                              if (res.data.status) {
                                 // dispatch(actions.fetchCustomers(customersUIProps.queryParams));

                                 let response = await axios.post(
                                    `/superuser/hibrary/contents`,
                                    {
                                       type: 'upload',
                                       value: res.data.data.value,
                                       clientUID
                                    }
                                 );
                                 if (response.status) {
                                    history.push(
                                       '/view-as-upload-content-history'
                                    );
                                 }
                                 //  setNumAdded(response.data.data?.success || 0);
                                 // setNumNotAdded(response.data.data?.fail || 0);
                                 // setLoadValue(false)

                                 // setLinkXLSX(response.data.data.fulfillmentUrl);
                              }
                           });
                        // setSuccessUpload(true);
                        return true;
                     })
                     .catch(() => {
                        setFailedUpload(true);
                     });
               }
            }
         }
      };
      //me

      reqFunc();
   }, [acceptedFiles]);
   const classes = useStyles1();
   return (
      <>
         <div className={classes.bigDiv}>
            <div className="row" style={{ justifyContent: 'center' }}>
               <div className="col-8">
                  {/* <p className={classes.information} onClick={uploadUIProps.openCoverInformationDialog}>
            <img src="/media/members/information.svg" style={{ marginBottom: "5px" }} alt="" />
          </p> */}
                  {successUpload ? (
                     loadValue ? (
                        <LoaderView />
                     ) : (
                        <>
                           <div className={`row ${classes.greenBox}`}>
                              <div
                                 className={`col-6 ${classes.greenLabel} ${classes.leftLabel}`}
                              >
                                 สำเร็จ
                              </div>
                              <div
                                 className={`col-6 ${classes.greenLabel} ${classes.rightLabel}`}
                              >
                                 {numAdded}{' '}
                                 <p className={classes.miniLabel}>เล่ม</p>
                              </div>
                           </div>
                           <div className={`row ${classes.redBox}`}>
                              <div
                                 className={`col-6 ${classes.redLabel} ${classes.leftLabel}`}
                              >
                                 ไม่สำเร็จ
                              </div>
                              <div
                                 className={`col-6 ${classes.redLabel} ${classes.rightLabel}`}
                                 style={{ marginTop: '-8px' }}
                              >
                                 {/* {loadValue ? <Spin indicator={antIcon} /> : numNotAdded} */}
                                 {numNotAdded}
                                 <p className={classes.miniLabel}>เล่ม</p>
                              </div>
                              {numNotAdded ? (
                                 <div className={classes.divCenter}>
                                    <button
                                       type="button"
                                       className={classes.button}
                                       onClick={() => {
                                          let link = document.createElement(
                                             'a'
                                          );
                                          link.setAttribute(
                                             'download',
                                             'template'
                                          );
                                          link.href = linkXLSX;
                                          document.body.appendChild(link);
                                          link.click();
                                          link.remove();
                                          setClickedDownload(true);
                                       }}
                                       style={{ margin: '24px auto 17px' }}
                                    >
                                       <span>
                                          <img
                                             alt="excel"
                                             src="/media/members/excel-button.svg"
                                             style={{ marginRight: '8px' }}
                                          />
                                       </span>
                                       <span>ดาวน์โหลด .XLSX</span>
                                    </button>
                                    <div className={classes.orangeLabel}>
                                       กรุณาดาวน์โหลดไฟล์ .XLSX
                                       เพื่อแก้ไขไฟล์และอัพโหลดอีกครั้ง
                                    </div>
                                 </div>
                              ) : (
                                 ''
                              )}
                           </div>
                           <div className={classes.divButton}>
                              {isExample ? (
                                 <button
                                    type="button"
                                    onClick={() => {
                                       setUploadingFile(false);
                                       setSuccessUpload(false);
                                       setFailedUpload(false);
                                       setNumAdded(0);
                                       setNumNotAdded(0);
                                       setLinkXLSX('');
                                       setLoaded(0);
                                       setTotal(0);
                                       setPercentage(0);
                                    }}
                                    className="denyButton"
                                 >
                                    ย้อนกลับ
                                 </button>
                              ) : (
                                 ''
                              )}
                              <button
                                 className="acceptButton"
                                 type="submit"
                                 onClick={() => {
                                    if (isExample) {
                                       handleSubmitOutside();
                                    } else {
                                       setUploadingFile(false);
                                       setSuccessUpload(false);
                                       setFailedUpload(false);
                                       setNumAdded(0);
                                       setNumNotAdded(0);
                                       setLinkXLSX('');
                                       setLoaded(0);
                                       setTotal(0);
                                       setPercentage(0);
                                    }
                                 }}
                              >
                                 ตกลง
                              </button>
                           </div>
                        </>
                     )
                  ) : (
                     <>
                        <div className={classes.blue_p}>อัปโหลดไฟล์</div>
                        <div className={classes.blue_p_small}>
                           อัปโหลดไฟล์ .XLSX เท่านั้น
                        </div>
                        <div
                           {...getRootProps({ className: 'dropzone' })}
                           style={{
                              border: '1px dashed',
                              textAlign: 'center',
                              borderRadius: '10px'
                           }}
                        >
                           <input {...getInputProps()} />
                           <img src="/media/members/excel-modal.svg" alt="" />
                           <div style={{ height: '200px' }}>
                              {failedUpload ? (
                                 <p className={classes.dropzoneTitle}>
                                    ล้มเหลว กรุณาลองใหม่อีกครั้ง
                                 </p>
                              ) : uploadingFile ? (
                                 <>
                                    <p className={classes.dropzoneTitle}>
                                       รอสักครู่ ระบบกำลังอัปโหลดไฟล์...
                                    </p>
                                    <div
                                       className="row"
                                       style={{
                                          margin: '40px 0.5px 0',
                                          color: '#012875'
                                       }}
                                    >
                                       <p
                                          className="col-6"
                                          style={{
                                             fontSize: '12px',
                                             textAlign: 'left',
                                             margin: '0'
                                          }}
                                       >
                                          {loaded}KB/{total}KB
                                       </p>
                                       <p
                                          className="col-6"
                                          style={{
                                             fontSize: '14px',
                                             textAlign: 'end',
                                             margin: '0'
                                          }}
                                       >
                                          {parseInt(percentage, 10)}%
                                       </p>
                                    </div>
                                    <BorderLinearProgress
                                       className={classes.progressUpload}
                                       variant="determinate"
                                       color="secondary"
                                       value={percentage}
                                    />
                                 </>
                              ) : (
                                 <>
                                    <h4>Drag a file here</h4>
                                    <div>or browse your computer</div>
                                    <button
                                       type="button"
                                       className="browseButton "
                                       onClick={open}
                                    >
                                       Browse
                                    </button>
                                 </>
                              )}
                           </div>
                        </div>
                     </>
                  )}
               </div>
            </div>
         </div>
         <ModalsSuccess
            show={successUploadExample}
            onHide={() => {
               setSuccessUploadExample(false);
               handleSubmitOutside();
            }}
            onClickAccept={() => {
               setSuccessUploadExample(false);
               handleSubmitOutside();
            }}
         />
      </>
   );
};

export default withRouter(FileAndCover);
