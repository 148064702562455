export interface HtiResponse<T = any> {
   status: boolean;
   data?: T;
   errorCode?: string;
   message?: string;
}

// ========= AUTH ========= //
export interface UserTokenInfo {
   uid: string;
   role: Role;
   expireDate: number;
   startDate: number;
   name: string;
   email: string;
   storeType: string;
   storeStatus: string;
   packageUID: string;
   clientUID: string;
   clientName: string;
   companyName: string;
   clientLogo: string;
   clientPrefix: string;
   showClientLogo: boolean;
   isPrivateLibrary: boolean;
   isAnonymous: boolean;
   contentDistributor: boolean;
   iat: number;
   exp: number;
}
export enum Role {
   SuperAdmin = 'super',
   Admin = 'admin'
}
export interface CreateCategoryBodyRequest {
   catName: string;
   status: number;
   contentType?: string;
}
export interface CreateSubCategoryBodyRequest {
   subcatName: string;
   status: number;
   contentType?: string;
}
export interface UpdateCategoryBodyRequest {
   catName?: string;
   pin?: boolean;
   status?: number;
}
export interface updateSubCategoryBodyRequest {
   subcatName?: string;
   status?: number;
}
export interface CategoryResponse {
   status?: boolean;
   data?: Categories;
}
export interface findCategoriesQueryParam {
   contentType?: string;
   keyword?: string;
   pageSize?: string;
   memberMode?: string;
}
export interface Categories {
   categories?: Category[];
}

export interface Category {
   subcats: Subcat[];
   catName: string;
   status: number;
   id: number;
   pin?: boolean;
}
export interface Subcat {
   status: number;
   subcatName: string;
   id: number;
}
export interface Authtokendecode {
   uid: string;
   role: string;
   expireDate: number;
   startDate: number;
   name: string;
   email: string;
   storeType: string;
   storeStatus: string;
   packageUID: string;
   clientUID: string;
   clientName: string;
   companyName: string;
   clientLogo: string;
   clientPrefix: string;
   showClientLogo: boolean;
   isPrivateLibrary: boolean;
   isAnonymous: boolean;
   iat: number;
   exp: number;
}
export interface HtiErrorResponse<T = any> {
   message: string;
   errorCode: string;
   status: boolean;
   data?: T;
}
export interface LoginRequest {
   password: string;
   userID: string;
   isCu: boolean;
}
export interface LoginResponse {
   userToken: string;
}
export interface ForgotResponse {
   uid: string;
   referenceCode: string;
   transactionDate: number;
   expireDate: number;
}
export interface VerifyOtpResponse {
   userToken: string;
   client: Client;
}

export interface Client {
   themes: Themes;
}

export interface Themes {
   Primary?: string;
   PrimaryVariant?: string;
   Secondary?: string;
   SecondaryVariant?: string;
   Background?: string;
   Surface?: string;
   Error?: string;
   OnPrimary?: string;
   OnSecondary?: string;
   OnBackground?: string;
   OnSurface?: string;
   OnError?: string;
   Title?: string;
   Body?: string;
}
export interface customersUIPropsQueryParams {
   filter?: Filter;
   sortOrder?: string;
   sortField?: string;
   pageNumber?: number;
   pageSize?: number;
   limit?: number;
   totalSize?: number;
   contentType?: string;
   keyword?: string;
   memberMode?: string;
   ebookType?: filterEbookType;
}

export enum filterEbookType {
   Magazine = 'Magazine',
   Newspaper = 'Newspaper',
   Ebook = 'Ebook'
}

export interface Filter {
   lastName: string;
   firstName: string;
   email: string;
   ipAddress: string;
}
export interface areaLimitPayload {
   latitude: number;
   longitude: number;
   name: string;
   radius: number;
}
export interface CreateLimitArea {
   status: boolean;
}

export interface LimitArea {
   location: Location[];
}

export interface Location {
   latitude: number;
   longitude: number;
   distance: number;
}
export interface EditLimitAreaPayload {
   oldName: string;
   latitude?: number;
   longitude?: number;
   distance?: number;
   name?: string;
}
