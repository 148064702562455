// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Role } from "../../../../../../../interface";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  {
    categoryForEdit,
    isSubCat,
    catName,
    type,
    isPrivate,
    openEditCategoryDialog,
    openEditSubCategoryDialog,
    openDeleteCategoryDialog,
    openDeleteSubCategoryDialog,
    openBlockCategoryDialog,
    openUnblockCategoryDialog,
    openSubCategoriesPage,
    openSubCategoryBooksPage,
    role,
  }
) {
  return row && (row.status === 1 || row.status === 0) ? (
    <>
      {isPrivate && role !== Role.SuperAdmin ? (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="quick-user-tooltip">
              แก้ไขหมวดหมู่{isSubCat ? "ย่อย" : ""}
            </Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => {
              if (isSubCat) {
                openEditSubCategoryDialog(
                  row.subcatName,
                  categoryForEdit.id,
                  row.id,
                  isPrivate,
                  catName
                );
              } else {
                openEditCategoryDialog(row.catName, row.id, isPrivate);
              }
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/members/edit-button.svg")} />
            </span>
          </a>
        </OverlayTrigger>
      ) : (
        ""
      )}
      {isPrivate && role !== Role.SuperAdmin ? (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="quick-user-tooltip">
              {isSubCat ? "ลบหมวดหมู่ย่อย" : "ลบหมวดหมู่"}
            </Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            style={{ marginLeft: "0.75rem" }}
            onClick={() => {
              if (isSubCat) {
                openDeleteSubCategoryDialog(
                  row.subcatName,
                  categoryForEdit.id,
                  row.id,
                  isPrivate,
                  catName
                );
              } else {
                openDeleteCategoryDialog(row.catName, row.id, isPrivate);
              }
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/members/delete-button.svg")} />
            </span>
          </a>
        </OverlayTrigger>
      ) : (
        ""
      )}
      {isSubCat ? (
        ""
      ) : (
        <button
          title={isSubCat ? "View books" : "View subcategory"}
          onClick={(e) => {
            e.preventDefault();
            openSubCategoriesPage(cellContent, row.catName); //cellContent = id
          }}
          style={{
            padding: "3px",
            width: "100px",
            height: "28px",
            borderRadius: "6px",
            backgroundColor: "#f2f2f2",
            color: "#012875",
            border: "none",
            marginLeft: "0.75rem",
          }}
        >
          {isSubCat ? "รายละเอียด" : "ดูหมวดหมู่ย่อย"}
        </button>
      )}
    </>
  ) : (
    // : row && row.status === 0 ? (
    //   <a
    //     title="Unblock category"
    //     className="btn btn-lg"
    //     style={{
    //       padding: "3px",
    //       width: "100px",
    //       height: "28px",
    //       borderRadius: "100px",
    //       backgroundColor: "#ff5337",
    //       color: "#ffffff",
    //     }}
    //     onClick={() => openUnblockCategoryDialog(type, row.catName, row.id, isPrivate)}
    //   >
    //     ปลดบล็อก
    //   </a>
    // )
    ""
  );
}
