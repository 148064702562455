import React from "react";
import { Empty } from "antd";
import { AnyCnameRecord } from "dns";

interface NodataViewProps {
  text?: string;
  isSearch: boolean;
  image?: any;
  content?: any;
}
export const NodataView = (props: NodataViewProps) => {
  let val = props.text
    ? props.text
    : props.isSearch
    ? "ไม่พบรายการที่ค้นหา"
    : "ไม่มีข้อมูล";
  var img = props.image ? (
    props.image
  ) : props.isSearch ? (
    <img src="/media/members/no-search-result.png" alt="" />
  ) : (
    <Empty description={false} />
  );
  return (
    <div className="nodata">
      {img}
      <p>{val}</p>
      {props.content ? props.content : ""}
    </div>
  );
};
