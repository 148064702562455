// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { message } from 'antd';

export function DetailColumnFormatter(row, isCuDomain) {
    const timeStatus = () => {
        switch (row.status) {
            case 1: {
                return 'ใช้งาน';
            }
            case 4: {
                return 'ทดลอง';
            }
            default:
                return '-';
        }

        // if (moment().unix() < row.startDate) {
        //   return "รอใช้งาน1" + row.status;
        // } else {
        //   if (moment().unix() < row.expireDate) {
        //     return "ใช้งาน1" + row.status;
        //   } else {
        //     return "หมดอายุ1" + row.status;
        //   }
        // }
    };

    const getRedirectPath = () => {
        switch (process.env.REACT_APP_ENV) {
            case 'production':
                return isCuDomain
                    ? `https://elibrary-${row.companyPrefix}.cu-elibrary.com/`
                    : `https://elibrary-${row.companyPrefix}.hibrary.me/`;
            case 'staging':
                return isCuDomain
                    ? `https://elibrary-${row.companyPrefix}.cu-elibrary.com/`
                    : `https://staging-elibrary-hti.hibrary.me/`;
            case 'development':
                return isCuDomain
                    ? 'https://elibrary-aritnrru.cu-elibrary.com/'
                    : `https://dev-elibrary-hti.hibrary.me/`;

            default:
                return `https://elibrary-${row.companyPrefix}.hibrary.me/`;
        }
    };

    const eleClientName = (
        <div className="text-title wrap">{row ? row.clientName : ''}</div>
    );
    const eleDisplayName = (
        <div className="text-detail wrap">{row ? row.displayName : ''}</div>
    );

    return (
        <div>
            {/* {row && row.clientName && row.clientName.length > 22 ? ( */}
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id="quick-user-tooltip">
                        {row ? row.clientName : ''}
                    </Tooltip>
                }
            >
                <a target={'_blank'} href={getRedirectPath()} rel="noreferrer">
                    {eleClientName}
                </a>
            </OverlayTrigger>
            {/* // ) : (
      //   eleClientName
      // )} */}
            {/* {row && row.displayName && row.displayName.length > 25 ? ( */}
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id="quick-user-tooltip">
                        {row ? row.displayName : ''}
                    </Tooltip>
                }
            >
                {eleDisplayName}
            </OverlayTrigger>
            {/* ) : (
        eleDisplayName
      )} */}
            {row && row.companyPrefix ? (
                <div className="text-detail">{`ตัวย่อ : ${row.companyPrefix}`}</div>
            ) : (
                ''
            )}
            {row && row.clientUID ? (
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip id="quick-user-tooltip">
                            คลิกเพื่อคัดลอก UID
                        </Tooltip>
                    }
                >
                    <div className="text-detail wrap">
                        UID :{' '}
                        <span
                            onClick={() => {
                                navigator.clipboard.writeText(row.clientUID);
                                message.success('คัดลอกสำเร็จ');
                            }}
                        >
                            {row.clientUID}
                        </span>
                    </div>
                </OverlayTrigger>
            ) : (
                ''
            )}
            {row ? (
                <div className="text-detail">
                    {`สถานะ : ${
                        row.status === 0 ? 'กำลังดำเนินการ' : timeStatus()
                    }`}
                </div>
            ) : (
                ''
            )}
        </div>
    );
}
