import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { login } from '../_redux/authCrud';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HtiResponse, LoginResponse } from '../../../../interface';
import { HibraryRootState } from '../../../../redux/rootReducer';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
   email: '',
   password: ''
};

function Login(props: any) {
   const { intl } = props;
   const [loading, setLoading] = useState(false);
   const [seePW, setSeePW] = useState(false);
   const { isCuDomain } = useSelector((state: HibraryRootState) => state.auth);
   const LoginSchema = Yup.object().shape({
      email: Yup.string()
         .email('อีเมลไม่ถูกต้อง')
         .min(3, 'อย่างน้อย 3 ตัวอักษร')
         .max(200, 'ไม่เกิน 200 ตัวอักษร')
         // .required(
         //   intl.formatMessage({
         //     id: "AUTH.VALIDATION.REQUIRED_FIELD",
         //   })
         // ),
         .required('กรุณากรอกอีเมล'),
      password: Yup.string()
         .min(6, 'อย่างน้อย 6 ตัวอักษร')
         .max(50, 'ไม่เกิน 50 ตัวอักษร')
         // .required(
         //   intl.formatMessage({
         //     id: "AUTH.VALIDATION.REQUIRED_FIELD",
         //   })
         // ),
         .required('กรุณากรอกรหัสผ่าน')
   });

   const enableLoading = () => {
      setLoading(true);
   };

   const disableLoading = () => {
      setLoading(false);
   };

   const errorWord = (err: any) => {
      if (
         err &&
         err.response &&
         err.response.data &&
         err.response.data.errorCode
      ) {
         switch (err.response.data.errorCode) {
            case '9001':
               return 'AUTH.VALIDATION.INVALID_LOGIN_DEVICE';
            case '919':
               return 'AUTH.VALIDATION.INVALID_LOGIN_EMAIL';
            case '005':
               return 'AUTH.VALIDATION.INVALID_LOGIN_PASSWORD';
            case '909':
               return 'AUTH.VALIDATION.INVALID_LOGIN_EXPIRE';
            case '008':
               return 'พบข้อผิดพลาด';
            default:
               return 'AUTH.VALIDATION.INVALID_LOGIN';
         }
      } else {
         return 'AUTH.VALIDATION.INVALID_LOGIN';
      }
   };

   const formik = useFormik({
      initialValues,
      validationSchema: LoginSchema,
      onSubmit: (values, { setStatus, setSubmitting }) => {
         enableLoading();
         setTimeout(() => {
            login(values.email, values.password, isCuDomain ?? false)
               // .then(({ data: { data } }) => {
               .then(response => {
                  const { data }: HtiResponse<LoginResponse> = response.data;
                  disableLoading();
                  props.login(data);
               })
               .catch(err => {
                  disableLoading();
                  setSubmitting(false);
                  setStatus(
                     intl.formatMessage({
                        id: errorWord(err)
                     })
                  );
               });
         }, 1000);
      }
   });

   useEffect(() => {
      console.log('intl ', props, intl);
   }, [props]);

   return (
      <div className="login-form login-signin" id="kt_login_signin_form">
         {/* begin::Head */}
         <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1" id="signin">
               {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
               เข้าสู่ระบบ
            </h3>
            {/* <p className="text-muted font-weight-bold">Enter your username and password</p> */}
         </div>
         {/* end::Head */}

         {/*begin::Form*/}
         <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
         >
            {formik.status ? (
               <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                     {formik.status}
                  </div>
               </div>
            ) : (
               // <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
               //   <div className="alert-text ">
               //     Use account <strong>admin@demo.com</strong> and password{" "}
               //     <strong>demo</strong> to continue.
               //   </div>
               // </div>
               ''
            )}
            <div className="form-group fv-plugins-icon-container">
               <p className="text">Email</p>
               <input
                  placeholder="Email"
                  type="email"
                  className={`form-control form-control-solid h-auto py-5 px-6`}
                  title="email"
                  autoComplete="new-password"
                  {...formik.getFieldProps('email')}
               />
               {/* // */}
               {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                     <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
               ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
               <div className="flex justify-content-between">
                  <p className="text">Password</p>
                  <Link
                     to="/auth/forgot-password"
                     className="mr-2"
                     id="kt_login_forgot"
                  >
                     <p className="forgot">Forgot Password ?</p>
                  </Link>
               </div>
               <input
                  placeholder="Password"
                  type={seePW ? 'text' : 'password'}
                  className={`form-control form-control-solid h-auto py-5 px-6`}
                  title="password"
                  autoComplete="new-password"
                  {...formik.getFieldProps('password')}
               />
               {formik.values.password ? (
                  <OverlayTrigger
                     placement="bottom"
                     overlay={
                        <Tooltip id="quick-user-tooltip">
                           {seePW ? 'ซ่อนรหัสผ่าน' : 'แสดงรหัสผ่าน'}
                        </Tooltip>
                     }
                  >
                     <img
                        src={
                           seePW
                              ? `/media/auth/eye-slash.svg`
                              : `/media/auth/eye.svg`
                        }
                        alt=""
                        onClick={() => {
                           setSeePW(!seePW);
                        }}
                        className="button-hidepass"
                     />
                  </OverlayTrigger>
               ) : (
                  ''
               )}
               {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                     <div className="fv-help-block">
                        {formik.errors.password}
                     </div>
                  </div>
               ) : null}
            </div>
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
               <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={
                     formik.isSubmitting ||
                     formik.errors.email !== undefined ||
                     formik.errors.password !== undefined ||
                     !formik.values['email'] ||
                     !formik.values['password']
                  }
                  className="acceptButton"
               >
                  <span>เข้าสู่ระบบ</span>
                  {loading && (
                     <span className="ml-3 spinner spinner-white"></span>
                  )}
               </button>
            </div>
         </form>
         {/*end::Form*/}
      </div>
   );
}

export default injectIntl(connect(null, auth.actions)(Login));
