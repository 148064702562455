import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
export { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg"; 

export const corePath =
  "https://unpkg.com/@ffmpeg/core@0.9.0/dist/ffmpeg-core.js";
// export const corePath = toAbsoluteUrl("/media/ffmpeg/ffmpeg-core.js");
export const defDuration = "00:00:00";

export const Loggers = (type, message) => {
  let width = 0;
  let height = 0;
  let duration = defDuration;
  let millisecs = 0;

  const resolutionRegex = new RegExp(`[0-9]{3,4}x[0-9]{3,4}`, "g");
  const durationRegex = new RegExp(`Duration: [0-9]{2}:[0-9]{2}:[0-9]{2}`, "g");
  const lengthRegex = new RegExp(
    `Duration: [0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{2}`,
    "g"
  );
  console.log("createFFmpeg ", type, message);
  
  if (type === "fferr") {
    if (message.match(resolutionRegex)) {
      const match = resolutionRegex.exec(message);
      [width, height] = match[0].split("x");
    }
    if (message.match(durationRegex)) {
      const match = durationRegex.exec(message);
      duration = match[0].replace("Duration: ", "");
      const len = lengthRegex.exec(message);
      const lenDuration = len[0].replace("Duration: ", "");
      const [hrs, mins, sandm] = lenDuration.split(":");
      const [secs, milli] = sandm.split(".");
      millisecs =
        (parseFloat(secs) + parseInt(mins) * 60 + parseInt(hrs) * 60 * 60) *
          1000 +
        parseInt(milli) * 100;
    }
  }
  console.log({ width, height, duration, millisecs });
  return { width, height, duration, millisecs };
};

export const getMediaInfo = async (ffmpeg, video) => {
  console.log({ ffmpeg, video });
  if (video !== null && video !== undefined) {
    ffmpeg.FS("writeFile", "test.mp4", await fetchFile(video));
    await ffmpeg.run("-i", "test.mp4");
  } 
};
