/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { UserProfileDropdown } from './dropdowns/UserProfileDropdown';
import jwt_decode from 'jwt-decode';
import { makeStyles } from '@material-ui/core/styles';

import { Role } from '../../../../interface';

const useStyles = makeStyles(theme => ({
    goToLib: {
        '&:hover': { textDecoration: 'underline', cursor: 'pointer' },
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 1,
        lineClamp: 1,
        '-webkit-box-orient': 'vertical',
        textAlign: 'right'
        // whiteSpace: 'nowrap'
    }
}));

export function QuickUserToggler() {
    const classes = useStyles();
    const { authToken, role, viewAsToken, isCuDomain } = useSelector(
        state => state.auth
    );
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            offcanvas:
                objectPath.get(uiService.config, 'extras.user.layout') ===
                'offcanvas'
        };
    }, [uiService]);

    return authToken ? (
        <>
            {viewAsToken ? <div className="devWaterMark">View As</div> : ''}

            <span
                className={
                    !viewAsToken && role === Role.SuperAdmin
                        ? ''
                        : classes.goToLib
                }
                style={{
                    fontWeight: '500',
                    fontSize: '16px',
                    color: '#012875',
                    whiteSpace: 'nowrap',
                    margin:
                        role === Role.SuperAdmin
                            ? 'auto 16px auto auto'
                            : 'auto'
                }}
                onClick={() => {
                    if (viewAsToken) {
                        let url;
                        if (isCuDomain) {
                            url = `https://elibrary-${
                                jwt_decode(viewAsToken).clientPrefix
                            }.cu-elibrary.com`;
                        } else {
                            url = `https://elibrary-${
                                jwt_decode(viewAsToken).clientPrefix
                            }.hibrary.me`;
                        }
                        window.open(url, '_blank');
                    } else if (role !== Role.SuperAdmin) {
                        let url;
                        if (isCuDomain) {
                            url = `https://elibrary-${
                                jwt_decode(authToken).clientPrefix
                            }.cu-elibrary.com`;
                        } else {
                            url = `https://elibrary-${
                                jwt_decode(authToken).clientPrefix
                            }.hibrary.me`;
                        }
                        window.open(url, '_blank');
                    }
                }}
            >
                {role === Role.SuperAdmin
                    ? viewAsToken
                        ? `ไปที่ ${jwt_decode(viewAsToken).clientName}`
                        : 'Super Admin'
                    : `ไปที่ ${jwt_decode(authToken).clientName}`}
            </span>
            {jwt_decode(authToken).clientLogo ? (
                <div
                    style={{
                        display: 'inline-flex',
                        borderRadius: '36px',
                        width: '36px',
                        height: '36px',
                        border: '1px solid',
                        margin: '0 16px'
                    }}
                >
                    <img
                        src={jwt_decode(authToken).clientLogo}
                        alt=""
                        style={{
                            padding: '5px',
                            width: 'auto',
                            margin: 'auto',
                            height: '100%',
                            imageRendering: '-webkit-optimize-contrast'
                        }}
                    />
                </div>
            ) : (
                ''
            )}
            {layoutProps.offcanvas && (
                <Link
                    to="/logout"
                    className={`btn btn-light-primary font-weight-bold ${
                        viewAsToken ? 'logout-viewas' : ''
                    }`}
                >
                    {viewAsToken ? 'ออกจาก View as' : 'ลงชื่อออก'}
                </Link>
            )}

            {!layoutProps.offcanvas && <UserProfileDropdown />}
        </>
    ) : (
        ''
    );
}
