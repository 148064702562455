/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import CompaniesTableAnt from './companies-table/CompaniesTableAnt';
import { useCompaniesUIContext } from './CompaniesUIContext';
import { Card } from 'antd';
import { CompaniesFilter } from './companies-filter/CompaniesFilter';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { NodataView, LoaderView } from '../../../CustomView';
import * as actions from '../../_redux/companies/companiesActions';
import { companiesSlice } from '../../_redux/companies/companiesSlice';
import { ClientProcessStatus } from '../../../../../interface';
interface CompaniesCard {
    isPrivate: number;
}
export function CompaniesCard({ isPrivate }: CompaniesCard) {
    const dispatch = useDispatch();

    const { ccompaniesState, authState, companiesList } = useSelector(
        ({ companies, auth }: any) => ({
            ccompaniesState: companies,
            authState: auth,
            companiesList: companies.entities
        }),
        shallowEqual
    );
    const history = useHistory();
    const { companyUpdate, companySearch, LastEvaluatedKey } = ccompaniesState;
    const anchorRef = React.useRef(null);
    const [currentTab, setcurrentTab] = useState('School');
    const [loadingBottom, setLoadingBottom] = useState(false);
    const [counter, setCounter] = useState(30);
    enum tab {
        School = 'โรงเรียน',
        PrivateCompany = 'บริษัทเอกชน',
        GovernmentAgency = 'หน่วยงานรัฐ',
        University = 'มหาวิทยาลัย',
        Library = 'ห้องสมุดประชาชน'
    }
    const tabList = [
        {
            key: 'School',
            tab: tab.School
        },
        {
            key: 'PrivateCompany',
            tab: tab.PrivateCompany
        },
        {
            key: 'GovernmentAgency',
            tab: tab.GovernmentAgency
        },
        {
            key: 'University',
            tab: tab.University
        },
        {
            key: 'Library',
            tab: tab.Library
        }
    ];

    const updateCompany = () => {
        if (companiesList !== undefined && companyUpdate) {
            let newCompaniesList = JSON.parse(JSON.stringify(companiesList));
            let index = companiesList.findIndex(
                (obj: any) => obj.clientUID === companyUpdate.clientUID
            );

            newCompaniesList[index].clientName = companyUpdate?.clientName;
            newCompaniesList[index].displayName = companyUpdate?.displayName;
            newCompaniesList[index].companyPrefix =
                companyUpdate?.companyPrefix;
            newCompaniesList[index].userLimit = companyUpdate?.userLimit;
            newCompaniesList[index].expireDate = companyUpdate?.expireDate;
            newCompaniesList[index].startDate = companyUpdate?.startDate;
            newCompaniesList[index].processStatus =
                companyUpdate?.processStatus;
            newCompaniesList[index].status = companyUpdate?.status;

            dispatch(
                companiesSlice.actions.setEntities({
                    entities: newCompaniesList
                })
            );
            // setConpaniesList(newCompaniesList);
        }
    };

    const companiesUIContext = useCompaniesUIContext();
    const companiesUIProps = useMemo(() => {
        return {
            ids: companiesUIContext.ids,
            setIds: companiesUIContext.setIds,
            queryParams: companiesUIContext.queryParams,
            newCustomerButtonClick: companiesUIContext.newCustomerButtonClick
        };
    }, [companiesUIContext]);

    const onTabChange = (key: string) => {
        setcurrentTab(key);

        companiesUIContext.setCurrentTab(key);
    };

    const extraTool = () => {
        return (
            <>
                <CompaniesFilter
                    isPrivate={isPrivate}
                    setLoadingBottom={setLoadingBottom}
                    currentTab={currentTab}
                />
                <button
                    type="button"
                    className="button"
                    //   className={classes.button}
                    onClick={() => {
                        history.push('/upload-company');
                    }}
                >
                    <span>เพิ่มองค์กร</span>
                </button>
                {!authState.isCuDomain ? (
                    <button
                        ref={anchorRef}
                        aria-haspopup="true"
                        onClick={companiesUIProps.newCustomerButtonClick}
                        className="button"
                    >
                        <span>
                            <img
                                alt="addmember"
                                src="/media/members/add-member-button.svg"
                            />
                        </span>
                        <span>เพิ่มสมาชิก</span>
                    </button>
                ) : (
                    ''
                )}
            </>
        );
    };

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (companiesList) {
            const isProcessData: boolean[] = companiesList.map(
                (el: { processStatus: ClientProcessStatus }) => {
                    if (
                        el.processStatus === ClientProcessStatus.ClearMember ||
                        el.processStatus === ClientProcessStatus.ClearStore
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                }
            );

            const clearStoreCount = parseInt(
                localStorage.getItem('clearStore') ?? ''
            );

            if (counter > 0 && isProcessData.includes(true)) {
                timer = setInterval(() => {
                    setCounter(counter - 1);
                }, 1000);
            }

            if (counter === 0) {
                if (
                    isProcessData.includes(true) &&
                    !isNaN(clearStoreCount) &&
                    clearStoreCount >= 0
                ) {
                    if (clearStoreCount === 0) {
                        localStorage.removeItem('clearStore');
                    } else {
                        dispatch(
                            actions.fetchCompanies(
                                companiesUIProps.queryParams,
                                isPrivate,
                                undefined,
                                setLoadingBottom,
                                currentTab
                            )
                        );
                        localStorage.setItem(
                            'clearStore',
                            `${clearStoreCount - 1}`
                        );
                    }
                }
                setCounter(60);
            }
        }

        return () => {
            clearInterval(timer);
        };
    }, [counter, companiesList]);

    useEffect(() => {
        if (companiesList !== undefined) updateCompany();
    }, [companyUpdate]);

    useEffect(() => {
        dispatch(
            companiesSlice.actions.setEntities({ entities: companySearch })
        );
    }, [companySearch]);

    useEffect(() => {
        if (
            LastEvaluatedKey === undefined ||
            JSON.stringify(LastEvaluatedKey) === JSON.stringify({})
        ) {
        } else {
            dispatch(
                actions.fetchCompanies(
                    companiesUIProps.queryParams,
                    isPrivate,
                    LastEvaluatedKey,
                    setLoadingBottom,
                    currentTab
                )
            );
        }
    }, [LastEvaluatedKey, loadingBottom]);

    useEffect(() => {
        // clear selections list
        companiesUIProps.setIds([]);
        // server call by queryParams

        let queryParams = companiesUIProps.queryParams;
        if (ccompaniesState.searchKeyword) {
            queryParams = Object.assign(queryParams, {
                keyword: ccompaniesState.searchKeyword
            });
        }

        // dispatch(
        //     actions.fetchCompanies(
        //         queryParams,
        //         isPrivate,
        //         undefined,
        //         setLoadingBottom,
        //         currentTab
        //     )
        // );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPrivate]);

    useEffect(() => {
        let queryParams = companiesUIProps.queryParams;
        queryParams = Object.assign(queryParams, {
            keyword: undefined
        });

        if (currentTab !== undefined && companiesList) {
            dispatch(
                companiesSlice.actions.saveKeyword({
                    searchKeyword: undefined
                })
            );
            dispatch(
                actions.fetchCompanies(
                    companiesUIProps.queryParams,
                    isPrivate,
                    undefined,
                    setLoadingBottom,
                    currentTab
                )
            );
        }
    }, [currentTab]);

    return (
        <Card
            title={
                <div className="card-label">
                    <div>องค์กร</div>
                </div>
            }
            className="cardStyle CompaniesCardAnt CompaniesCard"
            tabList={tabList}
            extra={extraTool()}
            onTabChange={key => {
                onTabChange(key);
            }}
        >
            {companiesList === undefined || loadingBottom ? (
                <LoaderView />
            ) : companiesList.length === 0 ? (
                <NodataView isSearch={false} />
            ) : (
                <CompaniesTableAnt data={companiesList} />
            )}
        </Card>
    );
}
