// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Role } from "../../../../../../../interface";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  {
    ele,
    isPrivate,
    channelName,
    isCreator,
    channelIdOfCreators,
    openEditCreatorDialog,
    openEditChannelDialog,
    openDeleteCreatorDialog,
    openDeleteChannelDialog,
    openBlockCreatorDialog,
    openBlockChannelDialog,
    openUnblockCreatorDialog,
    openUnblockChannelDialog,
    openCreatorsPage,
    openCreatorBooksPage,
    role,
  }
) {
  const barStatus = (row) => {
    if (row.status === 1) {
      return "ACTIVE";
    } else if (row.status === 0) {
      return "BLOCKED";
    }
  };
  return row && ele ? (
    <div style={{ position: "absolute", textAlign: "right" }}>
      {role === Role.SuperAdmin ? (
        ""
      ) : (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="quick-user-tooltip">
              แก้ไข{isCreator ? " Creator" : " Channel"}
            </Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => {
              if (isCreator) {
                openEditCreatorDialog(
                  row.name,
                  channelIdOfCreators,
                  row.uid,
                  isPrivate,
                  channelName
                );
              } else {
                openEditChannelDialog(row.name, row.uid, isPrivate);
              }
            }}
            style={{ marginBottom: "4px" }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/members/edit-button.svg")} />
            </span>
          </a>
        </OverlayTrigger>
      )}
      {role === Role.SuperAdmin ? (
        <a
          className="btn btn-icon btn-light btn-sm mx-3"
          style={{
            backgroundColor:
              barStatus(row) === "ACTIVE" ? "#F3F6F9" : "#ff5337",
            cursor: "default",
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl(
                `/media/members/${
                  barStatus(row) === "ACTIVE" ? "block" : "unblock"
                }-button.svg`
              )}
            />
          </span>
        </a>
      ) : (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="quick-user-tooltip">
              {barStatus(row) === "ACTIVE" ? "บล็อก" : "ปลดบล็อก"}
              {isCreator ? " Creator" : " Channel"}
            </Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={() => {
              if (barStatus(row) === "ACTIVE") {
                if (isCreator) {
                  openBlockCreatorDialog(
                    row.name,
                    channelIdOfCreators,
                    row.uid,
                    isPrivate,
                    channelName
                  );
                } else {
                  openBlockChannelDialog(row.name, row.uid, isPrivate);
                }
              } else if (barStatus(row) === "BLOCKED") {
                if (isCreator) {
                  openUnblockCreatorDialog(
                    row.name,
                    channelIdOfCreators,
                    row.uid,
                    isPrivate,
                    channelName
                  );
                } else {
                  openUnblockChannelDialog(row.name, row.uid, isPrivate);
                }
              }
            }}
            style={{
              backgroundColor:
                barStatus(row) === "ACTIVE" ? "#F3F6F9" : "#ff5337",
              marginBottom: "4px",
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl(
                  `/media/members/${
                    barStatus(row) === "ACTIVE" ? "block" : "unblock"
                  }-button.svg`
                )}
              />
            </span>
          </a>
        </OverlayTrigger>
      )}
      {role === Role.SuperAdmin ? (
        ""
      ) : (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="quick-user-tooltip">
              ลบ {isCreator ? "Creator" : "Channel"}
            </Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            style={{ marginLeft: "0.75rem", marginBottom: "4px" }}
            onClick={() => {
              if (isCreator) {
                openDeleteCreatorDialog(
                  row.name,
                  channelIdOfCreators,
                  row.uid,
                  isPrivate,
                  channelName
                );
              } else {
                openDeleteChannelDialog(row.name, row.uid, isPrivate);
              }
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/members/delete-button.svg")} />
            </span>
          </a>
        </OverlayTrigger>
      )}
    </div>
  ) : (
    ""
  );
}
