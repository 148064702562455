import * as requestFromServer from "./publishersCrud";
import { publishersSlice, callTypes } from "./publishersSlice";
import {
  CreatePublisherPayload,
  CreateAuthorPayload,
  customersUIPropsQueryParams,
  LastEvaluatedKey_GetPublishers,
  UpdatePublishersPayload,
  UpdateAuthorPayload,
} from "../../../../../interface";
import { Stringifiable } from "query-string";
const { actions } = publishersSlice;

export const fetchPublishers = (
  queryParams: customersUIPropsQueryParams,
  isPrivate: string,
  lastEvaluate: LastEvaluatedKey_GetPublishers,
  isAll: boolean
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  //isAll is for dropdown
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPublishers(queryParams, parseInt(isPrivate, 10), lastEvaluate, isAll)
    .then((response) => {
      // const { LastEvaluatedKey, publishers } = response.data.data;
      const { data } = response.data;
      const entities = data?.publishers;
      dispatch(
        actions.publishersFetched({
          totalCount: data?.publishers.length,
          entities,
          LastEvaluatedKey : data?.LastEvaluatedKey,
          lastEvaluate,
          memberMode: queryParams.memberMode,
        })
      );
    })
    .catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorCode === "012"
      ) {
        alert("กรุณาเข้าสู่ระบบอีกครั้ง เนื่องจากระยะเวลาการใช้งานหมดอายุ");
        window.location.href = "/logout";
      }
      dispatch(
        actions.publishersFetched({
          totalCount: 0,
          entities: [],
          LastEvaluatedKey: {},
        })
      );
      error.clientMessage = "Can't find publishers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchAuthors = (
  queryParams: customersUIPropsQueryParams,
  publisherId: string,
  isPrivate: string
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  if (!publisherId) {
    return dispatch(actions.authorsFetched({ authorsForEdit: undefined }));
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAuthorsById(queryParams, publisherId, parseInt(isPrivate, 10))
    .then((response) => {
      if (response.data.status) {
        
        const {data} = response.data
        dispatch(
          actions.authorsFetched({
            authors : data?.authors,
            publisherId: publisherId,
            memberMode: queryParams.memberMode,
          })
        );
      }
    })
    .catch((error) => {
      dispatch(actions.authorsFetched({ authorsForEdit: undefined }));
      error.clientMessage = "Can't find publisher";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const deletePublisher = (id: string, isPrivate: number) => (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deletePublisher(id, isPrivate)
    .then((response) => {
      dispatch(actions.publisherDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete publisher";
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          from: "delete",
        })
      );
    });
};

export const deleteAuthor = (publisherId  : string, authorId :string, isPrivate : number) => (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteAuthor(publisherId, authorId, isPrivate)
    .then((response) => {
      dispatch(actions.authorDeleted({ authorId: authorId }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete author";
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          from: "delete",
        })
      );
    });
};

export const blockAuthor = (name : string, publisherId : string, authorId : string, isPrivate : string) => (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .blockAuthor(name, publisherId, authorId, parseInt(isPrivate, 10))
    .then((response) => {
      dispatch(actions.elementUpdated());
    })
    .catch((error) => {
      error.clientMessage = "Can't block author";
      dispatch(
        actions.catchError({ error, callType: callTypes.action, from: "block" })
      );
    });
};

export const blockPublisher = (name : string, id : string, isPrivate : string) => (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .blockPublisher(name, id, parseInt(isPrivate, 10))
    .then((response) => {
      dispatch(actions.elementUpdated());
    })
    .catch((error) => {
      error.clientMessage = "Can't block publisher";
      dispatch(
        actions.catchError({ error, callType: callTypes.action, from: "block" })
      );
    });
};

export const unblockAuthor = (name : string, publisherId : string, authorId : string, isPrivate :string) => (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .unblockAuthor(name, publisherId, authorId, parseInt(isPrivate, 10))
    .then((response) => {
      dispatch(actions.elementUpdated());
    })
    .catch((error) => {
      error.clientMessage = "Can't unblock author";
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          from: "unblock",
        })
      );
    });
};

export const unblockPublisher = (
  name: string,
  id: string,
  isPrivate: string
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .unblockPublisher(name, id, parseInt(isPrivate, 10))
    .then((response) => {
      dispatch(actions.elementUpdated());
    })
    .catch((error) => {
      error.clientMessage = "Can't unblock publisher";
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          from: "unblock",
        })
      );
    });
};

export const createPublisher = (
  publisherForCreation: CreatePublisherPayload,
  isPrivate: string,
  setSuccessModal: (success: boolean) => void,
  onSuccess: () => void
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createPublisher(publisherForCreation, parseInt(isPrivate, 10))
    .then((response) => {
      if (response.data.status) {
        const { publisher } = response.data.data;
        dispatch(actions.publisherCreated({ publisher }));
        if (setSuccessModal) {
          setSuccessModal(true);
        }
        if (onSuccess) {
          onSuccess();
        }
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't create publisher";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      // console.log(error);
      if (error) {
        return "error";
      }
    });
};

export const createAuthor = (
  authorForCreation: CreateAuthorPayload,
  isPrivate: string,
  publisherId: string,
  publisherName: string,
  history: any,
  onSuccess: () => void
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createAuthor(authorForCreation, parseInt(isPrivate, 10), publisherId)
    .then((response) => {
      if (response.data.status) {
        const { author } = response.data.data;
        if (author.status) {
          dispatch(actions.authorCreated({ author, publisherId }));
        }
        if (history && onSuccess) {
          onSuccess();
          history.push(
            `/authors?publisherId=${publisherId}&publisherName=${publisherName}`
          );
        }
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't create author";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      if (error) {
        return "error";
      }
    });
};

export const updatePublisher = (
  publisher: UpdatePublishersPayload,
  isPrivate: string,
  setSuccessModal: (success: boolean) => void
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updatePublisher(publisher, parseInt(isPrivate, 10))
    .then(() => {
      dispatch(actions.publisherUpdated({ publisher }));
      if (setSuccessModal) {
        setSuccessModal(true);
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't update publisher";
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          from: "update",
        })
      );
    });
};

export const updateAuthor = (
  author: UpdateAuthorPayload,
  isPrivate: string,
  setSuccessModal: (success: boolean) => void
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateAuthor(author, parseInt(isPrivate, 10))
    .then(() => {
      dispatch(
        actions.authorUpdated({
          author: { name: author.name, status: author.status },
          authorId: author.authorId,
        })
      );
      if (setSuccessModal) {
        setSuccessModal(true);
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't update author";
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          from: "update",
        })
      );
    });
};

// export const updatePublishersStatus = (ids, status) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateStatusForPublishers(ids, status)
//     .then(() => {
//       dispatch(actions.publishersStatusUpdated({ ids, status }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't update publishers status";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const deletePublishers = (ids) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deletePublishers(ids)
//     .then(() => {
//       dispatch(actions.publishersDeleted({ ids }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't delete publishers";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };
