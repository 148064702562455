import axios from "axios";

// export const CHANNELS_URL = "api/channels";
export const CHANNELS_URL = `/admin/channels`;
export const PRIVATE_CHANNELS_URL = `/admin/private/channels`;

// CREATE =>  POST: add a new channel to the server
export function createChannel(channel, isPrivate) {
  return axios.post(
    isPrivate ? PRIVATE_CHANNELS_URL : CHANNELS_URL,
    Object.assign({}, channel, { publisherType: "podcast_channel" })
  );
}

export function createCreator(creator, isPrivate, channelId) {
  return axios.post(
    `${isPrivate ? PRIVATE_CHANNELS_URL : CHANNELS_URL}/${channelId}/creators`,
    Object.assign({}, creator, { publisherType: "podcast_creator" })
  );
}

// READ
export function getAllChannels() {
  return axios.get(CHANNELS_URL);
}

export function getCreatorsById(queryParams, channelId, isPrivate) {
  if (queryParams === "none" || !queryParams.keyword) {
    return axios.get(
      `${
        isPrivate ? PRIVATE_CHANNELS_URL : CHANNELS_URL
      }/${channelId}/creators?isAll=true`
    );
  } else {
    return axios.get(
      `${
        isPrivate ? PRIVATE_CHANNELS_URL : CHANNELS_URL
      }/${channelId}/creators/search?keyword=${encodeURIComponent(
        queryParams.keyword
      )}`
    );
  }
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findChannels(queryParams, isPrivate, lastEvaluate, isAll) {
  if (queryParams === "none" || !queryParams.keyword) {
    let limit = "";
    let lastKeyQuery = "";
    let isAllQuery = "";

    if (queryParams && queryParams.limit) {
      limit = `&limit=${queryParams.limit}`;
    }
    if (
      lastEvaluate !== undefined &&
      JSON.stringify(lastEvaluate) !== JSON.stringify({})
    ) {
      // lastKeyQuery = "&lastEvaluatedKey=" + btoa(JSON.stringify(lastEvaluate));
      lastKeyQuery = "&lastEvaluatedKey=" + btoa(JSON.stringify(lastEvaluate));
    }
    if (isAll) {
      isAllQuery = "&isAll=true";
    }
    return axios.get(
      `${
        isPrivate ? PRIVATE_CHANNELS_URL : CHANNELS_URL
      }?publisherType=podcast_channel${limit}${lastKeyQuery}${isAllQuery}`
    );
  } else {
    return axios.get(
      `${
        isPrivate ? PRIVATE_CHANNELS_URL : CHANNELS_URL
      }/search?publisherType=podcast_channel&keyword=${encodeURIComponent(
        queryParams.keyword
      )}`
    );
  }
}

// UPDATE => PUT: update the channel on the server
export function updateChannel(channel, isPrivate) {
  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }
  return axios.patch(
    `${isPrivate ? PRIVATE_CHANNELS_URL : CHANNELS_URL}/${channel.uid}`,
    {
      name: channel.name,
      status: channel.status,
      description: channel.description,
      publisherType: "podcast_channel",
      image: isValidHttpUrl(channel.image) ? undefined : channel.image
    }
  );
}

export function updateCreator(creator, isPrivate) {
  return axios.patch(
    `${isPrivate ? PRIVATE_CHANNELS_URL : CHANNELS_URL}/${
      creator.channelId
    }/creators/${creator.creatorId}`,
    {
      name: creator.name,
      status: creator.status
    }
  );
}

// UPDATE Status
export function updateStatusForChannels(ids, status) {
  return axios.post(`${CHANNELS_URL}/updateStatusForChannels`, {
    ids,
    status
  });
}

// DELETE => delete the channel from the server
export function deleteChannel(channelId, isPrivate) {
  return axios.delete(
    `${isPrivate ? PRIVATE_CHANNELS_URL : CHANNELS_URL}/${channelId}`
  );
}

export function deleteCreator(channelId, creatorId, isPrivate) {
  return axios.delete(
    `${
      isPrivate ? PRIVATE_CHANNELS_URL : CHANNELS_URL
    }/${channelId}/creators/${creatorId}`
  );
}

export function blockCreator(creatorName, channelId, creatorId, isPrivate) {
  return axios.patch(
    `${
      isPrivate ? PRIVATE_CHANNELS_URL : CHANNELS_URL
    }/${channelId}/creators/${creatorId}`,
    {
      name: creatorName,
      status: 0
    }
  );
}
export function blockChannel(channelName, channelId, isPrivate) {
  return axios.patch(
    `${isPrivate ? PRIVATE_CHANNELS_URL : CHANNELS_URL}/${channelId}`,
    {
      name: channelName,
      status: 0
    }
  );
}
export function unblockCreator(creatorName, channelId, creatorId, isPrivate) {
  return axios.patch(
    `${
      isPrivate ? PRIVATE_CHANNELS_URL : CHANNELS_URL
    }/${channelId}/creators/${creatorId}`,
    {
      name: creatorName,
      status: 1
    }
  );
}
export function unblockChannel(channelName, channelId, isPrivate) {
  return axios.patch(
    `${isPrivate ? PRIVATE_CHANNELS_URL : CHANNELS_URL}/${channelId}`,
    {
      name: channelName,
      status: 1
    }
  );
}

export function setStatusChannel(channelId, value, isPrivate) {
  return axios.patch(
    `${isPrivate ? PRIVATE_CHANNELS_URL : CHANNELS_URL}/${channelId}`,
    {
      status: value
    }
  );
}
export function setPinChannel(channelId, value, isPrivate) {
  return axios.patch(
    `${isPrivate ? PRIVATE_CHANNELS_URL : CHANNELS_URL}/${channelId}`,
    {
      pin: value
    }
  );
}
// DELETE Channels by ids
export function deleteChannels(ids) {
  return axios.post(`${CHANNELS_URL}/deleteChannels`, { ids });
}
