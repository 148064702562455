import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useSelector } from "react-redux";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { Table, Skeleton } from "antd";
const useStyles1 = makeStyles(() => ({
     p: { marginBottom: "0px", fontSize: "14px" },
     body: { margin: "auto" },
     center: {
          textAlign: "center",
     },

     body: { margin: "auto" },
}));
export function CatalogueSummary(props) {
     // const parsed = queryString.parse(location);
     // console.log(props);
     const classes = useStyles1();
     const [summary, setSummary] = useState();
     const [catagories, setCatagories] = useState();
     const [loading, setloading] = useState(false);
     const columns = [
          {
               title: "หมวดหมู่",
               dataIndex: "catName",
               key: "catName",
          },
          {
               title: "จำนวนอีบุ๊ก",
               dataIndex: "contentAmount",
               key: "contentAmount",
               width: 120,
               align: "center",
          },
          {
               title: "เปอร์เซ็นอีบุ๊ก",
               dataIndex: "contentPercentage",
               key: "contentPercentage",
               width: 120,
               align: "center",
          },
          {
               title: "จำนวนสิทธิ์",
               dataIndex: "concurrentAmount",
               key: "concurrentAmount",
               width: 120,
               align: "center",
          },
          {
               title: "เปอร์เซ็นสิทธิ์",
               dataIndex: "concurrentPercentage",
               key: "concurrentPercentage",
               width: 120,
               align: "center",
          },
     ];

     useEffect(() => {
          // const parsed = queryString.parse(location.search);
          if (props.id) {
               const reqFunc = async () => {
                    try {
                         const req = await axios.get(`/admin/catalogs/${props.id}/summary`);
                         const { data } = req;

                         if (data.status) {
                              setloading(true);
                         }
                         if (data.status && data.data) {
                              let summary = data.data;

                              setSummary(summary);
                              catagories(summary.catagories);
                         } else {
                              setloading(false);
                         }
                    } catch (error) {}
               };
               const catagories = (catagories) => {
                    let cat = catagories;
                    let per = "%";
                    const data = [];
                    for (let i = 0; i < cat.length; i++) {
                         let dataArr = cat[i];
                         data.push({
                              key: dataArr.catId,
                              catName: dataArr.catName,
                              contentAmount: dataArr.contentAmount,
                              contentPercentage: dataArr.contentPercentage + per,
                              concurrentAmount: dataArr.concurrentAmount,
                              concurrentPercentage: dataArr.concurrentPercentage + per,
                              subcats: dataArr.subcats,
                         });
                    }
                    setCatagories(data);
               };
               reqFunc();
          }
     }, []);

     const expandedRowRender = (record) => {
          let subcat = record.subcats;
          let per = "%";
          const columns = [
               {
                    title: "หมวดหมู่",
                    dataIndex: "subcatName",
                    key: "subcatName",
               },
               {
                    title: "จำนวนอีบุ๊ก",
                    dataIndex: "contentAmount",
                    key: "contentAmount",
                    width: 120,
               },
               {
                    title: "เปอร์เซ็นอีบุ๊ก",
                    dataIndex: "contentPercentage",
                    key: "contentPercentage",
                    width: 120,
               },
               {
                    title: "จำนวนสิทธิ์",
                    dataIndex: "concurrentAmount",
                    key: "concurrentAmount",
                    width: 120,
               },
               {
                    title: "เปอร์เซ็นสิทธิ์",
                    dataIndex: "concurrentPercentage",
                    key: "concurrentPercentage",
                    width: 120,
               },
          ];

          const data = [];
          for (let i = 0; i < subcat.length; i++) {
               let dataArr = subcat[i];
               data.push({
                    key: dataArr.subcatId,
                    subcatName: dataArr.subcatName,
                    contentAmount: dataArr.contentAmount,
                    contentPercentage: dataArr.contentPercentage + per,
                    concurrentAmount: dataArr.concurrentAmount,
                    concurrentPercentage: dataArr.concurrentPercentage + per,
               });
          }

          return <Table columns={columns} dataSource={data} pagination={false} className="components-table-nested" />;
     };

     return (
          <div>
               <h1 className="center">สรุปข้อมูล</h1>
               <p className="text-gray">{props?.catalogueForEdit}</p>

               <div className="row">
                    <div className="col-6 ">
                         <div className="card-booklist">
                              <p className="text-gray">จำนวนอีบุ๊ก</p>
                              <h1 className="center">{summary?.contentAmount}</h1>
                         </div>
                    </div>
                    <div className="col-6   ">
                         <div className="card-booklist">
                              <p className="text-gray">จำนวนสิทธิ์</p>
                              <h1 className="center">{summary?.concurrentAmount}</h1>
                         </div>
                    </div>
                    {loading ? (
                         <div className="col-12 ">
                              <Table
                                   className="components-table"
                                   expandedRowRender={(record) => expandedRowRender(record)}
                                   size="small"
                                   columns={columns}
                                   dataSource={catagories}
                                   expandIconColumnIndex={5}
                                   pagination={false}
                                   expandable={{
                                        expandIcon: ({ expanded, onExpand, record }) =>
                                             expanded ? (
                                                  <DownOutlined onClick={(e) => onExpand(record)} />
                                             ) : (
                                                  <RightOutlined onClick={(e) => onExpand(record)} />
                                             ),
                                   }}
                              />
                         </div>
                    ) : (
                         <Skeleton paragraph={{ rows: 1 }} active />
                    )}
               </div>
          </div>
     );
}
