import React, { useState, useEffect } from "react";
import { VideosCard } from "./VideosCard";
import { Route, Switch } from "react-router-dom";
import { VideosUIProvider } from "./VideosUIContext";
import { VideoUploadDialog } from "./video-upload-dialog/VideoUploadDialog";
import queryString from "query-string";
import { VideoEditDialog } from "./video-edit-dialog/VideoEditDialog";
import { VideoViewDialog } from "./video-view-dialog/VideoViewDialog";
import { VideoDeleteDialog } from "./video-delete-dialog/VideoDeleteDialog";
import { VideoBlockDialog } from "./video-block-dialog/VideoBlockDialog";
import { VideoUnblockDialog } from "./video-unblock-dialog/VideoUnblockDialog";
import { ChannelNewDialog } from "../channels/channel-new-dialog/ChannelNewDialog";
import { CategoryNewDialog } from "../categories/category-new-dialog/CategoryNewDialog";
import { UploadInformationDialog } from "../upload/upload-information-dialog/UploadInformationDialog";
import { Card, CardBody } from "../../../../../_metronic/_partials/controls";

export function VideosPrivatePage(props) {
  const { history, location } = props;
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [isEdit, setEdit] = useState(0);
  const [isNewVideo, setNewVideo] = useState(undefined);
  const [isNewChannel, setNewChannel] = useState(0);
  const [isNewCreator, setNewCreator] = useState(0);
  const [isNewCat, setNewCat] = useState(0);
  const [isNewSubcat, setNewSubcat] = useState(0);
  const [view, setView] = useState(0);
  const [isDelete, setDelete] = useState(0);
  const [isBlock, setBlock] = useState(0);
  const [isUnblock, setUnblock] = useState(0);

  const [errorMessage, setErrorMessage] = useState(undefined);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.edit) {
      setEdit(1);
    }
    if (parsed.newVideo) {
      setNewVideo(1);
    } else {
      setNewVideo(undefined);
    }
    if (parsed.newChannel) {
      setNewChannel(1);
    }
    if (parsed.newCreator) {
      setNewCreator(1);
    }
    if (parsed.newCat) {
      setNewCat(1);
    }
    if (parsed.newSubcat) {
      setNewSubcat(1);
    }
    if (parsed.view) {
      setView(true);
    }
    if (parsed.delete) {
      setDelete(1);
    }
    if (parsed.block) {
      setBlock(1);
    }
    if (parsed.unblock) {
      setUnblock(1);
    }
    if (parsed.id) {
      setId(parsed.id);
    }
    if (parsed.name) {
      setName(parsed.name);
    }
  }, [location.search]);

  const videosUIEvents = {
    openFileInformationDialog: (error, message) => {
      if (error) {
        setErrorMessage(message);
      }
    },
    newVideoButtonClick: () => {
      history.push(`/videos-private?newVideo=true`);
    },
    newChannelButtonClick: isNewVideo_ => {
      if (isNewVideo_) {
        history.push("/videos-private?newVideo=true&newChannel=true");
      } else {
        history.push("/videos-private?newChannel=true");
      }
    },
    newCreatorButtonClick: () => {
      history.push("/videos-private?newVideo=true&newCreator=true");
    },
    newCategoryButtonClick: isNewCat_ => {
      if (isNewCat_) {
        history.push("/videos-private?newVideo=true&newCat=true");
      } else {
        history.push("/videos-private?newCat=true");
      }
    },
    newSubCategoryButtonClick: isNewSubcat_ => {
      if (isNewSubcat_) {
        history.push("/videos-private?newVideo=true&newSubcat=true");
      } else {
        history.push("/videos-private?newSubcat=true");
      }
    },
    openViewVideoDialog: id => {
      history.push(`/videos-private?id=${id}&view=true`);
    },
    openDeleteVideoDialog: (name, id) => {
      history.push(
        `/videos-private?name=${encodeURIComponent(name)}&id=${id}&delete=true`
      );
    },
    openBlockVideoDialog: (name, id) => {
      history.push(
        `/videos-private?name=${encodeURIComponent(name)}&id=${id}&block=true`
      );
    },
    openUnblockVideoDialog: (name, id) => {
      history.push(
        `/videos-private?name=${encodeURIComponent(name)}&id=${id}&unblock=true`
      );
    },
    openEditVideoDialog: id => {
      history.push(`/videos-private?id=${id}&edit=true`);
    }
  };

  return (
    <VideosUIProvider videosUIEvents={videosUIEvents}>
      {isEdit ? (
        <VideoEditDialog
          show={true}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push("/videos-private");
            setEdit(0);
          }}
        />
      ) : (
        ""
      )}
      {errorMessage ? (
        <UploadInformationDialog
          show={true}
          onHide={() => {
            history.push(`/videos-private?newVideo=true`);
            setErrorMessage(undefined);
          }}
          isError={true}
          errorMessage={errorMessage}
        />
      ) : (
        ""
      )}
      {isNewChannel ? (
        <ChannelNewDialog
          show={true}
          onHide={() => {
            if (isNewVideo) {
              history.push(`/videos-private?newVideo=true`);
            } else {
              history.push(`/videos-private`);
            }
            setNewChannel(0);
          }}
          isPrivate={1}
          notOrigin={true}
        />
      ) : (
        ""
      )}
      {isNewCreator ? (
        <ChannelNewDialog
          show={true}
          onHide={() => {
            history.push(`/videos-private?newVideo=true`);
            setNewCreator(0);
          }}
          isPrivate={1}
          isCreator={true}
          notOrigin={true}
        />
      ) : (
        ""
      )}
      {isNewCat ? (
        <CategoryNewDialog
          show={true}
          onHide={() => {
            if (isNewVideo) {
              history.push(`/videos-private?newVideo=true`);
            } else {
              history.push(`/videos-private`);
            }
            setNewCat(0);
          }}
          isPrivate={1}
          history={history}
          notOrigin={true}
          contentType="video"
        />
      ) : (
        ""
      )}
      {isNewSubcat ? (
        <CategoryNewDialog
          show={true}
          onHide={() => {
            if (isNewVideo) {
              history.push(`/videos-private?newVideo=true`);
            } else {
              history.push(`/videos-private`);
            }
            setNewSubcat(0);
          }}
          isPrivate={1}
          isSubCat={true}
          history={history}
          notOrigin={true}
          contentType="video"
        />
      ) : (
        ""
      )}
      {view ? (
        <VideoViewDialog
          show={true}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push("/videos-private");
            setView(false);
          }}
        />
      ) : (
        ""
      )}
      {isDelete ? (
        <VideoDeleteDialog
          show={true}
          name={name}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push("/videos-private");
            setDelete(false);
          }}
        />
      ) : (
        ""
      )}
      {isUnblock ? (
        <VideoUnblockDialog
          show={true}
          name={name}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push("/videos-private");
            setUnblock(false);
          }}
        />
      ) : (
        ""
      )}
      {isBlock ? (
        <VideoBlockDialog
          show={true}
          name={name}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push("/videos-private");
            setBlock(false);
          }}
        />
      ) : (
        ""
      )}
      {isNewVideo ? (
        <Card
          style={{
            boxShadow: "none",
            borderRadius: "0.42rem",
            height: "100%"
          }}
        >
          <CardBody>
            <VideoUploadDialog
              show={true}
              onHide={() => {
                history.push("/videos-private");
                setNewVideo(0);
              }}
            />
          </CardBody>
        </Card>
      ) : (
        <VideosCard isPrivate={1} isNewVideo={isNewVideo} />
      )}
    </VideosUIProvider>
  );
}
