/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
// import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import LastPageIcon from "@material-ui/icons/LastPage";
import Grid from '@material-ui/core/Grid';
import Spinner from '../../../_helpers/spinner';
import { Empty, Row, Col } from 'antd';

const useStyles1 = makeStyles(theme => ({
   root: {
      flexShrink: 0,
      color: theme.palette.text.secondary
   }
}));

const StyledPaper = withStyles(theme => ({
   root: {
      flexShrink: 0,
      color: theme.palette.text.secondary
      // marginLeft: theme.spacing(2.5),
   },
   elevation1: {
      boxShadow: '0 0 3px 0 rgba(82, 63, 105, 0.15) !important'
   }
}))(Paper);

function TablePaginationActions(props) {
   const classes = useStyles1();
   const theme = useTheme();
   const { count, page, rowsPerPage, onChangePage } = props;

   // function handleFirstPageButtonClick(event) {
   //   onChangePage(event, 0);
   // }

   function handleBackButtonClick(event) {
      onChangePage(event, page - 1);
   }

   function handleNextButtonClick(event) {
      onChangePage(event, page + 1);
   }

   // function handleLastPageButtonClick(event) {
   //   onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
   // }

   return (
      <div className={classes.root}>
         {/* <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton> */}
         <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="Previous Page"
         >
            {theme.direction === 'rtl' ? (
               <KeyboardArrowRight />
            ) : (
               <KeyboardArrowLeft />
            )}
         </IconButton>
         <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="Next Page"
         >
            {theme.direction === 'rtl' ? (
               <KeyboardArrowLeft />
            ) : (
               <KeyboardArrowRight />
            )}
         </IconButton>
         {/* <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton> */}
      </div>
   );
}

TablePaginationActions.propTypes = {
   count: PropTypes.number.isRequired,
   onChangePage: PropTypes.func.isRequired,
   page: PropTypes.number.isRequired,
   rowsPerPage: PropTypes.number.isRequired
};

// const rows = [
//   createData("Cupcake", 305, 3.7),
//   createData("Donut", 452, 25.0),
//   createData("Eclair", 262, 16.0),
//   createData("Frozen yoghurt", 159, 6.0),
//   createData("Gingerbread", 356, 16.0),
//   createData("Honeycomb", 408, 3.2),
//   createData("Ice cream sandwich", 237, 9.0),
//   createData("Jelly Bean", 375, 0.0),
//   createData("KitKat", 518, 26.0),
//   createData("Lollipop", 392, 0.2),
//   createData("Marshmallow", 318, 0),
//   createData("Nougat", 360, 19.0),
//   createData("Oreo", 437, 18.0),
// ].sort((a, b) => (a.calories < b.calories ? -1 : 1));

const useStyles2 = makeStyles(theme => ({
   root: {
      width: '100%',
      padding: '16px 30px',
      // marginBottom: 25,
      height: '100%'
      // minHeight: "400px",
   },
   table: {
      width: '100%',
      height: 'calc(100vh - 450px)'
   },
   tableWrapper: {
      // height: "calc(100vh - 381px)",
      // overflowX: "auto",
      // transform: "translateY(1px)",
   },
   divScroll: {
      height: 'calc(100vh - 450px)',
      overflow: 'auto'
   },
   title: {
      fontFamily: 'Prompt',
      fontSize: '20px',
      fontWeight: '600',
      color: '#012875'
   },
   borrowedName: {
      fontFamily: 'Prompt',
      fontSize: '14px',
      fontWeight: 500,
      color: '#333333',
      marginBottom: 2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      display: '-webkit-box',
      maxWidth: '100%',
      boxOrient: 'vertical'
   },
   borrowedNameSub: {
      fontFamily: 'Prompt',
      fontSize: '12px',
      fontWeight: 500,
      color: '#828282',
      marginBottom: -8,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineClamp: 2,
      display: '-webkit-box',
      maxWidth: '100%',
      boxOrient: 'vertical'
   },
   borrowedCount: {
      fontFamily: 'Poppins',
      fontSize: '20px',
      fontWeight: '600',
      color: '#ff5337',
      marginBottom: 2
   },
   borrowedCountSub: {
      fontFamily: 'Prompt',
      fontSize: '12px',
      fontWeight: 500,
      color: '#b5b5c3',
      marginBottom: -8
   },
   coverImage: {
      width: '66px',
      height: '100px',
      backgroundColor: '#000000',
      margin: '12px 0 12px 12px',
      '-webkit-filter': 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))',
      filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))'
   }
}));

export function BorrowedTop20({ data, loaded }) {
   const classes = useStyles2();
   const [rows, setRows] = useState([]);
   const [page, setPage] = React.useState(0);
   // const rowsPerPage = 10;
   const rowsPerPage = 5;

   const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

   function handleChangePage(event, newPage) {
      setPage(newPage);
   }

   useEffect(() => {
      setRows(data);
      // setRows([]);
      // setRows([
      //   {
      //     contentUID: "02891138-3774-4690-aac0-0152d77613cc",
      //     rentCount: 4,
      //     title: "คัมภีร์เจ้าสัวแสนล้าน เจริญ สิริวัฒนภักดี",
      //     authorName: "ทศ คณนาพร",
      //     coverImage:
      //       "https://s3-ap-southeast-1.amazonaws.com/htiebook.content/images/coverImages/medium/02891138-3774-4690-aac0-0152d77613cc.jpg",
      //     fileType: "epub"
      //   },
      //   {
      //     contentUID: "81ff605a-91c7-40cf-8bfc-4ea8edb6de65",
      //     rentCount: 3,
      //     title: "คนยิ่งใหญ่ ต้อง “โง่” และ “แพ้” ให้เป็น",
      //     authorName: "ทศ คณนาพร",
      //     coverImage:
      //       "https://s3-ap-southeast-1.amazonaws.com/htiebook.content/images/coverImages/medium/81ff605a-91c7-40cf-8bfc-4ea8edb6de65.jpg",
      //     fileType: "pdf"
      //   },
      //   {
      //     contentUID: "34767ce3-a11a-4741-a8ce-29721aad45ef",
      //     rentCount: 3,
      //     title: "คัมภีร์สร้าง “ผู้นำ” สำเร็จไร้ขีดจำกัด",
      //     authorName: "พันโท อานันท์ ชินบุตร",
      //     coverImage:
      //       "https://s3-ap-southeast-1.amazonaws.com/htiebook.content/images/coverImages/medium/34767ce3-a11a-4741-a8ce-29721aad45ef.jpg",
      //     fileType: "pdf"
      //   },
      //   {
      //     contentUID: "1bc70451-37b8-4fff-8e15-52ee30e06b2b",
      //     rentCount: 3,
      //     title: "เลือกเกิดไม่ได้ แต่เราเลือกที่จะทำชีวิตให้ดีและยิ่งใหญ่ได้",
      //     authorName: "ทศ คณนาพร",
      //     coverImage:
      //       "https://s3-ap-southeast-1.amazonaws.com/htiebook.content/images/coverImages/medium/1bc70451-37b8-4fff-8e15-52ee30e06b2b.jpg",
      //     fileType: "pdf"
      //   },
      //   {
      //     contentUID: "16d4b872-74dd-44e7-a991-5c3c35db67b5",
      //     rentCount: 2,
      //     title: "ซีอีโอสอนลูกให้เป็นมังกร",
      //     authorName: "ภัทระ ฉลาดแพทย์",
      //     coverImage:
      //       "https://s3-ap-southeast-1.amazonaws.com/htiebook.content/images/coverImages/medium/16d4b872-74dd-44e7-a991-5c3c35db67b5.jpg",
      //     fileType: "epub"
      //   },
      //   {
      //     contentUID: "f2954fc6-9772-4752-8826-ef39f7860bc5",
      //     rentCount: 2,
      //     title: "คมคิด พิชิตเงินล้าน (พิสูจน์มาแล้วจากอัจฉริยะและมหาเศรษฐีโลก)",
      //     authorName: "ทศ คณนาพร",
      //     coverImage:
      //       "https://s3-ap-southeast-1.amazonaws.com/htiebook.content/images/coverImages/medium/f2954fc6-9772-4752-8826-ef39f7860bc5.jpg",
      //     fileType: "pdf"
      //   },
      //   {
      //     contentUID: "01a09257-18ec-4fa4-8cfd-5f80be5f5f8b",
      //     rentCount: 1,
      //     title: "ขงจื๊อสอนคนธรรมดาให้เป็นยอดคน (ฉบับของขวัญ)",
      //     authorName: "ภัทระ ฉลาดแพทย์ และธีระวุฒิ ปัญญา",
      //     coverImage:
      //       "https://s3-ap-southeast-1.amazonaws.com/htiebook.content/images/coverImages/medium/01a09257-18ec-4fa4-8cfd-5f80be5f5f8b.jpg",
      //     fileType: "pdf"
      //   }
      // ]);
   }, [data]);

   // function handleChangeRowsPerPage(event) {
   //   setRowsPerPage(parseInt(event.target.value, 10));
   // }

   return (
      <StyledPaper className={classes.root}>
         <div className={classes.tableWrapper}>
            <p className={classes.title}>20 อีบุ๊กยอดนิยม</p>
            {/*<Table className={classes.table}>
  <TableBody>*/}
            {rows && rows.length === 0 ? (
               loaded ? (
                  <div
                     style={{
                        textAlign: 'center',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '47vh',
                        flexFlow: 'column'
                        // position: "absolute"
                     }}
                  >
                     <Empty description={false} />
                     <p
                        style={{
                           color: '#012875',
                           marginBottom: '0',
                           fontSize: '20px',
                           fontWeight: '600'
                        }}
                     >
                        ไม่มีข้อมูลสื่อ
                     </p>
                  </div>
               ) : (
                  <Spinner forDashboard={true} />
               )
            ) : (
               <div className={`${classes.divScroll} noMarginRow`}>
                  {rows.map((row, index) => (
                     <Row
                        key={index}
                        style={{
                           height: '121px',
                           alignItems: 'center',
                           margin: '0 !important'
                        }}
                     >
                        <Col align="left" span={2} style={{ padding: '0' }}>
                           <p className={classes.borrowedName}>
                              {page * rowsPerPage + index + 1}
                           </p>
                        </Col>
                        <Col align="left" span={16} style={{ padding: '10px' }}>
                           <Grid container spacing={3}>
                              <img
                                 src={
                                    row && row.coverImage
                                       ? row.coverImage
                                       : '/media/members/placeholder-image.png'
                                 }
                                 className={classes.coverImage}
                                 alt=""
                              />
                              <Grid item xs={8}>
                                 <p className={classes.borrowedName}>
                                    {row.title}
                                 </p>
                                 <p className={classes.borrowedNameSub}>
                                    {row.authorName}
                                 </p>
                              </Grid>
                           </Grid>
                        </Col>
                        <Col
                           align="right"
                           span={6}
                           style={{ padding: '16px 0' }}
                        >
                           <p className={classes.borrowedCount}>
                              {row.rentCount ? row.rentCount : '-'}
                           </p>
                           <p className={classes.borrowedCountSub}>
                              จำนวนที่ถูกยืม
                           </p>
                        </Col>
                     </Row>
                  ))}
               </div>
            )}

            {/*emptyRows > 0 && (
              <TableRow style={{ height: 90 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )*/}
            {/*</TableBody>*/}
            {/*<TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "Rows per page" },
                  native: true
                }}
                onChangePage={handleChangePage}
                // onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
              </TableFooter>
        </Table>*/}
         </div>
      </StyledPaper>
   );
}
