/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles, withStyles, lighten } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import moment from "moment";
import { OverlayTrigger, Tooltip as Tooltip1 } from "react-bootstrap";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { DashboardMini } from "../../upload/DashboardMini";
import axios from "axios";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { videosSlice } from "../../../_redux/videos/videosSlice";
import ReactPlayer from "react-player";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { UploadInformationDialog } from "../../upload/upload-information-dialog/UploadInformationDialog";

const CancelToken = axios.CancelToken;
const actions = videosSlice.actions;

const useModalStyles = makeStyles(() => ({
  body: {
    textAlign: "center",
  },
  title: {
    fontSize: "19px",
    color: "#012875",
    fontWeight: "600",
    textAlign: "left",
  },
  titleError: {
    fontSize: "20px",
    fontWeight: 600,
    color: "#d7042b",
    marginBottom: "40px",
  },
  subtitle: {
    fontFamily: "Prompt",
    fontSize: "16px",
  },

  caution: { fontSize: "14px", color: "#D7042B" },
}));

const useStyles = makeStyles(() => ({
  head: {
    fontSize: "20px",
    fontWeight: "600",
    margin: "16px auto 0",
    color: "#012875",
  },
  blue_p: {
    fontSize: 20,
    fontWeight: 600,
    color: "#012875",
    marginBottom: "6px",
  },
  blue_p_small14: {
    fontSize: 14,
    fontWeight: 400,
    color: "#012875",
    display: "inline-block",
  },
  blue_p_small: {
    fontSize: 12,
    fontWeight: 400,
    color: "#012875",
    display: "inline-block",
  },

  body: { margin: "auto" },
  divSuccess: {
    textAlign: "center",
    width: "340px",
    height: "360px",
    borderRadius: "10px",
    backgroundColor: "#fff",
  },
  divFileModal: {
    width: "800px",
    height: "700px",
    borderRadius: "10px",
    backgroundColor: "#fff",
  },

  center: {
    textAlign: "center",
  },

  dropzoneTitle: { marginBottom: "0px", fontSize: "16px" },
  dropzoneSubTitle: { marginBottom: "36px", fontSize: "14px" },

  information: {
    backgroundColor: "#012875",
    width: 16,
    height: 16,
    borderRadius: 8,
    textAlign: "center",
    margin: "0 0 0 6px",
    display: "inline-block",
    "&:hover": { cursor: "pointer" },
  },
  progressUpload: {
    margin: "3px 13px 47px",
    borderRadius: "5px",
  },
  uploadedImg: {
    padding: "16px",
    height: "197px",
    marginBottom: "43px",
    maxWidth: "100%",
  },
}));

export const VideoEditFileAndCover = ({
  videoOrigin,
  videoValue,
  setVideoValue,
  setVideoDetail,
  contentUIDForUpdate,
  rentalPeriod,
  isPrivate,
  fixStatus,
  modalType,
  saveDetail,
  onHide,
  contentDetail,
  coverDetail,
  outSideFilePost,
  outSideCoverPost,
  fixFileOnly,
  ffmpeg,
  getMediaInfo,
}) => {
  const [filePost, setFilePost] = useState(null);
  const [coverPost, setCoverPost] = useState(null);

  const [source, setSource] = useState(
    Object.assign({}, CancelToken.source(), { key: 0 })
  );
  const [token, setToken] = useState(source.token);

  useEffect(() => {
    setToken(source.token);
  }, [source]);

  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(0);
  const [total, setTotal] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const [loaded1, setLoaded1] = useState(0);
  const [total1, setTotal1] = useState(0);
  const [percentage1, setPercentage1] = useState(0);

  const [informationDialogType, setInformationDialogType] = useState(null);
  const [informationDialogMessage, setInformationDialogMessage] = useState(
    undefined
  );
  const [storageUsedNow, setStorageUsedNow] = useState(0);

  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadingCover, setUploadingCover] = useState(false);

  const [fileList, setFileList] = useState([]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  useEffect(() => {
    if (Array.isArray(fileList) && fileList.length === 0) {
      setSuccessCoverUpload(false);
    }
  }, [fileList]);

  const [canUpload, setCanUpload] = useState(false);
  const [storageLeft, setStorageLeft] = useState(0);
  const [fileUsed, setFileUsed] = useState(0);
  const [fileName, setFileName] = useState(null);

  const [successFileUpload, setSuccessFileUpload] = useState(false);
  const [failedFileUpload, setFailedFileUpload] = useState(false);

  const [successCoverUpload, setSuccessCoverUpload] = useState(false);
  const [failedCoverUpload, setFailedCoverUpload] = useState(false);

  const [numPagesForBeforeUpdate, setNumPagesForBeforeUpdate] = useState(0);
  const [coverName, setCoverName] = useState(null);
  const [coverImg, setCoverImg] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const { currentState3 } = useSelector(
    (state) => ({
      currentState3: state.upload,
    }),
    shallowEqual
  );
  const BorderLinearProgress = withStyles({
    root: {
      height: 10,
      backgroundColor: lighten("#ff6c5c", 0.5),
    },
    bar: {
      borderRadius: 20,
      backgroundColor: "#ff6c5c",
    },
  })(LinearProgress);

  useEffect(() => {
    setFilePost(outSideFilePost);
  }, [outSideFilePost]);

  useEffect(() => {
    setCoverPost(outSideCoverPost);
  }, [outSideCoverPost]);

  useEffect(() => {
    if (filePost) {
      if (modalType === "content" || modalType === "both") {
        setSuccessFileUpload(true);
      }
    } else {
      setFilePost(null);
    }

    if (coverPost) {
      if (modalType === "cover" || modalType === "both") {
        setCoverImg(URL.createObjectURL(coverPost));
        setSuccessCoverUpload(true);
      }
    } else {
      setCoverImg(null);
    }
  }, [filePost, coverPost, modalType]);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    accept: ".mp4",
    noClick: true,
    noKeyboard: true,
    maxSize: canUpload ? storageLeft : 0,
    onDropRejected: (e) => onDropRejected(e),
    onDropAccepted: (e) => onDropAccepted(e),
  });

  const {
    getRootProps: getRootProps1,
    getInputProps: getInputProps1,
    open: open1,
    acceptedFiles: acceptedFiles1,
  } = useDropzone({
    accept: modalType === "content" ? ".mp4" : ".png, .jpg, .jpeg",
    noClick: true,
    noKeyboard: true,
    maxSize: canUpload ? storageLeft : 0,
    onDropRejected: (e) => onDropRejected(e),
    onDropAccepted: (e) => {
      if (modalType === "content") {
        onDropAccepted(e);
      }
    },
  });

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPagesForBeforeUpdate(parseInt(numPages, 10));
  };

  const onUploadCoverImage = (e) => {
    reqCoverFunc(e);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const uploadImageMemo = useMemo(() => {
    return (
      <div className="edit-cover">
        <ImgCrop
          aspect={16 / 9}
          modalTitle="ตัดรูปภาพด้วยอัตราส่วน 16 : 9"
          modalOk="บันทึก"
          modalCancel="ยกเลิก"
          accept="image/png, image/jpeg, image/jpg"
        >
          <Upload
            customRequest={onUploadCoverImage}
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
          >
            {fileList.length < 1 && <PlusOutlined />}
          </Upload>
        </ImgCrop>
      </div>
    );
  }, [onUploadCoverImage, onChange, fileList, onPreview]);

  useEffect(() => {
    const reqFunc = async () => {
      if (acceptedFiles && acceptedFiles.length) {
        setUploadingFile(true);
        const fileToUpload = acceptedFiles[0];
        if (fileToUpload) {
          getMediaInfo(ffmpeg, fileToUpload);
          setFileName(fileToUpload.name);
          const req = await axios.post(`/admin/private/contents/file`, {
            fileName: fileToUpload.name,
            contentUID: contentUIDForUpdate ? contentUIDForUpdate : undefined,
          });
          if (req.data.status && req.data.data) {
            const urlToUpload = req.data.data.url;
            const fieldsToUpload = req.data.data.fields;
            // const UID = req.data.data.contentUID;
            // setContentUIDForUpdate(UID);

            var formData = new FormData();
            const entries = Object.entries(fieldsToUpload);
            for (const [key, val] of entries) {
              formData.append(key, val);
            }
            formData.append("file", fileToUpload);
            axios({
              method: "post",
              url: urlToUpload,
              data: formData,
              onUploadProgress: (progressEvent) => {
                if (progressEvent) {
                  setLoaded((progressEvent.loaded / 1000).toFixed(2));
                  setTotal((progressEvent.total / 1000).toFixed(2));
                  setPercentage(
                    (
                      (100 * progressEvent.loaded) /
                      progressEvent.total
                    ).toFixed()
                  );
                }
              },
              cancelToken: token,
            })
              .then(() => {
                setFilePost(fileToUpload);
                setUploadingFile(false);
                setSuccessFileUpload(true);
                setLoaded(0);
                setTotal(0);
                setPercentage(0);
              })
              .catch(() => {
                setFilePost(fileToUpload);
                setUploadingFile(false);
                setFailedFileUpload(true);
                setLoaded(0);
                setTotal(0);
                setPercentage(0);
              });
          }
        }
      }
    };
    reqFunc();
  }, [acceptedFiles]);

  const reqCoverFunc = async (e) => {
    if (e) {
      setUploadingCover(true);
      const fileToUpload = e.file;
      if (fileToUpload) {
        setCoverName(fileToUpload.name);
        const req = await axios.post(`/admin/private/contents/file`, {
          fileName: fileToUpload.name,
          contentUID: contentUIDForUpdate ? contentUIDForUpdate : undefined,
        });
        if (req.data.status && req.data.data) {
          const urlToUpload = req.data.data.url;
          const fieldsToUpload = req.data.data.fields;
          // const UID = req.data.data.contentUID;
          // setContentUIDForUpdate(UID);

          var formData = new FormData();
          const entries = Object.entries(fieldsToUpload);
          for (const [key, val] of entries) {
            formData.append(key, val);
          }
          formData.append("file", fileToUpload);
          axios({
            method: "post",
            url: urlToUpload,
            data: formData,
            onUploadProgress: (progressEvent) => {
              if (progressEvent) {
                // setLoaded1((progressEvent.loaded / 1000).toFixed(2));
                // setTotal1((progressEvent.total / 1000).toFixed(2));
                // setPercentage1(((100 * progressEvent.loaded) / progressEvent.total).toFixed());
                e.onProgress(
                  {
                    percent: (
                      (100 * progressEvent.loaded) /
                      progressEvent.total
                    ).toFixed(),
                  },
                  e.file
                );
              }
            },
            cancelToken: token,
          })
            .then((response) => {
              setCoverPost(fileToUpload);
              setUploadingCover(false);
              setSuccessCoverUpload(true);
              setLoaded1(0);
              setTotal1(0);
              setPercentage1(0);
              e.onSuccess(response, e.file);
            })
            .catch(() => {
              setCoverPost(fileToUpload);
              setUploadingCover(false);
              setFailedCoverUpload(true);
              setLoaded1(0);
              setTotal1(0);
              setPercentage1(0);
            });
        }
      }
    }
  };

  // useEffect(() => {
  //   reqCoverFunc();
  // }, [acceptedFiles1]);

  useEffect(() => {
    if (currentState3.storageData && currentState3.titleData) {
      var storage =
        (currentState3.storageData?.totalStorage *
          currentState3.storageData?.percentage) /
        100;
      let storageLeft_ = currentState3.storageData.totalStorage - storage; //currentState3.storageData.usedStorage;
      let titleLeft =
        currentState3.titleData.totalTitle - currentState3.titleData.usedTitle;
      if (!isNaN(storageLeft_) && storageLeft_ > 0) {
        //} && titleLeft > 0) {
        setStorageLeft(storageLeft_ * 1000000000 - storageUsedNow);
        setCanUpload(true);
      }
    }
    setSource(Object.assign({}, CancelToken.source(), { key: source.key + 1 }));
  }, [currentState3.storageData, currentState3.titleData]);

  const onDropAccepted = (e) => {
    setSource(Object.assign({}, CancelToken.source(), { key: source.key + 1 }));
    if (e && Array.isArray(e) && e.length > 0) {
      if (
        currentState3.storageData.usedStorage + e[0].size / 1000000 >
        currentState3.storageData.totalStorage
      ) {
        openInformationDialog("error", "larger");
        setStorageUsedNow(currentState3.storageData.usedStorage);
        setSuccessFileUpload(false);
        setFailedFileUpload(false);
        source.cancel("Operation canceled by the user.");
      } else {
        setStorageLeft(storageLeft - e[0].size);
        setFileUsed(e[0].size);
        setStorageUsedNow(storageUsedNow + e[0].size);
      }
    }
  };

  const onDropRejected = (e) => {
    setSource(Object.assign({}, CancelToken.source(), { key: source.key + 1 }));
    if (e && Array.isArray(e) && e.length > 0) {
      if (e[0].errors && Array.isArray(e[0].errors) && e[0].errors.length > 0) {
        if (e[0].errors[0].code) {
          openInformationDialog("error", e[0].errors[0].message);
        }
      }
    }
  };

  useEffect(() => {
    if (modalType === "content" || modalType === "cover") {
      setSuccessFileUpload(true);
      if (modalType === "content" && contentDetail) {
        setFilePost(contentDetail.file);
      } else if (modalType === "cover" && coverDetail) {
        setFilePost(coverDetail.file);
      }
    }
    setSource(Object.assign({}, CancelToken.source(), { key: source.key + 1 }));
  }, [modalType]);

  const openInformationDialog = (params, message) => {
    if (params === "error") {
      setInformationDialogType("error");
      setInformationDialogMessage(message);
    } else if (params === "content") {
      setInformationDialogType("left");
    } else if (params === "cover") {
      setInformationDialogType("right");
    }
  };

  const reqDeleteFunc = async (name, uploading) => {
    const req = await axios.delete(`/admin/private/contents/file`, {
      data: { fileName: name, contentUID: contentUIDForUpdate },
    });
    if (req.data.status) {
      reqCancelFunc(uploading);
    }
  };

  const reqCancelFunc = (uploading) => {
    if (!uploading) {
      onHide();
    }
    setTimeout(() => {
      if (!uploading) {
        setStorageUsedNow(storageUsedNow - fileUsed);
      }
      setSuccessFileUpload(false);
      setFailedFileUpload(false);
      setCoverPost(null);
      setFilePost(null);
      if (modalType === "content") {
        setFileName(null);
      }
      if (modalType === "cover") {
        setCoverName(null);
      }
      setLoaded1(0);
      setTotal1(0);
      setPercentage1(0);
      setUploadingFile(false);
    }, 200);
  };

  const hideFixFileModal = (uploading) => {
    if (modalType === "content" && fileName) {
      reqDeleteFunc(fileName, uploading);
    } else if (modalType === "cover" && coverName) {
      reqDeleteFunc(coverName, uploading);
    } else if (modalType === "both") {
      if (fileName) {
        reqDeleteFunc(fileName, uploading);
      }
      if (coverName) {
        reqDeleteFunc(coverName, uploading);
      }
      onHide();
    } else {
      onHide();
    }
  };

  const styles = useStyles();
  const modalClasses = useModalStyles();

  const [isImgError, setIsImgError] = useState(0);
  const imgError = (image) => {
    if (isImgError === 0) {
      setIsImgError(1);
    }
    image.onError = "";
    return true;
  };
  const imgErrorSrc = "/media/members/placeholder-image.png";
  const [encryptedFileModal, setEncryptedFileModal] = useState(false);
  const onEncryptedFileModalHide = () => {
    setEncryptedFileModal(false);
    hideFixFileModal(true);
  };
  const onImgErrorModalHide = () => {
    setIsImgError(2);
    hideFixFileModal(true);
  };

  const modalFixFile = (modalType_) => (
    <div className="col-6">
      <h3>{modalType_ === "content" ? "อัปโหลดไฟล์" : "อัปโหลดไฟล์หน้าปก"}</h3>
      <div className="flex mb">
        {modalType_ === "content" ? (
          <div className="text-blue">*รองรับไฟล์ mp4 เท่านั้น</div>
        ) : (
          <div className="text-blue">
            *รองรับไฟล์ jpg, jpeg หรือ png เท่านั้น และกรุณาใช้อัตราส่วน 16:9
            เนื่องจากมีการใช้สัดส่วนนี้มากที่สุดในโปรแกรมเล่นวิดีโอและหน้าตัวอย่างของวิดีโอ
          </div>
        )}
      </div>
      {/* {modalType_ === "content" ? (
        <p className={styles.information} onClick={() => openInformationDialog(modalType_)}>
          <img src="/media/members/information.svg" style={{ marginBottom: "5px" }} alt="" />
        </p>
      ) : (
        ""
      )} */}
      {(modalType_ === "content" &&
        filePost &&
        (successFileUpload || failedFileUpload)) ||
      (modalType_ === "cover" &&
        coverImg &&
        (successCoverUpload || failedCoverUpload)) ? (
        <div
          style={{
            border: "1px dashed",
            textAlign: "center",
            borderRadius: "10px",
          }}
        >
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip1 id="quick-user-tooltip">
                {modalType_ === "content" ? "ลบไฟล์สื่อ" : "ลบไฟล์หน้าปก"}
              </Tooltip1>
            }
          >
            <a
              className="btn btn-icon btn-light btn-hover-danger btn-sm"
              style={{
                marginLeft: "0.75rem",
                position: "absolute",
                right: "22px",
              }}
              onClick={() => {
                const reqFunc = async () => {
                  const req = await axios.delete(
                    `/admin/private/contents/file`,
                    {
                      data: {
                        fileName:
                          modalType_ === "content" ? fileName : coverName,
                        contentUID: contentUIDForUpdate,
                      },
                    }
                  );
                  if (req.data.status) {
                    if (modalType_ === "content") {
                      setStorageUsedNow(storageUsedNow - fileUsed);
                      setSuccessFileUpload(false);
                      setFailedFileUpload(false);
                      setFileName(null);
                    } else {
                      setSuccessCoverUpload(false);
                      setFailedCoverUpload(false);
                      setCoverName(null);
                      setFileList([]);
                    }
                  }
                };
                reqFunc();
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG src={toAbsoluteUrl("/media/members/delete-button.svg")} />
              </span>
            </a>
          </OverlayTrigger>
          {failedFileUpload ? (
            <>
              <img src="/media/members/upload-file.png" alt="" />
              <div style={{ height: "160px" }}>
                <p className={styles.dropzoneTitle}>
                  ล้มเหลว กรุณาลองใหม่อีกครั้ง
                </p>
              </div>
            </>
          ) : modalType_ === "content" ? (
            <>
              <div
                style={{
                  margin: "auto",
                  padding: "32px 16px 0",
                }}
              >
                <ReactPlayer
                  className="react-player"
                  playing
                  url={[
                    { src: URL.createObjectURL(filePost), type: filePost.type },
                  ]}
                  width="320px"
                  height="250px"
                  controls
                  loop
                />
              </div>
              <div style={{ height: "60px" }}>
                <p
                  className={styles.dropzoneTitle}
                  style={{ marginTop: "16px" }}
                >
                  อัปโหลดไฟล์สำเร็จ
                </p>
              </div>
            </>
          ) : (
            <>
              <img
                className={styles.uploadedImg}
                alt=""
                src={isImgError ? imgErrorSrc : coverImg}
                onError={imgError}
              />
              <div style={{ height: "60px" }}>
                <p className={styles.dropzoneTitle}>อัปโหลดไฟล์หน้าปกสำเร็จ</p>
              </div>
            </>
          )}
        </div>
      ) : modalType_ === "content" ? (
        <div
          {...(modalType_ === "content"
            ? getRootProps({ className: "dropzone" })
            : getRootProps1({ className: "dropzone" }))}
          style={{
            border: "1px dashed",
            textAlign: "center",
            borderRadius: "10px",
          }}
        >
          <input
            {...(modalType_ === "content" ? getInputProps() : getInputProps1())}
          />
          <img src="/media/members/upload-file.png" alt="" />
          {(modalType_ === "content" && uploadingFile) ||
          (modalType_ === "cover" && uploadingCover) ? (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip1 id="quick-user-tooltip">
                  {modalType_ === "content" ? "ลบไฟล์สื่อ" : "ลบไฟล์หน้าปก"}
                </Tooltip1>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                style={{
                  marginLeft: "0.75rem",
                  position: "absolute",
                  right: "22px",
                }}
                onClick={() => {
                  source.cancel("Operation canceled by the user.");
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                    src={toAbsoluteUrl("/media/members/delete-button.svg")}
                  />
                </span>
              </a>
            </OverlayTrigger>
          ) : (
            ""
          )}
          <div style={{ height: "160px" }}>
            {(modalType_ === "content" && uploadingFile) ||
            (modalType_ === "cover" && uploadingCover) ? (
              <>
                <p className={styles.dropzoneTitle}>
                  รอสักครู่ ระบบกำลังอัปโหลดไฟล์...
                </p>
                <div
                  className="row"
                  style={{ margin: "40px 0.5px 0", color: "#012875" }}
                >
                  <p
                    className="col-6"
                    style={{
                      fontSize: "12px",
                      textAlign: "left",
                      margin: "0",
                    }}
                  >
                    {modalType_ === "content" ? loaded : loaded1}KB/
                    {modalType_ === "content" ? total : total1}KB
                  </p>
                  <p
                    className="col-6"
                    style={{
                      fontSize: "14px",
                      textAlign: "end",
                      margin: "0",
                    }}
                  >
                    {modalType_ === "content"
                      ? parseInt(percentage, 10)
                      : parseInt(percentage1, 10)}
                    %
                  </p>
                </div>
                <BorderLinearProgress
                  className={styles.progressUpload}
                  variant="determinate"
                  color="secondary"
                  value={modalType_ === "content" ? percentage : percentage1}
                />
              </>
            ) : (
              <>
                <h4>Drag a file here</h4>
                <div>or browse your computer</div>
                <button
                  type="button"
                  className="acceptButton"
                  onClick={modalType_ === "content" ? open : open1}
                >
                  Browse
                </button>
              </>
            )}
          </div>
        </div>
      ) : (
        uploadImageMemo
      )}
    </div>
  );

  const isButtonEnabled = (modalType_) => {
    switch (modalType_) {
      case "content":
        return filePost && successFileUpload && !failedFileUpload;
      case "cover":
        return coverImg && successCoverUpload && !failedCoverUpload;
      case "both":
        return (
          coverImg &&
          filePost &&
          successFileUpload &&
          !failedFileUpload &&
          successCoverUpload &&
          !failedCoverUpload
        );
      default:
        break;
    }
  };

  const fixFileOnlyReq = async () => {
    const req = await axios.put(
      `/admin/private/contents/${contentUIDForUpdate}/verify`,
      {
        rawContent: modalType === "content" || modalType === "both",
        coverImage: modalType === "cover" || modalType === "both",
      }
    );
    if (req.data.status && req.data.data) {
      setSuccessModal(true);
      dispatch(
        actions.videoUpdated({
          id: req.data.data.contentUID,
          video: req.data.data,
        })
      );
    }
  };

  return (
    <>
      <Modal
        backdrop="static"
        size="lg"
        show={modalType}
        onHide={() => hideFixFileModal()}
        aria-labelledby="example-modal-sizes-title-lg"
        style={{ marginTop: "27px" }}
        dialogClassName={styles.divFileModal}
      >
        <Modal.Body>
          <DashboardMini
            storageUsedNow={storageUsedNow}
            storageData={currentState3.storageData}
            titleData={currentState3.titleData}
          />
          <div className="uploadFileCover">
            {modalType === "both" ? (
              <div className="row">
                {modalFixFile("content")}
                {modalFixFile("cover")}
              </div>
            ) : (
              modalFixFile(modalType)
            )}
          </div>
          <div className="divButton justify-content-center">"
            <button
              className="denyButton"
              type="button"
              onClick={() => {
                if (uploadingFile) {
                  source.cancel("Operation canceled by the user.");
                  reqCancelFunc(uploadingFile);
                } else {
                  hideFixFileModal();
                }
              }}
            >
              ยกเลิก
            </button>
            <button
              className="acceptButton"
              onClick={() => {
                if (saveDetail) {
                  saveDetail(
                    {
                      fileName: modalType === "content" ? fileName : coverName,
                      updatedDate: moment().unix(),
                      file: modalType === "content" ? filePost : coverPost,
                    },
                    modalType,
                    numPagesForBeforeUpdate
                  );
                } else if (fixFileOnly) {
                  fixFileOnlyReq();
                }
              }}
              disabled={!isButtonEnabled(modalType)}
            >
              บันทึก
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {informationDialogType === "error" ||
      informationDialogType === "left" ||
      informationDialogType === "right" ? (
        <UploadInformationDialog
          show={true}
          onHide={() => {
            setInformationDialogType(null);
          }}
          isError={informationDialogType === "error"}
          isLeft={informationDialogType === "left"}
          errorMessage={informationDialogMessage}
        />
      ) : (
        ""
      )}
      <Modal
        backdrop="static"
        size="md"
        show={successModal}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        style={{ marginTop: "27px" }}
        dialogClassName={styles.divSuccess}
      >
        <Modal.Body className={styles.body}>
          <div>
            <img src="/media/bg/success.svg" alt="" />
          </div>
          <div className="divText-modal">เรียบร้อยแล้ว</div>
          <div>
            <button
              className="acceptButton "
              onClick={() => {
                onHide();
              }}
            >
              ตกลง
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        show={encryptedFileModal}
        onHide={onEncryptedFileModalHide}
        aria-labelledby="example-modal-sizes-title-lg"
        style={{ margin: "3% auto" }}
        size="md"
      >
        <Modal.Body className={modalClasses.body}>
          <>
            <img src="/media/members/image-modal.svg" alt="" />
            <div className={modalClasses.titleError}>
              ไฟล์ที่มีการตั้งรหัสผ่านไม่สามารถอัปโหลดได้
            </div>
            <div className="mt-2">
              <button
                className="acceptButton"
                type="submit"
                onClick={() => {
                  onEncryptedFileModalHide();
                }}
              >
                ตกลง
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        show={isImgError === 1}
        onHide={onImgErrorModalHide}
        aria-labelledby="example-modal-sizes-title-lg"
        style={{ margin: "3% auto" }}
        size="md"
      >
        <Modal.Body className={modalClasses.body}>
          <>
            <img src="/media/members/image-modal.svg" alt="" />
            <div className={modalClasses.titleError}>
              ไฟล์รูปมีปัญหาไม่สามารถอัปโหลดได้
            </div>
            <div className="mt-2">
              <button
                className="acceptButton"
                type="submit"
                onClick={() => {
                  onImgErrorModalHide();
                }}
              >
                ตกลง
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
