// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { makeStyles } from '@material-ui/core/';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../../../_metronic/_helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { Menu, Dropdown } from 'antd';
import { ClientProcessStatus } from '../../../../../../../interface/company';

export function ActionsColumnFormatter(
    row,

    {
        openEditCompanyDialog,
        openEditColorDialog,
        openDeleteCompanyDialog,
        openViewCompanyDialog,
        openViewAsPage,
        openCompanyClearStoreDialog,
        openCompanyClearMember,
        openExtendPeriodDialog
    }
) {
    const clearDataMenu = (
        <Menu>
            <Menu.Item
                onClick={() => {
                    openCompanyClearStoreDialog(row.clientName, row.clientUID);
                }}
            >
                เคลียร์สโตร์
            </Menu.Item>
            <Menu.Item
                onClick={() => {
                    openCompanyClearMember(row.clientName, row.clientUID);
                }}
            >
                เคลียร์สมาชิก
            </Menu.Item>
        </Menu>
    );

    const actionColumn = (
        <>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="quick-user-tooltip">View as</Tooltip>}
            >
                <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm"
                    onClick={() => openViewAsPage(row.clientUID)}
                >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                            src={toAbsoluteUrl(
                                '/media/members/view-qrcode-register.svg'
                            )}
                        />
                    </span>
                </a>
            </OverlayTrigger>{' '}
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="quick-user-tooltip">แก้ไขสี</Tooltip>}
            >
                <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm"
                    onClick={() => openEditColorDialog(row.clientUID)}
                >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                            src={toAbsoluteUrl('/media/members/edit-color.svg')}
                        />
                    </span>
                </a>
            </OverlayTrigger>{' '}
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="quick-user-tooltip">แก้ไของค์กร</Tooltip>}
            >
                <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm"
                    onClick={() => openEditCompanyDialog(row.clientUID)}
                >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                            src={toAbsoluteUrl(
                                '/media/members/edit-button.svg'
                            )}
                        />
                    </span>
                </a>
            </OverlayTrigger>{' '}
            {row.status === 4 ? (
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip id="quick-user-tooltip">ลบองค์กร</Tooltip>
                    }
                >
                    <a
                        className="btn btn-icon btn-light btn-hover-danger btn-sm"
                        onClick={() => {
                            console.log(row.clientUID);
                            openDeleteCompanyDialog(
                                row.clientName,
                                row.clientUID
                            );
                        }}
                    >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG
                                src={toAbsoluteUrl(
                                    '/media/members/delete-button.svg'
                                )}
                            />
                        </span>
                    </a>
                </OverlayTrigger>
            ) : (
                ''
            )}
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="quick-user-tooltip">การต่ออายุ</Tooltip>}
            >
                <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm"
                    onClick={() => {
                        openExtendPeriodDialog(row.clientUID, row.clientName);
                    }}
                >
                    <span className="">
                        <SVG
                            src={toAbsoluteUrl('/media/members/calendar.svg')}
                        />
                    </span>
                </a>
            </OverlayTrigger>
            {row.status === 4 || moment().unix() > row.expireDate ? (
                <Dropdown
                    overlay={clearDataMenu}
                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                >
                    <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG src={toAbsoluteUrl('/media/members/erase.svg')} />
                    </span>
                </Dropdown>
            ) : null}
            <button
                title="View company"
                className="button-description"
                onClick={e => {
                    e.preventDefault();
                    openViewCompanyDialog(row.clientUID);
                }}
            >
                รายละเอียด
            </button>
        </>
    );

    const pendingClearData = () => {
        if (row) {
            switch (row.processStatus) {
                case ClientProcessStatus.Idle:
                    return actionColumn;
                case ClientProcessStatus.ClearMember:
                    return (
                        <div className="clearLoadGroup">
                            <SVG src="/media/svg/icons/Design/ClearLoading.svg" />
                            <div>
                                <div>กำลังเคลียร์สมาชิก</div>
                                <div className="explainText">
                                    ในขณะทีกำเนินการจะไม่สามารถจัดการสโตร์ได้
                                </div>
                            </div>
                        </div>
                    );
                case ClientProcessStatus.ClearStore:
                    return (
                        <div className="clearLoadGroup">
                            <SVG src="/media/svg/icons/Design/ClearLoading.svg" />
                            <div>
                                <div>กำลังเคลียร์สโตร์</div>
                                <div className="explainText">
                                    ในขณะทีกำเนินการจะไม่สามารถจัดการสโตร์ได้
                                </div>
                            </div>
                        </div>
                    );
                default:
                    return actionColumn;
            }
        } else {
            return actionColumn;
        }
    };

    return (
        // row ? (
        <>{pendingClearData()}</>
        // ) : (
        //    ''
        // );
    );
}
