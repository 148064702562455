/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, { useState, useEffect, useRef, createRef } from "react";
import { makeStyles, withStyles, lighten } from "@material-ui/core/";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import validator from "validator";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { DashboardMini } from "../../upload/DashboardMini";
import { uploadSlice } from "../../../_redux/upload/uploadSlice";
import * as actions from "../../../_redux/upload/uploadActions";
import { UploadInformationDialog } from "../../upload/upload-information-dialog/UploadInformationDialog";
import { ClipLoader } from "react-spinners";
import Radio from "@material-ui/core/Radio";
const actions_slice = uploadSlice.actions;
const CancelToken = axios.CancelToken;
const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten("#ff6c5c", 0.5),
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "#ff6c5c",
  },
})(LinearProgress);
const useStyles1 = makeStyles((theme) => ({
  stat: { background: "#F2F2F2", borderRadius: "20px", padding: "20px 0" },
  dropzoneTitle: { marginBottom: "0px", fontSize: "16px" },
  dropzoneSubTitle: { marginBottom: "36px", fontSize: "14px" },
  progress: {
    margin: "13px 0px",
    borderRadius: "5px",
  },

  uploadedImg: {
    border: "1px solid rgba(0, 0, 0, 0.3)",
    width: "100%",
  },
  uploadedImg_edit_loading: {
    width: "100%",
    maxHeight: "555px",
    filter: "brightness(0.7) saturate(0.5)",
  },
  uploadedImg_edit: {
    border: "1px solid rgba(0, 0, 0, 0.3)",
    width: "100%",
    filter: "brightness(0.7) saturate(0.5)",
  },
  row: { display: "flex" },
  col: { margin: "2rem auto 0", display: "inline-block" },
  col_result: { display: "inline-block" },
  p: { marginBottom: "8px", fontSize: "14px" },
  title: {
    color: "#012875",
    fontWeight: 500,
    fontSize: "20px",
    textAlign: "center",
  },
  title_red: {
    fontSize: "20px",
    fontWeight: 600,
    color: "#d7042b",
    textAlign: "center",
  },
  slot: {
    height: "32px",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid black",
  },
  progressUpload: {
    margin: "3px 13px 47px",
    borderRadius: "5px",
  },
  dropDivImg: { margin: "auto", "&:hover": { cursor: "pointer" } },
  dropDivImg_noHover: { margin: "auto" },

  contentBox: { "&:hover": { cursor: "pointer" } },
}));
export const BannerEditForm = ({
  bannerValue,
  createBanner,
  saveBanner,
  onHide,
  setBannerValue,
  forEdit,
}) => {
  const loaderHeight = 50;
  const [uploading, setUploading] = useState(false);
  const [failedUpload, setFailedUpload] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [bannerType, setBannerType] = useState("ebook");
  const [isEditBanner, setIsEditBanner] = useState(false);
  const [loaded, setLoaded] = useState(0);
  const [total, setTotal] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [successUpload, setSuccessUpload] = useState(false);
  const [img, setImg] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [contentUID, setContentUID] = useState(null);
  const bannerTypeList = [
    <option value={"ebook"}>อีบุ๊ก</option>,
    // <option value={"podcast"}>พ็อดคาสท์</option>,
    // <option value={"video"}>วิดีโอ</option>,
    <option value={"link"}>ลิงค์</option>,
    <option value={"normal"}>ทั่วไป</option>,
  ];
  const imgRef = useRef(null);
  const titleRef = createRef(null);
  const authorNameRef = createRef(null);
  const [editingImg, setEditingImg] = useState(false);
  const [bannerLink, setBannerLink] = useState("");
  const [widthImage, setWidthImage] = useState(0);
  const [heightImage, setHeightImage] = useState(0);
  const [firstUpload, setFirstUpload] = useState(false);
  const [source, setSource] = useState(
    Object.assign({}, CancelToken.source(), { key: 0 })
  );
  const token = source.token;
  const [isOver, setOver] = useState(false);
  const [isNoUID, setNoUID] = useState(false);
  const [canEnterForSearch, setCanEnterForSearch] = useState(true);
  const [results, setResults] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [entered, setEntered] = useState(false);
  const [waitingSearch, setWaitingSearch] = useState(false);
  const [isTitleOverflow, setTitleOverflow] = useState(false);
  const [isAuthorNameOverflow, setAuthorNameOverflow] = useState(false);
  const [clickedAccept, setClickedAccept] = useState(false);
  const [imgAreaInvalid, setImgAreaInvalid] = useState(false);
  const [selectedUID, setSelectedUID] = useState(undefined);
  const [licenseType, setLicenseType] = useState(undefined);
  const [informationDialogType, setInformationDialogType] = useState(null);
  const [informationDialogMessage, setInformationDialogMessage] = useState(
    undefined
  );
  const handleBannerTypeChange = (e) => {
    setResults([]);
    setSelectedUID(undefined);
    setBannerType(e.target.value);
    setBannerLink("");
  };
  const handleBannerLinkChange = (e) => {
    setNoUID(false);
    setCanEnterForSearch(true);
    setEntered(false);
    if (Array.isArray(results) && results.length > 0) {
      setResults([]);
      setSelectedUID(undefined);
    }
    setBannerLink(e.target.value);
  };

  // Banners Redux state
  const { error, uploadState } = useSelector(
    (state) => ({
      error: state.banners.error,
      uploadState: state.upload,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (error === "5006") {
      setNoUID(true);
    }
  }, [error]);

  const classes = useStyles1();
  const dispatch = useDispatch();
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: ".png, .jpg, .jpeg",
    noClick: true,
    noKeyboard: true,
    onDropRejected: (e) => onDropRejected(e),
  });
  const openInformationDialog = (params, message) => {
    if (params === "error") {
      setInformationDialogType("error");
      setInformationDialogMessage(message);
    }
  };

  const onDropRejected = (e) => {
    if (e && Array.isArray(e) && e.length > 0) {
      if (e[0].errors && Array.isArray(e[0].errors) && e[0].errors.length > 0) {
        if (e[0].errors[0].code) {
          openInformationDialog("error", e[0].errors[0].message);
        }
      }
    }
  };

  useEffect(() => {
    const reqFunc = async () => {
      if (acceptedFiles && acceptedFiles.length) {
        const fileToUpload = acceptedFiles[0];
        var img = new Image();
        var objectUrl = URL.createObjectURL(fileToUpload);
        img.onload = async function() {
          if (fileToUpload && this.width / this.height === 900 / 360) {
            setUploading(true);
            setFileName(fileToUpload.name);
            if (contentUID) {
              setEditingImg(true);
            }
            const obj = contentUID
              ? { fileName: fileToUpload.name, bannerUID: contentUID }
              : { fileName: fileToUpload.name };
            if (obj) {
              try {
                const req = await axios.post(`/admin/banners/file`, obj);
                if (req.data.status && req.data.data) {
                  const urlToUpload = req.data.data.url;
                  const fieldsToUpload = req.data.data.fields;
                  const contentUIDToUpload = req.data.data.uid;
                  const imgUrl = req.data.data.imageUrl;

                  var formData = new FormData();
                  const entries = Object.entries(fieldsToUpload);
                  for (const [key, val] of entries) {
                    formData.append(key, val);
                  }
                  formData.append("file", fileToUpload);
                  axios({
                    method: "post",
                    url: urlToUpload,
                    data: formData,
                    onUploadProgress: (progressEvent) => {
                      if (progressEvent) {
                        setLoaded((progressEvent.loaded / 1000).toFixed(2));
                        setTotal((progressEvent.total / 1000).toFixed(2));
                        setPercentage(
                          (
                            (100 * progressEvent.loaded) /
                            progressEvent.total
                          ).toFixed()
                        );
                      }
                    },
                    cancelToken: token,
                  })
                    .then(() => {
                      setUploading(false);
                      setContentUID(contentUIDToUpload);
                      setImg(URL.createObjectURL(fileToUpload));
                      setImageUrl(imgUrl);
                      setSuccessUpload(true);
                      setLoaded(0);
                      setTotal(0);
                      setPercentage(0);
                      setFirstUpload(true);
                      return true;
                    })
                    .catch(() => {
                      setFailedUpload(true);
                    });
                }
              } catch (error) {
                if (
                  error.response &&
                  error.response.data.errorCode === "5004"
                ) {
                  setOver(true);
                }
              }
            }
          } else {
            setImgAreaInvalid(true);
          }
          URL.revokeObjectURL(objectUrl);
        };
        img.src = objectUrl;
      }
    };
    reqFunc();
  }, [acceptedFiles]);

  const searchContentFromUID = async (val, type) => {
    if (
      (val && type) ||
      (bannerLink !== "" &&
        canEnterForSearch &&
        (bannerType === "ebook" ||
          bannerType === "video" ||
          bannerType === "podcast"))
    ) {
      setCanEnterForSearch(false);
      setWaitingSearch(true);
      const req = await axios.get(
        `/admin/banners/contents?keyword=${val ||
          bannerLink}&contentType=${type || bannerType}`
      );
      if (req.data.status && req.data.data) {
        setEntered(true);
        setWaitingSearch(false);
        if (
          Array.isArray(req.data.data.results) &&
          req.data.data.results.length === 1
        ) {
          setLicenseType(req.data.data.results[0].licenseType);
        }
        setResults(req.data.data.results);
      }
    }
  };

  useEffect(() => {
    dispatch(actions_slice.saveFileNameAndUID({ fileName, contentUID }));
  }, [fileName, contentUID]);

  useEffect(() => {
    return () => {
      if (uploading) {
        source.cancel("Operation canceled by the user.");
      }
    };
  }, [uploading]);

  useEffect(() => {
    if (!uploading) {
      setSource(
        Object.assign({}, CancelToken.source(), { key: source.key + 1 })
      );
    }
  }, [uploading]);

  const handleSubmit = async () => {
    setClickedAccept(true);
    if (bannerValue) {
      saveBanner({
        uid: contentUID,
        type: bannerType,
        value:
          bannerType === "ebook" ||
          bannerType === "video" ||
          bannerType === "podcast"
            ? selectedUID
            : bannerLink,
        status: bannerValue.status,
        updateImage: editingImg && successUpload ? fileName : undefined,
        licenseType: licenseType,
      });
    } else {
      createBanner({
        uid: contentUID,
        type: bannerType,
        value:
          bannerType === "ebook" ||
          bannerType === "video" ||
          bannerType === "podcast"
            ? selectedUID
            : bannerLink,
        licenseType: licenseType,
        fileName: fileName,
      });
    }
  };
  const deleteBannerImgFunc = async () => {
    if (fileName && contentUID) {
      const req = await axios({
        method: "delete",
        url: `/admin/banners/file`,
        data: { fileName: fileName, bannerUID: contentUID },
        cancelToken: token,
      });
      if (req.data.status) {
        setFileName(null);
        if (!img) {
          setContentUID(null);
        }
        setSuccessUpload(false);
        setImg(null);
      }
    }
  };
  useEffect(() => {
    if (bannerValue) {
      setImageUrl(bannerValue.imageUrl);
      setBannerType(bannerValue.type);
      setIsEditBanner(true);
      if (bannerValue.value) {
        if (
          bannerValue.type === "ebook" ||
          bannerValue.type === "video" ||
          bannerValue.type === "podcast"
        ) {
          if (bannerValue.content) {
            setBannerLink(bannerValue.content.title);
            setResults([
              Object.assign({}, bannerValue.content, {
                licenseType: bannerValue.licenseType,
              }),
            ]);
          }
          setLicenseType(bannerValue.licenseType);
          setSelectedUID(bannerValue.value);
        } else {
          setBannerLink(bannerValue.value);
        }
      } else {
        setBannerLink("");
      }
      setContentUID(bannerValue.uid);
    }
  }, [bannerValue]);

  const onHideOutside = () => {
    // deleteBannerImgFunc("385");
    onHide();
    setBannerValue(null);
    setTimeout(() => {
      setSuccessUpload(false);
      setImg(null);
    }, 200);
  };
  const handleContentBox = (UID, licenseType) => {
    setSelectedUID(UID);
    setLicenseType(licenseType);
  };
  const checkOverflow = (el) => {
    var curOverflow = el.style.overflow;
    if (!curOverflow || curOverflow === "visible") {
      el.style.overflow = "hidden";
    }
    var isOverflowing =
      el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
    el.style.overflow = curOverflow;
    return isOverflowing;
  };
  useEffect(() => {
    if (titleRef.current && checkOverflow(titleRef.current)) {
      setTitleOverflow(true);
    }
  }, [titleRef]);
  useEffect(() => {
    if (authorNameRef.current && checkOverflow(authorNameRef.current)) {
      setAuthorNameOverflow(true);
    }
  }, [authorNameRef]);
  const header = (
    <div
      className="row"
      style={{ marginBottom: "8px", justifyContent: "space-between" }}
    >
      <div className="col-6">
        <p
          style={{
            color: "#012875",
            fontWeight: "500",
            marginBottom: "0",
            fontSize: "20px",
          }}
        >
          อัปโหลดไฟล์
        </p>
        <p style={{ color: "#FD6B53", fontWeight: "400", fontSize: "12px" }}>
          อัปโหลดไฟล์ .png, .jpg หรือ .jpeg เท่านั้น *ขนาด 900x360px
        </p>
      </div>
    </div>
  );
  return (
    <>
      {informationDialogType === "error" ? (
        <UploadInformationDialog
          show={true}
          onHide={() => {
            setInformationDialogType(null);
          }}
          isError={informationDialogType === "error"}
          errorMessage={informationDialogMessage}
        />
      ) : (
        ""
      )}
      <Modal
        backdrop="static"
        show={isOver}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <img src="/media/members/image-modal.svg" alt="" />
            <div className={classes.title_red}>
              อัปโหลดได้ 5 แบนเนอร์เท่านั้น
            </div>
            <div className="divButton">
              <button
                type="button"
                onClick={() => {
                  setOver(false);
                  onHide();
                }}
                className="acceptButton"
              >
                ยืนยัน
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        show={imgAreaInvalid}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <img src="/media/members/image-modal.svg" alt="" />
            <div className={classes.title_red}>
              กรุณาใช้ไฟล์รูปที่มีขนาด 900x360px
            </div>
            <div className="divButton">
              <button
                type="button"
                onClick={() => {
                  setImgAreaInvalid(false);
                }}
                className="acceptButton"
              >
                ยืนยัน
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        show={openConfirmModal}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <img src="/media/members/image-modal.svg" alt="" />
            <div className={classes.title_red}>
              ยืนยันการ{bannerValue ? "แก้ไข" : "อัปโหลด"}แบนเนอร์
            </div>
            <div className="divButton">
              <button
                type="button"
                onClick={() => setOpenConfirmModal(false)}
                className="denyButton"
              >
                ยกเลิก
              </button>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                  setOpenConfirmModal(false);
                }}
                className="acceptButton"
              >
                ยืนยัน
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {(img && successUpload) || bannerValue || forEdit ? (
        <>
          {bannerValue || forEdit ? (
            <p className={classes.title}>แก้ไขข้อมูลแบนเนอร์</p>
          ) : (
            header
          )}
          {widthImage === 0 && heightImage === 0 && !img ? (
            <div
              style={{
                minHeight: `${loaderHeight}px`,
                textAlign: "center",
                padding: "20px 0 0 0",
                width: "100%",
              }}
            >
              <ClipLoader
                size={loaderHeight}
                color={"#123abc"}
                loading={true}
              />
            </div>
          ) : (
            ""
          )}
          {imageUrl ? (
            <img
              ref={imgRef}
              src={`${imageUrl}?${Date.now()}`}
              className={
                widthImage === 0 && heightImage === 0
                  ? classes.uploadedImg_edit_loading
                  : classes.uploadedImg_edit
              }
              onLoad={() => {
                if (imgRef.current) {
                  setWidthImage(imgRef.current.clientWidth);
                  setHeightImage(imgRef.current.clientHeight);
                }
              }}
            />
          ) : (
            <div>
              {img ? (
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="quick-user-tooltip">แก้ไขรูป</Tooltip>}
                >
                  <a
                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                    style={{
                      marginLeft: "0.75rem",
                      position: "absolute",
                      right: "30px",
                      transform: "translateY(1px)",
                    }}
                    onClick={() => {
                      deleteBannerImgFunc();
                    }}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-danger">
                      <SVG
                        src={toAbsoluteUrl("/media/members/delete-button.svg")}
                      />
                    </span>
                  </a>
                </OverlayTrigger>
              ) : (
                ""
              )}
              <img src={img} className={classes.uploadedImg} />
            </div>
          )}
          {(imageUrl && heightImage && widthImage && bannerValue) ||
          (!img && firstUpload) ? (
            <div
              {...getRootProps({ className: "dropzone" })}
              style={{
                top: "66px",
                position: !img && firstUpload ? "unset" : "absolute",
                display: !img && firstUpload ? "block" : "flex",
                width: `${widthImage}px`,
                height: !img && firstUpload ? "555px" : `${heightImage}px`,
                border: !img && firstUpload ? "1px dashed" : "none",
                borderRadius: !img && firstUpload ? "10px" : "0px",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <input {...getInputProps()} />
              <img
                src="/media/members/upload-file.png"
                className={
                  !img && firstUpload
                    ? classes.dropDivImg_noHover
                    : classes.dropDivImg
                }
                style={{ marginTop: !img && firstUpload ? "50px" : "auto" }}
                onClick={() => {
                  if (!img && firstUpload) {
                  } else {
                    open();
                  }
                }}
              />
              {!img && firstUpload ? (
                <div style={{ height: "160px" }}>
                  <h4>Drag a file here</h4>
                  <div>or browse your computer</div>
                  <button type="button" className="browseButton" onClick={open}>
                    Browse
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {uploading ? (
            <BorderLinearProgress
              className={classes.progressUpload}
              variant="determinate"
              color="secondary"
              value={percentage}
              style={{ margin: "auto" }}
            />
          ) : (
            ""
          )}
          {(img && successUpload) || bannerValue ? (
            <>
              <div className={`row ${classes.row}`}>
                <div className={`col-6 ${classes.col}`}>
                  <p className={classes.p}>เลือกประเภทแบนเนอร์</p>
                  <select
                    className={classes.slot}
                    onChange={handleBannerTypeChange}
                    value={bannerType}
                  >
                    {bannerTypeList}
                  </select>
                </div>
                {bannerType !== "normal" ? (
                  <div className={`col-6 ${classes.col}`}>
                    <p className={classes.p}>
                      {bannerType !== "link"
                        ? "ค้นหาสื่อ"
                        : "ลิงค์ไปที่เว็บไซต์"}
                    </p>
                    <input
                      placeholder={
                        bannerType !== "link"
                          ? "ชื่อสื่อ / UID"
                          : "กรอกลิงค์ไปที่เว็บไซต์"
                      }
                      className={`${classes.slot}`}
                      onChange={handleBannerLinkChange}
                      value={bannerLink}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          searchContentFromUID();
                        }
                      }}
                    />
                    {bannerLink !== "" &&
                    bannerType === "link" &&
                    !validator.isURL(bannerLink, { require_protocol: true }) ? (
                      <>
                        <div className="is-invalid"></div>
                        <div
                          className="invalid-feedback"
                          style={{ marginBottom: "calc(-17px - 0.25rem)" }}
                        >
                          กรุณากรอกลิงค์ให้ถูกต้อง เช่น ขึ้นต้นด้วย http:// หรือ
                          https://
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {bannerLink !== "" &&
                    (bannerType === "ebook" ||
                      bannerType === "video" ||
                      bannerType === "podcast") ? (
                      waitingSearch ? (
                        <>
                          <div className="is-invalid"></div>
                          <div
                            className="invalid-feedback"
                            style={{ marginBottom: "calc(-17px - 0.25rem)" }}
                          >
                            กำลังค้นหาสื่อ...
                          </div>
                        </>
                      ) : entered && results.length === 0 ? (
                        <>
                          <div className="is-invalid"></div>
                          <div
                            className="invalid-feedback"
                            style={{ marginBottom: "calc(-17px - 0.25rem)" }}
                          >
                            ไม่พบสื่อที่ค้นหา
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {bannerType === "ebook" ||
                    bannerType === "video" ||
                    bannerType === "podcast" ? (
                      <span
                        className="searchIcon"
                        onClick={() => {
                          setBannerLink("");
                          setResults([]);
                          setSelectedUID(undefined);
                        }}
                      >
                        {bannerLink === "" ? (
                          <img src="/media/members/search-icon.svg" alt="" />
                        ) : (
                          <img
                            src="/media/members/reset-search.svg"
                            alt=""
                            style={{ transform: "scale(0.7)" }}
                          />
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className={`col-6 ${classes.col}`}></div>
                )}
              </div>
              {Array.isArray(results) && results.length ? (
                <>
                  <p
                    style={{
                      color: "#012875",
                      fontWeight: "500",
                      marginTop: "4rem",
                      marginBottom: "1rem",
                      fontSize: "20px",
                      textAlign: "center",
                    }}
                  >
                    เลือกสื่อ
                  </p>
                  <div className={`row ${classes.row}`}>
                    {results.map((e, index) => {
                      if (
                        validator.isURL(e.coverImage, {
                          require_protocol: true,
                        })
                      ) {
                        return (
                          <div
                            key={index}
                            className={`col-2 ${classes.col_result}`}
                          >
                            <div
                              className={`row ${classes.contentBox}`}
                              style={{
                                margin: "12px 0px",
                                height: "208px",
                                boxShadow:
                                  selectedUID === e.contentUID &&
                                  licenseType === e.licenseType
                                    ? "0px 0px 4px rgb(1, 40, 117)"
                                    : "0px 0px 4px rgba(0, 0, 0, 0.3)",
                                border:
                                  selectedUID === e.contentUID &&
                                  licenseType === e.licenseType
                                    ? "1px solid rgb(1, 40, 117)"
                                    : " 1px solid transparent",
                                borderRadius: "10px",
                              }}
                              onClick={() => {
                                handleContentBox(e.contentUID, e.licenseType);
                              }}
                            >
                              <div
                                className={`col-12`}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  margin: "auto",
                                }}
                              >
                                <img
                                  src={e.coverImage}
                                  alt=""
                                  style={{ maxWidth: "77px" }}
                                />
                              </div>
                              <div
                                className={`col-12`}
                                style={{
                                  marginTop: "-8px",
                                  display: "block",
                                  alignItems: "center",
                                  alignSelf: "center",
                                }}
                              >
                                {isTitleOverflow ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        {e.title}
                                      </Tooltip>
                                    }
                                  >
                                    <p
                                      ref={titleRef}
                                      style={{
                                        marginBottom: "0px",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {e.title}
                                    </p>
                                  </OverlayTrigger>
                                ) : (
                                  <p
                                    ref={titleRef}
                                    style={{
                                      marginBottom: "0px",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {e.title}
                                  </p>
                                )}
                                {isAuthorNameOverflow ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        {e.authorName}
                                      </Tooltip>
                                    }
                                  >
                                    <p
                                      ref={authorNameRef}
                                      style={{
                                        fontWeight: "400",
                                        fontSize: "10px",
                                        color: "#828282",
                                        marginBottom: "0px",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                      }}
                                    >
                                      ผู้แต่ง : {e.authorName}
                                    </p>
                                  </OverlayTrigger>
                                ) : (
                                  <p
                                    ref={authorNameRef}
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "10px",
                                      color: "#828282",
                                      marginBottom: "0px",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    ผู้แต่ง : {e.authorName}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="divButton">
                <button
                  className="acceptButton"
                  type="submit"
                  onClick={() => {
                    setOpenConfirmModal(true);
                  }}
                  disabled={
                    (!img && firstUpload) ||
                    (bannerType !== "normal" &&
                      bannerType === "link" &&
                      !validator.isURL(bannerLink, {
                        require_protocol: true,
                      })) ||
                    (bannerType !== "normal" &&
                      bannerType !== "link" &&
                      (bannerType === "ebook" ||
                        bannerType === "video" ||
                        bannerType ||
                        "podcast") &&
                      !selectedUID)
                  }
                >
                  {bannerValue ? "บันทึก" : "อัปโหลด"}
                </button>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          {header}
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              border: "1px dashed",
              borderRadius: "10px",
              textAlign: "center",
              height: "555px",
            }}
          >
            <input {...getInputProps()} />
            <img
              src="/media/members/upload-file.png"
              style={{ marginTop: "120px" }}
            />
            <div style={{ height: "160px" }}>
              {failedUpload ? (
                <p className={classes.dropzoneTitle}>
                  ล้มเหลว กรุณาลองใหม่อีกครั้ง
                </p>
              ) : uploading ? (
                <>
                  <p className={classes.dropzoneTitle}>
                    รอสักครู่ ระบบกำลังอัปโหลดไฟล์...
                  </p>
                  <div
                    className="row"
                    style={{ margin: "40px 0.5px 0", color: "#012875" }}
                  >
                    <p
                      className="col-6"
                      style={{
                        fontSize: "12px",
                        textAlign: "left",
                        margin: "0",
                      }}
                    >
                      {loaded}KB/{total}KB
                    </p>
                    <p
                      className="col-6"
                      style={{
                        fontSize: "14px",
                        textAlign: "end",
                        margin: "0",
                      }}
                    >
                      {parseInt(percentage, 10)}%
                    </p>
                  </div>
                  <BorderLinearProgress
                    className={classes.progressUpload}
                    variant="determinate"
                    color="secondary"
                    value={percentage}
                  />
                </>
              ) : (
                <>
                  <h4>Drag a file here</h4>
                  <div>or browse your computer</div>
                  <button type="button" className="browseButton" onClick={open}>
                    Browse
                  </button>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
