import * as requestFromServer from './qrcodesCrud';
import { qrcodesSlice, callTypes } from './qrcodesSlice';
import {
   LastEvaluatedKey_QRCode,
   customersUIPropsQueryParams
} from '../../../../../interface';

const { actions } = qrcodesSlice;

export const fetchQrcodes = (
   queryParams: customersUIPropsQueryParams,
   lastEvaluate: LastEvaluatedKey_QRCode,
   setLoadingBottom?: (loading: boolean) => void
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
   dispatch(actions.startCall({ callType: callTypes.list }));
   return requestFromServer
      .findQrcodes(queryParams, lastEvaluate)
      .then(response => {
         const { LastEvaluatedKey, QRCode, totalAmount } = response.data.data;
         const entities = QRCode;
         dispatch(
            actions.qrcodesFetched({
               totalCount: totalAmount,
               entities,
               LastEvaluatedKey,
               lastEvaluate
            })
         );
         if (setLoadingBottom) {
            setLoadingBottom(false);
         }
      })
      .catch(error => {
         if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errorCode === '012'
         ) {
            alert('กรุณาเข้าสู่ระบบอีกครั้ง เนื่องจากระยะเวลาการใช้งานหมดอายุ');
            window.location.href = '/logout';
         }
         error.clientMessage = "Can't find qrcodes";
         dispatch(actions.catchError({ error, callType: callTypes.list }));
      });
};

export const fetchQrcodeMembers = (
   qrcodeId: string,

   setLoadingBottom?: (loading: boolean) => void
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
   dispatch(actions.startCall({ callType: callTypes.list }));
   return requestFromServer
      .findQrcodeMembers(qrcodeId)
      .then(response => {
         const { LastEvaluatedKey, user } = response.data.data;
         const entities_members = user;
         dispatch(
            actions.qrcodeMembersFetched({
               entities_members,
               LastEvaluatedKey
            })
         );
         if (setLoadingBottom) {
            setLoadingBottom(false);
         }
      })
      .catch(error => {
         if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errorCode === '012'
         ) {
            alert('กรุณาเข้าสู่ระบบอีกครั้ง เนื่องจากระยะเวลาการใช้งานหมดอายุ');
            window.location.href = '/logout';
         }
         error.clientMessage = "Can't find qrcode members";
         dispatch(actions.catchError({ error, callType: callTypes.list }));
      });
};

export const fetchQrcode = (id: string) => (
   dispatch: (arg0: { payload: any; type: string }) => void
) => {
   if (!id) {
      return dispatch(actions.qrcodeFetched({ qrcodeForEdit: undefined }));
   }

   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .getQrcodeById(id)
      .then(response => {
         const qrcode = response.data;
         dispatch(actions.qrcodeFetched({ qrcodeForEdit: qrcode }));
      })
      .catch(error => {
         error.clientMessage = "Can't find qrcode";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const deleteQrcode = (id: string) => (
   dispatch: (arg0: { payload: any; type: string }) => void
) => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .deleteQrcode(id)
      .then(response => {
         dispatch(actions.qrcodeDeleted({ id }));
      })
      .catch(error => {
         error.clientMessage = "Can't delete qrcode";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

// export const blockQrcode = (id: string) => (
//   dispatch: (arg0: { payload: any; type: string }) => void
// ) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .blockQrcode(id)
//     .then((response) => {
//       dispatch(actions.qrcodeBlocked({ id }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't block qrcode";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const resetPwQrcode = (id: string) => (
//   dispatch: (arg0: { payload: any; type: string }) => void
// ) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .blockQrcode(id)
//     .then((response) => {
//       // dispatch(actions.qrcodeDeleted({ id }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't reset qrcode password";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

export const createQrcode = (qrcodeForCreation: any) => (
   dispatch: (arg0: { payload: any; type: string }) => void
) => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .createQrcode({
         email: qrcodeForCreation.email,
         name: qrcodeForCreation.firstName,
         surname: qrcodeForCreation.lastName,
         rawPassword: qrcodeForCreation.password,
         staffID: qrcodeForCreation.staffID
      })
      .then(response => {
         if (response.data.status) {
            dispatch(actions.qrcodeCreated());
         }
      })
      .catch(error => {
         alert('กรุณาลองใหม่ อีเมลหรือรหัสพนักงานมีในระบบแล้ว');
         error.clientMessage = "Can't create qrcode";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

// export const updateQrcode = (qrcode) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateQrcode(qrcode)
//     .then(() => {
//       dispatch(actions.qrcodeUpdated({ qrcode }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't update qrcode";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const deleteQrcodes = (ids: string) => (
//   dispatch: (arg0: { payload: any; type: string }) => void
// ) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deleteQrcodes(ids)
//     .then(() => {
//       dispatch(actions.qrcodesDeleted({ ids }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't delete qrcodes";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };
