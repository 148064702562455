/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect, useMemo } from "react";
import { Formik, Form, Field } from "formik";
import {
  Input,
  Select,
  Card,
  CardBody,
} from "../../../../../../_metronic/_partials/controls";
import { makeStyles } from "@material-ui/core";
import { DatePicker, Switch, Input as Input2, message } from "antd";
import * as Yup from "yup";
import moment from "moment";
import { Tag, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useVideosUIContext } from "../VideosUIContext";
import * as actions from "../../../_redux/channels/channelsActions";
import * as actions2 from "../../../_redux/categories/categoriesActions";
import * as actions3 from "../../../_redux/videos/videosActions";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { VideoEditFileAndCover } from "./VideoEditFileAndCover";
import Spinner from "../../../../../../_metronic/_helpers/spinner";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { dateStr, timeStr } from "../../../partials/timeStampToDate";
import { Modals, ModalsSuccess } from "../../../../CustomView/ModalView";
import * as vid from "../../../../CustomView/UploadFunc";
import { createFFmpeg } from "@ffmpeg/ffmpeg";

const { TextArea } = Input2;

const useStyles = makeStyles(() => ({
  body: { margin: "auto" },

  col: { margin: "8px 0 3px 0" },
  center: {
    textAlign: "center",
  },
}));

export const VideoEditForm = ({
  videoOrigin,
  videoValue,
  setVideoValue,
  setVideoDetail,
  contentUIDForUpdate,
  rentalPeriod,
  onHideOutside,
  isPrivate,
  languageList,
}) => {
  const [categoryValue, setCategoryValue] = useState(0);
  const [subCategoryValue, setSubCategoryValue] = useState(0);
  const [rentalPeriodValue, setRentalPeriodValue] = useState(undefined);
  const [anonymousRentalPeriodValue, setAnonymousRentalPeriodValue] = useState(
    undefined
  );
  const [startDate, setStartDate] = useState(moment().unix());
  const [endDate, setEndDate] = useState(undefined);
  const [isManageLicense, setIsManageLicense] = useState(false);
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [fixFileModal, setFixFileModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [filePost, setFilePost] = useState(null);
  const [coverPost, setCoverPost] = useState(null);

  const saveInputRef = useRef(null);

  const [successModal, setSuccessModal] = useState(false);

  const [checkingError, setCheckingError] = useState(false);

  const [creatorList, setCreatorList] = useState([
    <option value={0} disabled selected>
      เลือกนักเขียน
    </option>,
  ]);
  const [categoryList, setCategoryList] = useState([
    <option value={0} disabled selected>
      เลือกหมวดหมู่หลัก
    </option>,
  ]);
  const [subCategoryList, setSubCategoryList] = useState([
    <option value={0} disabled selected>
      เลือกหมวดหมู่ย่อย
    </option>,
  ]);
  const [rentalPeriodList, setRentalPeriodList] = useState([
    <option value={3600}>1 ชั่วโมง</option>,
    <option value={7200}>2 ชั่วโมง</option>,
  ]);
  const [description, setDescription] = useState("");
  const [contentDetail, setContentDetail] = useState(null);
  const [coverDetail, setCoverDetail] = useState(null);
  const [storageUsedNow, setStorageUsedNow] = useState(0);

  const [firstLoaded, setFirstLoaded] = useState(false);

  const [savedContent, setSavedContent] = useState(false);
  const [savedCover, setSavedCover] = useState(false);

  const [fileUsed, setFileUsed] = useState(0);
  const [fileName, setFileName] = useState(null);

  const [numPagesForUpdate, setNumPagesForUpdate] = useState(undefined);
  const [coverName, setCoverName] = useState(null);
  const [coverImg, setCoverImg] = useState(null);
  const [languageValue, setLanguageValue] = useState("th");

  const [fileType, setFileType] = useState("pdf");
  const [fileTypeForUpdate, setFileTypeUpdate] = useState("pdf");
  const dispatch = useDispatch();
  const { currentState, currentState2, actionsLoading } = useSelector(
    (state) => ({
      currentState: state.categories,
      currentState2: state.channels,
      actionsLoading: state.videos.actionsLoading,
    }),
    shallowEqual
  );
  const [channelValue, setChannelValue] = useState(0);
  const [creatorValue, setCreatorValue] = useState(0);
  const [channelName, setChannelName] = useState("");
  const [creatorName, setCreatorName] = useState("");
  const [channelList, setChannelList] = useState([
    <option value={0} disabled selected>
      เลือก Channel
    </option>,
  ]);
  const videosUIContext = useVideosUIContext();
  const videosUIProps = useMemo(() => {
    return {
      newChannelButtonClick: videosUIContext.newChannelButtonClick,
      newCreatorButtonClick: videosUIContext.newCreatorButtonClick,
      newCategoryButtonClick: videosUIContext.newCategoryButtonClick,
      newSubCategoryButtonClick: videosUIContext.newSubCategoryButtonClick,
      queryParams: videosUIContext.queryParams,
    };
  }, [videosUIContext]);

  const [ffmpeg, setFFMPEG] = useState(undefined);
  const [readyUpload, setReadyUpload] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [duration, setDuration] = useState("00:00:00");
  const [millisecs, setMillisecs] = useState(0);

  useEffect(() => {
    const cf = createFFmpeg({
      log: true,
      corePath: vid.corePath,
      logger: ({ type, message }) => {
        const logger = vid.Loggers(type, message);
        if (logger.width > 0) setWidth(logger.width);
        if (logger.height > 0) setHeight(logger.height);
        if (logger.duration !== vid.defDuration) setDuration(logger.duration);
        if (logger.millisecs > 0) setMillisecs(logger.millisecs);
      },
    });
    setFFMPEG(cf);
  }, []);
  useEffect(() => {
    if (ffmpeg !== undefined) {
      if (!ffmpeg.isLoaded()) {
        ffmpeg.load();
        // setReadyUpload(true);
      }
    }
  }, [ffmpeg]);
  useEffect(() => {
    console.log("logger ", width, height, duration, millisecs);
  }, [width, height, duration, millisecs]);

  useEffect(() => {
    dispatch(actions.fetchChannels("none", 1, undefined, true));
    dispatch(
      actions2.fetchCategories({ contentType: "video" }, 1, undefined, true)
    );
  }, []);

  const handleLanguageChange = (e) => {
    setLanguageValue(e.target.value);
  };

  useEffect(() => {
    if (filePost) {
      if (modalType === "content" || modalType === "both") {
        if (filePost.type.includes("epub")) {
          setFileType("epub");
        } else if (filePost.type.includes("pdf")) {
          setFileType("pdf");
        }
      }
    } else {
      setFilePost(null);
    }

    if (coverPost) {
      if (modalType === "cover" || modalType === "both") {
        setCoverImg(URL.createObjectURL(coverPost));
      }
    } else {
      setCoverImg(null);
    }
  }, [filePost, coverPost, modalType]);

  useEffect(() => {
    if (rentalPeriod) {
      setRentalPeriodList(
        rentalPeriod.map((el, index) => (
          <option key={index} value={el.value}>
            {el.label}
          </option>
        ))
      );
    }
  }, [rentalPeriod]);

  useEffect(() => {
    if (currentState && currentState.entities) {
      setCategoryList([
        <option value={0} disabled selected>
          เลือกหมวดหมู่หลัก
        </option>,
        ...currentState.entities.map((el, index) => {
          return (
            <option key={index} value={el.id}>
              {el.catName}
            </option>
          );
        }),
      ]);
      if (currentState.entities.length === 0) {
        setCategoryList([<option value={0}>กรุณาเพิ่มหมวดหมู่หลัก</option>]);
      }
    }
    if (categoryValue) {
      if (currentState && currentState.entities) {
        const currentCat = currentState.entities.filter(
          (el) => el.id === categoryValue
        );
        if (currentCat.length > 0) {
          const currentSubCat = currentCat[0].subcats;
          if (currentSubCat) {
            setSubCategoryList([
              <option value={0} disabled selected>
                เลือกหมวดหมู่ย่อย
              </option>,
              ...currentSubCat.map((el, index) => {
                return (
                  <option key={index} value={el.id}>
                    {el.subcatName}
                  </option>
                );
              }),
            ]);
            if (currentSubCat.length === 0) {
              setSubCategoryList([
                <option value={0}>กรุณาเพิ่มหมวดหมู่ย่อย</option>,
              ]);
            }
          } else {
            setSubCategoryList([
              <option value={0}>กรุณาเพิ่มหมวดหมู่ย่อย</option>,
            ]);
          }
        }
      }
    }
  }, [currentState.entities, categoryValue]);

  useEffect(() => {
    // console.log(startDate);
  }, [startDate]);

  useEffect(() => {
    // console.log(endDate);
  }, [endDate]);

  useEffect(() => {
    if (currentState2 && currentState2.entities) {
      setChannelList([
        <option value={0} disabled selected>
          เลือก Channel
        </option>,
        ...currentState2.entities
          .filter((el) => el.status === 1)
          .map((el, index) => {
            return (
              <option key={index} value={el.uid}>
                {el.name}
              </option>
            );
          }),
      ]);
      if (currentState2.entities && currentState2.entities.length === 0) {
        setChannelList([<option value={0}>กรุณาเพิ่ม Channel</option>]);
      }
    }
    if (videoValue) {
      const el = videoValue;
      if (
        !firstLoaded &&
        el.channelValue &&
        el.creatorValue &&
        el.categoryValue &&
        el.subCategoryValue
      ) {
        setChannelValue(el.channelValue);
        setCreatorValue(el.creatorValue);
        setCategoryValue(el.categoryValue);
        setSubCategoryValue(el.subCategoryValue);
        setFirstLoaded(true);
      }
      setRentalPeriodValue(el.rentalPeriodValue);
      setAnonymousRentalPeriodValue(el.anonymousRentalPeriodValue);
      if (el.language) {
        setLanguageValue(el.language);
      }
      if (el.startDate) {
        setStartDate(el.startDate);
      }
      if (el.endDate) {
        setEndDate(el.endDate);
      } else {
        setEndDate(null);
      }
      if (el.numPages) {
        setNumPagesForUpdate(el.numPages);
      }
      if (
        el.concurrent !== undefined &&
        el.concurrent > 0 &&
        el.activeLicense !== undefined
      ) {
        setIsManageLicense(true);
      } else {
        setIsManageLicense(false);
      }
      if (el.tags) {
        setTags(el.tags);
      }
      setDescription(el.description);
      if (el.file) {
        setContentDetail(el.file.content);
        setCoverDetail(el.file.coverImage);
      }
    } else {
      setChannelValue(0);
      setCreatorValue(0);
      setCategoryValue(0);
      setSubCategoryValue(0);
      setRentalPeriodValue(undefined);
      setAnonymousRentalPeriodValue(undefined);
      setStartDate(moment().unix());
      setEndDate(undefined);
      setIsManageLicense(false);
      setTags([]);
      setDescription("");
    }
  }, [currentState2.entities, videoValue]);

  useEffect(() => {
    if (currentState2 && currentState2.creatorsForEdit) {
      setCreatorList([
        ...currentState2.creatorsForEdit.map((el, index) => {
          setCreatorValue(el.uid);
          return (
            <option key={index} value={el.uid}>
              {el.name}
            </option>
          );
        }),
      ]);
    }
  }, [currentState2.creatorsForEdit]);

  useEffect(() => {
    if (channelValue) {
      dispatch(actions.fetchCreators("none", channelValue, 1));
    }
  }, [channelValue]);

  useEffect(() => {
    setCheckingError(false);
  }, [channelValue, creatorValue, categoryValue, subCategoryValue]);

  const onStartDateChange = (date) => {
    if (date) {
      setStartDate(date.unix());
      if (endDate && date.unix() > endDate) {
        setEndDate(null);
      }
    }
  };

  const onEndDateChange = (date) => {
    if (date) {
      setEndDate(date.unix());
    } else {
      setEndDate(null);
    }
  };

  const onStartDateOk = (date) => {
    if (date) {
      setStartDate(date.unix());
    }
  };

  const onEndDateOk = (date) => {
    if (date) {
      setEndDate(date.unix());
    }
  };

  const disabledEndDate = (date) => {
    return date && date < moment().endOf("day");
  };

  const onManageLicenseChange = (e) => {
    if (e) {
      setRentalPeriodValue(3600);
      setAnonymousRentalPeriodValue(3600);
    } else {
      setRentalPeriodValue(null);
      setAnonymousRentalPeriodValue(null);
    }
    setIsManageLicense(e);
  };

  const handleClose = (removedTag) => {
    setTags(tags.filter((tag) => tag !== removedTag));
  };

  const showInput = () => {
    setInputVisible(true);
  };

  useEffect(() => {
    if (inputVisible) saveInputRef.current.focus();
  }, [inputVisible]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue.length >= 3) {
      if (inputValue && tags.indexOf(inputValue) === -1) {
        let commaSplit = inputValue.split(",");
        setTags(tags.concat(commaSplit));
      }
    } else {
      setInputVisible(false);
      setInputValue("");
      message.warning("tag ต้องมากกว่า 3 ตัวอักษร");
    }
  };

  useEffect(() => {
    // console.log(tags);
  }, [tags]);

  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const errorNumberFormat = (e) =>
    !e || parseInt(e, 10) < 1 || isNaN(parseInt(e, 10));

  const cardMini = (obj, type) => {
    return (
      <div className={`row cardMini`}>
        <div
          className={`col-4 ${styles.col}`}
          style={{ margin: obj && obj.fileName ? "auto 0" : "17px 0" }}
        >
          {obj && obj.fileName ? obj.fileName : "ไฟล์เสีย"}
        </div>
        <div className={`col-2 ${styles.col}`} style={{ margin: "auto" }}>
          {type === "content" ? "ไฟล์วิดีโอ" : type === "cover" ? "ไฟล์ปก" : ""}
        </div>
        {obj && obj.updatedDate ? (
          <div className={`col-3 ${styles.col}`}>
            <p style={{ margin: "0" }}>
              <p style={{ marginBottom: "0px" }}>{dateStr(obj.updatedDate)}</p>
              <p style={{ marginBottom: "4px" }}>{timeStr(obj.updatedDate)}</p>
            </p>
          </div>
        ) : (
          <div className={`col-3 ${styles.col}`}>
            <p style={{ margin: "12px 0" }}>-</p>
          </div>
        )}
        <div
          className={`col-3 ${styles.col} ${styles.center}`}
          style={{ margin: "auto" }}
        >
          <button
            className="miniButton"
            style={{ margin: "0" }}
            onClick={(e) => {
              e.preventDefault();
              setFixFileModal(true);
              setModalType(type);
            }}
          >
            แก้ไขไฟล์
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (fixFileModal) {
      if (modalType === "content" || modalType === "cover") {
        if (modalType === "content") {
          setFilePost(contentDetail ? contentDetail.file : null);
        } else if (modalType === "cover") {
          setCoverPost(coverDetail ? coverDetail.file : null);
        }
      }
    }
  }, [fixFileModal, modalType]);

  // Validation schema
  const uploadSchema_pdf = Yup.object().shape({
    name: Yup.string()
      .min(1, "อย่างน้อย 1 ตัวอักษร")
      .max(200, "ไม่เกิน 200 ตัวอักษร")
      .required("กรุณากรอกชื่อ"),
    isbn: Yup.string(),
    eisbn: Yup.string(),
    numPages: Yup.number()
      .min(1, "อย่างน้อย 1 หน้า")
      .typeError("กรุณาใช้ตัวเลข"),
  });
  const uploadSchema_epub = Yup.object().shape({
    name: Yup.string()
      .min(1, "อย่างน้อย 1 ตัวอักษร")
      .max(200, "ไม่เกิน 200 ตัวอักษร")
      .required("กรุณากรอกชื่อ"),
    isbn: Yup.string(),
    eisbn: Yup.string(),
    numPages: Yup.number()
      .min(0, "กรุณาใช้ตัวเลขที่ถูกต้อง")
      .typeError("กรุณาใช้ตัวเลข"),
  });

  const handleCreatorChange = (e) => {
    setCreatorValue(e.target.value);
    var index = e.nativeEvent.target.selectedIndex;
    setCreatorName(e.nativeEvent.target[index].text);
  };
  const handleCategoryChange = (e) => {
    setCategoryValue(e.target.value);
    setSubCategoryValue(0);
  };
  const handleSubCategoryChange = (e) => {
    setSubCategoryValue(e.target.value);
  };
  const handleRentalPeriodChange = (e) => {
    setRentalPeriodValue(e.target.value);
  };
  const handleAnonymousRentalPeriodChange = (e) => {
    setAnonymousRentalPeriodValue(e.target.value);
  };

  const saveDetail = (obj, type, numPagesForBeforeUpdate) => {
    if (type === "content" && obj.file) {
      setSavedContent(true);
      setContentDetail(obj);
      setFileTypeUpdate(fileType);
      setFileName(obj.file.name);
      if (fileType === "pdf") {
        setNumPagesForUpdate(numPagesForBeforeUpdate);
      } else {
        setNumPagesForUpdate(0);
      }
    } else if (type === "cover" && obj.file) {
      setSavedCover(true);
      setCoverDetail(obj);
      console.log(obj.file);
      setCoverName(obj.file.name);
    }
    setFixFileModal(false);
    setTimeout(() => {
      setModalType(null);
    }, 200);
  };

  const onHide = () => {
    setSuccessModal(false);
    setVideoValue(null);
    setVideoDetail(null);
    onHideOutside();
  };

  const deleteSavedFile = () => {
    if (savedContent) {
      reqDeleteFunc(fileName);
    }
    if (savedCover) {
      reqDeleteFunc(coverName);
    }
  };

  const handleChannelChange = (e) => {
    setChannelValue(e.target.value);
    var index = e.nativeEvent.target.selectedIndex;
    setChannelName(e.nativeEvent.target[index].text);
    setCreatorValue(0);
  };

  const reqDeleteFunc = async (name) => {
    const req = await axios.delete(`/admin/private/contents/file`, {
      data: { fileName: name, contentUID: contentUIDForUpdate },
    });
    if (req.data.status) {
      reqCancelFunc();
    }
  };

  const reqCancelFunc = () => {
    setFileType(fileTypeForUpdate);
    setFixFileModal(false);
    setTimeout(() => {
      setStorageUsedNow(storageUsedNow - fileUsed);
      setFilePost(null);
      setCoverPost(null);
      if (modalType === "content") {
        setFileName(null);
      }
      if (modalType === "cover") {
        setCoverName(null);
      }
      setModalType(null);
    }, 200);
  };

  const styles = useStyles();

  return (
    <>
      {fixFileModal ? (
        <VideoEditFileAndCover
          modalType={modalType}
          onHide={() => setFixFileModal(false)}
          contentDetail={contentDetail}
          coverDetail={coverDetail}
          setContentDetail={setContentDetail}
          setCoverDetail={setCoverDetail}
          contentUIDForUpdate={contentUIDForUpdate}
          saveDetail={saveDetail}
          outSideFilePost={filePost}
          outSideCoverPost={coverPost}
          ffmpeg={ffmpeg}
          getMediaInfo={vid.getMediaInfo}
        />
      ) : (
        ""
      )}
      {actionsLoading && <Spinner forNewEbook={true} />}
      <Formik
        enableReinitialize={true}
        initialValues={videoValue}
        validationSchema={
          fileType === "pdf" ? uploadSchema_pdf : uploadSchema_epub
        }
        onSubmit={(values) => {
          const newValues = {
            contentType: "video",
            language: languageValue,
            title: values.name,
            creatorUID: channelValue,
            writerUID: creatorValue,
            catId: categoryValue,
            subcatId: subCategoryValue,
            startDate: startDate,
            expireDate: endDate,
            concurrent: isManageLicense
              ? values.numLicenses && parseInt(values.numLicenses, 10)
              : 0,
            // activeLicense: 0,
            tags: tags,
            rentalPeriod: rentalPeriodValue && parseInt(rentalPeriodValue, 10),
            anonymousRentalPeriod:
              anonymousRentalPeriodValue &&
              parseInt(anonymousRentalPeriodValue, 10),
            description: description,
            rawContent: savedContent,
            coverImage: savedCover,
            duration: duration,
            length: millisecs,
            dimension: { width: width, height: height },
          };
          // console.log("newValues ", newValues);
          if (
            channelValue &&
            creatorValue &&
            categoryValue &&
            subCategoryValue &&
            (!isManageLicense ||
              (isManageLicense && !errorNumberFormat(values.numLicenses)))
          ) {
            dispatch(
              actions3.updateVideo(
                newValues,
                contentUIDForUpdate,
                false,
                setSuccessModal,
                videoOrigin
              )
            );
          }
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => {
          return (
            <Card classNam="cardStyle">
              <CardBody className="cardBodyStyle">
                <div style={{ maxWidth: "736px", margin: "auto" }}>
                  <Form className="form form-label-right">
                    <div className="row">
                      <div className={`col-lg-12 input`}>
                        <Field
                          name="name"
                          component={Input}
                          placeholder="กรุณากรอกชื่อวิดีโอ"
                          label="ชื่อวิดีโอ*"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-lg-10 input`}>
                        <Select
                          name="channel"
                          withFeedbackLabel={false}
                          label="Channel*"
                          onChange={handleChannelChange}
                          value={channelValue}
                        >
                          {channelList}
                        </Select>
                        {channelValue === 0 && checkingError && (
                          <>
                            <div className="is-invalid"></div>
                            <div className="invalid-feedback">
                              กรุณาเพิ่ม Channel
                            </div>
                          </>
                        )}
                      </div>
                      <div className={`col-lg-2 input`}>
                        <Tag
                          className={`site-tag-plus dashedBox `}
                          style={{ position: "absolute", top: "29px" }}
                          onClick={() => videosUIProps.newChannelButtonClick()}
                        >
                          <PlusOutlined style={{ marginRight: "2px" }} /> เพิ่ม
                          Channel
                        </Tag>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-lg-4 input`}>
                        <Select
                          name="category"
                          withFeedbackLabel={false}
                          label="หมวดหมู่หลัก*"
                          onChange={handleCategoryChange}
                          value={categoryValue}
                        >
                          {categoryList}
                        </Select>
                        {categoryValue === 0 && checkingError && (
                          <>
                            <div className="is-invalid"></div>
                            <div className="invalid-feedback">
                              กรุณาเลือกหมวดหมู่หลัก
                            </div>
                          </>
                        )}
                      </div>
                      <div className={`col-lg-2 input`}>
                        <Tag
                          className={`site-tag-plus dashedBox `}
                          style={{ position: "absolute", top: "29px" }}
                          onClick={() => videosUIProps.newCategoryButtonClick()}
                        >
                          <PlusOutlined style={{ marginRight: "2px" }} />{" "}
                          หมวดหมู่หลัก
                        </Tag>
                      </div>
                      <div className={`col-lg-4 input`}>
                        <Select
                          name="subcategory"
                          withFeedbackLabel={false}
                          label="หมวดหมู่ย่อย*"
                          onChange={handleSubCategoryChange}
                          value={subCategoryValue}
                        >
                          {subCategoryList}
                        </Select>
                        {subCategoryValue === 0 && checkingError && (
                          <>
                            <div className="is-invalid"></div>
                            <div className="invalid-feedback">
                              กรุณาเลือกหมวดหมู่ย่อย
                            </div>
                          </>
                        )}
                      </div>
                      <div className={`col-lg-2 input`}>
                        <Tag
                          className={`site-tag-plus dashedBox `}
                          style={{ position: "absolute", top: "29px" }}
                          onClick={() =>
                            videosUIProps.newSubCategoryButtonClick()
                          }
                        >
                          <PlusOutlined style={{ marginRight: "2px" }} />{" "}
                          หมวดหมู่ย่อย
                        </Tag>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-lg-4 input`}>
                        <p style={{ marginBottom: "0.5rem" }}>
                          วันที่เริ่มเผยแพร่*
                        </p>
                        <DatePicker
                          format={"DD-MM-YYYY HH:mm:ss"}
                          // className={checkingError ? "form-control is-valid" : "form-control"}
                          placeholder="เลือกวันที่เริ่มเผยแพร่"
                          defaultValue={
                            startDate ? moment.unix(startDate) : moment()
                          }
                          value={startDate ? moment.unix(startDate) : moment()}
                          showTime
                          onChange={onStartDateChange}
                          onOk={onStartDateOk}
                          allowClear={false}
                          className="videoDatePicker"
                        />
                      </div>
                      <div className={`col-lg-4 input`}>
                        <p style={{ marginBottom: "0.5rem" }}>
                          วันที่หมดอายุ (optional)
                        </p>
                        <DatePicker
                          format={"DD-MM-YYYY HH:mm:ss"}
                          // className={checkingError ? "form-control is-valid" : "form-control"}
                          placeholder="เลือกวันที่หมดอายุ"
                          defaultValue={endDate ? moment.unix(endDate) : null}
                          value={endDate ? moment.unix(endDate) : null}
                          disabledDate={disabledEndDate}
                          showTime
                          onChange={onEndDateChange}
                          onOk={onEndDateOk}
                          className="videoDatePicker"
                        />
                      </div>
                      <div className={`col-lg-4 input`}>
                        <Select
                          name="language"
                          withFeedbackLabel={false}
                          label="ภาษา"
                          onChange={handleLanguageChange}
                          value={languageValue}
                        >
                          {languageList}
                        </Select>
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-lg-12 input`}>
                        <p style={{ marginBottom: "0.5rem" }}>
                          รายละเอียดวิดีโอ
                        </p>
                        <CKEditor
                          editor={ClassicEditor}
                          config={{
                            toolbar: [
                              "bold",
                              "italic",
                              "bulletedList",
                              "numberedList",
                            ],
                          }}
                          data={description ? description : ""}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            // console.log("Editor is ready to use!", editor);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setDescription(data);
                          }}
                          onBlur={(event, editor) => {
                            // console.log("Blur.", editor);
                          }}
                          onFocus={(event, editor) => {
                            // console.log("Focus.", editor);
                          }}
                        />
                        {/*<TextArea
                          name="description"
                          placeholder="กรอกรายละเอียดเกี่ยวกับวิดีโอ"
                          value={description}
                          onChange={onDescriptionChange}
                          rows={4}
                        />*/}
                      </div>
                    </div>
                    <div className="row">
                      <div className={`col-lg-2 input`}>
                        <p style={{ marginBottom: "0.5rem" }}>จัดการสิทธิ์</p>
                        <Switch
                          onChange={onManageLicenseChange}
                          checked={isManageLicense}
                          checkedChildren="เปิด"
                          unCheckedChildren="ปิด"
                        />
                      </div>
                      {isManageLicense ? (
                        <>
                          <div className={`col-lg-4 input`}>
                            <Field
                              name="numLicenses"
                              // className={
                              //   !errorNumberFormat(values["numLicenses"]) && checkingError
                              //     ? "form-control is-valid"
                              //     : "form-control"
                              // }
                              component={Input}
                              placeholder="กรุณากรอกจำนวนสิทธิ์"
                              label="จำนวนสิทธิ์*"
                              type="number"
                            />
                            {errorNumberFormat(values["numLicenses"]) &&
                              checkingError && (
                                <>
                                  <div className="is-invalid"></div>
                                  <div className="invalid-feedback">
                                    กรุณากรอกจำนวนสิทธิ์ให้ถูกต้อง
                                  </div>
                                </>
                              )}
                          </div>
                          <div className={`col-lg-3 input`}>
                            <Select
                              name="rentalPeriod"
                              withFeedbackLabel={false}
                              label="เวลาในการยืมแบบสมาชิก"
                              onChange={handleRentalPeriodChange}
                              value={rentalPeriodValue}
                            >
                              {rentalPeriodList}
                            </Select>
                          </div>
                          <div className={`col-md-3 input`}>
                            <Select
                              name="anonymousRentalPeriod"
                              withFeedbackLabel={false}
                              label="เวลาในการยืมแบบไม่ระบุตัวตน"
                              onChange={handleAnonymousRentalPeriodChange}
                              value={anonymousRentalPeriodValue}
                            >
                              {rentalPeriodList}
                            </Select>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row">
                      <div className={`col-lg-12 input`}>
                        <p style={{ marginBottom: "0.5rem" }}>Tags</p>
                        {tags.map((tag) => {
                          const isLongTag = tag.length > 20;

                          const tagElem = (
                            <Tag
                              className="edit-tag"
                              key={tag}
                              closable={true}
                              onClose={() => handleClose(tag)}
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                                fontSize: "13px",
                                height: "32px",
                              }}
                            >
                              <span>
                                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                              </span>
                            </Tag>
                          );
                          return isLongTag ? (
                            <Tooltip title={tag} key={tag}>
                              {tagElem}
                            </Tooltip>
                          ) : (
                            tagElem
                          );
                        })}
                        {inputVisible && (
                          <Input2
                            ref={saveInputRef}
                            type="text"
                            size="small"
                            className="tag-input"
                            value={inputValue}
                            onChange={handleInputChange}
                            onBlur={handleInputConfirm}
                            onPressEnter={handleInputConfirm}
                            style={{ width: "100px", height: "32px" }}
                          />
                        )}
                        {!inputVisible && (
                          <Tag
                            className={`site-tag-plus dashedBox `}
                            onClick={showInput}
                          >
                            <PlusOutlined style={{ marginRight: "2px" }} />{" "}
                            เพิ่ม Tag
                          </Tag>
                        )}
                      </div>
                    </div>
                    <Card
                      style={{
                        boxShadow: "none",
                        background: "none",
                        marginTop: "4rem",
                      }}
                    >
                      <CardBody style={{ padding: "0" }}>
                        <div className="row" style={{ margin: "auto 0 1rem" }}>
                          <p className={`col-4 ${styles.col}`}>ชื่อไฟล์</p>
                          <p className={`col-2 ${styles.col}`}>ชนิดไฟล์</p>
                          <p className={`col-3 ${styles.col}`}>วันที่</p>
                          <p className={`col-3 ${styles.col} ${styles.center}`}>
                            การแก้ไข
                          </p>
                        </div>
                        {cardMini(contentDetail, "content")}
                        {cardMini(coverDetail, "cover")}
                      </CardBody>
                    </Card>
                    <div className="divButton ">
                      <button
                        className="denyButton"
                        type="button"
                        onClick={() => {
                          onHide();
                          deleteSavedFile();
                        }}
                      >
                        ยกเลิก
                      </button>
                      <button
                        className="acceptButton"
                        type="submit"
                        onClick={() => {
                          setCheckingError(true);
                        }}
                      >
                        บันทึก
                      </button>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>
          );
        }}
      </Formik>
      <ModalsSuccess
        show={successModal}
        onHide={() => onHide()}
        onClickAccept={() => onHide()}
      />
    </>
  );
};
