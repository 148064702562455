/* eslint-disable react-hooks/exhaustive-deps */
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../_redux/banners/bannersActions';
import { bannersSlice } from '../../../_redux/banners/bannersSlice';
import {
   getHandlerTableChange,
   NoRecordsFoundMessage,
   PleaseWaitMessage,
   sortCaret,
   headerSortingClasses
} from '../../../../../../_metronic/_helpers';
import * as uiHelpers from '../BannersUIHelpers';
import * as columnFormatters from './column-formatters';
import { useBannersUIContext } from '../BannersUIContext';
import { ClipLoader } from 'react-spinners';
import { Empty } from 'antd';
import { Role } from '../../../../../../interface';

const actions1 = bannersSlice.actions;

export function BannersTable({ isPrivate, setListLoading }) {
   // Banners UI Context
   const bannersUIContext = useBannersUIContext();
   const bannersUIProps = useMemo(() => {
      return {
         ids: bannersUIContext.ids,
         setIds: bannersUIContext.setIds,
         queryParams: bannersUIContext.queryParams,
         setQueryParams: bannersUIContext.setQueryParams,
         openEditBannerDialog: bannersUIContext.openEditBannerDialog,
         openDeleteBannerDialog: bannersUIContext.openDeleteBannerDialog,
         openBlockBannerDialog: bannersUIContext.openBlockBannerDialog,
         openUnblockBannerDialog: bannersUIContext.openUnblockBannerDialog
      };
   }, [bannersUIContext]);

   // Getting current state of banners list from store (Redux)
   const { currentState, role } = useSelector(
      state => ({ currentState: state.banners, role: state.auth.role }),
      shallowEqual
   );
   const { entities, listLoading, LastEvaluatedKey, error } = currentState;
   const loaderHeight = 50;
   const [isEmpty, setEmpty] = useState(false);
   const footerHeight = 300;
   const [loadingBottom, setLoadingBottom] = useState(false);

   // const isBottom = () => {
   //   let scrollHeight = Math.max(
   //     document.body.scrollHeight,
   //     document.documentElement.scrollHeight,
   //     document.body.offsetHeight,
   //     document.documentElement.offsetHeight,
   //     document.body.clientHeight,
   //     document.documentElement.clientHeight
   //   );
   //   return (
   //     window.pageYOffset + window.innerHeight >= scrollHeight - 3 * loaderHeight - footerHeight
   //   );
   // };

   const trackScrolling = () => {
      if (isBottom()) {
         setLoadingBottom(true);
      }
   };

   // useEffect(() => {
   //   document.addEventListener("scroll", trackScrolling);
   //   // eslint-disable-next-line
   // }, []);

   // useEffect(() => {
   //   return () => {
   //     document.removeEventListener("scroll", trackScrolling);
   //   };
   //   // eslint-disable-next-line
   // }, []);

   useEffect(() => {
      if (
         LastEvaluatedKey === undefined ||
         JSON.stringify(LastEvaluatedKey) === JSON.stringify({})
      ) {
         setEmpty(true);
      } else {
         setEmpty(false);
         dispatch(
            actions.fetchBanners(
               bannersUIProps.queryParams,
               isPrivate,
               LastEvaluatedKey,
               setLoadingBottom
            )
         );
      }
   }, [LastEvaluatedKey, loadingBottom]);

   useEffect(() => {
      setListLoading(listLoading);
   }, [listLoading]);

   // Banners Redux state
   const dispatch = useDispatch();

   useEffect(() => {
      return () => {
         dispatch(
            actions1.bannersFetched({
               totalCount: 0,
               entities: [],
               LastEvaluatedKey: {}
            })
         );
      };
   }, [isPrivate]);

   useEffect(() => {
      // clear selections list
      bannersUIProps.setIds([]);
      // server call by queryParams
      dispatch(actions.fetchBanners(bannersUIProps.queryParams, isPrivate));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [bannersUIProps.queryParams, dispatch, isPrivate]);

   const onSwitchStatusChange = (id, value, e) => {
      if (e) {
         // console.log("unblock");
         bannersUIProps.openUnblockBannerDialog(id, isPrivate);
      } else {
         // console.log("block");
         bannersUIProps.openBlockBannerDialog(id, isPrivate);
      }
   };

   const [loadingCount, setLoadingCount] = useState(0);

   const minWidthOfAll = 800;
   const minusOfAll = 380;
   const [tableWidth, setTableWidth] = useState(
      Math.max(
         document.body.scrollWidth,
         document.documentElement.scrollWidth,
         document.body.offsetWidth,
         document.documentElement.offsetWidth,
         document.documentElement.clientWidth
      ) <
         minWidthOfAll + minusOfAll
         ? minWidthOfAll
         : Math.max(
              document.body.scrollWidth,
              document.documentElement.scrollWidth,
              document.body.offsetWidth,
              document.documentElement.offsetWidth,
              document.documentElement.clientWidth
           ) - minusOfAll
   );

   useEffect(() => {
      if (entities && entities.length > -1 && loadingCount < 2) {
         setLoadingCount(loadingCount + 1);
      }
   }, [entities]);

   const isBottom = () => {
      const getScroll = document
         .getElementById('custom-table-scroll')
         .getElementsByTagName('tbody')[0];
      const offsetHeight = Math.ceil(getScroll.offsetHeight);
      const scrollTop = Math.ceil(getScroll.scrollTop);
      if (
         !listLoading &&
         getScroll.scrollHeight - (scrollTop + offsetHeight) <= 1
      ) {
         return true;
      } else {
         return false;
      }
   };

   const resize = () => {
      if (
         document.getElementById('custom-table-scroll') &&
         loadingCount === 2
      ) {
         setTableWidth(
            document
               .getElementById('custom-table-scroll')
               .getBoundingClientRect().width < minWidthOfAll
               ? minWidthOfAll
               : document
                    .getElementById('custom-table-scroll')
                    .getBoundingClientRect().width
         );
      }
   };

   useEffect(() => {
      window.addEventListener('resize', resize);
      if (
         document.getElementById('custom-table-scroll') &&
         document
            .getElementById('custom-table-scroll')
            .getElementsByTagName('tbody')[0] &&
         loadingCount === 2
      ) {
         document
            .getElementById('custom-table-scroll')
            .getElementsByTagName('tbody')[0]
            .addEventListener('scroll', trackScrolling);
      }
      // eslint-disable-next-line
   }, [loadingCount]);

   useEffect(() => {
      return () => {
         window.removeEventListener('resize', resize);
         setLoadingCount(0);
         if (
            document.getElementById('custom-table-scroll') &&
            document
               .getElementById('custom-table-scroll')
               .getElementsByTagName('tbody')[0]
         ) {
            document
               .getElementById('custom-table-scroll')
               .getElementsByTagName('tbody')[0]
               .removeEventListener('scroll', trackScrolling);
         }
      };
      // eslint-disable-next-line
   }, []);

   // const widthOfAll =
   //    role === Role.SuperAdmin
   //       ? 75 + 350 + 100 + 100
   //       : 75 + 350 + 100 + 100 + 200;

   const widthOfAll = 75 + 350 + 100 + 100 + 200;

   // Table columns
   const columns = [
      {
         dataField: 'none',
         text: 'ลำดับ',
         sortCaret: sortCaret,
         headerSortingClasses,
         formatter: columnFormatters.IndexColumnFormatter,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
                  style={{
                     textAlign: 'center',
                     fontSize: '14px',
                     maxWidth: `${(75 / widthOfAll) * tableWidth}px`,
                     minWidth: `${(75 / widthOfAll) * tableWidth}px`,
                     margin: '-0.75rem'
                  }}
               >
                  {column.text}
               </div>
            );
         },
         style: {
            maxWidth: `${(75 / widthOfAll) * tableWidth}px`,
            minWidth: `${(75 / widthOfAll) * tableWidth}px`
         },
         formatExtraData: {
            pageNumber: bannersUIProps.queryParams.pageNumber,
            pageSize: bannersUIProps.queryParams.pageSize
         }
      },
      {
         dataField: 'imageUrl',
         text: 'แบนเนอร์',
         sortCaret: sortCaret,
         headerSortingClasses,
         formatter: columnFormatters.ImageColumnFormatter,
         formatExtraData: {
            isPrivate
         },
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
                  style={{
                     textAlign: 'left',
                     fontSize: '14px',
                     maxWidth: `${(350 / widthOfAll) * tableWidth}px`,
                     minWidth: `${(350 / widthOfAll) * tableWidth}px`,
                     margin: '-0.75rem',
                     paddingLeft: '0.75rem'
                  }}
               >
                  {column.text}
               </div>
            );
         },
         style: {
            maxWidth: `${(350 / widthOfAll) * tableWidth}px`,
            minWidth: `${(350 / widthOfAll) * tableWidth}px`
         }
      },
      {
         dataField: 'type',
         text: 'ประเภท',
         align: 'center',
         sortCaret: sortCaret,
         formatter: columnFormatters.TextColumnFormatter,
         headerSortingClasses,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
                  style={{
                     textAlign: 'center',
                     fontSize: '14px',
                     maxWidth: `${(100 / widthOfAll) * tableWidth}px`,
                     minWidth: `${(100 / widthOfAll) * tableWidth}px`,
                     margin: '-0.75rem'
                  }}
               >
                  {column.text}
               </div>
            );
         },
         style: {
            maxWidth: `${(100 / widthOfAll) * tableWidth}px`,
            minWidth: `${(100 / widthOfAll) * tableWidth}px`
         }
      },
      {
         dataField: 'status',
         text: 'สถานะ',
         align: 'center',
         formatter: columnFormatters.StatusColumnFormatter,
         formatExtraData: {
            entities: entities,
            onSwitchStatusChange: onSwitchStatusChange,
            role,
            isPrivate
         },
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
                  style={{
                     textAlign: 'center',
                     fontSize: '14px',
                     maxWidth: `${(100 / widthOfAll) * tableWidth}px`,
                     minWidth: `${(100 / widthOfAll) * tableWidth}px`,
                     margin: '-0.75rem'
                  }}
               >
                  {column.text}
               </div>
            );
         },
         style: {
            maxWidth: `${(100 / widthOfAll) * tableWidth}px`,
            minWidth: `${(100 / widthOfAll) * tableWidth}px`
         }
      },
      {
         dataField: 'uid',
         text: 'การจัดการ',
         formatter: columnFormatters.ActionsColumnFormatter,
         formatExtraData: {
            isPrivate,
            openEditBannerDialog: bannersUIProps.openEditBannerDialog,
            openDeleteBannerDialog: bannersUIProps.openDeleteBannerDialog
         },
         classes: 'text-right',
         headerClasses: 'text-right pr-3',
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
                  style={{
                     textAlign: 'right',
                     fontSize: '14px',
                     maxWidth: `${(200 / widthOfAll) * tableWidth}px`,
                     minWidth: `${(200 / widthOfAll) * tableWidth}px`,
                     margin: '-0.75rem',
                     paddingRight: '0.75rem'
                  }}
               >
                  {column.text}
               </div>
            );
         },
         style: {
            maxWidth: `${(200 / widthOfAll) * tableWidth}px`,
            minWidth: `${(200 / widthOfAll) * tableWidth}px`
         }
      }
   ];
   const isNotFound = () => {
      return (
         entities &&
         Array.isArray(entities) &&
         entities.length === 0 &&
         !listLoading
      );
   };
   // Table pagination properties
   return (
      <>
         {/* // <PaginationProvider pagination={paginationFactory(paginationOptions)}>
    //   {({ paginationProps, paginationTableProps }) => {
    //     return (
    //       <Pagination isLoading={listLoading} paginationProps={paginationProps}> */}
         <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes={`table table-head-custom table-vertical-center custom-table-scroll bannersTable ${
               isNotFound() ? 'isNotFound' : ''
            }`}
            id="custom-table-scroll"
            bootstrap4
            remote
            keyField="email"
            data={!Array.isArray(entities) ? [] : entities}
            columns={
               columns
               // role === Role.SuperAdmin
               //    ? columns.filter(el => {
               //         return el.text !== 'การจัดการ';
               //      })
               //    : columns
            }
            defaultSorted={uiHelpers.defaultSorted}
            onTableChange={getHandlerTableChange(bannersUIProps.setQueryParams)}
            // selectRow={getSelectRow({
            //   entities,
            //   ids: bannersUIProps.ids,
            //   setIds: bannersUIProps.setIds,
            // })}
            // {...paginationTableProps}
         >
            <PleaseWaitMessage entities={entities} />
            <NoRecordsFoundMessage entities={entities} />
         </BootstrapTable>
         {isNotFound() ? (
            <div className="div-isNotFound">
               <Empty description={false} />
               <p
                  style={{
                     color: '#012875',
                     marginBottom: '0',
                     fontSize: '20px',
                     fontWeight: '600'
                  }}
               >
                  ไม่มีข้อมูล
               </p>
            </div>
         ) : (
            ''
         )}
         {/* //       </Pagination>
    //     );
    //   }}
    // </PaginationProvider> */}
         {/* //       </Pagination>
    //     );
    //   }}
    // </PaginationProvider> */}
         {!error &&
         listLoading &&
         LastEvaluatedKey !== undefined &&
         (!isEmpty || loadingBottom) ? (
            <div className="cliploader-center">
               <ClipLoader
                  size={loaderHeight}
                  color={'#123abc'}
                  loading={true}
               />
            </div>
         ) : !error && !isEmpty ? (
            <div style={{ minHeight: `${loaderHeight}px` }} />
         ) : (
            ''
         )}
      </>
   );
   // ) : (
   //   <div
   //     style={{
   //       minHeight: `${loaderHeight}px`,
   //       textAlign: "center",
   //       padding: "20px 0 0 0",
   //       width: "100%",
   //     }}
   //   >
   //     <ClipLoader size={loaderHeight} color={"#123abc"} loading={true} />
   //   </div>
   // );
}
