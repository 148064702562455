import { Button, DatePicker, Modal, Space } from 'antd';
import moment, { Moment } from 'moment';
import { useState } from 'react';

//? css
import './index.scss';

interface QuotationModalProps {
    visible: boolean;
    startDate: number;
    expireDate: number;
    payDate: number;
    onClose: () => void;
    onConfirm: () => void;
    onChangeStartDate: (date: any) => void;
    onChangePayDate: (date: any) => void;
}

export const QuoteDatePickerModal: React.FC<QuotationModalProps> = ({
    visible,
    startDate,
    payDate,
    onClose,
    onConfirm,
    onChangeStartDate,
    onChangePayDate
}) => {
    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={null}
            footer={null}
            className="quotation-modal"
        >
            <div className="content-container">
                <div className="title">ตั้งค่าวันที่</div>
                <Space
                    size={16}
                    className="date-picker-container"
                    direction="vertical"
                >
                    <div>
                        <div className="text">วันที่เริ่ม</div>
                        <DatePicker
                            placeholder="วันที่เริ่มใช้งาน"
                            className="slecter_date_input"
                            onChange={onChangeStartDate}
                            allowClear={false}
                            format={'DD/MM/YY'}
                            value={
                                startDate ? moment.unix(startDate) : moment()
                            }
                            size="large"
                        />
                    </div>
                    <div>
                        <div className="text">รอบจ่ายเงิน</div>
                        <DatePicker
                            placeholder="รอบจ่ายเงิน"
                            className="slecter_date_input"
                            onChange={onChangePayDate}
                            allowClear={false}
                            format={'DD/MM/YY'}
                            value={payDate ? moment.unix(payDate) : moment()}
                            size="large"
                        />
                    </div>
                </Space>
            </div>
            <div className="divButton">
                <Button
                    className="denyButton"
                    onClick={() => {
                        onClose();
                    }}
                >
                    ยกเลิก
                </Button>
                <Button
                    className="acceptButton"
                    onClick={() => {
                        onClose();
                        onConfirm();
                    }}
                >
                    ยืนยัน
                </Button>
            </div>
        </Modal>
    );
};
