import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { DatePicker, message, Switch } from 'antd';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
   getClientDuration,
   updateClientDuration
} from '../../../_redux/companies/companiesCrud';
import { ClientDurationPayload, Company } from '../../../../../../interface';
import { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface.d';
import * as yup from 'yup';
import * as actions from '../../../_redux/companies/companiesActions';
import { useDispatch, useSelector } from 'react-redux';
import { LoaderViewSmall } from '../../../../CustomView';
import {
   disabledDateAfterExpire,
   disabledDateBeforeStart,
   todayDatePickerStart,
   todayDatePickerEnd
} from '../../../../../utility';
import { HibraryRootState } from '../../../../../../redux/rootReducer';
import { companiesSlice } from '../../../_redux/companies/companiesSlice';

function CompaniesExtendPeriodDialog({
   clientUID,
   name,
   show,
   onHide
}: {
   clientUID: string;
   name: string;
   show: boolean;
   onHide: () => void;
}) {
   const useStyles = makeStyles(() => ({
      form_group: {
         display: 'flex',
         flexDirection: 'column',
         marginBottom: 10
      },
      clientPeriod: {
         width: '100%',
         display: 'flex',
         gap: 8
      }
   }));
   const dispatch = useDispatch();
   const classes = useStyles();
   const [loadingPage, setLoadingPage] = useState(true);
   const [startDate, setStartDate] = useState<number | null>(null);
   const [endDate, setEndDate] = useState<number | null>(null);

   const { entities } = useSelector((state: HibraryRootState) => ({
      entities: state.companies.entities
   }));

   useEffect(() => {
      formik.setFieldValue('expireDate', endDate);
   }, [endDate]);

   useEffect(() => {
      formik.setFieldValue('startDate', startDate);
   }, [startDate]);

   let schema = yup.object().shape({
      startDate: yup.number().required(),
      expireDate: yup.number().required()
   });

   const formik = useFormik({
      initialValues: {
         startDate: null,
         expireDate: null,
         updateMemberExpire: false
      } as ClientDurationPayload,
      onSubmit: async values => {
         let { status } = await updateClientDuration(clientUID, values);
         if (status) {
            message.success('บันทึกสำเร็จ');
            setNewEntities(values.startDate ?? 0, values.expireDate ?? 0);
            onHide();
         }
      },
      validationSchema: schema
   });

   useEffect(() => {
      getClientDuration(clientUID).then(res => {
         setLoadingPage(false);
         if (res?.data) {
            formik.setValues(res.data);
         }
      });
   }, []);

   const setNewEntities = (startDate: number, expireDate: number) => {
      const index = (entities as Company[]).findIndex(
         el => el.clientUID === clientUID
      );
      let newEntities = JSON.parse(JSON.stringify(entities));
      if (entities) {
         newEntities[index].startDate = startDate;
         newEntities[index].expireDate = expireDate;
         dispatch(
            companiesSlice.actions.setEntities({ entities: newEntities })
         );
      }
   };

   return (
      <Modal
         backdrop="static"
         show={show}
         onHide={onHide}
         aria-labelledby="example-modal-sizes-title-lg"
         style={{ margin: '3% auto' }}
      >
         <Modal.Body>
            <h2 className="center" style={{ marginBottom: '1rem' }}>
               การต่ออายุ
            </h2>

            {loadingPage ? (
               <LoaderViewSmall />
            ) : (
               <>
                  <div className={classes.form_group}>
                     <p style={{ marginBottom: '0.5rem' }}>
                        วันที่เริ่มใช้งาน - วันที่หมดอายุ*
                     </p>
                     <div className={classes.clientPeriod}>
                        <DatePicker
                           showToday
                           renderExtraFooter={() =>
                              todayDatePickerStart(setStartDate)
                           }
                           placeholder="วันที่เริ่มใช้งาน"
                           showTime={{
                              defaultValue: moment('00:00:00', 'HH:mm:ss')
                           }}
                           onChange={dates => {
                              if (dates !== null)
                                 formik.setFieldValue(
                                    'startDate',
                                    dates.unix()
                                 );
                              else formik.setFieldValue('startDate', null);
                           }}
                           value={
                              formik.values.startDate !== null
                                 ? moment.unix(formik.values.startDate)
                                 : null
                           }
                           disabledDate={current => {
                              if (formik.values.expireDate) {
                                 return disabledDateAfterExpire(
                                    current,
                                    formik.values.expireDate
                                 );
                              } else {
                                 return false;
                              }
                           }}
                        />
                        <DatePicker
                           showToday
                           renderExtraFooter={() =>
                              todayDatePickerEnd(setEndDate)
                           }
                           placeholder="วันที่หมดอายุ"
                           showTime={{
                              defaultValue: moment('23:59:59', 'HH:mm:ss')
                           }}
                           onChange={dates => {
                              if (dates !== null)
                                 formik.setFieldValue(
                                    'expireDate',
                                    dates.unix()
                                 );
                              else formik.setFieldValue('expireDate', null);
                           }}
                           value={
                              formik.values.expireDate !== null
                                 ? moment.unix(formik.values.expireDate)
                                 : null
                           }
                           disabledDate={current => {
                              if (formik.values.startDate) {
                                 return disabledDateBeforeStart(
                                    current,
                                    formik.values.startDate
                                 );
                              } else {
                                 return false;
                              }
                           }}
                        />
                     </div>

                     {/* <RangePicker
                ranges={{
                  วันนี้: [moment().startOf("day"), moment().endOf("day")],
                  เดือนนี้: [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                showTime={{
                  defaultValue: [moment(), moment().add(1, "M")],
                }}
                format="DD/MM/YYYY HH:mm:ss"
                value={
                  formik.values.startDate && formik.values.expireDate
                    ? [
                        moment.unix(formik.values.startDate),
                        moment.unix(formik.values.expireDate),
                      ]
                    : null
                }
                onChange={onChangePeriod}
              /> */}
                     <p>
                        {formik.errors.startDate || formik.errors.expireDate ? (
                           <div className="validated">
                              <div className="invalid-feedback">
                                 startDate and expireDate is require
                              </div>
                           </div>
                        ) : null}
                     </p>
                  </div>
                  <div>
                     <p style={{ marginBottom: '0.5rem' }}>
                        ยืดอายุสมาชิก (เท่าอายุองค์กร)
                     </p>
                     <Switch
                        checkedChildren="เปิด"
                        unCheckedChildren="ปิด"
                        checked={formik.values.updateMemberExpire}
                        onChange={checked => {
                           formik.setFieldValue('updateMemberExpire', checked);
                        }}
                     />
                  </div>
                  <div className="divButton">
                     <button
                        type="button"
                        onClick={onHide}
                        className="denyButton"
                     >
                        ยกเลิก
                     </button>
                     <button
                        type="button"
                        onClick={() => formik.handleSubmit()}
                        className="acceptButton"
                        disabled={formik.isSubmitting}
                     >
                        ยืนยัน
                        {formik.isSubmitting ? (
                           <span className="ml-3 spinner spinner-white"></span>
                        ) : (
                           ''
                        )}
                     </button>
                  </div>
               </>
            )}
         </Modal.Body>
      </Modal>
   );
}

export default CompaniesExtendPeriodDialog;
