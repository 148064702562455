import { Button, Col, DatePicker, Input, Modal, Row } from 'antd';
import './createVote.scss';
import { useFormik } from 'formik';
import { useState } from 'react';
import { createcatalogVote } from '../../../_redux/catalogue/catalogueCrud';
import {
   disabledDateAfterExpire,
   disabledDateBeforeStart
} from '../../../../../utility';
import * as Yup from 'yup';

interface CreateVoteDialogProps {
   visible: boolean;
   onClose: () => void;
   onCreated: () => void;
   catalogAmount: number;
   catalogId: string;
}

interface CreateVotePayload {
   startDate?: number;
   endDate?: number;
   voteConcurrent?: number;
   percent?: number;
}

const CreateVoteDialog = (props: CreateVoteDialogProps) => {
   const { visible, onClose, catalogAmount, onCreated, catalogId } = props;
   // const [percent, setPercent] = useState<number>();
   const [isSubmitting, setIsSubmitting] = useState(false);

   const schema = Yup.object().shape({
      startDate: Yup.number().required('กรุณากรอกวันที่เริ่ม'),
      endDate: Yup.number().required('กรุณากรอกวันที่สิ้นสุด'),
      voteConcurrent: Yup.number()
         .positive('จำนวนโหวตต้องมากกว่า 0')
         .min(1, 'จำนวนเปอร์เซ็นต้องมากกว่า 0')
         .max(catalogAmount, 'จำนวนโหวตต้องไม่เกินจำนวนสื่อในแคตาล็อก')

         .required('กรุณากรอกจำนวนโหวต'),
      percent: Yup.number()
         .positive('จำนวนโหวตต้องมากกว่า 0')
         .min(1, 'จำนวนโหวตต้องมากกว่า 0')
         .max(100, 'จำนวนเปอร์เซ็นต้องไม่เกิน 100')
   });

   const formik = useFormik({
      initialValues: {
         startDate: undefined,
         endDate: undefined,
         voteConcurrent: undefined,
         catalogUID: catalogId,
         percent: undefined
      } as CreateVotePayload,
      onSubmit: values => {
         setIsSubmitting(true);
         console.log(values);
         delete values.percent;
         onCreateVote(values);
      },
      validationSchema: schema
   });

   const title = (
      <div className="modalTitle">
         <h2>สร้างโหวตแคตตาล็อก </h2>
      </div>
   );

   const onCreateVote = async (payload: CreateVotePayload) => {
      try {
         const res = await createcatalogVote(payload);
         if (res.status) {
            onCreated();
         }
      } finally {
         setIsSubmitting(true);
      }
   };

   const calulatePercent = (voteAmount: number, allcatalog: number) => {
      const percent = (voteAmount / allcatalog) * 100;
      const percentFix2 = percent.toFixed(2);
      return parseInt(percentFix2);
   };

   const calculatePrecentToAmount = (percent: number, allcatalog: number) => {
      const amountNum = (allcatalog / 100) * percent;

      return Math.round(amountNum);
   };

   return (
      <Modal
         wrapClassName="voteModal"
         visible={visible}
         onCancel={onClose}
         width={800}
         footer={null}
         title={title}
      >
         <div className="voteForm">
            <Row gutter={[16, 16]}>
               <Col span={12} className="displayColumn">
                  <label>วันที่เริ่ม*</label>
                  <DatePicker
                     size="large"
                     onChange={e => {
                        formik.setFieldValue('startDate', e?.unix());
                     }}
                     disabledDate={current =>
                        disabledDateAfterExpire(
                           current,
                           formik.values.endDate ?? 0
                        )
                     }
                  />
                  {formik.touched.startDate && formik.errors.startDate ? (
                     <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                           {formik.errors.startDate}
                        </div>
                     </div>
                  ) : null}
               </Col>

               <Col span={12} className="displayColumn">
                  <label>วันที่สิ้นสุด*</label>
                  <DatePicker
                     size="large"
                     onChange={e => {
                        formik.setFieldValue('endDate', e?.unix());
                     }}
                     disabledDate={current =>
                        disabledDateBeforeStart(
                           current,
                           formik.values.startDate ?? 0
                        )
                     }
                  />
                  {formik.touched.endDate && formik.errors.endDate ? (
                     <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                           {formik.errors.endDate}
                        </div>
                     </div>
                  ) : null}
               </Col>
            </Row>

            <div>
               <label>จำนวนโหวตของผู้ใช้งาน</label>
               <Row>
                  <Col span={8} className="flex-align-center">
                     <Input
                        size="large"
                        value={formik.values.percent}
                        type={'number'}
                        onChange={e => {
                           const value = parseInt(e.currentTarget.value);
                           console.log(value);

                           if (value <= 100) {
                              formik.setFieldValue('percent', value);
                              formik.setFieldValue(
                                 'voteConcurrent',
                                 calculatePrecentToAmount(value, catalogAmount)
                              );
                           } else if (isNaN(value)) {
                              formik.setFieldValue('percent', undefined);
                           }
                        }}
                     />
                     %
                  </Col>
                  <Col span={4} className="flexCenter">
                     หรือ
                  </Col>
                  <Col span={8}>
                     <Input
                        size="large"
                        type={'number'}
                        value={formik.values.voteConcurrent}
                        onChange={e => {
                           const concurrent = parseInt(e.currentTarget.value);
                           formik.setFieldValue('voteConcurrent', concurrent);
                           // setPercent(
                           //    calulatePercent(concurrent, catalogAmount)
                           // );
                           formik.setFieldValue(
                              'percent',
                              calulatePercent(concurrent, catalogAmount)
                           );
                        }}
                     />
                  </Col>
                  <Col span={4} className="flexEnd">
                     {' '}
                     จาก {catalogAmount} เล่ม
                  </Col>
               </Row>
               <Row>
                  <Col span={12}>
                     {formik.touched.voteConcurrent &&
                     formik.errors.voteConcurrent ? (
                        <div className="fv-plugins-message-container">
                           <div className="fv-help-block">
                              {formik.errors.voteConcurrent}
                           </div>
                        </div>
                     ) : null}
                  </Col>
                  <Col span={12}>
                     {formik.touched.percent && formik.errors.percent ? (
                        <div className="fv-plugins-message-container">
                           <div className="fv-help-block">
                              {formik.errors.percent}
                           </div>
                        </div>
                     ) : null}
                  </Col>
               </Row>
            </div>

            <div className="action-group">
               <Button
                  shape="round"
                  size="large"
                  type="default"
                  className="defaultButton"
                  onClick={() => props.onClose()}
               >
                  ยกเลิก
               </Button>
               <Button
                  shape="round"
                  size="large"
                  className="primaryButton"
                  loading={isSubmitting}
                  onClick={() => formik.handleSubmit()}
               >
                  สร้าง
               </Button>
            </div>
         </div>
      </Modal>
   );
};

export default CreateVoteDialog;
