import { Modal } from 'antd';
import styled from 'styled-components';
// grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

export const CardContainer = styled.div`
   background: #fff;
   padding: 1rem;
   border-radius: 0.5rem;
   height: 100%;
   position: relative;

   .headerSection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1rem;
      border-bottom: 1px solid #e0e0e0;

      h1 {
         font-size: 1.25rem;
         font-weight: 600;
         margin-bottom: 0;
         color: var(--primary);
      }

      button {
         background: var(--primary);
         color: #fff;
         border-color: var(--primary);
      }
   }

   .itemContainer {
      display: grid;
      gap: 1rem;
      padding-top: 1rem;
      grid-template-columns: repeat(3, minmax(400px, 1fr));

      @media (max-width: 1280.98px) {
         grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      }

      div.flex {
         display: flex;
         align-items: center;
         gap: 1rem;
      }

      .loading {
         position: absolute;
         top: 50%;
         left: 50%;

         > div {
            padding: 0 !important;
         }
      }

      .ant-empty {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
      }

      input {
         width: 100%;
         background: #fff;
         border: none;
         border-radius: 0.5rem;
         color: var(--primary);
         height: 40px;
         font-size: 1.5rem;
         padding: 0.5rem;
         -webkit-text-security: square;
      }

      .item {
         background: #f2f2f2;
         border-radius: 0.5rem;
         padding: 1rem;
         height: fit-content;

         h4 {
            font-size: 1.25rem;
            font-weight: 500;
            margin-bottom: 0;
            color: #1c1c1e;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
         }

         p {
            margin-bottom: 0;
         }

         span.startDate {
            color: var(--green);
         }

         span.expireDate {
            color: var(--red);
         }

         .copyTokenButton {
            width: 40px;
            height: 40px;
            border-radius: 0.5rem;
            background: #fff;
            color: var(--primary);
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
         }

         .editButton {
            width: 1rem;
            height: 1rem;
            transition: all 0.3s;
            cursor: pointer;

            :hover {
               transform: scale(1.1);
            }
         }
      }
   }
`;

export const CreateTokenContainer = styled.div`
   background: #fff;
   padding: 1rem;
   border-radius: 0.5rem;
   height: 100%;

   .ant-input {
      border-radius: 0.5rem;
      height: 40px;
   }

   .ant-radio-checked .ant-radio-inner {
      border: 1px solid var(--primary);

      &::after {
         background-color: var(--primary);
      }
   }

   .error {
      color: var(--red) !important;
      margin-bottom: 0;
      margin-top: 0.25rem;
   }

   .headerSection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1rem;
      border-bottom: 1px solid #e0e0e0;

      h1 {
         font-size: 1.25rem;
         font-weight: 600;
         margin-bottom: 0;
         color: var(--primary);
      }

      button.saveButton {
         background: var(--primary);
         color: #fff;
         border-color: var(--primary);
      }

      button.backButton {
         border: 1px solid var(--primary);
         color: var(--primary);
         font-weight: 500;
      }
   }

   .contentContainer {
      width: 100%;
      max-width: 60%;
      margin: 0 auto;
      padding: 2rem 0;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .ant-divider-horizontal {
         margin: 1rem 0;
      }

      p {
         color: #464e5f;
         margin-bottom: 0;
      }

      p.description {
         margin-bottom: 1.5rem !important;
      }

      .permissionContainer {
         display: flex;
         gap: 1.25rem;

         .switchContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
         }
      }
   }

   .selectApiContainer {
      width: 100%;

      p.highlight {
         color: var(--orange);
      }

      .ant-radio-group,
      .ant-space-vertical {
         width: 100%;
      }

      .item {
         padding-left: 2rem;
         padding-bottom: 1rem;
         border-bottom: 1px solid #f2f2f2;
      }
   }

   .listContainer {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;
      margin-top: 1rem;

      .customTag {
         height: 32px;
         border-radius: 0.25rem;
         border: none;
         background: #f2f2f2;
         display: flex;
         align-items: center;
         margin: 0;
         padding: 0 1rem;

         > span {
            margin-left: 1rem;
         }
      }

      .addTag {
         background: #fff;
         border: 1.5px dashed #f2f2f2;
         height: 32px;
         width: 120px;
         border-radius: 0.25rem;
         display: flex;
         align-items: center;
         justify-content: center;
         cursor: pointer;

         .anticon {
            margin-right: 0.5rem;
         }
      }

      .tagInput {
         height: 32px;
         width: 120px !important;
      }
   }
`;

export const ConfirmModalStyle = styled(Modal)`
   .ant-modal-content {
      border-radius: 0.5rem;
   }

   .ant-modal-body {
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 0.5rem;
   }

   .textContainer {
      margin: 2.5rem 0;

      h1 {
         font-size: 2rem;
         font-weight: 700;
         color: var(--orange);
         text-align: center;
      }

      p {
         font-size: 16px;
         color: #4f4f4f;
         max-width: 80%;
         text-align: center;
         margin: 0 auto;
      }

      span {
         color: var(--orange);
      }
   }

   button.outline {
      border: 1px solid var(--primary);
      font-size: 1rem;
      height: 48px;
      width: 150px;
      color: var(--primary);

      span {
         font-size: 1.25rem;
      }
   }

   button.background {
      border: 1px solid var(--primary);
      font-size: 1rem;
      height: 48px;
      width: 150px;
      color: #fff;
      background-color: var(--primary);

      span {
         font-size: 1.25rem;
      }
   }
`;
