/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import {
    Input,
    Select,
    CardHeader,
    CardHeaderToolbar,
    CardBody
} from '../../../../../_metronic/_partials/controls';
import { makeStyles } from '@material-ui/core';
import {
    DatePicker,
    Switch,
    Input as Input2,
    message,
    Select as SelectAnt
} from 'antd';
import * as Yup from 'yup';
import moment from 'moment';
import { Tag, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useUploadUIContext } from './UploadUIContext';
import * as actions from '../../_redux/publishers/publishersActions';
import * as actions2 from '../../_redux/categories/categoriesActions';
import * as actions3 from '../../_redux/ebooks/ebooksActions';
import Spinner from '../../../../../_metronic/_helpers/spinner';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ModalsSuccess } from '../../../CustomView/ModalView';

const FormForFile = ({
    uploadValue,
    handleSubmitOutside,
    rentalPeriod,
    filePost,
    coverPost,
    setFilePost,
    setCoverPost,
    contentUIDToUpload,
    setContentUIDToUpload,
    fileType,
    setTitleUsedNow,
    fromEbookPage,
    onHide
}) => {
    const useStyles = makeStyles(() => ({
        body: { margin: 'auto' },
        divButton: { marginTop: '-47px', textAlign: 'right' },
        searchSelect: {
            height: 'calc(1.5em + 1.3rem + 2px)',
            width: '100%',
            '&& .ant-select-selector': {
                borderRadius: 6.5,
                height: 'calc(1.5em + 1.3rem + 2px)',
                '&:hover,&:focus': {
                    borderColor: '#226edf'
                }
            },
            '&& .ant-select-selection-item': {
                lineHeight: '38px',
                fontSize: 13,
                fontWeight: 400
            },
            '&& .ant-select-arrow': {
                color: '#1b1b1b',
                right: 8
            },
            '& .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input': {
                height: '100%'
            }
        }
    }));
    const classes = useStyles();
    const [publisherValue, setPublisherValue] = useState(0);
    const [authorValue, setAuthorValue] = useState(0);
    const [categoryValue, setCategoryValue] = useState(0);
    const [subCategoryValue, setSubCategoryValue] = useState(0);
    const [rentalPeriodValue, setRentalPeriodValue] = useState(undefined);
    const [
        anonymousRentalPeriodValue,
        setAnonymousRentalPeriodValue
    ] = useState(undefined);
    const [startDate, setStartDate] = useState(moment().unix());
    const [endDate, setEndDate] = useState(undefined);
    const [isManageLicense, setIsManageLicense] = useState(false);
    const [tags, setTags] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const saveInputRef = useRef(null);

    const [successModal, setSuccessModal] = useState(false);

    const [checkingError, setCheckingError] = useState(false);

    const [publisherList, setPublisherList] = useState([
        <option value={0} disabled selected>
            เลือกสำนักพิมพ์
        </option>
    ]);
    const [authorList, setAuthorList] = useState([
        <option value={0} disabled selected>
            เลือกนักเขียน
        </option>
    ]);

    const [authorListAntd, setAuthorListAntd] = useState([
        { value: 0, label: 'เลือกนักเขียน' }
    ]);
    const [categoryList, setCategoryList] = useState([
        <option value={0} disabled selected>
            เลือกหมวดหมู่หลัก
        </option>
    ]);
    const [subCategoryList, setSubCategoryList] = useState([
        <option value={0} disabled selected>
            เลือกหมวดหมู่ย่อย
        </option>
    ]);
    const [rentalPeriodList, setRentalPeriodList] = useState([
        <option value={3600}>1 ชั่วโมง</option>,
        <option value={7200}>2 ชั่วโมง</option>
    ]);
    const [description, setDescription] = useState('');
    const dispatch = useDispatch();
    const { currentState, currentState2, actionsLoading } = useSelector(
        state => ({
            currentState: state.categories,
            currentState2: state.publishers,
            actionsLoading: state.ebooks.actionsLoading
        }),
        shallowEqual
    );
    const uploadUIContext = useUploadUIContext();
    const uploadUIProps = useMemo(() => {
        return {
            newPublisherButtonClick: uploadUIContext.newPublisherButtonClick,
            newAuthorButtonClick: uploadUIContext.newAuthorButtonClick,
            newCategoryButtonClick: uploadUIContext.newCategoryButtonClick,
            newSubCategoryButtonClick: uploadUIContext.newSubCategoryButtonClick
        };
    }, [uploadUIContext]);

    useEffect(() => {
        dispatch(actions.fetchPublishers('none', 1, undefined, true));
        dispatch(
            actions2.fetchCategories(
                { contentType: 'ebook' },
                1,
                undefined,
                true
            )
        );
    }, []);

    useEffect(() => {
        if (rentalPeriod) {
            setRentalPeriodList(
                rentalPeriod.map((el, index) => (
                    <option key={index} value={el.value}>
                        {el.label}
                    </option>
                ))
            );
        }
    }, [rentalPeriod]);

    useEffect(() => {
        if (currentState && currentState.entities) {
            setCategoryList([
                <option value={0} disabled selected>
                    เลือกหมวดหมู่หลัก
                </option>,
                ...currentState.entities
                    .filter(el => el.status === 1)
                    .map((el, index) => {
                        // if (index === 0 && categoryValue === 0) {
                        //   setCategoryValue(el.id);
                        // }
                        return (
                            <option key={index} value={el.id}>
                                {el.catName}
                            </option>
                        );
                    })
            ]);
            if (currentState.entities.length === 0) {
                setCategoryList([
                    <option value={0}>กรุณาเพิ่มหมวดหมู่หลัก</option>
                ]);
            }
        }
        if (categoryValue) {
            if (currentState && currentState.entities) {
                const currentCat = currentState.entities.filter(
                    el => el.id === categoryValue
                );
                if (currentCat.length > 0) {
                    const currentSubCat = currentCat[0].subcats;
                    if (currentSubCat) {
                        setSubCategoryList([
                            <option value={0} disabled selected>
                                เลือกหมวดหมู่ย่อย
                            </option>,
                            ...currentSubCat.map((el, index) => {
                                // if (index === 0 && subCategoryValue === 0) {
                                //   setSubCategoryValue(el.id);
                                // }
                                return (
                                    <option key={index} value={el.id}>
                                        {el.subcatName}
                                    </option>
                                );
                            })
                        ]);
                        if (currentSubCat.length === 0) {
                            setSubCategoryList([
                                <option value={0}>
                                    กรุณาเพิ่มหมวดหมู่ย่อย
                                </option>
                            ]);
                        }
                    } else {
                        setSubCategoryList([
                            <option value={0}>กรุณาเพิ่มหมวดหมู่ย่อย</option>
                        ]);
                    }
                }
            }
        }
    }, [currentState.entities, categoryValue]);

    useEffect(() => {
        if (currentState2 && currentState2.entities) {
            setPublisherList([
                <option value={0} disabled selected>
                    เลือกสำนักพิมพ์
                </option>,
                ...currentState2.entities
                    .filter(el => el.status === 1)
                    .map((el, index) => {
                        // if (index === 0 && publisherValue === 0) {
                        //   setPublisherValue(el.uid);
                        // }
                        return (
                            <option key={index} value={el.uid}>
                                {el.name}
                            </option>
                        );
                    })
            ]);
            if (currentState2.entities && currentState2.entities.length === 0) {
                setPublisherList([
                    <option value={0}>กรุณาเพิ่มสำนักพิมพ์</option>
                ]);
            }
        }
    }, [currentState2.entities]);

    useEffect(() => {
        if (currentState2 && currentState2.authorsForEdit) {
            setAuthorList([
                <option data-value={0} disabled selected>
                    เลือกนักเขียน
                </option>,
                ...currentState2.authorsForEdit
                    .filter(el => el.status === 1)
                    .map((el, index) => {
                        // if (index === 0 && authorValue === 0) {
                        //   setAuthorValue(el.uid);
                        // }
                        return (
                            <option key={index} data-value={el.uid}>
                                {el.name}
                            </option>
                        );
                    })
            ]);
            if (currentState2.authorsForEdit.length === 0) {
                setAuthorList([<option value={0}>กรุณาเพิ่มนักเขียน</option>]);
            }
        }
    }, [currentState2.authorsForEdit]);

    useEffect(() => {
        if (currentState2 && currentState2.authorsForEdit) {
            setAuthorListAntd([
                { value: 0, label: 'เลือกนักเขียน' },
                ...currentState2.authorsForEdit
                    .filter(el => el.status === 1)
                    .map((el, index) => {
                        return { value: el.uid, label: el.name };
                    })
            ]);
            if (currentState2.authorsForEdit.length === 0) {
                setAuthorListAntd([{ value: 0, label: 'เลือกนักเขียน' }]);
            }
        }
    }, [currentState2.authorsForEdit]);

    useEffect(() => {
        if (publisherValue) {
            dispatch(actions.fetchAuthors('none', publisherValue, 1));
        }
    }, [publisherValue]);

    useEffect(() => {
        setCheckingError(false);
    }, [publisherValue, authorValue, categoryValue, subCategoryValue]);

    const onStartDateChange = date => {
        if (date) {
            setStartDate(date.unix());
            if (endDate && date.unix() > endDate) {
                setEndDate(null);
            }
        }
    };

    const onEndDateChange = date => {
        if (date) {
            setEndDate(date.unix());
        } else {
            setEndDate(null);
        }
    };

    const onStartDateOk = date => {
        if (date) {
            setStartDate(date.unix());
        }
    };

    const onEndDateOk = date => {
        if (date) {
            setEndDate(date.unix());
        }
    };

    const disabledEndDate = date => {
        return date && date < moment.unix(startDate).endOf('day');
    };

    const onManageLicenseChange = e => {
        if (e) {
            setRentalPeriodValue(3600);
            setAnonymousRentalPeriodValue(3600);
        } else {
            setRentalPeriodValue(null);
            setAnonymousRentalPeriodValue(null);
        }
        setIsManageLicense(e);
    };

    const handleClose = removedTag => {
        setTags(tags.filter(tag => tag !== removedTag));
    };

    const showInput = () => {
        setInputVisible(true);
    };

    useEffect(() => {
        if (inputVisible) saveInputRef.current.focus();
    }, [inputVisible]);

    const handleInputChange = e => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue.length >= 3) {
            if (inputValue && tags.indexOf(inputValue) === -1) {
                let commaSplit = inputValue.split(',');
                setTags(tags.concat(commaSplit));
            }
        } else {
            message.warning('tag ต้องมากกว่า 3 ตัวอักษร');
        }
        setInputValue('');
        setInputVisible(false);
    };

    useEffect(() => {
        // console.log(tags);
    }, [tags]);

    const onDescriptionChange = e => {
        setDescription(e.target.value);
    };

    const onHideSuccessModal = () => {
        setContentUIDToUpload(undefined);
        setSuccessModal(false);
        handleSubmitOutside();
        setTitleUsedNow(1);
    };

    const errorNumberFormat = e =>
        !e || parseInt(e, 10) < 1 || isNaN(parseInt(e, 10));

    // Validation schema
    const uploadSchema_pdf = Yup.object().shape({
        name: Yup.string()
            .min(1, 'อย่างน้อย 1 ตัวอักษร')
            .max(200, 'ไม่เกิน 200 ตัวอักษร')
            .required('กรุณากรอกชื่อ'),
        isbn: Yup.string(),
        eisbn: Yup.string(),
        numPages: Yup.number()
            .min(1, 'อย่างน้อย 1 หน้า')
            .typeError('กรุณาใช้ตัวเลข')
            .required('กรุณากรอกจำนวนหน้า')
    });

    const uploadSchema_epub = Yup.object().shape({
        name: Yup.string()
            .min(1, 'อย่างน้อย 1 ตัวอักษร')
            .max(200, 'ไม่เกิน 200 ตัวอักษร')
            .required('กรุณากรอกชื่อ'),
        isbn: Yup.string(),
        eisbn: Yup.string(),
        numPages: Yup.number()
            .min(1, 'อย่างน้อย 1 หน้า')
            .typeError('กรุณาใช้ตัวเลข')
    });

    const handlePublisherChange = e => {
        setPublisherValue(e.target.value);
        setAuthorValue(0);
    };
    const handleAuthorChange = e => {
        setAuthorValue(e);
    };
    const handleCategoryChange = e => {
        setCategoryValue(e.target.value);
        setSubCategoryValue(0);
    };
    const handleSubCategoryChange = e => {
        setSubCategoryValue(e.target.value);
    };
    const handleRentalPeriodChange = e => {
        setRentalPeriodValue(e.target.value);
    };
    const handleAnonymousRentalPeriodChange = e => {
        setAnonymousRentalPeriodValue(e.target.value);
    };
    const onSearchAuthor = keyword => {
        if (keyword !== '') {
            setAuthorListAntd(author =>
                author.filter(e =>
                    e.label.toLowerCase()?.includes(keyword.toLowerCase())
                )
            );
        } else {
            setAuthorListAntd([
                { value: 0, label: 'เลือกนักเขียน' },
                ...currentState2.authorsForEdit
                    .filter(el => el.status === 1)
                    .map((el, index) => {
                        return { value: el.uid, label: el.name };
                    })
            ]);
        }
    };
    const styles = useStyles();
    return (
        <>
            {actionsLoading && <Spinner forNewEbook={true} fullScreen={true} />}
            <Formik
                enableReinitialize={true}
                initialValues={uploadValue}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={
                    fileType === 'pdf' ? uploadSchema_pdf : uploadSchema_epub
                }
                onSubmit={values => {
                    const newValues = {
                        contentUID: contentUIDToUpload,
                        contentType: 'ebook',
                        title: values.name,
                        creatorUID: publisherValue,
                        writerUID: authorValue,
                        ISBN: values.isbn
                            ? values.isbn.replace(/[.*+\-?^${}()|[\]\\]/g, '')
                            : undefined,
                        eISBN: values.eisbn
                            ? values.eisbn.replace(/[.*+\-?^${}()|[\]\\]/g, '')
                            : undefined,
                        pageCount: values.numPages
                            ? parseInt(values.numPages, 10)
                            : 0,
                        catId: categoryValue,
                        subcatId: subCategoryValue,
                        startDate: startDate,
                        expireDate: endDate,
                        concurrent:
                            values.numLicenses &&
                            parseInt(values.numLicenses, 10),
                        tags: tags,
                        rentalPeriod:
                            rentalPeriodValue &&
                            parseInt(rentalPeriodValue, 10),
                        anonymousRentalPeriod:
                            anonymousRentalPeriodValue &&
                            parseInt(anonymousRentalPeriodValue, 10),
                        description: description
                    };
                    if (
                        publisherValue &&
                        authorValue &&
                        categoryValue &&
                        subCategoryValue &&
                        !inputValue &&
                        (!isManageLicense ||
                            (isManageLicense &&
                                !errorNumberFormat(values.numLicenses))) &&
                        filePost &&
                        coverPost
                    ) {
                        dispatch(
                            actions3.createEbook(newValues, setSuccessModal)
                        );
                        setFilePost(null);
                        setCoverPost(null);
                    }
                }}
            >
                {({ handleSubmit, values, setFieldValue }) => {
                    return (
                        <Form className="form form-label-right">
                            <CardHeader title="">
                                <CardHeaderToolbar>
                                    <button
                                        className="denyButton"
                                        type="button"
                                        onClick={() => {
                                            handleSubmitOutside('prev');
                                        }}
                                    >
                                        ย้อนกลับ
                                    </button>
                                    <button
                                        className="acceptButton"
                                        type="submit"
                                        onClick={() => {
                                            setCheckingError(true);
                                            // onHideSuccessModal();
                                        }}
                                    >
                                        เสร็จสิ้น
                                    </button>
                                </CardHeaderToolbar>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <div className="row">
                                        <div className={`col-md-12 input`}>
                                            <Field
                                                name="name"
                                                component={Input}
                                                placeholder="กรุณากรอกชื่อสื่อ"
                                                label="ชื่อสื่อ*"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className={`col-sm-4 input`}>
                                            <Select
                                                name="publisher"
                                                withFeedbackLabel={false}
                                                label="สำนักพิมพ์*"
                                                onChange={handlePublisherChange}
                                                value={publisherValue}
                                            >
                                                {publisherList}
                                            </Select>
                                            {publisherValue === 0 &&
                                                checkingError && (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกสำนักพิมพ์
                                                        </div>
                                                    </>
                                                )}
                                        </div>
                                        <div className={`col-sm-2 input`}>
                                            <Tag
                                                className={`site-tag-plus dashedBox`}
                                                style={{
                                                    position: 'absolute',
                                                    top:
                                                        window.innerWidth < 1300
                                                            ? '25px'
                                                            : '29px'
                                                }}
                                                onClick={
                                                    uploadUIProps.newPublisherButtonClick
                                                }
                                            >
                                                <PlusOutlined
                                                    style={{
                                                        marginRight: '2px'
                                                    }}
                                                />{' '}
                                                เพิ่มสำนักพิมพ์
                                            </Tag>
                                        </div>
                                        <div className={`col-sm-4 input`}>
                                            {/* <Select
                                                name="author"
                                                customFeedbackLabel={e => {
                                                    console.log(e);
                                                }}
                                                label="นักเขียน*222"
                                                onChange={handleAuthorChange}
                                                value={authorValue}
                                                search
                                            >
                                                {authorList}
                                            </Select> */}

                                            <label>นักเขียน*</label>

                                            <SelectAnt
                                                options={authorListAntd}
                                                style={{
                                                    width: '100%',
                                                    borderRadius: 6.5
                                                }}
                                                showSearch
                                                onSearch={onSearchAuthor}
                                                filterOption={false}
                                                onChange={handleAuthorChange}
                                                value={authorValue}
                                                className={classes.searchSelect}
                                                name="author"
                                            />
                                            {authorValue === 0 &&
                                                checkingError && (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกนักเขียน
                                                        </div>
                                                    </>
                                                )}
                                        </div>
                                        <div className={`col-sm-2 input`}>
                                            <Tag
                                                className={`site-tag-plus dashedBox`}
                                                style={{
                                                    position: 'absolute',
                                                    top:
                                                        window.innerWidth < 1300
                                                            ? '25px'
                                                            : '29px'
                                                }}
                                                onClick={
                                                    uploadUIProps.newAuthorButtonClick
                                                }
                                            >
                                                <PlusOutlined
                                                    style={{
                                                        marginRight: '2px'
                                                    }}
                                                />{' '}
                                                เพิ่มนักเขียน
                                            </Tag>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className={`col-md-4 input`}>
                                            <Field
                                                name="isbn"
                                                component={Input}
                                                placeholder="กรุณากรอกเลข ISBN"
                                                label="ISBN (optional)"
                                            />
                                        </div>
                                        <div className={`col-md-4 input`}>
                                            <Field
                                                name="eisbn"
                                                component={Input}
                                                placeholder="กรุณากรอกเลข EISBN"
                                                label="EISBN (optional)"
                                            />
                                        </div>
                                        <div className={`col-md-4 input`}>
                                            <Field
                                                name="numPages"
                                                component={Input}
                                                placeholder="กรุณากรอกจำนวนหน้า"
                                                label="จำนวนหน้า"
                                                type="number"
                                                disabled={
                                                    fileType === 'pdf' &&
                                                    uploadValue.numPages
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className={`col-sm-4 input`}>
                                            <Select
                                                name="category"
                                                withFeedbackLabel={false}
                                                label="หมวดหมู่หลัก*"
                                                onChange={handleCategoryChange}
                                                value={categoryValue}
                                            >
                                                {categoryList}
                                            </Select>
                                            {categoryValue === 0 &&
                                                checkingError && (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกหมวดหมู่หลัก
                                                        </div>
                                                    </>
                                                )}
                                        </div>
                                        <div className={`col-sm-2 input`}>
                                            <Tag
                                                className={`site-tag-plus dashedBox`}
                                                style={{
                                                    position: 'absolute',
                                                    top:
                                                        window.innerWidth < 1300
                                                            ? '25px'
                                                            : '29px'
                                                }}
                                                onClick={
                                                    uploadUIProps.newCategoryButtonClick
                                                }
                                            >
                                                <PlusOutlined
                                                    style={{
                                                        marginRight: '2px'
                                                    }}
                                                />{' '}
                                                หมวดหมู่หลัก
                                            </Tag>
                                        </div>
                                        <div className={`col-sm-4 input`}>
                                            <Select
                                                name="subcategory"
                                                withFeedbackLabel={false}
                                                label="หมวดหมู่ย่อย*"
                                                onChange={
                                                    handleSubCategoryChange
                                                }
                                                value={subCategoryValue}
                                            >
                                                {subCategoryList}
                                            </Select>
                                            {subCategoryValue === 0 &&
                                                checkingError && (
                                                    <>
                                                        <div className="is-invalid"></div>
                                                        <div className="invalid-feedback">
                                                            กรุณาเลือกหมวดหมู่ย่อย
                                                        </div>
                                                    </>
                                                )}
                                        </div>
                                        <div className={`col-sm-2 input`}>
                                            <Tag
                                                className={`site-tag-plus dashedBox`}
                                                style={{
                                                    position: 'absolute',
                                                    top:
                                                        window.innerWidth < 1300
                                                            ? '25px'
                                                            : '29px'
                                                }}
                                                onClick={
                                                    uploadUIProps.newSubCategoryButtonClick
                                                }
                                            >
                                                <PlusOutlined
                                                    style={{
                                                        marginRight: '2px'
                                                    }}
                                                />{' '}
                                                หมวดหมู่ย่อย
                                            </Tag>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className={`col-md-4 input`}>
                                            <p
                                                style={{
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                วันที่เริ่มเผยแพร่*
                                            </p>
                                            <DatePicker
                                                format={'DD-MM-YYYY HH:mm:ss'}
                                                // className={checkingError ? "form-control is-valid" : "form-control"}
                                                className="form-control"
                                                placeholder="เลือกวันที่เริ่มเผยแพร่"
                                                defaultValue={moment()}
                                                showTime
                                                onChange={onStartDateChange}
                                                onOk={onStartDateOk}
                                                allowClear={false}
                                            />
                                        </div>
                                        <div className={`col-md-4 input`}>
                                            <p
                                                style={{
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                วันที่หมดอายุ (optional)
                                            </p>
                                            <DatePicker
                                                format={'DD-MM-YYYY HH:mm:ss'}
                                                // className={checkingError ? "form-control is-valid" : "form-control"}
                                                className="form-control"
                                                placeholder="เลือกวันที่หมดอายุ"
                                                disabledDate={disabledEndDate}
                                                showTime
                                                onChange={onEndDateChange}
                                                onOk={onEndDateOk}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className={`col-md-2 input`}>
                                            <p
                                                style={{
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                จัดการสิทธิ์
                                            </p>
                                            <Switch
                                                onChange={onManageLicenseChange}
                                                checked={isManageLicense}
                                                checkedChildren="เปิด"
                                                unCheckedChildren="ปิด"
                                            />
                                        </div>
                                        {isManageLicense ? (
                                            <>
                                                <div
                                                    className={`col-md-3 input`}
                                                >
                                                    <Field
                                                        name="numLicenses"
                                                        // className={
                                                        //   !errorNumberFormat(values["numLicenses"]) && checkingError
                                                        //     ? "form-control is-valid"
                                                        //     : "form-control"
                                                        // }
                                                        className="form-control"
                                                        component={Input}
                                                        placeholder="กรุณากรอกจำนวนสิทธิ์"
                                                        label="จำนวนสิทธิ์*"
                                                        type="number"
                                                    />
                                                    {errorNumberFormat(
                                                        values['numLicenses']
                                                    ) &&
                                                        checkingError && (
                                                            <>
                                                                <div className="is-invalid"></div>
                                                                <div className="invalid-feedback">
                                                                    กรุณากรอกจำนวนสิทธิ์ให้ถูกต้อง
                                                                </div>
                                                            </>
                                                        )}
                                                </div>
                                                <div
                                                    className={`col-md-3 input`}
                                                >
                                                    <Select
                                                        name="rentalPeriod"
                                                        withFeedbackLabel={
                                                            false
                                                        }
                                                        label="เวลาในการยืมแบบสมาชิก"
                                                        onChange={
                                                            handleRentalPeriodChange
                                                        }
                                                        value={
                                                            rentalPeriodValue
                                                        }
                                                    >
                                                        {rentalPeriodList}
                                                    </Select>
                                                </div>
                                                <div
                                                    className={`col-md-4 input`}
                                                >
                                                    <Select
                                                        name="anonymousRentalPeriod"
                                                        withFeedbackLabel={
                                                            false
                                                        }
                                                        label="เวลาในการยืมแบบไม่ระบุตัวตน"
                                                        onChange={
                                                            handleAnonymousRentalPeriodChange
                                                        }
                                                        value={
                                                            anonymousRentalPeriodValue
                                                        }
                                                    >
                                                        {rentalPeriodList}
                                                    </Select>
                                                </div>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className="row">
                                        <div className={`col-md-12 input`}>
                                            <p
                                                style={{
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                Tags
                                            </p>
                                            {tags.map(tag => {
                                                const isLongTag =
                                                    tag.length > 20;

                                                const tagElem = (
                                                    <Tag
                                                        className="edit-tag"
                                                        key={tag}
                                                        closable={true}
                                                        onClose={() =>
                                                            handleClose(tag)
                                                        }
                                                        style={{
                                                            display:
                                                                'inline-flex',
                                                            alignItems:
                                                                'center',
                                                            fontSize: '13px',
                                                            height: '32px'
                                                        }}
                                                    >
                                                        <span>
                                                            {isLongTag
                                                                ? `${tag.slice(
                                                                      0,
                                                                      20
                                                                  )}...`
                                                                : tag}
                                                        </span>
                                                    </Tag>
                                                );
                                                return isLongTag ? (
                                                    <Tooltip
                                                        title={tag}
                                                        key={tag}
                                                    >
                                                        {tagElem}
                                                    </Tooltip>
                                                ) : (
                                                    tagElem
                                                );
                                            })}
                                            {inputVisible && (
                                                <Input2
                                                    ref={saveInputRef}
                                                    type="text"
                                                    size="small"
                                                    className="tag-input"
                                                    value={inputValue}
                                                    onChange={handleInputChange}
                                                    onBlur={handleInputConfirm}
                                                    onPressEnter={
                                                        handleInputConfirm
                                                    }
                                                    style={{
                                                        width: '100px',
                                                        height: '32px'
                                                    }}
                                                />
                                            )}
                                            {!inputVisible && (
                                                <Tag
                                                    className={`site-tag-plus dashedBox`}
                                                    onClick={showInput}
                                                >
                                                    <PlusOutlined
                                                        style={{
                                                            marginRight: '2px'
                                                        }}
                                                    />{' '}
                                                    เพิ่ม Tag
                                                </Tag>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className={`col-md-12 input`}>
                                            <p
                                                style={{
                                                    marginBottom: '0.5rem'
                                                }}
                                            >
                                                รายละเอียดหนังสือ
                                            </p>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={{
                                                    toolbar: [
                                                        'bold',
                                                        'italic',
                                                        'bulletedList',
                                                        'numberedList'
                                                    ]
                                                }}
                                                data={
                                                    description
                                                        ? description
                                                        : ''
                                                }
                                                onReady={editor => {
                                                    // You can store the "editor" and use when it is needed.
                                                    // console.log("Editor is ready to use!", editor);
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setDescription(data);
                                                }}
                                                onBlur={(event, editor) => {
                                                    // console.log("Blur.", editor);
                                                }}
                                                onFocus={(event, editor) => {
                                                    // console.log("Focus.", editor);
                                                }}
                                            />
                                            {/*<TextArea
                          name="description"
                          placeholder="กรอกรายละเอียดเกี่ยวกับสื่อ"
                          value={description}
                          onChange={onDescriptionChange}
                          rows={4}
                        />*/}
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Form>
                    );
                }}
            </Formik>

            <ModalsSuccess
                show={successModal}
                onHide={() => onHideSuccessModal()}
                onClickAccept={() => {
                    onHideSuccessModal();
                    onHide();
                }}
            />
        </>
    );
};

export default FormForFile;
