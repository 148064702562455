import { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { withRouter } from "react-router-dom";
import { resetPassword } from "../_redux/authCrud";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ModalsSuccess } from "../../CustomView";
import { HtiResponse } from "../../../../interface";

const initialValues = {
  confirmNewPassword: "",
  newPassword: "",
};

function ForgotPassword(props: any) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(6, "อย่างน้อย 6 ตัวอักษร")
      .max(50, "ไม่เกิน 50 ตัวอักษร")
      .matches(/^[A-Za-z0-9]+$/, "ใช้ตัวอักษรภาษาอังกฤษหรือตัวเลขเท่านั้น")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    confirmNewPassword: Yup.string()
      .min(6, "อย่างน้อย 6 ตัวอักษร")
      .max(50, "ไม่เกิน 50 ตัวอักษร")
      .matches(/^[A-Za-z0-9]+$/, "ใช้ตัวอักษรภาษาอังกฤษหรือตัวเลขเท่านั้น")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const [successModal, setSuccessModal] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (values.confirmNewPassword === values.newPassword) {
        resetPassword(values.newPassword, props.uid, props.otp, props.refCode)
          .then((response) => {
            const data: HtiResponse = response.data;
            if (data.status) {
              setSuccessModal(true);
              props.deleteNewPasswordToken();
            }
          })
          .catch(() => {
            setIsRequested(false);
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN_PASSWORD",
              })
            );
          });
      } else {
        setStatus(
          intl.formatMessage({
            id: "AUTH.VALIDATION.INVALID_CONFIRM_NEWPASSWORD",
          })
        );
      }
    },
  });
  const [seeNewPW, setSeeNewPW] = useState(false);
  const [seeConfirmNewPW, setSeeConfirmNewPW] = useState(false);

  return (
    <>
      <ModalsSuccess
        show={successModal}
        onHide={() => {
          setIsRequested(true);
          setSuccessModal(false);
        }}
        onClickAccept={() => {
          setIsRequested(true);
          setSuccessModal(false);
        }}
      />
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <h3 className="font-size-h1">รหัสผ่านใหม่</h3>
              <input
                placeholder="รหัสผ่านใหม่"
                className={`form-control form-control-solid h-auto py-5 px-6`}
                type={seeNewPW ? "text" : "password"}
                title="newPassword"
                autoComplete="new-password"
                {...formik.getFieldProps("newPassword")}
              />
              {formik.values.newPassword ? (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="quick-user-tooltip">
                      {seeNewPW ? "ซ่อนรหัสผ่าน" : "แสดงรหัสผ่าน"}
                    </Tooltip>
                  }
                >
                  <img
                    src={
                      seeNewPW
                        ? `/media/auth/eye-slash.svg`
                        : `/media/auth/eye.svg`
                    }
                    alt=""
                    onClick={() => {
                      setSeeNewPW(!seeNewPW);
                    }}
                    className="button-hidepass"
                  />
                </OverlayTrigger>
              ) : (
                ""
              )}
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.newPassword}
                  </div>
                </div>
              ) : null}
              <input
                placeholder="ยืนยันรหัสผ่านใหม่"
                className={`form-control form-control-solid h-auto py-5 px-6 mt`}
                type={seeConfirmNewPW ? "text" : "password"}
                title="confirmNewPassword"
                autoComplete="new-password"
                {...formik.getFieldProps("confirmNewPassword")}
              />
              {formik.values.confirmNewPassword ? (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="quick-user-tooltip">
                      {seeConfirmNewPW ? "ซ่อนรหัสผ่าน" : "แสดงรหัสผ่าน"}
                    </Tooltip>
                  }
                >
                  <img
                    src={
                      seeConfirmNewPW
                        ? `/media/auth/eye-slash.svg`
                        : `/media/auth/eye.svg`
                    }
                    alt=""
                    onClick={() => {
                      setSeeConfirmNewPW(!seeConfirmNewPW);
                    }}
                    className="button-hidepass"
                  />
                </OverlayTrigger>
              ) : (
                ""
              )}
              {formik.touched.confirmNewPassword &&
              formik.errors.confirmNewPassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.confirmNewPassword}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="acceptButton"
                disabled={
                  formik.isSubmitting ||
                  formik.errors.newPassword !== undefined ||
                  formik.errors.confirmNewPassword !== undefined ||
                  !formik.values["newPassword"] ||
                  !formik.values["confirmNewPassword"]
                }
              >
                ยืนยัน
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(
  withRouter(connect(null, auth.actions)(ForgotPassword))
);
