/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { useEffect, useMemo, useState } from 'react';
import { Redirect, Switch, Route, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { actions } from '../app/modules/Auth/_redux/authRedux';
import { Layout } from '../_metronic/layout';
import BasePage from './BasePage';
import { Logout, AuthPage } from './modules/Auth';
import { createGlobalStyle } from 'styled-components';
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage';
import { HibraryRootState } from '../redux/rootReducer';
import * as auth from './modules/Auth/_redux/authRedux';

export function Routes() {
    const dispatch = useDispatch();
    const [showGoToTop, setShowGoToTop] = useState(false);
    const [GlobalStyle, setGlobalStyle] = useState(createGlobalStyle({}));
    const { search } = useLocation();

    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const isJWT = (token: string): boolean => {
        const jwtPattern = /^[A-Za-z0-9_-]+\.[A-Za-z0-9_-]+\.[A-Za-z0-9_-]+$/;
        return jwtPattern.test(token);
    };

    useEffect(() => {
        // add a default parameter if not set

        const token = searchParams.get('auth');
        console.log(token);
        if (token !== undefined && token !== null && isJWT(token)) {
            dispatch(auth.actions.login(token));
        }
    }, [searchParams]);

    useEffect(() => {
        window.addEventListener('scroll', function(event) {
            var scroll = this.scrollY;
            if (scroll > 700) {
                setShowGoToTop(true);
            } else {
                setShowGoToTop(false);
            }
        });

        const regMath = new RegExp(
            `${process.env.REACT_APP_API_CU_DOMAIN}`,
            'i'
        );
        if (window.location.hostname.match(regMath)) {
            document.documentElement.style.setProperty('--primary', '#DF588A');
            // is cu domain

            setGlobalStyle(
                createGlobalStyle({
                    html: {
                        '--primary-color': '#DF588A'
                    }
                })
            );

            dispatch(actions.setIsCuDomain(true));
        } else {
            document.documentElement.style.setProperty('--primary', '#012875');

            // not cu domain
            setGlobalStyle(
                createGlobalStyle({
                    html: {
                        '--primary-color': '#012875'
                    }
                })
            );

            dispatch(actions.setIsCuDomain(false));
        }
    }, []);
    //
    const { authenState } = useSelector(
        (state: HibraryRootState) => ({
            authenState: state.auth
        }),
        shallowEqual
    );
    const gotoTop = () => {
        if (showGoToTop) {
            return (
                <img
                    src="/media/members/goToTop.png"
                    alt=""
                    className="gotoTop"
                    onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                />
            );
        } else {
            return '';
        }
    };

    return (
        <>
            {gotoTop()}
            <GlobalStyle />
            <Switch>
                <Route path="/error" component={ErrorsPage} />
                <Route path="/logout" component={Logout} />
                {authenState.authToken === undefined ? (
                    <>
                        <Redirect to="/" />
                        <Route>
                            <AuthPage />
                        </Route>
                    </>
                ) : (
                    <Layout>
                        <BasePage />
                    </Layout>
                )}
            </Switch>
        </>
    );
}
