import { Dispatch } from "redux";
import { Content } from "../../../../../interface/ebook";
import { deleteExpiredContent, getAllEbooks } from "../ebooks/ebooksCrud";

interface ExpireAction {
  type: ExpireActionTypes;
  payload?: ExpireState;
}

export enum ExpireActionTypes {
  setContentExpire = "[Expire] setContentExpire",
  getContentExpire = "[Expire] getContentExpire",
  removeContentExpire = "[Expire] removeContentExpire",
  reset = "[Expire] reset",
  setLoading = "[Expire] setLoading",
}

export interface ExpireState {
  contentExpire?: Content[];
  lastEvaluatedKey?: string;
  loading?: boolean;
  contentUID?: string;
}

const initialExpireState: ExpireState = {
  contentExpire: undefined,
};

export const expireActions = {
  setContentExpire: (contentExpire: Content[], lastEvaluatedKey?: string) => ({
    type: ExpireActionTypes.setContentExpire,
    payload: { contentExpire, lastEvaluatedKey },
  }),

  setLoading: (loading: boolean) => ({
    type: ExpireActionTypes.setLoading,
    payload: { loading },
  }),

  getContentExpire: async (dispatch: Dispatch, lastEvaluatedKey?: string) => {
    try {
      dispatch(expireActions.setLoading(true));
      let { data } = await getAllEbooks(true, lastEvaluatedKey);
      dispatch(expireActions.setLoading(false));
      if (data && data.data) {
        dispatch(
          expireActions.setContentExpire(
            data.data.contents,
            data.data.LastEvaluatedKey as string
          )
        );
        return;
      } else {
        return;
      }
    } catch (error) {
      dispatch(expireActions.setLoading(false));
      return;
    }
  },

  removeContentExpire: async (
    dispatch: Dispatch,
    isAll?: boolean,
    contentUID?: string
  ) => {
    dispatch(expireActions.setLoading(true));
    let data = await deleteExpiredContent(isAll, contentUID);
    dispatch(expireActions.setLoading(false));
    console.log(data);

    if (data.status) {
      if (isAll) {
        dispatch(expireActions.reset());
      } else {
        dispatch({
          type: ExpireActionTypes.removeContentExpire,
          payload: { contentUID },
        });
      }

      return;
    } else {
      return;
    }
  },

  reset: () => ({
    type: ExpireActionTypes.reset,
  }),
};

export const reducer = (
  state: ExpireState = initialExpireState,
  action: ExpireAction
): ExpireState => {
  switch (action.type) {
    case ExpireActionTypes.setContentExpire: {
      return {
        ...state,
        contentExpire:
          state.contentExpire && action.payload?.contentExpire
            ? state.contentExpire.concat(action.payload?.contentExpire)
            : action.payload?.contentExpire,
        lastEvaluatedKey: action.payload?.lastEvaluatedKey,
      };
    }
    case ExpireActionTypes.setLoading: {
      return {
        ...state,
        loading: action.payload?.loading,
      };
    }
    case ExpireActionTypes.removeContentExpire: {
      if (action.payload?.contentUID) {
        let newContent = state.contentExpire?.filter(
          (el) => el.contentUID !== action.payload?.contentUID
        );
        console.log(newContent);
        return {
          ...state,
          contentExpire: newContent,
        };
      } else {
        return { ...state };
      }
    }

    case ExpireActionTypes.reset: {
      return initialExpireState;
    }

    default:
      return { ...state };
  }
};
