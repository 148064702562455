/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useMemo, useState, useEffect } from 'react';
import {
    Tabs,
    Row,
    Col,
    Divider,
    Skeleton,
    Button,
    Dropdown,
    Menu,
    Switch
} from 'antd';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    Input
} from '../../../../../_metronic/_partials/controls';
import dayjs from 'dayjs';
import './CatalogueCard.scss';
import { useCatalogueUIContext } from './CatalogueUIContext';
import { makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import FastAverageColor from 'fast-average-color';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import * as actions from '../../_redux/catalogue/catalogueActions';
import { Modal } from 'react-bootstrap';
import { isEqual, isNil } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CatalogueSummary } from './CatalogueSummary';
import jwt_decode from 'jwt-decode';
import { dateStr, timeStr } from '../../partials/timeStampToDate';
import { useStyles } from '../../partials/useStyles';
import { CatalogueFilter } from './catalogue-filter/CatalogueFilter';
import * as util from './CatalogueElement';
import { NodataView, ModalsSuccess } from '../../../CustomView';
import { Role } from '../../../../../interface';
import CreateVoteDialog from './create-vote-dialog/CreateVoteDialog';
import { stopCatalogVote } from '../../_redux/catalogue/catalogueCrud';
import VoteAlertModal from '../../../CustomView/modal/voteCatalogue/VoteAlertModa';
require('dayjs/locale/th');

const minimumTwoBooks = [
    // '350b7d19-633c-426a-bdde-8c80f38c4cdf', //matichon
    // 'e13b2db7-4652-4edf-a55a-cc6a4f851b6e', //tpabooks
    // '292da99f-931e-4598-8c6a-fa296b7eb5c1' //TPAPressbooks
];

const ebookBox_list = [
    {
        catId: 1,
        catName: 'สำนักพิมพ์/นักเขียน',
        date: '04/04/2564',
        status: 1,
        licenses: 10,
        email: 'nan@email.com',
        coverImage: '/media/members/catalogue-publisher.png',
        clickFor: 'publishers',
        bgColor: '#2D9CDB'
    },
    {
        catId: 3,
        catName: 'หมวดหมู่',
        date: '04/04/2564',
        status: 1,
        licenses: 10,
        email: 'nan@email.com',
        coverImage: '/media/members/catalogue-category.png',
        clickFor: 'categories',
        bgColor: '#FBC020'
    },
    {
        catId: 4,
        catName: 'มาใหม่',
        date: '04/04/2564',
        status: 1,
        licenses: 10,
        email: 'nan@email.com',
        coverImage: '/media/members/catalogue-new-ebook.png',
        clickFor: 'newEbooks',
        bgColor: '#219653'
    }
];

const useStyles1 = makeStyles(theme => ({
    div: {
        marginBottom: -29,
        marginTop: 0,
        zIndex: 2
    },
    p1: {
        fontFamily: 'Prompt',
        fontSize: '20px',
        fontWeight: 600,
        color: '#012875',
        margin: '32px 0 0 32px'
    },
    p2: {
        fontFamily: 'Prompt',
        fontSize: '13px',
        color: '#fd6b53'
    },
    votePeriod: {
        fontWeight: 500
    },
    button: {
        borderRadius: 100,
        backgroundColor: '#012875',
        marginLeft: 8,
        padding: '0px 22px',
        height: 40,
        color: 'white',
        fontSize: 13,
        fontWeight: 600,
        fontFamily: 'Prompt',
        border: 'none',
        '&:disabled': {
            cursor: 'not-allowed',
            opacity: 1,
            borderColor: 'transparent !important',
            backgroundColor: '#E0E0E0 !important',
            color: '#828282 !important'
        }
    },
    buttonSecondary: {
        borderRadius: 100,
        backgroundColor: '#FD6B53',
        marginLeft: 8,
        padding: '0px 22px',
        height: 40,
        color: 'white',
        fontSize: 13,
        fontWeight: 600,
        fontFamily: 'Prompt',
        border: 'none',
        '&:disabled': {
            cursor: 'not-allowed',
            opacity: 1,
            borderColor: 'transparent !important',
            backgroundColor: '#E0E0E0 !important',
            color: '#828282 !important'
        },
        '&:focus': {
            backgroundColor: '#FD6B53',
            color: 'white'
        },
        '&:hover': {
            backgroundColor: '#FD6B53',
            color: 'white',
            filter: 'brightness(120%)'
        }
    },
    buttonSort: {
        display: 'flex',
        alignItems: 'center',
        gap: 5
    },
    buttonskyblue: {
        borderRadius: 100,
        backgroundColor: '#0066FF',
        marginLeft: 8,
        padding: '0px 22px',
        height: 40,
        color: 'white',
        fontSize: 13,
        fontWeight: 600,
        fontFamily: 'Prompt',
        border: 'none',
        '&:disabled': {
            cursor: 'not-allowed',
            opacity: 1,
            borderColor: 'transparent !important',
            backgroundColor: '#E0E0E0 !important',
            color: '#828282 !important'
        }
    },
    buttonMaxHeight: {
        borderRadius: 100,
        backgroundColor: '#012875',
        marginLeft: 8,
        padding: '0px 22px',
        color: 'white',
        fontSize: 13,
        fontWeight: 600,
        fontFamily: 'Prompt',
        border: 'none',
        '&:disabled': {
            cursor: 'not-allowed',
            opacity: 1,
            borderColor: 'transparent !important',
            backgroundColor: '#E0E0E0 !important',
            color: '#828282 !important'
        },
        height: '40px'
    },
    dropdown1: {
        fontFamily: 'Prompt',
        paddingLeft: 10,
        paddingRight: 0
    },
    dropdown2: {
        fontFamily: 'Prompt',
        paddingLeft: 6,
        paddingRight: 12,
        paddingBottom: 6
    },
    stepDiv: {
        width: '200px',
        height: '48px',
        borderRadius: '20px 20px 0px 0px',
        margin: '0 -8.5px 0 12.5px',
        backgroundColor: 'white',
        padding: '12.5px',
        textAlign: 'center'
    },
    stepDivBlack: {
        width: '200px',
        height: '48px',
        borderRadius: '20px 20px 0px 0px',
        margin: '0 -8.5px 0 12.5px',
        backgroundColor: '#e0e0e0',
        padding: '12.5px',
        textAlign: 'center',
        '&:hover': { cursor: 'pointer' }
    },
    stepDivBlack_wait: {
        width: '200px',
        height: '48px',
        borderRadius: '20px 20px 0px 0px',
        margin: '0 -8.5px 0 12.5px',
        backgroundColor: '#e0e0e0',
        padding: '12.5px',
        textAlign: 'center',
        '&:hover': { cursor: 'wait' }
    },
    closeButton: {
        width: '30px',
        height: '30px',
        border: 'none',
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: 0,
        marginBottom: 10
    },
    stepDiv_p: {
        display: 'inline-block',
        fontSize: '20px',
        color: '#FD6B53',
        fontWeight: 600
    },
    stepDivBlack_p: {
        display: 'inline-block',
        fontSize: '20px',
        color: '#828282',
        fontWeight: 600
    },
    divLicense: {
        textAlign: 'center',
        // marginRight: "16px",
        display: 'flex',
        alignItems: 'center'
    },
    orangeText16: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#FD6B53'
    },
    firstDigit: {
        fontSize: '20px',
        fontWeight: 600,
        color: '#012875',
        marginLeft: '8px'
    },
    summary: { minHeight: '100vh' },
    secondDigit: { fontSize: '20px', fontWeight: 600, color: '#828282' },
    colCatalogue: {
        padding: '8px',
        backgroundColor: '#3F3428',
        borderRadius: 4,
        '&:hover': {
            cursor: 'pointer',
            '-webkit-filter': 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2))',
            filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2))',
            '-webkit-box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.2)',
            'box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.2)'
        }
    },
    colCatalogueType: {
        width: '230px',
        padding: '8px',
        backgroundColor: '#3F3428',
        borderRadius: 4,
        '&:hover': {
            cursor: 'pointer',
            '-webkit-filter': 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2))',
            filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2))',
            '-webkit-box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.2)',
            'box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.2)'
        },
        marginTop: '4px',
        '-webkit-filter': 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))',
        filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))',
        '-webkit-box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.1)',
        'box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.1)'
    },
    colType: {
        padding: '8px !important',
        gridRowGap: '16px',
        rowGap: '16px'
    },
    colCatalogueDetail: {
        padding: '8px'
    },
    catalogueDetail: {
        display: 'inline-block',
        // transform: "translateY(10px)",
        fontSize: 12,
        marginBottom: 12,
        width: '65%'
    },
    catalogueDetailDetail: {
        display: 'inline-block',
        // transform: "translateY(6px)",
        color: '#828282',
        maxWidth: 'calc(100% - 135px - 8px)'
    },
    catalogueDetailLicense: {
        color: '#4F4F4F',
        fontSize: '15px',
        marginTop: '0px',
        marginBottom: '0px'
    },
    greyDigit: {
        display: 'inline-flex',
        margin: '0 12px',
        width: 30,
        justifyContent: 'center',
        fontSize: 20,
        fontWeight: 500,
        transform: 'translateY(4px)'
    },
    blueDigit: {
        display: 'inline-flex',
        margin: '0 12px',
        width: 30,
        justifyContent: 'center',
        fontSize: 20,
        fontWeight: 500,
        transform: 'translateY(4px)',
        color: '#012875'
    },
    catalogueTitle: {
        fontSize: '16px',
        fontWeight: 500,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        // lineHeight: 20px;
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical'
    },
    catalogueTitle18: {
        fontSize: '18px',
        fontWeight: 500,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        // lineHeight: 20px;
        '-webkit-line-clamp': '1',
        '-webkit-box-orient': 'vertical'
    },
    catalogueDetailTitle: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#4f4f4f',
        '&:hover': { textDecoration: 'underline', cursor: 'pointer' }
    },
    catalogueDetailImage: {
        float: 'left',
        '&:hover': { cursor: 'pointer' },
        width: '135px',
        marginRight: '8px',
        '-webkit-filter': 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))',
        filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))',
        '-webkit-box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.1)',
        'box-shadow': '0px 0px 4px rgba(0, 0, 0, 0.1)'
    },
    flex: {
        display: 'flex',
        paddingLeft: '32px'
    },
    orangeDash: {
        height: '2px',
        width: '100%',
        backgroundColor: '#FD6B53',
        '&:hover': { cursor: 'pointer' }
    },
    transparentDash: {
        height: '2px',
        width: '100%',
        backgroundColor: 'transparent'
    },
    licenseAddRemove: {
        imageRendering: '-webkit-optimize-contrast',
        '&:hover': { cursor: 'pointer' }
    },
    licenseAddRemoveDisabled: {
        imageRendering: '-webkit-optimize-contrast',
        '&:hover': { cursor: 'not-allowed' }
    },
    marginBottom2px: {
        marginBottom: 2
    },
    updateConcurrentColumn: {
        display: 'flex',
        marginBottom: 2,
        gap: 50
    },
    redLabel: {
        color: '#F64E60'
    },
    orangeLabel: {
        color: '#DAA520'
    },
    greenLabel: {
        color: '#219653'
    },
    hoverLink: {
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },
    deleteCatalogButton: {
        right: '14px',
        marginLeft: '0.75rem',
        position: 'absolute',
        bottom: '6px'
    }
}));

const useStyles2 = makeStyles(theme => ({
    input: { margin: '12px 0' },
    body: { margin: '0' },
    divSuccess: {
        textAlign: 'center',
        width: '340px',
        height: '360px',
        borderRadius: '10px',
        backgroundColor: '#fff'
    },
    divInitial: {
        textAlign: 'center',
        width: '340px',
        borderRadius: '10px',
        backgroundColor: '#fff'
    },
    divText: { fontSize: '24px', fontWeight: '600', color: '#012875' },
    button: {
        marginTop: '32px',
        fontSize: '17px',
        fontWeight: '600',
        width: '200px',
        height: '48px',
        borderRadius: '100px',
        backgroundColor: '#012875',
        border: 'none',
        color: 'white',
        '&:disabled': {
            cursor: 'not-allowed',
            opacity: 1,
            borderColor: 'transparent !important',
            backgroundColor: '#E0E0E0 !important',
            color: '#828282 !important'
        }
    },
    acceptButton: {
        margin: '8px',
        width: '150px',
        height: '48px',
        borderRadius: '100px',
        backgroundColor: '#012875',
        border: 'none',
        color: 'white',
        fontWeight: '600',
        fontSize: '16px',
        cursor: 'pointer',
        '&:disabled': {
            backgroundColor: '#e0e0e0',
            color: '#828282',
            '&:hover': {
                cursor: 'not-allowed'
            }
        }
    },
    divButton: { marginTop: '0', textAlign: 'center' }
}));

export function CatalogueCard({
    isPrivate,
    isCatalogueDetail,
    id,
    isNewEbook,
    isPublishers,
    isNewEbooks,
    isEbooks,
    isExample,
    isCategories,
    isSubcategories,
    publisherId,
    catId,
    subcatId,
    isPureCatalogue,
    onHide
}) {
    const catalogueUIContext = useCatalogueUIContext();

    const catalogueUIProps = useMemo(() => {
        return {
            ids: catalogueUIContext.ids,
            newCatalogueButtonClick: catalogueUIContext.newCatalogueButtonClick,
            queryParams: catalogueUIContext.queryParams,
            setQueryParams: catalogueUIContext.setQueryParams
        };
    }, [catalogueUIContext]);
    const catalogueFilter = () => {
        return <CatalogueFilter isPrivate={isPrivate} />;
    };

    const modalStyles = useStyles();
    const classes = useStyles1();
    const styles = useStyles2();
    const dispatch = useDispatch();
    const stepLabel = ['สื่อ Hibrary', 'สื่อองค์กร'];
    const [activeTab, setActiveTab] = useState(0);
    const loaderHeight = 50;

    const [catalogSummary, setCatalogSummary] = useState();
    const [processingAmount, setProcessingAmount] = useState(0);
    const [submitAmount, setSubmitAmount] = useState(0);
    const [resultAmount, setResultAmount] = useState(0);
    const [completedAmount, setCompletedAmount] = useState(0);

    const [remainConcurrent, setRemainConcurrent] = useState(0);
    // const [concurrent, setConcurrent] = useState(0);
    // const [credit, setCredit] = useState(0);
    const [totalConcurrent, setTotalConcurrent] = useState(0);
    const [originConcurrent, setOriginConcurrent] = useState(0);
    // const [originConcurrentIon, setOriginConcurrentIon] = useState(0);
    const [saved, setSaved] = useState(false);
    const [loadingSaving, setLoadingSaving] = useState(false);
    const [loadingSavingAdd, setLoadingSavingAdd] = useState(false);
    const [catalogueEbooks, setCatalogueEbooks] = useState({});
    // const [catalogueEbooksIon, setCatalogueEbooksIon] = useState({});
    const [loadingBottom, setLoadingBottom] = useState(false);
    const [loadingBottomRow, setLoadingBottomRow] = useState(false);
    const [loadingStopVote, setLoadingStopVote] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [summaryModal, setsummaryModal] = useState(false);
    const [exampleBox_list, setExampleBox_list] = useState(null);
    const [selectedPreset, setSelectedPreset] = useState(-1);
    const [initialCreditModal, setInitialConcurrentModal] = useState(false);
    const [submitModal, setSubmitModal] = useState(false);
    const [rejectModal, setRejectModal] = useState(false);
    const [alertModal, setAlertModal] = useState(false);
    const [completeRejectModal, setCompleteRejectModal] = useState(false);
    const [searchLoad, setSearchLoad] = useState(false);

    const [createVoteModal, setCreateVoteModal] = useState(false);
    const [sortByAsc, setSortByAsc] = useState(false);
    // const [exam]
    const footerHeight = 300;
    const history = useHistory();
    const params = history.location.search;
    const catalogId = new URLSearchParams(params).get('id');
    const { authState } = useSelector(
        ({ auth }) => ({
            authState: auth
        }),
        shallowEqual
    );
    const { role, viewAsToken } = authState;

    const {
        listLoading,
        actionsLoading,
        actionsLoading_catalogueForEdit,
        entities: catalogue_list,
        entities_publishers: publishers_list,
        entities_categories: categories_list,
        entities_search,
        catalogueForEdit,
        publisherContents,
        newEbooksContents,
        categoryContents,
        LastEvaluatedKey_publisherContents,
        LastEvaluatedKey_newEbooksContents,
        LastEvaluatedKey_categoryContents,
        LastEvaluatedKey_search,
        error,
        // remainConcurrent,
        memberMode,
        LastEvaluatedKey_catalogueForEdit,
        publisherName,
        catName,
        subcats: subcats_list,
        subcatName,
        contentAmount,
        catalogue_contentAmount,
        voteStatus,
        voteStartDate,
        voteEndDate
    } = useSelector(
        state => ({
            listLoading: state.catalogue.listLoading,
            actionsLoading: state.catalogue.actionsLoading,
            actionsLoading_catalogueForEdit:
                state.catalogue.actionsLoading_catalogueForEdit,
            entities: state.catalogue.entities,
            entities_publishers: state.catalogue.entities_publishers,
            entities_categories: state.catalogue.entities_categories,
            entities_search: state.catalogue.entities_search,
            catalogueForEdit: state.catalogue.catalogueForEdit,
            publisherContents: state.catalogue.publisherContents,
            newEbooksContents: state.catalogue.newEbooksContents,
            categoryContents: state.catalogue.categoryContents,
            LastEvaluatedKey_publisherContents:
                state.catalogue.LastEvaluatedKey_publisherContents,
            LastEvaluatedKey_newEbooksContents:
                state.catalogue.LastEvaluatedKey_newEbooksContents,
            LastEvaluatedKey_categoryContents:
                state.catalogue.LastEvaluatedKey_categoryContents,
            LastEvaluatedKey_search: state.catalogue.LastEvaluatedKey_search,
            error: state.catalogue.error,
            // remainConcurrent: state.catalogue.remainConcurrent,
            memberMode: state.catalogue.memberMode,
            LastEvaluatedKey_catalogueForEdit:
                state.catalogue.LastEvaluatedKey_catalogueForEdit,
            publisherName: state.catalogue.publisherName,
            catName: state.catalogue.catName,
            subcats: state.catalogue.subcats,
            subcatName: state.catalogue.subcatName,
            contentAmount: state.catalogue.contentAmount,
            catalogue_contentAmount: state.catalogue.catalogue_contentAmount,
            voteStatus: state.catalogue.voteStatus,
            voteStartDate: state.catalogue.voteStartDate,
            voteEndDate: state.catalogue.voteEndDate
        }),
        shallowEqual
    );

    const isVoted = voteStatus === 1;
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const fetchCatalogSummary = async () => {
        try {
            const req = await axios.get(`/admin/catalogs/summary`);
            const { data } = req;
            if (data.status && data.data) {
                setCatalogSummary(data.data);
            }
        } catch (error) {}
    };

    const fetchExampleSet = () => {
        const reqFunc = async () => {
            const presetReq = await axios.get(
                `/superuser/hibrary/contents/presets`
            );
            if (presetReq.data.status && presetReq.data.data) {
                setExampleBox_list(presetReq.data.data.presets);
            }
        };
        reqFunc();
    };

    useEffect(() => {
        const handleBackButton = event => {
            // Your alert logic here
            if (localStorage.getItem('catalogueEbooks') !== null) {
                if (window.confirm('คุณต้องการบันทึกข้อมูลหรือไม่')) {
                    updateCatalogueEbooks('update');
                } else {
                    localStorage.removeItem('catalogueEbooks');
                    setCatalogueEbooks({});
                }
            }
        };
        window.addEventListener('popstate', handleBackButton);
        window.addEventListener('beforeunload', () =>
            localStorage.removeItem('catalogueEbooks')
        );
        //.

        return () => {
            //     window.removeEventListener('popstate', handleBackButton);
            localStorage.removeItem('catalogueEbooks');
        };
    }, []);

    useEffect(() => {
        const store = localStorage.getItem('catalogueEbooks');
        if (store !== null) {
            if (window.confirm('คุณต้องการบันทึกข้อมูลหรือไม่')) {
                updateCatalogueEbooks('update');
            } else {
                localStorage.removeItem('catalogueEbooks');
                setCatalogueEbooks({});
            }
        }
    }, [history.location]);

    useEffect(() => {
        if (catalogSummary) {
            // if(catalogSummary.remainConcurrent)
            setRemainConcurrent(catalogSummary.remainConcurrent);
            setProcessingAmount(catalogSummary.processingAmount);
            setSubmitAmount(catalogSummary.submitAmount);
            setResultAmount(catalogSummary.resultAmount);
            setCompletedAmount(catalogSummary.completedAmount);
        }
    }, [catalogSummary]);

    useEffect(() => {
        const reqFunc = () => {
            return isExample
                ? fetchExampleSet()
                : isNewEbook
                ? isPublishers
                    ? publishers_list
                        ? ''
                        : dispatch(
                              actions.fetchCatalogues(
                                  catalogueUIProps.queryParams,
                                  'publishers'
                              )
                          )
                    : isNewEbooks
                    ? newEbooksContents
                        ? ''
                        : dispatch(
                              actions.fetchNewEbooksContents(
                                  id,
                                  undefined,
                                  setLoadingBottomRow
                              )
                          )
                    : isCategories
                    ? categories_list
                        ? ''
                        : dispatch(
                              actions.fetchCatalogues(
                                  catalogueUIProps.queryParams,
                                  'categories'
                              )
                          )
                    : isSubcategories && catId
                    ? dispatch(actions.fetchCategoryContents(id, catId))
                    : isEbooks
                    ? catId && subcatId
                        ? dispatch(
                              actions.fetchCategoryContents(
                                  id,
                                  catId,
                                  undefined,
                                  undefined,
                                  subcatId
                              )
                          )
                        : publisherId
                        ? dispatch(
                              actions.fetchPublisherContents(id, publisherId)
                          )
                        : ''
                    : dispatch(actions.clearContents())
                : isCatalogueDetail
                ? ''
                : ''; //dispatch(actions.fetchCatalogues(catalogueUIProps.queryParams, "catalogues"));
        };
        reqFunc();
        if (document.getElementById('scrollingRow')) {
            document.getElementById('scrollingRow').scrollTop = 0;
        }
    }, [
        isNewEbook,
        isNewEbooks,
        isPublishers,
        isEbooks,
        isCategories,
        isCatalogueDetail,
        publisherId,
        catId,
        subcatId,
        isExample
    ]);

    const isBottom = () => {
        let scrollHeight = Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight
        );
        return (
            window.pageYOffset + window.innerHeight >=
            scrollHeight - 3 * loaderHeight - footerHeight
        );
    };

    const isBottomRow = () => {
        const getScroll = document.getElementById('scrollingRow');
        if (
            !actionsLoading &&
            !actionsLoading_catalogueForEdit &&
            getScroll.scrollTop >=
                0.9 * (getScroll.scrollHeight - getScroll.offsetHeight)
        ) {
            return true;
        } else {
            return false;
        }
    };

    const trackScrolling = () => {
        if (isBottom()) {
            setLoadingBottom(true);
        }
    };

    const trackScrollingRow = () => {
        if (isBottomRow()) {
            console.log(
                LastEvaluatedKey_publisherContents,
                LastEvaluatedKey_newEbooksContents,
                LastEvaluatedKey_categoryContents,
                LastEvaluatedKey_search,
                LastEvaluatedKey_catalogueForEdit
            );
            setLoadingBottomRow(true);
        }
    };

    useEffect(() => {
        console.log('remain : ' + remainConcurrent);
    }, [remainConcurrent]);

    useEffect(() => {
        fetchCatalogSummary();
    }, [catalogue_list]);

    useEffect(() => {
        document.addEventListener('scroll', trackScrolling);
        if (document.getElementById('scrollingRow')) {
            document
                .getElementById('scrollingRow')
                .addEventListener('scroll', trackScrollingRow);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (publisherId) {
            if (
                LastEvaluatedKey_publisherContents === undefined ||
                JSON.stringify(LastEvaluatedKey_publisherContents) ===
                    JSON.stringify({})
            ) {
                setLoadingBottomRow(false);
            } else if (loadingBottomRow && !actionsLoading) {
                dispatch(
                    actions.fetchPublisherContents(
                        id,
                        publisherId,
                        LastEvaluatedKey_publisherContents,
                        setLoadingBottomRow
                    )
                );
            }
        }
    }, [LastEvaluatedKey_publisherContents, loadingBottomRow, actionsLoading]);

    useEffect(() => {
        if (catalogueUIProps.queryParams.keyword) {
            if (
                LastEvaluatedKey_search === undefined ||
                JSON.stringify(LastEvaluatedKey_search) === JSON.stringify({})
            ) {
                setLoadingBottomRow(false);
            } else if (loadingBottomRow && !actionsLoading) {
                dispatch(
                    actions.searchCatalog(
                        catalogueUIProps.queryParams,
                        id,
                        LastEvaluatedKey_search,
                        setLoadingBottomRow
                    )
                );
            }
        }
    }, [LastEvaluatedKey_search, loadingBottomRow, actionsLoading]);

    useEffect(() => {
        if (catId) {
            if (
                LastEvaluatedKey_categoryContents === undefined ||
                JSON.stringify(LastEvaluatedKey_categoryContents) ===
                    JSON.stringify({})
            ) {
                setLoadingBottomRow(false);
            } else if (loadingBottomRow && !actionsLoading) {
                dispatch(
                    actions.fetchCategoryContents(
                        id,
                        catId,
                        LastEvaluatedKey_categoryContents,
                        setLoadingBottomRow,
                        subcatId
                    )
                );
            }
        }
    }, [LastEvaluatedKey_categoryContents, loadingBottomRow, actionsLoading]);

    useEffect(() => {
        if (id) {
            if (
                LastEvaluatedKey_newEbooksContents === undefined ||
                JSON.stringify(LastEvaluatedKey_newEbooksContents) ===
                    JSON.stringify({})
            ) {
                setLoadingBottomRow(false);
            } else if (loadingBottomRow && !actionsLoading) {
                dispatch(
                    actions.fetchNewEbooksContents(
                        id,
                        LastEvaluatedKey_newEbooksContents,
                        setLoadingBottomRow
                    )
                );
            }
        }
    }, [LastEvaluatedKey_newEbooksContents, loadingBottomRow, actionsLoading]);

    useEffect(() => {
        if (isCatalogueDetail && id) {
            if (
                LastEvaluatedKey_catalogueForEdit === undefined ||
                JSON.stringify(LastEvaluatedKey_catalogueForEdit) ===
                    JSON.stringify({})
            ) {
                setLoadingBottomRow(false);
            } else if (loadingBottomRow && !actionsLoading_catalogueForEdit) {
                dispatch(
                    actions.fetchCatalogue(
                        catalogueUIProps.queryParams,
                        id,
                        LastEvaluatedKey_catalogueForEdit,
                        setLoadingBottomRow,
                        true
                    )
                );
                dispatch(actions.clearContents());
            }
        }
    }, [
        LastEvaluatedKey_catalogueForEdit,
        loadingBottomRow,
        isCatalogueDetail,
        actionsLoading_catalogueForEdit
    ]);

    useEffect(() => {
        if (isPureCatalogue) {
            removeLocalStorage();
            fetchCatalogSummary();
            dispatch(
                actions.fetchCatalogues(
                    catalogueUIProps.queryParams,
                    'catalogues'
                )
            );
        }
    }, [catalogueUIProps.queryParams, isPureCatalogue]);

    useEffect(() => {
        if (
            catalogueForEdit &&
            catalogueForEdit.uid === id &&
            catalogueUIProps.queryParams &&
            memberMode === catalogueUIProps.queryParams.memberMode
        ) {
            if (
                Number.isInteger(catalogueForEdit.credit) &&
                Number.isInteger(catalogueForEdit.concurrent)
            ) {
                if (
                    !isCatalogueDetail &&
                    localStorage.getItem('catalogueEbooks') &&
                    localStorage.getItem('catalogueEbooksIon')
                ) {
                    let stored = JSON.parse(
                        localStorage.getItem('catalogueEbooks')
                    );
                    let storedIon = JSON.parse(
                        localStorage.getItem('catalogueEbooksIon')
                    );
                    let storageIon = Object.values(storedIon).reduce(
                        (t, n) => t + n
                    );
                    setCatalogueEbooks(stored);
                    // setCatalogueEbooksIon(storedIon);
                    setTotalConcurrent(
                        catalogueForEdit.credit -
                            catalogueForEdit.usedCredit -
                            storageIon
                    );
                    // setOriginConcurrentIon(
                    //     catalogueForEdit.credit -
                    //         catalogueForEdit.concurrent -
                    //         storageIon
                    // );
                } else {
                    setTotalConcurrent(
                        catalogueForEdit.credit - catalogueForEdit.usedCredit
                    );
                    // setCredit(catalogueForEdit.credit);
                    // setConcurrent(catalogueForEdit.concurrent);
                }
                setOriginConcurrent(
                    catalogueForEdit.credit - catalogueForEdit.usedCredit
                );
            }
            if (
                isCatalogueDetail &&
                catalogueForEdit &&
                !catalogueForEdit.contents
            ) {
                dispatch(
                    actions.fetchCatalogue(
                        catalogueUIProps.queryParams,
                        id,
                        undefined,
                        undefined,
                        true
                    )
                );
            }
        } else {
            if (id && !error) {
                dispatch(
                    actions.fetchCatalogue(
                        catalogueUIProps.queryParams,
                        id,
                        undefined,
                        undefined,
                        isCatalogueDetail
                    )
                );
            }
        }
    }, [catalogueUIProps.queryParams, isCatalogueDetail, catalogueForEdit]);

    useEffect(() => {
        // return history.listen(location => {
        //     if (localStorage.getItem('catalogueEbooks')) {
        //         updateCatalogueEbooks('update');
        //     }
        // });
    }, [history]);

    useEffect(() => {
        if (catalogueUIProps.queryParams.keyword !== undefined && id) {
            if (catalogueUIProps.queryParams.keyword === '') {
                // history.push(`/catalogue-detail?id=${id}&newEbook=true`);
            } else {
                viewCatalogueDetailSearch(id);
                setSearchLoad(true);
                dispatch(actions.resetSearch());
                dispatch(
                    actions.searchCatalog(catalogueUIProps.queryParams, id, {})
                );
            }
        }
    }, [catalogueUIProps.queryParams.keyword]);

    useEffect(() => {
        if (entities_search) setSearchLoad(false);
    }, [entities_search]);

    useEffect(() => {
        if (!catalogue_contentAmount) {
            dispatch(actions.fetchAllContentAmount());
        }
    }, [catalogue_contentAmount]);

    useEffect(() => {
        if (!isEbooks && catalogueForEdit) {
            if (localStorage.getItem('catalogueEbooksIon')) {
                let storedIon = JSON.parse(
                    localStorage.getItem('catalogueEbooksIon')
                );
                let storageIon = Object.values(storedIon).reduce(
                    (t, n) => t + n
                );
                setTotalConcurrent(
                    catalogueForEdit.credit -
                        catalogueForEdit.usedCredit -
                        storageIon
                );
            } else {
                setTotalConcurrent(
                    catalogueForEdit.credit - catalogueForEdit.usedCredit
                );
            }
            if (saved) {
                setSaved(false);
            }
        }
    }, [isEbooks, catalogueForEdit]);

    useEffect(() => {
        if (isCatalogueDetail && catalogueForEdit) {
            setTotalConcurrent(originConcurrent);
            if (saved) {
                setSaved(false);
            }
            if (
                catalogueForEdit &&
                catalogueForEdit != undefined &&
                catalogueForEdit.contents &&
                !completeRejectModal
            ) {
                if (
                    catalogueForEdit.contents.filter(el => el.status === 1)
                        .length > 0
                ) {
                    setRejectModal(true);
                    setCompleteRejectModal(true);
                }
            }
        }
    }, [isCatalogueDetail, catalogueForEdit, originConcurrent]);

    const removeLocalStorage = () => {
        setCatalogueEbooks({});
        // setCatalogueEbooksIon({});
        localStorage.removeItem('catalogueEbooks');
        localStorage.removeItem('catalogueEbooksIon');
    };
    const NoDataDiv = arr =>
        arr && arr.length === 0 ? <NodataView /> : undefined;
    const uploadSchema = Yup.object().shape({
        initialCredit: Yup.number()
            .min(1, 'กรุณาใช้จำนวน 1 ขึ้นไป')
            .max(10000, 'กรุณาใช้จำนวนไม่เกิน 10,000')
            .typeError('กรุณาใช้ตัวเลข')
    });
    const SpinnerDiv = forEndless => (
        <div
            style={
                forEndless
                    ? { width: '100%' }
                    : {
                          margin: '-4px',
                          display: 'flex',
                          alignItems: 'center',
                          width: '98%',
                          height: '100%',
                          position: 'absolute'
                      }
            }
        >
            <div
                style={{
                    minHeight: `${loaderHeight}px`,
                    textAlign: 'center',
                    padding: '20px 0 0 0',
                    width: '100%'
                }}
            >
                <ClipLoader
                    size={loaderHeight}
                    color={'#123abc'}
                    loading={true}
                />
            </div>
        </div>
    );
    const viewCatalogueDetailPublisherClick = (id, creatorUID) => {
        history.push(
            `/catalogue-detail?id=${id}&newEbook=true&ebooks=true&publisherId=${creatorUID}`
        );
    };
    const viewCatalogueDetailCategoryClick = (id, catId) => {
        history.push(
            `/catalogue-detail?id=${id}&newEbook=true&catId=${catId}&subcategories=true`
        );
    };
    const viewCatalogueDetailSubcategoryClick = (id, catId, subcatId) => {
        history.push(
            `/catalogue-detail?id=${id}&newEbook=true&ebooks=true&catId=${catId}&subcatId=${subcatId}`
        );
    };
    const viewCatalogueDetailSearch = (id, catId, subcatId) => {
        history.push(`/catalogue-detail?id=${id}&search=true&newEbook=true`);
    };
    const updateCatalogueEbooks = (type, catalogue) => {
        const updateReqFunc = async () => {
            let updateReq;
            if (type === 'update') {
                updateReq = await axios.post(
                    `/admin/catalogs/${id}/contents`,
                    JSON.parse(localStorage.getItem('catalogueEbooks'))
                );
            } else {
                updateReq = await axios.post(
                    `/admin/catalogs/${id}/contents`,
                    catalogueEbooks
                );
            }

            if (updateReq.data.status) {
                removeLocalStorage();
                dispatch(
                    actions.fetchCatalogue(
                        catalogueUIProps.queryParams,
                        id,
                        undefined,
                        undefined,
                        false,
                        catalogueEbooks
                    )
                );
                // if (isNewEbooks) {
                //     dispatch(
                //         actions.fetchNewEbooksContents(
                //             id,
                //             undefined,
                //             setLoadingBottomRow
                //         )
                //     );
                // }
                // if (isEbooks) {
                //     if (catId && subcatId) {
                //         dispatch(
                //             actions.fetchCategoryContents(
                //                 id,
                //                 catId,
                //                 undefined,
                //                 undefined,
                //                 subcatId
                //             )
                //         );
                //     }
                //     if (publisherId) {
                //         dispatch(
                //             actions.fetchPublisherContents(id, publisherId)
                //         );
                //     }
                // }
                if (!type) {
                    setSuccessModal(true);
                } else {
                    if (type === 'add') {
                        setLoadingSavingAdd(false);
                        catalogueUIProps.newCatalogueButtonClick();
                    } else if (type === 'publisher') {
                        setLoadingSaving(false);
                        viewCatalogueDetailPublisherClick(
                            id,
                            catalogue.creatorUID
                        );
                    } else if (type === 'category') {
                        setLoadingSaving(false);
                        viewCatalogueDetailCategoryClick(id, catalogue.catId);
                    } else if (type === 'subcategory') {
                        setLoadingSaving(false);
                        viewCatalogueDetailSubcategoryClick(
                            id,
                            catalogue.catId,
                            catalogue.subcatId
                        );
                    } else if (type === 'update') {
                        console.log('update', catalogueEbooks);
                    }
                }
                setSaved(false);
                setLoadingSaving(false);
            }
        };
        console.log(catalogueEbooks);
        if (catalogueEbooks) {
            updateReqFunc();
        }
    };

    const submitCatalogueEbooks = () => {
        const submitReqFunc = async () => {
            const updateReq = await axios.post(`/admin/catalogs/${id}`);
            if (updateReq.data.status) {
                removeLocalStorage();
                if (isCatalogueDetail) {
                    dispatch(
                        actions.fetchCatalogue(
                            catalogueUIProps.queryParams,
                            id,
                            undefined,
                            undefined,
                            false
                        )
                    );
                }
                setSuccessModal(true);
                setSubmitModal(false);
                setSaved(false);
            }
        };
        if (
            catalogueForEdit &&
            Number.isInteger(catalogueForEdit.allContentAmount) &&
            catalogueForEdit.allContentAmount > 0
        ) {
            submitReqFunc();
        }
    };

    const prepareFilter = (queryParams, values) => {
        const { memberMode } = values;
        const newQueryParams = { ...queryParams };
        newQueryParams.memberMode = memberMode;
        return newQueryParams;
    };

    const applyFilter = values => {
        const newQueryParams = prepareFilter(
            catalogueUIProps.queryParams,
            values
        );
        if (!isEqual(newQueryParams, catalogueUIProps.queryParams)) {
            // update list by queryParams
            catalogueUIProps.setQueryParams(newQueryParams);
        }
    };
    const onHidesummary = () => {
        setsummaryModal(false);
    };
    const openmodalsummary = () => {
        setsummaryModal(true);
    };

    const [loadingSavePreset, setLoadingSavePreset] = useState(false);
    const postPresetReq = () => {
        setLoadingSavePreset(true);
        axios
            .post(`/superuser/hibrary/contents`, {
                type: 'template',
                clientUID: jwt_decode(viewAsToken).clientUID,
                value: exampleBox_list[selectedPreset]
            })
            .then(res => {
                if (res.data && res.data.status) {
                    setLoadingSavePreset(false);
                    setSuccessModal(true);
                    setSelectedPreset(-1);
                }
            });
    };

    const downloadTemplate = () => {
        axios.get(`/admin/catalogs/${id}/export`).then(res => {
            if (res.data && res.data.data && res.data.data.link) {
                let link = document.createElement('a');
                link.setAttribute('download', 'template');
                link.href = res.data.data.link;
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        });
    };

    const downloadExampleTemplate = () => {
        axios.get(`/superuser/hibrary/contents/template`).then(res => {
            if (res.data && res.data.data && res.data.data.fulfillmentUrl) {
                let link = document.createElement('a');
                link.setAttribute('download', 'template');
                link.href = res.data.data.fulfillmentUrl;
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        });
    };

    const onStopVote = async () => {
        try {
            setLoadingStopVote(true);
            const res = await stopCatalogVote(id);
            if (res.status) {
                dispatch(actions.setVoteStatusAction(2));
            }
        } finally {
            setLoadingStopVote(false);
        }
    };

    const tabDiv = isPureCatalogue => {
        return '';
        //   return (
        //     <div
        //       className={classes.flex}
        //       style={{
        //         justifyContent: "space-between",
        //         paddingLeft: "0px",
        //         alignItems: "end",
        //         height: "100%",
        //       }}
        //     >
        //       {filterDiv(isPureCatalogue)}
        //       {isPureCatalogue ? "" : licenseNumDiv()}
        //     </div>
        //   );
    };
    const TabCatalogPage = () => {
        return (
            <div className="catalogueTab">
                <div>
                    <div
                        onClick={() => {
                            setActiveTab(0);
                            applyFilter({ memberMode: 0 });
                        }}
                        className={`catalogueDetailTab ${
                            activeTab === 0 ? 'active' : ''
                        }`}
                    >
                        <div>รอดำเนินการ ({processingAmount})</div>
                        <div className={`dash`}></div>
                    </div>
                    <div
                        onClick={() => {
                            setActiveTab(1);
                            applyFilter({ memberMode: 3 });
                        }}
                        className={`catalogueDetailTab ${
                            activeTab === 1 ? 'active' : ''
                        }`}
                    >
                        <div>รอตรวจสอบ ({submitAmount})</div>
                        <div className={`dash`}></div>
                    </div>
                    <div
                        onClick={() => {
                            setActiveTab(2);
                            applyFilter({ memberMode: 5 });
                        }}
                        className={`catalogueDetailTab ${
                            activeTab === 2 ? 'active' : ''
                        }`}
                    >
                        <div>ผลการตรวจสอบ ({resultAmount})</div>
                        <div className={`dash`}></div>
                    </div>
                    <div
                        onClick={() => {
                            setActiveTab(3);
                            applyFilter({ memberMode: 4 });
                        }}
                        className={`catalogueDetailTab ${
                            activeTab === 3 ? 'active' : ''
                        }`}
                    >
                        <div>ประวัติการสั่งซื้อ ({completedAmount})</div>
                        <div className={`dash`}></div>
                    </div>
                </div>
            </div>
        );
    };

    const TabCatalogDetailPage = () => {
        return (
            <div className="catalogueTab">
                <div>
                    <div
                        onClick={() => {
                            setActiveTab(0);
                            applyFilter({ memberMode: 0 });
                        }}
                        className={`catalogueDetailTab ${
                            activeTab === 0 ? 'active' : ''
                        }`}
                    >
                        <div>
                            ทั้งหมด
                            {catalogueForEdit &&
                            Number.isInteger(catalogueForEdit.allContentAmount)
                                ? ` (${catalogueForEdit.allContentAmount})`
                                : ' (0)'}
                        </div>
                        <div className={`dash`}></div>
                    </div>
                    {catalogueForEdit && catalogueForEdit.status === 4 ? (
                        <div
                            onClick={() => {
                                setActiveTab(1);
                                applyFilter({ memberMode: 1 });
                            }}
                            className={`catalogueDetailTab ${
                                activeTab === 1 ? 'active' : ''
                            }`}
                        >
                            <div>
                                รอตรวจสอบ
                                {catalogueForEdit &&
                                Number.isInteger(
                                    catalogueForEdit.processingAmount
                                )
                                    ? ` (${catalogueForEdit.processingAmount})`
                                    : ' (0)'}
                            </div>
                            <div className={`dash`}></div>
                        </div>
                    ) : (
                        ''
                    )}

                    {catalogueForEdit && catalogueForEdit.status === 4 ? (
                        <div
                            onClick={() => {
                                setActiveTab(2);
                                applyFilter({ memberMode: 2 });
                            }}
                            className={`catalogueDetailTab ${
                                activeTab === 2 ? 'active' : ''
                            }`}
                        >
                            <div>
                                ไม่ผ่านการตรวจสอบ
                                {catalogueForEdit &&
                                Number.isInteger(catalogueForEdit.rejectAmount)
                                    ? ` (${catalogueForEdit.rejectAmount})`
                                    : ' (0)'}
                            </div>
                            <div className={`dash`}></div>
                        </div>
                    ) : (
                        ''
                    )}
                    {catalogueForEdit && catalogueForEdit.status === 4 ? (
                        <div
                            onClick={() => {
                                setActiveTab(3);
                                applyFilter({ memberMode: 3 });
                            }}
                            className={`catalogueDetailTab ${
                                activeTab === 3 ? 'active' : ''
                            }`}
                        >
                            <div>
                                ผ่านการตรวจสอบ
                                {catalogueForEdit &&
                                Number.isInteger(catalogueForEdit.submitAmount)
                                    ? ` (${catalogueForEdit.submitAmount})`
                                    : ' (0)'}
                            </div>
                            <div className={`dash`}></div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <div>{isPureCatalogue ? '' : <LicenseNumDiv />}</div>
            </div>
        );

        // return (
        //   <Tabs
        //     activeKey={`${activeTab}`}
        //     className="tab-custom"
        //     tabBarExtraContent={licenseNumDiv()}
        //     onChange={(e) => {
        //       setActiveTab(e);
        //       applyFilter({ memberMode: e });
        //     }}
        //   >
        //     <TabPane
        //       tab={`รอดำเนินการ ${
        //         catalogueForEdit &&
        //         Number.isInteger(catalogueForEdit.allContentAmount)
        //           ? ` (${catalogueForEdit.allContentAmount})`
        //           : " (0)"
        //       }`}
        //       key="0"
        //     >
        //       รอดำเนินการ
        //     </TabPane>
        //     <TabPane
        //       tab={`รอตรวจสอบ ${
        //         catalogueForEdit &&
        //         Number.isInteger(catalogueForEdit.processingAmount)
        //           ? ` (${catalogueForEdit.processingAmount})`
        //           : " (0)"
        //       }`}
        //       key="1"
        //     >
        //       รอตรวจสอบ
        //     </TabPane>
        //     <TabPane
        //       tab={`ผลการตรวจสอบ ${
        //         catalogueForEdit && Number.isInteger(catalogueForEdit.rejectAmount)
        //           ? ` (${catalogueForEdit.rejectAmount})`
        //           : " (0)"
        //       }`}
        //       key="2"
        //     >
        //       ผลการตรวจสอบ
        //     </TabPane>
        //     <TabPane
        //       tab={`ประวัติการสั่งซื้อ ${
        //         catalogueForEdit && Number.isInteger(catalogueForEdit.submitAmount)
        //           ? ` (${catalogueForEdit.submitAmount})`
        //           : " (0)"
        //       }`}
        //       key="3"
        //     >
        //       ประวัติการสั่งซื้อ
        //     </TabPane>
        //   </Tabs>
        // );
    };
    const Tabbar = props => {
        return (
            <>
                {props.isPureCatalogue ? (
                    <TabCatalogPage />
                ) : props.isCatalogueDetail ? (
                    <TabCatalogDetailPage />
                ) : (
                    ''
                )}
            </>
        );
    };

    const RemainConcurrentView = () => {
        return (
            <div className="remainConcurrentView flex">
                <div className="text-secondary-md">
                    จำนวนสิทธิ์อีบุ๊กคงเหลือ :
                </div>
                <div className="text-primary-lg">
                    {isPureCatalogue
                        ? Number.isInteger(remainConcurrent)
                            ? remainConcurrent
                            : ''
                        : Number.isInteger(totalConcurrent)
                        ? totalConcurrent
                        : '-'}
                </div>
            </div>
        );
    };

    const LicenseNumDiv = () => {
        return catalogueForEdit &&
            (catalogueForEdit.status === 2 ||
                catalogueForEdit.status === 3) ? null : (
            <RemainConcurrentView />
        );
    };

    const onClickSort = isAsc => {
        setSortByAsc(isAsc);
        dispatch(
            actions.fetchCatalogue({ isAsc }, id, undefined, undefined, true)
        );
    };

    const itemsSort = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <div onClick={() => onClickSort(false)}>โหวตมากสุด</div>
                    )
                },
                {
                    key: '2',
                    label: (
                        <div onClick={() => onClickSort(true)}>โหวตน้อยสุด</div>
                    )
                }
            ]}
        />
    );

    const StepBar = () => {
        return isPrivate ? (
            ''
        ) : (
            <div className="row">
                {stepLabel.map((el, index) => {
                    if (index === isPrivate) {
                        return (
                            <div
                                key={index}
                                className={`col-2 ${
                                    index === isPrivate
                                        ? classes.stepDiv
                                        : listLoading
                                        ? classes.stepDivBlack_wait
                                        : classes.stepDivBlack
                                }`}
                                // onClick={() => {
                                //   if (!listLoading) handleChange(index);
                                // }}
                            >
                                <p
                                    className={
                                        index === isPrivate
                                            ? classes.stepDiv_p
                                            : classes.stepDivBlack_p
                                    }
                                >
                                    {el}
                                </p>
                            </div>
                        );
                    }
                })}
            </div>
        );
    };
    const ToolBar = () => {
        return (
            //  <div
            //    style={{
            //      display: "flex",
            //      justifyContent: "flex-end",
            //      margin: "32px 32px 0 0",
            //    }}
            //  >
            <>
                {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "32px 32px 0 0",
          }}
        > */}

                {isCatalogueDetail ? (
                    <Dropdown overlay={itemsSort}>
                        <Button
                            shape="round"
                            className={classes.buttonSort}
                            icon={<SVG src="/media/svg/icons/Tools/Sort.svg" />}
                        >
                            {sortByAsc ? 'โหวตน้อยสุด' : 'โหวตมากสุด'}
                        </Button>
                    </Dropdown>
                ) : null}
                {isCatalogueDetail && isVoted ? (
                    <Button
                        type="button"
                        loading={loadingStopVote}
                        className={classes.buttonSecondary}
                        onClick={() => onStopVote()}
                    >
                        <span>สิ้นสุดการโหวต </span>
                    </Button>
                ) : null}
                {isCatalogueDetail && !isVoted ? (
                    <button
                        type="button"
                        className="button"
                        loading={loadingSavingAdd}
                        onClick={() => {
                            if (catalogueForEdit.isEnded) {
                                setAlertModal(true);
                            } else {
                                setCreateVoteModal(true);
                            }
                        }}
                    >
                        <span>สร้างโหวต </span>
                    </button>
                ) : null}

                {isExample ? (
                    <div>
                        <button
                            type="button"
                            className={classes.buttonMaxHeight}
                            onClick={() => {
                                downloadExampleTemplate();
                            }}
                        >
                            <span>
                                <img
                                    alt="excel"
                                    src="/media/members/excel-button.svg"
                                    style={{ marginRight: '8px' }}
                                />
                            </span>
                            <span>ดาวน์โหลด Template</span>
                        </button>
                        <button
                            type="button"
                            className={classes.buttonMaxHeight}
                            onClick={catalogueUIProps.newCatalogueButtonClick}
                        >
                            <span>อัพโหลดไฟล์ </span>
                        </button>
                    </div>
                ) : !isCatalogueDetail ? (
                    <RemainConcurrentView />
                ) : null}
                {isCatalogueDetail ? (
                    <button
                        type="button"
                        className={classes.buttonMaxHeight}
                        onClick={() => {
                            downloadTemplate();
                        }}
                    >
                        <span>
                            <img
                                alt="excel"
                                src="/media/members/excel-button.svg"
                                style={{ marginRight: '8px' }}
                            />
                        </span>
                        <span>ดาวน์โหลด Report</span>
                    </button>
                ) : (
                    ''
                )}
                {isCatalogueDetail ? (
                    <button
                        type="button"
                        className={classes.buttonskyblue}
                        onClick={openmodalsummary}
                    >
                        <span>สรุปข้อมูล</span>
                    </button>
                ) : (
                    ''
                )}
                {isNewEbook ? (
                    ''
                ) : (
                    <>
                        {role === Role.SuperAdmin &&
                        viewAsToken &&
                        !isCatalogueDetail ? (
                            <button
                                type="button"
                                className="button"
                                onClick={() => setInitialConcurrentModal(true)}
                            >
                                <span>เพิ่มสิทธิ์</span>
                            </button>
                        ) : (
                            ''
                        )}
                        {isCatalogueDetail ? (
                            catalogueForEdit &&
                            catalogueForEdit.status !== 2 &&
                            catalogueForEdit.status !== 3 ? (
                                <button
                                    type="button"
                                    className="button"
                                    loading={loadingSavingAdd}
                                    onClick={() => {
                                        setLoadingSavingAdd(true);
                                        // updateCatalogueEbooks('add');
                                        catalogueUIProps.newCatalogueButtonClick();
                                    }}
                                >
                                    <span>เพิ่มรายการอีบุ๊ก </span>
                                </button>
                            ) : (
                                ''
                            )
                        ) : (
                            <button
                                type="button"
                                className="button"
                                onClick={
                                    catalogueUIProps.newCatalogueButtonClick
                                }
                            >
                                <span>สร้างแคตตาล็อก</span>
                            </button>
                        )}
                    </>
                )}
                {isCatalogueDetail &&
                catalogueForEdit &&
                catalogueForEdit.status !== 2 &&
                catalogueForEdit.status !== 3 ? (
                    <button
                        type="button"
                        className="button"
                        onClick={() => setSubmitModal(true)}
                        disabled={
                            (catalogueForEdit &&
                                Number.isInteger(
                                    catalogueForEdit.allContentAmount
                                ) &&
                                catalogueForEdit.allContentAmount === 0) ||
                            !catalogueForEdit ||
                            !Number.isInteger(catalogueForEdit.allContentAmount)
                        }
                    >
                        <span>ส่งข้อมูลการสั่งซื้อ</span>
                    </button>
                ) : (
                    ''
                )}
                {isCatalogueDetail ||
                publisherId ||
                catId ||
                isNewEbooks ||
                query.get('search') === 'true' ? (
                    catalogueForEdit &&
                    catalogueForEdit.status !== 2 &&
                    catalogueForEdit.status !== 3 ? (
                        <Button
                            type="button"
                            className="button"
                            onClick={() => {
                                setLoadingSaving(true);
                                updateCatalogueEbooks(true);
                            }}
                            disabled={!saved}
                            loading={loadingSaving}
                        >
                            <span>บันทึก </span>
                        </Button>
                    ) : (
                        ''
                    )
                ) : (
                    ''
                )}
                {isExample ? (
                    <button
                        className="button"
                        disabled={selectedPreset === -1}
                        onClick={() => {
                            postPresetReq();
                        }}
                    >
                        บันทึก
                    </button>
                ) : (
                    ''
                )}
                {/* </div> */}
            </>
        );
    };
    const TitleBarss = () => {
        return (
            <div className="card-label">
                <>
                    <div>
                        {isExample ? (
                            'รายการอีบุ๊ก'
                        ) : isCatalogueDetail ? (
                            catalogueForEdit ? (
                                <div>
                                    <h3>{catalogueForEdit.title}</h3>
                                    {isVoted ? (
                                        <p className={classes.votePeriod}>
                                            ระยะเวลาการโหวต :{' '}
                                            {dayjs
                                                .unix(voteStartDate)
                                                .locale('th')
                                                .format('DD MMM YYYY')}{' '}
                                            -{' '}
                                            {dayjs
                                                .unix(voteEndDate)
                                                .locale('th')
                                                .format('DD MMM YYYY')}
                                        </p>
                                    ) : null}
                                </div>
                            ) : (
                                <Skeleton paragraph={{ rows: 1 }} active />
                            )
                        ) : isNewEbook ? (
                            isPublishers ? (
                                'สำนักพิมพ์/นักเขียน'
                            ) : isNewEbooks ? (
                                newEbooksContents ? (
                                    'มาใหม่'
                                ) : (
                                    ''
                                )
                            ) : isCategories ? (
                                'หมวดหมู่'
                            ) : isSubcategories ? (
                                catName && subcats_list ? (
                                    catName
                                ) : (
                                    ''
                                )
                            ) : isEbooks ? (
                                subcatId ? (
                                    subcatName && categoryContents ? (
                                        `${subcatName}${
                                            contentAmount
                                                ? ' (' +
                                                  contentAmount +
                                                  ' เล่ม)'
                                                : ''
                                        }`
                                    ) : (
                                        ''
                                    )
                                ) : publisherId ? (
                                    publisherName && publisherContents ? (
                                        `${publisherName}${
                                            contentAmount
                                                ? ' (' +
                                                  contentAmount +
                                                  ' เล่ม)'
                                                : ''
                                        }`
                                    ) : (
                                        ''
                                    )
                                ) : (
                                    ''
                                )
                            ) : (
                                //ryo
                                `${
                                    entities_search &&
                                    entities_search.length > 0 ? (
                                        'ค้นหา'
                                    ) : catalogueForEdit ? (
                                        `${catalogueForEdit.title}${
                                            catalogue_contentAmount
                                                ? ' (' +
                                                  catalogue_contentAmount.toLocaleString() +
                                                  ' เล่ม)'
                                                : ''
                                        }`
                                    ) : (
                                        <Skeleton
                                            paragraph={{ rows: 1 }}
                                            active
                                        />
                                    )
                                }`
                            )
                        ) : (
                            'รายการแคตตาล็อก'
                        )}
                    </div>
                </>
            </div>
        );
    };

    return (
        <>
            <div
                id="CataloguePage"
                className={`catalogueCard ${
                    isPureCatalogue || !isNewEbook ? '' : 'headerBorder'
                }`}
            >
                <StepBar />
                <Card className="cardStyle">
                    <CardHeader title={<TitleBarss />}>
                        <CardHeaderToolbar>
                            {query.get('newEbook') === 'true' &&
                                catalogueFilter()}
                            <ToolBar />
                        </CardHeaderToolbar>
                    </CardHeader>

                    {/* <TitleBar /> */}
                    <CardBody className="cardBodyStyle">
                        <Tabbar
                            isPureCatalogue={isPureCatalogue}
                            isCatalogueDetail={isCatalogueDetail}
                        />
                        <Row
                            gutter={[16, 16]}
                            id="scrollingRow"
                            className={
                                isCatalogueDetail ||
                                isPureCatalogue ||
                                isSubcategories ||
                                isEbooks
                                    ? `scrollingRow${
                                          isCatalogueDetail
                                              ? NoDataDiv(
                                                    catalogueForEdit &&
                                                        catalogueForEdit.contents
                                                )
                                                  ? ' isCatalogueDetail-NoData'
                                                  : ' isCatalogueDetail'
                                              : isPureCatalogue ||
                                                isSubcategories ||
                                                isEbooks
                                              ? isPureCatalogue
                                                  ? NoDataDiv(catalogue_list)
                                                      ? ' isPureCatalogue-NoData'
                                                      : ' isPureCatalogue'
                                                  : isSubcategories
                                                  ? NoDataDiv(subcats_list)
                                                      ? ' isPureCatalogue-NoData'
                                                      : ' isPureCatalogue'
                                                  : isEbooks
                                                  ? NoDataDiv(
                                                        catId
                                                            ? categoryContents
                                                            : publisherId
                                                            ? publisherContents
                                                            : ebookBox_list
                                                    )
                                                      ? ' isPureCatalogue-NoData'
                                                      : ' isPureCatalogue'
                                                  : ''
                                              : ''
                                      }`
                                    : 'scrollingRow normal'
                            }
                        >
                            {isExample
                                ? Array.isArray(exampleBox_list) &&
                                  exampleBox_list.map((e, index) => {
                                      if (!authState.isCuDomain) {
                                          //ryou
                                          return (
                                              <CardOfEbookBox
                                                  catalogue={{
                                                      catId: index,
                                                      catName: e,
                                                      date: '04/04/2564',
                                                      status: 1,
                                                      licenses: 10,
                                                      email: 'nan@email.com',
                                                      coverImage:
                                                          '/media/members/catalogue-publisher.png',
                                                      clickFor: 'selectPreset',
                                                      bgColor: '#2D9CDB'
                                                  }}
                                                  type="type"
                                                  selectedPreset={
                                                      selectedPreset
                                                  }
                                                  setSelectedPreset={
                                                      setSelectedPreset
                                                  }
                                              />
                                          );
                                      } else {
                                          if (e === 'CU') {
                                              return (
                                                  <CardOfEbookBox
                                                      catalogue={{
                                                          catId: index,
                                                          catName: e,
                                                          date: '04/04/2564',
                                                          status: 1,
                                                          licenses: 10,
                                                          email:
                                                              'nan@email.com',
                                                          coverImage:
                                                              '/media/members/catalogue-publisher.png',
                                                          clickFor:
                                                              'selectPreset',
                                                          bgColor: '#2D9CDB'
                                                      }}
                                                      type="type"
                                                      selectedPreset={
                                                          selectedPreset
                                                      }
                                                      setSelectedPreset={
                                                          setSelectedPreset
                                                      }
                                                  />
                                              );
                                          }
                                      }
                                      // <CardOfEbookBox
                                      //   catalogue={{
                                      //     catId: index,
                                      //     catName: e,
                                      //     date: "04/04/2564",
                                      //     status: 1,
                                      //     licenses: 10,
                                      //     email: "nan@email.com",
                                      //     coverImage: "/media/members/catalogue-publisher.png",
                                      //     clickFor: "selectPreset",
                                      //     bgColor: "#2D9CDB",
                                      //   }}
                                      //   type="type"
                                      //   selectedPreset={selectedPreset}
                                      //   setSelectedPreset={setSelectedPreset}
                                      // />
                                  })
                                : isNewEbook
                                ? isPublishers
                                    ? publishers_list && !listLoading
                                        ? publishers_list.map(e => (
                                              <CardOfPublisher publisher={e} />
                                          ))
                                        : ''
                                    : isNewEbooks
                                    ? newEbooksContents
                                        ? newEbooksContents.map(e => (
                                              <CardOfEbook
                                                  ebook={e}
                                                  originConcurrent={
                                                      originConcurrent
                                                  }
                                                  totalConcurrent={
                                                      totalConcurrent
                                                  }
                                                  setTotalConcurrent={
                                                      setTotalConcurrent
                                                  }
                                                  saved={saved}
                                                  setSaved={setSaved}
                                                  setCatalogueEbooks={
                                                      setCatalogueEbooks
                                                  }
                                                  //   setCatalogueEbooksIon={
                                                  //       setCatalogueEbooksIon
                                                  //   }
                                                  catalogueForEdit={
                                                      catalogueForEdit
                                                  }
                                                  id={id}
                                                  subcatName={subcatName}
                                                  publisherName={publisherName}
                                              />
                                          ))
                                        : ''
                                    : isCategories
                                    ? categories_list && !listLoading
                                        ? categories_list.map(e => (
                                              <CardOfEbookBox
                                                  catalogue={e}
                                                  type="categories"
                                              />
                                          ))
                                        : ''
                                    : isSubcategories
                                    ? subcats_list && !actionsLoading
                                        ? subcats_list.map(e => (
                                              <CardOfEbookBox
                                                  catalogue={e}
                                                  type="subcategories"
                                                  catId={catId}
                                              />
                                          ))
                                        : ''
                                    : isEbooks
                                    ? catId && categoryContents
                                        ? categoryContents.map(e => (
                                              <CardOfEbook
                                                  ebook={e}
                                                  originConcurrent={
                                                      originConcurrent
                                                  }
                                                  totalConcurrent={
                                                      totalConcurrent
                                                  }
                                                  setTotalConcurrent={
                                                      setTotalConcurrent
                                                  }
                                                  setSaved={setSaved}
                                                  setCatalogueEbooks={
                                                      setCatalogueEbooks
                                                  }
                                                  //   setCatalogueEbooksIon={
                                                  //       setCatalogueEbooksIon
                                                  //   }
                                                  catalogueForEdit={
                                                      catalogueForEdit
                                                  }
                                                  id={id}
                                                  subcatName={subcatName}
                                                  publisherName={publisherName}
                                              />
                                          ))
                                        : publisherId && publisherContents
                                        ? publisherContents.map(e => (
                                              <CardOfEbook
                                                  ebook={e}
                                                  originConcurrent={
                                                      originConcurrent
                                                  }
                                                  totalConcurrent={
                                                      totalConcurrent
                                                  }
                                                  setTotalConcurrent={
                                                      setTotalConcurrent
                                                  }
                                                  setSaved={setSaved}
                                                  setCatalogueEbooks={
                                                      setCatalogueEbooks
                                                  }
                                                  //   setCatalogueEbooksIon={
                                                  //       setCatalogueEbooksIon
                                                  //   }
                                                  catalogueForEdit={
                                                      catalogueForEdit
                                                  }
                                                  id={id}
                                                  subcatName={subcatName}
                                                  publisherName={publisherName}
                                              />
                                          ))
                                        : ''
                                    : entities_search &&
                                      query.get('search') === 'true'
                                    ? entities_search.map(e => (
                                          <CardOfEbook
                                              ebook={e}
                                              type="search"
                                              catalogueForEdit={
                                                  catalogueForEdit
                                              }
                                              totalConcurrent={totalConcurrent}
                                              setCatalogueEbooks={
                                                  setCatalogueEbooks
                                              }
                                              //   setCatalogueEbooksIon={
                                              //       setCatalogueEbooksIon
                                              //   }
                                              setTotalConcurrent={
                                                  setTotalConcurrent
                                              }
                                              setSaved={setSaved}
                                          />
                                      ))
                                    : ebookBox_list
                                    ? ebookBox_list.map(e => (
                                          <CardOfEbookBox
                                              catalogue={e}
                                              type="type"
                                          />
                                      ))
                                    : ''
                                : isCatalogueDetail
                                ? catalogueForEdit && catalogueForEdit.contents
                                    ? catalogueForEdit.contents.map(e => (
                                          <CardOfCatalogueDetail
                                              catalogue={e}
                                              totalConcurrent={totalConcurrent}
                                              setTotalConcurrent={
                                                  setTotalConcurrent
                                              }
                                              setSaved={setSaved}
                                              setCatalogueEbooks={
                                                  setCatalogueEbooks
                                              }
                                              catalogueForEdit={
                                                  catalogueForEdit
                                              }
                                              id={id}
                                              updateCatalogueEbooks={
                                                  updateCatalogueEbooks
                                              }
                                              setLoadingSaving={
                                                  setLoadingSaving
                                              }
                                              viewCatalogueDetailPublisherClick={
                                                  viewCatalogueDetailPublisherClick
                                              }
                                          />
                                          //sortVote
                                      ))
                                    : ''
                                : catalogue_list
                                ? catalogue_list.map(e => (
                                      <CardOfCatalogue catalogue={e} />
                                  ))
                                : ''}

                            {/* //ryo */}
                            {/* { entities_search && entities_search.length > 0 ? entities_search.map((e) => (
                  <CardOfEbook ebook={e}  type="search"
                   />)):''} */}
                            <>
                                {isExample
                                    ? Array.isArray(exampleBox_list)
                                        ? NoDataDiv(exampleBox_list)
                                        : SpinnerDiv()
                                    : isNewEbook
                                    ? isPublishers
                                        ? Array.isArray(publishers_list) &&
                                          !listLoading
                                            ? NoDataDiv(publishers_list)
                                            : SpinnerDiv()
                                        : isNewEbooks
                                        ? Array.isArray(newEbooksContents) &&
                                          !actionsLoading
                                            ? NoDataDiv(newEbooksContents)
                                            : SpinnerDiv(
                                                  LastEvaluatedKey_newEbooksContents
                                              )
                                        : isCategories
                                        ? Array.isArray(categories_list) &&
                                          !listLoading
                                            ? NoDataDiv(categories_list)
                                            : SpinnerDiv()
                                        : isSubcategories
                                        ? Array.isArray(subcats_list) &&
                                          !actionsLoading
                                            ? NoDataDiv(subcats_list)
                                            : SpinnerDiv()
                                        : isEbooks
                                        ? catId
                                            ? Array.isArray(categoryContents) &&
                                              !actionsLoading
                                                ? NoDataDiv(categoryContents)
                                                : SpinnerDiv(
                                                      LastEvaluatedKey_categoryContents
                                                  )
                                            : publisherId
                                            ? Array.isArray(
                                                  publisherContents
                                              ) && !actionsLoading
                                                ? NoDataDiv(publisherContents)
                                                : SpinnerDiv(
                                                      LastEvaluatedKey_publisherContents
                                                  )
                                            : ''
                                        : Array.isArray(ebookBox_list)
                                        ? NoDataDiv(ebookBox_list)
                                        : SpinnerDiv()
                                    : // :query.get('search') ==='true'
                                    // ? Array.isArray(entities_search)
                                    // ? NoDataDiv( entities_search)
                                    // : SpinnerDiv(entities_search)
                                    isCatalogueDetail
                                    ? catalogueForEdit &&
                                      Array.isArray(
                                          catalogueForEdit.contents
                                      ) &&
                                      !actionsLoading_catalogueForEdit
                                        ? NoDataDiv(catalogueForEdit.contents)
                                        : ''
                                    : Array.isArray(catalogue_list) &&
                                      !listLoading
                                    ? NoDataDiv(catalogue_list)
                                    : SpinnerDiv()}
                                {searchLoad && SpinnerDiv()}
                                {isCatalogueDetail &&
                                actionsLoading_catalogueForEdit
                                    ? SpinnerDiv(
                                          LastEvaluatedKey_catalogueForEdit
                                      )
                                    : ''}
                                {isExample && loadingSavePreset
                                    ? SpinnerDiv()
                                    : ''}

                                {query.get('search') === 'true' &&
                                Array.isArray(entities_search)
                                    ? entities_search.length > 0 &&
                                      LastEvaluatedKey_search
                                        ? SpinnerDiv(true)
                                        : NoDataDiv(entities_search)
                                    : ''}
                            </>
                        </Row>
                    </CardBody>
                </Card>
            </div>
            {catalogueForEdit ? (
                <ModalsSuccess
                    show={rejectModal}
                    title={
                        <div className="text-text-head">
                            กรุณาลบอีบุ๊กที่ไม่ผ่านการตรวจสอบออก
                            <br />
                            เพื่อเพิ่มอีบุ๊กใหม่
                        </div>
                    }
                    image={<img src="/media/bg/close.svg" alt="" />}
                    onClickAccept={() => setRejectModal(false)}
                    dialogClassName="divdialog-medium"
                />
            ) : (
                ''
            )}
            <ModalsSuccess
                show={successModal}
                onHide={() => setSuccessModal(false)}
                onClickAccept={() => setSuccessModal(false)}
            />
            <Modal
                backdrop="static"
                show={submitModal}
                onHide={onHide}
                aria-labelledby="example-modal-sizes-title-lg"
                style={{ margin: '3% auto' }}
            >
                <Modal.Body className={modalStyles.body}>
                    <img src="/media/members/image-modal.svg" alt="" />
                    <div className={modalStyles.title}>
                        ยืนยันการส่งข้อมูลการสั่งซื้อ
                    </div>
                    <div className={modalStyles.divButton}>
                        <button
                            type="button"
                            onClick={() => setSubmitModal(false)}
                            className="denyButton"
                        >
                            ยกเลิก
                        </button>
                        <button
                            type="button"
                            onClick={() => submitCatalogueEbooks()}
                            className="acceptButton "
                        >
                            ยืนยัน
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                backdrop="static"
                size="md"
                show={initialCreditModal}
                onHide={() => {
                    setInitialConcurrentModal(false);
                    setSuccessModal(true);
                }}
                aria-labelledby="example-modal-sizes-title-lg"
                dialogClassName={styles.divInitial}
            >
                <Modal.Body className={styles.body}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            initialCredit: 0
                        }}
                        validationSchema={uploadSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            const newValues = {
                                credit: parseInt(values.initialCredit, 10)
                            };
                            const reqFunc = async () => {
                                const req = await axios.post(
                                    `/superuser/catalogs/credits`,
                                    newValues
                                );
                                if (req.data.status) {
                                    setSuccessModal(true);
                                    setInitialConcurrentModal(false);
                                    fetchCatalogSummary();
                                    // dispatch(
                                    //   actions.fetchCatalogues(
                                    //     catalogueUIProps.queryParams,
                                    //     "catalogues"
                                    //   )
                                    // );
                                }
                            };
                            reqFunc();
                            setSubmitting(false);
                        }}
                    >
                        {({ isSubmitting }) => {
                            return (
                                <Card className="cardStyle">
                                    <div className={styles.divText}>
                                        เพิ่มสิทธิ์
                                    </div>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            zIndex: '5',
                                            right: '0',
                                            textAlign: 'right'
                                        }}
                                    >
                                        <button
                                            className={classes.closeButton}
                                            onClick={() => {
                                                setInitialConcurrentModal(
                                                    false
                                                );
                                            }}
                                        >
                                            <img
                                                src="/media/books/close-button.svg"
                                                alt=""
                                            />
                                        </button>
                                    </div>
                                    <CardBody className="cardBodyStyle">
                                        <div
                                            style={{
                                                maxWidth: '736px',
                                                margin: 'auto'
                                            }}
                                        >
                                            <Form className="form form-label-right">
                                                <div
                                                    className="row"
                                                    style={{
                                                        textAlign: 'left'
                                                    }}
                                                >
                                                    <div
                                                        className={`col-12 ${styles.input}`}
                                                    >
                                                        <Field
                                                            name="initialCredit"
                                                            component={Input}
                                                            placeholder="กรุณากรอกสิทธิ์"
                                                            label="จำนวนสิทธิ์"
                                                            type="number"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="divButton">
                                                    <button
                                                        className="acceptButton"
                                                        disabled={isSubmitting}
                                                        type="submit"
                                                        style={{
                                                            marginTop: '60px'
                                                        }}
                                                    >
                                                        ตกลง
                                                    </button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                            );
                        }}
                    </Formik>
                </Modal.Body>
            </Modal>
            {isCatalogueDetail ? (
                <Modal
                    backdrop="static"
                    size="xl"
                    show={summaryModal}
                    onHide={onHide}
                    ClassName={classes.summary}
                >
                    <Modal.Body>
                        <div
                            style={{
                                zIndex: '5',
                                top: '12px',
                                right: '12px',
                                textAlign: 'right'
                            }}
                        >
                            <button
                                className={classes.closeButton}
                                onClick={() => {
                                    setsummaryModal(false);
                                }}
                            >
                                <img
                                    src="/media/books/close-button.svg"
                                    alt=""
                                />
                            </button>
                        </div>
                        <CatalogueSummary
                            id={id}
                            catalogueForEdit={catalogueForEdit?.title}
                        />
                    </Modal.Body>
                </Modal>
            ) : (
                ''
            )}
            {createVoteModal ? (
                <CreateVoteDialog
                    visible={createVoteModal}
                    catalogAmount={catalogueForEdit.allContentAmount}
                    onClose={() => {
                        setCreateVoteModal(false);
                    }}
                    onCreated={() => {
                        dispatch(actions.setVoteStatusAction(1));
                        setCreateVoteModal(false);
                        dispatch(
                            actions.fetchCatalogue(
                                catalogueUIProps.queryParams,
                                id,
                                undefined,
                                undefined,
                                isCatalogueDetail
                            )
                        );
                    }}
                    catalogId={id}
                />
            ) : null}
            <VoteAlertModal
                visible={alertModal}
                onCancel={() => setAlertModal(false)}
                onOpenCreateVoteModal={() => setCreateVoteModal(true)}
            />
        </>
    );
}

const setColorFunc = (image_, id_, setColor_, isLocal) => {
    try {
        if (image_ && id_) {
            var loaded = false;
            var img = document.getElementById(id_.toString());
            if (typeof img !== 'undefined' && img !== null) {
                let imageURL = image_;
                let googleProxyURL =
                    'https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=';
                img.crossOrigin = 'Anonymous';
                img.src = isLocal
                    ? encodeURIComponent(imageURL)
                    : googleProxyURL + encodeURIComponent(imageURL);
                // img.src = encodeURIComponent(imageURL);
                const loadHandler = () => {
                    if (loaded) {
                        return;
                    }
                    loaded = true;
                    var fac = new FastAverageColor();
                    if (img) {
                        fac.getColorAsync(img)
                            .then(function(c) {
                                setColor_(c);
                            })
                            .catch(function(error) {
                                console.log(error);
                                img.src = imageURL;
                                setColor_({ hex: '#7e7d7d' });
                            });
                    }
                };
                img.addEventListener('load', loadHandler, false);
                loadHandler();
            }
        }
    } catch (error) {
        console.log(error);
    }
};

const setBG_FontColorFunc = (color_, setBG_, setFontColor_) => {
    if (color_) {
        let bgEle = color_;
        let bg =
            bgEle !== undefined
                ? bgEle &&
                  bgEle.value &&
                  bgEle.value[0] > 230 &&
                  bgEle.value[1] > 230 &&
                  bgEle.value[2] > 230
                    ? 'rgb(230, 230, 230)'
                    : bgEle && bgEle.hex
                : 'rgb(230, 230, 230)';
        setBG_(bg);
        setFontColor_(
            bgEle
                ? !bgEle || (bgEle && bgEle.isLight)
                    ? '#000000'
                    : '#ffffff'
                : '#000000'
        );
    }
};

const CardOfCatalogue = props => {
    const [color, setColor] = useState({ hex: '#ffffff' });
    const [bg, setBG] = useState('');
    const [fontColor, setFontColor] = useState('');

    const catalogueUIContext = useCatalogueUIContext();
    const catalogueUIProps = useMemo(() => {
        return {
            ids: catalogueUIContext.ids,
            newCatalogueButtonClick: catalogueUIContext.newCatalogueButtonClick,
            viewCatalogueDetailClick:
                catalogueUIContext.viewCatalogueDetailClick,
            openDeleteCatalogueDialog:
                catalogueUIContext.openDeleteCatalogueDialog
        };
    }, [catalogueUIContext]);

    useEffect(() => {
        if (props.catalogue) {
            setColorFunc(
                props.catalogue.coverImage,
                'cardOfCatalogue' + props.catalogue.uid,
                setColor
            );
        }
    }, [props.catalogue]);

    useEffect(() => {
        setBG_FontColorFunc(color, setBG, setFontColor);
    }, [color]);
    const classes = useStyles1();

    const getVoteStatusText = () => {
        const current = dayjs().unix();

        switch (props.catalogue.voteStatus) {
            case 1: {
                if (
                    current > props.catalogue.voteStartDate &&
                    current < props.catalogue.voteEndDate
                ) {
                    return 'กำลังดำเนินการโหวต';
                } else if (current > props.catalogue.voteEndDate) {
                    return 'ยุติการโหวต';
                } else if (current < props.catalogue.voteStartDate) {
                    return 'รอดำเนินการโหวต ';
                } else {
                    return '';
                }
            }
            case 2: {
                return 'ยุติการโหวต';
            }
            default:
                return '';
        }
    };
    const statusWording = s => {
        switch (s) {
            case 0:
                return 'รอดำเนินการ';
            case 2:
                return 'รอตรวจสอบ';
            case 4:
                return 'ผลการตรวจสอบ';
            default:
                return '';
        }
    };
    return props.catalogue ? (
        <Col span={24} md={12} lg={8}>
            <div
                onClick={() => {
                    catalogueUIProps.viewCatalogueDetailClick(
                        props.catalogue.uid
                    );
                }}
                className={classes.colCatalogue}
                style={{
                    backgroundColor: bg,
                    color: fontColor,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'flex-start'
                }}
                // style={{ backgroundColor: "#000000", color: fontColor }}
            >
                <img
                    id={'cardOfCatalogue' + props.catalogue.uid}
                    src={props.catalogue.coverImage}
                    alt=""
                    style={{
                        maxWidth: '123px',
                        marginRight: '8px',
                        width: '35%'
                    }}
                    onError={e => {
                        e.currentTarget.onerror = null;
                        e.currentTarget.src =
                            'https://via.placeholder.com/120x170.png';
                    }}
                />
                <div className={classes.catalogueDetail}>
                    <div className={classes.catalogueTitle}>
                        {props.catalogue.title}
                    </div>
                    <div>
                        วันที่สั่งซื้อ :{' '}
                        {
                            <p
                                style={{
                                    marginBottom: '0px',
                                    display: 'inline-block'
                                }}
                            >
                                {dateStr(props.catalogue.registerDate)}{' '}
                                {timeStr(props.catalogue.registerDate)}
                            </p>
                        }
                    </div>
                    {/* {props.catalogue.status === 3 || props.catalogue.status === 4 ? (
            <div>สถานะ : {statusWording(props.catalogue.status)}</div>
          ) : (
            ""
          )} */}
                    <div>จำนวนสิทธิ์ : {props.catalogue.concurrent} สิทธิ์</div>
                    {props.catalogue.contentAmount ? (
                        <div>
                            จำนวนอีบุ๊ก : {props.catalogue.contentAmount} เล่ม
                        </div>
                    ) : (
                        ''
                    )}
                    <div>แอดมิน : {props.catalogue.creatorEmail}</div>
                    {props.catalogue.voteStatus ? (
                        <div>การโหวต : {getVoteStatusText()}</div>
                    ) : null}

                    {props.catalogue.voteStartDate &&
                    props.catalogue.voteStartDate ? (
                        <div>
                            ระยะเวลาการโหวต :
                            {dayjs
                                .unix(props.catalogue.voteStartDate)
                                .locale('th')
                                .format('DD MMM YYYY')}
                            -{' '}
                            {dayjs
                                .unix(props.catalogue.voteEndDate)
                                .locale('th')
                                .format('DD MMM YYYY')}
                        </div>
                    ) : null}
                </div>
            </div>
            {props.catalogue.status === 0 ? (
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip id="quick-user-tooltip">ลบแคตตาล็อก</Tooltip>
                    }
                >
                    <a
                        className={`btn btn-icon btn-light btn-hover-danger btn-sm ${classes.deleteCatalogButton}`}
                        onClick={() =>
                            catalogueUIProps.openDeleteCatalogueDialog(
                                props.catalogue.title,
                                props.catalogue.uid
                            )
                        }
                    >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG
                                src={toAbsoluteUrl(
                                    '/media/members/delete-button.svg'
                                )}
                            />
                        </span>
                    </a>
                </OverlayTrigger>
            ) : (
                ''
            )}
        </Col>
    ) : (
        <div></div>
    );
};

const CardOfPublisher = props => {
    const catalogueUIContext = useCatalogueUIContext();
    const catalogueUIProps = useMemo(() => {
        return {
            viewEbooksButtonClick: catalogueUIContext.viewEbooksButtonClick
        };
    }, [catalogueUIContext]);
    return props.publisher ? (
        <Col sm={24} md={12} lg={8}>
            <div
                className="row"
                style={{
                    margin: '12px 0px',
                    height: '95px',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.3)',
                    borderRadius: '10px',
                    cursor: 'pointer'
                }}
                onClick={() =>
                    catalogueUIProps.viewEbooksButtonClick(
                        props.publisher.uid,
                        'publisher'
                    )
                }
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '16px'
                    }}
                >
                    <img
                        src={props.publisher.profileImage}
                        alt=""
                        style={{
                            width: '62px',
                            imageRendering: '-webkit-optimize-contrast',
                            margin: 'auto 0 auto 8px',
                            borderRadius: '50%'
                        }}
                    />
                    <span
                        style={{
                            alignItems: 'center',
                            margin: 'auto 0 auto 8px',
                            padding: '0px'
                        }}
                    >
                        {props.publisher.name}
                    </span>
                </div>
            </div>
        </Col>
    ) : (
        <div></div>
    );
};

const CardOfEbookBox = props => {
    const [color, setColor] = useState({ hex: '#7e7d7d' });
    const [bg, setBG] = useState('');
    const [fontColor, setFontColor] = useState('');

    const catalogueUIContext = useCatalogueUIContext();
    const catalogueUIProps = useMemo(() => {
        return {
            ids: catalogueUIContext.ids,
            newCatalogueButtonClick: catalogueUIContext.newCatalogueButtonClick,
            viewCatalogueDetailClick:
                catalogueUIContext.viewCatalogueDetailClick,
            viewPublishersButtonClick:
                catalogueUIContext.viewPublishersButtonClick,
            viewNewEbooksButtonClick:
                catalogueUIContext.viewNewEbooksButtonClick,
            viewEbooksButtonClick: catalogueUIContext.viewEbooksButtonClick,
            viewCategoriesButtonClick:
                catalogueUIContext.viewCategoriesButtonClick
        };
    }, [catalogueUIContext]);

    useEffect(() => {
        if (props.catalogue) {
            // setColorFunc(
            //     props.catalogue.coverImage,
            //     'cardOfEbookBox' +
            //         (props.type === 'subcategories'
            //             ? props.catalogue.subcatId
            //             : props.catalogue.catId),
            //     setColor,
            //     props.type !== 'categories' && props.type !== 'subcategories'
            // );
        }
    }, [props.catalogue]);
    useEffect(() => {
        setBG_FontColorFunc(color, setBG, setFontColor);
    }, [color]);
    const classes = useStyles1();
    const insideDiv = (
        <div
            onClick={() => {
                if (props.catalogue.clickFor === 'publishers') {
                    catalogueUIProps.viewPublishersButtonClick();
                } else if (props.catalogue.clickFor === 'categories') {
                    catalogueUIProps.viewCategoriesButtonClick();
                } else if (props.catalogue.clickFor === 'newEbooks') {
                    catalogueUIProps.viewNewEbooksButtonClick();
                } else if (props.catalogue.clickFor === 'selectPreset') {
                    if (props.setSelectedPreset) {
                        props.setSelectedPreset(props.catalogue.catId);
                    }
                } else if (props.type === 'categories') {
                    catalogueUIProps.viewCategoriesButtonClick(
                        props.catalogue.catId,
                        true
                    );
                } else {
                    catalogueUIProps.viewEbooksButtonClick(
                        props.type === 'subcategories'
                            ? props.catId
                            : props.catalogue.catId,
                        'subcategory',
                        props.type === 'subcategories'
                            ? props.catalogue.subcatId
                            : props.catalogue.catId
                    );
                }
                // catalogueUIProps.viewCatalogueDetailClick(props.catalogue.id)
            }}
            className={`${
                props.type === 'type'
                    ? classes.colCatalogueType
                    : classes.colCatalogue
            }`}
            style={
                props.type === 'type'
                    ? {
                          backgroundColor:
                              props.catalogue && props.catalogue.bgColor,
                          color: '#ffffff',
                          textAlign: 'center',
                          margin:
                              props.catalogue &&
                              props.catalogue.clickFor === 'selectPreset' &&
                              props.catalogue.catId === props.selectedPreset
                                  ? '-5px'
                                  : 'auto',
                          border:
                              props.catalogue &&
                              props.catalogue.clickFor === 'selectPreset' &&
                              props.catalogue.catId === props.selectedPreset
                                  ? '5px solid green'
                                  : 'none'
                      }
                    : {
                          backgroundColor: bg,
                          color: fontColor,
                          textAlign: 'center'
                      }
            }
            // style={{
            //   backgroundColor: "#000000",
            //   color: fontColor,
            //   textAlign: "center"
            // }}
        >
            {props.type === 'type' ? (
                ''
            ) : (
                <div className={classes.catalogueTitle}>
                    {props.type === 'subcategories'
                        ? props.catalogue.subcatName
                        : props.catalogue.catName}
                </div>
            )}
            <img
                id={
                    'cardOfEbookBox' +
                    (props.type === 'subcategories'
                        ? props.catalogue.subcatId
                        : props.catalogue.catId)
                }
                src={props.catalogue.coverImage}
                alt=""
                style={{
                    margin: '20px auto',
                    height: '150px',
                    imageRendering: '-webkit-optimize-contrast'
                }}
            />
            {props.type === 'type' ? (
                <div className={classes.catalogueTitle18}>
                    {props.type === 'subcategories'
                        ? props.catalogue.subcatName
                        : props.catalogue.catName}
                </div>
            ) : (
                ''
            )}
        </div>
    );
    return props.catalogue ? (
        props.type === 'type' ? (
            <div className={classes.colType}>{insideDiv}</div>
        ) : (
            <Col md={8} lg={6} xxl={4}>
                {insideDiv}
            </Col>
        )
    ) : (
        <div></div>
    );
};

const CardOfCatalogueDetail = props => {
    const [licenseNum, setLicenseNum] = useState(0);
    const [loadedOrderAmount, setLoadedOrderAmount] = useState(false);
    const [lifetimePurchase, setLifetimePurchase] = useState(false);
    const catalogueUIContext = useCatalogueUIContext();
    const catalogueUIProps = useMemo(() => {
        return {
            ids: catalogueUIContext.ids,
            newCatalogueButtonClick: catalogueUIContext.newCatalogueButtonClick,
            viewCatalogueDetailClick:
                catalogueUIContext.viewCatalogueDetailClick,
            openViewCatalogueEbookDialog:
                catalogueUIContext.openViewCatalogueEbookDialog
        };
    }, [catalogueUIContext]);

    useEffect(() => {
        if (props.catalogue) {
            setLicenseNum(props.catalogue.concurrent);
            setLoadedOrderAmount(true);
        }
    }, [props.catalogue]);

    useEffect(() => {
        setLifetimePurchase(props.catalogue.lifetimePurchase);
    }, [props.catalogue.lifetimePurchase]);

    const updateMinusEbookConcurrent = (num, minus, isUpdate) => {
        if (isUpdate) {
            props.setTotalConcurrent(old => {
                if (licenseNum > 0) {
                    props.setSaved(true);
                    if (lifetimePurchase) {
                        return old + minus * 2;
                    } else {
                        return old + minus;
                    }
                } else return props.totalConcurrent;
            });
        }
        let dict = {};
        dict[props.catalogue.contentUID] = {
            concurrent: num - minus,
            lifetimePurchase
        };
        props.setCatalogueEbooks(old => Object.assign({}, old, dict));

        return num - minus;
    };
    const updatePlusEbookConcurrent = (num, plus, isUpdate) => {
        if (isUpdate) {
            props.setTotalConcurrent(old => {
                if (lifetimePurchase) {
                    return old - plus * 2;
                } else {
                    return old - plus;
                }
            });
            props.setSaved(true);
        }
        let dict = {};
        dict[props.catalogue.contentUID] = {
            concurrent: num + plus,
            lifetimePurchase
        };

        props.setCatalogueEbooks(old => {
            return Object.assign({}, old, dict);
        });
        return num + plus;
    };

    const onChangeSwitchLifttime = e => {
        setLifetimePurchase(e);
        if (e) {
            props.setTotalConcurrent(old => {
                if (licenseNum > 0) {
                    props.setSaved(true);
                    return old - licenseNum;
                } else return props.totalConcurrent;
            });
        } else {
            props.setTotalConcurrent(old => {
                if (licenseNum > 0) {
                    props.setSaved(true);
                    return old + licenseNum;
                } else return props.totalConcurrent;
            });
        }

        let dict = {};
        dict[props.catalogue.contentUID] = {
            concurrent: licenseNum,
            lifetimePurchase: e
        };

        props.setCatalogueEbooks(old => {
            console.log(Object.assign({}, old, dict));
            return Object.assign({}, old, dict);
        });
    };

    // const calulatePercent = (voteAmount, allcatalog) => {
    //    let percent = (voteAmount / allcatalog) * 100;
    //    percent = parseFloat(percent.toFixed(2));
    //    return percent;
    // };

    const displayPublicationDate = () => {
        if (
            props.catalogue?.publicationDate &&
            props.catalogue?.publicationDate !== 'Invalid Date'
        ) {
            const dateNumber = parseFloat(props.catalogue.publicationDate);
            if (dateNumber < 3000) {
                return dateNumber;
            } else {
                return timeStampToYear(props.catalogue.publicationDate);
            }
        } else {
            return '-';
        }
    };

    const classes = useStyles1();
    return props.catalogue ? (
        <Col span={12}>
            <div className={classes.colCatalogueDetail}>
                <div className={`filetype ${props.catalogue.fileType}`}>
                    {props.catalogue.fileType}
                </div>
                <img
                    onClick={() => {
                        catalogueUIProps.openViewCatalogueEbookDialog(
                            props.catalogue.contentUID
                        );
                    }}
                    id={props.catalogue.contentUID}
                    src={props.catalogue.coverImage}
                    alt=""
                    className={classes.catalogueDetailImage}
                />
                <div className={classes.catalogueDetailDetail}>
                    <div>
                        <span
                            onClick={() =>
                                catalogueUIProps.openViewCatalogueEbookDialog(
                                    props.catalogue.contentUID
                                )
                            }
                            className={classes.catalogueDetailTitle}
                        >
                            {props.catalogue.title}
                        </span>
                    </div>
                    <div className={classes.marginBottom2px}>
                        ผู้แต่ง :{' '}
                        {props.catalogue.authorName
                            ? props.catalogue.authorName
                            : '-'}
                    </div>
                    <div className={classes.marginBottom2px}>
                        <util.CardOfCatalogueDetailPublisher
                            id={props.id}
                            catalogue={props.catalogue}
                            catalogueForEdit={props.catalogueForEdit}
                            updateCatalogueEbooks={props.updateCatalogueEbooks}
                            setSaved={props.setSaved}
                            setLoadingSaving={props.setLoadingSaving}
                        />
                    </div>
                    <div className={classes.marginBottom2px}>
                        ปีที่ตีพิมพ์ :{' '}
                        {/* {props.catalogue.publicationDate
                            ? timeStampToYear(props.catalogue.publicationDate)
                            : '-'} */}
                        {displayPublicationDate()}
                    </div>
                    <div className={classes.marginBottom2px}>
                        <util.CardOfCatalogueDetailCategory
                            id={props.id}
                            catalogue={props.catalogue}
                            catalogueForEdit={props.catalogueForEdit}
                            updateCatalogueEbooks={props.updateCatalogueEbooks}
                            setSaved={props.setSaved}
                            setLoadingSaving={props.setLoadingSaving}
                        />
                    </div>
                    <div>
                        จำนวนสิทธิ์ที่มี :{' '}
                        {!isNil(props.catalogue?.purchasedConcurrent)
                            ? props.catalogue.purchasedConcurrent
                            : '-'}{' '}
                        สิทธิ์
                        <span style={{ color: '#D7042B' }}>
                            {minimumTwoBooks.includes(
                                props.catalogue.creatorUID
                            )
                                ? '(อย่างน้อยปกละ 2 สิทธิ์)'
                                : ''}
                        </span>
                    </div>
                    <div className={classes.marginBottom2px}>
                        {/* // RyouCatalogLifttime */}
                        <span style={{ marginRight: '8px' }}>
                            จำนวนสิทธิ์ :{' '}
                        </span>
                        {props.catalogueForEdit &&
                        (props.catalogueForEdit.status === 2 ||
                            props.catalogueForEdit.status === 3) ? (
                            <>
                                {licenseNum}
                                {lifetimePurchase ? (
                                    <div>ขายต่อเนื่อง</div>
                                ) : null}
                            </>
                        ) : (
                            <div className={'concurrentRow'}>
                                {minimumTwoBooks.includes(
                                    props.catalogue.creatorUID
                                ) ? (
                                    <div>
                                        <>
                                            {' '}
                                            {licenseNum > 0 &&
                                            props.catalogueForEdit &&
                                            loadedOrderAmount ? (
                                                <>
                                                    <img
                                                        src="/media/members/blue-minus.png"
                                                        alt=""
                                                        class="minus small "
                                                        onClick={() => {
                                                            setLicenseNum(
                                                                num => {
                                                                    if (
                                                                        num > 0
                                                                    ) {
                                                                        if (
                                                                            num ===
                                                                            2
                                                                        ) {
                                                                            return updateMinusEbookConcurrent(
                                                                                num,
                                                                                2,
                                                                                true
                                                                            );
                                                                        } else {
                                                                            return updateMinusEbookConcurrent(
                                                                                num,
                                                                                1,
                                                                                true
                                                                            );
                                                                        }
                                                                    } else {
                                                                        return updateMinusEbookConcurrent(
                                                                            num,
                                                                            0,
                                                                            false
                                                                        );
                                                                    }
                                                                }
                                                            );
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <img
                                                        src="/media/members/grey-minus.png"
                                                        alt=""
                                                        class="minus small disabled"
                                                    />
                                                </>
                                            )}
                                            <p
                                                className={
                                                    licenseNum
                                                        ? classes.blueDigit
                                                        : classes.greyDigit
                                                }
                                            >
                                                {licenseNum}สส
                                            </p>
                                            {!(
                                                props.totalConcurrent === 1 &&
                                                licenseNum === 0
                                            ) &&
                                            Number.isInteger(
                                                props.totalConcurrent
                                            ) &&
                                            props.totalConcurrent > 0 &&
                                            props.catalogueForEdit &&
                                            loadedOrderAmount ? (
                                                <>
                                                    <img
                                                        src="/media/members/blue-plus.png"
                                                        alt=""
                                                        class="plus small "
                                                        onClick={() => {
                                                            if (
                                                                licenseNum === 0
                                                            ) {
                                                                setLicenseNum(
                                                                    num => {
                                                                        return updatePlusEbookConcurrent(
                                                                            num,
                                                                            2,
                                                                            true
                                                                        );
                                                                    }
                                                                );
                                                            } else {
                                                                setLicenseNum(
                                                                    num => {
                                                                        return updatePlusEbookConcurrent(
                                                                            num,
                                                                            1,
                                                                            true
                                                                        );
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <img
                                                        src="/media/members/grey-plus.png"
                                                        alt=""
                                                        class="plus small disabled"
                                                    />
                                                </>
                                            )}
                                        </>
                                    </div>
                                ) : (
                                    <div>
                                        <>
                                            {licenseNum > 0 &&
                                            props.catalogueForEdit &&
                                            loadedOrderAmount ? (
                                                <>
                                                    <img
                                                        src="/media/members/blue-minus.png"
                                                        alt=""
                                                        class="minus small "
                                                        onClick={() => {
                                                            setLicenseNum(
                                                                num => {
                                                                    if (
                                                                        num > 0
                                                                    ) {
                                                                        return updateMinusEbookConcurrent(
                                                                            num,
                                                                            1,
                                                                            true
                                                                        );
                                                                    } else {
                                                                        return updateMinusEbookConcurrent(
                                                                            num,
                                                                            0,
                                                                            false
                                                                        );
                                                                    }
                                                                }
                                                            );
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <img
                                                        src="/media/members/grey-minus.png"
                                                        alt=""
                                                        class="minus small disabled"
                                                    />
                                                </>
                                            )}
                                            <p
                                                className={
                                                    licenseNum
                                                        ? classes.blueDigit
                                                        : classes.greyDigit
                                                }
                                                style={{
                                                    fontSize: '18px',
                                                    margin: '0px 8px'
                                                }}
                                            >
                                                {licenseNum}
                                            </p>
                                            {Number.isInteger(
                                                props.totalConcurrent
                                            ) &&
                                            props.totalConcurrent > 0 &&
                                            props.catalogueForEdit &&
                                            loadedOrderAmount ? (
                                                <>
                                                    <img
                                                        src="/media/members/blue-plus.png"
                                                        alt=""
                                                        class="minus small "
                                                        onClick={() => {
                                                            setLicenseNum(
                                                                num => {
                                                                    return updatePlusEbookConcurrent(
                                                                        num,
                                                                        1,
                                                                        true
                                                                    );
                                                                }
                                                            );
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <img
                                                        src="/media/members/grey-plus.png"
                                                        alt=""
                                                        class="minus small disabled"
                                                    />
                                                </>
                                            )}
                                        </>
                                    </div>
                                )}
                                {props.catalogue.lifetimeSaleAvailable ? (
                                    <div>
                                        ขายต่อเนื่อง{' '}
                                        <Switch
                                            checked={lifetimePurchase}
                                            onChange={onChangeSwitchLifttime}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </div>
                    <div></div>
                    <div>จำนวนโหวต : {props.catalogue?.voteCount}</div>
                    {props.catalogueForEdit &&
                    props.catalogueForEdit.status !== 2 ? (
                        <div>
                            สถานะ :{' '}
                            {props.catalogue.status === 0 ? (
                                <span className="text-small text-orange">
                                    รอการตรวจสอบ
                                </span>
                            ) : props.catalogue.status === 1 ? (
                                <span className="text-small text-red">
                                    {props.catalogue.rejectMessages}
                                </span>
                            ) : props.catalogue.status === 2 ? (
                                <span className="text-small text-green">
                                    ผ่านการตรวจสอบ
                                </span>
                            ) : (
                                '-'
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </Col>
    ) : (
        <div></div>
    );
};

// RyouCatalogLifttime
const CardOfEbook = props => {
    const history = useHistory();
    const [licenseNum, setLicenseNum] = useState(0);
    const [loadedOrderAmount, setLoadedOrderAmount] = useState(false);
    const [lifetimePurchase, setLifetimePurchase] = useState(
        props.ebook.lifetimePurchase
    );
    const catalogueUIContext = useCatalogueUIContext();
    const catalogueUIProps = useMemo(() => {
        return {
            ids: catalogueUIContext.ids,
            newCatalogueButtonClick: catalogueUIContext.newCatalogueButtonClick,
            viewCatalogueDetailClick:
                catalogueUIContext.viewCatalogueDetailClick,
            openViewCatalogueEbookDialog:
                catalogueUIContext.openViewCatalogueEbookDialog
        };
    }, [catalogueUIContext]);
    const params = history.location.search;
    const catalogId = new URLSearchParams(params).get('id');
    useEffect(() => {
        if (props.ebook) {
            //&& props.type !== "search"
            let stored = JSON.parse(localStorage.getItem('catalogueEbooks'));
            if (localStorage.getItem('catalogueEbooks')) {
                props.setSaved(true);
                console.log(stored[props.ebook.contentUID]);

                setLifetimePurchase(
                    stored[props.ebook.contentUID]?.lifetimePurchase ?? false
                );
                if (
                    Number.isInteger(stored[props.ebook.contentUID]?.concurrent)
                ) {
                    setLicenseNum(stored[props.ebook.contentUID]?.concurrent);
                    setLoadedOrderAmount(true);
                } else {
                    setLicenseNum(props.ebook.orderAmount);
                    setLoadedOrderAmount(true);
                }
            } else {
                setLicenseNum(props.ebook.orderAmount);
                setLoadedOrderAmount(true);
            }
        }
    }, [props.ebook, props.originConcurrent, props.setTotalConcurrent]);

    const classes = useStyles1();

    const onChangeSwitchLifttime = e => {
        setLifetimePurchase(e);
        let dict = {};
        let dictIon = {};
        dict[props.ebook.contentUID] = {
            concurrent: licenseNum,
            lifetimePurchase: e
        };
        dictIon[props.ebook.contentUID] = {
            concurrent: licenseNum,
            lifetimePurchase: e
        };
        props.setCatalogueEbooks(old => {
            let newItems = Object.assign({}, old, dict);
            localStorage.setItem('catalogueEbooks', JSON.stringify(newItems));
            return newItems;
        });
        // props.setCatalogueEbooksIon(old => {
        //     let newItemsIon = Object.assign({}, old, dictIon);
        //     localStorage.setItem(
        //         'catalogueEbooksIon',
        //         JSON.stringify(newItemsIon)
        //     );
        //     return newItemsIon;
        // });
        if (e) {
            props.setTotalConcurrent(old => {
                return old - licenseNum;
            });
            props.setSaved(true);
        } else {
            props.setTotalConcurrent(old => {
                return old + licenseNum;
            });
        }
    };

    const decreaseConcurrentMinimumTwo = () => {
        if (licenseNum > 0 && props.catalogueForEdit && loadedOrderAmount) {
            setLicenseNum(num => {
                let dict = {};
                let dictIon = {};
                if (num === 2) {
                    props.setTotalConcurrent(old => {
                        if (licenseNum > 0) {
                            props.setSaved(true);
                            if (lifetimePurchase) {
                                return old + 4;
                            } else {
                                return old + 2;
                            }
                        } else return props.totalConcurrent;
                    });
                    dict[props.ebook.contentUID] = {
                        concurrent: num - 2,
                        lifetimePurchase
                    };
                    dictIon[props.ebook.contentUID] = {
                        concurrent: num - props.ebook.orderAmount - 2,
                        lifetimePurchase
                    };
                    props.setCatalogueEbooks(old => {
                        let newItems = Object.assign({}, old, dict);
                        localStorage.setItem(
                            'catalogueEbooks',
                            JSON.stringify(newItems)
                        );
                        return newItems;
                    });
                    // props.setCatalogueEbooksIon(old => {
                    //     let newItemsIon = Object.assign({}, old, dictIon);
                    //     localStorage.setItem(
                    //         'catalogueEbooksIon',
                    //         JSON.stringify(newItemsIon)
                    //     );
                    //     return newItemsIon;
                    // });
                    return num - 2;
                } else {
                    if (num > 0) {
                        props.setTotalConcurrent(old => {
                            if (licenseNum > 0) {
                                props.setSaved(true);
                                if (lifetimePurchase) {
                                    return old + 2;
                                } else {
                                    return old + 1;
                                }
                            } else return props.totalConcurrent;
                        });
                        dict[props.ebook.contentUID] = {
                            concurrent: num - 1,
                            lifetimePurchase
                        };
                        dictIon[props.ebook.contentUID] = {
                            concurrent: num - props.ebook.orderAmount - 1,
                            lifetimePurchase
                        };
                        props.setCatalogueEbooks(old => {
                            let newItems = Object.assign({}, old, dict);
                            localStorage.setItem(
                                'catalogueEbooks',
                                JSON.stringify(newItems)
                            );
                            return newItems;
                        });
                        // props.setCatalogueEbooksIon(old => {
                        //     let newItemsIon = Object.assign({}, old, dictIon);
                        //     localStorage.setItem(
                        //         'catalogueEbooksIon',
                        //         JSON.stringify(newItemsIon)
                        //     );
                        //     return newItemsIon;
                        // });
                        return num - 1;
                    } else {
                        dict[props.ebook.contentUID] = {
                            concurrent: num,
                            lifetimePurchase
                        };
                        dictIon[props.ebook.contentUID] = {
                            concurrent: num - props.ebook.orderAmount,
                            lifetimePurchase
                        };
                        props.setCatalogueEbooks(old => {
                            let newItems = Object.assign({}, old, dict);
                            localStorage.setItem(
                                'catalogueEbooks',
                                JSON.stringify(newItems)
                            );
                            return newItems;
                        });
                        // props.setCatalogueEbooksIon(old => {
                        //     let newItemsIon = Object.assign({}, old, dictIon);
                        //     localStorage.setItem(
                        //         'catalogueEbooksIon',
                        //         JSON.stringify(newItemsIon)
                        //     );
                        //     return newItemsIon;
                        // });
                        return num;
                    }
                }
            });
        }
    };

    const increaseConcurrentMinimumTwo = () => {
        if (props.totalConcurrent === 1 && licenseNum === 0) {
        } else {
            if (
                Number.isInteger(props.totalConcurrent) &&
                props.totalConcurrent > 0 &&
                props.catalogueForEdit &&
                loadedOrderAmount
            ) {
                if (licenseNum === 0) {
                    setLicenseNum(num => {
                        let dict = {};
                        let dictIon = {};
                        dict[props.ebook.contentUID] = {
                            concurrent: num + 2,
                            lifetimePurchase
                        };
                        dictIon[props.ebook.contentUID] = {
                            concurrent: num - props.ebook.orderAmount + 2,
                            lifetimePurchase
                        };
                        props.setCatalogueEbooks(old => {
                            let newItems = Object.assign({}, old, dict);
                            localStorage.setItem(
                                'catalogueEbooks',
                                JSON.stringify(newItems)
                            );
                            return newItems;
                        });
                        // props.setCatalogueEbooksIon(old => {
                        //     let newItemsIon = Object.assign({}, old, dictIon);
                        //     localStorage.setItem(
                        //         'catalogueEbooksIon',
                        //         JSON.stringify(newItemsIon)
                        //     );
                        //     return newItemsIon;
                        // });
                        return num + 2;
                    });
                    props.setTotalConcurrent(old => {
                        console.log(lifetimePurchase);
                        if (lifetimePurchase) {
                            return old - 4;
                        } else {
                            return old - 2;
                        }
                    });
                    props.setSaved(true);
                } else {
                    setLicenseNum(num => {
                        let dict = {};
                        let dictIon = {};
                        dict[props.ebook.contentUID] = {
                            concurrent: num + 1,
                            lifetimePurchase
                        };
                        dictIon[props.ebook.contentUID] = {
                            concurrent: num - props.ebook.orderAmount + 1,
                            lifetimePurchase
                        };

                        props.setCatalogueEbooks(old => {
                            let newItems = Object.assign({}, old, dict);
                            localStorage.setItem(
                                'catalogueEbooks',
                                JSON.stringify(newItems)
                            );
                            return newItems;
                        });
                        // props.setCatalogueEbooksIon(old => {
                        //     let newItemsIon = Object.assign({}, old, dictIon);
                        //     localStorage.setItem(
                        //         'catalogueEbooksIon',
                        //         JSON.stringify(newItemsIon)
                        //     );
                        //     return newItemsIon;
                        // });
                        return num + 1;
                    });
                    props.setTotalConcurrent(old => {
                        if (lifetimePurchase) {
                            return old - 2;
                        } else {
                            return old - 1;
                        }
                    });
                    props.setSaved(true);
                }
            }
        }
    };

    const increaseConcurrent = () => {
        if (
            Number.isInteger(props.totalConcurrent) &&
            props.totalConcurrent > 0 &&
            props.catalogueForEdit &&
            loadedOrderAmount
        ) {
            setLicenseNum(num => {
                let dict = {};
                let dictIon = {};

                let concurrent = num - props.ebook.orderAmount + 1;
                dict[props.ebook.contentUID] = {
                    concurrent: num + 1,
                    lifetimePurchase
                };

                dictIon[props.ebook.contentUID] = {
                    concurrent,
                    lifetimePurchase
                };
                props.setCatalogueEbooks(old => {
                    let newItems = Object.assign({}, old, dict);
                    localStorage.setItem(
                        'catalogueEbooks',
                        JSON.stringify(newItems)
                    );
                    return newItems;
                });
                // props.setCatalogueEbooksIon(old => {
                //     let newItemsIon = Object.assign({}, old, dictIon);
                //     localStorage.setItem(
                //         'catalogueEbooksIon',
                //         JSON.stringify(newItemsIon)
                //     );
                //     return newItemsIon;
                // });
                return num + 1;
            });
            props.setTotalConcurrent(old => {
                console.log('life time plus icon :', lifetimePurchase);
                if (lifetimePurchase) {
                    return old - 2;
                } else {
                    return old - 1;
                }
            });
            props.setSaved(true);
        }
    };

    const decreaseConcurrent = () => {
        if (licenseNum > 0 && props.catalogueForEdit && loadedOrderAmount) {
            setLicenseNum(num => {
                let dict = {};
                let dictIon = {};
                let concurrent = num - props.ebook.orderAmount - 1;

                if (num > 0) {
                    props.setTotalConcurrent(old => {
                        if (licenseNum > 0) {
                            props.setSaved(true);
                            if (lifetimePurchase) {
                                return old + 2;
                            } else {
                                return old + 1;
                            }
                        } else return props.totalConcurrent;
                    });
                    dict[props.ebook.contentUID] = {
                        concurrent: num - 1,
                        lifetimePurchase
                    };
                    dictIon[props.ebook.contentUID] = {
                        concurrent,
                        lifetimePurchase
                    };
                    props.setCatalogueEbooks(old => {
                        let newItems = Object.assign({}, old, dict);
                        localStorage.setItem(
                            'catalogueEbooks',
                            JSON.stringify(newItems)
                        );
                        return newItems;
                    });
                    // props.setCatalogueEbooksIon(old => {
                    //     let newItemsIon = Object.assign({}, old, dictIon);
                    //     localStorage.setItem(
                    //         'catalogueEbooksIon',
                    //         JSON.stringify(newItemsIon)
                    //     );
                    //     return newItemsIon;
                    // });
                    return num - 1;
                } else {
                    dict[props.ebook.contentUID] = {
                        concurrent: num,
                        lifetimePurchase
                    };

                    dictIon[props.ebook.contentUID] = {
                        concurrent: num - props.ebook.orderAmount,
                        lifetimePurchase
                    };

                    props.setCatalogueEbooks(old => {
                        let newItems = Object.assign({}, old, dict);
                        localStorage.setItem(
                            'catalogueEbooks',
                            JSON.stringify(newItems)
                        );
                        return newItems;
                    });
                    // props.setCatalogueEbooksIon(old => {
                    //     let newItemsIon = Object.assign({}, old, dictIon);
                    //     localStorage.setItem(
                    //         'catalogueEbooksIon',
                    //         JSON.stringify(newItemsIon)
                    //     );
                    //     return newItemsIon;
                    // });
                    return num;
                }
            });
        }
    };

    const displayPublicationDate = () => {
        if (
            props.ebook?.publicationDate !== undefined &&
            props.ebook?.publicationDate !== 'Invalid Date'
        ) {
            const dateNumber = parseFloat(props.ebook.publicationDate);
            if (dateNumber < 3000) {
                return dateNumber;
            } else {
                return timeStampToYear(props.ebook.publicationDate);
            }
        } else {
            return '-';
        }
    };

    return props.ebook ? (
        <Col span={12}>
            <div className={classes.colCatalogueDetail}>
                <div className={`filetype ${props.ebook.fileType}`}>
                    {props.ebook.fileType}
                </div>
                <img
                    //openModal
                    onClick={() =>
                        catalogueUIProps.openViewCatalogueEbookDialog(
                            props.ebook.contentUID
                        )
                    }
                    id={props.ebook.contentUID}
                    src={props.ebook.coverImage}
                    alt=""
                    className={classes.catalogueDetailImage}
                />
                <div className={classes.catalogueDetailDetail}>
                    <div>
                        <span
                            onClick={() =>
                                catalogueUIProps.openViewCatalogueEbookDialog(
                                    props.ebook.contentUID
                                )
                            }
                            className={classes.catalogueDetailTitle}
                        >
                            {props.ebook.title}
                        </span>
                    </div>
                    <div>
                        ผู้แต่ง :{' '}
                        {props.ebook.authorName ? props.ebook.authorName : '-'}
                    </div>
                    <div>
                        สำนักพิมพ์ :{' '}
                        <span
                            className={
                                props.ebook.creatorUID &&
                                props.ebook.publisherName !==
                                    props.publisherName
                                    ? classes.hoverLink
                                    : ''
                            }
                            onClick={() => {
                                if (
                                    //ryou
                                    props.ebook.creatorUID &&
                                    props.ebook.publisherName !==
                                        props.publisherName &&
                                    catalogId
                                )
                                    history.push(
                                        `/catalogue-detail?id=${catalogId}&newEbook=true&ebooks=true&publisherId=${props.ebook.creatorUID}`
                                    );
                            }}
                        >
                            {props.ebook.publisherName
                                ? props.ebook.publisherName
                                : props.ebook.authorName
                                ? props.ebook.authorName
                                : '-'}
                        </span>
                    </div>
                    <div style={{ float: 'left', width: '100%' }}>
                        <div>
                            ปีที่ตีพิมพ์ : {displayPublicationDate()}
                            {/* {props.ebook.publicationDate
                                ? timeStampToYear(props.ebook.publicationDate)
                                : '-'} */}
                        </div>
                        <div>
                            หมวดหมู่ :{' '}
                            <span
                                className={
                                    props.ebook.catId ? classes.hoverLink : ''
                                }
                                onClick={() => {
                                    if (props.ebook.catId && catalogId)
                                        history.push(
                                            `/catalogue-detail?id=${catalogId}&newEbook=true&catId=${props.ebook.catId}&subcategories=true`
                                        );
                                }}
                            >
                                {props.ebook.catName}
                            </span>{' '}
                            /{' '}
                            <span
                                className={
                                    props.ebook.catId &&
                                    props.ebook.subcatId &&
                                    props.ebook.subcatName !== props.subcatName
                                        ? classes.hoverLink
                                        : ''
                                }
                                onClick={() => {
                                    if (
                                        props.ebook.catId &&
                                        props.ebook.subcatId &&
                                        props.ebook.subcatName !==
                                            props.subcatName
                                    )
                                        history.push(
                                            `/catalogue-detail?id=${catalogId}&newEbook=true&ebooks=true&catId=${props.ebook.catId}&subcatId=${props.ebook.subcatId}`
                                        );
                                }}
                            >
                                {props.ebook.subcatName}
                            </span>
                        </div>

                        <div>
                            จำนวนสิทธิ์ที่มี :{' '}
                            {!isNil(props.catalogue?.purchasedConcurrent)
                                ? props.catalogue.purchasedConcurrent
                                : null}
                            {!isNil(props.ebook?.purchasedConcurrent)
                                ? props.ebook.purchasedConcurrent
                                : null}{' '}
                            สิทธิ์
                        </div>
                        <div className={classes.catalogueDetailLicense}>
                            เลือกจำนวนสิทธิ์{' '}
                            <span style={{ color: '#D7042B' }}>
                                {minimumTwoBooks.includes(
                                    props.ebook.creatorUID
                                )
                                    ? '(ต้องซื้ออย่างน้อยปกละ 2 สิทธิ์)'
                                    : ''}
                            </span>
                        </div>
                        {/* <MinusCircleOutlined style={{ fontSize: "30px", color: "#BDBDBD" }} /> */}
                        <div className="concurrentRow">
                            {/* {minimumTwoBooks.includes(
                                props.ebook.creatorUID
                            ) ? ( */}
                            <div>
                                <img
                                    src={`/media/members/${
                                        licenseNum > 0 &&
                                        props.catalogueForEdit &&
                                        loadedOrderAmount
                                            ? 'blue'
                                            : 'grey'
                                    }-minus.png`}
                                    alt=""
                                    onClick={() => {
                                        if (
                                            minimumTwoBooks.includes(
                                                props.ebook.creatorUID
                                            )
                                        ) {
                                            decreaseConcurrentMinimumTwo();
                                        } else {
                                            decreaseConcurrent();
                                        }
                                    }}
                                    className={`minus ${
                                        licenseNum > 0 &&
                                        props.catalogueForEdit &&
                                        loadedOrderAmount
                                            ? ``
                                            : `disabled`
                                    }`}
                                />
                                <p
                                    className={
                                        licenseNum
                                            ? classes.blueDigit
                                            : classes.greyDigit
                                    }
                                >
                                    {licenseNum}
                                </p>
                                {/* <PlusCircleOutlined style={{ fontSize: "30px", color: "#012875" }} /> */}

                                <img
                                    src={`/media/members/${
                                        props.totalConcurrent === 1 &&
                                        lifetimePurchase
                                            ? 'grey'
                                            : Number.isInteger(
                                                  props.totalConcurrent
                                              ) &&
                                              props.totalConcurrent > 0 &&
                                              props.catalogueForEdit &&
                                              loadedOrderAmount
                                            ? 'blue'
                                            : 'grey'
                                    }-plus.png`}
                                    onClick={() => {
                                        if (
                                            minimumTwoBooks.includes(
                                                props.ebook.creatorUID
                                            )
                                        ) {
                                            increaseConcurrentMinimumTwo();
                                        } else {
                                            increaseConcurrent();
                                        }
                                    }}
                                    alt=""
                                    className={`plus ${
                                        (props.totalConcurrent === 1 &&
                                            lifetimePurchase) ||
                                        !(
                                            Number.isInteger(
                                                props.totalConcurrent
                                            ) &&
                                            props.totalConcurrent > 0 &&
                                            props.catalogueForEdit &&
                                            loadedOrderAmount
                                        )
                                            ? `disabled`
                                            : ``
                                    }`}
                                />
                            </div>
                            {/* ) : (
                                <div>
                                    {licenseNum !== undefined && (
                                        <img
                                            src={`/media/members/${
                                                licenseNum > 0 &&
                                                props.catalogueForEdit &&
                                                loadedOrderAmount
                                                    ? 'blue'
                                                    : 'grey'
                                            }-minus.png`}
                                            alt=""
                                            onClick={() => {
                                              decreaseConcurrent()
                                            }}
                                            className={`minus ${
                                                licenseNum > 0 &&
                                                props.catalogueForEdit &&
                                                loadedOrderAmount
                                                    ? ``
                                                    : `disabled`
                                            }`}
                                        />
                                    )}
                                    <p
                                        className={
                                            licenseNum
                                                ? classes.blueDigit
                                                : classes.greyDigit
                                        }
                                    >
                                        {licenseNum}
                                    </p>

                                    {licenseNum !== undefined && (
                                        <img
                                            src={`/media/members/${
                                                Number.isInteger(
                                                    props.totalConcurrent
                                                ) &&
                                                props.totalConcurrent > 0 &&
                                                props.catalogueForEdit &&
                                                loadedOrderAmount
                                                    ? 'blue'
                                                    : 'grey'
                                            }-plus.png`}
                                            onClick={() => {
                                               increaseConcurrent()
                                            }}
                                            alt=""
                                            className={`plus ${
                                                Number.isInteger(
                                                    props.totalConcurrent
                                                ) &&
                                                props.totalConcurrent > 0 &&
                                                props.catalogueForEdit &&
                                                loadedOrderAmount
                                                    ? ``
                                                    : `disabled`
                                            }`}
                                        />
                                    )}
                                </div>
                            )
                            } */}

                            {props.ebook.lifetimeSaleAvailable ? (
                                <div>
                                    ขายต่อเนื่อง{' '}
                                    <Switch
                                        checked={lifetimePurchase}
                                        onChange={onChangeSwitchLifttime}
                                    />{' '}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    ) : (
        <div></div>
    );
};

const timeStampToYear = t => {
    if (t) {
        const date = new Date(t * 1000);
        const dateStr = date.getFullYear();
        return (
            <>
                <p style={{ marginBottom: '0px', display: 'inline-block' }}>
                    {dateStr}
                </p>
            </>
        );
    }
};
