import { createSlice } from '@reduxjs/toolkit';

const initialReportsState = {
   listLoading: false,
   actionsLoading: false,
   totalCount: 0,
   entities: [],
   reportForEdit: undefined,
   lastError: null,
   LastEvaluatedKey: {}
};
export const callTypes = {
   list: 'list',
   action: 'action'
};

export const reportsSlice = createSlice({
   name: 'reports',
   initialState: initialReportsState,
   reducers: {
      catchError: (state, action) => {
         state.error = `${action.type}: ${action.payload.error}`;
         if (action.payload.callType === callTypes.list) {
            state.listLoading = false;
         } else {
            state.actionsLoading = false;
         }
      },
      startCall: (state, action) => {
         state.error = null;
         if (action.payload.callType === callTypes.list) {
            state.listLoading = true;
         } else {
            state.actionsLoading = true;
         }
      },
      // getReportById
      reportFetched: (state, action) => {
         state.actionsLoading = false;
         state.reportForEdit = action.payload.reportForEdit;
         state.error = null;
      },
      // findReports
      reportsFetched: (state, action) => {
         const { totalCount, entities, LastEvaluatedKey } = action.payload;
         state.listLoading = false;
         state.error = null;
         state.entities = entities;
         state.totalCount = totalCount;
         state.LastEvaluatedKey = LastEvaluatedKey;
         // state.entities = mockUsers;
         // state.totalCount = 17;
      },
      // createReport
      reportCreated: (state, action) => {
         state.actionsLoading = false;
         state.error = null;
         state.entities.push(action.payload.report);
      },
      // updateReport
      reportUpdated: (state, action) => {
         state.error = null;
         state.actionsLoading = false;
         state.entities = state.entities.map(entity => {
            if (entity.id === action.payload.report.id) {
               return action.payload.report;
            }
            return entity;
         });
      },
      // deleteReport
      reportDeleted: (state, action) => {
         state.error = null;
         state.actionsLoading = false;
         state.entities = state.entities.filter(
            el => el.id !== action.payload.id
         );
      },
      // blockReport
      reportBlocked: (state, action) => {
         state.error = null;
         state.actionsLoading = false;
         state.entities = state.entities.filter(
            el => el.id !== action.payload.id
         );
      },

      // reportsUpdateState
      reportsStatusUpdated: (state, action) => {
         state.actionsLoading = false;
         state.error = null;
         const { ids, status } = action.payload;
         state.entities = state.entities.map(entity => {
            if (ids.findIndex(id => id === entity.id) > -1) {
               entity.status = status;
            }
            return entity;
         });
      }
   }
});
