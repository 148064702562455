// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import axios from "axios";

export function DetailColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openEditEbookDialog, isPrivate, getRefreshContent }
) {
  // const refreshEbook = (
  //   <span
  //     style={{ cursor: "pointer", fontSize: "14px", color: "#189AEB", marginBottom: "0px" }}
  //     onClick={() => {
  //       const reqFunc = async () => {
  //         const req = await axios.get(`/admin/private/contents/${row.contentUID}/verify`);
  //         if (req.data.status && req.data.data) {
  //           getRefreshContent(req.data.data);
  //         }
  //       };
  //       reqFunc();
  //     }}
  //   >
  //     ตรวจสอบไฟล์
  //   </span>
  // );
  const fixFile = (status) => {
    if (isPrivate && (status === 4 || status === 5 || status === 6)) {
      return (
        <p style={{ fontSize: "14px", color: "#D7042B", marginBottom: "0px" }}>
          <span>
            {status === 4
              ? "ไฟล์มีปัญหา : อัปโหลดไฟล์สื่อ/ปก  "
              : status === 6
              ? "ไฟล์มีปัญหา : อัปโหลดไฟล์สื่อ  "
              : status === 5
              ? "ไฟล์มีปัญหา : อัปโหลดไฟล์ปก  "
              : ""}
            <img
              src="/media/members/fix-file-button.png"
              alt=""
              style={{
                background: "#f3f6f9",
                width: "22px",
                imageRendering: "-webkit-optimize-contrast",
                padding: "2px",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onClick={() => {
                openEditEbookDialog(row.contentUID, status);
              }}
            />
          </span>
        </p>
      );
    } else {
      return "";
    }
  };
  return (
    <div>
      <div className="text-title">{row ? row.title : ""}</div>
      <div className="text-detail">ผู้แต่ง : {row ? row.authorName : ""}</div>
      {row && isPrivate
        ? row.status === 4 || row.status === 5 || row.status === 6
          ? fixFile(row.status)
          : ""
        : ""}
      {/* {fixFile(5)} */}
    </div>
  );
}
