import axios from "axios";
import { HtiResponse } from "../../../../interface";

export enum DriveFileType {
  Folder = "folder",
  File = "folder",
}

interface GetRootDriveResponse {
  contents: DriveFile[];
}

export interface DriveFile {
  fileName: string;
  fileType: DriveFileType;
  filePath: string;
  lastModified?: string;
  size?: string;
}

export const getFileContent = async (filePath?: string) => {
  const {
    data: { data },
  } = await axios.get<HtiResponse<GetRootDriveResponse>>(
    `/drive/list${filePath ? `?path=${filePath}` : ``}`
  );
  return data;
};

interface DriveFileDownload {
  url: string;
  concurrent: Concurrent;
}

interface Concurrent {
  concurrent: number;
  totalDownLoad: number;
}
export const getDownloadFile = async (path?: string) => {
  const {
    data: { data },
  } = await axios.post<HtiResponse<DriveFileDownload>>(`/drive/download`, {
    path,
  });
  return data;
};
