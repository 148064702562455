import { createSlice } from "@reduxjs/toolkit";
import { QRCode} from "../../../../../interface"

interface QrcodeStateRedux {
  listLoading: boolean,
  actionsLoading: boolean,
  totalCount: 0,
  entities: QRCode[],
  entities_members: [],
  qrcodeForEdit: {},
  lastError: null,
  LastEvaluatedKey: {},
  error?:string | null
}
const initialQrcodesState : QrcodeStateRedux= {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  entities_members: [],
  qrcodeForEdit: {},
  lastError: null,
  LastEvaluatedKey: {},
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const qrcodesSlice = createSlice({
  name: "qrcodes",
  initialState: initialQrcodesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getQrcodeById
    qrcodeFetched: (state, action) => {
      state.actionsLoading = false;
      state.qrcodeForEdit = action.payload.qrcodeForEdit;
      state.error = null;
    },
    // findQrcodes
    qrcodesFetched: (state, action) => {
      const { totalCount, entities, LastEvaluatedKey, lastEvaluate } = action.payload;
      state.listLoading = false;
      state.error = null;
      if (lastEvaluate === undefined) {
        state.entities = entities;
      } else {
        entities.forEach((item :QRCode) => state.entities.push(item));
      }
      state.totalCount = totalCount;
      state.LastEvaluatedKey = LastEvaluatedKey;
      // state.entities = mockUsers;
      // state.totalCount = 17;
    },
    qrcodeMembersFetched: (state, action) => {
      const { entities_members, LastEvaluatedKey, lastEvaluate } = action.payload;
      state.listLoading = false;
      state.error = null;
      if (lastEvaluate === undefined) {
        state.entities_members = entities_members;
      } else {
        state.entities_members = entities_members;
      }
      state.LastEvaluatedKey = LastEvaluatedKey;
      // state.entities = mockUsers;
      // state.totalCount = 17;
    },
    // createQrcode
    qrcodeCreated: (state) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.qrcode);
    },
    // updateQrcode
    // qrcodeUpdated: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities = state.entities.map((entity) => {
    //     if (entity.id === action.payload.qrcode.id) {
    //       return action.payload.qrcode;
    //     }
    //     return entity;
    //   });
    // },
    // blockQrcode
    qrcodeBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // deleteQrcode
    qrcodeDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter((el) => el.memberUID !== action.payload.id);
    },
    // deleteQrcodes
    qrcodesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter((el) => !action.payload.ids.includes(el.id));
    },
  
  },
});
