import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { EbooksLoadingDialog } from './ebooks-loading-dialog/EbooksLoadingDialog';
import { EbookEditDialog } from './ebook-edit-dialog/EbookEditDialog';
import { EbookViewDialog } from './ebook-view-dialog/EbookViewDialog';
import { EbookDeleteDialog } from './ebook-delete-dialog/EbookDeleteDialog';
import { EbookBlockDialog } from './ebook-block-dialog/EbookBlockDialog';
import { EbookUnblockDialog } from './ebook-unblock-dialog/EbookUnblockDialog';
import { EbooksUIProvider } from './EbooksUIContext';
import { EbooksCard } from './EbooksCard';
import queryString from 'query-string';

export function EbooksPage(props) {
   const { history, location } = props;
   const [id, setId] = useState(null);
   const [name, setName] = useState(null);
   const [edit, setEdit] = useState(false);
   const [view, setView] = useState(false);
   const [isDelete, setDelete] = useState(false);
   const [isUnblock, setUnblock] = useState(false);
   const [isBlock, setBlock] = useState(false);

   useEffect(() => {
      // console.log('ebook type', ebookType, props.match.params);
      const parsed = queryString.parse(location.search);
      if (parsed.id) {
         setId(parsed.id);
      }
      if (parsed.name) {
         setName(parsed.name);
      }
      if (parsed.edit) {
         setEdit(parsed.edit);
      }
      if (parsed.view) {
         setView(parsed.view);
      }
      if (parsed.delete) {
         setDelete(true);
      }
      if (parsed.block) {
         setBlock(true);
      }
      if (parsed.unblock) {
         setUnblock(true);
      }
   }, [location.search]);

   const ebooksUIEvents = {
      newEbookButtonClick: () => {
         history.push('/ebooks?isNew=true');
      },
      openEditEbookDialog: id => {
         history.push(`/ebooks?id=${id}&edit=true`);
      },
      openViewEbookDialog: id => {
         history.push(`/ebooks?id=${id}&view=true`);
      },
      openDeleteEbookDialog: (name, id) => {
         history.push(
            `/ebooks?name=${encodeURIComponent(name)}&id=${id}&delete=true`
         );
      },
      openBlockEbookDialog: (name, id) => {
         history.push(
            `/ebooks?name=${encodeURIComponent(name)}&id=${id}&block=true`
         );
      },
      openUnblockEbookDialog: (name, id) => {
         history.push(
            `/ebooks?name=${encodeURIComponent(name)}&id=${id}&unblock=true`
         );
      },
      openDeleteEbooksDialog: () => {
         history.push(`/ebooks/deleteEbooks`);
      },
      openFetchEbooksDialog: () => {
         history.push(`/ebooks/fetch`);
      },
      openUpdateEbooksStatusDialog: () => {
         history.push('/ebooks/updateStatus');
      }
   };

   return (
      <EbooksUIProvider ebooksUIEvents={ebooksUIEvents}>
         <EbooksLoadingDialog />
         {edit ? (
            <EbookEditDialog
               show={true}
               id={id}
               isPrivate={0}
               isFree={0}
               onHide={() => {
                  history.push('/ebooks');
                  setEdit(false);
               }}
            />
         ) : (
            ''
         )}
         {view ? (
            <EbookViewDialog
               show={true}
               id={id}
               isPrivate={0}
               isFree={0}
               onHide={() => {
                  history.push('/ebooks');
                  setView(false);
               }}
            />
         ) : (
            ''
         )}
         {isDelete ? (
            <EbookDeleteDialog
               show={true}
               name={decodeURIComponent(name)}
               id={id}
               isPrivate={0}
               isFree={0}
               onHide={() => {
                  history.push('/ebooks');
                  setDelete(false);
               }}
            />
         ) : (
            ''
         )}
         {isUnblock ? (
            <EbookUnblockDialog
               show={true}
               name={decodeURIComponent(name)}
               id={id}
               isPrivate={0}
               isFree={0}
               onHide={() => {
                  history.push('/ebooks');
                  setUnblock(false);
               }}
            />
         ) : (
            ''
         )}
         {isBlock ? (
            <EbookBlockDialog
               show={true}
               name={decodeURIComponent(name)}
               id={id}
               isPrivate={0}
               isFree={0}
               onHide={() => {
                  history.push('/ebooks');
                  setBlock(false);
               }}
            />
         ) : (
            ''
         )}
         <EbooksCard isPrivate={0} isFree={0} />
      </EbooksUIProvider>
   );
}
