/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/customers/customersActions";
import { useCustomersUIContext } from "../CustomersUIContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
     body: {
          textAlign: "center",
     },
}));

export function CustomerBannedDialog({ email, show, onHide }) {
     // Customers UI Context
     const customersUIContext = useCustomersUIContext();
     const customersUIProps = useMemo(() => {
          return {
               setIds: customersUIContext.setIds,
               queryParams: customersUIContext.queryParams,
          };
     }, [customersUIContext]);

     // Customers Redux state
     const dispatch = useDispatch();
     const { isLoading } = useSelector((state) => ({ isLoading: state.customers.actionsLoading }), shallowEqual);

     // if !id we should close modal
     useEffect(() => {
          if (!email) {
               onHide();
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [email]);

     // looking for loading/dispatch
     useEffect(() => {}, [isLoading, dispatch]);

     const classes = useStyles();

     return (
          <Modal
               backdrop="static"
               show={show}
               onHide={onHide}
               aria-labelledby="example-modal-sizes-title-lg"
               style={{ margin: "3% auto" }}
          >
               {/*begin::Loading*/}
               {isLoading && <ModalProgressBar />}
               {/*end::Loading*/}
               {/* <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Customer Block</Modal.Title>
      </Modal.Header> */}
               <Modal.Body className={classes.body}>
                    <img src="/media/members/image-modal.svg" alt="" />
                    <div className={classes.title}>บัญชี {email}</div>
                    <div className={classes.title}>ถูกระงับการใช้งาน</div>
                    <div className={classes.title} style={{ margin: "0" }}>
                         เนื่องจากทำผิดข้อตกลงการใช้งาน
                    </div>
                    <div className={classes.title} style={{ margin: "0 0 28px" }}>
                         กรุณาติดต่อ Hibrary
                    </div>
               </Modal.Body>
               {/* <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            Cancel
          </button>
          <> </>
          <button type="button" onClick={blockCustomer} className="btn btn-primary btn-elevate">
            Block
          </button>
        </div>
      </Modal.Footer> */}
          </Modal>
     );
}
