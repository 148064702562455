import React from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  body: {
    textAlign: "center",
  },
  title: {
    fontSize: "19px",
    color: "#012875",
    fontWeight: "600",
    textAlign: "left",
  },
  titleError: {
    fontSize: "20px",
    fontWeight: 600,
    color: "#d7042b",
    marginBottom: "40px",
  },
  subtitle: {
    fontFamily: "Prompt",
    fontSize: "16px",
  },
  divButton: {
    marginTop: "16px",
  },
  acceptButton: {
    margin: "8px",
    color: "#ffffff",
    fontSize: "18px",
    fontWeight: 600,
    width: "160px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "#012875",
    border: "none",
  },
  denyButton: {
    margin: "8px",
    color: "#012875",
    fontSize: "18px",
    fontWeight: 600,
    width: "160px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "#ffffff",
    border: "solid 1px #012875",
  },
  closeButton: { border: "none", backgroundColor: "transparent" },
  caution: { fontSize: "14px", color: "#D7042B" },
}));

const errorMessageText = (message) => {
  if (message.includes("pdf")) {
    return "กรุณาอัปโหลดไฟล์ .epub หรือ .pdf เท่านั้น";
  } else if (message.includes("xlsx")) {
    return "กรุณาอัปโหลดไฟล์ .xlsx เท่านั้น";
  } else if (message.includes("png")) {
    return "กรุณาอัปโหลดไฟล์ .png, .jpg หรือ .jpeg เท่านั้น";
  } else if (message.includes("mp4")) {
    return "กรุณาอัปโหลดไฟล์ .mp4 เท่านั้น";
  } else if (message.includes("larger")) {
    return "ไฟล์มีขนาดหรือจำนวนเกินกว่าพื้นที่ที่จัดเก็บได้";
  }else if (message.includes("mp3")) {
    return "กรุณาอัปโหลดไฟล์ .mp3 เท่านั้น";
  }
};

export function UploadInformationDialog({ show, onHide, isLeft, isError, errorMessage }) {
  const classes = useStyles();
  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      style={{ margin: "3% auto" }}
      size={isError ? "md" : "sm"}
    >
      <Modal.Body className={classes.body}>
        {!isError ? (
          <div style={{ textAlign: "right", position: "absolute", right: "8px" }}>
            <button
              className={classes.closeButton}
              onClick={() => {
                onHide();
              }}
            >
              <img src="/media/books/close-button.svg" alt="" />
            </button>
          </div>
        ) : (
          ""
        )}
        {isError ? (
          <>
            <img src="/media/members/image-modal.svg" alt="" />
            <div className={classes.titleError}>
              {errorMessage && errorMessageText(errorMessage)}
            </div>
            <div className={classes.divButton}>
              <button
                className={classes.acceptButton}
                type="submit"
                onClick={() => {
                  onHide();
                }}
              >
                ตกลง
              </button>
            </div>
          </>
        ) : (
          <>
            <div className={classes.title}>
              {isLeft ? (
                <>
                  <p style={{ margin: "0" }}>รายละเอียดการอัปโหลดไฟล์</p>
                  <p>และไฟล์ที่รองรับ</p>
                </>
              ) : (
                "รายละเอียดขนาดไฟล์หน้าปก"
              )}
            </div>
            <div className="row" style={{ margin: "24px 0" }}>
              <div className="col-12">
                <img src={`/media/members/ebook-info${isLeft ? "" : "-big"}.png`} alt="" />
              </div>
              {/* <div className="col-4">
            <img src={`/media/members/video-info${isLeft ? "" : "-big"}.png`} />
          </div>
          <div className="col-4">
            <img src={`/media/members/audio-info${isLeft ? "" : "-big"}.png`} />
          </div> */}
            </div>
            {isLeft ? "" : <div className={classes.caution}>.png, .jpg หรือ .jpeg เท่านั้น</div>}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
