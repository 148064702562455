import { useMemo } from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { AlignType } from 'rc-table/lib/interface';
import { useCompaniesUIContext } from '../CompaniesUIContext';

import {
    RemainAmountColumnFormatter,
    ImageColumnFormatter,
    DetailColumnFormatter,
    ActionsColumnFormatter
} from './column-formatters';
import { HibraryRootState } from '../../../../../../redux/rootReducer';
interface CompaniesTable {
    data?: object[];
}

function CompaniesTableAnt(props: CompaniesTable) {
    const companiesUIContext = useCompaniesUIContext();

    const { isCuDomain } = useSelector((state: HibraryRootState) => ({
        isCuDomain: state.auth.isCuDomain
    }));

    const companiesUIProps = useMemo(() => {
        return {
            ids: companiesUIContext.ids,
            setIds: companiesUIContext.setIds,
            queryParams: companiesUIContext.queryParams,
            setQueryParams: companiesUIContext.setQueryParams,
            openEditCompanyDialog: companiesUIContext.openEditCompanyDialog,
            openEditColorDialog: companiesUIContext.openEditColorDialog,
            openViewAsPage: companiesUIContext.openViewAsPage,
            openViewCompanyDialog: companiesUIContext.openViewCompanyDialog,
            openDeleteCompanyDialog: companiesUIContext.openDeleteCompanyDialog,
            openBlockCompanyDialog: companiesUIContext.openBlockCompanyDialog,
            openUnblockCompanyDialog:
                companiesUIContext.openUnblockCompanyDialog,
            openCompanyClearStoreDialog:
                companiesUIContext.openCompanyClearStoreDialog,
            openCompanyClearMember: companiesUIContext.openCompanyClearMember,
            openExtendPeriodDialog: companiesUIContext.openExtendPeriodDialog
        };
    }, [companiesUIContext]);

    const columns = [
        {
            title: 'ลำดับ',
            align: 'center' as AlignType,
            dataIndex: 'none',

            render: (text: string, record: any, index: number) => (
                <p>{index + 1}</p>
            )
        },
        {
            title: 'โลโก้',
            align: 'center' as AlignType,
            dataIndex: ['logoImages'],
            render: (text: string, row: any) => ImageColumnFormatter(row)
        },
        {
            title: 'ชื่อองค์กร',
            dataIndex: [
                'clientName',
                'companyPrefix',
                'clientUID',
                'expireDate',
                'displayName'
            ],
            render: (text: string, row: any) =>
                DetailColumnFormatter(row, isCuDomain)
        },
        {
            title: 'จำนวนสมาชิกสูงสุด',
            align: 'center' as AlignType,
            // dataIndex: 'userLimit',
            dataIndex: 'userLimit',
            render: (text: number) => (
                <p>
                    {/* {text?.toLocaleString()} */}
                    {text}
                </p>
            )
        },
        {
            title: 'วันที่เริ่ม/วันที่สิ้นสุด',
            dataIndex: ['expireDate', 'startDate'],
            render: (text: number, row: any) => RemainAmountColumnFormatter(row)
        },
        {
            title: 'สื่อองค์กร',
            align: 'center' as AlignType,
            dataIndex: 'privateLibrary',
            render: (text: boolean) => (
                <>
                    {text ? (
                        <p style={{ fontSize: 14, marginBottom: 0 }}>
                            <img src="/media/members/check-true.svg" alt="" />{' '}
                        </p>
                    ) : (
                        <p style={{ fontSize: 14, marginBottom: 0 }}>-</p>
                    )}
                </>
            )
        },
        {
            title: 'การจัดการ',
            dataIndex: ['clientName', 'clientUID'],
            render: (text: string, row: any) =>
                ActionsColumnFormatter(row, companiesUIProps)
        }
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={props.data}
                pagination={{ pageSize: 100 }}
                scroll={{ x: 1000 }}
                style={{ height: 'calc(100vh - 320px)', overflow: 'auto' }}
            />
        </>
    );
}

export default CompaniesTableAnt;
