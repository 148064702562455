import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ModalProgressBar } from '../../../../../../_metronic/_partials/controls';
import * as actions from '../../../_redux/reports/reportsActions';
import { useReportsUIContext } from '../ReportsUIContext';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'antd';
export function ReportDeleteDialog({ id, show, onOK, onHide }) {
   const [loading, setLoading] = useState(false);
   const useStyles1 = makeStyles(theme => ({
      row: { display: 'block', justifyContent: 'center' },
      col: { margin: '1.5rem auto 0' },
      p: { marginBottom: '8px', fontSize: '14px' },
      select: {
         width: '100%',
         borderRadius: '4px',
         border: '1px solid #E5EAEE',
         display: 'block',
         height: 'calc(1.5em + 1.3rem + 2px)',
         padding: '0.65rem 1rem',
         fontSize: '1rem',
         fontWeight: '400',
         lineHeight: '1.5',
         color: '#464E5F',
         backgroundColor: '#ffffff',
         backgroundClip: 'padding-box',
         boxShadow: 'none',
         transition:
            'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
      },
      center: {
         textAlign: 'center'
      },
      textSection: {
         maxWidth: '60%',
         margin: '0 auto'
      },
      body: { margin: 'auto', textAlign: 'center' }
   }));
   const classes = useStyles1();
   const search = window.location.search;
   const params = new URLSearchParams(search);
   const name = params.get('name');

   const { isLoading } = useSelector(
      state => ({ isLoading: state.reports.actionsLoading }),
      shallowEqual
   );

   return (
      <Modal
         backdrop="static"
         show={show}
         onHide={onHide}
         aria-labelledby="example-modal-sizes-title-lg"
         style={{ margin: '3% auto' }}
      >
         {isLoading && <ModalProgressBar />}

         <Modal.Body className={classes.body}>
            <img src="/media/members/image-modal.svg" alt="" />
            <div className={classes.textSection}>
               <div className="text-text-red">ยืนยันการลบ</div>
               <div className="text-text-gray">ต้องการลบรีพอร์ต</div>
               <div className="text-text-gray">{`${name} `}</div>
               <div className="text-text-gray">{`หรือไม่?`}</div>
            </div>

            <div className="mt-3">
               <button type="button" onClick={onHide} className="denyButton">
                  ยกเลิก
               </button>
               <Button
                  type="button"
                  loading={loading}
                  onClick={() => {
                     setLoading(true);
                     onOK();
                  }}
                  className="acceptButton "
               >
                  ยืนยัน
               </Button>
            </div>
         </Modal.Body>
      </Modal>
   );
}
