import { Button, DatePicker, Modal } from "antd";
import moment from "moment";

//? css
import "./index.scss";

interface CheckLicenseProps {
	visible: boolean;
	licenseDate: number;
	onLicenseDate: (date: any) => void;
	onClose: () => void;
	onConfirm: () => void;
}

export const CheckLicenseModal: React.FC<CheckLicenseProps> = ({
	visible,
	licenseDate,
	onClose,
	onConfirm,
	onLicenseDate,
}) => {
	return (
		<Modal
			visible={visible}
			onCancel={onClose}
			title={null}
			footer={null}
			className='check-license-modal'
		>
			<div className='content-container'>
				<div className='title'>ตั้งค่าวันที่</div>
				<div className='date-picker-container'>
					<DatePicker
						placeholder='เลือกวันที่'
						className='slecter_date_input'
						onChange={onLicenseDate}
						allowClear={false}
						format={"DD/MM/YY"}
						value={licenseDate ? moment.unix(licenseDate) : moment()}
						size='large'
					/>
				</div>
				<div className='divButton' style={{ marginTop: 0 }}>
					<Button
						className='denyButton'
						onClick={() => {
							onClose();
						}}
					>
						ยกเลิก
					</Button>
					<Button
						className='acceptButton'
						onClick={() => {
							onConfirm();
						}}
					>
						ยืนยัน
					</Button>
				</div>
			</div>
		</Modal>
	);
};
