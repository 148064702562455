/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, lazy, useEffect, useState } from 'react';
import { Redirect, Switch, Route, useLocation } from 'react-router-dom';
import { LayoutSplashScreen } from '../_metronic/layout';
import { BuilderPage } from './pages/BuilderPage';
import { MyPage } from './pages/MyPage';
import { DashboardPage } from './pages/DashboardPage';
import { CustomersPage } from './modules/ECommerce/pages/customers/CustomersPage';
import { BannedCustomersPage } from './modules/ECommerce/pages/customers/BannedCustomersPage';
import { ViewAsUploadContentPage } from './modules/ECommerce/pages/viewAsFunction/ViewAsUploadContentPage';
import { SubscriptionPage } from './modules/ECommerce/pages/viewAsFunction/SubscriptionPage';
import { UploadSubscriptionPage } from './modules/ECommerce/pages/viewAsFunction/UploadSubscriptionPage';
import { QrcodesPage } from './modules/ECommerce/pages/customers/QrcodesPage';
import { QrcodeMembersPage } from './modules/ECommerce/pages/customers/QrcodeMembersPage';
import { EbooksPage } from './modules/ECommerce/pages/ebooks/EbooksPage';
import { CataloguePage } from './modules/ECommerce/pages/catalogue/CataloguePage';
import { CatalogueDetailPage } from './modules/ECommerce/pages/catalogue/CatalogueDetailPage';
import { CatalogueExamplePage } from './modules/ECommerce/pages/catalogue/CatalogueExamplePage';
import { CompaniesPage } from './modules/ECommerce/pages/companies/CompaniesPage';
import { EbooksFreePage } from './modules/ECommerce/pages/ebooks/EbooksFreePage';
import { EbooksPrivatePage } from './modules/ECommerce/pages/ebooks/EbooksPrivatePage';
import { VideosPrivatePage } from './modules/ECommerce/pages/videos/VideosPrivatePage';
import { PodcastsPrivatePage } from './modules/ECommerce/pages/podcasts/PodcastsPrivatePage';
import { BannersPage } from './modules/ECommerce/pages/banners/BannersPage';
import { BannerPrivatePage } from './modules/ECommerce/pages/banners/BannerPrivatePage';
import { UploadPage } from './modules/ECommerce/pages/upload/UploadPage';
import { UploadCompanyPage } from './modules/ECommerce/pages/upload-company/UploadCompanyPage';
import { CategoriesPage } from './modules/ECommerce/pages/categories/CategoriesPage';
import { CategoriesPrivatePage } from './modules/ECommerce/pages/categories/CategoriesPrivatePage';
import { SubCategoriesPage } from './modules/ECommerce/pages/categories/SubCategoriesPage';
import { SubCategoriesPrivatePage } from './modules/ECommerce/pages/categories/SubCategoriesPrivatePage';
import { AuthorsPage } from './modules/ECommerce/pages/publishers/AuthorsPage';
import { CreatorsPage } from './modules/ECommerce/pages/channels/CreatorsPage';
import { CreatorsPodcastPage } from './modules/ECommerce/pages/channels-podcast/CreatorsPage';
import { ReportsPage } from './modules/ECommerce/pages/report/ReportsPage';
import { SettingPage } from './modules/ECommerce/pages/settings/SettingPage';
import { CatalogueSummary } from './modules/ECommerce/pages/catalogue/CatalogueSummary';
import { CheckEbookPage } from './modules/ECommerce/pages/checkebook/CheckEbookPage';
import { CheckEbookDetailPage } from './modules/ECommerce/pages/checkebook/CheckEbookDetailPage';
import { CheckEbookSubDetail } from './modules/ECommerce/pages/checkebook/CheckEbookSubDetail';
import { CheckEbookCatagory } from './modules/ECommerce/pages/checkebook/CheckEbookCatagory';
import jwt_decode from 'jwt-decode';
import * as auth from './modules/Auth/_redux/authRedux';
import { connect } from 'react-redux';
import { shallowEqual, useSelector } from 'react-redux';
import axios from 'axios';
import { ip } from '../redux/setupAxios';
import { Role } from '../interface/index';
import { DownLoadFileView } from './modules/DownloadContent/component';
import ListUploadedContentPage from './modules/ECommerce/pages/viewAsFunction/ListUploadedContentPage';
import ExpirePage from './modules/ECommerce/pages/expirecontent/ExpirePage';
import ReportCardSuperAdmin from './modules/ECommerce/pages/report/report-super-admin/ReportCardSuperAdmin';
import PublicApi from './modules/publicApi/PublicApi';
import CreateToken from './modules/publicApi/CreateToken';

const ECommercePage = lazy(() =>
    import('./modules/ECommerce/pages/eCommercePage')
);

function BasePage() {
    const [memberAmountData, setMemberAmountData] = useState(null);
    const [smsCount, setSmsCount] = useState(null);
    const [contentAmount, setContentAmount] = useState(null);
    const [licenseAmount, setLicenseAmount] = useState(null);
    const [borrowTop20List, setBorrowTop20List] = useState([]);
    const [borrowQueue, setBorrowQueue] = useState([]);
    const [loadedDashboard, setLoadedDashboard] = useState(false);
    let location = useLocation();

    useEffect(() => {
        const setDeviceUID = async () => {
            localStorage.setItem('deviceuid', await ip);
        };
        setDeviceUID();
    }, []);

    const reqFunc = async () => {
        try {
            const req = await axios.get(`/admin/dashboard`);
            const { data } = req;
            if (data.status && data.data) {
                setMemberAmountData(data.data.memberAmountData);
                setSmsCount(data.data?.smsCount);

                setContentAmount(data.data.contentAmount);
                setLicenseAmount(data.data.licenseAmount);
                setBorrowTop20List(data.data.licenseList);
                setBorrowQueue(data.data.waitingList);
                setLoadedDashboard(true);
            }
        } catch (error) {
            setLoadedDashboard(true);
            // props.logout();
        }
    };
    const [canUpload, setCanUpload] = useState(true);
    const [mainPath, setMainPath] = useState('/dashboard');
    const { authState } = useSelector(
        ({ auth }) => ({
            authState: auth
        }),
        shallowEqual
    );
    const { authToken, role, viewAsToken } = authState;
    useEffect(() => {
        console.log(authState);
        if (
            (role === Role.Admin && authState?.userInfo?.isPrivateLibrary) ||
            (role === Role.SuperAdmin &&
                viewAsToken !== undefined &&
                jwt_decode(viewAsToken).isPrivateLibrary)
        ) {
            setCanUpload(true);
        } else {
            setCanUpload(false);
        }
        if (role === Role.SuperAdmin) {
            setMainPath('/companies');
        } else if (role === Role.Admin) {
            setMainPath('/dashboard');
        }
    }, [authToken, viewAsToken]);

    useEffect(() => {
        if (
            (location &&
            location.pathname === '/dashboard' && //|| location.pathname === "/members"
                role === Role.SuperAdmin &&
                authState.viewAsToken !== undefined) ||
            role !== Role.SuperAdmin
        ) {
            reqFunc();
        } else {
            if (
                role === Role.SuperAdmin &&
                authState.viewAsToken === undefined
            ) {
                setLoadedDashboard(true);
            }
        }
    }, [location.pathname]);

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to={mainPath} />
                }
                {!canUpload && (
                    <Redirect exact from="/ebooks-private" to={mainPath} />
                )}
                {!canUpload && (
                    <Redirect exact from="/videos-private" to={mainPath} />
                )}
                {!canUpload && (
                    <Redirect exact from="/podcasts-private" to={mainPath} />
                )}
                {!canUpload && <Redirect exact from="/upload" to={mainPath} />}
                {!canUpload && (
                    <Redirect exact from="/publishers" to={mainPath} />
                )}
                {!canUpload && (
                    <Redirect exact from="/channels" to={mainPath} />
                )}

                {!canUpload && (
                    <Redirect exact from="/channels-podcast" to={mainPath} />
                )}
                {!canUpload && (
                    <Redirect exact from="/categories/ebooks" to={mainPath} />
                )}

                <Redirect exact from="/auth/login" to={mainPath} />
                <Route
                    path="/dashboard"
                    component={() => (
                        <DashboardPage
                            memberAmountData={memberAmountData}
                            contentAmount={contentAmount}
                            licenseAmount={licenseAmount}
                            borrowTop20List={borrowTop20List}
                            borrowQueue={borrowQueue}
                            loadedDashboard={loadedDashboard}
                            smsCount={smsCount}
                        />
                    )}
                />
                <Route path="/publicApi" component={() => <PublicApi />} />
                <Route path="/createToken" component={() => <CreateToken />} />
                <Route path="/builder" component={BuilderPage} />
                <Route
                    path="/expire"
                    render={() => (
                        <>
                            <ExpirePage />
                        </>
                    )}
                />
                <Route path="/my-page" component={MyPage} />
                <Route
                    path="/members"
                    render={props => (
                        <CustomersPage
                            {...props}
                            memberAmountData={memberAmountData}
                        />
                    )}
                />

                <Route
                    path="/view-as-upload-content"
                    exat
                    render={props => <ViewAsUploadContentPage {...props} />}
                />
                <Route
                    path="/view-as-upload-content-history"
                    render={props => <ListUploadedContentPage {...props} />}
                />
                <Route
                    path="/upload-subscription"
                    render={props => (
                        <UploadSubscriptionPage
                            {...props}
                            uploadValue={{ name: '' }}
                            isNew={true}
                        />
                    )}
                />
                <Route
                    path="/download-file"
                    render={props => <DownLoadFileView />}
                />
                <Route
                    path="/subscription"
                    render={props => <SubscriptionPage {...props} />}
                />
                <Route
                    path="/qrcodes"
                    render={props => <QrcodesPage {...props} />}
                />
                <Route
                    path="/qrcode-members"
                    render={props => <QrcodeMembersPage {...props} />}
                />

                <Route
                    path="/ebooks/type/:ebookType"
                    render={props => {
                        return <EbooksPage {...props} />;
                    }}
                />
                <Route
                    path="/ebooks"
                    render={props => {
                        return <EbooksPage {...props} />;
                    }}
                    exact
                />

                <Route
                    path="/catalogue-detail"
                    render={props => <CatalogueDetailPage {...props} />}
                />
                <Route
                    path="/catalogue-example"
                    render={props => <CatalogueExamplePage {...props} />}
                />
                <Route
                    path="/catalogue"
                    render={props => <CataloguePage {...props} />}
                />
                <Route
                    path="/ebooks-private"
                    render={props => <EbooksPrivatePage {...props} />}
                />
                <Route
                    path="/videos-private"
                    render={props => <VideosPrivatePage {...props} />}
                />
                <Route
                    path="/podcasts-private"
                    render={props => <PodcastsPrivatePage {...props} />}
                />
                <Route
                    path="/ebooks-free"
                    render={props => <EbooksFreePage {...props} />}
                />
                <Route
                    path="/banners"
                    render={props => <BannersPage {...props} />}
                />
                <Route
                    path="/banner-private"
                    render={props => <BannerPrivatePage {...props} />}
                />
                <Route
                    path="/upload"
                    render={props => <UploadPage {...props} />}
                />
                <Route
                    path="/upload-company"
                    render={props => <UploadCompanyPage {...props} />}
                />
                <Route
                    path="/categories"
                    render={props => <CategoriesPage {...props} />}
                />
                <Route
                    path="/subcategories"
                    render={props => <SubCategoriesPage {...props} />}
                />
                <Route
                    path="/summarycatalogs"
                    render={props => <CatalogueSummary {...props} />}
                />

                <Route
                    path="/categories-private-ebook"
                    render={props => (
                        <CategoriesPrivatePage {...props} contentType="ebook" />
                    )}
                />
                <Route
                    path="/subcategories-private-ebook"
                    render={props => (
                        <SubCategoriesPrivatePage
                            {...props}
                            contentType="ebook"
                        />
                    )}
                />
                <Route
                    path="/categories-private-video"
                    render={props => (
                        <CategoriesPrivatePage {...props} contentType="video" />
                    )}
                />
                <Route
                    path="/subcategories-private-video"
                    render={props => (
                        <SubCategoriesPrivatePage
                            {...props}
                            contentType="video"
                        />
                    )}
                />
                <Route
                    path="/categories-private-podcast"
                    render={props => (
                        <CategoriesPrivatePage
                            {...props}
                            contentType="podcast"
                        />
                    )}
                />
                <Route
                    path="/subcategories-private-podcast"
                    render={props => (
                        <SubCategoriesPrivatePage
                            {...props}
                            contentType="podcast"
                        />
                    )}
                />
                <Route
                    path="/authors"
                    render={props => <AuthorsPage {...props} />}
                />
                <Route
                    path="/creators"
                    render={props => <CreatorsPage {...props} />}
                />
                <Route
                    path="/creators-podcast"
                    render={props => <CreatorsPodcastPage {...props} />}
                />
                <Route
                    path="/report"
                    render={props => <ReportsPage {...props} />}
                />
                <Route
                    path="/setting"
                    render={props => <SettingPage {...props} />}
                />

                {role === Role.SuperAdmin ? (
                    <SuperRoute memberAmountData={memberAmountData} />
                ) : null}
                <Route path="/" component={ECommercePage} />
                <Redirect to="error/error-v1" />
            </Switch>
        </Suspense>
    );
}

export default connect(null, auth.actions)(BasePage);

const SuperRoute = props => {
    return (
        <>
            <Route
                path="/companies"
                render={props => <CompaniesPage {...props} />}
            />
            <Route
                path="/members"
                render={renderProps => (
                    <CustomersPage
                        {...renderProps}
                        memberAmountData={props.memberAmountData}
                    />
                )}
            />
            <Route
                path="/banned-members"
                render={props => <BannedCustomersPage {...props} />}
            />
            <Route
                path="/checkebook"
                render={props => <CheckEbookPage {...props} />}
            />
            <Route
                path="/checkebook-detail"
                render={props => <CheckEbookDetailPage {...props} />}
            />
            <Route
                path="/checkebook-subdetail"
                render={props => <CheckEbookSubDetail {...props} />}
            />
            <Route
                path="/checkebook-catagory"
                render={props => <CheckEbookCatagory {...props} />}
            />
            <Route
                path="/report-super-admin"
                render={props => <ReportCardSuperAdmin {...props} />}
            />
            <Route path="/" component={ECommercePage} />
        </>
    );
};
