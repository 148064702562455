// export const ReportStatusCssClasses = ["danger", "success", "info", ""];
export const ReportStatusCssClasses = ["danger", "danger", "danger", ""];
export const ReportStatusTitles = ["Suspended", "Active", "Pending", ""];
export const ReportTypeCssClasses = ["success", "primary", ""];
export const ReportTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "30", value: 30 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  filter: {
    lastName: "",
    firstName: "",
    email: "",
    ipAddress: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 50,
  limit: 50, //10
  totalSize: 9, //จำนวนสมาชิก
};
