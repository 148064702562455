import axios from "axios";
import {
  CreatePublisherPayload,
  CreateAuthorPayload,
  customersUIPropsQueryParams,
  LastEvaluatedKey_GetPublishers,
  UpdatePublishersPayload,
  UpdateAuthorPayload,
  GetPublishersData,
  HtiResponse,
  getAuthorData,
  getAuthorSearchData,
  
} from "../../../../../interface";
// export const PUBLISHERS_URL = "api/publishers";
export const PUBLISHERS_URL = `/admin/publishers`;
export const PRIVATE_PUBLISHERS_URL = `/admin/private/publishers`;

// CREATE =>  POST: add a new publisher to the server
export function createPublisher(publisher : CreatePublisherPayload, isPrivate : number) {
  return axios.post(isPrivate ? PRIVATE_PUBLISHERS_URL : PUBLISHERS_URL, publisher);
}

export function createAuthor(author : CreateAuthorPayload, isPrivate : number, publisherId : string) {
  return axios.post(
    `${isPrivate ? PRIVATE_PUBLISHERS_URL : PUBLISHERS_URL}/${publisherId}/authors`,
    author
  );
}

// READ
export function getAllPublishers() {
  return axios.get<HtiResponse<GetPublishersData>>(PUBLISHERS_URL);
}

export function getAuthorsById(queryParams :customersUIPropsQueryParams, publisherId : string, isPrivate : number) {
  if (queryParams === "none" || !queryParams.keyword) {
    return axios.get<HtiResponse<getAuthorData>>(
      `${isPrivate ? PRIVATE_PUBLISHERS_URL : PUBLISHERS_URL}/${publisherId}/authors?isAll=true`
    );
  } else {
    return axios.get<HtiResponse<getAuthorSearchData>>(
      `${
        isPrivate ? PRIVATE_PUBLISHERS_URL : PUBLISHERS_URL
      }/${publisherId}/authors/search?keyword=${encodeURIComponent(queryParams.keyword)}`
    );
  }
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findPublishers(queryParams :customersUIPropsQueryParams, isPrivate : number, lastEvaluate : LastEvaluatedKey_GetPublishers, isAll : boolean) {
  if (queryParams === "none" || !queryParams.keyword) {
    let limit = "";
    let lastKeyQuery = "";
    let isAllQuery = "";

    if (queryParams && queryParams.limit) {
      limit = `?limit=${queryParams.limit}`;
    }
    if (lastEvaluate !== undefined && JSON.stringify(lastEvaluate) !== JSON.stringify({})) {
      // lastKeyQuery = "&lastEvaluatedKey=" + btoa(JSON.stringify(lastEvaluate));
      lastKeyQuery = "&lastEvaluatedKey=" + btoa(JSON.stringify(lastEvaluate));
    }
    if (isAll) {
      isAllQuery = "?isAll=true";
    }
    return axios.get<HtiResponse<GetPublishersData>>(
      `${isPrivate ? PRIVATE_PUBLISHERS_URL : PUBLISHERS_URL}${limit}${lastKeyQuery}${isAllQuery}`
    );
  } else {
    return axios.get<HtiResponse<GetPublishersData>>(
      `${isPrivate ? PRIVATE_PUBLISHERS_URL : PUBLISHERS_URL}/search?keyword=${encodeURIComponent(
        queryParams.keyword
      )}`
    );
  }
}

// UPDATE => PUT: update the publisher on the server
export function updatePublisher(publisher : UpdatePublishersPayload, isPrivate :number) {
  return axios.patch(`${isPrivate ? PRIVATE_PUBLISHERS_URL : PUBLISHERS_URL}/${publisher.uid}`, {
    name: publisher.name,
    status: publisher.status,
  });
}

export function updateAuthor(author :UpdateAuthorPayload, isPrivate : number) {
  return axios.patch(
    `${isPrivate ? PRIVATE_PUBLISHERS_URL : PUBLISHERS_URL}/${author.publisherId}/authors/${
      author.authorId
    }`,
    {
      name: author.name,
      status: author.status,
    }
  );
}

// UPDATE Status
// export function updateStatusForPublishers(ids, status) {
//   return axios.post(`${PUBLISHERS_URL}/updateStatusForPublishers`, {
//     ids,
//     status,
//   });
// }

// DELETE => delete the publisher from the server
export function deletePublisher(publisherId : string, isPrivate : number) {
  return axios.delete(`${isPrivate ? PRIVATE_PUBLISHERS_URL : PUBLISHERS_URL}/${publisherId}`);
}

export function deleteAuthor(publisherId : string , authorId : string, isPrivate :number) {
  return axios.delete(
    `${isPrivate ? PRIVATE_PUBLISHERS_URL : PUBLISHERS_URL}/${publisherId}/authors/${authorId}`
  );
}

export function blockAuthor(authorName : string, publisherId : string, authorId : string, isPrivate : number) {
  return axios.patch(
    `${isPrivate ? PRIVATE_PUBLISHERS_URL : PUBLISHERS_URL}/${publisherId}/authors/${authorId}`,
    {
      name: authorName,
      status: 0,
    }
  );
}
export function blockPublisher(publisherName :string, publisherId : string, isPrivate : number) {
  return axios.patch(`${isPrivate ? PRIVATE_PUBLISHERS_URL : PUBLISHERS_URL}/${publisherId}`, {
    name: publisherName,
    status: 0,
  });
}
export function unblockAuthor(authorName : string, publisherId : string, authorId : string, isPrivate : number) {
  return axios.patch(
    `${isPrivate ? PRIVATE_PUBLISHERS_URL : PUBLISHERS_URL}/${publisherId}/authors/${authorId}`,
    {
      name: authorName,
      status: 1,
    }
  );
}
export function unblockPublisher(publisherName :string, publisherId : string, isPrivate : number) {
  return axios.patch(`${isPrivate ? PRIVATE_PUBLISHERS_URL : PUBLISHERS_URL}/${publisherId}`, {
    name: publisherName,
    status: 1,
  });
}

