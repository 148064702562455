/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import {
   createLimitArea,
   getLimitArea,
   deleteLimitArea,
   editLimitArea,
   trunOn_Or_Off_limitArea
} from '../../../RequestServer/setting';
import {
   Card,
   CardBody,
   Input
} from '../../../../../_metronic/_partials/controls';
import LoadScriptOnlyIfNeeded from '../../pages/customers/qrcode-generator-dialog/QRCodeGeneratorForm';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Switch, Tag, Tooltip, Input as Input2, Space } from 'antd';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import SettingDeleteModal from './settingDeleteModal';

import SVG from 'react-inlinesvg';
import {
   GoogleMap,
   LoadScript,
   Autocomplete,
   Marker,
   Circle
} from '@react-google-maps/api';
import { PlusOutlined } from '@ant-design/icons';

function SettingQrCode(props) {
   const useStyles1 = makeStyles(theme => ({
      row: { display: 'block', justifyContent: 'center' },
      col: { margin: '1.5rem auto 0' },
      p: { marginBottom: '0px', fontSize: '14px' },
      title: { color: '#012875', fontWeight: 600, fontSize: '20px' },
      text: {
         color: '#1C1C1E',
         fontWeight: 500,
         fontSize: '16px',
         margin: '0'
      },
      textgray: {
         color: '#464E5F',
         fontWeight: 400,
         fontSize: '14px',
         margin: '0'
      },
      textred: { color: '#FF5337', fontWeight: 400, fontSize: '12px' },
      dashedBox: {
         border: '1px dashed #BDBDBD',
         backgroundColor: '#ffffff',
         height: '32px',
         display: 'inline-flex',
         alignItems: 'center',
         fontSize: '13px',
         '&:hover': { cursor: 'pointer' }
      },
      select: {
         width: '100%',
         borderRadius: '4px',
         border: '1px solid #E5EAEE',
         display: 'block',
         height: 'calc(1.5em + 1.3rem + 2px)',
         padding: '0.65rem 1rem',
         fontSize: '1rem',
         fontWeight: '400',
         lineHeight: '1.5',
         color: '#464E5F',
         backgroundColor: '#ffffff',
         backgroundClip: 'padding-box',
         boxShadow: 'none',
         transition:
            'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
      },

      center: {
         textAlign: 'center'
      },

      divText: { fontSize: '18px', fontWeight: '600', color: '#012875' },
      button: {
         marginTop: '32px',
         marginBottom: '20px',
         fontSize: '17px',
         fontWeight: '600',
         width: '200px',
         height: '48px',
         borderRadius: '100px',
         backgroundColor: '#012875',
         border: 'none',
         color: 'white'
      },
      subtitle: {
         fontFamily: 'Prompt',
         fontSize: '16px'
      },
      body: { margin: 'auto' },
      limitAreaForm: {
         display: 'flex',
         justifyContent: 'space-around'
      },
      QRForm: {
         '& label': {
            fontSize: 12
         }
      },
      rowInput: {
         marginBottom: 10
      },
      titleLimitarea: {
         color: '#012875',
         cursor: 'pointer'
      }
   }));
   const libraries = ['geometry', 'drawing', 'places'];
   const zoom = 14;
   const [center, setCenter] = useState({
      lat: 13.8118153,
      lng: 100.5638696
   });
   const classes = useStyles1();
   const [limitForEdit, setLimitForEdit] = useState();
   const [loading4, setLoading4] = useState(true); //reqLimitArea
   const [deleteName, setDeleteName] = useState(undefined);
   const [isDetail, setIsDetail] = useState(false);
   const [latitude, setLatitude] = useState(undefined);
   const [longitude, setLongitude] = useState(undefined);
   const [autocomplete, setAutocomplete] = useState(null);
   const [markerPosition, setMarkerPosition] = useState(null);
   const [isMarkerShown, setIsMarkerShown] = useState(false);
   const [isDelete, setIsDelete] = useState(false);
   const [key, setKey] = useState(0);
   const [modalVisible, setModalVisible] = useState();

   useEffect(() => {
      console.log(limitForEdit);
   }, [limitForEdit]);

   useEffect(() => {
      if (
         latitude !== null &&
         latitude !== undefined &&
         longitude !== null &&
         longitude !== undefined
      ) {
         console.log(latitude, longitude);
         setIsMarkerShown(true);
         setMarkerPosition({
            lat: latitude,
            lng: longitude
         });
      }
   }, [latitude, longitude]);

   const handleLatitudeChange = e => {
      setLatitude(parseFloat(e.target.value));
   };

   const circleOptions = {
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      zIndex: 1
   };

   const qrCodeSchema = Yup.object().shape({
      name: Yup.string()
         .min(1, 'อย่างน้อย 1 ตัวอักษร')
         .max(200, 'ไม่เกิน 200 ตัวอักษร')
         .required('กรุณากรอกชื่อ QR Code'),
      latitude: Yup.number()
         .typeError('กรุณาใช้ตัวเลข')
         .required('กรุณากรอกค่า Latitude หรือปักหมุด'),
      longitude: Yup.number()
         .typeError('กรุณาใช้ตัวเลข')
         .required('กรุณากรอกค่า Longitude หรือปักหมุด'),
      distance: Yup.number()
         .typeError('กรุณาใช้ตัวเลข')
         .required('กรุณากรอกค่ารัศมี')
   });

   const handleLongitudeChange = e => {
      console.log(parseFloat(e.target.value));
      setLongitude(parseFloat(e.target.value));
   };

   const checkChanged = newValues => {
      const keys1 = Object.keys(limitForEdit);
      const keys2 = Object.keys(newValues);
      let newObject = {};

      if (keys1.length !== keys2.length) {
         return false;
      }

      for (let key of keys1) {
         if (limitForEdit[key] !== newValues[key]) {
            newObject = Object.assign(newObject, { [key]: newValues[key] });
         }
      }
      newObject = Object.assign(newObject, { oldName: limitForEdit.name });
      return newObject;
   };

   const resetForm = () => {
      setMarkerPosition(null);
      setLatitude(undefined);
      setLongitude(undefined);
      setLimitForEdit(undefined);
   };

   const editArea = async (oldName, values) => {
      let newLimitArea = JSON.parse(JSON.stringify(props.limitArea));
      let index = props.limitArea.findIndex(obj => obj.name == oldName);
      newLimitArea[index] = values;
      props.setLimitArea(newLimitArea);
      // console.log( limitArea.findIndex((el)=>{ el.name === oldName }));
      // setLimitArea(prev =>{
      //   return  prev.filter((el)=>{ if( el.name == oldName){ return values} else{ return true} })
      //
   };

   const onLoad = autocomplete => {
      setAutocomplete(autocomplete);
   };

   const onPlaceChanged = setFieldValue => {
      if (autocomplete !== null && autocomplete.getPlace().geometry) {
         const latLng = autocomplete.getPlace().geometry.location;
         setCenter(latLng);
         setMarkerPosition(latLng);
         setIsMarkerShown(true);
         const latLngString = latLng
            .toString()
            .replace(/[()]/g, '')
            .split(', ');
         setFieldValue('latitude', parseFloat(latLngString[0]));
         setFieldValue('longitude', parseFloat(latLngString[1]));
         setLatitude(parseFloat(latLngString[0]));
         setLongitude(parseFloat(latLngString[1]));
      }
   };

   const deleteArea = async () => {
      await deleteLimitArea(deleteName);
      props.setLimitArea(prev => {
         return prev.filter(el => {
            return el.name !== deleteName;
         });
      });
      setDeleteName(undefined);
      setLoading4(false);
   };

   const renderListArea = e => {
      return (
         <div className="row">
            <div className="col-10">
               <div
                  className={classes.titleLimitarea}
                  onClick={() => {
                     setModalVisible(true);
                     setCenter({
                        lat: e.latitude,
                        lng: e.longitude
                     });
                     setLimitForEdit(e);
                     setIsDetail(true);
                  }}
               >
                  {e.name}
               </div>
            </div>
            <div className="col-2">
               <a
                  className="btn btn-icon btn-light btn-hover-primary btn-sm"
                  onClick={() => {
                     setModalVisible(true);
                     setCenter({
                        lat: e.latitude,
                        lng: e.longitude
                     });
                     setLimitForEdit(e);
                  }}
                  style={{
                     backgroundColor: '#F3F6F9',
                     marginRight: 10
                  }}
               >
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                     <SVG
                        src={toAbsoluteUrl('/media/members/edit-button.svg')}
                     />
                  </span>
               </a>
               <a
                  className="btn btn-icon btn-light btn-hover-danger btn-sm"
                  onClick={() => {
                     setIsDelete(true);
                     setDeleteName(e.name);
                  }}
               >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                     <SVG
                        src={toAbsoluteUrl('/media/members/delete-button.svg')}
                     />
                  </span>
               </a>
            </div>
         </div>
      );
   };

   const onKeyDown = keyEvent => {
      if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
         keyEvent.preventDefault();
      }
   };

   const onMapClick = (e, setFieldValue) => {
      console.log(JSON.stringify(e));
      setMarkerPosition(e.latLng);
      setIsMarkerShown(true);
      const latLngString = e.latLng
         .toString()
         .replace(/[()]/g, '')
         .split(', ');
      setFieldValue('latitude', parseFloat(latLngString[0]));
      setFieldValue('longitude', parseFloat(latLngString[1]));
      setLatitude(parseFloat(latLngString[0]));
      setLongitude(parseFloat(latLngString[1]));
   };

   return (
      <div>
         <div>
            <div className="row">
               <div className="col-3"></div>
               <div className="col-9">
                  <Divider style={{ margin: '20px 0' }} />
               </div>
            </div>
            <div className="row align-items-center">
               <div className="col-3"></div>
               <div className="col-9">
                  <Space
                     size={18}
                     direction={'vertical'}
                     style={{ width: '100%' }}
                  >
                     {Array.isArray(props.limitArea) &&
                        props.limitArea.map((e, index) => {
                           return renderListArea(e);
                        })}
                  </Space>
               </div>
            </div>
            <div className="row align-items-center">
               <div className="col-3"></div>
               <div className="col-3">
                  <Tag
                     className={`site-tag-plus ${classes.dashedBox}`}
                     onClick={() => setModalVisible(true)}
                  >
                     <PlusOutlined style={{ marginRight: '2px' }} />
                     เพิ่มจุดสแกน
                  </Tag>
               </div>
            </div>
            <div className="row">
               <div className="col-3"></div>
               <div className="col-9">
                  <Divider style={{ margin: '20px 0' }} />
               </div>
            </div>
         </div>
         <Modal
            backdrop="static"
            size="lg"
            show={modalVisible}
            onHide={() => {
               setModalVisible(false);
            }}
            aria-labelledby="example-modal-sizes-title-lg"
         >
            <h2 className="text-text-head">จุดสแกน QR Code</h2>
            <Modal.Body>
               <div>
                  <Formik
                     initialValues={
                        limitForEdit
                           ? limitForEdit
                           : { name: '', distance: 2000 }
                     }
                     validationSchema={qrCodeSchema}
                     onSubmit={async (values, actions) => {
                        try {
                           actions.setSubmitting(true);
                           if (!isDetail) {
                              if (limitForEdit) {
                                 let valueschanged = await checkChanged(values);
                                 editArea(limitForEdit.name, values);
                                 console.log(valueschanged);
                                 await editLimitArea(valueschanged);
                                 setModalVisible(false);
                                 actions.setSubmitting(false);
                              } else {
                                 let result = await createLimitArea(values);
                                 if (result) {
                                    props.setLimitArea(prev => {
                                       if (Array.isArray(prev)) {
                                          return prev.concat(values);
                                       } else {
                                          return [values];
                                       }
                                    });
                                    setModalVisible(false);
                                 }
                                 actions.setSubmitting(false);
                              }

                              // setLoading4(false);
                              await resetForm();
                              // setSuccessModal(true) RYou
                              props.onSuccess();
                           }
                        } catch (error) {}
                     }}
                  >
                     {({
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        isSubmitting,
                        values
                     }) => (
                        <Form onSubmit={handleSubmit} onKeyDown={onKeyDown}>
                           <div className={classes.QRForm}>
                              <div className={`row ${classes.rowInput}`}>
                                 <div className={`col-lg-12 `}>
                                    <Field
                                       readOnly={isDetail}
                                       name="name"
                                       label="ชื่อจุดสแกน QR Code*"
                                       onChange={handleChange}
                                       component={Input}
                                       placeholder="กรุณากรอกชื่อจุดสแกน QR Code"
                                       type={'text'}
                                       className="input-responsive"
                                    />
                                 </div>
                              </div>
                              <div
                                 className={`row ${classes.limitAreaForm}  ${classes.rowInput}`}
                              >
                                 <br />

                                 <div className={`col-lg-4 `}>
                                    <Field
                                       readOnly={isDetail}
                                       name="distance"
                                       label="รัศมีที่ครอบคลุม (พื้นที่สแกน QR Code)"
                                       onChange={handleChange}
                                       component={Input}
                                       value={values.distance}
                                       type={'number'}
                                       className="input-responsive"
                                    />
                                    {errors.distance && touched.distance ? (
                                       <div>{errors.distance}</div>
                                    ) : null}
                                 </div>
                                 <div className={`col-lg-4 `}>
                                    <Field
                                       readOnly={isDetail}
                                       name="latitude"
                                       label="latitude"
                                       placeholder="0.00000"
                                       value={
                                          limitForEdit
                                             ? values.latitude
                                             : latitude
                                       }
                                       onChange={e => {
                                          handleChange(e);
                                          handleLatitudeChange(e);
                                       }}
                                       component={Input}
                                       type="number"
                                       step="any"
                                       className="input-responsive"
                                    />
                                    {errors.latitude && touched.latitude ? (
                                       <div>{errors.latitude}</div>
                                    ) : null}
                                 </div>

                                 <div className={`col-lg-4 `}>
                                    <Field
                                       readOnly={isDetail}
                                       name="longitude"
                                       label="Longitude"
                                       placeholder="0.00000"
                                       value={
                                          limitForEdit
                                             ? values.longitude
                                             : longitude
                                       }
                                       onChange={e => {
                                          handleChange(e);
                                          handleLongitudeChange(e);
                                       }}
                                       component={Input}
                                       type="number"
                                       step="any"
                                       className="input-responsive"
                                    />
                                    {errors.longitude && touched.longitude ? (
                                       <div>{errors.longitude}</div>
                                    ) : null}
                                 </div>
                              </div>

                              <LoadScriptOnlyIfNeeded
                                 googleMapsApiKey="AIzaSyCFiRHTBRKXn9IQijX6sUcinAOT1ei-mlU"
                                 libraries={libraries}
                              >
                                 <GoogleMap
                                    mapContainerStyle={{
                                       width: '100%',
                                       height: '400px',
                                       borderRadius: '5px',
                                       marginTop: '10px'
                                    }}
                                    defaultZoom={zoom}
                                    zoom={zoom}
                                    center={center}
                                    options={{
                                       streetViewControl: false,
                                       mapTypeControl: false,
                                       fullscreenControl: false
                                    }}
                                    onClick={e => {
                                       if (!isDetail)
                                          onMapClick(e, setFieldValue);
                                    }}
                                 >
                                    {(isMarkerShown || limitForEdit) && (
                                       <Marker
                                          position={
                                             limitForEdit
                                                ? {
                                                     lat: values.latitude,
                                                     lng: values.longitude
                                                  }
                                                : markerPosition
                                          }
                                       />
                                    )}
                                    <Autocomplete
                                       onLoad={onLoad}
                                       onPlaceChanged={() => {
                                          if (!isDetail)
                                             onPlaceChanged(setFieldValue);
                                       }}
                                    >
                                       <input
                                          type="text"
                                          placeholder="ค้นหาสถานที่หรือปักหมุด"
                                          style={{
                                             boxSizing: `border-box`,
                                             border: `1px solid transparent`,
                                             width: `300px`,
                                             height: `40px`,
                                             padding: `0 12px`,
                                             borderRadius: `20px`,
                                             boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                             fontSize: `15px`,
                                             outline: `none`,
                                             textOverflow: `ellipses`,
                                             position: 'absolute',
                                             margin: '8px'
                                          }}
                                       />
                                    </Autocomplete>

                                    {(isMarkerShown || limitForEdit) && (
                                       <Circle
                                          center={
                                             limitForEdit
                                                ? {
                                                     lat: values.latitude,
                                                     lng: values.longitude
                                                  }
                                                : markerPosition
                                          }
                                          radius={values.distance}
                                          options={circleOptions}
                                       />
                                    )}
                                 </GoogleMap>
                              </LoadScriptOnlyIfNeeded>
                              <div className="divButton ">
                                 <button
                                    type="button"
                                    className="denyButton"
                                    onClick={() => {
                                       setKey(key + 1);
                                       setLimitForEdit(false);
                                       setModalVisible(false);
                                       setIsDetail(false);
                                    }}
                                 >
                                    ยกเลิก
                                 </button>
                                 <button
                                    className="acceptButton"
                                    disabled={isDetail || isSubmitting}
                                    type="submit"
                                 >
                                    ตกลง
                                 </button>
                              </div>
                           </div>
                        </Form>
                     )}
                  </Formik>
               </div>
            </Modal.Body>
         </Modal>
         {isDelete && (
            <SettingDeleteModal
               show={true}
               name={deleteName}
               onHide={() => {
                  setIsDelete(false);
               }}
               onConfirm={() => {
                  setLoading4(true);
                  deleteArea();
                  setIsDelete(false);
               }}
            />
         )}
      </div>
   );
}

export default SettingQrCode;
