import { createSlice } from "@reduxjs/toolkit";
import {
  Content,
  Episodes,
  LastEvaluatedKey_Content,
  LastEvaluatedKey_FreeContent
} from "../../../../../interface";
interface podcastState {
    listLoading: boolean,
    actionsLoading: boolean,
    totalCount:number,
    entities: Content[],
    entities_hti: Content[],
    entities_free: Content[],
    podcastForEdit?: Episodes,
    lastError: null,
    LastEvaluatedKey: LastEvaluatedKey_Content  |{},
    LastEvaluatedKey_hti: LastEvaluatedKey_Content  |{},
    LastEvaluatedKey_free: LastEvaluatedKey_FreeContent | {},
    podcastName: undefined,
    podcastEpisodes: Episodes[]
    error?: string |null
}
const initialPodcastsState :podcastState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  entities_hti: [],
  entities_free: [],
  podcastForEdit: undefined,
  lastError: null,
  LastEvaluatedKey: {},
  LastEvaluatedKey_hti: {},
  LastEvaluatedKey_free: {},
  podcastName: undefined,
  podcastEpisodes: []
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const podcastsSlice = createSlice({
  name: "podcasts",
  initialState: initialPodcastsState,
  reducers: {
    catchError: (state, action) => {
      if (
        action.payload.error &&
        action.payload.error.response &&
        action.payload.error.response.data
      ) {
        state.error = action.payload.error.response.data.errorCode;
      }
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getPodcastById
    podcastFetched: (state, action) => {
      state.actionsLoading = false;
      state.podcastForEdit = action.payload.podcastForEdit;
      state.error = null;
    },
    // findPodcasts
    podcastsFetched: (state, action) => {
      const {
        totalCount,
        entities,
        LastEvaluatedKey,
        lastEvaluate,
        isPrivate,
        isFree,
        memberMode
      } = action.payload;
      const blockedOrNormal_Private = (e_status:number) => (e_status === 1 ? 1 : 0);
      state.listLoading = false;
      state.error = null;
      if (isPrivate === 1) {
        state.podcastName = undefined;
      }
      if (isPrivate) {
        if (lastEvaluate === undefined) {
          if (memberMode) {
            state.entities = entities.filter(
              (e :Content) => e.status === parseInt(memberMode, 10)
            );
          } else {
            state.entities = entities;
          }
        } else {
          if (memberMode) {
            entities
              .filter((e :Content) =>e.status=== parseInt(memberMode, 10))
              .forEach((item :Content) => state.entities.push(item));
          } else {
            entities.forEach((item :Content) => state.entities.push(item));
          }
        }
      } else {
        if (isFree) {
          if (lastEvaluate === undefined) {
            if (memberMode) {
              state.entities_free = entities.filter((e :Content) => {
                return e.status === parseInt(memberMode, 10);
              });
            } else {
              state.entities_free = entities;
            }
          } else {
            if (memberMode) {
              entities
                .filter((e :Content) => e.status === parseInt(memberMode, 10))
                .forEach((item :Content) => state.entities_free.push(item));
            } else {
              entities.forEach((item :Content) => state.entities_free.push(item));
            }
          }
        } else {
          if (lastEvaluate === undefined) {
            if (memberMode) {
              state.entities_hti = entities.filter(
                (e :Content) => e.status === parseInt(memberMode, 10)
              );
            } else {
              state.entities_hti = entities;
            }
          } else {
            if (memberMode) {
              entities
                .filter((e :Content) => e.status === parseInt(memberMode, 10))
                .forEach((item :Content) => state.entities_hti.push(item));
            } else {
              entities.forEach((item :Content) => state.entities_hti.push(item));
            }
          }
        }
      }
      state.totalCount = totalCount;
      if (isFree) {
        state.LastEvaluatedKey_free = LastEvaluatedKey;
      } else if (isPrivate) {
        state.LastEvaluatedKey = LastEvaluatedKey;
      } else {
        state.LastEvaluatedKey_hti = LastEvaluatedKey;
      }
      // state.entities = mockUsers;
      // state.totalCount = 17;
    },
    // createPodcast
    podcastCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.podcast);
    },
    // updatePodcast
    podcastUpdated: (state, action) => {
      // console.log(action.payload.id, action.payload.podcast);
      state.error = null;
      state.actionsLoading = false;
      if (action.payload.podcast) {
        if (action.payload.podcast.contentType === "podcast_episode") {
          state.podcastEpisodes = state.podcastEpisodes.map(entity => {
            if (entity.contentUID === action.payload.id) {
              // console.log(action.payload.podcast);
              // delete action.payload.podcast.coverImage;
              return Object.assign({}, entity, action.payload.podcast);
            }
            return entity;
          });
        } else {
          state.entities = state.entities.map(entity => {
            if (entity.contentUID === action.payload.id) {
              // console.log(action.payload.podcast);
              // delete action.payload.podcast.coverImage;
              return Object.assign({}, entity, action.payload.podcast);
            }
            return entity;
          });
        }
      }
    },
    // deletePodcast
    podcastDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => el.contentUID !== action.payload.id
      );
    },
    // blockPodcast
    podcastBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.contentUID === action.payload.id) {
          return Object.assign({}, entity, { status: 2 });
        }
        return entity;
      });
    },
    // unblockPodcast
    podcastUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.contentUID === action.payload.id) {
          return Object.assign({}, entity, { status: 1 });
        }
        return entity;
      });
    },
    // deletePodcasts
    // podcastsDeleted: (state, action) => {
    //   state.error = null;
    //   state.actionsLoading = false;
    //   state.entities = state.entities.filter(
    //     el => !action.payload.ids.includes(el.id)
    //   );
    // },
    // // podcastsUpdateState
    // podcastsStatusUpdated: (state, action) => {
    //   state.actionsLoading = false;
    //   state.error = null;
    //   const { ids, status } = action.payload;
    //   state.entities = state.entities.map(entity => {
    //     if (ids.findIndex(id => id === entity.id) > -1) {
    //       entity.status = status;
    //     }
    //     return entity;
    //   });
    // },
    elementUpdated: (state) => {
      state.actionsLoading = false;
    },
    podcastNameFetched: (state, action) => {
      state.podcastName = action.payload.podcastName;
    },
    podcastEpisodesFetched: (state, action) => {
      state.listLoading = false;
      state.podcastEpisodes = action.payload.podcastEpisodes;
    }
  }
});
