// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import moment from "moment-timezone";
interface RemainAmountColumnFormatterProps{
  startDate : number,
  expireDate : number,
  status : number,
}
export function RemainAmountColumnFormatter(
 
  row:RemainAmountColumnFormatterProps,
  
) {
  return (
    <>
      <div className="text-small" style={{maxWidth:115,margin:'0 auto'}}>
        {row.startDate && row.expireDate
          ? millisecsToDateTime(row.startDate, undefined, "dd/mm/yyyy") +
            " - " +
            millisecsToDateTime(row.expireDate, undefined, "dd/mm/yyyy")
          : "-"}
      </div>
      {row.status && (row.status === 1 || row.status === 4) ? (
        <div className="text-small">
          {row.status === 1
            ? "ใช้งานจริง"
            : row.status === 4
            ? "ทดลองใช้งาน"
            : ""}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

const millisecsToDateTime = (secs :number, timeFormat?: string, dateFormat?:string) => {
  const pad = (num : number) => {
    return ("0" + num).slice(-2);
  };
  var momentBKK = moment.tz(secs * 1000, "Asia/Bangkok").format();
  var a = new Date(momentBKK);
  var months = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];
  var year = a.getFullYear() +''|| "";
  var month = months[a.getMonth()] +''|| "";
  var date = a.getDate() +''|| "";
  var hour = a.getHours() || 0;
  var minute = a.getMinutes() || 0;
  var time =
    timeFormat === "hr-min"
      ? dateFormat === "dd/mm/yyyy"
        ? (parseInt(date , 10) < 10 ? "0" : "") +
          date +
          (parseInt((a.getMonth() + 1)+'', 10) < 10 ? "/0" : "/") +
          parseInt((a.getMonth() + 1)+'', 10) +
          "/" +
          year +
          " " +
          pad(hour) +
          ":" +
          pad(minute)
        : date + " " + month + " " + year + " " + pad(hour) + ":" + pad(minute)
      : date + " " + month + " " + year;
  return time;
};
