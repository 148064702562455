import { Button, Modal, ModalProps, Space } from 'antd';
import { EmailPublisherType } from '../../../../../../redux/axios/email';
import './index.scss';

interface SendQuoteMOdalProps {
    visible: boolean;
    publisherList: EmailPublisherType[];
    unsentPublisher: string[];
    sendAllEmail: () => Promise<void>;
    onClose: () => void;
}

export const AlertSendQuoteModal: React.FC<SendQuoteMOdalProps> = ({
    visible,
    publisherList,
    unsentPublisher,
    onClose,
    sendAllEmail
}) => {
    const modalProps: ModalProps = {
        visible: visible,
        onCancel: onClose,
        footer: null,
        className: 'alertSendQuoteModal'
    };

    return (
        <Modal {...modalProps}>
            <img src="/media/members/image-modal.svg" />
            <h1>ยืนยันการส่งใบเสนอราคา</h1>
            <p>สำนักพิมพ์ที่ไม่ส่งใบเสนอราคา ได้แก่</p>
            {publisherList
                .filter(e => unsentPublisher.includes(e.publisherUID))
                .map(e => (
                    <p className="publisherName">{e.publisherName}</p>
                ))}
            <p>(โปรดตรวจสอบความถูกต้อง)</p>
            <div className="buttonContain">
                <Button shape="round" className="edit" onClick={onClose}>
                    แก้ไข
                </Button>
                <Button
                    shape="round"
                    className="send"
                    onClick={() => sendAllEmail()}
                >
                    ส่งใบเสนอราคา
                </Button>
            </div>
        </Modal>
    );
};
