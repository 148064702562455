import { useState, useEffect, useRef } from 'react';
import { Card, CardBody } from '../../../../../_metronic/_partials/controls';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import {
    Divider,
    Switch,
    Tag,
    Tooltip,
    Input as Input2,
    notification
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Modal } from 'react-bootstrap';
import Spinner from '../../../../../_metronic/_helpers/spinner';
import SettingQrCode from './settingQrCode';

import {
    trunOn_Or_Off_limitArea,
    enableStaticLocation,
    disableStaticLocation,
    triggerButtonRegister,
    getAllSetting,
    enableRequireEmployeeID,
    disableRequireEmployeeID,
    enableRequireDomain,
    disableRequireDomain
} from '../../../RequestServer/setting';

import SuccessModal from './SuccessModal';

export function SettingCard() {
    const useStyles1 = makeStyles(theme => ({
        row: { display: 'block', justifyContent: 'center' },
        col: { margin: '1.5rem auto 0' },
        p: { marginBottom: '0px', fontSize: '14px' },
        title: { color: '#012875', fontWeight: 600, fontSize: '20px' },
        text: {
            color: '#1C1C1E',
            fontWeight: 500,
            fontSize: '16px',
            margin: '0'
        },
        textgray: {
            color: '#464E5F',
            fontWeight: 400,
            fontSize: '14px',
            margin: '0'
        },
        textred: { color: '#FF5337', fontWeight: 400, fontSize: '12px' },
        dashedBox: {
            border: '1px dashed #BDBDBD',
            backgroundColor: '#ffffff',
            height: '32px',
            display: 'inline-flex',
            alignItems: 'center',
            fontSize: '13px',
            '&:hover': { cursor: 'pointer' }
        },
        select: {
            width: '100%',
            borderRadius: '4px',
            border: '1px solid #E5EAEE',
            display: 'block',
            height: 'calc(1.5em + 1.3rem + 2px)',
            padding: '0.65rem 1rem',
            fontSize: '1rem',
            fontWeight: '400',
            lineHeight: '1.5',
            color: '#464E5F',
            backgroundColor: '#ffffff',
            backgroundClip: 'padding-box',
            boxShadow: 'none',
            transition:
                'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
        },

        center: {
            textAlign: 'center'
        },

        divText: {
            fontSize: '18px',
            fontWeight: '600',
            color: '#012875',
            textAlign: 'center'
        },
        errorDescroption: {
            textAlign: 'center'
        },
        button: {
            marginTop: '32px',
            marginBottom: '20px',
            fontSize: '17px',
            fontWeight: '600',
            width: '200px',
            height: '48px',
            borderRadius: '100px',
            backgroundColor: '#012875',
            border: 'none',
            color: 'white'
        },
        subtitle: {
            fontFamily: 'Prompt',
            fontSize: '16px'
        },
        body: { margin: 'auto' },
        limitAreaForm: {
            display: 'flex',
            justifyContent: 'space-around'
        },
        QRForm: {
            '& label': {
                fontSize: 12
            }
        },
        rowInput: {
            marginBottom: 10
        },
        titleLimitarea: {
            color: '#012875',
            cursor: 'pointer'
        }
    }));

    const classes = useStyles1();
    const [isPublicLibrary, setIsManagePublicLibrary] = useState(false);
    const [isNationwide, setIsNationwide] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [isLocationLimit, setIsLocationLimit] = useState(false);
    const [limitForEdit, setLimitForEdit] = useState(false);
    const [inputVisible, setInputVisible] = useState(false);
    // const [tags, setTags] = useState([]);
    const [isDomainRegister, setIsDomainRegister] = useState(false);
    const [isEmailRegister, setIsEmailRegister] = useState(false);
    const [isEmployeeIDRegister, setIsEmployeeIDRegister] = useState(false);

    const [canRegisterFromApi, setCanRegisterFromApi] = useState(false);
    const [canRegister, setCanRegister] = useState(false);

    const [inputValue, setInputValue] = useState('@');
    const [loading1, setLoading1] = useState(true); //reqFunc
    const [loading2, setLoading2] = useState(true); //reqRegister
    const [loading3, setLoading3] = useState(true); //reqDomain
    const [loading4, setLoading4] = useState(true); //reqLimitArea
    const [limitArea, setLimitArea] = useState(undefined);
    const [isShowModalLimitArea, setIsShowModalLimitArea] = useState(false);
    const [limitAreaStatus, setLimitAreaStatus] = useState(false);
    // const [domainsname, setdomainsname] = useState("");
    // const [radius, setRadius] = useState(2000);

    const [newDomainModal, setNewDomainModal] = useState(false);
    const [domains, setDomains] = useState([]);
    const [errorModalText, setErrorModalText] = useState();

    const saveInputRef = useRef(null);
    useEffect(() => {
        reqFunc();
        // reqRegister();
        // reqdomain();
        getSetting();
        return () => {
            console.log('setting cleanup');

            // if (
            //   !(isNationwide ||
            //     isDomainRegister ||
            //     isEmployeeIDRegister ||
            //     limitAreaStatus) &&
            //   canRegisterFromApi
            // ) {
            //   triggerButtonRegister()
            // }
        };
    }, []);

    useEffect(() => {
        if (canRegister) {
            if (
                (isNationwide ||
                    isDomainRegister ||
                    isEmployeeIDRegister ||
                    limitAreaStatus) &&
                !canRegisterFromApi
            ) {
                triggerButtonRegister();
                setCanRegisterFromApi(prev => !prev);
            } else {
                if (
                    isNationwide == false &&
                    isDomainRegister == false &&
                    isEmployeeIDRegister == false &&
                    limitAreaStatus == false &&
                    canRegisterFromApi
                ) {
                    triggerButtonRegister();
                    setCanRegisterFromApi(prev => !prev);
                }
            }
        }
    }, [isNationwide, isEmployeeIDRegister, limitAreaStatus, isDomainRegister]);

    useEffect(() => {
        if (inputVisible) saveInputRef.current.focus();
    }, [inputVisible]);

    useEffect(() => {
        if (
            !isDomainRegister &&
            !limitAreaStatus &&
            !loading1 &&
            !loading2 &&
            !loading3 &&
            !loading4
        ) {
            disableRequireEmployeeID();
            setIsEmployeeIDRegister(false);
        }
    }, [isDomainRegister, limitAreaStatus]);

    const getSetting = async () => {
        let res = await getAllSetting();
        if (res.status && res.data) {
            let {
                emailDomainRegister,
                registerByLocation,
                publicRegisterThailand,
                requireEmployeeNumber
            } = res.data.register;

            setCanRegisterFromApi(res.data.register.isPublicRegister);
            setCanRegister(res.data.register.isPublicRegister);
            setIsNationwide(publicRegisterThailand);
            setIsEmployeeIDRegister(requireEmployeeNumber);
            setIsDomainRegister(emailDomainRegister.status);
            setLoading3(false);
            setLoading2(false);

            if (
                emailDomainRegister.status &&
                emailDomainRegister.domains &&
                emailDomainRegister.domains.length > 0
            ) {
                setDomains(emailDomainRegister.domains);
            }
            // if (
            //   registerByLocation.location.status
            // ) {
            setLimitAreaStatus(registerByLocation.status);
            setLimitArea(registerByLocation.location);
            setIsLocationLimit(true);

            // }
        }
        setLoading4(false);
    };

    const reqFunc = async () => {
        const req = await axios.get('/admin/website/public');
        if (req.data.status && req.data.data) {
            const obj = req.data.data;
            setIsManagePublicLibrary(obj.isPublicLibrary);
            setLoading1(false);
        }
    };
    const requpdate = async () => {
        const req = await axios.patch('/admin/website/public');
        if (req.data.status) {
            reqFunc();
        }
    };

    const reqRegister = async () => {
        const req = await axios.get('/admin/register/public');
        if (req.data.status && req.data.data) {
            const obj = req.data.data;
            setIsDomainRegister(obj.isDomainRegister);
            setLoading2(false);
        }
    };

    const removeRegister = async tag => {
        const req = await axios.patch('/admin/register/domains', {
            domain: tag
        });
        if (req.data.status) {
            handleClose(tag);
        }
    };

    const reqdomain = async () => {
        if (domains === undefined || domains.length === 0) {
            const req = await axios.get('/admin/register/domains');
            if (req.data.status && req.data.data) {
                const obj = req.data.data.domains;
                setDomains(old => old.concat(obj));
                setLoading3(false);
                // setDomains(obj);
            }
        }
    };

    const checkErrorText = () => {
        if (
            inputValue.includes('hotmail') &&
            inputValue.includes('gmail') &&
            inputValue.includes('yahoo') &&
            inputValue.includes('outlook')
        ) {
            setErrorModalText(
                'ไม่สามารถใช้ Domain gmail.com, hotmail.com, outlook.com, yahoo.com สมาชิกได้'
            );
        } else if (
            /@[a-zA-Z0-9]+$/.test(inputValue) &&
            !/(-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(inputValue)
        ) {
            setErrorModalText('กรุณากรอก TLD (.com , .co.th , .org)');
        } else if (
            !/@[a-zA-Z0-9]+$/.test(inputValue) &&
            /(-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(inputValue)
        ) {
            setErrorModalText(
                'กรุณากรอก SLD ([SLD].com , [SLD].co.th , [SLD].org)'
            );
        }
    };

    const reqDomainNames = async () => {
        if (
            /@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/.test(
                inputValue
            ) &&
            !inputValue.includes('hotmail') &&
            !inputValue.includes('gmail') &&
            !inputValue.includes('yahoo') &&
            !inputValue.includes('outlook')
        ) {
            const req = await axios.post('/admin/register/domains', {
                domain: inputValue
            });
            if (req.data.status) {
                handleInputConfirm();
            }
        } else if (inputValue && inputValue !== '') {
            checkErrorText();
            setInputVisible(false);
            setInputValue('@');
            setNewDomainModal(true);
        } else {
            setInputVisible(false);
            setInputValue('@');
        }
    };

    const onSwitchChange = e => {
        if (!isDomainRegister && isNationwide) {
            openNotificationSwitchNationwide();
        } else {
            if (isDomainRegister) {
                disableRequireDomain();
            } else {
                enableRequireDomain();
                reqdomain();
            }
            setIsDomainRegister(prev => !prev);
        }
    };
    const onSwitchChangeisPublicLibrary = e => {
        // console.log(e);
        setIsManagePublicLibrary(e);
        requpdate();
    };
    const onSwitchChangeisLocationLimit = e => {
        if (!limitAreaStatus && isNationwide) {
            openNotificationSwitchLimitArea();
        } else {
            trunOn_Or_Off_limitArea();
            setIsLocationLimit(e);
            setLimitAreaStatus(prev => {
                return !prev;
            });
        }
    };
    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputConfirm = () => {
        if (inputValue && domains.indexOf(inputValue) === -1) {
            setDomains([...domains, inputValue]);
        }
        setInputVisible(false);
        setInputValue('@');
    };
    const handleClose = removedTag => {
        setDomains(domains.filter(tag => tag !== removedTag));
    };
    const handleInputChange = e => {
        setInputValue(e.target.value);
    };

    const onSwitchChangeisNationwide = async () => {
        try {
            if (
                !isNationwide &&
                (isDomainRegister || isEmployeeIDRegister || limitAreaStatus)
            ) {
                openNotificationSwitchDomain();
            } else {
                if (isNationwide) {
                    disableStaticLocation();
                    setIsNationwide(false);
                } else {
                    enableStaticLocation();
                    setIsNationwide(true);
                    setIsEmailRegister(true);
                    if (isLocationLimit && limitAreaStatus) {
                        trunOn_Or_Off_limitArea();
                        setIsLocationLimit(false);
                    }
                }
            }
        } catch (err) {
            setIsNationwide(prev => !prev);
        }
    };

    const onSwitchEmployeeIDRegister = () => {
        if (!isEmployeeIDRegister && isNationwide) {
            openNotificationSwitchEmployeeID();
        } else {
            if (isEmployeeIDRegister) {
                disableRequireEmployeeID();
            } else {
                enableRequireEmployeeID();
            }
            setIsEmployeeIDRegister(prev => !prev);
        }
    };
    const onSwitchChangeCanRegister = () => {
        if (
            //ถ้าทุกตัวปิด
            !(
                isNationwide &&
                isDomainRegister &&
                isEmployeeIDRegister &&
                limitAreaStatus
            )
        ) {
            if (canRegisterFromApi) {
                triggerButtonRegister();
            }
        } else {
            triggerButtonRegister();
        }
        setCanRegister(prev => !prev);
    };

    const openNotificationSwitchDomain = () => {
        notification.info({
            message: 'ไม่สามารถเปิดการลงทะเบียนสาธารณะได้',
            description:
                'กรุณา ปิดการกำหนดค่า Domain ,การระบุรหัสพนักงาน และ จุดให้บริการได้ ก่อนเพื่อเปิดการลงทะเบียนสาธารณะ'
        });
    };

    const openNotificationSwitchNationwide = () => {
        notification.info({
            message: 'ไม่สามารถเปิดการกำหนดค่า Domain ได้',
            description:
                'กรุณาปิดการกำหนดค่า Domain ก่อนเพื่อเปิดการกำหนดค่า Domain'
        });
    };

    const openNotificationSwitchEmployeeID = () => {
        notification.info({
            message: 'ไม่สามารถเปิดได้',
            description:
                'กรุณา ปิดการลงทะเบียนสาธารณะ ก่อนเพื่อเปิดการระบุรหัสพนักงาน'
        });
    };
    const openNotificationSwitchLimitArea = () => {
        notification.info({
            message: 'ไม่สามารถเปิดจุดให้บริการได้',
            description:
                'กรุณา ปิดการลงทะเบียนสาธารณะ ก่อนเพื่อเปิดใช้จุดให้บริการ'
        });
    };
    return (
        <Card className="cardStyle">
            <CardBody>
                {loading1 || loading2 || loading3 || loading4 ? (
                    <Spinner />
                ) : (
                    <div>
                        <p className={classes.title}>ตั้งค่า</p>
                        {/* <Divider style={{ margin: '20px 0' }} />
                  <div className="row align-items-center">
                     <div className="col-3">
                        <p className={classes.text}>การใช้งานหน้าเว็บ</p>
                     </div>
                     <div className="col-6">
                        <p className={classes.textgray}>
                           ใครสามารถเห็นหน้าเว็บได้บ้าง
                        </p>
                     </div>
                     <div className="col-3 d-flex justify-content-end">
                        <p className={classes.textgray}>เปิดให้ดูแบบสาธารณะ</p>
                        <Switch
                           style={{ marginLeft: '20px' }}
                           onChange={onSwitchChangeisPublicLibrary}
                           checked={isPublicLibrary}
                           checkedChildren="เปิด"
                           unCheckedChildren="ปิด"
                        />
                     </div>
                  </div> */}

                        <Divider style={{ margin: '20px 0' }} />
                        <div className="row align-items-center">
                            <div className="col-3">
                                <p className={classes.text}>เปิดลงทะเบียน</p>
                            </div>
                            <div className="col-6">
                                <p className={classes.textgray}>
                                    {' '}
                                    เปิดลงทะเบียน
                                </p>
                            </div>
                            <div className="col-3 d-flex justify-content-end">
                                <p className={classes.textgray}>
                                    เปิดให้ลงทะเบียน
                                </p>
                                <Switch
                                    style={{ marginLeft: '20px' }}
                                    onChange={onSwitchChangeCanRegister}
                                    checked={canRegister}
                                    checkedChildren="เปิด"
                                    unCheckedChildren="ปิด"
                                />
                            </div>
                        </div>
                        {canRegister && (
                            <div>
                                <Divider style={{ margin: '20px 0' }} />
                                <div className="row align-items-center">
                                    <div className="col-3">
                                        <p className={classes.text}>
                                            ลงทะเบียนสาธารณะ
                                        </p>
                                    </div>
                                    <div className="col-6">
                                        <p className={classes.textgray}>
                                            {' '}
                                            ครอบคลุมแผนที่ทั่วประเทศไทย
                                        </p>
                                    </div>
                                    <div className="col-3 d-flex justify-content-end">
                                        <p className={classes.textgray}>
                                            เปิดให้ลงทะเบียน
                                        </p>
                                        <Switch
                                            style={{ marginLeft: '20px' }}
                                            onChange={
                                                onSwitchChangeisNationwide
                                            }
                                            checked={isNationwide}
                                            checkedChildren="เปิด"
                                            unCheckedChildren="ปิด"
                                        />
                                    </div>
                                </div>
                                <Divider style={{ margin: '20px 0' }} />
                                {/*  */}
                                <div className="row ">
                                    <div className="col-3">
                                        <p className={classes.text}>
                                            ลงทะเบียน
                                        </p>
                                    </div>
                                    <div className="col-6">
                                        <p className={classes.textgray}>
                                            กำหนดค่า Domain
                                            ที่อนุญาตให้สมัครสมาชิก{' '}
                                        </p>
                                    </div>
                                    <div className="col-3 d-flex justify-content-end">
                                        <p className={classes.textgray}>
                                            เปิดให้ลงทะเบียน
                                        </p>
                                        <Switch
                                            style={{ marginLeft: '34px' }}
                                            checked={isDomainRegister}
                                            onChange={onSwitchChange}
                                            checkedChildren="เปิด"
                                            unCheckedChildren="ปิด"
                                        />
                                    </div>
                                </div>
                                {isDomainRegister ? (
                                    <div>
                                        {/* <div className="row">
                      <div className="col-3"></div>
                      <div className="col-9">
                        <Divider style={{ margin: "20px 0" }} />
                      </div>
                    </div> */}
                                        <div className="row">
                                            <div className="col-3"></div>
                                            <div className="col-9">
                                                {/* <p className={classes.textgray}>
                          กำหนดค่า Domain ที่อนุญาตให้สมัครสมาชิก
                        </p> */}
                                                <p className={classes.textred}>
                                                    หมายเหตุ : ไม่สามารถใช้
                                                    Domain
                                                    ดังต่อไปนี้ในการสมัครสมาชิกได้
                                                    gmail.com, hotmail.com,
                                                    outlook.com, yahoo.com
                                                </p>
                                                <div className="row">
                                                    <div
                                                        className={`col-lg-12 ${classes.input}`}
                                                    >
                                                        {domains.map(tag => {
                                                            const isLongTag =
                                                                tag.length > 20;

                                                            const tagElem = (
                                                                <Tag
                                                                    className="edit-tag"
                                                                    key={tag}
                                                                    closable={
                                                                        true
                                                                    }
                                                                    onClose={() =>
                                                                        removeRegister(
                                                                            tag
                                                                        )
                                                                    }
                                                                    style={{
                                                                        display:
                                                                            'inline-flex',
                                                                        alignItems:
                                                                            'center',
                                                                        fontSize:
                                                                            '13px',
                                                                        height:
                                                                            '32px'
                                                                    }}
                                                                >
                                                                    <span>
                                                                        {isLongTag
                                                                            ? `${tag.slice(
                                                                                  0,
                                                                                  20
                                                                              )}...`
                                                                            : tag}
                                                                    </span>
                                                                </Tag>
                                                            );
                                                            return isLongTag ? (
                                                                <Tooltip
                                                                    title={tag}
                                                                    key={tag}
                                                                >
                                                                    {tagElem}
                                                                </Tooltip>
                                                            ) : (
                                                                tagElem
                                                            );
                                                        })}
                                                        {inputVisible && (
                                                            <Input2
                                                                ref={
                                                                    saveInputRef
                                                                }
                                                                type="text"
                                                                size="small"
                                                                className="tag-input"
                                                                value={
                                                                    inputValue
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                onBlur={
                                                                    reqDomainNames
                                                                }
                                                                onPressEnter={
                                                                    reqDomainNames
                                                                }
                                                                style={{
                                                                    width:
                                                                        '100px',
                                                                    height:
                                                                        '32px'
                                                                }}
                                                            />
                                                        )}
                                                        {!inputVisible && (
                                                            <Tag
                                                                className={`site-tag-plus ${classes.dashedBox}`}
                                                                onClick={
                                                                    showInput
                                                                }
                                                            >
                                                                <PlusOutlined
                                                                    style={{
                                                                        marginRight:
                                                                            '2px'
                                                                    }}
                                                                />
                                                                New Domain
                                                            </Tag>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                <div className="row">
                                    <div className="col-3"></div>
                                    <div className="col-9">
                                        <Divider style={{ margin: '20px 0' }} />
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-3"> </div>
                                    <div className="col-6">
                                        <p className={classes.textgray}>
                                            จำเป็นต้องระบุรหัสพนักงาน
                                        </p>
                                    </div>
                                    <div className="col-3 d-flex justify-content-end">
                                        <p className={classes.textgray}>
                                            เปิดให้ลงทะเบียน
                                        </p>

                                        <Switch
                                            disabled={
                                                !(
                                                    isDomainRegister ||
                                                    limitAreaStatus
                                                )
                                            }
                                            style={{ marginLeft: '34px' }}
                                            checked={isEmployeeIDRegister}
                                            onChange={
                                                onSwitchEmployeeIDRegister
                                            }
                                            checkedChildren="เปิด"
                                            unCheckedChildren="ปิด"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3"></div>
                                    <div className="col-9">
                                        <Divider style={{ margin: '20px 0' }} />
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-3"> </div>
                                    <div className="col-6">
                                        <p className={classes.textgray}>
                                            การลงทะเบียนจากพื้นที่ให้บริการ
                                        </p>
                                    </div>
                                    <div className="col-3 d-flex justify-content-end">
                                        <p className={classes.textgray}>
                                            เปิดจุดให้บริการ
                                        </p>
                                        <Switch
                                            style={{ marginLeft: '20px' }}
                                            onChange={
                                                onSwitchChangeisLocationLimit
                                            }
                                            checked={limitAreaStatus}
                                            checkedChildren="เปิด"
                                            unCheckedChildren="ปิด"
                                        />
                                    </div>
                                </div>

                                {isLocationLimit && limitAreaStatus ? (
                                    <SettingQrCode
                                        key={isShowModalLimitArea}
                                        limitArea={limitArea}
                                        isShowModalLimitArea={
                                            isShowModalLimitArea
                                        }
                                        setIsShowModalLimitArea={
                                            setIsShowModalLimitArea
                                        }
                                        setLimitArea={setLimitArea}
                                        onSuccess={() => {
                                            setSuccessModal(true);
                                        }}
                                    />
                                ) : null}
                            </div>
                        )}
                    </div>
                )}
            </CardBody>
            <Modal
                backdrop="static"
                size="sm"
                show={newDomainModal}
                onHide={() => {
                    setNewDomainModal(false);
                }}
                aria-labelledby="example-modal-sizes-title-lg"
                style={{ marginTop: '27px' }}
            >
                <Modal.Body className={classes.body}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '20px'
                        }}
                    >
                        <img
                            src="/media/bg/close.svg"
                            alt=""
                            style={{ width: '50%' }}
                        />
                    </div>
                    <p className={classes.divText}>กรุณากรอก domain ใหม่</p>
                    <div className={classes.errorDescroption}>
                        <label>{errorModalText}</label>
                        <label>
                            ตัวอย่าง Doamin @hytexts.com , @hibrary.co.th{' '}
                        </label>
                    </div>

                    <div style={{ textAlign: 'center' }}>
                        <button
                            className="acceptButton"
                            onClick={() => {
                                setNewDomainModal(false);
                            }}
                        >
                            ตกลง
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <SuccessModal
                show={successModal}
                onHide={() => {
                    setSuccessModal(false);
                }}
            />
        </Card>
    );
}
