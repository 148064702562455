import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { BannersLoadingDialog } from "./banners-loading-dialog/BannersLoadingDialog";
import { BannerDeleteDialog } from "./banner-delete-dialog/BannerDeleteDialog";
import { BannerBlockDialog } from "./banner-block-dialog/BannerBlockDialog";
import { BannerUnblockDialog } from "./banner-unblock-dialog/BannerUnblockDialog";
import { BannersDeleteDialog } from "./banners-delete-dialog/BannersDeleteDialog";
import { BannersFetchDialog } from "./banners-fetch-dialog/BannersFetchDialog";
import { BannersUpdateStateDialog } from "./banners-update-status-dialog/BannersUpdateStateDialog";
import { BannersUIProvider } from "./BannersUIContext";
import { BannersCard } from "./BannersCard";
import queryString from "query-string";

export function BannersPage({ history, location }) {
  const [isError, setError] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isBlock, setBlock] = useState(false);
  const [isUnblock, setUnblock] = useState(false);
  const [id, setId] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [name, setName] = useState(null);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.id) {
      setId(parsed.id);
    }
    if (parsed.delete) {
      setDelete(true);
    }
    if (parsed.block) {
      setBlock(true);
    }
    if (parsed.unblock) {
      setUnblock(true);
    }
    if (parsed.isPrivate) {
      setIsPrivate(parseInt(parsed.isPrivate, 10));
    }
  }, [location.search]);

  const bannersUIEvents = {
    newBannerButtonClick: (isPrivate) => {
      history.push(`/banner-private?isPrivate=${isPrivate}&new=true`);
    },
    openEditBannerDialog: (id, isPrivate) => {
      history.push(`/banner-private?id=${id}&isPrivate=${isPrivate}&edit=true`);
    },
    openDeleteBannerDialog: (id, isPrivate) => {
      history.push(`/banners?id=${id}&isPrivate=${isPrivate}&delete=true`);
    },
    openBlockBannerDialog: (id, isPrivate) => {
      history.push(`/banners?id=${id}&isPrivate=${isPrivate}&block=true`);
    },
    openUnblockBannerDialog: (id, isPrivate) => {
      history.push(`/banners?id=${id}&isPrivate=${isPrivate}&unblock=true`);
    },
    openDeleteBannersDialog: () => {
      history.push(`/banners/deleteBanners`);
    },
    openFetchBannersDialog: () => {
      history.push(`/banners/fetch`);
    },
    openUpdateBannersStatusDialog: () => {
      history.push("/banners/updateStatus");
    },
  };

  return (
    <BannersUIProvider bannersUIEvents={bannersUIEvents}>
      <BannersLoadingDialog />
      {isDelete ? (
        <BannerDeleteDialog
          show={true}
          id={id}
          onHide={() => {
            history.push("/banners");
            setDelete(false);
          }}
          isPrivate={isPrivate}
        />
      ) : (
        ""
      )}
      {isBlock ? (
        <BannerBlockDialog
          show={true}
          id={id}
          onHide={() => {
            history.push("/banners");
            setBlock(false);
          }}
          isPrivate={isPrivate}
        />
      ) : (
        ""
      )}
      {isUnblock ? (
        <BannerUnblockDialog
          show={true}
          id={id}
          onHide={() => {
            history.push("/banners");
            setUnblock(false);
          }}
          isPrivate={isPrivate}
        />
      ) : (
        ""
      )}
      <Route path="/banners/deleteBanners">
        {({ history, match }) => (
          <BannersDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/banners");
            }}
          />
        )}
      </Route>
      <Route path="/banners/fetch">
        {({ history, match }) => (
          <BannersFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/banners");
            }}
          />
        )}
      </Route>
      <Route path="/banners/updateStatus">
        {({ history, match }) => (
          <BannersUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/banners");
            }}
          />
        )}
      </Route>
      <BannersCard />
    </BannersUIProvider>
  );
}
