import React, { useState, useEffect } from "react";
import { BannersLoadingDialog } from "./banners-loading-dialog/BannersLoadingDialog";
import { BannerEditDialog } from "./banner-edit-dialog/BannerEditDialog";
import { BannersUIProvider } from "./BannersUIContext";
import queryString from "query-string";

export function BannerPrivatePage(props) {
  const { history, location } = props;
  const [isNew, setNew] = useState(0);
  const [isEdit, setEdit] = useState(0);
  const [id, setId] = useState(null);
  const [isPrivate, setPrivate] = useState(0);
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.new) {
      setNew(1);
    }
    if (parsed.edit) {
      setEdit(1);
    }
    if (parsed.id) {
      setId(parsed.id);
    }
    // else {
    //   setId(null);
    // }
    if (parsed.isPrivate) {
      setPrivate(parseInt(parsed.isPrivate, 10));
    }
  }, [location.search]);
  const bannersUIEvents = {
    newBannerButtonClick: (isPrivate) => {
      history.push(`/banners/${isPrivate}/new`);
    },
    openEditBannerDialog: (id, isPrivate) => {
      history.push(`/banners/${id}/${isPrivate}/edit`);
    },
    openDeleteBannerDialog: (id, isPrivate) => {
      history.push(`/banners/${id}/${isPrivate}/delete`);
    },
    openBlockBannerDialog: (id, isPrivate) => {
      history.push(`/banners/${id}/${isPrivate}/block`);
    },
    openUnblockBannerDialog: (id, isPrivate) => {
      history.push(`/banners/${id}/${isPrivate}/unblock`);
    },
    openDeleteBannersDialog: () => {
      history.push(`/banners/deleteBanners`);
    },
    openFetchBannersDialog: () => {
      history.push(`/banners/fetch`);
    },
    openUpdateBannersStatusDialog: () => {
      history.push("/banners/updateStatus");
    },
  };

  return (
    <BannersUIProvider bannersUIEvents={bannersUIEvents}>
      <BannersLoadingDialog />
      {isNew ? (
        <BannerEditDialog
          show={true}
          isPrivate={isPrivate}
          onHide={() => {
            history.push("/banners");
          }}
        />
      ) : (
        ""
      )}
      {isEdit ? (
        <BannerEditDialog
          show={true}
          id={id}
          isPrivate={isPrivate}
          onHide={() => {
            history.push("/banners");
          }}
          forEdit={true}
        />
      ) : (
        ""
      )}
    </BannersUIProvider>
  );
}
