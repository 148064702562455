import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { VideosFilter } from "./VideosFilter";
import { VideosTable } from "./videos-table/VideosTable";
import { useVideosUIContext } from "./VideosUIContext";
import { useSelector, shallowEqual } from "react-redux";

export function VideosCard({ isPrivate, isNewVideo }) {
  const videosUIContext = useVideosUIContext();
  // const [open1, setOpen1] = React.useState(false);
  // const anchorRef = React.useRef(null);
  const { role } = useSelector((state) => state.auth);
  const { currentState } = useSelector((state) => ({ currentState: state.qrcodes }), shallowEqual);
  const { entities_members } = currentState;
  const videosUIProps = useMemo(() => {
    return {
      newVideoButtonClick: videosUIContext.newVideoButtonClick,
    };
  }, [videosUIContext]);

  return (
    <Card className="cardStyle">
      <CardHeader
        title={
          <div className="card-label">
            <div>วิดีโอ</div>
          </div>
        }
      >
        <CardHeaderToolbar>
          <VideosFilter />
          <button
            type="button"
            className="button"
            onClick={videosUIProps.newVideoButtonClick}
          >
            <span>อัปโหลดวิดีโอ</span>
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody className="cardBodyStyle">
        <VideosTable isPrivate={isPrivate} isNewVideo={isNewVideo} />
      </CardBody>
    </Card>
  );
}
