import {
    osVersion,
    osName,
    mobileModel,
    browserName
} from 'react-device-detect';
import J from '../../package.json';
import { message } from 'antd';
import publicIp from 'public-ip';
import { HtiErrorResponse, Authtokendecode } from '../interface/request';
export const ip = publicIp.v4();
export default function setupAxios(
    axios: any,
    store: any,
    BASE_URL: string | undefined
) {
    axios.interceptors.request.use(
        (config: any) => {
            const {
                auth: { authToken, viewAsToken, newPasswordToken, isCuDomain }
            } = store.getState();

            if (
                config &&
                config.url &&
                config.url.substring(0, 5) === 'https'
            ) {
                // config.url = config.url;
            } else {
                config.url = BASE_URL + config.url;
                config.headers = {
                    appversion: J.version,
                    deviceuid:
                        'hibrary.admin.' + localStorage.getItem('deviceuid'),
                    devicemodel:
                        mobileModel === 'none' ? browserName : mobileModel,
                    devicename: osName,
                    osversion: osVersion,
                    appname: 'HibraryAdmin',
                    appid: isCuDomain ? 'CU.ADMIN' : 'Hibrary.ADMIN',
                    os: osName
                };
                if (authToken) {
                    config.headers.Authorization = `Bearer ${
                        viewAsToken ? viewAsToken : authToken
                    }`;
                } else if (newPasswordToken) {
                    config.headers.Authorization = `Bearer ${newPasswordToken}`;
                }
            }
            return config;
        },
        (err: any) => Promise.reject(err)
    );
    axios.interceptors.response.use(
        (response: any) => {
            return response;
        },
        (err: any) => {
            console.log('err', err);
            const {
                auth: { authToken }
            } = store.getState();

            if (err && err.response && err.response.data) {
                if (err.response.config) {
                    errorHandler(err.response.data, authToken);
                }
            } else {
                if (
                    err.response.status === 504 ||
                    err.code === 'ECONNABORTED'
                ) {
                    message.error(`A timeout happend on url ${err.config.url}`);
                }
            }
            return Promise.reject(err);
        }
    );
}

const errorHandler = (
    errorData: HtiErrorResponse,
    authToken: Authtokendecode
) => {
    // "012", "015","901","907","909" force logout
    const isForcedLogout =
        errorData.errorCode === '012' ||
        errorData.errorCode === '015' ||
        // errorData.errorCode === "901" ||
        errorData.errorCode === '907' ||
        errorData.errorCode === '909';

    const dontShow =
        errorData.errorCode === '009' ||
        errorData.errorCode === '011' ||
        errorData.errorCode === '014' ||
        errorData.errorCode === '905' ||
        errorData.errorCode === '911' ||
        errorData.errorCode === '912' ||
        errorData.errorCode === '915' ||
        errorData.errorCode === '5003' ||
        errorData.errorCode === '5004' ||
        errorData.errorCode === '5006' ||
        errorData.errorCode === '5007' ||
        errorData.errorCode === 'Error: 8002' ||
        errorData.message === 'Cannot convert undefined or null to object' ||
        errorData.message ===
            `Content Service Cannot read properties of undefined (reading 'coverImage')`;

    const undefinedClient =
        errorData.errorCode === '8000' || errorData.errorCode === '8001'
            ? true
            : false;

    var url = window.location.href;
    var arr = url.split('/');
    var result = arr[0] + '//' + arr[2];
    if (dontShow || undefinedClient || url.includes('logout')) {
    } else {
        if (
            errorData.message &&
            errorData.message !== "Cannot read property 'replace' of undefined"
        ) {
            message.error(errorData.message);
        }
        if (isForcedLogout && authToken) {
            setTimeout(() => {
                window.location.href = result + '/logout';
            }, 200);
        }
    }
};
