import axios from 'axios';
import {
   ForgotResponse,
   HtiResponse,
   LoginRequest,
   LoginResponse,
   VerifyOtpResponse
} from '../../../../interface';

const LOGIN_URL = `/auth/admin`;
const LOGOUT_URL = `/logout`;
const REGISTER_URL = '';
const REQUEST_PASSWORD_URL = '/auth/forgotPassword';
const VERIFY_OTP_URL = '/auth/verifyPasswordOTP';
const RESET_PASSWORD_URL = '/auth/changePasswordOTP';

export const ME_URL = '/api/me';

export function login(email: string, password: string, isCu: boolean) {
   const req: LoginRequest = { userID: email, password: password, isCu };
   return axios.post<HtiResponse<LoginResponse>>(LOGIN_URL, req);
}

export function viewAs(id: string) {
   return axios.get(`/superuser/clients/${id}/refactor-token`);
}

export function register(
   email: string,
   fullname: string,
   username: string,
   password: string
) {
   return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email: string) {
   return axios.post<HtiResponse<ForgotResponse>>(REQUEST_PASSWORD_URL, {
      userID: email
   });
}

export function verifyOTP(uid: string, otp: string, refCode: string) {
   return axios.post<HtiResponse<VerifyOtpResponse>>(VERIFY_OTP_URL, {
      uid: uid,
      referenceCode: refCode,
      otp: otp
   });
}

export function resetPassword(
   newPassword: string,
   uid: string,
   otp: string,
   refCode: string
) {
   return axios.patch(RESET_PASSWORD_URL, {
      password: newPassword,
      verification: { uid: uid, referenceCode: refCode, otp: otp }
   });
}

export function getUserByToken() {
   // Authorization head should be fulfilled in interceptor.
   return axios.get(ME_URL);
}

export function logout() {
   return axios.patch(LOGOUT_URL);
}
