import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/categories/categoriesActions";
import { useCategoriesUIContext } from "../CategoriesUIContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
     body: {
          textAlign: "center",
     },
}));

export function CategoryDeleteDialog({ type, name, id, catId, subcatId, isPrivate, show, onHide }) {
     // Categories UI Context
     const categoriesUIContext = useCategoriesUIContext();
     const categoriesUIProps = useMemo(() => {
          return {
               setIds: categoriesUIContext.setIds,
               queryParams: categoriesUIContext.queryParams,
               contentType: categoriesUIContext.contentType,
          };
     }, [categoriesUIContext]);
     const [isSubCat, setIsSubCat] = useState(false);

     useEffect(() => {
          if (catId && subcatId) {
               setIsSubCat(true);
          }
     }, [catId, subcatId]);

     // Categories Redux state
     const dispatch = useDispatch();
     const { isLoading } = useSelector((state) => ({ isLoading: state.categories.actionsLoading }), shallowEqual);

     // if !id we should close modal
     useEffect(() => {
          if (!id) {
               // onHide();
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [id]);

     // looking for loading/dispatch
     useEffect(() => {}, [isLoading, dispatch]);

     const deleteCategory = () => {
          // server request for deleting category by id
          dispatch(actions.deleteCategory(id, isPrivate)).then(() => {
               // refresh list after deletion
               dispatch(
                    actions.fetchCategories(
                         Object.assign({}, categoriesUIProps.queryParams, {
                              contentType: categoriesUIProps.contentType,
                         }),
                         isPrivate
                    )
               );
               // clear selections list
               categoriesUIProps.setIds([]);
               // closing delete modal
               onHide();
          });
     };

     const deleteSubCategory = () => {
          // server request for deleting category by id
          dispatch(actions.deleteSubCategory(catId, subcatId, isPrivate)).then(() => {
               // refresh list after deletion
               dispatch(
                    actions.fetchCategories(
                         Object.assign({}, categoriesUIProps.queryParams, {
                              contentType: categoriesUIProps.contentType,
                         }),
                         isPrivate,
                         catId
                    )
               );
               // closing delete modal
               onHide();
          });
     };

     const classes = useStyles();

     return (
          <Modal
               backdrop="static"
               show={show}
               onHide={onHide}
               aria-labelledby="example-modal-sizes-title-lg"
               style={{ margin: "3% auto" }}
          >
               {isLoading && <ModalProgressBar />}
               <Modal.Body className={classes.body}>
                    {name ? (
                         <>
                              <img src="/media/members/image-modal.svg" alt="" />
                              <div className="text-text-red">ยืนยันการลบ</div>
                              <div className="text-text-gray">ต้องการลบหมวดหมู่{isSubCat ? "ย่อย" : ""}</div>
                              <div className="text-text-gray">{name} หรือไม่?</div>
                              <div className="mt-3">
                                   <button type="button" onClick={onHide} className="denyButton">
                                        ยกเลิก
                                   </button>
                                   <button
                                        type="button"
                                        onClick={() => {
                                             if (isSubCat) {
                                                  deleteSubCategory();
                                             } else {
                                                  deleteCategory();
                                             }
                                        }}
                                        className="acceptButton"
                                   >
                                        ยืนยัน
                                   </button>
                              </div>
                         </>
                    ) : (
                         ""
                    )}
               </Modal.Body>
          </Modal>
     );
}
