import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./ChannelsUIHelpers";

const ChannelsUIContext = createContext();

export function useChannelsUIContext() {
  return useContext(ChannelsUIContext);
}

export const ChannelsUIConsumer = ChannelsUIContext.Consumer;

export function ChannelsUIProvider({ channelsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initChannel = {
    uid: undefined,
    name: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initChannel,
    newChannelButtonClick: channelsUIEvents.newChannelButtonClick,
    newCreatorButtonClick: channelsUIEvents.newCreatorButtonClick,
    openEditChannelDialog: channelsUIEvents.openEditChannelDialog,
    openEditCreatorDialog: channelsUIEvents.openEditCreatorDialog,
    openViewChannelDialog: channelsUIEvents.openViewChannelDialog,
    openDeleteCreatorDialog: channelsUIEvents.openDeleteCreatorDialog,
    openDeleteChannelDialog: channelsUIEvents.openDeleteChannelDialog,
    openBlockCreatorDialog: channelsUIEvents.openBlockCreatorDialog,
    openBlockChannelDialog: channelsUIEvents.openBlockChannelDialog,
    openUnblockCreatorDialog: channelsUIEvents.openUnblockCreatorDialog,
    openUnblockChannelDialog: channelsUIEvents.openUnblockChannelDialog,
    openFetchChannelsDialog: channelsUIEvents.openFetchChannelsDialog,
    openUpdateChannelsStatusDialog: channelsUIEvents.openUpdateChannelsStatusDialog,
    openCreatorsPage: channelsUIEvents.openCreatorsPage,
    openCreatorBooksPage: channelsUIEvents.openCreatorBooksPage,
  };

  return <ChannelsUIContext.Provider value={value}>{children}</ChannelsUIContext.Provider>;
}
