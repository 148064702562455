import moment from "moment-timezone";

export function DateColumnFormatter(cellContent, row, rowIndex) {
  return (
    <div>
      <div>
        {cellContent ? moment.unix(cellContent).format("DD/MM/YYYY") : "-"}
      </div>
      <div className="error-text">
        {cellContent ? moment.unix(cellContent).format("HH:mm:ss") : ""}
      </div>
    </div>
  );
}
