export interface PrivateContentResponse {
  status: boolean;
  data: PrivateContentData;
}

export interface PrivateContentData {
  contents: PrivateContent[];
}

export interface PrivateContent {
  contentUID: string;
  creatorUID: string;
  title: string;
  status: number;
  registerDate: number;
  publisher: string;
  authorName: string;
  coverImage: string;
  fileType: string;
  waitingAmount: number;
  rentAmount: number;
  concurrent?: number;
  activeLicense?: number;
}
export interface ContentResponse {
  status: boolean;
  data: ContentData;
}

export interface ContentData {
  contents: Content[];
  LastEvaluatedKey?: LastEvaluatedKey_Content | string;
  totalAmount?: number;
}

export interface LastEvaluatedKey_Content {
  sortKey: string;
  contentUID: string;
  clientUID: string;
}

export interface Content {
  contentUID: string;
  creatorUID: string;
  title: string;
  status: number;
  registerDate: number;
  publisher: string;
  authorName: string;
  coverImage: string;
  fileType: string;
  waitingAmount: number;
  rentAmount: number;
  startDate: number;
  expireDate: number;
  concurrent: number;
  activeLicense: number;
}

export interface CreateContentPayload {
  contentUID: string;
  title: string;
  creatorUID: string;
  writerUID: string;
  ISBN: string;
  eISBN: string;
  pageCount: number;
  catId: string;
  subcatId: string;
  startDate: number;
  expireDate: number;
  concurrent: number;
  tags: string[];
  language: string;
  rentalPeriod: number;
  anonymousRentalPeriod: number;
  description: string;
  contentType: string;
  duration: string;
  length: number;
  dimension: Dimension;
}

export interface Dimension {
  width: number;
  height: number;
}
export interface UpdateContentPayload {
  title?: string;
  creatorUID?: string;
  writerUID?: string;
  ISBN?: string;
  eISBN?: string;
  pageCount?: number;
  catId?: string;
  subcatId?: string;
  startDate?: number;
  expireDate?: number;
  status?: number;
  concurrent?: number;
  tags?: string[];
  rentalPeriod?: number;
  anonymousRentalPeriod?: number;
  description?: string;
  coverImage?: boolean;
  rawContent?: boolean;
  language?: string;
  contentType?: string;
  authorName?: string;
  publisherName?: string;
  activeLicense?: string;
}
export interface ContentById {
  startDate: number;
  contentUID: string;
  expireDate: number;
  activeLicense: number;
  concurrent: number;
  status: number;
  waitingAmount: number;
  rentAmount: number;
  fileSize: number;
  pageCount: number;
  description: string;
  creatorUID: string;
  title: string;
  publisherName: string;
  catName: string;
  subcatName: string;
  writerID: number;
  authorName: string;
  coverImage: string;
  fileType: string;
}
export interface FreeContentData {
  contents: FreeContent[];
  LastEvaluatedKey: LastEvaluatedKey_FreeContent;
  totalAmount?: number;
}

export interface FreeContent {
  contentUID: string;
  activeLicense: number;
  concurrent: number;
  status: number;
  creatorUID: string;
  title: string;
  authorName: string;
  coverImage: string;
  fileType: string;
  waitingAmount: number;
  rentAmount: number;
}
export interface deletePayload {
  forceReturn: boolean;
}

export interface LastEvaluatedKey_FreeContent {
  saleDate: number;
  bookUID: string;
  priceTHB: number;
}

export interface contentForEdit {
  registerDate: number;
  ISBN: string;
  status: number;
  contentType: string;
  subcatId: string;
  startDate: number;
  concurrent: number;
  eISBN: string;
  creatorUID: string;
  tags: any[];
  rentalPeriod: number;
  catId: string;
  clientUID: string;
  contentUID: string;
  expireDate: number;
  pageCount: number;
  fileType: string;
  activeLicense: number;
  description: string;
  title: string;
  writerUID: string;
  file: File;
}

export interface File {
  content: File_Content;
  coverImage: File_Content;
}
export interface File_Content {
  fileName: string;
  updatedDate: number;
  size: number;
}

export interface HtiContentJobResponse {
  listJob: ListJob[];
  lastEvaluatedKey: LastEvaluatedKeyContentJob;
}

export interface LastEvaluatedKeyContentJob {
  reportUID: string;
  clientUID: string;
}

export interface ListJob {
  clientUID: string;
  reportUID: string;
  memberUID: string;
  memberName: string;
  registerDate: number;
  status: number;
}

export enum ListJobStatus {
  Process = 0,
  Success = 1,
  SomethingWrong = 2,
}

export interface getUploadedFileContentToStore {
  uploadFile: string;
}
