/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/channels/channelsActions";
import { ChannelNewForm } from "./ChannelNewForm";
import { useChannelsUIContext } from "../ChannelsUIContext";

export function ChannelNewDialog({
  name,
  isPrivate,
  show,
  onHide,
  onSuccess,
  isCreator,
  history,
  notOrigin,
}) {
  // Channels UI Context
  const channelsUIContext = useChannelsUIContext();
  const channelsUIProps = useMemo(() => {
    return {
      initChannel: {
        uid: undefined,
        name: "",
      },
    };
  }, [channelsUIContext]);

  // Channels Redux state
  const dispatch = useDispatch();
  const { actionsLoading, entities, error } = useSelector(
    (state) => ({
      actionsLoading: state.channels.actionsLoading,
      entities: state.channels.entities,
      error: state.channels.error,
    }),
    shallowEqual
  );

  const [isDuplicate, setDuplicate] = useState(false);
  const [chId, setChId] = useState(null);
  const [chName, setChName] = useState(null);
  const [contentUIDToUpload, setContentUIDToUpload] = useState(undefined);
  const [description, setDescription] = useState("");
  const [coverName, setCoverName] = useState(undefined);

  useEffect(() => {
    if (error && error.errorCode === "014" && !isDuplicate) {
      setDuplicate(true);
    }
  }, [error]);

  useEffect(() => {
    if (chId && chName) {
      createCreator({ name: chName }, chId, chName);
    }
  }, [chId, chName]);

  const createChannel = (channel) => {
    const newOne = {
      name: channel.name,
      uid: contentUIDToUpload,
      description: description !== "" ? description : undefined,
      image: coverName,
    };
    dispatch(
      actions.createChannel(newOne, isPrivate, undefined, onSuccess, setChName, setChId)
    ).then((val) => {
      if (notOrigin && val !== "error") {
        onHide();
      }
    });
  };

  const createCreator = (creator, channelId, channelName) => {
    const newOne = { name: creator.name };
    dispatch(
      actions.createCreator(newOne, isPrivate, channelId, channelName, history, onSuccess)
    ).then((val) => {
      setChId(null);
      setChName(null);
      if (
        notOrigin &&
        channelId &&
        val !== "error"
        // ||
        // (notOrigin === undefined && channelId && val === undefined)
      ) {
        onHide(); //onHide of Upload
      }
    });
  };

  return (
    <Modal
      backdrop="static"
      size="md"
      show={show}
      onHide={onHide}
      style={{ marginTop: "27px" }}
      aria-labelledby="example-modal-sizes-title-md"
    >
      <ChannelNewForm
        createChannel={createChannel}
        createCreator={createCreator}
        actionsLoading={actionsLoading}
        channel={channelsUIProps.initChannel}
        onHide={onHide}
        isCreator={isCreator}
        channelList={entities}
        isDuplicate={isDuplicate}
        setDuplicate={setDuplicate}
        contentUIDToUpload={contentUIDToUpload}
        setContentUIDToUpload={setContentUIDToUpload}
        description={description}
        setDescription={setDescription}
        coverName={coverName}
        setCoverName={setCoverName}
      />
    </Modal>
  );
}
