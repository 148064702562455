import * as requestFromServer from './catalogueCrud';
import { catalogueSlice, callTypes } from './catalogueSlice';
const { actions, state } = catalogueSlice;

export const fetchAllContentAmount = () => dispatch => {
   return requestFromServer
      .findAllContentAmount()
      .then(response => {
         if (response.data.data) {
            const { amount } = response.data.data;
            dispatch(
               actions.allContentAmountFetched({
                  catalogue_contentAmount: amount
               })
            );
         }
      })
      .catch(error => {
         if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errorCode === '012'
         ) {
            alert('กรุณาเข้าสู่ระบบอีกครั้ง เนื่องจากระยะเวลาการใช้งานหมดอายุ');
            window.location.href = '/logout';
         }
         dispatch(
            actions.allContentAmountFetched({
               catalogue_contentAmount: undefined
            })
         );
      });
};

export const fetchCatalogues = (
   queryParams,
   type,
   lastEvaluate,
   catalogId
) => dispatch => {
   dispatch(actions.startCall({ callType: callTypes.list }));
   return requestFromServer
      .findCatalogues(queryParams, type, lastEvaluate)
      .then(response => {
         const {
            LastEvaluatedKey,
            catalogs,
            publishers,
            categories,
            totalAmount,
            remainConcurrent
         } = response.data.data;
         const entities =
            type === 'categories'
               ? categories
               : type === 'publishers'
               ? publishers
               : catalogs;
         dispatch(
            actions.cataloguesFetched({
               totalCount: totalAmount,
               entities,
               LastEvaluatedKey,
               lastEvaluate,
               type,
               memberMode: queryParams.memberMode,
               remainConcurrent
            })
         );
      })
      .catch(error => {
         if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errorCode === '012'
         ) {
            alert('กรุณาเข้าสู่ระบบอีกครั้ง เนื่องจากระยะเวลาการใช้งานหมดอายุ');
            window.location.href = '/logout';
         }
         dispatch(
            actions.cataloguesFetched({
               totalCount: 0,
               entities: [],
               LastEvaluatedKey: {}
            })
         );
         error.clientMessage = "Can't find catalogue";
         dispatch(actions.catchError({ error, callType: callTypes.list }));
      });
};

export const fetchCatalogue = (
   queryParams,
   id,
   lastEvaluated,
   setLoadingBottom,
   isCatalogueDetail,
   catalogueEbooks
) => dispatch => {
   if (!id) {
      return dispatch(
         actions.catalogueFetched({ catalogueForEdit: undefined })
      );
   }
   dispatch(actions.startCall({ callType: callTypes.action_catalogueForEdit }));
   return requestFromServer
      .getCatalogueById(queryParams, id, lastEvaluated, isCatalogueDetail)
      .then(response => {
         if (response.data.status && response.data.data) {
            const catalogue = response.data.data;
            const { lastEvaluatedKey } = catalogue;
            dispatch(
               actions.catalogueFetched({
                  catalogueForEdit: catalogue,
                  catalogueEbooks,
                  memberMode: queryParams.memberMode,
                  lastEvaluated_catalogueForEdit: lastEvaluated,
                  LastEvaluatedKey_catalogueForEdit: lastEvaluatedKey
               })
            );
            if (setLoadingBottom) {
               setLoadingBottom(false);
            }
         }
      })
      .catch(error => {
         error.clientMessage = "Can't find catalogue";
         dispatch(
            actions.catchError({
               error,
               callType: callTypes.action_catalogueForEdit
            })
         );
      });
};

export const fetchPublisherContents = (
   id,
   publisherId,
   lastEvaluated,
   setLoadingBottom
) => dispatch => {
   if (!id) {
      return dispatch(
         actions.cataloguesFetched({
            publisherContents: undefined,
            publisherName: undefined,
            contentAmount: undefined
         })
      );
   }

   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .getPublisherContentsById(id, publisherId, lastEvaluated)
      .then(response => {
         if (response.data.status && response.data.data) {
            const {
               contents,
               LastEvaluatedKey,
               publisherName,
               contentAmount
            } = response.data.data;
            dispatch(
               actions.contentsFetched({
                  publisherContents: contents,
                  publisherName,
                  contentAmount,
                  lastEvaluated_publisherContents: lastEvaluated,
                  LastEvaluatedKey_publisherContents: LastEvaluatedKey,
                  type: 'publisher'
               })
            );
            if (setLoadingBottom) {
               setLoadingBottom(false);
            }
         }
      })
      .catch(error => {
         error.clientMessage = "Can't find catalogue";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const fetchNewEbooksContents = (
   id,
   lastEvaluated,
   setLoadingBottom
) => dispatch => {
   if (!id) {
      return dispatch(
         actions.cataloguesFetched({
            newEbooksContents: undefined
         })
      );
   }

   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .getNewEbooksContentsById(id, lastEvaluated)
      .then(response => {
         if (response.data.status && response.data.data) {
            const { contents, LastEvaluatedKey } = response.data.data;
            dispatch(
               actions.contentsFetched({
                  newEbooksContents: contents,
                  lastEvaluated_newEbooksContents: lastEvaluated,
                  LastEvaluatedKey_newEbooksContents: LastEvaluatedKey,
                  type: 'newEbooks'
               })
            );
            if (setLoadingBottom) {
               setLoadingBottom(false);
            }
         }
      })
      .catch(error => {
         error.clientMessage = "Can't find catalogue";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const fetchCategoryContents = (
   id,
   catId,
   lastEvaluated,
   setLoadingBottom,
   subcatId
) => dispatch => {
   if (!id) {
      return dispatch(
         actions.cataloguesFetched({
            categoryContents: undefined,
            catName: undefined,
            contentAmount: undefined
         })
      );
   }

   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .getCategoryContentsById(id, catId, lastEvaluated, subcatId)
      .then(response => {
         if (response.data.status && response.data.data) {
            const {
               contents,
               LastEvaluatedKey,
               catName,
               subcats,
               subcatName,
               contentAmount
            } = response.data.data;
            dispatch(
               actions.contentsFetched({
                  categoryContents: contents,
                  catName,
                  lastEvaluated_categoryContents: lastEvaluated,
                  LastEvaluatedKey_categoryContents: LastEvaluatedKey,
                  subcats,
                  subcatName,
                  contentAmount,
                  type: 'category'
               })
            );
            if (setLoadingBottom) {
               setLoadingBottom(false);
            }
         }
      })
      .catch(error => {
         error.clientMessage = "Can't find catalogue";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const deleteCatalogue = id => dispatch => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .deleteCatalogue(id)
      .then(response => {
         if (response.data.status) {
            dispatch(actions.catalogueDeleted({ id }));
         }
      })
      .catch(error => {
         error.clientMessage = "Can't delete catalogue";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const blockCatalogue = (id, isPrivate, isFree) => dispatch => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .blockCatalogue(id, isPrivate, isFree)
      .then(response => {
         if (response.data.status) {
            dispatch(actions.catalogueBlocked({ id }));
         }
      })
      .catch(error => {
         error.clientMessage = "Can't block catalogue";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const unblockCatalogue = (id, isPrivate, isFree) => dispatch => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .unblockCatalogue(id, isPrivate, isFree)
      .then(response => {
         if (response.data.status) {
            dispatch(actions.catalogueUnblocked({ id }));
         }
      })
      .catch(error => {
         error.clientMessage = "Can't unblock catalogue";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const createCatalogue = (newValues, setSuccessModal) => dispatch => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer.createCatalogue(newValues).then(res => {
      if (res.data.status) {
         dispatch(actions.elementUpdated());
         if (setSuccessModal) {
            setSuccessModal(true);
         }
      }
   });
};

export const updateCatalogue = (
   newValues,
   contentUID,
   isNew,
   setSuccessModal,
   originValues
) => dispatch => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .updateCatalogue(newValues, contentUID, isNew)
      .then(res => {
         if (res.data.status && res.data.data) {
            dispatch(
               actions.catalogueUpdated({
                  id: contentUID,
                  catalogue: Object.assign(
                     {},
                     originValues,
                     newValues,
                     res.data.data,
                     {
                        concurrent:
                           newValues.concurrent === 0
                              ? 'unlimited'
                              : newValues.concurrent
                     }
                  )
               })
            );
            if (setSuccessModal) {
               setSuccessModal(true);
            }
         }
      });
};

export const clearContents = () => dispatch => {
   dispatch(actions.clearContents());
};
// export const searchCatalog =(
//   queryParams ,
//   lastEvaluate
// )=> dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer.searchCatalog(queryParams,lastEvaluate).then((res) =>console.log(res))

// }
export const searchCatalog = (
   queryParams,
   id,
   lastEvaluate,
   setLoadingBottom
) => dispatch => {
   dispatch(actions.startCall({ callType: callTypes.action }));

   return requestFromServer
      .searchCatalog(queryParams, id, lastEvaluate)
      .then(response => {
         if (response !== null) {
            const { contents, lastEvaluatedKey } = response.data.data;

            dispatch(
               actions.searched({
                  LastEvaluatedKey_search: lastEvaluatedKey,
                  contents
               })
            );
            if (setLoadingBottom) {
               setLoadingBottom(true);
            }
         }
      });
};

export const resetSearch = () => dispatch => {
   dispatch(actions.resetSearch());
};

export const setVoteStatusAction = voteStatus => dispatch => {
   dispatch(
      actions.setVoteStatus({
         voteStatus
      })
   );
};
