import React, { useState, useEffect } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import SVG from 'react-inlinesvg';

import { Modal, Spinner } from 'react-bootstrap';
import { Tabs, Dropdown, Menu, Button, Divider, Skeleton } from 'antd';
import {
    Card,
    CardHeader,
    CardHeaderToolbar,
    CardBody
} from '../../../../../_metronic/_partials/controls/Card';

import { DownOutlined, CaretDownOutlined } from '@ant-design/icons';
import BootstrapTable from 'react-bootstrap-table-next';
import { makeStyles } from '@material-ui/core/styles';
import {
    NodataView,
    LoaderView,
    Modals,
    ModalsSuccess
} from '../../../CustomView';
import * as util from './CheckEbookFunc';
import './checkebook.scss';
const { TabPane } = Tabs;

export function CheckEbookCatagory({ history, location }) {
    const parsed = queryString.parse(location.search);

    const [activeTab, setActiveTab] = useState('1');
    const [firstLoad, setFirstLoad] = useState(true);
    const [search, setSearch] = useState('');
    const [sortBy, setSortBy] = useState('sort');
    const [isSearch, setIsSearch] = useState(false);

    const [loadingDetail, setLoadingAwait] = useState(false);
    const [loadingApprove, setLoadingApprove] = useState(false);

    const [catalogSummary, setCatalogSummary] = useState();
    const [catalogAwait, setCatalogAwait] = useState([]);
    const [catalogApprove, setCatalogApprove] = useState([]);
    const [filterCatalogAwait, setFilterCatalogAwait] = useState([]);
    const [filterCatalogApprove, setFilterCatalogApprove] = useState([]);

    const [confirmStatus, setConfirmStatus] = useState();
    const [contentUID, setcontentUID] = useState();
    const [contentvalue, setContentvalue] = useState();
    const [rejectMessages, setrejectMessages] = useState();
    const [contentStatus, setContentStatus] = useState();

    const getContentStatus = async () => {
        try {
            const req = await axios.get(
                `/superuser/catalogs/${parsed.id}/contents/status?clientUID=${parsed.clientUID}`
            );
            const { data } = req;
            if (data.status && data.data) {
                let status = data.data;
                setContentStatus(status.status);
            }
        } catch (error) {}
    };

    const getCatalogSummary = async () => {
        try {
            if (parsed.subcategoryId === undefined) {
                var qString = {
                    clientUID: parsed.clientUID
                };
                var path = `/superuser/catalogs/${parsed.id}/categories/${parsed.categoryId}/summary`;
                const req = await axios.get(
                    queryString.stringifyUrl({
                        url: path,
                        query: qString
                    })
                );
                const { data } = req;
                if (data.status && data.data) {
                    let catalogs = data.data;
                    setCatalogSummary(catalogs);
                }
            } else {
                var qString = {
                    clientUID: parsed.clientUID,
                    subCategoryID: parsed.subcategoryId
                };
                var path = `/superuser/catalogs/${parsed.id}/categories/${parsed.categoryId}/summary`;
                const req = await axios.get(
                    queryString.stringifyUrl({
                        url: path,
                        query: qString
                    })
                );
                const { data } = req;
                if (data.status && data.data) {
                    let catalogs = data.data;
                    setCatalogSummary(catalogs);
                }
            }
        } catch (error) {}
    };

    const getCatalogContent = async status => {
        try {
            setLoadingAwait(true);
            setLoadingApprove(true);
            if (parsed.subcategoryId === undefined) {
                var qString = { clientUID: parsed.clientUID, status: status };
                var path = `/superuser/catalogs/${parsed.id}/categories/${parsed.categoryId}`;
                const req = await axios.get(
                    queryString.stringifyUrl({
                        url: path,
                        query: qString
                    })
                );
                const { data } = req;
                if (data.status && data.data) {
                    const { contents } = data.data;
                    if (status === 0) {
                        setLoadingAwait(false);
                        setCatalogAwait(contents);
                    } else if (status === 1) {
                        setLoadingApprove(false);
                        setCatalogApprove(contents);
                    }
                }
            } else {
                var qString = {
                    clientUID: parsed.clientUID,
                    status: status,
                    subCategoryID: parsed.subcategoryId
                };
                var path = `/superuser/catalogs/${parsed.id}/categories/${parsed.categoryId}`;
                const req = await axios.get(
                    queryString.stringifyUrl({
                        url: path,
                        query: qString
                    })
                );
                const { data } = req;
                if (data.status && data.data) {
                    const { contents } = data.data;
                    if (status === 0) {
                        setLoadingAwait(false);
                        setCatalogAwait(contents);
                    } else if (status === 1) {
                        setLoadingApprove(false);
                        setCatalogApprove(contents);
                    }
                }
            }
            setLoadingAwait(false);
            setLoadingApprove(false);
        } catch (error) {
            if (status === 0) {
                setLoadingAwait(false);
            } else if (status === 1) {
                setLoadingApprove(false);
            }
        }
    };
    const forceReload = tab => {
        if (tab === 1) {
            setLoadingAwait(true);
            getCatalogContent(0);
        } else if (tab === 2) {
            setLoadingApprove(true);
            getCatalogContent(1);
        }
    };
    const dropdownBtn = values => (
        <Menu>
            {util.sortWordingValue?.map((e, i) => {
                return (
                    <Menu.Item key={e.value} onClick={() => setSortBy(e.value)}>
                        <span className="dropdown-text no-pic">{e.title}</span>
                    </Menu.Item>
                );
            })}
        </Menu>
    );

    /* Dropdown for ebooks in tbl */
    const dropdownStatusBtn = row => (
        <Menu>
            {contentStatus?.map((e, i) => {
                return (
                    <Menu.Item
                        key={i}
                        onClick={() => handleDropdownStatusClick(row, e)}
                    >
                        {e.title}
                    </Menu.Item>
                );
            })}
        </Menu>
    );
    const handleDropdownStatusClick = (row, e) => {
        console.log('handleDropdownStatusClick', row, e);
        if (row !== null) {
            setConfirmStatus(true);
            setcontentUID(row.uid);
            setContentvalue(e.value);
            setrejectMessages(e.rejectMessages);
        }
    };
    const postStatus = async () => {
        try {
            const req = await axios.post(
                `/superuser/catalogs/${parsed.id}/contents/approved`,
                {
                    clientUID: parsed.clientUID,
                    payload: [
                        {
                            contentUID: contentUID,
                            status: contentvalue,
                            rejectStatus: rejectMessages
                        }
                    ]
                }
            );
            if (req) {
                setConfirmStatus(false);

                // reload
                getCatalogSummary();
                forceReload(1);
                forceReload(2);
            }
        } catch (error) {
            setLoadingAwait(false);
            setLoadingApprove(false);
        }
    };
    /* End Dropdown for ebooks in tbl */

    const columns = [
        {
            dataField: '',
            text: 'ลำดับ',
            formatter: util.IndexFormatter,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'coverImage',
            text: 'ปก',
            formatter: util.CoverImageFormatter,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'none',
            text: 'รายละเอียด',

            formatter: util.DetailContentFormatter,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'lifttimePurchase',
            text: 'ขายต่อเนื่อง',
            formatter: util.IsLifttimePurchaseFormatter,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'concurrent',
            text: 'จำนวนสิทธิ์',
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'priceTHB',
            text: 'ราคาขาย',
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'totalPrice',
            text: 'ราคารวม',
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'status',
            text: 'ตรวจสอบสิทธิ์',
            formatter: util.ActionContentFormatter,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            },
            formatExtraData: {
                isHistory:
                    catalogSummary && catalogSummary.catalogStatus !== 3
                        ? false
                        : true,
                dropdownStatusBtn,
                contentStatus
            }
        }
    ];

    const Summaryebook = () => {
        return (
            <div>
                <span className="mr-5">
                    <span className="text text-gray-drak">จำนวนอีบุ๊ก : </span>
                    <span className="text text-blue bold">
                        {catalogSummary?.contentsAmount
                            ? catalogSummary?.contentsAmount
                            : 0}
                    </span>
                </span>
                <span className="mr-5">
                    <span className="text text-gray-drak ">จำนวนสิทธิ์ : </span>
                    <span className="text text-blue bold">
                        {catalogSummary?.concurrent
                            ? catalogSummary?.concurrent
                            : 0}
                    </span>
                </span>

                <span className="text text-sky-blue">
                    ราคาทั้งหมด :{' '}
                    {catalogSummary?.totalPrice
                        ? catalogSummary?.totalPrice
                        : '0.00'}
                </span>
            </div>
        );
    };

    const menusubcat = (
        <Menu>
            <Menu.Item key="1">เรื่องสั้น / เรื่องเล่า</Menu.Item>
            <Menu.Item key="2">ความรัก / โรแมนติก</Menu.Item>
        </Menu>
    );

    const ToolBar = () => {
        return (
            <>
                <input
                    type="text"
                    className="form-control"
                    name="searchText"
                    autoFocus="autoFocus"
                    placeholder="ค้นหา"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            setIsSearch(true);
                            if (activeTab === '1') {
                                onSearchCatalogAwait();
                            } else {
                                onSearchCatalogApprove();
                            }
                        }
                    }}
                />
                {isSearch ? (
                    <SVG
                        src="/media/members/reset-search.svg"
                        className="icon-search"
                        onClick={() => {
                            setIsSearch(false);
                            setSearch('');
                            forceReload(parseInt(activeTab));
                        }}
                    />
                ) : (
                    <SVG
                        src="/media/members/search-icon.svg"
                        alt=""
                        className="icon-search"
                        onClick={() => {
                            setIsSearch(true);
                            if (activeTab === '1') {
                                onSearchCatalogAwait();
                            } else {
                                onSearchCatalogApprove();
                            }
                        }}
                    />
                )}

                <Dropdown
                    overlay={() => dropdownBtn()}
                    trigger={['click']}
                    placement="bottomLeft"
                >
                    <button type="button" className="button-border">
                        <span>{`${util.sortWording(sortBy)}`}</span>
                    </button>
                </Dropdown>
            </>
        );
    };
    const handleTab = tab => {
        let chk = parseInt(tab);
        if (chk === 1) {
            if (catalogAwait.length === 0) {
                forceReload(chk);
            }
        } else if (chk === 2) {
            if (catalogApprove.length === 0) {
                forceReload(chk);
            }
        }
    };

    const onSearchCatalogAwait = () => {
        if (Array.isArray(catalogAwait)) {
            setFilterCatalogAwait(
                Object.values(catalogAwait)
                    .filter(el =>
                        el.title.toLowerCase().includes(search.toLowerCase())
                    )
                    .sort(util.sortByFunc(sortBy))
            );
        }
    };

    const onSearchCatalogApprove = () => {
        if (Array.isArray(catalogApprove)) {
            setFilterCatalogApprove(
                Object.values(catalogApprove)
                    .filter(el =>
                        el.title.toLowerCase().includes(search.toLowerCase())
                    )
                    .sort(util.sortByFunc(sortBy))
            );
        }
    };

    useEffect(() => {
        console.log('effect1');
        getContentStatus();
        getCatalogSummary();
        handleTab(1);
    }, []);

    useEffect(() => {
        console.log('effect2', catalogAwait);
        setFilterCatalogAwait(
            Object.values(catalogAwait)
                .filter(el =>
                    el.title.toLowerCase().includes(search?.toLowerCase())
                )
                .sort(util.sortByFunc(sortBy))
        );
    }, [catalogAwait]);

    useEffect(() => {
        console.log('effect3');
        setFilterCatalogApprove(
            Object.values(catalogApprove)
                .filter(el =>
                    el.title.toLowerCase().includes(search.toLowerCase())
                )
                .sort(util.sortByFunc(sortBy))
        );
    }, [catalogApprove]);

    useEffect(() => {
        console.log('effect4');
        onSearchCatalogAwait();
        onSearchCatalogApprove();
    }, [sortBy]);

    useEffect(() => {
        console.log('effect5');
        if (firstLoad && catalogSummary) {
            if (
                catalogSummary.processingAmount === 0 &&
                catalogSummary.passedAmount > 0
            ) {
                setActiveTab(2);
                handleTab(2);
            } else {
                handleTab(1);
            }
            setFirstLoad(false);
        }
    }, [catalogSummary]);

    const summarydetail = <Summaryebook />;
    return (
        <div id="CheckEbookCategoryPage" className="checkebook">
            <Card className="cardStyle">
                <CardHeader
                    title={
                        catalogSummary ? (
                            <div className="card-label">
                                <div>{parsed.subtitle}</div>
                            </div>
                        ) : (
                            // </Dropdown>
                            <Skeleton paragraph={{ rows: 1 }} active />
                        )
                    }
                >
                    <CardHeaderToolbar>
                        <ToolBar />
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody className="cardBodyStyle">
                    <Tabs
                        // defaultActiveKey="1"
                        activeKey={`${activeTab}`}
                        className="tab-custom"
                        tabBarExtraContent={summarydetail}
                        onChange={e => {
                            setActiveTab(e);
                            handleTab(e);
                        }}
                    >
                        <TabPane
                            tab={`รอตรวจสอบ (${
                                catalogSummary?.processingAmount
                                    ? catalogSummary?.processingAmount
                                    : 0
                            })`}
                            key="1"
                        >
                            {loadingDetail ? (
                                <LoaderView></LoaderView>
                            ) : filterCatalogAwait &&
                              filterCatalogAwait.length === 0 ? (
                                <NodataView></NodataView>
                            ) : (
                                <div className="CheckEbookDetailTable">
                                    <BootstrapTable
                                        wrapperClasses="table-responsive"
                                        bordered={false}
                                        classes="table table-head-custom table-vertical-center custom-table-scroll"
                                        id="custom-table-scroll"
                                        bootstrap4
                                        remote
                                        keyField="id"
                                        data={filterCatalogAwait}
                                        columns={columns}
                                    ></BootstrapTable>
                                </div>
                            )}
                        </TabPane>
                        <TabPane
                            tab={`ตรวจสอบแล้ว (${
                                catalogSummary?.passedAmount
                                    ? catalogSummary?.passedAmount
                                    : 0
                            })`}
                            key="2"
                        >
                            {loadingApprove ? (
                                <LoaderView></LoaderView>
                            ) : filterCatalogApprove &&
                              filterCatalogApprove.length === 0 ? (
                                <NodataView></NodataView>
                            ) : (
                                <div className="CheckEbookDetailTable">
                                    <BootstrapTable
                                        wrapperClasses="table-responsive"
                                        bordered={false}
                                        classes="table table-head-custom table-vertical-center custom-table-scroll"
                                        id="custom-table-scroll"
                                        bootstrap4
                                        remote
                                        keyField="id"
                                        data={filterCatalogApprove}
                                        columns={columns}
                                    ></BootstrapTable>
                                </div>
                            )}
                        </TabPane>
                    </Tabs>
                </CardBody>
            </Card>
            <Modals
                title="ยืนยันการเปลี่ยนแปลง ?"
                show={confirmStatus}
                onHide={() => setConfirmStatus(false)}
                onClickAccept={e => postStatus(e)}
                onClickDeny={() => setConfirmStatus(false)}
            />
        </div>
    );
}
