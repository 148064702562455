import axios from 'axios';
import { HtiResponse } from '../../interface';

export enum SellerEmailAction {
   add = 'ADD',
   update = 'UPDATE',
   delete = 'DELETE'
}

export interface EmailPublisherResponse {
   publishers: EmailPublisherType[];
}

export interface EmailPublisherType {
   publisherName: string;
   publisherUID: string;
   sellerEmail: SellerEmailType[];
}

export interface SellerEmailType {
   email: string;
   uniqueUID: string;
   action?: SellerEmailAction;
   newEmail?: boolean;
}

export interface VendorsEmail {
   publisherUID: string;
   sellerEmail: SellerEmailType[];
}

export interface SendEmailAllVendor {
   clientUID: string;
   payDeadLine?: number;
   startDate: number;
   publishers: VendorsEmail[];
}

export const getAllEmailPublisher = async (
   catalogUID: string,
   clientUID: string
) => {
   const path = `/superuser/catalogs/listEmail/${catalogUID}/client/${clientUID}`;
   const {
      data: { data }
   } = await axios.get<HtiResponse<EmailPublisherResponse>>(path);
   return data;
};

export const updateEmailSeller = async (
   publisherUID: string,
   email: SellerEmailType
) => {
   const path = `/superuser/catalogs/updateEmail/publisher/${publisherUID}`;

   const {
      data: { data }
   } = await axios.post<HtiResponse>(path, email);
   return data;
};

export const sendReportToAllVendors = async (
   catalogUID: string,
   publisher: SendEmailAllVendor
) => {
   const path = `/superuser/catalogs/${catalogUID}/allVendors`;
   const {
      data: { data }
   } = await axios.post(path, publisher);
   return data;
};

export const sendAllLicenseToAllVendors = async (
   catalogUID: string,
   publisher: SendEmailAllVendor
) => {
   const path = `/superuser/catalogs/${catalogUID}/allLicenses`;
   const {
      data: { data }
   } = await axios.post(path, publisher);
   return data;
};
