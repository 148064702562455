import moment from 'moment';
import { Moment } from 'moment-timezone';
import { Tag } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { log } from 'console';

export const disabledDateBeforeStart = (
   current: Moment,
   startDate: number,
   expireDate?: number
) => {
   if (startDate && current < moment.unix(startDate)) {
      return true;
   } else if (expireDate && current > moment.unix(expireDate)) {
      return true;
   } else {
      return false;
   }
};

export const disabledDateAfterExpire = (current: Moment, unixDate: number) => {
   if (unixDate && current > moment.unix(unixDate)) {
      return true;
   } else {
      return false;
   }
};

export const disabledDateOnDateNow = (current: Moment, endDate: number) => {
   if (
      (!endDate && current < moment().startOf('date')) ||
      (endDate && current < moment.unix(endDate))
   ) {
      return false;
   } else {
      return true;
   }
};

export const disabledDateOnStartDate = (current: Moment, startDate: number) => {
   if (
      (!startDate && current < moment().startOf('date')) ||
      (startDate &&
         current > moment.unix(startDate) &&
         current < moment().startOf('date'))
   ) {
      return false;
   } else {
      return true;
   }
};

export const todayDatePickerEnd = (setState: Dispatch<SetStateAction<any>>) => {
   const setTodayDate = () => {
      setState(
         moment()
            .endOf('day')
            .unix()
      );
   };
   return (
      <>
         <Tag color="blue" onClick={setTodayDate} style={{ cursor: 'pointer' }}>
            วันนี้
         </Tag>
      </>
   );
};

export const todayDatePickerStart = (
   setState: Dispatch<SetStateAction<any>>
) => {
   const setTodayDate = () => {
      setState(
         moment()
            .startOf('day')
            .unix()
      );
   };
   return (
      <>
         <Tag color="blue" onClick={setTodayDate} style={{ cursor: 'pointer' }}>
            วันนี้
         </Tag>
      </>
   );
};
