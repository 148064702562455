import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import axios from 'axios';
import queryString from 'query-string';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Tabs, Dropdown, Menu, Divider } from 'antd';
import {
    Card,
    CardHeader,
    CardHeaderToolbar,
    CardBody
} from '../../../../../_metronic/_partials/controls/Card';
import SVG from 'react-inlinesvg';

import { useStyles1 } from '../viewAsFunction/SubscriptionCard';
import { dateStr, timeStr } from '../../partials/timeStampToDate';
import * as util from './CheckEbookFunc';
import { LoaderView, NodataView } from '../../../CustomView';
import Search from 'antd/lib/input/Search';

const { TabPane } = Tabs;
export function CheckEbookPage({ history, location }) {
    const parsed = queryString.parse(location);
    const { authState } = useSelector(
        ({ auth }) => ({
            authState: auth
        }),
        shallowEqual
    );
    const classes1 = useStyles1();

    const [loading, setLoading] = useState(true);
    const [loadingApprove, setLoadingApprove] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);

    const [catalogs, setCatalogs] = useState([]);
    const [catalogsApprove, setCatalogsApprove] = useState([]);
    const [catalogsHistory, setCatalogsHistory] = useState([]);

    const [search, setSearch] = useState('');
    const [searchApprove, setSearchApprove] = useState('');
    const [searchHistory, setSearchHistory] = useState('');

    const [filtered, setFiltered] = useState([]);
    const [filteredApprove, setFilteredApprove] = useState([]);
    const [filteredHistory, setFilteredHistory] = useState([]);
    const [tab, setTab] = useState();
    const [sortBy, setSortBy] = useState('sort');

    const getdata = async status => {
        try {
            const req = await axios.get(
                queryString.stringifyUrl({
                    url: `/superuser/catalogs`,
                    query: { status: parseInt(status) }
                })
            );
            const { data } = req;
            if (data.status && data.data) {
                let catalogs = data.data.catalogs;
                if (status === 2) {
                    setLoading(false);
                    setCatalogs([...catalogs]);
                } else if (status === 4) {
                    setLoadingApprove(false);
                    setCatalogsApprove([...catalogs]);
                } else if (status === 3) {
                    setLoadingHistory(false);
                    setCatalogsHistory([...catalogs]);
                }
            }
        } catch (error) {
            if (status === 2) {
                setLoading(false);
            } else if (status === 4) {
                setLoadingApprove(false);
            } else if (status === 3) {
                setLoadingHistory(false);
            }
        }
    };
    const sortBtn = () => (
        <Menu>
            {util.sortWordingValue?.map((e, i) => {
                return (
                    <Menu.Item key={e.value} onClick={() => setSortBy(e.value)}>
                        <span className="dropdown-text no-pic">{e.title}</span>
                    </Menu.Item>
                );
            })}
        </Menu>
    );
    const Catalogs = catalogs => {
        return (
            <>
                <div id="CatalogCard">
                    {catalogs?.catalogs
                        ?.sort(util.sortByFunc(sortBy))
                        .map((e, index) => (
                            <div>
                                <div
                                    onClick={() => {
                                        history.push(
                                            `/checkebook-detail?id=${e.uid}&title=${e.title}&clientUID=${e.clientUID}&check-ebook=true` //&name=${e.clientName}
                                        );
                                    }}
                                    className={`${classes1.colCatalogue} colDiv`}
                                >
                                    <div
                                        className="colDiv-imageDiv"
                                        style={{ backgroundColor: e.color }}
                                    >
                                        <img
                                            src={e.logoImages}
                                            alt=""
                                            id={e.uid}
                                            onError={e => {
                                                if (e.currentTarget) {
                                                    e.currentTarget.onerror = null;
                                                    e.currentTarget.src =
                                                        'https://via.placeholder.com/90x90.png?text=Logo';
                                                    e.currentTarget.style.borderRadius =
                                                        '100%';
                                                }
                                            }}
                                        />
                                    </div>

                                    <div
                                        className={`${classes1.catalogueDetail} colDiv-detail`}
                                    >
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip>{e.title}</Tooltip>
                                            }
                                        >
                                            <div className="text-title wrap">
                                                แคตตาล็อก : {e.title}
                                            </div>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip>
                                                    {e.clientName}
                                                </Tooltip>
                                            }
                                        >
                                            <div className="text-title wrap">
                                                {e.clientName}
                                            </div>
                                        </OverlayTrigger>
                                        <div className="text">
                                            {e.libraryName}
                                        </div>
                                        <div className="text-detail">
                                            วันที่สั่งซื้อ :{' '}
                                            {dateStr(e.registerDate)}{' '}
                                            {timeStr(e.registerDate)}
                                        </div>
                                        <div className="text-detail">
                                            จำนวนสิทธิ์ : {e.concurrent} สิทธิ์
                                        </div>
                                        <div className="text-detail">
                                            จำนวนอีบุ๊ก : {e.contentAmount} เล่ม
                                        </div>
                                        <div className="text-detail">
                                            แอดมิน : {e.creatorEmail}{' '}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </>
        );
    };
    const TitleBar = () => {
        return (
            <div className="card-label">
                <div>รายการแคตตาล็อก</div>
            </div>
        );
    };
    const onChangesearch = e => {
        setSearch(e.target.value);
        setLoading(false);
    };
    const onChangesearchApprove = e => {
        setSearchApprove(e.target.value);
        setLoadingApprove(false);
    };
    const ToolBar = props => {
        return (
            <>
                {props.tab == 1 ? (
                    <input
                        className="form-control"
                        type="text"
                        placeholder="ค้นหา"
                        value={search}
                        autoFocus={true}
                        onChange={e => onChangesearch(e)}
                    />
                ) : (
                    <input
                        className="form-control"
                        type="text"
                        placeholder="ค้นหา"
                        value={searchApprove}
                        autoFocus={true}
                        onChange={e => onChangesearchApprove(e)}
                    />
                )}

                <SVG
                    src="/media/members/search-icon.svg"
                    alt=""
                    className="icon-search"
                />
                <Dropdown
                    overlay={() => sortBtn()}
                    trigger={['click']}
                    placement="bottomLeft"
                >
                    <button type="button" className="button-border">
                        <span>{`${util.sortWording(sortBy)}`}</span>
                    </button>
                </Dropdown>
            </>
        );
    };
    const handleTab = tab => {
        setTab(tab);
        let status = undefined;
        let chktab = parseInt(tab);
        if (chktab === 1 && catalogs.length === 0) {
            setLoading(true);

            status = 2;
        } else if (chktab === 2 && catalogsApprove.length === 0) {
            setLoadingApprove(true);
            status = 4;
        } else if (chktab === 3 && catalogsHistory.length === 0) {
            setLoadingHistory(true);
            status = 3;
        }
        if (status !== undefined) getdata(status);
    };

    useEffect(() => {
        if (Array.isArray(catalogsApprove)) {
            setFilteredApprove(
                Object.values(catalogsApprove).filter(el =>
                    el.clientName
                        .toLowerCase()
                        .includes(searchApprove.toLowerCase())
                )
            );
        }
    }, [searchApprove, catalogsApprove]);
    useEffect(() => {
        if (Array.isArray(catalogsHistory)) {
            setFilteredHistory(
                Object.values(catalogsHistory).filter(el =>
                    el.clientName
                        .toLowerCase()
                        .includes(searchHistory.toLowerCase())
                )
            );
        }
    }, [searchHistory, catalogsHistory]);
    useEffect(() => {
        if (Array.isArray(catalogs)) {
            setFiltered(
                Object.values(catalogs).filter(el =>
                    el.clientName.toLowerCase().includes(search.toLowerCase())
                )
            );
        }
    }, [search, catalogs]);
    useEffect(() => {
        const parsed = queryString.parse(location.search);
        handleTab(1);
        handleTab(2);
        handleTab(3);
    }, [location.search]);
    return (
        <div id="CheckEbookPage" className="checkebook">
            <Card className="cardStyle">
                <CardHeader title={<TitleBar />}>
                    <CardHeaderToolbar>
                        {<ToolBar tab={tab} />}
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody className="cardBodyStyle">
                    <Tabs
                        defaultActiveKey="1"
                        className="tab-head"
                        // onChange={(e) => handleTab(e)}
                    >
                        <TabPane
                            tab={`รอตรวจสอบ (${
                                catalogs ? catalogs?.length : 0
                            })`}
                            key="1"
                        >
                            {loading ? (
                                <LoaderView></LoaderView>
                            ) : filtered && filtered.length === 0 ? (
                                <NodataView></NodataView>
                            ) : (
                                <Catalogs catalogs={filtered} />
                            )}
                        </TabPane>
                        <TabPane
                            tab={`ตรวจสอบแล้ว (${
                                catalogsApprove ? catalogsApprove?.length : 0
                            })`}
                            key="2"
                        >
                            {loadingApprove ? (
                                <LoaderView></LoaderView>
                            ) : filteredApprove &&
                              filteredApprove.length === 0 ? (
                                <NodataView></NodataView>
                            ) : (
                                <Catalogs catalogs={filteredApprove} />
                            )}
                        </TabPane>
                        <TabPane
                            tab={`ประวัติการสั่งซื้อ (${
                                catalogsHistory ? catalogsHistory?.length : 0
                            })`}
                            key="3"
                        >
                            {loadingHistory ? (
                                <LoaderView></LoaderView>
                            ) : filteredHistory &&
                              filteredHistory.length === 0 ? (
                                <NodataView></NodataView>
                            ) : (
                                <Catalogs catalogs={filteredHistory} />
                            )}
                        </TabPane>
                    </Tabs>
                </CardBody>
            </Card>
        </div>
    );
}
