// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Role } from "../../../../../../../interface";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  {
    openEditPodcastDialog,
    openDeletePodcastDialog,
    openViewPodcastDialog,
    isPrivate,
    role,
  }
) {
  return row && isPrivate ? (
    <>
      {row.status === 0 || role === Role.SuperAdmin ? (
        ""
      ) : (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-user-tooltip">แก้ไขรายละเอียด</Tooltip>}
        >
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            onClick={(e) => {
              e.preventDefault();
              openEditPodcastDialog(cellContent);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/members/edit-button.svg")} />
            </span>
          </a>
        </OverlayTrigger>
      )}
      {role === Role.SuperAdmin ? (
        ""
      ) : (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-user-tooltip">ลบสื่อ</Tooltip>}
        >
          <a
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            style={{ marginLeft: "0.75rem" }}
            onClick={() =>
              openDeletePodcastDialog(row.title, row.contentUID, isPrivate)
            }
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/members/delete-button.svg")} />
            </span>
          </a>
        </OverlayTrigger>
      )}
      <button
        title="View books"
        onClick={(e) => {
          e.preventDefault();
          openViewPodcastDialog(cellContent, "podcast");
        }}
        style={{
          padding: "3px",
          width: "100px",
          height: "28px",
          borderRadius: "6px",
          backgroundColor: "#f2f2f2",
          color: "#012875",
          border: "none",
          marginLeft: "0.75rem",
        }}
      >
        รายละเอียด
      </button>
    </>
  ) : row ? (
    <button
      title="View podcast"
      onClick={(e) => {
        e.preventDefault();
        openViewPodcastDialog(cellContent, "podcast");
      }}
      style={{
        padding: "3px",
        width: "100px",
        height: "28px",
        borderRadius: "6px",
        backgroundColor: "#f2f2f2",
        color: "#012875",
        border: "none",
      }}
    >
      รายละเอียด
    </button>
  ) : (
    ""
  );
}
