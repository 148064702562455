import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/banners/bannersActions";
import { useBannersUIContext } from "../BannersUIContext";
import { makeStyles } from "@material-ui/core/styles";
import { useStyles } from "../../../partials/useStyles";

export function BannerDeleteDialog({ id, show, onHide, isPrivate }) {
     // Banners UI Context
     const bannersUIContext = useBannersUIContext();
     const bannersUIProps = useMemo(() => {
          return {
               setIds: bannersUIContext.setIds,
               queryParams: bannersUIContext.queryParams,
          };
     }, [bannersUIContext]);

     // Banners Redux state
     const dispatch = useDispatch();
     const { isLoading } = useSelector((state) => ({ isLoading: state.banners.actionsLoading }), shallowEqual);

     // if !id we should close modal
     useEffect(() => {
          if (!id) {
               onHide();
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [id]);

     // looking for loading/dispatch
     useEffect(() => {}, [isLoading, dispatch]);

     const deleteBanner = () => {
          const c = bannersUIProps.queryParams;
          // server request for deleting banner by id
          dispatch(actions.deleteBanner(id, isPrivate)).then(() => {
               // refresh list after deletion
               dispatch(actions.fetchBanners(c, isPrivate));
               // clear selections list
               bannersUIProps.setIds([]);
               // closing delete modal
               onHide();
          });
     };

     const classes = useStyles();

     return (
          <Modal
               backdrop="static"
               show={show}
               onHide={onHide}
               aria-labelledby="example-modal-sizes-title-lg"
               style={{ margin: "3% auto" }}
          >
               {/*begin::Loading*/}
               {isLoading && <ModalProgressBar />}
               {/*end::Loading*/}
               {/* <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Banner Delete
        </Modal.Title>
      </Modal.Header> */}
               <Modal.Body className={classes.body}>
                    <img src="/media/members/image-modal.svg" alt="" />
                    <div className="text-text-red">ยืนยันการลบ</div>
                    <div className="text-text-gray">ต้องการลบแบนเนอร์นี้หรือไม่?</div>
                    <div className="mt-3">
                         <button type="button" onClick={onHide} className="denyButton">
                              ยกเลิก
                         </button>
                         <button type="button" onClick={deleteBanner} className="acceptButton">
                              ยืนยัน
                         </button>
                    </div>
               </Modal.Body>
          </Modal>
     );
}
