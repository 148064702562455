/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/categories/categoriesActions";
import { CategoryNewForm } from "./CategoryNewForm";
import { useCategoriesUIContext } from "../CategoriesUIContext";

export function CategoryNewDialog({
  type,
  name,
  isPrivate,
  show,
  onHide,
  isSubCat,
  notOrigin,
  history,
  onSuccess,
  contentType
}) {
  // Categories UI Context
  const categoriesUIContext = useCategoriesUIContext();
  const categoriesUIProps = useMemo(() => {
    return {
      initCategory: {
        id: undefined,
        name: ""
      }
    };
  }, [categoriesUIContext]);

  // Categories Redux state
  const dispatch = useDispatch();
  const { actionsLoading, entities, error } = useSelector(
    state => ({
      actionsLoading: state.categories.actionsLoading,
      entities: state.categories.entities,
      error: state.categories.error
    }),
    shallowEqual
  );

  const [isDuplicate, setDuplicate] = useState(false);

  useEffect(() => {
    if (error && error.errorCode === "014" && !isDuplicate) {
      setDuplicate(true);
    }
  }, [error]);

  // server request for saving category
  const createCategory = category => {
    const newCat = {
      catName: category.name,
      status: 1,
      contentType: contentType
    };
    dispatch(actions.createCategory(newCat, isPrivate, onSuccess)).then(val => {
      if (notOrigin && val !== "error") {
        onHide();
      }
    });
  };

  const createSubCategory = (subcategory, catId, catName) => {
    const newSubCat = {
      subcatName: subcategory.name,
      status: 1,
      contentType: contentType
    };
    dispatch(
      actions.createSubCategory(
        newSubCat,
        isPrivate,
        catId,
        catName,
        history,
        onSuccess
      )
    ).then(val => {
      if (notOrigin && catId && val !== "error") {
        onHide();
      }
    });
  };

  return (
    <Modal
      backdrop="static"
      size="md"
      show={show}
      onHide={onHide}
      style={{ marginTop: "27px" }}
      aria-labelledby="example-modal-sizes-title-md"
    >
      <CategoryNewForm
        createCategory={createCategory}
        createSubCategory={createSubCategory}
        actionsLoading={actionsLoading}
        category={categoriesUIProps.initCategory}
        onHide={onHide}
        isSubCat={isSubCat}
        categoryList={entities}
        isDuplicate={isDuplicate}
        setDuplicate={setDuplicate}
      />
    </Modal>
  );
}
