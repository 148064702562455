import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Dropdown, Button, Menu } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";

interface ModalsProps {
  onHide: () => void;
  onClickAccept: (e?: any) => void;
  onClickDeny?: () => void;
  show?: boolean;
  title?: string;
  loading?: boolean;
  dialogClassName?: string;
  image?: any;
  size?: "sm" | "lg" | "xl" | undefined;
  el?: any
}
export const Modals = (props: ModalsProps) => {
  return (
    <Modal
      backdrop="static"
      show={props.show ? props.show : false}
      onHide={() => {
        props.onHide();
      }}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Body>
        <div style={{ textAlign: "center" }}>
          <img src="/media/members/image-modal.svg" alt="" />
          <div className="text-text-red">{props.title ? props.title : ""}</div>

          <div className="divButton">
            <button
              type="button"
              className="denyButton"
              onClick={() => props.onClickDeny ? props.onClickDeny() : ""}
            >
              ยกเลิก
            </button>
            <Button
              className="acceptButton"
              type="default"
              loading={props.loading}
              onClick={(e) => props.onClickAccept(e)}
            >
              ยืนยัน
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const ModalsSuccess = (props: ModalsProps) => {
  return (
    <Modal
      backdrop="static"
      size="sm"
      show={props.show ? props.show : false}
      onHide={() => {
        props.onHide();
      }}
      aria-labelledby="example-modal-sizes-title-lg"
      // style={{ marginTop: "27px" }}
      dialogClassName={`divSuccess ${props.dialogClassName}`}
    >
      <Modal.Body className="body-modal">
        <div className="modal-icon">
          {props.image ? (
            props.image
          ) : (
            <img src="/media/bg/success.svg" alt="" />
          )}
        </div>
        {/* <div className="divText-modal"> */}
        <div className="text-text-head">
          {props.title ? props.title : "เรียบร้อยแล้ว"}
        </div>
        {/* </div> */}

        <div className="divButton">
          <button
            className="acceptButton "
            onClick={(e) => props.onClickAccept(e)}
          >
            ตกลง
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const ModalsWithBody = (props: ModalsProps) => {
  return (
    <Modal
      backdrop="static"
      size={props.size ? props.size : "sm"}
      show={props.show ? props.show : false}
      aria-labelledby="example-modal-sizes-title-lg"
      dialogClassName="divdialog-small"
    >
      <Modal.Body>
        <h2 className="center">{props.title}</h2>
        {props.el}
        <div className="divButton">
          <button
            type="button"
            className="denyButton"
            onClick={() => props.onClickDeny ? props.onClickDeny() : ""}
          >
            ยกเลิก
          </button>
          <Button
            className="acceptButton"
            type="default"
            loading={props.loading}
            onClick={(e) => props.onClickAccept(e)}
          >
            ยืนยัน
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

interface CloseButonProps {
  onClick: () => void;
}
export const CloseButon = (props: CloseButonProps) => {
  return (
    <div
      style={{
        textAlign: "right",
        position: "absolute",
        right: "16px",
        top: "16px",
      }}
    >
      <button
        className="closeButton"
        onClick={() => {
          props.onClick();
        }}
      >
        <img src="/media/books/close-button.svg" alt="" />
      </button>
    </div>
  );
};
// <ModalsSuccess
// show={true} //successModal}
// onHide={() => setSuccessModal(false)}
// onClickAccept={() => setSuccessModal(false)}
// />
