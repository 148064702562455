import { useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { verifyOTP } from "../_redux/authCrud";
import { withRouter } from "react-router-dom";
import OtpInput from "react-otp-input";
import { HtiResponse, VerifyOtpResponse } from "../../../../interface";

function VerifyOTP(props: any) {
  const { intl } = props;
  const isRequested = false;
  const initialValues = {
    OTP: "",
  };

  useEffect(() => {
    const interval = setInterval(() => {
      props.setTimeLeft((seconds: number) => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (props.timeLeft === 0) {
      props.history.push("/auth");
      props.setTimeLeft(500);
    }
  }, [props.timeLeft]);

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      verifyOTP(props.uid, props.otp, props.refCode)
        .then((response) => {
          const { data }: HtiResponse<VerifyOtpResponse> = response.data;
          if (data) {
            props.saveNewPasswordToken(data.userToken);
            props.history.push("/auth/newpw");
          }
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
          setStatus(intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_OTP" }));
        });
    },
  });
  const regexInput = RegExp(/^[0-9]*$/i);
  const onOTPChange = (otp: string) => {
    props.setOTP(otp);
  };

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            // style={{ width: "400px" }}
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <h3 className="font-size-h1">Verify OTP</h3>
              <p className="refcode">Reference Code : {props.refCode}</p>
              <div className="boxOTP">
                <OtpInput
                  inputStyle={`form-control form-control-solid`}
                  value={props.otp}
                  onChange={onOTPChange}
                  numInputs={6}
                  separator={" "}
                />
              </div>
              <p className="refcode">
                หมดเวลาภายใน{" "}
                {props.timeLeft < 60
                  ? ""
                  : Math.floor(props.timeLeft / 60) + " นาที "}
                {props.timeLeft % 60} วินาที
              </p>
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="acceptButton"
                disabled={formik.isSubmitting || props.otp.length < 6}
              >
                <span>ยืนยัน</span>
                {formik.isSubmitting && props.otp.length === 6 && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(withRouter(connect(null, auth.actions)(VerifyOTP)));
