/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../_redux/ebooks/ebooksActions';
import { ebooksSlice } from '../../../_redux/ebooks/ebooksSlice';
import { message } from 'antd';
import {
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses
} from '../../../../../../_metronic/_helpers';
import * as uiHelpers from '../EbooksUIHelpers';
import * as columnFormatters from './column-formatters';
import { useEbooksUIContext } from '../EbooksUIContext';
import { ClipLoader } from 'react-spinners';

import {
    NodataView,
    LoaderView,
    LoaderViewSmall
} from '../../../../CustomView';
import moment from 'moment-timezone';

const actions1 = ebooksSlice.actions;

export function EbooksTable({
    isPrivate,
    setListLoading,
    isFree,
    isNewEbook,
    ebookType
}) {
    // Ebooks UI Context
    const ebooksUIContext = useEbooksUIContext();
    const ebooksUIProps = useMemo(() => {
        return {
            ids: ebooksUIContext.ids,
            setIds: ebooksUIContext.setIds,
            queryParams: ebooksUIContext.queryParams,
            setQueryParams: ebooksUIContext.setQueryParams,
            openEditEbookDialog: ebooksUIContext.openEditEbookDialog,
            openViewEbookDialog: ebooksUIContext.openViewEbookDialog,
            openDeleteEbookDialog: ebooksUIContext.openDeleteEbookDialog,
            openBlockEbookDialog: ebooksUIContext.openBlockEbookDialog,
            openUnblockEbookDialog: ebooksUIContext.openUnblockEbookDialog
        };
    }, [ebooksUIContext]);

    // Getting current state of ebooks list from store (Redux)
    const { currentState, role } = useSelector(
        state => ({ currentState: state.ebooks, role: state.auth.role }),
        shallowEqual
    );
    const {
        totalCount,
        entities,
        entities_free,
        entities_hti,
        listLoading,
        LastEvaluatedKey,
        LastEvaluatedKey_free,
        LastEvaluatedKey_hti,
        error
    } = currentState;
    const loaderHeight = 50;
    const [loadingCount, setLoadingCount] = useState(0);
    const [isError, setIsError] = useState(false);
    const [isEmpty, setEmpty] = useState(false);
    const footerHeight = 300;
    const [loadingBottom, setLoadingBottom] = useState(false);
    const [isSearching, setSearching] = useState(false);
    const [deleteUIDTemp, setDeleteUIDTemp] = useState('');

    // const [loadEndscroll, setLoadEndscroll] = useState(false);
    useEffect(() => {
        if (error === '5003') {
            setIsError('5003');
        }
    }, [error]);

    // const isBottom = () => {
    //   let scrollHeight = Math.max(
    //     document.body.scrollHeight,
    //     document.documentElement.scrollHeight,
    //     document.body.offsetHeight,
    //     document.documentElement.offsetHeight,
    //     document.body.clientHeight,
    //     document.documentElement.clientHeight
    //   );
    //   return (
    //     window.pageYOffset + window.innerHeight >=
    //     scrollHeight - 3 * loaderHeight - footerHeight
    //   );
    // };

    const trackScrolling = () => {
        if (isBottom()) {
            setLoadingBottom(true);
        }
    };

    // useEffect(() => {
    //   document.addEventListener("scroll", trackScrolling);
    //   // eslint-disable-next-line
    // }, []);

    // useEffect(() => {
    //   return () => {
    //     setLoadingCount(0);
    //     document.removeEventListener("scroll", trackScrolling);
    //   };
    //   // eslint-disable-next-line
    // }, []);

    useEffect(() => {
        if (isNewEbook === 0) {
            dispatch(
                actions.fetchEbooks(
                    Object.assign(ebooksUIProps.queryParams, { ebookType }),
                    isPrivate,
                    isFree,
                    LastEvaluatedKey,
                    setLoadingBottom
                )
            );
        }
    }, [isNewEbook]);

    useEffect(() => {
        if (isPrivate) {
            if (
                LastEvaluatedKey === undefined
                // ||
                // JSON.stringify(LastEvaluatedKey) === JSON.stringify({})
            ) {
                setEmpty(true);
            } else if (loadingBottom && !listLoading) {
                setEmpty(false);
                dispatch(
                    actions.fetchEbooks(
                        Object.assign(ebooksUIProps.queryParams, { ebookType }),
                        isPrivate,
                        isFree,
                        LastEvaluatedKey,
                        setLoadingBottom
                    )
                );
            }
        }
    }, [LastEvaluatedKey, loadingBottom, listLoading]);

    useEffect(() => {
        if (isFree) {
            if (LastEvaluatedKey_free === undefined) {
                setEmpty(true);
            } else if (loadingBottom && !listLoading) {
                setEmpty(false);
                dispatch(
                    actions.fetchEbooks(
                        Object.assign(ebooksUIProps.queryParams, { ebookType }),
                        isPrivate,
                        isFree,
                        LastEvaluatedKey_free,
                        setLoadingBottom
                    )
                );
            }
        }
    }, [LastEvaluatedKey_free, loadingBottom, listLoading]);

    useEffect(() => {
        if (!isFree && !isPrivate) {
            if (LastEvaluatedKey_hti === undefined) {
                setEmpty(true);
            } else if (loadingBottom && !listLoading) {
                setEmpty(false);

                dispatch(
                    actions.fetchEbooks(
                        Object.assign(ebooksUIProps.queryParams, { ebookType }),
                        isPrivate,
                        isFree,
                        LastEvaluatedKey_hti,
                        setLoadingBottom
                    )
                );
            }
        }
    }, [LastEvaluatedKey_hti, loadingBottom, listLoading]);
    // Ebooks Redux state
    const dispatch = useDispatch();
    const history = useHistory();

    const getRefreshContent = content => {
        dispatch(
            actions1.ebookUpdated({
                id: content.contentUID,
                ebook: content
            })
        );
        message.success(`"${content.title}" มีสถานะล่าสุดแล้ว`);
    };

    useEffect(() => {
        setListLoading(listLoading);
    }, [listLoading]);

    useEffect(() => {
        if (entities && entities.length > -1 && loadingCount < 2) {
            setLoadingCount(loadingCount + 1);
        }
    }, [entities]);

    useEffect(() => {
        if (entities_hti && entities_hti.length > -1 && loadingCount < 2) {
            setLoadingCount(loadingCount + 1);
        }
    }, [entities_hti]);

    useEffect(() => {
        if (entities_free && entities_free.length > -1 && loadingCount < 2) {
            setLoadingCount(loadingCount + 1);
        }
    }, [entities_free]);

    useEffect(() => {
        // clear selections list
        ebooksUIProps.setIds([]);
        // server call by queryParams
        dispatch(
            actions.fetchEbooks(
                Object.assign(ebooksUIProps.queryParams, { ebookType }),
                isPrivate,
                isFree,
                undefined,
                undefined,
                setSearching
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ebooksUIProps.queryParams, dispatch, isPrivate, isFree]);

    // useEffect(() => {
    //    setSearching(true);
    //    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [ebooksUIProps.queryParams]);

    const minWidthOfAll = 950;
    const minusOfAll = 380;
    const [tableWidth, setTableWidth] = useState(
        Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        ) <
            minWidthOfAll + minusOfAll
            ? minWidthOfAll
            : Math.max(
                  document.body.scrollWidth,
                  document.documentElement.scrollWidth,
                  document.body.offsetWidth,
                  document.documentElement.offsetWidth,
                  document.documentElement.clientWidth
              ) - minusOfAll
    );

    const isBottom = () => {
        const getScroll = document
            .getElementById('custom-table-scroll')
            .getElementsByTagName('tbody')[0];
        const scrollTop = Math.ceil(getScroll.scrollTop);
        const offsetHeight = Math.ceil(getScroll.offsetHeight);
        if (
            !listLoading &&
            getScroll.scrollHeight - (scrollTop + offsetHeight) <= 1
        ) {
            return true;
        } else {
            return false;
        }
    };

    const resize = () => {
        if (
            document.getElementById('custom-table-scroll') &&
            loadingCount === 2
        ) {
            setTableWidth(
                document
                    .getElementById('custom-table-scroll')
                    .getBoundingClientRect().width < minWidthOfAll
                    ? minWidthOfAll
                    : document
                          .getElementById('custom-table-scroll')
                          .getBoundingClientRect().width
            );
        }
    };
    const forceDeleteEbook = () => {
        dispatch(
            actions.deleteEbook(deleteUIDTemp, isPrivate, { forceReturn: true })
        ).then(() => {
            // refresh list after deletion
            setIsError(false);
            dispatch(
                actions.fetchEbooks(
                    Object.assign(ebooksUIProps.queryParams, { ebookType }),
                    isPrivate
                )
            );
            // clear selections list
            ebooksUIProps.setIds([]);
            // closing block modal
        });
    };

    const addListenerEvent = () => {
        window.addEventListener('resize', resize);
        if (
            document.getElementById('custom-table-scroll') &&
            document
                .getElementById('custom-table-scroll')
                .getElementsByTagName('tbody')[0]
            // &&
            // loadingCount === 2
        ) {
            document
                .getElementById('custom-table-scroll')
                .getElementsByTagName('tbody')[0]
                .addEventListener('scroll', trackScrolling);
        }
    };

    useEffect(() => {
        addListenerEvent();
        // eslint-disable-next-line
    }, [loadingCount]);

    useEffect(() => {
        // return () => {
        //   alert("remove");
        //   window.removeEventListener("resize", resize);
        //   setLoadingCount(0);
        //   if (
        //     document.getElementById("custom-table-scroll") &&
        //     document
        //       .getElementById("custom-table-scroll")
        //       .getElementsByTagName("tbody")[0]
        //   ) {
        //     document
        //       .getElementById("custom-table-scroll")
        //       .getElementsByTagName("tbody")[0]
        //       .removeEventListener("scroll", trackScrolling);
        //   }
        // };
        // eslint-disable-next-line
    }, []);

    // Table columns
    const columns = [
        {
            dataField: 'none',
            text: 'ลำดับ',
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.IndexColumnFormatter,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            },
            formatExtraData: {
                pageNumber: ebooksUIProps.queryParams.pageNumber,
                pageSize: ebooksUIProps.queryParams.pageSize
            }
        },
        {
            dataField: 'coverImage',
            text: 'ปก',
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.ImageColumnFormatter,
            formatExtraData: {
                openViewEbookDialog: ebooksUIProps.openViewEbookDialog,
                isPrivate
            },
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'title',
            text: 'รายละเอียด',
            // sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.DetailColumnFormatter,
            formatExtraData: {
                openEditEbookDialog: ebooksUIProps.openEditEbookDialog,
                isPrivate,
                getRefreshContent
            },
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'concurrent',
            text: 'ทั้งหมด',
            // sort: true,
            sortCaret: sortCaret,
            formatter: columnFormatters.TextColumnFormatter,
            formatExtraData: {
                isPrivate
            },
            headerSortingClasses,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'none1',
            text: 'คงเหลือ',
            sortCaret: sortCaret,
            formatter: columnFormatters.RemainAmountColumnFormatter,
            formatExtraData: {
                isPrivate
            },
            headerSortingClasses,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'waitingAmount',
            text: 'จองคิว',
            sortCaret: sortCaret,
            formatter: columnFormatters.WaitingAmountFormatter,
            formatExtraData: {
                isPrivate
            },
            headerSortingClasses,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'rentAmount',
            text: 'ยืมแล้ว',
            sortCaret: sortCaret,
            formatter: columnFormatters.TextAmountColumnFormatter,
            headerSortingClasses,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'startDate',
            text: 'วันที่เริ่มใข้งาน',
            sortCaret: sortCaret,
            formatter: columnFormatters.DateColumnFormatter,
            headerSortingClasses,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'expireDate',
            text: 'วันที่หมดอายุ',
            sortCaret: sortCaret,
            formatter: columnFormatters.DateColumnFormatter,
            headerSortingClasses,
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'status',
            text: 'สถานะ',
            formatter: columnFormatters.StatusColumnFormatter,
            formatExtraData: {
                isPrivate,
                openBlockEbookDialog: ebooksUIProps.openBlockEbookDialog,
                openUnblockEbookDialog: ebooksUIProps.openUnblockEbookDialog,
                role,
                setIsError
            },
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        },
        {
            dataField: 'contentUID',
            text: 'การจัดการ',
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
                setDeleteUIDTemp,
                isPrivate,
                openEditEbookDialog: ebooksUIProps.openEditEbookDialog,
                openViewEbookDialog: ebooksUIProps.openViewEbookDialog,
                openDeleteEbookDialog: ebooksUIProps.openDeleteEbookDialog,
                role
            },
            headerFormatter: (
                column,
                colIndex,
                { sortElement, filterElement }
            ) => {
                return <div>{column.text}</div>;
            }
        }
    ];
    const isNotFound = () => {
        return (
            (isPrivate &&
                entities &&
                Array.isArray(entities) &&
                entities.length === 0 &&
                !listLoading) ||
            (!isFree &&
                (!isPrivate || isPrivate === undefined) &&
                entities_hti &&
                Array.isArray(entities_hti) &&
                entities_hti.length === 0 &&
                !listLoading) ||
            (isFree &&
                (!isPrivate || isPrivate === undefined) &&
                entities_free &&
                Array.isArray(entities_free) &&
                entities_free.length === 0 &&
                !listLoading)
        );
    };
    return loadingCount === 2 ? (
        <>
            <div
                className={`EbooksTable ${isFree ? 'isFree' : ''} ${
                    isPrivate ? 'isPrivated' : ''
                }`}
            >
                <BootstrapTable
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes={`table table-head-custom table-vertical-center custom-table-scroll ${
                        isNotFound() ? 'isNotFound' : ''
                    }`}
                    id="custom-table-scroll"
                    bootstrap4
                    remote
                    keyField="contentUID"
                    data={
                        isSearching
                            ? []
                            : isPrivate
                            ? !Array.isArray(entities)
                                ? []
                                : entities
                            : isFree
                            ? !Array.isArray(entities_free)
                                ? []
                                : entities_free
                            : !Array.isArray(entities_hti)
                            ? []
                            : entities_hti
                    }
                    columns={
                        isFree
                            ? columns.filter(el => {
                                  return (
                                      el.text !== 'ทั้งหมด' &&
                                      el.text !== 'คงเหลือ' &&
                                      el.text !== 'จองคิว'
                                  );
                              })
                            : columns
                    }
                    defaultSorted={uiHelpers.defaultSorted}
                    onTableChange={getHandlerTableChange(
                        ebooksUIProps.setQueryParams,
                        addListenerEvent
                    )}
                    // selectRow={getSelectRow({
                    //   entities,
                    //   ids: ebooksUIProps.ids,
                    //   setIds: ebooksUIProps.setIds,
                    // })}
                    // {...paginationTableProps}
                >
                    <PleaseWaitMessage
                        entities={
                            isPrivate
                                ? entities
                                : isFree
                                ? entities_free
                                : entities_hti
                        }
                    />
                    <NoRecordsFoundMessage
                        entities={
                            isPrivate
                                ? entities
                                : isFree
                                ? entities_free
                                : entities_hti
                        }
                    />
                </BootstrapTable>
            </div>
            {isNotFound() ? (
                ebooksUIProps.queryParams.keyword ? (
                    <NodataView isSearch={true} />
                ) : (
                    <NodataView text="ไม่มีข้อมูลสื่อ" />
                )
            ) : (
                ''
            )}
            {!error &&
            listLoading &&
            !(
                LastEvaluatedKey === undefined ||
                JSON.stringify(LastEvaluatedKey) === JSON.stringify({})
            ) &&
            !(
                LastEvaluatedKey_hti === undefined ||
                JSON.stringify(LastEvaluatedKey_hti) === JSON.stringify({})
            ) &&
            !(
                LastEvaluatedKey_free === undefined ||
                JSON.stringify(LastEvaluatedKey_free) === JSON.stringify({})
            ) &&
            (!isEmpty || loadingBottom) ? (
                <LoaderViewSmall />
            ) : !error && !isEmpty ? (
                // <div style={{ minHeight: `${loaderHeight}px` }} />
                ''
            ) : (
                ''
            )}
            <Modal
                backdrop="static"
                show={isError}
                onHide={() => {
                    setIsError(false);
                }}
                aria-labelledby="example-modal-sizes-title-lg"
                style={{ margin: '3% auto' }}
                size="md"
            >
                <Modal.Body
                    style={{
                        textAlign: 'center'
                    }}
                >
                    <img src="/media/members/image-modal.svg" alt="" />
                    <div
                        style={{
                            fontSize: '20px',
                            fontWeight: 600,
                            color: '#d7042b',
                            marginBottom: '12px'
                        }}
                    >
                        {isError === '5003' ? (
                            <>
                                <p style={{ marginBottom: '0px' }}>
                                    มีการใช้งานสื่อนี้อยู่
                                </p>
                                <p>ต้องการที่จะลบหรือไม่</p>
                            </>
                        ) : isError === 'fixFileFirst' ? (
                            <>
                                <p style={{ marginBottom: '0px' }}>
                                    กรุณาซ่อมไฟล์ก่อนเปิดใช้งานสื่อนี้
                                </p>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                    <div style={{ marginTop: '36px' }}>
                        {isError === '5003' ? (
                            <>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setIsError(false);
                                    }}
                                    className="denyButton"
                                >
                                    ยกเลิก
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        forceDeleteEbook();
                                    }}
                                    className="acceptButton"
                                >
                                    ตกลง
                                </button>
                            </>
                        ) : (
                            <button
                                type="button"
                                onClick={() => {
                                    setIsError(false);
                                }}
                                className="acceptButton"
                            >
                                ตกลง
                            </button>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    ) : (
        <LoaderView />
    );
}
