import axios from "axios";
import {
  PodcastData,
  HtiResponse,
  CreatePodcastPayload,
  CreatePodPayload,
  ContentData,
  Content,
  PodcastByIdData,
  customersUIPropsQueryParams,
  LastEvaluatedKey_Content,
  LastEvaluatedKey_FreeContent,
  UpdateContentPayload,
  FreeContent,
  ContentById,
  FreeContentData,
  deletePayload
} from "../../../../../interface";
// export const PODCASTS_URL = "api/podcasts";
export const PODCASTS_URL = `/admin/contents`;
export const PRIVATE_PODCASTS_URL = `/admin/private/contents`;
export const FREE_PODCASTS_URL = "/admin/free/contents";

export function createPod(newValues: CreatePodPayload) {
  return axios.post(PRIVATE_PODCASTS_URL, newValues);
}
//
// CREATE =>  POST: add a new podcast to the server
export function createPodcast(
  newValues: CreatePodcastPayload,
  creatorUID: string
) {
  return axios.post(
    PRIVATE_PODCASTS_URL + `/${creatorUID}/podcasts`,
    newValues
  );
}

// READ
export function getAllPodcasts() {
  return axios.get<HtiResponse<PodcastData>>(PODCASTS_URL);
}

export function getPodcastById(podcastId: string) {
  return axios.get<HtiResponse<ContentById>>(`${PODCASTS_URL}/${podcastId}`);
}

export function getPodcastEpisodes(id: string) {
  return axios.get<HtiResponse<PodcastByIdData>>(
    `${PRIVATE_PODCASTS_URL}/${id}/podcasts`
  );
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findPodcasts(
  queryParams: customersUIPropsQueryParams,
  isPrivate: number,
  lastEvaluate: LastEvaluatedKey_Content | LastEvaluatedKey_FreeContent,
  isFree: number
) {
  if (!queryParams.keyword) {
    // return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
    let limit = "";
    let lastKeyQuery = "";
    let status = "";
    if (queryParams.memberMode) {
      status = `&status=${
        queryParams.memberMode === "2" && !isPrivate
          ? "0"
          : queryParams.memberMode
      }`;
    }
    if (queryParams && queryParams.pageSize) {
      limit = `?limit=${queryParams.pageSize}`;
    }
    if (
      lastEvaluate !== undefined &&
      JSON.stringify(lastEvaluate) !== JSON.stringify({})
    ) {
      // lastKeyQuery = "&lastEvaluatedKey=" + btoa(JSON.stringify(lastEvaluate));
      lastKeyQuery = "&lastEvaluatedKey=" + btoa(JSON.stringify(lastEvaluate));
    }
    return axios.get<HtiResponse<ContentData | FreeContentData>>(
      `${
        isPrivate
          ? PRIVATE_PODCASTS_URL
          : isFree
          ? FREE_PODCASTS_URL
          : PODCASTS_URL
      }${limit}${lastKeyQuery}${status}&contentType=podcast`
    );
  } else {
    return axios.get<HtiResponse<ContentData | FreeContentData>>(
      `${
        isPrivate
          ? PRIVATE_PODCASTS_URL
          : isFree
          ? FREE_PODCASTS_URL
          : PODCASTS_URL
      }/search?keyword=${encodeURIComponent(
        queryParams.keyword
      )}&contentType=podcast`
    );
  }
  // return axios.post(`${PODCASTS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the podcast on the server
export function updatePodcast(newValues :UpdateContentPayload, contentUID :string, isNew : boolean) {
  let isNewQuery = "";
  if (isNew) {
    isNewQuery = "?isNew=true";
  }
  let values = newValues;
  delete values.authorName;
  delete values.publisherName;
  delete values.activeLicense;
  // console.log(values);
  return axios.put(
    `${PRIVATE_PODCASTS_URL}/${contentUID}/edit${isNewQuery}`,
    values
  );
}

// UPDATE Status
// export function updateStatusForPodcasts(ids, status) {
//   return axios.post(`${PODCASTS_URL}/updateStatusForPodcasts`, {
//     ids,
//     status,
//   });
// }

// DELETE => delete the podcast from the server
export function deletePodcast(podcastId : string, isPrivate : boolean,forceReturn:deletePayload) {
  console.log(podcastId)
  return axios.delete(
    `${isPrivate ? PRIVATE_PODCASTS_URL : PODCASTS_URL}/${podcastId}`,{data:forceReturn}
  );
}

export function blockPodcast(podcastId :string, isPrivate :number, isFree :number) {
  if (isPrivate) {
    return axios.patch(`${PRIVATE_PODCASTS_URL}/${podcastId}/status`, {
      status: 2,
    });
  } else {
    if (isFree) {
      return axios.patch(`${FREE_PODCASTS_URL}/${podcastId}/status`);
    } else {
      return axios.patch(`${PODCASTS_URL}/${podcastId}/status`);
    }
  }
}

export function unblockPodcast(podcastId :string, isPrivate :number, isFree :number) {
  if (isPrivate) {
    return axios.patch(`${PRIVATE_PODCASTS_URL}/${podcastId}/status`, {
      status: 1,
    });
  } else {
    if (isFree) {
      return axios.patch(`${FREE_PODCASTS_URL}/${podcastId}/status`);
    } else {
      return axios.patch(`${PODCASTS_URL}/${podcastId}/status`);
    }
  }
}

// DELETE Podcasts by ids
// export function deletePodcasts(ids) {
//   return axios.post(`${PODCASTS_URL}/deletePodcasts`, { ids });
// }
