/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
   PaginationProvider
} from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../_redux/customers/customersActions';
import {
   getSelectRow,
   getHandlerTableChange,
   NoRecordsFoundMessage,
   PleaseWaitMessage,
   sortCaret,
   headerSortingClasses
} from '../../../../../../_metronic/_helpers';
import * as uiHelpers from '../CustomersUIHelpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../../../../../_metronic/_partials/controls';
import { useCustomersUIContext } from '../CustomersUIContext';
import { ClipLoader } from 'react-spinners';
import { Checkbox, Empty, Tag } from 'antd';
import { NodataView, LoaderView } from '../../../../CustomView';

export function CustomersTable({ isBanned, viewAsToken }) {
   // Customers UI Context
   const customersUIContext = useCustomersUIContext();
   const customersUIProps = useMemo(() => {
      return {
         ids: customersUIContext.ids,
         setIds: customersUIContext.setIds,
         queryParams: customersUIContext.queryParams,
         setQueryParams: customersUIContext.setQueryParams,
         openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
         openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
         openBlockCustomerDialog: customersUIContext.openBlockCustomerDialog,
         openUnblockCustomerDialog:
            customersUIContext.openUnblockCustomerDialog,
         openBannedCustomerDialog: customersUIContext.openBannedCustomerDialog,
         openResetPwCustomerDialog:
            customersUIContext.openResetPwCustomerDialog,
         openCustomerDevicesDialog: customersUIContext.openCustomerDevicesDialog
      };
   }, [customersUIContext]);

   // Getting current state of customers list from store (Redux)
   const { currentState, auth } = useSelector(
      state => ({ currentState: state.customers, auth: state.auth }),
      shallowEqual
   );
   const {
      totalCount,
      entities,
      listLoading,
      LastEvaluatedKey,
      error
   } = currentState;
   const { role, email } = auth;
   const loaderHeight = 50;
   const [isEmpty, setEmpty] = useState(false);
   const footerHeight = 300;
   const [loadingBottom, setLoadingBottom] = useState(false);

   // const isBottom = () => {
   //   let scrollHeight = Math.max(
   //     document.body.scrollHeight,
   //     document.documentElement.scrollHeight,
   //     document.body.offsetHeight,
   //     document.documentElement.offsetHeight,
   //     document.body.clientHeight,
   //     document.documentElement.clientHeight
   //   );
   //   return (
   //     window.pageYOffset + window.innerHeight >=
   //     scrollHeight - 3 * loaderHeight - footerHeight
   //   );
   // };

   const trackScrolling = () => {
      if (isBottom()) {
         setLoadingBottom(true);
      }
   };

   useEffect(() => {
      console.log(LastEvaluatedKey, loadingBottom, listLoading);
      if (
         LastEvaluatedKey === undefined ||
         JSON.stringify(LastEvaluatedKey) === JSON.stringify({}) ||
         JSON.stringify(LastEvaluatedKey) ===
            JSON.stringify({ lastPosition: 20 })
      ) {
         setEmpty(true);
         if (
            JSON.stringify(LastEvaluatedKey) ===
            JSON.stringify({ lastPosition: 20 })
         ) {
            setLoadingBottom(false);
         }
      } else if (loadingBottom && !listLoading) {
         setEmpty(false);
         dispatch(
            actions.fetchCustomers(
               customersUIProps.queryParams,
               LastEvaluatedKey,
               setLoadingBottom
            )
         );
      }
   }, [LastEvaluatedKey, loadingBottom, listLoading]);

   // Customers Redux state
   const dispatch = useDispatch();
   useEffect(() => {
      if (isBanned) {
         const newQueryParams = { ...customersUIProps.queryParams };
         newQueryParams.memberMode = isBanned ? 3 : 1;
         customersUIProps.setQueryParams(newQueryParams);
      }

      // clear selections list
      customersUIProps.setIds([]);
      // server call by queryParams
      dispatch(actions.fetchCustomers(customersUIProps.queryParams));
      setEmpty(true);

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [customersUIProps.queryParams, dispatch]);

   // const [loadingCount, setLoadingCount] = useState(0);
   // const minWidthOfAll = 1003;
   // const minusOfAll = 380;

   // useEffect(() => {
   //    if (entities && entities.length > -1 && loadingCount < 2) {
   //       setLoadingCount(prev => prev + 1);
   //    }
   // }, [entities]);

   const isBottom = () => {
      const getScroll = document
         .getElementById('custom-table-scroll')
         .getElementsByTagName('tbody')[0];
      const offsetHeight = Math.ceil(getScroll.offsetHeight);
      const scrollTop = Math.ceil(getScroll.scrollTop);
      if (
         !listLoading &&
         !loadingBottom &&
         getScroll.scrollHeight - (scrollTop + offsetHeight) <= 1
      ) {
         return true;
      } else {
         return false;
      }
   };

   // const resize = () => {
   //    if (
   //       document.getElementById('custom-table-scroll') &&
   //       loadingCount === 2
   //    ) {
   //       setTableWidth(
   //          document
   //             .getElementById('custom-table-scroll')
   //             .getBoundingClientRect().width < minWidthOfAll
   //             ? minWidthOfAll
   //             : document
   //                  .getElementById('custom-table-scroll')
   //                  .getBoundingClientRect().width
   //       );
   //    }
   // };

   useEffect(() => {
      // window.addEventListener('resize', resize);

      if (
         entities.length > 0 &&
         document.getElementById('custom-table-scroll') &&
         document
            .getElementById('custom-table-scroll')
            .getElementsByTagName('tbody')[0]
      ) {
         document
            .getElementById('custom-table-scroll')
            .getElementsByTagName('tbody')[0]
            .addEventListener('scroll', trackScrolling);
      }
      // eslint-disable-next-line
   }, [entities]);

   useEffect(() => {
      return () => {
         // window.removeEventListener('resize', resize);
         // setLoadingCount(0);
         if (
            document.getElementById('custom-table-scroll') &&
            document
               .getElementById('custom-table-scroll')
               .getElementsByTagName('tbody')[0]
         ) {
            document
               .getElementById('custom-table-scroll')
               .getElementsByTagName('tbody')[0]
               .removeEventListener('scroll', trackScrolling);
         }
      };
      // eslint-disable-next-line
   }, []);

   // Table columns
   const columns = [
      {
         dataField: 'none',
         text: 'ลำดับ',
         sortCaret: sortCaret,
         headerSortingClasses,
         formatter: columnFormatters.IndexColumnFormatter,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return <div>{column.text}</div>;
         },
         formatExtraData: {
            pageNumber: customersUIProps.queryParams.pageNumber,
            pageSize: customersUIProps.queryParams.pageSize,
            callback: (checked, memberUID) => {
               dispatch(actions.memberSelectRow(checked, memberUID));
            }
         }
      },
      {
         dataField: 'staffID',
         text: 'รหัสพนักงาน',
         // sort: true,
         sortCaret: sortCaret,
         headerSortingClasses,
         formatter: columnFormatters.StaffIdColumnFormatter,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return <div>{column.text}</div>;
         }
      },
      {
         dataField: 'name',
         text: 'ชื่อ',
         // sort: true,
         sortCaret: sortCaret,
         headerSortingClasses,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return <div>{column.text}</div>;
         }
      },
      {
         dataField: 'surname',
         text: 'นามสกุล',
         sortCaret: sortCaret,
         headerSortingClasses,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return <div>{column.text}</div>;
         }
      },
      {
         dataField: 'email',
         text: 'อีเมล',
         sortCaret: sortCaret,
         headerSortingClasses,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return <div>{column.text}</div>;
         }
      },
      {
         dataField: 'memberType',
         text: '',
         sortCaret: sortCaret,
         headerSortingClasses,
         classes: 'text-center',
         formatter: value => {
            return (
               <Tag
                  style={{ margin: 'auto' }}
                  color={value === 'admin' ? 'magenta' : 'blue'}
               >
                  {value}
               </Tag>
            );
         },
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return <div>{column.text}</div>;
         }
      },
      {
         dataField: 'devices',
         text: 'อุปกรณ์',
         sortCaret: sortCaret,
         formatter: columnFormatters.StatusColumnFormatter,
         headerSortingClasses,
         align: 'center',
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return <div>{column.text}</div>;
         }
      },
      {
         dataField: 'action',
         text: 'การจัดการ',
         formatter: columnFormatters.ActionsColumnFormatter,
         formatExtraData: {
            openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
            openDeleteCustomerDialog: customersUIProps.openDeleteCustomerDialog,
            openBlockCustomerDialog: customersUIProps.openBlockCustomerDialog,
            openUnblockCustomerDialog:
               customersUIProps.openUnblockCustomerDialog,
            openBannedCustomerDialog: customersUIProps.openBannedCustomerDialog,
            openResetPwCustomerDialog:
               customersUIProps.openResetPwCustomerDialog,
            openCustomerDevicesDialog:
               customersUIProps.openCustomerDevicesDialog,
            role,
            email,
            isBanned,
            viewAsToken
         },
         classes: 'text-right',
         headerClasses: 'text-right pr-3',
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return <div>{column.text}</div>;
         }
      }
   ];
   // Table pagination properties
   const paginationOptions = {
      custom: true,
      totalSize: totalCount,
      sizePerPageList: uiHelpers.sizePerPageList,
      sizePerPage: customersUIProps.queryParams.pageSize,
      page: customersUIProps.queryParams.pageNumber
   };

   const isNotFound = () => {
      return (
         entities &&
         Array.isArray(entities) &&
         entities.length === 0 &&
         !listLoading
      );
   };

   return (
      <>
         {entities.length > 0 ? (
            <>
               {/* <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={listLoading} paginationProps={paginationProps}> */}
               <div className={`CustomerTable ${isBanned ? `Banned` : ``}`}>
                  <BootstrapTable
                     wrapperClasses="table-responsive"
                     bordered={false}
                     classes={`table table-head-custom table-vertical-center custom-table-scroll ${
                        isNotFound() ? 'isNotFound' : ''
                     }`}
                     id="custom-table-scroll"
                     bootstrap4
                     remote
                     keyField="memberUID"
                     data={!Array.isArray(entities) ? [] : entities}
                     columns={
                        isBanned
                           ? columns.filter(el => {
                                return (
                                   el.text !== 'อุปกรณ์' && el.text !== 'บทบาท'
                                );
                             })
                           : columns
                     }
                     defaultSorted={uiHelpers.defaultSorted}
                     onTableChange={getHandlerTableChange(
                        customersUIProps.setQueryParams
                     )}
                     // selectRow={getSelectRow({
                     //   entities,
                     //   ids: customersUIProps.ids,
                     //   setIds: customersUIProps.setIds,
                     // })}
                     // {...paginationTableProps}
                  >
                     <PleaseWaitMessage entities={entities} />
                     <NoRecordsFoundMessage entities={entities} />
                  </BootstrapTable>
               </div>
               {isNotFound() ? (
                  customersUIProps.queryParams.keyword ? (
                     <NodataView isSearch={true} />
                  ) : (
                     <NodataView />
                  )
               ) : (
                  ''
               )}
            </>
         ) : (
            <>
               {isNotFound() ? <NodataView/> :<LoaderView />  }
            </>
            
         )}
      </>
   );
}
