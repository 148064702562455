import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/reports/reportsActions";
import { ReportEditDialogHeader } from "./ReportEditDialogHeader";
import { ReportEditForm } from "./ReportEditForm";
import { useReportsUIContext } from "../ReportsUIContext";

export function ReportEditDialog({ id, show, onHide }) {
  // Reports UI Context
  const reportsUIContext = useReportsUIContext();
  const reportsUIProps = useMemo(() => {
    return {
      initReport: reportsUIContext.initReport,
    };
  }, [reportsUIContext]);

  // Reports Redux state
  const dispatch = useDispatch();
  const { actionsLoading, reportForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.reports.actionsLoading,
      reportForEdit: state.reports.reportForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Report by id
    dispatch(actions.fetchReport(id));
  }, [id, dispatch]);

  // server request for saving report
  const saveReport = (report) => {
    if (!id) {
      // server request for creating report
      dispatch(actions.createReport(report)).then(() => onHide());
    } else {
      // server request for updating report
      dispatch(actions.updateReport(report)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <ReportEditDialogHeader id={id} />
      <ReportEditForm
        saveReport={saveReport}
        actionsLoading={actionsLoading}
        report={reportForEdit || reportsUIProps.initReport}
        onHide={onHide}
      />
    </Modal>
  );
}
