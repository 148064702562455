import * as requestFromServer from './ebooksCrud';
import { ebooksSlice, callTypes } from './ebooksSlice';
import {
   CreateContentPayload,
   customersUIPropsQueryParams,
   LastEvaluatedKey_Content,
   LastEvaluatedKey_FreeContent,
   UpdateContentPayload,
   HtiResponse,
   ContentData,
   FreeContentData,
   contentForEdit,
   deletePayload
} from '../../../../../interface';
const { actions } = ebooksSlice;

export const fetchEbooks = (
   queryParams: customersUIPropsQueryParams,
   isPrivate: string,
   isFree: string,
   lastEvaluate:
      | LastEvaluatedKey_Content
      | LastEvaluatedKey_FreeContent
      | string,
   setLoadingBottom?: (loading: boolean) => void,
   setSearching?: (searching: boolean) => void
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
   console.log('params', queryParams);

   dispatch(actions.startCall({ callType: callTypes.list }));
   return requestFromServer
      .findEbooks(
         queryParams,
         parseInt(isPrivate, 10),
         lastEvaluate,
         parseInt(isFree, 10)
      )
      .then(async response => {
         const {
            data
         }: HtiResponse<ContentData | FreeContentData> = response.data;
         if (setLoadingBottom) {
            setLoadingBottom(false);
         }
         const entities = data?.contents;
         dispatch(
            actions.ebooksFetched({
               totalCount: data?.totalAmount,
               entities,
               LastEvaluatedKey: data?.LastEvaluatedKey,
               lastEvaluate,
               isPrivate,
               isFree,
               memberMode: queryParams.memberMode
            })
         );
         if (setSearching) {
            setSearching(false);
         }
      })
      .catch(error => {
         if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errorCode === '012'
         ) {
            alert('กรุณาเข้าสู่ระบบอีกครั้ง เนื่องจากระยะเวลาการใช้งานหมดอายุ');
            window.location.href = '/logout';
         }
         dispatch(
            actions.ebooksFetched({
               totalCount: 0,
               entities: [],
               LastEvaluatedKey: {},
               isPrivate,
               isFree
            })
         );
         if (setSearching) {
            setSearching(false);
         }
         error.clientMessage = "Can't find ebooks";
         dispatch(actions.catchError({ error, callType: callTypes.list }));
      });
};

export const fetchEbook = (id: string) => (
   dispatch: (arg0: { payload: any; type: string }) => void
) => {
   if (!id) {
      return dispatch(actions.ebookFetched({ ebookForEdit: undefined }));
   }

   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .getEbookById(id)
      .then(response => {
         const ebook = response.data;
         dispatch(actions.ebookFetched({ ebookForEdit: ebook }));
      })
      .catch(error => {
         error.clientMessage = "Can't find ebook";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const deleteEbook = (
   id: string,
   isPrivate: boolean,
   forceReturn: deletePayload
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .deleteEbook(id, isPrivate, forceReturn)
      .then(response => {
         if (response.data.status) {
            dispatch(actions.ebookDeleted({ id }));
         }
      })
      .catch(error => {
         error.clientMessage = "Can't delete ebook";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const blockEbook = (id: string, isPrivate: boolean, isFree: boolean) => (
   dispatch: (arg0: { payload: any; type: string }) => void
) => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .blockEbook(id, isPrivate, isFree)
      .then(response => {
         if (response.data.status) {
            dispatch(actions.ebookBlocked({ id }));
         }
      })
      .catch(error => {
         error.clientMessage = "Can't block ebook";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const unblockEbook = (
   id: string,
   isPrivate: boolean,
   isFree: boolean
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .unblockEbook(id, isPrivate, isFree)
      .then(response => {
         if (response.data.status) {
            dispatch(actions.ebookUnblocked({ id }));
         }
      })
      .catch(error => {
         error.clientMessage = "Can't unblock ebook";
         dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
};

export const createEbook = (
   newValues: CreateContentPayload,
   setSuccessModal?: (isSuccess: boolean) => void
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer.createEbook(newValues).then(res => {
      if (res.data.status) {
         if (setSuccessModal) {
            dispatch(actions.elementUpdated());
            setSuccessModal(true);
         }
      }
   });
};

export const updateEbook = (
   newValues: UpdateContentPayload,
   contentUID: string,
   isNew: boolean,
   setSuccessModal?: (isSuccess: boolean) => void,
   originValues?: contentForEdit
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
   dispatch(actions.startCall({ callType: callTypes.action }));
   return requestFromServer
      .updateEbook(newValues, contentUID, isNew)
      .then(res => {
         if (res.data.status && res.data.data) {
            dispatch(
               actions.ebookUpdated({
                  id: contentUID,
                  ebook: Object.assign(
                     {},
                     originValues,
                     newValues,
                     res.data.data,
                     {
                        concurrent:
                           newValues.concurrent === 0
                              ? 'unlimited'
                              : newValues.concurrent
                     }
                  )
               })
            );
            if (setSuccessModal) {
               setSuccessModal(true);
            }
         }
      });
};

// export const updateEbooksStatus = (ids, status) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateStatusForEbooks(ids, status)
//     .then(() => {
//       dispatch(actions.ebooksStatusUpdated({ ids, status }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't update ebooks status";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const deleteEbooks = (ids) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deleteEbooks(ids)
//     .then(() => {
//       dispatch(actions.ebooksDeleted({ ids }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't delete ebooks";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };
