import axios from 'axios';
import { HtiResponse } from '../../interface';

export enum AllowType {
   IPAddress = 'IPAddress',
   Domain = 'Domain',
   All = 'All'
}

export interface TokenType {
   uid: string;
   name: string;
   createBy: string;
   token: string;
   startDate: number;
   expireDate: number;
}

export interface TokenKeyResponse {
   tokenKeys: TokenType[];
}

export interface TokenPayload {
   name: string;
   isEnable: boolean;
   allowType?: AllowType;
   domain: string[];
   ip: string[];
}

export const getAllToken = async () => {
   try {
      const {
         data: { data }
      } = await axios.get<HtiResponse<TokenKeyResponse>>('/admin/allKey');
      return data;
   } catch (error) {
      return Promise.reject();
   }
};

export const createToken = async (token: TokenPayload) => {
   try {
      const path = '/admin/key';
      const data = await axios.post<HtiResponse>(path, token);
      return data;
   } catch (error) {
      return Promise.reject();
   }
};

export const updateToken = async (token: TokenPayload) => {
   try {
      const path = '/admin/key';
      const data = await axios.patch<HtiResponse>(path, token);
      return data;
   } catch (error) {
      return Promise.reject();
   }
};

export const getTokenByUID = async (uid: string) => {
   try {
      const path = `/admin/key/${uid}`;
      const {
         data: { data }
      } = await axios.get<HtiResponse<TokenPayload>>(path);
      return data;
   } catch (error) {
      return Promise.reject();
   }
};

export const deleteToken = async (uid: string) => {
   try {
      const path = `/admin/key/${uid}`;
      const data = await axios.delete<HtiResponse>(path);
      return data;
   } catch (error) {
      return Promise.reject();
   }
};
