// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Role } from "../../../../../../../interface";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  {
    setDeleteUIDTemp,
    openEditVideoDialog,
    openDeleteVideoDialog,
    openViewVideoDialog,
    isPrivate,
    role,
  }
) {
  return row && isPrivate ? (
    <>
      {row.status === 0 ? ( // || role === Role.superadmin ? (
        ""
      ) : (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-user-tooltip">แก้ไขรายละเอียด</Tooltip>}
        >
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            onClick={(e) => {
              e.preventDefault();
              openEditVideoDialog(cellContent);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/members/edit-button.svg")} />
            </span>
          </a>
        </OverlayTrigger>
      )}
      {/* {role === Role.superadmin ? (
        ""
      ) : ( */}
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="quick-user-tooltip">ลบสื่อ</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={() =>{
            setDeleteUIDTemp(row.contentUID)
            openDeleteVideoDialog(row.title, row.contentUID, isPrivate)
          }
          }
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/members/delete-button.svg")} />
          </span>
        </a>
      </OverlayTrigger>
      {/* )} */}
      <button
        title="View books"
        className="button-description"
        onClick={(e) => {
          e.preventDefault();
          openViewVideoDialog(cellContent, 1);
        }}
      >
        รายละเอียด
      </button>
    </>
  ) : row ? (
    <>
      <button
        title="View video"
        className="button-description"
        onClick={(e) => {
          e.preventDefault();
          openViewVideoDialog(cellContent, 0);
        }}
      >
        รายละเอียด
      </button>
    </>
  ) : (
    ""
  );
}
