import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCustomersUIContext } from "../CustomersUIContext";
import { makeStyles } from "@material-ui/core";
import { Menu, Dropdown } from "antd";
import SVG from "react-inlinesvg";

const prepareFilter = (queryParams, values) => {
  const { status, type, searchText, memberMode } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by status
  filter.status = status !== "" ? +status : undefined;
  // Filter by type
  filter.type = type !== "" ? +type : undefined;
  // Filter by all fields
  filter.lastName = searchText;
  // filter.firstName = searchText;
  // filter.email = searchText;
  // filter.ipAddress = searchText;
  newQueryParams.keyword = searchText;
  newQueryParams.memberMode = memberMode;
  return newQueryParams;
};

export function CustomersFilter({ listLoading, isQrcode, isBanned }) {
  // Customers UI Context
  const [memberMode, setMemberMode] = useState(0);
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
    };
  }, [customersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(customersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      customersUIProps.setQueryParams(newQueryParams);
    }
  };

  const sortWording = () => {
    switch (memberMode) {
      case 0:
        return "ทั้งหมด";
      case "1":
        return "ใช้งาน";
      case "2":
        return "บล็อก";
      default:
        break;
    }
  };

  const dropdownBtn = (values) => (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          setMemberMode(0);
          applyFilter(Object.assign({}, values, { memberMode: 0 }));
        }}
      >
        <span className="dropdown-text no-pic">ทั้งหมด</span>
      </Menu.Item>
      <Menu.Item
        key="1"
        onClick={() => {
          setMemberMode("1");
          applyFilter(Object.assign({}, values, { memberMode: "1" }));
        }}
      >
        <span className="dropdown-text no-pic">ใช้งาน</span>
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          setMemberMode("2");
          applyFilter(Object.assign({}, values, { memberMode: "2" }));
        }}
      >
        <span className="dropdown-text no-pic">บล็อก</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Formik
        initialValues={{
          status: "", // values => All=""/Suspended=0/Active=1/Pending=2
          type: "", // values => All=""/Business=0/Individual=1
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(Object.assign({}, values, { memberMode: memberMode }));
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="form form-label-right search-box"
            // style={{ marginTop: "0", width: "400px" }}
          >
            <div className="form-group">
              {/* {memberMode ? (
                <div className="col-lg-6"></div>
              ) : ( */}
              <input
                type="text"
                className="form-control"
                name="searchText"
                placeholder="ค้นหา"
                onBlur={handleBlur}
                value={values.searchText}
                onChange={(e) => {
                  setFieldValue("searchText", e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
              />
              <div
                className="icon-search"
                onClick={() => {
                  if (
                    !customersUIProps.queryParams.keyword ||
                    !values.searchText
                  ) {
                    handleSubmit();
                  } else {
                    applyFilter(
                      Object.assign({}, values, {
                        searchText: "",
                        memberMode: memberMode,
                      })
                    );
                    setFieldValue("searchText", "");
                  }
                }}
              >
                {!customersUIProps.queryParams.keyword || !values.searchText ? (
                  <SVG src="/media/members/search-icon.svg" alt="" />
                ) : (
                  <SVG
                    src="/media/members/reset-search.svg"
                    alt=""
                    style={{ transform: "scale(0.7)" }}
                  />
                )}
              </div>
              {isQrcode || isBanned ? (
                ""
              ) : (
                <Dropdown
                  overlay={() => dropdownBtn(values)}
                  trigger={["click"]}
                  placement="bottomLeft"
                >
                  <button type="button" className="button-border">
                    <span>{`${sortWording()}`}</span>
                  </button>
                </Dropdown>
              )}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
