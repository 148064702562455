import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  body: {
    textAlign: "center"
  },
  title: {
    fontSize: "20px",
    fontWeight: 600,
    color: "#d7042b"
  },
  subtitle: {
    fontFamily: "Prompt",
    fontSize: "16px"
  },
  divButton: {
    marginTop: "36px"
  },
  acceptButton: {
    margin: "8px",
    color: "#ffffff",
    fontSize: "18px",
    fontWeight: 600,
    width: "160px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "#012875",
    border: "none"
  },
  denyButton: {
    margin: "8px",
    color: "#012875",
    fontSize: "18px",
    fontWeight: 600,
    width: "160px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "#ffffff",
    border: "solid 1px #012875"
  }
}));
