import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ModalProgressBar } from '../../../../../../_metronic/_partials/controls';
import { useCompaniesUIContext } from '../CompaniesUIContext';
import * as actions from '../../../_redux/companies/companiesActions';
// import { deleteCompany } from "../../../_redux/companies/companiesCrud";
import { makeStyles } from '@material-ui/core/styles';
import {
    ClientTable,
    ClientProcessStatus
} from '../../../../../../interface/company';
import { message } from 'antd';

const useStyles = makeStyles(() => ({
    body: {
        textAlign: 'center'
    }
}));

export function CompanyClearStoreDialog({
    name,
    id,
    isPrivate,
    show,
    onHide,
    clearType
}) {
    // Companies UI Context
    const [isSubmitting, setIsSubmitting] = useState(false);
    const companiesUIContext = useCompaniesUIContext();
    const companiesUIProps = useMemo(() => {
        return {
            setIds: companiesUIContext.setIds,
            queryParams: companiesUIContext.queryParams
        };
    }, [companiesUIContext]);

    // Companies Redux state
    const dispatch = useDispatch();
    const { isLoading, companiesState } = useSelector(
        state => ({
            isLoading: state.companies.actionsLoading,
            companiesState: state.companies
        }),
        shallowEqual
    );

    // if !id we should close modal
    useEffect(() => {
        if (!id) {
            onHide();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // looking for loading/dispatch
    useEffect(() => {}, [isLoading, dispatch]);

    const getClearTypeThai = () => {
        switch (clearType) {
            case 'member': {
                return 'เคลียร์สมาชิก';
            }
            case 'store': {
                return 'เคลียร์สโตร์';
            }
            default:
                return '-';
        }
    };

    const clearStore = () => {
        localStorage.setItem('clearStore', 3);
        if (clearType === 'store') {
            setIsSubmitting(true);
            dispatch(
                actions.clearAllData(
                    id,
                    ClientTable.clientStore,
                    ClientProcessStatus.ClearStore
                )
            ).then(res => {
                setIsSubmitting(false);
                companiesUIProps.setIds([]);
                if (res?.status) {
                    message.success('เคลียร์สโตร์สำเร็จ');
                }
                onHide();
            });
        }

        if (clearType === 'member') {
            const backup =
                process.env.REACT_APP_ENV === 'development' ? false : true;
            setIsSubmitting(true);
            dispatch(
                actions.clearAllData(
                    id,
                    ClientTable.member,
                    ClientProcessStatus.ClearMember,
                    backup
                )
            ).then(response => {
                setIsSubmitting(false);
                companiesUIProps.setIds([]);
                if (response?.status) {
                    message.success('เคลียร์สมาชิกสำเร็จ');
                }
                onHide();
            });
        }
    };

    const classes = useStyles();

    return (
        <Modal
            backdrop="static"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
            style={{ margin: '3% auto' }}
        >
            {isLoading && <ModalProgressBar />}
            <Modal.Body className={classes.body}>
                {name ? (
                    <>
                        <img src="/media/members/image-modal.svg" alt="" />
                        <h2 className="header-red">ยืนยันการเคลียร์สโตร์</h2>
                        <div className="detail-text">
                            ต้องการ {getClearTypeThai()}{' '}
                            <span class="bold">{name}</span> หรือไม่?
                        </div>

                        <div className="divButton">
                            <button
                                type="button"
                                onClick={onHide}
                                className="denyButton"
                            >
                                ยกเลิก
                            </button>
                            <button
                                type="button"
                                onClick={clearStore}
                                className="acceptButton"
                                disabled={isSubmitting}
                            >
                                ยืนยัน
                            </button>
                        </div>
                    </>
                ) : null}
            </Modal.Body>
        </Modal>
    );
}
//
