// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Checkbox } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../_redux/customers/customersActions';

export const IndexColumnFormatter = (
   cellContent,
   row,
   rowIndex,
   { pageNumber, pageSize, callback }
) => {
   const onChageCheckbox = e => {
      console.log(e.target.checked, row.memberUID);
      callback(e.target.checked, row.memberUID);
   };

   return (
      <div>
         {/* <Checkbox onChange={onChageCheckbox}>
            {rowIndex + 1 + (pageNumber - 1) * pageSize}
         </Checkbox> */}

         <p style={{ marginBottom: '0', textAlign: 'center' }}>
            {rowIndex + 1 + (pageNumber - 1) * pageSize}
         </p>
      </div>
   );
};
