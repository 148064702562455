import { useMemo, useState } from 'react';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import { useEbooksUIContext } from '../EbooksUIContext';
import { makeStyles } from '@material-ui/core';
import axios from 'axios';
import * as actions from '../../../_redux/ebooks/ebooksActions';
import { useDispatch } from 'react-redux';
import { Menu, Dropdown, Button } from 'antd';
import SVG from 'react-inlinesvg';

const useStyles = makeStyles(() => ({
    divButton: {
        marginLeft: '8px'
    },
    inactive: {
        border: 'none',
        width: '64px',
        height: '30px',
        color: '#828282',
        backgroundColor: 'transparent'
    },
    active: {
        border: 'none',
        width: '64px',
        height: '28px',
        borderRadius: '100px',
        backgroundColor: '#012875',
        color: '#ffffff'
    }
}));

const prepareFilter = (queryParams, values) => {
    const { status, type, searchText, memberMode } = values;
    const newQueryParams = { ...queryParams };
    console.log(status);
    const filter = {};
    // Filter by status
    filter.status = status !== '' ? +status : undefined;
    // Filter by type
    filter.type = type !== '' ? +type : undefined;
    // Filter by all fields
    filter.lastName = searchText;
    // filter.firstName = searchText;
    // filter.email = searchText;
    // filter.ipAddress = searchText;
    newQueryParams.keyword = searchText;
    newQueryParams.memberMode = memberMode === 0 ? '' : memberMode;
    return newQueryParams;
};

export function EbooksFilter({ listLoading, isPrivate, isFree }) {
    // Ebooks UI Context
    const dispatch = useDispatch();
    const [memberMode, setMemberMode] = useState(0);
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const ebooksUIContext = useEbooksUIContext();
    const ebooksUIProps = useMemo(() => {
        return {
            queryParams: ebooksUIContext.queryParams,
            setQueryParams: ebooksUIContext.setQueryParams
        };
    }, [ebooksUIContext]);

    // queryParams, setQueryParams,
    const applyFilter = values => {
        const newQueryParams = prepareFilter(ebooksUIProps.queryParams, values);
        console.log(newQueryParams, ebooksUIProps.queryParams);
        console.log(!isEqual(newQueryParams, ebooksUIProps.queryParams));
        if (!isEqual(newQueryParams, ebooksUIProps.queryParams)) {
            newQueryParams.pageNumber = 1;
            // update list by queryParams
            ebooksUIProps.setQueryParams(newQueryParams);
        }
    };

    const sortWording = () => {
        switch (memberMode) {
            case 0:
                return 'ทั้งหมด';
            case '1':
                return 'ใช้งาน';
            case '2':
                return 'บล็อก';
            default:
                break;
        }
    };

    const dropdownBtn = values => (
        <Menu>
            <Menu.Item
                key="0"
                onClick={() => {
                    setMemberMode(0);
                    applyFilter(Object.assign({}, values, { memberMode: 0 }));
                }}
            >
                <span className="dropdown-text no-pic">ทั้งหมด</span>
            </Menu.Item>
            <Menu.Item
                key="1"
                onClick={() => {
                    setMemberMode('1');
                    applyFilter(Object.assign({}, values, { memberMode: '1' }));
                }}
            >
                <span className="dropdown-text no-pic">ใช้งาน</span>
            </Menu.Item>
            <Menu.Item
                key="2"
                onClick={() => {
                    setMemberMode('2');
                    applyFilter(Object.assign({}, values, { memberMode: '2' }));
                }}
            >
                <span className="dropdown-text no-pic">บล็อก</span>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Formik
                initialValues={{
                    status: '', // values => All=""/Suspended=0/Active=1/Pending=2
                    type: '', // values => All=""/Business=0/Individual=1
                    searchText: ''
                }}
                onSubmit={values => {
                    applyFilter(
                        Object.assign({}, values, { memberMode: memberMode })
                    );
                }}
            >
                {({
                    values,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    setFieldValue
                }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="form form-label-right search-box"
                    >
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                name="searchText"
                                placeholder="ค้นหา"
                                onBlur={handleBlur}
                                value={values.searchText}
                                onChange={e => {
                                    setFieldValue('searchText', e.target.value);
                                }}
                                onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                        handleSubmit();
                                    }
                                }}
                            />
                            <div
                                className="icon-search"
                                onClick={() => {
                                    if (
                                        !ebooksUIProps.queryParams.keyword ||
                                        !values.searchText
                                    ) {
                                        handleSubmit();
                                    } else {
                                        applyFilter(
                                            Object.assign({}, values, {
                                                searchText: '',
                                                memberMode: memberMode
                                            })
                                        );
                                        setFieldValue('searchText', '');
                                    }
                                }}
                            >
                                {!ebooksUIProps.queryParams.keyword ||
                                !values.searchText ? (
                                    <SVG
                                        src="/media/members/search-icon.svg"
                                        alt=""
                                    />
                                ) : (
                                    <SVG
                                        src="/media/members/reset-search.svg"
                                        alt=""
                                        style={{ transform: 'scale(0.7)' }}
                                    />
                                )}
                            </div>

                            <Dropdown
                                overlay={() => dropdownBtn(values)}
                                trigger={['click']}
                                placement="bottomLeft"
                            >
                                <button type="button" className="button-border">
                                    <span>{sortWording()}</span>
                                </button>
                            </Dropdown>
                            {isPrivate ? (
                                <Button
                                    // type="link"
                                    className="button-blue"
                                    onClick={() => {
                                        setLoadingSpinner(true);
                                        const reqFunc = async () => {
                                            const req = await axios.get(
                                                `/admin/private/contents/verify`
                                            );
                                            if (req.data.status) {
                                                setLoadingSpinner(false);
                                                dispatch(
                                                    actions.fetchEbooks(
                                                        ebooksUIProps.queryParams,
                                                        isPrivate
                                                    )
                                                );
                                            }
                                        };
                                        reqFunc();
                                    }}
                                    loading={loadingSpinner}
                                >
                                    ตรวจสอบไฟล์ทั้งหมด
                                </Button>
                            ) : (
                                ''
                            )}
                        </div>
                    </form>
                )}
            </Formik>
        </>
    );
}
