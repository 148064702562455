// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import { Switch } from "antd";
import { Role } from "../../../../../../../interface";

export function StatusColumnFormatter(
  cellContent,
  row,
  rowIndex,
  {
    openBlockPodcastDialog,
    openUnblockPodcastDialog,
    isPrivate,
    role,
    setIsError,
  }
) {
  const onSwitchChange = () => {
    console.log(cellContent ,isPrivate);
    if (cellContent === 1) {
      openBlockPodcastDialog(row.title, row.contentUID, isPrivate);
    } else if (
      (cellContent === 2 && isPrivate) ||
      (cellContent === 0 && !isPrivate)
    ) {
      openUnblockPodcastDialog(row.title, row.contentUID, isPrivate);
    } else if (cellContent === 4 || cellContent === 5 || cellContent === 6) {
      setIsError("fixFileFirst");
    }
  };
  const textOfStatus = () => {
    return cellContent === 1 ? "เปิดใช้งาน" : "บล็อก";
  };
  return (
    <span style={{ fontSize: "14px" }}>
      {isPrivate && cellContent === 0 ? (
        "กำลังดำเนินการ"
      ) : role === Role.SuperAdmin && isPrivate ? (
        textOfStatus()
      ) : (
        <Switch
          onChange={onSwitchChange}
          checked={cellContent === 1 ? true : false}
          checkedChildren="เปิด"
          unCheckedChildren="ปิด"
        />
      )}
    </span>
  );
}
