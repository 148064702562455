import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/podcasts/podcastsActions";
import { usePodcastsUIContext } from "../PodcastsUIContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
     body: {
          textAlign: "center",
     },
}));

export function PodcastDeleteDialog({ name, id, episodeId, isPrivate, show, onHide }) {
     // Podcasts UI Context
     const podcastsUIContext = usePodcastsUIContext();
     const podcastsUIProps = useMemo(() => {
          return {
               setIds: podcastsUIContext.setIds,
               queryParams: podcastsUIContext.queryParams,
          };
     }, [podcastsUIContext]);

     // Podcasts Redux state
     const dispatch = useDispatch();
     const { isLoading } = useSelector((state) => ({ isLoading: state.podcasts.actionsLoading }), shallowEqual);

     // if !id we should close modal
     useEffect(() => {
          if (!id) {
               onHide();
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [id]);

     // looking for loading/dispatch
     useEffect(() => {}, [isLoading, dispatch]);

     const deletePodcast = () => {
          // server request for deleting podcast by id
          let tempId = episodeId ? episodeId : id ;
          dispatch(actions.deletePodcast( tempId, isPrivate,{forceReturn:false})).then(() => {
             
                    // refresh list after deletion
                    if(episodeId){
                         dispatch(actions.fetchPodcastEpisodes(id))
                    }else{
                         dispatch(actions.fetchPodcasts(podcastsUIProps.queryParams, isPrivate));

                    }
                    // clear selections list
                    podcastsUIProps.setIds([]);
                    // closing block modal
               
               onHide();
          });
     };
     // const deletePodcast = () => {
     //      // server request for deleting podcast by id
     //      dispatch(actions.deletePodcast(episodeId || id, isPrivate,{forceReturn:false})).then(() => {
     //           if (episodeId) {
     //                dispatch(actions.fetchPodcastEpisodes(id));
     //           } else {
     //                // refresh list after deletion
     //                dispatch(actions.fetchPodcasts(podcastsUIProps.queryParams, isPrivate));
     //                // clear selections list
     //                podcastsUIProps.setIds([]);
     //                // closing block modal
     //           }
     //           onHide();
     //      });
     // };

     const classes = useStyles();

     return (
          <Modal
               backdrop="static"
               show={show}
               onHide={onHide}
               aria-labelledby="example-modal-sizes-title-lg"
               style={{ margin: "3% auto" }}
          >
               {isLoading && <ModalProgressBar />}
               <Modal.Body className={classes.body}>
                    {name ? (
                         <>
                              <img src="/media/members/image-modal.svg" alt="" />
                              <div className="text-text-red">ยืนยันการลบ</div>
                              <div className="text-text-gray">ต้องการลบ {name} หรือไม่?</div>

                              <div className="mt-3">
                                   <button type="button" onClick={onHide} className="denyButton">
                                        ยกเลิก
                                   </button>
                                   <button type="button" 
                                   onClick={deletePodcast} 
                                   className="acceptButton">
                                        ยืนยัน 
                                   </button>
                              </div>
                         </>
                    ) : (
                         ""
                    )}
               </Modal.Body>
          </Modal>
     );
}
