/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/publishers/publishersActions";
import { PublisherEditForm } from "./PublisherEditForm";
import { usePublishersUIContext } from "../PublishersUIContext";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
     body: { margin: "auto" },
     divSuccess: {
          textAlign: "center",
          width: "340px",
          height: "360px",
          borderRadius: "10px",
          backgroundColor: "#fff",
     },
}));

export function PublisherEditDialog({ name, id, publisherId, authorId, isPrivate, show, onHide }) {
     // Publishers UI Context
     const [successModal, setSuccessModal] = useState(false);
     const publishersUIContext = usePublishersUIContext();
     const publishersUIProps = useMemo(() => {
          return {
               initPublisher: publishersUIContext.initPublisher,
          };
     }, [publishersUIContext]);
     const [isAuthor, setIsAuthor] = useState(false);

     useEffect(() => {
          if (publisherId && authorId) {
               setIsAuthor(true);
          }
     }, [publisherId, authorId]);

     const styles = useStyles();

     // Publishers Redux state
     const dispatch = useDispatch();
     const { actionsLoading, authorsForEdit, entities } = useSelector(
          (state) => ({
               actionsLoading: state.publishers.actionsLoading,
               authorsForEdit: state.publishers.authorsForEdit,
               entities: state.publishers.entities,
          }),
          shallowEqual
     );
     const [authorForEdit, setAuthorForEdit] = useState({});
     const [hasId, setHasId] = useState(false);
     useEffect(() => {
          if (id) {
               setHasId(true);
               if (entities && entities.find((el) => el.uid === id)) {
                    setAuthorForEdit(entities.find((el) => el.uid === id));
               }
          } else if (authorId) {
               setHasId(true);
               if (authorsForEdit && authorsForEdit.find((el) => el.uid === authorId)) {
                    setAuthorForEdit(authorsForEdit.find((el) => el.uid === authorId));
               }
          }
     }, [id, dispatch, entities, authorId]);

     // server request for saving publisher
     const savePublisher = (publisher) => {
          const newOne = { name: publisher.name };
          const updatedOne = { name: publisher.name, status: publisher.status, uid: id };
          if (!id) {
               // server request for creating publisher
               dispatch(actions.createPublisher(newOne, isPrivate, setSuccessModal));
          } else {
               // server request for updating publisher
               dispatch(actions.updatePublisher(updatedOne, isPrivate, setSuccessModal));
          }
     };

     const saveAuthor = (author) => {
          const updatedOne = {
               name: author.name,
               status: author.status,
               publisherId: publisherId,
               authorId: authorId,
          };
          // server request for deleting category by id
          dispatch(actions.updateAuthor(updatedOne, isPrivate, setSuccessModal)).then(() => {
               // refresh list after deletion
               dispatch(actions.fetchAuthors("none", publisherId, isPrivate));
               // closing delete modal
               // onHide();
          });
     };

     return (
          <Modal
               backdrop="static"
               size="md"
               show={show}
               onHide={onHide}
               style={{ marginTop: "27px" }}
               aria-labelledby="example-modal-sizes-title-md"
               dialogClassName={successModal ? styles.divSuccess : ""}
          >
               {successModal ? (
                    <Modal.Body className={styles.body}>
                         <div>
                              <img src="/media/bg/success.svg" alt="" />
                         </div>
                         <div className="divText-modal">เรียบร้อยแล้ว</div>
                         <div>
                              <button
                                   className="acceptButton "
                                   onClick={() => {
                                        onHide();
                                        setTimeout(() => {
                                             setSuccessModal(false);
                                        }, 200);
                                   }}
                              >
                                   ตกลง
                              </button>
                         </div>
                    </Modal.Body>
               ) : (
                    <PublisherEditForm
                         savePublisher={savePublisher}
                         saveAuthor={saveAuthor}
                         actionsLoading={actionsLoading}
                         publisher={(hasId && authorForEdit) || publishersUIProps.initPublisher}
                         onHide={onHide}
                         isAuthor={isAuthor}
                         publisherList={entities}
                    />
               )}
          </Modal>
     );
}
