import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import * as columnFormatters from "./customers-table/column-formatters";
import { useCustomersUIContext } from "./CustomersUIContext";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "./CustomersUIHelpers";
import {
  getMemberBanned,
  unBanMember,
} from "../../_redux/customers/customersCrud";
import { Empty, Tag } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import CustomerBannedModal from "./customer-banned-modal/CustomerUnbannedModal";
import { NodataView, LoaderView } from "../../../CustomView";
import SVG from "react-inlinesvg";
import { CustomersFilter } from "./customers-filter/CustomersFilter";

function CustomerBanned() {
  const [memberBanned, setMemberBanned] = useState();
  useEffect(() => {
    getMemberBanned().then((res) => {
      console.log(res.data.data.members);
      setMemberBanned(res.data.data.members);
    });
  }, []);

  const { currentState, auth } = useSelector(
    (state) => ({ currentState: state.customers, auth: state.auth }),
    shallowEqual
  );

  const [isShowModal, setIsShowModal] = useState(false);
  const [rowSelect, setRowSelect] = useState();
  // const { listLoading } = currentState;
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
      openBlockCustomerDialog: customersUIContext.openBlockCustomerDialog,
      openUnblockCustomerDialog: customersUIContext.openUnblockCustomerDialog,
      openBannedCustomerDialog: customersUIContext.openBannedCustomerDialog,
      openResetPwCustomerDialog: customersUIContext.openResetPwCustomerDialog,
      openCustomerDevicesDialog: customersUIContext.openCustomerDevicesDialog,
    };
  }, [customersUIContext]);
  // const isNotFound = () => {
  //   return (
  //     memberBanned &&
  //     Array.isArray(memberBanned) &&
  //     memberBanned.length === 0 &&

  //   );
  // };
  const unBan = async () => {
    let res = await unBanMember({ memberUIDList: [rowSelect.memberUID] });
    if (res.data.status === true) {
      setIsShowModal(false);
      getMemberBanned().then((res) => {
        console.log(res.data.data.members);
        if (res.status && res.data) {
          setMemberBanned(res.data.data.members);
        }
      });
    }
  };
  const columns = [
    {
      dataField: "none",
      text: "ลำดับ",
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.IndexColumnFormatter,
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return <div>{column.text}</div>;
      },
      formatExtraData: {
        pageNumber: customersUIProps.queryParams.pageNumber,
        pageSize: customersUIProps.queryParams.pageSize,
      },
    },
    {
      dataField: "staffID",
      text: "รหัสพนักงาน",
      // sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.StaffIdColumnFormatter,
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return <div>{column.text}</div>;
      },
    },
    {
      dataField: "name",
      text: "ชื่อ",
      // sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return <div>{column.text}</div>;
      },
    },
    {
      dataField: "surname",
      text: "นามสกุล",
      sortCaret: sortCaret,
      headerSortingClasses,
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return <div>{column.text}</div>;
      },
    },
    {
      dataField: "email",
      text: "อีเมล",
      sortCaret: sortCaret,
      headerSortingClasses,
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return <div>{column.text}</div>;
      },
    },
    {
      dataField: "memberType",
      text: "",
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-center",
      formatter: (value) => {
        return (
          <Tag
            style={{ margin: "auto" }}
            color={value === "admin" ? "magenta" : "blue"}
          >
            {value}
          </Tag>
        );
      },
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return <div>{column.text}</div>;
      },
    },
    {
      dataField: "devices",
      text: "อุปกรณ์",
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
      headerSortingClasses,
      align: "center",
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return <div>{column.text}</div>;
      },
    },
    {
      dataField: "action",
      text: "การจัดการ",
      formatter: myActionFormat,
      formatExtraData: {
        setIsShowModal,
        setRowSelect,
      },
      classes: "text-right",
      headerClasses: "text-right pr-3",
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        return <div>{column.text}</div>;
      },
    },
  ];
  return (
    <div>
      <CustomerBannedModal
        email={rowSelect?.email ? rowSelect.email : ""}
        isShow={isShowModal}
        setIsShowModal={setIsShowModal}
        onConfirm={unBan}
      />
      <Card className="cardStyle">
        <CardHeader
          title={
            //  className={classes.div}
            <div
              className="card-label"
              //   style={role === Role.superadmin ? { marginTop: "0" } : {}}
            >
              <div>{"สมาชิกที่ถูกแบน"}</div>
              {/* {role === Role.superadmin || isBanned ? (
                ""
              ) : (
                <div>
                  จำนวนสมาชิก{" "}
                  {memberAmountData
                    ? memberAmountData.currentMember?.toLocaleString()
                    : 0}
                  /
                  {memberAmountData
                    ? memberAmountData.maximumMember?.toLocaleString()
                    : 0}
                </div>
              )} */}
            </div>
          }
        >
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody className="cardBodyStyle">
          {memberBanned === undefined ? (
            <LoaderView />
          ) : memberBanned.length === 0 ? (
            <NodataView />
          ) : (
            <div className="CustomerTable ">
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes={`table table-head-custom table-vertical-center custom-table-scroll ${
                  memberBanned.length === 0 ? "isNotFound" : ""
                }`}
                id="custom-table-scroll"
                bootstrap4
                remote
                keyField="memberUID"
                data={!Array.isArray(memberBanned) ? [] : memberBanned}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  customersUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   memberBanned,
                //   ids: customersUIProps.ids,
                //   setIds: customersUIProps.setIds,
                // })}
                // {...paginationTableProps}
              >
                <PleaseWaitMessage entities={memberBanned} />
                <NoRecordsFoundMessage entities={memberBanned} />
              </BootstrapTable>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default CustomerBanned;
export function myActionFormat(
  cellContent,
  row,
  rowIndex,
  { setIsShowModal, setRowSelect }
) {
  console.log(row);
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="quick-user-tooltip">{"ปลดบล็อกผู้ใช้"}</Tooltip>}
    >
      <a
        className="btn btn-icon btn-light btn-hover-primary btn-sm"
        style={{
          backgroundColor: "#ff5337",
        }}
        onClick={() => {
          setRowSelect(row);
          setIsShowModal(true);
          console.log(row);
        }}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG src={toAbsoluteUrl(`/media/members/${"unblock"}-button.svg`)} />
        </span>
      </a>
    </OverlayTrigger>
  );
}
