/* eslint-disable react-hooks/exhaustive-deps */
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../_redux/customers/qrcodesActions';
import { ClipLoader } from 'react-spinners';
import {
   getHandlerTableChange,
   NoRecordsFoundMessage,
   PleaseWaitMessage,
   sortCaret,
   headerSortingClasses
} from '../../../../../../_metronic/_helpers';
import * as uiHelpers from '../CustomersUIHelpers';
import * as columnFormatters from './column-formatters';
import { useCustomersUIContext } from '../CustomersUIContext';
import { NodataView, LoaderView } from '../../../../CustomView';

export function QrcodesTable() {
   // Customers UI Context
   const customersUIContext = useCustomersUIContext();
   const customersUIProps = useMemo(() => {
      return {
         ids: customersUIContext.ids,
         setIds: customersUIContext.setIds,
         queryParams: customersUIContext.queryParams,
         setQueryParams: customersUIContext.setQueryParams,
         openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
         openDeleteQrcodeDialog: customersUIContext.openDeleteQrcodeDialog,
         openBlockCustomerDialog: customersUIContext.openBlockCustomerDialog,
         openUnblockCustomerDialog:
            customersUIContext.openUnblockCustomerDialog,
         openBannedCustomerDialog: customersUIContext.openBannedCustomerDialog,
         openResetPwCustomerDialog:
            customersUIContext.openResetPwCustomerDialog,
         openCustomerDevicesDialog:
            customersUIContext.openCustomerDevicesDialog,
         openViewQrcodeDialog: customersUIContext.openViewQrcodeDialog,
         openEditQrcodeDialog: customersUIContext.openEditQrcodeDialog,
         openViewQrcodeMembersPage: customersUIContext.openViewQrcodeMembersPage
      };
   }, [customersUIContext]);

   // Getting current state of customers list from store (Redux)
   const { currentState, role } = useSelector(
      state => ({ currentState: state.qrcodes, role: state.auth.role }),
      shallowEqual
   );
   const { entities, listLoading, LastEvaluatedKey, error } = currentState;
   const [isEmpty, setEmpty] = useState(false);
   const loaderHeight = 50;
   const footerHeight = 300;
   const [loadingBottom, setLoadingBottom] = useState(false);

   // const isBottom = () => {
   //   let scrollHeight = Math.max(
   //     document.body.scrollHeight,
   //     document.documentElement.scrollHeight,
   //     document.body.offsetHeight,
   //     document.documentElement.offsetHeight,
   //     document.body.clientHeight,
   //     document.documentElement.clientHeight
   //   );
   //   return (
   //     window.pageYOffset + window.innerHeight >= scrollHeight - 3 * loaderHeight - footerHeight
   //   );
   // };

   const trackScrolling = () => {
      if (isBottom()) {
         setLoadingBottom(true);
      }
   };

   // useEffect(() => {
   //   document.addEventListener("scroll", trackScrolling);
   //   // eslint-disable-next-line
   // }, []);

   // useEffect(() => {
   //   return () => {
   //     document.removeEventListener("scroll", trackScrolling);
   //   };
   //   // eslint-disable-next-line
   // }, []);

   useEffect(() => {
      if (
         LastEvaluatedKey === undefined ||
         JSON.stringify(LastEvaluatedKey) === JSON.stringify({})
      ) {
         setEmpty(true);
      } else if (loadingBottom && !listLoading) {
         setEmpty(false);
         dispatch(
            actions.fetchQrcodes(
               customersUIProps.queryParams,
               LastEvaluatedKey,
               setLoadingBottom
            )
         );
      }
   }, [LastEvaluatedKey, loadingBottom, listLoading]);

   // Customers Redux state
   const dispatch = useDispatch();
   useEffect(() => {
      // clear selections list
      customersUIProps.setIds([]);
      // server call by queryParams
      dispatch(actions.fetchQrcodes(customersUIProps.queryParams));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [customersUIProps.queryParams, dispatch]);

   const [loadingCount, setLoadingCount] = useState(0);
   const minWidthOfAll = 900;
   const minusOfAll = 380;
   const [tableWidth, setTableWidth] = useState(
      Math.max(
         document.body.scrollWidth,
         document.documentElement.scrollWidth,
         document.body.offsetWidth,
         document.documentElement.offsetWidth,
         document.documentElement.clientWidth
      ) <
         minWidthOfAll + minusOfAll
         ? minWidthOfAll
         : Math.max(
              document.body.scrollWidth,
              document.documentElement.scrollWidth,
              document.body.offsetWidth,
              document.documentElement.offsetWidth,
              document.documentElement.clientWidth
           ) - minusOfAll
   );

   useEffect(() => {
      if (entities && entities.length > -1 && loadingCount < 2) {
         setLoadingCount(loadingCount + 1);
      }
   }, [entities]);

   const isBottom = () => {
      const getScroll = document
         .getElementById('custom-table-scroll')
         .getElementsByTagName('tbody')[0];
      const offsetHeight = Math.ceil(getScroll.offsetHeight);
      const scrollTop = Math.ceil(getScroll.scrollTop);
      if (
         !listLoading &&
         getScroll.scrollHeight - (scrollTop + offsetHeight) <= 1
      ) {
         return true;
      } else {
         return false;
      }
   };

   const resize = () => {
      if (
         document.getElementById('custom-table-scroll') &&
         loadingCount === 2
      ) {
         setTableWidth(
            document
               .getElementById('custom-table-scroll')
               .getBoundingClientRect().width < minWidthOfAll
               ? minWidthOfAll
               : document
                    .getElementById('custom-table-scroll')
                    .getBoundingClientRect().width
         );
      }
   };

   useEffect(() => {
      window.addEventListener('resize', resize);
      if (
         document.getElementById('custom-table-scroll') &&
         document
            .getElementById('custom-table-scroll')
            .getElementsByTagName('tbody')[0] &&
         loadingCount === 2
      ) {
         document
            .getElementById('custom-table-scroll')
            .getElementsByTagName('tbody')[0]
            .addEventListener('scroll', trackScrolling);
      }
      // eslint-disable-next-line
   }, [loadingCount]);

   useEffect(() => {
      return () => {
         window.removeEventListener('resize', resize);
         setLoadingCount(0);
         if (
            document.getElementById('custom-table-scroll') &&
            document
               .getElementById('custom-table-scroll')
               .getElementsByTagName('tbody')[0]
         ) {
            document
               .getElementById('custom-table-scroll')
               .getElementsByTagName('tbody')[0]
               .removeEventListener('scroll', trackScrolling);
         }
      };
      // eslint-disable-next-line
   }, []);

   const widthOfAll = 55 + 260 + 260 + 130 + 330;
   // Table columns
   const columns = [
      {
         dataField: 'none',
         text: 'ลำดับ',
         sortCaret: sortCaret,
         headerSortingClasses,
         formatter: columnFormatters.IndexColumnFormatter,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
               // style={{
               //   textAlign: "center",
               //   maxWidth: `${(55 / widthOfAll) * tableWidth}px`,
               //   minWidth: `${(55 / widthOfAll) * tableWidth}px`,
               //   margin: "-0.75rem",
               // }}
               >
                  {column.text}
               </div>
            );
         }
         // style: {
         //   maxWidth: `${(55 / widthOfAll) * tableWidth}px`,
         //   minWidth: `${(55 / widthOfAll) * tableWidth}px`,
         // },
      },
      {
         dataField: 'name',
         text: 'ชื่อ',
         // sort: true,
         sortCaret: sortCaret,
         headerSortingClasses,
         formatter: columnFormatters.StaffIdColumnFormatter,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
               // style={{
               //   textAlign: "left",
               //   maxWidth: `${(260 / widthOfAll) * tableWidth}px`,
               //   minWidth: `${(260 / widthOfAll) * tableWidth}px`,
               //   margin: "-0.75rem",
               //   paddingLeft: "0.75rem",
               // }}
               >
                  {column.text}
               </div>
            );
         }
         // style: {
         //   maxWidth: `${(260 / widthOfAll) * tableWidth}px`,
         //   minWidth: `${(260 / widthOfAll) * tableWidth}px`,
         // },
      },
      {
         dataField: 'registerDate',
         text: 'วันที่เริ่มใช้งาน - วันที่หมดอายุ',
         sortCaret: sortCaret,
         formatter: columnFormatters.TimestampColumnFormatter,
         headerSortingClasses,
         align: 'left',
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
               // style={{
               //   textAlign: "left",
               //   maxWidth: `${(260 / widthOfAll) * tableWidth}px`,
               //   minWidth: `${(260 / widthOfAll) * tableWidth}px`,
               //   margin: "-0.75rem",
               //   paddingLeft: "0.75rem",
               // }}
               >
                  {column.text}
               </div>
            );
         }
         // style: {
         //   maxWidth: `${(260 / widthOfAll) * tableWidth}px`,
         //   minWidth: `${(260 / widthOfAll) * tableWidth}px`,
         // },
      },
      // {
      //   dataField: "qrcodeType",
      //   text: "จำนวนผู้ลงทะเบียน",
      //   // sort: true,
      //   sortCaret: sortCaret,
      //   headerSortingClasses,
      // },
      {
         dataField: 'userLimit',
         text: 'จำนวนผู้ลงทะเบียน/จำนวนผู้ใช้งานสูงสุด',
         sortCaret: sortCaret,
         formatter: columnFormatters.StatusColumnFormatter,
         headerSortingClasses,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
               // style={{
               //   textAlign: "center",
               //   maxWidth: `${(130 / widthOfAll) * tableWidth}px`,
               //   minWidth: `${(130 / widthOfAll) * tableWidth}px`,
               //   margin: "-0.75rem",
               // }}
               >
                  {column.text}
               </div>
            );
         }
         // style: {
         //   maxWidth: `${(130 / widthOfAll) * tableWidth}px`,
         //   minWidth: `${(130 / widthOfAll) * tableWidth}px`,
         // },
      },
      {
         dataField: 'action',
         text: 'การจัดการ',
         formatter: columnFormatters.ActionsColumnFormatter,
         formatExtraData: {
            openViewQrcodeDialog: customersUIProps.openViewQrcodeDialog,
            openEditQrcodeDialog: customersUIProps.openEditQrcodeDialog,
            openViewQrcodeMembersPage:
               customersUIProps.openViewQrcodeMembersPage,
            openDeleteQrcodeDialog: customersUIProps.openDeleteQrcodeDialog,
            openBlockCustomerDialog: customersUIProps.openBlockCustomerDialog,
            openUnblockCustomerDialog:
               customersUIProps.openUnblockCustomerDialog,
            openBannedCustomerDialog: customersUIProps.openBannedCustomerDialog,
            openResetPwCustomerDialog:
               customersUIProps.openResetPwCustomerDialog,
            openCustomerDevicesDialog:
               customersUIProps.openCustomerDevicesDialog,
            role
         },
         // classes: "text-right",
         // headerClasses: "text-right pr-3",
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
               // style={{
               //   textAlign: "right",
               //   maxWidth: `${(330 / widthOfAll) * tableWidth}px`,
               //   minWidth: `${(330 / widthOfAll) * tableWidth}px`,
               //   margin: "-0.75rem",
               //   paddingRight: "1.5rem",
               // }}
               >
                  {column.text}
               </div>
            );
         }
         // style: {
         //   maxWidth: `${(330 / widthOfAll) * tableWidth}px`,
         //   minWidth: `${(330 / widthOfAll) * tableWidth}px`,
         // },
      }
   ];

   const isNotFound = () => {
      return (
         entities &&
         Array.isArray(entities) &&
         entities.length === 0 &&
         !listLoading
      );
   };

   // Table pagination properties
   // const paginationOptions = {
   //   custom: true,
   //   totalSize: totalCount,
   //   sizePerPageList: uiHelpers.sizePerPageList,
   //   sizePerPage: customersUIProps.queryParams.pageSize,
   //   page: customersUIProps.queryParams.pageNumber,
   // };
   return loadingCount === 2 ? (
      <>
         {/* // <PaginationProvider pagination={paginationFactory(paginationOptions)}>
    //   {({ paginationProps, paginationTableProps }) => {
    //     return (
    //       <Pagination isLoading={listLoading} paginationProps={paginationProps}> */}
         <div className="QRCodeTable">
            <BootstrapTable
               wrapperClasses="table-responsive"
               bordered={false}
               classes={`table table-head-custom table-vertical-center custom-table-scroll ${
                  isNotFound() ? 'isNotFound' : ''
               }`}
               id="custom-table-scroll"
               bootstrap4
               remote
               keyField="qrcodeUID"
               data={!Array.isArray(entities) ? [] : entities}
               columns={columns}
               defaultSorted={uiHelpers.defaultSorted}
               onTableChange={getHandlerTableChange(
                  customersUIProps.setQueryParams
               )}
               // selectRow={getSelectRow({
               //   entities,
               //   ids: customersUIProps.ids,
               //   setIds: customersUIProps.setIds,
               // })}
               // {...paginationTableProps}
            >
               <PleaseWaitMessage entities={entities} />
               <NoRecordsFoundMessage entities={entities} />
            </BootstrapTable>
         </div>
         {isNotFound() ? (
            customersUIProps.queryParams.keyword ? (
               <NodataView isSearch={true} />
            ) : (
               <NodataView />
            )
         ) : (
            ''
         )}
         {/* //       </Pagination>
    //     );
    //   }}
    // </PaginationProvider> */}
         {!error &&
         !error &&
         listLoading &&
         LastEvaluatedKey !== undefined &&
         (!isEmpty || loadingBottom) ? (
            <LoaderView></LoaderView>
         ) : // <div
         //   style={{
         //     minHeight: `${loaderHeight}px`,
         //     textAlign: "center",
         //     padding: "20px 0 0 0",
         //     width: "100%",
         //   }}
         // >
         //   <ClipLoader size={loaderHeight} color={"#123abc"} loading={true} />
         // </div>
         !error && !isEmpty ? (
            // <div style={{ minHeight: `${loaderHeight}px` }} />
            ''
         ) : (
            ''
         )}
      </>
   ) : (
      <LoaderView></LoaderView>
      // <div className="cliploader-center">
      //   <ClipLoader size={loaderHeight} color={"#123abc"} loading={true} />
      // </div>
   );
}
