// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../../../_metronic/_helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export function ActionsColumnFormatter(
   cellContent,
   row,
   rowIndex,
   { openEditBannerDialog, openDeleteBannerDialog, isPrivate }
) {
   return isPrivate ? (
      <>
         {row.status === 0 ? null : (
            <OverlayTrigger
               placement="bottom"
               overlay={
                  <Tooltip id="quick-user-tooltip">แก้ไขรายละเอียด</Tooltip>
               }
            >
               <a
                  className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                  onClick={e => {
                     e.preventDefault();
                     openEditBannerDialog(cellContent, isPrivate);
                  }}
               >
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                     <SVG
                        src={toAbsoluteUrl('/media/members/edit-button.svg')}
                     />
                  </span>
               </a>
            </OverlayTrigger>
         )}
         <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="quick-user-tooltip">ลบแบนเนอร์</Tooltip>}
         >
            <a
               className="btn btn-icon btn-light btn-hover-danger btn-sm"
               style={{ marginLeft: '0.75rem' }}
               onClick={() => {
                  openDeleteBannerDialog(cellContent, isPrivate);
               }}
            >
               <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                     src={toAbsoluteUrl('/media/members/delete-button.svg')}
                  />
               </span>
            </a>
         </OverlayTrigger>
         {/* <button
        title="View books"
        onClick={(e) => {
          e.preventDefault();
        }}
        style={{
          padding: "3px",
          width: "100px",
          height: "28px",
          borderRadius: "6px",
          backgroundColor: "#f2f2f2",
          color: "#012875",
          border: "none",
          marginLeft: "0.75rem",
        }}
      >
        รายละเอียด
      </button> */}
      </>
   ) : (
      <>
         {/* <button
        title="View banner"
        onClick={(e) => {
          e.preventDefault();
        }}
        style={{
          padding: "3px",
          width: "100px",
          height: "28px",
          borderRadius: "6px",
          backgroundColor: "#f2f2f2",
          color: "#012875",
          border: "none",
        }}
      >
        รายละเอียด
      </button> */}
      </>
   );
}
