/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/upload/uploadActions";
import { VideoEditForm } from "./VideoEditForm";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { VideoEditFileAndCover } from "./VideoEditFileAndCover";
import { LoaderViewSmall } from "../../../../CustomView";

export function VideoEditDialog({ id, show, onHide, isPrivate }) {
  // Videos Redux state
  const dispatch = useDispatch();
  const { uploadState } = useSelector(
    (state) => ({
      uploadState: state.upload,
    }),
    shallowEqual
  );
  const loaderHeight = 50;
  const [videoDetail, setVideoDetail] = useState(null);
  const [videoValue, setVideoValue] = useState(null);
  const [rentalPeriod, setRentalPeriod] = useState([]);
  const [fixFileModal, setFixFileModal] = useState(false);
  const [modalType, setModalType] = useState(null);

  const [languageList, setLanguageList] = useState([
    <option value="th">th-ไทย</option>,
    <option value="en">en-อังกฤษ</option>,
  ]);

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
      setVideoDetail(null);
    } else {
      dispatch(actions.fetchNewPrivateContentData()); //rental period dropdown
      const reqFunc = async () => {
        try {
          const req = await axios.get(
            `/admin${isPrivate ? "/private/contents" : "/contents"}/${id}/edit`
          );
          const { data } = req;
          if (data.status && data.data) {
            setVideoDetail(data.data);
          }
        } catch (err) {
          onHide();
        }
      };
      const fetchLangFunc = async () => {
        const req = await axios.get("/admin/contents/languages");
        if (req.data.status && req.data.data) {
          setLanguageList(
            req.data.data.languages.map((el, index) => (
              <option key={index} value={el.value}>
                {el.label}
              </option>
            ))
          );
        }
      };
      fetchLangFunc();
      reqFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setRentalPeriod(uploadState.rentalPeriod);
  }, [uploadState.rentalPeriod]);

  useEffect(() => {
    if (videoDetail) {
      const values = videoDetail;
      const originValues = {
        name: values.title,
        channelValue: values.creatorUID,
        creatorValue: values.writerUID,
        categoryValue: values.catId,
        subCategoryValue: values.subcatId,
        startDate: values.startDate,
        endDate: values.expireDate,
        numLicenses: values.concurrent > 0 ? values.concurrent : undefined,
        language: values.language,
        tags: values.tags,
        rentalPeriodValue: values.rentalPeriod,
        anonymousRentalPeriodValue: values.anonymousRentalPeriod,
        description: values.description,
        concurrent: values.concurrent,
        activeLicense: values.activeLicense,
        file: values.file,
      };
      setVideoValue(originValues);
    }
  }, [videoDetail]);

  return fixFileModal ? (
    <VideoEditFileAndCover
      modalType={modalType}
      onHide={() => onHide()}
      contentUIDForUpdate={id}
      fixFileOnly={true}
    />
  ) : (
    <Modal
      backdrop="static"
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
    <Modal.Body>
      {videoDetail ? (
        <>
          <h2 className="center">
            แก้ไขรายละเอียด
          </h2>
          <VideoEditForm
            videoOrigin={videoDetail}
            videoValue={Object.assign(
              {},
              {
                id: undefined,
                name: "",
              },
              videoValue
            )}
            rentalPeriod={rentalPeriod}
            contentUIDForUpdate={id}
            onHideOutside={onHide}
            setVideoValue={setVideoValue}
            setVideoDetail={setVideoDetail}
            isPrivate={isPrivate}
            languageList={languageList}
          />
        </>
      ) : (
        <LoaderViewSmall />
      )}
      </Modal.Body>
    </Modal>
  );
}
