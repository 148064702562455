import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/videos/videosActions";
import { useVideosUIContext } from "../VideosUIContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
     body: {
          textAlign: "center",
     },
}));

export function VideoDeleteDialog({ name, id, isPrivate, show, onHide }) {
     // Videos UI Context
     const videosUIContext = useVideosUIContext();
     const videosUIProps = useMemo(() => {
          return {
               setIds: videosUIContext.setIds,
               queryParams: videosUIContext.queryParams,
          };
     }, [videosUIContext]);

     // Videos Redux state
     const dispatch = useDispatch();
     const { isLoading } = useSelector((state) => ({ isLoading: state.videos.actionsLoading }), shallowEqual);

     // if !id we should close modal
     useEffect(() => {
          if (!id) {
               onHide();
          }

          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [id]);

     // looking for loading/dispatch
     useEffect(() => {}, [isLoading, dispatch]);

     const deleteVideo = () => {
          // server request for deleting video by id
          dispatch(actions.deleteVideo(id, isPrivate,{forceReturn:false})).then(() => {
               // refresh list after deletion
               dispatch(actions.fetchVideos(videosUIProps.queryParams, isPrivate));
               // clear selections list
               videosUIProps.setIds([]);
               // closing block modal
               onHide();
          });
     };

     const classes = useStyles();

     return (
          <Modal
               backdrop="static"
               show={show}
               onHide={onHide}
               aria-labelledby="example-modal-sizes-title-lg"
               style={{ margin: "3% auto" }}
          >
               {isLoading && <ModalProgressBar />}
               <Modal.Body className={classes.body}>
                    {name ? (
                         <>
                              <img src="/media/members/image-modal.svg" alt="" />
                              <div className="text-text-red">ยืนยันการลบ</div>
                              <div className="text-text-gray">ต้องการลบ {name} หรือไม่?</div>

                              <div className="mt-3">
                                   <button type="button" onClick={onHide} className="denyButton">
                                        ยกเลิก
                                   </button>
                                   <button type="button" onClick={deleteVideo} className="acceptButton">
                                        ยืนยัน
                                   </button>
                              </div>
                         </>
                    ) : (
                         ""
                    )}
               </Modal.Body>
          </Modal>
     );
}
