import React, { useState, useEffect } from "react";
import { CustomersLoadingDialog } from "./customers-loading-dialog/CustomersLoadingDialog";
import { CustomersUIProvider } from "./CustomersUIContext";
import { QrcodesCard } from "./QrcodesCard";
import { QrcodeMemberMapDialog } from "./qrcode-member-map-dialog/QrcodeMemberMapDialog";
import queryString from "query-string";
import LoadScriptOnlyIfNeeded, { libraries } from "./LoadScriptOnlyIfNeeded";
import { googleMapsApiKey } from "../../../../../_metronic/_helpers/GoogleMapsApiKey";

export function QrcodeMembersPage(props) {
  const { history, location } = props;
  const [qrcodeId, setQrcodeId] = useState(null);
  const [rowIndex, setRowIndex] = useState(null);
  const [everyNode, setEveryNode] = useState(false);
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.id) {
      setQrcodeId(parsed.id);
    }
    if (parsed.rowIndex) {
      setRowIndex(parsed.rowIndex);
    }
    if (parsed.everyNode) {
      setEveryNode(true);
    }
  }, [location.search]);
  const customersUIEvents = {
    newCustomerButtonClick: () => {
      history.push("/qrcodes/new");
    },
    newQRCodeButtonClick: () => {
      history.push("/qrcodes/new/qrcode");
    },
    openEditCustomerDialog: (id) => {
      history.push(`/qrcodes/${id}/edit`);
    },
    openDeleteQrcodeDialog: (UID, name) => {
      history.push(`/qrcodes/${encodeURIComponent(name)}/delete`);
    },
    openBlockCustomerDialog: (id, email) => {
      history.push(`/qrcodes/${id}/${email}/block`);
    },
    openUnblockCustomerDialog: (id, email) => {
      history.push(`/qrcodes/${id}/${email}/unblock`);
    },
    openBannedCustomerDialog: (email) => {
      history.push(`/qrcodes/${email}/banned`);
    },
    openResetPwCustomerDialog: (id) => {
      history.push(`/qrcodes/${id}/resetpw`);
    },
    openDeleteCustomersDialog: () => {
      history.push(`/qrcodes/deleteCustomers`);
    },
    openFetchCustomersDialog: () => {
      history.push(`/qrcodes/fetch`);
    },
    openUpdateCustomersStatusDialog: () => {
      history.push("/qrcodes/updateStatus");
    },
    openCustomerDevicesDialog: (devices, UID, staffID, name) => {
      history.push(`/qrcodes/${staffID}/${encodeURIComponent(name)}/devices`);
    },
    openViewQrcodeDialog: (id) => {
      history.push(`/qrcodes/${id}/view`);
    },
    openEditQrcodeDialog: (id) => {
      history.push(`/qrcodes/${id}/edit`);
    },
    openViewQrcodeMembersPage: (id) => {
      history.push(`/qrcode-members?id=${id}`);
    },
    openQrcodeMembersMapDialog: (rowIndex, everyNode) => {
      history.push(
        `/qrcode-members?id=${qrcodeId}&rowIndex=${rowIndex}${everyNode ? `&everyNode=true` : ""}`
      );
    },
  };

  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <CustomersLoadingDialog />
      {rowIndex ? (
        <LoadScriptOnlyIfNeeded googleMapsApiKey={googleMapsApiKey} libraries={libraries}>
          <QrcodeMemberMapDialog
            show={true}
            rowIndex={rowIndex}
            onHide={() => {
              history.push(`/qrcode-members?id=${qrcodeId}`);
              setRowIndex(null);
              setEveryNode(false);
            }}
            everyNode={everyNode}
          />
        </LoadScriptOnlyIfNeeded>
      ) : (
        ""
      )}
      <QrcodesCard
        isMemberTable={true}
        qrcodeId={qrcodeId}
        onHide={() => history.push("/qrcodes")}
      />
    </CustomersUIProvider>
  );
}
