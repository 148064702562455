/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect, useState } from "react";
import { useUploadUIContext } from "./UploadUIContext";
import { makeStyles } from "@material-ui/core/styles";
import FileAndCover from "./FileAndCover";
import FormForFile from "./FormForFile";
import * as actions from "../../_redux/upload/uploadActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

export function UploadCard({ fromEbookPage, onHide }) {
  const [filePost, setFilePost] = useState(null);
  const [coverPost, setCoverPost] = useState(null);
  const [contentUIDToUpload, setContentUIDToUpload] = useState(undefined);
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const { currentState } = useSelector(
    (state) => ({ currentState: state.upload }),
    shallowEqual
  );
  const [numPagesForUpdate, setNumPagesForUpdate] = useState(undefined);
  const [fileType, setFileType] = useState(null);
  const [storageUsedNow, setStorageUsedNow] = useState(0);
  const [titleUsedNow, setTitleUsedNow] = useState(0);
  useEffect(() => {
    if (step === 1) {
      dispatch(actions.fetchNewPrivateContentData());
    }
  }, [step]);
  const uploadUIContext = useUploadUIContext();
  const uploadUIProps = useMemo(() => {
    return {
      initUploadValue: uploadUIContext.initUploadValue,
    };
  }, [uploadUIContext]);
  const useStyles1 = makeStyles((theme) => ({
    stepDiv_p: {
      width: "100%",
      fontSize: 20,
      color: "#012875",
      fontWeight: 600,

      marginBottom: 0,
    },
  }));
  const classes = useStyles1();
  const handleSubmit = (note) => {
    if (note === "prev") {
      setStep(step - 1);
    } else {
      if (step === 2) {
        setStep(1);
      } else {
        setStep(step + 1);
      }
    }
  };
  return (
    <>
      {/* <h2>อัปโหลดอีบุ๊ก</h2> */}
      {step === 1 ? (
        <FileAndCover
          handleSubmitOutside={handleSubmit}
          storageData={currentState.storageData}
          // storageData={{ usedStorage: 0, totalStorage: 0.007, percentage: 0.89 }}
          titleData={currentState.titleData}
          setNumPagesForUpdate={setNumPagesForUpdate}
          storageUsedNow={storageUsedNow}
          setStorageUsedNow={setStorageUsedNow}
          titleUsedNow={titleUsedNow}
          filePost={filePost}
          coverPost={coverPost}
          setFilePost={setFilePost}
          setCoverPost={setCoverPost}
          contentUIDToUpload={contentUIDToUpload}
          setContentUIDToUpload={setContentUIDToUpload}
          fileType={fileType}
          setFileType={setFileType}
          fromEbookPage={fromEbookPage}
        />
      ) : step === 2 ? (
        <FormForFile
          uploadValue={Object.assign({}, uploadUIProps.initUploadValue, {
            isbn: undefined,
            eisbn: undefined,
            numPages: fileType === "pdf" ? numPagesForUpdate : undefined,
          })}
          rentalPeriod={currentState.rentalPeriod}
          handleSubmitOutside={handleSubmit}
          filePost={filePost}
          coverPost={coverPost}
          setFilePost={setFilePost}
          setCoverPost={setCoverPost}
          contentUIDToUpload={contentUIDToUpload}
          setContentUIDToUpload={setContentUIDToUpload}
          setTitleUsedNow={setTitleUsedNow}
          fileType={fileType}
          fromEbookPage={fromEbookPage}
          onHide={onHide}
        />
      ) : (
        // : step === 3 ? (
        //   <Marc21 initFileValue={initFileData} handleSubmitOutside={handleSubmitttttttt} />
        // )
        ""
      )}
    </>
  );
}
