import * as requestFromServer from "./bannersCrud";
import { bannersSlice, callTypes } from "./bannersSlice";
const { actions } = bannersSlice;

export const fetchBanners = (queryParams, isPrivate, lastEvaluate, setLoadingBottom) => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findBanners(queryParams, parseInt(isPrivate, 10), lastEvaluate)
    .then((response) => {
      if (response.data.status) {
        const { LastEvaluatedKey, privateBanners } = response.data.data;
        const entities = privateBanners;
        dispatch(
          actions.bannersFetched({
            totalCount: entities.length,
            entities,
            LastEvaluatedKey,
            lastEvaluate,
            isPrivate,
          })
        );
        if (setLoadingBottom) {
          setLoadingBottom(false);
        }
      }
    })
    .catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorCode === "012"
      ) {
        alert("กรุณาเข้าสู่ระบบอีกครั้ง เนื่องจากระยะเวลาการใช้งานหมดอายุ");
        window.location.href = "/logout";
      }
      dispatch(
        actions.bannersFetched({ totalCount: 0, entities: [], LastEvaluatedKey: {}, isPrivate })
      );
      error.clientMessage = "Can't find banners";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchBanner = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.bannerFetched({ bannerForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getBannerById(id)
    .then((response) => {
      const banner = response.data;
      dispatch(actions.bannerFetched({ bannerForEdit: banner }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find banner";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteBanner = (id, isPrivate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteBanner(id, isPrivate)
    .then((response) => {
      if (response.data.status) {
        dispatch(actions.bannerDeleted({ id }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't delete banner";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const blockBanner = (id, banner, isPrivate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .blockBanner(id, banner, isPrivate)
    .then((response) => {
      if (response.data.status) {
        dispatch(actions.bannerBlocked({ id }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't block banner";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// export const createBanner = (bannerForCreation) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .createBanner(bannerForCreation)
//     .then((response) => {
//       const { banner } = response.data;
//       dispatch(actions.bannerCreated({ banner }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't create banner";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

export const createBanner = (bannerForCreation, isPrivate, onHideOutside) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createBanner(bannerForCreation, isPrivate)
    .then(() => {
      dispatch(actions.bannerCreated({ banner: bannerForCreation }));
      if (onHideOutside) {
        onHideOutside();
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't create banner";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateBanner = (banner, isPrivate, onHideOutside) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateBanner(banner, isPrivate)
    .then(() => {
      dispatch(actions.bannerUpdated({ banner }));
      if (onHideOutside) {
        onHideOutside();
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't update banner";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateBannerStatus = (id, value, isPrivate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateBannerStatus(id, value, isPrivate)
    .then(() => {
      dispatch(actions.elementUpdated());
    })
    .catch((error) => {
      error.clientMessage = "Can't update banner status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateBannersStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForBanners(ids, status)
    .then(() => {
      dispatch(actions.bannersStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update banners status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteBanners = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteBanners(ids)
    .then(() => {
      dispatch(actions.bannersDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete banners";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
