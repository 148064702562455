import React from "react";
import moment from "moment-timezone";

export function TimestampColumnFormatter(cellContent, row, rowIndex) {
  return (
    <p style={{ fontSize: "13px", marginBottom: "0", textAlign: "left" }}>
      {cellContent
        ? millisecsToDateTime(cellContent, "hr-min", "dd/mm/yyyy")
        : "-"}
    </p>
  );
}
const millisecsToDateTime = (secs, timeFormat, dateFormat) => {
  const pad = (num) => {
    return ("0" + num).slice(-2);
  };
  var momentBKK = moment.tz(secs * 1000, "Asia/Bangkok").format();
  var a = new Date(momentBKK);
  var months = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];
  var year = a.getFullYear() || "";
  var month = months[a.getMonth()] || "";
  var date = a.getDate() || "";
  var hour = a.getHours() || "0";
  var minute = a.getMinutes() || "0";
  var time =
    timeFormat === "hr-min"
      ? dateFormat === "dd/mm/yyyy"
        ? (parseInt(date, 10) < 10 ? "0" : "") +
          date +
          (parseInt(a.getMonth() + 1, 10) < 10 ? "/0" : "/") +
          parseInt(a.getMonth() + 1, 10) +
          "/" +
          year +
          " " +
          pad(hour) +
          ":" +
          pad(minute)
        : date + " " + month + " " + year + " " + pad(hour) + ":" + pad(minute)
      : date + " " + month + " " + year;
  return time;
};
