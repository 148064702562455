/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/upload/uploadActions";
import { EbookEditForm } from "./EbookEditForm";
import axios from "axios";
import { EbookEditFileAndCover } from "./EbookEditFileAndCover";
import { LoaderViewSmall } from "../../../../CustomView";

export function EbookEditDialog({ id, show, onHide, isPrivate, fixStatus }) {
  // Ebooks Redux state
  const dispatch = useDispatch();
  const { uploadState } = useSelector(
    (state) => ({
      uploadState: state.upload,
    }),
    shallowEqual
  );
  const loaderHeight = 50;
  const [ebookDetail, setEbookDetail] = useState(null);
  const [ebookValue, setEbookValue] = useState(null);
  const [rentalPeriod, setRentalPeriod] = useState([]);
  const [fixFileModal, setFixFileModal] = useState(false);
  const [modalType, setModalType] = useState(null);

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
      setEbookDetail(null);
    } else {
      dispatch(actions.fetchNewPrivateContentData()); //rental period dropdown
      const reqFunc = async () => {
        try {
          const req = await axios.get(
            `/admin${isPrivate ? "/private/contents" : "/contents"}/${id}/edit`
          );
          const { data } = req;
          if (data.status && data.data) {
            setEbookDetail(data.data);
          }
        } catch (err) {
          onHide();
          //axios.patch(`/member/logout`);
        }
      };
      if (fixStatus === 0) {
        reqFunc();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, fixStatus]);

  useEffect(() => {
    if (fixStatus === 4) {
      setFixFileModal(true);
      setModalType("both");
    } else if (fixStatus === 6) {
      setFixFileModal(true);
      setModalType("content");
    } else if (fixStatus === 5) {
      setFixFileModal(true);
      setModalType("cover");
    }
  }, [fixStatus]);

  useEffect(() => {
    setRentalPeriod(uploadState.rentalPeriod);
  }, [uploadState.rentalPeriod]);

  useEffect(() => {
    if (ebookDetail) {
      const values = ebookDetail;
      const originValues = {
        name: values.title,
        publisherValue: values.creatorUID,
        authorValue: values.writerUID,
        isbn: values.ISBN,
        eisbn: values.eISBN,
        numPages: values.pageCount ? values.pageCount : undefined,
        categoryValue: values.catId,
        subCategoryValue: values.subcatId,
        startDate: values.startDate,
        endDate: values.expireDate,
        numLicenses: values.concurrent > 0 ? values.concurrent : undefined,
        tags: values.tags,
        rentalPeriodValue: values.rentalPeriod,
        anonymousRentalPeriodValue: values.anonymousRentalPeriod,
        description: values.description,
        concurrent: values.concurrent,
        activeLicense: values.activeLicense,
        file: values.file,
      };
      setEbookValue(originValues);
    }
  }, [ebookDetail]);

  return fixFileModal ? (
    <EbookEditFileAndCover
      modalType={modalType}
      onHide={() => onHide()}
      contentUIDForUpdate={id}
      fixFileOnly={true}
    />
  ) : (
    <Modal
      backdrop="static"
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Body>
        {ebookDetail ? (
          <>
            <h2 className="center">แก้ไขรายละเอียด</h2>
            <EbookEditForm
              ebookOrigin={ebookDetail}
              ebookValue={Object.assign(
                {},
                {
                  id: undefined,
                  name: "",
                },
                ebookValue
              )}
              rentalPeriod={rentalPeriod}
              contentUIDForUpdate={id}
              onHideOutside={onHide}
              setEbookValue={setEbookValue}
              setEbookDetail={setEbookDetail}
              isPrivate={isPrivate}
            />
          </>
        ) : (
          <LoaderViewSmall />
        )}
      </Modal.Body>
    </Modal>
  );
}
