// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch } from "antd";
import { Role } from "../../../../../../../interface";

export const TextColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  {
    categoryForEdit,
    isSubCat,
    catName,
    forStatus,
    forPin,
    isPrivate,
    onSwitchPinChange,
    openBlockCategoryDialog,
    openUnblockCategoryDialog,
    role,
  }
) => {
  const isChecked = () => {
    if (isSubCat) {
      if (
        categoryForEdit &&
        categoryForEdit.subcats &&
        categoryForEdit.subcats.length > 0
      ) {
        return categoryForEdit.subcats[rowIndex] &&
          categoryForEdit.subcats[rowIndex].status
          ? true
          : false;
      }
    } else {
      return cellContent ? true : false;
    }
  };
  const onSwitchChange = (e) => {
    if (isSubCat) {
      if (e) {
        openUnblockCategoryDialog(
          row && (row.catName || row.subcatName),
          row.id,
          isPrivate,
          isSubCat,
          catName,
          categoryForEdit.id
        );
      } else {
        openBlockCategoryDialog(
          row && (row.catName || row.subcatName),
          row.id,
          isPrivate,
          isSubCat,
          catName,
          categoryForEdit.id
        );
      }
    } else if (forStatus !== undefined) {
      if (e) {
        openUnblockCategoryDialog(
          row && (row.catName || row.subcatName),
          row.id,
          isPrivate
        );
      } else {
        openBlockCategoryDialog(
          row && (row.catName || row.subcatName),
          row.id,
          isPrivate
        );
      }
    } else if (forPin !== undefined) {
      onSwitchPinChange(row.catName, row.id, e);
    }
  };
  // const categoriesUIContext = useCategoriesUIContext();
  // const dispatch = useDispatch();
  // const blockCategory = () => {
  //   // server request for deleting category by id
  //   dispatch(actions.blockCategory(name, id, isPrivate)).then(() => {
  //     // refresh list after deletion
  //     dispatch(actions.fetchCategories(categoriesUIContext.queryParams, isPrivate));
  //     // clear selections list
  //     categoriesUIProps.setIds([]);
  //     // closing block modal
  //     onHide();
  //   });
  // };
  const textOfStatus = (boo) => (boo ? "เปิดใช้งาน" : "บล็อก");
  const textOfPin = (boo) =>
    boo ? <img src="/media/members/check-true.svg" alt="" /> : "-";

  return role === Role.SuperAdmin ? (
    forPin !== undefined ? (
      // textOfPin(isChecked())
      <Switch
        onChange={onSwitchChange}
        checked={isChecked()}
        checkedChildren="เปิด"
        unCheckedChildren="ปิด"
      />
    ) : (
      textOfStatus(isChecked())
    )
  ) : (
    <Switch
      onChange={onSwitchChange}
      checked={isChecked()}
      checkedChildren="เปิด"
      unCheckedChildren="ปิด"
    />
  );
};
