import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { BannerStatusCssClasses } from "../BannersUIHelpers";
import { useBannersUIContext } from "../BannersUIContext";

const selectedBanners = (entities, ids) => {
  const _banners = [];
  ids.forEach((id) => {
    const banner = entities.find((el) => el.id === id);
    if (banner) {
      _banners.push(banner);
    }
  });
  return _banners;
};

export function BannersFetchDialog({ show, onHide }) {
  // Banners UI Context
  const bannersUIContext = useBannersUIContext();
  const bannersUIProps = useMemo(() => {
    return {
      ids: bannersUIContext.ids,
    };
  }, [bannersUIContext]);

  // Banners Redux state
  const { banners } = useSelector(
    (state) => ({
      banners: selectedBanners(
        state.banners.entities,
        bannersUIProps.ids
      ),
    }),
    shallowEqual
  );

  // if banners weren't selected we should close modal
  useEffect(() => {
    if (!bannersUIProps.ids || bannersUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannersUIProps.ids]);

  return (
    <Modal
    backdrop="static"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Fetch selected elements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="timeline timeline-5 mt-3">
          {banners.map((banner) => (
            <div className="timeline-item align-items-start" key={`id${banner.id}`}>
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3" />
              <div className="timeline-badge">
                <i
                  className={`fa fa-genderless text-${
                    BannerStatusCssClasses[banner.status]
                  } icon-xxl`}
                />
              </div>
              <div className="timeline-content text-dark-50 mr-5">
                <span
                    className={`label label-lg label-light-${
                      BannerStatusCssClasses[banner.status]
                    } label-inline`}
                  >
                    ID: {banner.id}
                </span>
                <span className="ml-3">{banner.lastName}, {banner.firstName}</span>                
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-primary btn-elevate"
          >
            Ok
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
