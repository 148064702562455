/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import FastAverageColor from 'fast-average-color';
import * as actions from '../../../_redux/categories/categoriesActions';
import {
   getHandlerTableChange,
   NoRecordsFoundMessage,
   PleaseWaitMessage,
   sortCaret,
   headerSortingClasses
} from '../../../../../../_metronic/_helpers';
import * as uiHelpers from '../CategoriesUIHelpers';
import * as columnFormatters from './column-formatters';
import { useCategoriesUIContext } from '../CategoriesUIContext';
import { categoriesSlice } from '../../../_redux/categories/categoriesSlice';
import { ClipLoader } from 'react-spinners';
import { Empty } from 'antd';
const actions1 = categoriesSlice.actions;

export function CategoriesTable({
   catName,
   type,
   catId,
   isSubCat,
   isSubCatBooks,
   isPrivate,
   setListLoading
}) {
   // Categories UI Context
   const categoriesUIContext = useCategoriesUIContext();
   const categoriesUIProps = useMemo(() => {
      return {
         ids: categoriesUIContext.ids,
         setIds: categoriesUIContext.setIds,
         queryParams: categoriesUIContext.queryParams,
         setQueryParams: categoriesUIContext.setQueryParams,
         openEditCategoryDialog: categoriesUIContext.openEditCategoryDialog,
         openEditSubCategoryDialog:
            categoriesUIContext.openEditSubCategoryDialog,
         openViewCategoryDialog: categoriesUIContext.openViewCategoryDialog,
         openDeleteCategoryDialog: categoriesUIContext.openDeleteCategoryDialog,
         openDeleteSubCategoryDialog:
            categoriesUIContext.openDeleteSubCategoryDialog,
         openBlockCategoryDialog: categoriesUIContext.openBlockCategoryDialog,
         openUnblockCategoryDialog:
            categoriesUIContext.openUnblockCategoryDialog,
         openPinCategoryDialog: categoriesUIContext.openPinCategoryDialog,
         openUnpinCategoryDialog: categoriesUIContext.openUnpinCategoryDialog,
         openSubCategoriesPage: categoriesUIContext.openSubCategoriesPage,
         openSubCategoryBooksPage: categoriesUIContext.openSubCategoryBooksPage,
         contentType: categoriesUIContext.contentType
      };
   }, [categoriesUIContext]);

   // Getting current state of categories list from store (Redux)
   const { currentState, role } = useSelector(
      state => ({ currentState: state.categories, role: state.auth.role }),
      shallowEqual
   );
   const {
      totalCount,
      entities,
      listLoading,
      categoryForEdit,
      LastEvaluatedKey,
      error,
      contentType: contentTypeState
   } = currentState;
   const [isError, setError] = useState(false);
   const [errorType, setErrorType] = useState(null);
   const [checkErrorPin, setCheckErrorPin] = useState(0);
   const loaderHeight = 50;
   const [isEmpty, setEmpty] = useState(false);
   const footerHeight = 300;
   const [loadingBottom, setLoadingBottom] = useState(false);
   const [categorySubcatData, setCategorySubcatData] = useState([]);

   useEffect(() => {
      if (error && error.errorCode === '011') {
         setError(true);
         setErrorType(error.from);
      } else if (error && error.errorCode === '5007') {
         setError(true);
         setErrorType(error.from);
      }
   }, [error]);

   // const isBottom = () => {
   //   let scrollHeight = Math.max(
   //     document.body.scrollHeight,
   //     document.documentElement.scrollHeight,
   //     document.body.offsetHeight,
   //     document.documentElement.offsetHeight,
   //     document.body.clientHeight,
   //     document.documentElement.clientHeight
   //   );
   //   return (
   //     window.pageYOffset + window.innerHeight >=
   //     scrollHeight - 3 * loaderHeight - footerHeight
   //   );
   // };

   const trackScrolling = () => {
      if (isBottom()) {
         setLoadingBottom(true);
      }
   };

   useEffect(() => {
      if (isSubCat && categoryForEdit && categoryForEdit.subcats) {
         const c = categoriesUIProps.queryParams;
         if (!c.keyword) {
            if (c.memberMode) {
               setCategorySubcatData(
                  categoryForEdit.subcats.filter(
                     e => e.status === parseInt(c.memberMode, 10)
                  )
               );
            } else {
               setCategorySubcatData(categoryForEdit.subcats);
            }
         } else {
            if (c.memberMode) {
               setCategorySubcatData(
                  categoryForEdit.subcats.filter(
                     e =>
                        e.subcatName.includes(c.keyword) &&
                        e.status === parseInt(c.memberMode, 10)
                  )
               );
            } else {
               setCategorySubcatData(
                  categoryForEdit.subcats.filter(e =>
                     e.subcatName.includes(c.keyword)
                  )
               );
            }
         }
      }
   }, [isSubCat, categoryForEdit, categoriesUIProps.queryParams]);

   useEffect(() => {
      if (
         LastEvaluatedKey === undefined ||
         JSON.stringify(LastEvaluatedKey) === JSON.stringify({})
      ) {
         setEmpty(true);
      } else if (loadingBottom && listLoading) {
         setEmpty(false);
         dispatch(
            actions.fetchCategories(
               Object.assign({}, categoriesUIProps.queryParams, {
                  contentType: categoriesUIProps.contentType
               }),
               isPrivate,
               catId,
               false,
               LastEvaluatedKey,
               setLoadingBottom
            )
         );
      }
   }, [LastEvaluatedKey, loadingBottom, listLoading]);

   // useEffect(() => {
   //   console.log(
   //     listLoading,
   //     LastEvaluatedKey,
   //     isEmpty,
   //     loadingBottom,
   //     !error && (listLoading || (LastEvaluatedKey !== undefined && (!isEmpty || loadingBottom)))
   // eieieieiei
   //   );
   // }, [listLoading, LastEvaluatedKey, isEmpty, loadingBottom]);

   useEffect(() => {
      setListLoading(listLoading);
   }, [listLoading]);

   // const [isPin, setIsPin] = useState(false);
   // const [isStatus1, setIsStatus1] = useState(false);
   const isStatus1 = false;
   const [firstLoaded, setFirstLoaded] = useState(0);

   const onSwitchPinChange = (name, id, e) => {
      setCheckErrorPin(e);
      if (e) {
         categoriesUIProps.openPinCategoryDialog(name, id, isPrivate);
      } else {
         categoriesUIProps.openUnpinCategoryDialog(name, id, isPrivate);
      }
   };

   const [colorObj, setColorObj] = useState([]);

   useEffect(() => {
      if (!entities) {
         dispatch(
            actions.fetchCategories(
               Object.assign({}, categoriesUIProps.queryParams, {
                  contentType: categoriesUIProps.contentType
               }),
               isPrivate
            )
         );
      } else {
         setColorObj([]);
         entities.map((el, index) => {
            var loaded = false;
            var img = document.getElementById(index.toString());
            if (typeof img !== 'undefined' && img !== null) {
               img.crossOrigin = 'Anonymous';
               img.addEventListener('load', loadHandler, false);
               img.src = el.coverImage
                  ? el.coverImage
                  : '/media/members/placeholder-image.png';
               loadHandler();
            }
            function loadHandler() {
               if (loaded) {
                  return;
               }
               loaded = true;
               var fac = new FastAverageColor();
               if (img) {
                  fac.getColorAsync(img)
                     .then(function(color) {
                        setColorObj(oldArray => [
                           ...oldArray,
                           { index: index, color: color }
                        ]);
                     })
                     .catch(function(e) {
                        setColorObj(oldArray => [
                           ...oldArray,
                           { index: index, color: 'error' }
                        ]);
                     });
               }
            }
            // const img = new Image();
            // img.onload = function() {
            //   var fac = new FastAverageColor();
            //   if (img) {
            //     fac
            //       .getColorAsync(img)
            //       .then(function(color) {
            //         setColorObj((oldArray) => [...oldArray, { index: index, color: color }]);
            //       })
            //       .catch(function(e) {
            //         setColorObj((oldArray) => [...oldArray, { index: index, color: "error" }]);
            //       });
            //   }
            // };
            // img.src = el.coverImage ? el.coverImage : "/media/members/placeholder-image.png";
         });
      }
   }, [entities]);

   // Categories Redux state
   const dispatch = useDispatch();

   useEffect(() => {
      // clear selections list
      categoriesUIProps.setIds([]);
      // server call by queryParams
      if (isSubCat) {
         dispatch(actions.fetchCategory(catId, isPrivate, isSubCat));
         if (firstLoaded < 2) {
            if (firstLoaded === 1) {
               dispatch(
                  actions.fetchCategories(
                     Object.assign({}, categoriesUIProps.queryParams, {
                        contentType: categoriesUIProps.contentType
                     }),
                     isPrivate,
                     catId
                  )
               );
            }
            setFirstLoaded(firstLoaded + 1);
         }
      } else {
         console.log('param' + JSON.stringify(categoriesUIProps.queryParams));
         dispatch(
            actions.fetchCategories(
               Object.assign({}, categoriesUIProps.queryParams, {
                  contentType: categoriesUIProps.contentType
               }),
               isPrivate
            )
         );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [
      categoriesUIProps.queryParams,
      dispatch,
      isPrivate,
      catId,
      isSubCat,
      categoriesUIProps.contentType
   ]);

   const [loadingCount, setLoadingCount] = useState(0);
   const minWidthOfAll = 800;
   const minusOfAll = 380;
   const [tableWidth, setTableWidth] = useState(
      Math.max(
         document.body.scrollWidth,
         document.documentElement.scrollWidth,
         document.body.offsetWidth,
         document.documentElement.offsetWidth,
         document.documentElement.clientWidth
      ) <
         minWidthOfAll + minusOfAll
         ? minWidthOfAll
         : Math.max(
              document.body.scrollWidth,
              document.documentElement.scrollWidth,
              document.body.offsetWidth,
              document.documentElement.offsetWidth,
              document.documentElement.clientWidth
           ) - minusOfAll
   );

   useEffect(() => {
      if (entities && entities.length > -1 && loadingCount < 2) {
         setLoadingCount(loadingCount + 1);
      }
   }, [entities]);

   useEffect(() => {
      if (
         categorySubcatData &&
         categorySubcatData.length > -1 &&
         loadingCount < 2
      ) {
         setLoadingCount(loadingCount + 1);
      }
   }, [categorySubcatData]);

   const isBottom = () => {
      const getScroll = document
         .getElementById('custom-table-scroll')
         .getElementsByTagName('tbody')[0];
      const offsetHeight = Math.ceil(getScroll.offsetHeight);
      const scrollTop = Math.ceil(getScroll.scrollTop);
      if (
         !listLoading &&
         getScroll.scrollHeight - (scrollTop + offsetHeight) <= 1
      ) {
         return true;
      } else {
         return false;
      }
   };

   const resize = () => {
      if (
         document.getElementById('custom-table-scroll') &&
         loadingCount === 2
      ) {
         setTableWidth(
            document
               .getElementById('custom-table-scroll')
               .getBoundingClientRect().width < minWidthOfAll
               ? minWidthOfAll
               : document
                    .getElementById('custom-table-scroll')
                    .getBoundingClientRect().width
         );
      }
   };

   useEffect(() => {
      window.addEventListener('resize', resize);
      if (
         document.getElementById('custom-table-scroll') &&
         document
            .getElementById('custom-table-scroll')
            .getElementsByTagName('tbody')[0] &&
         loadingCount === 2
      ) {
         document
            .getElementById('custom-table-scroll')
            .getElementsByTagName('tbody')[0]
            .addEventListener('scroll', trackScrolling);
      }
      // eslint-disable-next-line
   }, [loadingCount]);

   useEffect(() => {
      return () => {
         window.removeEventListener('resize', resize);
         setLoadingCount(0);
         if (
            document.getElementById('custom-table-scroll') &&
            document
               .getElementById('custom-table-scroll')
               .getElementsByTagName('tbody')[0]
         ) {
            document
               .getElementById('custom-table-scroll')
               .getElementsByTagName('tbody')[0]
               .removeEventListener('scroll', trackScrolling);
         }
      };
      // eslint-disable-next-line
   }, []);

   const widthOfAll = isSubCat
      ? !isPrivate
         ? 65 + 105 + 80
         : 65 + 105 + 80 + 240
      : !isPrivate
      ? 65 + 105 + 80 + 150 + 240
      : 65 + 105 + 170 + 80 + 150 + 240;

   // Table columns
   const columns = [
      {
         dataField: 'none',
         text: 'ลำดับ',
         sortCaret: sortCaret,
         headerSortingClasses,
         formatter: columnFormatters.IndexColumnFormatter,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
                  style={{
                     textAlign: 'center',
                     fontSize: '14px',
                     maxWidth: `${(65 / widthOfAll) * tableWidth}px`,
                     minWidth: `${(65 / widthOfAll) * tableWidth}px`,
                     margin: '-0.75rem'
                  }}
               >
                  {column.text}
               </div>
            );
         },
         style: {
            maxWidth: `${(65 / widthOfAll) * tableWidth}px`,
            minWidth: `${(65 / widthOfAll) * tableWidth}px`
         },
         formatExtraData: {
            pageNumber: categoriesUIProps.queryParams.pageNumber,
            pageSize: categoriesUIProps.queryParams.pageSize
         }
      },
      {
         dataField: 'coverImage',
         text: isSubCat ? 'หมวดหมู่ย่อย' : 'หมวดหมู่',
         sortCaret: sortCaret,
         headerSortingClasses,
         formatter: columnFormatters.ImageColumnFormatter,
         formatExtraData: {
            totalCount: totalCount,
            colorObj: colorObj,
            isSubCat: isSubCat,
            openViewCategoryDialog: categoriesUIProps.openViewCategoryDialog
         },
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
                  style={{
                     textAlign: 'left',
                     fontSize: '14px',
                     maxWidth: `${(105 / widthOfAll) * tableWidth}px`,
                     minWidth: `${(105 / widthOfAll) * tableWidth}px`,
                     margin: '-0.75rem',
                     padding: '0.75rem'
                  }}
               >
                  {column.text}
               </div>
            );
         },
         style: {
            maxWidth: `${(105 / widthOfAll) * tableWidth}px`,
            minWidth: `${(105 / widthOfAll) * tableWidth}px`
         }
      },
      {
         dataField: 'title',
         text: 'จำนวนหมวดหมู่ย่อย',
         // sort: true,
         sortCaret: sortCaret,
         headerSortingClasses,
         formatter: columnFormatters.DetailColumnFormatter,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
                  style={{
                     textAlign: 'center',
                     fontSize: '14px',
                     maxWidth: `${(170 / widthOfAll) * tableWidth}px`,
                     minWidth: `${(170 / widthOfAll) * tableWidth}px`,
                     margin: '-0.75rem'
                  }}
               >
                  {column.text}
               </div>
            );
         },
         style: {
            maxWidth: `${(170 / widthOfAll) * tableWidth}px`,
            minWidth: `${(170 / widthOfAll) * tableWidth}px`
         }
      },
      // {
      //   dataField: "concurrent",
      //   text: "ยืมแล้ว",
      //   // sort: true,
      //   align: "center",
      //   sortCaret: sortCaret,
      //   formatter: columnFormatters.RemainAmountColumnFormatter,
      //   headerSortingClasses,
      //   headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
      //     return <div style={{ textAlign: "center", fontSize: "14px" }}>{column.text}</div>;
      //   },
      // },
      {
         dataField: 'status',
         text: 'สถานะ',
         // sort: true,
         align: 'center',
         sortCaret: sortCaret,
         formatter: columnFormatters.TextColumnFormatter,
         formatExtraData: {
            categoryForEdit,
            isSubCat,
            catName,
            forStatus: isStatus1,
            isPrivate,
            openBlockCategoryDialog: categoriesUIProps.openBlockCategoryDialog,
            openUnblockCategoryDialog:
               categoriesUIProps.openUnblockCategoryDialog,
            onSwitchPinChange,
            role
         },
         headerSortingClasses,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
                  style={{
                     textAlign: 'center',
                     fontSize: '14px',
                     maxWidth: `${(80 / widthOfAll) * tableWidth}px`,
                     minWidth: `${(80 / widthOfAll) * tableWidth}px`,
                     margin: '-0.75rem'
                  }}
               >
                  {column.text}
               </div>
            );
         },
         style: {
            maxWidth: `${(80 / widthOfAll) * tableWidth}px`,
            minWidth: `${(80 / widthOfAll) * tableWidth}px`
         }
      },
      {
         dataField: 'pin',
         text: 'ปักหมุดที่หน้าแรก',
         // sort: true,
         align: 'center',
         sortCaret: sortCaret,
         formatter: columnFormatters.TextColumnFormatter,
         formatExtraData: {
            forPin: true,
            catName,
            isPrivate,
            openBlockCategoryDialog: categoriesUIProps.openBlockCategoryDialog,
            openUnblockCategoryDialog:
               categoriesUIProps.openUnblockCategoryDialog,
            onSwitchPinChange,
            role
         },
         headerSortingClasses,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
                  style={{
                     textAlign: 'center',
                     fontSize: '14px',
                     maxWidth: `${(150 / widthOfAll) * tableWidth}px`,
                     minWidth: `${(150 / widthOfAll) * tableWidth}px`,
                     margin: '-0.75rem'
                  }}
               >
                  {column.text}
               </div>
            );
         },
         style: {
            maxWidth: `${(150 / widthOfAll) * tableWidth}px`,
            minWidth: `${(150 / widthOfAll) * tableWidth}px`
         }
      },
      {
         dataField: 'id',
         text: 'การจัดการ',
         formatter: columnFormatters.ActionsColumnFormatter,
         formatExtraData: {
            categoryForEdit,
            isSubCat,
            catName,
            type,
            isPrivate,
            openEditCategoryDialog: categoriesUIProps.openEditCategoryDialog,
            openEditSubCategoryDialog:
               categoriesUIProps.openEditSubCategoryDialog,
            openBlockCategoryDialog: categoriesUIProps.openBlockCategoryDialog,
            openUnblockCategoryDialog:
               categoriesUIProps.openUnblockCategoryDialog,
            openDeleteCategoryDialog:
               categoriesUIProps.openDeleteCategoryDialog,
            openDeleteSubCategoryDialog:
               categoriesUIProps.openDeleteSubCategoryDialog,
            openSubCategoriesPage: categoriesUIProps.openSubCategoriesPage,
            openSubCategoryBooksPage:
               categoriesUIProps.openSubCategoryBooksPage,
            role
         },
         classes: 'text-right',
         headerClasses: 'text-right pr-3',
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
                  style={{
                     textAlign: 'right',
                     fontSize: '14px',
                     maxWidth: `${(240 / widthOfAll) * tableWidth}px`,
                     minWidth: `${(240 / widthOfAll) * tableWidth}px`,
                     margin: '-0.75rem',
                     paddingRight: '1.5rem'
                  }}
               >
                  {column.text}
               </div>
            );
         },
         style: {
            maxWidth: `${(240 / widthOfAll) * tableWidth}px`,
            minWidth: `${(240 / widthOfAll) * tableWidth}px`
         }
      }
   ];
   const errorTypeWording = type => {
      if (type) {
         switch (type) {
            case 'delete':
               return 'ลบ';
            case 'block':
               return 'บล็อก';
            case 'unblock':
               return 'ปลดบล็อก';
            case 'setStatus':
               return 'เปลี่ยนสถานะ';
            case 'update':
               return 'เปลี่ยนชื่อ';
            default:
               return 'อัปเดท';
         }
      }
   };
   // Table pagination properties
   // const paginationOptions = {
   //   custom: true,
   //   totalSize: totalCount,
   //   sizePerPageList: uiHelpers.sizePerPageList,
   //   sizePerPage: categoriesUIProps.queryParams.pageSize,
   //   page: categoriesUIProps.queryParams.pageNumber,
   //   onPageChange: () => {
   //     window.scrollTo(0, 0);
   //   },
   // };
   const isNotFound = () => {
      return (
         (isSubCat &&
            categoryForEdit &&
            Array.isArray(categorySubcatData) &&
            categorySubcatData.length === 0 &&
            !listLoading) ||
         (!isSubCat &&
            entities &&
            Array.isArray(entities) &&
            entities.length === 0 &&
            !listLoading)
      );
   };
   return loadingCount === 2 ? (
      <>
         {/* <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => {
        return (
          <Pagination isLoading={listLoading} paginationProps={paginationProps}> */}
         <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes={`table table-head-custom table-vertical-center custom-table-scroll categoriesTable ${
               isNotFound() ? 'isNotFound' : ''
            }`}
            id="custom-table-scroll"
            bootstrap4
            remote
            keyField="id"
            data={
               isSubCat
                  ? categoryForEdit
                     ? categorySubcatData
                     : []
                  : !Array.isArray(entities) ||
                    categoriesUIProps.contentType !== contentTypeState
                  ? []
                  : entities
            }
            columns={
               isSubCat
                  ? !isPrivate
                     ? columns.filter(el => {
                          return (
                             el.dataField !== 'pin' &&
                             el.text !== 'จำนวนหมวดหมู่ย่อย' &&
                             el.text !== 'การจัดการ'
                          );
                       })
                     : columns.filter(el => {
                          return (
                             el.dataField !== 'pin' &&
                             el.text !== 'จำนวนหมวดหมู่ย่อย'
                          );
                       })
                  : !isPrivate
                  ? columns.filter(el => {
                       return el.text !== 'จำนวนหมวดหมู่ย่อย';
                    })
                  : columns
            }
            defaultSorted={uiHelpers.defaultSorted}
            onTableChange={getHandlerTableChange(
               categoriesUIProps.setQueryParams
            )}
            // selectRow={getSelectRow({
            //   entities,
            //   ids: categoriesUIProps.ids,
            //   setIds: categoriesUIProps.setIds,
            // })}
            // {...paginationTableProps}
         >
            <PleaseWaitMessage entities={entities} />
            <NoRecordsFoundMessage entities={entities} />
         </BootstrapTable>
         {/* </Pagination>
        );
      }}
    </PaginationProvider> */}
         {isSubCat ? (
            categoryForEdit &&
            Array.isArray(categorySubcatData) &&
            categorySubcatData.length === 0 &&
            !listLoading ? (
               <div className="div-isNotFound">
                  {categoriesUIProps.queryParams.keyword ? (
                     <img src="/media/members/no-search-result.png" alt="" />
                  ) : (
                     <Empty description={false} />
                  )}
                  <p
                     style={{
                        color: '#012875',
                        marginBottom: '0',
                        fontSize: '20px',
                        fontWeight: '600'
                     }}
                  >
                     {categoriesUIProps.queryParams.keyword
                        ? 'ไม่พบรายการที่ค้นหา'
                        : 'ไม่มีหมวดหมู่ย่อย'}
                  </p>
               </div>
            ) : (
               ''
            )
         ) : entities &&
           Array.isArray(entities) &&
           entities.length === 0 &&
           !listLoading ? (
            <div className="div-isNotFound">
               {categoriesUIProps.queryParams.keyword ? (
                  <img src="/media/members/no-search-result.png" alt="" />
               ) : (
                  <Empty description={false} />
               )}
               <p
                  style={{
                     color: '#012875',
                     marginBottom: '0',
                     fontSize: '20px',
                     fontWeight: '600'
                  }}
               >
                  {categoriesUIProps.queryParams.keyword
                     ? 'ไม่พบรายการที่ค้นหา'
                     : 'ไม่มีหมวดหมู่'}
               </p>
            </div>
         ) : (
            ''
         )}
         {!error &&
         listLoading &&
         LastEvaluatedKey !== undefined &&
         (!isEmpty || loadingBottom) ? (
            <div
               style={{
                  minHeight: `${loaderHeight}px`,
                  textAlign: 'center',
                  padding: '20px 0 0 0',
                  width: '100%'
               }}
            >
               <ClipLoader
                  size={loaderHeight}
                  color={'#123abc'}
                  loading={true}
               />
            </div>
         ) : !error && !isEmpty ? (
            // <div style={{ minHeight: `${loaderHeight}px` }} />
            ''
         ) : (
            ''
         )}
         <Modal
            backdrop="static"
            show={isError}
            onHide={() => {
               setError(false);
               setTimeout(() => {
                  setCheckErrorPin(0);
               }, 500);
            }}
            aria-labelledby="example-modal-sizes-title-lg"
            style={{ margin: '3% auto' }}
            size="md"
         >
            <Modal.Body
               style={{
                  textAlign: 'center'
               }}
            >
               <img src="/media/members/image-modal.svg" alt="" />
               <div
                  style={{
                     fontSize: '20px',
                     fontWeight: 600,
                     color: '#d7042b',
                     marginBottom: '12px'
                  }}
               >
                  {!errorType && checkErrorPin === true ? (
                     'ไม่สามารถปักหมุดที่หน้าแรกเกิน 3 หมวดหมู่ได้'
                  ) : !errorType && checkErrorPin === false ? (
                     'ต้องปักหมุดที่หน้าแรกอย่างน้อย 1 หมวดหมู่'
                  ) : isSubCat ? (
                     <>
                        <p style={{ marginBottom: '0px' }}>
                           ไม่สามารถ{errorTypeWording(errorType)}หมวดหมู่ย่อยได้
                        </p>
                        <p>เนื่องจากมีการใช้งานหมวดหมู่ย่อยอยู่</p>
                     </>
                  ) : (
                     <>
                        <p style={{ marginBottom: '0px' }}>
                           {errorTypeWording(errorType) === 'เปลี่ยนสถานะ'
                              ? 'ไม่สามารถปิดสถานะได้'
                              : `ไม่สามารถ${errorTypeWording(
                                   errorType
                                )}หมวดหมู่หลักได้`}
                        </p>
                        <p>
                           {errorTypeWording(errorType) === 'เปลี่ยนสถานะ'
                              ? 'เนื่องจากหมวดหมู่นี้มีการปักหมุดอยู่'
                              : 'เนื่องจากมีการใช้งานหมวดหมู่หลักอยู่'}
                        </p>
                     </>
                  )}
               </div>
               <div style={{ marginTop: '36px' }}>
                  <button
                     type="button"
                     onClick={() => {
                        dispatch(actions1.errorNull());
                        setError(false);
                        setTimeout(() => {
                           setCheckErrorPin(0);
                        }, 500);
                     }}
                     style={{
                        margin: '8px',
                        color: '#ffffff',
                        fontSize: '18px',
                        fontWeight: 600,
                        width: '160px',
                        height: '48px',
                        borderRadius: '100px',
                        backgroundColor: '#012875',
                        border: 'none'
                     }}
                  >
                     ตกลง
                  </button>
               </div>
            </Modal.Body>
         </Modal>
      </>
   ) : (
      <div className="cliploader-center">
         <ClipLoader size={loaderHeight} color={'#123abc'} loading={true} />
      </div>
   );
}
