import { createSlice } from "@reduxjs/toolkit";

const initialUploadState = {
  titleData: {},
  storageData: {},
  rentalPeriod: [],
  categories: [],
  publishers: [],
  fileName: null,
  contentUID: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const uploadSlice = createSlice({
  name: "upload",
  initialState: initialUploadState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.error && action.payload.error.response && action.payload.error.response.data) {
        state.error = action.payload.error.response.data.errorCode;
      }
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    contentDataFetched: (state, action) => {
      if (action.payload.contentData) {
        if (action.payload.contentData.titleData) {
          state.titleData = action.payload.contentData.titleData;
        }
        if (action.payload.contentData.storageData) {
          state.storageData = action.payload.contentData.storageData;
        }
        if (action.payload.contentData.rentalPeriod) {
          state.rentalPeriod = action.payload.contentData.rentalPeriod;
        }
      }
    },
    saveFileNameAndUID: (state, action) => {
      state.fileName = action.payload.fileName;
      state.contentUID = action.payload.contentUID;
    },
  },
});
