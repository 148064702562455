import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./CategoriesUIHelpers";

const CategoriesUIContext = createContext();

export function useCategoriesUIContext() {
  return useContext(CategoriesUIContext);
}

export const CategoriesUIConsumer = CategoriesUIContext.Consumer;

export function CategoriesUIProvider({ categoriesUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initCategory = {
    id: undefined,
    name: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initCategory,
    newCategoryButtonClick: categoriesUIEvents.newCategoryButtonClick,
    newSubCategoryButtonClick: categoriesUIEvents.newSubCategoryButtonClick,
    openEditCategoryDialog: categoriesUIEvents.openEditCategoryDialog,
    openEditSubCategoryDialog: categoriesUIEvents.openEditSubCategoryDialog,
    openViewCategoryDialog: categoriesUIEvents.openViewCategoryDialog,
    openDeleteCategoryDialog: categoriesUIEvents.openDeleteCategoryDialog,
    openDeleteSubCategoryDialog: categoriesUIEvents.openDeleteSubCategoryDialog,
    openBlockCategoryDialog: categoriesUIEvents.openBlockCategoryDialog,
    openUnblockCategoryDialog: categoriesUIEvents.openUnblockCategoryDialog,
    openPinCategoryDialog: categoriesUIEvents.openPinCategoryDialog,
    openUnpinCategoryDialog: categoriesUIEvents.openUnpinCategoryDialog,
    openFetchCategoriesDialog: categoriesUIEvents.openFetchCategoriesDialog,
    openUpdateCategoriesStatusDialog: categoriesUIEvents.openUpdateCategoriesStatusDialog,
    openSubCategoriesPage: categoriesUIEvents.openSubCategoriesPage,
    openSubCategoryBooksPage: categoriesUIEvents.openSubCategoryBooksPage,
    contentType: categoriesUIEvents.contentType,
  };

  return <CategoriesUIContext.Provider value={value}>{children}</CategoriesUIContext.Provider>;
}
