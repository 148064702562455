import { Card } from "@material-ui/core";
import { Breadcrumb, Button, Modal } from "antd";
import { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  FolderOutlined,
  DownloadOutlined,
  SearchOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { Table, Tag, Space } from "antd";
import "./index.scss";
import { ColumnsType } from "antd/lib/table";
import {
  DriveFile,
  DriveFileType,
  getDownloadFile,
  getFileContent,
} from "../request";
import { saveAs } from "file-saver";
import axios from "axios";
import path from "path";
interface DriveFileViewModel extends DriveFile {
  key?: string;
}

export const DownLoadFileView: FC = () => {
  const configModel = {
    title: "ยืนยันการดาวน์โหลด",
    cancelText: "ยกเลิก",
    okText: "ดาวน์์โหลด",
    onOk: () => {
      setConfirmDownload(true);
      //downloadFile(filePath);
    },
    onCancel: () => {
      setConfirmDownload(false);
    },
  };
  const fetchData = async (path?: string) => {
    setLoading(true);
    try {
      const data = await getFileContent(path);
      const mappingData: DriveFileViewModel[] =
        data?.contents.map((el, index) => ({
          ...el,
          key: `${index}`,
        })) ?? [];
      setFiles(mappingData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const [files, setFiles] = useState<DriveFile[]>();
  const [filePaths, setFilePaths] = useState<string[]>([""]);
  const [downloadFilePath, setDownloadFilePath] = useState<string>();
  const [confirmDownload, setConfirmDownload] = useState<boolean>(false);
  const [currentPath, setCurrentPath] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    fetchData();
  }, []);

  const onLookup = (filePath: string) => {
    setCurrentPath(filePath);
    setFilePaths(filePaths.concat([filePath]));
    fetchData(filePath);
  };

  const onDownload = (filePath: string) => {
    setDownloadFilePath(filePath);
    Modal.confirm(configModel);
  };

  useEffect(() => {
    if (downloadFilePath !== undefined && confirmDownload) {
      downloadFile(downloadFilePath);
    }
  }, [downloadFilePath, confirmDownload]);
  const downloadFile = async (filePath: string) => {
    setLoading(true);
    try {
      const response = await getDownloadFile(filePath);

      const { data } = await axios({
        url: `${response?.url ?? ""}`, //your url
        method: "GET",
        responseType: "blob", // important
      });
      const getUrl = new URL(response?.url ?? "");
      const fileName = decodeURI(path.basename(getUrl.pathname));

      saveAs(data, fileName);
      //const url = window.URL.createObjectURL(new Blob([data]));
      /*const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();*/
      setLoading(false);
      console.log({ response });
    } catch (error) {
      setLoading(false);
    }
    setConfirmDownload(false);
  };
  const onBack = () => {
    const index = filePaths.findIndex((el) => el === currentPath);
    if (index > 0) {
      const newArray: string[] = [];
      const newFilePaths = filePaths.slice(0, index);
      const backPath = newFilePaths[newFilePaths.length - 1];
      setFilePaths(newArray.concat(newFilePaths));
      setCurrentPath(backPath);
      fetchData(backPath);
    }
  };
  const getFileTypeIcon = (fileName: string) => {
    const fileExt = path.extname(fileName).toLocaleLowerCase();

    switch (fileExt) {
      case ".pdf":
        return <FilePdfOutlined className="icon" />;

      case ".json":
        return <FileTextOutlined className="icon" />;
      default:
        return <FileOutlined className="icon" />;
    }
  };
  const columns = [
    {
      title: "รูปแบบ",
      width: "10%",
      render: (record: DriveFile) => (
        <>
          <div className="file-type">
            {record.fileType === DriveFileType.Folder ? (
              <FolderOutlined className="icon" />
            ) : (
              getFileTypeIcon(record.fileName)
            )}
          </div>
        </>
      ),
    },
    {
      title: "ชื่อ",
      width: "50%",

      render: (record: DriveFile) => (
        <>
          {record.fileType === DriveFileType.Folder ? (
            <span
              className="filename-link"
              onClick={() => {
                onLookup(record.filePath);
              }}
            >
              {record.fileName}
            </span>
          ) : (
            <span>{record.fileName}</span>
          )}
        </>
      ),
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "lastModified",
      width: "15%",

      render: (date: number) => (
        <>{date ? dayjs.unix(date).format("DD-MM-YYYY HH:mm:ss") : ""}</>
      ),
    },
    {
      title: "ขนาดไฟล์(MB)",
      dataIndex: "size",

      render: (fileSize: number) => (
        <>{fileSize ? `${convertFileSize(fileSize)}` : ""}</>
      ),
    },
    {
      title: "จัดการ",
      width: "10%",

      render: (record: DriveFile) => (
        <>
          <div className="action-button">
            {record.fileType === DriveFileType.Folder ? (
              <Button
                shape="circle"
                icon={<SearchOutlined />}
                onClick={() => {
                  onLookup(record.filePath);
                }}
              />
            ) : (
              <Button
                shape="circle"
                icon={<DownloadOutlined />}
                onClick={() => {
                  onDownload(`${record.filePath}/${record.fileName}`);
                }}
              />
            )}
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="card card-custom gutter-b cardStyle">
        <div className="card-header">
          <div className="card-title">
            <div className="card-label">
              <div>ดาวน์โหลด</div>
            </div>
          </div>
        </div>
        <div className="card-body cardBodyStyle">
          <div>
            <Button
              onClick={onBack}
              disabled={currentPath === filePaths[0] ? true : false}
            >
              กลับ
            </Button>
          </div>

          <div className="download-container">
            <Table
              columns={columns}
              dataSource={files}
              key={"tablefile"}
              loading={loading}
              pagination={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const FolderItem: FC = () => {
  return (
    <div className="folder-item">
      <div className="folderIcon">
        <FolderOutlined />
      </div>

      <div></div>
    </div>
  );
};

export const roundDecimal = (num: number, decimal: number = 2) => {
  return Math.round(num * 10 ** decimal) / 10 ** decimal;
};

export const convertFileSize = (byteSize: number) => {
  return roundDecimal(byteSize / (1024 * 1024), 3);
};
