import axios from 'axios';

// export const REPORTS_URL = "api/reports";
export const REPORTS_URL = `/admin/contents`;

// CREATE =>  POST: add a new report to the server
export function createReport(report) {
   return axios.post(REPORTS_URL, { report });
}

// READ
export function getAllReports() {
   return axios.get(REPORTS_URL);
}

export function getReportById(reportId) {
   return axios.get(`${REPORTS_URL}/${reportId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findReports(queryParams) {
   // return axios.post(`${REPORTS_URL}/find`, { queryParams });
   let limit = '';
   let lastKey = {};
   let lastKeyQuery = '';
   if (queryParams.pageSize) {
      limit = `?limit=${queryParams.pageSize}`;
   }
   if (queryParams.pageNumber > 1) {
      lastKey['lastPosition'] =
         (queryParams.pageNumber - 1) * queryParams.pageSize;
      lastKeyQuery = '&lastEvaluatedKey=' + btoa(JSON.stringify(lastKey));
   }
   return axios.get(`${REPORTS_URL}${limit}${lastKeyQuery}`);
}

// UPDATE => PUT: update the report on the server
export function updateReport(report) {
   return axios.put(`${REPORTS_URL}/${report.id}`, { report });
}

// UPDATE Status
export function updateStatusForReports(ids, status) {
   return axios.post(`${REPORTS_URL}/updateStatusForReports`, {
      ids,
      status
   });
}

// DELETE => delete the report from the server
export function deleteReport(reportId) {
   return axios.delete(`${REPORTS_URL}/${reportId}`);
}

export function blockReport(reportId) {
   return axios.delete(`${REPORTS_URL}/${reportId}`);
}

// DELETE Reports by ids
export function deleteReports(ids) {
   return axios.delete(`/admin/deleteReports`, {
      data: { reportUIDList: ids }
   });
}
