// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../../_metronic/_partials/controls";
import Spinner from "../../../../../../_metronic/_helpers/spinner";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
     head: { fontSize: "20px", fontWeight: "600", margin: "16px auto 0", color: "#012875" },
}));

// Validation schema
const CategoryEditSchema = Yup.object().shape({
     name: Yup.string()
          .min(2, "อย่างน้อย 2 ตัวอักษร")
          .max(200, "ไม่เกิน 200 ตัวอักษร")
          .required("กรุณากรอกชื่อ"),
});

export function CategoryEditForm({
     saveCategory,
     saveSubCategory,
     categoryForEdit,
     subCategoryForEdit,
     actionsLoading,
     onHide,
     isSubCat,
}) {
     const styles = useStyles();
     return (
          <>
               <p className={styles.head}>{isSubCat ? "แก้ไขชื่อหมวดหมู่ย่อย" : "แก้ไขชื่อหมวดหมู่หลัก"}</p>
               <Formik
                    enableReinitialize={true}
                    initialValues={
                         isSubCat
                              ? { name: subCategoryForEdit.subcatName, status: subCategoryForEdit.status }
                              : { name: categoryForEdit.catName, status: categoryForEdit.status }
                    }
                    validationSchema={CategoryEditSchema}
                    onSubmit={(values) => {
                         if (isSubCat) {
                              saveSubCategory(values, categoryForEdit.id);
                         } else {
                              saveCategory(values, categoryForEdit.id);
                         }
                    }}
               >
                    {({ handleSubmit, values, setFieldValue, errors }) => {
                         return (
                              <>
                                   <Modal.Body>
                                        {actionsLoading && <Spinner forNewCat={true} />}
                                        <Form className="form form-label-right">
                                             <div className={`col-lg-12 input`}>
                                                  <Field
                                                       name="name"
                                                       component={Input}
                                                       placeholder={
                                                            isSubCat ? "กรอกชื่อหมวดหมู่ย่อย" : "กรอกชื่อหมวดหมู่หลัก"
                                                       }
                                                       label={isSubCat ? "ชื่อหมวดหมู่ย่อย" : "ชื่อหมวดหมู่หลัก"}
                                                  />
                                             </div>
                                        </Form>
                                        <div className="divButton ">
                                             <button className="denyButton" type="button" onClick={onHide}>
                                                  ยกเลิก
                                             </button>
                                             <button
                                                  className="acceptButton"
                                                  type="submit"
                                                  disabled={actionsLoading || !values["name"] ? true : false}
                                                  onClick={() => {
                                                       handleSubmit();
                                                  }}
                                             >
                                                  ตกลง
                                             </button>
                                        </div>
                                   </Modal.Body>
                              </>
                         );
                    }}
               </Formik>
          </>
     );
}
