import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCatalogueUIContext } from "../CatalogueUIContext";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import * as actions from "../../../_redux/catalogue/catalogueActions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(() => ({
  divButton: {
    marginLeft: "8px",
  },
  inactive: {
    border: "none",
    width: "64px",
    height: "30px",
    color: "#828282",
    backgroundColor: "transparent",
  },
  active: {
    border: "none",
    width: "64px",
    height: "28px",
    borderRadius: "100px",
    backgroundColor: "#012875",
    color: "#ffffff",
  },

}));

const prepareFilter = (queryParams, values) => {
  const { status, type, searchText, memberMode } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by status
  filter.status = status !== "" ? +status : undefined;
  // Filter by type
  filter.type = type !== "" ? +type : undefined;
  // Filter by all fields
  filter.lastName = searchText;
  // filter.firstName = searchText;
  // filter.email = searchText;
  // filter.ipAddress = searchText;
  newQueryParams.keyword = searchText;
  newQueryParams.memberMode = memberMode;
  return newQueryParams;
};

export function CatalogueFilter({ listLoading, isPrivate, isFree }) {
  // Catalogue UI Context
  const dispatch = useDispatch();
  const [memberMode, setMemberMode] = useState(0);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const catalogueUIContext = useCatalogueUIContext();
  const catalogueUIProps = useMemo(() => {
    return {
      queryParams: catalogueUIContext.queryParams,
      setQueryParams: catalogueUIContext.setQueryParams,
    };
  }, [catalogueUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(catalogueUIProps.queryParams, values);
    if (!isEqual(newQueryParams, catalogueUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      catalogueUIProps.setQueryParams(newQueryParams);
    }
  };

  const classes = useStyles();

  return (
    <>
      <Formik
        initialValues={{
          status: "", // values => All=""/Suspended=0/Active=1/Pending=2
          type: "", // values => All=""/Business=0/Individual=1
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(Object.assign({}, values, { memberMode: memberMode }));
        }}
      >
        {({ values, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
          <form
            onSubmit={handleSubmit}
            className="form form-label-right"
            style={{ marginTop: "10px" }}
          >
            <div className="form-group row" style={{ justifyContent: "space-between" }}>
              <div className="col-md-10">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="ค้นหา"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                />
                <span
                  className="searchIcon"
                  onClick={() => {
                    if (!catalogueUIProps.queryParams.keyword || !values.searchText) {
                      handleSubmit();
                    } else {
                      applyFilter(
                        Object.assign({}, values, { searchText: "", memberMode: memberMode })
                      );
                      setFieldValue("searchText", "");
                    }
                  }}
                >
                  {!catalogueUIProps.queryParams.keyword || !values.searchText ? (
                    <img src="/media/members/search-icon.svg" alt="" />
                  ) : (
                    <img
                      src="/media/members/reset-search.svg"
                      alt=""
                      style={{ transform: "scale(0.7)" }}
                    />
                  )}
                </span>
                {/* <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small> */}
              </div>
              {/* <div style={{ padding: "5px 16px", display: "flex" }}>
                {isPrivate ? (
                  <span
                    style={{
                      cursor: "pointer",
                      fontSize: "14px",
                      color: "#189AEB",
                      marginBottom: "0px",
                      transform: "translate(-16px, 4px)",
                    }}
                    onClick={() => {
                      setLoadingSpinner(true);
                      const reqFunc = async () => {
                        const req = await axios.get(`/admin/private/contents/verify`);
                        if (req.data.status) {
                          setLoadingSpinner(false);
                          dispatch(actions.fetchCatalogue(catalogueUIProps.queryParams, isPrivate));
                        }
                      };
                      reqFunc();
                    }}
                  >
                    {loadingSpinner ? (
                      <div
                        className="overlay-layer bg-transparent"
                        style={{ transform: "translate(-30px, 10px)" }}
                      >
                        <div className="spinner spinner-lg spinner-success" />
                      </div>
                    ) : (
                      ""
                    )}
                    ตรวจสอบไฟล์ทั้งหมด
                  </span>
                ) : (
                  ""
                )}
                <div>
                  <button
                    className={memberMode === 0 ? classes.active : classes.inactive}
                    onClick={() => {
                      setMemberMode(0);
                      applyFilter(Object.assign({}, values, { memberMode: 0 }));
                    }}
                  >
                    ทั้งหมด
                  </button>
                </div>
                <div className={classes.divButton}>
                  <button
                    className={memberMode === "1" ? classes.active : classes.inactive}
                    onClick={() => {
                      setMemberMode("1");
                      applyFilter(Object.assign({}, values, { memberMode: "1" }));
                    }}
                  >
                    ใช้งาน
                  </button>
                </div>
                <div className={classes.divButton}>
                  <button
                    className={memberMode === "0" ? classes.active : classes.inactive}
                    onClick={() => {
                      setMemberMode("0");
                      applyFilter(Object.assign({}, values, { memberMode: "0" }));
                    }}
                  >
                    บล็อก
                  </button>
                </div>
              </div> */}
              {/* <select
                  className="form-control"
                  name="status"
                  placeholder="Filter by Status"
                  // TODO: Change this code
                  onChange={(e) => {
                    setFieldValue("status", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.status}
                >
                  <option value="">ทั้งหมด</option>
                  <option value="0">ใช้งาน</option>
                  <option value="1">บล็อก</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Status
                </small> */}

              {/* <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Type"
                  name="type"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("type", e.target.value);
                    handleSubmit();
                  }}
                  value={values.type}
                >
                  <option value="">All</option>
                  <option value="0">Business</option>
                  <option value="1">Individual</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Type
                </small>
              </div> */}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
