import React from 'react';
import { ClipLoader } from 'react-spinners';

const Spinner = ({
   forEditCustomer,
   forNewCat,
   forQrCode,
   isSubCat,
   forNewEbook,
   forDashboard,
   fullScreen,
   forNewChannel,
   forNewCompany
}) => {
   const loaderHeight = 50;
   return (
      <div
         style={
            forEditCustomer
               ? {
                    transform: 'translate(0px, 239px)',
                    position: 'absolute',
                    width: 'calc(500px - 3.5rem)',
                    minHeight: `${loaderHeight}px`,
                    textAlign: 'center',
                    padding: '20px 0 0 0',
                    zIndex: 1
                 }
               : forNewCat
               ? {
                    transform: isSubCat
                       ? 'translate(0px, 50px)'
                       : 'translate(0px, 12px)',
                    position: 'absolute',
                    width: 'calc(500px - 3.5rem)',
                    minHeight: `${loaderHeight}px`,
                    textAlign: 'center',
                    padding: '20px 0 0 0',
                    zIndex: 1
                 }
               : forNewChannel
               ? {
                    transform: 'translate(0px, 300%)',
                    position: 'absolute',
                    width: 'calc(500px - 3.5rem)',
                    minHeight: `${loaderHeight}px`,
                    textAlign: 'center',
                    padding: '20px 0 0 0',
                    zIndex: 1
                 }
               : forNewCompany
               ? {
                    transform: 'translate(0px, 620px)',
                    position: 'absolute',
                    width: '736px',
                    minHeight: `${loaderHeight}px`,
                    textAlign: 'center',
                    padding: '20px 0 0 0',
                    zIndex: 1
                 }
               : forQrCode
               ? {
                    transform: 'translate(0px, 200px)',
                    position: 'absolute',
                    width: 'calc(800px - 3.5rem)',
                    minHeight: `${loaderHeight}px`,
                    textAlign: 'center',
                    padding: '20px 0 0 0',
                    zIndex: 1
                 }
               : forNewEbook
               ? {
                    transform: 'translate(0px, 320px)',
                    position: 'absolute',
                    width: fullScreen ? 'calc(100% - 4.5rem)' : '100%',
                    minHeight: `${loaderHeight}px`,
                    textAlign: 'center',
                    padding: '20px 0 0 0',
                    zIndex: 1
                 }
               : forDashboard
               ? {
                    // transform: "translate(0px, 12px)",
                    // position: "absolute",
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '42vh',
                    width: '100%',
                    minHeight: `${loaderHeight}px`,
                    textAlign: 'center',
                    padding: '20px 0 0 0',
                    zIndex: 1,
                    display: 'flex'
                 }
               : {
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    width: '100%',
                    minHeight: `${loaderHeight}px`,
                    textAlign: 'center',
                    padding: '20px 0 0 0',
                    zIndex: 1,
                    display: 'flex'
                 }
         }
      >
         <ClipLoader size={loaderHeight} color={'#123abc'} loading={true} />
      </div>
   );
};
export default Spinner;
