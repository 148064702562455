import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { Divider } from "antd";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import FileAndCover from "../../upload-company/FileAndCover";
import moment from "moment";
import { UploadInformationDialog } from "../../upload-company/upload-information-dialog/UploadInformationDialog";

export function CompanyViewDialog({ id, isPrivate, show, onHide }) {
  // Companies UI Context
  const loaderHeight = 50;
  const [companyDetail, setCompanyDetail] = useState(null);
  const [coverPost, setCoverPost] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [FILENAMEToUpload, setFILENAMEToUpload] = useState(undefined);
  const imgRef = useRef(null);
  const [successCoverUpload, setSuccessCoverUpload] = useState(false);
  const [editedImage, setEditedImage] = useState(null);

  // Companies Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.companies.actionsLoading }),
    shallowEqual
  );
  const [editImageModal, setEditImageModal] = useState(false);
  const [errorInfoModal, setErrorInfoModal] = useState(false);
  const [clientDetail, setClientDetail] = useState(null);

  const reqDetailFunc = async (val) => {
    try {
      const req = await axios.get(`/superuser/clients/${id}`);
      console.log("dd", req.data.data);
      const { data } = req;
      if (data.status && data.data) {
        setClientDetail(data.data.client);
      }
    } catch (err) {
      setCompanyDetail(0);
    }
  };

  const resetStates = () => {
    setCoverPost(null);
    setCompletedCrop(null);
    setFILENAMEToUpload(undefined);
    setSuccessCoverUpload(false);
    setEditImageModal(false);
  };

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
      setCompanyDetail(null);
    } else {
      reqDetailFunc();
      setCompanyDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const millisecsToDateTime = (secs, timeFormat, dateFormat) => {
    const pad = (num) => {
      return ("0" + num).slice(-2);
    };
    if (secs) {
      var momentBKK = moment.tz(secs * 1000, "Asia/Bangkok").format();
      var a = new Date(momentBKK);
      var months = [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ];
      var year = a.getFullYear() || "";
      var month = months[a.getMonth()] || "";
      var date = a.getDate() || "";
      var hour = a.getHours() || "0";
      var minute = a.getMinutes() || "0";
      var time =
        timeFormat === "hr-min"
          ? dateFormat === "dd/mm/yyyy"
            ? (parseInt(date, 10) < 10 ? "0" : "") +
              date +
              (parseInt(a.getMonth() + 1, 10) < 10 ? "/0" : "/") +
              parseInt(a.getMonth() + 1, 10) +
              "/" +
              year +
              " " +
              pad(hour) +
              ":" +
              pad(minute)
            : date +
              " " +
              month +
              " " +
              year +
              " " +
              pad(hour) +
              ":" +
              pad(minute)
          : date + " " + month + " " + year;
      return time;
    }
  };

  const reqDeleteFunc = async () => {
    const req = await axios.delete(`/superuser/clients/file`, {
      data: { fileName: FILENAMEToUpload },
    });
    if (req.data.status) {
      resetStates();
    }
  };

  return (
    <>
      <UploadInformationDialog
        show={errorInfoModal}
        onHide={() => {
          setErrorInfoModal(false);
        }}
        isError={true}
      />
      <Modal
        backdrop="static"
        size="md"
        show={editImageModal}
        onHide={() => setEditImageModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        onShow={() => {
          // console.log(window.scrollY);
        }}
      >
        <Modal.Body>
          <div
            style={{ position: "absolute", right: "1.75rem", zIndex: "555" }}
          >
            <button
              className="closeButton"
              onClick={() => {
                if (successCoverUpload) {
                  reqDeleteFunc();
                } else {
                  resetStates();
                }
              }}
            >
              <img src="/media/books/close-button.svg" alt="" />
            </button>
          </div>
          <FileAndCover
            id={id}
            isModal={true}
            openFileInformationDialog={() => setErrorInfoModal(true)}
            handleSubmitOutside={() => {
              resetStates();
            }}
            // storageData={{ usedStorage: 0, totalStorage: 0.007, percentage: 0.89 }}
            coverPost={coverPost}
            setCoverPost={setCoverPost}
            completedCrop={completedCrop}
            setCompletedCrop={setCompletedCrop}
            imgRef={imgRef}
            FILENAMEToUpload={FILENAMEToUpload}
            setFILENAMEToUpload={setFILENAMEToUpload}
            successCoverUpload={successCoverUpload}
            setSuccessCoverUpload={setSuccessCoverUpload}
            setEditedImage={setEditedImage}
          />
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        size={companyDetail === 0 ? "md" : "lg"}
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        onShow={() => {
          // console.log(window.scrollYyyy);
        }}
      >
        {isLoading && <ModalProgressBar />}
        <Modal.Body>
          {companyDetail === 0 ? (
            ""
          ) : (
            <div style={{ position: "absolute", right: "1.75rem" }}>
              <button
                className="closeButton"
                onClick={() => {
                  onHide();
                  setCompanyDetail(null);
                }}
              >
                <img src="/media/books/close-button.svg" alt="" />
              </button>
            </div>
          )}
          {clientDetail ? (
            <div className="companyDetail">
              <h2>รายละเอียดข้อมูลองค์กร</h2>
              <div className="box">
                <h3 className="absolute">ข้อมูลองค์กร</h3>
                <div className="coverEdit">
                  <img
                    className="logo"
                    src={editedImage || clientDetail.logoImages}
                    alt=""
                  />
                  <img
                    className="cameraBG"
                    src="/media/members/ellipse.svg"
                    alt=""
                  />
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="quick-actions-tooltip">แก้ไขรูป</Tooltip>
                    }
                  >
                    <img
                      src="/media/members/camera.svg"
                      alt=""
                      onClick={() => setEditImageModal(true)}
                      className="camera"
                    />
                  </OverlayTrigger>
                </div>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">ชื่อองค์กร</div>
                  </div>
                  <div className="col-lg-8">{clientDetail.clientName}</div>
                </div>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">ชื่อห้องสมุดองค์กร</div>
                  </div>
                  <div className="col-lg-8">{clientDetail.displayName}</div>
                </div>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">ชื่อย่อองค์กร</div>
                  </div>
                  <div className="col-lg-8">{clientDetail.companyPrefix}</div>
                </div>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">ปรับแต่งหัวข้อ บรรทัดที่ 1</div>
                  </div>
                  <div className="col-lg-8">
                    {clientDetail?.customTitle?.line1 ?? "-"}
                  </div>
                </div>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">ปรับแต่งหัวข้อ บรรทัดที่ 2</div>
                  </div>
                  <div className="col-lg-8">
                    {clientDetail?.customTitle?.line2 ?? "-"}
                  </div>
                </div>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">ปรับแต่งหัวข้อ บรรทัดที่ 3</div>
                  </div>
                  <div className="col-lg-8">
                    {clientDetail?.customTitle?.line3 ?? "-"}
                  </div>
                </div>

                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">UID</div>
                  </div>
                  <div className="col-lg-8">{id}</div>
                </div>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">จำนวนสมาชิก</div>
                  </div>
                  <div className="col-lg-8">
                    {clientDetail.currentMember
                      ? clientDetail.currentMember.toLocaleString()
                      : "-"}
                    {" / "}
                    {clientDetail.userLimit
                      ? clientDetail.userLimit.toLocaleString()
                      : "-"}
                  </div>
                </div>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">
                      วันที่เริ่ม - วันที่สิ้นสุด
                    </div>
                  </div>
                  <div className="col-lg-8">
                    {millisecsToDateTime(
                      clientDetail.startDate,
                      undefined,
                      "dd/mm/yyyy"
                    ) +
                      " - " +
                      millisecsToDateTime(
                        clientDetail.expireDate,
                        undefined,
                        "dd/mm/yyyy"
                      )}
                  </div>
                </div>
              </div>
              <div className="box">
                <h3>สิทธิ์การยืมรายเล่ม (concurrent)</h3>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">
                      จำนวนสูงสุดในการยืมแบบสมาชิก
                    </div>
                  </div>
                  <div className="col-lg-8">{clientDetail.rentalLimit}</div>
                </div>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">
                      จำนวนสูงสุดในการยืมแบบไม่ระบุตัวตน
                    </div>
                  </div>
                  <div className="col-lg-8">
                    {clientDetail.anonymousRentalLimit}
                  </div>
                </div>
              </div>
              {clientDetail.privateLibrary &&
              clientDetail.privateLibrary.totalStorage &&
              clientDetail.privateLibrary.totalTitle ? (
                <div className="box">
                  <h3>พื้นที่จัดเก็บสื่อองค์กร</h3>

                  <Divider />
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="text-title">พื้นที่จัดเก็บข้อมูลสื่อ</div>
                    </div>
                    <div className="col-lg-8">
                      {clientDetail.privateLibrary.totalStorage < 1
                        ? clientDetail.privateLibrary.totalStorage * 1000 +
                          " MB"
                        : clientDetail.privateLibrary.totalStorage + " GB"}
                    </div>
                  </div>
                  <Divider />
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="text-title">จำนวนรายการสื่อ</div>
                    </div>
                    <div className="col-lg-8">
                      {clientDetail?.privateLibrary?.totalTitle
                        ? clientDetail.privateLibrary.totalTitle.toLocaleString()
                        : "-"}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="boxAlone">
                  <div className="row">
                    <div className="col-lg-4">
                      <h3>พื้นที่จัดเก็บสื่อองค์กร</h3>
                    </div>
                    <div className="col-lg-8" style={{ alignSelf: "center" }}>
                      <div>ไม่มีแพคเกจ</div>
                    </div>
                  </div>
                </div>
              )}
              <div className="box">
                <h3>ข้อมูลติดต่อ</h3>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">ที่อยู่</div>
                  </div>
                  <div className="col-lg-8">
                    {clientDetail.contactList &&
                    clientDetail.contactList.address
                      ? clientDetail.contactList.address
                      : "-"}
                  </div>
                </div>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">เบอร์โทรศัพท์</div>
                  </div>
                  <div className="col-lg-8">
                    {clientDetail.contactList &&
                    clientDetail.contactList.phoneNumber
                      ? clientDetail.contactList.phoneNumber
                      : "-"}
                  </div>
                </div>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">เบอร์โทรศัพท์มือถือ</div>
                  </div>
                  <div className="col-lg-8">
                    {clientDetail.contactList &&
                    clientDetail.contactList.mobilePhone
                      ? clientDetail.contactList.mobilePhone
                      : "-"}
                  </div>
                </div>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">Facebook</div>
                  </div>
                  <div className="col-lg-8">
                    {clientDetail.contactList &&
                    clientDetail.contactList.Facebook
                      ? clientDetail.contactList.Facebook
                      : "-"}
                  </div>
                </div>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">Line ID</div>
                  </div>
                  <div className="col-lg-8">
                    {clientDetail.contactList && clientDetail.contactList.LineID
                      ? clientDetail.contactList.LineID
                      : "-"}
                  </div>
                </div>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">อีเมล</div>
                  </div>
                  <div className="col-lg-8">
                    {clientDetail.contactList && clientDetail.contactList.email
                      ? clientDetail.contactList.email
                      : "-"}
                  </div>
                </div>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">Twitter</div>
                  </div>
                  <div className="col-lg-8">
                    {clientDetail.contactList &&
                    clientDetail.contactList.Twitter
                      ? clientDetail.contactList.Twitter
                      : "-"}
                  </div>
                </div>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">Instagram</div>
                  </div>
                  <div className="col-lg-8">
                    {clientDetail.contactList &&
                    clientDetail.contactList.Instagram
                      ? clientDetail.contactList.Instagram
                      : "-"}
                  </div>
                </div>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">YouTube</div>
                  </div>
                  <div className="col-lg-8">
                    {clientDetail.contactList &&
                    clientDetail.contactList.YouTube
                      ? clientDetail.contactList.YouTube
                      : "-"}
                  </div>
                </div>
                <Divider />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="text-title">Website</div>
                  </div>
                  <div className="col-lg-8">
                    {clientDetail.contactList &&
                    clientDetail.contactList.website
                      ? clientDetail.contactList.website
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
          ) : companyDetail === 0 ? (
            <div>
              <img src="/media/members/image-modal.svg" alt="" />
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  color: "#d7042b",
                  marginBottom: "12px",
                }}
              >
                <p>กำลังดำเนินการ...</p>
              </div>
              <div className="divButton">
                <button
                  type="button"
                  onClick={() => {
                    onHide();
                    setCompanyDetail(null);
                  }}
                  className="acceptButton"
                  // style={{
                  //   margin: "8px",
                  //   color: "#ffffff",
                  //   fontSize: "18px",
                  //   fontWeight: 600,
                  //   width: "160px",
                  //   height: "48px",
                  //   borderRadius: "100px",
                  //   backgroundColor: "#012875",
                  //   border: "none",
                  // }}
                >
                  ตกลง
                </button>
              </div>
            </div>
          ) : (
            <div
              style={{
                minHeight: `${loaderHeight}px`,
                textAlign: "center",
                padding: "20px 0 0 0",
                width: "100%",
              }}
            >
              <ClipLoader
                size={loaderHeight}
                color={"#123abc"}
                loading={true}
              />
            </div>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            Cancel
          </button>
          <> </>
          <button type="button" onClick={blockCompany} className="btn btn-primary btn-elevate">
            Block
          </button>
        </div>
      </Modal.Footer> */}
      </Modal>
    </>
  );
}
