/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useState } from 'react';
import objectPath from 'object-path';
import { makeStyles } from '@material-ui/core/';
import { useLocation, useHistory } from 'react-router-dom';
import { BreadCrumbs } from './components/BreadCrumbs';
import {
    getBreadcrumbsAndTitle,
    useSubheader
} from '../../_core/MetronicSubheader';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { shallowEqual, useSelector } from 'react-redux';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import queryString from 'query-string';
import { Breadcrumb } from 'antd';
import './subheader.scss';
const useStyles = makeStyles(theme => ({}));

const BreadCrumbView = ({ items }) => {
    const history = useHistory();
    return (
        <Breadcrumb separator=">">
            {Array.isArray(items())
                ? items().map((e, index) => {
                      return e.pathname ? (
                          <Breadcrumb.Item
                              key={index}
                              onClick={() => {
                                  console.log(e.actionsLoading);
                                  if (e.actionsLoading) {
                                  } else if (e.notAllowed) {
                                  } else {
                                      history.push(e.pathname);
                                  }
                              }}
                              style={
                                  e.actionsLoading || e.notAllowed
                                      ? { cursor: 'not-allowed' }
                                      : { cursor: 'pointer' }
                              }
                          >
                              {e.title}
                          </Breadcrumb.Item>
                      ) : (
                          <Breadcrumb.Item key={index}>
                              {e.title}
                          </Breadcrumb.Item>
                      );
                  })
                : ''}
        </Breadcrumb>
    );
};

export function SubHeader() {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const uiService = useHtmlClassService();
    const location = useLocation();
    const subheader = useSubheader();
    const history = useHistory();
    let query = useQuery();

    const { uploadState } = useSelector(
        state => ({
            uploadState: state.upload
        }),
        shallowEqual
    );
    const { actionsLoading } = useSelector(
        state => ({
            actionsLoading: state.catalogue.actionsLoading
        }),
        shallowEqual
    );
    const {
        publisherName,
        catName,
        subcatName,
        catalogueName,
        podcastName
    } = useSelector(
        state => ({
            publisherName: state.catalogue.publisherName,
            catName: state.catalogue.catName,
            subcatName: state.catalogue.subcatName,
            catalogueName: state.catalogue.catalogueName,
            podcastName: state.podcasts.podcastName
        }),
        shallowEqual
    );
    const [confirmBack, setConfirmBack] = useState(false);
    const classes = useStyles();

    const deleteUploadedImg = async () => {
        if (uploadState.fileName && uploadState.contentUID) {
            const req = await axios({
                method: 'delete',
                url: `/admin/banners/file`,
                data: {
                    fileName: uploadState.fileName,
                    bannerUID: uploadState.contentUID
                }
            });
            if (req.data.status) {
                setConfirmBack(false);
                history.push(`/banners`);
            }
        }
    };

    const layoutProps = useMemo(() => {
        return {
            config: uiService.config,
            subheaderMobileToggle: objectPath.get(
                uiService.config,
                'subheader.mobile-toggle'
            ),
            subheaderCssClasses: uiService.getClasses('subheader', true),
            subheaderContainerCssClasses: uiService.getClasses(
                'subheader_container',
                true
            )
        };
    }, [uiService]);

    useLayoutEffect(() => {
        const aside = getBreadcrumbsAndTitle(
            'kt_aside_menu',
            location.pathname
        );
        const header = getBreadcrumbsAndTitle(
            'kt_header_menu',
            location.pathname
        );
        subheader.setBreadcrumbs(aside.breadcrumbs || header.breadcrumbs);
        subheader.setTitle(aside.title || header.title);
        // eslint-disable-next-line
    }, [location.pathname]);

    // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
    return (
        <>
            <Modal
                backdrop="static"
                show={confirmBack}
                onHide={() => {
                    setConfirmBack(false);
                }}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Body>
                    <div style={{ textAlign: 'center' }}>
                        <img src="/media/members/image-modal.svg" alt="" />
                        <div className="text-text-red">
                            ต้องการย้อนกลับไปหน้ารวมแบนเนอร์?
                        </div>
                        <div className="divButton">
                            <button
                                type="button"
                                onClick={() => setConfirmBack(false)}
                                className="denyButton"
                            >
                                ยกเลิก
                            </button>
                            <button
                                type="button"
                                onClick={deleteUploadedImg}
                                className="acceptButton"
                            >
                                ยืนยัน
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <div
                id="kt_subheader"
                className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
            >
                <div
                    className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
                >
                    {/* Info */}
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        {layoutProps.subheaderMobileToggle && (
                            <button
                                className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                                id="kt_subheader_mobile_toggle"
                            >
                                <span />
                            </button>
                        )}

                        {/*<div className="d-flex align-items-baseline mr-5">
            <h5 className="text-dark font-weight-bold my-2 mr-5">
              <>{subheader.title}</>
              <small></small>
            </h5>
          </div>*/}

                        {location.pathname === '/authors' ||
                        location.pathname === '/creators' ||
                        location.pathname === '/upload-subscription' ||
                        location.pathname === '/subcategories-private-ebook' ||
                        location.pathname === '/subcategories-private-video' ||
                        location.pathname ===
                            '/subcategories-private-podcast' ||
                        location.pathname === '/qrcode-members' ||
                        location.pathname === '/banner-private' ? (
                            <button
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    color: '#012875',
                                    fontSize: '16px'
                                }}
                                onClick={() => {
                                    if (location.pathname === '/authors') {
                                        history.push(`/publishers`);
                                    } else if (
                                        location.pathname === '/creators'
                                    ) {
                                        history.push(`/channels`);
                                    } else if (
                                        location.pathname === '/subcategories'
                                    ) {
                                        history.push(`/categories`);
                                    } else if (
                                        location.pathname ===
                                        '/subcategories-private-ebook'
                                    ) {
                                        history.push(
                                            `/categories-private-ebook`
                                        );
                                    } else if (
                                        location.pathname ===
                                        '/subcategories-private-video'
                                    ) {
                                        history.push(
                                            `/categories-private-video`
                                        );
                                    } else if (
                                        location.pathname ===
                                        '/subcategories-private-podcast'
                                    ) {
                                        history.push(
                                            `/categories-private-podcast`
                                        );
                                    } else if (
                                        location.pathname ===
                                        '/upload-subscription'
                                    ) {
                                        history.push(`/subscription`);
                                    } else if (
                                        location.pathname === '/qrcode-members'
                                    ) {
                                        history.push(`/qrcodes`);
                                    } else if (
                                        location.pathname === '/banner-private'
                                    ) {
                                        if (
                                            uploadState.fileName &&
                                            uploadState.contentUID
                                        ) {
                                            setConfirmBack(true);
                                        } else {
                                            history.push(`/banners`);
                                        }
                                    }
                                }}
                            >
                                <span style={{ display: 'flex' }}>
                                    <img
                                        src="/media/members/arrow-back.png"
                                        alt=""
                                        style={{ marginRight: '7px' }}
                                    />
                                    Back
                                </span>
                            </button>
                        ) : location.pathname === '/catalogue-detail' ||
                          location.pathname === '/ebooks-private' ||
                          location.pathname === '/videos-private' ||
                          location.pathname === '/podcasts-private' ||
                          location.pathname === '/upload-company' ||
                          location.pathname === '/checkebook' ||
                          location.pathname === '/checkebook-detail' ||
                          location.pathname === '/checkebook-catagory' ||
                          location.pathname === '/checkebook-subdetail' ? (
                            <BreadCrumbView
                                items={() => {
                                    if (location.pathname === '/checkebook') {
                                        const parsed = queryString.parse(
                                            location.search
                                        );

                                        return [
                                            {
                                                pathname: `/checkebook`,
                                                title: 'ตรวจสอบรายการอีบุ๊ก'
                                            }
                                        ];
                                    }

                                    if (
                                        location.pathname ===
                                        '/checkebook-detail'
                                    ) {
                                        const parsed = queryString.parse(
                                            location.search
                                        );

                                        if (location.search.includes('id')) {
                                            return [
                                                {
                                                    pathname: `/checkebook`,
                                                    title: `ตรวจสอบรายการอีบุ๊ก`
                                                },
                                                {
                                                    pathname: `/checkebook-detail?id=${parsed?.id}&name=${parsed?.title}&clientUID=${parsed.clientUID}`,
                                                    title: parsed.title
                                                        ? parsed.title
                                                        : '',
                                                    actionsLoading: actionsLoading
                                                }
                                            ];
                                        }
                                    }

                                    if (
                                        location.pathname ===
                                        '/checkebook-catagory'
                                    ) {
                                        const parsed = queryString.parse(
                                            location.search
                                        );
                                        console.log(parsed);
                                        if (location.search.includes('id')) {
                                            return [
                                                {
                                                    pathname: `/checkebook`,
                                                    title: `ตรวจสอบรายการอีบุ๊ก`
                                                },
                                                {
                                                    pathname: `/checkebook-detail?id=${parsed?.id}&title=${parsed?.title}&clientUID=${parsed.clientUID}`,
                                                    title: parsed.title
                                                        ? parsed.title
                                                        : '',
                                                    actionsLoading: actionsLoading
                                                },
                                                {
                                                    pathname: `/checkebook-detail?id=${parsed?.id}&title=${parsed?.title}&clientUID=${parsed.clientUID}`,
                                                    title: 'หมวดหมู่',
                                                    actionsLoading: actionsLoading
                                                },
                                                {
                                                    pathname: `/checkebook-detail?id=${parsed?.id}&title=${parsed?.title}&clientUID=${parsed.clientUID}`,
                                                    title: parsed.subtitle
                                                        ? parsed.subtitle
                                                        : '',
                                                    actionsLoading: actionsLoading
                                                }
                                            ];
                                        }
                                    }
                                    if (
                                        location.pathname ===
                                        '/checkebook-subdetail'
                                    ) {
                                        const parsed = queryString.parse(
                                            location.search
                                        );
                                        console.log(parsed);
                                        if (location.search.includes('id')) {
                                            return [
                                                {
                                                    pathname: `/checkebook`,
                                                    title: `ตรวจสอบรายการอีบุ๊ก`
                                                },
                                                {
                                                    pathname: `/checkebook-detail?id=${parsed?.id}&title=${parsed?.title}&clientUID=${parsed.clientUID}`,
                                                    title: parsed.title
                                                        ? parsed.title
                                                        : '',
                                                    actionsLoading: actionsLoading
                                                },
                                                {
                                                    pathname: `/checkebook-detail?id=${parsed?.id}&title=${parsed?.title}&clientUID=${parsed.clientUID}`,
                                                    title:
                                                        'สำนักพิมพ์/นักเขียน',
                                                    actionsLoading: actionsLoading
                                                },
                                                {
                                                    pathname: `/checkebook-subdetail?id=${parsed.id}&title=${parsed.title}&publisherName=${parsed.publisherName}&clientUID=${parsed.clientUID}&uid=${parsed.uid}`,
                                                    title: parsed.publisherName
                                                        ? parsed.publisherName
                                                        : '',
                                                    actionsLoading: actionsLoading
                                                }
                                            ];
                                        }
                                    }
                                    if (
                                        location.pathname ===
                                        '/checkebook-catagory'
                                    ) {
                                        const parsed = queryString.parse(
                                            location.search
                                        );
                                        if (location.search.includes('id')) {
                                            return [
                                                {
                                                    pathname: `/checkebook`,
                                                    title: `ตรวจสอบรายการอีบุ๊ก`
                                                },
                                                {
                                                    pathname: `/checkebook-detail?id=${parsed?.id}&title=${parsed?.title}&clientUID=${parsed.clientUID}`,
                                                    title: parsed.title
                                                        ? parsed.title
                                                        : '',
                                                    actionsLoading: actionsLoading
                                                },
                                                {
                                                    pathname: `/checkebook-detail?id=${parsed?.id}&title=${parsed?.title}&clientUID=${parsed.clientUID}`,
                                                    title: 'หมวดหมู่',
                                                    actionsLoading: actionsLoading
                                                },
                                                {
                                                    pathname: `/checkebook-detail?id=${parsed?.id}&title=${parsed?.title}&clientUID=${parsed.clientUID}`,
                                                    title: 'นวนิยาย',
                                                    actionsLoading: actionsLoading
                                                }
                                            ];
                                        }
                                    }
                                    if (
                                        location.pathname ===
                                        '/catalogue-detail'
                                    ) {
                                        if (
                                            location.search.includes('newEbook')
                                        ) {
                                            const parsed = queryString.parse(
                                                location.search
                                            );

                                            if (
                                                location.search.includes(
                                                    'publishers'
                                                ) ||
                                                location.search.includes(
                                                    'categories'
                                                ) ||
                                                location.search.includes(
                                                    'ebooks'
                                                ) ||
                                                location.search.includes(
                                                    'newEbooks'
                                                ) ||
                                                query.get('search') === 'true'
                                            ) {
                                                if (
                                                    location.search.includes(
                                                        'subcatId'
                                                    )
                                                ) {
                                                    return [
                                                        {
                                                            pathname: `/catalogue`,
                                                            title:
                                                                'รายการแคตตาล็อก',
                                                            actionsLoading: actionsLoading
                                                        },
                                                        {
                                                            pathname: `/catalogue-detail?id=${parsed.id}`,
                                                            title: catalogueName
                                                                ? catalogueName
                                                                : 'แคตตาล็อก',
                                                            actionsLoading: actionsLoading
                                                        },
                                                        {
                                                            pathname: `/catalogue-detail?id=${parsed.id}&newEbook=true`,
                                                            title: `เพิ่มรายการอีบุ๊ก`,
                                                            actionsLoading: actionsLoading
                                                        },
                                                        {
                                                            pathname: `/catalogue-detail?id=${parsed.id}&newEbook=true&categories=true`,
                                                            title: 'หมวดหมู่',
                                                            actionsLoading: actionsLoading
                                                        },
                                                        {
                                                            pathname: `/catalogue-detail?id=${parsed.id}&newEbook=true&catId=${parsed.catId}&subcategories=true`,
                                                            title: catName
                                                                ? catName
                                                                : '',
                                                            actionsLoading: actionsLoading
                                                        },
                                                        {
                                                            pathname: `/catalogue-detail?id=${parsed.id}&newEbook=true&ebooks=true&catId=${parsed.catId}&subcatId=${parsed.subcatId}`,
                                                            title: subcatName
                                                                ? subcatName
                                                                : '',
                                                            actionsLoading: actionsLoading
                                                        }
                                                    ];
                                                } else if (
                                                    query.get('search') ===
                                                    'true'
                                                ) {
                                                    console.log(
                                                        query.get('search') ===
                                                            'true'
                                                    );
                                                    return [
                                                        {
                                                            pathname: `/catalogue`,
                                                            title:
                                                                'รายการแคตตาล็อก',
                                                            actionsLoading: actionsLoading
                                                        },
                                                        {
                                                            pathname: `/catalogue-detail?id=${parsed.id}`,
                                                            title: catalogueName
                                                                ? catalogueName
                                                                : 'แคตตาล็อก',
                                                            actionsLoading: actionsLoading
                                                        },
                                                        {
                                                            pathname: `/catalogue-detail?id=${parsed.id}&newEbook=true`,
                                                            title: `เพิ่มรายการอีบุ๊ก`,
                                                            actionsLoading: actionsLoading
                                                        },
                                                        {
                                                            pathname: `/catalogue-detail?id=${parsed.id}&search=true&newEbooks=true`,
                                                            title: 'ค้นหา',
                                                            actionsLoading: actionsLoading
                                                        }
                                                    ];
                                                } else if (
                                                    location.search.includes(
                                                        'publisherId'
                                                    )
                                                ) {
                                                    return [
                                                        {
                                                            pathname: `/catalogue`,
                                                            title:
                                                                'รายการแคตตาล็อก',
                                                            actionsLoading: actionsLoading
                                                        },
                                                        {
                                                            pathname: `/catalogue-detail?id=${parsed.id}`,
                                                            title: catalogueName
                                                                ? catalogueName
                                                                : 'แคตตาล็อก',
                                                            actionsLoading: actionsLoading
                                                        },
                                                        {
                                                            pathname: `/catalogue-detail?id=${parsed.id}&newEbook=true`,
                                                            title: `เพิ่มรายการอีบุ๊ก`,
                                                            actionsLoading: actionsLoading
                                                        },
                                                        {
                                                            pathname: `/catalogue-detail?id=${parsed.id}&newEbook=true&publishers=true`,
                                                            title:
                                                                'สำนักพิมพ์/นักเขียน',
                                                            actionsLoading: actionsLoading
                                                        },
                                                        {
                                                            pathname: `/catalogue-detail?id=${parsed.id}&newEbook=true&ebooks=true&publisherId=${parsed.publisherId}`,
                                                            title: publisherName
                                                                ? publisherName
                                                                : '',
                                                            actionsLoading: actionsLoading
                                                        }
                                                    ];
                                                } else if (
                                                    location.search.includes(
                                                        'catId'
                                                    )
                                                ) {
                                                    return [
                                                        {
                                                            pathname: `/catalogue`,
                                                            title:
                                                                'รายการแคตตาล็อก',
                                                            actionsLoading: actionsLoading
                                                        },
                                                        {
                                                            pathname: `/catalogue-detail?id=${parsed.id}`,
                                                            title: catalogueName
                                                                ? catalogueName
                                                                : 'แคตตาล็อก',
                                                            actionsLoading: actionsLoading
                                                        },
                                                        {
                                                            pathname: `/catalogue-detail?id=${parsed.id}&newEbook=true`,
                                                            title: `เพิ่มรายการอีบุ๊ก`,
                                                            actionsLoading: actionsLoading
                                                        },
                                                        {
                                                            pathname: `/catalogue-detail?id=${parsed.id}&newEbook=true&categories=true`,
                                                            title: 'หมวดหมู่',
                                                            actionsLoading: actionsLoading
                                                        },
                                                        {
                                                            pathname: `/catalogue-detail?id=${parsed.id}&newEbook=true&catId=${parsed.catId}&subcategories=true`,
                                                            title: catName
                                                                ? catName
                                                                : '',
                                                            actionsLoading: actionsLoading
                                                        }
                                                    ];
                                                } else {
                                                    if (
                                                        location.search.includes(
                                                            'publishers'
                                                        )
                                                    ) {
                                                        return [
                                                            {
                                                                pathname: `/catalogue`,
                                                                title:
                                                                    'รายการแคตตาล็อก',
                                                                actionsLoading: actionsLoading
                                                            },
                                                            {
                                                                pathname: `/catalogue-detail?id=${parsed.id}`,
                                                                title: catalogueName
                                                                    ? catalogueName
                                                                    : 'แคตตาล็อก',
                                                                actionsLoading: actionsLoading
                                                            },
                                                            {
                                                                pathname: `/catalogue-detail?id=${parsed.id}&newEbook=true`,
                                                                title: `เพิ่มรายการอีบุ๊ก`,
                                                                actionsLoading: actionsLoading
                                                            },
                                                            {
                                                                pathname: `/catalogue-detail?id=${parsed.id}&newEbook=true&publishers=true`,
                                                                title:
                                                                    'สำนักพิมพ์/นักเขียน',
                                                                actionsLoading: actionsLoading
                                                            }
                                                        ];
                                                    } else if (
                                                        location.search.includes(
                                                            'categories'
                                                        )
                                                    ) {
                                                        return [
                                                            {
                                                                pathname: `/catalogue`,
                                                                title:
                                                                    'รายการแคตตาล็อก',
                                                                actionsLoading: actionsLoading
                                                            },
                                                            {
                                                                pathname: `/catalogue-detail?id=${parsed.id}`,
                                                                title: catalogueName
                                                                    ? catalogueName
                                                                    : 'แคตตาล็อก',
                                                                actionsLoading: actionsLoading
                                                            },
                                                            {
                                                                pathname: `/catalogue-detail?id=${parsed.id}&newEbook=true`,
                                                                title: `เพิ่มรายการอีบุ๊ก`,
                                                                actionsLoading: actionsLoading
                                                            },
                                                            {
                                                                pathname: `/catalogue-detail?id=${parsed.id}&newEbook=true&categories=true`,
                                                                title:
                                                                    'หมวดหมู่',
                                                                actionsLoading: actionsLoading
                                                            }
                                                        ];
                                                    } else {
                                                        return [
                                                            {
                                                                pathname: `/catalogue`,
                                                                title:
                                                                    'รายการแคตตาล็อก',
                                                                actionsLoading: actionsLoading
                                                            },
                                                            {
                                                                pathname: `/catalogue-detail?id=${parsed.id}`,
                                                                title: catalogueName
                                                                    ? catalogueName
                                                                    : 'แคตตาล็อก',
                                                                actionsLoading: actionsLoading,
                                                                notAllowed: true
                                                            },
                                                            {
                                                                pathname: `/catalogue-detail?id=${parsed.id}&newEbook=true`,
                                                                title: `เพิ่มรายการอีบุ๊ก`,
                                                                actionsLoading: actionsLoading
                                                                //  notAllowed: true
                                                            },
                                                            {
                                                                pathname: `/catalogue-detail?id=${parsed.id}&newEbook=true&newEbooks=true`,
                                                                title: 'มาใหม่',
                                                                actionsLoading: actionsLoading
                                                            }
                                                        ];
                                                    }
                                                }
                                            } else {
                                                return [
                                                    {
                                                        pathname: `/catalogue`,
                                                        title:
                                                            'รายการแคตตาล็อก',
                                                        actionsLoading: actionsLoading
                                                    },
                                                    {
                                                        pathname: `/catalogue-detail?id=${parsed.id}`,
                                                        title: catalogueName
                                                            ? catalogueName
                                                            : 'แคตตาล็อก',
                                                        actionsLoading: actionsLoading
                                                    },
                                                    {
                                                        pathname: `/catalogue-detail?id=${parsed.id}&newEbook=true`,
                                                        title: `เพิ่มรายการอีบุ๊ก`,
                                                        actionsLoading: actionsLoading
                                                    }
                                                ];
                                            }
                                        } else {
                                            const parsed = queryString.parse(
                                                location.search
                                            );
                                            return [
                                                {
                                                    pathname: `/catalogue`,
                                                    title: 'รายการแคตตาล็อก',
                                                    actionsLoading: actionsLoading
                                                },
                                                {
                                                    pathname: `/catalogue-detail?id=${parsed.id}`,
                                                    title: catalogueName
                                                        ? catalogueName
                                                        : 'แคตตาล็อก',
                                                    actionsLoading: actionsLoading
                                                }
                                            ];
                                        }
                                    }
                                    if (
                                        location.pathname === '/ebooks-private'
                                    ) {
                                        if (
                                            location.search.includes('newEbook')
                                        ) {
                                            return [
                                                {
                                                    pathname: `/ebooks-private`,
                                                    title: 'อีบุ๊ก'
                                                },
                                                {
                                                    pathname: `/ebooks-private?newEbook=true`,
                                                    title: `อัปโหลดไฟล์`
                                                }
                                            ];
                                        }
                                    }

                                    if (
                                        location.pathname === '/upload-company'
                                    ) {
                                        return [
                                            {
                                                pathname: `/companies`,
                                                title: 'องค์กรที่ใช้บริการ'
                                            },
                                            {
                                                pathname: `/upload-company`,
                                                title: `เพิ่มองค์กร`
                                            }
                                        ];
                                    }
                                    if (
                                        location.pathname === '/videos-private'
                                    ) {
                                        if (
                                            location.search.includes('newVideo')
                                        ) {
                                            return [
                                                {
                                                    pathname: `/videos-private`,
                                                    title: 'วิดีโอ'
                                                },
                                                {
                                                    pathname: `/videos-private?newVideo=true`,
                                                    title: `อัปโหลดวิดีโอ`
                                                }
                                            ];
                                        }
                                    }
                                    if (
                                        location.pathname ===
                                        '/podcasts-private'
                                    ) {
                                        const parsed = queryString.parse(
                                            location.search
                                        );
                                        if (
                                            location.search.includes('newPod')
                                        ) {
                                            return [
                                                {
                                                    pathname: `/podcasts-private`,
                                                    title: 'พ็อดคาสท์'
                                                },
                                                {
                                                    pathname: `/podcasts-private?newPod=true`,
                                                    title: `สร้างรายการพ็อดคาสท์`
                                                }
                                            ];
                                        } else if (
                                            location.search.includes('view')
                                        ) {
                                            return [
                                                {
                                                    pathname: `/podcasts-private`,
                                                    title: 'พ็อดคาสท์'
                                                },
                                                {
                                                    pathname: `/podcasts-private?id=${parsed.id}&view=true`,
                                                    title: podcastName
                                                }
                                            ];
                                        } else if (
                                            location.search.includes('upload')
                                        ) {
                                            return [
                                                {
                                                    pathname: `/podcasts-private`,
                                                    title: 'พ็อดคาสท์'
                                                },
                                                {
                                                    pathname: `/podcasts-private?id=${parsed.id}&view=true`,
                                                    title: podcastName
                                                },
                                                {
                                                    pathname: `/podcasts-private?id=${parsed.id}&upload=true`,
                                                    title: 'อัปโหลดพ็อดคาสท์'
                                                }
                                            ];
                                        }
                                    }
                                    if (location.pathname === '/createToken') {
                                        return [
                                            {
                                                pathname: `/publicApi`,
                                                title: 'Token Key'
                                            },
                                            {
                                                pathname: `/createToken`,
                                                title: 'สร้าง Token Key'
                                            }
                                        ];
                                    }
                                }}
                            />
                        ) : (
                            <BreadCrumbs items={subheader.breadcrumbs} />
                        )}
                    </div>

                    {/* Toolbar */}
                    {/* <div className="d-flex align-items-center">
            <a href="#" className="btn btn-light btn-sm font-weight-bold" id="kt_dashboard_daterangepicker"
               data-toggle="tooltip" title="Select dashboard daterange" data-placement="left">
              <span className="text-muted font-weight-bold mr-2" id="kt_dashboard_daterangepicker_title">Today</span>
              <span className="text-primary font-weight-bold" id="kt_dashboard_daterangepicker_date">Aug 16</span>
            </a>
            <QuickActions/>
          </div> */}
                </div>
            </div>
        </>
    );
}
