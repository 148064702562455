import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { CustomersLoadingDialog } from "./customers-loading-dialog/CustomersLoadingDialog";
import { CustomerEditDialog } from "./customer-edit-dialog/CustomerEditDialog";
import { CustomerDeleteDialog } from "./customer-delete-dialog/CustomerDeleteDialog";
import { CustomerBlockDialog } from "./customer-block-dialog/CustomerBlockDialog";
import { UnblockAllBannedDialog } from "./unblock-all-banned-dialog/UnblockAllBannedDialog";
import { CustomerUnblockDialog } from "./customer-unblock-dialog/CustomerUnblockDialog";
import { CustomerBannedDialog } from "./customer-banned-dialog/CustomerBannedDialog.js";
import { CustomerResetPwDialog } from "./customer-resetPw-dialog/CustomerResetPwDialog";
import { CustomersDeleteDialog } from "./customers-delete-dialog/CustomersDeleteDialog";
import { CustomersFetchDialog } from "./customers-fetch-dialog/CustomersFetchDialog";
import { CustomersUpdateStateDialog } from "./customers-update-status-dialog/CustomersUpdateStateDialog";
import { CustomerDevicesDialog } from "./customer-devices-dialog/CustomerDevicesDialog";
import { CustomersUIProvider } from "./CustomersUIContext";
import { CustomersCard } from "./CustomersCard";
import CustomerBanned from "./CustomerBanned"
import { QRCodeGeneratorDialog } from "./qrcode-generator-dialog/QRCodeGeneratorDialog";
import queryString from "query-string";

export function BannedCustomersPage({ history, memberAmountData, location }) {
  const [devices, setDevices] = useState([]);
  const [isDevices, setIsDevices] = useState([]);
  const [UID, setUID] = useState("");
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [isDelete, setDelete] = useState(false);
  const [isBlock, setBlock] = useState(false);
  const [isUnblock, setUnblock] = useState(false);
  const [isNew, setNew] = useState(false);
  const [isNewQrcode, setNewQrcode] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [email, setEmail] = useState(false);
  const [isBanned, setBanned] = useState(false);
  const [isResetPw, setResetPw] = useState(false);
  const [staffID, setStaffID] = useState(null);
  const [isUnblockAll, setUnblockAll] = useState(false);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    console.log(parsed);
    if (parsed.isNew) {
      setNew(true);
    }
    if (parsed.isNewQrcode) {
      setNewQrcode(true);
    }
    if (parsed.edit) {
      setEdit(true);
    }
    if (parsed.id) {
      setId(parsed.id);
    }
    if (parsed.name) {
      setName(parsed.name);
    }
    if (parsed.email) {
      setEmail(parsed.email);
    }
    if (parsed.delete) {
      setDelete(true);
    }
    if (parsed.block) {
      setBlock(true);
    }
    if (parsed.unblock) {
      setUnblock(true);
    }
    if (parsed.banned) {
      setBanned(true);
    }
    if (parsed.resetpw) {
      setResetPw(true);
    }
    if (parsed.staffID) {
      setStaffID(true);
    }
    if (parsed.isDevices) {
      setIsDevices(true);
    }
    if (parsed.unblockAll) {
      setUnblockAll(true);
    }
  }, [location.search]);

  const customersUIEvents = {
    newCustomerButtonClick: () => {
      history.push("/banned-members?isNew=true");
    },
    newQRCodeButtonClick: () => {
      history.push("/banned-members?isNewQrcode=true");
    },
    openEditCustomerDialog: (id) => {
      history.push(`/banned-members?id=${id}&edit=true`);
    },
    openDeleteCustomerDialog: (UID, email) => {
      setUID(UID);
      history.push(`/banned-members?email=${email}&delete=true`);
    },
    openBlockCustomerDialog: (id, email) => {
      history.push(
        `/banned-members?id=${id}&email=${
          email ? email : "Anonymous"
        }&block=true`
      );
    },
    openUnblockCustomerDialog: (id, email) => {
      history.push(`/banned-members?id=${id}&email=${email}&unblock=true`);
    },
    openBannedCustomerDialog: (email) => {
      history.push(`/banned-members?email=${email}&banned=true`);
    },
    openResetPwCustomerDialog: (id) => {
      history.push(`/banned-members?id=${id}&resetpw=true`);
    },
    openCustomerDevicesDialog: (devices, UID, staffID, name) => {
      setDevices(devices);
      setUID(UID);
      history.push(
        `/banned-members?staffID=${staffID ? staffID : "Anonymous"}&name=${
          name ? name : "Anonymous"
        }&isDevices=true`
      );
    },
    unblockAllBannedDialog: () => {
      history.push(`/banned-members?unblockAll=true`);
    },
    openDeleteCustomersDialog: () => {
      history.push(`/banned-members/deleteCustomers`);
    },
    openFetchCustomersDialog: () => {
      history.push(`/banned-members/fetch`);
    },
    openUpdateCustomersStatusDialog: () => {
      history.push("/banned-members/updateStatus");
    },
  };

  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <CustomersLoadingDialog />
      {isNew ? (
        <CustomerEditDialog
          show={true}
          onHide={() => {
            history.push("/banned-members");
            setNew(false);
          }}
        />
      ) : (
        ""
      )}
      {isNewQrcode ? (
        <QRCodeGeneratorDialog
          show={true}
          onHide={() => {
            history.push("/banned-members");
            setNewQrcode(false);
          }}
        />
      ) : (
        ""
      )}
      {isEdit ? (
        <CustomerEditDialog
          show={true}
          id={id}
          onHide={() => {
            history.push("/banned-members");
            setEdit(false);
          }}
        />
      ) : (
        ""
      )}
      {isDelete ? (
        <CustomerDeleteDialog
          show={true}
          id={email}
          UID={UID}
          onHide={() => {
            history.push("/banned-members");
            setDelete(false);
          }}
        />
      ) : (
        ""
      )}
      {isBlock ? (
        <CustomerBlockDialog
          show={true}
          id={id}
          email={email}
          onHide={() => {
            history.push("/banned-members");
            setBlock(false);
          }}
        />
      ) : (
        ""
      )}
      {isUnblockAll ? (
        <UnblockAllBannedDialog
          show={true}
          onHide={() => {
            history.push("/banned-members");
            setUnblockAll(false);
          }}
        />
      ) : (
        ""
      )}
      {isUnblock ? (
        <CustomerUnblockDialog
          show={true}
          id={id}
          email={email}
          onHide={() => {
            history.push("/banned-members");
            setUnblock(false);
          }}
        />
      ) : (
        ""
      )}
      {isBanned ? (
        <CustomerBannedDialog
          show={true}
          email={email}
          onHide={() => {
            history.push("/banned-members");
            setBanned(false);
          }}
        />
      ) : (
        ""
      )}
      {isResetPw ? (
        <CustomerResetPwDialog
          show={true}
          id={id}
          onHide={() => {
            history.push("/banned-members");
            setResetPw(false);
          }}
        />
      ) : (
        ""
      )}
      {isDevices ? (
        <CustomerDevicesDialog
          show={true}
          UID={UID}
          id={staffID}
          name={decodeURIComponent(name)}
          devices={devices}
          onHide={() => {
            history.push("/banned-members");
            setIsDevices(false);
          }}
        />
      ) : (
        ""
      )}
      <Route path="/banned-members/deleteCustomers">
        {({ history, match }) => (
          <CustomersDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/banned-members");
            }}
          />
        )}
      </Route>
      <Route path="/banned-members/fetch">
        {({ history, match }) => (
          <CustomersFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/banned-members");
            }}
          />
        )}
      </Route>
      <Route path="/banned-members/updateStatus">
        {({ history, match }) => (
          <CustomersUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/banned-members");
            }}
          />
        )}
      </Route>
      {/* <CustomersCard memberAmountData={memberAmountData} isBanned={true} /> */}
      <CustomerBanned/>
    </CustomersUIProvider>
  );
}
