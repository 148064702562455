import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { CatalogueNewDialog } from "./catalogue-new-dialog/CatalogueNewDialog";
import { CatalogueEditDialog } from "./catalogue-edit-dialog/CatalogueEditDialog";
import { CatalogueViewDialog } from "./catalogue-view-dialog/CatalogueViewDialog";
import { CatalogueDeleteDialog } from "./catalogue-delete-dialog/CatalogueDeleteDialog";
import { CatalogueBlockDialog } from "./catalogue-block-dialog/CatalogueBlockDialog";
import { CatalogueUnblockDialog } from "./catalogue-unblock-dialog/CatalogueUnblockDialog";
import { CatalogueUIProvider } from "./CatalogueUIContext";
import { CatalogueCard } from "./CatalogueCard";
import queryString from "query-string";

export function CataloguePage({ history, location, onHide }) {
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [isDelete, setDelete] = useState(false);
  const [isBlock, setBlock] = useState(false);
  const [isUnblock, setUnblock] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isView, setView] = useState(false);
  const [isNew, setNew] = useState(false);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.isNew) {
      setNew(true);
    }
    if (parsed.id) {
      setId(parsed.id);
    }
    if (parsed.name) {
      setName(parsed.name);
    }
    if (parsed.view) {
      setView(true);
    }
    if (parsed.edit) {
      setEdit(true);
    }
    if (parsed.delete) {
      setDelete(true);
    }
    if (parsed.block) {
      setBlock(true);
    }
    if (parsed.unblock) {
      setUnblock(true);
    }
  }, [location.search]);

  const catalogueUIEvents = {
    newCatalogueButtonClick: () => {
      history.push("/catalogue?isNew=true");
    },
    searchCatalogueButtonClick: () => {
      history.push("/catalogue?search=true");
    },
    openEditCatalogueDialog: (id) => {
      history.push(`/catalogue?id=${id}&edit=true`);
    },
    openViewCatalogueEbookDialog: (id) => {
      history.push(`/catalogue?id=${id}&view=true`);
    },
    openDeleteCatalogueDialog: (name, id) => {
      history.push(
        `/catalogue?name=${encodeURIComponent(name)}&id=${id}&delete=true`
      );
    },
    openBlockCatalogueDialog: (name, id) => {
      history.push(
        `/catalogue?name=${encodeURIComponent(name)}&id=${id}&block=true`
      );
    },
    openUnblockCatalogueDialog: (name, id) => {
      history.push(
        `/catalogue?name=${encodeURIComponent(name)}&id=${id}&unblock=true`
      );
    },
    openFetchCatalogueDialog: () => {
      history.push(`/catalogue/fetch`);
    },
    openUpdateCatalogueStatusDialog: () => {
      history.push("/catalogue/updateStatus");
    },
    viewCatalogueDetailClick: (id) => {
      history.push(`/catalogue-detail?id=${id}`);
    },
  };

  return (
    <CatalogueUIProvider catalogueUIEvents={catalogueUIEvents}>
      {isNew ? (
        <CatalogueNewDialog
          show={true}
          onHide={() => {
            history.push("/catalogue");
            setNew(false);
          }}
        />
      ) : (
        ""
      )}
      {isEdit ? (
        <CatalogueEditDialog
          show={true}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push("/catalogue");
            setEdit(false);
          }}
        />
      ) : (
        ""
      )}
      {isView ? (
        <CatalogueViewDialog
          show={true}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push("/catalogue");
            setView(false);
          }}
        />
      ) : (
        ""
      )}
      {isDelete ? (
        <CatalogueDeleteDialog
          show={true}
          name={decodeURIComponent(name)}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push("/catalogue");
            setDelete(false);
          }}
        />
      ) : (
        ""
      )}
      {isUnblock ? (
        <CatalogueUnblockDialog
          show={true}
          name={decodeURIComponent(name)}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push("/catalogue");
            setUnblock(false);
          }}
        />
      ) : (
        ""
      )}
      {isBlock ? (
        <CatalogueBlockDialog
          show={true}
          name={decodeURIComponent(name)}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push("/catalogue");
            setBlock(false);
          }}
        />
      ) : (
        ""
      )}
      <CatalogueCard
        isPrivate={1}
        isFree={0}
        isNewCatalogue={0}
        isPureCatalogue={1}
        onHide={onHide}
      />
    </CatalogueUIProvider>
  );
}
