// export const CompanyStatusCssClasses = ["danger", "success", "info", ""];
export const CompanyStatusCssClasses = ["danger", "danger", "danger", ""];
export const CompanyStatusTitles = ["Suspended", "Active", "Pending", ""];
export const CompanyTypeCssClasses = ["success", "primary", ""];
export const CompanyTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "30", value: 30 },
  { text: "50", value: 50 },
  { text: "100", value: 100 }
];
export const initialFilter = {
  filter: {
    lastName: "",
    firstName: "",
    email: "",
    ipAddress: ""
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 50,
  limit: 48, //10
  totalSize: 9 //จำนวนสมาชิก
};
