/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/upload/uploadActions";
import { PodEditForm } from "./PodEditForm";
import { PodcastEditForm } from "./PodcastEditForm";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { PodcastEditFileAndCover } from "./PodcastEditFileAndCover";
import { LoaderViewSmall } from "../../../../CustomView";

export function PodcastEditDialog({ id, show, onHide, isPrivate, episodeId }) {
  // Podcasts Redux state
  const dispatch = useDispatch();
  const { uploadState } = useSelector(
    state => ({
      uploadState: state.upload
    }),
    shallowEqual
  );
  const loaderHeight = 50;
  const [podcastDetail, setPodcastDetail] = useState(null);
  const [podcastValue, setPodcastValue] = useState(null);
  const [rentalPeriod, setRentalPeriod] = useState([]);
  const [fixFileModal, setFixFileModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [languageList, setLanguageList] = useState([
    <option value="th">th-ไทย</option>,
    <option value="en">en-อังกฤษ</option>
  ]);

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
      setPodcastDetail(null);
    } else {
      dispatch(actions.fetchNewPrivateContentData()); //rental period dropdown
      const reqFunc = async id_ => {
        try {
          const req = await axios.get(
            `/admin${isPrivate ? "/private/contents" : "/contents"}/${id_}/edit`
          );
          const { data } = req;
          if (data.status && data.data) {
            setPodcastDetail(data.data);
          }
        } catch (err) {
          onHide();
        }
      };
      const fetchLangFunc = async () => {
        const req = await axios.get("/admin/contents/languages");
        if (req.data.status && req.data.data) {
          setLanguageList(
            req.data.data.languages.map((el, index) => (
              <option key={index} value={el.value}>
                {el.label}
              </option>
            ))
          );
        }
      };
      if (episodeId) {
        fetchLangFunc();
        reqFunc(episodeId);
      } else {
        reqFunc(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, episodeId]);

  useEffect(() => {
    setRentalPeriod(uploadState.rentalPeriod);
  }, [uploadState.rentalPeriod]);

  useEffect(() => {
    if (podcastDetail) {
      const values = podcastDetail;
      const originValues = {
        name: values.title,
        channelValue: values.creatorUID,
        creatorValue: values.writerUID,
        categoryValue: values.catId,
        subCategoryValue: values.subcatId,
        startDate: values.startDate,
        endDate: values.expireDate,
        numLicenses: values.concurrent > 0 ? values.concurrent : undefined,
        language: values.language,
        tags: values.tags,
        rentalPeriodValue: values.rentalPeriod,
        anonymousRentalPeriodValue: values.anonymousRentalPeriod,
        description: values.description,
        concurrent: values.concurrent,
        activeLicense: values.activeLicense,
        file: values.file
      };
      setPodcastValue(originValues);
    }
  }, [podcastDetail]);

  return fixFileModal ? (
    <PodcastEditFileAndCover
      modalType={modalType}
      onHide={() => onHide()}
      contentUIDForUpdate={id}
      fixFileOnly={true}
    />
  ) : (
    <Modal
      backdrop="static"
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
    <Modal.Body>
      {podcastDetail ? (
        <>
          <h2 className="center">
            แก้ไขรายละเอียด
          </h2>
          {episodeId ? (
            <PodcastEditForm
              podcastOrigin={podcastDetail}
              podcastValue={Object.assign(
                {},
                {
                  id: undefined,
                  name: ""
                },
                podcastValue
              )}
              rentalPeriod={rentalPeriod}
              contentUIDForUpdate={episodeId}
              onHideOutside={onHide}
              setPodcastValue={setPodcastValue}
              setPodcastDetail={setPodcastDetail}
              isPrivate={isPrivate}
              languageList={languageList}
            />
          ) : (
            <PodEditForm
              podcastOrigin={podcastDetail}
              podcastValue={Object.assign(
                {},
                {
                  id: undefined,
                  name: ""
                },
                podcastValue
              )}
              rentalPeriod={rentalPeriod}
              contentUIDForUpdate={id}
              onHideOutside={onHide}
              setPodcastValue={setPodcastValue}
              setPodcastDetail={setPodcastDetail}
              isPrivate={isPrivate}
            />
          )}
        </>
      ) : (
        <LoaderViewSmall />
      )}
      </Modal.Body>
    </Modal>
  );
}
