import React from 'react';
import { Route } from 'react-router-dom';
import { ReportsLoadingDialog } from './reports-loading-dialog/ReportsLoadingDialog';
import { ReportEditDialog } from './report-edit-dialog/ReportEditDialog';
import { ReportDeleteDialog } from './report-delete-dialog/ReportDeleteDialog';
import { ReportBlockDialog } from './report-block-dialog/ReportBlockDialog';
import { ReportsFetchDialog } from './reports-fetch-dialog/ReportsFetchDialog';
import { ReportsUpdateStateDialog } from './reports-update-status-dialog/ReportsUpdateStateDialog';
import { ReportsUIProvider } from './ReportsUIContext';
import { ReportsCard } from './ReportsCard';

export function ReportsPage({ history }) {
   const reportsUIEvents = {
      newReportButtonClick: () => {
         history.push('/report/new');
      },
      openEditReportDialog: id => {
         history.push(`/report/${id}/edit`);
      },
      openDeleteReportDialog: id => {
         history.push(`/report/${id}/delete`);
      },
      openBlockReportDialog: id => {
         history.push(`/report/${id}/block`);
      },
      openDeleteReportsDialog: () => {
         history.push(`/report/deleteReports`);
      },
      openFetchReportsDialog: () => {
         history.push(`/report/fetch`);
      },
      openUpdateReportsStatusDialog: () => {
         history.push('/report/updateStatus');
      }
   };

   return (
      <ReportsUIProvider reportsUIEvents={reportsUIEvents}>
         <ReportsLoadingDialog />
         <Route path="/report/new">
            {({ history, match }) => (
               <ReportEditDialog
                  show={match != null}
                  onHide={() => {
                     history.push('/report');
                  }}
               />
            )}
         </Route>
         <Route path="/report/:id/edit">
            {({ history, match }) => (
               <ReportEditDialog
                  show={match != null}
                  id={match && match.params.id}
                  onHide={() => {
                     history.push('/report');
                  }}
               />
            )}
         </Route>

         <Route path="/report/:id/delete">
            {({ history, match }) => (
               <ReportDeleteDialog
                  show={match != null}
                  id={match && match.params.id}
                  onHide={() => {
                     history.push('/report');
                  }}
               />
            )}
         </Route>
         <Route path="/report/:id/block">
            {({ history, match }) => (
               <ReportBlockDialog
                  show={match != null}
                  id={match && match.params.id}
                  onHide={() => {
                     history.push('/report');
                  }}
               />
            )}
         </Route>
         <Route path="/report/fetch">
            {({ history, match }) => (
               <ReportsFetchDialog
                  show={match != null}
                  onHide={() => {
                     history.push('/report');
                  }}
               />
            )}
         </Route>
         <Route path="/report/updateStatus">
            {({ history, match }) => (
               <ReportsUpdateStateDialog
                  show={match != null}
                  onHide={() => {
                     history.push('/report');
                  }}
               />
            )}
         </Route>
         <ReportsCard />
      </ReportsUIProvider>
   );
}
