import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/channels/channelsActions";
import { useChannelsUIContext } from "../ChannelsUIContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
     body: {
          textAlign: "center",
     },
     title: {
          fontSize: "20px",
          fontWeight: 600,
          color: "#d7042b",
     },
     subtitle: {
          fontFamily: "Prompt",
          fontSize: "16px",
     },
     divButton: {
          marginTop: "36px",
     },
     acceptButton: {
          margin: "8px",
          color: "#ffffff",
          fontSize: "18px",
          fontWeight: 600,
          width: "160px",
          height: "48px",
          borderRadius: "100px",
          backgroundColor: "#012875",
          border: "none",
     },
     denyButton: {
          margin: "8px",
          color: "#012875",
          fontSize: "18px",
          fontWeight: 600,
          width: "160px",
          height: "48px",
          borderRadius: "100px",
          backgroundColor: "#ffffff",
          border: "solid 1px #012875",
     },
}));

export function ChannelUnblockDialog({ name, id, channelId, creatorId, isPrivate, show, onHide }) {
     // Channels UI Context
     const channelsUIContext = useChannelsUIContext();
     const channelsUIProps = useMemo(() => {
          return {
               setIds: channelsUIContext.setIds,
               queryParams: channelsUIContext.queryParams,
          };
     }, [channelsUIContext]);
     const [isCreator, setIsCreator] = useState(false);

     useEffect(() => {
          if (channelId && creatorId) {
               setIsCreator(true);
          }
     }, [channelId, creatorId]);

     // Channels Redux state
     const dispatch = useDispatch();
     const { isLoading } = useSelector((state) => ({ isLoading: state.channels.actionsLoading }), shallowEqual);

     // if !id we should close modal
     useEffect(() => {
          if (!id) {
               // onHide();
          }

          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [id]);

     // looking for loading/dispatch
     useEffect(() => {}, [isLoading, dispatch]);

     const unblockChannel = () => {
          // server request for deleting channel by id
          dispatch(actions.unblockChannel(name, id, isPrivate)).then(() => {
               // refresh list after deletion
               dispatch(actions.fetchChannels(channelsUIProps.queryParams, isPrivate));
               // clear selections list
               channelsUIProps.setIds([]);
               // closing unblock modal
               onHide();
          });
     };

     const unblockCreator = () => {
          dispatch(actions.unblockCreator(name, channelId, creatorId, isPrivate)).then(() => {
               dispatch(actions.fetchCreators(channelsUIProps.queryParams, channelId, isPrivate));
               channelsUIProps.setIds([]);
               onHide();
          });
     };

     const classes = useStyles();

     return (
          <Modal
               backdrop="static"
               show={show}
               onHide={onHide}
               aria-labelledby="example-modal-sizes-title-lg"
               style={{ margin: "3% auto" }}
          >
               {isLoading && <ModalProgressBar />}
               <Modal.Body className={classes.body}>
                    {name ? (
                         <>
                              <img src="/media/members/image-modal.svg" alt="" />
                              {!isLoading ? (
                                   <>
                                        <div className="text-text-red">ยืนยันการปลดบล็อก</div>
                                        <div className="text-text-gray">ต้องการปลดบล็อก {name} หรือไม่?</div>

                                        <div className="mt-3">
                                             <button type="button" onClick={onHide} className="denyButton">
                                                  ยกเลิก
                                             </button>
                                             <button
                                                  type="button"
                                                  onClick={() => {
                                                       if (isCreator) {
                                                            unblockCreator();
                                                       } else {
                                                            unblockChannel();
                                                       }
                                                  }}
                                                  className="acceptButton"
                                             >
                                                  ยืนยัน
                                             </button>
                                        </div>
                                   </>
                              ) : (
                                   <div className="text-text-gray" style={{ marginBottom: "36px" }}>
                                        กำลังปลดบล็อก...
                                   </div>
                              )}
                         </>
                    ) : (
                         ""
                    )}
               </Modal.Body>
          </Modal>
     );
}
