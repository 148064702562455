import { createSlice } from "@reduxjs/toolkit";

const initialChannelsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  creatorsForEdit: [],
  lastError: null,
  LastEvaluatedKey: {},
  channelIdOfCreators: undefined,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const channelsSlice = createSlice({
  name: "channels",
  initialState: initialChannelsState,
  reducers: {
    catchError: (state, action) => {
      if (action.payload.error && action.payload.error.response && action.payload.error.response.data) {
        state.error = {
          from: action.payload.from,
          errorCode: action.payload.error.response.data.errorCode,
        };
      }
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCreatorsById
    creatorsFetched: (state, action) => {
      const { creators, channelId, memberMode } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      if (memberMode) {
        state.creatorsForEdit = creators
          ? creators.filter((e) => e.status === parseInt(memberMode, 10))
          : [];
      } else {
        state.creatorsForEdit = creators;
      }
      state.channelIdOfCreators = channelId;
      if (creators) {
        state.totalCount = creators.length;
      } else {
        state.totalCount = 0;
      }
      state.error = null;
    },
    // findChannels
    channelsFetched: (state, action) => {
      const { totalCount, entities, LastEvaluatedKey, lastEvaluate, memberMode } = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
      if (lastEvaluate === undefined) {
        if (memberMode) {
          state.entities = entities
            ? entities.filter((e) => e.status === parseInt(memberMode, 10))
            : [];
        } else {
          state.entities = entities;
        }
      } else {
        if (memberMode) {
          entities
            .filter((e) => e.status === parseInt(memberMode, 10))
            .forEach((item) => state.entities.push(item));
        } else {
          entities.forEach((item) => state.entities.push(item));
        }
      }
      state.totalCount = totalCount;
      state.LastEvaluatedKey = LastEvaluatedKey;
      // state.entities = mockUsers;
      // state.totalCount = 17;
    },
    // createChannel
    channelCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.channel);
      state.totalCount += 1;
    },
    creatorCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      if (action.payload.channelId === state.channelIdOfCreators) {
        state.creatorsForEdit.push(action.payload.creator);
      }
    },
    // updateChannel
    channelUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.uid === action.payload.channel.uid) {
          return action.payload.channel;
        }
        return entity;
      });
    },
    creatorUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.creatorsForEdit = state.creatorsForEdit.map((el) => {
        if (el.uid === action.payload.creatorId) {
          return Object.assign({}, el, action.payload.creator);
        }
        return el;
      });

      Object.assign(
        {},
        ...state.creatorsForEdit,
        ...state.creatorsForEdit.filter((el) => el.uid === action.payload.creator.creatorId)
      );
    },
    // deleteChannel
    channelDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => el.id !== action.payload.id);
    },
    creatorDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.creatorsForEdit = state.creatorsForEdit.filter(
        (el) => el.uid !== action.payload.creatorsId
      );
    },
    
    // deleteChannels
    channelsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el) => !action.payload.ids.includes(el.id));
    },
    // channelsUpdateState
    channelsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    elementUpdated: (state, action) => {
      state.actionsLoading = false;
    },
  },
});
