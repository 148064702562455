/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/catalogue/catalogueActions";
import { CatalogueNewForm } from "./CatalogueNewForm";
import { useCatalogueUIContext } from "../CatalogueUIContext";

export function CatalogueNewDialog({ show, onHide }) {
  // Catalogue UI Context
  const catalogueUIContext = useCatalogueUIContext();
  const catalogueUIProps = useMemo(() => {
    return {
      initCatalogue: {
        uid: undefined,
        name: "",
      },
      queryParams: catalogueUIContext.queryParams,
    };
  }, [catalogueUIContext]);

  // Catalogue Redux state
  const dispatch = useDispatch();
  const { actionsLoading, error } = useSelector(
    (state) => ({
      actionsLoading: state.catalogue.actionsLoading,
      entities: state.catalogue.entities,
      error: state.catalogue.error,
    }),
    shallowEqual
  );

  const [isDuplicate, setDuplicate] = useState(false);

  useEffect(() => {
    if (error && error.errorCode === "014" && !isDuplicate) {
      setDuplicate(true);
    }
  }, [error]);

  const createCatalogue = (catalogue) => {
    const newOne = { title: catalogue.name };
    dispatch(actions.createCatalogue(newOne)).then((val) => {
      if (val !== "error") {
        dispatch(actions.fetchCatalogues(catalogueUIProps.queryParams, "catalogues"));
        onHide();
      }
    });
  };

  return (
    <Modal
      backdrop="static"
      size="md"
      show={show}
      onHide={onHide}
      style={{ marginTop: "27px" }}
      aria-labelledby="example-modal-sizes-title-md"
    >
      <CatalogueNewForm
        createCatalogue={createCatalogue}
        actionsLoading={actionsLoading}
        catalogue={catalogueUIProps.initCatalogue}
        onHide={onHide}
        isDuplicate={isDuplicate}
        setDuplicate={setDuplicate}
      />
    </Modal>
  );
}
