import { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { withRouter } from "react-router-dom";
import { requestPassword } from "../_redux/authCrud";
import moment from "moment";
import { HtiResponse, ForgotResponse } from "../../../../interface";

const initialValues = {
  email: "",
};

function ForgotPassword(props: any) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("อีเมลไม่ถูกต้อง")
      .max(50, "ไม่เกิน 50 ตัวอักษร")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      requestPassword(values.email)
        .then((response) => {
          const { data }: HtiResponse<ForgotResponse> = response.data;
          if (data) {
            props.setEmailDone(true);
            props.setTimeLeft(data.expireDate - moment().unix());
            props.setRefCode(data.referenceCode);
            props.setUidOfOTP(data.uid);
            setIsRequested(true);
          }
        })
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.email }
            )
          );
        });
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth/verify-otp" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <h3 className="font-size-h1">ลืมรหัสผ่าน</h3>
              <input
                type="email"
                placeholder="Email"
                className={`form-control form-control-solid h-auto py-5 px-6`}
                title="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-center justify-content-between">
            {/* flex-wrap  */}
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="denyButton"
                >
                  ยกเลิก
                </button>
              </Link>
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="acceptButton"
                disabled={
                  formik.isSubmitting ||
                  formik.errors.email !== undefined ||
                  !formik.values["email"]
                }
              >
                {formik.isSubmitting ? "รอสักครู่" : "ยืนยัน"}
                {formik.isSubmitting && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(
  withRouter(connect(null, auth.actions)(ForgotPassword))
);
