/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import FormForFile from '../../upload-company/FormForFile';
import axios from 'axios';
import {
    getPresetCompany,
    getCompanyById
} from '../../../_redux/companies/companiesCrud';
import { LoaderViewFull } from '../../../../CustomView';
import {
    HtiResponse,
    Company,
    PresetCompany,
    CompanyData,
    ConcurrentPreset
} from '../../../../../../interface';

interface CompanyEditDialogProps {
    id: string;
    show: any;
    onHide: () => void;
    isPrivate: boolean;
}
export function CompanyEditDialog({
    id,
    show,
    onHide,
    isPrivate
}: CompanyEditDialogProps) {
    const [companyDetail, setCompanyDetail] = useState<Company | null>(null);
    const [companyValue, setCompanyValue] = useState<any>(null);

    const [userLimitData, setUserLimitData] = useState<ConcurrentPreset[]>([]);
    const [titleLimitData, setTitleLimitData] = useState<ConcurrentPreset[]>(
        []
    );
    const [storageLimitData, setStorageLimitData] = useState<
        ConcurrentPreset[]
    >([]);
    const [smsRegisterPreset, setSmsRegisterPreset] = useState<
        ConcurrentPreset[]
    >([]);
    const [concurrentLimitData, setConcurrentLimitData] = useState<
        ConcurrentPreset[]
    >([]);
    const [organizationTypeData, setOrganizationTypeData] = useState<
        ConcurrentPreset[]
    >([]);
    const [storagePeriodData, setStoragePeriodData] = useState<
        ConcurrentPreset[]
    >([]);

    const reqDetailFunc = async () => {
        try {
            const req = await getCompanyById(id); // axios.get(`/superuser/clients/${id}`);

            console.log('rr', req.data.data);

            const { data }: HtiResponse<CompanyData> = req.data;
            if (data) {
                setCompanyDetail(data.client);
            }
        } catch (err) {
            setCompanyDetail(null);
        }
    };
    //  ddd;
    const fetchDropdownFunc = async () => {
        try {
            const req = await getPresetCompany(); //axios.get(`/superuser/clients/create`);
            const { data }: HtiResponse<PresetCompany> = req.data;
            if (data) {
                setUserLimitData(
                    data.userLimitPreset ? data.userLimitPreset : []
                );
                setTitleLimitData(data.titlePreset ? data.titlePreset : []);
                setStorageLimitData(
                    data.storagePreset ? data.storagePreset : []
                );
                setSmsRegisterPreset(
                    data.remainSMSPreset ? data.remainSMSPreset : []
                );
                setConcurrentLimitData(
                    data.concurrentPreset ? data.concurrentPreset : []
                );
                setOrganizationTypeData(
                    data.organizationType ? data.organizationType : []
                );
                setStoragePeriodData(
                    data.rentalPeriod ? data.rentalPeriod : []
                );
            } else {
                onHide();
            }
        } catch (err) {
            setCompanyDetail(null);
        }
    };

    // if !id we should close modal
    useEffect(() => {
        if (!id) {
            onHide();
            // setCompanyId(null);
        } else {
            // setCompanyId(id);
            fetchDropdownFunc();
            reqDetailFunc();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (companyDetail) {
            const values = companyDetail;
            console.log(values);

            const originValues = {
                id: id,
                name: values.clientName,
                nameLib: values.displayName,
                nameMini: values.companyPrefix,
                startDate: values.startDate,
                endDate: values.expireDate,
                status: values.status,
                userLimit: values.userLimit,
                rentalLimit: values.rentalLimit,
                anonymousRentalLimit: values.anonymousRentalLimit,
                rentalLimit_anonymous_magazine:
                    values.rentalLimitCategory &&
                    values.rentalLimitCategory.magazine
                        ? values.rentalLimitCategory.magazine.anonymous
                        : undefined,
                rentalLimit_magazine:
                    values.rentalLimitCategory &&
                    values.rentalLimitCategory.magazine
                        ? values.rentalLimitCategory.magazine.user
                        : undefined,
                rentalLimit_anonymous_newspaper:
                    values.rentalLimitCategory &&
                    values.rentalLimitCategory.newspapers
                        ? values.rentalLimitCategory.newspapers.anonymous
                        : undefined,
                rentalLimit_newspaper:
                    values.rentalLimitCategory &&
                    values.rentalLimitCategory.newspapers
                        ? values.rentalLimitCategory.newspapers.user
                        : undefined,

                rentalPeriod: values.rentalPeriod,
                anonymousPeriod: values.anonymousPeriod,

                period_anonymous_magazine:
                    values.periodCategory && values.periodCategory.magazine
                        ? values.periodCategory.magazine.anonymous
                        : undefined,
                period_magazine:
                    values.periodCategory && values.periodCategory.magazine
                        ? values.periodCategory.magazine.user
                        : undefined,
                period_anonymous_newspaper:
                    values.periodCategory && values.periodCategory.newspapers
                        ? values.periodCategory.newspapers.anonymous
                        : undefined,
                period_newspaper:
                    values.periodCategory && values.periodCategory.newspapers
                        ? values.periodCategory.newspapers.user
                        : undefined,

                totalStorage:
                    values.privateLibrary && values.privateLibrary.totalStorage,
                totalTitle:
                    values.privateLibrary && values.privateLibrary.totalTitle,
                // :values.startDate,
                address: values.contactList && values.contactList.address,
                email: values.contactList && values.contactList.email,
                instagram: values.contactList && values.contactList.Instagram,
                lineID: values.contactList && values.contactList.LineID,
                telephone: values.contactList && values.contactList.mobilePhone,
                phone: values.contactList && values.contactList.phoneNumber,
                twitter: values.contactList && values.contactList.Twitter,
                website: values.contactList && values.contactList.website,
                facebook: values.contactList && values.contactList.Facebook,
                youtube: values.contactList && values.contactList.YouTube,
                organizationType: values.organizationType,
                monthlyLicense: values.monthlyLicense,
                registerByPhoneNumber: values.registerByPhoneNumber,
                rentalLimitCategory: values.rentalLimitCategory,
                customTitle: values.customTitle,
                description: values.description,
                registerByPhoneDetail: values.registerByPhoneDetail,
                isCustomTotalStorage: values.isCustomTotalStorage,
                isCustomTotalTitle: values.isCustomTotalTitle
            };
            setCompanyValue(originValues);
        }
    }, [companyDetail]);

    return (
        <Modal
            backdrop="static"
            size="lg"
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Body>
                {companyValue &&
                userLimitData &&
                userLimitData.length &&
                titleLimitData &&
                titleLimitData.length &&
                storageLimitData &&
                storageLimitData.length &&
                concurrentLimitData &&
                concurrentLimitData.length &&
                storagePeriodData &&
                storagePeriodData.length ? (
                    <>
                        <h2 className="center">แก้ไขข้อมูลองค์กร </h2>
                        <FormForFile
                            isEditing={true}
                            uploadValue={Object.assign(
                                {},
                                {
                                    id: undefined,
                                    name: id,
                                    nameLib: '',
                                    nameMini: ''
                                },
                                companyValue
                            )}
                            clientUIDForUpdate={id}
                            handleSubmitOutside={onHide}
                            userLimitData={userLimitData}
                            titleLimitData={titleLimitData}
                            storageLimitData={storageLimitData}
                            concurrentLimitData={concurrentLimitData}
                            organizationTypeData={organizationTypeData}
                            smsRegisterPreset={smsRegisterPreset}
                            storagePeriodData={storagePeriodData}
                            FILENAMEToUpload={undefined}
                            themeValue={undefined}
                            lightColorObj={undefined}
                            darkColorObj={undefined}
                            setCoverPost={undefined}
                            setSuccessCoverUpload={undefined}
                            registerByPhoneNumber={
                                companyDetail?.registerByPhoneNumber
                            }
                        />
                    </>
                ) : (
                    <LoaderViewFull />
                )}
            </Modal.Body>
        </Modal>
    );
}
