import * as requestFromServer from './companiesCrud';
import { companiesSlice, callTypes } from './companiesSlice';
import moment from 'moment';
import {
    HtiResponse,
    CompanyList,
    Company,
    CompanyFilter,
    ClientTable
} from '../../../../../interface';
import {
    ClientProcessStatus,
    PhoneRegisterUpdate
} from '../../../../../interface/company';
const { actions } = companiesSlice;

export const fetchCompanies = (
    queryParams: CompanyFilter,
    isPrivate: number,
    lastEvaluate?: any,
    setLoadingBottom?: (e: boolean) => void,
    groupClientType?: string
) => (dispatch: (arg0: any) => void) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    console.log(isPrivate);
    
    return requestFromServer
        .findCompanies(queryParams, isPrivate, lastEvaluate, groupClientType)
        .then(response => {
            console.log(response.data);

            const { data }: HtiResponse<CompanyList> = response.data;
            if (queryParams.keyword) {
                dispatch(
                    actions.saveKeyword({ searchKeyword: queryParams.keyword })
                );
            }
            // const { lastEvaluatedKey, clients, totalAmount } = response.data.data;
            const entities = data?.clients.map(el => {
                el.timeStatus =
                    moment().unix() < el.startDate
                        ? 1
                        : moment().unix() > el.startDate &&
                          moment().unix() < el.expireDate
                        ? 2
                        : moment().unix() > el.expireDate
                        ? 3
                        : undefined;
                return el;
            });

            dispatch(
                actions.companiesFetched({
                    totalCount: data?.totalAmount,
                    entities,
                    LastEvaluatedKey: data?.lastEvaluatedKey,
                    lastEvaluate,
                    isPrivate,
                    memberMode: queryParams.memberMode
                })
            );
            if (setLoadingBottom) {
                setLoadingBottom(false);
            }
        })
        .catch(error => {
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.errorCode === '012'
            ) {
                alert(
                    'กรุณาเข้าสู่ระบบอีกครั้ง เนื่องจากระยะเวลาการใช้งานหมดอายุ'
                );
                window.location.href = '/logout';
            }
            dispatch(
                actions.companiesFetched({
                    totalCount: 0,
                    entities: [],
                    LastEvaluatedKey: {},
                    isPrivate
                })
            );
            error.clientMessage = "Can't find companies";
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const fetchCompany = (id: string) => (dispatch: (arg0: any) => void) => {
    if (!id) {
        return dispatch(actions.companyFetched({ companyForEdit: undefined }));
    }

    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getCompanyById(id)
        .then(response => {
            const company = response.data;
            dispatch(actions.companyFetched({ companyForEdit: company }));
        })
        .catch(error => {
            error.clientMessage = "Can't find company";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const deleteCompany = (id: string) => (
    dispatch: (arg0: any) => void
) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .deleteCompany(id)
        .then(response => {
            if (response.data.status) {
                dispatch(actions.companyDeleted({ id }));
            }
        })
        .catch(error => {
            error.clientMessage = "Can't delete company";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const clearAllData = (
    clientUID: string,
    table: ClientTable,

    processStatus: ClientProcessStatus,
    backup = true
) => (dispatch: (arg0: any) => void) => {
    dispatch(actions.startCall({ callTypes: callTypes.action }));
    return requestFromServer
        .deleteAllDataOfClient(clientUID, table, backup)
        .then(response => {
            if (response.data.status) {
                dispatch(
                    actions.clearedAllData({
                        id: clientUID,
                        processStatus,
                        table
                    })
                );
                return response.data;
            } else {
                return response.data;
            }
        })
        .catch(error => {
            error.clientMessage = "Can't clear " + table;
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const blockCompany = (id: string, isPrivate: boolean) => (
    dispatch: (arg0: any) => void
) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .blockCompany(id, isPrivate)
        .then(response => {
            if (response.data.status) {
                dispatch(actions.companyBlocked({ id }));
            }
        })
        .catch(error => {
            error.clientMessage = "Can't block company";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const unblockCompany = (id: string, isPrivate: boolean) => (
    dispatch: (arg0: any) => void
) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .unblockCompany(id, isPrivate)
        .then(response => {
            if (response.data.status) {
                dispatch(actions.companyUnblocked({ id }));
            }
        })
        .catch(error => {
            error.clientMessage = "Can't unblock company";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

// export const createCompany = (companyForCreation: Company) => (
//   dispatch: (arg0: any) => void
// ) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .createCompany(companyForCreation)
//     .then((response) => {
//       const { company } = response.data;
//       dispatch(actions.companyCreated({ company }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't create company";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

export const updateCompany = (
    id: string,
    company: Company,
    setSuccessModal: (e: boolean) => void,
    phonePayload?: PhoneRegisterUpdate,
    setSubmittingState?:(e: boolean) => void
) => (dispatch: (arg0: any) => void) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    if (phonePayload) {
        return Promise.all([
            requestFromServer.updateCompany(id, company),
            requestFromServer.updateRegisterByPhone(id, phonePayload)
        ])
            .then(() => {
                if (setSuccessModal) {
                    setSuccessModal(true);
                    console.log(company);
                    console.log(id);
                }
                dispatch(actions.companyUpdated({ id: id, company }));
            })
            .catch(error => {
                error.clientMessage = "Can't update company";
                if(setSubmittingState) setSubmittingState(false)
                dispatch(
                    actions.catchError({ error, callType: callTypes.action })
                );
            });
    } else {
        return requestFromServer
            .updateCompany(id, company)
            .then(() => {
                if (setSuccessModal) {
                    setSuccessModal(true);
                    console.log(company);
                    console.log(id);
                }
                dispatch(actions.companyUpdated({ id: id, company }));
            })
            .catch(error => {
                error.clientMessage = "Can't update company";
                if(setSubmittingState) setSubmittingState(false)
                dispatch(
                    actions.catchError({ error, callType: callTypes.action })
                );
            });
    }
    // return requestFromServer
    //     .updateCompany(id, company)
    //     .then(() => {
    //      if (setSuccessModal) {
    //      setSuccessModal(true);
    //      console.log(company);
    //      console.log(id);
    //      }
    // dispatch(actions.companyUpdated({ id: id, company }));
    //     })
    //     .catch(error => {
    //         error.clientMessage = "Can't update company";
    //         dispatch(actions.catchError({ error, callType: callTypes.action }));
    //     });
};

export const updateCompaniesStatus = (ids: string, status: number) => (
    dispatch: (arg0: any) => void
) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .updateStatusForCompanies(ids, status)
        .then(() => {
            dispatch(actions.companiesStatusUpdated({ ids, status }));
        })
        .catch(error => {
            error.clientMessage = "Can't update companies status";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const deleteCompanies = (ids: string) => (
    dispatch: (arg0: any) => void
) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .deleteCompanies(ids)
        .then(() => {
            dispatch(actions.companiesDeleted({ ids }));
        })
        .catch(error => {
            error.clientMessage = "Can't delete companies";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};
