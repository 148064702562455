import React, { useState, useEffect } from 'react';
import FastAverageColor from 'fast-average-color';
import { dateStr } from '../../partials/timeStampToDate';
import { Col, Dropdown } from 'antd';
import moment from 'moment-timezone';

const dateList = [
   {
      title: '15 นาที',
      value: 900
   },
   {
      title: '30 นาที',
      value: 1800
   },
   {
      title: '45 นาที',
      value: 2700
   },
   {
      title: '1 ชั่วโมง',
      value: 3600
   },
   {
      title: '2 ชั่วโมง',
      value: 7200
   },
   {
      title: '3 ชั่วโมง',
      value: 10800
   },
   {
      title: '6 ชั่วโมง',
      value: 21600
   },
   {
      title: '12 ชั่วโมง',
      value: 43200
   },
   {
      title: '1 วัน',
      value: 86400
   },
   {
      title: '3 วัน',
      value: 259200
   },
   {
      title: '5 วัน',
      value: 432000
   },
   {
      title: '7 วัน',
      value: 604800
   },
   {
      title: '14 วัน',
      value: 1209600
   },
   {
      title: '30 วัน',
      value: 2592000
   }
];

export const SubscriptionBox = ({ e, classes, dropdownBtn }) => {
   const [color, setColor] = useState({ hex: '#ffffff' });
   const [bg, setBG] = useState('');
   const [fontColor, setFontColor] = useState('');
   const setColorFunc = (image_, id_, setColor_, isLocal) => {
      if (image_ && id_) {
         var loaded = false;
         var img = document.getElementById(id_.toString());
         if (typeof img !== 'undefined' && img !== null) {
            let imageURL = image_;
            let googleProxyURL =
               'https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=';
            img.crossOrigin = 'Anonymous';
            img.src = isLocal
               ? encodeURIComponent(imageURL)
               : googleProxyURL + encodeURIComponent(imageURL);
            // img.src = encodeURIComponent(imageURL);
            const loadHandler = () => {
               if (loaded) {
                  return;
               }
               loaded = true;
               var fac = new FastAverageColor();
               if (img) {
                  fac.getColorAsync(img)
                     .then(function(c) {
                        setColor_(c);
                     })
                     .catch(function() {});
               }
            };
            img.addEventListener('load', loadHandler, false);
            loadHandler();
         }
      }
   };

   useEffect(() => {
      if (e) {
         setColorFunc(e.coverImage, e.uid, setColor);
      }
   }, [e]);
   useEffect(() => {
      setBG_FontColorFunc(color, setBG, setFontColor);
   }, [color]);

   const setBG_FontColorFunc = (color_, setBG_, setFontColor_) => {
      if (color_) {
         const bgEle = color_;
         const backgroundElement =
            bgEle &&
            bgEle.value &&
            bgEle.value[0] > 230 &&
            bgEle.value[1] > 230 &&
            bgEle.value[2] > 230
               ? 'rgb(230, 230, 230)'
               : bgEle && bgEle.hex;
         const bg =
            backgroundElement !== undefined && backgroundElement !== '#ffffff'
               ? backgroundElement
               : 'rgb(230, 230, 230)';

         setBG_(bg);

         setFontColor_(
            bgEle
               ? !bgEle ||
                 (bgEle && bgEle.isLight) ||
                 backgroundElement === '#ffffff'
                  ? '#000000'
                  : '#ffffff'
               : '#000000'
         );
      }
   };
   return (
      <>
         {/* <Col span={24} md={12} lg={8}> */}

         <div>
            <div
               style={{
                  backgroundColor: bg ?? '#00000',
                  color: fontColor
               }}
            >
               <div>
                  <img
                     // className="bg-white"
                     id={e.uid}
                     src={e.coverImage}
                     alt=""
                     crossorigin="Anonymous"
                  />
               </div>
               <div>
                  <div
                     class="text-title wrap"
                     style={{
                        color: fontColor
                     }}
                  >
                     {e.title}
                  </div>
                  <div>
                     วันที่เริ่ม :{' '}
                     {moment.unix(e.startDate).format('DD/MM/YYYY')}
                  </div>
                  <div>
                     วันที่สิ้นสุด :{' '}
                     {moment.unix(e.expireDate).format('DD/MM/YYYY')}
                  </div>
                  <div>สถานะ : {e.status === 1 ? 'ใช้งาน' : 'ปิดใช้งาน'}</div>
                  <div>จำนวนสูงสุดในการยืม : {e.concurrent} เล่ม</div>
                  <div>
                     เวลาสูงสุดในการยืม :
                     {dateList.find(({ value }) => value === e.rentalPeriod)
                        ? dateList.find(({ value }) => value === e.rentalPeriod)
                             .title
                        : '-'}
                  </div>
                  <div>แอดมิน :</div>
                  <Dropdown
                     overlay={() => dropdownBtn(e.uid)}
                     trigger={['click']}
                     placement="bottomLeft"
                  >
                     <img
                        alt=""
                        src="/media/members/dot-bookshelf.svg"
                        className="dot" //{classes.dot}
                     />
                  </Dropdown>
               </div>
            </div>
         </div>
         {/* </Col> */}
         {/* <div
      // onClick={() => catalogueUIProps.viewCatalogueDetailClick(props.catalogue.uid)}
      className={classes.colCatalogue}
      style={{
        backgroundColor: bg,
        color: fontColor,
        height: "100%",
        display: "flex",
        alignItems: "flex-start"
      }}
    >
      <img
        src={e.coverImage}
        alt=""
        id={e.uid}
        style={{
          maxWidth: "123px",
          marginRight: "8px",
          width: "35%",
          height: "auto"
        }}
      />
      <div className={classes.catalogueDetail}>
        <div className={classes.catalogueTitle}>{e.title}</div>
        <div>วันที่เริ่ม : {dateStr(e.startDate)}</div>
        <div>วันที่สิ้นสุด : {dateStr(e.expireDate)}</div>
        <div>สถานะ : {e.status === 1 ? "ใช้งาน" : "ปิดใช้งาน"}</div>
        <div>จำนวนสูงสุดในการยืม : {e.concurrent} เล่ม</div>
        <div>
          เวลาสูงสุดในการยืม :{" "}
          {dateList.find(({ value }) => value === e.rentalPeriod)
            ? dateList.find(({ value }) => value === e.rentalPeriod).title
            : "-"}
        </div>
        <div>แอดมิน :</div>
      </div>
    </div> */}
      </>
   );
};
