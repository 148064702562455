import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../_redux/banners/bannersActions";
import { BannerEditForm } from "./BannerEditForm";
import { Card, CardBody } from "../../../../../../_metronic/_partials/controls";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

const useStyles = makeStyles((theme) => ({}));

export function BannerEditDialog({ id, show, onHide, isPrivate, forEdit }) {
     const dispatch = useDispatch();
     const [bannerValue, setBannerValue] = useState(null);

     // if !id we should close modal
     useEffect(() => {
          if (!id) {
               // onHide();
          } else {
               const reqBannerFunc = async () => {
                    const reqBanner = await axios.get(`/admin/banners/${id}/update`);
                    if (reqBanner.data.status) {
                         setBannerValue(reqBanner.data.data);
                    }
               };
               reqBannerFunc();
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [id]);

     const createBanner = (val) => {
          if (val) {
               dispatch(actions.createBanner(val, isPrivate, onHide)).then(() => {
                    // dispatch(actions.fetchBanners(bannersUIProps.queryParams, isPrivate));
               });
          }
     };

     const saveBanner = (val) => {
          if (val) {
               dispatch(actions.updateBanner(val, isPrivate, onHide)).then(() => {
                    // dispatch(actions.fetchBanners(bannersUIProps.queryParams, isPrivate));
               });
          }
     };
     const classes = useStyles();
     return (
          <>
               {/*<div className={`col-2 ${classes.stepDiv}`}>
        <p className={classes.stepDiv_p}>องค์กร</p>
      </div>*/}
               <Card className="cardStyle">
                    <CardBody>
                         <BannerEditForm
                              bannerValue={bannerValue}
                              contentUIDForUpdate={id}
                              createBanner={createBanner}
                              saveBanner={saveBanner}
                              onHide={onHide}
                              setBannerValue={setBannerValue}
                              forEdit={forEdit}
                         />
                    </CardBody>
               </Card>
          </>
     );
}
