/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect, useState, useRef } from 'react';
import { useUploadUIContext } from './UploadUIContext';
import { makeStyles } from '@material-ui/core/styles';
import FileAndCover from './FileAndCover';
import FormForFile from './FormForFile';
import ColorForm from './ColorForm';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export function UploadCard() {
    const history = useHistory();
    const [coverPost, setCoverPost] = useState(null);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [FILENAMEToUpload, setFILENAMEToUpload] = useState(undefined);
    const [step, setStep] = useState(1);
    const imgRef = useRef(null);
    const [successCoverUpload, setSuccessCoverUpload] = useState(false);

    const [colorThemeData, setColorThemeData] = useState([]);
    const [themeValue, setThemeValue] = useState('light');
    const [colorObj, setColorObj] = useState({});
    const [lightColorObj, setLightColorObj] = useState({});
    const [darkColorObj, setDarkColorObj] = useState({});
    const [smsRegisterPreset, setSmsRegisterPreset] = useState([]);

    const [userLimitData, setUserLimitData] = useState([]);
    const [titleLimitData, setTitleLimitData] = useState([]);
    const [storageLimitData, setStorageLimitData] = useState([]);
    const [concurrentLimitData, setConcurrentLimitData] = useState([]);
    const [organizationTypeData, setOrganizationTypeData] = useState([]);
    const [storagePeriodData, setStoragePeriodData] = useState([]);

    useEffect(() => {
        const fetchFunc = async () => {
            const req = await axios.get(`/superuser/clients/create`);

            if (req.data.status && req.data.data) {
                setColorThemeData(
                    req.data.data.colorTheme ? req.data.data.colorTheme : []
                );
                setUserLimitData(
                    req.data.data.userLimitPreset
                        ? req.data.data.userLimitPreset
                        : []
                );
                setTitleLimitData(
                    req.data.data.titlePreset ? req.data.data.titlePreset : []
                );
                setStorageLimitData(
                    req.data.data.storagePreset
                        ? req.data.data.storagePreset
                        : []
                );
                setConcurrentLimitData(
                    req.data.data.concurrentPreset
                        ? req.data.data.concurrentPreset
                        : []
                );
                setOrganizationTypeData(
                    req.data.data.organizationType
                        ? req.data.data.organizationType
                        : []
                );
                setStoragePeriodData(
                    req.data.data.rentalPeriod ? req.data.data.rentalPeriod : []
                );

                setSmsRegisterPreset(req.data.data.remainSMSPreset);
            }
        };
        console.log('fetchFunc');
        fetchFunc();
    }, []);
    const uploadUIContext = useUploadUIContext();
    const uploadUIProps = useMemo(() => {
        return {
            initUploadValue: uploadUIContext.initUploadValue
        };
    }, [uploadUIContext]);
    const useStyles1 = makeStyles(theme => ({
        stepDiv: {
            width: '250px',
            height: '53px',
            borderRadius: '20px 20px 0px 0px',
            margin: '0 -8.5px 0 12.5px',
            backgroundColor: 'white',
            padding: '12.5px'
        },
        stepDivBlack: {
            width: '250px',
            height: '53px',
            borderRadius: '20px 20px 0px 0px',
            margin: '0 -8.5px 0 12.5px',
            backgroundColor: '#e0e0e0',
            padding: '12.5px'
        },
        stepIndex: {
            marginRight: '7px',
            fontSize: 20,
            fontWeight: 600,
            color: 'white',
            backgroundColor: '#FD6B53',
            display: 'inline-block',
            width: 30,
            height: 30,
            textAlign: 'center',
            borderRadius: 100
        },
        stepIndexBlack: {
            marginRight: '7px',
            fontSize: 20,
            fontWeight: 600,
            color: '#828282',
            backgroundColor: '#BDBDBD',
            display: 'inline-block',
            width: 30,
            height: 30,
            textAlign: 'center',
            borderRadius: 100
        },
        stepDiv_p: {
            display: 'inline-block',
            fontSize: '20px',
            color: '#FD6B53',
            fontWeight: 600
        },
        stepDivBlack_p: {
            display: 'inline-block',
            fontSize: '20px',
            color: '#828282',
            fontWeight: 600
        }
    }));
    const classes = useStyles1();
    const handleSubmit = note => {
        if (note === 'prev') {
            setStep(step - 1);
        } else {
            if (step === 3) {
                history.push('/companies');
            } else {
                setStep(step + 1);
            }
        }
    };
    const stepLabel = ['อัปโหลดโลโก้', 'สีองค์กร', 'ข้อมูลองค์กร'];
    return (
        <>
            <div className="row">
                {stepLabel.map((el, index) => (
                    <div
                        key={index}
                        className={`col-3 ${
                            index + 1 === step
                                ? classes.stepDiv
                                : classes.stepDivBlack
                        }`}
                    >
                        <p
                            className={
                                index + 1 === step
                                    ? classes.stepIndex
                                    : classes.stepIndexBlack
                            }
                        >
                            {index + 1}
                        </p>
                        <p
                            className={
                                index + 1 === step
                                    ? classes.stepDiv_p
                                    : classes.stepDivBlack_p
                            }
                        >
                            {el}
                        </p>
                    </div>
                ))}
            </div>
            {step === 1 ? (
                <FileAndCover
                    handleSubmitOutside={handleSubmit}
                    // storageData={{ usedStorage: 0, totalStorage: 0.007, percentage: 0.89 }}
                    coverPost={coverPost}
                    setCoverPost={setCoverPost}
                    completedCrop={completedCrop}
                    setCompletedCrop={setCompletedCrop}
                    imgRef={imgRef}
                    FILENAMEToUpload={FILENAMEToUpload}
                    setFILENAMEToUpload={setFILENAMEToUpload}
                    successCoverUpload={successCoverUpload}
                    setSuccessCoverUpload={setSuccessCoverUpload}
                />
            ) : step === 2 ? (
                <ColorForm
                    uploadValue={uploadUIProps.initUploadValue}
                    handleSubmitOutside={handleSubmit}
                    colorThemeData={colorThemeData}
                    // colorObj={colorObj}
                    // setColorObj={setColorObj}
                    lightColorObj={lightColorObj}
                    setLightColorObj={setLightColorObj}
                    darkColorObj={darkColorObj}
                    setDarkColorObj={setDarkColorObj}
                    themeValue={themeValue}
                    setThemeValue={setThemeValue}
                />
            ) : step === 3 ? (
                <FormForFile
                    uploadValue={uploadUIProps.initUploadValue}
                    themeValue={themeValue}
                    setCoverPost={setCoverPost}
                    // colorObj={colorObj}
                    smsRegisterPreset={smsRegisterPreset}
                    lightColorObj={lightColorObj}
                    darkColorObj={darkColorObj}
                    handleSubmitOutside={handleSubmit}
                    FILENAMEToUpload={FILENAMEToUpload}
                    userLimitData={userLimitData}
                    titleLimitData={titleLimitData}
                    storageLimitData={storageLimitData}
                    concurrentLimitData={concurrentLimitData}
                    organizationTypeData={organizationTypeData}
                    storagePeriodData={storagePeriodData}
                    setSuccessCoverUpload={setSuccessCoverUpload}
                />
            ) : (
                ''
            )}
        </>
    );
}
