import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/channels/channelsActions";
import { useChannelsUIContext } from "../ChannelsUIContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
     body: {
          textAlign: "center",
     },
}));

export function ChannelDeleteDialog({ name, id, channelId, creatorId, isPrivate, show, onHide }) {
     // Channels UI Context
     const channelsUIContext = useChannelsUIContext();
     const channelsUIProps = useMemo(() => {
          return {
               setIds: channelsUIContext.setIds,
               queryParams: channelsUIContext.queryParams,
          };
     }, [channelsUIContext]);
     const [isCreator, setIsCreator] = useState(false);

     useEffect(() => {
          if (channelId && creatorId) {
               setIsCreator(true);
          }
     }, [channelId, creatorId]);

     // Channels Redux state
     const dispatch = useDispatch();
     const { isLoading } = useSelector((state) => ({ isLoading: state.channels.actionsLoading }), shallowEqual);

     // if !id we should close modal
     useEffect(() => {
          if (!id) {
               // onHide();
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [id]);

     // looking for loading/dispatch
     useEffect(() => {}, [isLoading, dispatch]);

     const deleteChannel = () => {
          // server request for deleting channel by id
          dispatch(actions.deleteChannel(id, isPrivate)).then(() => {
               // refresh list after deletion
               dispatch(actions.fetchChannels(channelsUIProps.queryParams, isPrivate));
               // clear selections list
               channelsUIProps.setIds([]);
               // closing delete modal
               onHide();
          });
     };

     const deleteCreator = () => {
          // server request for deleting category by id
          dispatch(actions.deleteCreator(channelId, creatorId, isPrivate)).then(() => {
               // refresh list after deletion
               dispatch(actions.fetchCreators(channelsUIProps.queryParams, channelId, isPrivate));
               // closing delete modal
               onHide();
          });
     };

     const classes = useStyles();

     return (
          <Modal
               backdrop="static"
               show={show}
               onHide={onHide}
               aria-labelledby="example-modal-sizes-title-lg"
               style={{ margin: "3% auto" }}
          >
               {isLoading && <ModalProgressBar />}
               <Modal.Body className={classes.body}>
                    {name ? (
                         <>
                              <img src="/media/members/image-modal.svg" alt="" />
                              <div className="text-text-red">ยืนยันการลบ</div>
                              <div className="text-text-gray">ต้องการลบ</div>
                              <div className="text-text-gray"> {name} หรือไม่?</div>
                              <div className="mt-3">
                                   <button type="button" onClick={onHide} className="denyButton">
                                        ยกเลิก
                                   </button>
                                   <button
                                        type="button"
                                        onClick={() => {
                                             if (isCreator) {
                                                  deleteCreator();
                                             } else {
                                                  deleteChannel();
                                             }
                                        }}
                                        className="acceptButton"
                                   >
                                        ยืนยัน
                                   </button>
                              </div>
                         </>
                    ) : (
                         ""
                    )}
               </Modal.Body>
          </Modal>
     );
}
