import { createSlice } from '@reduxjs/toolkit';
import {
    ContentById,
    Content,
    LastEvaluatedKey_Content,
    LastEvaluatedKey_FreeContent
} from '../../../../../interface';
interface EbooksState {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: 0;
    entities: Content[];
    entities_hti: Content[];
    entities_free: Content[];
    ebookForEdit?: ContentById;
    lastError: null;
    LastEvaluatedKey?: string;
    LastEvaluatedKey_hti?: string;
    LastEvaluatedKey_free?: string;
    error?: string | null;
}

const initialEbooksState: EbooksState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    entities_hti: [],
    entities_free: [],
    ebookForEdit: undefined,
    lastError: null,
    LastEvaluatedKey: undefined,
    LastEvaluatedKey_hti: undefined,
    LastEvaluatedKey_free: undefined
};
export const callTypes = {
    list: 'list',
    action: 'action'
};

export const ebooksSlice = createSlice({
    name: 'ebooks',
    initialState: initialEbooksState,
    reducers: {
        catchError: (state, action) => {
            if (
                action.payload.error &&
                action.payload.error.response &&
                action.payload.error.response.data
            ) {
                state.error = action.payload.error.response.data.errorCode;
            }
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            console.log(
                'start call',
                action.payload.callType === callTypes.list
            );

            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getEbookById
        ebookFetched: (state, action) => {
            state.actionsLoading = false;
            state.ebookForEdit = action.payload.ebookForEdit;
            state.error = null;
        },
        // findEbooks
        ebooksFetched: (state, action) => {
            const {
                totalCount,
                entities,
                LastEvaluatedKey,
                lastEvaluate,
                isPrivate,
                isFree,
                memberMode
            } = action.payload;
            const blockedOrNormal_Private = (e_status: number) =>
                e_status === 1 ? 1 : 0;

            state.error = null;
            if (isPrivate) {
                if (lastEvaluate === undefined) {
                    // if (memberMode) {
                    //    state.entities = entities.filter(
                    //       (e: Content) =>
                    //          blockedOrNormal_Private(e.status) ===
                    //          parseInt(memberMode, 10)
                    //    );
                    // } else {
                    state.entities = entities;
                    // }
                } else {
                    if (memberMode) {
                        entities
                            //  .filter(
                            //      (e: Content) =>
                            //          blockedOrNormal_Private(e.status) ===
                            //          parseInt(memberMode, 10)
                            //  )
                            .forEach((item: Content) =>
                                state.entities.push(item)
                            );
                    } else {
                        entities.forEach((item: Content) =>
                            state.entities.push(item)
                        );
                    }
                }
            } else {
                if (isFree) {
                    if (lastEvaluate === undefined) {
                        if (memberMode) {
                            state.entities_free = entities.filter(
                                (e: Content) => {
                                    return (
                                        e.status === parseInt(memberMode, 10)
                                    );
                                }
                            );
                        } else {
                            state.entities_free = entities;
                        }
                    } else {
                        if (memberMode) {
                            entities
                                .filter(
                                    (e: Content) =>
                                        e.status === parseInt(memberMode, 10)
                                )
                                .forEach((item: Content) =>
                                    state.entities_free.push(item)
                                );
                        } else {
                            entities.forEach((item: Content) =>
                                state.entities_free.push(item)
                            );
                        }
                    }
                } else {
                    if (lastEvaluate === undefined) {
                        if (memberMode) {
                            state.entities_hti = entities.filter(
                                (e: Content) =>
                                    e.status === parseInt(memberMode, 10)
                            );
                        } else {
                            state.entities_hti = entities;
                        }
                    } else {
                        if (memberMode) {
                            entities
                                .filter(
                                    (e: Content) =>
                                        e.status === parseInt(memberMode, 10)
                                )
                                .forEach((item: Content) =>
                                    state.entities_hti.push(item)
                                );
                        } else {
                            entities.forEach((item: Content) =>
                                state.entities_hti.push(item)
                            );
                        }
                    }
                }
            }
            state.totalCount = totalCount;
            if (isFree) {
                state.LastEvaluatedKey_free = LastEvaluatedKey;
            } else if (isPrivate) {
                state.LastEvaluatedKey = LastEvaluatedKey;
            } else {
                state.LastEvaluatedKey_hti = LastEvaluatedKey;
            }
            // state.entities = mockUsers;
            // state.totalCount = 17;
            state.listLoading = false;
        },
        // createEbook
        ebookCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.ebook);
        },
        // updateEbook
        ebookUpdated: (state, action) => {
            // console.log(action.payload.id, action.payload.ebook);
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.contentUID === action.payload.id) {
                    // console.log(action.payload.ebook);
                    // delete action.payload.ebook.coverImage;
                    return Object.assign({}, entity, action.payload.ebook);
                }
                return entity;
            });
        },
        // deleteEbook
        ebookDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => el.contentUID !== action.payload.id
            );
        },
        // blockEbook
        ebookBlocked: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.contentUID === action.payload.id) {
                    return Object.assign({}, entity, { status: 2 });
                }
                return entity;
            });
        },
        // unblockEbook
        ebookUnblocked: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.contentUID === action.payload.id) {
                    return Object.assign({}, entity, { status: 1 });
                }
                return entity;
            });
        },
        // deleteEbooks
        // ebooksDeleted: (state, action) => {
        //   state.error = null;
        //   state.actionsLoading = false;
        //   state.entities = state.entities.filter((el) => !action.payload.ids.includes(el.id));
        // },
        // ebooksUpdateState
        // ebooksStatusUpdated: (state, action) => {
        //   state.actionsLoading = false;
        //   state.error = null;
        //   const { ids, status } = action.payload;
        //   state.entities = state.entities.map((entity) => {
        //     if (ids.findIndex((id) => id === entity.id) > -1) {
        //       entity.status = status;
        //     }
        //     return entity;
        //   });
        // },
        elementUpdated: state => {
            state.actionsLoading = false;
        }
    }
});
