/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/channels-podcast/channelsActions";
import { ChannelEditForm } from "./ChannelEditForm";
import { useChannelsUIContext } from "../ChannelsUIContext";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
     body: { margin: "auto" },
     divSuccess: {
          textAlign: "center",
          width: "340px",
          height: "360px",
          borderRadius: "10px",
          backgroundColor: "#fff",
     },
     divText: { fontSize: "24px", fontWeight: "600", color: "#012875" },
     button: {
          marginTop: "32px",
          fontSize: "17px",
          fontWeight: "600",
          width: "200px",
          height: "48px",
          borderRadius: "100px",
          backgroundColor: "#012875",
          border: "none",
          color: "white",
     },
}));

export function ChannelEditDialog({ name, id, channelId, creatorId, isPrivate, show, onHide }) {
     // Channels UI Context
     const [successModal, setSuccessModal] = useState(false);
     const channelsUIContext = useChannelsUIContext();
     const channelsUIProps = useMemo(() => {
          return {
               initChannel: channelsUIContext.initChannel,
          };
     }, [channelsUIContext]);
     const [isCreator, setIsCreator] = useState(false);

     useEffect(() => {
          if (channelId && creatorId) {
               setIsCreator(true);
          }
     }, [channelId, creatorId]);

     const styles = useStyles();

     // Channels Redux state
     const dispatch = useDispatch();
     const { actionsLoading, creatorsForEdit, entities } = useSelector(
          (state) => ({
               actionsLoading: state.channels.actionsLoading,
               creatorsForEdit: state.channels.creatorsForEdit,
               entities: state.channels.entities,
          }),
          shallowEqual
     );
     const [creatorForEdit, setCreatorForEdit] = useState({});
     const [hasId, setHasId] = useState(false);
     useEffect(() => {
          if (id) {
               setHasId(true);
               if (entities && entities.find((el) => el.uid === id)) {
                    setCreatorForEdit(entities.find((el) => el.uid === id));
               }
          } else if (creatorId) {
               setHasId(true);
               if (creatorsForEdit && creatorsForEdit.find((el) => el.uid === creatorId)) {
                    setCreatorForEdit(creatorsForEdit.find((el) => el.uid === creatorId));
               }
          }
     }, [id, dispatch, entities, creatorId]);

     // server request for saving channel
     const saveChannel = (channel) => {
          if (!id) {
               // server request for creating channel
               dispatch(actions.createChannel(channel, isPrivate, setSuccessModal));
          } else {
               // server request for updating channel
               dispatch(actions.updateChannel(channel, isPrivate, setSuccessModal));
          }
     };

     const saveCreator = (creator) => {
          const updatedOne = {
               name: creator.name,
               status: creator.status,
               channelId: channelId,
               creatorId: creatorId,
               image: creator.image,
          };
          // server request for deleting category by id
          dispatch(actions.updateCreator(updatedOne, isPrivate, setSuccessModal)).then(() => {
               // refresh list after deletion
               dispatch(actions.fetchCreators("none", channelId, isPrivate));
               // closing delete modal
               // onHide();
          });
     };

     return (
          <Modal
               backdrop="static"
               size="md"
               show={show}
               onHide={onHide}
               style={{ marginTop: "27px" }}
               aria-labelledby="example-modal-sizes-title-md"
               dialogClassName={successModal ? styles.divSuccess : ""}
          >
               {successModal ? (
                    <Modal.Body className={styles.body}>
                         <div>
                              <img src="/media/bg/success.svg" alt="" />
                         </div>
                         <div className="divText-modal">เรียบร้อยแล้ว</div>
                         <div>
                              <button
                                   className="acceptButton "
                                   onClick={() => {
                                        onHide();
                                        setTimeout(() => {
                                             setSuccessModal(false);
                                        }, 200);
                                   }}
                              >
                                   ตกลง
                              </button>
                         </div>
                    </Modal.Body>
               ) : (
                    <ChannelEditForm
                         saveChannel={saveChannel}
                         saveCreator={saveCreator}
                         actionsLoading={actionsLoading}
                         channel={(hasId && creatorForEdit) || channelsUIProps.initChannel}
                         onHide={onHide}
                         isCreator={isCreator}
                         channelList={entities}
                         id={id}
                    />
               )}
          </Modal>
     );
}
