import React from 'react';
import { CardOnTop, BorrowedTop20, BorrowingQueue } from '../widgets';
export function Demo1Dashboard(props) {
    const isSmsRegister = props?.smsCount !== undefined ? true : false;

    const getCardClass = isSmsRegister => {
        if (isSmsRegister) {
            return 'col-3';
        } else {
            return 'col-4';
        }
    };

    return (
        <>
            <div className="row" style={{ height: 'calc(100vh - 338px)' }}>
                {props?.smsCount !== undefined + ''}
                <div className={getCardClass(isSmsRegister)}>
                    <CardOnTop
                        className="card-stretch card-stretch-half gutter-b"
                        type="member"
                        data={props.memberAmountData}
                    />
                    {/* <MixedWidget1 className="card-stretch gutter-b" /> */}
                </div>
                <div className={getCardClass(isSmsRegister)}>
                    <CardOnTop
                        className="card-stretch card-stretch-half gutter-b"
                        type="ebook"
                        data={props.contentAmount}
                    />
                    {/* <ListsWidget9 className="card-stretch gutter-b" /> */}
                </div>

                {isSmsRegister ? (
                    <div className={getCardClass(isSmsRegister)}>
                        <CardOnTop
                            className="card-stretch card-stretch-half gutter-b"
                            type="sms-otp"
                            data={props.smsCount}
                        />
                    </div>
                ) : null}

                <div className={getCardClass(isSmsRegister)}>
                    <CardOnTop
                        className="card-stretch card-stretch-half gutter-b"
                        type="borrowing"
                        data={props.licenseAmount}
                    />
                    {/* <StatsWidget12 className="card-stretch card-stretch-half gutter-b" /> */}
                </div>
                <div className="col-12 divBorrowed">
                    <div className="col-xxl-6 order-1 order-xxl-1 marginBottom25">
                        <BorrowedTop20
                            className="card-stretch gutter-b"
                            loaded={props.loadedDashboard}
                            data={props.borrowTop20List}
                            style={{ marginBottom: 0 }}
                        />
                    </div>
                    <div className="col-xxl-6 order-2 order-xxl-1 marginBottom25">
                        <BorrowingQueue
                            className="card-stretch"
                            loaded={props.loadedDashboard}
                            data={props.borrowQueue}
                            style={{ marginBottom: 0 }}
                        />
                    </div>
                </div>
                {/* <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget3 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget4 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget8 className="card-stretch gutter-b" />
        </div> */}
            </div>
            {/* <div className="row">
        <div className="col-lg-4">
          <MixedWidget14 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-8">
          <AdvanceTablesWidget4 className="card-stretch gutter-b" />
        </div>
      </div> */}
        </>
    );
}
