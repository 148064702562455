import * as requestFromServer from "./videosCrud";
import { videosSlice, callTypes } from "./videosSlice";
import {
  CreateContentPayload,
  customersUIPropsQueryParams,
  LastEvaluatedKey_Content,
  LastEvaluatedKey_FreeContent,
  UpdateContentPayload,
  HtiResponse,
  ContentData,
  FreeContentData,
  contentForEdit,
  deletePayload,
} from "../../../../../interface";

const { actions } = videosSlice;

export const fetchVideos = (
  queryParams: customersUIPropsQueryParams,
  isPrivate: string,
  isFree: string,
  lastEvaluate: LastEvaluatedKey_Content | LastEvaluatedKey_FreeContent,
  setLoadingBottom?: (loading: boolean) => void,
  setSearching?: (searching: boolean) => void
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findVideos(
      queryParams,
      parseInt(isPrivate, 10),
      lastEvaluate,
      parseInt(isFree, 10)
    )
    .then((response) => {
      const {
        data,
      }: HtiResponse<ContentData | FreeContentData> = response.data;
      // const { LastEvaluatedKey, contents, totalAmount } = response.data.data;
      const entities = data?.contents;
      dispatch(
        actions.videosFetched({
          totalCount: data?.totalAmount,
          entities,
          LastEvaluatedKey: data?.LastEvaluatedKey,
          lastEvaluate,
          isPrivate,
          isFree,
          memberMode: queryParams.memberMode,
        })
      );
      if (setSearching) {
        setSearching(false);
      }
      if (setLoadingBottom) {
        setLoadingBottom(false);
      }
    })
    .catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorCode === "012"
      ) {
        alert("กรุณาเข้าสู่ระบบอีกครั้ง เนื่องจากระยะเวลาการใช้งานหมดอายุ");
        window.location.href = "/logout";
      }
      dispatch(
        actions.videosFetched({
          totalCount: 0,
          entities: [],
          LastEvaluatedKey: {},
          isPrivate,
          isFree,
        })
      );
      if (setSearching) {
        setSearching(false);
      }
      error.clientMessage = "Can't find videos";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchVideo = (id: string) => (
  dispatch: (arg0: { payload: any; type: string }) => void
) => {
  if (!id) {
    return dispatch(actions.videoFetched({ videoForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getVideoById(id)
    .then((response) => {
      const video = response.data;
      dispatch(actions.videoFetched({ videoForEdit: video }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find video";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteVideo = (
  id: string,
  isPrivate: boolean,
  forceReturn: deletePayload
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteVideo(id, isPrivate, forceReturn)
    .then((response) => {
      if (response.data.status) {
        dispatch(actions.videoDeleted({ id }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't delete video";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const blockVideo = (id : string, isPrivate :boolean, isFree:boolean) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .blockVideo(id, isPrivate, isFree)
    .then((response) => {
      if (response.data.status) {
        dispatch(actions.videoBlocked({ id }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't block video";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const unblockVideo = (id : string, isPrivate : boolean, isFree : boolean) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .unblockVideo(id, isPrivate, isFree)
    .then((response) => {
      if (response.data.status) {
        dispatch(actions.videoUnblocked({ id }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't unblock video";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createVideo = (newValues : CreateContentPayload, setSuccessModal ?: (success:boolean)=>void) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.createVideo(newValues).then((res) => {
    if (res.data.status) {
      if (setSuccessModal) {
        dispatch(actions.elementUpdated());
        setSuccessModal(true);
      }
    }
  });
};

export const updateVideo = (
  newValues : UpdateContentPayload,
  contentUID : string,
  isNew :boolean,
  setSuccessModal ?: (success:boolean)=>void,
  originValues ?:contentForEdit
) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateVideo(newValues, contentUID, isNew)
    .then((res) => {
      if (res.data.status && res.data.data) {
        dispatch(
          actions.videoUpdated({
            id: contentUID,
            video: Object.assign(
              {},
              originValues,
              newValues,
              res.data.data,
              {
                channelName:
                  res.data.data.publisher || res.data.data.channelName,
              },
              {
                concurrent:
                  newValues.concurrent === 0
                    ? "unlimited"
                    : newValues.concurrent,
              }
            ),
          })
        );
        if (setSuccessModal) {
          setSuccessModal(true);
        }
      }
    });
};

// export const updateVideosStatus = (ids, status) => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateStatusForVideos(ids, status)
//     .then(() => {
//       dispatch(actions.videosStatusUpdated({ ids, status }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't update videos status";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const deleteVideos = ids => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deleteVideos(ids)
//     .then(() => {
//       dispatch(actions.videosDeleted({ ids }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't delete videos";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };
