import axios from 'axios';

export const CATALOGUE_URL = '/admin/catalogs';
// export const CATALOGUE_URL = `/admin/contents`;
// export const CATALOGUE_URL = `/admin/private/contents`;
export const PUBLISHERS_CATALOGUE_URL = '/admin/catalogs/publishers';
export const CATEGORIES_CATALOGUE_URL = '/admin/catalogs/categories';
export const catalog_VOTE_URL = '/catalogs/vote';

// CREATE =>  POST: add a new catalogue to the server
export function createCatalogue(newValues) {
   return axios.post(CATALOGUE_URL, newValues);
}

export const createcatalogVote = async votePayload => {
   try {
      const { data } = await axios.post(catalog_VOTE_URL, votePayload);
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};

export const stopCatalogVote = async catalogUID => {
   try {
      const { data } = await axios.delete(catalog_VOTE_URL, {
         data: { catalogUID }
      });
      return data;
   } catch (error) {
      return Promise.reject(error);
   }
};

// READ
export function getAllCatalogues() {
   return axios.get(CATALOGUE_URL);
}

export function getCatalogueById(
   queryParams,
   catalogueId,
   lastEvaluate,
   isCatalogueDetail
) {
   let status = '';
   let lastKeyQuery = '';
   let isAsc = '';

   if (queryParams.memberMode && isCatalogueDetail) {
      status = `&status=${queryParams.memberMode - 1}`;
   }
   if (queryParams.isAsc && isCatalogueDetail) {
      isAsc = `&isAsc=${queryParams.isAsc}`;
   }
   if (
      lastEvaluate !== undefined &&
      JSON.stringify(lastEvaluate) !== JSON.stringify({}) &&
      isCatalogueDetail
   ) {
      lastKeyQuery = '&lastEvaluatedKey=' + lastEvaluate;
   }

   return axios.get(
      `${CATALOGUE_URL}/voteCount/${catalogueId}${
         isCatalogueDetail ? '?limit=100' : '?withContents=false'
      }${status}${lastKeyQuery}${isAsc}`
   );
}

export function getPublisherContentsById(id, publisherId, lastEvaluate) {
   let lastKeyQuery = '';
   if (
      lastEvaluate !== undefined &&
      JSON.stringify(lastEvaluate) !== JSON.stringify({})
   ) {
      // lastKeyQuery = "&lastEvaluatedKey=" + btoa(JSON.stringify(lastEvaluate));
      lastKeyQuery = '?lastEvaluatedKey=' + btoa(JSON.stringify(lastEvaluate));
   }
   return axios.get(
      `${CATALOGUE_URL}/${id}/publishers/${publisherId}${lastKeyQuery}`
   );
}

export function getNewEbooksContentsById(id, lastEvaluate) {
   let lastKeyQuery = '';
   if (
      lastEvaluate !== undefined &&
      JSON.stringify(lastEvaluate) !== JSON.stringify({})
   ) {
      // lastKeyQuery = "&lastEvaluatedKey=" + btoa(JSON.stringify(lastEvaluate));
      lastKeyQuery = '?lastEvaluatedKey=' + btoa(JSON.stringify(lastEvaluate));
   }
   return axios.get(`${CATALOGUE_URL}/${id}/newest${lastKeyQuery}`);
}

export function getCategoryContentsById(id, catId, lastEvaluate, subcatId) {
   if (subcatId) {
      let lastKeyQuery = '';
      if (
         lastEvaluate !== undefined &&
         JSON.stringify(lastEvaluate) !== JSON.stringify({})
      ) {
         // lastKeyQuery = "&lastEvaluatedKey=" + btoa(JSON.stringify(lastEvaluate));
         lastKeyQuery =
            '?lastEvaluatedKey=' + btoa(JSON.stringify(lastEvaluate));
      }
      return axios.get(
         `${CATALOGUE_URL}/${id}/categories/${catId}/subcategories/${subcatId}${lastKeyQuery}`
      );
   } else {
      return axios.get(`${CATALOGUE_URL}/${id}/categories/${catId}`);
   }
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCatalogues(queryParams, type, lastEvaluate, catalogId) {
   if (!queryParams.keyword) {
      // return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
      let limit = '';
      let lastKeyQuery = '';
      let status = '';
      if (queryParams && queryParams.pageSize) {
         limit = `?limit=${queryParams.pageSize}`;
      }
      if (
         lastEvaluate !== undefined &&
         JSON.stringify(lastEvaluate) !== JSON.stringify({})
      ) {
         // lastKeyQuery = "&lastEvaluatedKey=" + btoa(JSON.stringify(lastEvaluate));
         lastKeyQuery =
            '&lastEvaluatedKey=' + btoa(JSON.stringify(lastEvaluate));
      }
      if (queryParams.memberMode) {
         status = `&status=${queryParams.memberMode - 1}`;
      }
      return axios.get(
         `${
            type === 'catalogues'
               ? CATALOGUE_URL
               : type === 'publishers'
               ? PUBLISHERS_CATALOGUE_URL
               : type === 'categories'
               ? CATEGORIES_CATALOGUE_URL
               : type === 'catalogueDetail'
               ? CATALOGUE_URL + '/' + queryParams
               : CATALOGUE_URL
         }${limit}${lastKeyQuery}${status}`
      );
   } else {
      return axios.get(
         `${
            type === 'catalogues'
               ? CATALOGUE_URL
               : type === 'publishers'
               ? PUBLISHERS_CATALOGUE_URL
               : type === 'categories'
               ? CATEGORIES_CATALOGUE_URL
               : type === 'catalogueDetail'
               ? CATALOGUE_URL
               : CATALOGUE_URL
         }
     
      `
         ///search?keyword=${encodeURIComponent(queryParams.keyword)}
      );
   }
   // return axios.post(`${CATALOGUE_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the catalogue on the server
export function updateCatalogue(newValues, contentUID, isNew) {
   let isNewQuery = '';
   if (isNew) {
      isNewQuery = '?isNew=true';
   }
   let values = newValues;
   delete values.authorName;
   delete values.publisherName;
   delete values.activeLicense;
   // console.log(values);
   return axios.put(`${CATALOGUE_URL}/${contentUID}/edit${isNewQuery}`, values);
}

// UPDATE Status
export function updateStatusForCatalogues(ids, status) {
   return axios.post(`${CATALOGUE_URL}/updateStatusForCatalogues`, {
      ids,
      status
   });
}

// DELETE => delete the catalogue from the server
export function deleteCatalogue(catalogueId) {
   return axios.delete(`${CATALOGUE_URL}/${catalogueId}`);
}

export function blockCatalogue(catalogueId, isPrivate, isFree) {
   if (isPrivate) {
      return axios.patch(`${CATALOGUE_URL}/${catalogueId}/status`, {
         status: 2
      });
   } else {
      if (isFree) {
         return axios.patch(
            `${PUBLISHERS_CATALOGUE_URL}/${catalogueId}/status`
         );
      } else {
         return axios.patch(`${CATALOGUE_URL}/${catalogueId}/status`);
      }
   }
}

export function unblockCatalogue(catalogueId, isPrivate, isFree) {
   if (isPrivate) {
      return axios.patch(`${CATALOGUE_URL}/${catalogueId}/status`, {
         status: 1
      });
   } else {
      if (isFree) {
         return axios.patch(
            `${PUBLISHERS_CATALOGUE_URL}/${catalogueId}/status`
         );
      } else {
         return axios.patch(`${CATALOGUE_URL}/${catalogueId}/status`);
      }
   }
}

// DELETE Catalogues by ids
export function deleteCatalogues(ids) {
   return axios.post(`${CATALOGUE_URL}/deleteCatalogues`, { ids });
}

export function findAllContentAmount() {
   return axios.get(`${CATALOGUE_URL}/contents`);
}
export function searchCatalog(queryParams, id, lastEvaluate) {
   let lastKeyQuery = '';
   let catalogID = id ? `&catalogUID=` + id : '';
   // if (!queryParams.keyword) {
   if (
      lastEvaluate !== undefined &&
      JSON.stringify(lastEvaluate) !== JSON.stringify({})
   ) {
      lastKeyQuery = '&lastEvaluatedKey=' + btoa(JSON.stringify(lastEvaluate));
      return axios.get(
         `${'/catalogs/search'}?keyword=${
            queryParams.keyword
         }${lastKeyQuery}${catalogID}`
      );
   } else {
      if (queryParams.keyword) {
         return axios.get(
            `${'/catalogs/search'}?keyword=${queryParams.keyword}${catalogID}`
         );
      } else {
         return new Promise((resolve, reject) => {
            // resolve({ data: { data: { contents: [] } } });
            resolve(null);
         });
      }
   }

   // }else{
   //   return axios.get(
   //     `${"/catalogs/search"}?keyword=${
   //       '2020'
   //       // queryParams.keyword
   //     }`
   //   );
   // }
}
