import { Button, message, Space } from 'antd';
import { useState } from 'react';

import { deleteToken, TokenType } from '../../../redux/axios/tokenKey';
import { ConfirmModalStyle } from './styled';

interface ModalProps {
   visible: boolean;
   onCancel: () => void;
   onDelete: (uid: string) => void;
   tokenData: TokenType;
}

const ConfirmModal = (props: ModalProps) => {
   const [loading, setLoading] = useState<boolean>(false);

   const onDeleteToken = async () => {
      try {
         setLoading(true);
         const response = await deleteToken(props.tokenData.uid);
         if (response) {
            setLoading(false);
            props.onDelete(props.tokenData.uid);
            props.onCancel();
            message.success('ลบข้อมูล Token สำเร็จ');
         }
      } catch (error) {
         setLoading(false);
      }
   };

   return (
      <ConfirmModalStyle
         visible={props.visible}
         title={null}
         onCancel={props.onCancel}
         footer={null}
         maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.25)' }}
      >
         <img src="/media/members/Frame.png" alt="" />
         <div className="textContainer">
            <h1>ยืนยันการลบ</h1>
            <p>
               ต้องการลบ Token Key <span>{props.tokenData.name}</span> หรือไม่
            </p>
         </div>

         <Space>
            <Button
               size="large"
               shape="round"
               className="outline"
               onClick={props.onCancel}
            >
               ยกเลิก
            </Button>
            <Button
               size="large"
               shape="round"
               onClick={() => onDeleteToken()}
               loading={loading}
               className="background"
            >
               ลบ
            </Button>
         </Space>
      </ConfirmModalStyle>
   );
};

export default ConfirmModal;
