import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { EbooksLoadingDialog } from "./ebooks-loading-dialog/EbooksLoadingDialog";
import { EbookViewDialog } from "./ebook-view-dialog/EbookViewDialog";
import { EbookBlockDialog } from "./ebook-block-dialog/EbookBlockDialog";
import { EbookUnblockDialog } from "./ebook-unblock-dialog/EbookUnblockDialog";
import { EbooksUIProvider } from "./EbooksUIContext";
import { EbooksCard } from "./EbooksCard";
import queryString from "query-string";

export function EbooksFreePage({ history, location }) {
  // const [isNew, setNew] = useState(false);
  // const [isEdit, setEdit] = useState(false);
  // const [isDelete, setDelete] = useState(false);
  const [isBlock, setBlock] = useState(false);
  const [isUnblock, setUnblock] = useState(false);
  const [isView, setView] = useState(false);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.id) {
      setId(true);
    }
    if (parsed.name) {
      setName(true);
    }
    if (parsed.id) {
      setId(parsed.id);
    }
    // if (parsed.isNew) {
    //   setNew(true);
    // }
    // if (parsed.edit) {
    //   setEdit(true);
    // }
    // if (parsed.delete) {
    //   setDelete(true);
    // }
    if (parsed.block) {
      setBlock(true);
    }
    if (parsed.unblock) {
      setUnblock(true);
    }
    if (parsed.view) {
      setView(true);
    }
  }, [location.search]);

  const ebooksUIEvents = {
    newEbookButtonClick: () => {
      history.push("/ebooks-free?isNew=true");
    },
    openEditEbookDialog: (id) => {
      history.push(`/ebooks-free?id=${id}&edit=true`);
    },
    openViewEbookDialog: (id) => {
      history.push(`/ebooks-free?id=${id}&view=true`);
    },
    openDeleteEbookDialog: (name, id) => {
      history.push(`/ebooks-free?name=${encodeURIComponent(name)}&id=${id}&delete=true`);
    },
    openBlockEbookDialog: (name, id) => {
      history.push(`/ebooks-free?name=${encodeURIComponent(name)}&id=${id}&block=true`);
    },
    openUnblockEbookDialog: (name, id) => {
      history.push(`/ebooks-free?name=${encodeURIComponent(name)}&id=${id}&unblock=true`);
    },
    openDeleteEbooksDialog: () => {
      history.push(`/ebooks-free/deleteEbooks`);
    },
    openFetchEbooksDialog: () => {
      history.push(`/ebooks-free/fetch`);
    },
    openUpdateEbooksStatusDialog: () => {
      history.push("/ebooks-free/updateStatus");
    },
  };

  return (
    <EbooksUIProvider ebooksUIEvents={ebooksUIEvents}>
      <EbooksLoadingDialog />
      <Switch>
        {isView ? (
          <EbookViewDialog
            show={true}
            id={id}
            isPrivate={0}
            isFree={1}
            onHide={() => {
              history.push("/ebooks-free");
              setView(false);
            }}
          />
        ) : (
          ""
        )}
        {isUnblock ? (
          <EbookUnblockDialog
            show={true}
            name={decodeURIComponent(name)}
            id={id}
            isPrivate={0}
            isFree={1}
            onHide={() => {
              history.push("/ebooks-free");
              setUnblock(false);
            }}
          />
        ) : (
          ""
        )}
        {isBlock ? (
          <EbookBlockDialog
            show={true}
            name={decodeURIComponent(name)}
            id={id}
            isPrivate={0}
            isFree={1}
            onHide={() => {
              history.push("/ebooks-free");
              setBlock(false);
            }}
          />
        ) : (
          ""
        )}
      </Switch>
      <EbooksCard isPrivate={0} isFree={1} />
    </EbooksUIProvider>
  );
}
