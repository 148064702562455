// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
const cellContentText = (e) => {
  switch (e) {
    case "normal":
      return "ทั่วไป";
    case "ebook":
      return "อีบุ๊ก";
    case "podcast":
      return "พ็อดคาสท์";
    case "video":
      return "วิดีโอ";
    case "link":
      return "ลิงค์";
    default:
      return "-";
  }
};

export function TextColumnFormatter(cellContent, row, rowIndex) {
  return <p style={{ fontSize: "14px", marginBottom: "0" }}>{cellContentText(cellContent)}</p>;
}
