import React, { useState, useEffect, useRef } from "react";
import { Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { CatalogueEditDialog } from "./catalogue-edit-dialog/CatalogueEditDialog";
import { CatalogueViewDialog } from "./catalogue-view-dialog/CatalogueViewDialog";
import { CatalogueDeleteDialog } from "./catalogue-delete-dialog/CatalogueDeleteDialog";
import { CatalogueBlockDialog } from "./catalogue-block-dialog/CatalogueBlockDialog";
import { CatalogueUnblockDialog } from "./catalogue-unblock-dialog/CatalogueUnblockDialog";
import FileAndCover from "../viewAsFunction/FileAndCover";
import { CatalogueUIProvider } from "./CatalogueUIContext";
import { CatalogueCard } from "./CatalogueCard";
import queryString from "query-string";
import { EbookViewDialog } from "../ebooks/ebook-view-dialog/EbookViewDialog";

export function CatalogueExamplePage({ history, location }) {
  const parsed_ = queryString.parse(location.search);
  const [id, setId] = useState(null);
  const [ebookId, setEbookId] = useState(null);
  const [name, setName] = useState(null);
  const [isDelete, setDelete] = useState(false);
  const [isBlock, setBlock] = useState(false);
  const [isUnblock, setUnblock] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isView, setView] = useState(false);
  const [isExample, setExample] = useState(false);
  const [viewEbookModal, setViewEbookModal] = useState(false);
  const step = 1;

  const useStyles1 = makeStyles(theme => ({
    stepDiv: {
      width: "250px",
      height: "53px",
      borderRadius: "20px 20px 0px 0px",
      margin: "0 -8.5px 0 12.5px",
      backgroundColor: "white",
      padding: "12.5px"
    },
    stepDivBlack: {
      width: "250px",
      height: "53px",
      borderRadius: "20px 20px 0px 0px",
      margin: "0 -8.5px 0 12.5px",
      backgroundColor: "#e0e0e0",
      padding: "12.5px"
    },
    stepIndex: {
      marginRight: "7px",
      fontSize: 20,
      fontWeight: 600,
      color: "white",
      backgroundColor: "#FD6B53",
      display: "inline-block",
      width: 30,
      height: 30,
      textAlign: "center",
      borderRadius: 100
    },
    stepIndexBlack: {
      marginRight: "7px",
      fontSize: 20,
      fontWeight: 600,
      color: "#828282",
      backgroundColor: "#BDBDBD",
      display: "inline-block",
      width: 30,
      height: 30,
      textAlign: "center",
      borderRadius: 100
    },
    stepDiv_p: {
      display: "inline-block",
      fontSize: "20px",
      color: "#FD6B53",
      fontWeight: 600
    },
    stepDivBlack_p: {
      display: "inline-block",
      fontSize: "20px",
      color: "#828282",
      fontWeight: 600
    }
  }));
  const classes = useStyles1();

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.newExample) {
      setExample(true);
    } else {
      setExample(false);
    }
    if (parsed.id) {
      setId(parsed.id);
    }
    if (parsed.name) {
      setName(parsed.name);
    }
    if (parsed.view) {
      setView(true);
    }
    if (parsed.viewEbook) {
      setViewEbookModal(true);
    }
    if (parsed.ebookId) {
      setEbookId(parsed.ebookId);
    }
    if (parsed.edit) {
      setEdit(true);
    }
    if (parsed.delete) {
      setDelete(true);
    }
    if (parsed.block) {
      setBlock(true);
    }
    if (parsed.unblock) {
      setUnblock(true);
    }
  }, [location.search]);

  const catalogueUIEvents = {
    newCatalogueButtonClick: () => {
      history.push(`/catalogue-example?newExample=true`);
    },
    viewPublishersButtonClick: () => {
      history.push(
        `/catalogue-detail?id=${parsed_.id}&newEbook=true&publishers=true`
      );
    },
    viewNewEbooksButtonClick: () => {
      history.push(
        `/catalogue-detail?id=${parsed_.id}&newEbook=true&newEbooks=true`
      );
    },
    viewEbooksButtonClick: (uid, type, subcatId) => {
      if (type === "publisher") {
        history.push(
          `/catalogue-detail?id=${parsed_.id}&newEbook=true&ebooks=true&publisherId=${uid}`
        );
      } else if (type === "subcategory") {
        history.push(
          `/catalogue-detail?id=${parsed_.id}&newEbook=true&ebooks=true&catId=${uid}&subcatId=${subcatId}`
        );
      }
    },
    viewCategoriesButtonClick: (catId, isSubcats) => {
      if (isSubcats) {
        history.push(
          `/catalogue-detail?id=${parsed_.id}&newEbook=true&catId=${catId}&subcategories=true`
        );
      } else {
        history.push(
          `/catalogue-detail?id=${parsed_.id}&newEbook=true&categories=true`
        );
      }
    },
    openEditCatalogueDialog: id => {
      history.push(`/catalogue-detail?id=${id}&edit=true`);
    },
    openViewCatalogueEbookDialog: ebookId => {
      if (parsed_.newEbook && parsed_.ebooks) {
        if (parsed_.publisherId) {
          history.push(
            `/catalogue-detail?id=${id}&newEbook=true&ebooks=true&publisherId=${parsed_.publisherId}&ebookId=${ebookId}&viewEbook=true`
          );
        } else if (parsed_.catId && parsed_.subcatId) {
          history.push(
            `/catalogue-detail?id=${id}&newEbook=true&ebooks=true&catId=${parsed_.catId}&subcatId=${parsed_.subcatId}&ebookId=${ebookId}&viewEbook=true`
          );
        }
      } else {
        history.push(
          `/catalogue-detail?id=${id}&ebookId=${ebookId}&viewEbook=true`
        );
      }
    },
    openDeleteCatalogueDialog: (name, id) => {
      history.push(
        `/catalogue-detail?name=${encodeURIComponent(
          name
        )}&id=${id}&delete=true`
      );
    },
    openBlockCatalogueDialog: (name, id) => {
      history.push(
        `/catalogue-detail?name=${encodeURIComponent(name)}&id=${id}&block=true`
      );
    },
    openUnblockCatalogueDialog: (name, id) => {
      history.push(
        `/catalogue-detail?name=${encodeURIComponent(
          name
        )}&id=${id}&unblock=true`
      );
    },
    openFetchCatalogueDialog: () => {
      history.push(`/catalogue-detail/fetch`);
    },
    openUpdateCatalogueStatusDialog: () => {
      history.push("/catalogue-detail/updateStatus");
    }
  };

  const handleSubmit = () => {
    history.push("/catalogue-example");
  };
  const stepLabel = ["อัปโหลดไฟล์"];

  return (
    <CatalogueUIProvider catalogueUIEvents={catalogueUIEvents}>
      {isExample ? (
        <>
          <div className="row">
            {stepLabel.map((el, index) => (
              <div
                key={index}
                className={`col-3 ${
                  index + 1 === step ? classes.stepDiv : classes.stepDivBlack
                }`}
              >
                <p
                  className={
                    index + 1 === step
                      ? classes.stepIndex
                      : classes.stepIndexBlack
                  }
                >
                  {index + 1}
                </p>
                <p
                  className={
                    index + 1 === step
                      ? classes.stepDiv_p
                      : classes.stepDivBlack_p
                  }
                >
                  {el}
                </p>
              </div>
            ))}
          </div>
          <FileAndCover handleSubmitOutside={handleSubmit} isExample={true} />
        </>
      ) : (
        <CatalogueCard
          isPrivate={1}
          isFree={0}
          isExample={true}
          isNewEbook={true}
          isEbooks={true}
        />
      )}
      {isEdit ? (
        <CatalogueEditDialog
          show={true}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push(`/catalogue-detail?id=${parsed_.id}`);
            setEdit(false);
          }}
        />
      ) : (
        ""
      )}
      {isView ? (
        <CatalogueViewDialog
          show={true}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push(`/catalogue-detail?id=${parsed_.id}`);
            setView(false);
          }}
        />
      ) : (
        ""
      )}
      {isDelete ? (
        <CatalogueDeleteDialog
          show={true}
          name={decodeURIComponent(name)}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push(`/catalogue-detail?id=${parsed_.id}`);
            setDelete(false);
          }}
        />
      ) : (
        ""
      )}
      {isUnblock ? (
        <CatalogueUnblockDialog
          show={true}
          name={decodeURIComponent(name)}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push(`/catalogue-detail?id=${parsed_.id}`);
            setUnblock(false);
          }}
        />
      ) : (
        ""
      )}
      {isBlock ? (
        <CatalogueBlockDialog
          show={true}
          name={decodeURIComponent(name)}
          id={id}
          isPrivate={1}
          isFree={0}
          onHide={() => {
            history.push(`/catalogue-detail?id=${parsed_.id}`);
          }}
        />
      ) : (
        ""
      )}
      {viewEbookModal ? (
        <EbookViewDialog
          show={true}
          id={ebookId}
          isPrivate={0}
          isFree={0}
          onHide={() => {
            if (parsed_.newEbook && parsed_.ebooks) {
              if (parsed_.publisherId) {
                history.push(
                  `/catalogue-detail?id=${parsed_.id}&newEbook=true&ebooks=true&publisherId=${parsed_.publisherId}`
                );
              } else if (parsed_.catId && parsed_.subcatId) {
                history.push(
                  `/catalogue-detail?id=${parsed_.id}&newEbook=true&ebooks=true&catId=${parsed_.catId}&subcatId=${parsed_.subcatId}`
                );
              }
            } else {
              history.push(`/catalogue-detail?id=${parsed_.id}`);
            }
            setViewEbookModal(false);
            setEbookId(null);
          }}
          catalogueId={id}
        />
      ) : (
        ""
      )}
    </CatalogueUIProvider>
  );
}
