/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { ChannelsTable } from "./channels-table/ChannelsTable";
import { ChannelsGrouping } from "./channels-grouping/ChannelsGrouping";
import { useChannelsUIContext } from "./ChannelsUIContext";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useSelector } from "react-redux";
import { ChannelsFilter } from "./channels-filter/ChannelsFilter";
import { Role } from "../../../../../interface";

export function ChannelsCard({ id, name, isCreator, onHide }) {
  const channelsUIContext = useChannelsUIContext();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(1);
  const anchorRef = React.useRef(null);

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  const channelsUIProps = useMemo(() => {
    return {
      ids: channelsUIContext.ids,
      newChannelButtonClick: channelsUIContext.newChannelButtonClick,
      newCreatorButtonClick: channelsUIContext.newCreatorButtonClick,
    };
  }, [channelsUIContext]);
  const { role } = useSelector((state) => state.auth);
  const useStyles1 = makeStyles((theme) => ({
    div: {
      marginBottom: -22,
    },

    button: {
      borderRadius: 100,
      backgroundColor: "#012875",
      marginLeft: 8,
      padding: "10px 22px",
      height: 40,
      color: "white",
      fontSize: 12,
      fontWeight: 600,
      fontFamily: "Prompt",
      border: "none",
    },

    textHead: {
      color: "#012875",
      fontSize: "18px",
      marginBottom: "0",
      height: "40px",
      display: "flex",
      alignItems: "center",
    },
  }));
  const classes = useStyles1();

  const addButton = (
    <div style={{ display: "inline-block" }}>
      <button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={() => channelsUIContext.newChannelButtonClick(1)}
        className={classes.button}
      >
        <span>เพิ่ม Channel</span>
      </button>
      {/* <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-start"
        // disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  style={{ paddingTop: "4px", paddingBottom: "0px" }}
                >
                  <MenuItem
                    className={classes.dropdown1}
                    onClick={() => channelsUIContext.newChannelButtonClick(value, id, name)}
                  >
                    <span>เพิ่ม Channel</span>
                  </MenuItem>
                  <MenuItem
                    className={classes.dropdown2}
                    onClick={() => channelsUIContext.newCreatorButtonClick(value, id, name)}
                  >
                    <span>เพิ่ม Creator</span>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper> */}
    </div>
  );
  const stepLabel = ["Hibrary", "วิดีโอ"];
  const [listLoading, setListLoading] = useState(false);

  return (
    <>
      <div className="row">
        {/*stepLabel.map((el, index) => {
          if (index === 1) {
            return (
              <div
                key={index}
                className={`col-2 ${
                  index === value
                    ? classes.stepDiv
                    : listLoading
                    ? classes.stepDivBlack_wait
                    : isCreator
                    ? classes.stepDivBlack_notAllowed
                    : classes.stepDivBlack
                }`}
                onClick={() => {
                  if (!listLoading && !isCreator) handleChange(index);
                }}
              >
                <p
                  className={
                    index === value ? classes.stepDiv_p : classes.stepDivBlack_p
                  }
                >
                  {el}
                </p>
              </div>
            );
          }
        })*/}
      </div>
      <Card className="cardStyle">
        <CardHeader
          title={
            <p className={classes.textHead}>
              {isCreator ? name : "Channel ทั้งหมด"}
            </p>
          }
        >
          <CardHeaderToolbar>
            <ChannelsFilter role={role} />
            {role === Role.SuperAdmin ? (
              ""
            ) : (
              <div className="row">{value ? addButton : ""}</div>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody className="cardBodyStyle">
          {channelsUIProps.ids.length > 0 && <ChannelsGrouping />}
          <ChannelsTable
            channelId={id}
            channelName={name}
            isCreator={isCreator}
            isPrivate={value}
            setListLoading={setListLoading}
          />
        </CardBody>
      </Card>
    </>
  );
}
