/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../_redux/channels-podcast/channelsActions';
import { ClipLoader } from 'react-spinners';
import * as uiHelpers from '../ChannelsUIHelpers';
import * as columnFormatters from './column-formatters';
import { useChannelsUIContext } from '../ChannelsUIContext';
import { channelsSlice } from '../../../_redux/channels-podcast/channelsSlice';
import { Empty } from 'antd';
const actions1 = channelsSlice.actions;

export function ChannelsTable({
   channelId,
   channelName,
   isCreator,
   isPrivate,
   setListLoading
}) {
   // Channels UI Context
   const channelsUIContext = useChannelsUIContext();
   const channelsUIProps = useMemo(() => {
      return {
         ids: channelsUIContext.ids,
         setIds: channelsUIContext.setIds,
         queryParams: channelsUIContext.queryParams,
         setQueryParams: channelsUIContext.setQueryParams,
         openEditCreatorDialog: channelsUIContext.openEditCreatorDialog,
         openEditChannelDialog: channelsUIContext.openEditChannelDialog,
         openViewChannelDialog: channelsUIContext.openViewChannelDialog,
         openDeleteCreatorDialog: channelsUIContext.openDeleteCreatorDialog,
         openDeleteChannelDialog: channelsUIContext.openDeleteChannelDialog,
         openBlockCreatorDialog: channelsUIContext.openBlockCreatorDialog,
         openBlockChannelDialog: channelsUIContext.openBlockChannelDialog,
         openUnblockCreatorDialog: channelsUIContext.openUnblockCreatorDialog,
         openUnblockChannelDialog: channelsUIContext.openUnblockChannelDialog,
         openCreatorsPage: channelsUIContext.openCreatorsPage,
         openCreatorBooksPage: channelsUIContext.openCreatorBooksPage
      };
   }, [channelsUIContext]);

   // Getting current state of channels list from store (Redux)
   const { currentState, role } = useSelector(
      state => ({ currentState: state.channelsPodcast, role: state.auth.role }),
      shallowEqual
   );
   const {
      totalCount,
      entities,
      listLoading,
      creatorsForEdit,
      channelIdOfCreators,
      LastEvaluatedKey,
      error
   } = currentState;
   const [firstLoaded, setFirstLoaded] = useState(false);
   const [isError, setError] = useState(false);
   const [errorType, setErrorType] = useState(null);
   const [isEmpty, setEmpty] = useState(false);
   const loaderHeight = 50;

   useEffect(() => {
      if (error && error.errorCode === '011') {
         setError(true);
         setErrorType(error.from);
      }
   }, [error]);

   useEffect(() => {
      setListLoading(listLoading);
   }, [listLoading]);

   // useEffect(() => {
   //   if (!entities) {
   //     dispatch(actions.fetchChannels(channelsUIProps.queryParams, isPrivate, LastEvaluatedKey));
   //   }
   // }, [entities]);

   useEffect(() => {
      if (
         LastEvaluatedKey === undefined ||
         JSON.stringify(LastEvaluatedKey) === JSON.stringify({})
      ) {
         setEmpty(true);
      } else {
         setEmpty(false);
         dispatch(
            actions.fetchChannels(
               channelsUIProps.queryParams,
               isPrivate,
               LastEvaluatedKey
            )
         );
      }
   }, [LastEvaluatedKey]);

   // Channels Redux state
   const dispatch = useDispatch();
   useEffect(() => {
      // clear selections list
      channelsUIProps.setIds([]);
      // server call by queryParams
      if (isCreator) {
         dispatch(
            actions.fetchCreators(
               channelsUIProps.queryParams,
               channelId,
               isPrivate
            )
         );
         if (!firstLoaded) {
            dispatch(
               actions.fetchChannels(channelsUIProps.queryParams, isPrivate)
            );
            setFirstLoaded(true);
         }
      } else {
         dispatch(
            actions.fetchChannels(channelsUIProps.queryParams, isPrivate)
         );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [channelsUIProps.queryParams, dispatch, isPrivate, channelId, isCreator]);

   useEffect(() => {
      return () => {
         dispatch(
            actions1.channelsFetched({
               totalCount: 0,
               entities: [],
               LastEvaluatedKey: {}
            })
         );
      };
   }, []);

   useEffect(() => {
      return () => {
         if (!isCreator) {
            dispatch(actions1.creatorsFetched({ creatorsForEdit: undefined }));
         }
      };
   }, [isCreator]);

   const errorTypeWording = type => {
      if (type) {
         switch (type) {
            case 'delete':
               return 'ลบ';
            case 'block':
               return 'บล็อก';
            case 'unblock':
               return 'ปลดบล็อก';
            case 'setStatus':
               return 'เปลี่ยนสถานะ';
            case 'update':
               return 'เปลี่ยนชื่อ';
            default:
               return 'อัปเดท';
         }
      }
   };

   const cardOfEle = arr => {
      return arr && Array.isArray(arr) && arr.length ? (
         arr.map((el, index) => {
            return (
               <div key={index} className="col-6">
                  <div
                     className="row"
                     style={{
                        margin: '12px 0px',
                        height: '95px',
                        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.3)',
                        borderRadius: '10px'
                     }}
                  >
                     <div
                        className="col-1"
                        style={{
                           display: 'flex',
                           alignItems: 'center',
                           fontSize: '16px'
                        }}
                     >
                        <p style={{ margin: 'auto' }}>{index + 1}.</p>
                     </div>
                     <div
                        className={`col-${isCreator ? 6 : 4}`}
                        style={{
                           display: 'block',
                           alignItems: 'center',
                           fontSize: '16px',
                           margin: 'auto',
                           padding: '0px'
                        }}
                     >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                           <img
                              src={el.image}
                              alt=""
                              style={{
                                 width: '48px',
                                 height: '48px',
                                 imageRendering: '-webkit-optimize-contrast',
                                 borderRadius: '50%',
                                 marginRight: '12px',
                                 boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)'
                              }}
                           />
                           {el.name}
                        </div>
                     </div>
                     <div
                        className={`col-${isCreator ? 5 : 7}`}
                        style={{
                           display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'flex-end'
                        }}
                     >
                        {columnFormatters.ActionsColumnFormatter(
                           el.uid,
                           el,
                           index,
                           {
                              ele: entities,
                              channelName,
                              isPrivate,
                              isCreator,
                              channelIdOfCreators: channelIdOfCreators,
                              openEditCreatorDialog:
                                 channelsUIProps.openEditCreatorDialog,
                              openEditChannelDialog:
                                 channelsUIProps.openEditChannelDialog,
                              openBlockCreatorDialog:
                                 channelsUIProps.openBlockCreatorDialog,
                              openBlockChannelDialog:
                                 channelsUIProps.openBlockChannelDialog,
                              openUnblockCreatorDialog:
                                 channelsUIProps.openUnblockCreatorDialog,
                              openUnblockChannelDialog:
                                 channelsUIProps.openUnblockChannelDialog,
                              openDeleteCreatorDialog:
                                 channelsUIProps.openDeleteCreatorDialog,
                              openDeleteChannelDialog:
                                 channelsUIProps.openDeleteChannelDialog,
                              openCreatorsPage:
                                 channelsUIProps.openCreatorsPage,
                              openCreatorBooksPage:
                                 channelsUIProps.openCreatorBooksPage,
                              role
                           }
                        )}
                     </div>
                  </div>
               </div>
            );
         })
      ) : !Array.isArray(arr) ||
        (Array.isArray(arr) && arr.length === 0 && !listLoading) ? (
         <div className="div-isNotFound">
            {channelsUIProps.queryParams.keyword ? (
               <img src="/media/members/no-search-result.png" alt="" />
            ) : (
               <Empty description={false} />
            )}
            <p
               style={{
                  color: '#012875',
                  marginBottom: '0',
                  fontSize: '20px',
                  fontWeight: '600'
               }}
            >
               {channelsUIProps.queryParams.keyword
                  ? 'ไม่พบรายการที่ค้นหา'
                  : 'ไม่มีข้อมูล'}
            </p>
         </div>
      ) : (
         ''
      );
   };
   // Table pagination properties
   const paginationOptions = {
      custom: true,
      totalSize: totalCount,
      sizePerPageList: uiHelpers.sizePerPageList,
      sizePerPage: channelsUIProps.queryParams.pageSize,
      page: channelsUIProps.queryParams.pageNumber,
      onPageChange: () => {
         window.scrollTo(0, 0);
      }
   };

   return (
      <>
         <div className="channelsTable">
            {isCreator ? cardOfEle(creatorsForEdit) : cardOfEle(entities)}
            {listLoading || (LastEvaluatedKey !== undefined && !isEmpty) ? (
               <div className="cliploader-center">
                  <ClipLoader
                     size={loaderHeight}
                     color={'#123abc'}
                     loading={true}
                  />
               </div>
            ) : !error && !isEmpty ? (
               <div style={{ minHeight: `${loaderHeight}px` }} />
            ) : (
               ''
            )}
         </div>
         <Modal
            backdrop="static"
            show={isError}
            onHide={() => {
               setError(false);
            }}
            aria-labelledby="example-modal-sizes-title-lg"
            style={{ margin: '3% auto' }}
            size="md"
         >
            <Modal.Body
               style={{
                  textAlign: 'center'
               }}
            >
               <img src="/media/members/image-modal.svg" alt="" />
               <div
                  style={{
                     fontSize: '20px',
                     fontWeight: 600,
                     color: '#d7042b',
                     marginBottom: '12px'
                  }}
               >
                  {isCreator ? (
                     <>
                        <p style={{ marginBottom: '0px' }}>
                           ไม่สามารถ{errorTypeWording(errorType)} Creator ได้
                        </p>
                        <p>เนื่องจากมีการใช้งาน Creator อยู่</p>
                     </>
                  ) : (
                     <>
                        <p style={{ marginBottom: '0px' }}>
                           ไม่สามารถ{errorTypeWording(errorType)} Channel ได้
                        </p>
                        <p>เนื่องจากมีการใช้งาน Channel อยู่</p>
                     </>
                  )}
               </div>
               <div style={{ marginTop: '36px' }}>
                  <button
                     type="button"
                     onClick={() => {
                        setError(false);
                     }}
                     style={{
                        margin: '8px',
                        color: '#ffffff',
                        fontSize: '18px',
                        fontWeight: 600,
                        width: '160px',
                        height: '48px',
                        borderRadius: '100px',
                        backgroundColor: '#012875',
                        border: 'none'
                     }}
                  >
                     ตกลง
                  </button>
               </div>
            </Modal.Body>
         </Modal>
      </>
   );
}
