/* eslint-disable react-hooks/exhaustive-deps */
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../_redux/customers/qrcodesActions';
import { qrcodesSlice } from '../../../_redux/customers/qrcodesSlice';
import { ClipLoader } from 'react-spinners';
import {
   getHandlerTableChange,
   NoRecordsFoundMessage,
   PleaseWaitMessage,
   sortCaret,
   headerSortingClasses
} from '../../../../../../_metronic/_helpers';
import * as uiHelpers from '../CustomersUIHelpers';
import * as columnFormatters from './column-formatters';
import { useCustomersUIContext } from '../CustomersUIContext';
import { Empty } from 'antd';
const actions1 = qrcodesSlice.actions;

export function QrcodeMembersTable({ qrcodeId }) {
   // Customers UI Context
   const customersUIContext = useCustomersUIContext();
   const customersUIProps = useMemo(() => {
      return {
         ids: customersUIContext.ids,
         setIds: customersUIContext.setIds,
         queryParams: customersUIContext.queryParams,
         setQueryParams: customersUIContext.setQueryParams,
         openQrcodeMembersMapDialog:
            customersUIContext.openQrcodeMembersMapDialog
      };
   }, [customersUIContext]);

   // Getting current state of customers list from store (Redux)
   const { currentState } = useSelector(
      state => ({ currentState: state.qrcodes }),
      shallowEqual
   );
   const {
      entities_members,
      listLoading,
      LastEvaluatedKey,
      error
   } = currentState;
   const entities = entities_members;
   const [isEmpty, setEmpty] = useState(false);
   const loaderHeight = 50;
   const footerHeight = 300;
   const [loadingBottom, setLoadingBottom] = useState(false);

   // const isBottom = () => {
   //   let scrollHeight = Math.max(
   //     document.body.scrollHeight,
   //     document.documentElement.scrollHeight,
   //     document.body.offsetHeight,
   //     document.documentElement.offsetHeight,
   //     document.body.clientHeight,
   //     document.documentElement.clientHeight
   //   );
   //   return (
   //     window.pageYOffset + window.innerHeight >= scrollHeight - 3 * loaderHeight - footerHeight
   //   );
   // };

   const trackScrolling = () => {
      if (isBottom()) {
         setLoadingBottom(true);
      }
   };

   // useEffect(() => {
   //   document.addEventListener("scroll", trackScrolling);
   //   // eslint-disable-next-line
   // }, []);

   useEffect(() => {
      return () => {
         dispatch(
            actions1.qrcodeMembersFetched({
               entities_members: [],
               LastEvaluatedKey: undefined,
               lastEvaluate: undefined
            })
         );
         // document.removeEventListener("scroll", trackScrolling);
      };
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      if (
         LastEvaluatedKey === undefined ||
         JSON.stringify(LastEvaluatedKey) === JSON.stringify({})
      ) {
         setEmpty(true);
      } else if (loadingBottom && qrcodeId && !listLoading) {
         setEmpty(false);
         dispatch(
            actions.fetchQrcodeMembers(
               qrcodeId,
               customersUIProps.queryParams,
               LastEvaluatedKey,
               setLoadingBottom
            )
         );
      }
   }, [LastEvaluatedKey, loadingBottom, listLoading]);

   // Customers Redux state
   const dispatch = useDispatch();
   useEffect(() => {
      if (qrcodeId) {
         dispatch(
            actions.fetchQrcodeMembers(qrcodeId, customersUIProps.queryParams)
         );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [customersUIProps.queryParams, dispatch, qrcodeId]);

   const [loadingCount, setLoadingCount] = useState(0);
   const minWidthOfAll = 750;
   const minusOfAll = 380;
   const [tableWidth, setTableWidth] = useState(
      Math.max(
         document.body.scrollWidth,
         document.documentElement.scrollWidth,
         document.body.offsetWidth,
         document.documentElement.offsetWidth,
         document.documentElement.clientWidth
      ) <
         minWidthOfAll + minusOfAll
         ? minWidthOfAll
         : Math.max(
              document.body.scrollWidth,
              document.documentElement.scrollWidth,
              document.body.offsetWidth,
              document.documentElement.offsetWidth,
              document.documentElement.clientWidth
           ) - minusOfAll
   );

   useEffect(() => {
      if (entities && entities.length > -1 && loadingCount < 2) {
         setLoadingCount(loadingCount + 1);
      }
   }, [entities]);

   const isBottom = () => {
      const getScroll = document
         .getElementById('custom-table-scroll')
         .getElementsByTagName('tbody')[0];
      const offsetHeight = Math.ceil(getScroll.offsetHeight);
      const scrollTop = Math.ceil(getScroll.scrollTop);
      if (
         !listLoading &&
         !loadingBottom &&
         getScroll.scrollHeight - (scrollTop + offsetHeight) <= 1
      ) {
         return true;
      } else {
         return false;
      }
   };

   const resize = () => {
      if (
         document.getElementById('custom-table-scroll') &&
         loadingCount === 2
      ) {
         setTableWidth(
            document
               .getElementById('custom-table-scroll')
               .getBoundingClientRect().width < minWidthOfAll
               ? minWidthOfAll
               : document
                    .getElementById('custom-table-scroll')
                    .getBoundingClientRect().width
         );
      }
   };

   useEffect(() => {
      window.addEventListener('resize', resize);
      if (
         document.getElementById('custom-table-scroll') &&
         document
            .getElementById('custom-table-scroll')
            .getElementsByTagName('tbody')[0] &&
         loadingCount === 2
      ) {
         document
            .getElementById('custom-table-scroll')
            .getElementsByTagName('tbody')[0]
            .addEventListener('scroll', trackScrolling);
      }
      // eslint-disable-next-line
   }, [loadingCount]);

   useEffect(() => {
      return () => {
         window.removeEventListener('resize', resize);
         setLoadingCount(0);
         if (
            document.getElementById('custom-table-scroll') &&
            document
               .getElementById('custom-table-scroll')
               .getElementsByTagName('tbody')[0]
         ) {
            document
               .getElementById('custom-table-scroll')
               .getElementsByTagName('tbody')[0]
               .removeEventListener('scroll', trackScrolling);
         }
      };
      // eslint-disable-next-line
   }, []);

   const widthOfAll = 70 + 300 + 185 + 215;

   // Table columns
   const columns = [
      {
         dataField: 'none',
         text: 'ลำดับ',
         sortCaret: sortCaret,
         headerSortingClasses,
         formatter: columnFormatters.IndexColumnFormatter,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
                  style={{
                     textAlign: 'center',
                     fontSize: '12px',
                     maxWidth: `${(70 / widthOfAll) * tableWidth}px`,
                     minWidth: `${(70 / widthOfAll) * tableWidth}px`,
                     margin: '-0.75rem'
                  }}
               >
                  {column.text}
               </div>
            );
         },
         style: {
            maxWidth: `${(70 / widthOfAll) * tableWidth}px`,
            minWidth: `${(70 / widthOfAll) * tableWidth}px`
         },
         formatExtraData: {
            pageNumber: customersUIProps.queryParams.pageNumber,
            pageSize: customersUIProps.queryParams.pageSize
         }
      },
      {
         dataField: 'email',
         text: 'อีเมล',
         // sort: true,
         sortCaret: sortCaret,
         headerSortingClasses,
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
                  style={{
                     textAlign: 'left',
                     fontSize: '12px',
                     maxWidth: `${(300 / widthOfAll) * tableWidth}px`,
                     minWidth: `${(300 / widthOfAll) * tableWidth}px`,
                     margin: '-0.75rem',
                     paddingLeft: '0.75rem'
                  }}
               >
                  {column.text}
               </div>
            );
         },
         style: {
            maxWidth: `${(300 / widthOfAll) * tableWidth}px`,
            minWidth: `${(300 / widthOfAll) * tableWidth}px`
         }
      },
      {
         dataField: 'registDate',
         text: 'วัน/เวลาที่ลงทะเบียน',
         sortCaret: sortCaret,
         formatter: columnFormatters.TimestampColumnFormatter,
         headerSortingClasses,
         align: 'left',
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
                  style={{
                     textAlign: 'left',
                     fontSize: '12px',
                     maxWidth: `${(185 / widthOfAll) * tableWidth}px`,
                     minWidth: `${(185 / widthOfAll) * tableWidth}px`,
                     margin: '-0.75rem',
                     paddingLeft: '0.75rem'
                  }}
               >
                  {column.text}
               </div>
            );
         },
         style: {
            maxWidth: `${(185 / widthOfAll) * tableWidth}px`,
            minWidth: `${(185 / widthOfAll) * tableWidth}px`
         }
      },
      {
         dataField: 'action',
         text: 'การจัดการ',
         formatter: columnFormatters.ActionsColumnFormatter,
         formatExtraData: {
            openQrcodeMembersMapDialog:
               customersUIProps.openQrcodeMembersMapDialog
         },
         classes: 'text-center',
         headerClasses: 'text-center pr-3',
         headerFormatter: (
            column,
            colIndex,
            { sortElement, filterElement }
         ) => {
            return (
               <div
                  style={{
                     textAlign: 'center',
                     fontSize: '12px',
                     maxWidth: `${(215 / widthOfAll) * tableWidth}px`,
                     minWidth: `${(215 / widthOfAll) * tableWidth}px`,
                     margin: '-0.75rem'
                  }}
               >
                  {column.text}
               </div>
            );
         },
         style: {
            maxWidth: `${(215 / widthOfAll) * tableWidth}px`,
            minWidth: `${(215 / widthOfAll) * tableWidth}px`
         }
      }
   ];

   // Table pagination properties
   // const paginationOptions = {
   //   custom: true,
   //   totalSize: totalCount,
   //   sizePerPageList: uiHelpers.sizePerPageList,
   //   sizePerPage: customersUIProps.queryParams.pageSize,
   //   page: customersUIProps.queryParams.pageNumber,
   // };
   const isNotFound = () => {
      return (
         entities &&
         Array.isArray(entities) &&
         entities.length === 0 &&
         !listLoading
      );
   };
   return (
      <>
         {/* // <PaginationProvider pagination={paginationFactory(paginationOptions)}>
    //   {({ paginationProps, paginationTableProps }) => {
    //     return (
    //       <Pagination isLoading={listLoading} paginationProps={paginationProps}> */}
         <BootstrapTable
            wrapperClasses="table-responsive"
            bordered={false}
            classes={`table table-head-custom table-vertical-center custom-table-scroll qrcodeMembersTable ${
               isNotFound() ? 'isNotFound' : ''
            }`}
            id="custom-table-scroll"
            bootstrap4
            remote
            keyField="qrcodeUID"
            data={!Array.isArray(entities) ? [] : entities}
            columns={columns}
            defaultSorted={uiHelpers.defaultSorted}
            onTableChange={getHandlerTableChange(
               customersUIProps.setQueryParams
            )}
            // selectRow={getSelectRow({
            //   entities,
            //   ids: customersUIProps.ids,
            //   setIds: customersUIProps.setIds,
            // })}
            // {...paginationTableProps}
         >
            <PleaseWaitMessage entities={entities} />
            <NoRecordsFoundMessage entities={entities} />
         </BootstrapTable>
         {isNotFound() ? (
            <div className="div-isNotFound">
               <Empty description={false} />
               <p
                  style={{
                     color: '#012875',
                     marginBottom: '0',
                     fontSize: '20px',
                     fontWeight: '600'
                  }}
               >
                  ไม่มีข้อมูล
               </p>
            </div>
         ) : (
            ''
         )}
         {/* //       </Pagination>
    //     );
    //   }}
    // </PaginationProvider> */}
         {!error &&
         listLoading &&
         LastEvaluatedKey !== undefined &&
         (!isEmpty || loadingBottom) ? (
            <div className="cliploader-center">
               <ClipLoader
                  size={loaderHeight}
                  color={'#123abc'}
                  loading={true}
               />
            </div>
         ) : !error && !isEmpty ? (
            // <div style={{ minHeight: `${loaderHeight}px` }} />
            ''
         ) : (
            ''
         )}
      </>
   );
}
