import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/customers/customersActions";
import { useCustomersUIContext } from "../CustomersUIContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
     body: {
          textAlign: "center",
     },
}));

export function CustomerResetPwDialog({ id, show, onHide }) {
     // Customers UI Context
     const customersUIContext = useCustomersUIContext();
     const customersUIProps = useMemo(() => {
          return {
               setIds: customersUIContext.setIds,
               queryParams: customersUIContext.queryParams,
          };
     }, [customersUIContext]);

     // Customers Redux state
     const dispatch = useDispatch();
     const { isLoading } = useSelector((state) => ({ isLoading: state.customers.actionsLoading }), shallowEqual);

     // if !id we should close modal
     useEffect(() => {
          if (!id) {
               onHide();
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [id]);

     // looking for loading/dispatch
     useEffect(() => {}, [isLoading, dispatch]);

     const resetPwCustomer = () => {
          // server request for deleting customer by id
          dispatch(actions.resetPwCustomer(id)).then(() => {
               // refresh list after deletion
               dispatch(actions.fetchCustomers(customersUIProps.queryParams));
               // clear selections list
               customersUIProps.setIds([]);
               // closing resetpw modal
               onHide();
          });
     };

     const classes = useStyles();

     return (
          <Modal
               backdrop="static"
               show={show}
               onHide={onHide}
               aria-labelledby="example-modal-sizes-title-lg"
               style={{ margin: "3% auto" }}
          >
               {/*begin::Loading*/}
               {isLoading && <ModalProgressBar />}
               {/*end::Loading*/}
               {/* <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Customer Resetpw</Modal.Title>
      </Modal.Header> */}
               <Modal.Body className={classes.body}>
                    <img src="/media/members/image-modal.svg" alt="" />
                    {!isLoading && (
                         <>
                              <div className="text-text-red">ยืนยันการรีเซ็ตรหัสผ่าน</div>
                              <div className="text-text-gray">ต้องการรีเซ็ตรหัสผ่าน อีเมล {id} หรือไม่?</div>
                         </>
                    )}
                    {isLoading && <span>กำลังรีเซ็ตรหัสผ่าน...</span>}
                    <div className="mt-3">
                         <button type="button" onClick={onHide} className="denyButton">
                              ยกเลิก
                         </button>
                         <button type="button" onClick={resetPwCustomer} className="acceptButton">
                              ยืนยัน
                         </button>
                    </div>
               </Modal.Body>
               {/* <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            Cancel
          </button>
          <> </>
          <button type="button" onClick={resetPwCustomer} className="btn btn-primary btn-elevate">
            ResetPw
          </button>
        </div>
      </Modal.Footer> */}
          </Modal>
     );
}
