import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/publishers/publishersActions";
import { usePublishersUIContext } from "../PublishersUIContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  body: {
    textAlign: "center",
  },
  title: {
    fontSize: "20px",
    fontWeight: 600,
    color: "#d7042b",
  },
  subtitle: {
    fontFamily: "Prompt",
    fontSize: "16px",
  },
  divButton: {
    marginTop: "36px",
  },
  acceptButton: {
    margin: "8px",
    color: "#ffffff",
    fontSize: "18px",
    fontWeight: 600,
    width: "160px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "#012875",
    border: "none",
  },
  denyButton: {
    margin: "8px",
    color: "#012875",
    fontSize: "18px",
    fontWeight: 600,
    width: "160px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "#ffffff",
    border: "solid 1px #012875",
  },
}));

export function PublisherBlockDialog({ name, id, publisherId, authorId, isPrivate, show, onHide }) {
  // Publishers UI Context
  const publishersUIContext = usePublishersUIContext();
  const publishersUIProps = useMemo(() => {
    return {
      setIds: publishersUIContext.setIds,
      queryParams: publishersUIContext.queryParams,
    };
  }, [publishersUIContext]);
  const [isAuthor, setIsAuthor] = useState(false);

  useEffect(() => {
    if (publisherId && authorId) {
      setIsAuthor(true);
    }
  }, [publisherId, authorId]);

  // Publishers Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.publishers.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      // onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const blockPublisher = () => {
    // server request for deleting publisher by id
    dispatch(actions.blockPublisher(name, id, isPrivate)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchPublishers(publishersUIProps.queryParams, isPrivate));
      // clear selections list
      publishersUIProps.setIds([]);
      // closing block modal
      onHide();
    });
  };

  const blockAuthor = () => {
    dispatch(actions.blockAuthor(name, publisherId, authorId, isPrivate)).then(() => {
      dispatch(actions.fetchAuthors(publishersUIProps.queryParams, publisherId, isPrivate));
      publishersUIProps.setIds([]);
      onHide();
    });
  };

  const classes = useStyles();

  return (
    <Modal
    backdrop="static"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      style={{ margin: "3% auto" }}
    >
      {isLoading && <ModalProgressBar />}
      <Modal.Body className={classes.body}>
        {name ? (
          <>
            <img src="/media/members/image-modal.svg" alt="" />
            {!isLoading ? (
              <>
                <div className="text-text-red">ยืนยันการบล็อก</div>
                <div className="text-text-gray">ต้องการบล็อก  {name} หรือไม่?</div>
          
                <div className="mt-3">
                  <button type="button" onClick={onHide} className="denyButton">
                    ยกเลิก
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      if (isAuthor) {
                        blockAuthor();
                      } else {
                        blockPublisher();
                      }
                    }}
                    className="acceptButton"
                  >
                    ยืนยัน
                  </button>
                </div>
              </>
            ) : (
              <div className="text-text-gray" style={{ marginBottom: "36px" }}>
                กำลังบล็อก...
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </Modal.Body>
    </Modal>
  );
}
