import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/ebooks/ebooksActions";
import { useEbooksUIContext } from "../EbooksUIContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  body: {
    textAlign: "center",
  },
  title: {
    fontSize: "20px",
    fontWeight: 600,
    color: "#d7042b",
  },
  subtitle: {
    fontFamily: "Prompt",
    fontSize: "16px",
  },
  divButton: {
    marginTop: "36px",
  },
  acceptButton: {
    margin: "8px",
    color: "#ffffff",
    fontSize: "18px",
    fontWeight: 600,
    width: "160px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "#012875",
    border: "none",
  },
  denyButton: {
    margin: "8px",
    color: "#012875",
    fontSize: "18px",
    fontWeight: 600,
    width: "160px",
    height: "48px",
    borderRadius: "100px",
    backgroundColor: "#ffffff",
    border: "solid 1px #012875",
  },
}));

export function EbookBlockDialog({ name, id, isPrivate, show, onHide, isFree }) {
  // Ebooks UI Context
  const ebooksUIContext = useEbooksUIContext();
  const ebooksUIProps = useMemo(() => {
    return {
      setIds: ebooksUIContext.setIds,
      queryParams: ebooksUIContext.queryParams,
    };
  }, [ebooksUIContext]);

  // Ebooks Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.ebooks.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const blockEbook = () => {
    // server request for deleting ebook by id
    dispatch(actions.blockEbook(id, isPrivate, isFree)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchEbooks(ebooksUIProps.queryParams, isPrivate, isFree));
      // clear selections list
      ebooksUIProps.setIds([]);
      // closing block modal
      onHide();
    });
  };

  const classes = useStyles();

  return (
    <Modal
    backdrop="static"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      style={{ margin: "3% auto" }}
    >
      {isLoading && <ModalProgressBar />}
      <Modal.Body className={classes.body}>
        {name ? (
          <>
            <img src="/media/members/image-modal.svg" alt="" />
            {!isLoading ? (
              <>
                <div className={classes.title}>ยืนยันการบล็อก</div>
                <div className={classes.subtitle}>ต้องการบล็อก</div>
                <div className={classes.subtitle}> {name} หรือไม่?</div>
                <div className={classes.divButton}>
                  <button type="button" onClick={onHide} className={classes.denyButton}>
                    ยกเลิก
                  </button>
                  <button type="button" onClick={blockEbook} className={classes.acceptButton}>
                    ยืนยัน
                  </button>
                </div>
              </>
            ) : (
              <div className={classes.subtitle} style={{ marginBottom: "36px" }}>
                กำลังบล็อกสื่อ...
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </Modal.Body>
    </Modal>
  );
}
