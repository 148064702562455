import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/customers/qrcodesActions";
import { QRCodeGeneratorForm } from "../qrcode-generator-dialog/QRCodeGeneratorForm";
import { ClipLoader } from "react-spinners";
import LoadScriptOnlyIfNeeded, { libraries } from "../LoadScriptOnlyIfNeeded";
import { googleMapsApiKey } from "../../../../../../_metronic/_helpers/GoogleMapsApiKey";
import jwt_decode from "jwt-decode";

export function QrcodeEditDialog({ id, show, onHide, isEditing }) {
  // Customers Redux state
  const dispatch = useDispatch();
  const { actionsLoading, qrcodeForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.customers.actionsLoading,
      qrcodeForEdit: state.qrcodes.qrcodeForEdit,
    }),
    shallowEqual
  );
  const loaderHeight = 50;

  const [clientstartDate, setClientStartDate] = useState(null);
  const [clientExpireDate, setClientExpireDate] = useState(null);

  const { authToken, viewAsToken } = useSelector(
    (state) => ({
      authToken: state.auth.authToken,
      viewAsToken: state.auth.viewAsToken,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (viewAsToken) {
      if (jwt_decode(viewAsToken).startDate) {
        setClientStartDate(jwt_decode(viewAsToken).startDate);
      } else {
        setClientStartDate(null);
      }
      if (jwt_decode(viewAsToken).expireDate) {
        setClientExpireDate(jwt_decode(viewAsToken).expireDate);
      } else {
        setClientExpireDate(null);
      }
    } else if (authToken) {
      if (jwt_decode(authToken).startDate) {
        setClientStartDate(jwt_decode(authToken).startDate);
      } else {
        setClientStartDate(null);
      }
      if (jwt_decode(authToken).expireDate) {
        setClientExpireDate(jwt_decode(authToken).expireDate);
      } else {
        setClientExpireDate(null);
      }
    }
  }, [authToken, viewAsToken]);

  // const initQRCodeData = {
  //   name: "",
  //   latitude: "",
  //   longitude: "",
  // };

  useEffect(() => {
    // server call for getting Customer by id
    dispatch(actions.fetchQrcode(id));
  }, [id, dispatch]);

  // server request for saving customer
  const saveQrcodeData = (qrcode) => {
    // if (!id) {
    //   // server request for creating customer
    //   dispatch(actions.createCustomer(qrcode)).then(() => {
    //     dispatch(actions.fetchCustomers(customersUIProps.queryParams));
    //     onHide();
    //   });
    // } else {
    //   // server request for updating customer
    //   dispatch(actions.updateCustomer(qrcode)).then(() => onHide());
    // }
  };

  return (
    <Modal
      backdrop="static"
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {qrcodeForEdit && qrcodeForEdit.status && qrcodeForEdit.data ? (
        <LoadScriptOnlyIfNeeded googleMapsApiKey={googleMapsApiKey} libraries={libraries}>
          <QRCodeGeneratorForm
            saveQRCodeData={saveQrcodeData}
            actionsLoading={actionsLoading}
            isEditing={isEditing}
            qrCodeData={Object.assign(
              {},
              {
                status: qrcodeForEdit.status,
                data: Object.assign({}, qrcodeForEdit.data, {
                  latitude:
                    qrcodeForEdit.data &&
                    qrcodeForEdit.data.geoLocation &&
                    qrcodeForEdit.data.geoLocation.latitude,
                  longitude:
                    qrcodeForEdit.data &&
                    qrcodeForEdit.data.geoLocation &&
                    qrcodeForEdit.data.geoLocation.longitude,
                }),
              }
            )}
            onHide={onHide}
            id={id}
            clientstartDate={clientstartDate}
            clientExpireDate={clientExpireDate}
          />
        </LoadScriptOnlyIfNeeded>
      ) : (
        <div
          style={{
            minHeight: `${loaderHeight}px`,
            textAlign: "center",
            padding: "20px 0 0 0",
            width: "100%",
          }}
        >
          <ClipLoader size={loaderHeight} color={"#123abc"} loading={true} />
        </div>
      )}
    </Modal>
  );
}
