/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/categories/categoriesActions";
import { CategoryEditForm } from "./CategoryEditForm";
import { useCategoriesUIContext } from "../CategoriesUIContext";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
     body: { margin: "auto" },
}));

export function CategoryEditDialog({ type, catId, name, subcatId, isPrivate, show, onHide }) {
     // Categories UI Context
     const [successModal, setSuccessModal] = useState(false);
     const categoriesUIContext = useCategoriesUIContext();
     const categoriesUIProps = useMemo(() => {
          return {
               initCategory: categoriesUIContext.initCategory,
               queryParams: categoriesUIContext.queryParams,
          };
     }, [categoriesUIContext]);

     const styles = useStyles();

     // Categories Redux state
     const dispatch = useDispatch();
     const { actionsLoading, categoryForEdit, entities } = useSelector(
          (state) => ({
               actionsLoading: state.categories.actionsLoading,
               categoryForEdit: state.categories.categoryForEdit,
               entities: state.categories.entities,
          }),
          shallowEqual
     );
     const [subCategoryForEdit, setSubCategoryForEdit] = useState(undefined);

     useEffect(() => {
          if (catId) {
               // server call for getting Category by id
               dispatch(actions.fetchCategory(catId, isPrivate));
          }
     }, [catId, isPrivate, dispatch]);

     useEffect(() => {
          if (categoryForEdit && subcatId && categoryForEdit.subcats && categoryForEdit.subcats.length > 0) {
               setSubCategoryForEdit(categoryForEdit.subcats.find((el) => el.id === subcatId));
          }
     }, [subcatId]);

     // server request for saving category
     const saveCategory = (category) => {
          const updatedCat = { catName: category.name };
          dispatch(actions.updateCategory(updatedCat, isPrivate, catId, setSuccessModal));
     };

     const saveSubCategory = (subcategory) => {
          const updatedSubCat = { subcatName: subcategory.name };
          dispatch(actions.updateSubCategory(updatedSubCat, isPrivate, catId, subcatId, setSuccessModal));
     };

     return (
          <Modal
               backdrop="static"
               size="md"
               show={show}
               onHide={onHide}
               style={{ marginTop: "27px" }}
               aria-labelledby="example-modal-sizes-title-md"
               dialogClassName={successModal ? styles.divSuccess : ""}
          >
               {successModal ? (
                    <Modal.Body className={styles.body}>
                         <div>
                              <img src="/media/bg/success.svg" alt="" />
                         </div>
                         <div className="divText-modal">เรียบร้อยแล้ว</div>
                         <div>
                              <button
                                   className="acceptButton "
                                   onClick={() => {
                                        onHide();
                                        setTimeout(() => {
                                             setSuccessModal(false);
                                        }, 200);
                                   }}
                              >
                                   ตกลง
                              </button>
                         </div>
                    </Modal.Body>
               ) : (
                    <CategoryEditForm
                         saveCategory={saveCategory}
                         isSubCat={subcatId ? true : false}
                         saveSubCategory={saveSubCategory}
                         actionsLoading={actionsLoading}
                         categoryForEdit={categoryForEdit || categoriesUIProps.initCategory}
                         subCategoryForEdit={subCategoryForEdit || categoriesUIProps.initCategory}
                         onHide={onHide}
                         categoryList={entities}
                    />
               )}
          </Modal>
     );
}
