// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Role } from "../../../../../../../interface";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  {
    openEditCustomerDialog,
    openDeleteCustomerDialog,
    openBlockCustomerDialog,
    openUnblockCustomerDialog,
    openBannedCustomerDialog,
    openResetPwCustomerDialog,
    openCustomerDevicesDialog,
    role,
    email,
    isBanned,
    viewAsToken,
  }
) {
  return row.status === 1 || row.status === 2 ? (
    <>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="quick-user-tooltip">แก้ไขผู้ใช้</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm"
          onClick={() => {
            openEditCustomerDialog(row.memberUID, row.email);
          }}
          style={{
            backgroundColor: "#F3F6F9",
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/members/edit-button.svg")} />
          </span>
        </a>
      </OverlayTrigger>

      {isBanned || row.email !== email ? (
        <>
          {/* <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="quick-user-tooltip">แก้ไขผู้ใช้</Tooltip>}
          >
            <a
              className="btn btn-icon btn-light btn-hover-primary btn-sm"
              onClick={() => {
                openEditCustomerDialog(row.memberUID, row.email);
              }}
              style={{
                backgroundColor: "#F3F6F9",
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/members/edit-button.svg")} />
              </span>
            </a>
          </OverlayTrigger> */}

          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="quick-user-tooltip">
                {row.status === 1 ? "บล็อกผู้ใช้" : "ปลดบล็อกผู้ใช้"}
              </Tooltip>
            }
          >
            <a
              className="btn btn-icon btn-light btn-hover-primary btn-sm"
              onClick={() => {
                if (row.status === 1) {
                  openBlockCustomerDialog(row.memberUID, row.email);
                } else if (row.status === 2) {
                  openUnblockCustomerDialog(row.memberUID, row.email);
                }
              }}
              style={{
                backgroundColor: row.status === 1 ? "#F3F6F9" : "#ff5337",
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    `/media/members/${
                      row.status === 1 ? "block" : "unblock"
                    }-button.svg`
                  )}
                />
              </span>
            </a>
          </OverlayTrigger>
        </>
      ) : (
        ""
      )}
      {isBanned ? (
        ""
      ) : (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="quick-user-tooltip">
              {role === Role.SuperAdmin ? "ดูอุปกรณ์" : "จัดการอุปกรณ์"}
            </Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            onClick={() => {
              if (row.name && row.surname && row.staffID) {
                openCustomerDevicesDialog(
                  row.devices,
                  row.memberUID,
                  row.staffID,
                  row.name + " " + row.surname
                );
              } else if (row.name && row.surname) {
                openCustomerDevicesDialog(
                  row.devices,
                  row.memberUID,
                  undefined,
                  row.name + " " + row.surname
                );
              } else {
                openCustomerDevicesDialog(row.devices, row.memberUID);
              }
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/members/managedevice-button.svg")}
              />
            </span>
          </a>
        </OverlayTrigger>
      )}
      {row.email === email ? (
        ""
      ) : (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-user-tooltip">ลบผู้ใช้</Tooltip>}
        >
          <a
            className="btn btn-icon btn-light btn-hover-danger btn-sm"
            onClick={() => openDeleteCustomerDialog(row.memberUID, row.email)}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/members/delete-button.svg")} />
            </span>
          </a>
        </OverlayTrigger>
      )}
    </>
  ) : row.status === 3 ? (
    <div
      className="btn btn-lg"
      style={{
        padding: "3px 6px",
        borderRadius: "100px",
        backgroundColor: "#ff0000",
        color: "#ffffff",
        cursor: "default",
      }}
      // onClick={() => openBannedCustomerDialog(row.email)}
    >
      สมาชิกถูกระงับการใช้งาน กรุณาติดต่อ Hibrary
    </div>
  ) : (
    ""
  );
}
