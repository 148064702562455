// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../../../_metronic/_partials/controls";
import { makeStyles } from "@material-ui/core";
import Spinner from "../../../../../../_metronic/_helpers/spinner";

const useStyles = makeStyles(() => ({
     head: {
          fontSize: "20px",
          fontWeight: "600",
          margin: "16px auto 0",
          color: "#012875",
     },
     input: { margin: "12px 0" },
}));

// Validation schema
const CatalogueNewSchema = Yup.object().shape({
     name: Yup.string()
          .min(2, "อย่างน้อย 2 ตัวอักษร")
          .max(200, "ไม่เกิน 200 ตัวอักษร")
          .matches(/^[^#%&?/]*$/g,'ไม่สามารถกรอก #, &, %, ? หรือ /')
          .required("กรุณากรอกชื่อ"),
});

export function CatalogueNewForm({
     createCatalogue,
     createAuthor,
     catalogue,
     actionsLoading,
     onHide,
     setDuplicate,
     isDuplicate,
}) {
     const styles = useStyles();
     const [catalogueIn, setCatalogueIn] = useState("");
     const [catalogueInList, setCatalogueInList] = useState([<option key="none" value=""></option>]);
     const [catalogueName, setCatalogueName] = useState("");
     const [checkingError, setCheckingError] = useState(false);

     useEffect(() => {
          setCheckingError(false);
     }, [catalogueIn]);

     const handleCatalogueInChange = (e) => {
          setCatalogueIn(e.target.value);
          var index = e.nativeEvent.target.selectedIndex;
          setCatalogueName(e.nativeEvent.target[index].text);
     };
     return (
          <>
               <p className="text-text-head">เพิ่มแคตตาล็อก</p>
               <Formik
                    enableReinitialize={true}
                    initialValues={{ name: catalogue.name, status: catalogue.status }}
                    validationSchema={CatalogueNewSchema}
                    onSubmit={(values) => {
                         setDuplicate(false);
                         createCatalogue(values);
                    }}
               >
                    {({ handleSubmit, values, setFieldValue, errors }) => {
                         return (
                              <>
                                   <Modal.Body>
                                        {actionsLoading && <Spinner forNewCat={true} />}
                                        <Form className="form form-label-right">
                                             <div className={`col-lg-12 ${styles.input}`}>
                                                  <Field
                                                       name="name"
                                                       component={Input}
                                                       placeholder={"กรอกชื่อแคตตาล็อก"}
                                                       label={"ชื่อแคตตาล็อก"}
                                                  />
                                                  {isDuplicate && checkingError && (
                                                       <>
                                                            <div className="is-invalid"></div>
                                                            <div className="invalid-feedback">
                                                                 ไม่สามารถเพิ่มแคตตาล็อกได้ เนื่องจากชื่อซ้ำ
                                                            </div>
                                                       </>
                                                  )}
                                             </div>
                                        </Form>
                                        <div className="divButton">
                                             <button className="denyButton " type="button" onClick={onHide}>
                                                  ยกเลิก
                                             </button>
                                             <button
                                                  className="acceptButton"
                                                  type="submit"
                                                  onClick={() => {
                                                       setCheckingError(true);
                                                       handleSubmit();
                                                  }}
                                                  disabled={actionsLoading || !values["name"] ? true : false}
                                             >
                                                  ตกลง
                                             </button>
                                        </div>
                                   </Modal.Body>
                              </>
                         );
                    }}
               </Formik>
          </>
     );
}
