import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { EbooksLoadingDialog } from './ebooks-loading-dialog/EbooksLoadingDialog';
import { EbookEditDialog } from './ebook-edit-dialog/EbookEditDialog';
import { EbookViewDialog } from './ebook-view-dialog/EbookViewDialog';
import { EbookDeleteDialog } from './ebook-delete-dialog/EbookDeleteDialog';
import { EbookBlockDialog } from './ebook-block-dialog/EbookBlockDialog';
import { EbookUnblockDialog } from './ebook-unblock-dialog/EbookUnblockDialog';
import { EbooksDeleteDialog } from './ebooks-delete-dialog/EbooksDeleteDialog';
import { EbooksFetchDialog } from './ebooks-fetch-dialog/EbooksFetchDialog';
import { EbooksUpdateStateDialog } from './ebooks-update-status-dialog/EbooksUpdateStateDialog';
import { PublisherNewDialog } from '../publishers/publisher-new-dialog/PublisherNewDialog';
import { CategoryNewDialog } from '../categories/category-new-dialog/CategoryNewDialog';
import { EbooksUIProvider } from './EbooksUIContext';
import { EbooksCard } from './EbooksCard';
import { UploadEbookPage } from '../upload/UploadEbookPage';
import queryString from 'query-string';
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardBody } from '../../../../../_metronic/_partials/controls';

const useStyles = makeStyles(theme => ({
   body: { margin: 0 }
}));

export function EbooksPrivatePage(props) {
   const { history, location } = props;
   const [isEdit, setEdit] = useState(0);
   const [view, setView] = useState(false);
   const [isDelete, setDelete] = useState(false);
   const [fixStatus, setFixStatus] = useState(0);
   const [isNewEbook, setNewEbook] = useState(undefined);
   const [isNewPublisher, setNewPublisher] = useState(0);
   const [isNewAuthor, setNewAuthor] = useState(0);
   const [isNewCategory, setNewCategory] = useState(0);
   const [isNewSubCategory, setNewSubCategory] = useState(0);
   const classes = useStyles();
   const [id, setId] = useState(null);
   const [name, setName] = useState(null);
   const [isBlock, setBlock] = useState(false);
   const [isUnblock, setUnblock] = useState(false);

   useEffect(() => {
      const parsed = queryString.parse(location.search);
      if (parsed.edit) {
         setEdit(1);
      }
      if (parsed.fixStatus) {
         setFixStatus(parseInt(parsed.fixStatus, 10));
      }
      if (parsed.newEbook) {
         setNewEbook(1);
      } else {
         setNewEbook(undefined);
      }
      if (parsed.newPublisher) {
         setNewPublisher(1);
      }
      if (parsed.newAuthor) {
         setNewAuthor(1);
      }
      if (parsed.newCategory) {
         setNewCategory(1);
      }
      if (parsed.newSubCategory) {
         setNewSubCategory(1);
      }
      if (parsed.id) {
         setId(parsed.id);
      }
      if (parsed.name) {
         setName(parsed.name);
      }
      if (parsed.view) {
         setView(parsed.view);
      }
      if (parsed.block) {
         setBlock(parsed.block);
      }
      if (parsed.unblock) {
         setUnblock(parsed.unblock);
      }
      if (parsed.delete) {
         setDelete(parsed.delete);
      }
      // else {
      //   setId(null);
      // }
   }, [location.search]);
   const ebooksUIEvents = {
      newEbookButtonClick: () => {
         history.push('/ebooks-private?newEbook=true');
      },
      openViewEbookDialog: id => {
         history.push(`/ebooks-private?id=${id}&view=true`);
      },
      openDeleteEbookDialog: (name, id) => {
         history.push(
            `/ebooks-private?name=${encodeURIComponent(
               name
            )}&id=${id}&delete=true`
         );
      },
      openBlockEbookDialog: (name, id) => {
         history.push(
            `/ebooks-private?name=${encodeURIComponent(
               name
            )}&id=${id}&block=true`
         );
      },
      openUnblockEbookDialog: (name, id) => {
         history.push(
            `/ebooks-private?name=${encodeURIComponent(
               name
            )}&id=${id}&unblock=true`
         );
      },
      openDeleteEbooksDialog: () => {
         history.push(`/ebooks-private/deleteEbooks`);
      },
      openFetchEbooksDialog: () => {
         history.push(`/ebooks-private/fetch`);
      },
      openUpdateEbooksStatusDialog: () => {
         history.push('/ebooks-private/updateStatus');
      },
      openEditEbookDialog: (id, status) => {
         history.push(
            `/ebooks-private?id=${id}&edit=true${
               status === 4 || status === 5 || status === 6
                  ? `&fixStatus=${status}`
                  : ''
            }`
         );
      },
      newPublisherButtonClick: id => {
         history.push(`/ebooks-private?id=${id}&edit=true&newPublisher=true`);
      },
      newAuthorButtonClick: id => {
         history.push(`/ebooks-private?id=${id}&edit=true&newAuthor=true`);
      },
      newCategoryButtonClick: id => {
         history.push(`/ebooks-private?id=${id}&edit=true&newCategory=true`);
      },
      newSubCategoryButtonClick: id => {
         history.push(`/ebooks-private?id=${id}&edit=true&newSubCategory=true`);
      }
   };

   return (
      <EbooksUIProvider ebooksUIEvents={ebooksUIEvents}>
         <EbooksLoadingDialog />
         {isEdit ? (
            <EbookEditDialog
               show={true}
               id={id}
               isPrivate={1}
               isFree={0}
               fixStatus={fixStatus}
               onHide={() => {
                  history.push('/ebooks-private');
                  setEdit(0);
                  setFixStatus(0);
               }}
            />
         ) : (
            ''
         )}
         {view ? (
            <EbookViewDialog
               show={true}
               id={id}
               isPrivate={1}
               isFree={0}
               onHide={() => {
                  history.push('/ebooks-private');
                  setView(false);
               }}
            />
         ) : (
            ''
         )}
         {isDelete ? (
            <EbookDeleteDialog
               show={true}
               name={decodeURIComponent(name)}
               id={id}
               isPrivate={1}
               isFree={0}
               onHide={() => {
                  history.push('/ebooks-private');
                  setDelete(false);
               }}
            />
         ) : (
            ''
         )}
         {isUnblock ? (
            <EbookUnblockDialog
               show={true}
               name={name}
               id={id}
               isPrivate={1}
               isFree={0}
               onHide={() => {
                  history.push('/ebooks-private');
                  setUnblock(false);
               }}
            />
         ) : (
            ''
         )}
         {isBlock ? (
            <EbookBlockDialog
               show={true}
               name={name}
               id={id}
               isPrivate={1}
               isFree={0}
               onHide={() => {
                  history.push('/ebooks-private');
                  setBlock(false);
               }}
            />
         ) : (
            ''
         )}
         {isNewPublisher ? (
            <PublisherNewDialog
               show={true}
               onHide={() => {
                  if (!isNewEbook) {
                     history.push(`/ebooks-private?id=${id}&edit=true`);
                  } else {
                     history.push(`/ebooks-private?newEbook=true`);
                  }
                  setNewPublisher(0);
               }}
               isPrivate={1}
               notOrigin={true}
            />
         ) : (
            ''
         )}
         {isNewAuthor ? (
            <PublisherNewDialog
               show={true}
               onHide={() => {
                  if (!isNewEbook) {
                     history.push(`/ebooks-private?id=${id}&edit=true`);
                  } else {
                     history.push(`/ebooks-private?newEbook=true`);
                  }
                  setNewAuthor(0);
               }}
               isPrivate={1}
               isAuthor={true}
               notOrigin={true}
            />
         ) : (
            ''
         )}
         {isNewCategory ? (
            <CategoryNewDialog
               show={true}
               onHide={() => {
                  if (!isNewEbook) {
                     history.push(`/ebooks-private?id=${id}&edit=true`);
                  } else {
                     history.push(`/ebooks-private?newEbook=true`);
                  }
                  setNewCategory(0);
               }}
               isPrivate={1}
               history={history}
               notOrigin={true}
               contentType="ebook"
            />
         ) : (
            ''
         )}
         {isNewSubCategory ? (
            <CategoryNewDialog
               show={true}
               onHide={() => {
                  if (!isNewEbook) {
                     history.push(`/ebooks-private?id=${id}&edit=true`);
                  } else {
                     history.push(`/ebooks-private?newEbook=true`);
                  }
                  setNewSubCategory(0);
               }}
               isPrivate={1}
               isSubCat={true}
               history={history}
               notOrigin={true}
               contentType="ebook"
            />
         ) : (
            ''
         )}
         <Switch>
            <Route path="/ebooks-private/deleteEbooks">
               {({ history, match }) => (
                  <EbooksDeleteDialog
                     show={match != null}
                     onHide={() => {
                        history.push('/ebooks-private');
                     }}
                  />
               )}
            </Route>
            <Route path="/ebooks-private/fetch">
               {({ history, match }) => (
                  <EbooksFetchDialog
                     show={match != null}
                     onHide={() => {
                        history.push('/ebooks-private');
                     }}
                  />
               )}
            </Route>
            <Route path="/ebooks-private/updateStatus">
               {({ history, match }) => (
                  <EbooksUpdateStateDialog
                     show={match != null}
                     onHide={() => {
                        history.push('/ebooks-private');
                     }}
                  />
               )}
            </Route>
         </Switch>
         {isNewEbook ? (
            <Card
               style={{
                  boxShadow: 'none',
                  borderRadius: '0.42rem',
                  marginBottom: '14px',
                  height: '100%'
               }}
            >
               <CardBody>
                  <UploadEbookPage
                     {...props}
                     onHide={() => {
                        history.push('/ebooks-private');
                        setNewEbook(0);
                     }}
                  />
               </CardBody>
            </Card>
         ) : (
            <EbooksCard isPrivate={1} isFree={0} isNewEbook={isNewEbook} />
         )}
      </EbooksUIProvider>
   );
}
