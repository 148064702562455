import axios from 'axios';
import queryString from 'query-string';
import {
    HtiResponse,
    CompanyList,
    CompanyData,
    Company,
    CompanyFilter,
    PresetCompany,
    ClientTable,
    ClientDuration,
    ClientDurationPayload,
    PhoneRegisterUpdate
} from '../../../../../interface';

// export const COMPANIES_URL = "api/companies";
export const COMPANIES_URL = `/superuser/clients`;
export const PRIVATE_COMPANIES_URL = `/admin/private/contents`;

// CREATE =>  POST: add a new company to the server
export function createCompany(company: Company) {
    return axios.post(COMPANIES_URL, { company });
}

// READ
export function getAllCompanies() {
    return axios.get<HtiResponse<CompanyList>>(COMPANIES_URL);
}

export function getCompanyById(companyId: string) {
    return axios.get<HtiResponse<CompanyData>>(`${COMPANIES_URL}/${companyId}`);
}

export async function getClientDuration(clientUID: string) {
    try {
        const { data } = await axios.get<HtiResponse<ClientDuration>>(
            `${COMPANIES_URL}/${clientUID}/edit-duration`
        );
        return data;
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function updateClientDuration(
    clientUID: string,
    durationPayload: ClientDurationPayload
) {
    try {
        const { data } = await axios.patch<HtiResponse>(
            `/superuser/clients/${clientUID}/edit-duration`,
            durationPayload
        );
        return data;
    } catch (error) {
        return Promise.reject(error);
    }
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCompanies(
    queryParams: CompanyFilter,
    isPrivate: number,
    lastEvaluate: any,
    groupClientType?: string
) {
    if (!queryParams.keyword) {
        var qString: any = {
            // limit: queryParams.limit
            limit: 300
        };

        if (queryParams.memberMode) {
            qString.status =
                queryParams.memberMode === '2' && !isPrivate
                    ? '0'
                    : queryParams.memberMode;
        }
        if (
            lastEvaluate !== undefined &&
            JSON.stringify(lastEvaluate) !== JSON.stringify({})
        ) {
            qString.lastEvaluatedKey = btoa(JSON.stringify(lastEvaluate));
        }
        if (groupClientType) {
            qString.groupClientType = groupClientType;
        }
        return axios.get<HtiResponse<CompanyList>>(
            queryString.stringifyUrl({
                url: isPrivate ? PRIVATE_COMPANIES_URL : COMPANIES_URL,
                query: qString
            })
        );
    } else {
        return axios.get<HtiResponse<CompanyList>>(
            queryString.stringifyUrl({
                url: `${
                    isPrivate ? PRIVATE_COMPANIES_URL : COMPANIES_URL
                }/search`,
                query: { keyword: encodeURIComponent(queryParams.keyword) }
            })
        );
    }
}

// UPDATE => PUT: update the company on the server
export function updateCompany(id: string, company: Company) {
    return axios.put(`${COMPANIES_URL}/${id}/edit`, company);
}

export function updateRegisterByPhone(
    id: string,
    payload: PhoneRegisterUpdate
) {
    return axios.post(`/superuser/clients/${id}/edit/registerByPhone`, payload);
}

// UPDATE Status
export function updateStatusForCompanies(ids: string, status: number) {
    return axios.post(`${COMPANIES_URL}/updateStatusForCompanies`, {
        ids,
        status
    });
}

// DELETE => delete the company from the server
export function deleteCompany(companyId: string) {
    return axios.post<HtiResponse<any>>(
        `${COMPANIES_URL}/${companyId}/table/client/delete`,
        {
            backup: false
        }
    );
}

export function deleteAllDataOfClient(
    clientUID: string,
    table: ClientTable,
    backup: boolean
) {
    return axios.post<HtiResponse>(
        `/superuser/clients/${clientUID}/table/${table}/delete`,
        {
            backup
        }
    );
}

export function blockCompany(companyId: string, isPrivate: boolean) {
    if (isPrivate) {
        return axios.patch(`${PRIVATE_COMPANIES_URL}/${companyId}/status`, {
            status: 2
        });
    } else {
        return axios.patch(`${COMPANIES_URL}/${companyId}/status`);
    }
}

export function unblockCompany(companyId: string, isPrivate: boolean) {
    if (isPrivate) {
        return axios.patch(`${PRIVATE_COMPANIES_URL}/${companyId}/status`, {
            status: 1
        });
    } else {
        return axios.patch(`${COMPANIES_URL}/${companyId}/status`);
    }
}

// DELETE Companies by ids
export function deleteCompanies(ids: string) {
    return axios.post<HtiResponse<any>>(`${COMPANIES_URL}/deleteCompanies`, {
        ids
    });
}

export function getPresetCompany() {
    return axios.get<HtiResponse<PresetCompany>>(`${COMPANIES_URL}/create`);
}
