import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/customers/qrcodesActions";
import { QRCodeGeneratorForm } from "../qrcode-generator-dialog/QRCodeGeneratorForm";
import LoadScriptOnlyIfNeeded, { libraries } from "../LoadScriptOnlyIfNeeded";
import { googleMapsApiKey } from "../../../../../../_metronic/_helpers/GoogleMapsApiKey";

export function QrcodeViewDialog({ id, show, onHide, isFull }) {
  // Customers UI Context
  // Customers Redux state
  const dispatch = useDispatch();
  const { actionsLoading, qrcodeForEdit } = useSelector(
    state => ({
      actionsLoading: state.customers.actionsLoading,
      qrcodeForEdit: state.qrcodes.qrcodeForEdit
    }),
    shallowEqual
  );

  const initQRCodeData = {
    name: "",
    latitude: "",
    longitude: ""
  };

  useEffect(() => {
    // server call for getting Customer by id
    dispatch(actions.fetchQrcode(id));
  }, [id, dispatch]);

  return (
    <Modal
      backdrop="static"
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <LoadScriptOnlyIfNeeded
        googleMapsApiKey={googleMapsApiKey}
        libraries={libraries}
      >
        <QRCodeGeneratorForm
          actionsLoading={actionsLoading}
          qrCodeData={qrcodeForEdit || initQRCodeData}
          onHide={onHide}
          isFull={isFull}
          isForDownload={true}
        />
      </LoadScriptOnlyIfNeeded>
    </Modal>
  );
}
