import { Themes } from './request';

export interface CompanyList {
    clients: Company[];
    lastEvaluatedKey: any;
    totalAmount: number;
}

export interface CompanyData {
    client: Company;
}

export interface ClientDuration {
    startDate: number;
    expireDate: number;
}

export interface ClientDurationPayload {
    startDate: number | null;
    expireDate: number | null;
    updateMemberExpire?: boolean;
}

export interface Company {
    clientUID: string;
    clientName: string;
    displayName: string;
    status: number;
    startDate: number;
    expireDate: number;
    userLimit: number;
    logoImages: string;
    privateLibrary: PrivateLibrary; // | boolean;
    companyPrefix: string;
    timeStatus?: number;
    totalAmount: number;
    rentalLimit?: number;
    anonymousRentalLimit?: number;
    rentalLimitCategory?: RentalCategory;
    rentalPeriod?: number;
    anonymousPeriod?: number;
    periodCategory?: RentalCategory;
    monthlyLicense?: number;
    organizationType?: string;
    contactList?: ContactList;
    registerByPhoneNumber?: boolean;
    customTitle?: string;
    description?: string;
    isCustomTotalStorage?: boolean;
    isCustomTotalTitle?: boolean;

    registerByPhoneDetail?: RegisterByPhoneDetail;
}

export interface PhoneRegisterUpdate {
    emailList?: string[];
    addSMSCount?: number;
    freeOTPVerify?: boolean;
}

export interface RegisterByPhoneDetail {
    email?: string[];
    freeOTPVerify?: boolean;
    remainSMS?: number;
    totalSMS?: number;
}

export interface ContactList {
    website?: string;
    address?: string;
    phoneNumber?: string;
    LineID?: string;
    mobilePhone?: string;
    Instagram?: string;
    Twitter?: string;
    Facebook?: string;
    YouTube?: string;
    email?: string;
}
export interface PrivateLibrary {
    totalTitle?: number;
    totalStorage?: number;
}
export interface RentalCategory {
    newspapers?: RentalCategoryUser;
    magazine?: RentalCategoryUser;
}
export interface RentalCategoryUser {
    user?: number;
    anonymous?: number;
}

export interface CompanyFilter {
    keyword?: string;
    memberMode?: string;
    limit?: number;
}

export interface CompanyTheme {
    theme: Themes;
    mode: ThemeMode;
    colorTheme: PresetTheme[];
}
export enum ThemeMode {
    Light = 'light',
    Dark = 'dark'
}

export interface PresetTheme {
    label: string;
    value: string;
    color: PresetThemeColor;
}

export interface PresetThemeColor {
    OnPrimary: string;
    OnSecondary: string;
}
export interface PresetCompany {
    userLimitPreset: ConcurrentPreset[];
    storagePreset: ConcurrentPreset[];
    titlePreset: ConcurrentPreset[];
    concurrentPreset: ConcurrentPreset[];
    rentalPeriod: ConcurrentPreset[];
    colorTheme: PresetTheme[];
    organizationType: ConcurrentPreset[];
    remainSMSPreset: ConcurrentPreset[];
}
export interface ConcurrentPreset {
    label: string;
    value: string | number;
}

export enum ClientTable {
    client = 'client',
    clientdata = 'clientdata',
    statistics = 'statistics',
    member = 'member',
    clientStore = 'clientStore',
    readingTime = 'readingTime',
    license = 'license',
    waitingList = 'waitingList',
    privateContent = 'privateContent',
    privatePublisher = 'privatePublisher',
    log = 'log',
    qrcode = 'qrcode'
}

export enum ClientProcessStatus {
    Idle = 'Idle',
    ClearMember = 'ClearMember',
    ClearStore = 'ClearStore'
}
