/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useRef } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/upload/uploadActions";
import * as actions2 from "../../../_redux/channels-podcast/channelsActions";
import * as actions3 from "../../../_redux/categories/categoriesActions";
import * as actions4 from "../../../_redux/podcasts/podcastsActions";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { makeStyles, withStyles, lighten } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { DashboardMini } from "../../upload/DashboardMini";
import {
  Input,
  Select,
  Card,
  CardHeader,
  CardHeaderToolbar,
  CardBody,
} from "../../../../../../_metronic/_partials/controls";
import { usePodcastsUIContext } from "../PodcastsUIContext";
import { PlusOutlined } from "@ant-design/icons";
import { message, Tag, Tooltip } from "antd";
import { DatePicker, Switch, Input as Input2, Divider, Upload } from "antd";
import moment from "moment";
import ImgCrop from "antd-img-crop";
import "antd/dist/antd.css";
import axios from "axios";
import Spinner from "../../../../../../_metronic/_helpers/spinner";
import { useDropzone } from "react-dropzone";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ModalsSuccess } from "../../../../CustomView/ModalView";
import { UploadInformationDialog } from "../../upload/upload-information-dialog/UploadInformationDialog";

const { TextArea } = Input2;
const CancelToken = axios.CancelToken;

const useStyles = makeStyles((theme) => ({
  stat: { background: "#F2F2F2", borderRadius: "20px", padding: "20px 0" },
  blue_p: {
    fontSize: 20,
    fontWeight: 600,
    color: "#012875",
    marginBottom: "6px",
  },
  blue_p_small: {
    fontSize: 14,
    fontWeight: 400,
    color: "#012875",
    display: "inline-block",
  },

  dropzoneTitle: { marginBottom: "0px", fontSize: "16px" },
  dropzoneSubTitle: { marginBottom: "2px", fontSize: "14px", color: "#828282" },
  dropzoneSubTitleBlue: {
    marginBottom: "0px",
    fontSize: "14px",
    color: "#012875",
  },

  progress: {
    margin: "13px 0px",
    borderRadius: "5px",
  },

  p: { marginBottom: "8px", fontSize: "14px" },

  subtitle: {
    color: "#012875",
    fontWeight: 400,
    fontSize: 12,
    marginBottom: 0,
  },

  slot: {
    height: "32px",
    width: "100%",
    borderRadius: "4px",
    border: "1px solid black",
  },

  progressUpload: {
    margin: "3px 13px 47px",
    borderRadius: "5px",
  },
  uploadFileImg: { marginTop: "70px" },

  uploadingLabel: {
    fontSize: 14,
    alignSelf: "center",
  },
  body: { margin: "auto" },
}));

export function PodUploadDialog({ id, show, onHide, isPrivate, fixStatus }) {
  // Podcasts Redux state
  const dispatch = useDispatch();
  const {
    uploadState,
    currentState,
    currentState2,
    actionsLoading,
  } = useSelector(
    (state) => ({
      uploadState: state.upload,
      currentState: state.categories,
      currentState2: state.channelsPodcast,
      actionsLoading: state.podcasts.actionsLoading,
    }),
    shallowEqual
  );
  const podcastsUIContext = usePodcastsUIContext();
  const podcastsUIProps = useMemo(() => {
    return {
      openFileInformationDialog: podcastsUIContext.openFileInformationDialog,
      newChannelButtonClick: podcastsUIContext.newChannelButtonClick,
      newCreatorButtonClick: podcastsUIContext.newCreatorButtonClick,
      newCategoryButtonClick: podcastsUIContext.newCategoryButtonClick,
      newSubCategoryButtonClick: podcastsUIContext.newSubCategoryButtonClick,
    };
  }, [podcastsUIContext]);
  const saveInputRef = useRef(null);
  const loaderHeight = 50;
  const [fixFileModal, setFixFileModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [channelValue, setChannelValue] = useState(0);
  const [creatorValue, setCreatorValue] = useState(0);
  const [channelName, setChannelName] = useState("");
  const [categoryValue, setCategoryValue] = useState(0);
  const [subCategoryValue, setSubCategoryValue] = useState(0);
  const [startDate, setStartDate] = useState(moment().unix());
  const [endDate, setEndDate] = useState(undefined);
  const [isManageLicense, setIsManageLicense] = useState(false);
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [rentalPeriodValue, setRentalPeriodValue] = useState(undefined);
  const [anonymousRentalPeriodValue, setAnonymousRentalPeriodValue] = useState(
    undefined
  );
  const [coverImageSuccess, setCoverImageSuccess] = useState(false);
  const [description, setDescription] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [contentUIDToUpload, setContentUIDToUpload] = useState(undefined);
  const [coverName, setCoverName] = useState(null);
  const [source, setSource] = useState(
    Object.assign({}, CancelToken.source(), { key: 0 })
  );
  const [token, setToken] = useState(source.token);
  const [filePost, setFilePost] = useState(null);
  const [storageLeft, setStorageLeft] = useState(0);
  const [canUpload, setCanUpload] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [fileUploadError, setFileUploadError] = useState(false);
  const [numLicenses, setNumLicenses] = useState(undefined);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  useEffect(() => {
    if (Array.isArray(fileList) && fileList.length === 0) {
      setCoverImageSuccess(false);
    }
  }, [fileList]);

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  useEffect(() => {
    setToken(source.token);
  }, [source]);

  useEffect(() => {
    if (inputVisible) saveInputRef.current.focus();
  }, [inputVisible]);

  const [channelList, setChannelList] = useState([
    <option value={0} disabled selected>
      เลือก Channel
    </option>,
  ]);
  const [categoryList, setCategoryList] = useState([
    <option value={0} disabled selected>
      เลือกหมวดหมู่หลัก
    </option>,
  ]);
  const [subCategoryList, setSubCategoryList] = useState([
    <option value={0} disabled selected>
      เลือกหมวดหมู่ย่อย
    </option>,
  ]);

  const [rentalPeriodList, setRentalPeriodList] = useState([
    <option value={3600}>1 ชั่วโมง</option>,
    <option value={7200}>2 ชั่วโมง</option>,
  ]);

  const [checkingError, setCheckingError] = useState(false);

  const classes = useStyles();

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: ".mp3",
    noClick: true,
    noKeyboard: true,
    maxSize: canUpload
      ? storageLeft > 4000000000
        ? 4000000000
        : storageLeft
      : 0,
    onDropRejected: (e) => onDropRejected(e),
  });

  useEffect(() => {
    if (uploadState.storageData && uploadState.titleData) {
      let storageLeft_ =
        uploadState.storageData.totalStorage -
        uploadState.storageData.usedStorage;
      let titleLeft =
        uploadState.titleData.totalTitle - uploadState.titleData.usedTitle;
      if (!isNaN(storageLeft_) && storageLeft_ > 0 && titleLeft > 0) {
        setStorageLeft(storageLeft_ * 1000000000);
        setCanUpload(true);
      }
    }
    setSource(Object.assign({}, CancelToken.source(), { key: source.key + 1 }));
  }, [uploadState.storageData, uploadState.titleData]);

  const onDropRejected = (e) => {
    setSource(Object.assign({}, CancelToken.source(), { key: source.key + 1 }));
    if (e && Array.isArray(e) && e.length > 0) {
      if (e[0].errors && Array.isArray(e[0].errors) && e[0].errors.length > 0) {
        if (e[0].errors[0] && e[0].errors[0].code === "file-too-large") {
          podcastsUIProps.openFileInformationDialog("file-too-large", "larger");
        } else if (
          e[0].errors[0] &&
          e[0].errors[0].code === "file-invalid-type"
        ) {
          podcastsUIProps.openFileInformationDialog(
            "file-invalid-type",
            e[0].errors[0].message
          );
        }
      }
    }
  };

  const reqDeleteFileFunc = async () => {
    const req = await axios.delete(`/admin/private/contents/file`, {
      data: { fileName: fileName, contentUID: contentUIDToUpload },
    });
    if (req.data.status) {
      setFileName(null);
    }
  };
  const reqDeleteCoverFunc = async () => {
    const req = await axios.delete(`/admin/private/contents/file`, {
      data: { fileName: coverName, contentUID: contentUIDToUpload },
    });
    if (req.data.status) {
      setCoverName(null);
    }
  };

  const onUploadCoverImage = (e) => {
    const reqFunc = async () => {
      try {
        if (e) {
          const fileToUpload = e.file;
          if (fileToUpload) {
            setCoverName(fileToUpload.name);
            const req = await axios.post(`/admin/private/contents/file`, {
              fileName: fileToUpload.name,
              contentUID: contentUIDToUpload ? contentUIDToUpload : undefined,
            });
            if (req.data.status && req.data.data) {
              const urlToUpload = req.data.data.url;
              const fieldsToUpload = req.data.data.fields;
              const UID = req.data.data.contentUID;
              setContentUIDToUpload(UID);
              var formData = new FormData();
              const entries = Object.entries(fieldsToUpload);
              for (const [key, val] of entries) {
                formData.append(key, val);
              }
              formData.append("file", fileToUpload);
              axios({
                method: "post",
                url: urlToUpload,
                data: formData,
                onUploadProgress: (progressEvent) => {
                  if (progressEvent) {
                    e.onProgress(
                      {
                        percent: (
                          (100 * progressEvent.loaded) /
                          progressEvent.total
                        ).toFixed(),
                      },
                      e.file
                    );
                  }
                },
              })
                .then((response) => {
                  e.onSuccess(response, e.file);
                  setCoverImageSuccess(true);
                })
                .catch(() => {});
            }
          }
        }
      } catch (err) {
        console.log(err);
        if (err.response.data.errorCode == "File not support") {
          setFileList([]);
          setFileUploadError(true);
          return;
        }
      }
    };

    reqFunc();
  };

  useEffect(() => {
    if (currentState && currentState.entities) {
      setCategoryList([
        <option value={0} disabled selected>
          เลือกหมวดหมู่หลัก
        </option>,
        ...currentState.entities.map((el, index) => {
          return (
            <option key={index} value={el.id}>
              {el.catName}
            </option>
          );
        }),
      ]);
      if (currentState.entities.length === 0) {
        setCategoryList([<option value={0}>กรุณาเพิ่มหมวดหมู่หลัก</option>]);
      }
    }
    if (categoryValue) {
      if (currentState && currentState.entities) {
        const currentCat = currentState.entities.filter(
          (el) => el.id === categoryValue
        );
        if (currentCat.length > 0) {
          const currentSubCat = currentCat[0].subcats;
          if (currentSubCat) {
            setSubCategoryList([
              <option value={0} disabled selected>
                เลือกหมวดหมู่ย่อย
              </option>,
              ...currentSubCat.map((el, index) => {
                return (
                  <option key={index} value={el.id}>
                    {el.subcatName}
                  </option>
                );
              }),
            ]);
            if (currentSubCat.length === 0) {
              setSubCategoryList([
                <option value={0}>กรุณาเพิ่มหมวดหมู่ย่อย</option>,
              ]);
            }
          } else {
            setSubCategoryList([
              <option value={0}>กรุณาเพิ่มหมวดหมู่ย่อย</option>,
            ]);
          }
        }
      }
    }
  }, [currentState.entities, categoryValue]);

  useEffect(() => {
    if (currentState2 && currentState2.entities) {
      setChannelList([
        <option value={0} disabled selected>
          เลือก Channel
        </option>,
        ...currentState2.entities
          .filter((el) => el.status === 1)
          .map((el, index) => {
            // if (index === 0 && channelValue === 0) {
            //   setChannelValue(el.uid);
            // }
            return (
              <option key={index} value={el.uid}>
                {el.name}
              </option>
            );
          }),
      ]);
      if (currentState2.entities && currentState2.entities.length === 0) {
        setChannelList([<option value={0}>กรุณาเพิ่ม Channel</option>]);
      }
    }
  }, [currentState2.entities]);

  useEffect(() => {
    if (
      currentState2 &&
      currentState2.creatorsForEdit &&
      currentState2.creatorsForEdit.length > 0
    ) {
      setCreatorValue(currentState2.creatorsForEdit[0].uid);
      console.log(currentState2.creatorsForEdit[0]);
      // setCreatorList([
      //   <option value={0} disabled selected>
      //     เลือก Creator
      //   </option>,
      //   ...currentState2.creatorsForEdit
      //     .filter((el) => el.status === 1)
      //     .map((el, index) => {
      //       // if (index === 0 && creatorValue === 0) {
      //       //   setCreatorValue(el.uid);
      //       // }
      //       return (
      //         <option key={index} value={el.uid}>
      //           {el.name}
      //         </option>
      //       );
      //     }),
      // ]);
      // if (currentState2.creatorsForEdit.length === 0) {
      //   setCreatorList([<option value={0}>เลือก Creator</option>]);
      // }
    }
  }, [currentState2.creatorsForEdit]);

  useEffect(() => {
    if (channelValue) {
      dispatch(actions2.fetchCreators("none", channelValue, 1));
    }
  }, [channelValue]);

  useEffect(() => {
    dispatch(actions2.fetchChannels("none", 1, undefined, true));
    dispatch(
      actions3.fetchCategories({ contentType: "podcast" }, 1, undefined, true)
    );
    dispatch(actions.fetchNewPrivateContentData());
  }, []);

  useEffect(() => {
    if (fixStatus === 4) {
      setFixFileModal(true);
      setModalType("both");
    } else if (fixStatus === 6) {
      setFixFileModal(true);
      setModalType("content");
    } else if (fixStatus === 5) {
      setFixFileModal(true);
      setModalType("cover");
    }
  }, [fixStatus]);

  useEffect(() => {
    if (uploadState.rentalPeriod) {
      setRentalPeriodList(
        uploadState.rentalPeriod.map((el, index) => (
          <option key={index} value={el.value}>
            {el.label}
          </option>
        ))
      );
    }
  }, [uploadState.rentalPeriod]);

  const onStartDateChange = (date) => {
    if (date) {
      setStartDate(date.unix());
      if (endDate && date.unix() > endDate) {
        setEndDate(null);
      }
    }
  };

  const onEndDateChange = (date) => {
    if (date) {
      setEndDate(date.unix());
    } else {
      setEndDate(null);
    }
  };

  const onStartDateOk = (date) => {
    if (date) {
      setStartDate(date.unix());
    }
  };

  const onEndDateOk = (date) => {
    if (date) {
      setEndDate(date.unix());
    }
  };

  const disabledEndDate = (date) => {
    return date && date < moment().endOf("day");
  };

  const errorNumberFormat = (e) =>
    !e || parseInt(e, 10) < 1 || isNaN(parseInt(e, 10));

  const onHideSuccessModal = () => {
    setSuccessModal(false);
  };

  const uploadSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, "อย่างน้อย 1 ตัวอักษร")
      .max(200, "ไม่เกิน 200 ตัวอักษร")
      .required("กรุณากรอกชื่อ"),
    isbn: Yup.string(),
    eisbn: Yup.string(),
    numPages: Yup.number()
      .min(1, "อย่างน้อย 1 หน้า")
      .typeError("กรุณาใช้ตัวเลข"),
  });

  const handleChannelChange = (e) => {
    setChannelValue(e.target.value);
    console.log(e.target.value);
    var index = e.nativeEvent.target.selectedIndex;
    setChannelName(e.nativeEvent.target[index].text);
    setCreatorValue(0);
  };
  // const handleCreatorChange = (e) => {
  //   setCreatorValue(e.target.value);
  //   var index = e.nativeEvent.target.selectedIndex;
  //   setCreatorName(e.nativeEvent.target[index].text);
  // };
  const handleCategoryChange = (e) => {
    setCategoryValue(e.target.value);
    setSubCategoryValue(0);
  };
  const handleSubCategoryChange = (e) => {
    setSubCategoryValue(e.target.value);
  };
  const onManageLicenseChange = (e) => {
    if (e) {
      setRentalPeriodValue(3600);
      setAnonymousRentalPeriodValue(3600);
    } else {
      setRentalPeriodValue(null);
      setAnonymousRentalPeriodValue(null);
    }
    setIsManageLicense(e);
  };
  const handleRentalPeriodChange = (e) => {
    setRentalPeriodValue(e.target.value);
  };
  const handleAnonymousRentalPeriodChange = (e) => {
    setAnonymousRentalPeriodValue(e.target.value);
  };
  const handleClose = (removedTag) => {
    setTags(tags.filter((tag) => tag !== removedTag));
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue.length >= 3) {
      if (inputValue && tags.indexOf(inputValue) === -1) {
        let commaSplit = inputValue.split(",");
        setTags(tags.concat(commaSplit));
      }
    } else {
      message.warning("tag ต้องมากกว่า 3 ตัวอักษร");
    }

    setInputVisible(false);
    setInputValue("");
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const uploadImageMemo = useMemo(() => {
    return (
      <div className="podcast-cover">
        <ImgCrop
          aspect={1 / 1}
          modalTitle="ตัดรูปภาพด้วยอัตราส่วน 1 : 1"
          modalOk="บันทึก"
          modalCancel="ยกเลิก"
          accept="image/png, image/jpeg, image/jpg"
        >
          <Upload
            customRequest={onUploadCoverImage}
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
            onRemove={reqDeleteCoverFunc}
          >
            {fileList.length < 1 && <PlusOutlined />}
          </Upload>
        </ImgCrop>
      </div>
    );
  }, [onUploadCoverImage, onChange, fileList, onPreview]);

  const TitleBar = () => {
    return (
      <div className="card-label">
        <div>สร้างรายการพ็อดคาสท์</div>
      </div>
    );
  };
  const ToolBar = () => {
    return (
      <>
        <button
          className="denyButton"
          type="button"
          onClick={() => {
            onHide();
          }}
        >
          ยกเลิก
        </button>
        <button
          className="acceptButton"
          type="submit"
          disabled={
            !coverImageSuccess ||
            !(
              channelValue &&
              creatorValue &&
              categoryValue &&
              subCategoryValue &&
              (!isManageLicense ||
                (isManageLicense && !errorNumberFormat(numLicenses)))
            )
          }
          onClick={() => {
            setCheckingError(true);
          }}
        >
          สร้าง
        </button>
      </>
    );
  };

  return (
    <>
      {/* <div className="row">
        <div className="col-6">
          <p
            style={{
              color: "#012875",
              fontWeight: "500",
              marginBottom: "0",
              marginTop: "20px",
              fontSize: "20px",
            }}
          >
            สร้างรายการพ็อดคาสท์
          </p>
        </div>

        <div className="col-6">
          <div className="divbtn_upload">
            <button
              className="acceptButton"
              type="submit"
              onClick={() => {
                //  handleSubmitOutside();
              }}
            >
              ยกเลิก
            </button>
            <button
              className="acceptButton"
              type="submit"
              disabled={!coverImageSuccess}
              onClick={() => {
                setCheckingError(true);
              }}
            >
              สร้าง
            </button>
          </div>
        </div>
      </div>
      <Divider style={{ margin: "20px 0" }} /> */}
      <>
        {actionsLoading && <Spinner forNewEbook={true} fullScreen={true} />}
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: undefined,
          }}
          validationSchema={uploadSchema}
          onSubmit={(values) => {
            setNumLicenses(values.numLicenses);
            const newValues = {
              contentUID: contentUIDToUpload,
              contentType: "podcast",
              title: values.name,
              creatorUID: channelValue,
              writerUID: creatorValue,
              catId: categoryValue,
              subcatId: subCategoryValue,
              startDate: startDate,
              expireDate: endDate,
              concurrent:
                values.numLicenses && parseInt(values.numLicenses, 10),
              tags: tags,
              rentalPeriod:
                rentalPeriodValue && parseInt(rentalPeriodValue, 10),
              anonymousRentalPeriod:
                anonymousRentalPeriodValue &&
                parseInt(anonymousRentalPeriodValue, 10),
              description: description,
            };

            if (
              channelValue &&
              creatorValue &&
              categoryValue &&
              subCategoryValue &&
              (!isManageLicense ||
                (isManageLicense && !errorNumberFormat(values.numLicenses)))
            ) {
              dispatch(actions4.createPod(newValues, setSuccessModal));
            }
          }}
        >
          {({ handleSubmit, values, setFieldValue }) => {
            return (
              <Form className="form form-label-right">
                <Card className="cardStyle">
                  <CardHeader title={<TitleBar />}>
                    <CardHeaderToolbar>
                      <ToolBar />
                    </CardHeaderToolbar>
                  </CardHeader>
                  {/* <Card
                style={{
                  boxShadow: "none",
                  borderRadius: "0px 12px 12px 12px",
                }}
                title="สร้างรายการพ็อดคาสท์"
              > */}
                  <CardBody className="cardBodyStyle">
                    <div style={{ maxWidth: "736px", margin: "auto" }}>
                      <div className="row">
                        <div className={`col-lg-12 input`}>
                          <Field
                            name="name"
                            component={Input}
                            placeholder="กรุณากรอกชื่อ"
                            label="ชื่อรายการ*"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className={`col-lg-10 input`}>
                          <Select
                            name="channel"
                            withFeedbackLabel={false}
                            label="Channel*"
                            onChange={handleChannelChange}
                            value={channelValue}
                          >
                            {channelList}
                          </Select>
                          {channelValue === 0 && checkingError && (
                            <>
                              <div className="is-invalid"></div>
                              <div className="invalid-feedback">
                                กรุณาเพิ่ม Channel
                              </div>
                            </>
                          )}
                        </div>
                        <div className={`col-lg-2 input`}>
                          <Tag
                            className={`site-tag-plus dashedBox`}
                            style={{
                              position: "absolute",
                              top: "29px",
                            }}
                            onClick={() =>
                              podcastsUIProps.newChannelButtonClick("pod")
                            }
                          >
                            <PlusOutlined style={{ marginRight: "2px" }} />{" "}
                            เพิ่ม Channel
                          </Tag>
                        </div>
                        {/* <div className={`col-lg-4 ${classes.input}`}>
                            <Select
                              name="creator"
                              withFeedbackLabel={false}
                              label="Created by (optional)"
                              onChange={handleCreatorChange}
                              value={creatorValue}
                            >
                              {creatorList}
                            </Select>
                            {creatorValue === 0 && checkingError && (
                              <>
                                <div className="is-invalid"></div>
                                <div className="invalid-feedback">กรุณาเพิ่ม Creator</div>
                              </>
                            )}
                          </div> */}
                        {/* <div className={`col-lg-2 ${classes.input}`}>
                            <Tag
                              className={`site-tag-plus ${classes.dashedBox}`}
                              style={{ position: "absolute", top: "29px" }}
                              onClick={() => podcastsUIProps.newCreatorButtonClick()}
                            >
                              <PlusOutlined style={{ marginRight: "2px" }} /> เพิ่ม Creator
                            </Tag>
                          </div> */}
                      </div>
                      <div className="row">
                        <div className={`col-lg-4 input`}>
                          <Select
                            name="category"
                            withFeedbackLabel={false}
                            label="หมวดหมู่หลัก*"
                            onChange={handleCategoryChange}
                            value={categoryValue}
                          >
                            {categoryList}
                          </Select>
                          {categoryValue === 0 && checkingError && (
                            <>
                              <div className="is-invalid"></div>
                              <div className="invalid-feedback">
                                กรุณาเลือกหมวดหมู่หลัก
                              </div>
                            </>
                          )}
                        </div>
                        <div className={`col-lg-2 input`}>
                          <Tag
                            className={`site-tag-plus dashedBox`}
                            style={{
                              position: "absolute",
                              top: "29px",
                            }}
                            onClick={() =>
                              podcastsUIProps.newCategoryButtonClick("pod")
                            }
                          >
                            <PlusOutlined style={{ marginRight: "2px" }} />{" "}
                            หมวดหมู่หลัก
                          </Tag>
                        </div>
                        <div className={`col-lg-4 input`}>
                          <Select
                            name="subcategory"
                            withFeedbackLabel={false}
                            label="หมวดหมู่ย่อย*"
                            onChange={handleSubCategoryChange}
                            value={subCategoryValue}
                          >
                            {subCategoryList}
                          </Select>
                          {subCategoryValue === 0 && checkingError && (
                            <>
                              <div className="is-invalid"></div>
                              <div className="invalid-feedback">
                                กรุณาเลือกหมวดหมู่ย่อย
                              </div>
                            </>
                          )}
                        </div>
                        <div className={`col-lg-2 input`}>
                          <Tag
                            className={`site-tag-plus dashedBox`}
                            style={{
                              position: "absolute",
                              top: "29px",
                            }}
                            onClick={() =>
                              podcastsUIProps.newSubCategoryButtonClick("pod")
                            }
                          >
                            <PlusOutlined style={{ marginRight: "2px" }} />{" "}
                            หมวดหมู่ย่อย
                          </Tag>
                        </div>
                      </div>
                      <div className="row">
                        <div className={`col-lg-6 input`}>
                          <p style={{ marginBottom: "0.5rem" }}>
                            วันที่เริ่มเผยแพร่*
                          </p>
                          <DatePicker
                            format={"DD-MM-YYYY HH:mm:ss"}
                            // className={checkingError ? "form-control is-valid" : "form-control"}
                            placeholder="เลือกวันที่เริ่มเผยแพร่"
                            defaultValue={
                              startDate ? moment.unix(startDate) : moment()
                            }
                            value={
                              startDate ? moment.unix(startDate) : moment()
                            }
                            showTime
                            onChange={onStartDateChange}
                            onOk={onStartDateOk}
                            allowClear={false}
                            className="podcastDatePicker"
                          />
                        </div>
                        <div className={`col-lg-6 input`}>
                          <p style={{ marginBottom: "0.5rem" }}>
                            วันที่หมดอายุ
                          </p>
                          <DatePicker
                            format={"DD-MM-YYYY HH:mm:ss"}
                            // className={checkingError ? "form-control is-valid" : "form-control"}
                            placeholder="เลือกวันที่หมดอายุ"
                            defaultValue={endDate ? moment.unix(endDate) : null}
                            value={endDate ? moment.unix(endDate) : null}
                            disabledDate={disabledEndDate}
                            showTime
                            onChange={onEndDateChange}
                            onOk={onEndDateOk}
                            className="podcastDatePicker"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className={`col-lg-12 input`}>
                          <p style={{ marginBottom: "0.5rem" }}>
                            รายละเอียดพ็อดคาสท์
                          </p>
                          <CKEditor
                            editor={ClassicEditor}
                            config={{
                              toolbar: [
                                "bold",
                                "italic",
                                "bulletedList",
                                "numberedList",
                              ],
                            }}
                            data={description ? description : ""}
                            onReady={(editor) => {
                              // You can store the "editor" and use when it is needed.
                              // console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setDescription(data);
                            }}
                            onBlur={(event, editor) => {
                              // console.log("Blur.", editor);
                            }}
                            onFocus={(event, editor) => {
                              // console.log("Focus.", editor);
                            }}
                          />
                          {/* <TextArea
                                name="description"
                                placeholder="กรอกรายละเอียดเกี่ยวกับพ็อดคาสท์"
                                value={description}
                                onChange={onDescriptionChange}
                                rows={4}
                              />*/}
                        </div>
                      </div>
                      <div className="row">
                        <div className={`col-lg-2 input`}>
                          <p style={{ marginBottom: "0.5rem" }}>จัดการสิทธิ์</p>
                          <Switch
                            onChange={onManageLicenseChange}
                            checked={isManageLicense}
                            checkedChildren="เปิด"
                            unCheckedChildren="ปิด"
                          />
                        </div>
                        {isManageLicense ? (
                          <>
                            <div className={`col-lg-4 input`}>
                              <Field
                                name="numLicenses"
                                // className={
                                //   !errorNumberFormat(values["numLicenses"]) && checkingError
                                //     ? "form-control is-valid"
                                //     : "form-control"
                                // }
                                component={Input}
                                placeholder="กรุณากรอกจำนวนสิทธิ์"
                                label="จำนวนสิทธิ์*"
                                type="number"
                              />
                              {errorNumberFormat(values["numLicenses"]) &&
                                checkingError && (
                                  <>
                                    <div className="is-invalid"></div>
                                    <div className="invalid-feedback">
                                      กรุณากรอกจำนวนสิทธิ์ให้ถูกต้อง
                                    </div>
                                  </>
                                )}
                            </div>
                            <div className={`col-lg-3 input`}>
                              <Select
                                name="rentalPeriod"
                                withFeedbackLabel={false}
                                label="เวลาในการยืมแบบสมาชิก"
                                onChange={handleRentalPeriodChange}
                                value={rentalPeriodValue}
                              >
                                {rentalPeriodList}
                              </Select>
                            </div>
                            <div className={`col-md-3 input`}>
                              <Select
                                name="anonymousRentalPeriod"
                                withFeedbackLabel={false}
                                label="เวลาในการยืมแบบไม่ระบุตัวตน"
                                onChange={handleAnonymousRentalPeriodChange}
                                value={anonymousRentalPeriodValue}
                              >
                                {rentalPeriodList}
                              </Select>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="row">
                        <div className={`col-lg-12 input`}>
                          <p style={{ marginBottom: "0.5rem" }}>Tags</p>
                          {tags.map((tag) => {
                            const isLongTag = tag.length > 20;

                            const tagElem = (
                              <Tag
                                className="edit-tag"
                                key={tag}
                                closable={true}
                                onClose={() => handleClose(tag)}
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                  fontSize: "13px",
                                  height: "32px",
                                }}
                              >
                                <span>
                                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                </span>
                              </Tag>
                            );
                            return isLongTag ? (
                              <Tooltip title={tag} key={tag}>
                                {tagElem}
                              </Tooltip>
                            ) : (
                              tagElem
                            );
                          })}
                          {inputVisible && (
                            <Input2
                              ref={saveInputRef}
                              type="text"
                              size="small"
                              className="tag-input"
                              value={inputValue}
                              onChange={handleInputChange}
                              onBlur={handleInputConfirm}
                              onPressEnter={handleInputConfirm}
                              style={{
                                width: "100px",
                                height: "32px",
                              }}
                            />
                          )}
                          {!inputVisible && (
                            <Tag
                              className={`site-tag-plus dashedBox`}
                              onClick={showInput}
                            >
                              <PlusOutlined style={{ marginRight: "2px" }} />{" "}
                              เพิ่ม Tag
                            </Tag>
                          )}
                        </div>
                      </div>
                      <div>
                        <p
                          style={{
                            marginTop: "12px",
                            marginBottom: "0.5rem",
                            fontSize: "16px",
                          }}
                        >
                          อัปโหลดไฟล์หน้าปกรายการพ็อดคาสท์*
                        </p>
                        <p className={classes.subtitle}>
                          รองรับไฟล์ jpg, jpeg หรือ png เท่านั้น
                        </p>
                        <p
                          className={classes.subtitle}
                          style={{ marginBottom: "8px" }}
                        >
                          กรุณาใช้อัตราส่วน 1:1
                          เนื่องจากมีการใช้สัดส่วนนี้มากที่สุดในโปรแกรมเล่นพ็อดคาสท์และหน้าตัวอย่างของพ็อดคาสท์
                        </p>
                        {uploadImageMemo}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </>
      {fileUploadError ? (
        <UploadInformationDialog
          show={true}
          onHide={() => {
            setFileUploadError(false);
          }}
          isError={fileUploadError}
          errorMessage={"png"}
        />
      ) : (
        ""
      )}
      <ModalsSuccess
        show={successModal}
        onHide={() => {
          onHideSuccessModal();
        }}
        onClickAccept={() => {
          onHideSuccessModal();
          onHide();
          if (contentUIDToUpload) {
            reqDeleteFileFunc();
            reqDeleteCoverFunc();
          }
          setTimeout(() => {
            setFilePost(false);
          }, 200);
        }}
      />
    </>
  );
}
